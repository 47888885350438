/**
 * Librerias
 */
import React,{useEffect, useState} from "react";
import Swal from "sweetalert2"
import moment from 'moment';
import { HiSearch } from "react-icons/hi";
import {MdOutlineDoDisturb,MdOutlineEditCalendar} from 'react-icons/md';
import { GiConfirmed } from "react-icons/gi";

/**
 * Styles
 */
 import '../styles/table.css'

 /**
 * Objects
 */
//Contiene la interacción a la base de datos del objeto de tipo calendarLog
import { createCalendarLog } from "../objects/calendarLog";

 /**
 * Components
 */
//Componente que muestra la información de los requerimientos del médico tratante para la cirugía
import DetallesModal from "./detallesModal";

/**
 * Componente que muestra el listado de eventos por realizar en forma de tabla
 * @param {Object} param0 Recibe los elementos necesarios para mostrar el listado de eventos realizados
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const PendienteTable = ({eventos,uhList,events,setEventoSeleccionado,eventoSeleccionado,
    setIsMostrarEditar,userName,isMostrarEnPendiente,userLevel}) => {
    
    //Estado que almacena el listado de eventos a mostrar
    const [eventosAMostrar,setEventosAMostrar] = useState([])
    //Estado que indica si el usuario logeado tiene permitido visualizar las opciones de actualización de evento
    const [optionsVisible,setOptionsVisible] = useState(true)
    const [isPacific, setIsPacific] = useState(false)

    useEffect(()=>{
        var MyDate = new Date();
        if(MyDate.toString().includes("GMT-0700")){
        setIsPacific(true)
        }
        else{
        setIsPacific(false)
        }
    },[])

    useEffect(()=>{
        setEventosAMostrar(eventos)
    },[eventos])

    useEffect(()=>{
        if(userLevel === "superuser" || userLevel === "agenda"){
            setOptionsVisible(true)
        }
        else{
            setOptionsVisible(false)
        }
    },[userLevel])

    /**
     * Función que muestra la información de un evento seleccionado
     * @param {Object} evento Recibe el evento seleccionado
     * @returns Devuelve el estado de la solicitud
     */
     const getEvent = (evento) => (event) => {
        event.preventDefault()
        const resultado = events.find( uh => uh.id === evento.id )
        if(resultado){
            setEventoSeleccionado(resultado)
            if(document.getElementById('modal').style.display === "none" || !document.getElementById('modal').style.display){
                document.getElementById('modal').style.display='block'
            }
        }
    }
    
    /**
     * Función que obtiene el nombre de la unidad hospitalaria a la que pertenece el evento
     * @param {String} UnidadHospitalaria Recibe la unidad hospitalaria a buscar
     * @returns String Devuelve el nombre encontrado
     */
    function getUH(UnidadHospitalaria){
        const resultado = uhList.find( uh => uh.calendar === UnidadHospitalaria )
        if(resultado){
            return resultado.hospital
        }
        else{
            return UnidadHospitalaria
        }
    }

    /**
     * Función que atrapa el evento click de cancelar y dispara la solicitud de cancelación de evento
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
     const handleCancelarClick = (evento) => (event) => {
        event.preventDefault()
        const resultado = events.find( uh => uh.id === evento.id )
        let mensaje = ""
        if(isPacific){
            mensaje = getUH(evento.UnidadHospitalaria).toUpperCase() + " " + evento.Paciente.toUpperCase() + " " + evento.Procedimiento.toUpperCase() + " del " + moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end.dateTime).add(1,'hours').format('DD-MM-YYYY hh:mm:ss a')
        }
        else{
            mensaje = getUH(evento.UnidadHospitalaria).toUpperCase() + " " + evento.Paciente.toUpperCase() + " " + evento.Procedimiento.toUpperCase() + " del " + moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end.dateTime).format('DD-MM-YYYY hh:mm:ss a')
        }

        let motivoOpciones = ""
        motivoOpciones+="<option value='cero' selected>Seleccione el motivo de la cancelación</option>"
        motivoOpciones+="<option value='Cancelada por el médico'>Cancelada por el médico</option>"
        motivoOpciones+="<option value='Cancelada por el Paciente'>Cancelada por el Paciente</option>"
        motivoOpciones+="<option value='Error de Captura'>Error de Captura</option>"
        motivoOpciones+="<option value='Expediente'>Expediente</option>"
        motivoOpciones+="<option value='Hospital'>Hospital</option>"
        motivoOpciones+="<option value='Aseguradora'>Aseguradora</option>"
        
        Swal.fire({
            title: "Se cancelará la reservación del quirófano:",
            html:`<div class="form_wrapper">
                                                                            <div class="form_container">
                                                                                <div class="row clearfix">
                                                                                    <div class="">
                                                                                        <form autocomplete="off" method="post">
                                                                                            <h3>`+mensaje+`</h3>
                                                                                            <br />
                                                                                            <div class="input_field select_option">
                                                                                                <select id="selectMotivo">
                                                                                                    `+motivoOpciones+`
                                                                                                </select>
                                                                                                <div class="select_arrow"></div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>`,
            text: mensaje,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#14988f',
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
            cancelButtonText:`Cancelar`,
            customClass: 'swal-confirm',
            preConfirm: () => {
                if(document.getElementById('selectMotivo').value !== "cero"){
                    let date = new Date()
                    //Se solicita el registro del evento
                    solicitarUpdateEvent()
                    async function solicitarUpdateEvent(){
                        const response = await createCalendarLog(evento.id,"cancel",document.getElementById('selectMotivo').value,evento,resultado.description,userName,date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                        date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":"+date.getMilliseconds())
                        if(response === "success"){//Registro de usuario exitoso
                            Swal.fire({
                                icon: 'success',
                                title: 'Actualización de evento',
                                text: 'Cirugía cancelada de manera exitosa',
                                confirmButtonText: "Aceptar",
                                confirmButtonColor: "#04afaa",
                            })
                        }
                        else{//Error en el registro de usuario
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response,
                            })
                        }
                    }
                }
                else{
                    Swal.showValidationMessage('Seleccione el motivo de la reasignación')   
                } 
            }
        })
    }

    /**
     * Función que atrapa el evento click de confirmar y dispara la solicitud de confirmación del evento
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
     const handleConfirmarClick = (evento) => (event) => {
        event.preventDefault()
        let mensaje = ""
        if(isPacific){
            mensaje = getUH(evento.UnidadHospitalaria).toUpperCase() + " " + evento.Paciente.toUpperCase() + " " + evento.Procedimiento.toUpperCase() + " del " + moment(evento.start).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end).add(1,'hours').format('DD-MM-YYYY hh:mm:ss a')
        }
        else{
            mensaje = getUH(evento.UnidadHospitalaria).toUpperCase() + " " + evento.Paciente.toUpperCase() + " " + evento.Procedimiento.toUpperCase() + " del " + moment(evento.start).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end).format('DD-MM-YYYY hh:mm:ss a')
        }
        const resultado = events.find( uh => uh.id === evento.id )
        Swal.fire({
            title: "Se confirmará la reservación del quirófano:",
            text: mensaje,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#14988f',
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
            cancelButtonText:`Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                //Se solicita el registro del evento
                let description = resultado.description.toString().split('|')
                let requetsBody = ""
                requetsBody += description[0] + "|"
                requetsBody += description[1] + "|"
                requetsBody += description[2] + "|"
                requetsBody += description[3] + "|"
                requetsBody += description[4] + "|"
                requetsBody += description[5] + "|"
                requetsBody += description[6] + "|"
                requetsBody += description[7] + "|"
                requetsBody += description[8] + "|"
                requetsBody += description[9] + "|"
                requetsBody += description[10] + "|"
                requetsBody += description[11] + "|"
                requetsBody += description[12] + "|"
                requetsBody += description[13] + "|"
                requetsBody += description[14] + "|"
                requetsBody += description[15] + "|"
                requetsBody += description[16] + "|"
                requetsBody += description[17] + "|"
                requetsBody += "Solicitado=" + userName
                requetsBody += "|Concepto=Confirmación" 
                if(description[20]){
                    requetsBody += description[20] + "|"
                }
                else{
                    requetsBody += "|EstudiosTransoperatorios=NA"
                }
                if(description[21]){
                    requetsBody += description[21] + "|"
                }
                else{
                    requetsBody += "|TipoClienteOpcion=NA"
                }
                if(description[22]){
                    requetsBody += description[22] + "|"
                }
                else{
                    requetsBody += "|TipoAnestesiologo=NA"
                }
                if(description[23]){
                    requetsBody += description[32] + "|"
                }
                else{
                    requetsBody += "|TipoCambio=NA"
                }

                let date = new Date()
                evento.description = requetsBody
                let eventoObj = null
                for(let i =0;i<events.length;i++){
                    if(events[i].id === evento.id){
                        eventoObj = events[i]
                    }
                }
                solicitarUpdateEvent()
                async function solicitarUpdateEvent(){
                    const response = await createCalendarLog(evento.id,"confirmar","confirm",eventoObj,requetsBody,userName,date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                    date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":"+date.getMilliseconds(),evento.id_evento)
                    if(response === "success"){//Registro de usuario exitoso
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualización de evento',
                            text: 'Cirugía confirmada de manera exitosa',
                            confirmButtonText: "Aceptar",
                            confirmButtonColor: "#04afaa",
                          })
                    }
                    else{//Error en el registro de usuario
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response,
                          })
                    }
                }
            }
        })
    }

    /**
     * Función que atrapa el evento click para editar un evento y disparara el muestreo de la sección de edición
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
     const handleShowEditeFormClick = (evento) => (event) => {
        event.preventDefault()
        const resultado = events.find( uh => uh.id === evento.id )
        if(resultado){
            setEventoSeleccionado(resultado)
            setIsMostrarEditar(true)
        }
    }


    return (
        <>
        <div className="Table">
            <div className="Heading">
                <div className="Cell">
                    <p>Unidad Hospitalaria</p>
                </div>
                <div className="Cell">
                    <p>Solicitado</p>
                </div>
                <div className="Cell">
                    <p>Quirófano</p>
                </div>
                <div className="Cell">
                    <p>Médico</p>
                </div>
                <div className="Cell">
                    <p>Tel. Médico</p>
                </div>
                <div className="Cell">
                    <p>Paciente</p>
                </div>
                <div className="Cell">
                    <p>Tel. Paciente</p>
                </div>
                <div className="Cell">
                    <p>Procedimiento</p>
                </div>
                <div className="Cell">
                    <p>Inicio</p>
                </div>
                <div className="Cell">
                    <p>Fin</p>
                </div>
                <div className="Cell">
                    <p></p>
                </div>
                {
                    optionsVisible
                        ?
                            <>
                                <div className="Cell">
                                    <p></p>
                                </div>
                                <div className="Cell">
                                    <p></p>
                                </div>
                            </>
                        :
                            null
                }
                {
                    isMostrarEnPendiente && optionsVisible
                        ?
                            <div className="Cell">
                                <p></p>
                            </div>
                        :
                            null
                }
            </div>
            {
                eventosAMostrar.map(event => 
                    <div className="Row" key={event.id}>
                        <div className="Cell">
                            <p>{getUH(event.UnidadHospitalaria).toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{moment(event.created).format('DD-MM-YYYY hh:mm:ss a')}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Sala.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Medico.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.TelefonoMedico}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Paciente.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.TelefonoPaciente}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Procedimiento.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            {
                                isPacific
                                    ?
                                        <p>{event.start.split('T')[0].split('-')[2]+ '-' + event.start.split('T')[0].split('-')[1] + '-' + event.start.split('T')[0].split('-')[0]+ ' ' + event.start.split('T')[1].split(':')[0] + ":" + event.start.split('T')[1].split(':')[1]}</p>
                                    :
                                        <p>{event.start.split('T')[0].split('-')[2]+ '-' + event.start.split('T')[0].split('-')[1] + '-' + event.start.split('T')[0].split('-')[0]+ ' ' + event.start.split('T')[1].split(':')[0] + ":" + event.start.split('T')[1].split(':')[1]}</p>
                            }
                        </div>
                        <div className="Cell">
                            {
                                isPacific
                                    ?
                                        <p>{event.end.split('T')[0].split('-')[2]+ '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0]+ ' ' + event.end.split('T')[1].split(':')[0] + ":" + event.end.split('T')[1].split(':')[1]}</p>
                                    :
                                        <p>{event.end.split('T')[0].split('-')[2]+ '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0]+ ' ' + event.end.split('T')[1].split(':')[0] + ":" + event.end.split('T')[1].split(':')[1]}</p>
                            }
                        </div>
                        {
                            isMostrarEnPendiente && optionsVisible
                                ?
                                    <div className="Cell">
                                        <h1 className='info_itemblogConfirmar'onClick={handleConfirmarClick(event)}><GiConfirmed /> { "Confirmar".toUpperCase()}</h1> 
                                    </div>
                                :
                                    null
                        }
                        {
                            optionsVisible
                                ?
                                    <>
                                        <div className="Cell">
                                            <h1 className='info_itemblogEditar'onClick={handleShowEditeFormClick(event)}><MdOutlineEditCalendar /> { "Editar".toUpperCase()}</h1> 
                                        </div>
                                        <div className="Cell">
                                            <h1 className='info_itemblogCancelar'onClick={handleCancelarClick(event)}><MdOutlineDoDisturb /> { "Cancelar".toUpperCase()}</h1> 
                                        </div>
                                    </>
                                :
                                    null
                        }
                        <div className="Cell">
                            <h1 className='info_itemblogRequerimientos' onClick={getEvent(event)}><HiSearch /> { "Detalles".toUpperCase()}</h1> 
                        </div>
                    </div>
                )
            }
        </div>
        <DetallesModal eventoSeleccionado={eventoSeleccionado}/>
        </>
    )
}

export default PendienteTable;