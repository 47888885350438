/**
 * Librerías
 */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"
import moment from 'moment';
import { BsXSquare } from "react-icons/bs";
import { AiFillCaretDown,AiFillCaretUp } from "react-icons/ai";
import cie from '../utils/cie'
import procedimientos from '../utils/procedimientos'
import { createMedico,createMedicoMail } from "../objects/medico";
import { createEventIntent } from "../objects/reserve";

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'
import '../styles/reservar.css'

/**
 * Utilerías
 */
//Objetos y colecciones de la base de datos
import { buildingCollection, pacientesStore, resourcesCollection } from "../utils/firebase";
//Funciones necesarias en la lógica del negocio
import { compararInformación } from "../utils/config";

const RegitrarEvento = ({setIsMostrarRealizado,setIsMostrarAgendar,nomHospital,uhList,userLevel,userName}) => {

    //Estado que indica si aun no se ha seleccionado unidad hospitalaria
    const [buildHadError,setBuildHadError] = useState(false)
    //Estado que almacena la lista de unidades hospitalarias disponibles
    const [buildingList, setBuildingList] = useState([])
    //Estado que almacena la unidad hospitalaria seleccionada
    const [buildingSelected ,setBuildingSelected] = useState("-- Seleccione la unidad hospitalaria --")
    //Estado que almacena el día actual
    const [today,setToday] = useState("")
    const [rangoInicial,setRangoInicial] = useState("")
    //Estado que indica si aun no se ha seleccionado el quirófano requerido
    const [resourceHadError,setResourceHadError] = useState(false)
    //Estado que almacena la lista de quirófanos disponibles para la unidad hospitalaria seleccionada
    const [resourcesList, setResourcesList] = useState([])
    //Estado que almacena el quirófano requerido para la cirugía
    const [resourcesSelected ,setResourcesSelected] = useState("-- Seleccione el quirófano deseado --")
    const [isMediaHora, setIsMediaHora] = useState(true)
    //Estado que almacena el mensaje de asistencia para el usuario cuando un parámetro es invalido
    const [messageError,setMessageError] = useState("")
    //Estado que almacena la cedula profesional del medico tratatente
    const [cedula, setCedula] = useState("")
    //Estado que indica si aun no se ha ingresado la cedula del medico tratante
    const [cedulaHadError, setCedulaHadError] = useState(false)
    //Estado que almacena el télefono de contacto del medico tratante
    const [telefono, setTelefono] = useState("")
    //Estado que indica si aun no se ha ingresado el teléfono de contacto del medico tratante
    const [telefonoHadError, setTelefonoHadError] = useState(false)
    //Estado que almacena el nombre del paciente
    const [nombrePaciente, setNombrePaciente] = useState("")
    //Estado que indica si aun no se ha ingresado el nombre del paciente
    const [nombrePaciennteHadError, setNombrePacienteHadError] = useState(false)
    //Estado que almacena el télefono de contacto del paciente
    const [telefonoPaciente, setTelefonoPaciente] = useState("")
    //Estado que indica si aun no se ha ingresado el teléfono de contacto del paciente
    const [telefonoPacienteHadError, setTelefonoPacienteHadError] = useState(false)
    //Estadp que almacena si la fecha de nacimiento tiene error
    const [fechaNacimientoHadError,setFechaNacimientoHadError] = useState(true)
    //Estado que almacena la fecha de nacimiento del paciente
    const [fecha_nacimiento,setFechaNacimiento] = useState("")
    //Estado que almacena la edad del paciente
    const [edad, setEdad] = useState("")
    //Estado que almacena el sexo del paciente
    const [sexo,setSexo] = useState("")
    //Estado que almacena el tipo de cliente
    const [tipoCliente, setTipoCliente] = useState("")
    const [tipoClienteMensaje, setTipoClienteMensaje] = useState("Por favor indique el tipo de convenio que tiene el cliente")
    const [tipoClienteHadError, setTipoClienteHadError] = useState(false)
    const [opcionCliente, setOpcionCliente] = useState("")
    //Estado que almacena el doagnostico del paciente
    const [diagnostico, setDiagnostico] = useState("")
    const [seMuestra,setSeMuestra] = useState(true)
    const [seMuestraProdecimiento,setSeMuestraProcedimiento] = useState(true)
    //Estado que almacena los resultados de la búsqueda de diagnosticos
    const [resultList,setResultList] = useState([])
    //Estado que indica si se muestra la lista de resultados para una búsqueda de diagnostico
    const [isMostrarBusqueda,setIsMostrarBusqueda] = useState(true)
    //Estado que almacena la lista de diagnosticos disponibles
    const [cieList] = useState(cie)
    //Estado que almacena el procedimiento
    const [procedimiento, setProcedimiento] = useState("")
    //Estado que indica si aun no se ha ingresado el procedimiento
    const [procedimientoHadError, setProcedimientoHadError] = useState(false)
    const [resultProcedimientoList,setResultProcedimientoList] = useState([])
    const [isMostrarBusquedaProcedimiento,setIsMostrarBusquedaProcedimiento] = useState(true)
    //Estado que almacena el lado del procedimiento (es un campo opcional)
    const [ladoProcedimiento,setLadoProcedimiento] = useState("")
    //Estado que indica si aun no se ha ingresado el procedimiento
    const [ladoProcedimientoHadError, setLadoProcedimientoHadError] = useState(false)
    //Estado que almacena el nombre del anestesiologo
    const [nombreAnestesiologo, setNombreAnestesiologo] = useState("")
    const [tipoAnestesiologo, setTipoAnestesiologo] = useState("")
    const [nombreAnestesiologoHadError, setNombreAnestesiologoHadError] = useState(false)
    const [isAnestesiologoExterno, setIsAnestesiologoExterno] = useState(false)
    //Estado que almacena el tipo de anestesía
    const [tipoAnestesia, setTipoAnestesia] = useState("")
    //Estado que almacena la fecha en que se requiere reservar la cirugía
    const [fecha_seleccionada,setFechaSeleccionada] = useState("")
    //Estado que almacena si la fecha seleccionada tiene error
    const [fechaSeleccionadaHadError,setFechaSeleccionadaHadError] = useState("")
    const [mostrarMensajeFecha,setMostrarMensajeFecha] = useState(true)
    //Estado que almacena la cantidad de eventos reservados en un periodo de tiempo para una sala
    const [cantidadEventos,setCantidadEventos] = useState(0)
    //Estado que indica si aun no se ha seleccionado hora de finalización para la cirugía
    const [horaFinalHadError, setHoraFinalHadError] = useState(false)
    //Estado que almacena la hora de finalización requerido para la cirugía
    const [horaFinalSelected,setHoraFinalSelected] = useState("-- Seleccione la hora final --")
    //Estado que indica si aun no se ha seleccionado hora inicial para la cirugía
    const [horaInicialHadError,setHoraInicialHadError] = useState(false)
    //Estado que almacena la hora de inicio requerida para la cirugía
    const [horaInicialSelected,setHoraInicialSelected] = useState("-- Seleccione la hora inicial --")
    //Estado que almacena los eventos registrados en el calendario
    const [eventosTotales, setEventosTotales] = useState([])
    //Estado que almacena las horas iniciales disponibles
    const [horasInicialArray, setHorasIniciaArray] = useState([])
    //Estado que indica si esta desplegada la lista de horas iniciales disponibles
    const [clicHoraInicialSelected, setClicHoraInicialSelected] = useState(false)
    //Estado que almacena las horas que se encuentran disponibles para inicio de cirugía
    const [horasInicialesDisponibles,setHorasInicialesDisponibles] = useState([])
    //Estado que almacena las horas que se encuentran reservadas en el periodo de tiempo seleccionado
    const [horasReservadas, setHorasReservadas] = useState([])
    //Estado que indica si esta desplegada la lista de horas de finalización disponibles
    const [clicHoraFinalSelected, setClicHoraFinalSelected] = useState(false)
    //Estado que almacena las horas que se encuentran disponibles para finalizar la cirugía
    const [horasFinalesDisponibles, setHorasFinalesDisponibles] = useState([])
    //Estado que almacena las horas de finalización disponibles
    const [horasFinalArray, setHorasFinalArray] = useState([])
    //Estado que indica si aun no se ha ingresado el equipo quirurgico
    const [equipoQuirirgicoHadError, setEquipoQuirirgicoHadError] = useState(false)
    //Estado que almacena el equipo quirurgico requerido para la cirugía
    const [equipoQuirirgico, setEquipoQuirurgico] = useState("")
    const [equipoEspecial, setEquipoEspecial] = useState("")
    const [equipoEspecialHadError, setEquipoEspecialHadError] = useState(false)
    const [hemoderivados, setHemoderivados] = useState("")
    const [hemoderivadosHadError, setHemoderivadosHadError] = useState(false)
    //Estado que indica si se tienen estudios transoperatorios
    const [hadEstudios,setHadEstudios] = useState(false)
    //Estado que almacena los estudios transoperatorios
    const [estudios, setEstudios] = useState("")
    //Estado que indica si se tienen requerimientos extras
    const [hadExtras,setHadExtras] = useState(false)
    //Estado que almacena los requerimientos extras
    const [extras, setExtras] = useState("")
    //Estado que almacena la lista de medicos disponibles para reservar quirófanos
    const [medicoList, setMedicoList] = useState([])
    const [yesterday, setYesterday] = useState("")

    useEffect(()=>{
        let hoy = new Date()
        let dia = ""
        if(hoy.getDate().toString().length < 2){
            dia = "0" + hoy.getDate()
        }
        else{
            dia = hoy.getDate()
        }
        let mes = hoy.getMonth() + 1
        if(mes.toString().length < 2){
            mes = "0" + mes
        }
        let nextMont = hoy.getMonth() + 2
        if(nextMont.toString().length < 2){
            nextMont = "0" + nextMont
        }
        let fechaAnterior = sumarDias(hoy, -1)
        let diaAnterior = ""
        if(fechaAnterior.getDate().toString().length < 2){
            diaAnterior = "0" + fechaAnterior.getDate()
        }
        else{
            diaAnterior = fechaAnterior.getDate()
        }
        let mesAnterior = fechaAnterior.getMonth() + 1
        if(mesAnterior.toString().length < 2){
            mesAnterior = "0" + mesAnterior
        }
        setYesterday(fechaAnterior.getFullYear() + "-" + mesAnterior + "-" + diaAnterior)
        pacientesStore.collection("doctordirectory")
         .onSnapshot(snap =>{
             const resultList = []
             snap.forEach(doc => {
                 resultList.push({ id: doc.id,...doc.data() })
             })
            setMedicoList(resultList)
             
         })
    },[])


    useEffect(()=>{
        /**
         * Se obtiene la fecha actual y se predefine en la selección de fechas con formato yyyy-mm-dd
         */
        let hoy = new Date()
        let dia = ""
        if(hoy.getDate().toString().length < 2){
            dia = "0" + hoy.getDate()
        }
        else{
            dia = hoy.getDate()
        }
        let mes = hoy.getMonth() + 1
        if(mes.toString().length < 2){
            mes = "0" + mes
        }
        let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 7;
        let resta = hoy.getTime() - semanaEnMilisegundos;
        let fechaDentroDeUnaSemana = new Date(resta);
        

        setToday(hoy.getFullYear() + "-" + mes + "-" + dia)
        dia = ""
        if(fechaDentroDeUnaSemana.getDate().toString().length < 2){
            dia = "0" + fechaDentroDeUnaSemana.getDate()
        }
        else{
            dia = fechaDentroDeUnaSemana.getDate()
        }
        mes = fechaDentroDeUnaSemana.getMonth() + 1
        if(mes.toString().length < 2){
            mes = "0" + mes
        }
        setRangoInicial(fechaDentroDeUnaSemana.getFullYear() + "-" + mes + "-" + dia)
        //setFechaSeleccionada(hoy.getFullYear() + "-" + mes + "-" + dia)

        /**
         * Se obtiene la lista de unidades hospitalarias
         */
        pacientesStore.collection(buildingCollection)
        .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                if(userLevel !== "superuser"){
                    let data = doc.data()
                    const resultado = uhList.find( uh => uh.calendar === data.Building_Id );
                    if(resultado){
                        if(nomHospital === resultado.hospital){
                            resultList.push({ id: doc.id, ...doc.data() })
                        }
                    }
                }
                else{
                    resultList.push({ id: doc.id, ...doc.data() })
                }
            })
            resultList.sort(compararInformación('Building_Id'))
            setBuildingList(resultList)
        },(error)=>{ 
            setBuildingList([]) 
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error,
            })
          })
    },[nomHospital,uhList,userLevel])

    useEffect(()=>{
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Se verifica que se haya seleccionado una unidad hospitalaria
            if(buildHadError){//Si se habia indicado error en unidad hospitalaria se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setBuildHadError(false)
            if(buildingSelected === "Hospital-MAC-Celaya"){
                setIsMediaHora(true)
            }
            else{
                setIsMediaHora(true)
            }
            /**
             * Se obtiene la lista de quirófanos disponibles en la unidad hospitalaria seleccionada
             */
            pacientesStore.collection(resourcesCollection).where('Building_Id',"==",buildingSelected).where('Activo',"==",true)
            .onSnapshot(snap =>{
                const resultList = []
                snap.forEach(doc => {
                    resultList.push({ id: doc.id, ...doc.data() })
                })
                resultList.sort(compararInformación('Resource_Name'))
                setResourcesList(resultList)
            })
        }
        else{//Se limpia la lista de quirofanos
            setResourcesList([])
            setIsMediaHora(false)
        }
    },[buildingSelected,buildHadError,resourcesSelected])

    useEffect(()=>{
        if(buildingList){
            if(buildingList.length > 0){
                setBuildingSelected(buildingList[0].Building_Id)
            }
        }
    },[buildingList])

    useEffect(()=>{
        if(cedula !== ""){
            if(cedulaHadError){//Si se habia indicado error en cedula profesional se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setCedulaHadError(false)
        }
    },[cedula,cedulaHadError])

    useEffect(()=>{
        let expresion = /^\d{10}$/gm
        if(expresion.test(telefono)){
            if(telefonoHadError){//Si se habia indicado error en teléfono de contacto del medico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setTelefonoHadError(false)
        }
    },[telefono,telefonoHadError])

    useEffect(()=>{
        if(validarNombre(nombrePaciente)){
            if(nombrePaciennteHadError){//Si se habia indicado error en el nombre del paciente se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setNombrePacienteHadError(false)
        }
    },[nombrePaciente,nombrePaciennteHadError])

    useEffect(()=>{
        let expresion = /^\d{10}$/gm
        if(expresion.test(telefonoPaciente) && telefonoPaciente !== telefono){
            if(telefonoPacienteHadError){//Si se habia indicado error en teléfono de contacto del paciente se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setTelefonoPacienteHadError(false)
        }
    },[telefonoPaciente,telefonoPacienteHadError,telefono])

    useEffect(()=>{
        if(fecha_nacimiento){
            setEdad(getEdad(fecha_nacimiento))
        }
        else{
            if(fechaNacimientoHadError){
                setMessageError("")
            }
            setFechaNacimientoHadError(true)
        }
        function getEdad(dateString) {
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            let diferenciaDias = hoy.getDate() - fechaNacimiento.getDate()
            let diasAgregar = 0
            switch(fechaNacimiento.getMonth()){
                case 0:
                    diasAgregar = 31
                    break
                case 1:
                    diasAgregar = 28
                    break
                case 2:
                    diasAgregar = 31
                    break
                case 3:
                    diasAgregar = 30
                    break
                case 4:
                    diasAgregar = 31
                    break
                case 5:
                    diasAgregar = 30
                    break
                case 6:
                    diasAgregar = 31
                    break
                case 7:
                    diasAgregar = 31
                    break
                case 8:
                    diasAgregar = 30
                    break
                case 9:
                    diasAgregar = 31
                    break
                case 10:
                    diasAgregar = 30
                    break
                case 11:
                    diasAgregar = 31
                    break
                default:
                    diasAgregar = 0
                    break
            }
            if (
              diferenciaMeses < 0 ||
              (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
              edad--
              diferenciaMeses = diferenciaMeses + 12
            }

            if (
                diferenciaDias < 0 ||
                (diferenciaDias === 0 && hoy.getDate() < fechaNacimiento.getDate())
              ) {
                diferenciaMeses--
                diferenciaDias = diferenciaDias + diasAgregar
              }
              if(edad >= 0){
                setMessageError("")
                setFechaNacimientoHadError(false)
                return edad + "a " + diferenciaMeses + "m " + diferenciaDias +"d"
              }
              else{
                if(fechaNacimientoHadError){
                    setMessageError("")
                }
                setFechaNacimientoHadError(true)
                return ""
              }
          }
    },[fecha_nacimiento,fechaNacimientoHadError])

    useEffect(()=>{
        if(tipoCliente !== "" && opcionCliente!== ""){
            if(tipoClienteHadError){//Si se habia indicado error en el procedimiento a realizar se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setTipoClienteHadError(false)
        }
    },[tipoCliente,opcionCliente,tipoClienteHadError])

    useEffect(()=>{
        if(opcionCliente){
            if(opcionCliente === "Gobierno"){
                setTipoCliente("Gobierno")
                setTipoClienteMensaje("Tipo de cliente con convenio de Gobierno")
            }
            else{
                if(opcionCliente === "Privado"){
                    setTipoCliente("Privado")
                    setTipoClienteMensaje("Tipo de cliente Privado")
                }
                else{
                    if(opcionCliente === "Aseguradora"){
                        setTipoCliente("")
                        setTipoClienteMensaje("Tipo de cliente Privado")
                    }
                    else{
                        if(opcionCliente === "Empresa"){
                            setTipoClienteMensaje("Por favor indique el tipo de convenio que tiene")
                            setTipoCliente("")
                        }
                    }
                }
            }
        }
        else{
            setTipoClienteMensaje("Por favor indique el tipo de convenio que tiene el cliente")
        }
    },[opcionCliente])

    useEffect(()=>{
        let busqueda = []
        if(diagnostico){
            setSeMuestra(true)
            if(diagnostico.length >= 3){
                cieList.forEach(
                    cie => {
                        if(quitarAcentos(cie.NOMBRE.toUpperCase()).includes(quitarAcentos(diagnostico.toUpperCase()))){
                            busqueda.push(cie)
                        }
                    }
                )
                if(busqueda.length > 0){
                    if(isMostrarBusqueda){
                        //document.getElementById('selectCIE').style.display='inline'
                    }
                    else{
                        //document.getElementById('selectCIE').style.display='none'
                    }
                }
                else{
                    setIsMostrarBusqueda(true)
                    //document.getElementById('selectCIE').style.display='none'
                }
                setResultList(busqueda)
            }
            else{
                //document.getElementById('selectCIE').style.display='none'
                setResultList([])
                setIsMostrarBusqueda(true)
            }
        }
        else{
            //document.getElementById('selectCIE').style.display='none'
            setResultList([])
            setIsMostrarBusqueda(true)
            setSeMuestra(false)
        }

    },[diagnostico,cieList,isMostrarBusqueda])

    useEffect(()=>{
        let busqueda = []
        if(procedimiento){
            setSeMuestraProcedimiento(true)
            if(procedimiento.length >= 3){
                procedimientos.forEach(
                    cie => {
                        if(quitarAcentos(cie.PRO_NOMBRE.toUpperCase()).includes(quitarAcentos(procedimiento.toUpperCase()))){
                            busqueda.push(cie)
                        }
                    }
                )
                if(busqueda.length > 0){
                    if(isMostrarBusquedaProcedimiento){
                        //document.getElementById('selectCIE').style.display='inline'
                    }
                    else{
                        //document.getElementById('selectCIE').style.display='none'
                    }
                }
                else{
                    setIsMostrarBusquedaProcedimiento(true)
                    //document.getElementById('selectCIE').style.display='none'
                }
                setResultProcedimientoList(busqueda)
            }
            else{
                setResultProcedimientoList([])
                setIsMostrarBusquedaProcedimiento(true)
            }
        }
        else{
            setResultProcedimientoList([])
            setIsMostrarBusquedaProcedimiento(true)
            setSeMuestraProcedimiento(false)
        }
    },[procedimiento,isMostrarBusquedaProcedimiento])

    useEffect(()=>{
        if(ladoProcedimiento !== ""){
            if(ladoProcedimientoHadError){//Si se habia indicado error en el procedimiento a realizar se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setLadoProcedimientoHadError(false)
        }
    },[ladoProcedimiento,ladoProcedimientoHadError])

    useEffect(()=>{
        if(nombreAnestesiologo !== "" && tipoAnestesiologo!== ""){
            if(nombreAnestesiologoHadError){//Si se habia indicado error en el procedimiento a realizar se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setNombreAnestesiologoHadError(false)
        }
    },[nombreAnestesiologo,tipoAnestesiologo,nombreAnestesiologoHadError])

    useEffect(()=>{
        if(tipoAnestesiologo){
            if(tipoAnestesiologo === "Staff"){
                setIsAnestesiologoExterno(false)
                setNombreAnestesiologo("Anestesiólogo Staff")
            }
            else{
                setIsAnestesiologoExterno(true)
                setNombreAnestesiologo("")
            }
        }
        else{
            setIsAnestesiologoExterno(false)
        }
    },[tipoAnestesiologo])

    useEffect(()=>{
        if(!fecha_seleccionada){//Se verifica si la fecha se encuentra vacía
            if(today){//Se verifica si se cuenta con el valor de la fecha actual
                //Se predefine la fecha actual como fecha seleccionada
                setMostrarMensajeFecha(true)
                //setFechaSeleccionada(today)
            }
        }

        //Se solicitan las horas iniciales disponibles
        getTimeLeft()
        /**
         * Función que obtiene las horas disponibles para el inicio de la cirugía
         */
        function getTimeLeft(){
            //Se obtiene el día actual
            let hoy = new Date()

            let dia = ""
            if(hoy.getDate().toString().length < 2){
                dia = "0" + hoy.getDate()
            }
            else{
                dia = hoy.getDate()
            }
            let mes = hoy.getMonth() + 1
            if(mes.toString().length < 2){
                mes = "0" + mes
            }

            pacientesStore.collection("eventos")
            .onSnapshot(snap =>{
                const resultList = []
                snap.forEach(doc => {
                    resultList.push({ id: doc.id, ...doc.data() })
                })
                
                let resultados = []
                for(let i=0;i<resultList.length;i++){
                    if(resultList[i].event){
                        resultados.push(resultList[i].event)
                    }
                }


                resultados.sort(function(x, y) {
                var firstDate = new Date(x.start.dateTime),
                    SecondDate = new Date(y.start.dateTime);
                    
                if (firstDate < SecondDate) return -1;
                if (firstDate > SecondDate) return 1;
                return 0;
                });

                if(resultados.length !== cantidadEventos){
                    setHoraFinalSelected("-- Seleccione la hora final --")
                    setHoraInicialSelected("-- Seleccione la hora inicial --")
                }
                setCantidadEventos(resultados.length)
                setEventosTotales(resultados)
            })


            let horasArray = []
            if(fecha_seleccionada === hoy.getFullYear() + "-" + mes + "-" + dia){//Se  verifica si el día seleccionado corresponde al día actual
                //Se obtiene la hora actual
                let ahora = new Date()
                let hora = ahora.getHours()
                //let minutos = ahora.getMinutes()
                horasArray = []
                // if(minutos < 30){
                //     horasArray.push({
                //         hora : hora,
                //         minutos: "30"
                //     })
                // }

                for(let i=hora + 1;i<24;i++){//Se agregan las horas que aun están disponibles en el día actual considerando tambien medias horas
                    horasArray.push({
                        hora : i,
                        minutos: "00"
                    })
                    if(isMediaHora){
                        horasArray.push({
                            hora : i,
                            minutos: "30"
                        })
                    }
                }
            }
            else{//Se ha seleccionado otra fecha por lo que se muestran las 24 horas disponibles considerando medias horas
                if(isMediaHora){
                    horasArray = [
                        {
                            hora : "00",
                            minutos: "00"
                        },
                        {
                            hora : "00",
                            minutos: "30"
                        },
                        {
                            hora : "01",
                            minutos: "00"
                        },
                        {
                            hora : "01",
                            minutos: "30"
                        },
                        {
                            hora : "02",
                            minutos: "00"
                        },
                        {
                            hora : "02",
                            minutos: "30"
                        },
                        {
                            hora : "03",
                            minutos: "00"
                        },
                        {
                            hora : "03",
                            minutos: "30"
                        },
                        {
                            hora : "04",
                            minutos: "00"
                        },
                        {
                            hora : "04",
                            minutos: "30"
                        },
                        {
                            hora : "05",
                            minutos: "00"
                        },
                        {
                            hora : "05",
                            minutos: "30"
                        },
                        {
                            hora : "06",
                            minutos: "00"
                        },
                        {
                            hora : "06",
                            minutos: "30"
                        },
                        {
                            hora : "07",
                            minutos: "00"
                        },
                        {
                            hora : "07",
                            minutos: "30"
                        },
                        {
                            hora : "08",
                            minutos: "00"
                        },
                        {
                            hora : "08",
                            minutos: "30"
                        },
                        {
                            hora : "09",
                            minutos: "00"
                        },
                        {
                            hora : "09",
                            minutos: "30"
                        },
                        {
                            hora : "10",
                            minutos: "00"
                        },
                        {
                            hora : "10",
                            minutos: "30"
                        },
                        {
                            hora : "11",
                            minutos: "00"
                        },
                        {
                            hora : "11",
                            minutos: "30"
                        },
                        {
                            hora : "12",
                            minutos: "00"
                        },
                        {
                            hora : "12",
                            minutos: "30"
                        },
                        {
                            hora : "13",
                            minutos: "00"
                        },
                        {
                            hora : "13",
                            minutos: "30"
                        },
                        {
                            hora : "14",
                            minutos: "00"
                        },
                        {
                            hora : "14",
                            minutos: "30"
                        },
                        {
                            hora : "15",
                            minutos: "00"
                        },
                        {
                            hora : "15",
                            minutos: "30"
                        },
                        {
                            hora : "16",
                            minutos: "00"
                        },
                        {
                            hora : "16",
                            minutos: "30"
                        },
                        {
                            hora : "17",
                            minutos: "00"
                        },
                        {
                            hora : "17",
                            minutos: "30"
                        },
                        {
                            hora : "18",
                            minutos: "00"
                        },
                        {
                            hora : "18",
                            minutos: "30"
                        },
                        {
                            hora : "19",
                            minutos: "00"
                        },
                        {
                            hora : "19",
                            minutos: "30"
                        },
                        {
                            hora : "20",
                            minutos: "00"
                        },
                        {
                            hora : "20",
                            minutos: "30"
                        },
                        {
                            hora : "21",
                            minutos: "00"
                        },
                        {
                            hora : "21",
                            minutos: "30"
                        },
                        {
                            hora : "22",
                            minutos: "00"
                        },
                        {
                            hora : "22",
                            minutos: "30"
                        },
                        {
                            hora : "23",
                            minutos: "00"
                        },
                        {
                            hora : "23",
                            minutos: "30"
                        },
                    ]
                }
                else{
                    horasArray = [
                        {
                            hora : "00",
                            minutos: "00"
                        },
                        {
                            hora : "01",
                            minutos: "00"
                        },
                        {
                            hora : "02",
                            minutos: "00"
                        },
                        {
                            hora : "03",
                            minutos: "00"
                        },
                        {
                            hora : "04",
                            minutos: "00"
                        },
                        {
                            hora : "05",
                            minutos: "00"
                        },
                        {
                            hora : "06",
                            minutos: "00"
                        },
                        {
                            hora : "07",
                            minutos: "00"
                        },
                        {
                            hora : "08",
                            minutos: "00"
                        },
                        {
                            hora : "09",
                            minutos: "00"
                        },
                        {
                            hora : "10",
                            minutos: "00"
                        },
                        {
                            hora : "11",
                            minutos: "00"
                        },
                        {
                            hora : "12",
                            minutos: "00"
                        },
                        {
                            hora : "13",
                            minutos: "00"
                        },
                        {
                            hora : "14",
                            minutos: "00"
                        },
                        {
                            hora : "15",
                            minutos: "00"
                        },
                        {
                            hora : "16",
                            minutos: "00"
                        },
                        {
                            hora : "17",
                            minutos: "00"
                        },
                        {
                            hora : "18",
                            minutos: "00"
                        },
                        {
                            hora : "19",
                            minutos: "00"
                        },
                        {
                            hora : "20",
                            minutos: "00"
                        },
                        {
                            hora : "21",
                            minutos: "00"
                        },
                        {
                            hora : "22",
                            minutos: "00"
                        },
                        {
                            hora : "23",
                            minutos: "00"
                        }
                    ]
                }
            }
            
            //Se asignan las horas iniciales disponibles
            setHorasIniciaArray(horasArray)
            //Se solicitan las horas finales disponibles
            //getFinalTimeLeft()
        }
    },[fecha_seleccionada,today,buildingSelected,resourcesSelected,horaInicialSelected,cantidadEventos,isMediaHora])

    useEffect(()=>{
        setHoraFinalSelected("-- Seleccione la hora final --")
        setHoraInicialSelected("-- Seleccione la hora inicial --")

    },[fecha_seleccionada])

    useEffect(()=>{
        let horasValidas = []
        if(horasReservadas.length > 0){
            for(let i=0;i<horasInicialArray.length;i++){
                let encontrado = false
                for(let j=0;j<horasReservadas.length;j++){
                    if(parseInt(horasInicialArray[i].hora) === parseInt(horasReservadas[j].Hora) && horasReservadas[j].Recurso === resourcesSelected && horasReservadas[j].Unidad === buildingSelected){
                        encontrado = true
                        if(horasReservadas[j].Identificador === 'Final' && horasInicialArray[i].minutos === '30'){
                            encontrado = false
                        }
                    }
                }
                if(!encontrado){
                    horasValidas.push(horasInicialArray[i])
                }
            }

            setHorasInicialesDisponibles(horasValidas)
        }
        else{
            //alert('sin reservaciones')
            setHorasInicialesDisponibles(horasInicialArray)
        }
    },[horasReservadas,horasInicialArray,resourcesSelected,buildingSelected,fecha_seleccionada])

    useEffect(()=>{
        let horasOcupadas = []
        eventosTotales.forEach(
                    event => {
                        let dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
                        let dayInicial =  new Date(moment(fecha_seleccionada).format('MM/DD/YYYY'))
                        
                        // let isConfirmed = false
                        // if(event.summary === "Confirmada"){
                        //     isConfirmed = true
                        // }

                        if(dayEvento.getFullYear() + "-" + dayEvento.getMonth() + "-" + dayEvento.getDate() === dayInicial.getFullYear() + "-" + dayInicial.getMonth() + "-" + dayInicial.getDate()){
                            let tiempoInicial = ""
                            //let tiempoInicial = event.start.dateTime.toString().split('T')
                            if(event.start.dateTime){
                                tiempoInicial = event.start.dateTime.toString().split('T')
                            }
                            else{
                                tiempoInicial = event.start.toString().split('T')
                            }
                            let horaInicial = parseInt(tiempoInicial[1].split(':')[0])

                            //let tiempoFinal = event.end.dateTime.toString().split('T')
                            let tiempoFinal = ""
                            if(event.end.dateTime){
                                tiempoFinal = event.end.dateTime.toString().split('T')
                            }
                            else{
                                tiempoFinal = event.end.toString().split('T')
                            }

                            let horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                            let minutoFinal = parseInt(tiempoFinal[1].split(':')[1])
                            let description = event.description.toString().split('|')
                            let AppointmentType = ""
                            let Unidad = ""
                            if(description.length > 1){
                                AppointmentType = description[0].split('=')[1]
                                Unidad = description[8].split('=')[1]
                            }
                            else{
                                AppointmentType = description[0]
                            }

                            let horaReservadaObject = {
                                'Recurso' : AppointmentType,
                                'Hora' : horaInicial,
                                'Identificador':"Inicio",
                                'Unidad' : Unidad
                            }

                            horasOcupadas.push(horaReservadaObject)
                            for(let i=horaInicial + 1;i<horaFinal;i++){
                                let horaReservadaObject = {
                                    'Recurso' : AppointmentType,
                                    'Hora' : i,
                                    'Identificador':"Intermedio",
                                    'Unidad' : Unidad
                                }
                                horasOcupadas.push(horaReservadaObject)
                            }
                            if(minutoFinal === 30){
                                let horaReservadaObject = {
                                    'Recurso' : AppointmentType,
                                    'Hora' : horaFinal,
                                    'Identificador':"Final",
                                    'Unidad' : Unidad
                                }
                                horasOcupadas.push(horaReservadaObject)
                            }
                        }
                    }
                )
        setHorasReservadas(horasOcupadas)
    },[eventosTotales,fecha_seleccionada])

    useEffect(()=>{
        let horasValidas = []
        if(horasReservadas.length > 0){
            for(let i=0;i<horasFinalArray.length;i++){
                let encontrado = false
                for(let j=0;j<horasReservadas.length;j++){
                    if(parseInt(horasFinalArray[i].hora) === parseInt(horasReservadas[j].Hora) && horasReservadas[j].Recurso === resourcesSelected && horasReservadas[j].Unidad === buildingSelected){
                        if(horasReservadas[j].Identificador !== "Inicio"){
                            encontrado = true
                        }
                        else{
                            if(parseInt(horasReservadas[j].Hora) > parseInt(horaInicialSelected.split(':')[0])){
                                horasValidas.push(horasFinalArray[i])
                                i = horasFinalArray.length
                            }
                        }
                        j = horasReservadas.length
                    }
                }
                if(!encontrado && horasFinalArray[i]){
                    horasValidas.push(horasFinalArray[i])
                }
            }
            setHorasFinalesDisponibles(horasValidas)
        }
        else{
            setHorasFinalesDisponibles(horasFinalArray)
        }
    },[horasReservadas,horasFinalArray,resourcesSelected,horaInicialSelected,buildingSelected])

    useEffect(()=>{
        //Se solicitan las horas de finalización disponibles
        getFinalTimeLeft()
        /**
         * Función que obtiene las horas disponibles para finalización de la cirugía
         */
        function getFinalTimeLeft(){
            //Se obtiene la hora inicial seleccionada
            let hora = horaInicialSelected.toString().split(':')[0]
            let horasArray = []
            let contador = 0

            for(let i=parseInt(hora) + 1;i<24;i++){//Se agregan las horas disponibles en el día seleccionado considerando también medias horas
                let hora = i
                if(hora.toString().length < 2){
                    hora = "0" + hora
                }
                horasArray.push({
                    hora : hora,
                    minutos: "00"
                })

                if(isMediaHora){
                    horasArray.push({
                        hora : hora,
                        minutos: "30"
                    })
                }
                
                contador = contador + 1
            }

            //Se obtienen la cantidad de horas faltantes para completar esquema de 24 horas
            let faltantes = 24 - contador

            for(let i=0;i < faltantes - 1;i++){//Se agregan las horas faltantes considerando también medias horas (las horas agregadas corresponde al día siguiente del seleccionado)
                let hora = i
                if(hora.toString().length < 2){
                    hora = "0" + hora
                }
                horasArray.push({
                    hora : hora,
                    minutos: "00"
                })
                if(isMediaHora){
                    horasArray.push({
                        hora : hora,
                        minutos: "30"
                    })
                }
            }
            //Se asignan las horas finales disponibles
            setHorasFinalArray(horasArray)
        }
    },[horaInicialSelected,isMediaHora])

    useEffect(()=>{
        if(equipoQuirirgico !== ""){
            if(equipoQuirirgicoHadError){//Si se habia indicado error en el equipo quirúrgico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setEquipoQuirirgicoHadError(false)
        }
    },[equipoQuirirgico,equipoQuirirgicoHadError])

    useEffect(()=>{
        if(equipoEspecial !== ""){
            if(equipoEspecialHadError){//Si se habia indicado error en el equipo quirúrgico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setEquipoEspecialHadError(false)
        }
    },[equipoEspecial,equipoEspecialHadError])

    useEffect(()=>{
        if(hemoderivados !== ""){
            if(hemoderivadosHadError){//Si se habia indicado error en el equipo quirúrgico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setHemoderivadosHadError(false)
        }
    },[hemoderivados,hemoderivadosHadError])

    useEffect(()=>{
        if(resourcesSelected !== "-- Seleccione el quirófano deseado --"){//Se verifica si se ha seleccionado un quirófano valido
            if(resourceHadError){//Si se habia indicado error en quirófano se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setResourceHadError(false)
        }
    },[resourcesSelected,resourceHadError])

    useEffect(()=>{
        if(procedimiento !== ""){
            if(procedimientoHadError){//Si se habia indicado error en el procedimiento a realizar se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setProcedimientoHadError(false)
        }
    },[procedimiento,procedimientoHadError])

    useEffect(()=>{
        if(fecha_seleccionada){
            getEdad(fecha_seleccionada)
        }
        else{
            setFechaSeleccionadaHadError(true)
        }

        function getEdad(dateString) {
            let hoy = new Date()
            hoy = sumarDias(hoy,-1)
            let fecha = dateString.split('-')
            if(parseInt(fecha[0]) < hoy.getFullYear()){
                setFechaSeleccionadaHadError(true)
            }
            else{
                if(parseInt(fecha[1]) < (hoy.getMonth() + 1)){
                    if(parseInt(fecha[0]) > hoy.getFullYear()){
                        setFechaSeleccionadaHadError(false)
                    }
                    else{
                        let diaPermitido = 0
                        let mes = (hoy.getMonth() + 1)

                        switch(mes){
                            case 1:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 31
                                break
                            case 2:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 29
                                break
                            case 3:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 31
                                break
                            case 4:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 30
                                break
                            case 5:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 31
                                break
                            case 6:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 30
                                break
                            case 7:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 31
                                break
                            case 8:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 31
                                break
                            case 9:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 30
                                break
                            case 10:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 31
                                break
                            case 11:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 30
                                break
                            case 12:
                                diaPermitido = ((hoy.getDate() + 1) - 7) + 31
                                break
                        }

                        if(parseInt(fecha[2]) >= diaPermitido){
                            setFechaSeleccionadaHadError(false)
                        }
                        else{
                            setFechaSeleccionadaHadError(true)
                        }
                    }
                }
                else{
                    if(parseInt(fecha[2]) < (hoy.getDate())){
                        if(parseInt(fecha[1]) === (hoy.getMonth() + 1)){
                            setFechaSeleccionadaHadError(false)
                        }
                        else{
                            setFechaSeleccionadaHadError(false)
                        }
                    }
                    else{
                        setFechaSeleccionadaHadError(false)
                    }
                }
            }
          }
    },[fecha_seleccionada,fechaSeleccionadaHadError])

    const handleCancelarClick = () => (event) => {
        event.preventDefault()
        closeModal()
    }

    const closeModal = () => {
        setIsMostrarAgendar(false)
        setIsMostrarRealizado(true)
    }

    const handleBuildChange = (value) => {
        setBuildingSelected(value)
    };

    const handleResourceChange = (value) => {
        setResourcesSelected(value)
    };

    const onChangeCedula = (cedula) => {
        setCedula(cedula.target.value.toString().toUpperCase())
    };

    const onChangeTelefono = (telefono) => {
        setTelefono(telefono.target.value)
    };

    const onChangeNombrePaciente = (cedula) => {
        setNombrePaciente(cedula.target.value)
    };

    const onChangeTelefonoPaciente = (telefono) => {
        setTelefonoPaciente(telefono.target.value)
    };

    const onChangeNacimientoDate = (date) => {
        setFechaNacimiento(date.target.value)
    };

    const onChangeEdad = (edad) => {
        setEdad(edad.target.value)
    };

    const onChangeSexo = (sexo) => {
        setSexo(sexo.target.value)
    };

    const onChangeTypeCliente = (sexo) => {
        setOpcionCliente(sexo.target.value)
    };

    const onChangeTipoCliente = (tipo) => {
        setTipoCliente(tipo.target.value)
    };

    const onChangeDiagnostico = (diagnostico) => {
        setDiagnostico(diagnostico.target.value)
    };

    const handleDiagnosticoChange = (value) => {
        //Se asigna el diagnositico a buscar
        setDiagnostico(value)
        setIsMostrarBusqueda(true)
    };

    const handleProcedimientosChange = (value) => {
        //Se asigna el diagnositico a buscar
        setProcedimiento(value)
        setIsMostrarBusqueda(true)
    };

    const handleCancelarBusquedaClick = () => (event) => {
        event.preventDefault()
        setDiagnostico("")
    }

    const onChangeProcedimiento = (procedimiento) => {
        setProcedimiento(procedimiento.target.value)
    };

    const handleCancelarProcedimientoClick = () => (event) => {
        event.preventDefault()
        setProcedimiento("")
    }

    const handleProcedimientoChange = (value) => {
        //Se asigna el diagnositico a buscar
        setProcedimiento(value)
        setIsMostrarBusquedaProcedimiento(true)
    };

    const onChangeLadoProcedimiento = (ladoProcedimiento) => {
        setLadoProcedimiento(ladoProcedimiento.target.value)
    };

    const onChangeTipoAnestesiologo = (sexo) => {
        setTipoAnestesiologo(sexo.target.value)
    };

    const onChangeNombreAnestesiologo = (nombre) => {
        setNombreAnestesiologo(nombre.target.value)
    };

    const onChangeTipoAnestesia = (tipo) => {
        setTipoAnestesia(tipo.target.value)
    };

    const onChangeDate = (date) => {
        setMostrarMensajeFecha(false)
        setFechaSeleccionada(date.target.value)
    };

    const onChangeEquipoQuirurgico = (equipo) => {
        setEquipoQuirurgico(equipo.target.value)
    };

    const onChangeEquipoEspecial = (equipo) => {
        setEquipoEspecial(equipo.target.value)
    };

    const onChangeHemoderivados = (equipo) => {
        setHemoderivados(equipo.target.value)
    };

    const onCheckEstudios = () => {
        setHadEstudios(!hadEstudios)
    }

    const onChangeEstudios = (extras) => {
        setEstudios(extras.target.value)
    };

    const onCheckExtras = () => {
        setHadExtras(!hadExtras)
    }

    const onChangeExtras = (extras) => {
        setExtras(extras.target.value)
    };

    function mostrarOcultarHoraInicial(){ 
        if(!clicHoraInicialSelected){
            setClicHoraInicialSelected(true)
        } else{
            setClicHoraInicialSelected(false)
        }   
    }

    function mostrarOcultarHoraFinal(){ 
        if(!clicHoraFinalSelected){
            setClicHoraFinalSelected(true)
        } else{
            setClicHoraFinalSelected(false)
        }   
    }

    const seleccionarHoraInicial = (hora) => (event) => {
        event.preventDefault()
        //Se limpiar la ayuda al usuario si se contaba con error de hora inicial requerida
        if(horaInicialHadError){
            setMessageError("")
        }
        setHoraInicialHadError(false)

        //Se asigna la hora inicial y se oculta la lista de horas iniciales disponibles
        setHoraInicialSelected(hora)
        mostrarOcultarHoraInicial()
        //Se limpia la selección de hora final y se muestra el listado disponible
        setHoraFinalSelected("-- Seleccione la hora final --")
        mostrarOcultarHoraFinal()
    }

    const seleccionarHoraFinal = (hora) => (event) => {
        event.preventDefault()
        //Se limpiar la ayuda al usuario si se contaba con error de hora final requerida
        if(horaFinalHadError){
            setMessageError("")
        }
        setHoraFinalHadError(false)

        //Se asigna la hora final y se oculta la lista de horas finales disponibles
        setHoraFinalSelected(hora)
        mostrarOcultarHoraFinal()
    }

    const quitarAcentos = (cadena) =>{
        const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
        return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
    }

    const validarNombre = (nombre) => {
        if(nombre !== ""){
            if(nombre.toString().toUpperCase() !== "Pendiente".toUpperCase()
            && nombre.toString().toUpperCase() !== "En revisión".toUpperCase()
            && nombre.toString().toUpperCase() !== "En revision".toUpperCase()
            && nombre.toString().toUpperCase() !== "Sin información".toUpperCase()
            && nombre.toString().toUpperCase() !== "Sin informacion".toUpperCase()
            && nombre.toString().toUpperCase() !== "No aplica".toUpperCase()
            && nombre.toString().toUpperCase() !== "S/A".toUpperCase()
            && nombre.toString().toUpperCase() !== "Se desconoce".toUpperCase()
            && nombre.toString().toUpperCase() !== "Conocido".toUpperCase()
            && nombre.toString().toUpperCase() !== "Desconocido".toUpperCase()
            && nombre.toString().toUpperCase() !== "na".toUpperCase()){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }

    function sumarDias(fecha, dias){
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
      }

    
      const handleReservaClick = () => (event) => {
        event.preventDefault()
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Se verifica si se ha seleccionado una unidad hospitalaria valida
            setBuildHadError(false)
            if(resourcesSelected !== "-- Seleccione el quirófano deseado --"){//Se verifica si se ha seleccionado un quirófano valido
                setResourceHadError(false)
                if(fecha_seleccionada){//Se verifica si se ha seleccionado una fecha valida
                    if(horaInicialSelected !== "-- Seleccione la hora inicial --"){//Se verifica si se ha seleccionado una hora inicial valida
                        setHoraInicialHadError(false)
                        if(horaFinalSelected !== "-- Seleccione la hora final --"){//Se verifica si se ha seleccionado una hora final valida
                            setHoraFinalHadError(false)
                            if(cedula !== ""){
                                setCedulaHadError(false)
                                if(validarNombre(nombrePaciente)){
                                    setNombrePacienteHadError(false)
                                    if(procedimiento !== ""){
                                        setProcedimientoHadError(false)
                                        if(equipoQuirirgico !== "" || true){
                                            //Aqui iria lo nuevo
                                            setEquipoQuirirgicoHadError(false)///^\d{10}$/gm
                                            let expresion = /^\d{10}$/gm
                                            if(expresion.test(telefono) || true){
                                                setTelefonoHadError(false)
                                                let expresionDos = /^\d{10}$/gm
                                                if(expresionDos.test(telefonoPaciente)|| true){
                                                    let isMedicoValido = false
                                                    let nombre = ""
                                                    let cedulaABuscar = cedula
                                                    if(cedula.toString().includes('DGP')){

                                                        let separado = cedula.split('DGP')
                                                        setCedula('DGP ' + separado[1].replace(' ',''))
                                                        cedulaABuscar = 'DGP ' + separado[1].replace(' ','')
                                                    }
                                                    
                                                    const resultado = medicoList.find( uh => uh.doctorInfo.RFC === cedulaABuscar.toString() || 
                                                        uh.doctorInfo.Cedula === cedulaABuscar.toString() || 
                                                        uh.doctorInfo.Cedula === "DGP " + cedulaABuscar.toString() || 
                                                        "DGP " + uh.doctorInfo.Cedula === cedulaABuscar.toString() ||
                                                        uh.doctorInfo.CedulaEspecialidad === cedulaABuscar.toString() || 
                                                        uh.doctorInfo.CedulaEspecialidad === "DGP " + cedulaABuscar.toString() || 
                                                        "DGP " + uh.doctorInfo.CedulaEspecialidad === cedulaABuscar.toString() ||
                                                        uh.doctorInfo.CedulaSubEspecialidad === cedulaABuscar.toString() || 
                                                        uh.doctorInfo.CedulaSubEspecialidad === "DGP " + cedulaABuscar.toString() || 
                                                        "DGP " + uh.doctorInfo.CedulaSubEspecialidad === cedulaABuscar.toString())
                                                    if(resultado){
                                                        if(!resultado.doctorInfo.IsEliminated){
                                                            nombre = resultado.doctorInfo.Nombre
                                                            isMedicoValido = true
                                                        }
                                                    }

                                                    if(!fechaNacimientoHadError){
                                                        if(!fechaSeleccionadaHadError){
                                                            let expresion = /^\d{10}$/gm
                                                            //expresion.test(telefonoPaciente) && telefonoPaciente !== telefono
                                                            if(true){
                                                                setTelefonoPacienteHadError(false)
                                                                if(ladoProcedimiento !== ""){
                                                                    setLadoProcedimientoHadError(false)
                                                                    if(tipoCliente !== "" && opcionCliente !== ""){
                                                                        if(nombreAnestesiologo !== "" && tipoAnestesiologo !== ""){
                                                                            
                                                                            //Aqui el inicio de las nuevas validaciones
                                                                            if(equipoEspecial !== "" || true){
                                                                                setEquipoEspecialHadError(false)
                                                                                if(hemoderivados !== "" || true){
                                                                                    setHemoderivadosHadError(false)
                                                                                    setMessageError("")
                                                                            if(isMedicoValido){
                                                                                //Se muestra dialogo de confirmación con los detalles de la reservación
                                                                                let fechaInicial = fecha_seleccionada.toString().split('-')
                                                                                    
                                                                                let horaInicialSeparada = horaInicialSelected.toString().split(':')
                                                                                let horaFinalSeparada = horaFinalSelected.toString().split(':')
                                                                                let fechaFinal = fecha_seleccionada + "T" + horaFinalSelected
                                                                                let mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " a " + horaFinalSelected + " con fecha " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0]
                                                                                mensaje += " para el paciente " + nombrePaciente
                    
                                                                                if(parseInt(horaFinalSeparada[0]) < parseInt(horaInicialSeparada[0])){//En caso de que la hora final sea menor que la inicial se agrega un día para indicar que se trata del día siguiente
                                                                                    let fechaSeparada = fecha_seleccionada.split('-')
                                                                                    let diaNuevo = parseInt(fechaSeparada[2]) + 1
                                                                                    fechaFinal = diaNuevo + "-" + fechaSeparada[1] + "-" + fechaSeparada[0]
                                                                                    mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " del " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0] + " a " + horaFinalSelected + " del " + fechaFinal
                                                                                    mensaje += " para el paciente " + nombrePaciente
                                                                                }
                                                                                
                                                                                Swal.fire({
                                                                                    title: "Se agenda cirugía de: " + nombre,
                                                                                    text: mensaje,
                                                                                    showDenyButton: false,
                                                                                    showCancelButton: true,
                                                                                    confirmButtonColor: '#14988f',
                                                                                    confirmButtonText: 'Aceptar',
                                                                                    denyButtonText: `Cancelar`,
                                                                                    cancelButtonText:`Cancelar`,
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        //Se solicita el registro del evento
                                                                                        if(nombreAnestesiologo === "Anestesiólogo Staff"){
                                                                    
                                                                                            getReserve(nombre)
                                                                                        }
                                                                                        else{//
                                                                                            const resultado = medicoList.find( uh => uh.doctorInfo.RFC === nombreAnestesiologo.toString() || 
                                                                                                uh.doctorInfo.Cedula === nombreAnestesiologo.toString() || 
                                                                                                uh.doctorInfo.Cedula === "DGP " + nombreAnestesiologo.toString() || 
                                                                                                "DGP " + uh.doctorInfo.Cedula === nombreAnestesiologo.toString() ||
                                                                                                uh.doctorInfo.CedulaEspecialidad === nombreAnestesiologo.toString() || 
                                                                                                uh.doctorInfo.CedulaEspecialidad === "DGP " + nombreAnestesiologo.toString() || 
                                                                                                "DGP " + uh.doctorInfo.CedulaEspecialidad === nombreAnestesiologo.toString() ||
                                                                                                uh.doctorInfo.CedulaSubEspecialidad === nombreAnestesiologo.toString() || 
                                                                                                uh.doctorInfo.CedulaSubEspecialidad === "DGP " + nombreAnestesiologo.toString() || 
                                                                                                "DGP " + uh.doctorInfo.CedulaSubEspecialidad === nombreAnestesiologo.toString() ||
                                                                                                uh.doctorInfo.Nombre.toString().toUpperCase().trim() === nombreAnestesiologo.toString().toUpperCase().trim())

                                                                                            if(resultado){
                                                                                                if(!resultado.doctorInfo.IsEliminated){
                                                                                                    
                                                                                                    getReserve(nombre)
                                                                                                }
                                                                                            }
                                                                                            else{
                                                                                                let uhOpciones = ""
                                                                                                uhOpciones += "<option value="+0+">-- Seleccione la unidad hospitalaria --</option>"
                                                                                                uhList.forEach(uh => {
                                                                                                    if(uh.calendar === buildingSelected){
                                                                                                        uhOpciones+="<option value="+uh.hospital+" selected>"+uh.hospital+"</option>"
                                                                                                    }
                                                                                                    else{
                                                                                                        uhOpciones+="<option value="+uh.hospital+">"+uh.hospital+"</option>"
                                                                                                    }
                                                                                                    
                                                                                                })

                                                                                                Swal.fire({
                                                                                                    customClass:'modalRegistro',
                                                                                                    html:`<div class="form_wrapper">
                                                                                                        <div class="form_container">
                                                                                                            <div class="row clearfix">
                                                                                                                <div class="">
                                                                                                                    <form autocomplete="off" method="post">
                                                                                                                        <h2 class="modalTitle">Médico Anestesiologo Nuevo  (`+nombreAnestesiologo+`)</h2>
                                                                                                                        <br />
                                                                                                                        <div class="input_field">
                                                                                                                            <input type="text" name="inputNombreAnestesiologo" id="inputNombreAnestesiologo" placeholder="Ingrese el nombre del anestesiólogo" 
                                                                                                                            required  class="inputText" autocomplete="off"
                                                                                                                            oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                                                                                                        </div>
                                                                                                                        <div class="input_field select_option">
                                                                                                                            <select id="selectUHAnestesiologo">
                                                                                                                                `+uhOpciones+`
                                                                                                                            </select>
                                                                                                                            <div class="select_arrow"></div>
                                                                                                                        </div>
                                                                                                                        <div class="input_field">
                                                                                                                            <input type="text" name="inputCedulaEspecialidadAnestesiologo" id="inputCedulaEspecialidadAnestesiologo" placeholder="Ingrese la cédula profesional de la especialidad" value = "" 
                                                                                                                            required class="inputText" autocomplete="off"/>
                                                                                                                        </div>
                                                                                                                        <div class="input_field">
                                                                                                                            <input type="text" name="inputTelefonoAnestesiologo" id="inputTelefonoAnestesiologo" placeholder="Ingrese el teléfono del anestesiólogo" 
                                                                                                                            required class="inputText" autocomplete="off" value = ""
                                                                                                                            oninput="this.value=value.replace(/[' ']/g,'')"/>
                                                                                                                        </div>
                                                                                                                        <div class="input_field">
                                                                                                                            <input type="text" name="inputCorreoAnestesiologo" id="inputCorreoAnestesiologo" placeholder="Ingrese correo electrónico del anestesiólogo" 
                                                                                                                            required class="inputText" autocomplete="off"/>
                                                                                                                        </div>
                                                                                                                    </form>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>`,
                                                                                                    showDenyButton: true,
                                                                                                    denyButtonText: "Cancelar",
                                                                                                    denyButtonColor: "grey",
                                                                                                    confirmButtonText: "Aceptar",
                                                                                                    confirmButtonColor: "#04afaa",
                                                                                                    scrollbarPadding:false,
                                                                                                    allowOutsideClick:false,
                                                                                                    width:'500px',
                                                                                                    heightAuto: false,
                                                                                                    focusConfirm: false,
                                                                                                    showCloseButton:true,
                                                                                                    preConfirm: () => {
                                                                                                        if (document.getElementById('inputNombreAnestesiologo').value) {//Se verifica que el campo nombre sea valido
                                                                                                            if (true){
                                                                                                                if (document.getElementById('inputTelefonoAnestesiologo').value){
                                                                                                                    if (document.getElementById('inputTelefonoAnestesiologo').value.length === 10){
                                                                                                                        if (true){
                                                                                                                            var combo = document.getElementById("selectUHAnestesiologo");
                                                                                                                                                var selected = combo.options[combo.selectedIndex].text;

                                                                                                                                                let unidadGardar = ""
                                                    
                                                                                                                                                if(selected === "HMAS Querétaro"){
                                                                                                                                                    unidadGardar = "HMAS Querétaro"
                                                                                                                                                }
                                                                                                                                                else{
                                                                                                                                                    if(selected === "HMAS Los Cabos"){
                                                                                                                                                        unidadGardar = "HMAS Los Cabos"
                                                                                                                                                    }
                                                                                                                                                    else{
                                                                                                                                                        unidadGardar = selected.split('MAC')[1].trim()
                                                                                                                                                    }
                                                                                                                                                }
            
                                                                                                                                                let medicoObject = {
                                                                                                                                                    "Descripcion": "",
                                                                                                                                                    "idTab_DirectorioMedico": "",
                                                                                                                                                    "NumeroTelefonico": "",
                                                                                                                                                    "NumeroCelular": document.getElementById('inputTelefonoAnestesiologo').value,
                                                                                                                                                    "Extension": "",
                                                                                                                                                    "SitioWeb": "",
                                                                                                                                                    "Especialidad": "",
                                                                                                                                                    "Nombre": document.getElementById('inputNombreAnestesiologo').value,
                                                                                                                                                    "Especialidad2": "",
                                                                                                                                                    "Subespecialidad": "",
                                                                                                                                                    "Hospital": unidadGardar,
                                                                                                                                                    "CorreoElectronico": document.getElementById('inputCorreoAnestesiologo').value,
                                                                                                                                                    "Consultorio": "",
                                                                                                                                                    "Cedula": document.getElementById('inputCedulaEspecialidadAnestesiologo').value,
                                                                                                                                                    "RFC": "",
                                                                                                                                                    "CedulaMedicinaGeneral":"",
                                                                                                                                                    "Validate":0
                                                                                                                                                }
            
                                                                                                                                                let inputNombreAnestesiologo = document.getElementById('inputNombreAnestesiologo').value
                                                                                                                                                let correoNuevoMedico = document.getElementById('inputCorreoAnestesiologo').value
                                                                                                                                                let telefonoNuevoMedico = document.getElementById('inputTelefonoAnestesiologo').value
                                                                                                                                                let cedulaNuevoMedico = document.getElementById('inputCedulaEspecialidadAnestesiologo').value
                                                                                                                                                solicitarCreateUser()
                                                                                                                                                                async function solicitarCreateUser(){
                                                                                                                                                                    const response = await createMedico(medicoObject)
                                                                                                                                                                    if(response === "success"){//Registro de usuario exitoso
                                                                                                                                                                        //Se muestra dialogo de confirmación con los detalles de la reservación
                                                                                                                                                                        let fechaInicial = fecha_seleccionada.toString().split('-')
                                                                                                                                                                        
                                                                                                                                                                        let horaInicialSeparada = horaInicialSelected.toString().split(':')
                                                                                                                                                                        let horaFinalSeparada = horaFinalSelected.toString().split(':')
                                                                                                                                                                        let fechaFinal = fecha_seleccionada + "T" + horaFinalSelected
                                                                                                                                                                        let mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " a " + horaFinalSelected + " con fecha " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0]
                                                                                                                                                                        mensaje += " para el paciente " + nombrePaciente
                                                                                                            
                                                                                                                                                                        if(parseInt(horaFinalSeparada[0]) < parseInt(horaInicialSeparada[0])){//En caso de que la hora final sea menor que la inicial se agrega un día para indicar que se trata del día siguiente
                                                                                                                                                                            let fechaSeparada = fecha_seleccionada.split('-')
                                                                                                                                                                            let diaNuevo = parseInt(fechaSeparada[2]) + 1
                                                                                                                                                                            fechaFinal = diaNuevo + "-" + fechaSeparada[1] + "-" + fechaSeparada[0]
                                                                                                                                                                            mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " del " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0] + " a " + horaFinalSelected + " del " + fechaFinal
                                                                                                                                                                            mensaje += " para el paciente " + nombrePaciente
                                                                                                                                                                        }
                                                                                                                                                                        
                                                                                                                                                                        Swal.fire({
                                                                                                                                                                            icon: 'warning',
                                                                                                                                                                            title: "Se agenda cirugía de: " + nombre,
                                                                                                                                                                            text: mensaje,
                                                                                                                                                                            confirmButtonText: "Aceptar",
                                                                                                                                                                            confirmButtonColor: "#04afaa",
                                                                                                                                                                        }).then((result) => {
                                                                                                                                                                            if (result.isConfirmed) {
                                                                                                                                                                                //Se solicita el registro del evento
                                                                                                                                                                                
                                                                                                                                                                                getReserve(nombre)
                                                                                                                                                                                if(nomHospital === "MAC León"){
                                                                                                                                                                                    createMedicoMail(inputNombreAnestesiologo,nomHospital,"jose.lomeli@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                }
                                                                                                                                                                                else{
                                                                                                                                                                                    if(nomHospital === "MAC Celaya"){
                                                                                                                                                                                        createMedicoMail(inputNombreAnestesiologo,nomHospital,"claudia.barrientos@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                    }
                                                                                                                                                                                    else{
                                                                                                                                                                                        if(nomHospital === "MAC Los Mochis"){
                                                                                                                                                                                            createMedicoMail(inputNombreAnestesiologo,nomHospital,"calidad.mochis@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                        }
                                                                                                                                                                                        else{
                                                                                                                                                                                            if(nomHospital === "HMAS Querétaro"){
                                                                                                                                                                                                createMedicoMail(inputNombreAnestesiologo,nomHospital,"elizabethrodriguez@hmasqueretaro.mx",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                            }
                                                                                                                                                                                            else{
                                                                                                                                                                                                if(nomHospital === "MAC La Viga"){
                                                                                                                                                                                                    createMedicoMail(inputNombreAnestesiologo,nomHospital,"liliana.quezada@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                                }
                                                                                                                                                                                                else{
                                                                                                                                                                                                    if(nomHospital === "MediMAC Cuemanco"){
                                                                                                                                                                                                        createMedicoMail(inputNombreAnestesiologo,nomHospital,"berenice.rosales@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else{
                                                                                                                                                                                                        createMedicoMail(inputNombreAnestesiologo,nomHospital,"diana.granados@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                                        createMedicoMail(inputNombreAnestesiologo,nomHospital,"hayde.arellano@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,1)
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                            
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            else{
                                                                                                                                                                                
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    else{//Error en el registro de usuario
                                                                                                                                                                        Swal.fire({
                                                                                                                                                                            icon: 'error',
                                                                                                                                                                            title: 'Error',
                                                                                                                                                                            text: response,
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                
                                                                                                                        }
                                                                                                                        else{
                                                                                                                            Swal.showValidationMessage('Proporcione la cedula de la especialidad') 
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else{
                                                                                                                        Swal.showValidationMessage('Proporcione el teléfono del médico')   
                                                                                                                    }
                                                                                                                }
                                                                                                                else{
                                                                                                                    Swal.showValidationMessage('Proporcione el teléfono del médico')   
                                                                                                                }   
                                                                                                            }
                                                                                                            else{
                                                                                                                Swal.showValidationMessage('Proporcione la dirección de correo')   
                                                                                                            }
                                                                                                        } else {//Nombre de usuario invalido
                                                                                                        Swal.showValidationMessage('Proporcione el nombre del médico')   
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        }//
                                                                                    }
                                                                                })
                                                                            }
                                                                            else{
                                                                                Swal.fire({
                                                                                    title: 'Estimado médico ¿desea registrar sus datos para iniciar su trámite de credencialización y otorgamiento de privilegios médicos?',
                                                                                    showDenyButton: true,
                                                                                    denyButtonColor: "grey",
                                                                                    showCancelButton: false,
                                                                                    confirmButtonText: 'Registrase',
                                                                                    confirmButtonColor: "#04afaa",
                                                                                    denyButtonText: `Cancelar`,
                                                                                }).then((result) => {
                                                                                    /* Read more about isConfirmed, isDenied below */
                                                                                    if (result.isConfirmed) {
                                                                                        let uhOpciones = ""
                                                                                        uhOpciones += "<option value="+0+">-- Seleccione la unidad hospitalaria --</option>"
                                                                                        uhList.forEach(uh => {
                                                                                            if(uh.calendar === buildingSelected){
                                                                                                uhOpciones+="<option value="+uh.hospital+" selected>"+uh.hospital+"</option>"
                                                                                            }
                                                                                            else{
                                                                                                uhOpciones+="<option value="+uh.hospital+">"+uh.hospital+"</option>"
                                                                                            }
                                                                                            
                                                                                        })
    
                                                                                        Swal.fire({
                                                                                            customClass:'modalRegistro',
                                                                                            html:`<div class="form_wrapper">
                                                                                                <div class="form_container">
                                                                                                    <div class="row clearfix">
                                                                                                        <div class="">
                                                                                                            <form autocomplete="off" method="post">
                                                                                                                <h2 class="modalTitle">Médico Nuevo</h2>
                                                                                                                <br />
                                                                                                                <div class="input_field">
                                                                                                                    <input type="text" name="inputNombre" id="inputNombre" placeholder="Ingrese el nombre del médico" 
                                                                                                                    required  class="inputText" autocomplete="off"
                                                                                                                    oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                                                                                                </div>
                                                                                                                <div class="input_field select_option">
                                                                                                                    <select id="selectUH">
                                                                                                                        `+uhOpciones+`
                                                                                                                    </select>
                                                                                                                    <div class="select_arrow"></div>
                                                                                                                </div>
                                                                                                                <div class="input_field">
                                                                                                                    <input type="text" name="inputCedulaEspecialidad" id="inputCedulaEspecialidad" placeholder="Ingrese la cedula profesional de la especialidad" value = "`+cedula+`" 
                                                                                                                    required class="inputText" autocomplete="off"/>
                                                                                                                </div>
                                                                                                                <div class="input_field">
                                                                                                                    <input type="text" name="inputTelefono" id="inputTelefono" placeholder="Ingrese el teléfono del médico" 
                                                                                                                    required class="inputText" autocomplete="off" value = "`+telefono+`"
                                                                                                                    oninput="this.value=value.replace(/[' ']/g,'')"/>
                                                                                                                </div>
                                                                                                                <div class="input_field">
                                                                                                                    <input type="text" name="inputCorreo" id="inputCorreo" placeholder="Ingrese correo electrónico del médico" 
                                                                                                                    required class="inputText" autocomplete="off"/>
                                                                                                                </div>
                                                                                                            </form>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>`,
                                                                                            showDenyButton: true,
                                                                                            denyButtonText: "Cancelar",
                                                                                            denyButtonColor: "grey",
                                                                                            confirmButtonText: "Aceptar",
                                                                                            confirmButtonColor: "#04afaa",
                                                                                            scrollbarPadding:false,
                                                                                            allowOutsideClick:false,
                                                                                            width:'500px',
                                                                                            heightAuto: false,
                                                                                            focusConfirm: false,
                                                                                            showCloseButton:true,
                                                                                            preConfirm: () => {
                                                                                                if (document.getElementById('inputNombre').value) {//Se verifica que el campo nombre sea valido
                                                                                                    if (document.getElementById('inputCorreo').value){
                                                                                                        if (document.getElementById('inputTelefono').value){
                                                                                                            if (document.getElementById('inputTelefono').value.length === 10){
                                                                                                                if (document.getElementById('inputCedulaEspecialidad').value){
                                                                                                                    var combo = document.getElementById("selectUH");
                                                                                                                                        var selected = combo.options[combo.selectedIndex].text;
                                                                                                                                        let unidadGardar = ""
                                                    
                                                                                                                                        if(selected === "HMAS Querétaro"){
                                                                                                                                            unidadGardar = "HMAS Querétaro"
                                                                                                                                        }
                                                                                                                                        else{
                                                                                                                                            if(selected === "HMAS Los Cabos"){
                                                                                                                                                unidadGardar = "HMAS Los Cabos"
                                                                                                                                            }
                                                                                                                                            else{
                                                                                                                                                if(selected === "HMAS Los Cabos"){
                                                                                                                                                    unidadGardar = "HMAS Los Cabos"
                                                                                                                                                }
                                                                                                                                                else{
                                                                                                                                                    unidadGardar = selected.split('MAC')[1].trim()
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
    
                                                                                                                                        let medicoObject = {
                                                                                                                                            "Descripcion": "",
                                                                                                                                            "idTab_DirectorioMedico": "",
                                                                                                                                            "NumeroTelefonico": "",
                                                                                                                                            "NumeroCelular": document.getElementById('inputTelefono').value,
                                                                                                                                            "Extension": "",
                                                                                                                                            "SitioWeb": "",
                                                                                                                                            "Especialidad": "",
                                                                                                                                            "Nombre": document.getElementById('inputNombre').value,
                                                                                                                                            "Especialidad2": "",
                                                                                                                                            "Subespecialidad": "",
                                                                                                                                            "Hospital": unidadGardar,
                                                                                                                                            "CorreoElectronico": document.getElementById('inputCorreo').value,
                                                                                                                                            "Consultorio": "",
                                                                                                                                            "Cedula": document.getElementById('inputCedulaEspecialidad').value,
                                                                                                                                            "RFC": "",
                                                                                                                                            "CedulaMedicinaGeneral":"",
                                                                                                                                            "Validate":0
                                                                                                                                        }
    
                                                                                                                                        let nombreMedico = document.getElementById('inputNombre').value
                                                                                                                                        let cedulaNuevoMedico = document.getElementById('inputCedulaEspecialidad').value
                                                                                                                                        let telefonoNuevoMedico = document.getElementById('inputTelefono').value
                                                                                                                                        let correoNuevoMedico = document.getElementById('inputCorreo').value
                                                                                                                        
                                                                                                                                        solicitarCreateUser()
                                                                                                                                                        async function solicitarCreateUser(){
                                                                                                                                                            const response = await createMedico(medicoObject)
                                                                                                                                                            if(response === "success"){//Registro de usuario exitoso
                                                                                                                                                                //Se muestra dialogo de confirmación con los detalles de la reservación
                                                                                                                                                                let fechaInicial = fecha_seleccionada.toString().split('-')
                                                                                                                                                                
                                                                                                                                                                let horaInicialSeparada = horaInicialSelected.toString().split(':')
                                                                                                                                                                let horaFinalSeparada = horaFinalSelected.toString().split(':')
                                                                                                                                                                let fechaFinal = fecha_seleccionada + "T" + horaFinalSelected
                                                                                                                                                                let mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " a " + horaFinalSelected + " con fecha " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0]
                                                                                                                                                                mensaje += " para el paciente " + nombrePaciente
                                                                                                    
                                                                                                                                                                if(parseInt(horaFinalSeparada[0]) < parseInt(horaInicialSeparada[0])){//En caso de que la hora final sea menor que la inicial se agrega un día para indicar que se trata del día siguiente
                                                                                                                                                                    let fechaSeparada = fecha_seleccionada.split('-')
                                                                                                                                                                    let diaNuevo = parseInt(fechaSeparada[2]) + 1
                                                                                                                                                                    fechaFinal = diaNuevo + "-" + fechaSeparada[1] + "-" + fechaSeparada[0]
                                                                                                                                                                    mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " del " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0] + " a " + horaFinalSelected + " del " + fechaFinal
                                                                                                                                                                    mensaje += " para el paciente " + nombrePaciente
                                                                                                                                                                }
                                                                                                                                                                
                                                                                                                                                                Swal.fire({                               
                                                                                                                                                                    title: 'Solicitud de credencialización',
                                                                                                                                                                    text: 'Sus datos se han registrado con éxito, en breve sera contactado por nuestra área de calidad para continuar su proceso de credencialización, Gracias por su preferencia.',
                                                                                                                                                                    
                                                                                                                                                                    showDenyButton: false,
                                                                                                                                                                    showCancelButton: true,
                                                                                                                                                                    confirmButtonColor: '#14988f',
                                                                                                                                                                    confirmButtonText: 'Aceptar',
                                                                                                                                                                    denyButtonText: `Cancelar`,
                                                                                                                                                                    cancelButtonText:`Cancelar`,
                                                                                                                                                                }).then((result) => {
                                                                                                                                                                    if (result.isConfirmed) {
                                                                                                                                                                        Swal.fire({
                                                                                                                                                                            icon: 'warning',
                                                                                                                                                                            title: "Se agenda cirugía de: " + nombreMedico,
                                                                                                                                                                            text: mensaje,
                                                                                                                                                                            confirmButtonText: "Aceptar",
                                                                                                                                                                            confirmButtonColor: "#04afaa",
                                                                                                                                                                        }).then((result) => {
                                                                                                                                                                            if (result.isConfirmed) {
                                                                                                                                                                                //Se solicita el registro del evento
                                                                                                                                                                                if(nombreAnestesiologo === "Anestesiólogo Staff"){
                                                                                                                                                                                    
                                                                                                                                                                                    getReserve(nombreMedico)
                                                                                                                                                                                    if(nomHospital === "MAC León"){
                                                                                                                                                                                        createMedicoMail(nombreMedico,nomHospital,"jose.lomeli@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                    }
                                                                                                                                                                                    else{
                                                                                                                                                                                        if(nomHospital === "MAC Celaya"){
                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"claudia.barrientos@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                        }
                                                                                                                                                                                        else{
                                                                                                                                                                                            if(nomHospital === "MAC Los Mochis"){
                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"calidad.mochis@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                            }
                                                                                                                                                                                            else{
                                                                                                                                                                                                if(nomHospital === "HMAS Querétaro"){
                                                                                                                                                                                                    createMedicoMail(nombreMedico,nomHospital,"elizabethrodriguez@hmasqueretaro.mx",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                                }
                                                                                                                                                                                                else{
                                                                                                                                                                                                    if(nomHospital === "MAC La Viga"){
                                                                                                                                                                                                        createMedicoMail(nombreMedico,nomHospital,"liliana.quezada@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else{
                                                                                                                                                                                                        if(nomHospital === "MediMAC Cuemanco"){
                                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"berenice.rosales@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                                        }
                                                                                                                                                                                                        else{
                                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"diana.granados@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"hayde.arellano@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,2)
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                                
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                                else{
                                                                                                                                                                                    const resultado = medicoList.find( uh => uh.doctorInfo.RFC === nombreAnestesiologo.toString() || 
                                                                                                                                                                                        uh.doctorInfo.Cedula === nombreAnestesiologo.toString() || 
                                                                                                                                                                                        uh.doctorInfo.Cedula === "DGP " + nombreAnestesiologo.toString() || 
                                                                                                                                                                                        "DGP " + uh.doctorInfo.Cedula === nombreAnestesiologo.toString() ||
                                                                                                                                                                                        uh.doctorInfo.CedulaEspecialidad === nombreAnestesiologo.toString() || 
                                                                                                                                                                                        uh.doctorInfo.CedulaEspecialidad === "DGP " + nombreAnestesiologo.toString() || 
                                                                                                                                                                                        "DGP " + uh.doctorInfo.CedulaEspecialidad === nombreAnestesiologo.toString() ||
                                                                                                                                                                                        uh.doctorInfo.CedulaSubEspecialidad === nombreAnestesiologo.toString() || 
                                                                                                                                                                                        uh.doctorInfo.CedulaSubEspecialidad === "DGP " + nombreAnestesiologo.toString() || 
                                                                                                                                                                                        "DGP " + uh.doctorInfo.CedulaSubEspecialidad === nombreAnestesiologo.toString() ||
                                                                                                                                                                                        uh.doctorInfo.Nombre.toString().toUpperCase().trim() === nombreAnestesiologo.toString().toUpperCase().trim())

                                                                                                                                                                                    if(resultado){
                                                                                                                                                                                        setNombreAnestesiologo(resultado.doctorInfo.Nombre)
                                                                                                                                                                                        
                                                                                                                                                                                        getReserve(nombreMedico)
                                                                                                                                                                                        if(nomHospital === "MAC León"){
                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"jose.lomeli@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                        }
                                                                                                                                                                                        else{
                                                                                                                                                                                            if(nomHospital === "MAC Celaya"){
                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"claudia.barrientos@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                            }
                                                                                                                                                                                            else{
                                                                                                                                                                                                if(nomHospital === "MAC Los Mochis"){
                                                                                                                                                                                                    createMedicoMail(nombreMedico,nomHospital,"calidad.mochis@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                                }
                                                                                                                                                                                                else{
                                                                                                                                                                                                    if(nomHospital === "HMAS Querétaro"){
                                                                                                                                                                                                        createMedicoMail(nombreMedico,nomHospital,"elizabethrodriguez@hmasqueretaro.mx",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else{
                                                                                                                                                                                                        if(nomHospital === "MAC La Viga"){
                                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"liliana.quezada@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                                        }
                                                                                                                                                                                                        else{
                                                                                                                                                                                                            if(nomHospital === "MediMAC Cuemanco"){
                                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"berenice.rosales@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                                            }
                                                                                                                                                                                                            else{
                                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"diana.granados@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"hayde.arellano@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,3)
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                    
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                    else{
                                                                                                                                                                                        let uhOpciones = ""
                                                                                                                                                                                        uhOpciones += "<option value="+0+">-- Seleccione la unidad hospitalaria --</option>"
                                                                                                                                                                                        uhList.forEach(uh => {
                                                                                                                                                                                            if(uh.calendar === buildingSelected){
                                                                                                                                                                                                uhOpciones+="<option value="+uh.hospital+" selected>"+uh.hospital+"</option>"
                                                                                                                                                                                            }
                                                                                                                                                                                            else{
                                                                                                                                                                                                uhOpciones+="<option value="+uh.hospital+">"+uh.hospital+"</option>"
                                                                                                                                                                                            }
                                                                                                                                                                                            
                                                                                                                                                                                        })

                                                                                                                                                                                        Swal.fire({
                                                                                                                                                                                            customClass:'modalRegistro',
                                                                                                                                                                                            html:`<div class="form_wrapper">
                                                                                                                                                                                                <div class="form_container">
                                                                                                                                                                                                    <div class="row clearfix">
                                                                                                                                                                                                        <div class="">
                                                                                                                                                                                                            <form autocomplete="off" method="post">
                                                                                                                                                                                                                <h2 class="modalTitle">Médico Anestesiologo Nuevo (`+nombreAnestesiologo+`)</h2>
                                                                                                                                                                                                                <br />
                                                                                                                                                                                                                <div class="input_field">
                                                                                                                                                                                                                    <input type="text" name="inputNombreAnestesiologo" id="inputNombreAnestesiologo" placeholder="Ingrese el nombre del anestesiólogo" 
                                                                                                                                                                                                                    required  class="inputText" autocomplete="off"
                                                                                                                                                                                                                    oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                <div class="input_field select_option">
                                                                                                                                                                                                                    <select id="selectUHAnestesiologo">
                                                                                                                                                                                                                        `+uhOpciones+`
                                                                                                                                                                                                                    </select>
                                                                                                                                                                                                                    <div class="select_arrow"></div>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                <div class="input_field">
                                                                                                                                                                                                                    <input type="text" name="inputCedulaEspecialidadAnestesiologo" id="inputCedulaEspecialidadAnestesiologo" placeholder="Ingrese la cédula profesional de la especialidad" value = "" 
                                                                                                                                                                                                                    required class="inputText" autocomplete="off"/>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                <div class="input_field">
                                                                                                                                                                                                                    <input type="text" name="inputTelefonoAnestesiologo" id="inputTelefonoAnestesiologo" placeholder="Ingrese el teléfono del anestesiólogo" 
                                                                                                                                                                                                                    required class="inputText" autocomplete="off" value = ""
                                                                                                                                                                                                                    oninput="this.value=value.replace(/[' ']/g,'')"/>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                <div class="input_field">
                                                                                                                                                                                                                    <input type="text" name="inputCorreoAnestesiologo" id="inputCorreoAnestesiologo" placeholder="Ingrese correo electrónico del anestesiólogo" 
                                                                                                                                                                                                                    required class="inputText" autocomplete="off"/>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            </form>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </div>`,
                                                                                                                                                                                            showDenyButton: true,
                                                                                                                                                                                            denyButtonText: "Cancelar",
                                                                                                                                                                                            denyButtonColor: "grey",
                                                                                                                                                                                            confirmButtonText: "Aceptar",
                                                                                                                                                                                            confirmButtonColor: "#04afaa",
                                                                                                                                                                                            scrollbarPadding:false,
                                                                                                                                                                                            allowOutsideClick:false,
                                                                                                                                                                                            width:'500px',
                                                                                                                                                                                            heightAuto: false,
                                                                                                                                                                                            focusConfirm: false,
                                                                                                                                                                                            showCloseButton:true,
                                                                                                                                                                                            preConfirm: () => {
                                                                                                                                                                                                if (document.getElementById('inputNombreAnestesiologo').value) {//Se verifica que el campo nombre sea valido
                                                                                                                                                                                                    if (true){
                                                                                                                                                                                                        if (document.getElementById('inputTelefonoAnestesiologo').value){
                                                                                                                                                                                                            if (document.getElementById('inputTelefonoAnestesiologo').value.length === 10){
                                                                                                                                                                                                                if (true){
                                                                                                                                                                                                                    var combo = document.getElementById("selectUHAnestesiologo");
                                                                                                                                                                                                                                        var selected = combo.options[combo.selectedIndex].text;
                                                                                                                                                                                                                                        let unidadGardar = ""
                                                    
                                                                                                                                                                                                                                        if(selected === "HMAS Querétaro"){
                                                                                                                                                                                                                                            unidadGardar = "HMAS Querétaro"
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        else{
                                                                                                                                                                                                                                            if(selected === "HMAS Los Cabos"){
                                                                                                                                                                                                                                                unidadGardar = "HMAS Los Cabos"
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                            else{
                                                                                                                                                                                                                                                unidadGardar = selected.split('MAC')[1].trim()
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        }

                                                                                                                                                                                                                                        let inputNombreAnestesiologo = document.getElementById('inputNombreAnestesiologo').value
                                                                                                                                                                                                                                        let correoAnestesiologo = document.getElementById('inputCorreoAnestesiologo').value
                                                                                                                                                                                                                                        let telefonoAnestesiologo = document.getElementById('inputTelefonoAnestesiologo').value
                                                                                                                                                                                                                                        let cedulaAnestesiologo = document.getElementById('inputCedulaEspecialidadAnestesiologo').value

                                                                                                    
                                                                                                                                                                                                                                        let medicoObject = {
                                                                                                                                                                                                                                            "Descripcion": "",
                                                                                                                                                                                                                                            "idTab_DirectorioMedico": "",
                                                                                                                                                                                                                                            "NumeroTelefonico": "",
                                                                                                                                                                                                                                            "NumeroCelular": telefonoAnestesiologo,
                                                                                                                                                                                                                                            "Extension": "",
                                                                                                                                                                                                                                            "SitioWeb": "",
                                                                                                                                                                                                                                            "Especialidad": "",
                                                                                                                                                                                                                                            "Nombre": inputNombreAnestesiologo,
                                                                                                                                                                                                                                            "Especialidad2": "",
                                                                                                                                                                                                                                            "Subespecialidad": "",
                                                                                                                                                                                                                                            "Hospital": unidadGardar,
                                                                                                                                                                                                                                            "CorreoElectronico": correoAnestesiologo,
                                                                                                                                                                                                                                            "Consultorio": "",
                                                                                                                                                                                                                                            "Cedula": cedulaAnestesiologo,
                                                                                                                                                                                                                                            "RFC": "",
                                                                                                                                                                                                                                            "CedulaMedicinaGeneral":"",
                                                                                                                                                                                                                                            "Validate":0
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        
                                                                                                    
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        solicitarCreateUser()
                                                                                                                                                                                                                                                        async function solicitarCreateUser(){
                                                                                                                                                                                                                                                            const response = await createMedico(medicoObject)
                                                                                                                                                                                                                                                            if(response === "success"){//Registro de usuario exitoso
                                                                                                                                                                                                                                                                //Se muestra dialogo de confirmación con los detalles de la reservación
                                                                                                                                                                                                                                                                let fechaInicial = fecha_seleccionada.toString().split('-')
                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                let horaInicialSeparada = horaInicialSelected.toString().split(':')
                                                                                                                                                                                                                                                                let horaFinalSeparada = horaFinalSelected.toString().split(':')
                                                                                                                                                                                                                                                                let fechaFinal = fecha_seleccionada + "T" + horaFinalSelected
                                                                                                                                                                                                                                                                let mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " a " + horaFinalSelected + " con fecha " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0]
                                                                                                                                                                                                                                                                mensaje += " para el paciente " + nombrePaciente
                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                if(parseInt(horaFinalSeparada[0]) < parseInt(horaInicialSeparada[0])){//En caso de que la hora final sea menor que la inicial se agrega un día para indicar que se trata del día siguiente
                                                                                                                                                                                                                                                                    let fechaSeparada = fecha_seleccionada.split('-')
                                                                                                                                                                                                                                                                    let diaNuevo = parseInt(fechaSeparada[2]) + 1
                                                                                                                                                                                                                                                                    fechaFinal = diaNuevo + "-" + fechaSeparada[1] + "-" + fechaSeparada[0]
                                                                                                                                                                                                                                                                    mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " del " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0] + " a " + horaFinalSelected + " del " + fechaFinal
                                                                                                                                                                                                                                                                    mensaje += " para el paciente " + nombrePaciente
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                Swal.fire({
                                                                                                                                                                                                                                                                    icon: 'warning',
                                                                                                                                                                                                                                                                    title: "Se agenda cirugía de: " + nombre,
                                                                                                                                                                                                                                                                    text: mensaje,
                                                                                                                                                                                                                                                                    confirmButtonText: "Aceptar",
                                                                                                                                                                                                                                                                    confirmButtonColor: "#04afaa",
                                                                                                                                                                                                                                                                }).then((result) => {
                                                                                                                                                                                                                                                                    if (result.isConfirmed) {
                                                                                                                                                                                                                                                                        //Se solicita el registro del evento
                                                                                                                                                                                                                                                                        setNombreAnestesiologo(inputNombreAnestesiologo)
                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                        getReserve(nombreMedico)
                                                                                                                                                                                                                                                                        if(nomHospital === "MAC León"){
                                                                                                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"jose.lomeli@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                            createMedicoMail(inputNombreAnestesiologo,nomHospital,"jose.lomeli@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                        else{
                                                                                                                                                                                                                                                                            if(nomHospital === "MAC Celaya"){
                                                                                                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"claudia.barrientos@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                createMedicoMail(inputNombreAnestesiologo,nomHospital,"claudia.barrientos@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                            else{
                                                                                                                                                                                                                                                                                if(nomHospital === "MAC Los Mochis"){
                                                                                                                                                                                                                                                                                    createMedicoMail(nombreMedico,nomHospital,"calidad.mochis@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                    createMedicoMail(inputNombreAnestesiologo,nomHospital,"calidad.mochis@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                else{
                                                                                                                                                                                                                                                                                    if(nomHospital === "HMAS Querétaro"){
                                                                                                                                                                                                                                                                                        createMedicoMail(nombreMedico,nomHospital,"elizabethrodriguez@hmasqueretaro.mx",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                        createMedicoMail(inputNombreAnestesiologo,nomHospital,"elizabethrodriguez@hmasqueretaro.mx",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                    else{
                                                                                                                                                                                                                                                                                        if(nomHospital === "MAC La Viga"){
                                                                                                                                                                                                                                                                                            createMedicoMail(nombreMedico,nomHospital,"liliana.quezada@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                            createMedicoMail(inputNombreAnestesiologo,nomHospital,"liliana.quezada@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                        else{
                                                                                                                                                                                                                                                                                            if(nomHospital === "MediMAC Cuemanco"){
                                                                                                                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"berenice.rosales@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                                createMedicoMail(inputNombreAnestesiologo,nomHospital,"berenice.rosales@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                            else{
                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"diana.granados@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                                createMedicoMail(nombreMedico,nomHospital,"hayde.arellano@hospitalesmac.com",cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today,4)
                                                                                                                                                                                                                                                                                                createMedicoMail(inputNombreAnestesiologo,nomHospital,"diana.granados@hospitalesmac.com",cedulaAnestesiologo,telefonoAnestesiologo,correoAnestesiologo,today,4)
                                                                                                                                                                                                                                                                                                createMedicoMail(inputNombreAnestesiologo,nomHospital,"hayde.arellano@hospitalesmac.com",cedulaAnestesiologo,telefonoAnestesiologo,correoAnestesiologo,today,4)
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                })
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            else{//Error en el registro de usuario
                                                                                                                                                                                                                                                                Swal.fire({
                                                                                                                                                                                                                                                                    icon: 'error',
                                                                                                                                                                                                                                                                    title: 'Error',
                                                                                                                                                                                                                                                                    text: response,
                                                                                                                                                                                                                                                                })
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                }
                                                                                                                                                                                                                else{
                                                                                                                                                                                                                    Swal.showValidationMessage('Proporcione la cedula de la especialidad') 
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                            else{
                                                                                                                                                                                                                Swal.showValidationMessage('Proporcione el teléfono del médico')   
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                        else{
                                                                                                                                                                                                            Swal.showValidationMessage('Proporcione el teléfono del médico')   
                                                                                                                                                                                                        }   
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else{
                                                                                                                                                                                                        Swal.showValidationMessage('Proporcione la dirección de correo')   
                                                                                                                                                                                                    }
                                                                                                                                                                                                } else {//Nombre de usuario invalido
                                                                                                                                                                                                Swal.showValidationMessage('Proporcione el nombre del médico')   
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        })
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                                //createMedicoMail(nombreMedico,hospitalSeleccionado,correoCalidad,cedulaNuevoMedico,telefonoNuevoMedico,correoNuevoMedico,today)
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                            else{//Error en el registro de usuario
                                                                                                                                                                Swal.fire({
                                                                                                                                                                    icon: 'error',
                                                                                                                                                                    title: 'Error',
                                                                                                                                                                    text: response,
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                        
                                                                                                                }
                                                                                                                else{
                                                                                                                    Swal.showValidationMessage('Proporcione la cedula de la especialidad') 
                                                                                                                }
                                                                                                            }
                                                                                                            else{
                                                                                                                Swal.showValidationMessage('Proporcione el teléfono del médico')   
                                                                                                            }
                                                                                                        }
                                                                                                        else{
                                                                                                            Swal.showValidationMessage('Proporcione el teléfono del médico')   
                                                                                                        }   
                                                                                                    }
                                                                                                    else{
                                                                                                        Swal.showValidationMessage('Proporcione la dirección de correo')   
                                                                                                    }
                                                                                                } else {//Nombre de usuario invalido
                                                                                                Swal.showValidationMessage('Proporcione el nombre del médico')   
                                                                                                }
                                                                                            }
                                                                                        })
    
                                                                                    } else if (result.isDenied) {
                                                                                    //Swal.fire('Changes are not saved', '', 'info')
                                                                                    }
                                                                                })
                                                                            }
                                                                            //Aqui el final de las nuevas validaciones
                                                                            
                                                                            }
                                                                            else{
                                                                                setHemoderivadosHadError(true)
                                                                                setMessageError("Favor de ingresar los hemoderivados requeridos")
                                                                            }
                                                                            }
                                                                            else{
                                                                                setEquipoEspecialHadError(true)
                                                                                setMessageError("Favor de ingresar el equipo especial requerido")
                                                                            }
                                                                        }
                                                                        else{
                                                                            setNombreAnestesiologoHadError(true)
                                                                            setMessageError("Favor de seleccionar e ingresar el tipo de anestesiologo")
                                                                        }
                                                                    }
                                                                    else{
                                                                        setTipoClienteHadError(true)
                                                                        setMessageError("Favor de seleccionar e ingresar el tipo de cliente")
                                                                    }
                                                                }
                                                                else{
                                                                    setLadoProcedimientoHadError(true)
                                                                    setMessageError("Favor de ingresar el lado del procedimiento")
                                                                }
                                                            }
                                                            else{
                                                                setTelefonoPacienteHadError(true)
                                                                setMessageError("Favor de ingresar el teléfono de contacto del paciente, está información se utilizará para contactar al paciente y realizar el pre-registro, con el objetivo de tener un ingreso ágil")
                                                            }
                                                        }
                                                        else{
                                                            setMessageError("Ha seleccionado una fecha invalida")
                                                        }
                                                    }
                                                    else{
                                                        setMessageError("Ha seleccionado una fecha de nacimiento invalida")
                                                    }
                                                    
                                                }
                                                else{
                                                    setTelefonoPacienteHadError(true)
                                                    setMessageError("Favor de ingresar el teléfono de contacto del paciente, está información se utilizará para contactar al paciente y realizar el pre-registro, con el objetivo de tener un ingreso ágil")
                                                }
                                            }
                                            else{
                                                setTelefonoHadError(true)
                                                setMessageError("Favor de ingresar el teléfono de contacto del médico tratante")
                                            }
                                        }
                                        else{
                                            setEquipoQuirirgicoHadError(true)
                                            setMessageError("Favor de ingresar los materiales o medicamentos requeridos")
                                        }
                                    }
                                    else{
                                        setProcedimientoHadError(true)
                                        setMessageError("Favor de ingresar el procedimiento a realizar")
                                    }
                                }
                                else{
                                    setNombrePacienteHadError(true)
                                    setMessageError("Favor de ingresar el nombre del paciente valido")
                                }
                            }
                            else{
                                setCedulaHadError(true)
                                setMessageError("Favor de ingresar la cedula profesional del médico tratante")
                            }
                        }
                        else{//Se muestra el error de hora final requerida
                            setHoraFinalHadError(true)
                            setMessageError("Favor de seleccionar la hora final de la cirugía")
                        }
                    }
                    else{//Se muestra el error de hora inicial requerida
                        setHoraInicialHadError(true)
                        setMessageError("Favor de seleccionar la hora inicial de la cirugía")
                    }
                }
                else{//Se muestra el error de fecha requerida
                    setMessageError("Favor de seleccionar la fecha de la cirugía")
                }
            }
            else{//Se muestra el error de quirófano requerido
                setResourceHadError(true)
                setMessageError("Favor de seleccionar el Quirófano deseado")
            }
        }
        else{//Se muestra el error de unidad hospitalaria requerida
            setBuildHadError(true)
            setMessageError("Favor de seleccionar la Unidad Hospitalaria")
        }
    }


    const getReserve = async (nombre) =>{
        try{
            /**
             * Se da formato a los parametros correspondientes a las fechas (actual,inical y final)
             */
            let date = new Date()
            let today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
            let result = resourcesList.find( resource => resource.Resource_Name === resourcesSelected && resource.Building_Id === buildingSelected)
            let horaInicialSeparada = horaInicialSelected.toString().split(':')
            let horaFinalSeparada = horaFinalSelected.toString().split(':')
            let fechaFinal = fecha_seleccionada + "T" + horaFinalSelected

            if(parseInt(horaFinalSeparada[0]) < parseInt(horaInicialSeparada[0])){//En caso de que la hora final sea menor que la inicial se agrega un día para indicar que se trata del día siguiente
                let fechaSeparada = fecha_seleccionada.split('-')
                let diaNuevo = parseInt(fechaSeparada[2]) + 1
                fechaFinal = fechaSeparada[0] + "-" + fechaSeparada[1] + "-" + diaNuevo + "T" + horaFinalSelected

            }

            //Solicitud de registro de un evento
            let solicitudBody = "AppointmentType=" + result.Resource_Name
            solicitudBody += "|Medico=" + nombre + "(" + cedula + ")" 
            solicitudBody += "|Paciente=" + nombrePaciente
            solicitudBody += "|Procedimiento=" + procedimiento
            solicitudBody += "|Equipo Quirúrgico=NA" 
            if(hadExtras){
                if(extras){
                    solicitudBody += "|Extras=NA"
                }
                else{
                    solicitudBody += "|Extras=NA"
                }
            }
            else{
                solicitudBody += "|Extras=NA"
            }
            solicitudBody += "|Telefono Medico=" + telefono
            solicitudBody += "|Telefono Paciente=" + telefonoPaciente
            solicitudBody += "|Unidad Hospitalaroa=" + buildingSelected

            let duracion = parseInt(horaFinalSeparada[0]) - parseInt(horaInicialSeparada[0])
            if(duracion < 0){
                duracion = duracion + 24
            }
            solicitudBody += "|Duracion=" + duracion
            if(ladoProcedimiento){
                solicitudBody += "|LadoProcedimiento=" + ladoProcedimiento
            }
            else{
                solicitudBody += "|LadoProcedimiento=NA"
            }
            if(nombreAnestesiologo){
                solicitudBody += "|Anestesiologo=" + nombreAnestesiologo
            }
            else{
                solicitudBody += "|Anestesiologo=NA"
            }
            if(tipoAnestesia){
                solicitudBody += "|TipoAnestesia=" + tipoAnestesia
            }
            else{
                solicitudBody += "|TipoAnestesia=NA"
            }
            if(fecha_nacimiento){
                solicitudBody += "|FechaNacimientoPaciente=" + fecha_nacimiento
            }
            else{
                solicitudBody += "|FechaNacimientoPaciente=NA"
            }
            if(edad){
                solicitudBody += "|EdadPaciente=" + edad
            }
            else{
                solicitudBody += "|EdadPaciente=NA"
            }
            if(sexo){
                solicitudBody += "|SexoPaciente=" + sexo
            }
            else{
                solicitudBody += "|SexoPaciente=NA"
            }
            if(tipoCliente){
                solicitudBody += "|TipoCliente=" + tipoCliente
            }
            else{
                solicitudBody += "|TipoCliente=NA"
            }
            if(diagnostico){
                solicitudBody += "|Diagnostico=" + diagnostico
            }
            else{
                solicitudBody += "|Diagnostico=NA"
            }
            solicitudBody += "|Solicitado=" + userName
            if(fecha_seleccionada === yesterday){
                solicitudBody += "|Concepto=Cirugía de urgencia"
            }
            else{
                solicitudBody += "|Concepto=Cirugía de urgencia"
            }
            if(estudios){
                solicitudBody += "|EstudiosTransoperatorios=" + estudios
            }
            else{
                solicitudBody += "|EstudiosTransoperatorios=NA"
            }
            if(opcionCliente){
                solicitudBody += "|TipoClienteOpcion=" + opcionCliente
            }
            else{
                solicitudBody += "|TipoClienteOpcion=NA"
            }
            if(tipoAnestesiologo){
                solicitudBody += "|TipoAnestesiologo=" + tipoAnestesiologo
            }
            else{
                solicitudBody += "|TipoAnestesiologo=NA"
            }
            solicitudBody += "|EquipoEspecial=NA"
            solicitudBody += "|Hemoderivados=NA"
            solicitarEvento()

            /**
             * Función asyncrona que solicita el registro de un evento
             */
            async function solicitarEvento(){
                const response = await createEventIntent(
                    solicitudBody,
                    fecha_seleccionada + "T" + horaInicialSelected,
                    "Site",
                    today,
                    result.Building_Id,
                    result.Resource_Id,
                    result.Resource_Name,
                    fechaFinal
                )

                if(response === "success"){//Registro de evento exitoso
                    Swal.fire({
                        icon: 'warning',
                        title: 'Cirugía registrada',
                        text: 'Se ha registrado de manera exitosa la cirugía',
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#04afaa",
                      }).then((result) => {
                        if (result.isConfirmed) {
                            //Se solicita el registro del evento
                            setCedula("")
                            setTelefono("")
                            setNombrePaciente("")
                            setTelefonoPaciente("")
                            setFechaNacimiento("")
                            setEdad("")
                            setTipoCliente("")
                            setDiagnostico("")
                            setProcedimiento("")
                            setLadoProcedimiento("")
                            setNombreAnestesiologo("")
                            setTipoAnestesia("")
                            setEquipoQuirurgico("")
                            setHadExtras(false)
                            setExtras("")
                            window.location.reload()
                        }
                    })
                }
                else{//Error en el registro de un evento
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response,
                      })
                }
            }
            
        }
        catch(error){//Error inesperado
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                button: "Aceptar",
            });
        }
    }

    return(
        <div className="reserve_form">
            <p className="reserve_title">Agendar Cirugía De Urgencia  <span onClick={handleCancelarClick()}><BsXSquare /></span></p>
            <div className={buildHadError ? "contenedorError":"contenedor"} id="contenedorBuilding">
                <div className="contenedorEncabezado">
                    ¿Dónde quiere reservar?<span className="requerido">*</span>
                </div>
                <div className="contenido">
                    <select value={buildingSelected} 
                        onChange={(e)=>handleBuildChange(e.target.value)} 
                        className="datos">
                        {
                            buildingList.map(
                                        build => <option key={build.id} value={build.Building_Id}>{build.Building_Id}</option>
                            )
                        }
                    </select>
                </div>
            </div>
            <div className={resourceHadError ? "contenedorError":"contenedor"} id="contenedorResources">
                        <div className="contenedorEncabezado">
                            En el quirófano<span className="requerido">*</span>
                        </div>
                        <div className="contenido">
                            <select value={resourcesSelected} onChange={(e)=>handleResourceChange(e.target.value)} className="datos">
                                <option key={"0"} value={"-- Seleccione el quirófano deseado --"}>{"-- Seleccione el quirófano deseado --"}</option>
                                {
                                    resourcesList.map(
                                        resource => <option key={resource.id} value={resource.Resource_Name}>{resource.Resource_Name}</option>
                                    )
                                }
                            </select>
                        </div>
            </div>
            <div className={cedulaHadError ? "contenedorError":"contenedor"} id="contenedorMedico">
                        <div className="contenedorEncabezado">
                            Cédula del médico tratante<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="cedula" 
                            name="cedula" 
                            className="datos"
                            value={cedula}
                            onChange={onChangeCedula}/>
            </div>
            {/* <div className={telefonoHadError ? "contenedorError":"contenedor"} id="contenedorTelefonoMedico">
                        <div className="contenedorEncabezado">
                            Teléfono de contacto del médico tratante<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="telefono" 
                            name="telefono" 
                            className="datos"
                            value={telefono}
                            onChange={onChangeTelefono}/>
            </div> */}
            <div className={nombrePaciennteHadError ? "contenedorError":"contenedor"} id="contenedorNombrePaciente">
                        <div className="contenedorEncabezado">
                            Nombre del paciente<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="nombrePaciente" 
                            name="nombrePaciente" 
                            className="datos"
                            value={nombrePaciente}
                            onChange={onChangeNombrePaciente}/>
            </div>
            {/* <div className={telefonoPacienteHadError ? "contenedorError":"contenedor"} id="contenedorTelefonoPaciente">
                        <div className="contenedorEncabezado">
                            Teléfono de contacto del paciente<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="telefonoPaciente" 
                            name="telefonoPaciente" 
                            className="datos"
                            value={telefonoPaciente}
                            onChange={onChangeTelefonoPaciente}/>
            </div> */}
            <div className={fechaNacimientoHadError ? "contenedorError":"contenedor"} id="contenedorFechaNacimiento">
                        <div className="contenedorEncabezado">
                            Fecha de nacimiento<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"date"} 
                            id="fecha_nacimiento" 
                            name="fecha_nacimiento" 
                            className="fecha_seleccionada"
                            value={fecha_nacimiento}
                            max={today}
                            onChange={onChangeNacimientoDate}/>
            </div>
            <div className="contenedor" id="contenedorEdad">
                        <div className="contenedorEncabezado">
                            Edad del paciente
                        </div>
                        <input 
                            type={"text"} 
                            id="edad" 
                            name="edad" 
                            className="datos"
                            value={edad}
                            onChange={onChangeEdad}/>
            </div>
            <div className="contenedor" id="contenedorSexo">
                        <div className="contenedorEncabezado">
                            Sexo del paciente
                        </div>
                        <div onChange={onChangeSexo} className="datos">
                            <input type="radio" value="Masculino" name="gender" /> Masculino
                            <input type="radio" value="Femenino" name="gender" /> Femenino
                        </div>
            </div>
            <div className={tipoClienteHadError ? "contenedorError":"contenedor"} id="contenedorTipoCliente">
                        <div className="contenedorEncabezado">
                            Tipo de cliente<span className="requerido">*</span>
                        </div>
                        <div onChange={onChangeTypeCliente} className="datos">
                            <input type="radio" value="Gobierno" name="clienteType" /> Gobierno
                            <input type="radio" value="Privado" name="clienteType" /> Privado
                            <br/>
                            <input type="radio" value="Aseguradora" name="clienteType" /> Aseguradora
                            <input type="radio" value="Empresa" name="clienteType" /> Empresa
                        </div>
                        <input 
                            type={"text"} 
                            id="tipoCliente" 
                            name="tipoCliente" 
                            className="datos"
                            value={tipoCliente}
                            onChange={onChangeTipoCliente}/>
            </div>
            {/* <div className="contenedor" id="contenedorDiagnostico">
                        <div className="contenedorEncabezado">
                            Diagnóstico del paciente (Catálogo CIE-10)
                        </div>
                        <div className="text-container">
                            <textarea
                                id="diagnostico" 
                                name="diagnostico" 
                                className="datos"
                                value={diagnostico}
                                onChange={onChangeDiagnostico}
                                rows="3"
                            />
                            {
                                seMuestra ? <span className='clearBtn' onClick={handleCancelarBusquedaClick()}>X</span>
                                : null
                            } 
                        </div>
                            <ul>
                                {resultList.map((res) => {
                                    return <li key={res.id} onClick={(e)=>handleDiagnosticoChange(res.NOMBRE)} onSelect={(e)=>handleDiagnosticoChange(res.NOMBRE)} onMouseDown={(e)=>handleDiagnosticoChange(res.NOMBRE)}>{res.NOMBRE}</li>
                                })}
                            </ul>
            </div> */}
            <div className={procedimientoHadError ? "contenedorError":"contenedor"} id="contenedorProcedimiento">
                        <div className="contenedorEncabezado">
                            Procedimiento a realizar (Catálogo CIE-9)<span className="requerido">*</span>
                        </div>
                        <div className="text-container">
                            <textarea
                                id="procedimiento" 
                                name="procedimiento" 
                                className="datos"
                                value={procedimiento}
                                onChange={onChangeProcedimiento}
                                rows="3"
                            />
                            {
                                seMuestraProdecimiento ? <span className='clearBtn' onClick={handleCancelarProcedimientoClick()}>X</span>
                                : null
                            } 
                        </div>
                            <ul>
                                {resultProcedimientoList.map((res) => {
                                    return <li key={res.CATALOG_KEY} onClick={(e)=>handleProcedimientosChange(res.PRO_NOMBRE)} onSelect={(e)=>handleProcedimientosChange(res.PRO_NOMBRE)} onMouseDown={(e)=>handleProcedimientoChange(res.PRO_NOMBRE)}>{res.PRO_NOMBRE}</li>
                                })}
                            </ul>
            </div>
            <div className={ladoProcedimientoHadError ? "contenedorError":"contenedor"} id="contenedorLadoProcedimiento">
                        <div className="contenedorEncabezado">
                            Lado del procedimiento<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="ladoProcedimiento" 
                            name="ladoProcedimiento" 
                            className="datos"
                            value={ladoProcedimiento}
                            onChange={onChangeLadoProcedimiento}/>
            </div>
            <div className={nombreAnestesiologoHadError ? "contenedorError":"contenedor"} id="contenedorNombreAnestesiologo">
                        <div className="contenedorEncabezado">
                            Seleccione el tipo de anestesiólogo<span className="requerido">*</span>
                        </div>
                        <div onChange={onChangeTipoAnestesiologo} className="datos">
                            <input type="radio" value="Staff" name="anestesiologo" /> Anestesiólogo Staff
                            <br/>
                            <input type="radio" value="Externo" name="anestesiologo" /> Anestesiólogo Externo
                        </div>
                        {
                            isAnestesiologoExterno
                                ?
                                    <>
                                        <br/>
                                        <span className="requeridoFecha">Ingresar Nombre del Anestesiólogo o Cédula Profesional</span>
                                        <input 
                                            type={"text"} 
                                            id="nombreAnestesiologo" 
                                            name="nombreAnestesiologo" 
                                            className="datos"
                                            value={nombreAnestesiologo}
                                            onChange={onChangeNombreAnestesiologo}/>
                                    </>
                                    
                                :
                                    null
                        }
            </div>
            <div className="contenedor" id="contenedorTipoAnestesia">
                        <div className="contenedorEncabezado">
                            Tipo de anestesia
                        </div>
                        <input 
                            type={"text"} 
                            id="tipoAnestesia" 
                            name="tipoAnestesia" 
                            className="datos"
                            value={tipoAnestesia}
                            onChange={onChangeTipoAnestesia}/>
            </div>
            <div className={fechaSeleccionadaHadError ? "contenedorError":"contenedor"} id="contenedorFecha">
                        <div className="contenedorEncabezado">
                            Día de la cirugía<span className="requerido">*</span>
                        </div>
                        {
                            mostrarMensajeFecha
                                ?
                                    <>
                                        <span className="requeridoFecha">Seleccionar la fecha de cirugía</span>
                                        <br/>
                                    </>
                                :
                                    null
                        }
                        <input 
                            type={"date"} 
                            id="fecha_seleccionada" 
                            name="fecha_seleccionada" 
                            className="fecha_seleccionada"
                            value={fecha_seleccionada}
                            min={rangoInicial}
                            //max={today}
                            onChange={onChangeDate}/>
            </div>
            <div className={horaInicialHadError ? "contenedorError":"contenedor"} id="contenedorHoraInicial">
                        <div className="contenedorEncabezado">
                            Hora inicial<span className="requerido">*</span>
                        </div>
                        <div id="horaInicialSelected" className="contenedor_item_show" onClick={mostrarOcultarHoraInicial}>
                            {horaInicialSelected}
                        </div>
                        {
                            clicHoraInicialSelected
                                ?
                                    <>
                                        <span id="horaInicialDownArrow" className="arrow" onClick={mostrarOcultarHoraInicial}><AiFillCaretUp/></span>
                                        <div className="wrapper">
                                            {
                                                horasInicialesDisponibles.map(
                                                    hora => <div key={hora.hora + ":" + hora.minutos} className="wrapper_element" onClick={seleccionarHoraInicial(hora.hora + ":" + hora.minutos)}>{hora.hora + ":" + hora.minutos}</div>
                                                )
                                            }
                                        </div>
                                    </>
                                :
                                    <span id="horaInicialDownArrow" className="arrow" onClick={mostrarOcultarHoraInicial}><AiFillCaretDown /></span>
                        }
            </div>
            <div className={horaFinalHadError ? "contenedorError":"contenedor"} id="contenedorHoraFinal">
                        <div className="contenedorEncabezado">
                            Hora Final<span className="requerido">*</span>
                        </div>
                        <div id="horaFinalSelected" className="contenedor_item_show" onClick={mostrarOcultarHoraFinal}>
                            {horaFinalSelected}
                        </div>
                        {
                            clicHoraFinalSelected
                                ?
                                    <>
                                        <span id="horaFinalDownArrow" className="arrow" onClick={mostrarOcultarHoraFinal}><AiFillCaretUp/></span>
                                        <div className="wrapper">
                                            {
                                                horasFinalesDisponibles.map(
                                                    hora => <div key={hora.hora + ":" + hora.minutos} className="wrapper_element" onClick={seleccionarHoraFinal(hora.hora + ":" + hora.minutos)}>{hora.hora + ":" + hora.minutos}</div>
                                                )
                                            }
                                        </div>
                                    </>
                                :
                                    <span id="horaFinalDownArrow" className="arrow" onClick={mostrarOcultarHoraFinal}><AiFillCaretDown /></span>
                        }
            </div>
            {/* <div className={equipoQuirirgicoHadError ? "contenedorError":"contenedor"} id="contenedorEquipoQuirigico">
                        <div className="contenedorEncabezado">
                            Materiales y medicamentos<span className="requerido">*</span>
                        </div>
                        <textarea
                            className="datos"
                            id="equipoQuirirgico" 
                            name="equipoQuirirgico"
                            value={equipoQuirirgico}
                            onChange={onChangeEquipoQuirurgico}
                            rows="4"
                        />
            </div>
            <div className={equipoEspecialHadError ? "contenedorError":"contenedor"} id="contenedorEquipoQuirigico">
                        <div className="contenedorEncabezado">
                            Equipo especial<span className="requerido">*</span>
                        </div>
                        <textarea
                            className="datos"
                            id="equipoEspecial" 
                            name="equipoEspecial"
                            value={equipoEspecial}
                            onChange={onChangeEquipoEspecial}
                            rows="4"
                        />
            </div>
            <div className={hemoderivadosHadError ? "contenedorError":"contenedor"} id="contenedorEquipoQuirigico">
                        <div className="contenedorEncabezado">
                            Hemoderivados<span className="requerido">*</span>
                        </div>
                        <textarea
                            className="datos"
                            id="hemoderivados" 
                            name="hemoderivados"
                            value={hemoderivados}
                            onChange={onChangeHemoderivados}
                            rows="4"
                        />
            </div>
            <div className={"contenedor"} id="contenedorEstudios">
                        <div className="contenedorEncabezado">
                            <input type="checkbox" id="checkboxEstudios" checked={hadEstudios} onChange={onCheckEstudios}/>
                            Estudios transoperatorios
                        </div>
                        {
                            hadEstudios
                                ?
                                    <textarea
                                        className="datos"
                                        id="estudios" 
                                        name="estudios" 
                                        value={estudios}
                                        onChange={onChangeEstudios}
                                        rows="4"
                                    />
                                :
                                    null
                        }
            </div>
            <div className={"contenedor"} id="contenedorExtras">
                        <div className="contenedorEncabezado">
                            <input type="checkbox" id="checkbox" checked={hadExtras} onChange={onCheckExtras}/>
                            ¿Tiene algún requerimiento extra?
                        </div>
                        {
                            hadExtras
                                ?
                                    <textarea
                                        className="datos"
                                        id="extras" 
                                        name="extras" 
                                        value={extras}
                                        onChange={onChangeExtras}
                                        rows="4"
                                    />
                                :
                                    null
                        }
            </div> */}
            <p className='fail' id="fail">{messageError}</p>
            <p className='boton_uno' id='enviar' onClick={handleReservaClick()}>Guardar</p>
        </div>
    )

    
}

export default RegitrarEvento