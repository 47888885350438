/**
 * Librerias
 */
import React, { useEffect, useState } from "react";

/**
 * Componente que muestra las unidades hospitalarias disponibles
 * @param {Object} param0 Recibe los elementos necesarios para mostrar las unidades hospitalarias
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const BuildingElement = ({build,buildingSelected,setBuildingSelected,mostrarOcultarBuilding}) => {

    //Estado que indica si la unidad hospitalaria corresponde con la seleccionada por el usuario
    const [isSelected,setIsSelected] = useState(false)

    useEffect(()=>{
        if(build.Building_Id === buildingSelected){//Se verifica si la unidad hospitalaria corresponde con la seleccionada por el usuario
            setIsSelected(true)
        }
        else{
            setIsSelected(false)
        }
    },[build,buildingSelected])

    /**
     * Función que atrapa la selección de una unidad hospitalaria
     * @param {String} Building_Id Recibe el identificador de la unidad hospitalaria
     * @returns Devuelve el estado del objeto
     */
    const seleccionarBuilding = (Building_Id) => (event) => {
        event.preventDefault()
        //Se asigna la unidad hospitalaria seleccionada y se oculta la lista de unidades
        setBuildingSelected(Building_Id)
        mostrarOcultarBuilding()
    }

    return (
        <>
            {
                isSelected
                    ?
                        <div className="contenedor_item_selected" onClick={seleccionarBuilding(build.Building_Id)}>
                            <p>{build.Building_Id}</p>
                        </div>
                    :
                        <div className="reservar_contenedor_item" onClick={seleccionarBuilding(build.Building_Id)}>
                            <p>{build.Building_Id}</p>
                        </div>
            }
        </>
    )
}

export default BuildingElement;