/**
 * Librerías
 */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"

/**
 * Objects
 */
//Contiene la interacción a la base de datos del objeto de tipo resource
import { updateResource } from "../objects/resource";


/**
 * Componente que contiene la información y funcionalidad asignada a un quirófano
 * @param {Object} param0 Recibe los objetos relacionados con el tipo resource y permiten su interacción con el usuario
 * @returns Component Devuelve el componente con los elementos y funcionalidad requeridos
 */
const ResourceElement = ({resource,resourcesSelected,isReadOnly}) => {
    
    //Estado que indica si el quirófano corresponde con el seleccionado por el usuario
    const [isSelected,setIsSelected] = useState(false)

    useEffect(()=>{
        if(resource.Activo){//Se verifica si el quirófano se encuentra activo
            setIsSelected(true)
        }
        else{
            setIsSelected(false)
        }
    },[resource,resourcesSelected])

    /**
     * Función que atrapa la selección de un quirófano
     * @param {String} Resource_Name Recibe el identificador del quirófano
     * @returns Devuelve el estado del objeto
     */
    const seleccionarResource = (resource) => (event) => {
        event.preventDefault()
        //Se asigna el quirófano seleccionado y se oculta la lista de resources
        if(!isReadOnly){
            let mensaje = ""
            let title = ""
            let active = true
            if(resource.Activo){
                title = "Inhabilitar Recurso"
                mensaje = "¿Desea inhabilitar el recurso " + resource.Resource_Name + "?"
                active = false
            }
            else{
                title = "Habilitar Recurso"
                mensaje = "¿Desea habilitar el recurso " + resource.Resource_Name + "?"
                active = true
            }
            Swal.fire({
                title: title,
                text: mensaje,
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonColor: '#14988f',
                confirmButtonText: 'Aceptar',
                denyButtonText: `Cancelar`,
                cancelButtonText:`Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    //Se solicita la actualización del recurso
                    solicitarActualizarResource()
                    async function solicitarActualizarResource(){
                        const response = await updateResource(resource.id,active)

                        if(response === "success"){//Actualización de usuario exitosa
                            Swal.fire({
                                icon: 'success',
                                title: 'Actualización de quirófano',
                                text: 'Quirófano actualizado de manera exitosa',
                                confirmButtonText: "Aceptar",
                                confirmButtonColor: "#04afaa",
                            })
                        }
                        else{//Error en la actualización de usuario
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response,
                            })
                        }
                    }
                }
            })
        }
    }

    
    return(
        <>
            {
                isSelected
                    ?
                        <div className="contenedor_item_selected" onClick={seleccionarResource(resource)}>
                            <p>{resource.Resource_Name}</p>
                        </div>
                    :
                        <div className="reservar_contenedor_item_inactivo" onClick={seleccionarResource(resource)}>
                            <p>{resource.Resource_Name}</p>
                        </div>
            }
        </>
    )
}

export default ResourceElement;