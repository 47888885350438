/**
* Función que compara que compara si el valor de dos objetos tiene mayor peso dependiente del atributo deseado
* @param {string} attr Nombre del atributo que se desea evaluar
* @param {object} obj1 Primer objeto que se desea evaluar
* @param {object} obj2 Segundo objeto que se desea evaluar
* @returns Devuelve si el primer objeto tiene mayor peso que el segundo objeto
*/
function comparar(attr, obj1, obj2) {
    return obj1[attr].localeCompare(obj2[attr]);
}

/**
* Función que permite ordenar un arreglo según el argumento deseado
* @param {string} attr Nombre del atributo por el que se desea realizar la ordenación
* @returns Devuelve el array ordenado
*/
export const compararInformación = (attr) =>{
    return function (a,b) {
    return comparar(attr,a,b);
    }
}

export const compararInformaciónDesc = (attr) =>{
    return function (a,b) {
    return comparar(attr,b,a);
    }
}

/**
 * Función que convierte una cadena en valor booleano
 * @param {String} cadena Recibe la cadena de texto que se desea convertir
 * @returns Boolean Devuelve la cadena convertida
 */
export const convertStringToBoolean = (cadena) =>{
    switch(cadena.toLowerCase().trim()){
        case "yes": case "true": case "1": return true;
        case "no": case "false": case "0": case null: return false;
        default: return Boolean(cadena);
    }
}

/**
 * Función que da formato a una fecha
 * @param {String} cadena Recibe la cadena que contiene la fecha a la que se desea dar formato
 * @returns String Devuelve la cadena con el formato dd-mm-yyyy
 */
export const getDateString = (cadena) => {
    let date = new Date(cadena)
    return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
}

/**
* Función que elimina las tildes que contenga una cadena
* @param {String} cadena Cadena de caracteres a la que se desea eliminar los acentos
* @returns String Devuelve una cadena de caracteres sin tildes
*/
export const quitarAcentos = (cadena) =>{
    const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
    return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
}

/**
 * Función que elimina los caracteres especial a una cadena de texto
 * @param {String} cadena Recibe la cadena de texto a la que se desea eliminar los caracteres especiales
 * @returns String Devuelve una cadena sin caracteres especiales
 */
export const quitarCaracteresEspeciales = (cadena) => {
    return cadena.replace(/[^a-zA-Z 0-9.]+/g,'');
}

/**
 * Función que valida si una cadena es solo letras y espacios
 * @param {string} cadena Cadena de caracteres que se desea evaluar
 * @returns boolean Devuela si la evaluación fue true o false
 */
export const validarSoloLetras = (cadena) =>{
    //Expresión regular para solo carácteres, espacio y letra ñ
    const pattern = new RegExp('^[A-Z \u00D1]+$', 'i');

    if(!cadena) {
        return false
    }
    else{
        if(!pattern.test(quitarAcentos(cadena))){
            return false
        }
        else{
            return true
        }
    }
}

