/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore,doctordirectory } from '../utils/firebase';

/**
 * Función que agrega un nuevo médico en la colección
 * @param {Object} doctorInfo Recibe la información del nuevo médico
 * @returns Devuelve el resultado de la solicitud
 */
export const createMedico = async (doctorInfo) => {
  try {
      await pacientesStore
      .collection(doctordirectory)
      .add({
          doctorInfo: doctorInfo
      })
      return "success"
  } catch (error) {
      return error
  }
}

/**
 * Función que elimina un médico de la colección
 * @param {Object} medico Recibe la información del médico a eliminar
 * @returns Devuelve el resultado de la solicitud
 */
export const deletePaciente = async (medico) => {
  try {
    await pacientesStore
    .collection(doctordirectory)
    .doc(medico.id)
    .delete();
    return "success"
  } catch (error) {//Manejo de errores inesparados
      return error
  }
}

/**
 * Función que actualiza la información de un médico en la colección
 * @param {String} id Recibe el indentificador unico relacionado al médico
 * @param {Object} doctorInfo Recibe la nueva información del médico
 * @returns Devuelve el resultado de la solicitud
 */
export const updateMedico = async (id,doctorInfo) => {
    try {
        await pacientesStore
        .collection(doctordirectory)
        .doc(id)
        .update({
            doctorInfo:             doctorInfo
        })
        return "success"
      } catch (error) {
        return error
      }
}

export const deleteMedico = async (id) => {
  try {
      await pacientesStore
      .collection(doctordirectory)
      .doc(id)
      .delete()
      return "success"
    } catch (error) {
      return error
    }
}

export const createMedicoMail = async (nombre,hospital,correoCalidad,cedula,telefono,correoMedico,today,camino) => {
  try {
      let sending = "https://apibot.hospitalesmac.app/public/surgeryroom/email/"+correoCalidad+"/"+nombre+"/<br>%20Fecha%20de%20solicitud:"+today+"<br>%20A%20continuación%20se%20presentan%20los%20datos%20del%20Nuevo%20Médico:%20<br>%20Nombre%20del%20médico:"+nombre+"<br>%20Unidad%20Hospitalaria:"+hospital+"<br>%20Cedula%20profesional%20de%20la%20especialidad:"+cedula+"<br>%20Telefono%20del%20médico:"+telefono+"<br>%20Correo%20electronico%20del%20médico:"+correoMedico+"/5fe72104fac6af0fa95dbbb0e910fde11"
      let sendingRespaldo = "https://apibot.hospitalesmac.app/public/surgeryroom/email/"+"jose.campos@hospitalesmac.com"+"/"+nombre+"/<br>%20Fecha%20de%20solicitud:"+today+"<br>%20A%20continuación%20se%20presentan%20los%20datos%20del%20Nuevo%20Médico:%20<br>%20Nombre%20del%20médico:"+nombre+"<br>%20Unidad%20Hospitalaria:"+hospital+"<br>%20Cedula%20profesional%20de%20la%20especialidad:"+cedula+"<br>%20Telefono%20del%20médico:"+telefono+"<br>%20Correo%20electronico%20del%20médico:"+correoMedico+"/5fe72104fac6af0fa95dbbb0e910fde11"
      await pacientesStore
      .collection("medicorequest")
      .add({
          nombre: nombre,
          hospital: hospital,
          correoCalidad : correoCalidad,
          cedula : cedula,
          telefono : telefono,
          correoMedico : correoMedico,
          fecha : today,
          sending : sending,
          camino : camino
      })

      fetch(sending)
          .then(function(response) {
              if(response.ok) {
                //console.log(response)
              } else {
                //console.log('Respuesta de red OK pero respuesta HTTP no OK');
              }
            })
            .catch(function(error) {
              //console.log('Hubo un problema con la petición Fetch:' + error.message);
            });
            fetch(sendingRespaldo)
            .then(function(response) {
                if(response.ok) {
                  //console.log(response)
                } else {
                  //console.log('Respuesta de red OK pero respuesta HTTP no OK');
                }
              })
              .catch(function(error) {
                //console.log('Hubo un problema con la petición Fetch:' + error.message);
              });
      return "success"
  } catch (error) {
      //console.log(error)
      return error
  }
}