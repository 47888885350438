/**
 * Librerias
 */
import React from 'react';

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'

/**
 * Componente que muestra que se está procesando información
 * @returns Component Devuelve el componente con los elementos requeridos
 */
 const Loader = () => {
    
    return(
        <div className="itemuser">
            <div>
                <h1 className='itemblogTitle'>Cargando</h1>
                <h1 className='info_itemblog'>Procesando información...</h1>
            </div>
        </div>
    )
}

export default Loader;