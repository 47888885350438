/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore } from '../utils/firebase';

/**
 * Función que solicita el registro de un nuevo evento a la colección de calendarIntent
 * @param {String} AppointmentType Recibe el identificador del recurso solicitado
 * @param {String} date Recibe la fecha inicial de la reservación
 * @param {String} platform Recibe la plataforma desde la cual se hizo la petición
 * @param {String} created Recibe la fecha en que se realiza la petición
 * @param {String} Building_Id Recibe el identificador de la unidad hospitalaria que solicita la reservación
 * @param {String} Resource_Id Recibe el identificador del quirófano que se desea reservar
 * @param {String} Resource_Name Recibe el nombre del quirófano que se desea reservar
 * @param {String} dateFinal Recibe la fecha final de la reservación
 * @returns String Devuelve el resultado de la solicitud
 */
export const createEventIntent = async (AppointmentType, date, platform, created, Building_Id,Resource_Id,Resource_Name,dateFinal) => {
    try {
        //Registro del evento en la colección
        await pacientesStore
        .collection("calendarIntent")
        .add({
            AppointmentType:    AppointmentType,
            date:               date,
            platform:           platform,
            created:            created,
            Building_Id:        Building_Id,
            Resource_Id:        Resource_Id,
            Resource_Name:      Resource_Name,
            dateFinal:          dateFinal
        })
        return "success"
    } catch (error) {//Se atrapand errores inesperados
        return error
    }
}