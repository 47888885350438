/**
 * Librerias
 */
import React from 'react';

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'

/**
 * Componente que muestra que no hay información encontrada con los parámetros solicitados
 * @returns Component Devuelve el componente con los elementos requeridos
 */
const SinEventos = () => {
    
    return(
        <div className="itemuser">
            <div>
                <h1 className='itemblogTitle'>Sin Resultados</h1>
                <h1 className='info_itemblog'>No se encontraron resultados...</h1>
            </div>
        </div>
    )
}

export default SinEventos;