/**
 * Librearías
 */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"
import moment from 'moment';
import { AiFillCaretDown,AiFillCaretUp } from "react-icons/ai";

/**
 * Styles
 */
import '../styles/pacientesPendientes.css'

/**
 * Utilerías
 */
//Objetos y colecciones de la base de datos
import { buildingCollection, pacientesStore, resourcesCollection } from "../utils/firebase";
//Funciones necesarias en la lógica del negocio
import { compararInformación } from "../utils/config";

/**
 * Componentes
 */
//Componente asignado al muestro de información de una unidad hospitalaria
import BuildingElement from "./buildingElement";
//Componente asignado al muestreo de la información de un evento
import EventCard from "./eventCard";
//Componente que muestra el formulario para la edición de un evento
import EditarEvento from "./editarEvento";
//Componente que muestra el listado de eventos realizados en forma de tabla
import HistoricoTable from "./historicoTable";
//Componente que muestra que se está procesando información
import Loader from "./loader";
//Componente que muestra la administración de medicos
import MedicosSection from "./medicosSection";
//Componente que muestra el listado de eventos que estan por realizarse en forma de tabla
import PendienteTable from "./pendienteTable";
//Componente asignado al muestro de información de un quirófano
import ResourceElement from "./resourceElement";
//Componente que muestra que no hay información encontrada con los parámetros solicitados
import SinEventos from "./sinEventos";
//Componente que muestra el encabezado de la sección
import TituloSection from "./tituloSection";
//Componente que muestras la administración de usuarios
import UsuarioSection from "./usuarioSection";
import CancelEventCard from "./cancelEvent";
import AgendaVista from "./agendaVista";
import RegitrarEvento from "./registrarEvento";


/**
 * Componente que muestra la información y acciones del calendario
 * @param {Object} param0 Recibe los elementos necesarios para mostrar los eventos de calendarios
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const CalendarSection = ({tituloInicial,tituloFinal,cantidad,imgSource,isLoading,isEmpty,events,finalDay,initialDay,
    isMostrarRealizado,setIsMostrarRealizado,isMostrarEnProceso,setIsMostrarEnProceso,isMostrarEnPendiente,setIsMostrarEnPendiente,
    cantidadRealizada,setCantidadRealizada,
    cantidadProceso,setCantidadProceso,
    cantidadPendiente,setCantidadPendiente,uhList,
    isMostrarQuirofanos,setIsMostrarQuirofanos,
    isMostrarUsuarios,setIsMostrarUsuarios,userLevel,nomHospital,
    isMostrarEditar,setIsMostrarEditar,userName,cancelEvents,cantidadCanceladas,setCantidadCanceladas,
    isMostrarMedicos,setIsMostrarMedicos,isReadOnly,setIsMostrarAgendaVista,isMostrarAgendaVista,editEvents,isCorporativo,
    setuserNomHospital,isMostrarAgendar,setIsMostrarAgendar,hospitalSeleccionado}) => {

    //Estado que almacena la lista de unidades hospitalarias disponibles
    const [buildingList, setBuildingList] = useState([])
    //Estado que almacena la unidad hospitalaria seleccionada
    const [buildingSelected ,setBuildingSelected] = useState("-- Seleccione la unidad hospitalaria --")
    //Estado que indica si esta desplegada la lista de unidades hospitalarias
    const [clicBuildingSelect,setClicBuildingSelect] = useState(false)
    //Estado que indica si esta desplegada la lista de quirófanos disponibles en la unidad hospitalaria seleccionada
    const [clicResourcesSelect,setResourcesClicSelect] = useState(false)
    //Estado que almacena el evento seleccionado
    const [eventoSeleccionado,setEventoSeleccionado] = useState({})
    //Estado que almacena el listado de eventos por realizar confirmados
    const [eventosConfirmados,setEventosConfirmados] = useState([])
    //Estado que alamcena el listado de eventos realizados
    const [eventosHistoricos,setEventosHistoricos] = useState([])
    //Estado que almacena el listado de eventos por realizar pendientes de confirmación
    const [eventosPendientes,setEventosPendientes] = useState([])
    //Estado que almacena el listado de los eventos que se exportarán en formato csv
    const [exportedEventList, setExportedEvenList] = useState([])
    //Estado que indica si se cuenta con eventos que correspondan al rango de fecha y opción seleccionada
    const [hadValidateEvents, setHadValidateEvents] = useState(false)
    //Estado que indica que si se requiere ver la vista de tipo tarjetas
    const [isTarjetasChecked,setIsTarjetasChecked] = useState(true)
    //Estado que almacena la lista de quirófanos disponibles para la unidad hospitalaria seleccionada
    const [resourcesList, setResourcesList] = useState([])
    //Estado que almacena el quirófano requerido para la cirugía
    const [resourcesSelected ,setResourcesSelected] = useState("-- Seleccione el quirófano deseado --")
    //Estado que almacena la vista seleccionada por el usuario
    const [vista,setVista] = useState("Tarjetas")
    //Estado que indica el tipo de reporte deseado
    const [tipoReporte, setTipoReporte] = useState("")
    const [cancelados, setCancelados] = useState([])
    const [matrizAgenda,setMatrizAgenda] = useState([])
    const [eventosEditados, setEventosEditados] = useState([])
    const [hadEventosEditados, setHadEventosEditados] = useState(false)

    useEffect(()=>{
        let eventosTotales = []

        for(let i = 0;i<eventosPendientes.length;i++){
            let horaInicial = parseInt(eventosPendientes[i].start.split("T")[1].split(":")[0])
            let minutoInicial = parseInt(eventosPendientes[i].start.split("T")[1].split(":")[1])
            eventosTotales.push({
                "id": eventosPendientes[i].id,
                "Estatus": eventosPendientes[i].Estatus,
                "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                "Sala": eventosPendientes[i].Sala,
                "Medico": eventosPendientes[i].Medico,
                "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                "Paciente": eventosPendientes[i].Paciente,
                "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                "Edad": eventosPendientes[i].Edad,
                "Sexo": eventosPendientes[i].Sexo,
                "TipoCliente": eventosPendientes[i].TipoCliente,
                "Diagnostico": eventosPendientes[i].Diagnostico,
                "Procedimiento": eventosPendientes[i].Procedimiento,
                "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                "Anestesiologo": eventosPendientes[i].Anestesiologo,
                "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                "Fecha": eventosPendientes[i].Fecha,
                "HoraInicio": eventosPendientes[i].HoraInicio,
                "HoraFin": eventosPendientes[i].HoraFin,
                "Duracion": eventosPendientes[i].Duracion,
                "Materiales": eventosPendientes[i].Materiales,
                "Extras": eventosPendientes[i].Extras,
                "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                "Realizo": eventosPendientes[i].Realizo,
                "start": eventosPendientes[i].start,
                "end": eventosPendientes[i].end,
                "created": eventosPendientes[i].created,
                "type": eventosPendientes[i].type,
                "id_evento": eventosPendientes[i].id_evento,
                "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                "Hora" : horaInicial.toString().padStart(2,"0") + ":" + minutoInicial.toString().padStart(2,"0"),
                "Class" : "Preparacion",
                "IsPreparacion" : true
            })
            let isInicioCero = 0
            if(minutoInicial === 0){
                eventosTotales.push({
                    "id": eventosPendientes[i].id,
                    "Estatus": eventosPendientes[i].Estatus,
                    "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                    "Sala": eventosPendientes[i].Sala,
                    "Medico": eventosPendientes[i].Medico,
                    "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                    "Paciente": eventosPendientes[i].Paciente,
                    "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                    "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                    "Edad": eventosPendientes[i].Edad,
                    "Sexo": eventosPendientes[i].Sexo,
                    "TipoCliente": eventosPendientes[i].TipoCliente,
                    "Diagnostico": eventosPendientes[i].Diagnostico,
                    "Procedimiento": eventosPendientes[i].Procedimiento,
                    "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                    "Anestesiologo": eventosPendientes[i].Anestesiologo,
                    "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                    "Fecha": eventosPendientes[i].Fecha,
                    "HoraInicio": eventosPendientes[i].HoraInicio,
                    "HoraFin": eventosPendientes[i].HoraFin,
                    "Duracion": eventosPendientes[i].Duracion,
                    "Materiales": eventosPendientes[i].Materiales,
                    "Extras": eventosPendientes[i].Extras,
                    "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                    "Realizo": eventosPendientes[i].Realizo,
                    "start": eventosPendientes[i].start,
                    "end": eventosPendientes[i].end,
                    "created": eventosPendientes[i].created,
                    "type": eventosPendientes[i].type,
                    "id_evento": eventosPendientes[i].id_evento,
                    "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                    "Hora" : horaInicial.toString().padStart(2,"0") + ":30",
                    "Class" : "PendienteInicio",
                    "IsPreparacion" : false
                })
                isInicioCero = 1
            }
            let horaFinal = parseInt(eventosPendientes[i].end.split("T")[1].split(":")[0])
            let minutoFinal = parseInt(eventosPendientes[i].end.split("T")[1].split(":")[1])

            let cantidadCasillas = 0
            cantidadCasillas = (horaFinal - horaInicial)
            //nuevo
            if(cantidadCasillas < 0){
                cantidadCasillas = cantidadCasillas + 12
            }
            cantidadCasillas = (cantidadCasillas - 1) * 2
            let numeroCasillaMedia = Math.round((cantidadCasillas) / 2)
            let casilla = 1 + isInicioCero
            if(cantidadCasillas % 2 > 0){
                cantidadCasillas = cantidadCasillas + 1
            }

            for(let j=horaInicial+1;j<horaFinal;j++){
                let isMedia = false
                let clase = "Pendiente"
                if(casilla === numeroCasillaMedia){
                    isMedia = true

                }
                else{
                    if(cantidadCasillas === 2){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }
                }

                if(casilla === 1){
                    clase = "PendienteInicio"
                }
                else{
                    if(casilla === cantidadCasillas){
                        clase = "PendienteFin"
                    }
                    else{
                        clase = "Pendiente"
                    }
                }

                eventosTotales.push({
                    "id": eventosPendientes[i].id,
                    "Estatus": eventosPendientes[i].Estatus,
                    "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                    "Sala": eventosPendientes[i].Sala,
                    "Medico": eventosPendientes[i].Medico,
                    "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                    "Paciente": eventosPendientes[i].Paciente,
                    "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                    "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                    "Edad": eventosPendientes[i].Edad,
                    "Sexo": eventosPendientes[i].Sexo,
                    "TipoCliente": eventosPendientes[i].TipoCliente,
                    "Diagnostico": eventosPendientes[i].Diagnostico,
                    "Procedimiento": eventosPendientes[i].Procedimiento,
                    "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                    "Anestesiologo": eventosPendientes[i].Anestesiologo,
                    "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                    "Fecha": eventosPendientes[i].Fecha,
                    "HoraInicio": eventosPendientes[i].HoraInicio,
                    "HoraFin": eventosPendientes[i].HoraFin,
                    "Duracion": eventosPendientes[i].Duracion,
                    "Materiales": eventosPendientes[i].Materiales,
                    "Extras": eventosPendientes[i].Extras,
                    "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                    "Realizo": eventosPendientes[i].Realizo,
                    "start": eventosPendientes[i].start,
                    "end": eventosPendientes[i].end,
                    "created": eventosPendientes[i].created,
                    "type": eventosPendientes[i].type,
                    "id_evento": eventosPendientes[i].id_evento,
                    "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                    "Hora" : j.toString().padStart(2,"0") + ":00",
                    "Class" : clase,
                    "IsPendiente" : true,
                    "isMedia" : isMedia
                })

                casilla = casilla + 1
                if(minutoFinal === 30 || j!== (horaFinal - 1)){
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }

                    if(casilla === 1){
                        clase = "PendienteInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "PendienteFin"
                        }
                        else{
                            clase = "Pendiente"
                        }
                    }

                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":30",
                        "Class" : clase,
                        "IsPendiente" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
                }
            }

            //nuevo
            if((horaFinal - horaInicial) < 0){
                for(let j=horaInicial+1;j<24;j++){
                    let isMedia = false
                    let clase = "Pendiente"
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
    
                    }
                    else{
                        isMedia = false
                    }
    
                    if(casilla === 1){
                        clase = "PendienteInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "PendienteFin"
                        }
                        else{
                            clase = "Pendiente"
                        }
                    }
    
                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":00",
                        "Class" : clase,
                        "IsPendiente" : true,
                        "isMedia" : isMedia
                    })
    
                    casilla = casilla + 1
                    if(minutoFinal === 30 || j!== (horaFinal - 1)){
                        if(casilla === numeroCasillaMedia){
                            isMedia = true
                        }
                        else{
                            isMedia = false
                        }
    
                        if(casilla === 1){
                            clase = "PendienteInicio"
                        }
                        else{
                            if(casilla === cantidadCasillas){
                                clase = "PendienteFin"
                            }
                            else{
                                clase = "Pendiente"
                            }
                        }
    
                        eventosTotales.push({
                            "id": eventosPendientes[i].id,
                            "Estatus": eventosPendientes[i].Estatus,
                            "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                            "Sala": eventosPendientes[i].Sala,
                            "Medico": eventosPendientes[i].Medico,
                            "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                            "Paciente": eventosPendientes[i].Paciente,
                            "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                            "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                            "Edad": eventosPendientes[i].Edad,
                            "Sexo": eventosPendientes[i].Sexo,
                            "TipoCliente": eventosPendientes[i].TipoCliente,
                            "Diagnostico": eventosPendientes[i].Diagnostico,
                            "Procedimiento": eventosPendientes[i].Procedimiento,
                            "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                            "Anestesiologo": eventosPendientes[i].Anestesiologo,
                            "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                            "Fecha": eventosPendientes[i].Fecha,
                            "HoraInicio": eventosPendientes[i].HoraInicio,
                            "HoraFin": eventosPendientes[i].HoraFin,
                            "Duracion": eventosPendientes[i].Duracion,
                            "Materiales": eventosPendientes[i].Materiales,
                            "Extras": eventosPendientes[i].Extras,
                            "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                            "Realizo": eventosPendientes[i].Realizo,
                            "start": eventosPendientes[i].start,
                            "end": eventosPendientes[i].end,
                            "created": eventosPendientes[i].created,
                            "type": eventosPendientes[i].type,
                            "id_evento": eventosPendientes[i].id_evento,
                            "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                            "Hora" : j.toString().padStart(2,"0") + ":30",
                            "Class" : clase,
                            "IsPendiente" : true,
                            "isMedia" : isMedia
                        })
                        casilla = casilla + 1
                    }
                }
            }

            let fecha = new Date()
            if(Date.parse(finalDay) < Date.parse(initialDay)){
                //La fecha final es menor que la inicial
                fecha = new Date(initialDay)
             }else{
                //La fecha Final es mayor...
                if(finalDay){
                    fecha = new Date(finalDay)
                }
                else{
                    fecha = new Date(initialDay)
                }
                
             }
            
             let fechaBusqueda = fecha.getFullYear()+"-"+(fecha.getMonth()+1).toString().padStart(2,"0")+"-"+fecha.getDate().toString().padStart(2,"0")
             if(fechaBusqueda === eventosPendientes[i].end.split("T")[0]){
                if(minutoFinal === 30){
                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : horaFinal.toString().padStart(2,"0") + ":00",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
                else{
                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : (horaFinal-1).toString().padStart(2,"0") + ":30",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
             }
        }

        for(let i = 0;i<eventosConfirmados.length;i++){
            let horaInicial = parseInt(eventosConfirmados[i].start.split("T")[1].split(":")[0])
            let minutoInicial = parseInt(eventosConfirmados[i].start.split("T")[1].split(":")[1])
            eventosTotales.push({
                "id": eventosConfirmados[i].id,
                "Estatus": eventosConfirmados[i].Estatus,
                "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                "Sala": eventosConfirmados[i].Sala,
                "Medico": eventosConfirmados[i].Medico,
                "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                "Paciente": eventosConfirmados[i].Paciente,
                "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                "Edad": eventosConfirmados[i].Edad,
                "Sexo": eventosConfirmados[i].Sexo,
                "TipoCliente": eventosConfirmados[i].TipoCliente,
                "Diagnostico": eventosConfirmados[i].Diagnostico,
                "Procedimiento": eventosConfirmados[i].Procedimiento,
                "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                "Fecha": eventosConfirmados[i].Fecha,
                "HoraInicio": eventosConfirmados[i].HoraInicio,
                "HoraFin": eventosConfirmados[i].HoraFin,
                "Duracion": eventosConfirmados[i].Duracion,
                "Materiales": eventosConfirmados[i].Materiales,
                "Extras": eventosConfirmados[i].Extras,
                "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                "Realizo": eventosConfirmados[i].Realizo,
                "start": eventosConfirmados[i].start,
                "end": eventosConfirmados[i].end,
                "created": eventosConfirmados[i].created,
                "type": eventosConfirmados[i].type,
                "id_evento": eventosConfirmados[i].id_evento,
                "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                "Hora" : horaInicial.toString().padStart(2,"0") + ":" + minutoInicial.toString().padStart(2,"0"),
                "Class" : "Preparacion",
                "IsPreparacion" : true
            })

            let isInicioCero = 0
            if(minutoInicial === 0){
                eventosTotales.push({
                    "id": eventosConfirmados[i].id,
                    "Estatus": eventosConfirmados[i].Estatus,
                    "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                    "Sala": eventosConfirmados[i].Sala,
                    "Medico": eventosConfirmados[i].Medico,
                    "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                    "Paciente": eventosConfirmados[i].Paciente,
                    "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                    "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                    "Edad": eventosConfirmados[i].Edad,
                    "Sexo": eventosConfirmados[i].Sexo,
                    "TipoCliente": eventosConfirmados[i].TipoCliente,
                    "Diagnostico": eventosConfirmados[i].Diagnostico,
                    "Procedimiento": eventosConfirmados[i].Procedimiento,
                    "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                    "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                    "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                    "Fecha": eventosConfirmados[i].Fecha,
                    "HoraInicio": eventosConfirmados[i].HoraInicio,
                    "HoraFin": eventosConfirmados[i].HoraFin,
                    "Duracion": eventosConfirmados[i].Duracion,
                    "Materiales": eventosConfirmados[i].Materiales,
                    "Extras": eventosConfirmados[i].Extras,
                    "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                    "Realizo": eventosConfirmados[i].Realizo,
                    "start": eventosConfirmados[i].start,
                    "end": eventosConfirmados[i].end,
                    "created": eventosConfirmados[i].created,
                    "type": eventosConfirmados[i].type,
                    "id_evento": eventosConfirmados[i].id_evento,
                    "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                    "Hora" : horaInicial.toString().padStart(2,"0") + ":30",
                    "Class" : "ConfirmadosInicio",
                    "IsPreparacion" : false
                })
                isInicioCero = 1
            }
            let horaFinal = parseInt(eventosConfirmados[i].end.split("T")[1].split(":")[0])
            let minutoFinal = parseInt(eventosConfirmados[i].end.split("T")[1].split(":")[1])
            

            let cantidadCasillas = 0
            cantidadCasillas = (horaFinal - horaInicial)
            //nuevo
            if(cantidadCasillas < 0){
                cantidadCasillas = cantidadCasillas + 12
            }
            cantidadCasillas = (cantidadCasillas - 1) * 2

            let numeroCasillaMedia = Math.round((cantidadCasillas) / 2)
            let casilla = 1 + isInicioCero
            if(cantidadCasillas % 2 > 0){
                cantidadCasillas = cantidadCasillas + 1
            }

            for(let j=horaInicial+1;j<horaFinal;j++){
                let isMedia = false
                let clase = "Confirmados"
                if(casilla === numeroCasillaMedia){
                    isMedia = true

                }
                else{
                    if(cantidadCasillas === 2){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }
                }

                if(casilla === 1){
                    clase = "ConfirmadosInicio"
                }
                else{
                    if(casilla === cantidadCasillas){
                        clase = "ConfirmadosFin"
                    }
                    else{
                        clase = "Confirmados"
                    }
                }



                eventosTotales.push({
                    "id": eventosConfirmados[i].id,
                    "Estatus": eventosConfirmados[i].Estatus,
                    "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                    "Sala": eventosConfirmados[i].Sala,
                    "Medico": eventosConfirmados[i].Medico,
                    "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                    "Paciente": eventosConfirmados[i].Paciente,
                    "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                    "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                    "Edad": eventosConfirmados[i].Edad,
                    "Sexo": eventosConfirmados[i].Sexo,
                    "TipoCliente": eventosConfirmados[i].TipoCliente,
                    "Diagnostico": eventosConfirmados[i].Diagnostico,
                    "Procedimiento": eventosConfirmados[i].Procedimiento,
                    "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                    "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                    "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                    "Fecha": eventosConfirmados[i].Fecha,
                    "HoraInicio": eventosConfirmados[i].HoraInicio,
                    "HoraFin": eventosConfirmados[i].HoraFin,
                    "Duracion": eventosConfirmados[i].Duracion,
                    "Materiales": eventosConfirmados[i].Materiales,
                    "Extras": eventosConfirmados[i].Extras,
                    "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                    "Realizo": eventosConfirmados[i].Realizo,
                    "start": eventosConfirmados[i].start,
                    "end": eventosConfirmados[i].end,
                    "created": eventosConfirmados[i].created,
                    "type": eventosConfirmados[i].type,
                    "id_evento": eventosConfirmados[i].id_evento,
                    "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                    "Hora" : j.toString().padStart(2,"0") + ":00",
                    "Class" : clase,
                    "IsConfirmado" : true,
                    "isMedia" : isMedia
                })
                casilla = casilla + 1

                if(minutoFinal === 30 || j!== (horaFinal - 1)){
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }

                    if(casilla === 1){
                        clase = "ConfirmadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "ConfirmadosFin"
                        }
                        else{
                            clase = "Confirmados"
                        }
                    }

                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":30",
                        "Class" : clase,
                        "IsConfirmado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
                }
            }

            //nuevo
            if((horaFinal - horaInicial) < 0){
                for(let j=horaInicial+1;j<24;j++){
                    let isMedia = false
                    let clase = "Confirmados"
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
    
                    }
                    else{
                        isMedia = false
                    }
    
                    if(casilla === 1){
                        clase = "ConfirmadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "ConfirmadosFin"
                        }
                        else{
                            clase = "Confirmados"
                        }
                    }
    
                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":00",
                        "Class" : clase,
                        "IsConfirmado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
    
                    if(minutoFinal === 30 || j!== (horaFinal - 1)){
                        if(casilla === numeroCasillaMedia){
                            isMedia = true
                        }
                        else{
                            isMedia = false
                        }
    
                        if(casilla === 1){
                            clase = "ConfirmadosInicio"
                        }
                        else{
                            if(casilla === cantidadCasillas){
                                clase = "ConfirmadosFin"
                            }
                            else{
                                clase = "Confirmados"
                            }
                        }
    
                        eventosTotales.push({
                            "id": eventosConfirmados[i].id,
                            "Estatus": eventosConfirmados[i].Estatus,
                            "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                            "Sala": eventosConfirmados[i].Sala,
                            "Medico": eventosConfirmados[i].Medico,
                            "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                            "Paciente": eventosConfirmados[i].Paciente,
                            "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                            "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                            "Edad": eventosConfirmados[i].Edad,
                            "Sexo": eventosConfirmados[i].Sexo,
                            "TipoCliente": eventosConfirmados[i].TipoCliente,
                            "Diagnostico": eventosConfirmados[i].Diagnostico,
                            "Procedimiento": eventosConfirmados[i].Procedimiento,
                            "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                            "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                            "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                            "Fecha": eventosConfirmados[i].Fecha,
                            "HoraInicio": eventosConfirmados[i].HoraInicio,
                            "HoraFin": eventosConfirmados[i].HoraFin,
                            "Duracion": eventosConfirmados[i].Duracion,
                            "Materiales": eventosConfirmados[i].Materiales,
                            "Extras": eventosConfirmados[i].Extras,
                            "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                            "Realizo": eventosConfirmados[i].Realizo,
                            "start": eventosConfirmados[i].start,
                            "end": eventosConfirmados[i].end,
                            "created": eventosConfirmados[i].created,
                            "type": eventosConfirmados[i].type,
                            "id_evento": eventosConfirmados[i].id_evento,
                            "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                            "Hora" : j.toString().padStart(2,"0") + ":30",
                            "Class" : clase,
                            "IsConfirmado" : true,
                            "isMedia" : isMedia
                        })
                        casilla = casilla + 1
                    }
                }
            }

            let fecha = new Date()
            if(Date.parse(finalDay) < Date.parse(initialDay)){
                //La fecha final es menor que la inicial
                fecha = new Date(initialDay)
             }else{
                //La fecha Final es mayor...
                if(finalDay){
                    fecha = new Date(finalDay)
                }
                else{
                    fecha = new Date(initialDay)
                }
                
             }

             let fechaBusqueda = fecha.getFullYear()+"-"+(fecha.getMonth()+1).toString().padStart(2,"0")+"-"+fecha.getDate().toString().padStart(2,"0")
             if(fechaBusqueda === eventosConfirmados[i].end.split("T")[0]){
                if(minutoFinal === 30){
                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : horaFinal.toString().padStart(2,"0") + ":00",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
                else{
                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : (horaFinal-1).toString().padStart(2,"0") + ":30",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
            }
        }

        for(let i = 0;i<eventosHistoricos.length;i++){
            let horaInicial = parseInt(eventosHistoricos[i].start.split("T")[1].split(":")[0])
            let minutoInicial = parseInt(eventosHistoricos[i].start.split("T")[1].split(":")[1])
            eventosTotales.push({
                "id": eventosHistoricos[i].id,
                "Estatus": eventosHistoricos[i].Estatus,
                "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                "Sala": eventosHistoricos[i].Sala,
                "Medico": eventosHistoricos[i].Medico,
                "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                "Paciente": eventosHistoricos[i].Paciente,
                "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                "Edad": eventosHistoricos[i].Edad,
                "Sexo": eventosHistoricos[i].Sexo,
                "TipoCliente": eventosHistoricos[i].TipoCliente,
                "Diagnostico": eventosHistoricos[i].Diagnostico,
                "Procedimiento": eventosHistoricos[i].Procedimiento,
                "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                "Fecha": eventosHistoricos[i].Fecha,
                "HoraInicio": eventosHistoricos[i].HoraInicio,
                "HoraFin": eventosHistoricos[i].HoraFin,
                "Duracion": eventosHistoricos[i].Duracion,
                "Materiales": eventosHistoricos[i].Materiales,
                "Extras": eventosHistoricos[i].Extras,
                "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                "Realizo": eventosHistoricos[i].Realizo,
                "start": eventosHistoricos[i].start,
                "end": eventosHistoricos[i].end,
                "created": eventosHistoricos[i].created,
                "type": eventosHistoricos[i].type,
                "id_evento": eventosHistoricos[i].id_evento,
                "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                "Hora" : horaInicial.toString().padStart(2,"0") + ":" + minutoInicial.toString().padStart(2,"0"),
                "Class" : "Preparacion",
                "IsPreparacion" : true
            })
            let horaFinal = parseInt(eventosHistoricos[i].end.split("T")[1].split(":")[0])
            let minutoFinal = parseInt(eventosHistoricos[i].end.split("T")[1].split(":")[1])

            let cantidadCasillas = 0
            cantidadCasillas = (horaFinal - horaInicial)
        
            //nuevo
            if(cantidadCasillas < 0){
                cantidadCasillas = cantidadCasillas + 12
            }
            cantidadCasillas = (cantidadCasillas - 1) * 2
            let numeroCasillaMedia = Math.round((cantidadCasillas) / 2)
            let casilla = 1
            if(cantidadCasillas % 2 > 0){
                cantidadCasillas = cantidadCasillas + 1
            }

            let isInicioCero = 0
            if(minutoInicial === 0){
                eventosTotales.push({
                    "id": eventosHistoricos[i].id,
                    "Estatus": eventosHistoricos[i].Estatus,
                    "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                    "Sala": eventosHistoricos[i].Sala,
                    "Medico": eventosHistoricos[i].Medico,
                    "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                    "Paciente": eventosHistoricos[i].Paciente,
                    "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                    "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                    "Edad": eventosHistoricos[i].Edad,
                    "Sexo": eventosHistoricos[i].Sexo,
                    "TipoCliente": eventosHistoricos[i].TipoCliente,
                    "Diagnostico": eventosHistoricos[i].Diagnostico,
                    "Procedimiento": eventosHistoricos[i].Procedimiento,
                    "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                    "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                    "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                    "Fecha": eventosHistoricos[i].Fecha,
                    "HoraInicio": eventosHistoricos[i].HoraInicio,
                    "HoraFin": eventosHistoricos[i].HoraFin,
                    "Duracion": eventosHistoricos[i].Duracion,
                    "Materiales": eventosHistoricos[i].Materiales,
                    "Extras": eventosHistoricos[i].Extras,
                    "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                    "Realizo": eventosHistoricos[i].Realizo,
                    "start": eventosHistoricos[i].start,
                    "end": eventosHistoricos[i].end,
                    "created": eventosHistoricos[i].created,
                    "type": eventosHistoricos[i].type,
                    "id_evento": eventosHistoricos[i].id_evento,
                    "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                    "Hora" : horaInicial.toString().padStart(2,"0") + ":30",
                    "Class" : "RealizadosInicio",
                    "IsRealizado" : false
                })
                isInicioCero = 1
            }
            for(let j=horaInicial+1;j<horaFinal;j++){
                let isMedia = false
                let clase = "Realizados"
                if(casilla === numeroCasillaMedia){
                    isMedia = true

                }
                else{
                    if(cantidadCasillas === 2){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }
                }

                if(casilla === 1){
                    clase = "RealizadosInicio"
                }
                else{
                    if(casilla === cantidadCasillas){
                        clase = "RealizadosFin"
                    }
                    else{
                        clase = "Realizados"
                    }
                }

                eventosTotales.push({
                    "id": eventosHistoricos[i].id,
                    "Estatus": eventosHistoricos[i].Estatus,
                    "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                    "Sala": eventosHistoricos[i].Sala,
                    "Medico": eventosHistoricos[i].Medico,
                    "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                    "Paciente": eventosHistoricos[i].Paciente,
                    "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                    "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                    "Edad": eventosHistoricos[i].Edad,
                    "Sexo": eventosHistoricos[i].Sexo,
                    "TipoCliente": eventosHistoricos[i].TipoCliente,
                    "Diagnostico": eventosHistoricos[i].Diagnostico,
                    "Procedimiento": eventosHistoricos[i].Procedimiento,
                    "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                    "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                    "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                    "Fecha": eventosHistoricos[i].Fecha,
                    "HoraInicio": eventosHistoricos[i].HoraInicio,
                    "HoraFin": eventosHistoricos[i].HoraFin,
                    "Duracion": eventosHistoricos[i].Duracion,
                    "Materiales": eventosHistoricos[i].Materiales,
                    "Extras": eventosHistoricos[i].Extras,
                    "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                    "Realizo": eventosHistoricos[i].Realizo,
                    "start": eventosHistoricos[i].start,
                    "end": eventosHistoricos[i].end,
                    "created": eventosHistoricos[i].created,
                    "type": eventosHistoricos[i].type,
                    "id_evento": eventosHistoricos[i].id_evento,
                    "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                    "Hora" : j.toString().padStart(2,"0") + ":00",
                    "Class" : clase,
                    "IsRealizado" : true,
                    "isMedia" : isMedia
                })
                casilla = casilla + 1

                if(minutoFinal === 30 || j!== (horaFinal - 1)){
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }

                    if(casilla === 1){
                        clase = "RealizadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "RealizadosFin"
                        }
                        else{
                            clase = "Realizados"
                        }
                    }

                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":30",
                        "Class" : clase,
                        "IsRealizado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
                }
            }

            //nuevo
            if((horaFinal - horaInicial) < 0){
                for(let j=horaInicial+1;j<24;j++){
                    let isMedia = false
                    let clase = "Realizados"
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
    
                    }
                    else{
                        isMedia = false
                    }
    
                    if(casilla === 1){
                        clase = "RealizadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "RealizadosFin"
                        }
                        else{
                            clase = "Realizados"
                        }
                    }
    
                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":00",
                        "Class" : clase,
                        "IsRealizado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
    
                    if(minutoFinal === 30 || j!== (horaFinal - 1)){
                        if(casilla === numeroCasillaMedia){
                            isMedia = true
                        }
                        else{
                            isMedia = false
                        }
    
                        if(casilla === 1){
                            clase = "RealizadosInicio"
                        }
                        else{
                            if(casilla === cantidadCasillas){
                                clase = "RealizadosFin"
                            }
                            else{
                                clase = "Realizados"
                            }
                        }
    
                        eventosTotales.push({
                            "id": eventosHistoricos[i].id,
                            "Estatus": eventosHistoricos[i].Estatus,
                            "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                            "Sala": eventosHistoricos[i].Sala,
                            "Medico": eventosHistoricos[i].Medico,
                            "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                            "Paciente": eventosHistoricos[i].Paciente,
                            "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                            "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                            "Edad": eventosHistoricos[i].Edad,
                            "Sexo": eventosHistoricos[i].Sexo,
                            "TipoCliente": eventosHistoricos[i].TipoCliente,
                            "Diagnostico": eventosHistoricos[i].Diagnostico,
                            "Procedimiento": eventosHistoricos[i].Procedimiento,
                            "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                            "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                            "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                            "Fecha": eventosHistoricos[i].Fecha,
                            "HoraInicio": eventosHistoricos[i].HoraInicio,
                            "HoraFin": eventosHistoricos[i].HoraFin,
                            "Duracion": eventosHistoricos[i].Duracion,
                            "Materiales": eventosHistoricos[i].Materiales,
                            "Extras": eventosHistoricos[i].Extras,
                            "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                            "Realizo": eventosHistoricos[i].Realizo,
                            "start": eventosHistoricos[i].start,
                            "end": eventosHistoricos[i].end,
                            "created": eventosHistoricos[i].created,
                            "type": eventosHistoricos[i].type,
                            "id_evento": eventosHistoricos[i].id_evento,
                            "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                            "Hora" : j.toString().padStart(2,"0") + ":30",
                            "Class" : clase,
                            "IsRealizado" : true,
                            "isMedia" : isMedia
                        })
                        casilla = casilla + 1
                    }
                }
            }

            let fecha = new Date()
            if(Date.parse(finalDay) < Date.parse(initialDay)){
                //La fecha final es menor que la inicial
                fecha = new Date(initialDay)
             }else{
                if(finalDay){
                    //La fecha Final es mayor...
                    fecha = new Date(finalDay)
                }
                else{
                    fecha = new Date(initialDay)
                }
             }

             let fechaBusqueda = fecha.getFullYear()+"-"+(fecha.getMonth()+1).toString().padStart(2,"0")+"-"+fecha.getDate().toString().padStart(2,"0")
             if( fechaBusqueda === eventosHistoricos[i].end.split("T")[0]){
                if(minutoFinal === 30){
                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : horaFinal.toString().padStart(2,"0") + ":00",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
                else{
                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : (horaFinal-1).toString().padStart(2,"0") + ":30",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
             }
        }

        let calendarioVista = []
        if(resourcesList){
            if(resourcesList[0]){
                calendarioVista.push(getEncabezadoObject(resourcesList))
                for(let i=0;i<24;i++){
                    let objeto = {}
                    let horaUno = i.toString().padStart(2,"0") + ":00"
                    let horaDos = i.toString().padStart(2,"0") + ":30"

                    let aUno = {}//Sala 1 hora completa
                    let aDos = {}//Sala 1 media hora
                    if(resourcesList[0]){//Sala 1
                        aUno = buscarEvento(horaUno,resourcesList[0].Resource_Name,eventosTotales)
                        aDos = buscarEvento(horaDos,resourcesList[0].Resource_Name,eventosTotales)
                    }

                    let bUno = {}//Sala 2 hora completa
                    let bDos = {}//Sala 2 media hora
                    if(resourcesList[1]){//Sala 2
                        bUno = buscarEvento(horaUno,resourcesList[1].Resource_Name,eventosTotales)
                        bDos = buscarEvento(horaDos,resourcesList[1].Resource_Name,eventosTotales)
                    }

                    let cUno = {}//Sala 3 hora completa
                    let cDos = {}//Sala 3 media hora
                    if(resourcesList[2]){//Sala 3
                        cUno = buscarEvento(horaUno,resourcesList[2].Resource_Name,eventosTotales)
                        cDos = buscarEvento(horaDos,resourcesList[2].Resource_Name,eventosTotales)
                    }

                    let dUno = {}//Sala 4 hora completa
                    let dDos = {}//Sala 4 media hora
                    if(resourcesList[3]){//Sala 4
                        dUno = buscarEvento(horaUno,resourcesList[3].Resource_Name,eventosTotales)
                        dDos = buscarEvento(horaDos,resourcesList[3].Resource_Name,eventosTotales)
                    }

                    let eUno = {}//Sala 5 hora completa
                    let eDos = {}//Sala 5 media hora
                    if(resourcesList[4]){//Sala 5
                        eUno = buscarEvento(horaUno,resourcesList[4].Resource_Name,eventosTotales)
                        eDos = buscarEvento(horaDos,resourcesList[4].Resource_Name,eventosTotales)
                    }

                    let fUno = {}//Sala 6 hora completa
                    let fDos = {}//Sala 6 media hora
                    if(resourcesList[5]){//Sala 6
                        fUno = buscarEvento(horaUno,resourcesList[5].Resource_Name,eventosTotales)
                        fDos = buscarEvento(horaDos,resourcesList[5].Resource_Name,eventosTotales)
                    }

                    let gUno = {}//Sala 7 hora completa
                    let gDos = {}//Sala 7 media hora
                    if(resourcesList[6]){//Sala 7
                        gUno = buscarEvento(horaUno,resourcesList[6].Resource_Name,eventosTotales)
                        gDos = buscarEvento(horaDos,resourcesList[6].Resource_Name,eventosTotales)
                    }

                    let hUno = {}//Sala 8 hora completa
                    let hDos = {}//Sala 8 media hora
                    if(resourcesList[7]){//Sala 8
                        hUno = buscarEvento(horaUno,resourcesList[7].Resource_Name,eventosTotales)
                        hDos = buscarEvento(horaDos,resourcesList[7].Resource_Name,eventosTotales)
                    }

                    let iUno = {}//Sala 9 hora completa
                    let iDos = {}//Sala 9 media hora
                    if(resourcesList[8]){//Sala 9
                        iUno = buscarEvento(horaUno,resourcesList[8].Resource_Name,eventosTotales)
                        iDos = buscarEvento(horaDos,resourcesList[8].Resource_Name,eventosTotales)
                    }

                    let jUno = {}//Sala 10 hora completa
                    let jDos = {}//Sala 10 media hora
                    if(resourcesList[9]){//Sala 10
                        jUno = buscarEvento(horaUno,resourcesList[9].Resource_Name,eventosTotales)
                        jDos = buscarEvento(horaDos,resourcesList[9].Resource_Name,eventosTotales)
                    }

                    let kUno = {}//Sala 11 hora completa
                    let kDos = {}//Sala 11 media hora
                    if(resourcesList[10]){//Sala 11
                        kUno = buscarEvento(horaUno,resourcesList[10].Resource_Name,eventosTotales)
                        kDos = buscarEvento(horaDos,resourcesList[10].Resource_Name,eventosTotales)
                    }

                    let lUno = {}//Sala 12 hora completa
                    let lDos = {}//Sala 12 media hora
                    if(resourcesList[11]){//Sala 12
                        lUno = buscarEvento(horaUno,resourcesList[11].Resource_Name,eventosTotales)
                        lDos = buscarEvento(horaDos,resourcesList[11].Resource_Name,eventosTotales)
                    }

                    let mUno = {}//Sala 13 hora completa
                    let mDos = {}//Sala 13 media hora
                    if(resourcesList[12]){//Sala 13
                        mUno = buscarEvento(horaUno,resourcesList[12].Resource_Name,eventosTotales)
                        mDos = buscarEvento(horaDos,resourcesList[12].Resource_Name,eventosTotales)
                    }

                    let nUno = {}//Sala 14 hora completa
                    let nDos = {}//Sala 14 media hora
                    if(resourcesList[13]){//Sala 14
                        nUno = buscarEvento(horaUno,resourcesList[13].Resource_Name,eventosTotales)
                        nDos = buscarEvento(horaDos,resourcesList[13].Resource_Name,eventosTotales)
                    }

                    let oUno = {}//Sala 15 hora completa
                    let oDos = {}//Sala 15 media hora
                    if(resourcesList[14]){//Sala 15
                        oUno = buscarEvento(horaUno,resourcesList[14].Resource_Name,eventosTotales)
                        oDos = buscarEvento(horaDos,resourcesList[14].Resource_Name,eventosTotales)
                    }

                    let pUno = {}//Sala 16 hora completa
                    let pDos = {}//Sala 16 media hora
                    if(resourcesList[15]){//Sala 16
                        pUno = buscarEvento(horaUno,resourcesList[15].Resource_Name,eventosTotales)
                        pDos = buscarEvento(horaDos,resourcesList[15].Resource_Name,eventosTotales)
                    }

                    let qUno = {}//Sala 17 hora completa
                    let qDos = {}//Sala 17 media hora
                    if(resourcesList[16]){//Sala 17
                        qUno = buscarEvento(horaUno,resourcesList[16].Resource_Name,eventosTotales)
                        qDos = buscarEvento(horaDos,resourcesList[16].Resource_Name,eventosTotales)
                    }

                    let rUno = {}//Sala 18 hora completa
                    let rDos = {}//Sala 18 media hora
                    if(resourcesList[17]){//Sala 18
                        rUno = buscarEvento(horaUno,resourcesList[17].Resource_Name,eventosTotales)
                        rDos = buscarEvento(horaDos,resourcesList[17].Resource_Name,eventosTotales)
                    }

                    let sUno = {}//Sala 19 hora completa
                    let sDos = {}//Sala 19 media hora
                    if(resourcesList[18]){//Sala 19
                        sUno = buscarEvento(horaUno,resourcesList[18].Resource_Name,eventosTotales)
                        sDos = buscarEvento(horaDos,resourcesList[18].Resource_Name,eventosTotales)
                    }

                    let tUno = {}//Sala 20 hora completa
                    let tDos = {}//Sala 20 media hora
                    if(resourcesList[19]){//Sala 20
                        tUno = buscarEvento(horaUno,resourcesList[19].Resource_Name,eventosTotales)
                        tDos = buscarEvento(horaDos,resourcesList[19].Resource_Name,eventosTotales)
                    }

                    objeto = {
                        "Hora": horaUno,
                        "A": aUno,
                        "B": bUno,
                        "C": cUno,
                        "D": dUno,
                        "E": eUno,
                        "F": fUno,
                        "G": gUno,
                        "H": hUno,
                        "I": iUno,
                        "J": jUno,
                        "K": kUno,
                        "L": lUno,
                        "M": mUno,
                        "N": nUno,
                        "O": oUno,
                        "P": pUno,
                        "Q": qUno,
                        "R": rUno,
                        "S": sUno,
                        "T": tUno,
                    }
                    calendarioVista.push(objeto)

                    objeto = {
                        "Hora": horaDos,
                        "A": aDos,
                        "B": bDos,
                        "C": cDos,
                        "D": dDos,
                        "E": eDos,
                        "F": fDos,
                        "G": gDos,
                        "H": hDos,
                        "I": iDos,
                        "J": jDos,
                        "K": kDos,
                        "L": lDos,
                        "M": mDos,
                        "N": nDos,
                        "O": oDos,
                        "P": pDos,
                        "Q": qDos,
                        "R": rDos,
                        "S": sDos,
                        "T": tDos,
                    }
                    calendarioVista.push(objeto)
                }

                setMatrizAgenda(calendarioVista)
            }
            else{
                setMatrizAgenda([])
            }
        }
        else{
            setMatrizAgenda([])
        }


        function getEncabezadoObject(resources){
            let dimension = resources.length
            let objeto = {}
            switch (dimension){
                case 1:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": "",
                        "C": "",
                        "D": "",
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 2:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": "",
                        "D": "",
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 3:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": "",
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 4:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 5:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 6:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 7:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 8:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 9:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 10:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 11:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 12:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 13:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 14:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 15:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 16:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 17:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 18:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": resourcesList[17].Resource_Name,
                        "S": "",
                        "T": "",
                    }
                    break
                case 19:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": resourcesList[17].Resource_Name,
                        "S": resourcesList[18].Resource_Name,
                        "T": "",
                    }
                    break
                case 20:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": resourcesList[17].Resource_Name,
                        "S": resourcesList[18].Resource_Name,
                        "T": resourcesList[19].Resource_Name,
                    }
                    break
                default:
                    objeto = {}
                    break
            }
            return objeto
        }

        function buscarEvento(horaBuscada,sala,eventosTotales){
            let objeto = {
                "id": "0",
                "Class": "Vacio"
            }
            for(let i = 0; i < eventosTotales.length; i++){
                if(eventosTotales[i].Hora.toString().trim() === horaBuscada && eventosTotales[i].Sala.toString().trim() === sala){
                    let fechaAtratar = new Date()
                    let fechaEvento = eventosTotales[i].Fecha
                    if(finalDay){
                        fechaAtratar = new Date(finalDay)
                    }
                    else{
                        if(initialDay){
                            fechaAtratar = new Date(initialDay)   
                        }
                        else{
                            fechaAtratar = new Date()
                        }
                    }
                    if(fechaAtratar.getFullYear()+"-"+(fechaAtratar.getMonth()+1)+"-"+fechaAtratar.getDate() === fechaEvento.split('-')[0]+"-"+parseInt(fechaEvento.split('-')[1])+"-"+parseInt(fechaEvento.split('-')[2])){
                        objeto = eventosTotales[i]
                    }

                }
            }
            return objeto
        }
    },[eventosHistoricos,eventosPendientes,eventosConfirmados,resourcesList,finalDay,initialDay])

    useEffect(()=>{
        if(buildingList){
            if(buildingList.length > 0){
                if(buildingList[0].Building_Id){
                    setBuildingSelected(buildingList[0].Building_Id)
                }
            }
        }
    },[buildingList])

    useEffect(()=>{
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Se verifica que se haya seleccionado una unidad hospitalaria
            /**
             * Se obtiene la lista de quirófanos disponibles en la unidad hospitalaria seleccionada
             */
            pacientesStore.collection(resourcesCollection).where('Building_Id',"==",buildingSelected)
            .onSnapshot(snap =>{
                const resultList = []
                snap.forEach(doc => {
                    resultList.push({ id: doc.id, ...doc.data() })
                })
                resultList.sort(compararInformación('Resource_Name'))
                setResourcesList(resultList)
                setResourcesSelected("-- Seleccione el quirófano deseado --")
            })
        }
        else{//Se limpia la lista de quirofanos
            setResourcesList([])
        }
    },[buildingSelected])

    useEffect(()=>{
        let validos = 0
        let inicio = initialDay
        let final = initialDay
        if(finalDay){
            final = finalDay
        }

        let cantidadRealizados = 0
        let cantidadPendientes = 0
        let cantidadProceso = 0
        let cantidadCanceladas = 0

        let eventosGeneral = []
        let eventosRealizados = []
        let eventosEfectuados = []
        let eventosPendientesDeConfirmar = []
        let eventosDefinidos = []
        let eventosCancelados = []
        let eventosEditados = []

        events.forEach(
            event => {
                let dayEvento = null
                if(event.start.dateTime){
                    dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
                }
                else{
                    dayEvento = new Date(moment(event.start).format('MM/DD/YYYY'))
                }
                //
                let dayInicial =  new Date(moment(inicio).format('MM/DD/YYYY'))
                let dayFinal =  new Date(moment(final).format('MM/DD/YYYY'))
                

                if(Date.parse(dayEvento) >= dayInicial && Date.parse(dayFinal) >= dayEvento){//Se verifica que la fecha del evento se encuentre dento del rango seleccionado
                    validos = validos + 1
                    //let diferencia = moment(event.start.dateTime).diff(moment(),"minutes")
                    let diferencia = moment(event.start.dateTime).diff(moment().subtract(2, 'hours'),"minutes")
                    if(diferencia > 0){//Cuando los minutos sean mayores a 0 es un evento por realizarse
                        if(event.summary === "Confirmada"){
                            if(userLevel !== "superuser"){
                                let unidadHospitalariaEventoName = ""
                                let description = event.description.toString().split('|')
                                if(description.length > 1){
                                    if(description[8]){
                                        const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                                        if(resultado){
                                            unidadHospitalariaEventoName =  resultado.hospital
                                        }
                                        else{
                                            unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                        }
                                    }
                                    else{
                                        unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                    }
                                }
                                else{
                                    unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                }

                                if(unidadHospitalariaEventoName === nomHospital){
                                    cantidadProceso = cantidadProceso + 1
                                    eventosGeneral.push(getEventObject(event,"Reservada","Row"))
                                    eventosDefinidos.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                                }
                            }
                            else{
                                cantidadProceso = cantidadProceso + 1
                                eventosGeneral.push(getEventObject(event,"Reservada","Row"))
                                eventosDefinidos.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                            }
                            
                        }
                        else{
                            if(userLevel !== "superuser"){
                                let unidadHospitalariaEventoName = ""
                                let description = event.description.toString().split('|')
                                if(description.length > 1){
                                    if(description[8]){
                                        const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                                        if(resultado){
                                            unidadHospitalariaEventoName =  resultado.hospital
                                        }
                                        else{
                                            unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                        }
                                    }
                                    else{
                                        unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                    }
                                }
                                else{
                                    unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                }

                                if(unidadHospitalariaEventoName === nomHospital){
                                    cantidadPendientes = cantidadPendientes + 1
                                    eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                                    eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                                }
                            }
                            else{
                                cantidadPendientes = cantidadPendientes + 1
                                eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                                eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                            }
                        }
                    }
                    else{
                        if(userLevel !== "superuser"){
                            let unidadHospitalariaEventoName = ""
                            let description = event.description.toString().split('|')
                            if(description.length > 1){
                                if(description[8]){
                                    const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                                    if(resultado){
                                        unidadHospitalariaEventoName =  resultado.hospital
                                    }
                                    else{
                                        unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                    }
                                }
                                else{
                                    unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                }
                            }
                            else{
                                unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                            }

                            if(unidadHospitalariaEventoName === nomHospital){
                                
                                if(event.summary === "Confirmada"){
                                    cantidadRealizados = cantidadRealizados + 1
                                    eventosGeneral.push(getEventObject(event,"Cirugía realizada","Row"))
                                    eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                    eventosEfectuados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                }
                                else{
                                    cantidadRealizados = cantidadRealizados + 1
                                    eventosGeneral.push(getEventObject(event,"Cirugía realizada","Row"))
                                    let description = event.description.toString().split('|')
                                    let concepto = description[19].split('=')[1]
                                    if(concepto === "Cirugía de urgencia"){
                                        eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                        eventosEfectuados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                    }
                                    else{
                                        eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                        eventosEfectuados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                    }
                                }

                            }
                        }
                        else{
                            if(event.summary === "Confirmada"){
                                cantidadRealizados = cantidadRealizados + 1
                                eventosGeneral.push(getEventObject(event,"Cirugía realizada","Row"))
                                eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                eventosEfectuados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                            }
                            else{
                                cantidadRealizados = cantidadRealizados + 1
                                eventosGeneral.push(getEventObject(event,"Sin confirmación","Row"))
                                let description = event.description.toString().split('|')
                                let concepto = description[19].split('=')[1]
                                if(concepto === "Cirugía de urgencia"){
                                    eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                    eventosEfectuados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                                }
                                else{
                                    eventosRealizados.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                                    eventosEfectuados.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                                }
                            }
                        }
                        
                    }
                }
                else{//esto es prueba
                    dayInicial =  new Date(moment(new Date()).format('MM/DD/YYYY'))
                    if(Date.parse(dayEvento) >= dayInicial){
                        //let diferencia = moment(event.start.dateTime).diff(moment(),"minutes")
                        let diferencia = moment(event.start.dateTime).diff(moment().subtract(2, 'hours'),"minutes")
                        if(diferencia > 0){
                            if(event.summary !== "Confirmada"){
                                if(userLevel !== "superuser"){
                                    let unidadHospitalariaEventoName = ""
                                    let description = event.description.toString().split('|')
                                    if(description.length > 1){
                                      if(description[8]){
                                          const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                                          if(resultado){
                                              unidadHospitalariaEventoName =  resultado.hospital
                                          }
                                          else{
                                              unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                          }
                                      }
                                      else{
                                          unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                      }
                                    }
                                    else{
                                      unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                    }
            
                                    if(unidadHospitalariaEventoName === nomHospital){
                                        validos = validos + 1
                                        cantidadPendientes = cantidadPendientes + 1
                                        eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                                        eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                                    }
                                  }
                                  else{
                                    validos = validos + 1
                                    cantidadPendientes = cantidadPendientes + 1
                                    eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                                    eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                                  }
                            }
                        }
                    }
                }
            }
        )

        cancelEvents.forEach(
            event => {
                //let dayEvento = new Date(moment(event.afterEvent.start.dateTime).format('MM/DD/YYYY'))
                let dayInicial =  new Date(moment(inicio).format('MM/DD/YYYY'))
                let dayFinal =  new Date(moment(final).format('MM/DD/YYYY'))

                let eventoAgendadoElDia = ""
                if(event.afterEvent.start.dateTime){
                    eventoAgendadoElDia = event.afterEvent.start.dateTime.split("T")[0]
                }
                else{
                    eventoAgendadoElDia = event.afterEvent.start.split("T")[0]
                }

                let dayEvento = new Date(moment(eventoAgendadoElDia).format('MM/DD/YYYY'))
                if(Date.parse(dayEvento) >= dayInicial && Date.parse(dayFinal) >= dayEvento){
                    if(userLevel !== "superuser"){
                        let unidadHospitalariaEventoName = ""
                        if(event.afterEvent.description){
                            let description = event.afterEvent.description.toString().split('|')
                            if(description.length > 1){
                                if(description[8]){
                                    const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                                    if(resultado){
                                        unidadHospitalariaEventoName =  resultado.hospital
                                    }
                                    else{
                                        unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                    }
                                }
                                else{
                                    unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                }
                            }
                            else{
                                unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                            }
                        }
                        else{
                            const resultado = uhList.find( uh => uh.calendar === event.afterEvent.UnidadHospitalaria )
                            if(resultado){
                                unidadHospitalariaEventoName =  resultado.hospital
                            }
                            else{
                                unidadHospitalariaEventoName =  getUhName(event.afterEvent.UnidadHospitalaria)
                            }
                        }

                        if(unidadHospitalariaEventoName === nomHospital){
                            cantidadCanceladas = cantidadCanceladas + 1
                            let eventoCaducado = getEventObject(event.afterEvent,"Cancelada","Row")
                            eventoCaducado.Realizo = event.solicitado
                            eventoCaducado.UltimaSolicitud = event.status
                            eventosGeneral.push(eventoCaducado)
                            eventosRealizados.push(eventoCaducado)
                            eventosCancelados.push({ id: event.afterEvent.id, ...getEventObject(event.afterEvent,"Cancelada","Row") })
                        }
                    }
                    else{
                        cantidadCanceladas = cantidadCanceladas + 1
                        let eventoCaducado = getEventObject(event.afterEvent,"Cancelada","Row")
                        eventoCaducado.Realizo = event.solicitado
                        eventoCaducado.UltimaSolicitud = event.status
                        eventosGeneral.push(eventoCaducado)
                        eventosRealizados.push(eventoCaducado)
                        eventosCancelados.push({ id: event.afterEvent.id, ...eventoCaducado })
                    }
                }
            }
        )

        editEvents.forEach(
            event => {
                //let dayEvento = new Date(moment(event.afterEvent.start.dateTime).format('MM/DD/YYYY'))
                let dayInicial =  new Date(moment(inicio).format('MM/DD/YYYY'))
                let dayFinal =  new Date(moment(final).format('MM/DD/YYYY'))

                let eventoAgendadoElDia = ""
                if(event.afterEvent.start.dateTime){
                    eventoAgendadoElDia = event.afterEvent.start.dateTime.split("T")[0]
                }
                else{
                    eventoAgendadoElDia = event.afterEvent.start.split("T")[0]
                }

                let dayEvento = new Date(moment(eventoAgendadoElDia).format('MM/DD/YYYY'))

                let eventoEditado = getEventObject(event.afterEvent,"Reasignado","Row")
                let isEditadoPendiente = false
                for(let i =0;i<eventosPendientesDeConfirmar.length;i++){
                    if(eventosPendientesDeConfirmar[i].id_evento === eventoEditado.id_evento){
                        isEditadoPendiente = true
                    }
                }
                


                if(Date.parse(dayEvento) >= dayInicial && Date.parse(dayFinal) >= dayEvento || isEditadoPendiente){
                    if(userLevel !== "superuser"){
                        let unidadHospitalariaEventoName = ""
                        if(event.afterEvent.description){
                            
                            let description = event.afterEvent.description.toString().split('|')
                            if(description.length > 1){
                                if(description[8]){
                                    const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                                    if(resultado){
                                        unidadHospitalariaEventoName =  resultado.hospital
                                    }
                                    else{
                                        unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                    }
                                }
                                else{
                                    unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                                }
                            }
                            else{
                                unidadHospitalariaEventoName =  getUhName(event.organizer.displayName)
                            }
                        }
                        else{
                            const resultado = uhList.find( uh => uh.calendar === event.afterEvent.UnidadHospitalaria )
                            if(resultado){
                                unidadHospitalariaEventoName =  resultado.hospital
                            }
                            else{
                                unidadHospitalariaEventoName =  getUhName(event.afterEvent.UnidadHospitalaria)
                            }
                        }

                        if(unidadHospitalariaEventoName === nomHospital){
                            
                            let eventoCaducado = getEventObject(event.afterEvent,"Cancelada","Row")
                            eventoCaducado.Realizo = event.solicitado
                            eventoCaducado.UltimaSolicitud = event.status
                            if(event.fechaAnterior && event.horaFinalAnterior && event.horaInicialAnterior){
                                let eventoEditado = getEventObject(event.afterEvent,"Reasignado","Row")
                                eventoEditado.fechaAnterior = event.fechaAnterior
                                eventoEditado.horaFinalAnterior = event.horaFinalAnterior
                                eventoEditado.horaInicialAnterior = event.horaInicialAnterior
                                for(let i =0;i<eventosRealizados.length;i++){
                                    if(eventosRealizados[i].id_evento === eventoEditado.id_evento){
                                        eventosRealizados[i].fechaAnterior = event.fechaAnterior
                                        eventosRealizados[i].horaFinalAnterior = event.horaFinalAnterior
                                        eventosRealizados[i].horaInicialAnterior = event.horaInicialAnterior
                                        eventosRealizados[i].fechaEdicion = event.create+"T"+event.time
                                        eventosRealizados[i].fechaEdicionMostrar = event.create+" "+event.time
                                        //eventosEditados.push(eventosRealizados[i])
                                        eventosEditados.push(eventoEditado)
                                    }
                                }

                                for(let i =0;i<eventosDefinidos.length;i++){
                                    if(eventosDefinidos[i].id_evento === eventoEditado.id_evento){
                                        let ultimaSolicitud = eventoEditado.UltimaSolicitud
                                        eventosDefinidos[i].fechaAnterior = event.fechaAnterior
                                        eventosDefinidos[i].horaFinalAnterior = event.horaFinalAnterior
                                        eventosDefinidos[i].horaInicialAnterior = event.horaInicialAnterior
                                        eventosDefinidos[i].fechaEdicion = event.create+"T"+event.time
                                        eventosDefinidos[i].fechaEdicionMostrar = event.create+" "+event.time
                                        //eventosDefinidos[i].UltimaSolicitud = eventoEditado.UltimaSolicitud
                                        eventosDefinidos[i].UltimaSolicitud = ultimaSolicitud
                                        //eventosEditados.push(eventosDefinidos[i])
                                        eventosEditados.push(eventoEditado)
                                    }
                                }

                                for(let i =0;i<eventosPendientesDeConfirmar.length;i++){
                                    if(eventosPendientesDeConfirmar[i].id_evento === eventoEditado.id_evento){
                                        eventosPendientesDeConfirmar[i].fechaAnterior = event.fechaAnterior
                                        eventosPendientesDeConfirmar[i].horaFinalAnterior = event.horaFinalAnterior
                                        eventosPendientesDeConfirmar[i].horaInicialAnterior = event.horaInicialAnterior
                                        eventosPendientesDeConfirmar[i].fechaEdicion = event.create+"T"+event.time
                                        eventosPendientesDeConfirmar[i].fechaEdicionMostrar = event.create+" "+event.time
                                        //eventosEditados.push(eventosPendientesDeConfirmar[i])
                                        eventosEditados.push(eventoEditado)
                                    }
                                }

                                for(let i =0;i<eventosGeneral.length;i++){
                                    if(eventosGeneral[i].id_evento === eventoEditado.id_evento){
                                        eventosGeneral[i].fechaAnterior = event.fechaAnterior
                                        eventosGeneral[i].horaFinalAnterior = event.horaFinalAnterior
                                        eventosGeneral[i].horaInicialAnterior = event.horaInicialAnterior
                                    }
                                }
                            }
                            
                        }
                    }
                    else{
                        let eventoCaducado = getEventObject(event.afterEvent,"Cancelada","Row")
                        eventoCaducado.Realizo = event.solicitado
                        eventoCaducado.UltimaSolicitud = event.status
                        if(event.fechaAnterior && event.horaFinalAnterior && event.horaInicialAnterior){
                            let eventoEditado = getEventObject(event.afterEvent,"Reasignado","Row")
                            eventoEditado.fechaAnterior = event.fechaAnterior
                            eventoEditado.horaFinalAnterior = event.horaFinalAnterior
                            eventoEditado.horaInicialAnterior = event.horaInicialAnterior
                            for(let i =0;i<eventosRealizados.length;i++){
                                if(eventosRealizados[i].id_evento === eventoEditado.id_evento){
                                    
                                    eventosRealizados[i].fechaAnterior = event.fechaAnterior
                                    eventosRealizados[i].horaFinalAnterior = event.horaFinalAnterior
                                    eventosRealizados[i].horaInicialAnterior = event.horaInicialAnterior
                                    eventosEditados.push(eventosRealizados[i])
                                }
                            }

                            for(let i =0;i<eventosDefinidos.length;i++){
                                if(eventosDefinidos[i].id_evento === eventoEditado.id_evento){
                                    eventosDefinidos[i].fechaAnterior = event.fechaAnterior
                                    eventosDefinidos[i].horaFinalAnterior = event.horaFinalAnterior
                                    eventosDefinidos[i].horaInicialAnterior = event.horaInicialAnterior
                                    eventosEditados.push(eventosDefinidos[i])
                                }
                            }


                            for(let i =0;i<eventosPendientesDeConfirmar.length;i++){
                                if(eventosPendientesDeConfirmar[i].id_evento === eventoEditado.id_evento){
                                    eventosPendientesDeConfirmar[i].fechaAnterior = event.fechaAnterior
                                    eventosPendientesDeConfirmar[i].horaFinalAnterior = event.horaFinalAnterior
                                    eventosPendientesDeConfirmar[i].horaInicialAnterior = event.horaInicialAnterior
                                    eventosEditados.push(eventosPendientesDeConfirmar[i])
                                }
                            }

                            for(let i =0;i<eventosGeneral.length;i++){
                                if(eventosGeneral[i].id_evento === eventoEditado.id_evento){
                                    eventosGeneral[i].fechaAnterior = event.fechaAnterior
                                    eventosGeneral[i].horaFinalAnterior = event.horaFinalAnterior
                                    eventosGeneral[i].horaInicialAnterior = event.horaInicialAnterior
                                }
                            }
                        }
                    }
                }
            }
        )

        eventosGeneral.sort(function(x, y) {
            var firstDate = new Date(x.start),
              SecondDate = new Date(y.start);
              
            if (firstDate < SecondDate) return -1;
            if (firstDate < SecondDate) return 1;
            return 0;
          });
        
          eventosEditados.sort(function(x, y) {
            var firstDate = new Date(x.fechaEdicion),
              SecondDate = new Date(y.fechaEdicion);
              
            if (firstDate < SecondDate) return -1;
            if (firstDate < SecondDate) return 1;
            return 0;
          });

        if(eventosEditados.length > 0){
            setHadEventosEditados(true)
        }
        else{
            setHadEventosEditados(false)
        }

        setEventosEditados(eventosEditados)
        if(isMostrarRealizado){
            setExportedEvenList(eventosRealizados)
            setTipoReporte('Histórico')
        }
        else{
            if(isMostrarEnProceso){
                setExportedEvenList(eventosDefinidos)
                setTipoReporte('Agendadas')
            }
            else{
                if(isMostrarEnPendiente){
                    setExportedEvenList(eventosPendientesDeConfirmar)
                    setTipoReporte('Pendientes')
                }
                else{
                    setExportedEvenList(eventosGeneral)
                    setTipoReporte('General')
                }
            }
        }
        setCancelados(eventosCancelados)
        setEventosHistoricos(eventosEfectuados)
        setEventosConfirmados(eventosDefinidos)
        setEventosPendientes(eventosPendientesDeConfirmar)
        if(!isMostrarEnPendiente && !isMostrarEnPendiente && !isMostrarRealizado){//Se verifica si se ha seleccionado alguna opción para muestreo de eventos
            setHadValidateEvents(true)
        }
        setCantidadCanceladas(cantidadCanceladas)
        if(validos > 0){//Se verifica que se hayan encontrado eventos validos dentor del rango de fechas seleccionados
            //Se asignan las cantidades a mostrar en la sección de encabezado
            setCantidadRealizada(cantidadRealizados + cantidadCanceladas)
            setCantidadProceso(cantidadProceso)
            setCantidadPendiente(cantidadPendientes)

            if(isMostrarEnProceso){//Se verifica si la opción seleccionada es En Proceso
                if(cantidadProceso > 0){//Se verifica si hay eventos en proceso
                    //Se indica que hay eventos validos
                    setHadValidateEvents(true)
                }
                else{//Se inidca que no hay eventos validos
                    setHadValidateEvents(false)
                }
            }
            else{
                if(isMostrarEnPendiente){//Se verifica si la opción seleccionada es Pendientes
                    if(cantidadPendientes > 0){//Se verifica si hay eventos pendientes
                        //Se indica que hay eventos validos
                        setHadValidateEvents(true)
                    }
                    else{//Se indica que no hay eventos validos
                        setHadValidateEvents(false)
                    }
                }
                else{
                    if(isMostrarRealizado){//Se verifica si la opción seleccionada es Realizados
                        if(cantidadRealizados > 0 || cantidadCanceladas > 0){//Se verifica si hay eventos realizados
                            //Se indica que hay eventos validos
                            setHadValidateEvents(true)
                        }
                        else{//Se indica que no hay eventos validos
                            setHadValidateEvents(false)
                        }
                    }
                }
            }
        }
        else{//No se han encontrado eventos validos dentro del rango seleccionado
            setHadValidateEvents(false)
            setCantidadRealizada(cantidadCanceladas)
            setCantidadProceso(0)
            setCantidadPendiente(0)
        }


        /**
         * Función que busca el nombre de la unidad hospitalaría a la que pertenece el evento
         * @param {String} organizer Recibe el nombre del organizador en el calendario
         * @returns String Devuelve el nombre de la unidad hospitalaria encontrada
         */
         function getUhName(organizer){
            try{
                //Se toma el nombre del organizador a partir de donde se encuentre la palabra MAC
                let unidadABuscar= organizer.toUpperCase().split('MAC')[1]
                
                //Se realiza la búsqueda en la lista de unidad hospitalarias disponibles
                let busqueda = []
                uhList.forEach(
                    uh => {
                        if(uh.hospital.toUpperCase().includes(unidadABuscar.toUpperCase())){
                            busqueda.push(uh.hospital.toUpperCase())
                        }
                    }
                )

                if(busqueda.length > 0){//Se verifica si hay resultados en la búsqueda y se devuelve el valor localizado
                    return busqueda
                }
                else{//Se devuelve el nombre del organizador del calendario
                    return organizer
                }
            }
            catch{//Errores inesperados se devuelve el nombre del organizador del calendario
                return organizer
            }
        }

        /**
         * Función que crea el objeto del evento para ser utilizado en la exportación
         * @param {Object} event Recibe el objeto de tipo event con los detalles de la reservación
         * @param {String} estatus Recibe el estatus en el que se encuentra el evento
         * @returns Object devuelve el objeto con la información relevante del evento
         */
        function getEventObject(event,estatus,style){
            let unidadHospitalaria = "SR"//
            let quirofano = "SR"//
            let medico = "SR"//
            let telefonoMedico = "SR"//
            let nombrePaciente = "SR"//
            let telefonoPaciente = "SR"//
            let fechaNacimiento = "SR"//
            let edad = "SR"//
            let sexo = "SR"//
            let tipoCliente = "SR"//
            let diagnostico = "SR"//
            let procedimiento = "SR"//
            let ladoProcedimiento = "SR"//
            let anestesiologo = "SR"//
            let tipoAnestesia = "SR"//
            let fecha = "SR"//
            let horaInicio = "SR"//
            let horaFin = "SR"//
            let duracion = "SR"//
            let materiales = "SR"//
            let extras = "SR"//
            let ultimaSolicitud = "SR"//
            let solicito = "SR"//
            let start = ""//
            let end = ""//
            let tipoReasignacion = ""//
            let materialesEspeciales = ""//
            let hemoderivados = ""//

            if(event.description){
                let description = event.description.toString().split('|')
                if(description.length > 1){
                    if(event.start.dateTime){
                        fecha = event.start.dateTime.split('T')[0]
                    }
                    else{
                        fecha = event.start.split('T')[0]
                    }

                    if(event.start.dateTime){
                        horaInicio = event.start.dateTime.split('T')[1].split('-')[0]
                    }
                    else{
                        horaInicio = event.start.split('T')[1].split('-')[0]
                    }
                    
                    if(event.end.dateTime){
                        horaFin = event.end.dateTime.split('T')[1].split('-')[0]
                    }
                    else{
                        horaFin = event.end.split('T')[1].split('-')[0]
                    }


                    for(let n =0;n<description.length;n++){
                        let evaluar = description[n].split('=')[0]

                        if(evaluar === "AppointmentType"){//0
                            quirofano = description[n].split('=')[1]
                        }

                        if(evaluar === "Medico"){//1
                            medico = description[n].split('=')[1]
                        }

                        if(evaluar === "Paciente"){//2
                            nombrePaciente = description[n].split('=')[1]
                        }

                        if(evaluar === "Procedimiento"){//3
                            procedimiento = description[n].split('=')[1]
                        }

                        if(evaluar === "Equipo Quirúrgico"){//4
                            materiales = description[n].split('=')[1]
                        }

                        if(evaluar === "Extras"){//5
                            extras = description[n].split('=')[1]
                        }

                        if(evaluar === "Telefono Medico"){//6
                            telefonoMedico = description[n].split('=')[1]
                        }

                        if(evaluar === "Telefono Paciente"){//7
                            telefonoPaciente = description[n].split('=')[1]
                        }

                        if(evaluar === "Unidad Hospitalaroa"){//8
                            unidadHospitalaria = description[n].split('=')[1]
                        }

                        if(evaluar === "Duracion"){//9
                            duracion = description[n].split('=')[1]
                        }

                        if(evaluar === "LadoProcedimiento"){//10
                            ladoProcedimiento = description[n].split('=')[1]
                        }

                        if(evaluar === "Anestesiologo"){//11
                            anestesiologo = description[n].split('=')[1]
                        }

                        if(evaluar === "TipoAnestesia"){//12
                            tipoAnestesia = description[n].split('=')[1]
                        }

                        if(evaluar === "FechaNacimientoPaciente"){//13
                            fechaNacimiento = description[n].split('=')[1]
                        }

                        if(evaluar === "EdadPaciente"){//14
                            edad = description[n].split('=')[1]
                        }

                        if(evaluar === "SexoPaciente"){//15
                            sexo = description[n].split('=')[1]
                        }

                        if(evaluar === "TipoCliente"){//16
                            tipoCliente = description[n].split('=')[1]
                        }

                        if(evaluar === "Diagnostico"){//17
                            diagnostico = description[n].split('=')[1]
                        }

                        if(evaluar === "Solicitado"){//18
                            solicito = description[n].split('=')[1]
                        }

                        if(evaluar === "Concepto"){//19
                            ultimaSolicitud = description[n].split('=')[1]
                        }

                        if(evaluar === "EquipoEspecial"){//23
                            materialesEspeciales = description[n].split('=')[1]
                        }

                        if(evaluar === "Hemoderivados"){//24
                            hemoderivados = description[n].split('=')[1]
                        }

                        if(evaluar === "TipoCambio"){//25
                            tipoReasignacion = description[n].split('=')[1]
                        }
                    }
                    
                    
                }
                else{
                    quirofano = description[0]
                }

                if(event.start.dateTime){
                    start = event.start.dateTime
                    end = event.end.dateTime
                }
                else{
                    start = event.start
                    end = event.end
                }
                
            }
            else{
                unidadHospitalaria = event.UnidadHospitalaria
                quirofano = event.Sala
                medico = event.Medico
                telefonoMedico = event.TelefonoMedico
                nombrePaciente = event.Paciente
                telefonoPaciente = event.TelefonoPaciente
                fechaNacimiento = event.FechaNacimiento
                edad = event.Edad
                sexo = event.Sexo
                tipoCliente = event.TipoCliente
                diagnostico = event.Diagnostico
                procedimiento = event.Procedimiento
                ladoProcedimiento = event.LadoProcedimiento
                anestesiologo = event.Anestesiologo
                tipoAnestesia = event.TipoAnestesia
                fecha = event.Fecha
                horaInicio = event.HoraInicio
                horaFin = event.HoraFin
                duracion = event.Duracion
                materiales = event.Materiales
                extras = event.Extras
                ultimaSolicitud = event.UltimaSolicitud
                solicito = event.Realizo
                start = event.start
                end = event.end
                tipoReasignacion = "SR"
            }

            return {
                'Estatus' : estatus,
                'UnidadHospitalaria' : unidadHospitalaria,
                'Sala': quirofano,
                'Medico': medico,
                'TelefonoMedico' : telefonoMedico,
                'Paciente': nombrePaciente,
                'TelefonoPaciente':telefonoPaciente,
                'FechaNacimiento':fechaNacimiento,
                'Edad':edad,
                'Sexo':sexo,
                'TipoCliente':tipoCliente,
                'Diagnostico':diagnostico,
                'Procedimiento':procedimiento,
                'LadoProcedimiento':ladoProcedimiento,
                'Anestesiologo':anestesiologo,
                'TipoAnestesia':tipoAnestesia,
                'Fecha':fecha,
                'HoraInicio':horaInicio,
                'HoraFin':horaFin,
                'Duracion':duracion,
                'Materiales':materiales,
                'Extras':extras,
                'UltimaSolicitud':ultimaSolicitud,
                'Realizo': solicito,
                'start':start,
                'end':end,
                'created':event.created,
                'type':style,
                'id_evento' : event.id_evento,
                'TipoReasignacion' : tipoReasignacion,
                'MaterialesEspeciales' : materialesEspeciales,
                'Hemoderivados' : hemoderivados
            }
            
        }
    },[events,initialDay,finalDay,setCantidadPendiente,setCantidadProceso,setCantidadRealizada,isMostrarEnProceso,isMostrarEnPendiente,isMostrarRealizado,nomHospital,uhList,userLevel,cancelEvents,setCantidadCanceladas,editEvents])

    useEffect(()=>{
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Si se cuenta con una unidad hospitalaria seleccionada se despliega la lista de quirófanos
            setResourcesClicSelect(true)
        }
    },[resourcesList,buildingSelected])

    useEffect(()=>{
        if(resourcesList){
            let horasOcupadas = []
            events.forEach(
                event => {
                    let dayEvento = null
                    if(event.start.dateTime){
                        dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
                    }
                    else{
                        dayEvento = new Date(moment(event.start).format('MM/DD/YYYY'))
                    }
                    //new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
                    let dayInicial =  new Date(moment(initialDay).format('MM/DD/YYYY'))
                    
                    // let isConfirmed = false
                    // if(event.summary === "Confirmada"){
                    //     isConfirmed = true
                    // }
                    if(dayEvento.getFullYear() + "-" + dayEvento.getMonth() + "-" + dayEvento.getDate() === dayInicial.getFullYear() + "-" + dayInicial.getMonth() + "-" + dayInicial.getDate()){
                        //let tiempoInicial = event.start.dateTime.toString().split('T')
                        //let horaInicial = parseInt(tiempoInicial[1].split(':')[0])
                        let tiempoInicial = ""
                        let horaInicial = ""
                        if(event.start.dateTime){
                            tiempoInicial = event.start.dateTime.toString().split('T')
                            horaInicial = parseInt(tiempoInicial[1].split(':')[0])
                        }
                        else{
                            tiempoInicial = event.start.toString().split('T')
                            horaInicial = parseInt(tiempoInicial[1].split(':')[0])
                        }

                        //let tiempoFinal = event.end.dateTime.toString().split('T')
                        //let horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                        let tiempoFinal = ""
                        let horaFinal = ""

                        if( event.end.dateTime){
                            tiempoFinal = event.end.dateTime.toString().split('T')
                            horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                        }
                        else{
                            tiempoFinal = event.end.toString().split('T')
                            horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                        }

                        let description = event.description.toString().split('|')
                        let AppointmentType = ""
                        if(description.length > 1){
                            AppointmentType = description[0].split('=')[1]
                        }
                        else{
                            AppointmentType = description[0]
                        }

                        let horaReservadaObject = {
                            'Recurso' : AppointmentType,
                            'Hora' : horaInicial,
                            'Identificador':"Inicio",
                            'id':event.id,
                            'medico':description[1].split('=')[1]
                        }
                        horasOcupadas.push(horaReservadaObject)
                        for(let i=horaInicial + 1;i<horaFinal;i++){
                            let horaReservadaObject = {
                                'Recurso' : AppointmentType,
                                'Hora' : i,
                                'Identificador':"Intermedio",
                                'id':event.id,
                                'medico':description[1].split('=')[1]
                            }
                            horasOcupadas.push(horaReservadaObject)
                        }
                    }
                }
            )

            let agendaArray = []
            for(let i=0;i<resourcesList.length;i++){
                let cadenaGeneral = ""
                let id = 0
                let medico=""
                cadenaGeneral += resourcesList[i].Resource_Name
                for(let j=0;j<24;j++){
                    let encontrada = false
                    for(let h=0;h<horasOcupadas.length;h++){
                        if(resourcesList[i].Resource_Name === horasOcupadas[h].Recurso && parseInt(j) === parseInt(horasOcupadas[h].Hora)){
                            encontrada = true
                            id = horasOcupadas[h].id
                            medico = horasOcupadas[h].medico
                        }
                    }

                    if(encontrada){
                        cadenaGeneral += "°"+medico+"|" + id
                    }
                    else{
                        cadenaGeneral += "°Libre"
                    }
                }
                let reserveObject = {
                    'Sala' : cadenaGeneral.split('°')[0],
                    'h0' : cadenaGeneral.split('°')[1],
                    'h1' : cadenaGeneral.split('°')[2],
                    'h2' : cadenaGeneral.split('°')[3],
                    'h3' : cadenaGeneral.split('°')[4],
                    'h4' : cadenaGeneral.split('°')[5],
                    'h5' : cadenaGeneral.split('°')[6],
                    'h6' : cadenaGeneral.split('°')[7],
                    'h7' : cadenaGeneral.split('°')[8],
                    'h8' : cadenaGeneral.split('°')[9],
                    'h9' : cadenaGeneral.split('°')[10],
                    'h10' : cadenaGeneral.split('°')[11],
                    'h11' : cadenaGeneral.split('°')[12],
                    'h12' : cadenaGeneral.split('°')[13],
                    'h13' : cadenaGeneral.split('°')[14],
                    'h14' : cadenaGeneral.split('°')[15],
                    'h15' : cadenaGeneral.split('°')[16],
                    'h16' : cadenaGeneral.split('°')[17],
                    'h17' : cadenaGeneral.split('°')[18],
                    'h18' : cadenaGeneral.split('°')[19],
                    'h19' : cadenaGeneral.split('°')[20],
                    'h20' : cadenaGeneral.split('°')[21],
                    'h21' : cadenaGeneral.split('°')[22],
                    'h22' : cadenaGeneral.split('°')[23],
                    'h23' : cadenaGeneral.split('°')[24],
                }
                agendaArray.push(reserveObject)
            }
        }
    },[resourcesList,events,initialDay,eventosHistoricos,eventosConfirmados])

    useEffect(()=>{
        /**
         * Se obtiene la lista de unidades hospitalarias
         */
        pacientesStore.collection(buildingCollection)
        .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                if(userLevel !== "superuser"){
                    let data = doc.data()
                    const resultado = uhList.find( uh => uh.calendar === data.Building_Id );
                    if(resultado){
                        if(nomHospital === resultado.hospital){
                            resultList.push({ id: doc.id, ...doc.data() })
                        }
                    }
                }
                else{
                    resultList.push({ id: doc.id, ...doc.data() })
                }
            })
            resultList.sort(compararInformación('Building_Id'))
            setBuildingList(resultList)
        },(error)=>{ 
            setBuildingList([]) 
            Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
            })
        })
    },[uhList,nomHospital,userLevel])
            
    useEffect(()=>{
        if(vista === "Lista"){
            setIsTarjetasChecked(false)
        }
        else{
            setIsTarjetasChecked(true)
        }
    },[vista])
    
    /**
     * Función que cambio de estado la visualización de la lista de unidades hospitalarias (visible o invisible)
     */
     function mostrarOcultarBuilding(){ 
        if(!clicBuildingSelect){
            setClicBuildingSelect(true)
        } else{
            setClicBuildingSelect(false)
        }   
    }

    /**
     * Función que cambio de estado la visualización de la lista de quirófano disponibles (visible o invisible)
     */
     const mostrarOcultarResources = () => {
        if(!clicResourcesSelect){
            setResourcesClicSelect(true)
        } else{
            setResourcesClicSelect(false)
        }   
    }

    /**
     * Función que atrapa la selección de un genero
     * @param {Event} vista Recibe el evento disparado en la selección
     */
     const onChangeVista = (vista) => {
        setVista(vista.target.value)
    };

    return (
        <>
            <section className='seccion_pacientes_pendientes'>
                <div id="cuerpoEncabezado" className='cuerpoEncabezado'>
                    <TituloSection tituloInicial={tituloInicial} tituloFinal={tituloFinal} cantidad={cantidad} imgSource={imgSource}
                    isMostrarRealizado={isMostrarRealizado} setIsMostrarRealizado={setIsMostrarRealizado}
                    isMostrarEnProceso={isMostrarEnProceso} setIsMostrarEnProceso={setIsMostrarEnProceso}
                    isMostrarEnPendiente={isMostrarEnPendiente}  setIsMostrarEnPendiente={setIsMostrarEnPendiente}
                    cantidadRealizada={cantidadRealizada} cantidadProceso={cantidadProceso} cantidadPendiente={cantidadPendiente}
                    isMostrarQuirofanos={isMostrarQuirofanos} setIsMostrarQuirofanos={setIsMostrarQuirofanos}
                    setIsMostrarUsuarios={setIsMostrarUsuarios} userLevel={userLevel}
                    setIsMostrarEditar={setIsMostrarEditar} exportedEventList={exportedEventList} nomHospital={nomHospital} 
                    isMostrarUsuarios={isMostrarUsuarios} cantidadCanceladas={cantidadCanceladas}
                    setIsMostrarMedicos={setIsMostrarMedicos} isMostrarMedicos={isMostrarMedicos}
                    tipoReporte={tipoReporte} setIsMostrarAgendaVista={setIsMostrarAgendaVista} isMostrarAgendaVista={isMostrarAgendaVista}
                    eventosEditados = {eventosEditados} hadEventosEditados={hadEventosEditados} setuserNomHospital={setuserNomHospital}
                    isCorporativo={isCorporativo} setIsMostrarAgendar={setIsMostrarAgendar} isMostrarAgendar={isMostrarAgendar}/>
                </div>
                <div className='itemservespCont'>
                    {
                        (isMostrarEnPendiente || isMostrarEnProceso || isMostrarRealizado) && !isMostrarEditar
                        ?
                            <div className='firstfiltercont'>
                                <div className='ftconfliter'>
                                        <div className="datos">
                                            <input type="radio" value="Tarjetas" name="gender" checked={isTarjetasChecked} onChange={onChangeVista}/> Tarjetas
                                            <input type="radio" value="Lista" name="gender" checked={!isTarjetasChecked} onChange={onChangeVista}/> Listado
                                        </div>
                                </div>
                            </div>
                        :
                            null
                    }
                    {
                        isMostrarEditar
                        ?
                            <EditarEvento eventoSeleccionado={eventoSeleccionado} setIsMostrarEditar={setIsMostrarEditar} 
                            uhList={uhList} nomHospital={nomHospital} userLevel={userLevel} userName={userName} isMostrarEnPendiente={isMostrarEnPendiente}/>
                        :
                        isMostrarUsuarios
                        ?
                            <UsuarioSection uhList={uhList} userLevel={userLevel} nomHospital={nomHospital} hospitalSeleccionado={hospitalSeleccionado}/>
                        :
                        isMostrarEnPendiente || isMostrarEnProceso || isMostrarRealizado
                            ?isLoading 
                                ? <Loader />
                                : hadValidateEvents
                                    ?
                                        isTarjetasChecked
                                            ?
                                                <>
                                                    {
                                                        events.map(
                                                            event => <EventCard 
                                                                        key={event.id} 
                                                                        event={event} 
                                                                        finalDay={finalDay} 
                                                                        initialDay={initialDay}
                                                                        isMostrarRealizado={isMostrarRealizado}
                                                                        isMostrarEnProceso={isMostrarEnProceso}
                                                                        isMostrarEnPendiente={isMostrarEnPendiente}
                                                                        uhList={uhList}
                                                                        eventoSeleccionado={eventoSeleccionado}
                                                                        setEventoSeleccionado={setEventoSeleccionado}
                                                                        setIsMostrarEditar={setIsMostrarEditar}
                                                                        nomHospital={nomHospital}
                                                                        userLevel={userLevel}
                                                                        userName={userName}
                                                                        isReadOnly={isReadOnly}/>
                                                        )
                                                    }
                                                    {
                                                        isMostrarRealizado
                                                            ?
                                                                cancelados.map(
                                                                    event => <CancelEventCard 
                                                                                key={event.id} 
                                                                                event={event} 
                                                                                finalDay={finalDay} 
                                                                                initialDay={initialDay}
                                                                                isMostrarRealizado={isMostrarRealizado}
                                                                                isMostrarEnProceso={isMostrarEnProceso}
                                                                                isMostrarEnPendiente={isMostrarEnPendiente}
                                                                                uhList={uhList}
                                                                                eventoSeleccionado={eventoSeleccionado}
                                                                                setEventoSeleccionado={setEventoSeleccionado}
                                                                                setIsMostrarEditar={setIsMostrarEditar}
                                                                                nomHospital={nomHospital}
                                                                                userLevel={userLevel}
                                                                                userName={userName}
                                                                                isReadOnly={isReadOnly}
                                                                                events={events} 
                                                                                />
                                                                )
                                                            :
                                                                null
                                                    }
                                                </>
                                            :
                                                isMostrarRealizado
                                                    ?
                                                        <HistoricoTable eventos={eventosHistoricos} uhList={uhList} events={events} 
                                                        setEventoSeleccionado={setEventoSeleccionado} eventoSeleccionado={eventoSeleccionado} 
                                                        userLevel={userLevel} userName={userName} cancelados={cancelados} setIsMostrarEditar={setIsMostrarEditar}/>
                                                    :
                                                        isMostrarEnPendiente
                                                            ?
                                                                <PendienteTable eventos={eventosPendientes} uhList={uhList} events={events} 
                                                                setEventoSeleccionado={setEventoSeleccionado} eventoSeleccionado={eventoSeleccionado} 
                                                                setIsMostrarEditar={setIsMostrarEditar} userName={userName} isMostrarEnPendiente={isMostrarEnPendiente}
                                                                userLevel={userLevel}/>
                                                            :
                                                                <PendienteTable eventos={eventosConfirmados} uhList={uhList} events={events} 
                                                                setEventoSeleccionado={setEventoSeleccionado} eventoSeleccionado={eventoSeleccionado} 
                                                                setIsMostrarEditar={setIsMostrarEditar} userName={userName} 
                                                                isMostrarEnPendiente={isMostrarEnPendiente} userLevel={userLevel}/>

                                    :
                                        <SinEventos />
                            :
                                isMostrarQuirofanos
                                    ?
                                        <div className="reserve_form">
                                            <p className="reserve_title">Administración de Quirófanos</p>
                                            <div className={"content"} id="contenedorBuilding">
                                                <div className="contenedorEncabezado">
                                                    Seleccione la Unidad Hospitalaria
                                                </div>
                                                <div className="contenido">
                                                    <div id="buildingSelected" className="contenedor_item_show" onClick={mostrarOcultarBuilding}>
                                                        {buildingSelected}
                                                    </div>
                                                    {
                                                        clicBuildingSelect
                                                            ?
                                                                <>
                                                                    <span id="buildingDownArrow" className="arrow" onClick={mostrarOcultarBuilding}><AiFillCaretUp/></span>
                                                                    <div>
                                                                        {
                                                                            buildingList.map(
                                                                                build => <BuildingElement key={build.id} 
                                                                                build={build}
                                                                                buildingSelected={buildingSelected}
                                                                                setBuildingSelected={setBuildingSelected}
                                                                                mostrarOcultarBuilding={mostrarOcultarBuilding}/>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            :
                                                                <span id="buildingDownArrow" className="arrow" onClick={mostrarOcultarBuilding}><AiFillCaretDown /></span>
                                                    }
                                                </div>
                                            </div>
                                            <div className={"content"} id="contenedorResources">
                                                <div className="contenedorEncabezado">
                                                    Quirófanos Disponibles
                                                </div>
                                                <div className="contenido">
                                                    {
                                                        clicResourcesSelect
                                                            ?
                                                                <>
                                                                    <div>
                                                                        {
                                                                            resourcesList.map(
                                                                                resource => <ResourceElement key={resource.id}
                                                                                resource={resource}
                                                                                resourcesSelected={resourcesSelected}
                                                                                setResourcesSelected={setResourcesSelected}
                                                                                mostrarOcultarResources={mostrarOcultarResources} isReadOnly={isReadOnly}/>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            :
                                                                <span id="resourcesDownArrow" className="arrow"></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        isMostrarAgendaVista
                                                    ?
                                                        <AgendaVista matrizAgenda={matrizAgenda} setEventoSeleccionado={setEventoSeleccionado} 
                                                        eventoSeleccionado={eventoSeleccionado} events={events} finalDay={finalDay} initialDay={initialDay}/>
                                                    :
                                                        isMostrarAgendar
                                                            ? 
                                                                <RegitrarEvento setIsMostrarRealizado={setIsMostrarRealizado} setIsMostrarAgendar={setIsMostrarAgendar}
                                                                nomHospital={nomHospital} uhList={uhList} userLevel={userLevel} userName={userName}/>
                                                            :
                                                                <MedicosSection uhList={uhList} userLevel={userLevel} nomHospital={nomHospital} isTarjetasChecked={isTarjetasChecked} setIsTarjetasChecked={setIsTarjetasChecked} vista={vista} setVista={setVista} isMostrarMedicos={isMostrarMedicos}
                                                                eventosPendientes={eventosPendientes} eventosConfirmados={eventosConfirmados} events={events} userName={userName} isReadOnly={isReadOnly}/>
                    }
                </div>
            </section>
        </>
    )
}

export default CalendarSection;