/**
 * Librerías
 */
import React from "react";
import DatePicker from 'react-datepicker'

/**
 * Styles
 */
import "react-datepicker/dist/react-datepicker.css";

/**
 * Componente asignado para contener el elemento DatePicker
 * @param {Object} param0 Recibe los elementos necesarios para configurar el DatePicker
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const DateSelector = ({initialDay,setInitialDay,finalDay,setFinalDay}) => {

    /**
     * Evento que atrapa la selección de fechas
     * @param {DatePicker} dates Recibe las fechas seleccionadas y las asigna a los estados correspondientes
     */
    const onChange = (dates) => {
        const [start, end] = dates;
        setInitialDay(start);
        setFinalDay(end);
    };

    return (
        <DatePicker
            selected={initialDay}
            onChange={onChange}
            startDate={initialDay}
            endDate={finalDay}
            selectsRange
            inline
        />
    )
}

export default DateSelector;