const procedimientos = [
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "PROCEDIMIENTOS E INTERVENCIONES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
     "CATALOG_KEY": "000",
     "PRO_NOMBRE": "ULTRASONIDO TERAPÉUTICO"
    },
    {
     "CATALOG_KEY": "0001",
     "PRO_NOMBRE": "ULTRASONIDO TERAPÉUTICO DE VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "0002",
     "PRO_NOMBRE": "ULTRASONIDO TERAPÉUTICO CARDÍACO"
    },
    {
     "CATALOG_KEY": "0003",
     "PRO_NOMBRE": "ULTRASONIDO TERAPÉUTICO DE VASOS PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0009",
     "PRO_NOMBRE": "OTRO ULTRASONIDO TERAPÉUTICO"
    },
    {
     "CATALOG_KEY": "001",
     "PRO_NOMBRE": "FÁRMACOS"
    },
    {
     "CATALOG_KEY": "0010",
     "PRO_NOMBRE": "IMPLANTACIÓN DE AGENTE QUIMIOTERAPÉUTICO"
    },
    {
     "CATALOG_KEY": "0011",
     "PRO_NOMBRE": "INFUSIÓN DE DROTRECOGÍN ALFA (ACTIVADA)"
    },
    {
     "CATALOG_KEY": "0012",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE ÓXIDO NÍTRICO INHALADO"
    },
    {
     "CATALOG_KEY": "0013",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE NESIRITIDE"
    },
    {
     "CATALOG_KEY": "0014",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE ANTIBIÓTICOS DEL GRUPO DE LA OXAZOLIDINONA"
    },
    {
     "CATALOG_KEY": "0015",
     "PRO_NOMBRE": "INFUSIÓN DE DOSIS ALTA DE INTERLEUCINA-2 [IL-2]"
    },
    {
     "CATALOG_KEY": "0016",
     "PRO_NOMBRE": "TRATAMIENTO PRESURIZADO DE INJERTO DE DERIVACIÓN VENOSO [CONDUCTO] CON SUSTANCIA MEDICAMENTOSA"
    },
    {
     "CATALOG_KEY": "0017",
     "PRO_NOMBRE": "INFUSIÓN DE AGENTE VASOPRESOR"
    },
    {
     "CATALOG_KEY": "0018",
     "PRO_NOMBRE": "TERAPIA DE INFUSIÓN DE ANTICUERPOS INMUNOSUPRESORES"
    },
    {
     "CATALOG_KEY": "0019",
     "PRO_NOMBRE": "IRRUPCIÓN DE LA BARRERA HEMATOENCEFÁLICA VÍA INFUSIÓN [IBH] [BBBD]"
    },
    {
     "CATALOG_KEY": "002",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE VASOS SANGUÍNEOS"
    },
    {
     "CATALOG_KEY": "0021",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE VASOS CEREBRALES EXTRACRANEALES"
    },
    {
     "CATALOG_KEY": "0022",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE VASOS INTRATORÁCICOS"
    },
    {
     "CATALOG_KEY": "0023",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE VASOS PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0024",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE VASOS CORONARIOS"
    },
    {
     "CATALOG_KEY": "0025",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE VASOS RENALES"
    },
    {
     "CATALOG_KEY": "0028",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE OTRO(S) VASO(S) ESPECIFICADO(S)"
    },
    {
     "CATALOG_KEY": "0029",
     "PRO_NOMBRE": "IMAGEN INTRAVASCULAR DE VASO(S) NO ESPECIFICADO(S)"
    },
    {
     "CATALOG_KEY": "003",
     "PRO_NOMBRE": "CIRUGÍA ESPECIFICADOS ASISTIDA POR ORDENADOR [CAS]"
    },
    {
     "CATALOG_KEY": "0031",
     "PRO_NOMBRE": "CIRUGÍA ASISTIDA POR ORDENADOR CON TAC/TC"
    },
    {
     "CATALOG_KEY": "0032",
     "PRO_NOMBRE": "CIRUGÍA ASISTIDA POR ORDENADOR CON RM/ARM"
    },
    {
     "CATALOG_KEY": "0033",
     "PRO_NOMBRE": "CIRUGÍA ASISTIDA POR ORDENADOR CON RADIOSCOPIA"
    },
    {
     "CATALOG_KEY": "0034",
     "PRO_NOMBRE": "CIRUGÍA ASISTIDA POR ORDENADOR SIN IMAGEN"
    },
    {
     "CATALOG_KEY": "0035",
     "PRO_NOMBRE": "CIRUGÍA ASISTIDA POR ORDENADOR CON MÚLTIPLES FUENTES DE DATOS"
    },
    {
     "CATALOG_KEY": "0039",
     "PRO_NOMBRE": "OTRA CIRUGÍA ASISTIDA POR ORDENADOR"
    },
    {
     "CATALOG_KEY": "004",
     "PRO_NOMBRE": "PROCEDIMIENTOS AUXILIARES SOBRE EL SISTEMA VASCULAR"
    },
    {
     "CATALOG_KEY": "0040",
     "PRO_NOMBRE": "PROCEDIMIENTO SOBRE UN SOLO VASO"
    },
    {
     "CATALOG_KEY": "0041",
     "PRO_NOMBRE": "PROCEDIMIENTO SOBRE DOS VASOS"
    },
    {
     "CATALOG_KEY": "0042",
     "PRO_NOMBRE": "PROCEDIMIENTO SOBRE TRES VASOS"
    },
    {
     "CATALOG_KEY": "0043",
     "PRO_NOMBRE": "PROCEDIMIENTO SOBRE CUATRO O MÁS VASOS"
    },
    {
     "CATALOG_KEY": "0044",
     "PRO_NOMBRE": "PROCEDIMIENTO SOBRE BIFURCACIÓN VASCULAR"
    },
    {
     "CATALOG_KEY": "0045",
     "PRO_NOMBRE": "INSERCIÓN DE UN STENT VASCULAR"
    },
    {
     "CATALOG_KEY": "0046",
     "PRO_NOMBRE": "INSERCIÓN DE DOS STENTS VASCULARES"
    },
    {
     "CATALOG_KEY": "0047",
     "PRO_NOMBRE": "INSERCIÓN DE TRES STENTS VASCULARES"
    },
    {
     "CATALOG_KEY": "0048",
     "PRO_NOMBRE": "INSERCIÓN DE CUATRO O MÁS STENTS VASCULARES"
    },
    {
     "CATALOG_KEY": "0049",
     "PRO_NOMBRE": "TERAPIA CON OXIGENO SUPERSATURADO"
    },
    {
     "CATALOG_KEY": "005",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS CARDIOVASCULARES"
    },
    {
     "CATALOG_KEY": "0050",
     "PRO_NOMBRE": "IMPLANTACIÓN DE MARCAPASOS DE RESINCRONIZACIÓN CARDÍACA SIN MENCIÓN DE DESFIBRILACIÓN, SISTEMA TOTAL [DRCT] [CRT-P]"
    },
    {
     "CATALOG_KEY": "0051",
     "PRO_NOMBRE": "IMPLANTACIÓN DE DESFIBRILADOR DE RESINCRONIZACIÓN CARDÍACA, SISTEMA TOTAL [DRCT] [CRT-D]"
    },
    {
     "CATALOG_KEY": "0052",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE ELECTRODO DENTRO DEL SISTEMA VENOSO CORONARIO VENTRICULAR IZQUIERDO"
    },
    {
     "CATALOG_KEY": "0053",
     "PRO_NOMBRE": "IMPLANTACIÓN DE O SUSTITUCIÓN DE MARCAPASOS DE RESINCRONIZACIÓN CARDÍACA SÓLO GENERADOR DE PULSO [IMRC] [CRT-P]"
    },
    {
     "CATALOG_KEY": "0054",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE DESFIBRILADOR DE RESINCRONIZACIÓN CARDÍACA,SÓLO DISPOSITIVO GENERADOR DE PULSO [DRC] [CRT-D]"
    },
    {
     "CATALOG_KEY": "0055",
     "PRO_NOMBRE": "INSERCIÓN DE STENT(S) LIBERADOR DE FÁRMACO EN VASO PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "0056",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE SENSOR DE PRESIÓN IMPLANTABLE (ELECTRODO) PARA MONITORIZACIÓN HEMODINÁMICA INTRACARDIACA"
    },
    {
     "CATALOG_KEY": "0057",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE DISPOSITIVO SUBCUTÁNEO PARA MONITORIZACIÓN HEMODINÁMICA INTRACARDIACA"
    },
    {
     "CATALOG_KEY": "0058",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO PARA MONITORIZACIÓN DE PRESIÓN EN SACO INTRA-ANEURISMÁTICO (INTRAOPERATORIO)"
    },
    {
     "CATALOG_KEY": "0059",
     "PRO_NOMBRE": "MEDICIÓN DE PRESIÓN INTRAVASCULAR DE ARTERIAS CORONARIAS"
    },
    {
     "CATALOG_KEY": "006",
     "PRO_NOMBRE": "PROCEDIMIENTOS SOBRE VASOS SANGUÍNEOS"
    },
    {
     "CATALOG_KEY": "0061",
     "PRO_NOMBRE": "ANGIOPLASTIA O ATERECTOMÍA PERCUTÁNEA DE VASO(S) PRECEREBRAL(ES) (EXTRACRANEAL(ES))"
    },
    {
     "CATALOG_KEY": "0062",
     "PRO_NOMBRE": "ANGIOPLASTIA O ATERECTOMÍA PERCUTÁNEA DE VASO(S) INTRACRANEAL(ES)"
    },
    {
     "CATALOG_KEY": "0063",
     "PRO_NOMBRE": "INSERCIÓN PERCUTÁNEA DE STENT(S) DE ARTERIA CARÓTIDA"
    },
    {
     "CATALOG_KEY": "0064",
     "PRO_NOMBRE": "INSERCIÓN PERCUTÁNEA DE STENT(S) DE OTRA ARTERIA PRECEREBRAL (EXTRACRANEAL)"
    },
    {
     "CATALOG_KEY": "0065",
     "PRO_NOMBRE": "INSERCIÓN PERCUTÁNEA DE STENT(S) VASCULAR(ES) INTRACRANEAL(ES)"
    },
    {
     "CATALOG_KEY": "0066",
     "PRO_NOMBRE": "ANGIOPLASTIA CORONARIA PERCUTÁNEA TRANSLUMINAL (ACTP) [PTCA] O ATERECTOMÍA CORONARIA"
    },
    {
     "CATALOG_KEY": "0067",
     "PRO_NOMBRE": "MEDICIÓN DE PRESIÓN INTRAVASCULAR DE ARTERIAS INTRATORÁCICAS"
    },
    {
     "CATALOG_KEY": "0068",
     "PRO_NOMBRE": "MEDICIÓN DE PRESIÓN INTRAVASCULAR DE ARTERIAS PERIFÉRICAS"
    },
    {
     "CATALOG_KEY": "0069",
     "PRO_NOMBRE": "MEDICIÓN DE PRESIÓN INTRAVASCULAR DE OTROS VASOS ESPECIFICADOS Y LOS NO ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "007",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DE CADERA"
    },
    {
     "CATALOG_KEY": "0070",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE CADERA, COMPONENTES ACETABULAR Y FEMORAL"
    },
    {
     "CATALOG_KEY": "0071",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE CADERA, COMPONENTE ACETABULAR"
    },
    {
     "CATALOG_KEY": "0072",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE CADERA, COMPONENTE FEMORAL"
    },
    {
     "CATALOG_KEY": "0073",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE CADERA, SÓLO ALINEADOR ACETABULAR Y/O CABEZA FEMORAL"
    },
    {
     "CATALOG_KEY": "0074",
     "PRO_NOMBRE": "SUPERFICIE DE APOYO EN SUSTITUCIÓN DE CADERA, METAL SOBRE POLIETILENO"
    },
    {
     "CATALOG_KEY": "0075",
     "PRO_NOMBRE": "SUPERFICIE DE APOYO EN SUSTITUCIÓN DE CADERA, METAL SOBRE METAL"
    },
    {
     "CATALOG_KEY": "0076",
     "PRO_NOMBRE": "SUPERFICIE DE APOYO EN SUSTITUCIÓN DE CADERA, CERÁMICA SOBRE CERÁMICA"
    },
    {
     "CATALOG_KEY": "0077",
     "PRO_NOMBRE": "SUPERFICIE DE APOYO EN SUSTITUCIÓN DE CADERA, CERÁMICA SOBE POLIETILENO"
    },
    {
     "CATALOG_KEY": "008",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS SOBRE RODILLA Y CADERA"
    },
    {
     "CATALOG_KEY": "0080",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE RODILLA, TOTAL (TODOS LOS COMPONENTES)"
    },
    {
     "CATALOG_KEY": "0081",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE RODILLA, COMPONENTE TIBIAL"
    },
    {
     "CATALOG_KEY": "0082",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE RODILLA, COMPONENTE FEMORAL"
    },
    {
     "CATALOG_KEY": "0083",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE RODILLA, COMPONENTE ROTULIANO"
    },
    {
     "CATALOG_KEY": "0084",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN TOTAL DE RODILLA, INSERCIÓN (ALINEADOR) TIBIAL"
    },
    {
     "CATALOG_KEY": "0085",
     "PRO_NOMBRE": "RECUBRIMIENTO TOTAL DE CADERA, ACETÁBULO Y CABEZA FEMORAL"
    },
    {
     "CATALOG_KEY": "0086",
     "PRO_NOMBRE": "RECUBRIMIENTO PARCIAL DE CADERA, CABEZA FEMORAL"
    },
    {
     "CATALOG_KEY": "0087",
     "PRO_NOMBRE": "RECUBRIMIENTO PARCIAL DE CADERA, ACETÁBULO"
    },
    {
     "CATALOG_KEY": "009",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS E INTERVENCIONES"
    },
    {
     "CATALOG_KEY": "0091",
     "PRO_NOMBRE": "TRASPLANTE PROCEDENTE DE DONANTE VIVO EMPARENTADO"
    },
    {
     "CATALOG_KEY": "0092",
     "PRO_NOMBRE": "TRASPLANTE PROCEDENTE DE DONANTE VIVO NO EMPARENTADO"
    },
    {
     "CATALOG_KEY": "0093",
     "PRO_NOMBRE": "TRASPLANTE PROCEDENTE DE CADÁVER"
    },
    {
     "CATALOG_KEY": "0094",
     "PRO_NOMBRE": "MONITORIZACIÓN NEUROFISIOLÓGICA INTRAOPERATORIA"
    },
    {
     "CATALOG_KEY": "00A",
     "PRO_NOMBRE": "PROCEDIMIENTOS ASISTIDOS POR ROBÓTICA"
    },
    {
     "CATALOG_KEY": "00A1",
     "PRO_NOMBRE": "PROCEDIMIENTO ABIERTO ASISTIDO POR ROBÓTICA"
    },
    {
     "CATALOG_KEY": "00A2",
     "PRO_NOMBRE": "PROCEDIMIENTO LAPAROSCÓPICO ASISTIDO POR ROBÓTICA"
    },
    {
     "CATALOG_KEY": "00A3",
     "PRO_NOMBRE": "PROCEDIMIENTO PERCUTÁNEO ASISTIDO POR ROBÓTICA"
    },
    {
     "CATALOG_KEY": "00A4",
     "PRO_NOMBRE": "PROCEDIMIENTO ENDOSCÓPICO ASISTIDO POR ROBÓTICA"
    },
    {
     "CATALOG_KEY": "00A5",
     "PRO_NOMBRE": "PROCEDIMIENTO TORACOSCÓPICO ASISTIDO POR ROBÓTICA"
    },
    {
     "CATALOG_KEY": "00A9",
     "PRO_NOMBRE": "OTRO PROCEDIMIENTO ASISTIDO POR ROBÓTICA Y EL NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "00B",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE REHABILITACIÓN I"
    },
    {
     "CATALOG_KEY": "00B0",
     "PRO_NOMBRE": "POTENCIALES EVOCADOS AUDITIVOS"
    },
    {
     "CATALOG_KEY": "00B1",
     "PRO_NOMBRE": "POTENCIALES EVOCADOS SOMATOSENSORIALES"
    },
    {
     "CATALOG_KEY": "00B2",
     "PRO_NOMBRE": "POTENCIALES EVOCADOS MOTORES"
    },
    {
     "CATALOG_KEY": "00B3",
     "PRO_NOMBRE": "POTENCIALES EVOCADOS COGNITIVOS"
    },
    {
     "CATALOG_KEY": "00B4",
     "PRO_NOMBRE": "LABORATORIO DE ISOCINÉTICOS"
    },
    {
     "CATALOG_KEY": "00B5",
     "PRO_NOMBRE": "ENTRENAMIENTO EN EL PACIENTE AMPUTADO DE MIEMBRO INFERIOR, PREPROTÉSICA"
    },
    {
     "CATALOG_KEY": "00B6",
     "PRO_NOMBRE": "ENTRENAMIENTO EN EL PACIENTE AMPUTADO DE MIEMBRO INFERIOR, PROTÉSICA"
    },
    {
     "CATALOG_KEY": "00B7",
     "PRO_NOMBRE": "TINA DE HUBBARD"
    },
    {
     "CATALOG_KEY": "00B8",
     "PRO_NOMBRE": "NEUROFACILITACIÓN"
    },
    {
     "CATALOG_KEY": "00B9",
     "PRO_NOMBRE": "TENS"
    },
    {
     "CATALOG_KEY": "00C",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE REHABILITACIÓN II"
    },
    {
     "CATALOG_KEY": "00C0",
     "PRO_NOMBRE": "EJERCICIO ISOCINÉTICO"
    },
    {
     "CATALOG_KEY": "00C1",
     "PRO_NOMBRE": "TERAPIA CON LÁSER"
    },
    {
     "CATALOG_KEY": "00C2",
     "PRO_NOMBRE": "FLUIDOTERAPIA"
    },
    {
     "CATALOG_KEY": "00C3",
     "PRO_NOMBRE": "CRIOTERAPIA"
    },
    {
     "CATALOG_KEY": "00C4",
     "PRO_NOMBRE": "CORRIENTES INTERFERENCIALES"
    },
    {
     "CATALOG_KEY": "00C5",
     "PRO_NOMBRE": "TERAPIA OCUPACIONAL EN LESIONES DE MANO Y EXTREMIDAD SUPERIOR"
    },
    {
     "CATALOG_KEY": "00C6",
     "PRO_NOMBRE": "TERAPIA OCUPACIONAL EN PACIENTE PEDIÁTRICO"
    },
    {
     "CATALOG_KEY": "00C7",
     "PRO_NOMBRE": "TERAPIA OCUPACIONAL EN PACIENTE NEUROLÓGICO"
    },
    {
     "CATALOG_KEY": "00C8",
     "PRO_NOMBRE": "TERAPIA OCUPACIONAL: ADIESTRAMIENTO EN CUIDADO Y USO DE ÓRTESIS EN PACIENTES AMPUTADOS"
    },
    {
     "CATALOG_KEY": "00C9",
     "PRO_NOMBRE": "TERAPIA OCUPACIONAL: ADIESTRAMIENTO EN CUIDADO Y USO DE PRÓTESIS EN PACIENTES AMPUTADOS"
    },
    {
     "CATALOG_KEY": "00D",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE REHABILITACIÓN III"
    },
    {
     "CATALOG_KEY": "00D0",
     "PRO_NOMBRE": "GESTORÍA OCUPACIONAL"
    },
    {
     "CATALOG_KEY": "00D1",
     "PRO_NOMBRE": "TALLER DE ADIESTRAMIENTO DE ACTIVIDADES MÚLTIPLES"
    },
    {
     "CATALOG_KEY": "00D2",
     "PRO_NOMBRE": "ANÁLISIS DE PUESTO"
    },
    {
     "CATALOG_KEY": "00D3",
     "PRO_NOMBRE": "REACOMODO LABORAL"
    },
    {
     "CATALOG_KEY": "00D4",
     "PRO_NOMBRE": "VALORACIÓN DE CAPACIDAD RESIDUAL PARA PUESTO ESPECÍFICO"
    },
    {
     "CATALOG_KEY": "00D5",
     "PRO_NOMBRE": "VALORACIÓN DE FUNCIONALIDAD Y CAPACIDAD POTENCIAL PARA EL TRABAJO"
    },
    {
     "CATALOG_KEY": "00D6",
     "PRO_NOMBRE": "REHABILITACIÓN PARA EL TRABAJO"
    },
    {
     "CATALOG_KEY": "00D7",
     "PRO_NOMBRE": "PROYECTO MAGIA"
    },
    {
     "CATALOG_KEY": "00D8",
     "PRO_NOMBRE": "ULTRASONIDO TERAPEÚTICO"
    },
    {
     "CATALOG_KEY": "00D9",
     "PRO_NOMBRE": "BAÑO DE PARAFINA"
    },
    {
     "CATALOG_KEY": "00E0",
     "PRO_NOMBRE": "IRRADIACIÓN CON INFRARROJO"
    },
    {
     "CATALOG_KEY": "00E1",
     "PRO_NOMBRE": "COMPRESAS HÚMEDO CALIENTES"
    },
    {
     "CATALOG_KEY": "00E2",
     "PRO_NOMBRE": "ESTROBOSCOPÍA DE LARINGE"
    },
    {
     "CATALOG_KEY": "00F",
     "PRO_NOMBRE": "PROCEDIMIENTOS EN CONSULTORIO I"
    },
    {
     "CATALOG_KEY": "00F0",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE INFUSIÓN DE HIERRO"
    },
    {
     "CATALOG_KEY": "00F1",
     "PRO_NOMBRE": "AJUSTE DE APARATOS ORTOMAXILOFACIALES"
    },
    {
     "CATALOG_KEY": "00F2",
     "PRO_NOMBRE": "APLICACIÓN DE ORGANIL ÓTICO"
    },
    {
     "CATALOG_KEY": "00F3",
     "PRO_NOMBRE": "APLICACIÓN TÓPICA DE FLUOR"
    },
    {
     "CATALOG_KEY": "00F4",
     "PRO_NOMBRE": "BIOPSIA DE PERINÉ"
    },
    {
     "CATALOG_KEY": "00F5",
     "PRO_NOMBRE": "CARDIOTOCOGRAFÍA (PSS)"
    },
    {
     "CATALOG_KEY": "00F6",
     "PRO_NOMBRE": "COLOCACIÓN DE APARATOS ORTOMAXILOFACIALES"
    },
    {
     "CATALOG_KEY": "00F7",
     "PRO_NOMBRE": "COLOCACIÓN DE PARCHE DE CELULOSA"
    },
    {
     "CATALOG_KEY": "00F8",
     "PRO_NOMBRE": "CONTROL DE PLACA DENTOBACTERIANA"
    },
    {
     "CATALOG_KEY": "00F9",
     "PRO_NOMBRE": "CURACIÓN DE HERIDA"
    },
    {
     "CATALOG_KEY": "00G",
     "PRO_NOMBRE": "PROCEDIMIENTOS EN CONSULTORIO II"
    },
    {
     "CATALOG_KEY": "00G0",
     "PRO_NOMBRE": "DIÁLISIS ENTERAL"
    },
    {
     "CATALOG_KEY": "00G1",
     "PRO_NOMBRE": "DRENAJE DE ABSCESO DENTARIO"
    },
    {
     "CATALOG_KEY": "00G2",
     "PRO_NOMBRE": "ECOCARDIOGRAMA FETAL"
    },
    {
     "CATALOG_KEY": "00G3",
     "PRO_NOMBRE": "ECOCARDIOGRAMA MATERNO"
    },
    {
     "CATALOG_KEY": "00G4",
     "PRO_NOMBRE": "ELECTRONEUROGRAFÍA"
    },
    {
     "CATALOG_KEY": "00G5",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO EN CONDUCTO AUDITIVO INTERNO"
    },
    {
     "CATALOG_KEY": "00G6",
     "PRO_NOMBRE": "INFILTRACIÓN DE BLEOMICINA"
    },
    {
     "CATALOG_KEY": "00G7",
     "PRO_NOMBRE": "INTERPRETACIÓN DE ELECTROCADIOGRAMA"
    },
    {
     "CATALOG_KEY": "00G8",
     "PRO_NOMBRE": "LAVADO MECÁNICO DE CAVIDAD ORAL"
    },
    {
     "CATALOG_KEY": "00G9",
     "PRO_NOMBRE": "PRUEBA DE DILATADORES (HEGAR)"
    },
    {
     "CATALOG_KEY": "00H",
     "PRO_NOMBRE": "PROCEDIMIENTOS EN CONSULTORIO III"
    },
    {
     "CATALOG_KEY": "00H0",
     "PRO_NOMBRE": "PRUEBA DE MARSHALL"
    },
    {
     "CATALOG_KEY": "00H1",
     "PRO_NOMBRE": "PRUEBAS EPICUTÁNEAS"
    },
    {
     "CATALOG_KEY": "00H2",
     "PRO_NOMBRE": "PUVATERAPIA"
    },
    {
     "CATALOG_KEY": "00H3",
     "PRO_NOMBRE": "RETIRO DE TAPONAMIENTO NASAL"
    },
    {
     "CATALOG_KEY": "00H4",
     "PRO_NOMBRE": "TIMPANOMETRÍA"
    },
    {
     "CATALOG_KEY": "00H5",
     "PRO_NOMBRE": "TOMA DE CULTIVO DE EXUDADO DE HERIDA"
    },
    {
     "CATALOG_KEY": "00H6",
     "PRO_NOMBRE": "TOMA DE MUESTRA DE PAPANICOLAU CERVICAL"
    },
    {
     "CATALOG_KEY": "00H7",
     "PRO_NOMBRE": "TOMA DE MUESTRA DE TRACTO GENITAL FEMENINO"
    },
    {
     "CATALOG_KEY": "00H8",
     "PRO_NOMBRE": "INSTALACIÓN DE SONDA VESICAL"
    },
    {
     "CATALOG_KEY": "00H9",
     "PRO_NOMBRE": "RETIRO DE SONDA VESICAL"
    },
    {
     "CATALOG_KEY": "00I",
     "PRO_NOMBRE": "PRUEBAS NEONATALES"
    },
    {
     "CATALOG_KEY": "00I0",
     "PRO_NOMBRE": "TAMIZ METABÓLICO (NEONATAL)"
    },
    {
     "CATALOG_KEY": "00I1",
     "PRO_NOMBRE": "TAMIZ AUDITIVO (NEONATAL)"
    },
    {
     "CATALOG_KEY": "00J",
     "PRO_NOMBRE": "EXAMEN PARA COVID-19 (SARS-COV-2)"
    },
    {
     "CATALOG_KEY": "00J0",
     "PRO_NOMBRE": "ASPIRADO TRAQUEAL, ASPIRADO NASOFARÍNGEO O LAVADO NASAL PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J1",
     "PRO_NOMBRE": "BIOPSIA DE PULMÓN PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J2",
     "PRO_NOMBRE": "TEST DE DIAGNÓSTICO RÁPIDO (CROMATOGRAFÍA) PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J3",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO FARÍNGEO Y NASOFARÍNGEO (HISOPADO) PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J4",
     "PRO_NOMBRE": "LAVADO BRONQUIO ALVEOLAR PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J5",
     "PRO_NOMBRE": "PCR (“REACCIÓN EN CADENA DE LA POLIMERASA”) (TÉCNICA) PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J6",
     "PRO_NOMBRE": "TOMOGRAFÍA AXIAL COMPUTARIZADA (TAC) DE TÓRAX PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J8",
     "PRO_NOMBRE": "OTRO EXAMEN ESPECIFICADO PARA COVID-19"
    },
    {
     "CATALOG_KEY": "00J9",
     "PRO_NOMBRE": "EXAMEN (PRUEBA) PARA (COVID-19), NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "00K",
     "PRO_NOMBRE": "OTROS MÉTODOS ANTICONCEPTIVOS"
    },
    {
     "CATALOG_KEY": "00K1",
     "PRO_NOMBRE": "INYECCIÓN DE HORMONA PROGESTINA"
    },
    {
     "CATALOG_KEY": "00K2",
     "PRO_NOMBRE": "INYECCIÓN DE HORMONA ESTRÓGENO Y PROGESTERONA"
    },
    {
     "CATALOG_KEY": "00K3",
     "PRO_NOMBRE": "PARCHE (TRANSDÉRMICO) LIBERADOR DE HORMONAS PARA ANTICONCEPCIÓN"
    },
    {
     "CATALOG_KEY": "00K8",
     "PRO_NOMBRE": "OTRO MÉTODO ANTICONCEPTIVO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "00K9",
     "PRO_NOMBRE": "MÉTODO ANTICONCEPTIVO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "00L",
     "PRO_NOMBRE": "REHABILITACIÓN A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L0",
     "PRO_NOMBRE": "REHABILITACIÓN CON EL USO DE HIDROTERAPIA A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L1",
     "PRO_NOMBRE": "REHABILITACIÓN CON EL USO DE MECANOTERAPIA A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L2",
     "PRO_NOMBRE": "REHABILITACIÓN CON EL USO DE ULTRASONIDO A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L3",
     "PRO_NOMBRE": "REHABILITACIÓN CON EL USO DE LASER A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L4",
     "PRO_NOMBRE": "REHABILITACIÓN CON EL USO DE LUNIMOTERAPIA A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L5",
     "PRO_NOMBRE": "REHABILITACIÓN CON EL USO DE PARAFINA POST-COVID 19 A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L6",
     "PRO_NOMBRE": "REHABILITACIÓN CON APOYO ISOCINÉTICO O ROBÓTICOA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L7",
     "PRO_NOMBRE": "REHABILITACIÓN DE TRASTORNOS COGNITIVOS A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L8",
     "PRO_NOMBRE": "OTRA REHABILITACIÓN A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00L9",
     "PRO_NOMBRE": "REHABILITACIÓN A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19, NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "00M",
     "PRO_NOMBRE": "TERAPIA PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00M1",
     "PRO_NOMBRE": "TERAPIA DE LENGUAJE PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00M2",
     "PRO_NOMBRE": "TERAPIA Y REEDUCACIÓN DE LA VOZ Y DEGLUCIÓN PARA PERSONAS CON CONDICIÓN DE SALUD DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00M3",
     "PRO_NOMBRE": "TERAPIA PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19, CON SINTOMAS AUDITIVOS"
    },
    {
     "CATALOG_KEY": "00M4",
     "PRO_NOMBRE": "TERAPIA PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19, CON SINTOMAS VESTIBULARES"
    },
    {
     "CATALOG_KEY": "00M5",
     "PRO_NOMBRE": "TERAPIA DE MOVIMIENTO Y PATRONES DE COORDINACIÓN MOTORA PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00M6",
     "PRO_NOMBRE": "TERAPIA Y ENTRENAMIENTO DE LAS ACTIVIDADES DE LA VIDA DIARIA BÁSICAS E INSTRUMENTALES A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00M7",
     "PRO_NOMBRE": "TERAPIA DE EJERCICIOS FÍSICOS PARA REHABILITACIÓN A PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00M8",
     "PRO_NOMBRE": "OTRAS TERAPIAS PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00M9",
     "PRO_NOMBRE": "TERAPIA PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19, NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "00N",
     "PRO_NOMBRE": "MÉTODOS DE REHABILITACIÓN DE PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N1",
     "PRO_NOMBRE": "ENTRENAMIENTO DE LOS MÚSCULOS INSPIRATORIOS PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N2",
     "PRO_NOMBRE": "ESTIMULACIÓN ELÉCTRICA NEUROMUSCULAR PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N3",
     "PRO_NOMBRE": "ASESORAMIENTO PSICOSOCIAL PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N4",
     "PRO_NOMBRE": "EVALUACIÓN NUTRICIONAL Y ASESORAMIENTO PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N5",
     "PRO_NOMBRE": "PROGRAMA DE ENSEÑANZA PARA REALIZAR EN CASA TERAPIA DE REHABILITACIÓN PARA PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N6",
     "PRO_NOMBRE": "ENTRENAMIENTO Y CAPACITACIÓN A FAMILIARES O CUIDADOR DE PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N7",
     "PRO_NOMBRE": "ESTIMULACIÓN DE LA SENSO-PERCEPCIÓN Y LA PROPIOCEPCIÓN PARA LAS PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N8",
     "PRO_NOMBRE": "OTROS MÉTODOS PARA REHABILITACIÓN EN PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19"
    },
    {
     "CATALOG_KEY": "00N9",
     "PRO_NOMBRE": "MÉTODOS DE REHABILITACIÓN DE PERSONAS CON CONDICIÓN DE SALUD POSTERIOR A COVID-19, NO ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL SISTEMA NERVIOSO (01- 05)"
    },
    {
     "CATALOG_KEY": "01",
     "PRO_NOMBRE": "INCISIÓN Y EXCISIÓN DE CRÁNEO, CEREBRO Y MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "010",
     "PRO_NOMBRE": "PUNCIÓN CRANEAL"
    },
    {
     "CATALOG_KEY": "0101",
     "PRO_NOMBRE": "PUNCIÓN CISTERNAL"
    },
    {
     "CATALOG_KEY": "0102",
     "PRO_NOMBRE": "PUNCIÓN VENTRICULAR A TRAVÉS DE CATÉTER PREVIAMENTE IMPLANTADO"
    },
    {
     "CATALOG_KEY": "0109",
     "PRO_NOMBRE": "OTRA PUNCIÓN CRANEAL"
    },
    {
     "CATALOG_KEY": "011",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE CRÁNEO, CEREBRO Y MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0110",
     "PRO_NOMBRE": "MONITORIZACIÓN DE LA PRESIÓN INTRACRANEAL"
    },
    {
     "CATALOG_KEY": "0111",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [PERCUTÁNEA] DE MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0112",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE MENINGES CEREBRALES,"
    },
    {
     "CATALOG_KEY": "0113",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [PERCUTÁNEA] DE CEREBRO,"
    },
    {
     "CATALOG_KEY": "0114",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE CEREBRO"
    },
    {
     "CATALOG_KEY": "0115",
     "PRO_NOMBRE": "BIOPSIA DE CRÁNEO"
    },
    {
     "CATALOG_KEY": "0116",
     "PRO_NOMBRE": "MONITORIZACIÓN DE OXIGENACIÓN INTRACRANEAL"
    },
    {
     "CATALOG_KEY": "0117",
     "PRO_NOMBRE": "MONITORIZACIÓN DE LA TEMPERATURA DEL CEREBRO"
    },
    {
     "CATALOG_KEY": "0118",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE CEREBRO Y MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0119",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL CRÁNEO"
    },
    {
     "CATALOG_KEY": "012",
     "PRO_NOMBRE": "CRANEOTOMÍA Y CRANIECTOMÍA"
    },
    {
     "CATALOG_KEY": "0121",
     "PRO_NOMBRE": "INCISIÓN Y DRENAJE DE SENO CRANEAL"
    },
    {
     "CATALOG_KEY": "0122",
     "PRO_NOMBRE": "EXTRACCIÓN DE ELECTRODO (S) DE NEUROESTIMULADOR INTRACRANEAL"
    },
    {
     "CATALOG_KEY": "0123",
     "PRO_NOMBRE": "REAPERTURA DE CRANEOTOMÍA"
    },
    {
     "CATALOG_KEY": "0124",
     "PRO_NOMBRE": "OTRAS CRANEOTOMÍAS"
    },
    {
     "CATALOG_KEY": "0125",
     "PRO_NOMBRE": "OTRAS CRANIECTOMÍAS"
    },
    {
     "CATALOG_KEY": "0126",
     "PRO_NOMBRE": "INSERCIÓN DE CATÉTER(ES) EN CAVIDAD O TEJIDO CRANEAL"
    },
    {
     "CATALOG_KEY": "0127",
     "PRO_NOMBRE": "RETIRO DE CATÉTER(ES) DE CAVIDAD O TEJIDO CRANEAL"
    },
    {
     "CATALOG_KEY": "0128",
     "PRO_NOMBRE": "COLOCACIÓN DE CATÉTER(ES) INTRACEREBRAL(ES) A TRAVÉS DE AGUJERO(S) DE TRÉPANO"
    },
    {
     "CATALOG_KEY": "013",
     "PRO_NOMBRE": "INCISIÓN DE CEREBRO Y MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0131",
     "PRO_NOMBRE": "INCISIÓN DE MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0132",
     "PRO_NOMBRE": "LOBOTOMÍA Y TRACTOMÍA"
    },
    {
     "CATALOG_KEY": "0139",
     "PRO_NOMBRE": "OTRAS INCISIONES CEREBRALES"
    },
    {
     "CATALOG_KEY": "014",
     "PRO_NOMBRE": "OPERACIONES SOBRE TÁLAMO Y GLOBO PÁLIDO"
    },
    {
     "CATALOG_KEY": "0141",
     "PRO_NOMBRE": "OPERACIONES SOBRE TÁLAMO"
    },
    {
     "CATALOG_KEY": "0142",
     "PRO_NOMBRE": "OPERACIONES SOBRE GLOBO PÁLIDO"
    },
    {
     "CATALOG_KEY": "015",
     "PRO_NOMBRE": "OTRAS EXCISIONES O DESTRUCCIONES DE CEREBRO Y MENINGES"
    },
    {
     "CATALOG_KEY": "0151",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN O TEJIDO DE MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0152",
     "PRO_NOMBRE": "HEMISFERECTOMIA"
    },
    {
     "CATALOG_KEY": "0153",
     "PRO_NOMBRE": "LOBECTOMIA CEREBRAL"
    },
    {
     "CATALOG_KEY": "0159",
     "PRO_NOMBRE": "OTRAS EXCISIONES O DESTRUCCIONES DE LESIÓN O TEJIDO CEREBRAL"
    },
    {
     "CATALOG_KEY": "016",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN CRANEAL"
    },
    {
     "CATALOG_KEY": "016X",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN CRANEAL"
    },
    {
     "CATALOG_KEY": "02",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CRÁNEO, CEREBRO Y MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "020",
     "PRO_NOMBRE": "CRANEOPLASTIA"
    },
    {
     "CATALOG_KEY": "0201",
     "PRO_NOMBRE": "APERTURA DE SUTURA CRANEAL"
    },
    {
     "CATALOG_KEY": "0202",
     "PRO_NOMBRE": "ELEVACIÓN DE FRAGMENTOS DE FRACTURA DE CRÁNEO"
    },
    {
     "CATALOG_KEY": "0203",
     "PRO_NOMBRE": "FORMACIÓN DE PLIEGUE DE HUESO CRANEAL"
    },
    {
     "CATALOG_KEY": "0204",
     "PRO_NOMBRE": "INJERTO ÓSEO DE CRÁNEO"
    },
    {
     "CATALOG_KEY": "0205",
     "PRO_NOMBRE": "COLOCACIÓN DE PLACA CRANEAL (METÁLICA, ACRÍLICA)"
    },
    {
     "CATALOG_KEY": "0206",
     "PRO_NOMBRE": "OTRAS OSTEOPLASTIAS CRANEALES"
    },
    {
     "CATALOG_KEY": "0207",
     "PRO_NOMBRE": "EXTRACCIÓN DE PLACA CRANEAL"
    },
    {
     "CATALOG_KEY": "021",
     "PRO_NOMBRE": "REPARACIÓN DE MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0211",
     "PRO_NOMBRE": "SUTURA SIMPLE DE DURAMADRE CEREBRAL"
    },
    {
     "CATALOG_KEY": "0212",
     "PRO_NOMBRE": "OTRAS REPARACIONES DE MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0213",
     "PRO_NOMBRE": "LIGADURA DE VASO MENÍNGEO"
    },
    {
     "CATALOG_KEY": "0214",
     "PRO_NOMBRE": "PLEXECTOMÍA COROIDEA"
    },
    {
     "CATALOG_KEY": "022",
     "PRO_NOMBRE": "VENTRICULOSTOMÍA"
    },
    {
     "CATALOG_KEY": "022X",
     "PRO_NOMBRE": "VENTRICULOSTOMÍA"
    },
    {
     "CATALOG_KEY": "023",
     "PRO_NOMBRE": "DERIVACIÓN VENTRICULAR EXTRACRANEAL"
    },
    {
     "CATALOG_KEY": "0231",
     "PRO_NOMBRE": "DERIVACIÓN VENTRICULAR A ESTRUCTURAS EN CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "0232",
     "PRO_NOMBRE": "DERIVACIÓN VENTRICULAR AL APARATO CIRCULATORIO"
    },
    {
     "CATALOG_KEY": "0233",
     "PRO_NOMBRE": "DERIVACIÓN VENTRICULAR A LA CAVIDAD TORÁCICA"
    },
    {
     "CATALOG_KEY": "0234",
     "PRO_NOMBRE": "DERIVACIÓN VENTRICULAR A LA CAVIDAD Y ÓRGANOS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "0235",
     "PRO_NOMBRE": "DERIVACIÓN VENTRICULAR AL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "0239",
     "PRO_NOMBRE": "OTRAS OPERACIONES PARA ESTABLECER DRENAJES DE VENTRÍCULO"
    },
    {
     "CATALOG_KEY": "024",
     "PRO_NOMBRE": "REVISIÓN, EXTRACCIÓN E IRRIGACIÓN DE DERIVACIÓN VENTRICULAR"
    },
    {
     "CATALOG_KEY": "0241",
     "PRO_NOMBRE": "IRRIGACIÓN Y EXPLORACIÓN DE DERIVACIÓN VENTRICULAR"
    },
    {
     "CATALOG_KEY": "0242",
     "PRO_NOMBRE": "SUSTITUCIÓN DE DERIVACIÓN VENTRICULAR"
    },
    {
     "CATALOG_KEY": "0243",
     "PRO_NOMBRE": "EXTRACCIÓN DE DERIVACIÓN VENTRICULAR"
    },
    {
     "CATALOG_KEY": "029",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CRÁNEO, CEREBRO Y MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "0291",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS CORTICALES"
    },
    {
     "CATALOG_KEY": "0292",
     "PRO_NOMBRE": "REPARACIÓN CEREBRAL"
    },
    {
     "CATALOG_KEY": "0293",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE ELECTRODOS(S) DE NEUROESTIMULADOR INTRACRANEAL"
    },
    {
     "CATALOG_KEY": "0294",
     "PRO_NOMBRE": "COLOCACIÓN O SUSTITUCIÓN DE COMPÁS O DE HALO CRANEAL"
    },
    {
     "CATALOG_KEY": "0295",
     "PRO_NOMBRE": "EXTRACCIÓN DE COMPÁS O DE HALO CRANEAL"
    },
    {
     "CATALOG_KEY": "0296",
     "PRO_NOMBRE": "INSERCIÓN DE ELECTRODO ESFENOIDAL"
    },
    {
     "CATALOG_KEY": "0299",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE CRÁNEO, CEREBRO Y MENINGES CEREBRALES"
    },
    {
     "CATALOG_KEY": "03",
     "PRO_NOMBRE": "OPERACIONES SOBRE MÉDULA ESPINAL Y ESTRUCTURAS DEL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "030",
     "PRO_NOMBRE": "EXPLORACIÓN Y DESCOMPRESIÓN DE ESTRUCTURAS DEL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "0301",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DE CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "0302",
     "PRO_NOMBRE": "REAPERTURA DE SITIO DE LAMINECTOMÍA"
    },
    {
     "CATALOG_KEY": "0309",
     "PRO_NOMBRE": "OTRA EXPLORACIÓN Y DESCOMPRESIÓN DEL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "031",
     "PRO_NOMBRE": "DIVISIÓN DE RAÍZ DE NERVIO INTRAESPINAL"
    },
    {
     "CATALOG_KEY": "031X",
     "PRO_NOMBRE": "DIVISIÓN DE RAÍZ DE NERVIO INTRAESPINAL"
    },
    {
     "CATALOG_KEY": "032",
     "PRO_NOMBRE": "CORDOTOMÍA"
    },
    {
     "CATALOG_KEY": "0321",
     "PRO_NOMBRE": "CORDOTOMÍA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "0329",
     "PRO_NOMBRE": "OTRA CORDOTOMÍA"
    },
    {
     "CATALOG_KEY": "033",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA MÉDULA ESPINAL Y ESTRUCTURAS DEL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "0331",
     "PRO_NOMBRE": "PUNCIÓN ESPINAL"
    },
    {
     "CATALOG_KEY": "0332",
     "PRO_NOMBRE": "BIOPSIA DE MÉDULA ESPINAL O MENINGES ESPINALES"
    },
    {
     "CATALOG_KEY": "0339",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA MÉDULA ESPINAL Y ESTRUCTURAS DEL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "034",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA MÉDULA ESPINAL O DE MENINGES ESPINALES"
    },
    {
     "CATALOG_KEY": "034X",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA MÉDULA ESPINAL O DE MENINGES ESPINALES"
    },
    {
     "CATALOG_KEY": "035",
     "PRO_NOMBRE": "OPERACIONES PLÁSTICAS SOBRE ESTRUCTURAS DE LA MÉDULA ESPINAL"
    },
    {
     "CATALOG_KEY": "0351",
     "PRO_NOMBRE": "REPARACIÓN DE MININGOCELE ESPINAL"
    },
    {
     "CATALOG_KEY": "0352",
     "PRO_NOMBRE": "REPARACIÓN DE MIELOMENINGOCELE ESPINAL"
    },
    {
     "CATALOG_KEY": "0353",
     "PRO_NOMBRE": "REPARACIÓN DE FRACTURA VERTEBRAL"
    },
    {
     "CATALOG_KEY": "0359",
     "PRO_NOMBRE": "OTRAS REPARACIONES DE OPERACIONES PLÁSTICAS SOBRE ESTRUCTURAS DEL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "036",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE MÉDULA ESPINAL Y RAÍCES DE NERVIOS ESPINALES"
    },
    {
     "CATALOG_KEY": "036X",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE MÉDULA ESPINAL Y RAÍCES DE NERVIOS ESPINALES"
    },
    {
     "CATALOG_KEY": "037",
     "PRO_NOMBRE": "DERIVACIÓN DE TECA ESPINAL"
    },
    {
     "CATALOG_KEY": "0371",
     "PRO_NOMBRE": "DERIVACIÓN SUBARACNOIDE-PERITONEAL ESPINAL"
    },
    {
     "CATALOG_KEY": "0372",
     "PRO_NOMBRE": "DERIVACIÓN SUBARACNOIDE-URETERAL ESPINAL"
    },
    {
     "CATALOG_KEY": "0379",
     "PRO_NOMBRE": "OTRA DERIVACIÓN DE TECA ESPINAL"
    },
    {
     "CATALOG_KEY": "038",
     "PRO_NOMBRE": "INYECCIÓN DE AGENTE DESTRUCTIVO EN EL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "038X",
     "PRO_NOMBRE": "INYECCIÓN DE AGENTE DESTRUCTIVO EN EL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "039",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA MÉDULA ESPINAL Y ESTRUCTURAS DEL CONDUCTO ESPINAL"
    },
    {
     "CATALOG_KEY": "0390",
     "PRO_NOMBRE": "INSERCIÓN DE CATÉTER EN CONDUCTO ESPINAL PARA INFUSIÓN DE SUSTANCIAS TERAPÉUTICAS O PALIATIVAS"
    },
    {
     "CATALOG_KEY": "0391",
     "PRO_NOMBRE": "INYECCIÓN DE ANESTÉSICO EN EL CONDUCTO ESPINAL PARA ANALGESIA"
    },
    {
     "CATALOG_KEY": "0392",
     "PRO_NOMBRE": "INYECCIÓN DE OTRO AGENTE EN CANAL ESPINAL"
    },
    {
     "CATALOG_KEY": "0393",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE ELECRODO(S) DE NEUROESTIMULADOR MEDULAR"
    },
    {
     "CATALOG_KEY": "0394",
     "PRO_NOMBRE": "EXTRACCIÓN DE ELECTRODO(S) DE NEUROESTIMULADOR ESPINAL"
    },
    {
     "CATALOG_KEY": "0395",
     "PRO_NOMBRE": "PARCHE HEMÁTICO ESPINAL"
    },
    {
     "CATALOG_KEY": "0396",
     "PRO_NOMBRE": "DENERVACIÓN PERCUTÁNEA DE FACETA"
    },
    {
     "CATALOG_KEY": "0397",
     "PRO_NOMBRE": "REVISIÓN DE DERIVACIÓN DE TECA ESPINAL"
    },
    {
     "CATALOG_KEY": "0398",
     "PRO_NOMBRE": "EXTRACCIÓN DE DERIVACIÓN DE TECA ESPINAL"
    },
    {
     "CATALOG_KEY": "0399",
     "PRO_NOMBRE": "OTRA OPERACIONES SOBRE LA MÉDULA ESPINAL Y ESTRUCTURAS DEL CONDUCTO ESPINAL ESPINAL"
    },
    {
     "CATALOG_KEY": "04",
     "PRO_NOMBRE": "OPERACIONES SOBRE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "040",
     "PRO_NOMBRE": "INCISIÓN, DIVISIÓN Y EXCISIÓN DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0401",
     "PRO_NOMBRE": "EXCISIÓN DE NEUROMA ACÚSTICO"
    },
    {
     "CATALOG_KEY": "0402",
     "PRO_NOMBRE": "SECCIÓN DE NERVIO TRIGÉMINO"
    },
    {
     "CATALOG_KEY": "0403",
     "PRO_NOMBRE": "SECCIÓN APLASTAMIENTO DE OTROS NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0404",
     "PRO_NOMBRE": "OTRAS INCISIONES DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0405",
     "PRO_NOMBRE": "GANGLIONECTOMÍA DE GASSER"
    },
    {
     "CATALOG_KEY": "0406",
     "PRO_NOMBRE": "OTRAS GANGLIONECTOMÍAS CRANEALES O PERIFÉRICAS"
    },
    {
     "CATALOG_KEY": "0407",
     "PRO_NOMBRE": "OTRAS EXCISIONES O AVULSIONES DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "041",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL SISTEMA NERVIOSO PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "0411",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [PERCUTÁNEA] DE NERVIO O GANGLIO CRANEAL O SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "0412",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE NERVIO O GANGLIO CRANEAL O PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "0419",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE NERVIOS Y GLANGLIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "042",
     "PRO_NOMBRE": "DESTRUCCIÓN DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "042X",
     "PRO_NOMBRE": "DESTRUCCIÓN DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "043",
     "PRO_NOMBRE": "SUTURA DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "043X",
     "PRO_NOMBRE": "SUTURA DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "044",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS Y DESCOMPRESIÓN DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0441",
     "PRO_NOMBRE": "DESCOMPRESIÓN DE RAÍZ DE NERVIO TRIGÉMINO"
    },
    {
     "CATALOG_KEY": "0442",
     "PRO_NOMBRE": "DESCOMPRESIÓN DE OTRO NERVIO CRANEAL"
    },
    {
     "CATALOG_KEY": "0443",
     "PRO_NOMBRE": "LIBERACIÓN DE TÚNEL CARPIANO"
    },
    {
     "CATALOG_KEY": "0444",
     "PRO_NOMBRE": "LIBERACIÓN DE TÚNEL TARSAL"
    },
    {
     "CATALOG_KEY": "0449",
     "PRO_NOMBRE": "OTRA DESCOMPRESIÓN O LISIS DE ADHERENCIA DE NERVIO O GANGLIO PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "045",
     "PRO_NOMBRE": "INJERTO DE NERVIO CRANEAL O PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "045X",
     "PRO_NOMBRE": "INJERTO DE NERVIO CRANEAL O PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "046",
     "PRO_NOMBRE": "TRANSPOSICIÓN DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "046X",
     "PRO_NOMBRE": "TRANSPOSICIÓN DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "047",
     "PRO_NOMBRE": "OTRA NEUROPLASTIA CRANEAL O PERIFÉRICA"
    },
    {
     "CATALOG_KEY": "0471",
     "PRO_NOMBRE": "ANASTOMOSIS HIPOGLOSO-FACIAL"
    },
    {
     "CATALOG_KEY": "0472",
     "PRO_NOMBRE": "ANASTOMOSIS ACCESORIO-FACIAL"
    },
    {
     "CATALOG_KEY": "0473",
     "PRO_NOMBRE": "ANASTOMOSIS ACCESORIO-HIPOGLOSA"
    },
    {
     "CATALOG_KEY": "0474",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS DE NERVIO CRANEAL O PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "0475",
     "PRO_NOMBRE": "REVISIÓN DE REPARACIÓN ANTERIOR DE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0476",
     "PRO_NOMBRE": "REPARACIÓN DE HERIDA TRAUMÁTICA ANTIGUA DE CRÁNEO Y NERVIOS PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0479",
     "PRO_NOMBRE": "OTRAS NEUROPLASTIAS"
    },
    {
     "CATALOG_KEY": "048",
     "PRO_NOMBRE": "INYECCIÓN DENTRO DE NERVIO PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "0480",
     "PRO_NOMBRE": "INYECCIÓN DE NERVIO CRANEAL O PERIFÉRICO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "0481",
     "PRO_NOMBRE": "INYECCIÓN DE ANESTESIA DENTRO DE NERVIO CRANEAL O PERIFÉRICO CON FINES ANALGÉSICOS"
    },
    {
     "CATALOG_KEY": "0489",
     "PRO_NOMBRE": "INYECCIÓN DE OTRO AGENTE EXCEPTO NEUROLÍTICO"
    },
    {
     "CATALOG_KEY": "049",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "0491",
     "PRO_NOMBRE": "NEURECTASIA"
    },
    {
     "CATALOG_KEY": "0492",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE ELECTRODO(S) DE NEUROESTIMULADOR PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "0493",
     "PRO_NOMBRE": "EXTRACCIÓN DE ELECTRODO(S) DE NEUROESTIMULADOR PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "0499",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE NERVIOS CRANEALES Y PERIFÉRICOS"
    },
    {
     "CATALOG_KEY": "05",
     "PRO_NOMBRE": "OPERACIONES SOBRE NERVIOS O GANGLIOS SIMPÁTICOS"
    },
    {
     "CATALOG_KEY": "050",
     "PRO_NOMBRE": "DIVISIÓN DE NERVIO O GANGLIO SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "050X",
     "PRO_NOMBRE": "DIVISIÓN DE NERVIO O GANGLIO SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "051",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE NERVIOS O GANGLIOS SIMPÁTICOS"
    },
    {
     "CATALOG_KEY": "0511",
     "PRO_NOMBRE": "BIOPSIA DE NERVIO O GANGLIO SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "0519",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE NERVIOS O GANGLIOS SIMPÁTICOS"
    },
    {
     "CATALOG_KEY": "052",
     "PRO_NOMBRE": "SIMPATECTOMÍA"
    },
    {
     "CATALOG_KEY": "0521",
     "PRO_NOMBRE": "GANGLIONECTOMÍA ESFENOPALATINA"
    },
    {
     "CATALOG_KEY": "0522",
     "PRO_NOMBRE": "SIMPATECTOMÍA CERVICAL"
    },
    {
     "CATALOG_KEY": "0523",
     "PRO_NOMBRE": "SIMPATECTOMÍA LUMBAR"
    },
    {
     "CATALOG_KEY": "0524",
     "PRO_NOMBRE": "SIMPATECTOMÍA PRESACRAL"
    },
    {
     "CATALOG_KEY": "0525",
     "PRO_NOMBRE": "SIMPATECTOMÍA PERIARTERIAL"
    },
    {
     "CATALOG_KEY": "0529",
     "PRO_NOMBRE": "OTRAS SIMPATECTOMÍAS Y GANGLIONECTOMÍAS"
    },
    {
     "CATALOG_KEY": "053",
     "PRO_NOMBRE": "INYECCIÓN EN NERVIO O GANGLIO SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "0531",
     "PRO_NOMBRE": "INYECCIÓN DE ANESTÉSICO EN NERVIO SIMPÁTICO CON FINES ANALGÉSICOS"
    },
    {
     "CATALOG_KEY": "0532",
     "PRO_NOMBRE": "INYECCIÓN DE AGENTE NEUROLÍTICO EN NERVIO SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "0539",
     "PRO_NOMBRE": "OTRA INYECCIÓN EN NERVIO O GANGLIO SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "058",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE NERVIOS O GANGLIOS SIMPÁTICOS"
    },
    {
     "CATALOG_KEY": "0581",
     "PRO_NOMBRE": "REPARACIÓN DE NERVIO O GANGLIO SIMPÁTICO"
    },
    {
     "CATALOG_KEY": "0589",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE NERVIOS O GANGLIOS LINFÁTICOS"
    },
    {
     "CATALOG_KEY": "059",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL SISTEMA NERVIOSO"
    },
    {
     "CATALOG_KEY": "059X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL SISTEMA NERVIOSO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL SISTEMA ENDOCRINO (06 - 07)"
    },
    {
     "CATALOG_KEY": "06",
     "PRO_NOMBRE": "OPERACIONES SOBRE LAS GLÁNDULAS TIROIDES Y PARATIROIDES"
    },
    {
     "CATALOG_KEY": "060",
     "PRO_NOMBRE": "INCISIÓN SOBRE EL ÁREA TIROIDEA"
    },
    {
     "CATALOG_KEY": "0601",
     "PRO_NOMBRE": "ASPIRACIÓN DEL ÁREA TIROIDEA"
    },
    {
     "CATALOG_KEY": "0602",
     "PRO_NOMBRE": "REAPERTURA DE HERIDA DE ÁREA TIROIDEA"
    },
    {
     "CATALOG_KEY": "0609",
     "PRO_NOMBRE": "OTRA INCISIÓN DEL ÁREA TIROIDEA"
    },
    {
     "CATALOG_KEY": "061",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE GLÁNDULAS PARATIROIDES Y TIROIDES"
    },
    {
     "CATALOG_KEY": "0611",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [PERCUTÁNEA] DE GLÁNDULA TIROIDES"
    },
    {
     "CATALOG_KEY": "0612",
     "PRO_NOMBRE": "OTRA BIOPSIA DE GLÁNDULA TIROIDES"
    },
    {
     "CATALOG_KEY": "0613",
     "PRO_NOMBRE": "BIOPSIA DE GLÁNDULA PARATIROIDES"
    },
    {
     "CATALOG_KEY": "0619",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE GLÁNDULAS TIROIDES Y PARATIROIDES"
    },
    {
     "CATALOG_KEY": "062",
     "PRO_NOMBRE": "LOBECTOMÍA TIROIDEA UNILATERAL"
    },
    {
     "CATALOG_KEY": "062X",
     "PRO_NOMBRE": "LOBECTOMÍA TIROIDEA UNILATERAL"
    },
    {
     "CATALOG_KEY": "063",
     "PRO_NOMBRE": "OTRA TIROIDECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "0631",
     "PRO_NOMBRE": "EXTIRPACIÓN DE LESIÓN DE TIROIDES"
    },
    {
     "CATALOG_KEY": "0639",
     "PRO_NOMBRE": "OTRA TIROIDECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "064",
     "PRO_NOMBRE": "TIROIDECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "064X",
     "PRO_NOMBRE": "TIROIDECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "065",
     "PRO_NOMBRE": "TIROIDECTOMÍA RETROESTERNAL"
    },
    {
     "CATALOG_KEY": "0650",
     "PRO_NOMBRE": "TIROIDECTOMÍA RETROESTERNAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "0651",
     "PRO_NOMBRE": "TIROIDECTOMÍA RETROESTERNAL PARCIAL"
    },
    {
     "CATALOG_KEY": "0652",
     "PRO_NOMBRE": "TIROIDECTOMÍA RETROESTERNAL TOTAL"
    },
    {
     "CATALOG_KEY": "066",
     "PRO_NOMBRE": "EXTIRPACIÓN DE TIROIDES LINGUAL"
    },
    {
     "CATALOG_KEY": "066X",
     "PRO_NOMBRE": "EXTIRPACIÓN DE TIROIDES LINGUAL"
    },
    {
     "CATALOG_KEY": "067",
     "PRO_NOMBRE": "EXTIRPACIÓN DEL CONDUCTO TIROGLOSO"
    },
    {
     "CATALOG_KEY": "067X",
     "PRO_NOMBRE": "EXTIRPACIÓN DEL CONDUCTO TIROGLOSO"
    },
    {
     "CATALOG_KEY": "068",
     "PRO_NOMBRE": "PARATIROIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "0681",
     "PRO_NOMBRE": "PARATIROIDECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "0689",
     "PRO_NOMBRE": "OTRA PARATIROIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "069",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TIROIDES (REGIÓN) Y PARATIROIDES"
    },
    {
     "CATALOG_KEY": "0691",
     "PRO_NOMBRE": "DIVISIÓN DEL ISTMO TIROIDEO"
    },
    {
     "CATALOG_KEY": "0692",
     "PRO_NOMBRE": "LIGADURA DE VASOS TIROIDEOS"
    },
    {
     "CATALOG_KEY": "0693",
     "PRO_NOMBRE": "SUTURA DE GLÁNDULA TIROIDES"
    },
    {
     "CATALOG_KEY": "0694",
     "PRO_NOMBRE": "REIMPLANTACIÓN DE TEJIDOS TIROIDEOS"
    },
    {
     "CATALOG_KEY": "0695",
     "PRO_NOMBRE": "REIMPLANTACIÓN DE TEJIDO PARATIROIDEO"
    },
    {
     "CATALOG_KEY": "0698",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLÁNDULAS TIROIDES"
    },
    {
     "CATALOG_KEY": "0699",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLÁNDULAS PARATIROIDES"
    },
    {
     "CATALOG_KEY": "07",
     "PRO_NOMBRE": "OPERACIONES SOBRE OTRAS GLÁNDULAS ENDOCRINAS"
    },
    {
     "CATALOG_KEY": "070",
     "PRO_NOMBRE": "EXPLORACIÓN DEL ÁREA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0700",
     "PRO_NOMBRE": "EXPLORACIÓN DE ÁREA SUPRARRENAL, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "0701",
     "PRO_NOMBRE": "EXPLORACIÓN UNILATERAL DEL ÁREA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0702",
     "PRO_NOMBRE": "EXPLORACIÓN BILATERAL DEL ÁREA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "071",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE GLÁNDULAS SUPRARRENALES, GLÁNDULA PITUITARIA,"
    },
    {
     "CATALOG_KEY": "0711",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [PERCUTÁNEA] DE GLÁNDULA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0712",
     "PRO_NOMBRE": "OTRA BIOPSIA DE GLÁNDULA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0713",
     "PRO_NOMBRE": "BIOPSIA DE GLÁNDULA PITUITARIA, ACCESO TRANSFRONTAL"
    },
    {
     "CATALOG_KEY": "0714",
     "PRO_NOMBRE": "BIOPSIA DE GLÁNDULA PITUITARIA, ACCESO TRANSESFENOIDAL"
    },
    {
     "CATALOG_KEY": "0715",
     "PRO_NOMBRE": "BIOPSIA DE GLÁNDULA PITUITARIA, ACCESO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "0716",
     "PRO_NOMBRE": "BIOPSIA DE TIMO"
    },
    {
     "CATALOG_KEY": "0717",
     "PRO_NOMBRE": "BIOPSIA DE GLÁNDULA PINEAL"
    },
    {
     "CATALOG_KEY": "0719",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE GLÁNDULAS SUPRARRENALES, GLÁNDULA PITUITARIA, GLÁNDULA PINEAL Y TIMO"
    },
    {
     "CATALOG_KEY": "072",
     "PRO_NOMBRE": "SUPRARRENALECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "0721",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE GLÁNDULA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0722",
     "PRO_NOMBRE": "SUPRARRENALECTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "0729",
     "PRO_NOMBRE": "OTRA SUPRARRENALECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "073",
     "PRO_NOMBRE": "SUPRARRENALECTOMÍA BILATERAL"
    },
    {
     "CATALOG_KEY": "073X",
     "PRO_NOMBRE": "SUPRARRENALECTOMÍA BILATERAL"
    },
    {
     "CATALOG_KEY": "074",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLÁNDULAS, NERVIOS, Y VASOS SUPRARRENALES"
    },
    {
     "CATALOG_KEY": "0741",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0742",
     "PRO_NOMBRE": "DIVISIÓN DE NERVIOS AFERENTES CON GLÁNDULAS SUPRARRENALES"
    },
    {
     "CATALOG_KEY": "0743",
     "PRO_NOMBRE": "LIGADURA DE VASOS SUPRARRENALES"
    },
    {
     "CATALOG_KEY": "0744",
     "PRO_NOMBRE": "REPARACIÓN DE GLÁNDULA SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0745",
     "PRO_NOMBRE": "REIMPLANTACIÓN DE TEJIDO SUPRARRENAL"
    },
    {
     "CATALOG_KEY": "0749",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE GLÁNDULAS, NERVIOS Y VASOS SUPRARRENALES"
    },
    {
     "CATALOG_KEY": "075",
     "PRO_NOMBRE": "OPERACIONES SOBRE GLÁNDULA PINEAL"
    },
    {
     "CATALOG_KEY": "0751",
     "PRO_NOMBRE": "EXPLORACIÓN DEL ÁREA PINEAL"
    },
    {
     "CATALOG_KEY": "0752",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA PINEAL"
    },
    {
     "CATALOG_KEY": "0753",
     "PRO_NOMBRE": "EXCISIÓN PARCIAL DE GLÁNDULA PINEAL"
    },
    {
     "CATALOG_KEY": "0754",
     "PRO_NOMBRE": "EXCISIÓN TOTAL DE GLÁNDULA PINEAL"
    },
    {
     "CATALOG_KEY": "0759",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLÁNDULA PINEAL"
    },
    {
     "CATALOG_KEY": "076",
     "PRO_NOMBRE": "HIPOFISECTOMÍA"
    },
    {
     "CATALOG_KEY": "0761",
     "PRO_NOMBRE": "EXCISIÓN PARCIAL DE GLÁNDULA PITUITARIA, ACCESO TRANSFRONTAL"
    },
    {
     "CATALOG_KEY": "0762",
     "PRO_NOMBRE": "EXCISIÓN PARCIAL DE GLÁNDULA PITUITARIA, ACCESO TRANSESFENOIDAL"
    },
    {
     "CATALOG_KEY": "0763",
     "PRO_NOMBRE": "EXCISIÓN PARCIAL DE GLÁNDULA PITUITARIA, ACCESO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "0764",
     "PRO_NOMBRE": "EXCISIÓN TOTAL DE GLÁNDULA PITUITARIA, ACCESO TRANSFRONTAL"
    },
    {
     "CATALOG_KEY": "0765",
     "PRO_NOMBRE": "EXCISIÓN TOTAL DE GLÁNDULA PITUITARIA, ACCESO TRANSESFENOIDAL"
    },
    {
     "CATALOG_KEY": "0768",
     "PRO_NOMBRE": "EXCISIÓN TOTAL DE GLÁNDULA PITUITARIA, OTRO ACCESO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "0769",
     "PRO_NOMBRE": "EXCISIÓN TOTAL DE GLÁNDULA PITUITARIA, ACCESO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "077",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE HIPÓFISIS"
    },
    {
     "CATALOG_KEY": "0771",
     "PRO_NOMBRE": "EXPLORACIÓN DE FOSA PITUITARIA"
    },
    {
     "CATALOG_KEY": "0772",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA PITUITARIA"
    },
    {
     "CATALOG_KEY": "0779",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE HIPÓFISIS"
    },
    {
     "CATALOG_KEY": "078",
     "PRO_NOMBRE": "TIMECTOMÍA"
    },
    {
     "CATALOG_KEY": "0780",
     "PRO_NOMBRE": "TIMECTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "0781",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN PARCIAL DE TIMO"
    },
    {
     "CATALOG_KEY": "0782",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN TOTAL DE TIMO"
    },
    {
     "CATALOG_KEY": "0783",
     "PRO_NOMBRE": "EXTIRPACIÓN PARCIAL DEL TIMO POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "0784",
     "PRO_NOMBRE": "EXTIRPACIÓN TOTAL DEL TIMO POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "079",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL TIMO"
    },
    {
     "CATALOG_KEY": "0791",
     "PRO_NOMBRE": "EXPLORACIÓN DEL ÁREA TÍMICA"
    },
    {
     "CATALOG_KEY": "0792",
     "PRO_NOMBRE": "OTRA INCISIÓN DE TIMO"
    },
    {
     "CATALOG_KEY": "0793",
     "PRO_NOMBRE": "REPARACIÓN DE TIMO"
    },
    {
     "CATALOG_KEY": "0794",
     "PRO_NOMBRE": "TRANSPLANTE DE TIMO"
    },
    {
     "CATALOG_KEY": "0795",
     "PRO_NOMBRE": "INCISIÓN DE TIMO POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "0798",
     "PRO_NOMBRE": "OTRAS OPERACIONES Y LAS NO ESPECIFICADAS DEL TIMO POR TORACOSCOPIA"
    },
    {
     "CATALOG_KEY": "0799",
     "PRO_NOMBRE": "OTRAS OPERACIÓNES Y LAS NO ESPECIFICADAS DEL TIMO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL OJO (08 - 16)"
    },
    {
     "CATALOG_KEY": "08",
     "PRO_NOMBRE": "OPERACIONES SOBRE PÁRPADOS"
    },
    {
     "CATALOG_KEY": "080",
     "PRO_NOMBRE": "INCISIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0801",
     "PRO_NOMBRE": "INCISIÓN DEL MARGEN DEL PÁRPADO"
    },
    {
     "CATALOG_KEY": "0802",
     "PRO_NOMBRE": "SECCIONADO DE BLEFARORRAFIA"
    },
    {
     "CATALOG_KEY": "0809",
     "PRO_NOMBRE": "OTRA INCISIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "081",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0811",
     "PRO_NOMBRE": "BIOPSIA DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0819",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PÁRPADO"
    },
    {
     "CATALOG_KEY": "082",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0820",
     "PRO_NOMBRE": "ELIMINACIÓN DE LESIÓN DE PÁRPADO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "0821",
     "PRO_NOMBRE": "EXCISIÓN DE CHALAZIÓN"
    },
    {
     "CATALOG_KEY": "0822",
     "PRO_NOMBRE": "EXCISIÓN DE OTRA LESIÓN MENOR DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0823",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN IMPORTANTE DE PÁRPADO, ESPESOR PARCIAL"
    },
    {
     "CATALOG_KEY": "0824",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN IMPORTANTE DE PÁRPADO, ESPESOR TOTAL"
    },
    {
     "CATALOG_KEY": "0825",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "083",
     "PRO_NOMBRE": "REPARACIÓN DE BLEFAROPTOSIS Y RETRACCIÓN DEL PÁRPADO"
    },
    {
     "CATALOG_KEY": "0831",
     "PRO_NOMBRE": "REPARACIÓN DE BLEFAROPTOSIS POR TÉCNICA DE MÚSCULO FRONTAL CON SUTURA"
    },
    {
     "CATALOG_KEY": "0832",
     "PRO_NOMBRE": "REPARACIÓN DE BLEFAROPTOSIS POR TÉCNICA DE MÚSCULO FRONTAL CON SUSPENSIÓN FACIAL"
    },
    {
     "CATALOG_KEY": "0833",
     "PRO_NOMBRE": "REPARACIÓN DE BLEFAROPTOSIS POR RESECCIÓN O AVANCE DE MÚSCULO ELEVADOR O APONEUROSIS"
    },
    {
     "CATALOG_KEY": "0834",
     "PRO_NOMBRE": "REPARACIÓN DE BLEFAROPTOSIS POR OTRAS TÉCNICAS DEL MÚSCULO ELEVADOR"
    },
    {
     "CATALOG_KEY": "0835",
     "PRO_NOMBRE": "REPARACIÓN DE BLEFAROPTOSIS POR OTRA TÉCNICA TARSIANA"
    },
    {
     "CATALOG_KEY": "0836",
     "PRO_NOMBRE": "REPARACIÓN DE BLEFAROPTOSIS POR OTRAS TÉCNICAS"
    },
    {
     "CATALOG_KEY": "0837",
     "PRO_NOMBRE": "REDUCCIÓN DE CORRECCIÓN EXCESIVA DE PTOSIS"
    },
    {
     "CATALOG_KEY": "0838",
     "PRO_NOMBRE": "CORRECCIÓN DE RETRACCIÓN DEL PÁRPADO"
    },
    {
     "CATALOG_KEY": "084",
     "PRO_NOMBRE": "REPARACIÓN DE ENTROPIÓN O ECTROPIÓN"
    },
    {
     "CATALOG_KEY": "0841",
     "PRO_NOMBRE": "REPARACIÓN DE ENTROPIÓN O ECTROPIÓN POR TERMOCAUTERIZACIÓN"
    },
    {
     "CATALOG_KEY": "0842",
     "PRO_NOMBRE": "REPARACIÓN DE ENTROPIÓN O ECTROPIÓN POR TÉCNICA DE SUTURA"
    },
    {
     "CATALOG_KEY": "0843",
     "PRO_NOMBRE": "REPARACIÓN DE ENTROPIÓN O ECTROPIÓN CON RESECCIÓN EN CUÑA"
    },
    {
     "CATALOG_KEY": "0844",
     "PRO_NOMBRE": "REPARACIÓN DE ENTROPIÓN O ECTROPIÓN CON RECONSTRUCCIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0849",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ENTROPIÓN O ECTROPIÓN"
    },
    {
     "CATALOG_KEY": "085",
     "PRO_NOMBRE": "OTRO AJUSTE DE POSICIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0851",
     "PRO_NOMBRE": "CANTOTOMÍA"
    },
    {
     "CATALOG_KEY": "0852",
     "PRO_NOMBRE": "BLEFARORRAFIA"
    },
    {
     "CATALOG_KEY": "0859",
     "PRO_NOMBRE": "OTRO AJUSTE DE POSICIÓN DEL PÁRPADO"
    },
    {
     "CATALOG_KEY": "086",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO CON COLGAJO O INJERTO"
    },
    {
     "CATALOG_KEY": "0861",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO CON COLGAJO O INJERTO DE PIEL"
    },
    {
     "CATALOG_KEY": "0862",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO CON COLGAJO O INJERTO DE MEMBRANA MUCOSA"
    },
    {
     "CATALOG_KEY": "0863",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO CON INJERTO DE FOLÍCULO PILOSO"
    },
    {
     "CATALOG_KEY": "0864",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO CON COLGAJO TARSOCONJUNTIVAL"
    },
    {
     "CATALOG_KEY": "0869",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE PÁRPADO CON COLGAJOS O INJERTOS"
    },
    {
     "CATALOG_KEY": "087",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0870",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "0871",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO, CON IMPLICACIÓN DE MARGEN DE PÁRPADO, GROSOR PARCIAL"
    },
    {
     "CATALOG_KEY": "0872",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE PÁRPADO, GROSOR PARCIAL"
    },
    {
     "CATALOG_KEY": "0873",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PÁRPADO, CON IMPLICACIÓN DE MARGEN DEL PÁRPADO, GROSOR TOTAL"
    },
    {
     "CATALOG_KEY": "0874",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DEL PÁRPADO, GROSOR TOTAL"
    },
    {
     "CATALOG_KEY": "088",
     "PRO_NOMBRE": "OTRA REPARACIÓN DEL PÁRPADO"
    },
    {
     "CATALOG_KEY": "0881",
     "PRO_NOMBRE": "REPARACIÓN LINEAL DE LACERACIÓN DE PÁRPADO O CEJA"
    },
    {
     "CATALOG_KEY": "0882",
     "PRO_NOMBRE": "REPARACIÓN DE LACERACIÓN CON AFECTACIÓN DE MARGEN PÁRPADO, GROSOR PARCIAL"
    },
    {
     "CATALOG_KEY": "0883",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE LACERACIÓN DE PÁRPADO, GROSOR PARCIAL"
    },
    {
     "CATALOG_KEY": "0884",
     "PRO_NOMBRE": "REPARACIÓN DE LACERACIÓN CON AFECTACIÓN DE MARGEN DE PÁRPADO, GROSOR TOTAL"
    },
    {
     "CATALOG_KEY": "0885",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE LACERACIÓN DE PÁRPADO, GROSOR TOTAL"
    },
    {
     "CATALOG_KEY": "0886",
     "PRO_NOMBRE": "RITIDECTOMÍA DE PÁRPADO INFERIOR"
    },
    {
     "CATALOG_KEY": "0887",
     "PRO_NOMBRE": "RITIDECTOMÍA DE PÁRPADO SUPERIOR"
    },
    {
     "CATALOG_KEY": "0889",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "089",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PÁRPADOS"
    },
    {
     "CATALOG_KEY": "0891",
     "PRO_NOMBRE": "EPILACIÓN ELECTROQUIRÚRGICA DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0892",
     "PRO_NOMBRE": "EPILACIÓN CRIOQUIRÚRGICA DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0893",
     "PRO_NOMBRE": "OTRA EPILACIÓN DE PÁRPADO"
    },
    {
     "CATALOG_KEY": "0899",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE PÁRPADOS"
    },
    {
     "CATALOG_KEY": "09",
     "PRO_NOMBRE": "OPERACIONES SOBRE APARATO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "090",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA LAGRIMAL"
    },
    {
     "CATALOG_KEY": "090X",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA LAGRIMAL"
    },
    {
     "CATALOG_KEY": "091",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE APARATO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0911",
     "PRO_NOMBRE": "BIOPSIA DE GLÁNDULA LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0912",
     "PRO_NOMBRE": "BIOPSIA DE SACO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0919",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE APARATO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "092",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN O TEJIDO DE GLÁNDULA LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0920",
     "PRO_NOMBRE": "EXCISIÓN DE GLÁNDULA LAGRIMAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "0921",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE GLÁNDULA LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0922",
     "PRO_NOMBRE": "OTRA DACRIOADENECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "0923",
     "PRO_NOMBRE": "DACRIOADENECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "093",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLÁNDULA LAGRIMAL"
    },
    {
     "CATALOG_KEY": "093X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLÁNDULA LAGRIMAL"
    },
    {
     "CATALOG_KEY": "094",
     "PRO_NOMBRE": "MANIPULACIÓN DEL CONDUCTO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0941",
     "PRO_NOMBRE": "SONDAJE DEL PUNTO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0942",
     "PRO_NOMBRE": "SONDAJE DEL CANALÍCULOS LAGRIMALES"
    },
    {
     "CATALOG_KEY": "0943",
     "PRO_NOMBRE": "EXPLORACIÓN DE CONDUCTO NASOLAGRIMAL"
    },
    {
     "CATALOG_KEY": "0944",
     "PRO_NOMBRE": "INTUBACIÓN DE CONDUCTO NASOLAGRIMAL"
    },
    {
     "CATALOG_KEY": "0949",
     "PRO_NOMBRE": "OTRA MANIPULACIÓN DE CONDUCTO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "095",
     "PRO_NOMBRE": "INCISIÓN DE SACO Y CONDUCTOS LAGRIMALES"
    },
    {
     "CATALOG_KEY": "0951",
     "PRO_NOMBRE": "INCISIÓN DE PUNTO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0952",
     "PRO_NOMBRE": "INCISIÓN DE CANALÍCULOS LAGRIMALES"
    },
    {
     "CATALOG_KEY": "0953",
     "PRO_NOMBRE": "INCISIÓN DE SACO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0959",
     "PRO_NOMBRE": "OTRA INCISIÓN DE CONDUCTOS LAGRIMALES"
    },
    {
     "CATALOG_KEY": "096",
     "PRO_NOMBRE": "EXCISIÓN DE SACO Y CONDUCTO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "096X",
     "PRO_NOMBRE": "EXCISIÓN DE SACO Y CONDUCTO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "097",
     "PRO_NOMBRE": "REPARACIÓN DE CANALÍCULO Y PUNTO"
    },
    {
     "CATALOG_KEY": "0971",
     "PRO_NOMBRE": "CORRECCIÓN DE PUNTO EVERTIDO"
    },
    {
     "CATALOG_KEY": "0972",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE PUNTO"
    },
    {
     "CATALOG_KEY": "0973",
     "PRO_NOMBRE": "REPARACIÓN DE CANALÍCULOS"
    },
    {
     "CATALOG_KEY": "098",
     "PRO_NOMBRE": "FISTULIZACIÓN DEL TRACTO LAGRIMAL HASTA LA CAVIDAD NASAL"
    },
    {
     "CATALOG_KEY": "0981",
     "PRO_NOMBRE": "DACRIOCISTORRINOSTOMÍA [DCR]"
    },
    {
     "CATALOG_KEY": "0982",
     "PRO_NOMBRE": "CONJUNTIVOCISTORRINOSTOMÍA"
    },
    {
     "CATALOG_KEY": "0983",
     "PRO_NOMBRE": "CONJUNTIVORRINOSTOMÍA CON INSERCIÓN DE TUBO O VARILLA"
    },
    {
     "CATALOG_KEY": "099",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL APARATO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0991",
     "PRO_NOMBRE": "OBLITERACIÓN DEL PUNTO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "0999",
     "PRO_NOMBRE": "OTRAS OPERACIONES DEL APARATO LAGRIMAL"
    },
    {
     "CATALOG_KEY": "10",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "100",
     "PRO_NOMBRE": "EXTRACCIÓN DE LA CONJUNTIVA DE UN CUERPO EXTRAÑO INCRUSTADO MEDIANTE INCISIÓN"
    },
    {
     "CATALOG_KEY": "100X",
     "PRO_NOMBRE": "EXTRACCIÓN DE LA CONJUNTIVA DE UN CUERPO EXTRAÑO INCRUSTADO MEDIANTE INCISIÓN"
    },
    {
     "CATALOG_KEY": "101",
     "PRO_NOMBRE": "OTRA INCISIÓN DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "101X",
     "PRO_NOMBRE": "OTRA INCISIÓN DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "102",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS DE LA CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "1021",
     "PRO_NOMBRE": "BIOPSIA DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "1029",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS DE LA CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "103",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "1031",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN O TEJIDO DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "1032",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "1033",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DESTRUCTIVOS DE LA CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "104",
     "PRO_NOMBRE": "CONJUNTIVOPLASTIA"
    },
    {
     "CATALOG_KEY": "1041",
     "PRO_NOMBRE": "REPARACIÓN DE SIMBLÉFARON CON INJERTO LIBRE"
    },
    {
     "CATALOG_KEY": "1042",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE FONDO DE SACO CONJUNTIVAL CON INJERTO LIBRE"
    },
    {
     "CATALOG_KEY": "1043",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE FONDO DE SACO CONJUNTIVAL"
    },
    {
     "CATALOG_KEY": "1044",
     "PRO_NOMBRE": "OTRO INJERTO LIBRE A LA CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "1049",
     "PRO_NOMBRE": "OTRA CONJUNTIVOPLASTIA"
    },
    {
     "CATALOG_KEY": "105",
     "PRO_NOMBRE": "LISIS DE ADHERENCIA CONJUNTIVA Y PÁRPADO"
    },
    {
     "CATALOG_KEY": "105X",
     "PRO_NOMBRE": "LISIS DE ADHERENCIA CONJUNTIVA Y PÁRPADO"
    },
    {
     "CATALOG_KEY": "106",
     "PRO_NOMBRE": "REPARACIÓN DE LACERACIÓN DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "106X",
     "PRO_NOMBRE": "REPARACIÓN DE LACERACIÓN DE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "109",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "1091",
     "PRO_NOMBRE": "INYECCIÓN SUBCONJUNTIVAL"
    },
    {
     "CATALOG_KEY": "1099",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE LA CONJUNTIVA"
    },
    {
     "CATALOG_KEY": "11",
     "PRO_NOMBRE": "OPERACIONES DE LA CÓRNEA"
    },
    {
     "CATALOG_KEY": "110",
     "PRO_NOMBRE": "EXTRACCIÓN POR IMÁN DE CUERPO EXTRAÑO INCRUSTADO EN CÓRNEA"
    },
    {
     "CATALOG_KEY": "110X",
     "PRO_NOMBRE": "EXTRACCIÓN POR IMÁN DE CUERPO EXTRAÑO INCRUSTADO EN CÓRNEA"
    },
    {
     "CATALOG_KEY": "111",
     "PRO_NOMBRE": "INCISIÓN DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "111X",
     "PRO_NOMBRE": "INCISIÓN DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "112",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS DE LA CÓRNEA"
    },
    {
     "CATALOG_KEY": "1121",
     "PRO_NOMBRE": "RASPADO DE CÓRNEA PARA FROTIS O CULTIVO"
    },
    {
     "CATALOG_KEY": "1122",
     "PRO_NOMBRE": "BIOPSIA DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "1129",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS DE LA CÓRNEA"
    },
    {
     "CATALOG_KEY": "113",
     "PRO_NOMBRE": "EXCISIÓN DE PTERIGIÓN"
    },
    {
     "CATALOG_KEY": "1131",
     "PRO_NOMBRE": "TRANSPOSICIÓN DE PTERIGIÓN"
    },
    {
     "CATALOG_KEY": "1132",
     "PRO_NOMBRE": "EXCISIÓN DE PTERIGIÓN CON INJERTO CORNEAL"
    },
    {
     "CATALOG_KEY": "1139",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE PTERIGIÓN"
    },
    {
     "CATALOG_KEY": "114",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE TEJIDO U OTRA LESIÓN DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "1141",
     "PRO_NOMBRE": "EXTIRPACIÓN MECÁNICA DEL EPITELIO CORNEAL"
    },
    {
     "CATALOG_KEY": "1142",
     "PRO_NOMBRE": "TERMOCAUTERIZACIÓN DE LESIÓN CORNEAL"
    },
    {
     "CATALOG_KEY": "1143",
     "PRO_NOMBRE": "CRIOTERAPIA DE LESIÓN CORNEAL"
    },
    {
     "CATALOG_KEY": "1149",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN O DESTRUCCIÓN DE LESIÓN CORNEAL"
    },
    {
     "CATALOG_KEY": "115",
     "PRO_NOMBRE": "REPARACIÓN DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "1151",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN CORNEAL"
    },
    {
     "CATALOG_KEY": "1152",
     "PRO_NOMBRE": "REPARACIÓN DE DEHISCENCIA DE HERIDA POSTOPERATORIA DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "1153",
     "PRO_NOMBRE": "REPARACIÓN DE LACERACIÓN O HERIDA CORNEAL CON COLGAJO CONJUNTIVAL"
    },
    {
     "CATALOG_KEY": "1159",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "116",
     "PRO_NOMBRE": "TRASPLANTE DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "1160",
     "PRO_NOMBRE": "TRASPLANTE DE CORNEA, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "1161",
     "PRO_NOMBRE": "QUERATOPLASTIA LAMELAR CON AUTOINJERTO"
    },
    {
     "CATALOG_KEY": "1162",
     "PRO_NOMBRE": "OTRA QUERATOPLASTIA LAMELAR"
    },
    {
     "CATALOG_KEY": "1163",
     "PRO_NOMBRE": "QUERATOPLASTIA PENETRANTE CON AUTOINJERTO"
    },
    {
     "CATALOG_KEY": "1164",
     "PRO_NOMBRE": "OTRA QUERATOPLASTIA PENETRANTE"
    },
    {
     "CATALOG_KEY": "1169",
     "PRO_NOMBRE": "OTRO TRASPLANTE CORNEAL"
    },
    {
     "CATALOG_KEY": "117",
     "PRO_NOMBRE": "OTRA CIRUGÍA RECONSTRUCTIVA Y REFRACTIVA DE LA CÓRNEA"
    },
    {
     "CATALOG_KEY": "1171",
     "PRO_NOMBRE": "QUERATOMILEUSIS"
    },
    {
     "CATALOG_KEY": "1172",
     "PRO_NOMBRE": "QUERATOFAQUIA"
    },
    {
     "CATALOG_KEY": "1173",
     "PRO_NOMBRE": "QUERATOPRÓTESIS"
    },
    {
     "CATALOG_KEY": "1174",
     "PRO_NOMBRE": "TERMOQUERATOPLASTIA"
    },
    {
     "CATALOG_KEY": "1175",
     "PRO_NOMBRE": "QUERATOTOMÍA RADIAL"
    },
    {
     "CATALOG_KEY": "1176",
     "PRO_NOMBRE": "EPIQUERATOFAQUIA"
    },
    {
     "CATALOG_KEY": "1179",
     "PRO_NOMBRE": "OTRA CIRUGÍA RECONSTRUCTIVA Y REFRACTIVA DE LA CÓRNEA"
    },
    {
     "CATALOG_KEY": "119",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE LA CÓRNEA"
    },
    {
     "CATALOG_KEY": "1191",
     "PRO_NOMBRE": "TATUAJE DE CÓRNEA"
    },
    {
     "CATALOG_KEY": "1192",
     "PRO_NOMBRE": "ELIMINACIÓN DE LA CÓRNEA DE IMPLANTACIÓN ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "1199",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE LA CÓRNEA"
    },
    {
     "CATALOG_KEY": "12",
     "PRO_NOMBRE": "OPERACIONES SOBRE IRIS, CUERPO CILIAR, ESCLERÓTICA, Y CÁMARA ANTERIOR DEL OJO."
    },
    {
     "CATALOG_KEY": "120",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO INTRAOCULAR DEL SEGMENTO ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1200",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO INTRAOCULAR DEL SEGMENTO ANTERIOR DEL OJO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "1201",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO INTRAOCULAR DEL SEGMENTO ANTERIOR DEL OJO, CON EMPLEO DE IMÁN"
    },
    {
     "CATALOG_KEY": "1202",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DEL SEGMENTO ANTERIOR DEL OJO SIN EMPLEO DE IMÁN"
    },
    {
     "CATALOG_KEY": "121",
     "PRO_NOMBRE": "IRIDOTOMÍA E IRIDECTOMÍA SIMPLE"
    },
    {
     "CATALOG_KEY": "1211",
     "PRO_NOMBRE": "IRIDOTOMÍA CON TRANSFIXIÓN"
    },
    {
     "CATALOG_KEY": "1212",
     "PRO_NOMBRE": "OTRA IRIDOTOMÍA"
    },
    {
     "CATALOG_KEY": "1213",
     "PRO_NOMBRE": "EXCISIÓN DE IRIS PROLAPSADO"
    },
    {
     "CATALOG_KEY": "1214",
     "PRO_NOMBRE": "OTRA IRIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "122",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE IRIS, CUERPO CILIAR, ESCLERÓTICA, Y CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1221",
     "PRO_NOMBRE": "ASPIRACIÓN DIAGNÓSTICA DE LA CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1222",
     "PRO_NOMBRE": "BIOPSIA DE IRIS"
    },
    {
     "CATALOG_KEY": "1229",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL IRIS, CUERPO CILIAR, ESCLERÓTICA, Y CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "123",
     "PRO_NOMBRE": "IRIDOPLASTIA Y COROPLASTIA"
    },
    {
     "CATALOG_KEY": "1231",
     "PRO_NOMBRE": "LISIS DE GONIOSINEQUIAS"
    },
    {
     "CATALOG_KEY": "1232",
     "PRO_NOMBRE": "LISIS DE OTRAS SINEQUIAS ANTERIORES"
    },
    {
     "CATALOG_KEY": "1233",
     "PRO_NOMBRE": "LISIS DE SINEQUIAS POSTERIORES"
    },
    {
     "CATALOG_KEY": "1234",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS CORNEOVÍTREAS"
    },
    {
     "CATALOG_KEY": "1235",
     "PRO_NOMBRE": "COREOPLASTIA"
    },
    {
     "CATALOG_KEY": "1239",
     "PRO_NOMBRE": "OTRA IRIDOPLASTIA"
    },
    {
     "CATALOG_KEY": 124,
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE IRIS Y CUERPO CILIAR"
    },
    {
     "CATALOG_KEY": "1240",
     "PRO_NOMBRE": "EXTIRPACIÓN DE LESIÓN DEL SEGMENTO ANTERIOR DEL OJO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "1241",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN DE IRIS, NO POR EXCISIÓN"
    },
    {
     "CATALOG_KEY": "1242",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE IRIS"
    },
    {
     "CATALOG_KEY": "1243",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN DEL CUERPO CILIAR, NO POR EXCISIÓN"
    },
    {
     "CATALOG_KEY": "1244",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE CUERPO CILIAR"
    },
    {
     "CATALOG_KEY": 125,
     "PRO_NOMBRE": "FACILITACIÓN DE LA CIRCULACIÓN INTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1251",
     "PRO_NOMBRE": "GONIOPUNTURA SIN GONIOTOMÍA"
    },
    {
     "CATALOG_KEY": "1252",
     "PRO_NOMBRE": "GONIOTOMÍA SIN GONIOPUNTURA"
    },
    {
     "CATALOG_KEY": "1253",
     "PRO_NOMBRE": "GONIOTOMÍA CON GONIOPUNTURA"
    },
    {
     "CATALOG_KEY": "1254",
     "PRO_NOMBRE": "TRABECULOTOMÍA DESDE EL EXTERIOR"
    },
    {
     "CATALOG_KEY": "1255",
     "PRO_NOMBRE": "CICLODIÁLISIS"
    },
    {
     "CATALOG_KEY": "1259",
     "PRO_NOMBRE": "OTRA FACILITACIÓN DE LA CIRCULACIÓN INTRAOCULAR"
    },
    {
     "CATALOG_KEY": "126",
     "PRO_NOMBRE": "FISTULIZACIÓN ESCLERAL"
    },
    {
     "CATALOG_KEY": "1261",
     "PRO_NOMBRE": "TREPANACIÓN DE ESCLERÓTICA CON IRIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "1262",
     "PRO_NOMBRE": "TERMOCAUTERIZACIÓN DE ESCLERÓTICA CON IRIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "1263",
     "PRO_NOMBRE": "IRIDENCLESIS E IRIDOTASIS"
    },
    {
     "CATALOG_KEY": "1264",
     "PRO_NOMBRE": "TRABECULECTOMÍA DESDE EL EXTERIOR"
    },
    {
     "CATALOG_KEY": "1265",
     "PRO_NOMBRE": "OTRA FÍSTULIZACIÓN ESCLERAL CON IRIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "1266",
     "PRO_NOMBRE": "REVISIÓN POSTOPERATORIA DE PROCEDIMIENTO DE FISTULIZACIÓN ESCLERAL"
    },
    {
     "CATALOG_KEY": "1269",
     "PRO_NOMBRE": "OTRO PROCEDIMIENTO DE FISTULIZACIÓN"
    },
    {
     "CATALOG_KEY": "127",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS PARA EL ALIVIO DE UNA PRESIÓN INTRAOCULAR ELEVADA"
    },
    {
     "CATALOG_KEY": "1271",
     "PRO_NOMBRE": "CICLODIATERMIA"
    },
    {
     "CATALOG_KEY": "1272",
     "PRO_NOMBRE": "CICLOCRIOTERAPIA"
    },
    {
     "CATALOG_KEY": "1273",
     "PRO_NOMBRE": "CICLOFOTOCOAGULACIÓN"
    },
    {
     "CATALOG_KEY": "1274",
     "PRO_NOMBRE": "DISMINUCIÓN DEL CUERPO CILIAR, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "1279",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DE GLAUCOMA"
    },
    {
     "CATALOG_KEY": "128",
     "PRO_NOMBRE": "OPERACIONES SOBRE ESCLERÓTICA"
    },
    {
     "CATALOG_KEY": "1281",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN ESCLERÓTICA"
    },
    {
     "CATALOG_KEY": "1282",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA ESCLERAL"
    },
    {
     "CATALOG_KEY": "1283",
     "PRO_NOMBRE": "REVISIÓN DE HERIDA OPERATORIA DE SEGMENTO ANTERIOR, NO CLASIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "1284",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE ESCLERÓTICA"
    },
    {
     "CATALOG_KEY": "1285",
     "PRO_NOMBRE": "REPARACIÓN DE ESTAFILOMA ESCLERAL CON INJERTO"
    },
    {
     "CATALOG_KEY": "1286",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ESTAFILOMA ESCLERAL"
    },
    {
     "CATALOG_KEY": "1287",
     "PRO_NOMBRE": "REFUERZO ESCLERAL CON INJERTO"
    },
    {
     "CATALOG_KEY": "1288",
     "PRO_NOMBRE": "OTRO REFUERZO ESCLERAL"
    },
    {
     "CATALOG_KEY": "1289",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ESCLERÓTICA"
    },
    {
     "CATALOG_KEY": "129",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL IRIS, CUERPO CILIAR Y CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1291",
     "PRO_NOMBRE": "EVACUACIÓN TERAPÉUTICA DE LA CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1292",
     "PRO_NOMBRE": "INYECCIÓN EN CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1293",
     "PRO_NOMBRE": "EXTIRPACIÓN O DESTRUCCIÓN DE EXCRECENCIA DESCENDIENTE EPITELIAL DE LA CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1297",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE IRIS"
    },
    {
     "CATALOG_KEY": "1298",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL CUERPO CILIAR"
    },
    {
     "CATALOG_KEY": "1299",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA CÁMARA ANTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "13",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL CRISTALINO"
    },
    {
     "CATALOG_KEY": "130",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DE CRISTALINO"
    },
    {
     "CATALOG_KEY": "1300",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DEL CRISTALINO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "1301",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DEL CRISTALINO CON EMPLEO DE IMÁN"
    },
    {
     "CATALOG_KEY": "1302",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO SIN EMPLEO DE IMÁN"
    },
    {
     "CATALOG_KEY": "131",
     "PRO_NOMBRE": "EXTRACCIÓN INTRACAPSULAR DEL CRISTALINO"
    },
    {
     "CATALOG_KEY": "1311",
     "PRO_NOMBRE": "EXTRACCIÓN INTRACAPSULAR DE CRISTALINO POR LA RUTA TEMPORAL INFERIOR"
    },
    {
     "CATALOG_KEY": "1319",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN INTRACAPSULAR DE CRISTALINO"
    },
    {
     "CATALOG_KEY": "132",
     "PRO_NOMBRE": "EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO MEDIANTE TÉCNICA DE EXTRACCIÓN LINEAL"
    },
    {
     "CATALOG_KEY": "132X",
     "PRO_NOMBRE": "EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO MEDIANTE TÉCNICA DE EXTRACCIÓN LINEAL"
    },
    {
     "CATALOG_KEY": "133",
     "PRO_NOMBRE": "EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO MEDIANTE TÉCNICA SIMPLE SENCILLA DE ASPIRACIÓN (E IRRIGACIÓN)"
    },
    {
     "CATALOG_KEY": "133X",
     "PRO_NOMBRE": "EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO MEDIANTE TÉCNICA SIMPLE SENCILLA DE ASPIRACIÓN (E IRRIGACIÓN)"
    },
    {
     "CATALOG_KEY": "134",
     "PRO_NOMBRE": "EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO MEDIANTE TÉCNICA DE FRAGMENTACIÓN Y ASPIRACIÓN"
    },
    {
     "CATALOG_KEY": "1341",
     "PRO_NOMBRE": "FACOEMULSIFICACIÓN Y ASPIRACIÓN DE CATARATA"
    },
    {
     "CATALOG_KEY": "1342",
     "PRO_NOMBRE": "FACOFRAGMENTACIÓN MÉCANICA Y ASPIRACIÓN DE CATARATA POR RUTA POSTERIOR"
    },
    {
     "CATALOG_KEY": "1343",
     "PRO_NOMBRE": "FACOFRAGMENTACIÓN MÉCANICA Y OTRA ASPIRACIÓN DE CATARATA"
    },
    {
     "CATALOG_KEY": "135",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO"
    },
    {
     "CATALOG_KEY": "1351",
     "PRO_NOMBRE": "EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO POR LA RUTA TEMPORAL INFERIOR"
    },
    {
     "CATALOG_KEY": "1359",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN EXTRACAPSULAR DE CRISTALINO"
    },
    {
     "CATALOG_KEY": "136",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN DE CATARATA"
    },
    {
     "CATALOG_KEY": "1364",
     "PRO_NOMBRE": "DISECCIÓN DE MEMBRANA SECUNDARIA [DESPUÉS DE CATARATA]"
    },
    {
     "CATALOG_KEY": "1365",
     "PRO_NOMBRE": "EXCISIÓN DE MEMBRANA SECUNDARIA [DESPUÉS DE CATARATA]"
    },
    {
     "CATALOG_KEY": "1366",
     "PRO_NOMBRE": "FRAGMENTACIÓN MECÁNICA DE MEMBRANA SECUNDARIA [DESPUÉS DE CATARATA]"
    },
    {
     "CATALOG_KEY": "1369",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN DE CATARATA"
    },
    {
     "CATALOG_KEY": "137",
     "PRO_NOMBRE": "INSERCIÓN DE CRISTALINO PROTÉSICO [PSEUDOCRISTALINO]"
    },
    {
     "CATALOG_KEY": "1370",
     "PRO_NOMBRE": "INSERCIÓN DE PSEUDOCRISTALINO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "1371",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS DE CRISTALINO INTRAOCULAR EN EL MOMENTO DE LA EXTRACCIÓN DE LA CATARATA, UNA SOLA ETAPA"
    },
    {
     "CATALOG_KEY": "1372",
     "PRO_NOMBRE": "INSERCIÓN SECUNDARIA DE PRÓTESIS DE CRISTALINO INTRAOCULAR"
    },
    {
     "CATALOG_KEY": "138",
     "PRO_NOMBRE": "EXTRACCIÓN DE CRISTALINO IMPLANTADO"
    },
    {
     "CATALOG_KEY": "138X",
     "PRO_NOMBRE": "EXTRACCIÓN DE CRISTALINO IMPLANTADO"
    },
    {
     "CATALOG_KEY": "139",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CRISTALINO"
    },
    {
     "CATALOG_KEY": "1390",
     "PRO_NOMBRE": "OPERACIÓN DEL CRISTALINO, NO CLASIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "1391",
     "PRO_NOMBRE": "IMPLANTACIÓN DE PRÓTESIS INTRAOCULAR TELESCÓPICA"
    },
    {
     "CATALOG_KEY": "14",
     "PRO_NOMBRE": "OPERACIONES SOBRE RETINA, COROIDES, CUERPO VÍTREO Y CÁMARA POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "140",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DEL SEGMENTO POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1400",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DEL SEGMENTO POSTERIOR DEL OJO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "1401",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DEL SEGMENTO POSTERIOR DEL OJO CON EMPLEO DE IMÁN"
    },
    {
     "CATALOG_KEY": "1402",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DEL SEGMENTO POSTERIOR DEL OJO SIN EMPLEO DE IMÁN"
    },
    {
     "CATALOG_KEY": "141",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE RETINA, COROIDES, CUERPO VÍTREO, Y LA CÁMARA POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "1411",
     "PRO_NOMBRE": "ASPIRACIÓN DIAGNÓSTICA DE CUERPO VÍTREO"
    },
    {
     "CATALOG_KEY": "1419",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE RETINA, COROIDES, CUERPO VÍTREO Y CÁMARA POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "142",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN DE RETINA Y COROIDES"
    },
    {
     "CATALOG_KEY": "1421",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN CORIORRETINAL POR DIATERMIA"
    },
    {
     "CATALOG_KEY": "1422",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN CORIORRETINAL POR CRIOTERAPIA"
    },
    {
     "CATALOG_KEY": "1423",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN CORIORRETINAL POR FOTOCOAGULACIÓN POR ARCO DE XENÓN"
    },
    {
     "CATALOG_KEY": "1424",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN CORIORRETINAL POR FOTOCOAGULACIÓN POR LASER"
    },
    {
     "CATALOG_KEY": "1425",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN CORIORRETINAL POR FOTOCOAGULACIÓN DE TIPO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "1426",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN CORIORRETINAL POR TERAPIA DE RADIACIÓN"
    },
    {
     "CATALOG_KEY": "1427",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN CORIORRETINAL POR IMPLANTACIÓN DE FUENTE DE RADIACIÓN"
    },
    {
     "CATALOG_KEY": "1429",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN DE LESIÓN CORIORRETINAL"
    },
    {
     "CATALOG_KEY": "143",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO RETINAL"
    },
    {
     "CATALOG_KEY": "1431",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO RETINAL POR DIATERMIA"
    },
    {
     "CATALOG_KEY": "1432",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO RETINAL POR CRIOTERAPIA"
    },
    {
     "CATALOG_KEY": "1433",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO RETINAL POR FOTOCOAGULACIÓN POR ARCO DE XENÓN"
    },
    {
     "CATALOG_KEY": "1434",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO RETINAL POR FOTOCOAGULACIÓN POR LASER"
    },
    {
     "CATALOG_KEY": "1435",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO RETINAL POR FOTOCOAGULACIÓN DE TIPO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "1439",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE DESGARRO RETINAL"
    },
    {
     "CATALOG_KEY": "144",
     "PRO_NOMBRE": "REPARACIÓN DE DESPRENDIMIENTO RETINAL CON INDENTACIÓN ESCLERAL E IMPLANTACIÓN"
    },
    {
     "CATALOG_KEY": "1441",
     "PRO_NOMBRE": "INDENTACIÓN ESCLERAL CON IMPLANTACIÓN"
    },
    {
     "CATALOG_KEY": "1449",
     "PRO_NOMBRE": "OTRA INDENTACIÓN ESCLERAL"
    },
    {
     "CATALOG_KEY": "145",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE DESPRENDIMIENTO RETINAL"
    },
    {
     "CATALOG_KEY": "1451",
     "PRO_NOMBRE": "REPARACIÓN DE DESPRENDIMIENTO RETINAL CON DIATERMIA"
    },
    {
     "CATALOG_KEY": "1452",
     "PRO_NOMBRE": "REPARACIÓN DE DESPRENDIMIENTO RETINAL CON CRIOTERAPIA"
    },
    {
     "CATALOG_KEY": "1453",
     "PRO_NOMBRE": "REPARACIÓN DE DESPRENDIMIENTO RETINAL CON FOTOCOAGULACIÓN POR ARCO DE XENÓN"
    },
    {
     "CATALOG_KEY": "1454",
     "PRO_NOMBRE": "REPARACIÓN DE DESPRENDIMIENTO RETINAL CON FOTOCOAGULACIÓN CON LASER"
    },
    {
     "CATALOG_KEY": "1455",
     "PRO_NOMBRE": "REPARACIÓN DE DESPRENDIMIENTO RETINAL CON FOTOCOAGULACIÓN DE TIPO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "1459",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE DESPRENDIMIENTO RETINAL"
    },
    {
     "CATALOG_KEY": "146",
     "PRO_NOMBRE": "EXTRACCIÓN DE MATERIAL QUIRÚRGICAMENTE IMPLANTADO DEL SEGMENTO POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "146X",
     "PRO_NOMBRE": "EXTRACCIÓN DE MATERIAL QUIRÚRGICAMENTE IMPLANTADO DEL SEGMENTO POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "147",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL CUERPO VÍTREO"
    },
    {
     "CATALOG_KEY": "1471",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO VÍTREO, ACCESO ANTERIOR"
    },
    {
     "CATALOG_KEY": "1472",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN DEL CUERPO VÍTREO"
    },
    {
     "CATALOG_KEY": "1473",
     "PRO_NOMBRE": "VITRECTOMÍA MECÁNICA POR ACCESO ANTERIOR"
    },
    {
     "CATALOG_KEY": "1474",
     "PRO_NOMBRE": "OTRA VITRECTOMÍA MECÁNICA"
    },
    {
     "CATALOG_KEY": "1475",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTITUTO VÍTREO"
    },
    {
     "CATALOG_KEY": "1479",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL CUERPO VÍTREO"
    },
    {
     "CATALOG_KEY": "149",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE RETINA, COROIDES Y CÁMARA POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "149X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE RETINA, COROIDES Y CÁMARA POSTERIOR DEL OJO"
    },
    {
     "CATALOG_KEY": "15",
     "PRO_NOMBRE": "OPERACIONES SOBRE MÚSCULOS EXTRAOCULARES"
    },
    {
     "CATALOG_KEY": "150",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MÚSCULOS O TENDONES EXTRAOCULARES"
    },
    {
     "CATALOG_KEY": "1501",
     "PRO_NOMBRE": "BIOPSIA DE MÚSCULO O TENDÓN EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1509",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MÚSCULOS Y TENDONES EXTRAOCULARES"
    },
    {
     "CATALOG_KEY": "151",
     "PRO_NOMBRE": "OPERACIONES SOBRE UN MÚSCULO EXTRAOCULAR CON DESPRENDIMIENTO TEMPORAL DEL GLOBO"
    },
    {
     "CATALOG_KEY": "1511",
     "PRO_NOMBRE": "RECESIÓN DE UN MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1512",
     "PRO_NOMBRE": "AVANCE DE MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1513",
     "PRO_NOMBRE": "RESECCIÓN DE UN MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1519",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE UN MÚSCULO EXTRAOCULAR CON DESPRENDIMIENTO TEMPORAL DEL GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "152",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE UN MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1521",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO SOBRE UN MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1522",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ACORTAMIENTO SOBRE UN MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "1529",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE UN MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "153",
     "PRO_NOMBRE": "OPERACIONES SOBRE DOS O MÁS MÚSCULOS EXTRAOCULARES CON DESPRENDIMIENTO TEMPORAL DEL GLOBO OCULAR, UNO O AMBOS OJOS"
    },
    {
     "CATALOG_KEY": "153X",
     "PRO_NOMBRE": "OPERACIONES SOBRE DOS O MÁS MÚSCULOS EXTRAOCULARES CON DESPRENDIMIENTO TEMPORAL DEL GLOBO OCULAR, UNO U AMBOS OJOS"
    },
    {
     "CATALOG_KEY": "154",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE DOS O MÁS MÚSCULOS EXTRAOCULARES, UNO O AMBOS OJOS"
    },
    {
     "CATALOG_KEY": "154X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE DOS O MÁS MÚSCULOS EXTRAOCULARES, UNO U AMBOS OJOS"
    },
    {
     "CATALOG_KEY": "155",
     "PRO_NOMBRE": "TRANSPOSICIÓN DE MÚSCULOS EXTRAOCULARES"
    },
    {
     "CATALOG_KEY": "155X",
     "PRO_NOMBRE": "TRANSPOSICIÓN DE MÚSCULOS EXTRAOCULARES"
    },
    {
     "CATALOG_KEY": "156",
     "PRO_NOMBRE": "REVISIÓN DE INTERVENCIÓN QUIRÚRGICA SOBRE MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "156X",
     "PRO_NOMBRE": "REVISIÓN DE INTERVENCIÓN QUIRÚRGICA SOBRE MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "157",
     "PRO_NOMBRE": "REPARACIÓN DE LESIÓN DE MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "157X",
     "PRO_NOMBRE": "REPARACIÓN DE LESIÓN DE MÚSCULO EXTRAOCULAR"
    },
    {
     "CATALOG_KEY": "159",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE MÚSCULOS Y TENDONES EXTRAOCULARES"
    },
    {
     "CATALOG_KEY": "159X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE MÚSCULOS Y TENDONES EXTRAOCULARES"
    },
    {
     "CATALOG_KEY": "16",
     "PRO_NOMBRE": "OPERACIONES SOBRE ÓRBITA Y GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "160",
     "PRO_NOMBRE": "ORBITOTOMÍA"
    },
    {
     "CATALOG_KEY": "1601",
     "PRO_NOMBRE": "ORBITOTOMÍA CON COLGAJO ÓSEO"
    },
    {
     "CATALOG_KEY": "1602",
     "PRO_NOMBRE": "ORBITOTOMÍA CON INSERCIÓN DE IMPLANTACIÓN ORBITAL"
    },
    {
     "CATALOG_KEY": "1609",
     "PRO_NOMBRE": "OTRA ORBITOTOMÍA"
    },
    {
     "CATALOG_KEY": "161",
     "PRO_NOMBRE": "EXTRACCIÓN DEL OJO DE CUERPO EXTRAÑO PENETRANTE, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "161X",
     "PRO_NOMBRE": "EXTRACCIÓN DEL OJO DE CUERPO EXTRAÑO PENETRANTE, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "162",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ÓRBITA Y GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "1621",
     "PRO_NOMBRE": "OFTALMOSCOPIA"
    },
    {
     "CATALOG_KEY": "1622",
     "PRO_NOMBRE": "ASPIRACIÓN DIAGNÓSTICA DE ÓRBITA"
    },
    {
     "CATALOG_KEY": "1623",
     "PRO_NOMBRE": "BIOPSIA DE GLOBO OCULAR Y ÓRBITA"
    },
    {
     "CATALOG_KEY": "1629",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ÓRBITA Y GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "163",
     "PRO_NOMBRE": "EVISCERACIÓN DE GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "1631",
     "PRO_NOMBRE": "EXTRACCIÓN DEL CONTENIDO DEL OJO CON IMPLANTACIÓN SIMULTÁNEA DE LA ESCLERÓTICA"
    },
    {
     "CATALOG_KEY": "1639",
     "PRO_NOMBRE": "OTRA EVISCERACIÓN DEL GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "164",
     "PRO_NOMBRE": "ENUCLEACIÓN DEL GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "1641",
     "PRO_NOMBRE": "ENUCLEACIÓN DEL GLOBO OCULAR CON IMPLANTACIÓN SIMULTÁNEA DENTRO DE LA CÁPSULA DE TENON CON ADHESIÓN DE MÚSCULOS"
    },
    {
     "CATALOG_KEY": "1642",
     "PRO_NOMBRE": "ENUCLEACIÓN DEL GLOBO OCULAR CON OTRA IMPLANTACIÓN SIMULTÁNEA"
    },
    {
     "CATALOG_KEY": "1649",
     "PRO_NOMBRE": "OTRA ENUCLEACIÓN DEL GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "165",
     "PRO_NOMBRE": "EXANTERACIÓN DEL CONTENIDO DE LA ÓRBITA"
    },
    {
     "CATALOG_KEY": "1651",
     "PRO_NOMBRE": "EXENTERACIÓN DE LA ÓRBITA CON EXTIRPACIÓN DE ESTRUCTURAS ADYACENTES"
    },
    {
     "CATALOG_KEY": "1652",
     "PRO_NOMBRE": "EXENTERACIÓN DE ÓRBITA CON EXTRACCIÓN TERAPÉUTICA DE HUESO ORBITAL"
    },
    {
     "CATALOG_KEY": "1659",
     "PRO_NOMBRE": "OTRO EXCENTERACIÓN DE LA ÓRBITA"
    },
    {
     "CATALOG_KEY": "166",
     "PRO_NOMBRE": "PROCEDIMIENTOS SECUNDARIOS DESPUÉS DE EXTIRPACIÓN DE GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "1661",
     "PRO_NOMBRE": "INSERCIÓN SECUNDARIA DE IMPLANTACIÓN OCULAR"
    },
    {
     "CATALOG_KEY": "1662",
     "PRO_NOMBRE": "REVISIÓN Y REINSERCIÓN DE IMPLANTACIÓN OCULAR"
    },
    {
     "CATALOG_KEY": "1663",
     "PRO_NOMBRE": "REVISIÓN DE CAVIDAD DE ENUCLEACIÓN CON INJERTO"
    },
    {
     "CATALOG_KEY": "1664",
     "PRO_NOMBRE": "OTRA REVISIÓN DE CAVIDAD DE ENUCLEACIÓN"
    },
    {
     "CATALOG_KEY": "1665",
     "PRO_NOMBRE": "INJERTO SECUNDARIO A LA CAVIDAD DE EXANTERACIÓN"
    },
    {
     "CATALOG_KEY": "1666",
     "PRO_NOMBRE": "OTRA REVISIÓN DE LA CAVIDAD DE EXANTERACIÓN"
    },
    {
     "CATALOG_KEY": "1669",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS SECUNDARIOS DESPUÉS DE EXTIRPACIÓN DE GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "167",
     "PRO_NOMBRE": "ELIMINACIÓN DE IMPLANTACIÓN OCULAR U ORBITAL"
    },
    {
     "CATALOG_KEY": "1671",
     "PRO_NOMBRE": "EXTRACCIÓN DE IMPLANTACIÓN OCULAR"
    },
    {
     "CATALOG_KEY": "1672",
     "PRO_NOMBRE": "EXTRACCIÓN DE IMPLANTACIÓN ORBITAL"
    },
    {
     "CATALOG_KEY": "168",
     "PRO_NOMBRE": "REPARACIÓN DE LESIÓN DE GLOBO OCULAR Y DE ÓRBITA"
    },
    {
     "CATALOG_KEY": "1681",
     "PRO_NOMBRE": "REPARACIÓN DE HERIDA DE ÓRBITA"
    },
    {
     "CATALOG_KEY": "1682",
     "PRO_NOMBRE": "REPARACIÓN DE RUPTURA DE GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "1689",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE LESIÓN DE GLOBO OCULAR O DE ÓRBITA"
    },
    {
     "CATALOG_KEY": "169",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ÓRBITA Y GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "1691",
     "PRO_NOMBRE": "INYECCIÓN RETROBULBAR DE AGENTE TERAPÉUTICO"
    },
    {
     "CATALOG_KEY": "1692",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE ÓRBITA"
    },
    {
     "CATALOG_KEY": "1693",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE OJO, ESTRUCTURA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "1698",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ÓRBITA"
    },
    {
     "CATALOG_KEY": "1699",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLOBO OCULAR"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL OÍDO (18 - 20)"
    },
    {
     "CATALOG_KEY": "18",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "180",
     "PRO_NOMBRE": "INCISIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1801",
     "PRO_NOMBRE": "PERFORACIÓN DE LÓBULO DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1802",
     "PRO_NOMBRE": "INCISIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1809",
     "PRO_NOMBRE": "OTRA INCISIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "181",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1811",
     "PRO_NOMBRE": "OTOSCOPIA"
    },
    {
     "CATALOG_KEY": "1812",
     "PRO_NOMBRE": "BIOPSIA DE OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1819",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "182",
     "PRO_NOMBRE": "EXCISIÓN O RESECCIÓN DE LESIÓN DE OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1821",
     "PRO_NOMBRE": "EXCISIÓN DE FÍSTULA PREAURICULAR"
    },
    {
     "CATALOG_KEY": "1829",
     "PRO_NOMBRE": "EXCISIÓN O RESECCIÓN DE OTRA LESIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "183",
     "PRO_NOMBRE": "OTRA EXCISIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1831",
     "PRO_NOMBRE": "EXCISIÓN RADICAL DE LESIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1839",
     "PRO_NOMBRE": "OTRA EXCISIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "184",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "184X",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "185",
     "PRO_NOMBRE": "CORRECCIÓN QUIRÚRGICA DE PABELLÓN AURICULAR PROMINENTE"
    },
    {
     "CATALOG_KEY": "185X",
     "PRO_NOMBRE": "CORRECCIÓN QUIRÚRGICA DE PABELLÓN AURICULAR PROMINENTE"
    },
    {
     "CATALOG_KEY": "186",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE CONDUCTO AUDITIVO EXTERNO"
    },
    {
     "CATALOG_KEY": "186X",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE CONDUCTO AUDITIVO EXTERNO"
    },
    {
     "CATALOG_KEY": "187",
     "PRO_NOMBRE": "OTRA REPARACIÓN DEL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "1871",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DEL PABELLÓN DEL OÍDO"
    },
    {
     "CATALOG_KEY": "1872",
     "PRO_NOMBRE": "REPOSICIÓN DE OREJA AMPUTADA"
    },
    {
     "CATALOG_KEY": "1879",
     "PRO_NOMBRE": "OTRA REPARACIÓN PLÁSTICA DE OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "189",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "189X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL OÍDO EXTERNO"
    },
    {
     "CATALOG_KEY": "19",
     "PRO_NOMBRE": "OPERACIONES RECONSTRUCTIVAS SOBRE OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "190",
     "PRO_NOMBRE": "MOVILIZACIÓN DEL ESTRIBO"
    },
    {
     "CATALOG_KEY": "190X",
     "PRO_NOMBRE": "MOVILIZACIÓN DEL ESTRIBO"
    },
    {
     "CATALOG_KEY": "191",
     "PRO_NOMBRE": "ESTAPEDECTOMÍA"
    },
    {
     "CATALOG_KEY": "1911",
     "PRO_NOMBRE": "ESTAPEDECTOMÍA CON SUSTITUCIÓN DE YUNQUE"
    },
    {
     "CATALOG_KEY": "1919",
     "PRO_NOMBRE": "OTRA ESTAPEDECTOMÍA"
    },
    {
     "CATALOG_KEY": "192",
     "PRO_NOMBRE": "REVISIÓN DE ESTAPEDECTOMÍA"
    },
    {
     "CATALOG_KEY": "1921",
     "PRO_NOMBRE": "REVISIÓN DE ESTAPEDECTOMÍA CON SUSTITUCIÓN DE YUNQUE"
    },
    {
     "CATALOG_KEY": "1929",
     "PRO_NOMBRE": "OTRA REVISIÓN DE ESTAPEDECTOMÍA"
    },
    {
     "CATALOG_KEY": "193",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA CADENA OSICULAR"
    },
    {
     "CATALOG_KEY": "193X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA CADENA OSICULAR"
    },
    {
     "CATALOG_KEY": "194",
     "PRO_NOMBRE": "MIRINGOPLASTIA"
    },
    {
     "CATALOG_KEY": "194X",
     "PRO_NOMBRE": "MIRINGOPLASTIA"
    },
    {
     "CATALOG_KEY": "195",
     "PRO_NOMBRE": "OTRA TIMPANOPLASTIA"
    },
    {
     "CATALOG_KEY": "1952",
     "PRO_NOMBRE": "TIMPANOPLASTIA TIPO II"
    },
    {
     "CATALOG_KEY": "1953",
     "PRO_NOMBRE": "TIMPANOPLASTIA TIPO III"
    },
    {
     "CATALOG_KEY": "1954",
     "PRO_NOMBRE": "TIMPANOPLASTIA TIPO IV"
    },
    {
     "CATALOG_KEY": "1955",
     "PRO_NOMBRE": "TIMPANOPLASTIA TIPO V"
    },
    {
     "CATALOG_KEY": "196",
     "PRO_NOMBRE": "REVISIÓN DE TIMPANOPLASTIA"
    },
    {
     "CATALOG_KEY": "196X",
     "PRO_NOMBRE": "REVISIÓN DE TIMPANOPLASTIA"
    },
    {
     "CATALOG_KEY": "199",
     "PRO_NOMBRE": "OTRA REPARACIÓN DEL OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "199X",
     "PRO_NOMBRE": "OTRA REPARACIÓN DEL OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "20",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL OÍDO MEDIO Y EL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "200",
     "PRO_NOMBRE": "MIRINGOTOMÍA"
    },
    {
     "CATALOG_KEY": "2001",
     "PRO_NOMBRE": "MIRINGOTOMÍA CON INSERCIÓN DE TUBO"
    },
    {
     "CATALOG_KEY": "2009",
     "PRO_NOMBRE": "OTRA MIRINGOTOMÍA"
    },
    {
     "CATALOG_KEY": "201",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE MIRINGOTOMÍA"
    },
    {
     "CATALOG_KEY": "201X",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE MIRINGOTOMÍA"
    },
    {
     "CATALOG_KEY": "202",
     "PRO_NOMBRE": "INCISIÓN DE MASTOIDES Y OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "2021",
     "PRO_NOMBRE": "INCISIÓN DE MASTOIDES"
    },
    {
     "CATALOG_KEY": "2022",
     "PRO_NOMBRE": "INCISIÓN DE CÉLULAS AÉREAS DE LA PIRÁMIDE PETROSA"
    },
    {
     "CATALOG_KEY": "2023",
     "PRO_NOMBRE": "INCISIÓN DEL OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "203",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL OÍDO MEDIO Y EL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "2031",
     "PRO_NOMBRE": "ELECTROCOCLEOGRAFÍA"
    },
    {
     "CATALOG_KEY": "2032",
     "PRO_NOMBRE": "BIOPSIA DE OÍDO MEDIO E INTERNO"
    },
    {
     "CATALOG_KEY": "2039",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL OÍDO MEDIO Y EL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "204",
     "PRO_NOMBRE": "MASTOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "2041",
     "PRO_NOMBRE": "MASTOIDECTOMÍA SIMPLE"
    },
    {
     "CATALOG_KEY": "2042",
     "PRO_NOMBRE": "MASTOIDECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "2049",
     "PRO_NOMBRE": "OTRA MASTOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "205",
     "PRO_NOMBRE": "OTRA EXCISIÓN DEL OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "2051",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "2059",
     "PRO_NOMBRE": "OTRA EXCISIÓN DEL OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "206",
     "PRO_NOMBRE": "FENESTRACIÓN DEL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "2061",
     "PRO_NOMBRE": "FENESTRACIÓN DEL OÍDO INTERNO (INICIAL)"
    },
    {
     "CATALOG_KEY": "2062",
     "PRO_NOMBRE": "REVISIÓN DE FENESTRACIÓN DEL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "207",
     "PRO_NOMBRE": "INCISIÓN, EXCISIÓN Y DESTRUCCIÓN DEL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "2071",
     "PRO_NOMBRE": "DRENAJE ENDOLINFÁTICO"
    },
    {
     "CATALOG_KEY": "2072",
     "PRO_NOMBRE": "INYECCIÓN EN OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "2079",
     "PRO_NOMBRE": "OTRA INCISIÓN, EXCISIÓN Y DESTRUCCIÓN DEL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "208",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA TROMPA DE EUSTAQUIO"
    },
    {
     "CATALOG_KEY": "208X",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA TROMPA DE EUSTAQUIO"
    },
    {
     "CATALOG_KEY": "209",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL OÍDO INTERNO Y EL OÍDO MEDIO"
    },
    {
     "CATALOG_KEY": "2091",
     "PRO_NOMBRE": "TIMPANOSIMPATECTOMÍA"
    },
    {
     "CATALOG_KEY": "2092",
     "PRO_NOMBRE": "REVISIÓN DE MASTOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "2093",
     "PRO_NOMBRE": "REPARACIÓN DE LAS VENTANAS OVAL Y REDONDA"
    },
    {
     "CATALOG_KEY": "2094",
     "PRO_NOMBRE": "INYECCIÓN DE TÍMPANO"
    },
    {
     "CATALOG_KEY": "2095",
     "PRO_NOMBRE": "IMPLANTACIÓN DE PRÓTESIS AUDITIVA ELECTROMAGNÉTICA"
    },
    {
     "CATALOG_KEY": "2096",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE PRÓTESIS COCLEAR NO ESPECIFICADO DE OTRA FORMA (IMPLANTE COCLEAR)"
    },
    {
     "CATALOG_KEY": "2097",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE PRÓTESIS COCLEAR DE CANAL ÚNICO"
    },
    {
     "CATALOG_KEY": "2098",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE PRÓTESIS COCLEAR DE CANAL MÚLTIPLE"
    },
    {
     "CATALOG_KEY": "2099",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL OÍDO MEDIO Y EL OÍDO INTERNO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA NARIZ, BOCA Y FARINGE (21 - 29)"
    },
    {
     "CATALOG_KEY": "21",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA NARIZ"
    },
    {
     "CATALOG_KEY": "210",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS"
    },
    {
     "CATALOG_KEY": "2100",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "2101",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR TAPONAMIENTO NASAL ANTERIOR"
    },
    {
     "CATALOG_KEY": "2102",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR TAPONAMIENTO POSTERIOR (Y ANTERIOR)"
    },
    {
     "CATALOG_KEY": "2103",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR CAUTERIZACIÓN (Y TAPONAMIENTO)"
    },
    {
     "CATALOG_KEY": "2104",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR LIGADURA DE LAS ARTERIAS ETMOIDALES"
    },
    {
     "CATALOG_KEY": "2105",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR LIGADURA DE LA ARTERIA MAXILAR A TRAVÉS DEL SENO MAXILAR"
    },
    {
     "CATALOG_KEY": "2106",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR LIGADURA DE LA ARTERIA CARÓTIDA EXTERNA"
    },
    {
     "CATALOG_KEY": "2107",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR EXCISIÓN DE LAS MUCOSAS NASALES E INJERTOS DE PIEL EN EL TABIQUE Y LA PARED NASAL LATERAL"
    },
    {
     "CATALOG_KEY": "2109",
     "PRO_NOMBRE": "CONTROL DE EPISTAXIS POR OTROS MEDIOS"
    },
    {
     "CATALOG_KEY": "211",
     "PRO_NOMBRE": "INCISIÓN NASAL"
    },
    {
     "CATALOG_KEY": "211X",
     "PRO_NOMBRE": "INCISIÓN NASAL"
    },
    {
     "CATALOG_KEY": "212",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA REGIÓN NASAL"
    },
    {
     "CATALOG_KEY": "2121",
     "PRO_NOMBRE": "RINOSCOPIA"
    },
    {
     "CATALOG_KEY": "2122",
     "PRO_NOMBRE": "BIOPSIA DE NARIZ"
    },
    {
     "CATALOG_KEY": "2129",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA REGIÓN NASAL"
    },
    {
     "CATALOG_KEY": "213",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O EXTIRPACIÓN DE LESIÓN DE LA PIRÁMIDE NASAL"
    },
    {
     "CATALOG_KEY": "2130",
     "PRO_NOMBRE": "EXCISIÓN O EXTIRPACIÓN DE LESIÓN DE LA PIRÁMIDE NASAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "2131",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O EXTIRPACIÓN DE LESIÓN INTRANASAL"
    },
    {
     "CATALOG_KEY": "2132",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O EXTIRPACIÓN DE OTRA LESIÓN DE NARIZ"
    },
    {
     "CATALOG_KEY": "214",
     "PRO_NOMBRE": "RESECCIÓN DE LA PIRÁMIDE NASAL"
    },
    {
     "CATALOG_KEY": "214X",
     "PRO_NOMBRE": "RESECCIÓN DE LA PIRÁMIDE NASAL"
    },
    {
     "CATALOG_KEY": "215",
     "PRO_NOMBRE": "RESECCIÓN SUBMUCOSA DEL TABIQUE NASAL (SEPTOPLASTIAS)"
    },
    {
     "CATALOG_KEY": "215X",
     "PRO_NOMBRE": "RESECCIÓN SUBMUCOSA DEL TABIQUE NASAL (SEPTOPLASTIAS)"
    },
    {
     "CATALOG_KEY": "216",
     "PRO_NOMBRE": "TURBINECTOMÍA"
    },
    {
     "CATALOG_KEY": "2161",
     "PRO_NOMBRE": "TURBINECTOMÍA POR DIATERMIA O CRIOCIRUGÍA"
    },
    {
     "CATALOG_KEY": "2162",
     "PRO_NOMBRE": "FRACTURA DE CORNETES"
    },
    {
     "CATALOG_KEY": "2169",
     "PRO_NOMBRE": "OTRA TURBINECTOMÍA"
    },
    {
     "CATALOG_KEY": "217",
     "PRO_NOMBRE": "REDUCCIÓN DE FRACTURA NASAL"
    },
    {
     "CATALOG_KEY": "2171",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA NASAL"
    },
    {
     "CATALOG_KEY": "2172",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA NASAL"
    },
    {
     "CATALOG_KEY": "218",
     "PRO_NOMBRE": "OPERACIONES DE REPARACIÓN Y CIRUGÍA PLÁSTICA SOBRE LA PIRÁMIDE NASAL"
    },
    {
     "CATALOG_KEY": "2181",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE NARIZ"
    },
    {
     "CATALOG_KEY": "2182",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA NASAL"
    },
    {
     "CATALOG_KEY": "2183",
     "PRO_NOMBRE": "RECONSTRUCCIÓN NASAL TOTAL"
    },
    {
     "CATALOG_KEY": "2184",
     "PRO_NOMBRE": "REVISIÓN DE RINOPLASTIA (RINOPLASTIA SECUNDARIA)"
    },
    {
     "CATALOG_KEY": "2185",
     "PRO_NOMBRE": "RINOPLASTIA DE AUMENTO"
    },
    {
     "CATALOG_KEY": "2186",
     "PRO_NOMBRE": "RINOPLASTIA LIMITADA"
    },
    {
     "CATALOG_KEY": "2187",
     "PRO_NOMBRE": "OTRA RINOPLASTIA"
    },
    {
     "CATALOG_KEY": "2188",
     "PRO_NOMBRE": "OTRA SEPTOPLASTIA"
    },
    {
     "CATALOG_KEY": "2189",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE REPARACIÓN Y OPERACIONES PLÁSTICAS DE LA NARIZ"
    },
    {
     "CATALOG_KEY": "219",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA NARIZ"
    },
    {
     "CATALOG_KEY": "2191",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE NARIZ"
    },
    {
     "CATALOG_KEY": "2199",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE LA NARIZ"
    },
    {
     "CATALOG_KEY": "22",
     "PRO_NOMBRE": "OPERACIONES SOBRE SENOS NASALES (PARANASALES)"
    },
    {
     "CATALOG_KEY": "220",
     "PRO_NOMBRE": "ASPIRACIÓN Y LAVADO DE SENO MAXILAR"
    },
    {
     "CATALOG_KEY": "2200",
     "PRO_NOMBRE": "ASPIRACIÓN Y LAVADO DE SENO NASAL (MAXILAR), NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "2201",
     "PRO_NOMBRE": "PUNCIÓN DE SENO NASAL (MAXILAR) PARA ASPIRACIÓN O LAVADO"
    },
    {
     "CATALOG_KEY": "2202",
     "PRO_NOMBRE": "ASPIRACIÓN O LAVADO DE SENO NASAL (MAXILAR), A TRAVÉS DE ORIFICIO NATURAL"
    },
    {
     "CATALOG_KEY": "221",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE SENO NASAL"
    },
    {
     "CATALOG_KEY": "2211",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] [PUNCIÓN CON AGUJA] DE SENO NASAL"
    },
    {
     "CATALOG_KEY": "2212",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE SENO NASAL"
    },
    {
     "CATALOG_KEY": "2219",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE SENOS NASALES (MAXILARES)"
    },
    {
     "CATALOG_KEY": "222",
     "PRO_NOMBRE": "ANTROTOMÍA INTRANASAL"
    },
    {
     "CATALOG_KEY": "222X",
     "PRO_NOMBRE": "ANTROTOMÍA INTRANASAL"
    },
    {
     "CATALOG_KEY": "223",
     "PRO_NOMBRE": "ANTROTOMÍA MAXILAR EXTERNA"
    },
    {
     "CATALOG_KEY": "2231",
     "PRO_NOMBRE": "ANTROTOMÍA MAXILAR RADICAL"
    },
    {
     "CATALOG_KEY": "2239",
     "PRO_NOMBRE": "OTRA ANTROTOMÍA MAXILAR EXTERNA"
    },
    {
     "CATALOG_KEY": "224",
     "PRO_NOMBRE": "SINUSOTOMÍA Y SINUSECTOMÍA FRONTALES"
    },
    {
     "CATALOG_KEY": "2241",
     "PRO_NOMBRE": "SINUSOTOMÍA FRONTAL"
    },
    {
     "CATALOG_KEY": "2242",
     "PRO_NOMBRE": "SINUSECTOMÍA FRONTAL"
    },
    {
     "CATALOG_KEY": "225",
     "PRO_NOMBRE": "OTRA SINUSOTOMÍA NASAL"
    },
    {
     "CATALOG_KEY": "2250",
     "PRO_NOMBRE": "SINUSOTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "2251",
     "PRO_NOMBRE": "ETMOIDOTOMÍA"
    },
    {
     "CATALOG_KEY": "2252",
     "PRO_NOMBRE": "ESFENOIDOTOMÍA"
    },
    {
     "CATALOG_KEY": "2253",
     "PRO_NOMBRE": "INCISIÓN DE MÚLTIPLES SENOS NASALES"
    },
    {
     "CATALOG_KEY": "226",
     "PRO_NOMBRE": "OTRA SINUSECTOMÍA NASAL"
    },
    {
     "CATALOG_KEY": "2260",
     "PRO_NOMBRE": "SINUSECTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "2261",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE SENO MAXILAR CON ABORDAJE CALDWELL-LUC"
    },
    {
     "CATALOG_KEY": "2262",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE SENO MAXILAR CON OTRO ABORDAJE"
    },
    {
     "CATALOG_KEY": "2263",
     "PRO_NOMBRE": "ETMOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "2264",
     "PRO_NOMBRE": "ESFENOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "227",
     "PRO_NOMBRE": "REPARACIÓN DE SENO MAXILAR"
    },
    {
     "CATALOG_KEY": "2271",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE SENO MAXILAR"
    },
    {
     "CATALOG_KEY": "2279",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE SENO MAXILAR"
    },
    {
     "CATALOG_KEY": "229",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE SENOS NASALES (PARANASALES)"
    },
    {
     "CATALOG_KEY": "229X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRES SENOS NASALES (PARANASALES)"
    },
    {
     "CATALOG_KEY": "23",
     "PRO_NOMBRE": "EXTRACCIÓN Y RESTAURACIÓN DE DIENTES"
    },
    {
     "CATALOG_KEY": "230",
     "PRO_NOMBRE": "EXTRACCIÓN DE DIENTE CON FÓRCEPS"
    },
    {
     "CATALOG_KEY": "2301",
     "PRO_NOMBRE": "EXTRACCIÓN DE DIENTE CADUCO"
    },
    {
     "CATALOG_KEY": "2309",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DIENTE"
    },
    {
     "CATALOG_KEY": "231",
     "PRO_NOMBRE": "EXTRACCIÓN QUIRÚRGICA DE DIENTE"
    },
    {
     "CATALOG_KEY": "2311",
     "PRO_NOMBRE": "EXTRACCIÓN DE RAÍZ RESIDUAL"
    },
    {
     "CATALOG_KEY": "2319",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN QUIRÚRGICA DE DIENTE"
    },
    {
     "CATALOG_KEY": "232",
     "PRO_NOMBRE": "RESTAURACIÓN DE DIENTE MEDIANTE OBTURACIONES"
    },
    {
     "CATALOG_KEY": "232X",
     "PRO_NOMBRE": "RESTAURACIÓN DE DIENTE MEDIANTE OBTURACIONES"
    },
    {
     "CATALOG_KEY": "233",
     "PRO_NOMBRE": "RESTAURACIÓN DE DIENTE MEDIANTE INCRUSTACIÓN"
    },
    {
     "CATALOG_KEY": "233X",
     "PRO_NOMBRE": "RESTAURACIÓN DE DIENTE MEDIANTE INCRUSTACIÓN"
    },
    {
     "CATALOG_KEY": "234",
     "PRO_NOMBRE": "OTRA RESTAURACIÓN DENTAL"
    },
    {
     "CATALOG_KEY": "2341",
     "PRO_NOMBRE": "APLICACIÓN DE CORONA"
    },
    {
     "CATALOG_KEY": "2342",
     "PRO_NOMBRE": "INSERCIÓN DE PUENTE FIJO"
    },
    {
     "CATALOG_KEY": "2343",
     "PRO_NOMBRE": "INSERCIÓN DE PUENTE REMOVIBLE"
    },
    {
     "CATALOG_KEY": "2349",
     "PRO_NOMBRE": "OTRA RESTAURACIÓN DENTAL"
    },
    {
     "CATALOG_KEY": "235",
     "PRO_NOMBRE": "IMPLANTE DE DIENTE"
    },
    {
     "CATALOG_KEY": "235X",
     "PRO_NOMBRE": "IMPLANTE DE DIENTE"
    },
    {
     "CATALOG_KEY": "236",
     "PRO_NOMBRE": "IMPLANTE DENTAL PROTÉSICO"
    },
    {
     "CATALOG_KEY": "236X",
     "PRO_NOMBRE": "IMPLANTE DENTAL PROTÉSICO"
    },
    {
     "CATALOG_KEY": "237",
     "PRO_NOMBRE": "APICECTOMÍA Y TRATAMIENTO DE CONDUCTO RADICULAR"
    },
    {
     "CATALOG_KEY": "2370",
     "PRO_NOMBRE": "CONDUCTO RADICULAR, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "2371",
     "PRO_NOMBRE": "TRATAMIENTO DE CONDUCTO RADICULAR CON IRRIGACIÓN"
    },
    {
     "CATALOG_KEY": "2372",
     "PRO_NOMBRE": "TRATAMIENTO DE CONDUCTO RADICULAR CON APICOECTOMÍA"
    },
    {
     "CATALOG_KEY": "2373",
     "PRO_NOMBRE": "APICECTOMÍA"
    },
    {
     "CATALOG_KEY": "24",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE DIENTES, ENCÍAS Y ALVÉOLOS"
    },
    {
     "CATALOG_KEY": "240",
     "PRO_NOMBRE": "INCISIÓN DE ENCÍA O HUESO ALVEOLAR"
    },
    {
     "CATALOG_KEY": "240X",
     "PRO_NOMBRE": "INCISIÓN DE ENCÍA O HUESO ALVEOLAR"
    },
    {
     "CATALOG_KEY": "241",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE DIENTES, ENCÍAS Y ALVÉOLOS"
    },
    {
     "CATALOG_KEY": "2411",
     "PRO_NOMBRE": "BIOPSIA DE ENCÍA"
    },
    {
     "CATALOG_KEY": "2412",
     "PRO_NOMBRE": "BIOPSIA DE ALVEÓLO"
    },
    {
     "CATALOG_KEY": "2419",
     "PRO_NOMBRE": "OTROS PROCEDIMENTOS DIAGNÓSTICOS SOBRE DIENTES, ENCÍAS Y ALVÉOLOS"
    },
    {
     "CATALOG_KEY": "242",
     "PRO_NOMBRE": "GINGIVOPLASTIA"
    },
    {
     "CATALOG_KEY": "242X",
     "PRO_NOMBRE": "GINGIVOPLASTIA"
    },
    {
     "CATALOG_KEY": "243",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ENCÍA"
    },
    {
     "CATALOG_KEY": "2431",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN O DE TEJIDO DE ENCÍA"
    },
    {
     "CATALOG_KEY": "2432",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE ENCÍA"
    },
    {
     "CATALOG_KEY": "2439",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE ENCÍA"
    },
    {
     "CATALOG_KEY": "244",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN MAXILAR DE ORIGEN DENTARIO"
    },
    {
     "CATALOG_KEY": "244X",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN MAXILAR DE ORIGEN DENTARIO"
    },
    {
     "CATALOG_KEY": "245",
     "PRO_NOMBRE": "ALVEOLOPLASTIA"
    },
    {
     "CATALOG_KEY": "245X",
     "PRO_NOMBRE": "ALVEOLOPLASTIA"
    },
    {
     "CATALOG_KEY": "246",
     "PRO_NOMBRE": "EXPOSICIÓN DE DIENTE"
    },
    {
     "CATALOG_KEY": "246X",
     "PRO_NOMBRE": "EXPOSICIÓN DE DIENTE"
    },
    {
     "CATALOG_KEY": "247",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO ORTODÓNTICO"
    },
    {
     "CATALOG_KEY": "247X",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO ORTODÓNTICO"
    },
    {
     "CATALOG_KEY": "248",
     "PRO_NOMBRE": "OTRA OPERACIÓN ORTODÓNTICA"
    },
    {
     "CATALOG_KEY": "248X",
     "PRO_NOMBRE": "OTRA OPERACIÓN ORTODÓNTICA"
    },
    {
     "CATALOG_KEY": "249",
     "PRO_NOMBRE": "OTRAS OPERACIONES DENTALES"
    },
    {
     "CATALOG_KEY": "2491",
     "PRO_NOMBRE": "EXTENSIÓN O PROFUNDIZACIÓN DEL SURCO BUCOLABIAL O LINGUAL"
    },
    {
     "CATALOG_KEY": "2499",
     "PRO_NOMBRE": "OTRAS OPERACIONES DENTALES"
    },
    {
     "CATALOG_KEY": "25",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA LENGUA"
    },
    {
     "CATALOG_KEY": "250",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA LENGUA"
    },
    {
     "CATALOG_KEY": "2501",
     "PRO_NOMBRE": "BIOPSIA CERRADA (PUNCIÓN-ASPIRACIÓN CON AGUJA FINA) [PAAF] DE LENGUA"
    },
    {
     "CATALOG_KEY": "2502",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE LA LENGUA"
    },
    {
     "CATALOG_KEY": "2509",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA LENGUA"
    },
    {
     "CATALOG_KEY": "251",
     "PRO_NOMBRE": "EXCISIÓN, DESTRUCCIÓN O LISIS DE LESIÓN O TEJIDO DE LA LENGUA"
    },
    {
     "CATALOG_KEY": "251X",
     "PRO_NOMBRE": "EXCISIÓN, DESTRUCCIÓN O LISIS DE LESIÓN O TEJIDO DE LA LENGUA"
    },
    {
     "CATALOG_KEY": "252",
     "PRO_NOMBRE": "GLOSECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "252X",
     "PRO_NOMBRE": "GLOSECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "253",
     "PRO_NOMBRE": "GLOSECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "253X",
     "PRO_NOMBRE": "GLOSECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "254",
     "PRO_NOMBRE": "GLOSECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "254X",
     "PRO_NOMBRE": "GLOSECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "255",
     "PRO_NOMBRE": "REPARACIÓN DE LENGUA Y GLOSOPLASTIA"
    },
    {
     "CATALOG_KEY": "2551",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE LENGUA"
    },
    {
     "CATALOG_KEY": "2559",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE REPARACIÓN Y PLÁSTICA SOBRE LA LENGUA"
    },
    {
     "CATALOG_KEY": "259",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA LENGUA"
    },
    {
     "CATALOG_KEY": "2591",
     "PRO_NOMBRE": "FRENOTOMÍA LINGUAL"
    },
    {
     "CATALOG_KEY": "2592",
     "PRO_NOMBRE": "FRENECTOMÍA LINGUAL"
    },
    {
     "CATALOG_KEY": "2593",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE LENGUA"
    },
    {
     "CATALOG_KEY": "2594",
     "PRO_NOMBRE": "OTRA GLOSOTOMÍA"
    },
    {
     "CATALOG_KEY": "2599",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE LA LENGUA"
    },
    {
     "CATALOG_KEY": "26",
     "PRO_NOMBRE": "OPERACIONES SOBRE GLÁNDULAS Y CONDUCTOS SALIVALES"
    },
    {
     "CATALOG_KEY": "260",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "260X",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "261",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE GLÁNDULAS Y CONDUCTOS SALIVALES"
    },
    {
     "CATALOG_KEY": "2611",
     "PRO_NOMBRE": "BIOPSIA CERRADA [PUNCIÓN-ASPIRACIÓN CON AGUJA FINA] [PAAF] DE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "2612",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "2619",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE GLÁNDULAS Y CONDUCTOS SALIVALES"
    },
    {
     "CATALOG_KEY": "262",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE GLÁNDULA SALIVAL"
    },
    {
     "CATALOG_KEY": "2621",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE QUISTE DE GLÁNDULA SALIVAL"
    },
    {
     "CATALOG_KEY": "2629",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE LESIÓN DE GLÁNDULA SALIVAL"
    },
    {
     "CATALOG_KEY": "263",
     "PRO_NOMBRE": "SIALOADENECTOMÍA"
    },
    {
     "CATALOG_KEY": "2630",
     "PRO_NOMBRE": "SIALOADENECTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "2631",
     "PRO_NOMBRE": "SIALOADENECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "2632",
     "PRO_NOMBRE": "SIALOADENECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "264",
     "PRO_NOMBRE": "REPARACIÓN DE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "2641",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE GLÁNDULA SALIVAL"
    },
    {
     "CATALOG_KEY": "2642",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA SALIVAL"
    },
    {
     "CATALOG_KEY": "2649",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE REPARACIÓN Y PLÁSTICAS SOBRE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "269",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "2691",
     "PRO_NOMBRE": "EXPLORACIÓN DE CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "2699",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE GLÁNDULA O CONDUCTO SALIVAL"
    },
    {
     "CATALOG_KEY": "27",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE BOCA Y CARA"
    },
    {
     "CATALOG_KEY": "270",
     "PRO_NOMBRE": "DRENAJE DE CARA Y SUELO DE LA BOCA"
    },
    {
     "CATALOG_KEY": "270X",
     "PRO_NOMBRE": "DRENAJE DE CARA Y SUELO DE LA BOCA"
    },
    {
     "CATALOG_KEY": "271",
     "PRO_NOMBRE": "INCISIÓN DEL PALADAR"
    },
    {
     "CATALOG_KEY": "271X",
     "PRO_NOMBRE": "INCISIÓN DE PALADAR"
    },
    {
     "CATALOG_KEY": "272",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA CAVIDAD ORAL"
    },
    {
     "CATALOG_KEY": "2721",
     "PRO_NOMBRE": "BIOPSIA DEL PALADAR ÓSEO"
    },
    {
     "CATALOG_KEY": "2722",
     "PRO_NOMBRE": "BIOPSIA DE ÚVULA Y PALADAR BLANDO"
    },
    {
     "CATALOG_KEY": "2723",
     "PRO_NOMBRE": "BIOPSIA DE LABIO"
    },
    {
     "CATALOG_KEY": "2724",
     "PRO_NOMBRE": "BIOPSIA DE BOCA, ESTRUCTURA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "2729",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE CAVIDAD ORAL"
    },
    {
     "CATALOG_KEY": "273",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN O TEJIDO DEL PALADAR ÓSEO"
    },
    {
     "CATALOG_KEY": "2731",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O RESECCIÓN DE LESIÓN O DE TEJIDO DEL PALADAR ÓSEO"
    },
    {
     "CATALOG_KEY": "2732",
     "PRO_NOMBRE": "EXCISIÓN AMPLIA O RESECCIÓN DE LESIÓN O TEJIDO DEL PALADAR ÓSEO"
    },
    {
     "CATALOG_KEY": "274",
     "PRO_NOMBRE": "EXCISIÓN DE OTRAS PARTES DE LA BOCA"
    },
    {
     "CATALOG_KEY": "2741",
     "PRO_NOMBRE": "FRENECTOMÍA LABIAL"
    },
    {
     "CATALOG_KEY": "2742",
     "PRO_NOMBRE": "EXCISIÓN AMPLIA DE LESIÓN DE LABIO"
    },
    {
     "CATALOG_KEY": "2743",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE LESIÓN O TEJIDO DE LABIO"
    },
    {
     "CATALOG_KEY": "2749",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE BOCA"
    },
    {
     "CATALOG_KEY": "275",
     "PRO_NOMBRE": "REPARACIÓN PLÁSTICA DE LA BOCA"
    },
    {
     "CATALOG_KEY": "2751",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE LABIO"
    },
    {
     "CATALOG_KEY": "2752",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE LA OTRA PARTE DE BOCA"
    },
    {
     "CATALOG_KEY": "2753",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE BOCA"
    },
    {
     "CATALOG_KEY": "2754",
     "PRO_NOMBRE": "REPARACIÓN DE LABIO FISURADO"
    },
    {
     "CATALOG_KEY": "2755",
     "PRO_NOMBRE": "INJERTO DE PIEL DE GROSOR TOTAL APLICADO AL LABIO Y CAVIDAD BUCAL"
    },
    {
     "CATALOG_KEY": "2756",
     "PRO_NOMBRE": "OTRO INJERTO DE PIEL APLICADO AL LABIO Y CAVIDAD BUCAL"
    },
    {
     "CATALOG_KEY": "2757",
     "PRO_NOMBRE": "UNIÓN DE PEDÍCULO O INJERTO DE COLGAJO AL LABIO Y CAVIDAD BUCAL"
    },
    {
     "CATALOG_KEY": "2759",
     "PRO_NOMBRE": "OTRA REPARACIÓN PLÁSTICA DE LA BOCA"
    },
    {
     "CATALOG_KEY": "276",
     "PRO_NOMBRE": "PALATOPLASTIA"
    },
    {
     "CATALOG_KEY": "2761",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE PALADAR"
    },
    {
     "CATALOG_KEY": "2762",
     "PRO_NOMBRE": "CORRECCIÓN DE PALADAR FISURADO (ESTAFILORRAFIA) (L)"
    },
    {
     "CATALOG_KEY": "2763",
     "PRO_NOMBRE": "REVISIÓN DE REPARACIÓN DE PALADAR FISURADO"
    },
    {
     "CATALOG_KEY": "2764",
     "PRO_NOMBRE": "INSERCIÓN DE IMPLANTE DE PALADAR"
    },
    {
     "CATALOG_KEY": "2769",
     "PRO_NOMBRE": "OTRA REPARACIÓN PLÁSTICA DE PALADAR"
    },
    {
     "CATALOG_KEY": "277",
     "PRO_NOMBRE": "OPERACIONES SOBRE ÚVULA"
    },
    {
     "CATALOG_KEY": "2771",
     "PRO_NOMBRE": "INCISIÓN DE ÚVULA"
    },
    {
     "CATALOG_KEY": "2772",
     "PRO_NOMBRE": "EXCISIÓN DE ÚVULA"
    },
    {
     "CATALOG_KEY": "2773",
     "PRO_NOMBRE": "REPARACIÓN DE ÚVULA"
    },
    {
     "CATALOG_KEY": "2779",
     "PRO_NOMBRE": "OTRA OPERACIONES SOBRE ÚVULA"
    },
    {
     "CATALOG_KEY": "279",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE BOCA Y CARA"
    },
    {
     "CATALOG_KEY": "2791",
     "PRO_NOMBRE": "FRENOTOMÍA LABIAL"
    },
    {
     "CATALOG_KEY": "2792",
     "PRO_NOMBRE": "INCISIÓN DE CAVIDAD BUCAL ESTRUCTURA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "2799",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA CAVIDAD ORAL"
    },
    {
     "CATALOG_KEY": "28",
     "PRO_NOMBRE": "OPERACIONES SOBRE AMÍGDALAS Y ADENOIDES"
    },
    {
     "CATALOG_KEY": "280",
     "PRO_NOMBRE": "INCISIÓN Y DRENAJE DE AMÍGDALA Y ESTRUCTURAS PERIAMIGDALINAS"
    },
    {
     "CATALOG_KEY": "280X",
     "PRO_NOMBRE": "INCISIÓN Y DRENAJE DE AMÍGDALA Y ESTRUCTURAS PERIAMIGDALINAS"
    },
    {
     "CATALOG_KEY": "281",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE AMÍGDALAS Y ADENOIDES"
    },
    {
     "CATALOG_KEY": "2811",
     "PRO_NOMBRE": "BIOPSIA DE AMÍGDALAS Y ADENOIDES"
    },
    {
     "CATALOG_KEY": "2819",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE AMÍGDALAS Y ADENOIDES"
    },
    {
     "CATALOG_KEY": "282",
     "PRO_NOMBRE": "AMIGDALECTOMÍA SIN ADENOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "282X",
     "PRO_NOMBRE": "AMIGDALECTOMÍA SIN ADENOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "283",
     "PRO_NOMBRE": "AMIGDALECTOMÍA CON ADENOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "283X",
     "PRO_NOMBRE": "AMIGDALECTOMÍA CON ADENOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "284",
     "PRO_NOMBRE": "EXTIRPACIÓN DE RESTO AMIGDALINO"
    },
    {
     "CATALOG_KEY": "284X",
     "PRO_NOMBRE": "EXTIRPACIÓN DE RESTO AMÍGDALINO"
    },
    {
     "CATALOG_KEY": "285",
     "PRO_NOMBRE": "EXTIRPACIÓN DE AMÍGDALA LINGUAL"
    },
    {
     "CATALOG_KEY": "285X",
     "PRO_NOMBRE": "EXTIRPACIÓN DE AMÍGDALA LINGUAL"
    },
    {
     "CATALOG_KEY": "286",
     "PRO_NOMBRE": "ADENOIDECTOMÍA SIN AMIGDALECTOMÍA"
    },
    {
     "CATALOG_KEY": "286X",
     "PRO_NOMBRE": "ADENOIDECTOMÍA SIN AMIGDALECTOMÍA"
    },
    {
     "CATALOG_KEY": "287",
     "PRO_NOMBRE": "CONTROL DE HEMORRAGIA DESPUÉS DE AMIGDALECTOMÍA Y ADENOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "287X",
     "PRO_NOMBRE": "CONTROL DE HEMORRAGIA DESPUÉS DE AMIGDALECTOMÍA Y ADENOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "289",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE AMÍGDALAS Y ADENOIDES"
    },
    {
     "CATALOG_KEY": "2891",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DE LA AMÍGDALA Y ADENOIDES POR INCISIÓN"
    },
    {
     "CATALOG_KEY": "2892",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE AMÍGDALA Y ADENOIDES"
    },
    {
     "CATALOG_KEY": "2899",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE AMÍGDALA Y ADENOIDES"
    },
    {
     "CATALOG_KEY": "29",
     "PRO_NOMBRE": "OPERACIONES SOBRE FARINGE"
    },
    {
     "CATALOG_KEY": "290",
     "PRO_NOMBRE": "FARINGOTOMÍA"
    },
    {
     "CATALOG_KEY": "290X",
     "PRO_NOMBRE": "FARINGOTOMÍA"
    },
    {
     "CATALOG_KEY": "291",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE FARINGE"
    },
    {
     "CATALOG_KEY": "2911",
     "PRO_NOMBRE": "FARINGOSCOPÍA"
    },
    {
     "CATALOG_KEY": "2912",
     "PRO_NOMBRE": "BIOPSIA FARÍNGEA"
    },
    {
     "CATALOG_KEY": "2919",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE FARINGE"
    },
    {
     "CATALOG_KEY": "292",
     "PRO_NOMBRE": "EXCISIÓN DE QUISTE O VESTIGIO DE HENDIDURA BRANQUIAL"
    },
    {
     "CATALOG_KEY": "292X",
     "PRO_NOMBRE": "EXCISIÓN DE QUISTE O VESTIGIO DE HENDIDURA BRANQUIAL"
    },
    {
     "CATALOG_KEY": "293",
     "PRO_NOMBRE": "EXCISIÓN O RESECCIÓN DE LESIÓN O TEJIDO DE FARINGE"
    },
    {
     "CATALOG_KEY": "2931",
     "PRO_NOMBRE": "MIOTOMÍA CRICOFARÍNGEA"
    },
    {
     "CATALOG_KEY": "2932",
     "PRO_NOMBRE": "DIVERTICULOTOMÍA FARÍNGEA"
    },
    {
     "CATALOG_KEY": "2933",
     "PRO_NOMBRE": "FARINGECTOMÍA (PARCIAL)"
    },
    {
     "CATALOG_KEY": "2939",
     "PRO_NOMBRE": "OTRA EXCISIÓN O RESECCIÓN DE LESIÓN O TEJIDO DE LA FARINGE"
    },
    {
     "CATALOG_KEY": "294",
     "PRO_NOMBRE": "OPERACIÓN PLÁSTICA SOBRE FARINGE"
    },
    {
     "CATALOG_KEY": "294X",
     "PRO_NOMBRE": "OPERACIÓN PLÁSTICA SOBRE FARINGE"
    },
    {
     "CATALOG_KEY": "295",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE FARINGE (REPARACIÓN CON COLGAJOS LIBRES O PEDICULADOS)"
    },
    {
     "CATALOG_KEY": "2951",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE FARINGE"
    },
    {
     "CATALOG_KEY": "2952",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE HENDIDURA BRANQUIAL"
    },
    {
     "CATALOG_KEY": "2953",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA DE FARINGE"
    },
    {
     "CATALOG_KEY": "2954",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS FARÍNGEAS"
    },
    {
     "CATALOG_KEY": "2959",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE FARINGE"
    },
    {
     "CATALOG_KEY": "299",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE FARINGE"
    },
    {
     "CATALOG_KEY": "2991",
     "PRO_NOMBRE": "DILATACIÓN DE FARINGE"
    },
    {
     "CATALOG_KEY": "2992",
     "PRO_NOMBRE": "SECCIÓN DEL NERVIO GLOSOFARÍNGEO"
    },
    {
     "CATALOG_KEY": "2999",
     "PRO_NOMBRE": "OTRA OPERACIÓN DE LA FARINGE"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL APARATO RESPIRATORIO (30 - 34)"
    },
    {
     "CATALOG_KEY": "30",
     "PRO_NOMBRE": "EXCISIÓN DE LARINGE"
    },
    {
     "CATALOG_KEY": "300",
     "PRO_NOMBRE": "EXCISIÓN O RESECCIÓN DE LESIÓN O TEJIDO DE LARINGE"
    },
    {
     "CATALOG_KEY": "3001",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE QUISTE LARÍNGEO"
    },
    {
     "CATALOG_KEY": "3009",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE LA LARINGE"
    },
    {
     "CATALOG_KEY": "301",
     "PRO_NOMBRE": "HEMILARINGECTOMÍA"
    },
    {
     "CATALOG_KEY": "301X",
     "PRO_NOMBRE": "HEMILARINGECTOMÍA"
    },
    {
     "CATALOG_KEY": "302",
     "PRO_NOMBRE": "OTRA LARINGECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "3021",
     "PRO_NOMBRE": "EPIGLOTECTOMÍA"
    },
    {
     "CATALOG_KEY": "3022",
     "PRO_NOMBRE": "CORDECTOMÍA"
    },
    {
     "CATALOG_KEY": "3029",
     "PRO_NOMBRE": "OTRA LARINGECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "303",
     "PRO_NOMBRE": "LARINGECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "303X",
     "PRO_NOMBRE": "LARINGECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "304",
     "PRO_NOMBRE": "LARINGECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "304X",
     "PRO_NOMBRE": "LARINGECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "31",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LARINGE Y TRÁQUEA"
    },
    {
     "CATALOG_KEY": "310",
     "PRO_NOMBRE": "INYECCIÓN EN LARINGE"
    },
    {
     "CATALOG_KEY": "310X",
     "PRO_NOMBRE": "INYECCIÓN EN LARINGE"
    },
    {
     "CATALOG_KEY": "311",
     "PRO_NOMBRE": "TRAQUEOSTOMÍA TEMPORAL"
    },
    {
     "CATALOG_KEY": "311X",
     "PRO_NOMBRE": "TRAQUEOSTOMÍA TEMPORAL"
    },
    {
     "CATALOG_KEY": "312",
     "PRO_NOMBRE": "TRAQUEEOTOMÍA PERMANENTE"
    },
    {
     "CATALOG_KEY": "3121",
     "PRO_NOMBRE": "OTRA INCISIÓN DE LARINGE O TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3129",
     "PRO_NOMBRE": "OTRA TRAQUEOSTOMÍA PERMANENTE"
    },
    {
     "CATALOG_KEY": "313",
     "PRO_NOMBRE": "OTRA INCISIÓN DE LARINGE O TRÁQUEA"
    },
    {
     "CATALOG_KEY": "313X",
     "PRO_NOMBRE": "OTRA INCISIÓN DE LARINGE O TRÁQUEA"
    },
    {
     "CATALOG_KEY": "314",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LARIGE Y TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3141",
     "PRO_NOMBRE": "TRAQUEOSCOPIA A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "3142",
     "PRO_NOMBRE": "LARINGOSCOPIA Y OTRA TRAQUEOSCOPIA"
    },
    {
     "CATALOG_KEY": "3143",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] DE LARINGE"
    },
    {
     "CATALOG_KEY": "3144",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] DE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3145",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE LARINGE O TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3148",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LARINGE"
    },
    {
     "CATALOG_KEY": "3149",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "315",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO DE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "315X",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO DE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "316",
     "PRO_NOMBRE": "REPARACIÓN DE LARINGE"
    },
    {
     "CATALOG_KEY": "3161",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE LARINGE"
    },
    {
     "CATALOG_KEY": "3162",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE LARINGE"
    },
    {
     "CATALOG_KEY": "3163",
     "PRO_NOMBRE": "REVISIÓN DE LARINGOSTOMÍA"
    },
    {
     "CATALOG_KEY": "3164",
     "PRO_NOMBRE": "REPARACIÓN DE FRACTURA LARÍNGEA"
    },
    {
     "CATALOG_KEY": "3169",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE LARINGE"
    },
    {
     "CATALOG_KEY": "317",
     "PRO_NOMBRE": "OPERACIONES DE REPARACIÓN Y PLÁSTICA SOBRE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3171",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3172",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA EXTERNA DE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3173",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA DE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3174",
     "PRO_NOMBRE": "REVISIÓN DE TRAQUEOSTOMÍA"
    },
    {
     "CATALOG_KEY": "3175",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE TRÁQUEA Y CONSTRUCCIÓN DE LARINGE ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "3179",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE RECONSTRUCCIÓN Y PLÁSTICA SOBRE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "319",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LARINGE Y TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3191",
     "PRO_NOMBRE": "SECCIÓN DE NERVIO LARÍNGEO"
    },
    {
     "CATALOG_KEY": "3192",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE TRÁQUEA O LARINGE"
    },
    {
     "CATALOG_KEY": "3193",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUTOR LARINGEO O TRAQUEAL"
    },
    {
     "CATALOG_KEY": "3194",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA DE ACCIÓN LOCAL EN LA TRÁQUEA"
    },
    {
     "CATALOG_KEY": "3195",
     "PRO_NOMBRE": "FISTULIZACIÓN TRAQUEOESOFÁGICA"
    },
    {
     "CATALOG_KEY": "3198",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LARINGE"
    },
    {
     "CATALOG_KEY": "3199",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TRÁQUEA"
    },
    {
     "CATALOG_KEY": "32",
     "PRO_NOMBRE": "EXCISIÓN DE PULMÓN Y DE BRONQUIO"
    },
    {
     "CATALOG_KEY": "320",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO BRONQUIAL"
    },
    {
     "CATALOG_KEY": "3201",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN ENDOSCÓPICA DE LESIÓN O TEJIDO BRONQUIAL"
    },
    {
     "CATALOG_KEY": "3209",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN LOCAL DE LESIÓN O TEJIDO BRONQUIAL"
    },
    {
     "CATALOG_KEY": "321",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE BRONQUIO"
    },
    {
     "CATALOG_KEY": "321X",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE BRONQUIO"
    },
    {
     "CATALOG_KEY": "322",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO PULMONAR"
    },
    {
     "CATALOG_KEY": "3220",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN O TEJIDO PULMONAR POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3221",
     "PRO_NOMBRE": "PLICATURA DE BULLA ENFISEMATOSA"
    },
    {
     "CATALOG_KEY": "3222",
     "PRO_NOMBRE": "REDUCCIÓN QUIRÚRGICA DE VOLUMEN PULMONAR"
    },
    {
     "CATALOG_KEY": "3223",
     "PRO_NOMBRE": "ABLACIÓN ABIERTA DE LESIÓN O TEJIDO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3224",
     "PRO_NOMBRE": "ABLACIÓN PERCUTÁNEA DE LESIÓN O TEJIDO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3225",
     "PRO_NOMBRE": "ABLACIÓN DE LESIÓN O TEJIDO DE PULMÓN POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3226",
     "PRO_NOMBRE": "OTRA ABLACIÓN Y LA NO ESPECIFICADA DE LESIÓN O TEJIDO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3228",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN ENDOSCÓPICA DE LESIÓN O TEJIDO PULMÓN"
    },
    {
     "CATALOG_KEY": "3229",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO PULMONAR"
    },
    {
     "CATALOG_KEY": "323",
     "PRO_NOMBRE": "RESECCIÓN SEGMENTARIA DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3230",
     "PRO_NOMBRE": "RESECCIÓN SEGMENTARÍA DE PULMÓN POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3239",
     "PRO_NOMBRE": "OTRA RESECCIÓN SEGMENTARÍA Y LA NO ESPECIFICADA DE PULMÓN"
    },
    {
     "CATALOG_KEY": "324",
     "PRO_NOMBRE": "LOBECTOMÍA DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3241",
     "PRO_NOMBRE": "LOBECTOMÍA DE PULMÓN POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3249",
     "PRO_NOMBRE": "OTRA LOBECTOMÍA DE PULMÓN"
    },
    {
     "CATALOG_KEY": "325",
     "PRO_NOMBRE": "NEUMONECTOMÍA [PNEUMONECTOMÍA]"
    },
    {
     "CATALOG_KEY": "3250",
     "PRO_NOMBRE": "NEUMONECTOMÍA [PNEUMONECTOMÍA] POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3259",
     "PRO_NOMBRE": "OTRA NEUMONECTOMÍA [PNEUMONECTOMÍA] Y LA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "326",
     "PRO_NOMBRE": "DISECCIÓN RADICAL DE ESTRUCTURAS TORÁCICAS"
    },
    {
     "CATALOG_KEY": "326X",
     "PRO_NOMBRE": "DISECCIÓN RADICAL DE ESTRUCTURAS TORÁCICAS"
    },
    {
     "CATALOG_KEY": "329",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE PULMÓN"
    },
    {
     "CATALOG_KEY": "329X",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE PULMÓN"
    },
    {
     "CATALOG_KEY": "33",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PULMÓN Y BRONQUIO"
    },
    {
     "CATALOG_KEY": "330",
     "PRO_NOMBRE": "INCISIÓN DE BRONQUIO"
    },
    {
     "CATALOG_KEY": "330X",
     "PRO_NOMBRE": "INCISIÓN DE BRONQUIO"
    },
    {
     "CATALOG_KEY": "331",
     "PRO_NOMBRE": "INCISIÓN DE PULMÓN"
    },
    {
     "CATALOG_KEY": "331X",
     "PRO_NOMBRE": "INCISIÓN DE PULMÓN"
    },
    {
     "CATALOG_KEY": "332",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PULMÓN Y BRONQUIO"
    },
    {
     "CATALOG_KEY": "3320",
     "PRO_NOMBRE": "BIOPSIA DE PULMÓN POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3321",
     "PRO_NOMBRE": "BRONCOSCOPIA A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "3322",
     "PRO_NOMBRE": "BRONCOSCOPIA FIBROÓPTICA"
    },
    {
     "CATALOG_KEY": "3323",
     "PRO_NOMBRE": "OTRA BRONCOSCOPIA"
    },
    {
     "CATALOG_KEY": "3324",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] BRONQUIAL"
    },
    {
     "CATALOG_KEY": "3325",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE BRONQUIO"
    },
    {
     "CATALOG_KEY": "3326",
     "PRO_NOMBRE": "BIOPSIA CERRADA CON [AGUJA] [PERCUTÁNEA] DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3327",
     "PRO_NOMBRE": "BIOPSIA CERRADA DE PULMÓN POR ENDOCOPÍA"
    },
    {
     "CATALOG_KEY": "3328",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3329",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PULMÓN Y BRONQUIO"
    },
    {
     "CATALOG_KEY": "333",
     "PRO_NOMBRE": "COLAPSO QUIRÚRGICO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3331",
     "PRO_NOMBRE": "DESTRUCCIÓN DE NERVIO FRÉNICO POR COLAPSO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3332",
     "PRO_NOMBRE": "NEUMOTÓRAX ARTIFICIAL POR COLAPSO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3333",
     "PRO_NOMBRE": "NEUMOPERITONEO POR COLAPSO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3334",
     "PRO_NOMBRE": "TORACOPLASTIA"
    },
    {
     "CATALOG_KEY": "3339",
     "PRO_NOMBRE": "OTRO COLAPSO QUIRÚRGICO DE PULMÓN"
    },
    {
     "CATALOG_KEY": "334",
     "PRO_NOMBRE": "OPERACIONES DE REPARACIÓN Y PLÁSTICA SOBRE PULMÓN Y BRONQUIO"
    },
    {
     "CATALOG_KEY": "3341",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN BRONQUIAL"
    },
    {
     "CATALOG_KEY": "3342",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA BRONQUIAL"
    },
    {
     "CATALOG_KEY": "3343",
     "PRO_NOMBRE": "CIERRE DE LACERACIÓN DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3348",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE REPARACIÓN Y PLÁSTICA SOBRE BRONQUIO"
    },
    {
     "CATALOG_KEY": "3349",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE REPARACIÓN Y PLÁSTICA SOBRE PULMÓN"
    },
    {
     "CATALOG_KEY": "335",
     "PRO_NOMBRE": "TRASPLANTE DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3350",
     "PRO_NOMBRE": "TRANSPLANTE DE PULMÓN, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "3351",
     "PRO_NOMBRE": "TRANSPLANTE UNILATERAL DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3352",
     "PRO_NOMBRE": "TRANSPLANTE BILATERAL DE PULMÓN"
    },
    {
     "CATALOG_KEY": "336",
     "PRO_NOMBRE": "TRASPLANTE COMBINADO DE CORAZÓN-PULMÓN"
    },
    {
     "CATALOG_KEY": "336X",
     "PRO_NOMBRE": "TRANSPLANTE COMBINADO DE CORAZÓN-PULMÓN"
    },
    {
     "CATALOG_KEY": "337",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS ENDOSCÓPICOS SOBRE PULMÓN Y BRONQUIOS"
    },
    {
     "CATALOG_KEY": "3371",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN ENDOSCÓPICA DE VÁLVULA(S) BRONQUIAL(ES)"
    },
    {
     "CATALOG_KEY": "3372",
     "PRO_NOMBRE": "MEDICIÓN ENDOSCÓPICA DE FLUJO AÉREO PULMONAR"
    },
    {
     "CATALOG_KEY": "3378",
     "PRO_NOMBRE": "RETIRO ENDOSCÓPICO DE DISPOSITIVO(S) O SUSTANCIAS EN BRONQUIO"
    },
    {
     "CATALOG_KEY": "3379",
     "PRO_NOMBRE": "INSERCIÓN ENDOSCÓPICA DE OTRO DISPOSITIVO O SUSTANCIAS EN BRONQUIO"
    },
    {
     "CATALOG_KEY": "339",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PULMÓN Y BRONQUIO"
    },
    {
     "CATALOG_KEY": "3391",
     "PRO_NOMBRE": "DILATACIÓN BRONQUIAL"
    },
    {
     "CATALOG_KEY": "3392",
     "PRO_NOMBRE": "LIGADURA DE BRONQUIO"
    },
    {
     "CATALOG_KEY": "3393",
     "PRO_NOMBRE": "PUNCIÓN DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3398",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE BRONQUIO"
    },
    {
     "CATALOG_KEY": "3399",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PULMÓN"
    },
    {
     "CATALOG_KEY": "34",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA PARED TORÁCICA, PLEURA, MEDIASTINO Y DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "340",
     "PRO_NOMBRE": "INCISIÓN DE PARED TORÁCICA Y PLEURAL"
    },
    {
     "CATALOG_KEY": "3401",
     "PRO_NOMBRE": "INCISIÓN DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "3402",
     "PRO_NOMBRE": "TORACOTOMÍA EXPLORATORIA"
    },
    {
     "CATALOG_KEY": "3403",
     "PRO_NOMBRE": "REAPERTURA A TRAVÉS DE TORACOTOMÍA RECIENTE"
    },
    {
     "CATALOG_KEY": "3404",
     "PRO_NOMBRE": "INSERCIÓN DE CATÉTER INTERCOSTAL PARA DRENAJE"
    },
    {
     "CATALOG_KEY": "3405",
     "PRO_NOMBRE": "CREACIÓN DE DERIVACIÓN PLEUROPERITONEAL"
    },
    {
     "CATALOG_KEY": "3406",
     "PRO_NOMBRE": "DRENAJE DE CAVIDAD PLEURAL POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3409",
     "PRO_NOMBRE": "OTRA INCISIÓN DE PLEURA"
    },
    {
     "CATALOG_KEY": "340A",
     "PRO_NOMBRE": "SELLO DE AGUA"
    },
    {
     "CATALOG_KEY": "341",
     "PRO_NOMBRE": "INCISIÓN DE MEDIASTINO"
    },
    {
     "CATALOG_KEY": "341X",
     "PRO_NOMBRE": "INCISIÓN DE MEDIASTINO"
    },
    {
     "CATALOG_KEY": "342",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PARED TORÁCICA, PLEURA, MEDIASTINO Y DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3420",
     "PRO_NOMBRE": "BIOPSIA PLEURAL POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3421",
     "PRO_NOMBRE": "TORACOSCOPÍA TRANSPLEURAL"
    },
    {
     "CATALOG_KEY": "3422",
     "PRO_NOMBRE": "MEDIASTINOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3423",
     "PRO_NOMBRE": "BIOPSIA DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "3424",
     "PRO_NOMBRE": "OTRA BIOPSIA PLEURAL"
    },
    {
     "CATALOG_KEY": "3425",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [PERCUTÁNEA] DE MEDIASTINO"
    },
    {
     "CATALOG_KEY": "3426",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE MEDIASTINO"
    },
    {
     "CATALOG_KEY": "3427",
     "PRO_NOMBRE": "BIOPSIA DE DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3428",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA PARED TORÁCICA, PLEURA Y DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3429",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MEDIASTINO"
    },
    {
     "CATALOG_KEY": "343",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE MEDIASTINO"
    },
    {
     "CATALOG_KEY": "343X",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE MEDIASTINO"
    },
    {
     "CATALOG_KEY": "344",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "344X",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "345",
     "PRO_NOMBRE": "PLEURECTOMÍA"
    },
    {
     "CATALOG_KEY": "3451",
     "PRO_NOMBRE": "DECORTICACIÓN DE PULMÓN"
    },
    {
     "CATALOG_KEY": "3452",
     "PRO_NOMBRE": "DECORTICACIÓN DE PULMÓN POR TORACOSCOPÍA"
    },
    {
     "CATALOG_KEY": "3459",
     "PRO_NOMBRE": "OTROS EXCISIÓN DE PLEURA"
    },
    {
     "CATALOG_KEY": "346",
     "PRO_NOMBRE": "ESCARIFICACIÓN DE PLEURA"
    },
    {
     "CATALOG_KEY": "346X",
     "PRO_NOMBRE": "ESCARIFICACIÓN DE PLEURA"
    },
    {
     "CATALOG_KEY": "347",
     "PRO_NOMBRE": "REPARACIÓN DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "3471",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "3472",
     "PRO_NOMBRE": "CIERRE DE TORACOSTOMÍA"
    },
    {
     "CATALOG_KEY": "3473",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA DE TÓRAX"
    },
    {
     "CATALOG_KEY": "3474",
     "PRO_NOMBRE": "REPARACIÓN DE DEFORMIDAD DE PECTUS"
    },
    {
     "CATALOG_KEY": "3479",
     "PRO_NOMBRE": "OTRA REPARACIÓN SOBRE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "348",
     "PRO_NOMBRE": "OPERACIONES EN DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3481",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN O TEJIDO DE DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3482",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3483",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3484",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "3485",
     "PRO_NOMBRE": "IMPLANTACIÓN DE MARCAPASOS DIAFRAGMÁTICO"
    },
    {
     "CATALOG_KEY": "3489",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "349",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TÓRAX"
    },
    {
     "CATALOG_KEY": "3491",
     "PRO_NOMBRE": "TORACOCENTESIS"
    },
    {
     "CATALOG_KEY": "3492",
     "PRO_NOMBRE": "INYECCIÓN EN LA CAVIDAD TORÁCICA"
    },
    {
     "CATALOG_KEY": "3493",
     "PRO_NOMBRE": "REPARACIÓN DE PLEURA"
    },
    {
     "CATALOG_KEY": "3499",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE TÓRAX"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL APARATO CARDIOVASCULAR (35 - 39)"
    },
    {
     "CATALOG_KEY": "35",
     "PRO_NOMBRE": "OPERACIONES SOBRE VÁLVULAS Y TABIQUES DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "350",
     "PRO_NOMBRE": "VALVULOTOMÍA CARDÍACA CERRADA"
    },
    {
     "CATALOG_KEY": "3500",
     "PRO_NOMBRE": "VALVULOTOMÍA CARDÍACA CERRADA, VÁLVULA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "3501",
     "PRO_NOMBRE": "VALVULOTOMÍA CARDÍACA CERRADA, VÁLVULA AÓRTICA"
    },
    {
     "CATALOG_KEY": "3502",
     "PRO_NOMBRE": "VALVULOTOMÍA CARDÍACA CERRADA, VÁLVULA MITRAL"
    },
    {
     "CATALOG_KEY": "3503",
     "PRO_NOMBRE": "VALVULOTOMÍA CARDÍACA CERRADA, VÁLVULA PULMONAR"
    },
    {
     "CATALOG_KEY": "3504",
     "PRO_NOMBRE": "VALVULOTOMÍA CARDÍACA CERRADA, VÁLVULA TRICÚSPIDE"
    },
    {
     "CATALOG_KEY": "351",
     "PRO_NOMBRE": "VALVULOPLASTIA CARDIACA ABIERTA SIN SUSTITUCIÓN VALVULAR"
    },
    {
     "CATALOG_KEY": "3510",
     "PRO_NOMBRE": "VALVULOPLASTIA CARDÍACA ABIERTA SIN SUSTITUCIÓN, VÁLVULA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "3511",
     "PRO_NOMBRE": "VALVULOPLASTIA CARDÍACA ABIERTA DE VÁLVULA AÓRTICA SIN SUSTITUCIÓN"
    },
    {
     "CATALOG_KEY": "3512",
     "PRO_NOMBRE": "VALVULOPLASTIA CARDÍACA ABIERTA DE VÁLVULA MITRAL SIN SUSTITUCIÓN"
    },
    {
     "CATALOG_KEY": "3513",
     "PRO_NOMBRE": "VALVULOPLASTIA CARDIACA ABIERTA DE VÁLVULA PULMONAR SIN SUSTITUCIÓN"
    },
    {
     "CATALOG_KEY": "3514",
     "PRO_NOMBRE": "VALVULOPLASTIA CARDIACA ABIERTA DE VÁLVULA TRICÚSPIDE SIN SUSTITUCIÓN"
    },
    {
     "CATALOG_KEY": "352",
     "PRO_NOMBRE": "SUSTITUCIÓN DE VÁLVULA CARDIACA"
    },
    {
     "CATALOG_KEY": "3520",
     "PRO_NOMBRE": "SUSTITUCIÓN DE VÁLVULA CARDIACA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "3521",
     "PRO_NOMBRE": "SUSTITUCIÓN DE VÁLVULA AÓRTICA CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3522",
     "PRO_NOMBRE": "OTRA SUSTITUCIÓN DE VÁLVULA AÓRTICA"
    },
    {
     "CATALOG_KEY": "3523",
     "PRO_NOMBRE": "SUSTITUCIÓN DE VÁLVULA MITRAL CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3524",
     "PRO_NOMBRE": "OTRA SUSTITUCIÓN DE VÁLVULA MITRAL"
    },
    {
     "CATALOG_KEY": "3525",
     "PRO_NOMBRE": "SUSTITUCIÓN DE VÁLVULA PULMONAR CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3526",
     "PRO_NOMBRE": "OTRA SUSTITUCIÓN DE VÁLVULA PULMONAR"
    },
    {
     "CATALOG_KEY": "3527",
     "PRO_NOMBRE": "SUSTITUCIÓN DE VÁLVULA TRICÚSPIDE CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3528",
     "PRO_NOMBRE": "OTRA SUSTITUCIÓN DE VÁLVULA TRICÚSPIDE"
    },
    {
     "CATALOG_KEY": "353",
     "PRO_NOMBRE": "OPERACIONES SOBRE ESTRUCTURAS ADYACENTES A LAS VÁLVULAS CARDIACAS"
    },
    {
     "CATALOG_KEY": "3531",
     "PRO_NOMBRE": "OPERACIONES SOBRE MÚSCULO PAPILAR"
    },
    {
     "CATALOG_KEY": "3532",
     "PRO_NOMBRE": "OPERACIONES SOBRE CUERDAS TENDINOSAS"
    },
    {
     "CATALOG_KEY": "3533",
     "PRO_NOMBRE": "ANULOPLASTIA"
    },
    {
     "CATALOG_KEY": "3534",
     "PRO_NOMBRE": "INFUNDIBULECTOMÍA"
    },
    {
     "CATALOG_KEY": "3535",
     "PRO_NOMBRE": "OPERACIONES SOBRE TRABÉCULAS DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "3539",
     "PRO_NOMBRE": "OPERACIONES SOBRE OTRAS ESTRUCTURAS ADYACENTES A LAS VÁLVULAS CARDIACAS"
    },
    {
     "CATALOG_KEY": "354",
     "PRO_NOMBRE": "PRODUCCIÓN DE DEFECTO DE TABIQUE EN EL CORAZÓN"
    },
    {
     "CATALOG_KEY": "3541",
     "PRO_NOMBRE": "AMPLIACIÓN DE DEFECTO EXISTENTE EN TABIQUE INTERAURICULAR"
    },
    {
     "CATALOG_KEY": "3542",
     "PRO_NOMBRE": "CREACIÓN DE DEFECTO DE TABIQUE EN EL CORAZÓN"
    },
    {
     "CATALOG_KEY": "355",
     "PRO_NOMBRE": "REPARACIÓN DE TABIQUES INTERAURICULAR E INTERVENTRICULAR CON PRÓTESIS"
    },
    {
     "CATALOG_KEY": "3550",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO NO ESPECIFICADO DE TABIQUES CARDÍACOS CON PRÓTESIS"
    },
    {
     "CATALOG_KEY": "3551",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE TABIQUE INTERAURICULAR CON PRÓTESIS, TÉCNICA ABIERTA"
    },
    {
     "CATALOG_KEY": "3552",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE TABIQUE INTERAURICULAR CON PRÓTESIS, TÉCNICA CERRADA"
    },
    {
     "CATALOG_KEY": "3553",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE TABIQUE INTERVENTRICULAR CON PRÓTESIS, TÉCNICA ABIERTA"
    },
    {
     "CATALOG_KEY": "3554",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE COJINES ENDOCÁRDICOS CON PRÓTESIS"
    },
    {
     "CATALOG_KEY": "3555",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE TABIQUE INTERVENTRICULAR CON PRÓTESIS, TÉCNICA CERRADA"
    },
    {
     "CATALOG_KEY": "356",
     "PRO_NOMBRE": "REPARACIÓN DE TABIQUES INTERAURICULAR E INTERVENTRICULAR CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3560",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE TABIQUE CARDÍACO NO ESPECIFICADO CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3561",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE TABIQUE INTERAURICULAR CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3562",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE TABIQUE INTERVENTRICULAR CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3563",
     "PRO_NOMBRE": "REPARACIÓN DE DEFECTO DE COJINES ENDOCÁRDICOS CON INJERTO DE TEJIDO"
    },
    {
     "CATALOG_KEY": "357",
     "PRO_NOMBRE": "OTRA REPARACIÓN NO ESPECIFICADA DEL TABIQUE INTERAURICULAR E INTERVENTRICULAR"
    },
    {
     "CATALOG_KEY": "3570",
     "PRO_NOMBRE": "OTRA REPARACIÓN NO ESPECIFICADA DE DEFECTOS DE TABIQUES CARDÍACOS"
    },
    {
     "CATALOG_KEY": "3571",
     "PRO_NOMBRE": "OTRA REPARACIÓN NO ESPECIFICADA DE TABIQUE INTERAURICULAR"
    },
    {
     "CATALOG_KEY": "3572",
     "PRO_NOMBRE": "OTRA REPARACIÓN NO ESPECIFICADA DE TABIQUE INTERVENTRICULAR"
    },
    {
     "CATALOG_KEY": "3573",
     "PRO_NOMBRE": "OTRA REPARACIÓN NO ESPECIFICADA DE DEFECTO DE COJINES ENDOCÁRDICOS"
    },
    {
     "CATALOG_KEY": "358",
     "PRO_NOMBRE": "REPARACIÓN TOTAL DE CIERTAS ANOMALÍAS CARDIACAS CONGÉNITAS"
    },
    {
     "CATALOG_KEY": "3581",
     "PRO_NOMBRE": "REPARACIÓN TOTAL DE TETRALOGÍA DE FALLOT"
    },
    {
     "CATALOG_KEY": "3582",
     "PRO_NOMBRE": "REPARACIÓN COMPLETA DE DRENAJE VENOSO PULMONAR ANÓMALO TOTAL"
    },
    {
     "CATALOG_KEY": "3583",
     "PRO_NOMBRE": "REPARACIÓN TOTAL DE TRONCO ARTERIOSO"
    },
    {
     "CATALOG_KEY": "3584",
     "PRO_NOMBRE": "CORRECCIÓN TOTAL O TRANSPOSICIÓN DE GRANDES VASOS, NO CLASIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "359",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VÁLVULAS Y TABIQUES CARDIACOS"
    },
    {
     "CATALOG_KEY": "3591",
     "PRO_NOMBRE": "TRANSPOSICIÓN INTERAURICULAR DEL RETORNO VENOSO"
    },
    {
     "CATALOG_KEY": "3592",
     "PRO_NOMBRE": "CREACIÓN DE CONDUCTO ENTRE EL VENTRÍCULO DERECHO Y LA ARTERIA PULMONAR"
    },
    {
     "CATALOG_KEY": "3593",
     "PRO_NOMBRE": "CREACIÓN DE CONDUCTO ENTRE EL VENTRÍCULO IZQUIERDO Y LA AORTA"
    },
    {
     "CATALOG_KEY": "3594",
     "PRO_NOMBRE": "CREACIÓN DE CONDUCTO ENTRE AURÍCULA Y ARTERIA PULMONAR"
    },
    {
     "CATALOG_KEY": "3595",
     "PRO_NOMBRE": "REVISIÓN DE PROCEDIMIENTO CORRECTIVO SOBRE EL CORAZÓN"
    },
    {
     "CATALOG_KEY": "3596",
     "PRO_NOMBRE": "VALVULOPLASTIA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "3598",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TABIQUES CARDÍACOS"
    },
    {
     "CATALOG_KEY": "3599",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VÁLVULAS CARDÍACAS"
    },
    {
     "CATALOG_KEY": "36",
     "PRO_NOMBRE": "OPERACIONES SOBRE VASOS CARDIACOS"
    },
    {
     "CATALOG_KEY": "360",
     "PRO_NOMBRE": "ELIMINACIÓN DE ESTENOSIS ARTERIAL CORONARIA CON O SIN IMPLANTE DE PRÓTESIS INTRACORONARIA(S) \"STENT\"(S)"
    },
    {
     "CATALOG_KEY": "3603",
     "PRO_NOMBRE": "ANGIOPLASTIA DE ARTERIA CORONARIA A TÓRAX ABIERTO"
    },
    {
     "CATALOG_KEY": "3604",
     "PRO_NOMBRE": "INFUSIÓN ARTERIAL INTRACORONARIA DE TROMBOLÍTICO"
    },
    {
     "CATALOG_KEY": "3606",
     "PRO_NOMBRE": "INSERCIÓN DE STENT(S) NO LIBERADOR DE FÁRMACOS EN ARTERIA CORONARIA"
    },
    {
     "CATALOG_KEY": "3607",
     "PRO_NOMBRE": "INSERCIÓN DE STENT(S) LIBERADOR (ES) DE FÁRMACOS EN ARTERIA CORONARIA"
    },
    {
     "CATALOG_KEY": "3609",
     "PRO_NOMBRE": "OTRA ELIMINACIÓN DE OBSTRUCCIÓN DE ARTERIA CORONARIA"
    },
    {
     "CATALOG_KEY": "361",
     "PRO_NOMBRE": "ANASTOMOSIS DE DERIVACIÓN PARA REVASCULARIZACIÓN MIOCÁRDICA"
    },
    {
     "CATALOG_KEY": "3610",
     "PRO_NOMBRE": "DERIVACIÓN AORTOCORONARIA PARA REVASCULARIZACIÓN MIOCÁRDICA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "3611",
     "PRO_NOMBRE": "DERIVACIÓN (AORTO) CORONARIA DE UNA ARTERIA CORONARIA"
    },
    {
     "CATALOG_KEY": "3612",
     "PRO_NOMBRE": "DERIVACIÓN (AORTO) CORONARIA DE DOS ARTERIAS CORONARIAS"
    },
    {
     "CATALOG_KEY": "3613",
     "PRO_NOMBRE": "DERIVACIÓN (AORTO) CORONARIA DE TRES ARTERIAS CORONARIAS"
    },
    {
     "CATALOG_KEY": "3614",
     "PRO_NOMBRE": "DERIVACIÓN (AORTO) CORONARIA DE CUATRO O MÁS ARTERIAS CORONARIAS"
    },
    {
     "CATALOG_KEY": "3615",
     "PRO_NOMBRE": "ANASTOMOSIS SIMPLE DE ARTERIA MAMARIA INTERNA-ARTERIA CORONARIA"
    },
    {
     "CATALOG_KEY": "3616",
     "PRO_NOMBRE": "ANASTOMOSIS DOBLE DE ARTERIA MAMARIA INTERNA-ARTERIA CORONARIA"
    },
    {
     "CATALOG_KEY": "3617",
     "PRO_NOMBRE": "ANASTOMOSIS DE LA ARTERIA CORONARIA ABDOMINAL"
    },
    {
     "CATALOG_KEY": "3619",
     "PRO_NOMBRE": "OTRAS ANASTOMOSIS DE DERIVACIÓN PARA REVASCULARIZACIÓN MIOCÁRDICA"
    },
    {
     "CATALOG_KEY": "362",
     "PRO_NOMBRE": "REVASCULARIZACIÓN CARDIACA POR IMPLANTACIÓN ARTERIAL"
    },
    {
     "CATALOG_KEY": "362X",
     "PRO_NOMBRE": "REVASCULARIZACIÓN CARDÍACA POR IMPLANTACIÓN ARTERIAL"
    },
    {
     "CATALOG_KEY": "363",
     "PRO_NOMBRE": "OTRA REVASCULARIZACIÓN CARDÍACA"
    },
    {
     "CATALOG_KEY": "3631",
     "PRO_NOMBRE": "REVASCULARIZACIÓN TRANSMIOCÁRDIACA CON TORAX ABIERTO"
    },
    {
     "CATALOG_KEY": "3632",
     "PRO_NOMBRE": "OTRA REVASCULARIZACIÓN TRANSMIOCÁRDICA"
    },
    {
     "CATALOG_KEY": "3633",
     "PRO_NOMBRE": "REVASCULARIZACIÓN TRANSMIOCÁRDICA ENDOSCÓPICA"
    },
    {
     "CATALOG_KEY": "3634",
     "PRO_NOMBRE": "REVASCULARIZACIÓN TRANSMIOCÁRDICA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "3639",
     "PRO_NOMBRE": "OTRA REVASCULARIZACIÓN DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "369",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VASOS CARDIACOS"
    },
    {
     "CATALOG_KEY": "3691",
     "PRO_NOMBRE": "REPARACIÓN DE ANEURISMA DE VASO CORONARIO"
    },
    {
     "CATALOG_KEY": "3699",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VASOS CARDÍACOS"
    },
    {
     "CATALOG_KEY": "37",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CORAZÓN Y PERICARDIO"
    },
    {
     "CATALOG_KEY": "370",
     "PRO_NOMBRE": "PERICARDIOCENTESIS"
    },
    {
     "CATALOG_KEY": "370X",
     "PRO_NOMBRE": "PERICARDIOCENTESIS"
    },
    {
     "CATALOG_KEY": "371",
     "PRO_NOMBRE": "CARDIOTOMÍA Y PERICARDIOTOMÍA"
    },
    {
     "CATALOG_KEY": "3710",
     "PRO_NOMBRE": "INCISIÓN DE CORAZÓN, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "3711",
     "PRO_NOMBRE": "CARDIOTOMÍA"
    },
    {
     "CATALOG_KEY": "3712",
     "PRO_NOMBRE": "PERICARDIOTOMÍA"
    },
    {
     "CATALOG_KEY": "372",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE CORAZÓN Y PERICARDIO"
    },
    {
     "CATALOG_KEY": "3720",
     "PRO_NOMBRE": "ESTIMULACIÓN ELÉCTRICA PROGRAMADA NO INVASIVA [EEPNI] [NIPS]"
    },
    {
     "CATALOG_KEY": "3721",
     "PRO_NOMBRE": "CATETERISMO CARDÍACO DEL LADO DERECHO DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "3722",
     "PRO_NOMBRE": "CATETERISMO CARDÍACO DEL LADO IZQUIERDO DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "3723",
     "PRO_NOMBRE": "CATETERISMO COMBINADO DE LOS LADOS DERECHO E IZQUIERDO DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "3724",
     "PRO_NOMBRE": "BIOPSIA DE PERICARDIO"
    },
    {
     "CATALOG_KEY": "3725",
     "PRO_NOMBRE": "BIOPSIA DE CORAZÓN"
    },
    {
     "CATALOG_KEY": "3726",
     "PRO_NOMBRE": "ESTUDIO ELECTROFISIOLÓGICO INVASIVO POR CATÉTER (CORAZÓN Y PERICARDIO)"
    },
    {
     "CATALOG_KEY": "3727",
     "PRO_NOMBRE": "MAPEO CARDIACO"
    },
    {
     "CATALOG_KEY": "3728",
     "PRO_NOMBRE": "ECOCARDIOGRAFIA INTRACARDÍACA"
    },
    {
     "CATALOG_KEY": "3729",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE CORAZÓN Y PERICARDIO"
    },
    {
     "CATALOG_KEY": "373",
     "PRO_NOMBRE": "PERICARDIECTOMÍA Y EXCISIÓN DE LESIÓN DE CORAZÓN"
    },
    {
     "CATALOG_KEY": "3731",
     "PRO_NOMBRE": "PERICARDIECTOMÍA"
    },
    {
     "CATALOG_KEY": "3732",
     "PRO_NOMBRE": "EXCISIÓN DE ANEURISMA DE CORAZÓN"
    },
    {
     "CATALOG_KEY": "3733",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE OTRA LESIÓN O TEJIDO CARDÍACO POR VÍA ABIERTA"
    },
    {
     "CATALOG_KEY": "3734",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE OTRA LESIÓN O TEJIDOS DEL CORAZÓN, OTRO ACCESO"
    },
    {
     "CATALOG_KEY": "3735",
     "PRO_NOMBRE": "VENTRICULECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "3736",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE APÉNDICE DE AURÍCULA IZQUIERDA [LAA]"
    },
    {
     "CATALOG_KEY": "374",
     "PRO_NOMBRE": "REPARACIÓN DE CORAZÓN Y PERICARDIO"
    },
    {
     "CATALOG_KEY": "3741",
     "PRO_NOMBRE": "IMPLANTACIÓN DE DISPOSITIVO PROTÉSICO DE ASISTENCIA CARDIACA ALREDEDOR DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "3749",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE CORAZÓN Y PERICARDIO"
    },
    {
     "CATALOG_KEY": "375",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE SUSTITUCIÓN CARDÍACA"
    },
    {
     "CATALOG_KEY": "3751",
     "PRO_NOMBRE": "TRASPLANTE CARDÍACO"
    },
    {
     "CATALOG_KEY": "3752",
     "PRO_NOMBRE": "IMPLANTACIÓN DE SISTEMA TOTAL DE REEMPLAZO CARDÍACO INTERNO BIVENTRICULAR"
    },
    {
     "CATALOG_KEY": "3753",
     "PRO_NOMBRE": "REEMPLAZO O REPARACIÓN DE LA UNIDAD TORÁCICA DEL SISTEMA DE REEMPLAZO CARDÍACO (TOTAL)"
    },
    {
     "CATALOG_KEY": "3754",
     "PRO_NOMBRE": "REEMPLAZO O REPARACIÓN DE OTRO COMPONENTE IMPLANTABLE DEL SISTEMA DE REEMPLAZO CARDÍACO TOTAL"
    },
    {
     "CATALOG_KEY": "3755",
     "PRO_NOMBRE": "RETIRO DE SISTEMA INTERNO BIVENTRICULAR DE REEMPLAZO CARDÍACO"
    },
    {
     "CATALOG_KEY": "376",
     "PRO_NOMBRE": "IMPLANTE DE SISTEMA(S) DE ASISTENCIA CARDIACA Y CIRCULATORIA"
    },
    {
     "CATALOG_KEY": "3760",
     "PRO_NOMBRE": "IMPLANTACIÓN O INSERCIÓN DE UN SISTEMA EXTERNO BIVENTRICULAR DE ASISTENCIA CARDÍACA"
    },
    {
     "CATALOG_KEY": "3761",
     "PRO_NOMBRE": "IMPLANTE DE BALÓN CONTRAPULSACIÓN"
    },
    {
     "CATALOG_KEY": "3762",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO DE ASISTENCIA CIRCULATORIA EXTRACORPÓREA TEMPORAL NO IMPLANTABLE"
    },
    {
     "CATALOG_KEY": "3763",
     "PRO_NOMBRE": "REPARACIÓN DE SISTEMA DE ASISTENCIA CARDIACA"
    },
    {
     "CATALOG_KEY": "3764",
     "PRO_NOMBRE": "EXTRACCIÓN DE SISTEMA(S) O DISPOSITIVO(S) EXTERNO DE ASISTENCIA CARDÍACA"
    },
    {
     "CATALOG_KEY": "3765",
     "PRO_NOMBRE": "IMPLANTACIÓN DE SISTEMA DE ASISTENCIA CARDIACA EXTERNA DE UN SOLO VENTRÍCULO [EXTRACORPOREO]"
    },
    {
     "CATALOG_KEY": "3766",
     "PRO_NOMBRE": "INSERCIÓN DE SISTEMA IMPLANTABLE DE ASISTENCIA CARDIACA"
    },
    {
     "CATALOG_KEY": "3767",
     "PRO_NOMBRE": "IMPLANTACIÓN DE SISTEMA DE CARDIOMIOESTIMULACIÓN"
    },
    {
     "CATALOG_KEY": "3768",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO DE ASISTENCIA CARDÍACA EXTERNO PERCUTÁNEO"
    },
    {
     "CATALOG_KEY": "377",
     "PRO_NOMBRE": "INSERCIÓN, REVISIÓN, SUSTITUCIÓN Y ELIMINACIÓN DE ELECTRODOS; INSERCIÓN DE SISTEMA DE MARCAPASOS TEMPORAL O REVISIÓN DE BOLSA DE DISPOSITIVO CARDIACO"
    },
    {
     "CATALOG_KEY": "3770",
     "PRO_NOMBRE": "INSERCIÓN INICIAL DE ELECTRODO NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "3771",
     "PRO_NOMBRE": "INSERCIÓN INICIAL DE ELECTRODO TRANSVENOSO EN VENTRÍCULO"
    },
    {
     "CATALOG_KEY": "3772",
     "PRO_NOMBRE": "INSERCIÓN INICIAL DE ELECTRODO TRANSVENOSO EN AURICULA Y VENTRÍCULO"
    },
    {
     "CATALOG_KEY": "3773",
     "PRO_NOMBRE": "INSERCIÓN INICIAL DE ELECTRODO TRANSVENOSO EN AURÍCULA"
    },
    {
     "CATALOG_KEY": "3774",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE ELECTRODO EPICÁRDICO"
    },
    {
     "CATALOG_KEY": "3775",
     "PRO_NOMBRE": "REVISIÓN DE CABLE (ELECTRODO)"
    },
    {
     "CATALOG_KEY": "3776",
     "PRO_NOMBRE": "SUSTITUCIÓN DE ELECTRODO TRANSVENOSO EN AURÍCULA Y VENTRÍCULO"
    },
    {
     "CATALOG_KEY": "3777",
     "PRO_NOMBRE": "EXTRACCIÓN DE ELECTRODO SIN SUSTITUCIÓN"
    },
    {
     "CATALOG_KEY": "3778",
     "PRO_NOMBRE": "INSERCIÓN DE UN SISTEMA DE MARCAPASOS TRANSVENOSO TEMPORAL"
    },
    {
     "CATALOG_KEY": "3779",
     "PRO_NOMBRE": "REVISIÓN O REUBICACIÓN DE BOLSA DE DISPOSITIVO CARDIACO"
    },
    {
     "CATALOG_KEY": "378",
     "PRO_NOMBRE": "INSERCIÓN, SUSTITUCIÓN, EXTRACCIÓN Y REVISIÓN DE DISPOSITIVO MARCAPASOS"
    },
    {
     "CATALOG_KEY": "3780",
     "PRO_NOMBRE": "INSERCIÓN DE MARCAPASOS PERMANENTE, INICIAL O SUSTITUÍDO, SIN ESPECIFICAR TIPO DE APARATO"
    },
    {
     "CATALOG_KEY": "3781",
     "PRO_NOMBRE": "INSERCIÓN INICIAL DE UN DISPOSITIVO DE CÁMARA ÚNICA, NO ESPECIFICADO COMO RESPUESTA EN FRECUENCIA"
    },
    {
     "CATALOG_KEY": "3782",
     "PRO_NOMBRE": "INSERCIÓN INICIAL DE UN APARATO DE CÁMARA ÚNICA, CON RESPUESTA EN FRECUENCIA"
    },
    {
     "CATALOG_KEY": "3783",
     "PRO_NOMBRE": "INSERCIÓN INICIAL DE DISPOSITIVO DE DOBLE CÁMARA"
    },
    {
     "CATALOG_KEY": "3785",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CUALQUIER TIPO DE MARCAPASOS CON APARATO DE CÁMARA ÚNICA, NO ESPECIFICADO COMO RESPUESTA EN FRECUENCIA"
    },
    {
     "CATALOG_KEY": "3786",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CUALQUIER TIPO DE MARCAPASOS CON APARATO DE CÁMARA ÚNICA, CON RESPUESTA EN FRECUENCIA"
    },
    {
     "CATALOG_KEY": "3787",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CUALQUIER TIPO DE MARCAPASOS CON DISPOSITIVO DE CÁMARA DOBLE"
    },
    {
     "CATALOG_KEY": "3789",
     "PRO_NOMBRE": "REVISIÓN O EXTRACCIÓN DE MARCAPASOS CARDÍACO"
    },
    {
     "CATALOG_KEY": "379",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CORAZÓN Y PERICARDIO"
    },
    {
     "CATALOG_KEY": "3790",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO ACCESORIO AURICULAR IZQUIERDO"
    },
    {
     "CATALOG_KEY": "3791",
     "PRO_NOMBRE": "MASAJE CARDÍACO A TÓRAX ABIERTO"
    },
    {
     "CATALOG_KEY": "3792",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN CORAZÓN"
    },
    {
     "CATALOG_KEY": "3793",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN PERICARDIO"
    },
    {
     "CATALOG_KEY": "3794",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE CARDIOVERSOR/DESFIBRILADOR AUTOMÁTICO, SISTEMA TOTAL [AICD] SISTEMA TOTAL"
    },
    {
     "CATALOG_KEY": "3795",
     "PRO_NOMBRE": "IMPLANTE DE CARDIOVERSOR/DESFIBRILADOR AUTOMÁTICO, SÓLO ELECTRODOS"
    },
    {
     "CATALOG_KEY": "3796",
     "PRO_NOMBRE": "IMPLANTACIÓN DE CARDIOVERSOR/DESFIBRILADOR AUTOMÁTICO, SÓLO GENERADOR DE PULSO"
    },
    {
     "CATALOG_KEY": "3797",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CARDIOVERSOR/DESFIBRILADOR AUTOMÁTICO, SÓLO CABLE(S)"
    },
    {
     "CATALOG_KEY": "3798",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CARDIOVERSOR/DESFIBRILADOR AUTOMÁTICO, SÓLO GENERADOR DE PULSO"
    },
    {
     "CATALOG_KEY": "3799",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE CORAZÓN Y PERICARDIO"
    },
    {
     "CATALOG_KEY": "38",
     "PRO_NOMBRE": "INCISIÓN, EXCISIÓN Y OCLUSIÓN DE VASOS"
    },
    {
     "CATALOG_KEY": "380",
     "PRO_NOMBRE": "INCISIÓN DE VASO"
    },
    {
     "CATALOG_KEY": "3800",
     "PRO_NOMBRE": "INCISIÓN DE VASO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3801",
     "PRO_NOMBRE": "INCISIÓN, VASOS INTRACRANEALES"
    },
    {
     "CATALOG_KEY": "3802",
     "PRO_NOMBRE": "INCISIÓN, OTROS VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3803",
     "PRO_NOMBRE": "INCISIÓN, VASOS DE MIEMBROS SUPERIORES"
    },
    {
     "CATALOG_KEY": "3804",
     "PRO_NOMBRE": "INCISIÓN, AORTA"
    },
    {
     "CATALOG_KEY": "3805",
     "PRO_NOMBRE": "INCISIÓN, OTROS VASOS TORÁCICOS"
    },
    {
     "CATALOG_KEY": "3806",
     "PRO_NOMBRE": "INCISIÓN, ARTERIAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3807",
     "PRO_NOMBRE": "INCISIÓN, VENAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3808",
     "PRO_NOMBRE": "INCISIÓN, ARTERIAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "3809",
     "PRO_NOMBRE": "INCISIÓN DE VENAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "381",
     "PRO_NOMBRE": "ENDARTERECTOMÍA"
    },
    {
     "CATALOG_KEY": "3810",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3811",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, VASOS INTRACRANEALES"
    },
    {
     "CATALOG_KEY": "3812",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, OTROS VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3813",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, VASOS DE MIEMBROS SUPERIORES"
    },
    {
     "CATALOG_KEY": "3814",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, AORTA"
    },
    {
     "CATALOG_KEY": "3815",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, OTROS VASOS TORÁCICOS"
    },
    {
     "CATALOG_KEY": "3816",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, ARTERIAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3818",
     "PRO_NOMBRE": "ENDARTERECTOMÍA, ARTERIAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "382",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE VASOS SANGUÍNEOS"
    },
    {
     "CATALOG_KEY": "3821",
     "PRO_NOMBRE": "BIOPSIA DE VASO SANGUÍNEO"
    },
    {
     "CATALOG_KEY": "3822",
     "PRO_NOMBRE": "ANGIOSCOPIA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "3823",
     "PRO_NOMBRE": "ESPECTROSCOPÍA INTRAVASCULAR"
    },
    {
     "CATALOG_KEY": "3829",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE VASOS SANGUÍNEOS"
    },
    {
     "CATALOG_KEY": "383",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS"
    },
    {
     "CATALOG_KEY": "3830",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3831",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, VASOS INTRACRANEALES"
    },
    {
     "CATALOG_KEY": "3832",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, OTROS VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3833",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, VASOS DE MIEMBROS SUPERIORES"
    },
    {
     "CATALOG_KEY": "3834",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, AORTA"
    },
    {
     "CATALOG_KEY": "3835",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, OTROS VASOS TORÁCICOS"
    },
    {
     "CATALOG_KEY": "3836",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, ARTERIAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3837",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, VENAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3838",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS, ARTERIAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "3839",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON ANASTOMOSIS DE VENAS, MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "384",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN"
    },
    {
     "CATALOG_KEY": "3840",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3841",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, VASOS INTRACRANEALES"
    },
    {
     "CATALOG_KEY": "3842",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, OTROS VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3843",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN DE VASOS, MIEMBROS SUPERIORES"
    },
    {
     "CATALOG_KEY": "3844",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, AORTA ABDOMINAL"
    },
    {
     "CATALOG_KEY": "3845",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, VASOS TORÁCICOS"
    },
    {
     "CATALOG_KEY": "3846",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, ARTERIAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3847",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, VENAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3848",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, ARTERIAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "3849",
     "PRO_NOMBRE": "RESECCIÓN DE VASO CON SUSTITUCIÓN, VENAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "385",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS"
    },
    {
     "CATALOG_KEY": "3850",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3851",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS, VASOS INTRACRANEALES"
    },
    {
     "CATALOG_KEY": "3852",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS, OTROS VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3853",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS, VASOS DE MIEMBROS SUPERIORES"
    },
    {
     "CATALOG_KEY": "3855",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS, OTROS VASOS TORÁCICOS"
    },
    {
     "CATALOG_KEY": "3857",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS, VENAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3859",
     "PRO_NOMBRE": "LIGADURA Y EXTIRPACIÓN DE VENAS VARICOSAS, VENAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "386",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS"
    },
    {
     "CATALOG_KEY": "3860",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3861",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, VASOS INTRACRANEALES"
    },
    {
     "CATALOG_KEY": "3862",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASO, OTROS VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3863",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, VASOS DE MIEMBROS SUPERIORES"
    },
    {
     "CATALOG_KEY": "3864",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, AORTA"
    },
    {
     "CATALOG_KEY": "3865",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, OTROS VASOS TORÁCICOS"
    },
    {
     "CATALOG_KEY": "3866",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, ARTERIAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3867",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, VENAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3868",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, ARTERIAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "3869",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE VASOS, VENAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "387",
     "PRO_NOMBRE": "INTERRUPCIÓN DE VENA CAVA"
    },
    {
     "CATALOG_KEY": "387X",
     "PRO_NOMBRE": "INTERRUPCIÓN DE VENA CAVA"
    },
    {
     "CATALOG_KEY": "388",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS"
    },
    {
     "CATALOG_KEY": "3880",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3881",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, VASOS INTRACRANEALES"
    },
    {
     "CATALOG_KEY": "3882",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, OTROS VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3883",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, VASOS DE MIEMBROS SUPERIORES"
    },
    {
     "CATALOG_KEY": "3884",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, AORTA"
    },
    {
     "CATALOG_KEY": "3885",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, OTROS VASOS TORÁCICOS"
    },
    {
     "CATALOG_KEY": "3886",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, ARTERIAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3887",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, VENAS ABDOMINALES"
    },
    {
     "CATALOG_KEY": "3888",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUIRÚRGICA DE VASOS, ARTERIAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "3889",
     "PRO_NOMBRE": "OTRA OCLUSIÓN QUÍRURGICA DE VASOS., VENAS DE MIEMBROS INFERIORES"
    },
    {
     "CATALOG_KEY": "389",
     "PRO_NOMBRE": "PUNCIÓN DE VASO"
    },
    {
     "CATALOG_KEY": "3891",
     "PRO_NOMBRE": "CATETERISMO ARTERIAL"
    },
    {
     "CATALOG_KEY": "3892",
     "PRO_NOMBRE": "CATETERISMO DE VENA UMBILICAL"
    },
    {
     "CATALOG_KEY": "3893",
     "PRO_NOMBRE": "CATETERISMO VENOSO, NO CLASIFICADO BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "3894",
     "PRO_NOMBRE": "DISECCIÓN VENOSA"
    },
    {
     "CATALOG_KEY": "3895",
     "PRO_NOMBRE": "CATETERISMO VENOSO PARA DIÁLISIS RENAL"
    },
    {
     "CATALOG_KEY": "3898",
     "PRO_NOMBRE": "OTRA PUNCIÓN DE ARTERIA"
    },
    {
     "CATALOG_KEY": "3899",
     "PRO_NOMBRE": "OTRA PUNCIÓN DE VENA"
    },
    {
     "CATALOG_KEY": "39",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VASOS"
    },
    {
     "CATALOG_KEY": "390",
     "PRO_NOMBRE": "DERIVACIÓN SISTÉMICA A ARTERIA PULMONAR"
    },
    {
     "CATALOG_KEY": "390X",
     "PRO_NOMBRE": "DERIVACIÓN SISTÉMICA A ARTERIA PULMONAR"
    },
    {
     "CATALOG_KEY": "391",
     "PRO_NOMBRE": "DERIVACIÓN VENOSA INTRAABDOMINAL"
    },
    {
     "CATALOG_KEY": "391X",
     "PRO_NOMBRE": "DERIVACIÓN VENOSA INTRAABDOMINAL"
    },
    {
     "CATALOG_KEY": "392",
     "PRO_NOMBRE": "OTRA DERIVACIÓN O DESVIACIÓN VASCULAR"
    },
    {
     "CATALOG_KEY": "3921",
     "PRO_NOMBRE": "ANASTOMOSIS DE VENA CAVA-ARTERIA PULMONAR"
    },
    {
     "CATALOG_KEY": "3922",
     "PRO_NOMBRE": "DESVIACIÓN AORTA-SUBCLAVIA-CAROTÍDEA"
    },
    {
     "CATALOG_KEY": "3923",
     "PRO_NOMBRE": "OTRA DERIVACIÓN O DESVIACIÓN VASCULAR INTRATORÁCICA"
    },
    {
     "CATALOG_KEY": "3924",
     "PRO_NOMBRE": "DERIVACIÓN AORTA-RENAL"
    },
    {
     "CATALOG_KEY": "3925",
     "PRO_NOMBRE": "DERIVACIÓN AORTA-ILIACA-FEMORAL"
    },
    {
     "CATALOG_KEY": "3926",
     "PRO_NOMBRE": "OTRAS DERIVACIONES O DESVIACIONES VASCULARES INTRAABDOMINALES"
    },
    {
     "CATALOG_KEY": "3927",
     "PRO_NOMBRE": "ARTERIOVENOSTOMÍA PARA DIÁLISIS RENAL"
    },
    {
     "CATALOG_KEY": "3928",
     "PRO_NOMBRE": "DERIVACIÓN VASCULAR EXTRACRANEAL-INTRACRANEAL (EC-IC)"
    },
    {
     "CATALOG_KEY": "3929",
     "PRO_NOMBRE": "OTRA DERIVACIÓN O DESVIACIÓN VASCULAR (PERIFÉRICA)"
    },
    {
     "CATALOG_KEY": "393",
     "PRO_NOMBRE": "SUTURA DE VASO"
    },
    {
     "CATALOG_KEY": "3930",
     "PRO_NOMBRE": "SUTURA DE VASO SANGUÍNEO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3931",
     "PRO_NOMBRE": "SUTURA DE ARTERIA"
    },
    {
     "CATALOG_KEY": "3932",
     "PRO_NOMBRE": "SUTURA DE VENA"
    },
    {
     "CATALOG_KEY": "394",
     "PRO_NOMBRE": "REVISIÓN DE PROCEDIMIENTO VASCULAR"
    },
    {
     "CATALOG_KEY": "3941",
     "PRO_NOMBRE": "CONTROL DE HEMORRAGIA DESPUÉS DE CIRUGÍA VASCULAR"
    },
    {
     "CATALOG_KEY": "3942",
     "PRO_NOMBRE": "REVISIÓN DE DERIVACIÓN ARTERIOVENOSA PARA DIÁLISIS RENAL"
    },
    {
     "CATALOG_KEY": "3943",
     "PRO_NOMBRE": "EXTRACCIÓN DE DERIVACIÓN ARTERIOVENOSA PARA DIÁLISIS RENAL"
    },
    {
     "CATALOG_KEY": "3949",
     "PRO_NOMBRE": "OTRA REVISIÓN DE PROCEDIMIENTO VASCULAR"
    },
    {
     "CATALOG_KEY": "395",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE VASOS"
    },
    {
     "CATALOG_KEY": "3950",
     "PRO_NOMBRE": "ANGIOPLASTÍA O ATERECTOMÍA DE OTRO(S) VASO(S) NO CORONARIO(S)"
    },
    {
     "CATALOG_KEY": "3951",
     "PRO_NOMBRE": "INTERRUPCIÓN DE ANEURISMA"
    },
    {
     "CATALOG_KEY": "3952",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ANEURISMA"
    },
    {
     "CATALOG_KEY": "3953",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA ARTERIOVENOSA"
    },
    {
     "CATALOG_KEY": "3954",
     "PRO_NOMBRE": "OPERACIÓN DE RE-ENTRADA (AORTA)"
    },
    {
     "CATALOG_KEY": "3955",
     "PRO_NOMBRE": "REIMPLANTACIÓN DE VASO RENAL ABERRANTE"
    },
    {
     "CATALOG_KEY": "3956",
     "PRO_NOMBRE": "REPARACIÓN DE VASO SANGUÍNEO CON INJERTO DE PARCHE DE TEJIDO"
    },
    {
     "CATALOG_KEY": "3957",
     "PRO_NOMBRE": "REPARACIÓN DE VASO SANGUÍNEO CON INJERTO DE PARCHE SINTÉTICO"
    },
    {
     "CATALOG_KEY": "3958",
     "PRO_NOMBRE": "REPARACIÓN DE VASO SANGUÍNEO CON TIPO DE INJERTO DE PARCHE NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "3959",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE VASO"
    },
    {
     "CATALOG_KEY": "396",
     "PRO_NOMBRE": "CIRCULACIÓN EXTRACORPÓREA Y PROCEDIMIENTOS AUXILIARES DE CIRUGÍA CARDIACA"
    },
    {
     "CATALOG_KEY": "3961",
     "PRO_NOMBRE": "CIRCULACIÓN EXTRACORPÓREA AUXILIAR PARA CIRUGÍA CARDÍACA ABIERTA"
    },
    {
     "CATALOG_KEY": "3962",
     "PRO_NOMBRE": "HIPOTERMIA (SISTÉMICA) ACCESORÍA DE CIRUGÍA CARDÍACA ABIERTA"
    },
    {
     "CATALOG_KEY": "3963",
     "PRO_NOMBRE": "CARDIOPLEJIA"
    },
    {
     "CATALOG_KEY": "3964",
     "PRO_NOMBRE": "MARCAPASOS CARDÍACO INTRAOPERATORIO"
    },
    {
     "CATALOG_KEY": "3965",
     "PRO_NOMBRE": "OXIGENACIÓN EXTRACORPÓREA DE MEMBRANA (OECM)"
    },
    {
     "CATALOG_KEY": "3966",
     "PRO_NOMBRE": "DERIVACIÓN CARDIOPULMONAR PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "397",
     "PRO_NOMBRE": "REPARACIÓN ENDOVASCULAR DE VASO"
    },
    {
     "CATALOG_KEY": "3971",
     "PRO_NOMBRE": "IMPLANTE ENDOVASCULAR DE INJERTO EN AORTA ABDOMINAL"
    },
    {
     "CATALOG_KEY": "3972",
     "PRO_NOMBRE": "REPARACIÓN U OCLUSIÓN ENDOVASCULAR DE VASOS DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3973",
     "PRO_NOMBRE": "IMPLANTACIÓN ENDOVASCULAR DE INJERTO EN AORTA TORÁCICA"
    },
    {
     "CATALOG_KEY": "3974",
     "PRO_NOMBRE": "ELIMINACIÓN ENDOVASCULAR DE OBSTRUCCIÓN DE VASO(S) DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "3979",
     "PRO_NOMBRE": "OTRA REPARACIÓN ENDOVASCULAR (DE ANEURISMA) DE OTROS VASOS"
    },
    {
     "CATALOG_KEY": "398",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL CUERPO CAROTÍDEO, SENO CAROTÍDEO Y OTROS CUERPOS VASCULARES"
    },
    {
     "CATALOG_KEY": "398X",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL CUERPO CAROTÍDEO, SENO CAROTIDEO Y OTROS CUERPOS VASCULARES"
    },
    {
     "CATALOG_KEY": "399",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VASOS"
    },
    {
     "CATALOG_KEY": "3990",
     "PRO_NOMBRE": "INSERCIÓN DE STENT(S) NO LIBERADOR DE FÁRMACOS DE VASO PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "3991",
     "PRO_NOMBRE": "LIBERACIÓN DE VASO"
    },
    {
     "CATALOG_KEY": "3992",
     "PRO_NOMBRE": "INYECCIÓN DE AGENTE ESCLEROSANTE EN VENA"
    },
    {
     "CATALOG_KEY": "3993",
     "PRO_NOMBRE": "INSERCIÓN DE CÁNULA DE VASO A VASO"
    },
    {
     "CATALOG_KEY": "3994",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CÁNULA DE VASO A VASO"
    },
    {
     "CATALOG_KEY": "3995",
     "PRO_NOMBRE": "HEMODIÁLISIS"
    },
    {
     "CATALOG_KEY": "3996",
     "PRO_NOMBRE": "PERFUSIÓN DE CUERPO ENTERO"
    },
    {
     "CATALOG_KEY": "3997",
     "PRO_NOMBRE": "OTRA PERFUSIÓN"
    },
    {
     "CATALOG_KEY": "3998",
     "PRO_NOMBRE": "CONTROL DE HERMORRAGIA, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "3999",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VASOS"
    },
    {
     "CATALOG_KEY": "399A",
     "PRO_NOMBRE": "DESEMPAQUETAMIENTO ABDOMIANAL (PERITONEAL)"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL SISTEMA HEMÁTICO Y LINFÁTICO (40 - 41)"
    },
    {
     "CATALOG_KEY": "40",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL SISTEMA LINFÁTICO"
    },
    {
     "CATALOG_KEY": "400",
     "PRO_NOMBRE": "INCISIÓN DE ESTRUCTURAS LINFÁTICAS"
    },
    {
     "CATALOG_KEY": "400X",
     "PRO_NOMBRE": "INCISIÓN DE ESTRUCTURAS LINFÁTICAS"
    },
    {
     "CATALOG_KEY": "401",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESTRUCTURAS LINFÁTICAS"
    },
    {
     "CATALOG_KEY": "4011",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA LINFÁTICA"
    },
    {
     "CATALOG_KEY": "4019",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESTRUCTURAS LINFÁTICAS"
    },
    {
     "CATALOG_KEY": "402",
     "PRO_NOMBRE": "EXTIRPACIÓN SIMPLE DE ESTRUCTURA LINFÁTICA"
    },
    {
     "CATALOG_KEY": "4021",
     "PRO_NOMBRE": "EXTIRPACIÓN DE GANGLIO LINFÁTICO CERVICAL PROFUNDO"
    },
    {
     "CATALOG_KEY": "4022",
     "PRO_NOMBRE": "EXTIRPACIÓN DE GANGLIO LINFÁTICO MAMARIO INTERNO"
    },
    {
     "CATALOG_KEY": "4023",
     "PRO_NOMBRE": "EXTIRPACIÓN DE GANGLIO LINFÁTICO AXILIAR"
    },
    {
     "CATALOG_KEY": "4024",
     "PRO_NOMBRE": "EXTIRPACIÓN DE GANGLIO LINFÁTICO INGUINAL"
    },
    {
     "CATALOG_KEY": "4029",
     "PRO_NOMBRE": "EXTIRPACIÓN SIMPLE DE OTRA ESTRUCTURA LINFÁTICA"
    },
    {
     "CATALOG_KEY": "403",
     "PRO_NOMBRE": "EXTIRPACIÓN DE GANGLIO LINFÁTICO REGIONAL"
    },
    {
     "CATALOG_KEY": "403X",
     "PRO_NOMBRE": "EXTIRPACIÓN DE GANGLIO LINFÁTICO REGIONAL"
    },
    {
     "CATALOG_KEY": "404",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE GANGLIOS LINFÁTICOS CERVICALES"
    },
    {
     "CATALOG_KEY": "4040",
     "PRO_NOMBRE": "DISECCIÓN RADICAL DE CUELLO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4041",
     "PRO_NOMBRE": "DISECCIÓN RADICAL DE CUELLO, UNILATERAL"
    },
    {
     "CATALOG_KEY": "4042",
     "PRO_NOMBRE": "DISECCIÓN RADICAL DE CUELLO, BILATERAL"
    },
    {
     "CATALOG_KEY": "405",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE OTROS GANGLIOS LINFÁTICOS"
    },
    {
     "CATALOG_KEY": "4050",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE GANGLIOS LINFÁTICOS, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4051",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE GANGLIOS LINFÁTICOS AXILARES"
    },
    {
     "CATALOG_KEY": "4052",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE GANGLIOS LINFÁTICOS PERIAÓRTICOS"
    },
    {
     "CATALOG_KEY": "4053",
     "PRO_NOMBRE": "EXTIRPACIÓN DE GANGLIOS LINFÁTICOS ILIACOS"
    },
    {
     "CATALOG_KEY": "4054",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE GANGLIOS LINFÁTICOS DE LA INGLE"
    },
    {
     "CATALOG_KEY": "4059",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE OTROS GANGLIOS LINFÁTICOS"
    },
    {
     "CATALOG_KEY": "406",
     "PRO_NOMBRE": "OPERACIONES SOBRE CONDUCTO TORÁCICO"
    },
    {
     "CATALOG_KEY": "4061",
     "PRO_NOMBRE": "CANULACIÓN DE CONDUCTO TORÁCICO"
    },
    {
     "CATALOG_KEY": "4062",
     "PRO_NOMBRE": "FISTULIZACIÓN DE CONDUCTO TORÁCICO"
    },
    {
     "CATALOG_KEY": "4063",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE CONDUCTO TORÁCICO"
    },
    {
     "CATALOG_KEY": "4064",
     "PRO_NOMBRE": "LIGADURA DE CONDUCTO TORÁCICO"
    },
    {
     "CATALOG_KEY": "4069",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CONDUCTO TORÁCICO"
    },
    {
     "CATALOG_KEY": "409",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ESTRUCTURAS LINFÁTICAS"
    },
    {
     "CATALOG_KEY": "409X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ESTRUCTURAS LINFÁTICAS"
    },
    {
     "CATALOG_KEY": "41",
     "PRO_NOMBRE": "OPERACIONES SOBRE MÉDULA ÓSEA Y BAZO"
    },
    {
     "CATALOG_KEY": "410",
     "PRO_NOMBRE": "TRASPLANTE DE MÉDULA ÓSEA O DE CÉLULAS MADRE HEMATOPOYÉTICAS"
    },
    {
     "CATALOG_KEY": "4100",
     "PRO_NOMBRE": "TRASPLANTE DE MÉDULA ÓSEA, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4101",
     "PRO_NOMBRE": "TRASPLANTE AUTÓLOGO DE MÉDULA ÓSEA SIN PURGADO"
    },
    {
     "CATALOG_KEY": "4102",
     "PRO_NOMBRE": "TRASPLANTE ALOGÉNICO DE MÉDULA ÓSEA CON PURGADO"
    },
    {
     "CATALOG_KEY": "4103",
     "PRO_NOMBRE": "TRASPLANTE DE MÉDULA ÓSEA, SIN PURGADO"
    },
    {
     "CATALOG_KEY": "4104",
     "PRO_NOMBRE": "TRASPLANTE AUTÓLOGO DE CÉLULAS MADRE HEMATOPOYÉTICAS SIN PURGADO"
    },
    {
     "CATALOG_KEY": "4105",
     "PRO_NOMBRE": "TRASPLANTE ALOGÉNICO DE CÉLULAS MADRE HEMATOPOYÉTICAS SIN PURGADO"
    },
    {
     "CATALOG_KEY": "4106",
     "PRO_NOMBRE": "TRASPLANTE DE CÉLULAS PROGENITORAS DE SANGRE DEL CORDÓN UMBILICAL"
    },
    {
     "CATALOG_KEY": "4107",
     "PRO_NOMBRE": "TRASPLANTE AUTÓLOGO DE CÉLULAS MADRE HEMATOPOYÉTICAS CON PURGADO"
    },
    {
     "CATALOG_KEY": "4108",
     "PRO_NOMBRE": "TRASPLANTE ALOGÉNICO DE CÉLULAS MADRE HEMATOPOYÉTICAS CON PURGADO"
    },
    {
     "CATALOG_KEY": "4109",
     "PRO_NOMBRE": "TRASPLANTE AUTÓLOGO DE MÉDULA ÓSEA CON PURGADO"
    },
    {
     "CATALOG_KEY": "411",
     "PRO_NOMBRE": "PUNCIÓN DE BAZO"
    },
    {
     "CATALOG_KEY": "411X",
     "PRO_NOMBRE": "PUNCIÓN DE BAZO"
    },
    {
     "CATALOG_KEY": "412",
     "PRO_NOMBRE": "ESPLENOTOMÍA"
    },
    {
     "CATALOG_KEY": "412X",
     "PRO_NOMBRE": "ESPLENOTOMÍA"
    },
    {
     "CATALOG_KEY": "413",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MÉDULA ÓSEA Y BAZO"
    },
    {
     "CATALOG_KEY": "4131",
     "PRO_NOMBRE": "BIOPSIA DE MÉDULA ÓSEA"
    },
    {
     "CATALOG_KEY": "4132",
     "PRO_NOMBRE": "BIOPSIA CERRADA [PERCUTÁNEA] (POR ASPIRACIÓN) DE BAZO"
    },
    {
     "CATALOG_KEY": "4133",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE BAZO"
    },
    {
     "CATALOG_KEY": "4138",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MÉDULA ÓSEA"
    },
    {
     "CATALOG_KEY": "4139",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE BAZO"
    },
    {
     "CATALOG_KEY": "414",
     "PRO_NOMBRE": "EXTIRPACIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE BAZO"
    },
    {
     "CATALOG_KEY": "4141",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE QUISTE ESPLÉNICO"
    },
    {
     "CATALOG_KEY": "4142",
     "PRO_NOMBRE": "EXTIRPACIÓN DE LESIÓN O TEJIDO DE BAZO"
    },
    {
     "CATALOG_KEY": "4143",
     "PRO_NOMBRE": "ESPLENECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "415",
     "PRO_NOMBRE": "ESPLENECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "415X",
     "PRO_NOMBRE": "ESPLENECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "419",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE BAZO Y MÉDULA ÓSEA"
    },
    {
     "CATALOG_KEY": "4191",
     "PRO_NOMBRE": "ASPIRACIÓN DE MÉDULA ÓSEA DE DONANTE PARA TRASPLANTE"
    },
    {
     "CATALOG_KEY": "4192",
     "PRO_NOMBRE": "INYECCIÓN DE MÉDULA ÓSEA"
    },
    {
     "CATALOG_KEY": "4193",
     "PRO_NOMBRE": "EXTIRPACIÓN DE BAZO ACCESORIO"
    },
    {
     "CATALOG_KEY": "4194",
     "PRO_NOMBRE": "TRASPLANTE DE BAZO"
    },
    {
     "CATALOG_KEY": "4195",
     "PRO_NOMBRE": "OPERACIONES DE REPARACIÓN Y PLÁSTICAS SOBRE BAZO"
    },
    {
     "CATALOG_KEY": "4198",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE MÉDULA ÓSEA"
    },
    {
     "CATALOG_KEY": "4199",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE BAZO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL APARATO DIGESTIVO (42 - 54)"
    },
    {
     "CATALOG_KEY": "42",
     "PRO_NOMBRE": "OPERACIONES SOBRE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "420",
     "PRO_NOMBRE": "ESOFAGOTOMÍA"
    },
    {
     "CATALOG_KEY": "4201",
     "PRO_NOMBRE": "INCISIÓN DE MEMBRANA ESOFÁGICA"
    },
    {
     "CATALOG_KEY": "4209",
     "PRO_NOMBRE": "OTRA INCISIÓN DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "421",
     "PRO_NOMBRE": "ESOFAGOSTOMÍA"
    },
    {
     "CATALOG_KEY": "4210",
     "PRO_NOMBRE": "ESOFAGOSTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4211",
     "PRO_NOMBRE": "ESOFAGOSTOMÍA CERVICAL"
    },
    {
     "CATALOG_KEY": "4212",
     "PRO_NOMBRE": "EXTERIORIZACIÓN DE BOLSA ESOFÁGICA"
    },
    {
     "CATALOG_KEY": "4219",
     "PRO_NOMBRE": "OTRA FISTULIZACIÓN EXTERNA DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "422",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4221",
     "PRO_NOMBRE": "ESOFAGOSCOPÍA OPERATORIA POR INCISIÓN"
    },
    {
     "CATALOG_KEY": "4222",
     "PRO_NOMBRE": "ESOFAGOSCOPÍA A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "4223",
     "PRO_NOMBRE": "OTRA ESOFAGOSCOPÍA"
    },
    {
     "CATALOG_KEY": "4224",
     "PRO_NOMBRE": "BIOPSIA DE ESÓFAGO CERRADA [ENDOSCÓPICA]"
    },
    {
     "CATALOG_KEY": "4225",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4229",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "423",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) DE LESIÓN O TEJIDO DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4231",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL DE DIVERTÍCULO ESOFÁGICO"
    },
    {
     "CATALOG_KEY": "4232",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL DE OTRA LESIÓN O TEJIDO DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4233",
     "PRO_NOMBRE": "EXTIRPACIÓN O ELIMINACIÓN (DESTRUCCIÓN) ENDOSCÓPICA DE LESIÓN O DE TEJIDO DEL ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4239",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN DE LESIÓN O TEJIDO DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "424",
     "PRO_NOMBRE": "EXTIRPACIÓN DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4240",
     "PRO_NOMBRE": "ESOFAGUECTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4241",
     "PRO_NOMBRE": "ESOFAGUECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "4242",
     "PRO_NOMBRE": "ESOFAGUECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "425",
     "PRO_NOMBRE": "ANASTOMOSIS INTRATORÁCICA (O CERVICAL) DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4251",
     "PRO_NOMBRE": "ESOFAGOESOFAGOSTOMÍA INTRATORÁCICA (O CERVICAL)"
    },
    {
     "CATALOG_KEY": "4252",
     "PRO_NOMBRE": "ESOFAGOGASTROSTOMÍA INTRATORÁCICA (O CERVICAL)"
    },
    {
     "CATALOG_KEY": "4253",
     "PRO_NOMBRE": "ANASTOMOSÍS ESOFÁGICA INTRATORÁCICA (O CERVICAL) CON INTERPOSICIÓN DEL INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4254",
     "PRO_NOMBRE": "OTRA ESOFAGOENTEROSTOMÍA INTRATORÁCICA (O CERVICAL)"
    },
    {
     "CATALOG_KEY": "4255",
     "PRO_NOMBRE": "ANASTOMOSIS ESOFÁGICA INTRATORÁCICA (O CERVICAL) CON INTERPOSICIÓN DE COLON"
    },
    {
     "CATALOG_KEY": "4256",
     "PRO_NOMBRE": "OTRA ESOFAGOCOLOSTOMÍA INTRATORÁCICA (O CERVICAL)"
    },
    {
     "CATALOG_KEY": "4258",
     "PRO_NOMBRE": "ANASTOMOSIS ESOFÁGICA INTRATORÁCICA (O CERVICAL) CON OTRA INTERPOSICIÓN"
    },
    {
     "CATALOG_KEY": "4259",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS INTRATORÁCICA DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "426",
     "PRO_NOMBRE": "ANASTOMOSIS PRE O RETROESTERNAL DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4261",
     "PRO_NOMBRE": "ESOFAGOESOFAGOSTOMÍA PRE (O RETROESTERNAL)"
    },
    {
     "CATALOG_KEY": "4262",
     "PRO_NOMBRE": "ESOFAGOGASTROSTOMÍA PRE (O RETROESTERNAL)"
    },
    {
     "CATALOG_KEY": "4263",
     "PRO_NOMBRE": "ANASTOMOSIS ESOFÁGICA PRE (O RETROESTERNAL) CON INTERPOSICIÓN DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4264",
     "PRO_NOMBRE": "OTRA ESOFAGOENTEROSTOMÍA PRE (O RETROESTERNAL)"
    },
    {
     "CATALOG_KEY": "4265",
     "PRO_NOMBRE": "ANASTOMOSIS ESOFÁGICA PRE (O RETROESTERNAL) CON INTERPOSICIÓN DE COLON"
    },
    {
     "CATALOG_KEY": "4266",
     "PRO_NOMBRE": "OTRA ESOFAGOCOLOSTOMÍA PRE (O RETROESTERNAL)"
    },
    {
     "CATALOG_KEY": "4268",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS ESOFÁGICA PRE (O RETROESTERNAL) CON INTERPOSICIÓN"
    },
    {
     "CATALOG_KEY": "4269",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS PRE (O RETROESTERNAL) DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "427",
     "PRO_NOMBRE": "ESOFAGOMIOTOMÍA"
    },
    {
     "CATALOG_KEY": "427X",
     "PRO_NOMBRE": "ESOFAGOMIOTOMÍA"
    },
    {
     "CATALOG_KEY": "428",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4281",
     "PRO_NOMBRE": "INSERCIÓN DE TUBO O PRÓTESIS PERMANENTE EN ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4282",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN, DESGARRO O HERIDA DEL ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4283",
     "PRO_NOMBRE": "CIERRE DE ESOFAGOSTOMÍA"
    },
    {
     "CATALOG_KEY": "4284",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA ESOFÁGICA, NO ESPECIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "4285",
     "PRO_NOMBRE": "REPARACIÓN DE ESTENOSIS ESOFÁGICA"
    },
    {
     "CATALOG_KEY": "4286",
     "PRO_NOMBRE": "CREACIÓN DE TÚNEL SUBCUTÁNEO SIN ANASTOMOSIS ESOFÁGICA"
    },
    {
     "CATALOG_KEY": "4287",
     "PRO_NOMBRE": "OTRO INJERTO DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4289",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "429",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4291",
     "PRO_NOMBRE": "LIGADURA DE VARICES ESOFÁGICAS"
    },
    {
     "CATALOG_KEY": "4292",
     "PRO_NOMBRE": "DILATACIÓN DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "4299",
     "PRO_NOMBRE": "OTRA OPERACIÓN DE ESÓFAGO"
    },
    {
     "CATALOG_KEY": "43",
     "PRO_NOMBRE": "INCISIÓN Y EXTIRPACIÓN DEL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "430",
     "PRO_NOMBRE": "GASTROTOMÍA"
    },
    {
     "CATALOG_KEY": "430X",
     "PRO_NOMBRE": "GASTROTOMÍA"
    },
    {
     "CATALOG_KEY": "431",
     "PRO_NOMBRE": "GASTROSTOMÍA"
    },
    {
     "CATALOG_KEY": "4311",
     "PRO_NOMBRE": "GASTROSTOMÍA PERCUTÁNEA [ENDOSCÓPICA] (PEG)"
    },
    {
     "CATALOG_KEY": "4319",
     "PRO_NOMBRE": "OTRA GASTROSTOMÍA"
    },
    {
     "CATALOG_KEY": "433",
     "PRO_NOMBRE": "PILOROMIOTOMÍA"
    },
    {
     "CATALOG_KEY": "433X",
     "PRO_NOMBRE": "PILOROMIOTOMÍA"
    },
    {
     "CATALOG_KEY": "434",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) ENDOSCÓPICA DE LESIÓN O DE TEJIDO DE ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4341",
     "PRO_NOMBRE": "EXTIRPACIÓN O ELIMINACIÓN (DESTRUCCIÓN) ENDOSCÓPICA DE LESIÓN O DE TEJIDO DEL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4342",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL DE OTRA LESIÓN O DE TEJIDO DEL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4349",
     "PRO_NOMBRE": "OTRA ELIMINACIÓN (DESTRUCCIÓN) DE LESIÓN O TEJIDO DE ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "435",
     "PRO_NOMBRE": "GASTRECTOMÍA PARCIAL CON ANASTOMOSIS AL ESÓFAGO"
    },
    {
     "CATALOG_KEY": "435X",
     "PRO_NOMBRE": "GASTRECTOMÍA PARCIAL CON ANASTOMOSIS AL ESÓFAGO"
    },
    {
     "CATALOG_KEY": "436",
     "PRO_NOMBRE": "GASTRECTOMÍA PARCIAL CON ANASTOMOSIS AL DUODENO"
    },
    {
     "CATALOG_KEY": "436X",
     "PRO_NOMBRE": "GASTRECTOMÍA PARCIAL CON ANASTOMOSIS AL DUODENO"
    },
    {
     "CATALOG_KEY": "437",
     "PRO_NOMBRE": "GASTRECTOMÍA PARCIAL CON ANASTOMOSIS AL YEYUNO"
    },
    {
     "CATALOG_KEY": "437X",
     "PRO_NOMBRE": "GASTRECTOMÍA PARCIAL CON ANASTOMOSIS AL YEYUNO"
    },
    {
     "CATALOG_KEY": "438",
     "PRO_NOMBRE": "OTRA GASTRECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "4381",
     "PRO_NOMBRE": "GASTRECTOMÍA PARCIAL CON TRANSPOSICIÓN YEYUNAL"
    },
    {
     "CATALOG_KEY": "4389",
     "PRO_NOMBRE": "OTRA GASTRECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "439",
     "PRO_NOMBRE": "GASTRECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "4391",
     "PRO_NOMBRE": "GASTRECTOMÍA TOTAL CON INTERPOSICIÓN INTESTINAL"
    },
    {
     "CATALOG_KEY": "4399",
     "PRO_NOMBRE": "OTRA GASTRECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "44",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "440",
     "PRO_NOMBRE": "VAGOTOMÍA"
    },
    {
     "CATALOG_KEY": "4400",
     "PRO_NOMBRE": "VAGOTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4401",
     "PRO_NOMBRE": "VAGOTOMÍA TRONCULAR"
    },
    {
     "CATALOG_KEY": "4402",
     "PRO_NOMBRE": "VAGOTOMÍA SUPRASELECTIVA"
    },
    {
     "CATALOG_KEY": "4403",
     "PRO_NOMBRE": "OTRA VAGOTOMÍA SELECTIVA"
    },
    {
     "CATALOG_KEY": "441",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4411",
     "PRO_NOMBRE": "GASTROSCOPIA TRANSABDOMINAL"
    },
    {
     "CATALOG_KEY": "4412",
     "PRO_NOMBRE": "GASTROSCOPIA A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "4413",
     "PRO_NOMBRE": "OTRA GASTROSCOPIA"
    },
    {
     "CATALOG_KEY": "4414",
     "PRO_NOMBRE": "BIOPSIA CERRADA DE ESTÓMAGO (ENDOSCÓPICA)"
    },
    {
     "CATALOG_KEY": "4415",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DEL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4419",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "442",
     "PRO_NOMBRE": "PÍLOROPLASTIA"
    },
    {
     "CATALOG_KEY": "4421",
     "PRO_NOMBRE": "DILATACIÓN DE PILORO MEDIANTE INCISIÓN"
    },
    {
     "CATALOG_KEY": "4422",
     "PRO_NOMBRE": "DILATACIÓN ENDOSCÓPICA DE PILORO"
    },
    {
     "CATALOG_KEY": "4429",
     "PRO_NOMBRE": "OTRA PILOROPLASTIA"
    },
    {
     "CATALOG_KEY": "443",
     "PRO_NOMBRE": "GASTROENTEROSTOMÍA SIN GASTRECTOMÍA"
    },
    {
     "CATALOG_KEY": "4431",
     "PRO_NOMBRE": "DESVIACIÓN GÁSTRICA PROXIMAL"
    },
    {
     "CATALOG_KEY": "4432",
     "PRO_NOMBRE": "GASTROYEYUNOSTOMÍA PERCUTÁNEA [ENDOSCÓPICA]"
    },
    {
     "CATALOG_KEY": "4438",
     "PRO_NOMBRE": "GASTROENTEROSTOMÍA LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "4439",
     "PRO_NOMBRE": "OTRA GASTROENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "444",
     "PRO_NOMBRE": "CONTROL DE HEMORRAGIA Y SUTURA DE ÚLCERA GÁSTRICA O DUODENAL"
    },
    {
     "CATALOG_KEY": "4440",
     "PRO_NOMBRE": "SUTURA DE ÚLCERA PÉPTICA NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4441",
     "PRO_NOMBRE": "SUTURA DE ÚLCERA GÁSTRICA"
    },
    {
     "CATALOG_KEY": "4442",
     "PRO_NOMBRE": "SUTURA DE ÚLCERA DUODENAL"
    },
    {
     "CATALOG_KEY": "4443",
     "PRO_NOMBRE": "CONTROL ENDOSCÓPICO DE HEMORAGIA GÁSTRICA O DUODENAL"
    },
    {
     "CATALOG_KEY": "4444",
     "PRO_NOMBRE": "EMBOLIZACIÓN POR CATÉTER DE UNA HEMORRAGIA GÁSTRICA O DUODENAL"
    },
    {
     "CATALOG_KEY": "4449",
     "PRO_NOMBRE": "OTRO CONTROL DE HEMORAGIA DE ESTÓMAGO O DUODENO"
    },
    {
     "CATALOG_KEY": "445",
     "PRO_NOMBRE": "REVISIÓN DE ANASTOMOSIS GÁSTRICA"
    },
    {
     "CATALOG_KEY": "445X",
     "PRO_NOMBRE": "REVISIÓN DE ANASTOMOSIS GÁSTRICA"
    },
    {
     "CATALOG_KEY": "446",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4461",
     "PRO_NOMBRE": "SUTURA DE DESGARRO, LESIÓN O HERIDA DEL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4462",
     "PRO_NOMBRE": "CIERRE DE GASTROSTOMÍA"
    },
    {
     "CATALOG_KEY": "4463",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA GÁSTRICA"
    },
    {
     "CATALOG_KEY": "4464",
     "PRO_NOMBRE": "GASTROPEXIA"
    },
    {
     "CATALOG_KEY": "4465",
     "PRO_NOMBRE": "ESOFAGOGASTROPLASTIA"
    },
    {
     "CATALOG_KEY": "4466",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS PARA LA CREACIÓN DE COMPETENCIA ESFINTERIANA ESOFAGOGÁSTRICA"
    },
    {
     "CATALOG_KEY": "4467",
     "PRO_NOMBRE": "PROCEDIMIENTOS LAPAROSCÓPICOS PARA CREACIÓN DE COMPETENCIA DEL ESFÍNTER GASTROESOFÁGICO"
    },
    {
     "CATALOG_KEY": "4468",
     "PRO_NOMBRE": "GASTROPLASTIA LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "4469",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "449",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4491",
     "PRO_NOMBRE": "LIGADURA DE VÁRICES GÁSTRICAS"
    },
    {
     "CATALOG_KEY": "4492",
     "PRO_NOMBRE": "MANIPULACIÓN INTRAOPERATORIA DEL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "4493",
     "PRO_NOMBRE": "INSERCIÓN DE BURBUJA GÁSTRICA (BALÓN)"
    },
    {
     "CATALOG_KEY": "4494",
     "PRO_NOMBRE": "EXTRACCIÓN DE BURBUJA GÁSTRICA (BALÓN)"
    },
    {
     "CATALOG_KEY": "4495",
     "PRO_NOMBRE": "PROCEDIMIENTO LAPAROSCÓPICO GÁSTRICO RESTRICTIVO"
    },
    {
     "CATALOG_KEY": "4496",
     "PRO_NOMBRE": "REVISIÓN LAPAROSCÓPICA DE PROCEDIMIENTO GÁSTRICO RESTRICTIVO"
    },
    {
     "CATALOG_KEY": "4497",
     "PRO_NOMBRE": "RETIRO LAPAROSCÓPICO DE DISPOSITIVO GÁSTRICO RESTRICTIVO"
    },
    {
     "CATALOG_KEY": "4498",
     "PRO_NOMBRE": "AJUSTE (LAPAROSCÓPICO) DE TAMAÑO DE DISPOSITIVO GÁSTRICO RESTRICTIVO AJUSTABLE"
    },
    {
     "CATALOG_KEY": "4499",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE EL ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "45",
     "PRO_NOMBRE": "INCISIÓN, EXTIRPACIÓN Y ANASTOMOSIS DE INTESTINO"
    },
    {
     "CATALOG_KEY": "450",
     "PRO_NOMBRE": "ENTEROTOMÍA"
    },
    {
     "CATALOG_KEY": "4500",
     "PRO_NOMBRE": "INCISIÓN DE INTESTINO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4501",
     "PRO_NOMBRE": "INCISIÓN DE DUODENO"
    },
    {
     "CATALOG_KEY": "4502",
     "PRO_NOMBRE": "OTRA INCISIÓN DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4503",
     "PRO_NOMBRE": "INCISIÓN DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "451",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4511",
     "PRO_NOMBRE": "ENDOSCOPÍA TRANSABDOMINAL DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4512",
     "PRO_NOMBRE": "ENDOSCOPÍA DE INTESTINO DELGADO A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "4513",
     "PRO_NOMBRE": "OTRA ENDOSCOPÍA DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4514",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4515",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4516",
     "PRO_NOMBRE": "ESOFAGOGASTRODUODENOSCOPÍA [EGD] CON BIOPSIA CERRADA"
    },
    {
     "CATALOG_KEY": "4519",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "452",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4521",
     "PRO_NOMBRE": "ENDOSCOPÍA TRANSABDOMINAL DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4522",
     "PRO_NOMBRE": "ENDOSCOPÍA DE INTESTINO GRUESO A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "4523",
     "PRO_NOMBRE": "COLONOSCOPÍA"
    },
    {
     "CATALOG_KEY": "4524",
     "PRO_NOMBRE": "SIGMOIDOSCOPÍA FLEXIBLE"
    },
    {
     "CATALOG_KEY": "4525",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] DEL INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4526",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4527",
     "PRO_NOMBRE": "BIOPSIA INTESTINAL, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "4528",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4529",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE INTESTINO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "453",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) DE LESIÓN O TEJIDO DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4530",
     "PRO_NOMBRE": "EXTIRPACIÓN O ELIMINACIÓN (DESTRUCCIÓN) ENDÓSCOPICA DE LESIÓN DE DUODENO"
    },
    {
     "CATALOG_KEY": "4531",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN LOCAL DE LESIÓN DE DUODENO"
    },
    {
     "CATALOG_KEY": "4532",
     "PRO_NOMBRE": "OTRAS DESTRUCCIONES O EXTIRPACIONES DE LESIÓN DE DUODENO"
    },
    {
     "CATALOG_KEY": "4533",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL DE LESIÓN O TEJIDO DE INTESTINO DELGADO, EXCEPTO DUODENO"
    },
    {
     "CATALOG_KEY": "4534",
     "PRO_NOMBRE": "OTRA ELIMINACIÓN (DESTRUCCIÓN O EXTIRPACIÓN) DE LESIÓN DE INTESTINO DELGADO, EXCEPTO DUODENO"
    },
    {
     "CATALOG_KEY": "454",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) DE LESIÓN O TEJIDO DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4541",
     "PRO_NOMBRE": "EXTIRPACIÓN DE LESIÓN O TEJIDO DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4542",
     "PRO_NOMBRE": "POLIPECTOMÍA ENDOSCÓPICA DEL INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4543",
     "PRO_NOMBRE": "DESTRUCCIÒN ENDOSCÓPICA DE OTRA LESIÓN DEL INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4549",
     "PRO_NOMBRE": "OTRA ELIMINACIÓN DE LESIÓN DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "455",
     "PRO_NOMBRE": "AISLAMIENTO DE SEGMENTO INTESTINAL"
    },
    {
     "CATALOG_KEY": "4550",
     "PRO_NOMBRE": "AISLAMIENTO DE SEGMENTO INTESTINAL, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4551",
     "PRO_NOMBRE": "AISLAMIENTO DE SEGMENTO DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4552",
     "PRO_NOMBRE": "AISLAMIENTO DE SEGMENTO DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "456",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4561",
     "PRO_NOMBRE": "RESECCIÓN SEGMENTARIA MÚLTIPLE DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4562",
     "PRO_NOMBRE": "OTRA RESECCIÓN PARCIAL DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4563",
     "PRO_NOMBRE": "RESECCIÓN TOTAL DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "457",
     "PRO_NOMBRE": "EXTIRPACIÓN PARCIAL ABIERTA Y OTRA EXTIRPACIÓN PARCIAL DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4571",
     "PRO_NOMBRE": "RESECCIÓN SEGMENTARIA MÚLTIPLE ABIERTA Y OTRA RESECCIÓN SEGMENTARIA MÚLTIPLE DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4572",
     "PRO_NOMBRE": "CECECTOMÍA ABIERTA Y OTRA CECECTOMÍA"
    },
    {
     "CATALOG_KEY": "4573",
     "PRO_NOMBRE": "HEMICOLECTOMÍA DERECHA ABIERTA Y OTRA HEMICOLECTOMÍA DERECHA"
    },
    {
     "CATALOG_KEY": "4574",
     "PRO_NOMBRE": "RESECCIÓN ABIERTA DE COLON TRANSVERSO Y OTRA RESECCIÓN DE COLON TRANSVERSO"
    },
    {
     "CATALOG_KEY": "4575",
     "PRO_NOMBRE": "HEMICOLECTOMÍA IZQUIERDA ABIERTA Y OTRA HEMICOLECTOMÍA IZQUIERDA"
    },
    {
     "CATALOG_KEY": "4576",
     "PRO_NOMBRE": "SIGMOIDECTOMÍA ABIERTA Y OTRA SIGMOIDECTOMÍA"
    },
    {
     "CATALOG_KEY": "4579",
     "PRO_NOMBRE": "OTRA EXCISIÓN PARCIAL Y LA NO ESPECIFICADA DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "457A",
     "PRO_NOMBRE": "RESECCIÓN SEGMENTAL MÚLTIPLE DE INTESTINO GRUESO POR LAPAROSCOPÍA"
    },
    {
     "CATALOG_KEY": "457B",
     "PRO_NOMBRE": "CIEGOTOMÍA LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "457C",
     "PRO_NOMBRE": "HEMICOLECTOMÍA DERECHA POR LAPAROSCOPÍA"
    },
    {
     "CATALOG_KEY": "457D",
     "PRO_NOMBRE": "RESECCIÓN DE COLON TRANSVERSO POR LAPAROSCOPÍA"
    },
    {
     "CATALOG_KEY": "457E",
     "PRO_NOMBRE": "HEMICOLECTOMÍA IZQUIERDA POR LAPAROSCOPÍA"
    },
    {
     "CATALOG_KEY": "457F",
     "PRO_NOMBRE": "SIGMOIDECTOMÍA POR LAPAROSCOPÍA"
    },
    {
     "CATALOG_KEY": "457G",
     "PRO_NOMBRE": "OTRA EXCISIÓN PARCIAL DE INTESTINO GRUESO POR LAPAROSCOPÍA"
    },
    {
     "CATALOG_KEY": "458",
     "PRO_NOMBRE": "COLECTOMÍA INTRAABDOMINAL TOTAL"
    },
    {
     "CATALOG_KEY": "4581",
     "PRO_NOMBRE": "COLECTOMÍA INTRABDOMINAL TOTAL LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "4582",
     "PRO_NOMBRE": "COLECTOMÍA INTRABDOMINAL TOTAL ABIERTA"
    },
    {
     "CATALOG_KEY": "4583",
     "PRO_NOMBRE": "OTRA COLECTOMÍA INTRABDOMINAL TOTAL Y LA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "459",
     "PRO_NOMBRE": "ANASTOMOSIS INTESTINAL"
    },
    {
     "CATALOG_KEY": "4590",
     "PRO_NOMBRE": "ANASTOMOSIS INTESTINAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4591",
     "PRO_NOMBRE": "ANASTOMOSIS DE INTESTINO DELGADO A INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4592",
     "PRO_NOMBRE": "ANASTOMOSIS DE INTESTINO DELGADO AL MUÑÓN RECTAL"
    },
    {
     "CATALOG_KEY": "4593",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS DE INTESTINO DELGADO A INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4594",
     "PRO_NOMBRE": "ANASTOMOSIS DE INTESTINO GRUESO A INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4595",
     "PRO_NOMBRE": "ANASTOMOSIS AL ANO"
    },
    {
     "CATALOG_KEY": "46",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE INTESTINO"
    },
    {
     "CATALOG_KEY": "460",
     "PRO_NOMBRE": "EXTERIORIZACIÓN DE INTESTINO"
    },
    {
     "CATALOG_KEY": "4601",
     "PRO_NOMBRE": "EXTERIORIZACIÓN DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4602",
     "PRO_NOMBRE": "RESECCIÓN DE SEGMENTO ESTERIORIZADO DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4603",
     "PRO_NOMBRE": "EXTERIORIZACIÓN DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4604",
     "PRO_NOMBRE": "RESECCIÓN DE SEGMENTO EXTERIORIZADO DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "461",
     "PRO_NOMBRE": "COLOSTOMÍA"
    },
    {
     "CATALOG_KEY": "4610",
     "PRO_NOMBRE": "COLOSTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4611",
     "PRO_NOMBRE": "COLOSTOMÍA TEMPORAL"
    },
    {
     "CATALOG_KEY": "4613",
     "PRO_NOMBRE": "COLOSTOMÍA PERMANENTE"
    },
    {
     "CATALOG_KEY": "4614",
     "PRO_NOMBRE": "APERTURA RETARDADA DE COLOSTOMÍA"
    },
    {
     "CATALOG_KEY": "462",
     "PRO_NOMBRE": "LLEOSTOMÍA"
    },
    {
     "CATALOG_KEY": "4620",
     "PRO_NOMBRE": "ILEOSTOMÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4621",
     "PRO_NOMBRE": "ILEOSTOMÍA TEMPORAL"
    },
    {
     "CATALOG_KEY": "4622",
     "PRO_NOMBRE": "ILEOSTOMÍA CONTINENTE"
    },
    {
     "CATALOG_KEY": "4623",
     "PRO_NOMBRE": "OTRA ILEOSTOMÍA PERMANENTE"
    },
    {
     "CATALOG_KEY": "4624",
     "PRO_NOMBRE": "APERTURA RETARDADA DE ILEOSTOMÍA"
    },
    {
     "CATALOG_KEY": "463",
     "PRO_NOMBRE": "OTRA ENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "4631",
     "PRO_NOMBRE": "APERTURA RETARDADA DE OTRA ENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "4632",
     "PRO_NOMBRE": "YEYUNOSTOMÍA PERCUTÁNEA [ENDOSCÓPICA] (YPE)"
    },
    {
     "CATALOG_KEY": "4639",
     "PRO_NOMBRE": "OTRA ENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "464",
     "PRO_NOMBRE": "REVISIÓN DE ESTOMA INTESTINAL"
    },
    {
     "CATALOG_KEY": "4640",
     "PRO_NOMBRE": "REVISIÓN DE ESTOMA INTESTINAL, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4641",
     "PRO_NOMBRE": "REVISIÓN DE ESTOMA DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4642",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA PERICOLOSTOMIA"
    },
    {
     "CATALOG_KEY": "4643",
     "PRO_NOMBRE": "OTRA REVISIÓN DE ESTOMA DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "465",
     "PRO_NOMBRE": "CIERRE DE ESTOMA INTESTINAL"
    },
    {
     "CATALOG_KEY": "4650",
     "PRO_NOMBRE": "CIERRE DE ESTOMA INTESTINAL NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4651",
     "PRO_NOMBRE": "CIERRE DE ESTOMA DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4652",
     "PRO_NOMBRE": "CIERRE DE ESTOMA DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "466",
     "PRO_NOMBRE": "FIJACIÓN DE INTESTINO"
    },
    {
     "CATALOG_KEY": "4660",
     "PRO_NOMBRE": "FIJACIÓN DE INTESTINO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4661",
     "PRO_NOMBRE": "FIJACIÓN DE INTESTINO DELGADO A PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "4662",
     "PRO_NOMBRE": "OTRA FIJACIÓN DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4663",
     "PRO_NOMBRE": "FIJACIÓN DE INTESTINO GRUESO A PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "4664",
     "PRO_NOMBRE": "OTRA FIJACIÓN DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "467",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE INTESTINO"
    },
    {
     "CATALOG_KEY": "4671",
     "PRO_NOMBRE": "SUTURA DE HERIDA DE DUODENO"
    },
    {
     "CATALOG_KEY": "4672",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE DUODENO"
    },
    {
     "CATALOG_KEY": "4673",
     "PRO_NOMBRE": "SUTURA DE HERIDA DE INTESTINO DELGADO, EXCEPTO DUODENO"
    },
    {
     "CATALOG_KEY": "4674",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE INTESTINO DELGADO, EXCEPTO DUODENO"
    },
    {
     "CATALOG_KEY": "4675",
     "PRO_NOMBRE": "SUTURA DE HERIDA DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4676",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4679",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE INTESTINO"
    },
    {
     "CATALOG_KEY": "468",
     "PRO_NOMBRE": "DILATACIÓN Y MANIPULACIÓN DE INTESTINO"
    },
    {
     "CATALOG_KEY": "4680",
     "PRO_NOMBRE": "MANIPULACIÓN INTRAABDOMINAL DE INTESTINO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4681",
     "PRO_NOMBRE": "MANIPULACIÓN INTRAABDOMINAL DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4682",
     "PRO_NOMBRE": "MANIPULACIÓN INTRAABDOMINAL DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4685",
     "PRO_NOMBRE": "DILATACIÓN DEL INTESTINO"
    },
    {
     "CATALOG_KEY": "469",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE INTESTINOS"
    },
    {
     "CATALOG_KEY": "4691",
     "PRO_NOMBRE": "MIOTOMÍA DEL COLON SIGMOIDEO"
    },
    {
     "CATALOG_KEY": "4692",
     "PRO_NOMBRE": "MIOTOMÍA DE OTRAS PARTES DEL COLON"
    },
    {
     "CATALOG_KEY": "4693",
     "PRO_NOMBRE": "REVISIÓN DE ANASTOMOSIS DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4694",
     "PRO_NOMBRE": "REVISIÓN DE ANASTOMOSIS DE INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4695",
     "PRO_NOMBRE": "PERFUSIÓN LOCAL DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "4696",
     "PRO_NOMBRE": "PERFUSIÓN LOCAL DEL INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "4697",
     "PRO_NOMBRE": "TRASPLANTE DE INTESTINO"
    },
    {
     "CATALOG_KEY": "4699",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE INTESTINOS"
    },
    {
     "CATALOG_KEY": "47",
     "PRO_NOMBRE": "OPERACIÓN SOBRE APÉNDICE"
    },
    {
     "CATALOG_KEY": "470",
     "PRO_NOMBRE": "APENDICECTOMÍA"
    },
    {
     "CATALOG_KEY": "4701",
     "PRO_NOMBRE": "APENDICECTOMÍA LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "4709",
     "PRO_NOMBRE": "OTRA APENDICECTOMÍA"
    },
    {
     "CATALOG_KEY": "471",
     "PRO_NOMBRE": "APENDICECTOMÍA INCIDENTAL"
    },
    {
     "CATALOG_KEY": "4711",
     "PRO_NOMBRE": "APENDICECTOMÍA INCIDENTAL LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "4719",
     "PRO_NOMBRE": "OTRA APENDICECTOMÍA INCIDENTAL"
    },
    {
     "CATALOG_KEY": "472",
     "PRO_NOMBRE": "DRENAJE DE ABSCESO APENDICULAR"
    },
    {
     "CATALOG_KEY": "472X",
     "PRO_NOMBRE": "DRENAJE DE ABSCESO APENDICULAR"
    },
    {
     "CATALOG_KEY": "479",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE APÉNDICE"
    },
    {
     "CATALOG_KEY": "4791",
     "PRO_NOMBRE": "APENDICOSTOMÍA"
    },
    {
     "CATALOG_KEY": "4792",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE APÉNDICE"
    },
    {
     "CATALOG_KEY": "4799",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE APÉNDICE"
    },
    {
     "CATALOG_KEY": "48",
     "PRO_NOMBRE": "OPERACIONES SOBRE RECTO, RECTOSIGMOIDE Y TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "480",
     "PRO_NOMBRE": "PROCTOTOMÍA"
    },
    {
     "CATALOG_KEY": "480X",
     "PRO_NOMBRE": "PROCTOTOMÍA"
    },
    {
     "CATALOG_KEY": "481",
     "PRO_NOMBRE": "PROCTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "481X",
     "PRO_NOMBRE": "PROCTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "482",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE RECTO, RECTOSIGMOIDE Y TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "4821",
     "PRO_NOMBRE": "PROCTOSIGMOIDOSCOPIA TRANSABDOMINAL"
    },
    {
     "CATALOG_KEY": "4822",
     "PRO_NOMBRE": "PROCTOSIGMOIDOSCOPIA A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "4823",
     "PRO_NOMBRE": "PROCTOSIGMOIDOSCOPIA RÍGIDA"
    },
    {
     "CATALOG_KEY": "4824",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] DE RECTO"
    },
    {
     "CATALOG_KEY": "4825",
     "PRO_NOMBRE": "OTRA BIOPSIA DE RECTO"
    },
    {
     "CATALOG_KEY": "4826",
     "PRO_NOMBRE": "BIOPSIA DE TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "4829",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE RECTO, RECTOSIGMOIDE Y TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "483",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN DE LESIÓN O TEJIDO RECTAL"
    },
    {
     "CATALOG_KEY": "4831",
     "PRO_NOMBRE": "ELECTROCOAGULACIÓN RADICAL DE LESIÓN O TEJIDO RECTAL"
    },
    {
     "CATALOG_KEY": "4832",
     "PRO_NOMBRE": "OTRA ELECTROCOAGULACIÓN DE LESIÓN O TEJIDO RECTAL"
    },
    {
     "CATALOG_KEY": "4833",
     "PRO_NOMBRE": "ELIMINACIÓN DE LESIÓN O TEJIDO RECTAL POR LÁSER"
    },
    {
     "CATALOG_KEY": "4834",
     "PRO_NOMBRE": "ELIMINACIÓN DE LESIÓN O TEJIDO RECTAL POR CRIOCIRUGÍA"
    },
    {
     "CATALOG_KEY": "4835",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL DE LESIÓN O TEJIDO RECTAL"
    },
    {
     "CATALOG_KEY": "4836",
     "PRO_NOMBRE": "POLIPECTOMÍA ENDOSCÓPICA DEL RECTO"
    },
    {
     "CATALOG_KEY": "484",
     "PRO_NOMBRE": "RESECCIÓN DE RECTO CON RECONSTRUCCIÓN HACIENDO PASAR EL CEBO COLÓNICO PROXIMAL A TRAVÉS DE LA LUZ DEL DISTAL (PULL-THROUGH)"
    },
    {
     "CATALOG_KEY": "4840",
     "PRO_NOMBRE": "RESECCIÓN TIPO PULL-THROUGH DE RECT, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4841",
     "PRO_NOMBRE": "RESECCIÓN DE SUBMUCOSA DE RECTO DE SOAVE"
    },
    {
     "CATALOG_KEY": "4842",
     "PRO_NOMBRE": "RESECCIÓN TIPO PULL-THROUGH DE RECTO LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "4843",
     "PRO_NOMBRE": "RESECCIÓN TIPO PULL-THROUGH DE RECTO ABIERTA"
    },
    {
     "CATALOG_KEY": "4849",
     "PRO_NOMBRE": "OTRA RESECCIÓN DE RECTO CON RECONSTRUCCIÓN TIPO \"PULLTHROUGH\""
    },
    {
     "CATALOG_KEY": "485",
     "PRO_NOMBRE": "RESECCIÓN ABDOMINOPERINEAL DE RECTO"
    },
    {
     "CATALOG_KEY": "4850",
     "PRO_NOMBRE": "RESECCIÓN ABDOMINOPERINEAL DE RECTO NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "4851",
     "PRO_NOMBRE": "RESECCIÓN ABDOMINOPERINEAL DE RECTO LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "4852",
     "PRO_NOMBRE": "RESECCIÓN ABDOMINOPERINEAL DE RECTO ABIERTA"
    },
    {
     "CATALOG_KEY": "4859",
     "PRO_NOMBRE": "OTRA RESECCIÓN ABDOMINOPERINEAL DE RECTO"
    },
    {
     "CATALOG_KEY": "486",
     "PRO_NOMBRE": "OTRA RESECCIÓN DE RECTO"
    },
    {
     "CATALOG_KEY": "4861",
     "PRO_NOMBRE": "RECTOSIMOIDECTOMÍA TRANSACRA"
    },
    {
     "CATALOG_KEY": "4862",
     "PRO_NOMBRE": "RESECCIÓN ANTERIOR DE RECTO CON COLOSTOMÍA SIMULTÁNEA"
    },
    {
     "CATALOG_KEY": "4863",
     "PRO_NOMBRE": "OTRA RESECCIÓN ANTERIOR DE RECTO"
    },
    {
     "CATALOG_KEY": "4864",
     "PRO_NOMBRE": "RESECCIÓN POSTERIOR DE RECTO"
    },
    {
     "CATALOG_KEY": "4865",
     "PRO_NOMBRE": "RESECCIÓN DE RECTO DE DUHAMEL"
    },
    {
     "CATALOG_KEY": "4869",
     "PRO_NOMBRE": "OTRA RESECCIÓN DEL RECTO"
    },
    {
     "CATALOG_KEY": "487",
     "PRO_NOMBRE": "REPARACIÓN DE RECTO"
    },
    {
     "CATALOG_KEY": "4871",
     "PRO_NOMBRE": "SUTURA DE LESIÓN DE RECTO"
    },
    {
     "CATALOG_KEY": "4872",
     "PRO_NOMBRE": "CIERE DE PROCTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "4873",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA RECTAL"
    },
    {
     "CATALOG_KEY": "4874",
     "PRO_NOMBRE": "RECTORRECTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "4875",
     "PRO_NOMBRE": "PROCTOPEXIA ABDOMINAL"
    },
    {
     "CATALOG_KEY": "4876",
     "PRO_NOMBRE": "OTRA PROCTOPEXIA"
    },
    {
     "CATALOG_KEY": "4879",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE RECTO"
    },
    {
     "CATALOG_KEY": "488",
     "PRO_NOMBRE": "INCISIÓN O EXTIRPACIÓN DE TEJIDO O LESIÓN PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "4881",
     "PRO_NOMBRE": "INCISIÓN DE TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "4882",
     "PRO_NOMBRE": "EXCISIÓN DE TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "489",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE RECTO Y TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "4891",
     "PRO_NOMBRE": "INCISIÓN DE ESTENOSIS RECTAL"
    },
    {
     "CATALOG_KEY": "4892",
     "PRO_NOMBRE": "MIECTOMÍA ANORRECTAL"
    },
    {
     "CATALOG_KEY": "4893",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "4899",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE RECTO Y TEJIDO PERIRRECTAL"
    },
    {
     "CATALOG_KEY": "49",
     "PRO_NOMBRE": "OPERACIONES SOBRE ANO"
    },
    {
     "CATALOG_KEY": "490",
     "PRO_NOMBRE": "INCISIÓN O EXTIRPACIÓN DE TEJIDO PERIANAL"
    },
    {
     "CATALOG_KEY": "4901",
     "PRO_NOMBRE": "INCISIÓN DE ABSCESO PERIANAL"
    },
    {
     "CATALOG_KEY": "4902",
     "PRO_NOMBRE": "OTRA INCISIÓN DE TEJIDO PERIANAL"
    },
    {
     "CATALOG_KEY": "4903",
     "PRO_NOMBRE": "EXTIRPACIÓN DE COLGAJOS CUTÁNEOS PERIANALES"
    },
    {
     "CATALOG_KEY": "4904",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN DE TEJIDO PERIANAL"
    },
    {
     "CATALOG_KEY": "491",
     "PRO_NOMBRE": "INCISIÓN O EXTIRPACIÓN DE FÍSTULA ANAL"
    },
    {
     "CATALOG_KEY": "4911",
     "PRO_NOMBRE": "FISTULOTOMÍA ANAL"
    },
    {
     "CATALOG_KEY": "4912",
     "PRO_NOMBRE": "FISTULECTOMÍA ANAL"
    },
    {
     "CATALOG_KEY": "492",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ANO Y TEJIDO PERIANAL"
    },
    {
     "CATALOG_KEY": "4921",
     "PRO_NOMBRE": "ANOSCOPÍA"
    },
    {
     "CATALOG_KEY": "4922",
     "PRO_NOMBRE": "BIOPSIA DE TEJIDO PERIANAL"
    },
    {
     "CATALOG_KEY": "4923",
     "PRO_NOMBRE": "BIOPSIA DE ANO"
    },
    {
     "CATALOG_KEY": "4929",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ANO Y TEJIDO PERIANAL"
    },
    {
     "CATALOG_KEY": "493",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) DE OTRA LESIÓN O TEJIDO DEL ANO"
    },
    {
     "CATALOG_KEY": "4931",
     "PRO_NOMBRE": "EXTIRPACIÓN O ELIMINACIÓN (DESTRUCCIÓN) ENDOSCÓPICA DE LESIÓN O DE TEJIDO DEL ANO"
    },
    {
     "CATALOG_KEY": "4939",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) DE LESIÓN O DE TEJIDO DEL ANO"
    },
    {
     "CATALOG_KEY": "494",
     "PRO_NOMBRE": "PROCEDIMIENTOS SOBRE HEMORROIDES"
    },
    {
     "CATALOG_KEY": "4941",
     "PRO_NOMBRE": "REDUCCIÓN DE HEMORROIDES"
    },
    {
     "CATALOG_KEY": "4942",
     "PRO_NOMBRE": "INYECCIÓN (ESCLEROSIS) DE HEMORROIDES"
    },
    {
     "CATALOG_KEY": "4943",
     "PRO_NOMBRE": "CAUTERIZACIÓN DE HEMORROIDES"
    },
    {
     "CATALOG_KEY": "4944",
     "PRO_NOMBRE": "DESTRUCCIÓN DE HEMORROIDES POR CRIOTERAPIA"
    },
    {
     "CATALOG_KEY": "4945",
     "PRO_NOMBRE": "LIGAMENTO DE HEMORROIDES"
    },
    {
     "CATALOG_KEY": "4946",
     "PRO_NOMBRE": "EXTIRPACIÓN DE HEMORROIDES"
    },
    {
     "CATALOG_KEY": "4947",
     "PRO_NOMBRE": "EVACUACIÓN DE HEMORROIDES TROMBOSADAS"
    },
    {
     "CATALOG_KEY": "4949",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS SOBRE HEMORROIDES"
    },
    {
     "CATALOG_KEY": "495",
     "PRO_NOMBRE": "DIVISIÓN (SECCIÓN) DE ESFÍNTER ANAL"
    },
    {
     "CATALOG_KEY": "4951",
     "PRO_NOMBRE": "ESFINTEROTOMÍA ANAL LATERAL IZQUIERDA"
    },
    {
     "CATALOG_KEY": "4952",
     "PRO_NOMBRE": "ESFINTEROTOMÍA ANAL POSTERIOR"
    },
    {
     "CATALOG_KEY": "4959",
     "PRO_NOMBRE": "OTRA ESFINTEROTOMÍA ANAL"
    },
    {
     "CATALOG_KEY": "496",
     "PRO_NOMBRE": "EXCISIÓN (EXTIRPACIÓN) DE ANO"
    },
    {
     "CATALOG_KEY": "496X",
     "PRO_NOMBRE": "EXCISIÓN (EXTIRPACIÓN) DE ANO"
    },
    {
     "CATALOG_KEY": "497",
     "PRO_NOMBRE": "REPARACIÓN DE ANO"
    },
    {
     "CATALOG_KEY": "4971",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN O DESGARRO DE ANO"
    },
    {
     "CATALOG_KEY": "4972",
     "PRO_NOMBRE": "IMPLANTACIÓN DE UN ANILLO EN LA CIRCUNFERENCIA ANAL (CERCLAJE)"
    },
    {
     "CATALOG_KEY": "4973",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA ANAL"
    },
    {
     "CATALOG_KEY": "4974",
     "PRO_NOMBRE": "TRANSPOSICIÓN DEL MÚSCULO RECTO INTERNO PARA INCONTINENCIA ANAL"
    },
    {
     "CATALOG_KEY": "4975",
     "PRO_NOMBRE": "IMPLANTACIÓN O REVISIÓN DE ESFÍNTER ANAL ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "4976",
     "PRO_NOMBRE": "EXTRACCIÓN DE ESFÍNTER ANAL ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "4979",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ESFÍNTER ANAL"
    },
    {
     "CATALOG_KEY": "499",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ANO"
    },
    {
     "CATALOG_KEY": "4991",
     "PRO_NOMBRE": "INCISIÓN DE TABIQUE ANAL"
    },
    {
     "CATALOG_KEY": "4992",
     "PRO_NOMBRE": "INSERCIÓN O IMPLANTE DE ESTIMULADOR ANAL ELÉCTRICO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "4993",
     "PRO_NOMBRE": "OTRA INCISIÓN DE ANO"
    },
    {
     "CATALOG_KEY": "4994",
     "PRO_NOMBRE": "REDUCCIÓN DE PROLAPSO ANAL"
    },
    {
     "CATALOG_KEY": "4995",
     "PRO_NOMBRE": "CONTROL DE HEMORRAGIA (POSTOPERATORIA) DE ANO"
    },
    {
     "CATALOG_KEY": "4999",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE ANO"
    },
    {
     "CATALOG_KEY": "50",
     "PRO_NOMBRE": "OPERACIONES SOBRE HÍGADO"
    },
    {
     "CATALOG_KEY": "500",
     "PRO_NOMBRE": "HEPATOTOMÍA"
    },
    {
     "CATALOG_KEY": "500X",
     "PRO_NOMBRE": "HEPATOTOMÍA"
    },
    {
     "CATALOG_KEY": "501",
     "PRO_NOMBRE": "PROCEDIMIENTO DIAGNÓSTICO SOBRE HÍGADO"
    },
    {
     "CATALOG_KEY": "5011",
     "PRO_NOMBRE": "BIOPSIA CERRADA [CON AGUJA] (PERCUTÁNEA) DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5012",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5013",
     "PRO_NOMBRE": "BIOPSIA TRANSYUGULAR DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5014",
     "PRO_NOMBRE": "BIOPSIA DE HÍGADO POR LAPAROSCOPÍA"
    },
    {
     "CATALOG_KEY": "5019",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HÍGADO"
    },
    {
     "CATALOG_KEY": "502",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) DE TEJIDO O LESIÓN DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5021",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE LESIÓN DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5022",
     "PRO_NOMBRE": "HEPATECTOMÍA PARCIAL (SUBTOTAL)"
    },
    {
     "CATALOG_KEY": "5023",
     "PRO_NOMBRE": "ABLACIÓN ABIERTA DE LESIÓN O TEJIDO DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5024",
     "PRO_NOMBRE": "ABLACIÓN PERCUTÁNEA DE LESIÓN O TEJIDO DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5025",
     "PRO_NOMBRE": "ABLACIÓN LAPAROSCÓPICA DE LESIÓN O TEJIDO DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5026",
     "PRO_NOMBRE": "OTRA ABLACIÓN Y LA NO ESPECIFICADA DE LESIÓN O TEJIDO DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5029",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN DE LESIÓN DE HÍGADO"
    },
    {
     "CATALOG_KEY": "503",
     "PRO_NOMBRE": "LOBECTOMÍA HEPÁTICA"
    },
    {
     "CATALOG_KEY": "503X",
     "PRO_NOMBRE": "LOBECTOMÍA HEPÁTICA"
    },
    {
     "CATALOG_KEY": "504",
     "PRO_NOMBRE": "HEPATECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "504X",
     "PRO_NOMBRE": "HEPATECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "505",
     "PRO_NOMBRE": "TRASPLANTE DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5051",
     "PRO_NOMBRE": "TRASPLANTE AUXILIAR DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5059",
     "PRO_NOMBRE": "OTRA TRASPLANTE DE HÍGADO"
    },
    {
     "CATALOG_KEY": "506",
     "PRO_NOMBRE": "REPARACIÓN DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5061",
     "PRO_NOMBRE": "SUTURA DE LESIÓN HEPÁTICA"
    },
    {
     "CATALOG_KEY": "5069",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE HÍGADO"
    },
    {
     "CATALOG_KEY": "509",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE HÍGADO"
    },
    {
     "CATALOG_KEY": "5091",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5092",
     "PRO_NOMBRE": "ASISTENCIA HEPÁTICA EXTRACORPÓREA"
    },
    {
     "CATALOG_KEY": "5093",
     "PRO_NOMBRE": "PERFUSIÓN LOCALIZADA DE HÍGADO"
    },
    {
     "CATALOG_KEY": "5094",
     "PRO_NOMBRE": "OTRA INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN HÍGADO"
    },
    {
     "CATALOG_KEY": "5099",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE HÍGADO"
    },
    {
     "CATALOG_KEY": "51",
     "PRO_NOMBRE": "OPERACIONES SOBRE VESÍCULA BILIAR Y TRACTO BILIAR"
    },
    {
     "CATALOG_KEY": "510",
     "PRO_NOMBRE": "COLECISTOTOMÍA Y COLECISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5101",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE VESÍCULA BILIAR"
    },
    {
     "CATALOG_KEY": "5102",
     "PRO_NOMBRE": "COLECISTOSTOMÍA POR TRÓCAR"
    },
    {
     "CATALOG_KEY": "5103",
     "PRO_NOMBRE": "OTRA COLECISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5104",
     "PRO_NOMBRE": "OTRA COLECISTOTOMÍA"
    },
    {
     "CATALOG_KEY": "511",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA VÍA BILIAR PRINCIPAL"
    },
    {
     "CATALOG_KEY": "5110",
     "PRO_NOMBRE": "COLANGIOPANCREATOGRAFÍA RETRÓGRADA ENDOSCÓPICA [CPRE]"
    },
    {
     "CATALOG_KEY": "5111",
     "PRO_NOMBRE": "COLANGIOGRAFÍA RETRÓGRADA ENDOSCÓPICA (CRE)"
    },
    {
     "CATALOG_KEY": "5112",
     "PRO_NOMBRE": "BIOPSIA PERCUTÁNEA DE VESÍCULA BILIAR O VÍAS BILIARES"
    },
    {
     "CATALOG_KEY": "5113",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE VESÍCULA BILIAR O CONDUCTOS BILIARES"
    },
    {
     "CATALOG_KEY": "5114",
     "PRO_NOMBRE": "OTRA BIOPSIA CERRADA (ENDOSCÓPICA) DE VÍAS BILIARES O ENFÍNTER DE ODDI"
    },
    {
     "CATALOG_KEY": "5115",
     "PRO_NOMBRE": "MEDICIÓN DE LA PRESIÓN DEL ESFÍNTER DE ODDI"
    },
    {
     "CATALOG_KEY": "5119",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LOS CONDUCTOS BILIARES"
    },
    {
     "CATALOG_KEY": "512",
     "PRO_NOMBRE": "COLECISTECTOMÍA"
    },
    {
     "CATALOG_KEY": "5121",
     "PRO_NOMBRE": "OTRA COLECISTECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "5122",
     "PRO_NOMBRE": "COLECISTECTOMÍA"
    },
    {
     "CATALOG_KEY": "5123",
     "PRO_NOMBRE": "COLECISTECTOMÍA LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "5124",
     "PRO_NOMBRE": "COLECISTECTOMÍA PARCIAL LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "513",
     "PRO_NOMBRE": "ANASTOMOSIS DE VESÍCULA BILIAR O CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "5131",
     "PRO_NOMBRE": "ANASTOMOSIS DE VESÍCULA BILIAR A CONDUCTOS HEPÁTICOS"
    },
    {
     "CATALOG_KEY": "5132",
     "PRO_NOMBRE": "ANASTOMOSIS DE VESÍCULA BILIAR A INTESTINO"
    },
    {
     "CATALOG_KEY": "5133",
     "PRO_NOMBRE": "ANASTOMOSIS DE VESÍCULA BILIAR A PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5134",
     "PRO_NOMBRE": "ANASTOMOSIS DE VESÍCULA BILIAR A ESTÓMAGO"
    },
    {
     "CATALOG_KEY": "5135",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS DE VESÍCULA BILIAR"
    },
    {
     "CATALOG_KEY": "5136",
     "PRO_NOMBRE": "COLEDOCOENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5137",
     "PRO_NOMBRE": "ANASTOMOSIS DE CONDUCTO HEPÁTICO A TRACTO GASTROINTESTINAL"
    },
    {
     "CATALOG_KEY": "5139",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS DEL CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "514",
     "PRO_NOMBRE": "INCISIÓN DEL CONDUCTO BILIAR PARA ALIVIO DE OBSTRUCCIÓN"
    },
    {
     "CATALOG_KEY": "5141",
     "PRO_NOMBRE": "EXPLORACIÓN DEL CONDUCTO BILIAR PRINCIPAL PARA EXTRACCIÓN DE CÁLCULO"
    },
    {
     "CATALOG_KEY": "5142",
     "PRO_NOMBRE": "EXPLORACIÓN DEL CONDUCTO BILIAR PRINCIPAL PARA ALIVIO DE OTRA OBSTRUCCIÓN"
    },
    {
     "CATALOG_KEY": "5143",
     "PRO_NOMBRE": "INSERCCIÓN DE TUBO COLEDOCOHEPÁTICO PARA DESCOMPRESIÓN"
    },
    {
     "CATALOG_KEY": "5149",
     "PRO_NOMBRE": "INCISIÓN DE OTROS CONDUCTOS BILIARES PARA ALIVIO DE OBSTRUCCIÓN"
    },
    {
     "CATALOG_KEY": "515",
     "PRO_NOMBRE": "OTRA INCISIÓN DEL CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "5151",
     "PRO_NOMBRE": "EXPLORACIÓN DE COLÉDOCO"
    },
    {
     "CATALOG_KEY": "5159",
     "PRO_NOMBRE": "INCISIÓN DE OTRO CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "516",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN (DESTRUCCIÓN) DE LESIÓN O TEJIDO DE CONDUCTOS BILIARES Y ESFINTER DE ODDI"
    },
    {
     "CATALOG_KEY": "5161",
     "PRO_NOMBRE": "EXTIRPACIÓN DE CONDUCTO CÍSTICO REMANENTE (MUÑÓN CÍSTICO)"
    },
    {
     "CATALOG_KEY": "5162",
     "PRO_NOMBRE": "EXTIRPACIÓN DE AMPOLLA DE VATER (CON REIMPLANTACIÓN DE COLÉDOCO) (AMPULECTOMIA)"
    },
    {
     "CATALOG_KEY": "5163",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN DE COLÉDOCO"
    },
    {
     "CATALOG_KEY": "5164",
     "PRO_NOMBRE": "EXTIRPACIÓN O ELIMINACIÓN (DESTRUCCIÓN) ENDOSCÓPICA DE LESIÓN DE CONDUCTOS BILIARES O DE ESFÍNTER DE ODDI"
    },
    {
     "CATALOG_KEY": "5169",
     "PRO_NOMBRE": "EXTIRPACIÓN DE OTRO CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "517",
     "PRO_NOMBRE": "REPARACIÓN DE VÍAS BILIARES"
    },
    {
     "CATALOG_KEY": "5171",
     "PRO_NOMBRE": "SUTURA SIMPLE DE COLÉDOCO"
    },
    {
     "CATALOG_KEY": "5172",
     "PRO_NOMBRE": "COLEDOCOPLASTÍA"
    },
    {
     "CATALOG_KEY": "5179",
     "PRO_NOMBRE": "REPARACIÓN DE OTROS CONDUCTOS BILIARES"
    },
    {
     "CATALOG_KEY": "518",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CONDUCTOS BILIARES Y ESFÍNTER DE ODDI"
    },
    {
     "CATALOG_KEY": "5181",
     "PRO_NOMBRE": "DILATACIÓN DE ESFÍNTER DE ODDI"
    },
    {
     "CATALOG_KEY": "5182",
     "PRO_NOMBRE": "ESFINTEROTOMÍA PANCREÁTICA (Y BILIAR)"
    },
    {
     "CATALOG_KEY": "5183",
     "PRO_NOMBRE": "ESFINTEROPLASTÍA PANCREÁTICA ( Y BILIAR)"
    },
    {
     "CATALOG_KEY": "5184",
     "PRO_NOMBRE": "DILATACIÓN ENDOSCÓPICA DE AMPOLLA Y CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "5185",
     "PRO_NOMBRE": "ESFINTEROTOMÍA Y PAPILOTOMÍA ENDOSCÓPICAS"
    },
    {
     "CATALOG_KEY": "5186",
     "PRO_NOMBRE": "INSERCIÓN ENDOSCÓPICA DE TUBO DE DRENAJE NASOBILIAR"
    },
    {
     "CATALOG_KEY": "5187",
     "PRO_NOMBRE": "INSERCIÓN ENDOSCÓPICA DE TUBO TUTOR (STENT) EN CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "5188",
     "PRO_NOMBRE": "EXTRACCIÓN ENDOSCÓPICA DE CÁLCULO(S) DEL TRACTO BILIAR"
    },
    {
     "CATALOG_KEY": "5189",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ESFÍNTER DE ODDI"
    },
    {
     "CATALOG_KEY": "519",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL TRACTO BILIAR"
    },
    {
     "CATALOG_KEY": "5191",
     "PRO_NOMBRE": "REPARACIÓN DE LESIÓN DE VESÍCULA BILIAR"
    },
    {
     "CATALOG_KEY": "5192",
     "PRO_NOMBRE": "CIERRE DE COLECISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5193",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA BILIAR"
    },
    {
     "CATALOG_KEY": "5194",
     "PRO_NOMBRE": "REVISIÓN DE ANASTOMOSIS DE LAS VÍAS BILIARES"
    },
    {
     "CATALOG_KEY": "5195",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVO PROTÉSICO DE VÍA BILIAR"
    },
    {
     "CATALOG_KEY": "5196",
     "PRO_NOMBRE": "EXTRACCIÓN PERCUTÁNEA DE CÁLCULO EN COLÉDOCO"
    },
    {
     "CATALOG_KEY": "5198",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS PERCUTÁNEOS SOBRE TRACTO BILIAR"
    },
    {
     "CATALOG_KEY": "5199",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE EL TRACTO BILIAR"
    },
    {
     "CATALOG_KEY": "52",
     "PRO_NOMBRE": "OPERACIONES SOBRE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "520",
     "PRO_NOMBRE": "PANCREATOTOMÍA"
    },
    {
     "CATALOG_KEY": "5201",
     "PRO_NOMBRE": "DRENAJE DE QUISTE, PSEUDOQUISTE Y ABSCESO PANCREÁTICO POR CATÉTER"
    },
    {
     "CATALOG_KEY": "5209",
     "PRO_NOMBRE": "OTRA PANCREATOTOMÍA"
    },
    {
     "CATALOG_KEY": "521",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5211",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [POR ASPIRACIÓN] DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5212",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5213",
     "PRO_NOMBRE": "PANCREATOGRAFÍA RETRÓGRADA ENDOSCÓPICA [PRE]"
    },
    {
     "CATALOG_KEY": "5214",
     "PRO_NOMBRE": "BIOPSIA CERRADA [ENDOSCÓPICA] DEL CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5219",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "522",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL O ELIMINACIÓN DE PÁNCREAS Y CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5221",
     "PRO_NOMBRE": "EXTIRPACIÓN O ELIMINACIÓN (DESTRUCCIÓN) ENDOSCÓPICA DE LESIÓN O DE TEJIDO DEL CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5222",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN O ELIMINACIÓN (DESTRUCCIÓN) DE LESIÓN O DE TEJIDO DEL PÁNCREAS O DEL CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "523",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE QUISTE PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "523X",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE QUISTE PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "524",
     "PRO_NOMBRE": "DRENAJE INTERNO DE QUISTE Y PSEUDOQUISTE PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "524X",
     "PRO_NOMBRE": "DRENAJE INTERNO DE QUISTE Y PSEUDOQUISTE PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "525",
     "PRO_NOMBRE": "PANCREATECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "5251",
     "PRO_NOMBRE": "PANCREATECTOMÍA PROXIMAL"
    },
    {
     "CATALOG_KEY": "5252",
     "PRO_NOMBRE": "PANCREATECTOMÍA DISTAL"
    },
    {
     "CATALOG_KEY": "5253",
     "PRO_NOMBRE": "PANCREATECTOMÍA SUBTOTAL RADICAL"
    },
    {
     "CATALOG_KEY": "5259",
     "PRO_NOMBRE": "OTRA PANCREATECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "526",
     "PRO_NOMBRE": "PANCREATECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "526X",
     "PRO_NOMBRE": "PANCREATECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "527",
     "PRO_NOMBRE": "PANCREATICODUODENECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "527X",
     "PRO_NOMBRE": "PANCREATICODUODENECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "528",
     "PRO_NOMBRE": "TRASPLANTE DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5280",
     "PRO_NOMBRE": "TRASPLANTE DE PÁNCREAS, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "5281",
     "PRO_NOMBRE": "REIMPLANTE DE TEJIDO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5282",
     "PRO_NOMBRE": "HOMOTRASPLANTE DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5283",
     "PRO_NOMBRE": "HETEROTRASPLANTE DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5284",
     "PRO_NOMBRE": "AUTOTRASPLANTE DE CÉLULAS DE LOS ISLOTES DE LANGERHANS"
    },
    {
     "CATALOG_KEY": "5285",
     "PRO_NOMBRE": "ALOTRASPLANTE DE CÉLULAS DE LOS ISLOTES DE LANGERHANS"
    },
    {
     "CATALOG_KEY": "5286",
     "PRO_NOMBRE": "TRASPLANTE DE CÉLULAS DE LOS ISLOTES DE LANGERHANS, NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "529",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5292",
     "PRO_NOMBRE": "CANULACIÓN DEL CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5293",
     "PRO_NOMBRE": "INSERCIÓN DE TUBO TUTOR (STENT) EN EL CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5294",
     "PRO_NOMBRE": "EXTRACCIÓN ENDOSCÓPICA DE CÁLCULOS DEL CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5295",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5296",
     "PRO_NOMBRE": "ANASTOMOSIS DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "5297",
     "PRO_NOMBRE": "INSERCIÓN ENDOSCÓPICA DE TUBO DE DRENAJE NASOPANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5298",
     "PRO_NOMBRE": "DILATACIÓN ENDOSCÓPICA DEL CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "5299",
     "PRO_NOMBRE": "OTRA OPERACIÓN DE PÁNCREAS"
    },
    {
     "CATALOG_KEY": "53",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA"
    },
    {
     "CATALOG_KEY": "530",
     "PRO_NOMBRE": "REPARACIÓN UNILATERAL DE HERNIA INGUINAL"
    },
    {
     "CATALOG_KEY": "5300",
     "PRO_NOMBRE": "REPARACIÓN UNILATERAL DE HERNIA INGUINAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "5301",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN DE HERNIA INGUINAL DIRECTA"
    },
    {
     "CATALOG_KEY": "5302",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN DE HERNIA INGUINAL INDIRECTA"
    },
    {
     "CATALOG_KEY": "5303",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN DE HERNIA INGUINAL DIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5304",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN DE HERNIA INGUINAL INDIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5305",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA INGUINAL CON INJERTO O PRÓTESIS NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "530A",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA DE HERNIA INGUINAL DIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "530B",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA DE HERNIA INGUINAL INDIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "530C",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA DE HERNIA INGUINAL CON INJERTO O PRÓTESIS, SIN OTRA ESPECIFICACIÓN"
    },
    {
     "CATALOG_KEY": "531",
     "PRO_NOMBRE": "REPARACIÓN BILATERAL DE HERNIA INGUINAL"
    },
    {
     "CATALOG_KEY": "5310",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN BILATERAL DE HERNIA INGUINAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "5311",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN BILATERAL DE HERNIA INGUINAL DIRECTA"
    },
    {
     "CATALOG_KEY": "5312",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN BILATERAL DE HERNIA INGUINAL INDIRECTA"
    },
    {
     "CATALOG_KEY": "5313",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN BILATERAL DE HERNIA INGUINAL, UNA DIRECTA Y OTRA INDIRECTA"
    },
    {
     "CATALOG_KEY": "5314",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN BILATERAL DE HERNIA INGUINAL DIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5315",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN BILATERAL DE HERNIA INGUINAL INDIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5316",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN BILATERAL DE HERNIA INGUINAL, UNA DIRECTA Y UNA INDIRECTA, CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5317",
     "PRO_NOMBRE": "REPARACIÓN BILATERAL DE HERNIA INGUINAL CON INJERTO O PRÓTESIS, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "531A",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA BILATERAL DE HERNIA INGUINAL DIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "531B",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA BILATERAL DE HERNIA INGUINAL INDIRECTA CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "531C",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA BILATERAL DE HERNIA INGUINAL, UNA DIRECTA Y UNA INDIRECTA, CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "531D",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA BILATERAL DE HERNIA INGUINAL CON INJERTO O PRÓTESIS, SIN OTRA ESPECIFICACIÓN"
    },
    {
     "CATALOG_KEY": "532",
     "PRO_NOMBRE": "REPARACIÓN UNILATERAL DE HERNIA CRURAL (FEMORAL)"
    },
    {
     "CATALOG_KEY": "5321",
     "PRO_NOMBRE": "REPARACIÓN UNILATERAL DE HERNIA CRURAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5329",
     "PRO_NOMBRE": "OTRA HERNIORRAFIA DE HERNIA CRURAL UNILATERAL"
    },
    {
     "CATALOG_KEY": "533",
     "PRO_NOMBRE": "REPARACIÓN BILATERAL DE HERNIA CRURAL"
    },
    {
     "CATALOG_KEY": "5331",
     "PRO_NOMBRE": "REPARACIÓN BILATERAL DE HERNIA CRURAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5339",
     "PRO_NOMBRE": "OTRA HERNIORRAFIA CRURAL BILATERAL"
    },
    {
     "CATALOG_KEY": "534",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA UMBILICAL"
    },
    {
     "CATALOG_KEY": "5341",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN DE HERNIA UMBILICAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5342",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA DE HERNIA UMBILICAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5343",
     "PRO_NOMBRE": "OTRA REPARACIÓN LAPAROSCÓPICA DE HERNIA UMBILICAL"
    },
    {
     "CATALOG_KEY": "5349",
     "PRO_NOMBRE": "OTRA HERNIORRAFIA UMBILICAL ABIERTA"
    },
    {
     "CATALOG_KEY": "535",
     "PRO_NOMBRE": "REPARACIÓN DE OTRA HERNIA DE LA PARED ABDOMINAL ANTERIOR (SIN INJERTO NI PRÓTESIS)"
    },
    {
     "CATALOG_KEY": "5351",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA INCISIONAL (EVENTRACIÓN)"
    },
    {
     "CATALOG_KEY": "5359",
     "PRO_NOMBRE": "REPARACIÓN DE OTRA HERNIA DE LA PARED ABDOMINAL ANTERIOR"
    },
    {
     "CATALOG_KEY": "536",
     "PRO_NOMBRE": "REPARACIÓN DE OTRA HERNIA DE LA PARED ABDOMINAL ANTERIOR CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5361",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE HERNIA INCISIONAL (EVENTRACIÓN) CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5362",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA DE HERNIA INCISIONAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5363",
     "PRO_NOMBRE": "OTRA REPARACIÓN LAPAROSCÓPICA DE OTRA HERNIA DE PARED ABDOMINAL ANTERIOR CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "5369",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN DE OTRA HERNIA DE LA PARED ABDOMINAL ANTERIOR CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "537",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA DIAFRAGMÁTICA, ABORDAJE ABDOMINAL"
    },
    {
     "CATALOG_KEY": "5371",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA DE HERNIA DIAFRAGMÁTICA, ABORDAJE ABDOMINAL"
    },
    {
     "CATALOG_KEY": "5372",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPACIÓN DE HERNIA DIAFRAGMÁTICA, ABORDAJE ABDOMINAL"
    },
    {
     "CATALOG_KEY": "5375",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA DIAFRAGMÁTICA, ABORDAJE ABDOMINAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "538",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA DIAFRAGMÁTICA, ACCESO TORÁCICO"
    },
    {
     "CATALOG_KEY": "5380",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA DIAFRAGMÁTICA CON ACCESO TORÁCICO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "5381",
     "PRO_NOMBRE": "PLICATURA DE DIAFRAGMA"
    },
    {
     "CATALOG_KEY": "5382",
     "PRO_NOMBRE": "REPARACIÓN DE HERNIA PARASTERNAL (MORGAGNI)"
    },
    {
     "CATALOG_KEY": "5383",
     "PRO_NOMBRE": "REPARACIÓN LAPAROSCÓPICA DE HERNIA DIAFRAGMÁTICA, CON ABORDAJE TORÁCICO"
    },
    {
     "CATALOG_KEY": "5384",
     "PRO_NOMBRE": "REPARACIÓN ABIERTA Y OTRA REPARACIÓN DE HERNIA DIAFRAGMÁTICA, ABORDAJE TORÁCICO"
    },
    {
     "CATALOG_KEY": "539",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE HERNIA"
    },
    {
     "CATALOG_KEY": "539X",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE HERNIA"
    },
    {
     "CATALOG_KEY": "54",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA REGIÓN ABDOMINAL"
    },
    {
     "CATALOG_KEY": "540",
     "PRO_NOMBRE": "INCISIÓN DE PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "540X",
     "PRO_NOMBRE": "INCISIÓN DE PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "541",
     "PRO_NOMBRE": "LAPAROTOMÍA"
    },
    {
     "CATALOG_KEY": "5411",
     "PRO_NOMBRE": "LAPAROTOMÍA EXPLORADORA"
    },
    {
     "CATALOG_KEY": "5412",
     "PRO_NOMBRE": "REAPERTURA DE LAPARATOMÍA RECIENTE"
    },
    {
     "CATALOG_KEY": "5419",
     "PRO_NOMBRE": "OTRA LAPAROTOMÍA"
    },
    {
     "CATALOG_KEY": "542",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS DE LA REGIÓN ABDOMINAL"
    },
    {
     "CATALOG_KEY": "5421",
     "PRO_NOMBRE": "LAPAROSCOPIA"
    },
    {
     "CATALOG_KEY": "5422",
     "PRO_NOMBRE": "BIOPSIA DE PARED ABDOMINAL U OMBLIGO"
    },
    {
     "CATALOG_KEY": "5423",
     "PRO_NOMBRE": "BIOPSIA DE PERITONEO"
    },
    {
     "CATALOG_KEY": "5424",
     "PRO_NOMBRE": "BIOPSIA CERRADA [CON AGUJA] [PERCUTÁNEA] DE MASA INTRAABDOMINAL"
    },
    {
     "CATALOG_KEY": "5425",
     "PRO_NOMBRE": "LAVADO PERITONEAL"
    },
    {
     "CATALOG_KEY": "5429",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE REGIÓN ABDOMINAL"
    },
    {
     "CATALOG_KEY": "543",
     "PRO_NOMBRE": "EXCISIÓN O ELIMINACIÓN DE LESIÓN O TEJIDO DE PARED ABDOMINAL U OMBLIGO"
    },
    {
     "CATALOG_KEY": "543X",
     "PRO_NOMBRE": "EXCISIÓN O ELIMINACIÓN DE LESIÓN O TEJIDO DE PARED ABDOMINAL U OMBLIGO"
    },
    {
     "CATALOG_KEY": "544",
     "PRO_NOMBRE": "EXCISIÓN O ELIMINACIÓN DE TEJIDO PERITONEAL"
    },
    {
     "CATALOG_KEY": "544X",
     "PRO_NOMBRE": "EXCISIÓN O ELIMINACIÓN DE TEJIDO PERITONEAL"
    },
    {
     "CATALOG_KEY": "545",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS PERITONEALES"
    },
    {
     "CATALOG_KEY": "5451",
     "PRO_NOMBRE": "LISIS LAPAROSCÓPICA DE ADHERENCIAS PERITONEALES"
    },
    {
     "CATALOG_KEY": "5459",
     "PRO_NOMBRE": "OTRAS LISIS DE ADHERENCIAS PERITONEALES"
    },
    {
     "CATALOG_KEY": "546",
     "PRO_NOMBRE": "SUTURA DE PARED ABDOMINAL Y PERITONEO"
    },
    {
     "CATALOG_KEY": "5461",
     "PRO_NOMBRE": "NUEVO CIERRE DE DISRUPCIÓN POSTOPERATORIA DE PARED ABDOMINAL (EVISCERACIÓN)"
    },
    {
     "CATALOG_KEY": "5462",
     "PRO_NOMBRE": "CIERRE RETARDADO DE HERIDA ABDOMINAL EN FASE DE GRANULACIÓN"
    },
    {
     "CATALOG_KEY": "5463",
     "PRO_NOMBRE": "OTRA SUTURA DE PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "5464",
     "PRO_NOMBRE": "SUTURA DE PERITONEO"
    },
    {
     "CATALOG_KEY": "547",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE PARED ABDOMINAL Y PERITONEO"
    },
    {
     "CATALOG_KEY": "5471",
     "PRO_NOMBRE": "REPARACIÓN DE GASTROSQUISIS"
    },
    {
     "CATALOG_KEY": "5472",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "5473",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE PERITONEO"
    },
    {
     "CATALOG_KEY": "5474",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE EPIPLÓN"
    },
    {
     "CATALOG_KEY": "5475",
     "PRO_NOMBRE": "OTRA REPARACION DE MESENTERIO"
    },
    {
     "CATALOG_KEY": "549",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE LA REGIÓN ABDOMINAL"
    },
    {
     "CATALOG_KEY": "5491",
     "PRO_NOMBRE": "DRENAJE ABDOMINAL PERCUTÁNEO"
    },
    {
     "CATALOG_KEY": "5492",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO DE LA CAVIDAD PERITONEAL"
    },
    {
     "CATALOG_KEY": "5493",
     "PRO_NOMBRE": "CREACIÓN DE FÍSTULA CUTÁNEOPERITONEAL"
    },
    {
     "CATALOG_KEY": "5494",
     "PRO_NOMBRE": "CREACIÓN DE DERIVACIÓN PERITONEO-VASCULAR (SHUNT)"
    },
    {
     "CATALOG_KEY": "5495",
     "PRO_NOMBRE": "INCISIÓN DE PERITONEO"
    },
    {
     "CATALOG_KEY": "5496",
     "PRO_NOMBRE": "INYECCIÓN DE AIRE EN CAVIDAD PERITONEAL"
    },
    {
     "CATALOG_KEY": "5497",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA DE ACCIÓN LOCAL EN CAVIDAD PERITONEAL"
    },
    {
     "CATALOG_KEY": "5498",
     "PRO_NOMBRE": "DIÁLISIS PERITONEAL"
    },
    {
     "CATALOG_KEY": "5499",
     "PRO_NOMBRE": "OTRA OPERACIÓN DE LA REGIÓN ABDOMINAL"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL APARATO URINARIO (55 - 59)"
    },
    {
     "CATALOG_KEY": "55",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL RIÑÓN"
    },
    {
     "CATALOG_KEY": "550",
     "PRO_NOMBRE": "NEFROTOMÍA Y NEFROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5501",
     "PRO_NOMBRE": "NEFROTOMÍA"
    },
    {
     "CATALOG_KEY": "5502",
     "PRO_NOMBRE": "NEFROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5503",
     "PRO_NOMBRE": "NEFROSTOMÍA PERCUTÁNEA SIN FRAGMENTACIÓN"
    },
    {
     "CATALOG_KEY": "5504",
     "PRO_NOMBRE": "NEFROSTOMÍA PERCUTÁNEA CON FRAGMENTACIÓN"
    },
    {
     "CATALOG_KEY": "551",
     "PRO_NOMBRE": "PIELOTOMÍA Y PIELOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5511",
     "PRO_NOMBRE": "PIELOTOMÍA"
    },
    {
     "CATALOG_KEY": "5512",
     "PRO_NOMBRE": "PIELOSTOMÍA"
    },
    {
     "CATALOG_KEY": "552",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5521",
     "PRO_NOMBRE": "NEFROSCOPIA"
    },
    {
     "CATALOG_KEY": "5522",
     "PRO_NOMBRE": "PIELOSCOPIA"
    },
    {
     "CATALOG_KEY": "5523",
     "PRO_NOMBRE": "BIOPSIA CERRADA [AGUJA] [PERCUTÁNEA] DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5524",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5529",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL RIÑÓN"
    },
    {
     "CATALOG_KEY": "553",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN RENAL"
    },
    {
     "CATALOG_KEY": "5531",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE LESIÓN RENAL"
    },
    {
     "CATALOG_KEY": "5532",
     "PRO_NOMBRE": "ABLACIÓN ABIERTA DE LESIÓN O TEJIDO RENAL"
    },
    {
     "CATALOG_KEY": "5533",
     "PRO_NOMBRE": "ABLACIÓN PERCUTÁNEA DE LESIÓN O TEJIDO RENAL"
    },
    {
     "CATALOG_KEY": "5534",
     "PRO_NOMBRE": "ABLACIÓN LAPAROSCÓPICA DE LESIÓN O TEJIDO RENAL"
    },
    {
     "CATALOG_KEY": "5535",
     "PRO_NOMBRE": "OTRA ABLACIÓN Y LA NO ESPECIFICADA DE LESIÓN O TEJIDO RENAL"
    },
    {
     "CATALOG_KEY": "5539",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN LOCAL O EXCISIÓN DE LESIÓN O TEJIDO RENAL"
    },
    {
     "CATALOG_KEY": "554",
     "PRO_NOMBRE": "NEFRECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "554X",
     "PRO_NOMBRE": "NEFRECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "555",
     "PRO_NOMBRE": "NEFRECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "5551",
     "PRO_NOMBRE": "NEFROURETERECTOMÍA"
    },
    {
     "CATALOG_KEY": "5552",
     "PRO_NOMBRE": "NEFRECTOMÍA DE RIÑÓN RESTANTE"
    },
    {
     "CATALOG_KEY": "5553",
     "PRO_NOMBRE": "EXTRACCIÓN DE RIÑÓN TRASPLANTADO O RECHAZADO"
    },
    {
     "CATALOG_KEY": "5554",
     "PRO_NOMBRE": "NEFRECTOMÍA BILATERAL"
    },
    {
     "CATALOG_KEY": "556",
     "PRO_NOMBRE": "TRASPLANTE DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5561",
     "PRO_NOMBRE": "AUTOTRASPLANTE RENAL"
    },
    {
     "CATALOG_KEY": "5569",
     "PRO_NOMBRE": "OTRO TRASPLANTE DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "557",
     "PRO_NOMBRE": "NEFROPEXIA"
    },
    {
     "CATALOG_KEY": "557X",
     "PRO_NOMBRE": "NEFROPEXIA"
    },
    {
     "CATALOG_KEY": "558",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5581",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5582",
     "PRO_NOMBRE": "CIERRE DE NEFROSTOMÍA Y PIELOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5583",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5584",
     "PRO_NOMBRE": "REDUCCIÓN DE TORSIÓN DE PEDÍCULO RENAL"
    },
    {
     "CATALOG_KEY": "5585",
     "PRO_NOMBRE": "SINFISIOTOMÍA PARA RIÑÓN (ANULAR) EN HERRADURA"
    },
    {
     "CATALOG_KEY": "5586",
     "PRO_NOMBRE": "ANASTOMOSIS DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5587",
     "PRO_NOMBRE": "CORRECCIÓN DE UNIÓN URETEROPÉLVICA"
    },
    {
     "CATALOG_KEY": "5589",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "559",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL RIÑÓN"
    },
    {
     "CATALOG_KEY": "5591",
     "PRO_NOMBRE": "DECAPSULACIÓN DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5592",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE RIÑÓN (PELVIS)"
    },
    {
     "CATALOG_KEY": "5593",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO DE NEFROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5594",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO DE PIELOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5595",
     "PRO_NOMBRE": "PERFUSIÓN LOCAL DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "5596",
     "PRO_NOMBRE": "OTRA INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN RIÑÓN"
    },
    {
     "CATALOG_KEY": "5597",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE RIÑÓN MECÁNICO"
    },
    {
     "CATALOG_KEY": "5598",
     "PRO_NOMBRE": "EXTRACCIÓN DE RIÑÓN MECÁNICO"
    },
    {
     "CATALOG_KEY": "5599",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL RIÑÓN"
    },
    {
     "CATALOG_KEY": "56",
     "PRO_NOMBRE": "OPERACIONES SOBRE URÉTER"
    },
    {
     "CATALOG_KEY": "560X",
     "PRO_NOMBRE": "EXTRACCIÓN TRANSURETRAL DE OBSTÁCULO DE URÉTER Y PELVIS RENAL"
    },
    {
     "CATALOG_KEY": "561",
     "PRO_NOMBRE": "MEATOTOMÍA URETERAL"
    },
    {
     "CATALOG_KEY": "561X",
     "PRO_NOMBRE": "MEATOTOMÍA URETERAL"
    },
    {
     "CATALOG_KEY": "562",
     "PRO_NOMBRE": "URETEROTOMÍA"
    },
    {
     "CATALOG_KEY": "562X",
     "PRO_NOMBRE": "URETEROTOMÍA"
    },
    {
     "CATALOG_KEY": "563",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE DIAGNÓSTICO EN URÉTER"
    },
    {
     "CATALOG_KEY": "5631",
     "PRO_NOMBRE": "URETEROSCOPIA"
    },
    {
     "CATALOG_KEY": "5632",
     "PRO_NOMBRE": "BIOPSIA PERCUTÁNEA CERRADA DE URÉTER"
    },
    {
     "CATALOG_KEY": "5633",
     "PRO_NOMBRE": "BIOPSIA ENDOSCÓPICA CERRADA DE URÉTER"
    },
    {
     "CATALOG_KEY": "5634",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE URÉTER"
    },
    {
     "CATALOG_KEY": "5635",
     "PRO_NOMBRE": "ENDOSCOPÍA (CISTOSCOPÍA) DEL CONDUCTO ILEAL"
    },
    {
     "CATALOG_KEY": "5639",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DE DIAGNÓSTICO EN URÉTER"
    },
    {
     "CATALOG_KEY": "564",
     "PRO_NOMBRE": "URETERECTOMÍA"
    },
    {
     "CATALOG_KEY": "5640",
     "PRO_NOMBRE": "URETERECTOMÍA NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "5641",
     "PRO_NOMBRE": "URETERECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "5642",
     "PRO_NOMBRE": "URETERECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "565",
     "PRO_NOMBRE": "URETEROILEOSTOMÍA CUTÁNEA"
    },
    {
     "CATALOG_KEY": "5651",
     "PRO_NOMBRE": "FORMACIÓN DE URETEROILEOSTOMÍA CUTÁNEA"
    },
    {
     "CATALOG_KEY": "5652",
     "PRO_NOMBRE": "REVISIÓN DE URETEROILEOSTOMÍA CUTÁNEA"
    },
    {
     "CATALOG_KEY": "566",
     "PRO_NOMBRE": "OTRA DERIVACIÓN URINARIA EXTERNA"
    },
    {
     "CATALOG_KEY": "5661",
     "PRO_NOMBRE": "FORMACIÓN DE OTRA URETEROSTOMÍA CUTÁNEA"
    },
    {
     "CATALOG_KEY": "5662",
     "PRO_NOMBRE": "REVISIÓN DE OTRA URETEROSTOMÍA CUTÁNEA"
    },
    {
     "CATALOG_KEY": "567",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS O DESVIACIÓN DE URÉTER"
    },
    {
     "CATALOG_KEY": "5671",
     "PRO_NOMBRE": "DERIVACIÓN URINARIA A INTESTINO"
    },
    {
     "CATALOG_KEY": "5672",
     "PRO_NOMBRE": "REVISIÓN DE ANASTOMOSIS URETEROINTESTINAL"
    },
    {
     "CATALOG_KEY": "5673",
     "PRO_NOMBRE": "NEFROCISTOANASTOMOSIS NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "5674",
     "PRO_NOMBRE": "URETERONEOCISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5675",
     "PRO_NOMBRE": "TRANSURETEROURETEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5679",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS O DESVIACIÓN DE URÉTER"
    },
    {
     "CATALOG_KEY": "568",
     "PRO_NOMBRE": "REPARACIÓN DE URÉTER"
    },
    {
     "CATALOG_KEY": "5681",
     "PRO_NOMBRE": "LIBERACIÓN DE ADHERENCIAS INTRALUMINALES DE URÉTER"
    },
    {
     "CATALOG_KEY": "5682",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE URÉTER"
    },
    {
     "CATALOG_KEY": "5683",
     "PRO_NOMBRE": "CIERRE DE URETEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5684",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA DE URÉTER"
    },
    {
     "CATALOG_KEY": "5685",
     "PRO_NOMBRE": "URETEROPEXIA"
    },
    {
     "CATALOG_KEY": "5686",
     "PRO_NOMBRE": "EXTRACCIÓN DE LIGADURA DE URÉTER"
    },
    {
     "CATALOG_KEY": "5689",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE URÉTER"
    },
    {
     "CATALOG_KEY": "569",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE URÉTER"
    },
    {
     "CATALOG_KEY": "5691",
     "PRO_NOMBRE": "DILATACIÓN DEL MEATO URETERAL"
    },
    {
     "CATALOG_KEY": "5692",
     "PRO_NOMBRE": "IMPLANTACIÓN DE ESTIMULADOR URETERAL ELECTRÓNICO"
    },
    {
     "CATALOG_KEY": "5693",
     "PRO_NOMBRE": "SUSTITUCIÓN DE ESTIMULADOR URETERAL ELECTRÓNICO"
    },
    {
     "CATALOG_KEY": "5694",
     "PRO_NOMBRE": "EXTRACCIÓN DE ESTIMULADOR URETERAL ELECTRÓNICO"
    },
    {
     "CATALOG_KEY": "5695",
     "PRO_NOMBRE": "LIGADURA DE URÉTER"
    },
    {
     "CATALOG_KEY": "5699",
     "PRO_NOMBRE": "OTRA OPERACIONES SOBRE URÉTER"
    },
    {
     "CATALOG_KEY": "57",
     "PRO_NOMBRE": "OPERACIONES SOBRE VEJIGA URINARIA"
    },
    {
     "CATALOG_KEY": "570",
     "PRO_NOMBRE": "DRENAJE TRANSURETRAL VESICAL"
    },
    {
     "CATALOG_KEY": "570X",
     "PRO_NOMBRE": "DRENAJE TRANSURETRAL VESICAL"
    },
    {
     "CATALOG_KEY": "571",
     "PRO_NOMBRE": "CISTOTOMÍA Y CISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5711",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5712",
     "PRO_NOMBRE": "LIBERACIÓN DE ADHERENCIAS INTRALUMINALES CON INCISIÓN DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5717",
     "PRO_NOMBRE": "CISTOSTOMÍA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "5718",
     "PRO_NOMBRE": "OTRA CISTOSTOMÍA SUPRAPÚBICA"
    },
    {
     "CATALOG_KEY": "5719",
     "PRO_NOMBRE": "OTRA CISTOTOMÍA"
    },
    {
     "CATALOG_KEY": "572",
     "PRO_NOMBRE": "VESICOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5721",
     "PRO_NOMBRE": "VESICOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5722",
     "PRO_NOMBRE": "REVISIÓN O SUTURA DE VESICOSTOMÍA"
    },
    {
     "CATALOG_KEY": "573",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS EN VEJIGA"
    },
    {
     "CATALOG_KEY": "5731",
     "PRO_NOMBRE": "CISTOSCOPÍA A TRAVÉS DE ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "5732",
     "PRO_NOMBRE": "OTRA CISTOSCOPIA"
    },
    {
     "CATALOG_KEY": "5733",
     "PRO_NOMBRE": "BIOPSIA CERRADA [TRANSURETRAL] DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5734",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5739",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE VEJIGA"
    },
    {
     "CATALOG_KEY": "574",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN TRANSURETRAL DE TEJIDO DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5741",
     "PRO_NOMBRE": "LIBERACIÓN TRANSURETRAL DE ADHERENCIAS INTRALUMINALES"
    },
    {
     "CATALOG_KEY": "5749",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN TRANSURETRAL DE LESIÓN O TEJIDO VESICAL"
    },
    {
     "CATALOG_KEY": "575",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE TEJIDO DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5751",
     "PRO_NOMBRE": "EXCISIÓN DE URACO"
    },
    {
     "CATALOG_KEY": "5759",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN ABIERTA DE OTRA LESIÓN O TEJIDO DE VEJIGA"
    },
    {
     "CATALOG_KEY": "576",
     "PRO_NOMBRE": "CISTECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "576X",
     "PRO_NOMBRE": "CISTECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "577",
     "PRO_NOMBRE": "CISTECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "5771",
     "PRO_NOMBRE": "CISTECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "5779",
     "PRO_NOMBRE": "OTRA CISTECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "578",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE VEJIGA URINARIA"
    },
    {
     "CATALOG_KEY": "5781",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5782",
     "PRO_NOMBRE": "CIERRE DE CISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5783",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA CON AFECTACIÓN DE VEJIGA E INTESTINO"
    },
    {
     "CATALOG_KEY": "5784",
     "PRO_NOMBRE": "REPARACIÓN DE OTRA FÍSTULA DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5785",
     "PRO_NOMBRE": "CISTOURETROPLASTIA Y REPARACIÓN PLÁSTICA DE CUELLO DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5786",
     "PRO_NOMBRE": "REPARACIÓN DE EXTROFIA VESICAL"
    },
    {
     "CATALOG_KEY": "5787",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE VEJIGA URINARIA"
    },
    {
     "CATALOG_KEY": "5788",
     "PRO_NOMBRE": "OTRA ANASTOMOSIS DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5789",
     "PRO_NOMBRE": "OTRA REPARACIÒN DE VEJIGA"
    },
    {
     "CATALOG_KEY": "579",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VEJIGA"
    },
    {
     "CATALOG_KEY": "5791",
     "PRO_NOMBRE": "ESFINTEROTOMÍA DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5792",
     "PRO_NOMBRE": "DILATACIÓN DE CUELLO DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5793",
     "PRO_NOMBRE": "CONTROL DE HEMORRAGIA (POSTOPERATORIA) DE VEJIGA"
    },
    {
     "CATALOG_KEY": "5794",
     "PRO_NOMBRE": "INSERCIÓN DE CATÉTER URINARIO PERMANENTE"
    },
    {
     "CATALOG_KEY": "5795",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CATÉTER URINARIO PERMANENTE"
    },
    {
     "CATALOG_KEY": "5796",
     "PRO_NOMBRE": "IMPLANTACIÓN DE ESTIMULADOR DE VEJIGA ELECTRÓNICO"
    },
    {
     "CATALOG_KEY": "5797",
     "PRO_NOMBRE": "SUSTITUCIÓN DE ESTIMULADOR DE VEJIGA ELECTRÓNICO"
    },
    {
     "CATALOG_KEY": "5798",
     "PRO_NOMBRE": "EXTRACCIÓN DE ESTIMULADOR DE VEJIGA ELECTRÓNICO"
    },
    {
     "CATALOG_KEY": "5799",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VEJIGA"
    },
    {
     "CATALOG_KEY": "58",
     "PRO_NOMBRE": "OPERACIONES SOBRE URETRA"
    },
    {
     "CATALOG_KEY": "580",
     "PRO_NOMBRE": "URETROTOMÍA"
    },
    {
     "CATALOG_KEY": "580X",
     "PRO_NOMBRE": "URETROTOMÍA"
    },
    {
     "CATALOG_KEY": "581",
     "PRO_NOMBRE": "MEATOTOMÍA URETRAL"
    },
    {
     "CATALOG_KEY": "581X",
     "PRO_NOMBRE": "MEATOTOMÍA URETRAL"
    },
    {
     "CATALOG_KEY": "582",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS EN URETRA"
    },
    {
     "CATALOG_KEY": "5821",
     "PRO_NOMBRE": "URETROSCOPIA PERINEAL"
    },
    {
     "CATALOG_KEY": "5822",
     "PRO_NOMBRE": "OTRA URETROSCOPIA"
    },
    {
     "CATALOG_KEY": "5823",
     "PRO_NOMBRE": "BIOPSIA DE URETRA"
    },
    {
     "CATALOG_KEY": "5824",
     "PRO_NOMBRE": "BIOPSIA DE TEJIDO PERIURETRAL"
    },
    {
     "CATALOG_KEY": "5829",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE URETRA Y TEJIDO PERIURETRAL"
    },
    {
     "CATALOG_KEY": "583",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE TEJIDO O DE LESIÓN URETRAL"
    },
    {
     "CATALOG_KEY": "5831",
     "PRO_NOMBRE": "EXCISIÓN ENDOSCÓPICA O DESTRUCCIÓN DE LESIÓN O DE TEJIDO DE LA URETRA"
    },
    {
     "CATALOG_KEY": "5839",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O DE TEJIDO DE LA URETRA"
    },
    {
     "CATALOG_KEY": "584",
     "PRO_NOMBRE": "REPARACIÓN DE URETRA"
    },
    {
     "CATALOG_KEY": "5841",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE URETRA"
    },
    {
     "CATALOG_KEY": "5842",
     "PRO_NOMBRE": "CIERRE DE URETROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5843",
     "PRO_NOMBRE": "CIERRE DE OTRA FÍSTULA DE URETRA"
    },
    {
     "CATALOG_KEY": "5844",
     "PRO_NOMBRE": "REANASTOMOSIS DE URETRA"
    },
    {
     "CATALOG_KEY": "5845",
     "PRO_NOMBRE": "REPARACIÓN DE HIPOSPADIAS O EPISPADIAS"
    },
    {
     "CATALOG_KEY": "5846",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE URETRA"
    },
    {
     "CATALOG_KEY": "5847",
     "PRO_NOMBRE": "MEATOPLASTIA URETRAL"
    },
    {
     "CATALOG_KEY": "5849",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE URETRA"
    },
    {
     "CATALOG_KEY": "585",
     "PRO_NOMBRE": "LIBERACIÓN DE ESTENOSIS URETRAL"
    },
    {
     "CATALOG_KEY": "585X",
     "PRO_NOMBRE": "LIBERACIÓN DE ESTENOSIS URETRAL"
    },
    {
     "CATALOG_KEY": "586",
     "PRO_NOMBRE": "DILATACIÓN DE URETRA"
    },
    {
     "CATALOG_KEY": "586X",
     "PRO_NOMBRE": "DILATACIÓN DE URETRA"
    },
    {
     "CATALOG_KEY": "589",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE URETRA Y TEJIDO PERIURETRAL"
    },
    {
     "CATALOG_KEY": "5891",
     "PRO_NOMBRE": "INCISIÓN DE TEJIDO PERIURETRAL"
    },
    {
     "CATALOG_KEY": "5892",
     "PRO_NOMBRE": "EXCISIÓN DE TEJIDO PERIURETRAL"
    },
    {
     "CATALOG_KEY": "5893",
     "PRO_NOMBRE": "IMPLANTACIÓN DE ESFÍNTER URINARIO ARTIFICIAL [EUA]"
    },
    {
     "CATALOG_KEY": "5899",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE URETRA Y TEJIDO PERIURETRAL"
    },
    {
     "CATALOG_KEY": "59",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL TRACTO URINARIO"
    },
    {
     "CATALOG_KEY": "590",
     "PRO_NOMBRE": "DISECCIÓN DE TEJIDO RETROPERITONEAL"
    },
    {
     "CATALOG_KEY": "5900",
     "PRO_NOMBRE": "DISECCIÓN RETROPERITONEAL NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "5902",
     "PRO_NOMBRE": "OTRA LISIS DE ADHERENCIAS PERIRRENALES O PERIURETERALES"
    },
    {
     "CATALOG_KEY": "5903",
     "PRO_NOMBRE": "LISIS LAPAROSCÓPICA DE ADHERENCIAS PERIRRENALES O PERIURETERALES"
    },
    {
     "CATALOG_KEY": "5909",
     "PRO_NOMBRE": "OTRA INCISIÓN DE TEJIDO PERIRRENAL O PERIURETERAL"
    },
    {
     "CATALOG_KEY": "591",
     "PRO_NOMBRE": "INCISIÓN DE TEJIDO PERIVESICAL"
    },
    {
     "CATALOG_KEY": "5911",
     "PRO_NOMBRE": "OTRA LISIS DE ADHERENCIAS PERIVESICALES"
    },
    {
     "CATALOG_KEY": "5912",
     "PRO_NOMBRE": "LISIS LAPAROSCÓPICA DE ADHERENCIAS PERIVESICALES"
    },
    {
     "CATALOG_KEY": "5919",
     "PRO_NOMBRE": "OTRA INCISIÓN DE TEJIDO PERIVESICAL"
    },
    {
     "CATALOG_KEY": "592",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS EN TEJIDO PERIRRENAL Y PERIVESICAL"
    },
    {
     "CATALOG_KEY": "5921",
     "PRO_NOMBRE": "BIOPSIA DE TEJIDO PERIRRENAL O PERIVESICAL"
    },
    {
     "CATALOG_KEY": "5929",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE TEJIDO PERIRRENAL, TEJIDO PERIVESICAL Y RETROPERITONEO"
    },
    {
     "CATALOG_KEY": "593",
     "PRO_NOMBRE": "PLICATURA DE UNIÓN URETROVESICAL"
    },
    {
     "CATALOG_KEY": "593X",
     "PRO_NOMBRE": "PLICATURA DE UNIÓN URETROVESICAL"
    },
    {
     "CATALOG_KEY": "594",
     "PRO_NOMBRE": "OPERACIÓN SUPRAPÚBICA DE SUSPENSIÓN (SLING)"
    },
    {
     "CATALOG_KEY": "594X",
     "PRO_NOMBRE": "OPERACIÓN SUPRAPÚBICA DE SUSPENSIÓN (SLING)"
    },
    {
     "CATALOG_KEY": "595",
     "PRO_NOMBRE": "SUSPENSIÓN URETRAL RETROPÚBICA"
    },
    {
     "CATALOG_KEY": "595X",
     "PRO_NOMBRE": "SUSPENSIÓN URETRAL RETROPÚBICA"
    },
    {
     "CATALOG_KEY": "596",
     "PRO_NOMBRE": "SUSPENSIÓN PARAURETRAL"
    },
    {
     "CATALOG_KEY": "596X",
     "PRO_NOMBRE": "SUSPENSIÓN PARAURETRAL"
    },
    {
     "CATALOG_KEY": "597",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE INCONTINENCIA URINARIA DE ESFUERZO"
    },
    {
     "CATALOG_KEY": "5971",
     "PRO_NOMBRE": "OPERACIÓN DE MÚSCULO ELEVADOR PARA SUSPENSIÓN URETROVESICAL"
    },
    {
     "CATALOG_KEY": "5972",
     "PRO_NOMBRE": "INYECCIÓN DE IMPLANTE EN CUELLO VESICAL O INTRAURETRAL"
    },
    {
     "CATALOG_KEY": "5979",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE INCONTINENCIA URINARIA DE ESFUERZO"
    },
    {
     "CATALOG_KEY": "598",
     "PRO_NOMBRE": "CATETERISMO URETERAL"
    },
    {
     "CATALOG_KEY": "598X",
     "PRO_NOMBRE": "CATETERISMO URETERAL"
    },
    {
     "CATALOG_KEY": "599",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "5991",
     "PRO_NOMBRE": "EXCISIÓN DE TEJIDO PERIRRENAL O PERIVESICAL"
    },
    {
     "CATALOG_KEY": "5992",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TEJIDO PERIRRENAL O PERIVESICAL"
    },
    {
     "CATALOG_KEY": "5993",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO DE URETEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "5994",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO DE CISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "5995",
     "PRO_NOMBRE": "FRAGMENTACIÓN POR ULTRASONIDOS DE CÁLCULOS URINARIOS"
    },
    {
     "CATALOG_KEY": "5999",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE ÓRGANOS GENITALES MASCULINOS (60 - 64)"
    },
    {
     "CATALOG_KEY": "60",
     "PRO_NOMBRE": "OPERACIONES SOBRE PRÓSTATA Y VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "600",
     "PRO_NOMBRE": "INCISIÓN DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "600X",
     "PRO_NOMBRE": "INCISIÓN DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "601",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS EN PRÓSTATA Y VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "6011",
     "PRO_NOMBRE": "BIOPSIA CERRADA [POR AGUJA] [PERCUTÁNEA] DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6012",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6013",
     "PRO_NOMBRE": "BIOPSIA CERRADA [PERCUTÁNEA] DE VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "6014",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "6015",
     "PRO_NOMBRE": "BIOPSIA DE TEJIDO PERIPROSTÁTICO"
    },
    {
     "CATALOG_KEY": "6018",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PRÓSTATA Y TEJIDO PERIPROSTÁTICO"
    },
    {
     "CATALOG_KEY": "6019",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "602",
     "PRO_NOMBRE": "RESECCIÓN TRANSURETRAL DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6021",
     "PRO_NOMBRE": "PROSTATECTOMÍA TRANSURETRAL (ULTRASONIDO) GUIADO POR LASER INDUCIDO (TULIP)"
    },
    {
     "CATALOG_KEY": "6029",
     "PRO_NOMBRE": "OTRA PROSTATECTOMÍA TRANSURETRAL"
    },
    {
     "CATALOG_KEY": "603",
     "PRO_NOMBRE": "ADENOMECTOMÍA SUPRAPÚBICA"
    },
    {
     "CATALOG_KEY": "603X",
     "PRO_NOMBRE": "ADENOMECTOMÍA SUPRAPÚBICA"
    },
    {
     "CATALOG_KEY": "604",
     "PRO_NOMBRE": "ADENOMECTOMÍA RETROPÚBICA"
    },
    {
     "CATALOG_KEY": "604X",
     "PRO_NOMBRE": "ADENOMECTOMÍA RETROPÚBICA"
    },
    {
     "CATALOG_KEY": "605",
     "PRO_NOMBRE": "PROSTATECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "605X",
     "PRO_NOMBRE": "PROSTATECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "606",
     "PRO_NOMBRE": "OTRA PROSTATECTOMÍA"
    },
    {
     "CATALOG_KEY": "6061",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6062",
     "PRO_NOMBRE": "PROSTATECTOMÍA PERINEAL"
    },
    {
     "CATALOG_KEY": "6069",
     "PRO_NOMBRE": "OTRA PROSTATECTOMÍA"
    },
    {
     "CATALOG_KEY": "607",
     "PRO_NOMBRE": "OPERACIONES SOBRE VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "6071",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "6072",
     "PRO_NOMBRE": "INCISIÓN DE VESÍCULA SEMINAL"
    },
    {
     "CATALOG_KEY": "6073",
     "PRO_NOMBRE": "EXCISIÓN DE VESÍCULA SEMINAL"
    },
    {
     "CATALOG_KEY": "6079",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "608",
     "PRO_NOMBRE": "INCISIÓN O EXCISIÓN DE TEJIDO PERIPROSTÁTICO"
    },
    {
     "CATALOG_KEY": "6081",
     "PRO_NOMBRE": "INCISIÓN DE TEJIDO PERIPROSTÁTICO"
    },
    {
     "CATALOG_KEY": "6082",
     "PRO_NOMBRE": "EXCISIÓN DE TEJIDO PERIPROSTÁTICO"
    },
    {
     "CATALOG_KEY": "609",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6091",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6092",
     "PRO_NOMBRE": "INYECCIÓN EN PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6093",
     "PRO_NOMBRE": "REPARACIÓN DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6094",
     "PRO_NOMBRE": "CONTROL DE HEMORRAGIA (POSTOPERATORIA) DE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "6095",
     "PRO_NOMBRE": "DILATACIÓN TRANSURETRAL CON BALÓN DE URETRA PROSTÁTICA"
    },
    {
     "CATALOG_KEY": "6096",
     "PRO_NOMBRE": "DESTRUCCIÓN TRANSURETRAL DE TEJIDO PROSTÁTICO POR TERMOTERAPIA CON MICROONDAS"
    },
    {
     "CATALOG_KEY": "6097",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN TRANSURETRAL DE TEJIDO PROSTÁTICO CON OTRA TERMOTERAPIA"
    },
    {
     "CATALOG_KEY": "6099",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PRÓSTATA"
    },
    {
     "CATALOG_KEY": "61",
     "PRO_NOMBRE": "OPERACIONES SOBRE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "610",
     "PRO_NOMBRE": "INCISIÓN Y DRENAJE DE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "610X",
     "PRO_NOMBRE": "INCISIÓN Y DRENAJE DE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "611",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "6111",
     "PRO_NOMBRE": "BIOPSIA DE ESCROTO O TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "6119",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "612",
     "PRO_NOMBRE": "EXCISIÓN DE HIDROCELE (DE TÚNICA VAGINAL)"
    },
    {
     "CATALOG_KEY": "612X",
     "PRO_NOMBRE": "EXCISIÓN DE HIDROCELE (DE TÚNICA VAGINAL)"
    },
    {
     "CATALOG_KEY": "613",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE ESCROTO"
    },
    {
     "CATALOG_KEY": "613X",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE ESCROTO"
    },
    {
     "CATALOG_KEY": "614",
     "PRO_NOMBRE": "REPARACIÓN DE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "6141",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "6142",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA ESCROTAL"
    },
    {
     "CATALOG_KEY": "6149",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "619",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "6191",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "6192",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE TÚNICA VAGINAL SALVO HIDROCELE"
    },
    {
     "CATALOG_KEY": "6199",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ESCROTO Y TÚNICA VAGINAL"
    },
    {
     "CATALOG_KEY": "62",
     "PRO_NOMBRE": "OPERACIONES SOBRE TESTÍCULOS"
    },
    {
     "CATALOG_KEY": "620",
     "PRO_NOMBRE": "INCISIÓN DE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "620X",
     "PRO_NOMBRE": "INCISIÓN DE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "621",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE TESTÍCULOS"
    },
    {
     "CATALOG_KEY": "6211",
     "PRO_NOMBRE": "BIOPSIA CERRADA [PERCUTÁNEA] [POR AGUJA] DE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "6212",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "6219",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE TESTÍCULOS"
    },
    {
     "CATALOG_KEY": "622",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN TESTICULAR"
    },
    {
     "CATALOG_KEY": "622X",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN TESTICULAR"
    },
    {
     "CATALOG_KEY": "623",
     "PRO_NOMBRE": "ORQUIECTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "623X",
     "PRO_NOMBRE": "ORQUIECTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "624",
     "PRO_NOMBRE": "ORQUIECTOMÍA BILATERAL"
    },
    {
     "CATALOG_KEY": "6241",
     "PRO_NOMBRE": "EXTIRPACIÓN DE AMBOS TESTÍCULOS EN EL MISMO EPISODIO OPERATORIO"
    },
    {
     "CATALOG_KEY": "6242",
     "PRO_NOMBRE": "EXTIRPACIÓN DE TESTÍCULO RESTANTE"
    },
    {
     "CATALOG_KEY": "625",
     "PRO_NOMBRE": "ORQUIOPEXIA"
    },
    {
     "CATALOG_KEY": "625X",
     "PRO_NOMBRE": "ORQUIOPEXIA"
    },
    {
     "CATALOG_KEY": "626",
     "PRO_NOMBRE": "REPARACIÓN DE TESTÍCULOS"
    },
    {
     "CATALOG_KEY": "6261",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "6269",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "627",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS TESTICULAR"
    },
    {
     "CATALOG_KEY": "627X",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS TESTICULAR"
    },
    {
     "CATALOG_KEY": "629",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "6291",
     "PRO_NOMBRE": "ASPIRACIÓN DE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "6292",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN TESTÍCULO"
    },
    {
     "CATALOG_KEY": "6299",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TESTÍCULO"
    },
    {
     "CATALOG_KEY": "63",
     "PRO_NOMBRE": "OPERACIONES SOBRE CORDÓN ESPERMÁTICO, EPIDÍDIMO Y CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "630",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE CORDÓN ESPERMÁTICO, EPIDÍDIMO Y CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6301",
     "PRO_NOMBRE": "BIOPSIA DE CORDÓN ESPERMÁTICO, EPIDÍDIMO O CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6309",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICO SOBRE CORDÓN ESPERMÁTICO, EPIDÍDIMO Y CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "631",
     "PRO_NOMBRE": "EXCISIÓN DE VARICOCELE E HIDROCELE DE CORDÓN ESPERMÁTICO"
    },
    {
     "CATALOG_KEY": "631X",
     "PRO_NOMBRE": "EXCISIÓN DE VARICOCELE E HIDROCELE DE CORDÓN ESPERMÁTICO"
    },
    {
     "CATALOG_KEY": "632",
     "PRO_NOMBRE": "EXCISIÓN DE QUISTE DE EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "632X",
     "PRO_NOMBRE": "EXCISIÓN DE QUISTE DE EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "633",
     "PRO_NOMBRE": "EXCISIÓN DE OTRA LESIÓN O TEJIDO DE CORDÓN ESPERMÁTICO Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "633X",
     "PRO_NOMBRE": "EXCISIÓN DE OTRA LESIÓN O TEJIDO DE CORDÓN ESPERMÁTICO Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "634",
     "PRO_NOMBRE": "EPIDIDIMECTOMÍA"
    },
    {
     "CATALOG_KEY": "634X",
     "PRO_NOMBRE": "EPIDIDIMECTOMÍA"
    },
    {
     "CATALOG_KEY": "635",
     "PRO_NOMBRE": "REPARACIÓN DE CORDÓN ESPERMÁTICO Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "6351",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE CORDÓN ESPERMÁTICO Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "6352",
     "PRO_NOMBRE": "REDUCCIÓN DE TORSIÓN DE TESTÍCULO O CORDÓN ESPERMÁTICO"
    },
    {
     "CATALOG_KEY": "6353",
     "PRO_NOMBRE": "TRASPLANTE DE CORDÓN ESPERMÁTICO"
    },
    {
     "CATALOG_KEY": "6359",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE CORDÓN ESPERMÁTICO Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "636",
     "PRO_NOMBRE": "VASOTOMÍA"
    },
    {
     "CATALOG_KEY": "636X",
     "PRO_NOMBRE": "VASOTOMÍA"
    },
    {
     "CATALOG_KEY": "637",
     "PRO_NOMBRE": "VASECTOMÍA Y LIGADURA DE CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6370",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ESTERILIZACIÓN MASCULINA, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "6371",
     "PRO_NOMBRE": "LIGADURA DE CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6372",
     "PRO_NOMBRE": "LIGADURA DE CORDÓN ESPERMÁTICO"
    },
    {
     "CATALOG_KEY": "6373",
     "PRO_NOMBRE": "VASECTOMÍA"
    },
    {
     "CATALOG_KEY": "6374",
     "PRO_NOMBRE": "VASECTOMÍA SIN BISTURÍ"
    },
    {
     "CATALOG_KEY": "638",
     "PRO_NOMBRE": "REPARACIÓN DE CONDUCTO DEFERENTE Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "6381",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE CONDUCTO DEFERENTE Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "6382",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE CONDUCTO DEFERENTE DIVIDIDO QUIRÚRGICAMENTE"
    },
    {
     "CATALOG_KEY": "6383",
     "PRO_NOMBRE": "EPIDIDIMOVASOSTOMÍA"
    },
    {
     "CATALOG_KEY": "6384",
     "PRO_NOMBRE": "RETIRADA DE LIGADURA DE CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6385",
     "PRO_NOMBRE": "EXTRACCIÓN DE VÁLVULA DE CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6389",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE CONDUCTO DEFERENTE Y EPIDÍDIMO"
    },
    {
     "CATALOG_KEY": "639",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CORDÓN ESPERMÁTICO, EPIDÍDIMO Y CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6391",
     "PRO_NOMBRE": "ASPIRACIÓN DE ESPERMATOCELE"
    },
    {
     "CATALOG_KEY": "6392",
     "PRO_NOMBRE": "EPIDIDIMOTOMÍA"
    },
    {
     "CATALOG_KEY": "6393",
     "PRO_NOMBRE": "INCISIÓN DE CORDÓN ESPERMÁTICO"
    },
    {
     "CATALOG_KEY": "6394",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE CORDÓN ESPERMÁTICO"
    },
    {
     "CATALOG_KEY": "6395",
     "PRO_NOMBRE": "INSERCIÓN DE VÁLVULA EN VASO DEFERENTE"
    },
    {
     "CATALOG_KEY": "6399",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CORDÓN ESPERMÁTICO, EPIDÍDIMO Y CONDUCTO DEFERENTE"
    },
    {
     "CATALOG_KEY": "64",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL PENE"
    },
    {
     "CATALOG_KEY": "640",
     "PRO_NOMBRE": "CIRCUNCISIÓN"
    },
    {
     "CATALOG_KEY": "640X",
     "PRO_NOMBRE": "CIRCUNCISIÓN"
    },
    {
     "CATALOG_KEY": "641",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS EN EL PENE"
    },
    {
     "CATALOG_KEY": "6411",
     "PRO_NOMBRE": "BIOPSIA DE PENE"
    },
    {
     "CATALOG_KEY": "6419",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PENE"
    },
    {
     "CATALOG_KEY": "642",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "642X",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "643",
     "PRO_NOMBRE": "AMPUTACIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "643X",
     "PRO_NOMBRE": "AMPUTACIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "644",
     "PRO_NOMBRE": "OPERACIÓN DE REPARACIÓN Y PLÁSTICA SOBRE PENE"
    },
    {
     "CATALOG_KEY": "6441",
     "PRO_NOMBRE": "SUTURA DE LACERACIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "6442",
     "PRO_NOMBRE": "LIBERACIÓN DE CORDA [CHORDÉE]"
    },
    {
     "CATALOG_KEY": "6443",
     "PRO_NOMBRE": "CONSTRUCCIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "6444",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "6445",
     "PRO_NOMBRE": "REIMPLANTACIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "6449",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "645",
     "PRO_NOMBRE": "OPERACIONES PARA TRANSFORMACIÓN DE SEXO, NO CLASIFICADAS BAJO OTROS CONCEPTOS"
    },
    {
     "CATALOG_KEY": "645X",
     "PRO_NOMBRE": "OPERACIONES PARA TRANSFORMACIÓN DE SEXO, NO CLASIFICADAS BAJO OTROS CONCEPTOS"
    },
    {
     "CATALOG_KEY": "649",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ÓRGANOS GENITALES MASCULINOS"
    },
    {
     "CATALOG_KEY": "6491",
     "PRO_NOMBRE": "CORTE DORSAL O LATERAL DE PREPUCIO"
    },
    {
     "CATALOG_KEY": "6492",
     "PRO_NOMBRE": "INCISIÓN DE PENE"
    },
    {
     "CATALOG_KEY": "6493",
     "PRO_NOMBRE": "LIBERACIÓN DE ADHERENCIAS DE PENE"
    },
    {
     "CATALOG_KEY": "6494",
     "PRO_NOMBRE": "COLOCACIÓN DE PRÓTESIS EXTERNA DE PENE"
    },
    {
     "CATALOG_KEY": "6495",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE PRÓTESIS DE PENE NO INFLABLE"
    },
    {
     "CATALOG_KEY": "6496",
     "PRO_NOMBRE": "EXTRACCIÓN DE PRÓTESIS INTERNA DE PENE"
    },
    {
     "CATALOG_KEY": "6497",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE PRÓTESIS DE PENE INFLABLE"
    },
    {
     "CATALOG_KEY": "6498",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL PENE"
    },
    {
     "CATALOG_KEY": "6499",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ÓRGANOS GENITALES MASCULINOS"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE ÓRGANOS GENITALES FEMENINOS (65 - 71)"
    },
    {
     "CATALOG_KEY": "65",
     "PRO_NOMBRE": "OPERACIONES SOBRE LOS OVARIOS"
    },
    {
     "CATALOG_KEY": "650",
     "PRO_NOMBRE": "OOFOROTOMÍA"
    },
    {
     "CATALOG_KEY": "6501",
     "PRO_NOMBRE": "OOFOROTOMÍA LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "6509",
     "PRO_NOMBRE": "OTRA OOFOROTOMÍA"
    },
    {
     "CATALOG_KEY": "651",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LOS OVARIOS"
    },
    {
     "CATALOG_KEY": "6511",
     "PRO_NOMBRE": "BIOPSIA POR ASPIRACIÓN DE OVARIO"
    },
    {
     "CATALOG_KEY": "6512",
     "PRO_NOMBRE": "OTRA BIOPSIA DE OVARIO"
    },
    {
     "CATALOG_KEY": "6513",
     "PRO_NOMBRE": "BIOPSIA LAPAROSCÓPICA DE OVARIO"
    },
    {
     "CATALOG_KEY": "6514",
     "PRO_NOMBRE": "OTRO PROCEDIMIENTO DIAGNÓSTICO LAPAROSCÓPICO DE OVARIO"
    },
    {
     "CATALOG_KEY": "6519",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LOS OVARIOS"
    },
    {
     "CATALOG_KEY": "652",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO DE OVARIO"
    },
    {
     "CATALOG_KEY": "6521",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE QUISTE OVÁRICO"
    },
    {
     "CATALOG_KEY": "6522",
     "PRO_NOMBRE": "RESECCIÓN EN CUÑA DE OVARIO"
    },
    {
     "CATALOG_KEY": "6523",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN LAPAROSCÓPICA DE QUISTE OVÁRICO"
    },
    {
     "CATALOG_KEY": "6524",
     "PRO_NOMBRE": "RESECCIÓN EN CUÑA LAPAROSCÓPICA DE OVARIO"
    },
    {
     "CATALOG_KEY": "6525",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN LAPAROSCÓPICA DE OVARIO"
    },
    {
     "CATALOG_KEY": "6529",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE OVARIO"
    },
    {
     "CATALOG_KEY": "653",
     "PRO_NOMBRE": "OOFORECTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "6531",
     "PRO_NOMBRE": "OOFORECTOMÍA UNILATERAL LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "6539",
     "PRO_NOMBRE": "OTRA OOFOROTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "654",
     "PRO_NOMBRE": "SALPINGOOFORECTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "6541",
     "PRO_NOMBRE": "SALPINGOOFORECTOMÍA UNILATERAL LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "6549",
     "PRO_NOMBRE": "OTRA SALPINGOOFORECTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "655",
     "PRO_NOMBRE": "OOFORECTOMÍA BILATERAL"
    },
    {
     "CATALOG_KEY": "6551",
     "PRO_NOMBRE": "EXTIRPACIÓN DE AMBOS OVARIOS EN UN MISMO ACTO OPERATORIO"
    },
    {
     "CATALOG_KEY": "6552",
     "PRO_NOMBRE": "EXTIRPACIÓN DEL OVARIO RESTANTE"
    },
    {
     "CATALOG_KEY": "6553",
     "PRO_NOMBRE": "EXTIRPACIÓN LAPAROSCÓPICA DE AMBOS OVARIOS EN UN MISMO ACTO OPERATORIO"
    },
    {
     "CATALOG_KEY": "6554",
     "PRO_NOMBRE": "EXTIRPACIÓN LAPAROSCÓPICA DE OVARIO RESTANTE"
    },
    {
     "CATALOG_KEY": "656",
     "PRO_NOMBRE": "SALPINGOOFORECTOMÍA BILATERAL"
    },
    {
     "CATALOG_KEY": "6561",
     "PRO_NOMBRE": "EXTIRPACIÓN DE AMBOS OVARIOS Y TROMPAS EN UN MISMO ACTO OPERATORIO"
    },
    {
     "CATALOG_KEY": "6562",
     "PRO_NOMBRE": "EXTIRPACIÓN DE OVARIO Y TROMPA RESTANTES"
    },
    {
     "CATALOG_KEY": "6563",
     "PRO_NOMBRE": "EXTIRPACIÓN LAPAROSCÓPICA DE AMBOS OVARIOS Y TROMPAS EN UN MISMO ACTO OPERATORIO"
    },
    {
     "CATALOG_KEY": "6564",
     "PRO_NOMBRE": "EXTIRPACIÓN LAPAROSCÓPICA DE OVARIO Y TROMPA RESTANTES"
    },
    {
     "CATALOG_KEY": "657",
     "PRO_NOMBRE": "REPARACIÓN DE OVARIO"
    },
    {
     "CATALOG_KEY": "6571",
     "PRO_NOMBRE": "OTRA SUTURA SIMPLE DE OVARIO"
    },
    {
     "CATALOG_KEY": "6572",
     "PRO_NOMBRE": "REIMPLANTACIÓN DE OVARIO"
    },
    {
     "CATALOG_KEY": "6573",
     "PRO_NOMBRE": "OTRA SALPINGOOFOROPLASTÍA"
    },
    {
     "CATALOG_KEY": "6574",
     "PRO_NOMBRE": "SUTURA SIMPLE LAPAROSCÓPICA DE OVARIO"
    },
    {
     "CATALOG_KEY": "6575",
     "PRO_NOMBRE": "REIMPLANTACIÓN LAPAROSCÓPICA DE OVARIO"
    },
    {
     "CATALOG_KEY": "6576",
     "PRO_NOMBRE": "SALPINGOOFOROPLASTIA LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "6579",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE OVARIO"
    },
    {
     "CATALOG_KEY": "658",
     "PRO_NOMBRE": "LIBERACIÓN DE ADHERENCIAS DE OVARIO Y TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6581",
     "PRO_NOMBRE": "LIBERACIÓN LAPAROSCÓPICA DE ADHERENCIAS DE OVARIO Y TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6589",
     "PRO_NOMBRE": "OTRA LIBERACIÓN DE ADHERENCIAS DE OVARIO Y TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "659",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL OVARIO"
    },
    {
     "CATALOG_KEY": "6591",
     "PRO_NOMBRE": "ASPIRACIÓN DE OVARIO"
    },
    {
     "CATALOG_KEY": "6592",
     "PRO_NOMBRE": "TRASPLANTE DE OVARIO"
    },
    {
     "CATALOG_KEY": "6593",
     "PRO_NOMBRE": "RUPTURA MANUAL DE QUISTE OVÁRICO"
    },
    {
     "CATALOG_KEY": "6594",
     "PRO_NOMBRE": "DENERVACIÓN OVÁRICA"
    },
    {
     "CATALOG_KEY": "6595",
     "PRO_NOMBRE": "LIBERACIÓN DE TORSIÓN DE OVARIO"
    },
    {
     "CATALOG_KEY": "6599",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL OVARIO"
    },
    {
     "CATALOG_KEY": "66",
     "PRO_NOMBRE": "OPERACIONES SOBRE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "660",
     "PRO_NOMBRE": "SALPINGOTOMÍA Y SALPINGOSTOMIA"
    },
    {
     "CATALOG_KEY": "6601",
     "PRO_NOMBRE": "SALPINGOTOMÍA"
    },
    {
     "CATALOG_KEY": "6602",
     "PRO_NOMBRE": "SALPINGOSTOMÍA"
    },
    {
     "CATALOG_KEY": "661",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6611",
     "PRO_NOMBRE": "BIOPSIA DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6619",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "662",
     "PRO_NOMBRE": "DESTRUCCIÓN U OCLUSIÓN ENDOSCÓPICA BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6621",
     "PRO_NOMBRE": "LIGADURA Y APLASTAMIENTO ENDOSCÓPICO BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6622",
     "PRO_NOMBRE": "LIGADURA Y SECCIÓN ENDOSCÓPICA BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6629",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN U OCLUSIÓN ENDOSCÓPICA BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "663",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN U OCLUSIÓN BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6631",
     "PRO_NOMBRE": "OTRA LIGADURA Y APLASTAMIENTO BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6632",
     "PRO_NOMBRE": "OTRA LIGADURA Y SECCIÓN BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6639",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN U OCLUSIÓN BILATERAL DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "664",
     "PRO_NOMBRE": "SALPINGECTOMÍA UNILATERAL TOTAL"
    },
    {
     "CATALOG_KEY": "664X",
     "PRO_NOMBRE": "SALPINGECTOMÍA UNILATERAL TOTAL"
    },
    {
     "CATALOG_KEY": "665",
     "PRO_NOMBRE": "SALPINGECTOMÍA BILATERAL TOTAL"
    },
    {
     "CATALOG_KEY": "6651",
     "PRO_NOMBRE": "EXTIRPACIÓN DE AMBAS TROMPAS DE FALOPIO EN UN MISMO ACTO QUIRÚRGICO"
    },
    {
     "CATALOG_KEY": "6652",
     "PRO_NOMBRE": "EXTIRPACIÓN DE TROMPA DE FALOPIO RESTANTE"
    },
    {
     "CATALOG_KEY": "666",
     "PRO_NOMBRE": "OTRA SALPINGECTOMÍA"
    },
    {
     "CATALOG_KEY": "6661",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6662",
     "PRO_NOMBRE": "SALPINGECTOMÍA CON EXTRACCIÓN DE EMBARAZO TUBÁRICO"
    },
    {
     "CATALOG_KEY": "6663",
     "PRO_NOMBRE": "SALPINGECTOMÍA PARCIAL BILATERAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "6669",
     "PRO_NOMBRE": "OTRA SALPINGECTOMIA PARCIAL"
    },
    {
     "CATALOG_KEY": "667",
     "PRO_NOMBRE": "REPARACIÓN DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6671",
     "PRO_NOMBRE": "SUTURA SIMPLE DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6672",
     "PRO_NOMBRE": "SALPINGOOFOROSTOMÍA"
    },
    {
     "CATALOG_KEY": "6673",
     "PRO_NOMBRE": "SALPINGOSALPINGOSTOMÍA"
    },
    {
     "CATALOG_KEY": "6674",
     "PRO_NOMBRE": "SALPINGOUTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "6679",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "668",
     "PRO_NOMBRE": "INSUFLACIÓN DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "668X",
     "PRO_NOMBRE": "INSUFLACIÓN DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "669",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6691",
     "PRO_NOMBRE": "ASPIRACIÓN DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6692",
     "PRO_NOMBRE": "DESTRUCCIÓN U OCLUSIÓN UNILATERAL DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6693",
     "PRO_NOMBRE": "IMPLANTACIÓN O SUSTITUCIÓN DE PRÓTESIS DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6694",
     "PRO_NOMBRE": "EXTRACCIÓN DE PRÓTESIS DE TROMPA DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6695",
     "PRO_NOMBRE": "INSUFLACIÓN DE AGENTE TERAPÉUTICO EN LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6696",
     "PRO_NOMBRE": "DILATACIÓN DE LAS TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "6697",
     "PRO_NOMBRE": "ENTERRAMIENTO DE FIMBRIAS EN LA PARED UTERINA"
    },
    {
     "CATALOG_KEY": "6699",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE TROMPAS DE FALOPIO"
    },
    {
     "CATALOG_KEY": "67",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "670",
     "PRO_NOMBRE": "DILATACIÓN DEL CANAL CERVICAL"
    },
    {
     "CATALOG_KEY": "670X",
     "PRO_NOMBRE": "DILATACIÓN DEL CANAL CERVICAL"
    },
    {
     "CATALOG_KEY": "671",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "6711",
     "PRO_NOMBRE": "BIOPSIA ENDOCERVICAL"
    },
    {
     "CATALOG_KEY": "6712",
     "PRO_NOMBRE": "OTRA BIOPSIA CERVICAL"
    },
    {
     "CATALOG_KEY": "6719",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "672",
     "PRO_NOMBRE": "CONIZACIÓN"
    },
    {
     "CATALOG_KEY": "672X",
     "PRO_NOMBRE": "CONIZACIÓN"
    },
    {
     "CATALOG_KEY": "673",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "6731",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE QUISTE CERVICAL"
    },
    {
     "CATALOG_KEY": "6732",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN DEL CUELLO UTERINO POR CAUTERIZACIÓN"
    },
    {
     "CATALOG_KEY": "6733",
     "PRO_NOMBRE": "DESTRUCCIÓN DE LESIÓN DEL CUELLO UTERINO POR CRIOCIRUGÍA"
    },
    {
     "CATALOG_KEY": "6739",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "674",
     "PRO_NOMBRE": "AMPUTACIÓN DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "674X",
     "PRO_NOMBRE": "AMPUTACIÓN DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "675",
     "PRO_NOMBRE": "REPARACIÓN DE ORIFICIO CERVICAL INTERNO"
    },
    {
     "CATALOG_KEY": "6751",
     "PRO_NOMBRE": "CERCLAJE TRANSABDOMINAL DE CÉRVIX"
    },
    {
     "CATALOG_KEY": "6759",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ORIFICIO CERVICAL INTERNO"
    },
    {
     "CATALOG_KEY": "676",
     "PRO_NOMBRE": "OTRA REPARACIÓN DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "6761",
     "PRO_NOMBRE": "SUTURA DE DESGARRO DE CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "6762",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA DE CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "6769",
     "PRO_NOMBRE": "OTRA REPARACIÓN DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "68",
     "PRO_NOMBRE": "OTRA INCISIÓN Y EXCISIÓN DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "680",
     "PRO_NOMBRE": "HISTEROTOMÍA"
    },
    {
     "CATALOG_KEY": "680X",
     "PRO_NOMBRE": "HISTEROTOMÍA"
    },
    {
     "CATALOG_KEY": "681",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL ÚTERO Y SUS ESTRUCTURAS DE SOPORTE"
    },
    {
     "CATALOG_KEY": "6811",
     "PRO_NOMBRE": "EXAMEN DIGITAL DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "6812",
     "PRO_NOMBRE": "HISTEROSCOPIA"
    },
    {
     "CATALOG_KEY": "6813",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE ÚTERO"
    },
    {
     "CATALOG_KEY": "6814",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE LIGAMENTOS UTERINOS"
    },
    {
     "CATALOG_KEY": "6815",
     "PRO_NOMBRE": "BIOPSIA CERRADA DE LIGAMENTOS UTERINOS"
    },
    {
     "CATALOG_KEY": "6816",
     "PRO_NOMBRE": "BIOPSIA CERRADA DE ÚTERO"
    },
    {
     "CATALOG_KEY": "6819",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ÚTERO Y ESTRUCTURAS DE SOPORTE"
    },
    {
     "CATALOG_KEY": "682",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "6821",
     "PRO_NOMBRE": "DIVISIÓN DE SINEQUIAS ENDOMETRIALES"
    },
    {
     "CATALOG_KEY": "6822",
     "PRO_NOMBRE": "INCISIÓN O EXCISIÓN DE TABIQUE CONGÉNITO DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "6823",
     "PRO_NOMBRE": "ABLACIÓN ENDOMETRIAL"
    },
    {
     "CATALOG_KEY": "6829",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "683",
     "PRO_NOMBRE": "HISTERECTOMÍA SUBTOTAL ABDOMINAL"
    },
    {
     "CATALOG_KEY": "6831",
     "PRO_NOMBRE": "HISTERECTOMÍA SUPRACERVICAL LAPAROSCÓPICA [HSL]"
    },
    {
     "CATALOG_KEY": "6839",
     "PRO_NOMBRE": "OTRA HISTERECTOMÍA SUBTOTAL ABDOMINAL Y LA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "684",
     "PRO_NOMBRE": "HISTERECTOMÍA TOTAL ABDOMINAL"
    },
    {
     "CATALOG_KEY": "6841",
     "PRO_NOMBRE": "HISTERECTOMÍA TOTAL ABDOMINAL LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "6849",
     "PRO_NOMBRE": "OTRA HISTERECTOMÍA TOTAL ABDOMINAL Y LA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "685",
     "PRO_NOMBRE": "HISTERECTOMÍA VAGINAL"
    },
    {
     "CATALOG_KEY": "6851",
     "PRO_NOMBRE": "HISTERECTOMÍA VAGINAL ASISTIDA POR LAPAROSCOPIA (HVAL)"
    },
    {
     "CATALOG_KEY": "6859",
     "PRO_NOMBRE": "OTRA HISTERECTOMÍA VAGINAL Y LA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "686",
     "PRO_NOMBRE": "HISTERECTOMÍA RADICAL ABDOMINAL"
    },
    {
     "CATALOG_KEY": "6861",
     "PRO_NOMBRE": "HISTERECTOMÍA RADICAL ABDOMINAL LAPAROSCÓPICA"
    },
    {
     "CATALOG_KEY": "6869",
     "PRO_NOMBRE": "OTRA HISTERECTOMÍA RADICAL ABDOMINAL Y LA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "687",
     "PRO_NOMBRE": "HISTERECTOMÍA RADICAL VAGINAL"
    },
    {
     "CATALOG_KEY": "6871",
     "PRO_NOMBRE": "HISTERECTOMÍA RADICAL VAGINAL LAPAROSCÓPICA [HRVL]"
    },
    {
     "CATALOG_KEY": "6879",
     "PRO_NOMBRE": "OTRA HISTERECTOMÍA RADICAL VAGINAL Y LA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "688",
     "PRO_NOMBRE": "EXENTERACIÓN PÉLVICA"
    },
    {
     "CATALOG_KEY": "688X",
     "PRO_NOMBRE": "EXENTERACIÓN PÉLVICA"
    },
    {
     "CATALOG_KEY": "689",
     "PRO_NOMBRE": "OTRAS HISTERECTOMÍAS Y LAS NO ESPECIFICADAS"
    },
    {
     "CATALOG_KEY": "689X",
     "PRO_NOMBRE": "OTRAS HISTERECTOMÍAS Y LAS NO ESPECIFICADAS"
    },
    {
     "CATALOG_KEY": "69",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL ÚTERO Y SUS ESTRUCTURAS DE SOPORTE"
    },
    {
     "CATALOG_KEY": "690",
     "PRO_NOMBRE": "DILATACIÓN Y LEGRADO DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "6901",
     "PRO_NOMBRE": "DILATACIÓN Y LEGRADO PARA TERMINACIÓN DEL EMBARAZO"
    },
    {
     "CATALOG_KEY": "6902",
     "PRO_NOMBRE": "DILATACIÓN Y LEGRADO DESPUÉS DE PARTO O ABORTO"
    },
    {
     "CATALOG_KEY": "6909",
     "PRO_NOMBRE": "OTRA DILATACIÓN Y LEGRADO"
    },
    {
     "CATALOG_KEY": "691",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DEL ÚTERO Y SUS ESTRUCTURAS DE SOPORTE"
    },
    {
     "CATALOG_KEY": "6919",
     "PRO_NOMBRE": "OTRA EXISIÓN O DESTRUCCIÓN DEL ÚTERO Y SUS ESTRUCTURAS DE SOPORTE"
    },
    {
     "CATALOG_KEY": "692",
     "PRO_NOMBRE": "REPARACIÓN DE ESTRUCTURAS DE SOPORTE DE ÚTERO"
    },
    {
     "CATALOG_KEY": "6921",
     "PRO_NOMBRE": "OPERACIÓN DE INTERPOSICIÓN"
    },
    {
     "CATALOG_KEY": "6922",
     "PRO_NOMBRE": "OTRA SUSPENSIÓN UTERINA"
    },
    {
     "CATALOG_KEY": "6923",
     "PRO_NOMBRE": "REPARACIÓN VAGINAL DE INVERSIÓN CRÓNICA DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "6929",
     "PRO_NOMBRE": "OTRA REPARACIÓN DEL ÚTERO Y SUS ESTRUCTURAS DE SOPORTE"
    },
    {
     "CATALOG_KEY": "693",
     "PRO_NOMBRE": "DENERVACIÓN UTERINA PARACERVICAL"
    },
    {
     "CATALOG_KEY": "693X",
     "PRO_NOMBRE": "DENERVACIÓN UTERINA PARACERVICAL"
    },
    {
     "CATALOG_KEY": "694",
     "PRO_NOMBRE": "REPARACIÓN UTERINA"
    },
    {
     "CATALOG_KEY": "6941",
     "PRO_NOMBRE": "SUTURA DE DESGARRO DE ÚTERO"
    },
    {
     "CATALOG_KEY": "6942",
     "PRO_NOMBRE": "CIERRE DE FÍSTULA DE ÚTERO"
    },
    {
     "CATALOG_KEY": "6949",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ÚTERO"
    },
    {
     "CATALOG_KEY": "695",
     "PRO_NOMBRE": "LEGRADO POR ASPIRACIÓN DE ÚTERO"
    },
    {
     "CATALOG_KEY": "6951",
     "PRO_NOMBRE": "LEGRADO POR ASPIRACIÓN DEL ÚTERO PARA TERMINACIÓN DE EMBARAZO"
    },
    {
     "CATALOG_KEY": "6952",
     "PRO_NOMBRE": "LEGRADO POR ASPIRACIÓN DEL ÚTERO DESPUÉS DE PARTO O ABORTO"
    },
    {
     "CATALOG_KEY": "6959",
     "PRO_NOMBRE": "OTRO LEGRADO POR ASPIRACIÓN DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "696",
     "PRO_NOMBRE": "EXTRACCIÓN O REGULACIÓN MENSTRUAL"
    },
    {
     "CATALOG_KEY": "696X",
     "PRO_NOMBRE": "EXTRACCIÓN O REGULACIÓN MENSTRUAL"
    },
    {
     "CATALOG_KEY": "697",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO"
    },
    {
     "CATALOG_KEY": "697X",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO"
    },
    {
     "CATALOG_KEY": "699",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE ÚTERO, CUELLO Y ESTRUCTURAS DE APOYO"
    },
    {
     "CATALOG_KEY": "6991",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO TERAPÉUTICO EN ÚTERO"
    },
    {
     "CATALOG_KEY": "6992",
     "PRO_NOMBRE": "INSEMINACIÓN ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "6993",
     "PRO_NOMBRE": "INSERCIÓN DE LAMINARIA"
    },
    {
     "CATALOG_KEY": "6994",
     "PRO_NOMBRE": "REPOSICIÓN MANUAL DE ÚTERO INVERTIDO"
    },
    {
     "CATALOG_KEY": "6995",
     "PRO_NOMBRE": "INCISIÓN DE CUELLO"
    },
    {
     "CATALOG_KEY": "6996",
     "PRO_NOMBRE": "EXTRACCIÓN DE MATERIAL DE CERCLAJE DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "6997",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO CUERPO EXTRAÑO PENETRANTE DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "6998",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LAS ESTRUCTURAS DE SOPORTE DE ÚTERO"
    },
    {
     "CATALOG_KEY": "6999",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE CUELLO UTERINO Y ÚTERO"
    },
    {
     "CATALOG_KEY": "70",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA VAGINA Y FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "700",
     "PRO_NOMBRE": "CULDOCENTESIS"
    },
    {
     "CATALOG_KEY": "700X",
     "PRO_NOMBRE": "CULDOCENTESIS"
    },
    {
     "CATALOG_KEY": "701",
     "PRO_NOMBRE": "INCISIÓN DE VAGINA Y DE FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "7011",
     "PRO_NOMBRE": "HIMENOTOMÍA"
    },
    {
     "CATALOG_KEY": "7012",
     "PRO_NOMBRE": "CULDOTOMÍA"
    },
    {
     "CATALOG_KEY": "7013",
     "PRO_NOMBRE": "LIBERACIÓN DE ADHERENCIAS VAGINALES INTRALUMINALES"
    },
    {
     "CATALOG_KEY": "7014",
     "PRO_NOMBRE": "OTRA VAGINOTOMÍA"
    },
    {
     "CATALOG_KEY": "702",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE VAGINA Y FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "7021",
     "PRO_NOMBRE": "VAGINOSCOPÍA"
    },
    {
     "CATALOG_KEY": "7022",
     "PRO_NOMBRE": "CULDOSCOPÍA"
    },
    {
     "CATALOG_KEY": "7023",
     "PRO_NOMBRE": "BIOPSIA DE FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "7024",
     "PRO_NOMBRE": "BIOPSIA VAGINAL"
    },
    {
     "CATALOG_KEY": "7029",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE VAGINA Y FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "703",
     "PRO_NOMBRE": "ESCISIÓN LOCAL O DESTRUCCIÓN DE VAGINA Y FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "7031",
     "PRO_NOMBRE": "HIMENECTOMÍA"
    },
    {
     "CATALOG_KEY": "7032",
     "PRO_NOMBRE": "ESCISIÓN O DESTRUCCIÓN DE LESIÓN DE FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "7033",
     "PRO_NOMBRE": "ESCISIÓN O DESTRUCCIÓN DE LESIÓN DE VAGINA"
    },
    {
     "CATALOG_KEY": "704",
     "PRO_NOMBRE": "OBLITERACIÓN Y EXCISIÓN TOTAL DE VAGINA"
    },
    {
     "CATALOG_KEY": "704X",
     "PRO_NOMBRE": "OBLITERACIÓN Y ESCISIÓN TOTAL DE VAGINA"
    },
    {
     "CATALOG_KEY": "705",
     "PRO_NOMBRE": "REPARACIÓN DE CISTOCELE Y RECTOCELE"
    },
    {
     "CATALOG_KEY": "7050",
     "PRO_NOMBRE": "REPARACIÓN DE CISTOCELE Y RECTOCELE"
    },
    {
     "CATALOG_KEY": "7051",
     "PRO_NOMBRE": "REPARACIÓN DE CISTOCELE"
    },
    {
     "CATALOG_KEY": "7052",
     "PRO_NOMBRE": "REPARACIÓN DE RECTOCELE"
    },
    {
     "CATALOG_KEY": "7053",
     "PRO_NOMBRE": "REPARACIÓN DE CISTOCELE Y RECTOCELE CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "7054",
     "PRO_NOMBRE": "REPARACIÓN DE CISTOCELE CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "7055",
     "PRO_NOMBRE": "REPARACIÓN DE RECTOCELE CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "706",
     "PRO_NOMBRE": "CONSTRUCCIÓN Y RECONSTRUCCIÓN VAGINALES"
    },
    {
     "CATALOG_KEY": "7061",
     "PRO_NOMBRE": "CONSTRUCCIÓN VAGINAL (CREACIÓN DE UNA NEOVAGINA)"
    },
    {
     "CATALOG_KEY": "7062",
     "PRO_NOMBRE": "RECONSTRUCCIÓN VAGINAL"
    },
    {
     "CATALOG_KEY": "7063",
     "PRO_NOMBRE": "CONSTRUCCIÓN VAGINAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "7064",
     "PRO_NOMBRE": "RECONSTRUCCIÓN VAGINAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "707",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE VAGINA"
    },
    {
     "CATALOG_KEY": "7071",
     "PRO_NOMBRE": "SUTURA DE DESGARRO DE VAGINA"
    },
    {
     "CATALOG_KEY": "7072",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA COLOVAGINAL"
    },
    {
     "CATALOG_KEY": "7073",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA RECTOVAGINAL"
    },
    {
     "CATALOG_KEY": "7074",
     "PRO_NOMBRE": "REPARACIÓN DE OTRA FÍSTULA VAGINOENTÉRICA"
    },
    {
     "CATALOG_KEY": "7075",
     "PRO_NOMBRE": "REPARACIÓN DE OTRA FÍSTULA DE VAGINA"
    },
    {
     "CATALOG_KEY": "7076",
     "PRO_NOMBRE": "HIMENORRAFIA"
    },
    {
     "CATALOG_KEY": "7077",
     "PRO_NOMBRE": "SUSPENSIÓN Y FIJACIÓN DE VAGINA"
    },
    {
     "CATALOG_KEY": "7078",
     "PRO_NOMBRE": "SUSPENSIÓN Y FIJACIÓN VAGINAL CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "7079",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE VAGINA"
    },
    {
     "CATALOG_KEY": "708",
     "PRO_NOMBRE": "OBLITERACIÓN DE LA CÚPULA VAGINAL"
    },
    {
     "CATALOG_KEY": "708X",
     "PRO_NOMBRE": "OBLITERACIÓN DE LA CÚPULA VAGINAL"
    },
    {
     "CATALOG_KEY": "709",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA VAGINA Y FONDO DE SACO"
    },
    {
     "CATALOG_KEY": "7091",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA VAGINA"
    },
    {
     "CATALOG_KEY": "7092",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE FONDO DE SACO (VAGINA)"
    },
    {
     "CATALOG_KEY": "7093",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE FONDO DE SACO CON INJERTO O PRÓTESIS"
    },
    {
     "CATALOG_KEY": "7094",
     "PRO_NOMBRE": "INSERCIÓN DE INJERTO BIOLÓGICO"
    },
    {
     "CATALOG_KEY": "7095",
     "PRO_NOMBRE": "INSERCIÓN DE INJERTO SINTÉTICO O PRÓTESIS DE TEJIDO ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "71",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA VULVA Y EL PERINEO"
    },
    {
     "CATALOG_KEY": "710",
     "PRO_NOMBRE": "INCISIÓN DE VULVA Y PERINEO"
    },
    {
     "CATALOG_KEY": "7101",
     "PRO_NOMBRE": "LIBERACIÓN DE ADHERENCIAS VULVARES"
    },
    {
     "CATALOG_KEY": "7109",
     "PRO_NOMBRE": "OTRA INCISIÓN DE VULVA Y PERINEO"
    },
    {
     "CATALOG_KEY": "711",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA VULVA"
    },
    {
     "CATALOG_KEY": "7111",
     "PRO_NOMBRE": "BIOPSIA DE VULVA"
    },
    {
     "CATALOG_KEY": "7119",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE LA VULVA"
    },
    {
     "CATALOG_KEY": "712",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA GLÁNDULA DE BARTHOLIN"
    },
    {
     "CATALOG_KEY": "7121",
     "PRO_NOMBRE": "ASPIRACIÓN PERCUTÁNEA DE GLÁNDULA DE BARTHOLIN (QUISTE)"
    },
    {
     "CATALOG_KEY": "7122",
     "PRO_NOMBRE": "INCISIÓN DE GLÁNDULA DE BARTHOLIN (QUISTE)"
    },
    {
     "CATALOG_KEY": "7123",
     "PRO_NOMBRE": "MARSUPIALIZACIÓN DE GLÁNDULA DE BARTHOLIN (QUISTE)"
    },
    {
     "CATALOG_KEY": "7124",
     "PRO_NOMBRE": "EXCISIÓN U OTRA DESTRUCCIÓN DE GLÁNDULA DE BARTHOLIN (QUISTE)"
    },
    {
     "CATALOG_KEY": "7129",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LAS GLÁNDULAS DE BARTHOLIN"
    },
    {
     "CATALOG_KEY": "713",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LA VULVA Y EL PERINEO"
    },
    {
     "CATALOG_KEY": "713X",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LA VULVA Y EL PERINEO"
    },
    {
     "CATALOG_KEY": "714",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL CLÍTORIS"
    },
    {
     "CATALOG_KEY": "714X",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL CLÍTORIS"
    },
    {
     "CATALOG_KEY": "715",
     "PRO_NOMBRE": "VULVECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "715X",
     "PRO_NOMBRE": "VULVECTOMÍA RADICAL"
    },
    {
     "CATALOG_KEY": "716",
     "PRO_NOMBRE": "OTRA VULVECTOMÍA"
    },
    {
     "CATALOG_KEY": "7161",
     "PRO_NOMBRE": "VULVECTOMÍA UNILATERAL"
    },
    {
     "CATALOG_KEY": "7162",
     "PRO_NOMBRE": "VULVECTOMÍA BILATERAL"
    },
    {
     "CATALOG_KEY": "717",
     "PRO_NOMBRE": "REPARACIÓN DE LA VULVA O EL PERINEO"
    },
    {
     "CATALOG_KEY": "7171",
     "PRO_NOMBRE": "SUTURA DE DESGARRO DE LA VULVA O EL PERINEO ACTUAL"
    },
    {
     "CATALOG_KEY": "7172",
     "PRO_NOMBRE": "REPARACIÓN DE FÍSTULA DE VULVA O PERINEO"
    },
    {
     "CATALOG_KEY": "7179",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE LA VULVA Y EL PERINEO"
    },
    {
     "CATALOG_KEY": "718",
     "PRO_NOMBRE": "OTRAS REPARACIONES SOBRE VULVA"
    },
    {
     "CATALOG_KEY": "718X",
     "PRO_NOMBRE": "OTRAS REPARACIONES SOBRE VULVA"
    },
    {
     "CATALOG_KEY": "719",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LOS ÓRGANOS GENITALES FEMENINOS"
    },
    {
     "CATALOG_KEY": "719X",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LOS ÓRGANOS GENITALES FEMENINOS"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "PROCEDIMIENTOS OBSTÉTRICOS (72 - 75)"
    },
    {
     "CATALOG_KEY": "72",
     "PRO_NOMBRE": "FÓRCEPS, VENTOSA Y PARTO DE NALGAS"
    },
    {
     "CATALOG_KEY": "720",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS BAJO"
    },
    {
     "CATALOG_KEY": "720X",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS BAJO"
    },
    {
     "CATALOG_KEY": "721",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS BAJO CON EPISIOTOMÍA"
    },
    {
     "CATALOG_KEY": "721X",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS BAJO CON EPISIOTOMÍA"
    },
    {
     "CATALOG_KEY": "722",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS MEDIO"
    },
    {
     "CATALOG_KEY": "7221",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS MEDIO CON EPISIOTOMÍA"
    },
    {
     "CATALOG_KEY": "7229",
     "PRO_NOMBRE": "OTRA APLICACIÓN DE FÓRCEPS MEDIO"
    },
    {
     "CATALOG_KEY": "723",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS ALTO"
    },
    {
     "CATALOG_KEY": "7231",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS ALTO CON EPISIOTOMÍA"
    },
    {
     "CATALOG_KEY": "7239",
     "PRO_NOMBRE": "OTRA APLICACIÓN DE FÓRCEPS ALTO"
    },
    {
     "CATALOG_KEY": "724",
     "PRO_NOMBRE": "ROTACIÓN MEDIANTE FÓRCEPS DE LA CABEZA DEL FETO"
    },
    {
     "CATALOG_KEY": "724X",
     "PRO_NOMBRE": "ROTACIÓN MEDIANTE FÓRCEPS DE LA CABEZA DEL FETO"
    },
    {
     "CATALOG_KEY": "725",
     "PRO_NOMBRE": "EXTRACCIÓN CON PRESENTACIÓN DE NALGAS"
    },
    {
     "CATALOG_KEY": "7251",
     "PRO_NOMBRE": "EXTRACCIÓN PARCIAL CON PRESENTACIÓN DE NALGAS MEDIANTE APLICACIÓN DE FÓRCEPS EN CABEZA ÚLTIMA"
    },
    {
     "CATALOG_KEY": "7252",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN PARCIAL EN PRESENTACIÓN DE NALGAS"
    },
    {
     "CATALOG_KEY": "7253",
     "PRO_NOMBRE": "EXTRACCIÓN TOAL EN PRESENTACIÓN DE NALGAS CON APLICACIÓN DE FÓRCEPS EN CABEZA ÚLTIMA"
    },
    {
     "CATALOG_KEY": "7254",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN TOTAL CON PRESENTACIÓN DE NALGAS"
    },
    {
     "CATALOG_KEY": "726",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS A CABEZA ÚLTIMA"
    },
    {
     "CATALOG_KEY": "726X",
     "PRO_NOMBRE": "APLICACIÓN DE FÓRCEPS A CABEZA ÚLTIMA"
    },
    {
     "CATALOG_KEY": "727",
     "PRO_NOMBRE": "EXTRACCIÓN CON VENTOSA OBSTÉTRICA"
    },
    {
     "CATALOG_KEY": "7271",
     "PRO_NOMBRE": "EXTRACCIÓN MEDIANTE VENTOSA CON EPISIOTOMÍA"
    },
    {
     "CATALOG_KEY": "7279",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN MEDIANTE VENTOSA"
    },
    {
     "CATALOG_KEY": "728",
     "PRO_NOMBRE": "OTRO PARTO INSTRUMENTADO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "728X",
     "PRO_NOMBRE": "OTRO PARTO INSTRUMENTADO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "729",
     "PRO_NOMBRE": "PARTO INSTRUMENTADO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "729X",
     "PRO_NOMBRE": "PARTO INSTRUMENTADO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "73",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS PARA LA INDUCCIÓN O ASISTENCIA AL PARTO"
    },
    {
     "CATALOG_KEY": "730",
     "PRO_NOMBRE": "RUPTURA ARTIFICIAL DE LAS MEMBRANAS"
    },
    {
     "CATALOG_KEY": "7301",
     "PRO_NOMBRE": "INDUCCIÓN DEL PARTO POR RUPTURA ARTIFICIAL DE LAS MEMBRANAS"
    },
    {
     "CATALOG_KEY": "7309",
     "PRO_NOMBRE": "OTRA RUPTURA ARTIFICIAL DE LAS MEMBRANAS"
    },
    {
     "CATALOG_KEY": "731",
     "PRO_NOMBRE": "OTRA INDUCCIÓN QUIRÚRGICA DEL PARTO"
    },
    {
     "CATALOG_KEY": "731X",
     "PRO_NOMBRE": "OTRA INDUCCIÓN QUIRÚRGICA DEL PARTO"
    },
    {
     "CATALOG_KEY": "732",
     "PRO_NOMBRE": "VERSIÓN INTERNA Y COMBINADA Y EXTRACCIÓN"
    },
    {
     "CATALOG_KEY": "7321",
     "PRO_NOMBRE": "VERSIÓN INTERNA Y COMBINADA SIN EXTRACCIÓN"
    },
    {
     "CATALOG_KEY": "7322",
     "PRO_NOMBRE": "VERSIÓN INTERNA Y COMBINADA CON EXTRACCIÓN"
    },
    {
     "CATALOG_KEY": "733",
     "PRO_NOMBRE": "OPERACIÓN DE FÓRCEPS FALLIDA"
    },
    {
     "CATALOG_KEY": "733X",
     "PRO_NOMBRE": "OPERACIÓN DE FÓRCEPS FALLIDA"
    },
    {
     "CATALOG_KEY": "734",
     "PRO_NOMBRE": "INDUCCIÓN MÉDICA DE PARTO"
    },
    {
     "CATALOG_KEY": "734X",
     "PRO_NOMBRE": "INDUCCIÓN MÉDICA DE PARTO"
    },
    {
     "CATALOG_KEY": "735",
     "PRO_NOMBRE": "PARTO ASISTIDO MANUALMENTE"
    },
    {
     "CATALOG_KEY": "7351",
     "PRO_NOMBRE": "ROTACIÓN MANUAL DE LA CABEZA DE FETO"
    },
    {
     "CATALOG_KEY": "7359",
     "PRO_NOMBRE": "OTRO PARTO ASISTIDO MANUALMENTE"
    },
    {
     "CATALOG_KEY": "736",
     "PRO_NOMBRE": "EPISIOTOMÍA"
    },
    {
     "CATALOG_KEY": "736X",
     "PRO_NOMBRE": "EPISIOTOMÍA"
    },
    {
     "CATALOG_KEY": "738",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL FETO PARA FACILITAR EL PERÍODO EXPULSIVO"
    },
    {
     "CATALOG_KEY": "738X",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL FETO PARA FACILITAR EL PERÍODO EXPULSIVO"
    },
    {
     "CATALOG_KEY": "739",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE ASISTENCIA AL PERÍODO EXPULSIVO"
    },
    {
     "CATALOG_KEY": "7391",
     "PRO_NOMBRE": "VERSIÓN EXTERNA"
    },
    {
     "CATALOG_KEY": "7392",
     "PRO_NOMBRE": "SUSTITUCIÓN DEL CORDÓN UMBILICAL PROLAPSADO"
    },
    {
     "CATALOG_KEY": "7393",
     "PRO_NOMBRE": "INCISIÓN DEL CUELLO UTERINO PARA AYUDAR AL PERÍODO EXPULSIVO"
    },
    {
     "CATALOG_KEY": "7394",
     "PRO_NOMBRE": "PUBIOTOMÍA PARA ASISTIR AL PARTO"
    },
    {
     "CATALOG_KEY": "7399",
     "PRO_NOMBRE": "OTRA OPERACIÓN DE ASISTENCIA AL PERÍODO EXPULSIVO"
    },
    {
     "CATALOG_KEY": "74",
     "PRO_NOMBRE": "CESÁREA Y EXTRACCIÓN DE FETO"
    },
    {
     "CATALOG_KEY": "740",
     "PRO_NOMBRE": "CESÁREA CLÁSICA"
    },
    {
     "CATALOG_KEY": "740X",
     "PRO_NOMBRE": "CESÁREA CLÁSICA"
    },
    {
     "CATALOG_KEY": "741",
     "PRO_NOMBRE": "CESÁREA CLÁSICA BAJA"
    },
    {
     "CATALOG_KEY": "741X",
     "PRO_NOMBRE": "CESÁREA CLÁSICA BAJA"
    },
    {
     "CATALOG_KEY": "742",
     "PRO_NOMBRE": "CESÁREA EXTRAPERITONEAL"
    },
    {
     "CATALOG_KEY": "742X",
     "PRO_NOMBRE": "CESÁREA EXTRAPERITONEAL"
    },
    {
     "CATALOG_KEY": "743",
     "PRO_NOMBRE": "EXTRACCIÓN DE EMBRIÓN INTRAPERITONEAL"
    },
    {
     "CATALOG_KEY": "743X",
     "PRO_NOMBRE": "EXTRACCIÓN DE EMBRIÓN INTRAPERITONEAL"
    },
    {
     "CATALOG_KEY": "744",
     "PRO_NOMBRE": "CESÁREA DE OTRO TIPO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "744X",
     "PRO_NOMBRE": "CESÁREA DE OTRO TIPO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "749",
     "PRO_NOMBRE": "CESÁREA DE TIPO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7491",
     "PRO_NOMBRE": "HISTEROTOMÍA PARA TERMINAR EL EMBARAZO"
    },
    {
     "CATALOG_KEY": "7499",
     "PRO_NOMBRE": "OTRA CESÁREA DE TIPO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "75",
     "PRO_NOMBRE": "OTRAS OPERACIONES OBSTÉTRICAS"
    },
    {
     "CATALOG_KEY": "750",
     "PRO_NOMBRE": "INYECCIÓN INTRA-AMNIÓTICA PARA INDUCIR EL ABORTO"
    },
    {
     "CATALOG_KEY": "750X",
     "PRO_NOMBRE": "INYECCIÓN INTRA-AMNIÓTICA PARA INDUCIR EL ABORTO"
    },
    {
     "CATALOG_KEY": "751",
     "PRO_NOMBRE": "AMNIOCENTESIS DIAGNÓSTICA"
    },
    {
     "CATALOG_KEY": "751X",
     "PRO_NOMBRE": "AMNIOCENTESIS DIAGNÓSTICA"
    },
    {
     "CATALOG_KEY": "752",
     "PRO_NOMBRE": "TRANSFUSIÓN INTRAUTERINA"
    },
    {
     "CATALOG_KEY": "752X",
     "PRO_NOMBRE": "TRANSFUSIÓN INTRAUTERINA"
    },
    {
     "CATALOG_KEY": "753",
     "PRO_NOMBRE": "OTRAS OPERACIONES INTRAUTERINAS SOBRE EL FETO Y EL AMNIOS"
    },
    {
     "CATALOG_KEY": "7531",
     "PRO_NOMBRE": "AMNIOSCOPIA"
    },
    {
     "CATALOG_KEY": "7532",
     "PRO_NOMBRE": "ELECTROCARDIOGRAMA FETAL (CUERO CABELLUDO)"
    },
    {
     "CATALOG_KEY": "7533",
     "PRO_NOMBRE": "BIOPSIA Y TOMA DE SANGRE FETAL"
    },
    {
     "CATALOG_KEY": "7534",
     "PRO_NOMBRE": "OTRA MONITORIZACIÓN FETAL"
    },
    {
     "CATALOG_KEY": "7535",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE EL FETO Y AMNIOS"
    },
    {
     "CATALOG_KEY": "7536",
     "PRO_NOMBRE": "CORRECCIÓN DE DEFECTO FETAL"
    },
    {
     "CATALOG_KEY": "7537",
     "PRO_NOMBRE": "AMNIOINFUSIÓN"
    },
    {
     "CATALOG_KEY": "7538",
     "PRO_NOMBRE": "PULSIOXIMETRIA FETAL"
    },
    {
     "CATALOG_KEY": "754",
     "PRO_NOMBRE": "EXTRACCIÓN MANUAL DE PLACENTA RETENIDA"
    },
    {
     "CATALOG_KEY": "754X",
     "PRO_NOMBRE": "EXTRACCIÓN MANUAL DE PLACENTA RETENIDA"
    },
    {
     "CATALOG_KEY": "755",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO OBSTÉTRICO ACTUAL DEL ÚTERO"
    },
    {
     "CATALOG_KEY": "7550",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO OBSTÉTRICO ACTUAL DEL ÚTERO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "7551",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO OBSTÉTRICO ACTUAL DEL CUELLO UTERINO"
    },
    {
     "CATALOG_KEY": "7552",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO OBSTÉTRICO ACTUAL DEL CUERPO UTERINO"
    },
    {
     "CATALOG_KEY": "756",
     "PRO_NOMBRE": "REPARACIÓN DE OTRO DESGARRO OBSTÉTRICO ACTUAL"
    },
    {
     "CATALOG_KEY": "7561",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO OBSTÉTRICO ACTUAL DE VEJIGA Y URETRA"
    },
    {
     "CATALOG_KEY": "7562",
     "PRO_NOMBRE": "REPARACIÓN DE DESGARRO OBSTÉTRICO ACTUAL DE RECTO Y ESFÍNTER ANAL"
    },
    {
     "CATALOG_KEY": "7569",
     "PRO_NOMBRE": "REPARACIÓN DE OTRO DESGARRO OBSTÉTRICO ACTUAL"
    },
    {
     "CATALOG_KEY": "757",
     "PRO_NOMBRE": "EXPLORACIÓN MANUAL DE LA CAVIDAD UTERINA, DESPUÉS DEL PARTO"
    },
    {
     "CATALOG_KEY": "757X",
     "PRO_NOMBRE": "EXPLORACIÓN MANUAL DE LA CAVIDAD UTERINA, DESPUÉS DEL PARTO"
    },
    {
     "CATALOG_KEY": "758",
     "PRO_NOMBRE": "TAPONAMIENTO OBSTÉTRICO DE ÚTERO O VAGINA"
    },
    {
     "CATALOG_KEY": "758X",
     "PRO_NOMBRE": "TAPONAMIENTO OBSTÉTRICO DE ÚTERO O VAGINA"
    },
    {
     "CATALOG_KEY": "759",
     "PRO_NOMBRE": "OTRAS OPERACIONES OBSTÉTRICAS"
    },
    {
     "CATALOG_KEY": "7591",
     "PRO_NOMBRE": "EVACUACIÓN DE HEMATOMA POR INCISIÓN OBSTÉTRICA DE PERINEO"
    },
    {
     "CATALOG_KEY": "7592",
     "PRO_NOMBRE": "EVACUACIÓN DE OTRO HEMATOMA DE VULVA O VAGINA"
    },
    {
     "CATALOG_KEY": "7593",
     "PRO_NOMBRE": "CORRECCIÓN QUIRÚRGICA DE ÚTERO INVERTIDO"
    },
    {
     "CATALOG_KEY": "7594",
     "PRO_NOMBRE": "REPOSICIÓN MANUAL DE ÚTERO INVERTIDO"
    },
    {
     "CATALOG_KEY": "7599",
     "PRO_NOMBRE": "OTRAS OPERACIONES OBSTÉTRICAS"
    },
    {
     "CATALOG_KEY": "75A",
     "PRO_NOMBRE": "ADMINISTRACIÓN ORAL DE MEDICAMENTOS PARA TERMINACIÓN DE EMBARAZO"
    },
    {
     "CATALOG_KEY": "75A1",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE MISOPROSTOL PARA INTERRUPCIÓN LEGAL DEL EMBARAZO (ILE)"
    },
    {
     "CATALOG_KEY": "75A2",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE MISOPROSTOL PARA TERMINACIÓN DEL ABORTO INCOMPLETO"
    },
    {
     "CATALOG_KEY": "75A3",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE OTRO MEDICAMENTO PARA INTERRUPCIÓN LEGAL DEL EMBARAZO (ILE)"
    },
    {
     "CATALOG_KEY": "75A4",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE OTRO MEDICAMENTO PARA TERMINACIÓN DEL ABORTO INCOMPLETO"
    },
    {
     "CATALOG_KEY": "73A",
     "PRO_NOMBRE": "PROCEDIMIENTOS QUE REDUCEN LA MORBILIDAD Y LA MORTALIDAD MATERNO-NEONATAL AL NACIMIENTO"
    },
    {
     "CATALOG_KEY": "73A1",
     "PRO_NOMBRE": "ATENCIÓN DEL PERIODO EXPULSIVO DEL PARTO EN POSICIÓN VERTICAL"
    },
    {
     "CATALOG_KEY": "73A2",
     "PRO_NOMBRE": "ACOMPAÑAMIENTO PSICOEMOCIONAL CONTINUO DURANTE TODO EL TRABAJO DE PARTO"
    },
    {
     "CATALOG_KEY": "73A3",
     "PRO_NOMBRE": "APEGO INMEDIATO MADRE Y NEONATO"
    },
    {
     "CATALOG_KEY": "73A4",
     "PRO_NOMBRE": "MANEJO ACTIVO DE TERCER PERIODO DEL TRABAJO DE PARTO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL APARATO MUSCULOESQUELÉTICO (76 - 84)"
    },
    {
     "CATALOG_KEY": "76",
     "PRO_NOMBRE": "OPERACIONES SOBRE HUESOS Y ARTICULACIONES FACIALES"
    },
    {
     "CATALOG_KEY": "760",
     "PRO_NOMBRE": "INCISIÓN DE HUESO FACIAL SIN DIVISIÓN"
    },
    {
     "CATALOG_KEY": "7601",
     "PRO_NOMBRE": "SECUESTRECTOMÍA DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "7609",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "761",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESOS Y ARTICULACIONES FACIALES"
    },
    {
     "CATALOG_KEY": "7611",
     "PRO_NOMBRE": "BIOPSIA DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "7619",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS EN HUESO Y ARTICULACIONES FACIALES"
    },
    {
     "CATALOG_KEY": "762",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIONES DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "762X",
     "PRO_NOMBRE": "EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIONES DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "763",
     "PRO_NOMBRE": "OSTECTOMÍA PARCIAL DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "7631",
     "PRO_NOMBRE": "MANDIBULECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "7639",
     "PRO_NOMBRE": "OSTECTOMÍA, EXÉRESIS O EXCISIÓN PARCIAL DE OTRO HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "764",
     "PRO_NOMBRE": "EXCISIÓN Y RECONSTRUCCIÓN DE HUESOS FACIALES"
    },
    {
     "CATALOG_KEY": "7641",
     "PRO_NOMBRE": "MANDIBULECTOMÍA TOTAL CON RECONSTRUCCIÓN SIMULTÁNEA"
    },
    {
     "CATALOG_KEY": "7642",
     "PRO_NOMBRE": "OTRA MANDIBULECTOMÍA TOTAL"
    },
    {
     "CATALOG_KEY": "7643",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE MANDÍBULA"
    },
    {
     "CATALOG_KEY": "7644",
     "PRO_NOMBRE": "OSTECTOMÍA TOTAL DE OTRO HUESO FACIAL CON RECONSTRUCCIÓN SIMULTÁNEA"
    },
    {
     "CATALOG_KEY": "7645",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA TOTAL DE OTRO HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "7646",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE OTRO HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "765",
     "PRO_NOMBRE": "ARTROPLASTIA TEMPOROMANDIBULAR"
    },
    {
     "CATALOG_KEY": "765X",
     "PRO_NOMBRE": "ARTROPLASTIA TEMPOROMANDIBULAR"
    },
    {
     "CATALOG_KEY": "766",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE HUESO FACIAL Y CIRUGÍA ORTOGNÁTICA"
    },
    {
     "CATALOG_KEY": "7661",
     "PRO_NOMBRE": "OSTEOPLASTIA CERRADA [OSTEOTOMÍA] DE RAMA MANDIBULAR"
    },
    {
     "CATALOG_KEY": "7662",
     "PRO_NOMBRE": "OSTEOPLASTIA ABIERTA [OSTEOTOMÍA] DE RAMA MANDIBULAR"
    },
    {
     "CATALOG_KEY": "7663",
     "PRO_NOMBRE": "OSTEOPLASTIA [OSTEOTOMÍA] DE CUERPO DE MANDÍBULA"
    },
    {
     "CATALOG_KEY": "7664",
     "PRO_NOMBRE": "OTRA CIRUGÍA ORTOGNÁTICA SOBRE MANDÍBULA"
    },
    {
     "CATALOG_KEY": "7665",
     "PRO_NOMBRE": "OSTEOPLASTIA [OSTEOTOMÍA] SEGMENTARIA DE MAXILAR"
    },
    {
     "CATALOG_KEY": "7666",
     "PRO_NOMBRE": "OSTEOPLASTIA TOTAL [OSTEOTOMÍA] DE MAXILIAR"
    },
    {
     "CATALOG_KEY": "7667",
     "PRO_NOMBRE": "GENIOPLASTIA DE REDUCCIÓN"
    },
    {
     "CATALOG_KEY": "7668",
     "PRO_NOMBRE": "GENIOPLASTIA DE AUMENTO"
    },
    {
     "CATALOG_KEY": "7669",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "767",
     "PRO_NOMBRE": "REDUCCIÓN DE FRACTURA FACIAL"
    },
    {
     "CATALOG_KEY": "7670",
     "PRO_NOMBRE": "REDUCCIÓN DE FRACTURA FACIAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "7671",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA MALAR Y CIGOMÁTICA"
    },
    {
     "CATALOG_KEY": "7672",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA MALAR O CIGOMÁTICA"
    },
    {
     "CATALOG_KEY": "7673",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA MAXILAR"
    },
    {
     "CATALOG_KEY": "7674",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA MAXILAR"
    },
    {
     "CATALOG_KEY": "7675",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA MANDIBULAR"
    },
    {
     "CATALOG_KEY": "7676",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA MANDIBULAR"
    },
    {
     "CATALOG_KEY": "7677",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA ALVEOLAR"
    },
    {
     "CATALOG_KEY": "7678",
     "PRO_NOMBRE": "OTRA REDUCCIÓN CERRADA DE FRACTURA FACIAL"
    },
    {
     "CATALOG_KEY": "7679",
     "PRO_NOMBRE": "OTRA REDUCCIÓN ABIERTA DE FRACTURA FACIAL"
    },
    {
     "CATALOG_KEY": "769",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE HUESO Y ARTICULACIONES FACIALES"
    },
    {
     "CATALOG_KEY": "7691",
     "PRO_NOMBRE": "INJERTO ÓSEO A HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "7692",
     "PRO_NOMBRE": "INSERCIÓN DE IMPLANTE SINTÉTICO EN HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "7693",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN TEMPOROMANDIBULAR"
    },
    {
     "CATALOG_KEY": "7694",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE DISLOCACIÓN TEMPOROMANDIBULAR"
    },
    {
     "CATALOG_KEY": "7695",
     "PRO_NOMBRE": "OTRA MANIPULACIÓN DE ARTICULACIÓN TEMPOROMANDIBULAR"
    },
    {
     "CATALOG_KEY": "7696",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN ARTICULACIÓN TEMPOROMANDIBULAR"
    },
    {
     "CATALOG_KEY": "7697",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVO DE FIJACIÓN INTERNA DE HUESO FACIAL"
    },
    {
     "CATALOG_KEY": "7699",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE HUESOS Y ARTICULACIONES FACIALES"
    },
    {
     "CATALOG_KEY": "77",
     "PRO_NOMBRE": "INCISIÓN, EXCISIÓN Y DIVISIÓN DE OTROS HUESOS"
    },
    {
     "CATALOG_KEY": "770",
     "PRO_NOMBRE": "SECUESTRECTOMÍA"
    },
    {
     "CATALOG_KEY": "7700",
     "PRO_NOMBRE": "SECUESTRECTOMÍA, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7701",
     "PRO_NOMBRE": "SECUESTRECTOMÍA ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7702",
     "PRO_NOMBRE": "SECUESTRECTOMÍA HÚMERO"
    },
    {
     "CATALOG_KEY": "7703",
     "PRO_NOMBRE": "SECUESTRECTOMÍA RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7704",
     "PRO_NOMBRE": "SECUESTRECTOMÍA CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7705",
     "PRO_NOMBRE": "SECUESTRECTOMÍA FÉMUR"
    },
    {
     "CATALOG_KEY": "7706",
     "PRO_NOMBRE": "SECUESTRECTOMÍA RÓTULA"
    },
    {
     "CATALOG_KEY": "7707",
     "PRO_NOMBRE": "SECUESTRECTOMÍA TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7708",
     "PRO_NOMBRE": "SECUESTRECTOMÍA TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7709",
     "PRO_NOMBRE": "SECUESTRECTOMÍA, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "771",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN"
    },
    {
     "CATALOG_KEY": "7710",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7711",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN.ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7712",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN. HÚMERO"
    },
    {
     "CATALOG_KEY": "7713",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7714",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7715",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN. FÉMUR"
    },
    {
     "CATALOG_KEY": "7716",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN. RÓTULA"
    },
    {
     "CATALOG_KEY": "7717",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7718",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN. TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7719",
     "PRO_NOMBRE": "OTRA INCISIÓN DE HUESO SIN DIVISIÓN, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "772",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA"
    },
    {
     "CATALOG_KEY": "7720",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7721",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7722",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, HÚMERO"
    },
    {
     "CATALOG_KEY": "7723",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7724",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7725",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, FÉMUR"
    },
    {
     "CATALOG_KEY": "7726",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, RÓTULA"
    },
    {
     "CATALOG_KEY": "7727",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7728",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7729",
     "PRO_NOMBRE": "OSTEOTOMÍA EN CUÑA, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "773",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO"
    },
    {
     "CATALOG_KEY": "7730",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7731",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7732",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, HÚMERO"
    },
    {
     "CATALOG_KEY": "7733",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7734",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, CARPIANOS Y METARCAPIANOS"
    },
    {
     "CATALOG_KEY": "7735",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, FÉMUR"
    },
    {
     "CATALOG_KEY": "7736",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, RÓTULA"
    },
    {
     "CATALOG_KEY": "7737",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7738",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7739",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE HUESO, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "774",
     "PRO_NOMBRE": "BIOPSIA DE HUESO"
    },
    {
     "CATALOG_KEY": "7740",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7741",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7742",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, HÚMERO"
    },
    {
     "CATALOG_KEY": "7743",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7744",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7745",
     "PRO_NOMBRE": "BIOPSIA DE HUES, FÉMUR"
    },
    {
     "CATALOG_KEY": "7746",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, RÓTULA"
    },
    {
     "CATALOG_KEY": "7747",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7748",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7749",
     "PRO_NOMBRE": "BIOPSIA DE HUESO, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "775",
     "PRO_NOMBRE": "EXCISIÓN Y REPARACIÓN DE HALLUX VALGUS (JUANETE) Y DE OTRAS DEFORMACIONES DE LOS DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "7751",
     "PRO_NOMBRE": "BUNIONECTOMÍA CON CORRECCIÓN DE TEJIDO BLANDO Y OSTEOTOMÍA DEL PRIMER METATARSIANO"
    },
    {
     "CATALOG_KEY": "7752",
     "PRO_NOMBRE": "BUNIONECTOMÍA CON CORRECCIÓN DE TEJIDO BLANDO Y ARTRODESIS"
    },
    {
     "CATALOG_KEY": "7753",
     "PRO_NOMBRE": "OTRA BUNIONECTOMÍA CON CORRECCIÓN DE TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "7754",
     "PRO_NOMBRE": "EXCISIÓN O CORRECCIÓN DE HALLUX VALGUS"
    },
    {
     "CATALOG_KEY": "7756",
     "PRO_NOMBRE": "REPARACIÓN DE DEDO DE PIE EN MARTILLO"
    },
    {
     "CATALOG_KEY": "7757",
     "PRO_NOMBRE": "REPARACIÓN DE DEDO DE PIE EN GARRA"
    },
    {
     "CATALOG_KEY": "7758",
     "PRO_NOMBRE": "OTRA EXCISIÓN, FUSIÓN Y REPARACIÓN DE DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "7759",
     "PRO_NOMBRE": "OTRA BUNIONECTOMÍA"
    },
    {
     "CATALOG_KEY": "776",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO"
    },
    {
     "CATALOG_KEY": "7760",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7761",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7762",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO HÚMERO"
    },
    {
     "CATALOG_KEY": "7763",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7764",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7765",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO FÉMUR"
    },
    {
     "CATALOG_KEY": "7766",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO RÓTULA"
    },
    {
     "CATALOG_KEY": "7767",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7768",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7769",
     "PRO_NOMBRE": "EXCISIÓN LOCAL DE LESIÓN O TEJIDO DE HUESO, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "777",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO"
    },
    {
     "CATALOG_KEY": "7770",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7771",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7772",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, HÚMERO"
    },
    {
     "CATALOG_KEY": "7773",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7774",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7775",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, FÉMUR"
    },
    {
     "CATALOG_KEY": "7776",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, RÓTULA"
    },
    {
     "CATALOG_KEY": "7777",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7778",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7779",
     "PRO_NOMBRE": "EXCISIÓN DE HUESO PARA INJERTO, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "778",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA PARCIAL"
    },
    {
     "CATALOG_KEY": "7780",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7781",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7782",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, HÚMERO"
    },
    {
     "CATALOG_KEY": "7783",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7784",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7785",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, FÉMUR"
    },
    {
     "CATALOG_KEY": "7786",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, RÓTULA"
    },
    {
     "CATALOG_KEY": "7787",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7788",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7789",
     "PRO_NOMBRE": "OTRA OSTECTOMÍA, EXÉRESIS O EXCISIÓN ÓSEA PARCIAL, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "779",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL"
    },
    {
     "CATALOG_KEY": "7790",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7791",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7792",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, HÚMERO"
    },
    {
     "CATALOG_KEY": "7793",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7794",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7795",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, FÉMUR"
    },
    {
     "CATALOG_KEY": "7796",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, RÓTULA"
    },
    {
     "CATALOG_KEY": "7797",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7798",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7799",
     "PRO_NOMBRE": "EXÉRESIS O EXCISIÓN ÓSEA TOTAL, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "78",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE HUESOS, SALVO HUESOS FACIALES"
    },
    {
     "CATALOG_KEY": "780",
     "PRO_NOMBRE": "INJERTO DE HUESO"
    },
    {
     "CATALOG_KEY": "7800",
     "PRO_NOMBRE": "INJERTO DE HUESO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7801",
     "PRO_NOMBRE": "INJERTO DE HUESO, SSCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7802",
     "PRO_NOMBRE": "INJERTO DE HUESO, HÚMERO"
    },
    {
     "CATALOG_KEY": "7803",
     "PRO_NOMBRE": "INJERTO DE HUESO RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7804",
     "PRO_NOMBRE": "INJERTO DE HUESO, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7805",
     "PRO_NOMBRE": "INJERTO DE HUESO, FÉMUR"
    },
    {
     "CATALOG_KEY": "7806",
     "PRO_NOMBRE": "INJERTO DE HUESO, RÓTULA"
    },
    {
     "CATALOG_KEY": "7807",
     "PRO_NOMBRE": "INJERTO DE HUESO, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7808",
     "PRO_NOMBRE": "INJERTO DE HUESO, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7809",
     "PRO_NOMBRE": "INJERTO DE HUESO, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "781",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO"
    },
    {
     "CATALOG_KEY": "7810",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7811",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7812",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO HÚMERO"
    },
    {
     "CATALOG_KEY": "7813",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7814",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7815",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO FÉMUR"
    },
    {
     "CATALOG_KEY": "7816",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO RÓTULA"
    },
    {
     "CATALOG_KEY": "7817",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7818",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7819",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO, OTRO SITIO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "782",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES"
    },
    {
     "CATALOG_KEY": "7820",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7822",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES HÚMERO"
    },
    {
     "CATALOG_KEY": "7823",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7824",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7825",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES FÉMUR"
    },
    {
     "CATALOG_KEY": "7827",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7828",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7829",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ACORTAMIENTO DE EXTREMIDADES, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "783",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES"
    },
    {
     "CATALOG_KEY": "7830",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7832",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES, HÚMERO"
    },
    {
     "CATALOG_KEY": "7833",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7834",
     "PRO_NOMBRE": "PROCEDIMIENTOS DE ALARGAMIENTO DE EXTREMIDADES CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7835",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES, FÉMUR"
    },
    {
     "CATALOG_KEY": "7837",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7838",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7839",
     "PRO_NOMBRE": "PROCEDIMIENTO DE ALARGAMIENTO DE EXTREMIDADES, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "784",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO"
    },
    {
     "CATALOG_KEY": "7840",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7841",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7842",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, HÚMERO"
    },
    {
     "CATALOG_KEY": "7843",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7844",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7845",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, FÉMUR"
    },
    {
     "CATALOG_KEY": "7846",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, RÓTULA"
    },
    {
     "CATALOG_KEY": "7847",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7848",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7849",
     "PRO_NOMBRE": "OTRA REPARACIÓN U OPERACIONES PLÁSTICAS EN EL HUESO, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "785",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA"
    },
    {
     "CATALOG_KEY": "7850",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7851",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7852",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, HÚMERO"
    },
    {
     "CATALOG_KEY": "7853",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7854",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7855",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, FÉMUR"
    },
    {
     "CATALOG_KEY": "7856",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, RÓTULA"
    },
    {
     "CATALOG_KEY": "7857",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7858",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7859",
     "PRO_NOMBRE": "FIJACIÓN INTERNA DE HUESO SIN REDUCCIÓN DE FRACTURA, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "786",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO"
    },
    {
     "CATALOG_KEY": "7860",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7861",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7862",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, HÚMERO"
    },
    {
     "CATALOG_KEY": "7863",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7864",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7865",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, FÉMUR"
    },
    {
     "CATALOG_KEY": "7866",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, RÓTULA"
    },
    {
     "CATALOG_KEY": "7867",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7868",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7869",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVOS IMPLANTADOS EN EL HUESO, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "787",
     "PRO_NOMBRE": "OSTEOCLASIA"
    },
    {
     "CATALOG_KEY": "7870",
     "PRO_NOMBRE": "OSTEOCLASIA, SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7871",
     "PRO_NOMBRE": "OSTEOCLASIA. ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7872",
     "PRO_NOMBRE": "OSTEOCLASIA, HÚMERO"
    },
    {
     "CATALOG_KEY": "7873",
     "PRO_NOMBRE": "OSTEOCLASIA. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7874",
     "PRO_NOMBRE": "OSTEOCLASIA. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7875",
     "PRO_NOMBRE": "OSTEOCLASIA. FÉMUR"
    },
    {
     "CATALOG_KEY": "7876",
     "PRO_NOMBRE": "OSTEOCLASIA. RÓTULA"
    },
    {
     "CATALOG_KEY": "7877",
     "PRO_NOMBRE": "OSTEOCLASIA. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7878",
     "PRO_NOMBRE": "OSTEOCLASIA. TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7879",
     "PRO_NOMBRE": "OSTEOCLASIA, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "788",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
     "CATALOG_KEY": "7880",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7881",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7882",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE, HÚMERO"
    },
    {
     "CATALOG_KEY": "7883",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE, RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7884",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE, CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7885",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE. FÉMUR"
    },
    {
     "CATALOG_KEY": "7886",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE. RÓTULA"
    },
    {
     "CATALOG_KEY": "7887",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE, TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7888",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE, TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7889",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE HUESO, NO CLASIFICADOS EN OTRA PARTE, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "789",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO"
    },
    {
     "CATALOG_KEY": "7890",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7891",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. ESCÁPULA, CLAVÍCULA Y TÓRAX [COSTILLAS Y ESTERNÓN]"
    },
    {
     "CATALOG_KEY": "7892",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. HÚMERO"
    },
    {
     "CATALOG_KEY": "7893",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7894",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7895",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. FÉMUR"
    },
    {
     "CATALOG_KEY": "7896",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. RÓTULA"
    },
    {
     "CATALOG_KEY": "7897",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7898",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO. TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7899",
     "PRO_NOMBRE": "INSERCIÓN DE ESTIMULADOR DEL CRECIMIENTO ÓSEO, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "79",
     "PRO_NOMBRE": "REDUCCIÓN DE FRACTURA Y LUXACIÓN"
    },
    {
     "CATALOG_KEY": "790",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA"
    },
    {
     "CATALOG_KEY": "7900",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7901",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA HÚMERO"
    },
    {
     "CATALOG_KEY": "7902",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7903",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7904",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA FALANGES Y MANO"
    },
    {
     "CATALOG_KEY": "7905",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA FÉMUR"
    },
    {
     "CATALOG_KEY": "7906",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7907",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7908",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA FALANGES Y PIE"
    },
    {
     "CATALOG_KEY": "7909",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA SIN FIJACIÓN INTERNA OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "791",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA"
    },
    {
     "CATALOG_KEY": "7910",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7911",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA.HÚMERO"
    },
    {
     "CATALOG_KEY": "7912",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA.RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7913",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7914",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. FALANGES Y MANO"
    },
    {
     "CATALOG_KEY": "7915",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. FÉMUR"
    },
    {
     "CATALOG_KEY": "7916",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7917",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7918",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. FALANGES Y PIE"
    },
    {
     "CATALOG_KEY": "7919",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE FRACTURA CON FIJACIÓN INTERNA. OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "792",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA"
    },
    {
     "CATALOG_KEY": "7920",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7921",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA.HÚMERO"
    },
    {
     "CATALOG_KEY": "7922",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7923",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7924",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA. FALANGES Y MANO"
    },
    {
     "CATALOG_KEY": "7925",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA. FÉMUR"
    },
    {
     "CATALOG_KEY": "7926",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA.TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7927",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA. TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7928",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA. SIN FIJACIÓN INTERNA. FALANGES Y PIE"
    },
    {
     "CATALOG_KEY": "7929",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA SIN FIJACIÓN INTERNA. OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "793",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA"
    },
    {
     "CATALOG_KEY": "7930",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7931",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA .HÚMERO"
    },
    {
     "CATALOG_KEY": "7932",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7933",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7934",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. FALANGES Y MANO"
    },
    {
     "CATALOG_KEY": "7935",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. FÉMUR"
    },
    {
     "CATALOG_KEY": "7936",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7937",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7938",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. FALANGES Y PIE"
    },
    {
     "CATALOG_KEY": "7939",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE FRACTURA CON FIJACIÓN INTERNA. OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "794",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE EPÍFISIS SEPARADA"
    },
    {
     "CATALOG_KEY": "7940",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE EPÍFISIS SEPARADA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7941",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE EPÍFISIS SEPARADA. HÚMERO"
    },
    {
     "CATALOG_KEY": "7942",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE EPÍFISIS SEPARADA. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7945",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE EPÍFISIS SEPARADA. FÉMUR"
    },
    {
     "CATALOG_KEY": "7946",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE EPÍFISIS SEPARADA. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7949",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE EPÍFISIS SEPARADA. OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "795",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE EPÍFISIS SEPARADA"
    },
    {
     "CATALOG_KEY": "7950",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE EPÍFISIS SEPARADA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7951",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE EPÍFISIS SEPARADA. HÚMERO"
    },
    {
     "CATALOG_KEY": "7952",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE EPÍFISIS SEPARADA. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7955",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE EPÍFISIS SEPARADA. FÉMUR"
    },
    {
     "CATALOG_KEY": "7956",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE EPÍFISIS SEPARADA. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7959",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE EPÍFISIS SEPARADA. OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "796",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA"
    },
    {
     "CATALOG_KEY": "7960",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7961",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. HÚMERO"
    },
    {
     "CATALOG_KEY": "7962",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7963",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7964",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. FALANGES Y MANO"
    },
    {
     "CATALOG_KEY": "7965",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. FÉMUR"
    },
    {
     "CATALOG_KEY": "7966",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7967",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7968",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. FALANGES Y PIE"
    },
    {
     "CATALOG_KEY": "7969",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE SITIO DE FRACTURA ABIERTA. OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "797",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN"
    },
    {
     "CATALOG_KEY": "7970",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7971",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE HOMBRO"
    },
    {
     "CATALOG_KEY": "7972",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE CODO"
    },
    {
     "CATALOG_KEY": "7973",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE MUÑECA"
    },
    {
     "CATALOG_KEY": "7974",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE MANO Y DEDO DE MANO"
    },
    {
     "CATALOG_KEY": "7975",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE CADERA"
    },
    {
     "CATALOG_KEY": "7976",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE RODILLA"
    },
    {
     "CATALOG_KEY": "7977",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE TOBILLO"
    },
    {
     "CATALOG_KEY": "7978",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE PIE Y DEDO DE PIE"
    },
    {
     "CATALOG_KEY": "7979",
     "PRO_NOMBRE": "REDUCCIÓN CERRADA DE LUXACIÓN DE OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "798",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN"
    },
    {
     "CATALOG_KEY": "7980",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7981",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE HOMBRO"
    },
    {
     "CATALOG_KEY": "7982",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE CODO"
    },
    {
     "CATALOG_KEY": "7983",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE MUÑECA"
    },
    {
     "CATALOG_KEY": "7984",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE MANO Y DEDO DE MANO"
    },
    {
     "CATALOG_KEY": "7985",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE CADERA"
    },
    {
     "CATALOG_KEY": "7986",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE RODILLA"
    },
    {
     "CATALOG_KEY": "7987",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE TOBILLO"
    },
    {
     "CATALOG_KEY": "7988",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE PIE Y DEDO DE PIE"
    },
    {
     "CATALOG_KEY": "7989",
     "PRO_NOMBRE": "REDUCCIÓN ABIERTA DE LUXACIÓN DE OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "799",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO"
    },
    {
     "CATALOG_KEY": "7990",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "7991",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. HÚMERO"
    },
    {
     "CATALOG_KEY": "7992",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. RADIO Y CÚBITO"
    },
    {
     "CATALOG_KEY": "7993",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. CARPIANOS Y METACARPIANOS"
    },
    {
     "CATALOG_KEY": "7994",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. FALANGES Y MANO"
    },
    {
     "CATALOG_KEY": "7995",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. FÉMUR"
    },
    {
     "CATALOG_KEY": "7996",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "7997",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO.TARSIANOS Y METATARSIANOS"
    },
    {
     "CATALOG_KEY": "7998",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. FALANGES Y PIE"
    },
    {
     "CATALOG_KEY": "7999",
     "PRO_NOMBRE": "OPERACIÓN NO ESPECIFICADA SOBRE LESIÓN DE HUESO. OTROS HUESOS ESPECÍFICOS"
    },
    {
     "CATALOG_KEY": "80",
     "PRO_NOMBRE": "INCISIÓN Y EXCISIÓN DE ESTRUCTURAS DE LA ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "800",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS"
    },
    {
     "CATALOG_KEY": "8000",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "8001",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. HOMBRO"
    },
    {
     "CATALOG_KEY": "8002",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. CODO"
    },
    {
     "CATALOG_KEY": "8003",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. MUÑECA"
    },
    {
     "CATALOG_KEY": "8004",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8005",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. CADERA"
    },
    {
     "CATALOG_KEY": "8006",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. RODILLA"
    },
    {
     "CATALOG_KEY": "8007",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. TOBILLO"
    },
    {
     "CATALOG_KEY": "8008",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. PIE Y DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "8009",
     "PRO_NOMBRE": "ARTROTOMÍA PARA EXTRACCIÓN DE PRÓTESIS. OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "801",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA"
    },
    {
     "CATALOG_KEY": "8010",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "8011",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. HOMBRO"
    },
    {
     "CATALOG_KEY": "8012",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. CODO"
    },
    {
     "CATALOG_KEY": "8013",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. MUÑECA"
    },
    {
     "CATALOG_KEY": "8014",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8015",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. CADERA"
    },
    {
     "CATALOG_KEY": "8016",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. RODILLA"
    },
    {
     "CATALOG_KEY": "8017",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. TOBILLO"
    },
    {
     "CATALOG_KEY": "8018",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. PIE Y DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "8019",
     "PRO_NOMBRE": "OTRA ARTROTOMÍA. OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "802",
     "PRO_NOMBRE": "ARTROSCOPIA"
    },
    {
     "CATALOG_KEY": "8020",
     "PRO_NOMBRE": "ARTROSCOPIA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "8021",
     "PRO_NOMBRE": "ARTROSCOPIA. HOMBRO"
    },
    {
     "CATALOG_KEY": "8022",
     "PRO_NOMBRE": "ARTROSCOPIA. CODO"
    },
    {
     "CATALOG_KEY": "8023",
     "PRO_NOMBRE": "ARTROSCOPIA. MUÑECA"
    },
    {
     "CATALOG_KEY": "8024",
     "PRO_NOMBRE": "ARTROSCOPIA. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8025",
     "PRO_NOMBRE": "ARTROSCOPIA. CADERA"
    },
    {
     "CATALOG_KEY": "8026",
     "PRO_NOMBRE": "ARTROSCOPIA. RODILLA"
    },
    {
     "CATALOG_KEY": "8027",
     "PRO_NOMBRE": "ARTROSCOPIA. TOBILLO"
    },
    {
     "CATALOG_KEY": "8028",
     "PRO_NOMBRE": "ARTROSCOPIA. PIE Y DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "8029",
     "PRO_NOMBRE": "ARTROSCOPIA. OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "803",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "8030",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. SITIO NO ESPEICIFICADO"
    },
    {
     "CATALOG_KEY": "8031",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. HOMBRO"
    },
    {
     "CATALOG_KEY": "8032",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. CODO"
    },
    {
     "CATALOG_KEY": "8033",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. MUÑECA"
    },
    {
     "CATALOG_KEY": "8034",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8035",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. CADERA"
    },
    {
     "CATALOG_KEY": "8036",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. RODILLA"
    },
    {
     "CATALOG_KEY": "8037",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. TOBILLO"
    },
    {
     "CATALOG_KEY": "8038",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. PIE Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8039",
     "PRO_NOMBRE": "BIOPSIA DE ESTRUCTURA DE LA ARTICULACIÓN. OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "804",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO"
    },
    {
     "CATALOG_KEY": "8040",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "8041",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. HOMBRO"
    },
    {
     "CATALOG_KEY": "8042",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. CODO"
    },
    {
     "CATALOG_KEY": "8043",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. MUÑECA"
    },
    {
     "CATALOG_KEY": "8044",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8045",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. CADERA"
    },
    {
     "CATALOG_KEY": "8046",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. RODILLA"
    },
    {
     "CATALOG_KEY": "8047",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. TOBILLO"
    },
    {
     "CATALOG_KEY": "8048",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. PIE Y DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "8049",
     "PRO_NOMBRE": "DIVISIÓN DE CÁPSULA ARTICULAR, LIGAMENTO O CARTÍLAGO. OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "805",
     "PRO_NOMBRE": "EXCISIÓN, DESTRUCCIÓN Y OTRA REPARACIÓN DE DISCO INTERVERTEBRAL"
    },
    {
     "CATALOG_KEY": "8050",
     "PRO_NOMBRE": "EXCISIÓN O DESTRUCCIÓN DE DISCO INTERVERTEBRAL, NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "8051",
     "PRO_NOMBRE": "EXCISIÓN DE DISCO INTERVERTEBRAL"
    },
    {
     "CATALOG_KEY": "8052",
     "PRO_NOMBRE": "QUIMIONUCLEOLISIS INTERVERTEBRAL"
    },
    {
     "CATALOG_KEY": "8053",
     "PRO_NOMBRE": "REPARACIÓN DEL ANILLO FIBROSO CON INJERTO O PRÓTESIS (DISCO INTERVERTEBRAL)"
    },
    {
     "CATALOG_KEY": "8054",
     "PRO_NOMBRE": "OTRA REPARACIÓN Y LA NO ESPECIFICADA DEL ANILLO FIBROSO (DISCO INTERVERTEBRAL)"
    },
    {
     "CATALOG_KEY": "8059",
     "PRO_NOMBRE": "OTRA DESTRUCCIÓN DE DISCO INTERVERTEBRAL"
    },
    {
     "CATALOG_KEY": "806",
     "PRO_NOMBRE": "EXCISIÓN DE CARTÍLAGO SEMILUNAR DE LA RODILLA"
    },
    {
     "CATALOG_KEY": "806X",
     "PRO_NOMBRE": "EXCISIÓN DE CARTÍLAGO SEMILUNAR DE RODILLA"
    },
    {
     "CATALOG_KEY": "807",
     "PRO_NOMBRE": "SINOVECTOMÍA"
    },
    {
     "CATALOG_KEY": "8070",
     "PRO_NOMBRE": "SINOVECTOMÍA. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "8071",
     "PRO_NOMBRE": "SINOVECTOMÍA. HOMBRO"
    },
    {
     "CATALOG_KEY": "8072",
     "PRO_NOMBRE": "SINOVECTOMÍA. CODO"
    },
    {
     "CATALOG_KEY": "8073",
     "PRO_NOMBRE": "SINOVECTOMÍA. MUÑECA"
    },
    {
     "CATALOG_KEY": "8074",
     "PRO_NOMBRE": "SINOVECTOMÍA. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8075",
     "PRO_NOMBRE": "SINOVECTOMÍA. CADERA"
    },
    {
     "CATALOG_KEY": "8076",
     "PRO_NOMBRE": "SINOVECTOMÍA. RODILLA"
    },
    {
     "CATALOG_KEY": "8077",
     "PRO_NOMBRE": "SINOVECTOMÍA. TOBILLO"
    },
    {
     "CATALOG_KEY": "8078",
     "PRO_NOMBRE": "SINOVECTOMÍA. PIE Y DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "8079",
     "PRO_NOMBRE": "SINOVECTOMÍA, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "808",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "8080",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "8081",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. HOMBRO"
    },
    {
     "CATALOG_KEY": "8082",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. CODO"
    },
    {
     "CATALOG_KEY": "8083",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. MUÑECA"
    },
    {
     "CATALOG_KEY": "8084",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8085",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. CADERA"
    },
    {
     "CATALOG_KEY": "8086",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. RODILLA"
    },
    {
     "CATALOG_KEY": "8087",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. TOBILLO"
    },
    {
     "CATALOG_KEY": "8088",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN. PIE Y DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "8089",
     "PRO_NOMBRE": "OTRA EXCISIÓN LOCAL O DESTRUCCIÓN DE LESIÓN DE ARTICULACIÓN, OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "809",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "8090",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "8091",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. HOMBRO"
    },
    {
     "CATALOG_KEY": "8092",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. CODO"
    },
    {
     "CATALOG_KEY": "8093",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. MUÑECA"
    },
    {
     "CATALOG_KEY": "8094",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. MANO Y DEDOS DE LA MANO"
    },
    {
     "CATALOG_KEY": "8095",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. CADERA"
    },
    {
     "CATALOG_KEY": "8096",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. RODILLA"
    },
    {
     "CATALOG_KEY": "8097",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. TOBILLO"
    },
    {
     "CATALOG_KEY": "8098",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. PIE Y DEDOS DEL PIE"
    },
    {
     "CATALOG_KEY": "8099",
     "PRO_NOMBRE": "OTRA EXCISIÓN O DESTRUCCIÓN DE LESIÓN DE LA ARTICULACIÓN. OTROS SITIOS ESPECIFICADOS"
    },
    {
     "CATALOG_KEY": "81",
     "PRO_NOMBRE": "OPERACIONES DE REPARACIÓN Y PLÁSTICAS SOBRE ESTRUCTURAS DE LA ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "810",
     "PRO_NOMBRE": "FUSIÓN VERTEBRAL"
    },
    {
     "CATALOG_KEY": "8100",
     "PRO_NOMBRE": "FUSIÓN VERTEBRAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8101",
     "PRO_NOMBRE": "FUSIÓN VERTEBRAL ATLAS-AXIS"
    },
    {
     "CATALOG_KEY": "8102",
     "PRO_NOMBRE": "OTRA FUSIÓN CERVICAL, TÉCNICA ANTERIOR"
    },
    {
     "CATALOG_KEY": "8103",
     "PRO_NOMBRE": "OTRA FUSIÓN CERVICAL, TÉCNICA POSTERIOR"
    },
    {
     "CATALOG_KEY": "8104",
     "PRO_NOMBRE": "FUSIÓN DORSAL Y DORSOLUMBAR, TÉCNICA ANTERIOR"
    },
    {
     "CATALOG_KEY": "8105",
     "PRO_NOMBRE": "FUSIÓN DORSAL Y DORSOLUMBAR, TÉCNICA POSTERIOR"
    },
    {
     "CATALOG_KEY": "8106",
     "PRO_NOMBRE": "FUSIÓN LUMBAR Y LUMBOSACRA, TÉCNICA ANTERIOR"
    },
    {
     "CATALOG_KEY": "8107",
     "PRO_NOMBRE": "FUSIÓN LUMBAR Y LUMBOSACRA, TÉCNICA DE PROCEDIMIENTO LATERAL TRANSVERSO"
    },
    {
     "CATALOG_KEY": "8108",
     "PRO_NOMBRE": "FUSIÓN LUMBAR Y LUMBOSACRA, TÉCNICA POSTERIOR"
    },
    {
     "CATALOG_KEY": "811",
     "PRO_NOMBRE": "ARTRODESIS Y ARTROEREISIS DE PIE Y TOBILLO"
    },
    {
     "CATALOG_KEY": "8111",
     "PRO_NOMBRE": "FUSIÓN DE TOBILLO"
    },
    {
     "CATALOG_KEY": "8112",
     "PRO_NOMBRE": "TRIPLE ARTRODESIS"
    },
    {
     "CATALOG_KEY": "8113",
     "PRO_NOMBRE": "FUSIÓN SUBASTRAGALINA"
    },
    {
     "CATALOG_KEY": "8114",
     "PRO_NOMBRE": "FUSIÓN MEDIO TARSIANA"
    },
    {
     "CATALOG_KEY": "8115",
     "PRO_NOMBRE": "FUSIÓN TARSOMETATARSIANA"
    },
    {
     "CATALOG_KEY": "8116",
     "PRO_NOMBRE": "FUSIÓN METATARSO-FALÁNGICA"
    },
    {
     "CATALOG_KEY": "8117",
     "PRO_NOMBRE": "OTRA FUSIÓN DE PIE"
    },
    {
     "CATALOG_KEY": "8118",
     "PRO_NOMBRE": "ARTROEREISIS DE ARTICULACIÓN SUBASTRAGALINA"
    },
    {
     "CATALOG_KEY": "812",
     "PRO_NOMBRE": "ARTRODESIS DE OTRA ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "8120",
     "PRO_NOMBRE": "ARTRODESIS DE ARTICULACIÓN NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "8121",
     "PRO_NOMBRE": "ARTRODESIS DE CADERA"
    },
    {
     "CATALOG_KEY": "8122",
     "PRO_NOMBRE": "ARTRODESIS DE RODILLA"
    },
    {
     "CATALOG_KEY": "8123",
     "PRO_NOMBRE": "ARTRODESIS DE HOMBRO"
    },
    {
     "CATALOG_KEY": "8124",
     "PRO_NOMBRE": "ARTRODESIS DE CODO"
    },
    {
     "CATALOG_KEY": "8125",
     "PRO_NOMBRE": "FUSIÓN CARPORRADIAL"
    },
    {
     "CATALOG_KEY": "8126",
     "PRO_NOMBRE": "FUSIÓN CARPOMETACARPIANA"
    },
    {
     "CATALOG_KEY": "8127",
     "PRO_NOMBRE": "FUSIÓN METACARPOFALÁNGICA"
    },
    {
     "CATALOG_KEY": "8128",
     "PRO_NOMBRE": "FUSIÓN INTERFALÁNGICA"
    },
    {
     "CATALOG_KEY": "8129",
     "PRO_NOMBRE": "ARTRODESIS DE OTRAS ARTICULACIONES ESPECIFICADAS"
    },
    {
     "CATALOG_KEY": "813",
     "PRO_NOMBRE": "REFUSIÓN VERTEBRAL"
    },
    {
     "CATALOG_KEY": "8130",
     "PRO_NOMBRE": "REFUSIÓN VERTEBRAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8131",
     "PRO_NOMBRE": "REFUSIÓN ESPINAL ATLAS-AXIS"
    },
    {
     "CATALOG_KEY": "8132",
     "PRO_NOMBRE": "REFUSIÓN DE OTRAS VÉRTEBRAS CERVICALES, TÉCNICA ANTERIOR"
    },
    {
     "CATALOG_KEY": "8133",
     "PRO_NOMBRE": "REFUSIÓN DE OTRAS VÉRTEBRAS CERVICALES, TÉCNICA POSTERIOR"
    },
    {
     "CATALOG_KEY": "8134",
     "PRO_NOMBRE": "REFUSIÓN ESPINAL DORSAL Y DORSOLUMBAR, TÉCNICA ANTERIOR"
    },
    {
     "CATALOG_KEY": "8135",
     "PRO_NOMBRE": "REFUSIÓN ESPINAL DORSAL Y DORSOLUMBAR, TÉCNICA POSTERIOR"
    },
    {
     "CATALOG_KEY": "8136",
     "PRO_NOMBRE": "REFUSIÓN ESPINO LUMBAR Y LUMBOSACRA, TÉCNICA ANTERIOR"
    },
    {
     "CATALOG_KEY": "8137",
     "PRO_NOMBRE": "REFUSIÓN ESPINO LUMBAR Y LUMBOSACRA, TÉCNICA DE PROCEDIMIENTO LATERAL TRANSVERSO"
    },
    {
     "CATALOG_KEY": "8138",
     "PRO_NOMBRE": "REFUSIÓN ESPINAL LUMBAR Y LUMBOSACRA, TÉCNICA POSTERIOR"
    },
    {
     "CATALOG_KEY": "8139",
     "PRO_NOMBRE": "REFUSIÓN VERTEBRAL, NO CLASIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "814",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ARTICULACIÓN DE EXTREMIDADES INFERIORES"
    },
    {
     "CATALOG_KEY": "8140",
     "PRO_NOMBRE": "REPARACIÓN DE CADERA, NO ESPECIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "8142",
     "PRO_NOMBRE": "REPARACIÓN \"CINCO EN UNO\" DE RODILLA"
    },
    {
     "CATALOG_KEY": "8143",
     "PRO_NOMBRE": "REPARACIÓN DE UNA TRIADA DE RODILLA"
    },
    {
     "CATALOG_KEY": "8144",
     "PRO_NOMBRE": "ESTABILIZACIÓN DE RÓTULA"
    },
    {
     "CATALOG_KEY": "8145",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE LOS LIGAMENTOS CRUZADOS"
    },
    {
     "CATALOG_KEY": "8146",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE LIGAMENTOS COLATERALES"
    },
    {
     "CATALOG_KEY": "8147",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE RODILLA"
    },
    {
     "CATALOG_KEY": "8149",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE TOBILLO"
    },
    {
     "CATALOG_KEY": "815",
     "PRO_NOMBRE": "SUSTITUCIÓN DE LAS ARTICULACIONES DE LAS EXTREMIDADES INFERIORES"
    },
    {
     "CATALOG_KEY": "8151",
     "PRO_NOMBRE": "SUSTITUCIÓN O REEMPLAZO TOTAL DE CADERA"
    },
    {
     "CATALOG_KEY": "8152",
     "PRO_NOMBRE": "SUSTITUCIÓN PARCIAL DE CADERA"
    },
    {
     "CATALOG_KEY": "8153",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE CADERA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8154",
     "PRO_NOMBRE": "SUSTITUCIÓN TOTAL DE RODILLA"
    },
    {
     "CATALOG_KEY": "8155",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE RODILLA, NO ESPECIFICADA DE OTRA PARTE"
    },
    {
     "CATALOG_KEY": "8156",
     "PRO_NOMBRE": "SUSTITUCIÓN TOTAL DE TOBILLO"
    },
    {
     "CATALOG_KEY": "8157",
     "PRO_NOMBRE": "SUSTITUCIÓN DE ARTICULACIÓN DE PIE Y DEDOS"
    },
    {
     "CATALOG_KEY": "8159",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE ARTICULACIÓN DE EXTREMIDAD INFERIOR, NO CLASIFICADA EN OTRA PARTE"
    },
    {
     "CATALOG_KEY": "816",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS VERTEBRALES"
    },
    {
     "CATALOG_KEY": "8162",
     "PRO_NOMBRE": "FUSIÓN O REFUSIÓN DE 2-3 VÉRTEBRAS"
    },
    {
     "CATALOG_KEY": "8163",
     "PRO_NOMBRE": "FUSIÓN O REFUSIÓN DE 4-8 VÉRTEBRAS"
    },
    {
     "CATALOG_KEY": "8164",
     "PRO_NOMBRE": "FUSIÓN O REFUSIÓN DE 9 Ó MÁS VÉRTEBRAS"
    },
    {
     "CATALOG_KEY": "8165",
     "PRO_NOMBRE": "VERTEBROPLASTIA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "8166",
     "PRO_NOMBRE": "AUMENTO PERCUTÁNEO VERTEBRAL"
    },
    {
     "CATALOG_KEY": "817",
     "PRO_NOMBRE": "ARTROPLASTIA Y REPARACIÓN DE MANO, DEDO DE MANO Y MUÑECA"
    },
    {
     "CATALOG_KEY": "8171",
     "PRO_NOMBRE": "ARTROPLASTIA DE ARTICULACIONES METACARPOFALÁNGICA E INTERFALÁNGICA CON IMPLANTE"
    },
    {
     "CATALOG_KEY": "8172",
     "PRO_NOMBRE": "ARTROPLASTIA DE ARTICULACIONES METACARPOFALÁNGICA E INTERFALÁNGICA SIN IMPLANTE"
    },
    {
     "CATALOG_KEY": "8173",
     "PRO_NOMBRE": "SUSTITUCIÓN TOTAL DE MUÑECA"
    },
    {
     "CATALOG_KEY": "8174",
     "PRO_NOMBRE": "ARTROPLASTIA DE ARTICULACIONES CARPOCARPIANA O CARPOMETACARPIANA CON IMPLANTE"
    },
    {
     "CATALOG_KEY": "8175",
     "PRO_NOMBRE": "ARTROPLASTIA DE ARTICULACIONES CARPOCARPIANA O CARPOMETACARPIANA SIN IMPLANTE"
    },
    {
     "CATALOG_KEY": "8179",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE MANO, DEDO DE MANO Y MUÑECA"
    },
    {
     "CATALOG_KEY": "818",
     "PRO_NOMBRE": "ARTROPLASTIA Y REPARACIÓN DE HOMBRO Y CODO"
    },
    {
     "CATALOG_KEY": "8180",
     "PRO_NOMBRE": "SUSTITUCIÓN TOTAL DE HOMBRO"
    },
    {
     "CATALOG_KEY": "8181",
     "PRO_NOMBRE": "SUSTITUCIÓN PARCIAL DE HOMBRO"
    },
    {
     "CATALOG_KEY": "8182",
     "PRO_NOMBRE": "REPARACIÓN DE LUXACIÓN RECIDIVANTE DE HOMBRO"
    },
    {
     "CATALOG_KEY": "8183",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE HOMBRO"
    },
    {
     "CATALOG_KEY": "8184",
     "PRO_NOMBRE": "SUSTITUCIÓN TOTAL DE CODO"
    },
    {
     "CATALOG_KEY": "8185",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE CODO"
    },
    {
     "CATALOG_KEY": "819",
     "PRO_NOMBRE": "OTRAS REPARACIONES SOBRE ESTRUCTURAS DE ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "8191",
     "PRO_NOMBRE": "ARTROCENTESIS"
    },
    {
     "CATALOG_KEY": "8192",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN ARTICULACIÓN O LIGAMENTO"
    },
    {
     "CATALOG_KEY": "8193",
     "PRO_NOMBRE": "SUTURA DE CÁPSULA O LIGAMENTO DE EXTREMIDAD SUPERIOR"
    },
    {
     "CATALOG_KEY": "8194",
     "PRO_NOMBRE": "SUTURA DE CÁPSULA O LIGAMENTO DE TOBILLO Y PIE"
    },
    {
     "CATALOG_KEY": "8195",
     "PRO_NOMBRE": "SUTURA DE CÁPSULA O LIGAMIENTO DE OTRA EXTREMIDAD INFERIOR"
    },
    {
     "CATALOG_KEY": "8196",
     "PRO_NOMBRE": "OTRA REPARACIÓN DE ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "8197",
     "PRO_NOMBRE": "REVISIÓN DE SUSTITUCIÓN DE ARTICULACIÓN DE EXTREMIDAD SUPERIOR"
    },
    {
     "CATALOG_KEY": "8198",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE ESTRUCTURA DE ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "8199",
     "PRO_NOMBRE": "OTRA REPARACIÓN SOBRE ESTRUCTURAS DE ARTICULACIÓN"
    },
    {
     "CATALOG_KEY": "82",
     "PRO_NOMBRE": "OPERACIONES SOBRE MÚSCULO, TENDÓN Y FASCIA DE MANO"
    },
    {
     "CATALOG_KEY": "820",
     "PRO_NOMBRE": "INCISIÓN DE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL DE MANO"
    },
    {
     "CATALOG_KEY": "8201",
     "PRO_NOMBRE": "EXPLORACIÓN DE VAINA DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8202",
     "PRO_NOMBRE": "MIOTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8203",
     "PRO_NOMBRE": "BURSOTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8204",
     "PRO_NOMBRE": "INCISIÓN Y DRENAJE DE ESPACIO PALMAR Y TENAR"
    },
    {
     "CATALOG_KEY": "8209",
     "PRO_NOMBRE": "OTRA INCISIÓN DE TEJIDO BLANDO DE MANO"
    },
    {
     "CATALOG_KEY": "821",
     "PRO_NOMBRE": "DIVISIÓN DE MÚSCULO, TENDÓN Y FASCIA DE MANO"
    },
    {
     "CATALOG_KEY": "8211",
     "PRO_NOMBRE": "TENOTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8212",
     "PRO_NOMBRE": "FASCIOTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8219",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE TEJIDO BLANDO DE MANO"
    },
    {
     "CATALOG_KEY": "822",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE MÚSCULO, TENDÓN Y FASCIA DE MANO"
    },
    {
     "CATALOG_KEY": "8221",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE VAINA DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8222",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE MÚSCULO DE MANO"
    },
    {
     "CATALOG_KEY": "8229",
     "PRO_NOMBRE": "EXCISIÓN DE OTRA LESIÓN DE TEJIDO DE MANO"
    },
    {
     "CATALOG_KEY": "823",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE TEJIDO BLANDO DE MANO"
    },
    {
     "CATALOG_KEY": "8231",
     "PRO_NOMBRE": "BURSECTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8232",
     "PRO_NOMBRE": "EXCISIÓN DE TENDÓN DE MANO PARA INJERTO"
    },
    {
     "CATALOG_KEY": "8233",
     "PRO_NOMBRE": "OTRA TENDONECTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8234",
     "PRO_NOMBRE": "EXCISIÓN DE MÚSCULO O FASCIA DE MANO PARA INJERTO"
    },
    {
     "CATALOG_KEY": "8235",
     "PRO_NOMBRE": "OTRA FASCIECTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8236",
     "PRO_NOMBRE": "OTRA MIECTOMÍA DE MANO"
    },
    {
     "CATALOG_KEY": "8239",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE TEJIDO BLANDO DE MANO"
    },
    {
     "CATALOG_KEY": "824",
     "PRO_NOMBRE": "SUTURA DE MÚSCULO, TENDÓN Y FASCIA DE MANO"
    },
    {
     "CATALOG_KEY": "8241",
     "PRO_NOMBRE": "SUTURA DE VAINA DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8242",
     "PRO_NOMBRE": "SUTURA RETARDADA DEL TENDÓN FLEXOR DE MANO"
    },
    {
     "CATALOG_KEY": "8243",
     "PRO_NOMBRE": "SUTURA RETARDADA DE OTRO TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8244",
     "PRO_NOMBRE": "OTRA SUTURA DE TENDÓN FLEXOR DE MANO"
    },
    {
     "CATALOG_KEY": "8245",
     "PRO_NOMBRE": "OTRA SUTURA DE OTRO TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8246",
     "PRO_NOMBRE": "SUTURA DE MÚSCULO O FASCIA DE MANO"
    },
    {
     "CATALOG_KEY": "825",
     "PRO_NOMBRE": "TRASPLANTE DE MÚSCULO Y TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8251",
     "PRO_NOMBRE": "ADELANTAMIENTO DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8252",
     "PRO_NOMBRE": "RESECCIÓN DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8253",
     "PRO_NOMBRE": "REFIJACIÓN DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8254",
     "PRO_NOMBRE": "REFIJACIÓN DE MÚSCULO DE MANO"
    },
    {
     "CATALOG_KEY": "8255",
     "PRO_NOMBRE": "OTRO CAMBIO DE LONGITUD DE MÚSCULO O TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8256",
     "PRO_NOMBRE": "OTRA TRANSFERENCIA O TRASPLANTE DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8257",
     "PRO_NOMBRE": "OTRA TRANSPOSICIÓN DE TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8258",
     "PRO_NOMBRE": "OTRA TRANSFERENCIA O TRASPLANTE DE MÚSCULO DE MANO"
    },
    {
     "CATALOG_KEY": "8259",
     "PRO_NOMBRE": "OTRA TRASPOSICIÓN DE MÚSCULO DE MANO"
    },
    {
     "CATALOG_KEY": "826",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE DEDO PULGAR"
    },
    {
     "CATALOG_KEY": "8261",
     "PRO_NOMBRE": "OPERACIÓN DE POLICIZACIÓN CON TRANSFERENCIA DE NERVIOS Y APORTE VASCULAR"
    },
    {
     "CATALOG_KEY": "8269",
     "PRO_NOMBRE": "OTRA RECONSTRUCCIÓN DE DEDO PULGAR"
    },
    {
     "CATALOG_KEY": "827",
     "PRO_NOMBRE": "OPERACIÓN PLÁSTICA SOBRE MANO CON INJERTO O IMPLANTE"
    },
    {
     "CATALOG_KEY": "8271",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE POLEA DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8272",
     "PRO_NOMBRE": "OPERACIÓN PLÁSTICA SOBRE MANO CON INJERTO DE MÚSCULO O FASCIA"
    },
    {
     "CATALOG_KEY": "8279",
     "PRO_NOMBRE": "OPERACIÓN PLÁSTICA SOBRE MANO CON INJERTO O IMPLANTACIÓN"
    },
    {
     "CATALOG_KEY": "828",
     "PRO_NOMBRE": "OTRAS OPERACIONES PLÁSTICAS SOBRE MANO"
    },
    {
     "CATALOG_KEY": "8281",
     "PRO_NOMBRE": "TRANSFERENCIA DE DEDO, SALVO PULGAR"
    },
    {
     "CATALOG_KEY": "8282",
     "PRO_NOMBRE": "REPARACIÓN DE MANO HENDIDA"
    },
    {
     "CATALOG_KEY": "8283",
     "PRO_NOMBRE": "REPARACIÓN DE MACRODACTILIA"
    },
    {
     "CATALOG_KEY": "8284",
     "PRO_NOMBRE": "REPARACIÓN DE DEDO EN MARTILLO DE LA MANO"
    },
    {
     "CATALOG_KEY": "8285",
     "PRO_NOMBRE": "OTRA TENODESIS DE MANO"
    },
    {
     "CATALOG_KEY": "8286",
     "PRO_NOMBRE": "OTRA TENOPLASTIA DE MANO"
    },
    {
     "CATALOG_KEY": "8289",
     "PRO_NOMBRE": "OTRAS OPERACIONES PLÁSTICAS SOBRE MANO"
    },
    {
     "CATALOG_KEY": "829",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE MÚSCULOS, TENDÓN Y FASCIA DE MANO"
    },
    {
     "CATALOG_KEY": "8291",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE MANO"
    },
    {
     "CATALOG_KEY": "8292",
     "PRO_NOMBRE": "ASPIRACIÓN DE BOLSA SINOVIAL DE MANO"
    },
    {
     "CATALOG_KEY": "8293",
     "PRO_NOMBRE": "ASPIRACIÓN DE OTRO TEJIDO BLANDO DE MANO"
    },
    {
     "CATALOG_KEY": "8294",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN BOLSA SINOVIAL DE MANO"
    },
    {
     "CATALOG_KEY": "8295",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN TENDÓN DE MANO"
    },
    {
     "CATALOG_KEY": "8296",
     "PRO_NOMBRE": "OTRA INYECCIÓN DE SUSTANCIA TERAPÉUTICA DE ACCIÓN LOCAL EN TEJIDO BLANDO DE MANO"
    },
    {
     "CATALOG_KEY": "8299",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE MÚSCULO, TENDÓN Y FASCIA DE MANO"
    },
    {
     "CATALOG_KEY": "83",
     "PRO_NOMBRE": "OPERACIONES SOBRE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL SALVO DE LA MANO"
    },
    {
     "CATALOG_KEY": "830",
     "PRO_NOMBRE": "INCISIÓN DE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL"
    },
    {
     "CATALOG_KEY": "8301",
     "PRO_NOMBRE": "EXPLORACIÓN DE VAINA DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8302",
     "PRO_NOMBRE": "MIOTOMÍA"
    },
    {
     "CATALOG_KEY": "8303",
     "PRO_NOMBRE": "BURSOTOMÍA"
    },
    {
     "CATALOG_KEY": "8309",
     "PRO_NOMBRE": "OTRA INCISIÓN DE TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "831",
     "PRO_NOMBRE": "DIVISIÓN DE MÚSCULO, TENDÓN Y FASCIA"
    },
    {
     "CATALOG_KEY": "8311",
     "PRO_NOMBRE": "TENOTOMIA DE AQUILES"
    },
    {
     "CATALOG_KEY": "8312",
     "PRO_NOMBRE": "TENOTOMÍA DE ADUCTOR DE CADERA"
    },
    {
     "CATALOG_KEY": "8313",
     "PRO_NOMBRE": "OTRA TENOTOMÍA"
    },
    {
     "CATALOG_KEY": "8314",
     "PRO_NOMBRE": "FASCIOTOMÍA"
    },
    {
     "CATALOG_KEY": "8319",
     "PRO_NOMBRE": "OTRA DIVISIÓN DE TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "832",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL, INCLUSO AQUEL DE MANO"
    },
    {
     "CATALOG_KEY": "8321",
     "PRO_NOMBRE": "BIOPSIA DE TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "8329",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL, INCLUSO AQUEL DE MANO"
    },
    {
     "CATALOG_KEY": "833",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL"
    },
    {
     "CATALOG_KEY": "8331",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE VAINA DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8332",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE MÚSCULO"
    },
    {
     "CATALOG_KEY": "8339",
     "PRO_NOMBRE": "EXCISIÓN DE LESIÓN DE OTRO TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "834",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE MÚSCULO, TENDÓN Y FASCIA"
    },
    {
     "CATALOG_KEY": "8341",
     "PRO_NOMBRE": "EXCISIÓN DE TENDÓN PARA INJERTO"
    },
    {
     "CATALOG_KEY": "8342",
     "PRO_NOMBRE": "OTRA TENDONECTOMÍA"
    },
    {
     "CATALOG_KEY": "8343",
     "PRO_NOMBRE": "EXCISIÓN DE MÚSCULO O FASCIA PARA INJERTO"
    },
    {
     "CATALOG_KEY": "8344",
     "PRO_NOMBRE": "OTRA FASCIECTOMÍA"
    },
    {
     "CATALOG_KEY": "8345",
     "PRO_NOMBRE": "OTRA MIECTOMÍA"
    },
    {
     "CATALOG_KEY": "8349",
     "PRO_NOMBRE": "OTRA EXCISIÓN DE TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "835",
     "PRO_NOMBRE": "BURSECTOMÍA"
    },
    {
     "CATALOG_KEY": "835X",
     "PRO_NOMBRE": "BURSECTOMÍA"
    },
    {
     "CATALOG_KEY": "836",
     "PRO_NOMBRE": "SUTURA DE MÚSCULO, TENDÓN Y FASCIA"
    },
    {
     "CATALOG_KEY": "8361",
     "PRO_NOMBRE": "SUTURA DE VAINA DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8362",
     "PRO_NOMBRE": "SUTURA RETARDADA DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8363",
     "PRO_NOMBRE": "REPARACIÓN DE MANGUITO DE LOS ROTADORES"
    },
    {
     "CATALOG_KEY": "8364",
     "PRO_NOMBRE": "OTRA SUTURA DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8365",
     "PRO_NOMBRE": "OTRA SUTURA DE MÚSCULO O FASCIA"
    },
    {
     "CATALOG_KEY": "837",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE MÚSCULOS Y TENDÓN"
    },
    {
     "CATALOG_KEY": "8371",
     "PRO_NOMBRE": "ADELANTAMIENTO DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8372",
     "PRO_NOMBRE": "RESECCIÓN DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8373",
     "PRO_NOMBRE": "REANCLAJE DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8374",
     "PRO_NOMBRE": "REANCLAJE DE MÚSCULO"
    },
    {
     "CATALOG_KEY": "8375",
     "PRO_NOMBRE": "TRANSFERENCIA O TRASPLANTE DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8376",
     "PRO_NOMBRE": "OTRA TRASPOSICIÓN DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8377",
     "PRO_NOMBRE": "TRANSFERENCIA O TRASPLANTE DE MÚSCULO"
    },
    {
     "CATALOG_KEY": "8379",
     "PRO_NOMBRE": "OTRA TRASPOSICIÓN DE MÚSCULO"
    },
    {
     "CATALOG_KEY": "838",
     "PRO_NOMBRE": "OTRAS OPERACIONES PLÁSTICAS SOBRE MÚSCULO, TENDÓN Y FASCIA"
    },
    {
     "CATALOG_KEY": "8381",
     "PRO_NOMBRE": "INJERTO DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8382",
     "PRO_NOMBRE": "INJERTO DE MÚSCULO O FASCIA"
    },
    {
     "CATALOG_KEY": "8383",
     "PRO_NOMBRE": "RECONSTRUCCIÓN DE POLEA DE TENDÓN"
    },
    {
     "CATALOG_KEY": "8384",
     "PRO_NOMBRE": "LIBERACIÓN DE PIE TALO NO CLASIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "8385",
     "PRO_NOMBRE": "OTRO CAMBIO EN LONGITUD DE MÚSCULO O TENDÓN"
    },
    {
     "CATALOG_KEY": "8386",
     "PRO_NOMBRE": "PLASTIA DE CUADRÍCEPS"
    },
    {
     "CATALOG_KEY": "8387",
     "PRO_NOMBRE": "OTRAS OPERACIONES PLÁSTICAS SOBRE MÚSCULO"
    },
    {
     "CATALOG_KEY": "8388",
     "PRO_NOMBRE": "OTRAS OPERACIONES PLÁSTICAS SOBRE TENDÓN"
    },
    {
     "CATALOG_KEY": "8389",
     "PRO_NOMBRE": "OTRAS OPERACIONES PLÁSTICAS SOBRE FASCIA"
    },
    {
     "CATALOG_KEY": "839",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE MÚSCULO, TENDÓN FASCIA Y BOLSA SINOVIAL"
    },
    {
     "CATALOG_KEY": "8391",
     "PRO_NOMBRE": "LISIS DE ADHERENCIAS DE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL"
    },
    {
     "CATALOG_KEY": "8392",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE ESTIMULADOR DE MÚSCULO ESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "8393",
     "PRO_NOMBRE": "EXTRACCIÓN DE ESTIMULADOR DE MÚSCULO ESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "8394",
     "PRO_NOMBRE": "ASPIRACIÓN DE BOLSA SINOVIAL"
    },
    {
     "CATALOG_KEY": "8395",
     "PRO_NOMBRE": "ASPIRACIÓN DE OTRO TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "8396",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN BOLSA SINOVIAL"
    },
    {
     "CATALOG_KEY": "8397",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA EN TENDÓN"
    },
    {
     "CATALOG_KEY": "8398",
     "PRO_NOMBRE": "INYECCIÓN DE SUSTANCIA TERAPÉUTICA DE ACCIÓN LOCAL EN OTRO TEJIDO BLANDO"
    },
    {
     "CATALOG_KEY": "8399",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE MÚSCULO, TENDÓN, FASCIA Y BOLSA SINOVIAL"
    },
    {
     "CATALOG_KEY": "84",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS SOBRE APARATO MUSCULOESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "840",
     "PRO_NOMBRE": "AMPUTACIÓN DE MIEMBRO SUPERIOR"
    },
    {
     "CATALOG_KEY": "8400",
     "PRO_NOMBRE": "AMPUTACIÓN DE MIEMBRO SUPERIOR, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8401",
     "PRO_NOMBRE": "AMPUTACIÓN Y DESARTICULACIÓN DE DEDO DE MANO"
    },
    {
     "CATALOG_KEY": "8402",
     "PRO_NOMBRE": "AMPUTACIÓN Y DESARTICULACIÓN DE DEDO PULGAR"
    },
    {
     "CATALOG_KEY": "8403",
     "PRO_NOMBRE": "AMPUTACIÓN A TRAVÉS DE MANO"
    },
    {
     "CATALOG_KEY": "8404",
     "PRO_NOMBRE": "DESARTICULACIÓN DE MUÑECA"
    },
    {
     "CATALOG_KEY": "8405",
     "PRO_NOMBRE": "AMPUTACIÓN A TRAVÉS DE ANTEBRAZO"
    },
    {
     "CATALOG_KEY": "8406",
     "PRO_NOMBRE": "DESARTICULACIÓN DE CODO"
    },
    {
     "CATALOG_KEY": "8407",
     "PRO_NOMBRE": "AMPUTACIÓN A TRAVÉS DE HÚMERO"
    },
    {
     "CATALOG_KEY": "8408",
     "PRO_NOMBRE": "DESARTICULACIÓN DE HOMBRO"
    },
    {
     "CATALOG_KEY": "8409",
     "PRO_NOMBRE": "AMPUTACIÓN INTERTORACOESCAPULAR"
    },
    {
     "CATALOG_KEY": "841",
     "PRO_NOMBRE": "AMPUTACIÓN DE MIEMBRO INFERIOR"
    },
    {
     "CATALOG_KEY": "8410",
     "PRO_NOMBRE": "AMPUTACIÓN DE MIEMBRO INFERIOR, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8411",
     "PRO_NOMBRE": "AMPUTACIÓN DE DEDO DE PIE"
    },
    {
     "CATALOG_KEY": "8412",
     "PRO_NOMBRE": "AMPUTACIÓN A TRAVÉS DE PIE"
    },
    {
     "CATALOG_KEY": "8413",
     "PRO_NOMBRE": "DESARTICULACIÓN DE TOBILLO"
    },
    {
     "CATALOG_KEY": "8414",
     "PRO_NOMBRE": "AMPUTACIÓN DE TOBILLO A TRAVÉS DE MALÉOLOS DE TIBIA Y PERONÉ"
    },
    {
     "CATALOG_KEY": "8415",
     "PRO_NOMBRE": "OTRA AMPUTACIÓN DEBAJO DE LA RODILLA"
    },
    {
     "CATALOG_KEY": "8416",
     "PRO_NOMBRE": "DESARTICULACIÓN DE RODILLA"
    },
    {
     "CATALOG_KEY": "8417",
     "PRO_NOMBRE": "AMPUTACIÓN POR ENCIMA DE LA RODILLA"
    },
    {
     "CATALOG_KEY": "8418",
     "PRO_NOMBRE": "DESARTICULACIÓN DE CADERA"
    },
    {
     "CATALOG_KEY": "8419",
     "PRO_NOMBRE": "AMPUTACIÓN ABDOMINOPÉLVICA"
    },
    {
     "CATALOG_KEY": "842",
     "PRO_NOMBRE": "REIMPLANTE DE EXTREMIDAD"
    },
    {
     "CATALOG_KEY": "8421",
     "PRO_NOMBRE": "REIMPLANTE DE DEDO PULGAR"
    },
    {
     "CATALOG_KEY": "8422",
     "PRO_NOMBRE": "REIMPLANTE DE DEDO DE MANO"
    },
    {
     "CATALOG_KEY": "8423",
     "PRO_NOMBRE": "REIMPLANTE DE ANTEBRAZO, MUÑECA O MANO"
    },
    {
     "CATALOG_KEY": "8424",
     "PRO_NOMBRE": "REIMPLANTE DE BRAZO SUPERIOR"
    },
    {
     "CATALOG_KEY": "8425",
     "PRO_NOMBRE": "REIMPLANTE DE DEDO DE PIE"
    },
    {
     "CATALOG_KEY": "8426",
     "PRO_NOMBRE": "REIMPLANTE DE PIE"
    },
    {
     "CATALOG_KEY": "8427",
     "PRO_NOMBRE": "REIMPLANTE DE PIERNA INFERIOR Y TOBILLO"
    },
    {
     "CATALOG_KEY": "8428",
     "PRO_NOMBRE": "REIMPLANTE DE MUSLO"
    },
    {
     "CATALOG_KEY": "8429",
     "PRO_NOMBRE": "OTRO REIMPLANTE"
    },
    {
     "CATALOG_KEY": "843",
     "PRO_NOMBRE": "REVISIÓN DE MUÑÓN DE AMPUTACIÓN"
    },
    {
     "CATALOG_KEY": "843X",
     "PRO_NOMBRE": "REVISIÓN DE MUÑÓN DE AMPUTACIÓN"
    },
    {
     "CATALOG_KEY": "844",
     "PRO_NOMBRE": "IMPLANTACIÓN O COLOCACIÓN DE DISPOSITIVO DE MIEMBRO PROTÉSICO"
    },
    {
     "CATALOG_KEY": "8440",
     "PRO_NOMBRE": "IMPLANTACIÓN O COLOCACIÓN DE DISPOSITIVO DE MIEMBRO PROTÉSICO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8441",
     "PRO_NOMBRE": "COLOCACIÓN DE PRÓTESIS DE BRAZO SUPERIOR Y HOMBRO"
    },
    {
     "CATALOG_KEY": "8442",
     "PRO_NOMBRE": "COLOCACIÓN DE PRÓTESIS DE BRAZO INFERIOR Y MANO"
    },
    {
     "CATALOG_KEY": "8443",
     "PRO_NOMBRE": "COLOCACIÓN DE PRÓTESIS DE BRAZO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8444",
     "PRO_NOMBRE": "IMPLANTACIÓN DE DISPOSITIVO PROTÉSICO DE BRAZO"
    },
    {
     "CATALOG_KEY": "8445",
     "PRO_NOMBRE": "COLOCACIÓN DE PRÓTESIS ENCIMA DE LA RODILLA"
    },
    {
     "CATALOG_KEY": "8446",
     "PRO_NOMBRE": "COLOCACIÓN DE PRÓTESIS DEBAJO DE LA RODILLA"
    },
    {
     "CATALOG_KEY": "8447",
     "PRO_NOMBRE": "COLOCACIÓN DE PRÓTESIS DE PIERNA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8448",
     "PRO_NOMBRE": "IMPLANTACIÓN DE DISPOSITIVO PROTÉSICO DE PIERNA"
    },
    {
     "CATALOG_KEY": "845",
     "PRO_NOMBRE": "IMPLANTACIÓN DE OTRAS SUSTANCIAS Y DISPOSITIVOS MUSCULOESQUELÉTICOS"
    },
    {
     "CATALOG_KEY": "8451",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO DE FUSIÓN VERTEBRAL INTERSOMÁTICO"
    },
    {
     "CATALOG_KEY": "8452",
     "PRO_NOMBRE": "INSERCIÓN DE PROTEÍNA MORFOGENÉTICA ÓSEA RECOMBINANTE"
    },
    {
     "CATALOG_KEY": "8453",
     "PRO_NOMBRE": "IMPLANTACIÓN DE DISPOSITIVO INTERNO DE ALARGAMIENTO DE MIEMBRO CON TRACCIÓN CINÉTICA"
    },
    {
     "CATALOG_KEY": "8454",
     "PRO_NOMBRE": "IMPLANTACIÓN DE OTRO DISPOSITIVO INTERNO DE ALARGAMIENTO DE MIEMBRO"
    },
    {
     "CATALOG_KEY": "8455",
     "PRO_NOMBRE": "INSERCIÓN DE RELLENADOR DE HUESO"
    },
    {
     "CATALOG_KEY": "8456",
     "PRO_NOMBRE": "INSERCIÓN O REEMPLAZO DE ESPACIADOR (CEMENTO)"
    },
    {
     "CATALOG_KEY": "8457",
     "PRO_NOMBRE": "RETIRO DE ESPACIADOR (CEMENTO)"
    },
    {
     "CATALOG_KEY": "8459",
     "PRO_NOMBRE": "INSERCIÓN DE OTROS DISPOSITIVOS VERTEBRALES"
    },
    {
     "CATALOG_KEY": "846",
     "PRO_NOMBRE": "SUSTITUCIÓN DE DISCO INTERVERTEBRAL"
    },
    {
     "CATALOG_KEY": "8460",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS DE DISCO INTERVERTEBRAL, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8461",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS PARCIAL DE DISCO INTERVERTEBRAL, CERVICAL"
    },
    {
     "CATALOG_KEY": "8462",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS TOTAL DE DISCO INTERVERTEBRAL, CERVICAL"
    },
    {
     "CATALOG_KEY": "8463",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS DE DISCO INTERVERTEBRAL, TORÁCICO"
    },
    {
     "CATALOG_KEY": "8464",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS PARCIAL DE DISCO INTERVERTEBRAL, LUMBOSACRA"
    },
    {
     "CATALOG_KEY": "8465",
     "PRO_NOMBRE": "INSERCIÓN DE PRÓTESIS TOTAL DE DISCO INTERVERTEBRAL, LUMBOSACRA"
    },
    {
     "CATALOG_KEY": "8466",
     "PRO_NOMBRE": "REVISIÓN O SUSTITUCIÓN DE PRÓTESIS ARTIFICIAL DE DISCO INTERVERTEBRAL, CERVICAL"
    },
    {
     "CATALOG_KEY": "8467",
     "PRO_NOMBRE": "REVISIÓN O SUSTITUCIÓN DE PRÓTESIS ARTIFICIAL DE DISCO INTERVERTEBRAL, TORÁCICO"
    },
    {
     "CATALOG_KEY": "8468",
     "PRO_NOMBRE": "REVISIÓN O SUSTITUCIÓN DE PRÓTESIS ARTIFICIAL DE DISCO INTERVERTEBRAL, LUMBOSACRA"
    },
    {
     "CATALOG_KEY": "8469",
     "PRO_NOMBRE": "REVISIÓN O SUSTITUCIÓN DE PRÓTESIS ARTIFICIAL DE DISCO INTERVERTEBRAL, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "847",
     "PRO_NOMBRE": "CÓDIGOS AUXILIARES PARA DISPOSITIVOS FIJADORES EXTERNOS"
    },
    {
     "CATALOG_KEY": "8471",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO, SISTEMA MONOPLANO"
    },
    {
     "CATALOG_KEY": "8472",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO FIJADOR EXTERNO, SISTEMA EN ANILLO"
    },
    {
     "CATALOG_KEY": "8473",
     "PRO_NOMBRE": "APLICACIÓN DE DISPOSITIVO HÍBRIDO DE FIJACIÓN EXTERNA"
    },
    {
     "CATALOG_KEY": "848",
     "PRO_NOMBRE": "INSERCIÓN, REEMPLAZO Y REVISIÓN DEL DISPOSITIVO (S) DE CONSERVACIÓN DEL MOVIMIENTO VERTEBRAL POSTERIOR"
    },
    {
     "CATALOG_KEY": "8480",
     "PRO_NOMBRE": "INSERCIÓN O REEMPLAZO DE DISPOSITIVO (S) DE PROCESO INTERESPINOSO"
    },
    {
     "CATALOG_KEY": "8481",
     "PRO_NOMBRE": "REVISIÓN DE DISPOSITIVO(S) DE PROCESO INTERESPINOSO"
    },
    {
     "CATALOG_KEY": "8482",
     "PRO_NOMBRE": "INSERCIÓN O REEMPLAZO DE DISPOSITIVO (S) DE ESTABILIZACIÓN DE MOVIMIENTO BASADO EN PEDÍCULO"
    },
    {
     "CATALOG_KEY": "8483",
     "PRO_NOMBRE": "REVISIÓN DE DISPOSITIVO(S) DE ESTABILIZACIÓN DINÁMICO BASADO EN PEDÍCULO"
    },
    {
     "CATALOG_KEY": "8484",
     "PRO_NOMBRE": "INSERCIÓN O REEMPLAZO DE DISPOSITIVO(S) DE REEMPLAZO DE FACETA"
    },
    {
     "CATALOG_KEY": "8485",
     "PRO_NOMBRE": "REVISIÓN DE DISPOSITIVO (S) DE REEMPLAZO DE FACETA"
    },
    {
     "CATALOG_KEY": "849",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE EL APARATO MUSCULOESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "8491",
     "PRO_NOMBRE": "AMPUTACIÓN, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8492",
     "PRO_NOMBRE": "SEPARACIÓN DE GEMELOS UNIDOS IGUALES"
    },
    {
     "CATALOG_KEY": "8493",
     "PRO_NOMBRE": "SEPARACIÓN DE GEMELOS UNIDOS DESIGUALES"
    },
    {
     "CATALOG_KEY": "8499",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE EL APARATO MUSCULOESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "OPERACIONES SOBRE EL APARATO TEGUMENTARIO (85 - 86)"
    },
    {
     "CATALOG_KEY": "85",
     "PRO_NOMBRE": "OPERACIONES SOBRE LA MAMA"
    },
    {
     "CATALOG_KEY": "850",
     "PRO_NOMBRE": "MASTOTOMÍA"
    },
    {
     "CATALOG_KEY": "850X",
     "PRO_NOMBRE": "MASTOTOMÍA"
    },
    {
     "CATALOG_KEY": "851",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MAMA"
    },
    {
     "CATALOG_KEY": "8511",
     "PRO_NOMBRE": "BIOPSIA CERRADA (AGUJA) (PERCUTÁNEA) DE MAMA"
    },
    {
     "CATALOG_KEY": "8512",
     "PRO_NOMBRE": "BIOPSIA ABIERTA DE MAMA"
    },
    {
     "CATALOG_KEY": "8519",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE MAMA"
    },
    {
     "CATALOG_KEY": "852",
     "PRO_NOMBRE": "EXTIRPACIÓN O DESTRUCCIÓN DE TEJIDO DE LA MAMA"
    },
    {
     "CATALOG_KEY": "8520",
     "PRO_NOMBRE": "EXTIRPACIÓN O DESTRUCCIÓN DE TEJIDO DE MAMA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8521",
     "PRO_NOMBRE": "EXTIRPACIÓN LOCAL DE LESIÓN DE MAMA"
    },
    {
     "CATALOG_KEY": "8522",
     "PRO_NOMBRE": "RESECCIÓN DE CUADRANTE DE MAMA"
    },
    {
     "CATALOG_KEY": "8523",
     "PRO_NOMBRE": "MASTECTOMÍA SUBTOTAL"
    },
    {
     "CATALOG_KEY": "8524",
     "PRO_NOMBRE": "EXTIRPACIÓN DE TEJIDO MAMARIO ECTÓPICO"
    },
    {
     "CATALOG_KEY": "8525",
     "PRO_NOMBRE": "EXTIRPACIÓN DE PEZÓN"
    },
    {
     "CATALOG_KEY": "853",
     "PRO_NOMBRE": "MAMOPLASTIA DE REDUCCIÓN Y MASTECTOMÍA SUBCUTÁNEA"
    },
    {
     "CATALOG_KEY": "8531",
     "PRO_NOMBRE": "MAMOPLASTIA DE REDUCCIÓN UNILATERAL"
    },
    {
     "CATALOG_KEY": "8532",
     "PRO_NOMBRE": "MAMOPLASTIA DE REDUCCIÓN BILATERAL"
    },
    {
     "CATALOG_KEY": "8533",
     "PRO_NOMBRE": "MASTECTOMÍA SUBCUTÁNEA UNILATERAL CON IMPLANTACIÓN SIMULTÁNEA"
    },
    {
     "CATALOG_KEY": "8534",
     "PRO_NOMBRE": "OTRA MASTECTOMÍA SUBCUTÁNEA UNILATERAL"
    },
    {
     "CATALOG_KEY": "8535",
     "PRO_NOMBRE": "MASTECTOMÍA SUBCUTÁNEA BILATERAL CON IMPLANTACIÓN SIMULTÁNEA"
    },
    {
     "CATALOG_KEY": "8536",
     "PRO_NOMBRE": "OTRA MASTECTOMÍA SUBCUTÁNEA BILATERAL"
    },
    {
     "CATALOG_KEY": "854",
     "PRO_NOMBRE": "MASTECTOMÍA"
    },
    {
     "CATALOG_KEY": "8541",
     "PRO_NOMBRE": "MASTECTOMÍA SIMPLE UNILATERAL"
    },
    {
     "CATALOG_KEY": "8542",
     "PRO_NOMBRE": "MASTECTOMÍA SIMPLE BILATERAL"
    },
    {
     "CATALOG_KEY": "8543",
     "PRO_NOMBRE": "MASTECTOMÍA SIMPLE AMPLIADA UNILATERAL"
    },
    {
     "CATALOG_KEY": "8544",
     "PRO_NOMBRE": "MASTECTOMÍA SIMPLE AMPLIADA BILATERAL"
    },
    {
     "CATALOG_KEY": "8545",
     "PRO_NOMBRE": "MASTECTOMÍA RADICAL UNILATERAL"
    },
    {
     "CATALOG_KEY": "8546",
     "PRO_NOMBRE": "MASTECTOMÍA RADICAL BILATERAL"
    },
    {
     "CATALOG_KEY": "8547",
     "PRO_NOMBRE": "MASTECTOMÍA RADICAL AMPLIADA UNILATERAL"
    },
    {
     "CATALOG_KEY": "8548",
     "PRO_NOMBRE": "MASTECTOMÍA RADICAL AMPLIADA BILATERAL"
    },
    {
     "CATALOG_KEY": "855",
     "PRO_NOMBRE": "MAMOPLASTIA DE AUMENTO"
    },
    {
     "CATALOG_KEY": "8550",
     "PRO_NOMBRE": "MAMOPLASTIA DE AUMENTO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8551",
     "PRO_NOMBRE": "INYECCIÓN EN LA MAMA UNILATERAL PARA AUMENTO"
    },
    {
     "CATALOG_KEY": "8552",
     "PRO_NOMBRE": "INYECCIÓN EN EL INTERIOR DE LA MAMA BILATERAL PARA AUMENTO"
    },
    {
     "CATALOG_KEY": "8553",
     "PRO_NOMBRE": "IMPLANTE MAMARIO UNILATERAL"
    },
    {
     "CATALOG_KEY": "8554",
     "PRO_NOMBRE": "IMPLANTE MAMARIO BILATERAL"
    },
    {
     "CATALOG_KEY": "856",
     "PRO_NOMBRE": "MASTOPEXIA"
    },
    {
     "CATALOG_KEY": "856X",
     "PRO_NOMBRE": "MASTOPEXIA"
    },
    {
     "CATALOG_KEY": "857",
     "PRO_NOMBRE": "RECONSTRUCCIÓN TOTAL DE MAMA"
    },
    {
     "CATALOG_KEY": "8570",
     "PRO_NOMBRE": "RECONSTRUCCIÓN TOTAL DE MAMA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8571",
     "PRO_NOMBRE": "RECONSTRUCCIÓN TOTAL DE MAMA, COLGAJO MIOCUTÁNEO DEL MUSCULO DORSAL ANCHO"
    },
    {
     "CATALOG_KEY": "8572",
     "PRO_NOMBRE": "'RECONSTRUCCIÓN TOTAL DE MAMA, COLGAJO TRAM PEDICULADO (MIOCUTÁNEO DE RECTO TRANSVERSO ABDOMINAL, )"
    },
    {
     "CATALOG_KEY": "8573",
     "PRO_NOMBRE": "'RECONSTRUCCIÓN TOTAL DE MAMA,COLGAJO TRAM, LIBRE (MIOCUTÁNEO DE RECTO TRANSVERSO ABDOMINAL)"
    },
    {
     "CATALOG_KEY": "8574",
     "PRO_NOMBRE": "'RECONSTRUCCIÓN TOTAL DE MAMA,COLGAJO DIEP, LIBRE (CON ARTERIA EPIGÁSTRICA INFERIOR PROFUNDA PERFORANTE)"
    },
    {
     "CATALOG_KEY": "8575",
     "PRO_NOMBRE": "'RECONSTRUCCIÓN TOTAL DE MAMA,COLGAJO SIEA LIBRE (CON ARTERIA EPIGÁSTRICA SUPERFICIAL INFERIOR)"
    },
    {
     "CATALOG_KEY": "8576",
     "PRO_NOMBRE": "'RECONSTRUCCIÓN TOTAL DE MAMA,COLGAJO GAP, LIBRE (CON ARTERIA GLÚTEA PERFORANTE)"
    },
    {
     "CATALOG_KEY": "8579",
     "PRO_NOMBRE": "'OTRA RECONSTRUCCIÓN TOTAL DE MAMA"
    },
    {
     "CATALOG_KEY": "858",
     "PRO_NOMBRE": "OTRAS OPERACIONES DE REPARACIÓN Y PLÁSTICAS SOBRE MAMA"
    },
    {
     "CATALOG_KEY": "8581",
     "PRO_NOMBRE": "SUTURA DE DESGARRO DE LA MAMA"
    },
    {
     "CATALOG_KEY": "8582",
     "PRO_NOMBRE": "INJERTO DE GROSOR PARCIAL EN LA PIEL DE LA MAMA"
    },
    {
     "CATALOG_KEY": "8583",
     "PRO_NOMBRE": "INJERTO DE GROSOR TOTAL EN LA PIEL DE LA MAMA"
    },
    {
     "CATALOG_KEY": "8584",
     "PRO_NOMBRE": "COLGAJO SOBRE LA MAMA"
    },
    {
     "CATALOG_KEY": "8585",
     "PRO_NOMBRE": "COLGAJO MUSCULAR SOBRE REGIÓN MAMARIA"
    },
    {
     "CATALOG_KEY": "8586",
     "PRO_NOMBRE": "TRANSPOSICIÓN DE PEZÓN"
    },
    {
     "CATALOG_KEY": "8587",
     "PRO_NOMBRE": "OTRA REPARACIÓN O RECONSTRUCCIÓN DE PEZÓN"
    },
    {
     "CATALOG_KEY": "8589",
     "PRO_NOMBRE": "OTRA MAMOPLASTIA"
    },
    {
     "CATALOG_KEY": "859",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE LA MAMA"
    },
    {
     "CATALOG_KEY": "8591",
     "PRO_NOMBRE": "ASPIRACIÓN DE MAMA"
    },
    {
     "CATALOG_KEY": "8592",
     "PRO_NOMBRE": "INYECCIÓN DE AGENTE TERAPÉUTICO EN MAMA"
    },
    {
     "CATALOG_KEY": "8593",
     "PRO_NOMBRE": "REVISIÓN DE IMPLANTE DE MAMA"
    },
    {
     "CATALOG_KEY": "8594",
     "PRO_NOMBRE": "EXTRACCIÓN DE IMPLANTE DE MAMA"
    },
    {
     "CATALOG_KEY": "8595",
     "PRO_NOMBRE": "INSERCIÓN DE EXPANSORES DEL TEJIDO MAMARIO"
    },
    {
     "CATALOG_KEY": "8596",
     "PRO_NOMBRE": "EXTRACCIÓN DE EXPANSORES DE TEJIDO MAMARIO"
    },
    {
     "CATALOG_KEY": "8599",
     "PRO_NOMBRE": "OTRA OPERACIÓN SOBRE LA MAMA"
    },
    {
     "CATALOG_KEY": "86",
     "PRO_NOMBRE": "OPERACIONES SOBRE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "860",
     "PRO_NOMBRE": "INCISIÓN DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8601",
     "PRO_NOMBRE": "ASPIRACIÓN DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8602",
     "PRO_NOMBRE": "INYECCIÓN O TATUAJE DE LESIÓN O DEFECTO DE PIEL"
    },
    {
     "CATALOG_KEY": "8603",
     "PRO_NOMBRE": "INCISIÓN DE SENO O QUISTE PILONIDAL"
    },
    {
     "CATALOG_KEY": "8604",
     "PRO_NOMBRE": "OTRA INCISIÓN CON DRENAJE DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8605",
     "PRO_NOMBRE": "INCISIÓN CON EXTRACCIÓN DE CUERPO EXTRAÑO O DISPOSITIVO DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8606",
     "PRO_NOMBRE": "INSERCIÓN DE BOMBA DE INFUSIÓN TOTALMENTE IMPLANTABLE"
    },
    {
     "CATALOG_KEY": "8607",
     "PRO_NOMBRE": "INSERCIÓN DE DISPOSITIVO DE ACCESO VASCULAR [DAV] TOTALMENTE IMPLANTABLE"
    },
    {
     "CATALOG_KEY": "8609",
     "PRO_NOMBRE": "OTRA INCISIÓN DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "861",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8611",
     "PRO_NOMBRE": "BIOPSIA DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8619",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS SOBRE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "862",
     "PRO_NOMBRE": "EXTIRPACIÓN O DESTRUCCIÓN DE LESIÓN O TEJIDO DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8621",
     "PRO_NOMBRE": "EXTIRPACIÓN DE QUISTE O SENO PILONIDAL"
    },
    {
     "CATALOG_KEY": "8622",
     "PRO_NOMBRE": "DESBRIDAMIENTO EXCISIONAL DE HERIDA, INFECCIÓN O QUEMADURA"
    },
    {
     "CATALOG_KEY": "8623",
     "PRO_NOMBRE": "EXTRACCIÓN DE UÑA, LECHO DE UÑA O PLIEGUE DE UÑA"
    },
    {
     "CATALOG_KEY": "8624",
     "PRO_NOMBRE": "QUIMIOCIRUGÍA DE PIEL"
    },
    {
     "CATALOG_KEY": "8625",
     "PRO_NOMBRE": "ABRASIÓN DÉRMICA"
    },
    {
     "CATALOG_KEY": "8626",
     "PRO_NOMBRE": "LIGADURA DE APÉNDICE DÉRMICO"
    },
    {
     "CATALOG_KEY": "8627",
     "PRO_NOMBRE": "DESBRIDAMIENTO DE UÑA, BASE DE UÑA O PLIEGUE DE UÑA"
    },
    {
     "CATALOG_KEY": "8628",
     "PRO_NOMBRE": "DESBRIDAMIENTO NO EXCISIONAL DE HERIDA, INFECCIÓN O QUEMADURA"
    },
    {
     "CATALOG_KEY": "863",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "863X",
     "PRO_NOMBRE": "OTRA EXTIRPACIÓN LOCAL O DESTRUCCIÓN DE LESIÓN O TEJIDO DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "864",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE LESIÓN CUTÁNEA"
    },
    {
     "CATALOG_KEY": "864X",
     "PRO_NOMBRE": "EXTIRPACIÓN RADICAL DE LESIÓN CUTÁNEA"
    },
    {
     "CATALOG_KEY": "865",
     "PRO_NOMBRE": "SUTURA U OTRO CIERRE DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8651",
     "PRO_NOMBRE": "REIMPLANTACIÓN DE CUERO CABELLUDO"
    },
    {
     "CATALOG_KEY": "8659",
     "PRO_NOMBRE": "CIERRE DE PIEL Y TEJIDO SUBCUTÁNEO DE OTROS SITIOS"
    },
    {
     "CATALOG_KEY": "866",
     "PRO_NOMBRE": "INJERTO CUTÁNEO LIBRE"
    },
    {
     "CATALOG_KEY": "8660",
     "PRO_NOMBRE": "INJERTO CUTÁNEO LIBRE NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8661",
     "PRO_NOMBRE": "INJERTO CUTÁNEO DE ESPESOR TOTAL A MANO"
    },
    {
     "CATALOG_KEY": "8662",
     "PRO_NOMBRE": "OTRO INJERTO CUTÁNEO A MANO"
    },
    {
     "CATALOG_KEY": "8663",
     "PRO_NOMBRE": "INJERTO CUTÁNEO DE GROSOR TOTAL A OTROS SITIOS"
    },
    {
     "CATALOG_KEY": "8664",
     "PRO_NOMBRE": "TRASPLANTE DE PELO"
    },
    {
     "CATALOG_KEY": "8665",
     "PRO_NOMBRE": "HETEROINJERTO DE PIEL"
    },
    {
     "CATALOG_KEY": "8666",
     "PRO_NOMBRE": "HOMOINJERTO DE PIEL"
    },
    {
     "CATALOG_KEY": "8667",
     "PRO_NOMBRE": "INJERTO REGENERATIVO DE PIEL"
    },
    {
     "CATALOG_KEY": "8669",
     "PRO_NOMBRE": "OTRO INJERTO CUTÁNEO A OTROS SITIOS"
    },
    {
     "CATALOG_KEY": "867",
     "PRO_NOMBRE": "INJERTOS DE PEDÍCULOS O COLGAJOS"
    },
    {
     "CATALOG_KEY": "8670",
     "PRO_NOMBRE": "INJERTO DE PEDÍCULO O COLGAJO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8671",
     "PRO_NOMBRE": "CORTE Y PREPARACIÓN DE INJERTOS DE PEDÍCULOS O COLGAJOS"
    },
    {
     "CATALOG_KEY": "8672",
     "PRO_NOMBRE": "AVANCE DE PEDÍCULO O COLGAJO"
    },
    {
     "CATALOG_KEY": "8673",
     "PRO_NOMBRE": "FIJACIÓN DE INJERTO DE PEDÍCULO O COLGAJO A MANO"
    },
    {
     "CATALOG_KEY": "8674",
     "PRO_NOMBRE": "FIJACIÓN DE INJERTO DE PEDÍCULO O COLGAJO A OTROS SITIOS"
    },
    {
     "CATALOG_KEY": "8675",
     "PRO_NOMBRE": "REVISIÓN DE INJERTO O COLGAJO"
    },
    {
     "CATALOG_KEY": "868",
     "PRO_NOMBRE": "OTRA REPARACIÓN Y RECONSTRUCCIÓN DE PIEL DE TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8681",
     "PRO_NOMBRE": "REPARACIÓN DE DEBILIDAD FACIAL"
    },
    {
     "CATALOG_KEY": "8682",
     "PRO_NOMBRE": "RITIDECTOMÍA FACIAL"
    },
    {
     "CATALOG_KEY": "8683",
     "PRO_NOMBRE": "OPERACIÓN PLÁSTICA DE REDUCCIÓN DE TAMAÑO"
    },
    {
     "CATALOG_KEY": "8684",
     "PRO_NOMBRE": "RELAJACIÓN DE CICATRIZ O DE CONTRACTURA RETICULADA DE PIEL"
    },
    {
     "CATALOG_KEY": "8685",
     "PRO_NOMBRE": "CORRECCIÓN DE SINDACTILIA"
    },
    {
     "CATALOG_KEY": "8686",
     "PRO_NOMBRE": "ONICOPLASTIA"
    },
    {
     "CATALOG_KEY": "8689",
     "PRO_NOMBRE": "OTRA REPARACIÓN Y RECONSTRUCCIÓN DE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "869",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PIEL Y TEJIDO SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "8691",
     "PRO_NOMBRE": "EXCISIÓN DE PIEL PARA INJERTO"
    },
    {
     "CATALOG_KEY": "8692",
     "PRO_NOMBRE": "ELECTROLISIS Y OTRA DEPILACIÓN DE PIEL"
    },
    {
     "CATALOG_KEY": "8693",
     "PRO_NOMBRE": "INSERCIÓN DE EXPANSOR DE TEJIDOS"
    },
    {
     "CATALOG_KEY": "8694",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE NEUROESTIMULADOR GENERADOR DE PULSOS DE UN SOLO ELECTRODO NO ESPECIFICADO COMO RECARGABLE"
    },
    {
     "CATALOG_KEY": "8695",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE NEUROESTIMULADOR GENERADOR DE PULSOS DE DISPOSICIÓN DUAL"
    },
    {
     "CATALOG_KEY": "8696",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE OTRO NEUROESTIMULADOR GENERADOR DE PULSOS"
    },
    {
     "CATALOG_KEY": "8697",
     "PRO_NOMBRE": "INSERCIÓN O SUSTITUCIÓN DE OTRO NEUROESTIMULADOR GENERADOR DE PULSOS RECARGABLE DE ELECTRODO ÚNICO"
    },
    {
     "CATALOG_KEY": "8698",
     "PRO_NOMBRE": "INSERSIÓN O SUSTITUCIÓN DE NEUROESTIMULADOR GENERADOS DR PULSOS RECARGABLE DE DOBLE ELECTRODO"
    },
    {
     "CATALOG_KEY": "8699",
     "PRO_NOMBRE": "OTRAS OPERACIONES SOBRE PIEL Y TEJIDO CELULAR SUBCUTÁNEO"
    },
    {
     "CATALOG_KEY": "00",
     "PRO_NOMBRE": "PROCEDIMIENTOS DIAGNÓSTICOS Y TERAPÉUTICOS MISCELÁNEOS (87 - 99)"
    },
    {
     "CATALOG_KEY": "87",
     "PRO_NOMBRE": "RADIOLOGÍA DIAGNÓSTICA"
    },
    {
     "CATALOG_KEY": "870",
     "PRO_NOMBRE": "RADIOGRAFÍA DE TEJIDO BLANDO DE CARA, CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "8701",
     "PRO_NOMBRE": "NEUMOENCEFALOGRAMA"
    },
    {
     "CATALOG_KEY": "8702",
     "PRO_NOMBRE": "OTRAS RADIOGRAFIAS DE CONTRASTE DE CEREBRO Y CRÁNEO"
    },
    {
     "CATALOG_KEY": "8703",
     "PRO_NOMBRE": "TOMOGRAFÍA AXIAL COMPUTARIZADA DE CABEZA"
    },
    {
     "CATALOG_KEY": "8704",
     "PRO_NOMBRE": "OTRA TOMOGRAFIA DE CABEZA"
    },
    {
     "CATALOG_KEY": "8705",
     "PRO_NOMBRE": "DACRIOCISTOGRAFÍA CON CONTRASTE"
    },
    {
     "CATALOG_KEY": "8706",
     "PRO_NOMBRE": "RADIOGRAFÍA CON CONTRASTE DE LA NASOFARINGE"
    },
    {
     "CATALOG_KEY": "8707",
     "PRO_NOMBRE": "LARINGOGRAFÍA CON CONTRASTE"
    },
    {
     "CATALOG_KEY": "8708",
     "PRO_NOMBRE": "LINFANGIOGRAFÍA CERVICAL"
    },
    {
     "CATALOG_KEY": "8709",
     "PRO_NOMBRE": "OTRA RADIOGRAFÍA DE TEJIDO BLANDO DE CARA, CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "871",
     "PRO_NOMBRE": "OTRA RADIOGRAFÍA DE CARA, CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "8711",
     "PRO_NOMBRE": "RADIOGRAFÍA DENTAL DE BOCA ENTERA"
    },
    {
     "CATALOG_KEY": "8712",
     "PRO_NOMBRE": "OTRA RADIOGRAFÍA DENTAL"
    },
    {
     "CATALOG_KEY": "8713",
     "PRO_NOMBRE": "ARTROGRAFIA TEMPOROMANDIBULAR CON CONTRASTE"
    },
    {
     "CATALOG_KEY": "8714",
     "PRO_NOMBRE": "RADIOGRAFÍA CON CONTRASTE DE LA ÓRBITA"
    },
    {
     "CATALOG_KEY": "8715",
     "PRO_NOMBRE": "RADIOGRAFÍA CON CONTRASTE DE LOS SENOS PARANASALES"
    },
    {
     "CATALOG_KEY": "8716",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE LOS HUESOS FACIALES"
    },
    {
     "CATALOG_KEY": "8717",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE CRÁNEO"
    },
    {
     "CATALOG_KEY": "872",
     "PRO_NOMBRE": "RADIOGRAFÍAS DE COLUMNA VERTEBRAL"
    },
    {
     "CATALOG_KEY": "8721",
     "PRO_NOMBRE": "MIELOGRAFÍA CON CONTRASTE"
    },
    {
     "CATALOG_KEY": "8722",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE COLUMNA CERVICAL"
    },
    {
     "CATALOG_KEY": "8723",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE COLUMNA TORÁCICA"
    },
    {
     "CATALOG_KEY": "8724",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE COLUMNA LUMBOSACRA"
    },
    {
     "CATALOG_KEY": "8729",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE COLUMNA VERTEBRAL"
    },
    {
     "CATALOG_KEY": "873",
     "PRO_NOMBRE": "RADIOGRAFÍAS DE TEJIDOS BLANDOS DEL TÓRAX"
    },
    {
     "CATALOG_KEY": "8731",
     "PRO_NOMBRE": "BRONCOGRAFÍA ENDOTRAQUEAL"
    },
    {
     "CATALOG_KEY": "8732",
     "PRO_NOMBRE": "OTRA BONCOGRAFÍA DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8733",
     "PRO_NOMBRE": "NEUMOGRAFÍA MEDIASTÍNICA"
    },
    {
     "CATALOG_KEY": "8734",
     "PRO_NOMBRE": "LINFANGIOGRAFÍA TORÁCICA"
    },
    {
     "CATALOG_KEY": "8735",
     "PRO_NOMBRE": "RADIOGRAFÍA DE CONTRASTE DE CONDUCTOS MAMARIOS (GALACTOGRAFÍA)"
    },
    {
     "CATALOG_KEY": "8736",
     "PRO_NOMBRE": "XEROGRAFÍA DE MAMA"
    },
    {
     "CATALOG_KEY": "8737",
     "PRO_NOMBRE": "OTRAS MAMOGRAFÍAS"
    },
    {
     "CATALOG_KEY": "8738",
     "PRO_NOMBRE": "SINOGRAFÍA DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "8739",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE TEJIDOS BLANDOS DE PARED TORÁCICA"
    },
    {
     "CATALOG_KEY": "874",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE TÓRAX"
    },
    {
     "CATALOG_KEY": "8741",
     "PRO_NOMBRE": "TOMOGRAFÍA AXIAL COMPUTARIZADA DEL TÓRAX"
    },
    {
     "CATALOG_KEY": "8742",
     "PRO_NOMBRE": "OTRAS TOMOGRAFÍAS DE TÓRAX"
    },
    {
     "CATALOG_KEY": "8743",
     "PRO_NOMBRE": "RADIOGRAFÍA DE LAS COSTILLAS, ESTERNÓN Y CLAVÍCULA"
    },
    {
     "CATALOG_KEY": "8744",
     "PRO_NOMBRE": "RADIOGRAFÍA TORÁCICA RUTINARIA, DESCRITA COMO TAL"
    },
    {
     "CATALOG_KEY": "8749",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS TORÁCICAS"
    },
    {
     "CATALOG_KEY": "875",
     "PRO_NOMBRE": "RADIOGRAFÍA DE LAS VÍAS BILIARES"
    },
    {
     "CATALOG_KEY": "8751",
     "PRO_NOMBRE": "COLANGIOGRAFÍA HEPÁTICA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "8752",
     "PRO_NOMBRE": "COLANGIOGRAFÍA INTRAVENOSA"
    },
    {
     "CATALOG_KEY": "8753",
     "PRO_NOMBRE": "COLANGIOGRAFÍA INTRAOPERATORIA"
    },
    {
     "CATALOG_KEY": "8754",
     "PRO_NOMBRE": "OTRAS COLANGIOGRAFÍAS"
    },
    {
     "CATALOG_KEY": "8759",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE VÍAS BILIARES"
    },
    {
     "CATALOG_KEY": "876",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DEL APARATO DIGESTIVO"
    },
    {
     "CATALOG_KEY": "8761",
     "PRO_NOMBRE": "DEGLUCIÓN DE LA PAPILLA"
    },
    {
     "CATALOG_KEY": "8762",
     "PRO_NOMBRE": "SERIE GASTROINTESTINAL SUPERIOR"
    },
    {
     "CATALOG_KEY": "8763",
     "PRO_NOMBRE": "SERIE DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "8764",
     "PRO_NOMBRE": "SERIE GASTRINTESTINAL INFERIOR"
    },
    {
     "CATALOG_KEY": "8765",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DEL INTESTINO"
    },
    {
     "CATALOG_KEY": "8766",
     "PRO_NOMBRE": "PANCREATOGRAFÍA CON CONTRASTE"
    },
    {
     "CATALOG_KEY": "8769",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DEL TUBO DIGESTIVO"
    },
    {
     "CATALOG_KEY": "877",
     "PRO_NOMBRE": "RADIOGRAFÍA DEL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "8771",
     "PRO_NOMBRE": "TOMOGRAFÍA AXIAL COMPUTARIZADA DE RIÑÓN"
    },
    {
     "CATALOG_KEY": "8772",
     "PRO_NOMBRE": "OTRA NEFROTOMOGRAFÍA"
    },
    {
     "CATALOG_KEY": "8773",
     "PRO_NOMBRE": "PIELOGRAFÍA INTRAVENOSA"
    },
    {
     "CATALOG_KEY": "8774",
     "PRO_NOMBRE": "PIELOGRAFÍA RETRÓGRADA"
    },
    {
     "CATALOG_KEY": "8775",
     "PRO_NOMBRE": "PIELOGRAFÍA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "8776",
     "PRO_NOMBRE": "CISTOURETROGRAFÍA RETRÓGRADA"
    },
    {
     "CATALOG_KEY": "8777",
     "PRO_NOMBRE": "OTRA CISTOGRAFÍA"
    },
    {
     "CATALOG_KEY": "8778",
     "PRO_NOMBRE": "CONDUCTOGRAFÍA ILEAL"
    },
    {
     "CATALOG_KEY": "8779",
     "PRO_NOMBRE": "OTRA RADIOGRAFÍA DEL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "878",
     "PRO_NOMBRE": "RADIOGRAFÍA DE ÓRGANOS GENITALES FEMENINOS"
    },
    {
     "CATALOG_KEY": "8781",
     "PRO_NOMBRE": "RADIOGRAFÍA DE ÚTERO GRÁVIDO"
    },
    {
     "CATALOG_KEY": "8782",
     "PRO_NOMBRE": "HISTEROSALPINGOGRAFÍA CON CONTRASTE POR GAS"
    },
    {
     "CATALOG_KEY": "8783",
     "PRO_NOMBRE": "HISTEROSALPINGOGRAFÍA CON CONTRASTE RADIOPACO"
    },
    {
     "CATALOG_KEY": "8784",
     "PRO_NOMBRE": "HISTEROGRAFÍA PERCUTÁNEA"
    },
    {
     "CATALOG_KEY": "8785",
     "PRO_NOMBRE": "OTRA RADIOGRAFÍA DE TROMPAS DE FALOPIO Y ÚTERO"
    },
    {
     "CATALOG_KEY": "8789",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE ÓRGANOS GENITALES FEMENINOS"
    },
    {
     "CATALOG_KEY": "879",
     "PRO_NOMBRE": "RADIOGRAFÍA DE ÓRGANOS GENITALES MASCULINOS"
    },
    {
     "CATALOG_KEY": "8791",
     "PRO_NOMBRE": "RADIOGRAFÍA CON CONTRASTE DE VESÍCULAS SEMINALES (VESICULOGRAFÍA)"
    },
    {
     "CATALOG_KEY": "8792",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE LA PRÓSTATA Y LAS VESÍCULAS SEMINALES"
    },
    {
     "CATALOG_KEY": "8793",
     "PRO_NOMBRE": "EPIDIDIMOGRAMA DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8794",
     "PRO_NOMBRE": "DEFERENTOGRAFÍA CON CONTRASTE"
    },
    {
     "CATALOG_KEY": "8795",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DEL EPIDÍDIMO Y LOS CONDUCTOS DEFERENTES"
    },
    {
     "CATALOG_KEY": "8799",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
     "CATALOG_KEY": "88",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DIAGNÓSTICAS Y TÉCNICAS CONEXAS"
    },
    {
     "CATALOG_KEY": "880",
     "PRO_NOMBRE": "RADIOGRAFÍA DE TEJIDOS BLANDOS DEL ABDOMEN"
    },
    {
     "CATALOG_KEY": "8801",
     "PRO_NOMBRE": "TOMOGRAFÍA AXIAL COMPUTARIZADA DEL ABDÓMEN"
    },
    {
     "CATALOG_KEY": "8802",
     "PRO_NOMBRE": "OTRAS TOMOGRAFÍAS DE ABDOMEN"
    },
    {
     "CATALOG_KEY": "8803",
     "PRO_NOMBRE": "SINOGRAFÍA DE PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "8804",
     "PRO_NOMBRE": "LINFANGIOGRAFÍA ABDOMINAL"
    },
    {
     "CATALOG_KEY": "8809",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE LOS TEJIDOS BLANDOS DE LA PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "881",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE ABDOMEN"
    },
    {
     "CATALOG_KEY": "8811",
     "PRO_NOMBRE": "RADIOGRAFÍA PÉLVICA CON CONTRASTE OPACO"
    },
    {
     "CATALOG_KEY": "8812",
     "PRO_NOMBRE": "RADIOGRAFÍA PÉLVICA CON CONTRASTE POR GAS"
    },
    {
     "CATALOG_KEY": "8813",
     "PRO_NOMBRE": "OTRA NEUMOGRAFÍA PERITONEAL"
    },
    {
     "CATALOG_KEY": "8814",
     "PRO_NOMBRE": "FISTULOGRAFÍA RETROPERITONEAL"
    },
    {
     "CATALOG_KEY": "8815",
     "PRO_NOMBRE": "NEUMOGRAFÍA RETROPERITONEAL"
    },
    {
     "CATALOG_KEY": "8816",
     "PRO_NOMBRE": "OTRA RADIOGRAFÍA RETROPERITONEAL"
    },
    {
     "CATALOG_KEY": "8819",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE ABDOMEN"
    },
    {
     "CATALOG_KEY": "882",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DE LAS EXTREMIDADES Y LA PELVIS"
    },
    {
     "CATALOG_KEY": "8821",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DEL HOMBRO Y EL BRAZO SUPERIOR"
    },
    {
     "CATALOG_KEY": "8822",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DEL CODO Y EL ANTEBRAZO"
    },
    {
     "CATALOG_KEY": "8823",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DE LA MUÑECA Y LA MANO"
    },
    {
     "CATALOG_KEY": "8824",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DEL MIEMBRO SUPERIOR, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8825",
     "PRO_NOMBRE": "PELVIMETRÍA"
    },
    {
     "CATALOG_KEY": "8826",
     "PRO_NOMBRE": "OTRA RADIOGRAFÍA ÓSEA DE LA PELVIS Y CADERAS"
    },
    {
     "CATALOG_KEY": "8827",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DEL MUSLO, LA RODILLA Y LA PIERNA INFERIOR"
    },
    {
     "CATALOG_KEY": "8828",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DEL TOBILLO Y EL PIE"
    },
    {
     "CATALOG_KEY": "8829",
     "PRO_NOMBRE": "RADIOGRAFÍA ÓSEA DEL MIEMBRO INFERIOR, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "883",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS"
    },
    {
     "CATALOG_KEY": "8831",
     "PRO_NOMBRE": "SERIE ÓSEA"
    },
    {
     "CATALOG_KEY": "8832",
     "PRO_NOMBRE": "ARTROGRAFÍA CON CONTRASTE"
    },
    {
     "CATALOG_KEY": "8833",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DEL ESQUELETO"
    },
    {
     "CATALOG_KEY": "8834",
     "PRO_NOMBRE": "LINFANGIOGRAFÍA DE MIEMBRO SUPERIOR"
    },
    {
     "CATALOG_KEY": "8835",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE LOS TEJIDOS BLANDOS DE MIEMBRO SUPERIOR"
    },
    {
     "CATALOG_KEY": "8836",
     "PRO_NOMBRE": "LINFANGIOGRAFÍA DE MIEMBRO INFERIOR"
    },
    {
     "CATALOG_KEY": "8837",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS DE LOS TEJIDOS BLANDOS DE MIEMBRO INFERIOR"
    },
    {
     "CATALOG_KEY": "8838",
     "PRO_NOMBRE": "OTRA TOMOGRAFÍA AXIAL COMPUTARIZADA"
    },
    {
     "CATALOG_KEY": "8839",
     "PRO_NOMBRE": "OTRAS RADIOGRAFÍAS, NO ESPECIFICADAS"
    },
    {
     "CATALOG_KEY": "884",
     "PRO_NOMBRE": "ARTERIOGRAFÍA UTILIZANDO MEDIOS DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8840",
     "PRO_NOMBRE": "ARTERIOGRAFÍA CON CONTRASTE, ÁREA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "8841",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE LAS ARTERIAS CEREBRALES"
    },
    {
     "CATALOG_KEY": "8842",
     "PRO_NOMBRE": "AORTOGRAFÍA"
    },
    {
     "CATALOG_KEY": "8843",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE LAS ARTERIAS PULMONARES"
    },
    {
     "CATALOG_KEY": "8844",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE OTROS VASOS INTRATORÁCICOS"
    },
    {
     "CATALOG_KEY": "8845",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE ARTERIAS RENALES"
    },
    {
     "CATALOG_KEY": "8846",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE LA PLACENTA"
    },
    {
     "CATALOG_KEY": "8847",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE OTRAS ARTERIAS INTRA-ABDOMINALES"
    },
    {
     "CATALOG_KEY": "8848",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE ARTERIAS FEMORALES Y OTRAS ARTERIAS DE LAS EXTREMIDADES INFERIORES"
    },
    {
     "CATALOG_KEY": "8849",
     "PRO_NOMBRE": "ARTERIOGRAFÍA DE OTRAS ÁREAS ESPECIFICADAS"
    },
    {
     "CATALOG_KEY": "885",
     "PRO_NOMBRE": "ANGIOCARDIOGRAFÍA UTILIZANDO MEDIOS DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8850",
     "PRO_NOMBRE": "ANGIOCARDIOGRAFÍA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8851",
     "PRO_NOMBRE": "ANGIOCARDIOGRAFÍA DE LAS VENAS CAVAS"
    },
    {
     "CATALOG_KEY": "8852",
     "PRO_NOMBRE": "ANGIOCARDIOGRAFÍA DE ESTRUCTURAS CARDIACAS DERECHAS"
    },
    {
     "CATALOG_KEY": "8853",
     "PRO_NOMBRE": "ANGIOCARDIOGRAFÍA DE ESTRUCTURAS CARDIACAS IZQUIERDAS"
    },
    {
     "CATALOG_KEY": "8854",
     "PRO_NOMBRE": "ANGIOCARDIOGRAFÍA COMBINADA DE LAS CAVIDADES DERECHAS E IZQUIERADAS"
    },
    {
     "CATALOG_KEY": "8855",
     "PRO_NOMBRE": "ARTERIOGRAFÍA CORONARIA CON EMPLEO DE UN SOLO CATÉTER"
    },
    {
     "CATALOG_KEY": "8856",
     "PRO_NOMBRE": "ARTERIOGRAFÍA CORONARIA CON EMPLEO DE DOS CATÉTERES"
    },
    {
     "CATALOG_KEY": "8857",
     "PRO_NOMBRE": "OTRAS ARTEROGRAFÍA CORONARIA Y ARTERIOGRAFÍA CORONARIA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "8858",
     "PRO_NOMBRE": "RADIOGRAFÍA CARDIACA DE CONTRASTE NEGATIVO"
    },
    {
     "CATALOG_KEY": "8859",
     "PRO_NOMBRE": "ANGIOGRAFÍA VASCULAR FLUORESCENTE INTRA-OPERATORIA"
    },
    {
     "CATALOG_KEY": "886",
     "PRO_NOMBRE": "FLEBOGRAFÍA"
    },
    {
     "CATALOG_KEY": "8860",
     "PRO_NOMBRE": "FLEBOGRAFÍA CON CONTRASTE, ÁREA NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "8861",
     "PRO_NOMBRE": "FLEBOGRAFÍA DE LAS VENAS DE LA CABEZA Y EL CUELLO UTILIZANDO CONTRASTE"
    },
    {
     "CATALOG_KEY": "8862",
     "PRO_NOMBRE": "FLEBOGRAFÍA DE LAS VENAS PULMONARES UTILIZANDO UN MEDIO DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8863",
     "PRO_NOMBRE": "FLEBOGRAFÍA DE OTRAS VENAS INTRATORÁCICAS UTILIZANDO UN MEDIO DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8864",
     "PRO_NOMBRE": "FLEBOGRAFÍA DEL SISTEMA VENOSO PORTAL UTILIZANDO UN MEDIO DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8865",
     "PRO_NOMBRE": "FLEBOGRAFÍA DE OTRAS VENAS INTRABDOMINALES UTILIZANDO UN MEDIO DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8866",
     "PRO_NOMBRE": "FLEBOGRAFÍA DE VENAS FEMORALES Y OTRAS VENAS DE LOS MIEMBROS INFERIORES UTILIZANDO UN MEDIO DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8867",
     "PRO_NOMBRE": "FLEBOGRAFÍA DE OTRAS ÁREAS ESPECIFICADAS UTILIZANDO UN MEDIO DE CONTRASTE"
    },
    {
     "CATALOG_KEY": "8868",
     "PRO_NOMBRE": "FLEBOGRAFÍA DE IMPEDANCIA"
    },
    {
     "CATALOG_KEY": "887",
     "PRO_NOMBRE": "ECOGRAFÍA DIAGNÓSTICA"
    },
    {
     "CATALOG_KEY": "8871",
     "PRO_NOMBRE": "ULTRASONOGRAFÍA DIAGNÓSTICA DE LA CABEZA Y EL CUELLO"
    },
    {
     "CATALOG_KEY": "8872",
     "PRO_NOMBRE": "ULTRASONOGRAFÍA DIAGNÓSTICA DEL CORAZÓN"
    },
    {
     "CATALOG_KEY": "8873",
     "PRO_NOMBRE": "ULTRASONOGRAFÍA DIAGNÓSTICA DE OTRAS ÁREAS DEL TORÁX"
    },
    {
     "CATALOG_KEY": "8874",
     "PRO_NOMBRE": "ULTRASONOGRAFÍA DIAGNÓSTICA DEL APARATO DIGESTIVO"
    },
    {
     "CATALOG_KEY": "8875",
     "PRO_NOMBRE": "ULTRASONOGRAFÍA DIAGNÓSTICA DEL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "8876",
     "PRO_NOMBRE": "ULTRASONOGRAFIA DIAGNÓSTICA DEL ABDOMEN Y EL RETROPERITONEO"
    },
    {
     "CATALOG_KEY": "8877",
     "PRO_NOMBRE": "ULTRASONOGRAFÍA DIAGNÓSTICA DEL SISTEMA VASCULAR PERIFÉRICO"
    },
    {
     "CATALOG_KEY": "8878",
     "PRO_NOMBRE": "ULTRASONOGRAFÍA DIAGNÓSTICA DEL ÚTERO GRÁVIDO"
    },
    {
     "CATALOG_KEY": "8879",
     "PRO_NOMBRE": "OTRAS ULTRASONOGRAFÍAS DIAGNÓSTICAS"
    },
    {
     "CATALOG_KEY": "888",
     "PRO_NOMBRE": "TERMOGRAFÍA"
    },
    {
     "CATALOG_KEY": "8881",
     "PRO_NOMBRE": "TERMOGRAFÍA CEREBRAL"
    },
    {
     "CATALOG_KEY": "8882",
     "PRO_NOMBRE": "TERMOGRAFÍA OCULAR"
    },
    {
     "CATALOG_KEY": "8883",
     "PRO_NOMBRE": "TERMOGRAFÍA ÓSEA"
    },
    {
     "CATALOG_KEY": "8884",
     "PRO_NOMBRE": "TERMOGRAFÍA DEL MÚSCULO"
    },
    {
     "CATALOG_KEY": "8885",
     "PRO_NOMBRE": "TERMOGRAFÍA DE LA MAMA"
    },
    {
     "CATALOG_KEY": "8886",
     "PRO_NOMBRE": "TERMOGRAFÍA DE LOS VASOS SANGUÍNEOS"
    },
    {
     "CATALOG_KEY": "8889",
     "PRO_NOMBRE": "TERMOGRAFÍA DE OTRAS ÁREAS"
    },
    {
     "CATALOG_KEY": "889",
     "PRO_NOMBRE": "OTRAS TÉCNICAS DE DIAGNÓSTICO POR IMAGEN"
    },
    {
     "CATALOG_KEY": "8890",
     "PRO_NOMBRE": "TÉCNICAS DE DIAGNÓSTICO POR LAS IMÁGENES, NO CLASIFICADAS EN OTRO APARTADO"
    },
    {
     "CATALOG_KEY": "8891",
     "PRO_NOMBRE": "IMAGEN POR RESONANCIA MAGNÉTICA DEL CEREBRO Y EL TRONCO DEL ENCÉFALO"
    },
    {
     "CATALOG_KEY": "8892",
     "PRO_NOMBRE": "IMAGEN POR RESONANCIA MAGNÉTICA DEL TORÁX Y EL MIOCARDIO"
    },
    {
     "CATALOG_KEY": "8893",
     "PRO_NOMBRE": "IMAGEN POR RESONANCIA MAGNÉTICA DEL RAQUIS"
    },
    {
     "CATALOG_KEY": "8894",
     "PRO_NOMBRE": "IMAGEN POR RESONANCIA MAGNÉTICA DEL SISTEMA MUSCULOESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "8895",
     "PRO_NOMBRE": "IMAGEN POR RESONANCIA MAGNÉTICA DE LA PELVIS, LA PRÓSTATA, Y LA VEJIGA"
    },
    {
     "CATALOG_KEY": "8896",
     "PRO_NOMBRE": "OTRA RESONANCIA MAGNÉTICA INTRAOPERATORIA"
    },
    {
     "CATALOG_KEY": "8897",
     "PRO_NOMBRE": "IMÁGENES POR RESONANCIA DE OTRAS ÁREAS Y DE ÁREAS NO ESPECIFICADAS"
    },
    {
     "CATALOG_KEY": "8898",
     "PRO_NOMBRE": "ESTUDIOS DE LA DENSIDAD MINERAL DEL HUESO"
    },
    {
     "CATALOG_KEY": "89",
     "PRO_NOMBRE": "ENTREVISTA, CONSULTA Y EVALUACIÓN DIAGNÓSTICA"
    },
    {
     "CATALOG_KEY": "890",
     "PRO_NOMBRE": "ENTREVISTA, CONSULTA Y EVALUACIÓN DIAGNÓSTICAS"
    },
    {
     "CATALOG_KEY": "8901",
     "PRO_NOMBRE": "ENTREVISTA Y EVALUACIÓN, DESCRITAS COMO BREVES"
    },
    {
     "CATALOG_KEY": "8902",
     "PRO_NOMBRE": "ENTREVISTA Y EVALUACIÓN, DESCRITAS COMO LIMITADAS"
    },
    {
     "CATALOG_KEY": "8903",
     "PRO_NOMBRE": "ENTREVISTA Y EVALUACIÓN, DESCRITAS COMO GLOBALES"
    },
    {
     "CATALOG_KEY": "8904",
     "PRO_NOMBRE": "OTRA ENTREVISTA Y EVALUACIÓN"
    },
    {
     "CATALOG_KEY": "8905",
     "PRO_NOMBRE": "ENTREVISTA Y EVALUACIÓN DIAGNÓSTICAS, NO ESPECIFICADAS DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "8906",
     "PRO_NOMBRE": "CONSULTA DESCRITA COMO LIMITADA"
    },
    {
     "CATALOG_KEY": "8907",
     "PRO_NOMBRE": "CONSULTA DESCRITA COMO GLOBAL"
    },
    {
     "CATALOG_KEY": "8908",
     "PRO_NOMBRE": "OTRA CONSULTA"
    },
    {
     "CATALOG_KEY": "8909",
     "PRO_NOMBRE": "CONSULTA NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "891",
     "PRO_NOMBRE": "MEDICIONES ANATÓMICAS Y FISIOLÓGICAS Y EXÁMENES MANUALES - SISTEMA NERVIOSO Y ÓRGANOS DE SENTIDO"
    },
    {
     "CATALOG_KEY": "8910",
     "PRO_NOMBRE": "PRUEBA DEL AMOBARBITAL INTRACARÓTIDO"
    },
    {
     "CATALOG_KEY": "8911",
     "PRO_NOMBRE": "TONOMETRÍA"
    },
    {
     "CATALOG_KEY": "8912",
     "PRO_NOMBRE": "ESTUDIO DE FUNCIÓN NASAL"
    },
    {
     "CATALOG_KEY": "8913",
     "PRO_NOMBRE": "EXAMEN NEUROLÓGICO"
    },
    {
     "CATALOG_KEY": "8914",
     "PRO_NOMBRE": "ELECTROENCEFALOGRAMA"
    },
    {
     "CATALOG_KEY": "8915",
     "PRO_NOMBRE": "OTRAS PRUEBAS DE FUNCIONAMIENTO NEUROLÓGICO NO QUIRÚRGICAS"
    },
    {
     "CATALOG_KEY": "8916",
     "PRO_NOMBRE": "TRANSILUMINACIÓN CRANEAL DE RECIÉN NACIDO"
    },
    {
     "CATALOG_KEY": "8917",
     "PRO_NOMBRE": "POLISOMNOGRAMA"
    },
    {
     "CATALOG_KEY": "8918",
     "PRO_NOMBRE": "OTRAS PRUEBAS FUNCIONALES DEL TRASTORNO DEL SUEÑO"
    },
    {
     "CATALOG_KEY": "8919",
     "PRO_NOMBRE": "MONITORIZACIÓN ELECTROENCEFALOGRÁFICA POR VIDEO Y RADIO"
    },
    {
     "CATALOG_KEY": "892",
     "PRO_NOMBRE": "MEDICIONES ANATÓMICAS Y FISIOLÓGICAS Y EXÁMENES MANUALES - APARATO GENITOURINARIO"
    },
    {
     "CATALOG_KEY": "8921",
     "PRO_NOMBRE": "MANOMETRÍA URINARIA"
    },
    {
     "CATALOG_KEY": "8922",
     "PRO_NOMBRE": "CISTOMETROGRAMA"
    },
    {
     "CATALOG_KEY": "8923",
     "PRO_NOMBRE": "ELECTROMIOGRAMA DE ESFÍNTER URETRAL"
    },
    {
     "CATALOG_KEY": "8924",
     "PRO_NOMBRE": "UROFLUJOMETRÍA [UMF]"
    },
    {
     "CATALOG_KEY": "8925",
     "PRO_NOMBRE": "PERFIL DE PRESIÓN URETRAL [PPU]"
    },
    {
     "CATALOG_KEY": "8926",
     "PRO_NOMBRE": "EXAMEN GINECOLÓGICO"
    },
    {
     "CATALOG_KEY": "8929",
     "PRO_NOMBRE": "OTRAS MEDICIONES DEL APARATO GENITOURINARIO NO OPERATORIAS"
    },
    {
     "CATALOG_KEY": "893",
     "PRO_NOMBRE": "OTRAS MEDICIONES ANATÓMICAS Y FISIOLÓGICAS Y OTROS EXÁMENES MANUALES"
    },
    {
     "CATALOG_KEY": "8931",
     "PRO_NOMBRE": "EXAMEN DENTAL"
    },
    {
     "CATALOG_KEY": "8932",
     "PRO_NOMBRE": "MANOMETRÍA ESOFÁGICA"
    },
    {
     "CATALOG_KEY": "8933",
     "PRO_NOMBRE": "EXAMEN DIGITAL DE ESTOMA DE ENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "8934",
     "PRO_NOMBRE": "EXAMEN DIGITAL DE RECTO"
    },
    {
     "CATALOG_KEY": "8935",
     "PRO_NOMBRE": "TRANSILUMINACIÓN DE SENOS NASALES"
    },
    {
     "CATALOG_KEY": "8936",
     "PRO_NOMBRE": "EXAMEN MANUAL DE MAMA"
    },
    {
     "CATALOG_KEY": "8937",
     "PRO_NOMBRE": "DETERMINACIÓN DE CAPACIDAD VITAL"
    },
    {
     "CATALOG_KEY": "8938",
     "PRO_NOMBRE": "OTRAS MEDICIONES RESPIRATORIAS NO QUIRÚRGICAS"
    },
    {
     "CATALOG_KEY": "8939",
     "PRO_NOMBRE": "OTRAS MEDICIONES Y EXÁMENES NO QUIRÚRGICOS"
    },
    {
     "CATALOG_KEY": "894",
     "PRO_NOMBRE": "PRUEBAS DE STRESS CARDIACO Y REVISIONES DE MARCAPASOS Y DE DESFRIBILADOR"
    },
    {
     "CATALOG_KEY": "8941",
     "PRO_NOMBRE": "PRUEBA DE ESFUERZO CARDIOVASCULAR CON EMPLEO DE CINTA RODANTE"
    },
    {
     "CATALOG_KEY": "8942",
     "PRO_NOMBRE": "PRUEBA DE ESFUERZO EN DOS FASES DE MASTERS"
    },
    {
     "CATALOG_KEY": "8943",
     "PRO_NOMBRE": "PRUEBA DE ESFUERZO CARDIOVASCULAR CON EMPLEO DE ERGÓMETRO DE BICICLETA"
    },
    {
     "CATALOG_KEY": "8944",
     "PRO_NOMBRE": "OTRA PRUEBA DE ESFUERZO CARDIOVASCULAR"
    },
    {
     "CATALOG_KEY": "8945",
     "PRO_NOMBRE": "COMPROBACIÓN DEL RITMO DE MARCAPASOS ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "8946",
     "PRO_NOMBRE": "COMPROBACIÓN DE FORMA DE ONDA DE ARTEFACTO DE MARCAPASOS ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "8947",
     "PRO_NOMBRE": "COMPROBACIÓN DE IMPEDANCIA DE ELECTRODO DE MARCAPASOS ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "8948",
     "PRO_NOMBRE": "COMPROBACIÓN DE UMBRAL DE TENSIÓN O AMPERAJE DE MARCAPASOS ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "8949",
     "PRO_NOMBRE": "REVISIÓN DE CARDIOVERSOR/DESFIBRILADOR AUTOMÁTICO IMPLANTABLE (AICD)"
    },
    {
     "CATALOG_KEY": "895",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS DIAGNÓSTICOS CARDIACOS Y VASCULARES NO QUIRÚRGICOS"
    },
    {
     "CATALOG_KEY": "8950",
     "PRO_NOMBRE": "MONITORIZACIÓN CARDÍACA AMBULATORIA"
    },
    {
     "CATALOG_KEY": "8951",
     "PRO_NOMBRE": "ELECTROCARDIOGRAMA DE RITMO"
    },
    {
     "CATALOG_KEY": "8952",
     "PRO_NOMBRE": "ELECTROCARDIOGRAMA"
    },
    {
     "CATALOG_KEY": "8953",
     "PRO_NOMBRE": "VECTOCARDIOGRAMA (CON ECG)"
    },
    {
     "CATALOG_KEY": "8954",
     "PRO_NOMBRE": "MONITORIZACIÓN ELECTROGRÁFICA"
    },
    {
     "CATALOG_KEY": "8955",
     "PRO_NOMBRE": "FONOCARDIOGRAMA CON DERIVACIÓN DE ECG"
    },
    {
     "CATALOG_KEY": "8956",
     "PRO_NOMBRE": "CAROTIDOGRAMA CON DERIVACIÓN DE ECG"
    },
    {
     "CATALOG_KEY": "8957",
     "PRO_NOMBRE": "APEXCARDIOGRAMA (CON ELECTRODO ECG)"
    },
    {
     "CATALOG_KEY": "8958",
     "PRO_NOMBRE": "PLETISMOGRAMA"
    },
    {
     "CATALOG_KEY": "8959",
     "PRO_NOMBRE": "OTRAS MEDICIONES CARDIACAS Y VASCULARES NO OPERATORIAS"
    },
    {
     "CATALOG_KEY": "896",
     "PRO_NOMBRE": "MONITORIZACIÓN CIRCULATORIA"
    },
    {
     "CATALOG_KEY": "8960",
     "PRO_NOMBRE": "GASOMETRÍA INTRA ARTERIAL CONTINUA"
    },
    {
     "CATALOG_KEY": "8961",
     "PRO_NOMBRE": "MONITORIZACIÓN DE PRESIÓN ARTERIAL SISTÉMICA"
    },
    {
     "CATALOG_KEY": "8962",
     "PRO_NOMBRE": "MONITORIZACIÓN DE PRESIÓN VENOSA CENTRAL"
    },
    {
     "CATALOG_KEY": "8963",
     "PRO_NOMBRE": "MONITORIZACIÓN DE PRESIÓN DE ARTERIAL PULMONAR"
    },
    {
     "CATALOG_KEY": "8964",
     "PRO_NOMBRE": "MONITORIZACIÓN PRESIÓN DE ENCLAVAMIENTO DE ARTERIA PULMONAR"
    },
    {
     "CATALOG_KEY": "8965",
     "PRO_NOMBRE": "MEDICIÓN DE GASES EN LA SANGRE ARTERIAL SISTÉMICA"
    },
    {
     "CATALOG_KEY": "8966",
     "PRO_NOMBRE": "MEDICIÓN DE GASES EN SANGRE VENOSA MIXTA"
    },
    {
     "CATALOG_KEY": "8967",
     "PRO_NOMBRE": "MONITORIZACIÓN DE GASTO CARDIACO POR TÉCNICAS DE CONSUMO DE OXÍGENO"
    },
    {
     "CATALOG_KEY": "8968",
     "PRO_NOMBRE": "MONITORIZACIÓN DE GASTO CARDIACO POR OTRA TÉCNICA"
    },
    {
     "CATALOG_KEY": "8969",
     "PRO_NOMBRE": "MONITORIZACIÓN DE FLUJO SANGUÍNEO CORONARIO"
    },
    {
     "CATALOG_KEY": "897",
     "PRO_NOMBRE": "RECONOCIMIENTO MÉDICO GENERAL"
    },
    {
     "CATALOG_KEY": "897X",
     "PRO_NOMBRE": "RECONOCIMIENTO MÉDICO GENERAL"
    },
    {
     "CATALOG_KEY": "898",
     "PRO_NOMBRE": "AUTOPSIA"
    },
    {
     "CATALOG_KEY": "898X",
     "PRO_NOMBRE": "AUTOPSIA"
    },
    {
     "CATALOG_KEY": "90",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO - I"
    },
    {
     "CATALOG_KEY": "900",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO."
    },
    {
     "CATALOG_KEY": "9001",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9002",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9003",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9004",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9005",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9006",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO. BLOQUE CELULAR Y FROTIS PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9009",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SISTEMA NERVIOSO Y DE LÍQUIDO CEFALORRAQUÍDEO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "901",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO."
    },
    {
     "CATALOG_KEY": "9011",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9012",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9013",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9014",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9015",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9016",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9019",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE GLÁNDULA ENDOCRINA, NO CLASIFICADA BAJO OTRO CONCEPTO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "902",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO."
    },
    {
     "CATALOG_KEY": "9021",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9022",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9023",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9024",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9025",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9026",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9029",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OJO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "903",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE."
    },
    {
     "CATALOG_KEY": "9031",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9032",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE. CULTIVO"
    },
    {
     "CATALOG_KEY": "9033",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9034",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9035",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9036",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9039",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OIDO, NARIZ, GARGANTA Y LARINGE. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "904",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRÁQUEA, BRONQUIO, PLEURA, PULMÓN Y DE OTRA MUESTRA TORÁCICA Y DE OTRA MUESTRA TORÁSICA Y DE ESPUTOS"
    },
    {
     "CATALOG_KEY": "9041",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRÁQUEA, BRONQUIO, PLEURA, PULMÓN Y DE OTRA MUESTRA TORÁCICA Y DE OTRA MUESTRA TORÁSICA Y DE ESPUTOS. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9042",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRÁQUEA, BRONQUIO, PLEURA, PULMÓN Y DE OTRA MUESTRA TORÁCICA Y DE OTRA MUESTRA TORÁSICA Y DE ESPUTOS. CULTIVO"
    },
    {
     "CATALOG_KEY": "9043",
     "PRO_NOMBRE": "TORÁSICA Y DE ESPUTOS"
    },
    {
     "CATALOG_KEY": "9044",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRÁQUEA, BRONQUIO, PLEURA, PULMÓN Y DE OTRA MUESTRA TORÁCICA Y DE OTRA MUESTRA TORÁSICA Y DE ESPUTOS. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9045",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRÁQUEA, BRONQUIO, PLEURA, PULMÓN Y DE OTRA MUESTRA TORÁCICA Y DE OTRA MUESTRA TORÁSICA Y DE ESPUTOS. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9046",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRÁQUEA, BRONQUIO, PLEURA, PULMÓN Y DE OTRA MUESTRA TORÁCICA Y DE OTRA MUESTRA TORÁSICA Y DE ESPUTOS. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9049",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRÁQUEA, BRONQUIO, PLEURA, PULMÓN Y DE OTRA MUESTRA TORÁCICA DE Y DE OTRA MUESTRA TORÁSICA Y DE ESPUTOS. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "905",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE."
    },
    {
     "CATALOG_KEY": "9051",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9052",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE. CULTIVO"
    },
    {
     "CATALOG_KEY": "9053",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9054",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9055",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9056",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9059",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE SANGRE. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "906",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA."
    },
    {
     "CATALOG_KEY": "9061",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9062",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA. CULTIVO"
    },
    {
     "CATALOG_KEY": "9063",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9064",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9065",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9066",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9069",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE BAZO Y DE MÉDULA ÓSEA. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "907",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA."
    },
    {
     "CATALOG_KEY": "9071",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9072",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA. CULTIVO"
    },
    {
     "CATALOG_KEY": "9073",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9074",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9075",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA.TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9076",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9079",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE NÓDULO LINFÁTICO Y DE LINFA. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "908",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO."
    },
    {
     "CATALOG_KEY": "9081",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9082",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9083",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9084",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO. . PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9085",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO. . TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9086",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9089",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASROEINTESTINAL SUPERIOR Y DE VÓMITO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "909",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES."
    },
    {
     "CATALOG_KEY": "9091",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9092",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES. CULTIVO"
    },
    {
     "CATALOG_KEY": "9093",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9094",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9095",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9096",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9099",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GASTROINTESTINAL INFERIOR Y DE DEYECCIONES OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "91",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO - II"
    },
    {
     "CATALOG_KEY": "910",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS"
    },
    {
     "CATALOG_KEY": "9101",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9102",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS. CULTIVO"
    },
    {
     "CATALOG_KEY": "9103",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9104",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9105",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9106",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9109",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HÍGADO, TRACTO BILIAR Y PÁNCREAS. OTRO EXEMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "911",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL"
    },
    {
     "CATALOG_KEY": "9111",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9112",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL. CULTIVO"
    },
    {
     "CATALOG_KEY": "9113",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL. CULTIVO Y SENSIBILIDAD."
    },
    {
     "CATALOG_KEY": "9114",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9115",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9116",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9119",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA PERITONEAL Y RETROPERITONEAL. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "912",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URÉTER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL"
    },
    {
     "CATALOG_KEY": "9121",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URETER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9122",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URETER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL. CULTIVO"
    },
    {
     "CATALOG_KEY": "9123",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URETER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9124",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URETER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9125",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URETER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9126",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URETER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9129",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE RIÑÓN, URETER, TEJIDO PERIRRENAL Y TEJIDO PERIURETRAL. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "913",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL, DE ORINA Y DE SEMEN"
    },
    {
     "CATALOG_KEY": "9131",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL Y DE ORINA Y DE SEMEN. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9132",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL Y DE ORINA Y DE SEMEN. CULTIVO"
    },
    {
     "CATALOG_KEY": "9133",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL Y DE ORINA Y DE SEMEN. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9134",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL Y DE ORINA Y DE SEMEN. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9135",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL Y DE ORINA Y DE SEMEN. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9136",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL Y DE ORINA Y DE SEMEN. BLOQUE CELULAR Y FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9139",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE VEJIGA, URETRA, PRÓSTATA, VESÍCULA SEMINAL, TEJIDO PERIVESICAL Y DE ORINA Y DE SEMEN. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "914",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO"
    },
    {
     "CATALOG_KEY": "9141",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9142",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9143",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9144",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9145",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9146",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9149",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE TRACTO GENITAL FEMENINO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "915",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES"
    },
    {
     "CATALOG_KEY": "9151",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9152",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES. CULTIVO"
    },
    {
     "CATALOG_KEY": "9153",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES. CUTLIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9154",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9155",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9156",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9159",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE APARATO MUSCULOESQUELÉTICO Y DE LÍQUIDO DE ARTICULACIONES. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "916",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO."
    },
    {
     "CATALOG_KEY": "9161",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9162",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9163",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9164",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9165",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9166",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9169",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE PIEL Y DE OTRO TEGUMENTO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "917",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA"
    },
    {
     "CATALOG_KEY": "9171",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9172",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA. CULTIVO"
    },
    {
     "CATALOG_KEY": "9173",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9174",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9175",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9176",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9179",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE HERIDA OPERATORIA. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "918",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO"
    },
    {
     "CATALOG_KEY": "9181",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO. FROTIS BACTERINAO"
    },
    {
     "CATALOG_KEY": "9182",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9183",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9184",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9185",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9186",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9189",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE OTRO SITIO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "919",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "9191",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO. FROTIS BACTERIANO"
    },
    {
     "CATALOG_KEY": "9192",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO. CULTIVO"
    },
    {
     "CATALOG_KEY": "9193",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO. CULTIVO Y SENSIBILIDAD"
    },
    {
     "CATALOG_KEY": "9194",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO. PARASITOLOGÍA"
    },
    {
     "CATALOG_KEY": "9195",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO. TOXICOLOGÍA"
    },
    {
     "CATALOG_KEY": "9196",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO. BLOQUE CELULAR Y FROTIS DE PAPANICOLAU"
    },
    {
     "CATALOG_KEY": "9199",
     "PRO_NOMBRE": "EXAMEN MICROSCÓPICO DE MUESTRA DE SITIO NO ESPECIFICADO. OTRO EXAMEN MICROSCÓPICO"
    },
    {
     "CATALOG_KEY": "92",
     "PRO_NOMBRE": "MEDICINA NUCLEAR"
    },
    {
     "CATALOG_KEY": "920",
     "PRO_NOMBRE": "GAMMAGRAFÍA Y ESTUDIOS ISOTÓPICOS FUNCIONALES"
    },
    {
     "CATALOG_KEY": "9201",
     "PRO_NOMBRE": "GAMMAGRAFÍA Y ESTUDIOS RADIOISÓTOPOS FUNCIONALES DEL TIROIDES"
    },
    {
     "CATALOG_KEY": "9202",
     "PRO_NOMBRE": "GAMMAGRAFÍA Y ESTUDIO ISOTÓPICO FUNCIONAL DEL HÍGADO"
    },
    {
     "CATALOG_KEY": "9203",
     "PRO_NOMBRE": "GAMMAGRAFÍA Y ESTUDIO ISOTÓPICO FUNCIONAL DEL RIÑÓN"
    },
    {
     "CATALOG_KEY": "9204",
     "PRO_NOMBRE": "GAMMAGRAFÍA Y ESTUDIO ISOTÓPICO FUNCIONAL DEL TRACTO GASTROINTESTINAL"
    },
    {
     "CATALOG_KEY": "9205",
     "PRO_NOMBRE": "GAMMAGRAFÍA Y ESTUDIO ISOTÓPICO FUNCIONAL DEL APARATO CARDIOVASCULAR Y SISTEMA HEMATOPOYÉTICO"
    },
    {
     "CATALOG_KEY": "9209",
     "PRO_NOMBRE": "OTROS ESTUDIOS RADIOISOTÓPICOS FUNCIONALES"
    },
    {
     "CATALOG_KEY": "921",
     "PRO_NOMBRE": "OTRAS GAMMAGRAFÍAS"
    },
    {
     "CATALOG_KEY": "9211",
     "PRO_NOMBRE": "GAMMAGRAFÍA DEL CEREBRO"
    },
    {
     "CATALOG_KEY": "9212",
     "PRO_NOMBRE": "GAMMAGRAFÍA DE OTRAS ESTRUCTURAS DE LA CABEZA"
    },
    {
     "CATALOG_KEY": "9213",
     "PRO_NOMBRE": "GAMMAGRAFÍA DE LAS GLÁNDULAS PARATIROIDES"
    },
    {
     "CATALOG_KEY": "9214",
     "PRO_NOMBRE": "GAMMAGRAFÍA ÓSEA"
    },
    {
     "CATALOG_KEY": "9215",
     "PRO_NOMBRE": "GAMMAGRAFÍA DEL PULMÓN"
    },
    {
     "CATALOG_KEY": "9216",
     "PRO_NOMBRE": "GAMMAGRAFÍA DEL SISTEMA LINFÁTICO"
    },
    {
     "CATALOG_KEY": "9217",
     "PRO_NOMBRE": "GAMMAGRAFÍA DE LA PLACENTA"
    },
    {
     "CATALOG_KEY": "9218",
     "PRO_NOMBRE": "GAMMAGRAFÍA DEL CUERPO ENTERO"
    },
    {
     "CATALOG_KEY": "9219",
     "PRO_NOMBRE": "GAMMAGRAFÍA DE OTRAS LOCALIZACIONES"
    },
    {
     "CATALOG_KEY": "922",
     "PRO_NOMBRE": "RADIOTERAPIA Y MEDICINA NUCLEAR"
    },
    {
     "CATALOG_KEY": "9220",
     "PRO_NOMBRE": "INFUSIÓN DE RADIOISÓTOPO LÍQUIDO PARA BRAQUITERAPIA"
    },
    {
     "CATALOG_KEY": "9221",
     "PRO_NOMBRE": "RADIOTERAPIA SUPERFICIAL"
    },
    {
     "CATALOG_KEY": "9222",
     "PRO_NOMBRE": "RADIOTERAPIA CON ORTOVOLTAGE"
    },
    {
     "CATALOG_KEY": "9223",
     "PRO_NOMBRE": "TELERRADIOTERAPIA CON RADIOISÓTOPOS"
    },
    {
     "CATALOG_KEY": "9224",
     "PRO_NOMBRE": "TELERRADIOTERAPIA CON PROTONES"
    },
    {
     "CATALOG_KEY": "9225",
     "PRO_NOMBRE": "TELERRADIOTERAPIA CON ELECTRONES"
    },
    {
     "CATALOG_KEY": "9226",
     "PRO_NOMBRE": "TELERRADIOTERAPIA CON OTRAS PARTÍCULAS"
    },
    {
     "CATALOG_KEY": "9227",
     "PRO_NOMBRE": "IMPLANTACIÓN O INSERCIÓN DE ELEMENTOS RADIOACTIVOS"
    },
    {
     "CATALOG_KEY": "9228",
     "PRO_NOMBRE": "INYECCIÓN O INSTILACIÓN DE RADIOISÓTOPOS"
    },
    {
     "CATALOG_KEY": "9229",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS RADIOTERÁPICOS"
    },
    {
     "CATALOG_KEY": "923",
     "PRO_NOMBRE": "RADIOCIRUGÍA ESTEREOTÁXICA"
    },
    {
     "CATALOG_KEY": "9230",
     "PRO_NOMBRE": "RADIOCIRUGÍA ESTEREOTÁXICA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "9231",
     "PRO_NOMBRE": "RADIOCIRUGÍA DE FUENTE ÚNICA DE FOTONES"
    },
    {
     "CATALOG_KEY": "9232",
     "PRO_NOMBRE": "RADIOCIRUGÍA DE MÚLTIPLES FUENTES DE FOTONES"
    },
    {
     "CATALOG_KEY": "9233",
     "PRO_NOMBRE": "RADIOCIRUGÍA DE PARTÍCULAS"
    },
    {
     "CATALOG_KEY": "9239",
     "PRO_NOMBRE": "RADIOCIRUGÍA ESTEREOTÁXICA, NO CLASIFICADA BAJO OTROS CONCEPTOS"
    },
    {
     "CATALOG_KEY": "924",
     "PRO_NOMBRE": "PROCEDIMIENTOS INTRA-OPERATORIOS DE RADIACIÓN"
    },
    {
     "CATALOG_KEY": "9241",
     "PRO_NOMBRE": "PROCEDIMIENTOS INTRA-OPERATORIOS CON ELECTRONES"
    },
    {
     "CATALOG_KEY": "93",
     "PRO_NOMBRE": "MEDICINA FÍSICA Y REHABILITACIÓN Y PROCEDIMIENTOS RELACIONADOS"
    },
    {
     "CATALOG_KEY": "930",
     "PRO_NOMBRE": "DIAGNÓSTICO EN MEDICINA FÍSICA Y REHABILITACIÓN"
    },
    {
     "CATALOG_KEY": "9301",
     "PRO_NOMBRE": "EVALUACIÓN FUNCIONAL"
    },
    {
     "CATALOG_KEY": "9302",
     "PRO_NOMBRE": "EVALUACIÓN ORTÉSICA"
    },
    {
     "CATALOG_KEY": "9303",
     "PRO_NOMBRE": "EVALUACIÓN PROTÉSICA"
    },
    {
     "CATALOG_KEY": "9304",
     "PRO_NOMBRE": "PRUEBAS MANUALES DE FUNCIÓN MUSCULAR: (BALANCE MUSCULAR)."
    },
    {
     "CATALOG_KEY": "9305",
     "PRO_NOMBRE": "PRUEBAS DE AMPLITUD DE MOVIMIENTO: (BALANCE ARTICULAR)."
    },
    {
     "CATALOG_KEY": "9306",
     "PRO_NOMBRE": "MEDICIÓN DE LONGITUD DE EXTREMIDADES"
    },
    {
     "CATALOG_KEY": "9307",
     "PRO_NOMBRE": "MEDICIONES CORPORALES"
    },
    {
     "CATALOG_KEY": "9308",
     "PRO_NOMBRE": "ELECTROMIOGRAFÍA"
    },
    {
     "CATALOG_KEY": "9309",
     "PRO_NOMBRE": "OTRO PROCEDIMIENTO DIAGNÓSTICOS EN MEDICINA FÍSICA"
    },
    {
     "CATALOG_KEY": "931",
     "PRO_NOMBRE": "EJECICIOS DE FISIOTERAPIA"
    },
    {
     "CATALOG_KEY": "9311",
     "PRO_NOMBRE": "EJERCICIO ASISTIDO"
    },
    {
     "CATALOG_KEY": "9312",
     "PRO_NOMBRE": "OTRO EJERCICIO ACTIVO MUSCULOESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "9313",
     "PRO_NOMBRE": "EJERCICIO RESISTIDOS"
    },
    {
     "CATALOG_KEY": "9314",
     "PRO_NOMBRE": "ENTRENAMIENTO EN MOVIMIENTOS DE ARTICULACIONES"
    },
    {
     "CATALOG_KEY": "9315",
     "PRO_NOMBRE": "MOVILIZACIÓN DE COLUMNA"
    },
    {
     "CATALOG_KEY": "9316",
     "PRO_NOMBRE": "MOVILIZACIÓN DE OTRAS ARTICULACIONES"
    },
    {
     "CATALOG_KEY": "9317",
     "PRO_NOMBRE": "OTRO EJERCICIO PASIVO MUSCULOESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "9318",
     "PRO_NOMBRE": "EJERCICIO RESPIRATORIO"
    },
    {
     "CATALOG_KEY": "9319",
     "PRO_NOMBRE": "EJERCICIO, NO CLASIFICADO BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "932",
     "PRO_NOMBRE": "OTRA MANIPULACIÓN MUSCULOESQUELÉTICA EN MEDICINA FÍSICA Y REHABILITACIÓN"
    },
    {
     "CATALOG_KEY": "9321",
     "PRO_NOMBRE": "TRACCIÓN MANUAL Y MECÁNICA"
    },
    {
     "CATALOG_KEY": "9322",
     "PRO_NOMBRE": "MARCHA Y ENTRENAMIENTO DE LA MARCHA"
    },
    {
     "CATALOG_KEY": "9323",
     "PRO_NOMBRE": "ADAPTACIÓN DE DISPOSITIVO ORTÉSICO"
    },
    {
     "CATALOG_KEY": "9324",
     "PRO_NOMBRE": "ENTRENAMIENTO EN EL USO DE DISPOSITIVO ORTÉSICO Y PROTÉSICO"
    },
    {
     "CATALOG_KEY": "9325",
     "PRO_NOMBRE": "EXTENSIÓN FORZADA DE MIEMBRO"
    },
    {
     "CATALOG_KEY": "9326",
     "PRO_NOMBRE": "LIBERACIÓN MANUAL DE ADHERENCIAS ARTICULARES"
    },
    {
     "CATALOG_KEY": "9327",
     "PRO_NOMBRE": "ESTIRAMIENTO DE MÚSCULO O TENDÓN"
    },
    {
     "CATALOG_KEY": "9328",
     "PRO_NOMBRE": "ESTIRAMIENTO DE FASCIA"
    },
    {
     "CATALOG_KEY": "9329",
     "PRO_NOMBRE": "OTRA CORRECCIÓN FORZADA DE EXTREMIDAD"
    },
    {
     "CATALOG_KEY": "933",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS TERAPÉUTICOS DE FISIOTERAPIA"
    },
    {
     "CATALOG_KEY": "9331",
     "PRO_NOMBRE": "EJERCICIO ASISTIDO EN PISCINA"
    },
    {
     "CATALOG_KEY": "9332",
     "PRO_NOMBRE": "TRATAMIENTO EN BAÑO DE REMOLINO"
    },
    {
     "CATALOG_KEY": "9333",
     "PRO_NOMBRE": "OTRA HIDROTERAPIA"
    },
    {
     "CATALOG_KEY": "9334",
     "PRO_NOMBRE": "DIATERMIA"
    },
    {
     "CATALOG_KEY": "9335",
     "PRO_NOMBRE": "OTRA TERAPIA TÉRMICA"
    },
    {
     "CATALOG_KEY": "9336",
     "PRO_NOMBRE": "REENTRENAMIENTO CARDIACO"
    },
    {
     "CATALOG_KEY": "9337",
     "PRO_NOMBRE": "ENTRENAMIENTO PRENATAL"
    },
    {
     "CATALOG_KEY": "9338",
     "PRO_NOMBRE": "FISIOTERAPIA COMBINADA SIN MENCIÓN DE LOS COMPONENTES"
    },
    {
     "CATALOG_KEY": "9339",
     "PRO_NOMBRE": "OTRA FISIOTERAPIA"
    },
    {
     "CATALOG_KEY": "934",
     "PRO_NOMBRE": "TRACCIÓN ESQUELÉTICO Y OTRA TRACCIÓN"
    },
    {
     "CATALOG_KEY": "9341",
     "PRO_NOMBRE": "TRACCIÓN ESPINAL CON EMPLEO DE DISPOSITIVO CRANEAL"
    },
    {
     "CATALOG_KEY": "9342",
     "PRO_NOMBRE": "OTRA TRACCIÓN ESPINAL"
    },
    {
     "CATALOG_KEY": "9343",
     "PRO_NOMBRE": "TRACCIÓN ESQUELÉTICA INTERMITENTE"
    },
    {
     "CATALOG_KEY": "9344",
     "PRO_NOMBRE": "OTRA TRACCIÓN ESQUELÉTICA"
    },
    {
     "CATALOG_KEY": "9345",
     "PRO_NOMBRE": "TRACCIÓN DE FÉRULA DE THOMAS"
    },
    {
     "CATALOG_KEY": "9346",
     "PRO_NOMBRE": "OTRA TRACCIÓN CUTÁNEA DE MIEMBROS"
    },
    {
     "CATALOG_KEY": "935",
     "PRO_NOMBRE": "OTRA INMOVILIZACIÓN, PRESIÓN Y CUIDADO DE HERIDA"
    },
    {
     "CATALOG_KEY": "935A",
     "PRO_NOMBRE": "COLOCACIÓN DE BOMBA DE VACÍO (VAC)"
    },
    {
     "CATALOG_KEY": "935B",
     "PRO_NOMBRE": "EXTRACCIÓN DE BOMBA DE VACÍO (VAC)"
    },
    {
     "CATALOG_KEY": "9351",
     "PRO_NOMBRE": "APLICACIÓN DE VENDAJE ENYESADO"
    },
    {
     "CATALOG_KEY": "9352",
     "PRO_NOMBRE": "APLICACIÓN DE SOPORTE CERVICAL"
    },
    {
     "CATALOG_KEY": "9353",
     "PRO_NOMBRE": "APLICACIÓN DE OTRA ESCAYOLA"
    },
    {
     "CATALOG_KEY": "9354",
     "PRO_NOMBRE": "APLICACIÓN DE FÉRULA"
    },
    {
     "CATALOG_KEY": "9355",
     "PRO_NOMBRE": "ALAMBRE DENTAL"
    },
    {
     "CATALOG_KEY": "9356",
     "PRO_NOMBRE": "APLICACIÓN DE VENDAJE DE PRESIÓN"
    },
    {
     "CATALOG_KEY": "9357",
     "PRO_NOMBRE": "APLICACIÓN DE OTRO VENDAJE DE HERIDA"
    },
    {
     "CATALOG_KEY": "9358",
     "PRO_NOMBRE": "APLICACIÓN DE PANTALONES DE PRESIÓN"
    },
    {
     "CATALOG_KEY": "9359",
     "PRO_NOMBRE": "OTRA INMOVILIZACIÓN, PRESIÓN Y CUIDADO DE HERIDA"
    },
    {
     "CATALOG_KEY": "936",
     "PRO_NOMBRE": "TRATAMIENTO MANIPULATIVO OSTEOPÁTICO"
    },
    {
     "CATALOG_KEY": "9361",
     "PRO_NOMBRE": "TRATAMIENTO MANIPULATIVO OSTEOPÁTICO PARA MOVILIZACIÓN GENERAL"
    },
    {
     "CATALOG_KEY": "9362",
     "PRO_NOMBRE": "MANIPULACIÓN OSTEOPÁTICA DE MOVIMIENTO RÁPIDO Y BAJA AMPLITUD DE FUERZA"
    },
    {
     "CATALOG_KEY": "9363",
     "PRO_NOMBRE": "MANIPULAIÓN OSTEOPÁTICA DE MOVIMIENTO LENTO Y GRAN AMPLITUD DE FUERZA"
    },
    {
     "CATALOG_KEY": "9364",
     "PRO_NOMBRE": "TRATAMIENTO MANIPULATIVO OSTEOPÁTICO CON EMPLEO DE FUERZAS ISOTÓNICAS E ISOMÉTRICAS"
    },
    {
     "CATALOG_KEY": "9365",
     "PRO_NOMBRE": "TRATAMIENTO MANIPULATIVO OSTEOPÁTICO CON EMPLEO DE FUERZAS INDIRECTAS"
    },
    {
     "CATALOG_KEY": "9366",
     "PRO_NOMBRE": "TRATAMIENTO MANIPULATIVO OSTEOPÁTICO PARA DESPLAZAR LÍQUIDO DE TEJIDOS"
    },
    {
     "CATALOG_KEY": "9367",
     "PRO_NOMBRE": "OTRO TRATAMIENTO MANIPULATIVO OSTEOPÁTICO ESPECIFICADO"
    },
    {
     "CATALOG_KEY": "937",
     "PRO_NOMBRE": "REHABILITACIÓN DEL HABLA Y DE LECTURA Y REHABILITACIÓN DE CIEGOS"
    },
    {
     "CATALOG_KEY": "9371",
     "PRO_NOMBRE": "ADIESTRAMIENTO DE DISLÉXICOS"
    },
    {
     "CATALOG_KEY": "9372",
     "PRO_NOMBRE": "ADIESTRAMIENTO DE DISFÁSICOS"
    },
    {
     "CATALOG_KEY": "9373",
     "PRO_NOMBRE": "ADIESTRAMIENTO EN EL HABLA ESOFÁGICA"
    },
    {
     "CATALOG_KEY": "9374",
     "PRO_NOMBRE": "ADIESTRAMIENTO PARA CORREGIR DEFECTOS DEL HABLA"
    },
    {
     "CATALOG_KEY": "9375",
     "PRO_NOMBRE": "OTRO ADIESTRAMIENTO Y TERAPIA DEL HABLA"
    },
    {
     "CATALOG_KEY": "9376",
     "PRO_NOMBRE": "ADIESTRAMIENTO EN EMPLEO DE PERRO LAZARILLO PARA CIEGOS"
    },
    {
     "CATALOG_KEY": "9377",
     "PRO_NOMBRE": "EDUCACIÓN EN ESCRITURA BRAILLE O MON"
    },
    {
     "CATALOG_KEY": "9378",
     "PRO_NOMBRE": "OTRA REHABILITACIÓN PARA CIEGOS"
    },
    {
     "CATALOG_KEY": "938",
     "PRO_NOMBRE": "OTRA TERAPIA DE REHABILITACIÓN"
    },
    {
     "CATALOG_KEY": "9381",
     "PRO_NOMBRE": "TERAPIA RECREATIVA"
    },
    {
     "CATALOG_KEY": "9382",
     "PRO_NOMBRE": "TERAPIA EDUCATIVA"
    },
    {
     "CATALOG_KEY": "9383",
     "PRO_NOMBRE": "TERAPIA OCUPACIONAL"
    },
    {
     "CATALOG_KEY": "9384",
     "PRO_NOMBRE": "MUSICOTERAPIA"
    },
    {
     "CATALOG_KEY": "9385",
     "PRO_NOMBRE": "REHABILITACIÓN VOCACIONAL"
    },
    {
     "CATALOG_KEY": "9389",
     "PRO_NOMBRE": "REHABILITACIÓN, NO CLASIFICADA BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "939",
     "PRO_NOMBRE": "TERAPIA RESPIRATORIA"
    },
    {
     "CATALOG_KEY": "9390",
     "PRO_NOMBRE": "VENTILACIÓN MECÁNICA NO INVASIVA"
    },
    {
     "CATALOG_KEY": "9391",
     "PRO_NOMBRE": "RESPIRACIÓN DE PRESIÓN POSITIVA INTERMITENTE [RPPI]"
    },
    {
     "CATALOG_KEY": "9393",
     "PRO_NOMBRE": "MÉTODOS DE RESUSCITACIÓN NO MECÁNICOS"
    },
    {
     "CATALOG_KEY": "9394",
     "PRO_NOMBRE": "MEDICACIÓN RESPIRATORIA ADMINISTRADA MEDIANTE NEBULIZADOR"
    },
    {
     "CATALOG_KEY": "9395",
     "PRO_NOMBRE": "OXIGENACIÓN HIPERBÁRICA"
    },
    {
     "CATALOG_KEY": "9396",
     "PRO_NOMBRE": "OTRO ENRIQUECIMIENTO POR OXÍGENO"
    },
    {
     "CATALOG_KEY": "9397",
     "PRO_NOMBRE": "CÁMARA DE DESCOMPRESIÓN"
    },
    {
     "CATALOG_KEY": "9398",
     "PRO_NOMBRE": "OTRO CONTROL DE PRESIÓN Y COMPOSICIÓN ATMOSFÉRICAS"
    },
    {
     "CATALOG_KEY": "9399",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS RESPIRATORIOS"
    },
    {
     "CATALOG_KEY": "94",
     "PRO_NOMBRE": "PROCEDIMIENTOS RELACIONADOS CON LA PSIQUE"
    },
    {
     "CATALOG_KEY": "940",
     "PRO_NOMBRE": "EVALUACIÓN Y PRUEBAS PSICOLÓGICAS"
    },
    {
     "CATALOG_KEY": "9401",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE PRUEBA DE INTELIGENCIA"
    },
    {
     "CATALOG_KEY": "9402",
     "PRO_NOMBRE": "ADIMINISTRACIÓN DE PRUEBA PSICOLÓGICA"
    },
    {
     "CATALOG_KEY": "9403",
     "PRO_NOMBRE": "ANÁLISIS DE CARÁCTER"
    },
    {
     "CATALOG_KEY": "9408",
     "PRO_NOMBRE": "OTRAS PRUEBAS Y EVALUACIÓN PSICOLÓGICAS"
    },
    {
     "CATALOG_KEY": "9409",
     "PRO_NOMBRE": "DETERMINACIÓN DE ESTADO MENTAL PSICOLÓGICO, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "941",
     "PRO_NOMBRE": "ENTREVISTAS, CONSULTAS Y EVALUACIONES PSIQUIÁTRICAS"
    },
    {
     "CATALOG_KEY": "9411",
     "PRO_NOMBRE": "DETERMINACIÓN DE ESTADO MENTAL PSIQUIÁTRICO"
    },
    {
     "CATALOG_KEY": "9412",
     "PRO_NOMBRE": "CONSULTA PSIQUIÁTRICA RUTINARIA, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "9413",
     "PRO_NOMBRE": "EVALUACIÓN PSIQUIÁTRICA PARA INTERNAMIENTO"
    },
    {
     "CATALOG_KEY": "9419",
     "PRO_NOMBRE": "OTRA ENTREVISTA Y EVALUACIÓN PSIQUIÁTRICAS"
    },
    {
     "CATALOG_KEY": "942",
     "PRO_NOMBRE": "SOMATOTERAPIA PSIQUIÁTRICA"
    },
    {
     "CATALOG_KEY": "9421",
     "PRO_NOMBRE": "NARCOANÁLISIS"
    },
    {
     "CATALOG_KEY": "9422",
     "PRO_NOMBRE": "TERAPIA DE LITIO"
    },
    {
     "CATALOG_KEY": "9423",
     "PRO_NOMBRE": "TERAPIA NEUROLÉPTICA"
    },
    {
     "CATALOG_KEY": "9424",
     "PRO_NOMBRE": "TERAPIA DE SHOCK QUÍMICO"
    },
    {
     "CATALOG_KEY": "9425",
     "PRO_NOMBRE": "OTRA TERAPIA PSIQUIÁTRICA CON FÁRMACOS"
    },
    {
     "CATALOG_KEY": "9426",
     "PRO_NOMBRE": "TERAPIA DE ELECTROSHOCK SUBCONVULSIVO"
    },
    {
     "CATALOG_KEY": "9427",
     "PRO_NOMBRE": "OTRA TERAPIA DE ELECTROSHOCK"
    },
    {
     "CATALOG_KEY": "9429",
     "PRO_NOMBRE": "OTRA SOMATOTERAPIA PSIQUIÁTRICA"
    },
    {
     "CATALOG_KEY": "943",
     "PRO_NOMBRE": "PSICOTERAPIA INDIVIDUAL"
    },
    {
     "CATALOG_KEY": "9431",
     "PRO_NOMBRE": "PSICOANÁLISIS"
    },
    {
     "CATALOG_KEY": "9432",
     "PRO_NOMBRE": "HIPNOTERAPIA"
    },
    {
     "CATALOG_KEY": "9433",
     "PRO_NOMBRE": "TERAPIA DE COMPORTAMIENTO"
    },
    {
     "CATALOG_KEY": "9434",
     "PRO_NOMBRE": "TERAPIA INDIVIDUAL PARA DISFUNCIÓN PSICOSEXUAL"
    },
    {
     "CATALOG_KEY": "9435",
     "PRO_NOMBRE": "INTERVENCIÓN EN CRISIS"
    },
    {
     "CATALOG_KEY": "9436",
     "PRO_NOMBRE": "PSICOTERAPIA DE JUEGO"
    },
    {
     "CATALOG_KEY": "9437",
     "PRO_NOMBRE": "PSICOTERAPIA VERBAL EXPLORATORIA"
    },
    {
     "CATALOG_KEY": "9438",
     "PRO_NOMBRE": "PSICOTERAPIA VERBAL DE APOYO"
    },
    {
     "CATALOG_KEY": "9439",
     "PRO_NOMBRE": "OTRA PSICOTERAPIA INDIVIDUAL"
    },
    {
     "CATALOG_KEY": "944",
     "PRO_NOMBRE": "OTRA PSICOTERAPIA Y ASESORAMIENTO"
    },
    {
     "CATALOG_KEY": "9441",
     "PRO_NOMBRE": "TERAPIA DE GRUPO PARA DISFUNCIÓN PSICOSEXUAL"
    },
    {
     "CATALOG_KEY": "9442",
     "PRO_NOMBRE": "TERAPIA FAMILIAR"
    },
    {
     "CATALOG_KEY": "9443",
     "PRO_NOMBRE": "PSICODRAMA"
    },
    {
     "CATALOG_KEY": "9444",
     "PRO_NOMBRE": "OTRA TERAPIA DE GRUPO"
    },
    {
     "CATALOG_KEY": "9445",
     "PRO_NOMBRE": "ASESORAMIENTO SOBRE TOXICOMANÍA"
    },
    {
     "CATALOG_KEY": "9446",
     "PRO_NOMBRE": "ASESORAMIENTO SOBRE ALCOHOLISMO"
    },
    {
     "CATALOG_KEY": "9449",
     "PRO_NOMBRE": "OTRO ASESORAMIENTO"
    },
    {
     "CATALOG_KEY": "945",
     "PRO_NOMBRE": "REMITIR (AL PACIENTE) HACIA REHABILITACIÓN PSICOLÓGICA"
    },
    {
     "CATALOG_KEY": "9451",
     "PRO_NOMBRE": "REMITIR AL PACIENTE HACIA PSICOTERAPIA"
    },
    {
     "CATALOG_KEY": "9452",
     "PRO_NOMBRE": "REMITIR AL PACIENTE HACIA CUIDADOS PSIQUIÁTRICOS POST-TRATAMIENTO"
    },
    {
     "CATALOG_KEY": "9453",
     "PRO_NOMBRE": "REMITIR AL PACIENTE HACIA REHABILITACIÓN DE ALCOHOLISMO"
    },
    {
     "CATALOG_KEY": "9454",
     "PRO_NOMBRE": "REMITIR AL PACIENTE HACIA RAHABILITACIÓN DE TOXICOMANÍA"
    },
    {
     "CATALOG_KEY": "9455",
     "PRO_NOMBRE": "REMITIR AL PACIENTE HACIA REHABILITACIÓN DE PROFESIONAL"
    },
    {
     "CATALOG_KEY": "9459",
     "PRO_NOMBRE": "REMITIR AL PACIENTE HACIA OTRA REHABILITACIÓN PSICOLÓGICA"
    },
    {
     "CATALOG_KEY": "946",
     "PRO_NOMBRE": "REHABILITACIÓN Y DESINTOXICACIÓN DE ALCOHOL Y DROGAS"
    },
    {
     "CATALOG_KEY": "9461",
     "PRO_NOMBRE": "REHABILITACIÓN DEL ALCOHOL"
    },
    {
     "CATALOG_KEY": "9462",
     "PRO_NOMBRE": "DESINTOXICACIÓN DEL ALCOHOL"
    },
    {
     "CATALOG_KEY": "9463",
     "PRO_NOMBRE": "REHABILITACIÓN Y DESINTOXICACIÓN DEL ALCOHOL"
    },
    {
     "CATALOG_KEY": "9464",
     "PRO_NOMBRE": "REHABILITACIÓN DE DROGAS"
    },
    {
     "CATALOG_KEY": "9465",
     "PRO_NOMBRE": "DESINTOXICACIÓN DE DROGAS"
    },
    {
     "CATALOG_KEY": "9466",
     "PRO_NOMBRE": "REHABILITACIÓN Y DESINTOXICACIÓN DE DROGAS"
    },
    {
     "CATALOG_KEY": "9467",
     "PRO_NOMBRE": "REHABILITACIÓN COMBINADA DE ALCOHOL Y DROGAS"
    },
    {
     "CATALOG_KEY": "9468",
     "PRO_NOMBRE": "DESINTOXICACIÓN COMBINADA DE ALCOHOL Y DROGAS"
    },
    {
     "CATALOG_KEY": "9469",
     "PRO_NOMBRE": "REHABILITACIÓN Y DESINTOXICACIÓN COMBINADA DE ALCOHOL Y DROGAS"
    },
    {
     "CATALOG_KEY": "95",
     "PRO_NOMBRE": "DIAGNÓSTICO Y TRATAMIENTO OFTALMOLÓGICOS Y OTOLÓGICOS"
    },
    {
     "CATALOG_KEY": "950",
     "PRO_NOMBRE": "EXAMEN DE OJOS GENERAL Y SUBJETIVO"
    },
    {
     "CATALOG_KEY": "9501",
     "PRO_NOMBRE": "EXAMEN DE OJOS LIMITADO"
    },
    {
     "CATALOG_KEY": "9502",
     "PRO_NOMBRE": "EXAMEN OFTALMOLÓGICO GLOBAL"
    },
    {
     "CATALOG_KEY": "9503",
     "PRO_NOMBRE": "RECONOCIMIENTO OFTALMOLÓGICO EXTENDIDO"
    },
    {
     "CATALOG_KEY": "9504",
     "PRO_NOMBRE": "EXAMEN OFTALMOLÓGICO BAJO ANESTESIA"
    },
    {
     "CATALOG_KEY": "9505",
     "PRO_NOMBRE": "ESTUDIO DE CAMPO VISUAL"
    },
    {
     "CATALOG_KEY": "9506",
     "PRO_NOMBRE": "ESTUDIO DE VISIÓN DE COLORES"
    },
    {
     "CATALOG_KEY": "9507",
     "PRO_NOMBRE": "ESTUDIO DE ADAPTACIÓN A LA OSCURIDAD"
    },
    {
     "CATALOG_KEY": "9509",
     "PRO_NOMBRE": "EXAMEN OFTALMOLÓGICO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "951",
     "PRO_NOMBRE": "EXÁMENES DE FORMA Y ESTRUCTURA DE OJO"
    },
    {
     "CATALOG_KEY": 9510,
     "PRO_NOMBRE": "FOTOGRAFÍA DEL FONDO DEL OJO CON CÁMARA NO MIDRIÁTICA"
    },
    {
     "CATALOG_KEY": "9511",
     "PRO_NOMBRE": "FOTOGRAFÍA DEL FONDO DEL OJO"
    },
    {
     "CATALOG_KEY": "9512",
     "PRO_NOMBRE": "ANGIOGRAFÍA O ANGIOSCOPIA POR FLUORESCENCIA DEL OJO"
    },
    {
     "CATALOG_KEY": "9513",
     "PRO_NOMBRE": "ESTUDIO DEL OJO CON ULTRASONIDOS (ECOGRAFÍA)"
    },
    {
     "CATALOG_KEY": "9514",
     "PRO_NOMBRE": "ESTUDIO RADIOGRÁFICO DEL OJO"
    },
    {
     "CATALOG_KEY": "9515",
     "PRO_NOMBRE": "ESTUDIO DE MOVILIDAD OCULAR"
    },
    {
     "CATALOG_KEY": "9516",
     "PRO_NOMBRE": "ESTUDIOS DE LOS OJOS CON P32 Y OTROS INDICADORES"
    },
    {
     "CATALOG_KEY": "952",
     "PRO_NOMBRE": "PRUEBAS OBJETIVAS DEL FUNCIONAMIENTO DEL OJO"
    },
    {
     "CATALOG_KEY": "9521",
     "PRO_NOMBRE": "ELECTRORETINOGRAMA [ERG]"
    },
    {
     "CATALOG_KEY": "9522",
     "PRO_NOMBRE": "ELECTRO-OCULOGRAMA [EOG]"
    },
    {
     "CATALOG_KEY": "9523",
     "PRO_NOMBRE": "POTENCIAL VISUAL EVOCADO [PVE]"
    },
    {
     "CATALOG_KEY": "9524",
     "PRO_NOMBRE": "ELECTRONISTAGMOGRAMA [ENG]"
    },
    {
     "CATALOG_KEY": "9525",
     "PRO_NOMBRE": "ELECTROMIOGRAMA DEL OJO [EMG]"
    },
    {
     "CATALOG_KEY": "9526",
     "PRO_NOMBRE": "TONOGRAFÍA, PRUEBAS DE PROVOCACIÓN Y OTRAS PRUEBAS DE GLAUCOMA"
    },
    {
     "CATALOG_KEY": "953",
     "PRO_NOMBRE": "SERVICIOS VISUALES ESPECIALES"
    },
    {
     "CATALOG_KEY": "9531",
     "PRO_NOMBRE": "AJUSTE Y SUMINISTRO DE GAFAS"
    },
    {
     "CATALOG_KEY": "9532",
     "PRO_NOMBRE": "PRESCRIPCIÓN, ADAPTACIÓN Y SUMINISTRO DE LENTES DE CONTACTO"
    },
    {
     "CATALOG_KEY": "9533",
     "PRO_NOMBRE": "SUMINISTRO DE OTRAS AYUDAS A LA BAJA VISIÓN"
    },
    {
     "CATALOG_KEY": "9534",
     "PRO_NOMBRE": "PRÓTESIS OCULARES"
    },
    {
     "CATALOG_KEY": "9535",
     "PRO_NOMBRE": "FORMACIÓN ORTÓPTICA"
    },
    {
     "CATALOG_KEY": "9536",
     "PRO_NOMBRE": "ASESORAMIENTO E INSTRUCCIÓN OFTALMOLÓGICOS"
    },
    {
     "CATALOG_KEY": "954",
     "PRO_NOMBRE": "PROCEDIMIENTOS NO OPERATORIOS RELACIONADOS CON EL OÍDO"
    },
    {
     "CATALOG_KEY": "9541",
     "PRO_NOMBRE": "AUDIOMETRÍA"
    },
    {
     "CATALOG_KEY": "9542",
     "PRO_NOMBRE": "PRUEBA CLÍNICA DE AUDICIÓN"
    },
    {
     "CATALOG_KEY": "9543",
     "PRO_NOMBRE": "EVALUACIÓN AUDIOLÓGICA"
    },
    {
     "CATALOG_KEY": "9544",
     "PRO_NOMBRE": "EXAMEN CLÍNICO DE LA FUNCIÓN VESTIBULAR"
    },
    {
     "CATALOG_KEY": "9545",
     "PRO_NOMBRE": "PRUEBAS ROTATORIAS"
    },
    {
     "CATALOG_KEY": "9546",
     "PRO_NOMBRE": "OTRAS PRUEBAS AUDITIVAS Y VESTIBULARES"
    },
    {
     "CATALOG_KEY": "9547",
     "PRO_NOMBRE": "EXAMEN DE LA AUDICIÓN NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "9548",
     "PRO_NOMBRE": "APLICACIÓN DE AUDÍFONO"
    },
    {
     "CATALOG_KEY": "9549",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS NO OPERATORIOS RELACIONADOS CON EL OÍDO"
    },
    {
     "CATALOG_KEY": "96",
     "PRO_NOMBRE": "INTUBACIÓN E IRRIGACIÓN NO QUIRÚRGICAS"
    },
    {
     "CATALOG_KEY": "960",
     "PRO_NOMBRE": "INTUBACIÓN NO OPERATORIA DE LOS TRACTOS GASTROINTESTINAL Y RESPIRATORIO"
    },
    {
     "CATALOG_KEY": "9601",
     "PRO_NOMBRE": "INSERCIÓN DE VÍA AÉREA NASOFARÍNGEA"
    },
    {
     "CATALOG_KEY": "9602",
     "PRO_NOMBRE": "INSERCIÓN DE VÍA AÉREA OROFARÍNGEA"
    },
    {
     "CATALOG_KEY": "9603",
     "PRO_NOMBRE": "INSERCIÓN DE VÍA AÉREA OBTURADA ESOFÁGICA"
    },
    {
     "CATALOG_KEY": "9604",
     "PRO_NOMBRE": "INSERCIÓN DE TUBO ENDOTRAQUEAL"
    },
    {
     "CATALOG_KEY": "9605",
     "PRO_NOMBRE": "OTRA INTUBACIÓN DEL TRACTO RESPIRATORIO"
    },
    {
     "CATALOG_KEY": "9606",
     "PRO_NOMBRE": "INSERCIÓN DE TUBO DE SENGSTAKEN"
    },
    {
     "CATALOG_KEY": "9607",
     "PRO_NOMBRE": "INSERCIÓN DE OTRO TUBO (NASO-) GÁSTRICO"
    },
    {
     "CATALOG_KEY": "9608",
     "PRO_NOMBRE": "INSERCIÓN DE TUBO (NASO-) INTESTINAL"
    },
    {
     "CATALOG_KEY": "9609",
     "PRO_NOMBRE": "INSERCIÓN DE TUBO RECTAL"
    },
    {
     "CATALOG_KEY": "961",
     "PRO_NOMBRE": "OTRA INSERCIÓN NO QUIRÚRGICA"
    },
    {
     "CATALOG_KEY": "9611",
     "PRO_NOMBRE": "TAPONAMIENTO DEL CANAL AUDITIVO EXTERNO"
    },
    {
     "CATALOG_KEY": "9614",
     "PRO_NOMBRE": "TAPONAMIENTO VAGINAL"
    },
    {
     "CATALOG_KEY": "9615",
     "PRO_NOMBRE": "INSERCIÓN DE MOLDE VAGINAL"
    },
    {
     "CATALOG_KEY": "9616",
     "PRO_NOMBRE": "OTRA DILATACIÓN VAGINAL"
    },
    {
     "CATALOG_KEY": "9617",
     "PRO_NOMBRE": "INSERCIÓN DE DIAFRAGMA VAGINAL"
    },
    {
     "CATALOG_KEY": "9618",
     "PRO_NOMBRE": "INSERCIÓN DE OTRO PESARIO VAGINAL"
    },
    {
     "CATALOG_KEY": "9619",
     "PRO_NOMBRE": "TAPONAMIENTO RECTAL"
    },
    {
     "CATALOG_KEY": "962",
     "PRO_NOMBRE": "DILATACIÓN Y MANIPULACIÓN NO QUIRÚRGICA"
    },
    {
     "CATALOG_KEY": "9621",
     "PRO_NOMBRE": "DILATACIÓN DEL CONDUCTO FRONTONASAL"
    },
    {
     "CATALOG_KEY": "9622",
     "PRO_NOMBRE": "DILATACIÓN DEL RECTO"
    },
    {
     "CATALOG_KEY": "9623",
     "PRO_NOMBRE": "DILATACIÓN DEL ESFÍNTER ANAL"
    },
    {
     "CATALOG_KEY": "9624",
     "PRO_NOMBRE": "DILATACIÓN Y MANIPULACIÓN DE ESTOMA DE ESTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "9625",
     "PRO_NOMBRE": "DISTENSIÓN TERAPÉUTICA DE VEJIGA"
    },
    {
     "CATALOG_KEY": "9626",
     "PRO_NOMBRE": "REDUCCIÓN MANUAL DE PROLAPSO RECTAL"
    },
    {
     "CATALOG_KEY": "9627",
     "PRO_NOMBRE": "REDUCCIÓN MANUAL DE HERNIA"
    },
    {
     "CATALOG_KEY": "9628",
     "PRO_NOMBRE": "REDUCCIÓN MANUAL DE PROLAPSO DE ENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "9629",
     "PRO_NOMBRE": "REDUCCIÓN DE INTUSUSCEPCIÓN DEL TRACTO ALIMENTARIO"
    },
    {
     "CATALOG_KEY": "963",
     "PRO_NOMBRE": "IRRIGACIÓN, LIMPIEZA E INSTALACIÓN LOCAL DEL TRACTO DIGESTIVO NO QUIRÚRGICO"
    },
    {
     "CATALOG_KEY": "9631",
     "PRO_NOMBRE": "ENFRIAMIENTO GÁSTRICO"
    },
    {
     "CATALOG_KEY": "9632",
     "PRO_NOMBRE": "CONGELACIÓN GÁSTRICA"
    },
    {
     "CATALOG_KEY": "9633",
     "PRO_NOMBRE": "LAVADO GÁSTRICO"
    },
    {
     "CATALOG_KEY": "9634",
     "PRO_NOMBRE": "OTRA IRRIGACIÓN DE TUBO (NASO-)GÁSTRICO"
    },
    {
     "CATALOG_KEY": "9635",
     "PRO_NOMBRE": "ALIMENTACIÓN GÁSTRICA POR SONDA"
    },
    {
     "CATALOG_KEY": "9636",
     "PRO_NOMBRE": "IRRIGACIÓN DE GASTROSTOMÍA O DE ENTEROSTOMÍA"
    },
    {
     "CATALOG_KEY": "9637",
     "PRO_NOMBRE": "PROCTOCLISIS"
    },
    {
     "CATALOG_KEY": "9638",
     "PRO_NOMBRE": "EXTRACCIÓN DE HECES IMPACTADAS"
    },
    {
     "CATALOG_KEY": "9639",
     "PRO_NOMBRE": "OTRO ENEMA TRANSANAL"
    },
    {
     "CATALOG_KEY": "964",
     "PRO_NOMBRE": "IRRIGACIÓN, LIMPIEZA E INSTILACIÓN LOCAL NO QUIRÚRGICA DE OTROS ÓRGANOS DIGESTIVOS Y GENITOURINARIOS"
    },
    {
     "CATALOG_KEY": "9641",
     "PRO_NOMBRE": "IRRIGACIÓN DE COLECISTOSTOMÍA Y OTRO TUBO BILIAR"
    },
    {
     "CATALOG_KEY": "9642",
     "PRO_NOMBRE": "IRRIGACIÓN DE TUBO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "9643",
     "PRO_NOMBRE": "INSTALACIÓN DEL TRACTO DIGESTIVO, SALVO ALIMENTACIÓN GÁSTRICA POR SONDA"
    },
    {
     "CATALOG_KEY": "9644",
     "PRO_NOMBRE": "DUCHA VAGINAL"
    },
    {
     "CATALOG_KEY": "9645",
     "PRO_NOMBRE": "IRRIGACIÓN DE NEFROSTOMÍA Y PIELOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9646",
     "PRO_NOMBRE": "IRRIGACIÓN DE URETEROSTOMÍA Y CATÉTER URETERAL"
    },
    {
     "CATALOG_KEY": "9647",
     "PRO_NOMBRE": "IRRIGACIÓN DE CISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9648",
     "PRO_NOMBRE": "IRRIGACIÓN DE OTRO CATÉTER URINARIO PERMANENTE"
    },
    {
     "CATALOG_KEY": "9649",
     "PRO_NOMBRE": "OTRA INSTILACIÓN GENITOURINARIA"
    },
    {
     "CATALOG_KEY": "965",
     "PRO_NOMBRE": "OTRA IRRIGACIÓN Y LIMPIEZA NO QUIRÚRGICA"
    },
    {
     "CATALOG_KEY": "9651",
     "PRO_NOMBRE": "IRRIGACIÓN DEL OJO"
    },
    {
     "CATALOG_KEY": "9652",
     "PRO_NOMBRE": "IRRIGACIÓN DEL OÍDO"
    },
    {
     "CATALOG_KEY": "9653",
     "PRO_NOMBRE": "IRRIGACIÓN DE CONDUCTOS NASALES"
    },
    {
     "CATALOG_KEY": "9654",
     "PRO_NOMBRE": "ELIMINACIÓN DE SARRO, PULIDO Y DESBRIDAMIENTO DE DIENTES"
    },
    {
     "CATALOG_KEY": "9655",
     "PRO_NOMBRE": "LIMPIEZA DE TRAQUEOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9656",
     "PRO_NOMBRE": "OTRO LAVADO DE BRONQUIO Y TRÁQUEA"
    },
    {
     "CATALOG_KEY": "9657",
     "PRO_NOMBRE": "IRRIGACIÓN DE CATÉTER VASCULAR"
    },
    {
     "CATALOG_KEY": "9658",
     "PRO_NOMBRE": "IRRIGACIÓN DE CATÉTER DE HERIDA"
    },
    {
     "CATALOG_KEY": "9659",
     "PRO_NOMBRE": "OTRA IRRIGACIÓN DE HERIDA"
    },
    {
     "CATALOG_KEY": "966",
     "PRO_NOMBRE": "INFUSIÓN ENTERAL DE SUSTANCIAS NUTRITIVAS CONCENTRADAS"
    },
    {
     "CATALOG_KEY": "966X",
     "PRO_NOMBRE": "INFUSIÓN ENTERAL DE SUSTANCIAS NUTRITIVAS CONCENTRADAS"
    },
    {
     "CATALOG_KEY": "967",
     "PRO_NOMBRE": "OTRA VENTILACIÓN MECÁNICA CONTINUA INVASIVA"
    },
    {
     "CATALOG_KEY": "9670",
     "PRO_NOMBRE": "VENTILACIÓN MECÁNICA INVASIVA CONTINUA DE DURACIÓN NO ESPECIFICADA"
    },
    {
     "CATALOG_KEY": "9671",
     "PRO_NOMBRE": "VENTILACIÓN MECÁNICA INVASIVA CONTINUA INFERIOR A 96 HORAS CONSECUTIVAS"
    },
    {
     "CATALOG_KEY": "9672",
     "PRO_NOMBRE": "VENTILACIÓN MECÁNICA INVASIVA CONTINUA DURANTE 96 HORAS CONSECUTIVAS O MÁS"
    },
    {
     "CATALOG_KEY": "97",
     "PRO_NOMBRE": "SUSTITUCIÓN Y EXTRACCIÓN DE DISPOSITIVOS TERAPÉUTICOS"
    },
    {
     "CATALOG_KEY": "970",
     "PRO_NOMBRE": "SUSTITUCIÓN NO QUIRÚRGICA DE DISPOSITIVO GASTROINTESTINAL"
    },
    {
     "CATALOG_KEY": "9701",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO (NASO-)GÁSTRICO O DE ESOFAGOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9702",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO DE GASTROSTOMÍA"
    },
    {
     "CATALOG_KEY": "9703",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO O DISPOSITIVO DE ENTEROSTOMÍA DEL INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "9704",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO O DISPOSITIVO DE ENTEROSTOMÍA DEL INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "9705",
     "PRO_NOMBRE": "SUSTITUCIÓN DE STENT (TUBO TUTOR) EN VÍAS BILIARES O CONDUCTO PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "971",
     "PRO_NOMBRE": "SUSTITUCIÓN NO QUIRÚRGICA DE DISPOSITIVO DE LOS SISTEMAS MÚSCULO ESQUELÉTICOS O TEGUMENTARIO"
    },
    {
     "CATALOG_KEY": "9711",
     "PRO_NOMBRE": "SUSTITUCIÓN DE ESCAYOLA EN MIEMBRO SUPERIOR"
    },
    {
     "CATALOG_KEY": "9712",
     "PRO_NOMBRE": "SUSTITUCIÓN DE ESCAYOLA EN MIEMBRO INFERIOR"
    },
    {
     "CATALOG_KEY": "9713",
     "PRO_NOMBRE": "SUSTITUCIÓN DE OTRA ESCAYOLA"
    },
    {
     "CATALOG_KEY": "9714",
     "PRO_NOMBRE": "SUSTITUCIÓN DE OTRO DISPOSITIVO PARA INMOVILIZACIÓN MUSCULOESQUELÉTICO"
    },
    {
     "CATALOG_KEY": "9715",
     "PRO_NOMBRE": "SUSTITUCIÓN DE CATÉTER DE HERIDA"
    },
    {
     "CATALOG_KEY": "9716",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TAPÓN O DRENAJE DE HERIDA"
    },
    {
     "CATALOG_KEY": "972",
     "PRO_NOMBRE": "OTRA SUSTITUCIÓN, NO QUIRÚRGICA"
    },
    {
     "CATALOG_KEY": "9721",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TAPÓN NASAL"
    },
    {
     "CATALOG_KEY": "9722",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TAPÓN DENTAL"
    },
    {
     "CATALOG_KEY": "9723",
     "PRO_NOMBRE": "SUSTITUCIÓN DE TUBO DE TRAQUEOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9724",
     "PRO_NOMBRE": "SUSTITUCIÓN Y AJUSTE DE DIAFRAGMA VAGINAL"
    },
    {
     "CATALOG_KEY": "9725",
     "PRO_NOMBRE": "SUSTITUCIÓN DE OTRO PESARIO VAGINAL"
    },
    {
     "CATALOG_KEY": "9726",
     "PRO_NOMBRE": "SUSTITUCIÓN DE OTRO TAPÓN O DRENAJE VAGINAL O VULVAR"
    },
    {
     "CATALOG_KEY": "9729",
     "PRO_NOMBRE": "OTRAS SUSTITUCIONES NO QUIRÚRGICAS"
    },
    {
     "CATALOG_KEY": "973",
     "PRO_NOMBRE": "EXTRACCIÓN NO QUIRÚRGICA DE DISPOSITIVO TERAPÉUTICO DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "9731",
     "PRO_NOMBRE": "EXTRACCIÓN DE PRÓTESIS OCULAR"
    },
    {
     "CATALOG_KEY": "9732",
     "PRO_NOMBRE": "EXTRACCIÓN DE TAPÓN NASAL"
    },
    {
     "CATALOG_KEY": "9733",
     "PRO_NOMBRE": "EXTRACCIÓN DE FÉRULAS DENTALES"
    },
    {
     "CATALOG_KEY": "9734",
     "PRO_NOMBRE": "EXTRACCIÓN DE TAPÓN DENTAL"
    },
    {
     "CATALOG_KEY": "9735",
     "PRO_NOMBRE": "EXTRACCIÓN DE PRÓTESIS DENTAL"
    },
    {
     "CATALOG_KEY": "9736",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DE FIJACIÓN MANDIBULAR EXTERNA"
    },
    {
     "CATALOG_KEY": "9737",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE TRAQUEOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9738",
     "PRO_NOMBRE": "RETIRADA DE SUTURAS DE CABEZA Y CUELLO (RETIRADA DE PUNTOS)"
    },
    {
     "CATALOG_KEY": "9739",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO TERAPÉUTICO DE CABEZA Y CUELLO"
    },
    {
     "CATALOG_KEY": "974",
     "PRO_NOMBRE": "EXTRACCIÓN NO QUIRÚRGICA DE DISPOSITIVO TERAPÉUTICO DE TÓRAX"
    },
    {
     "CATALOG_KEY": "9741",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE TORACOTOMÍA O DE DRENAJE DE LA CAVIDAD PLEURAL"
    },
    {
     "CATALOG_KEY": "9742",
     "PRO_NOMBRE": "EXTRACCIÓN DE DRENAJE MEDIASTÍNICO"
    },
    {
     "CATALOG_KEY": "9743",
     "PRO_NOMBRE": "RETIRO DE SUTURAS DE TÓRAX (RETIRADA DE PUNTOS)"
    },
    {
     "CATALOG_KEY": "9744",
     "PRO_NOMBRE": "EXTRACCIÓN NO QUIRÚRGICA DE SISTEMA DE ASISTENCIA CARDÍACA"
    },
    {
     "CATALOG_KEY": "9749",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DE TÓRAX"
    },
    {
     "CATALOG_KEY": "975",
     "PRO_NOMBRE": "EXTRACCIÓN NO QUIRÚRGICA DE DISPOSITIVO TERAPÉUTICO DEL APARATO DIGESTIVO"
    },
    {
     "CATALOG_KEY": "9751",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE GASTROSTOMÍA"
    },
    {
     "CATALOG_KEY": "9752",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "9753",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE INTESTINO GRUESO O APÉNDICE"
    },
    {
     "CATALOG_KEY": "9754",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE COLECISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9755",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO EN T, OTRO TUBO DE VÍA BILIAR O TUBO HEPÁTICO"
    },
    {
     "CATALOG_KEY": "9756",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO O DRENAJE PANCREÁTICO"
    },
    {
     "CATALOG_KEY": "9759",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DEL APARATO DIGESTIVO"
    },
    {
     "CATALOG_KEY": "976",
     "PRO_NOMBRE": "EXTRACCIÓN NO QUIRÚRGICA DE DISPOSITIVO TERAPÉUTICO DEL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "9761",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE PIELOSTOMÍA Y NEFROSTOMÍA"
    },
    {
     "CATALOG_KEY": "9762",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE URETEROSTOMÍA Y DE CATÉTER URETERAL"
    },
    {
     "CATALOG_KEY": "9763",
     "PRO_NOMBRE": "EXTRACCIÓN DE TUBO DE CISTOSTOMÍA"
    },
    {
     "CATALOG_KEY": "9764",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DE DRENAJE URINARIO"
    },
    {
     "CATALOG_KEY": "9765",
     "PRO_NOMBRE": "EXTRACCIÓN DE SONDA URETRAL"
    },
    {
     "CATALOG_KEY": "9769",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DEL APARATO URINARIO"
    },
    {
     "CATALOG_KEY": "977",
     "PRO_NOMBRE": "EXTRACCIÓN NO QUIRÚRGICA DE DISPOSITIVO TERAPÉUTICO DEL APARATO GENITAL"
    },
    {
     "CATALOG_KEY": "9771",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO"
    },
    {
     "CATALOG_KEY": "9772",
     "PRO_NOMBRE": "EXTRACCIÓN DE TAPÓN INTRAUTERINO"
    },
    {
     "CATALOG_KEY": "9773",
     "PRO_NOMBRE": "EXTRACCIÓN DE DIAFRAGMA VAGINAL"
    },
    {
     "CATALOG_KEY": "9774",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO PESARIO VAGINAL"
    },
    {
     "CATALOG_KEY": "9775",
     "PRO_NOMBRE": "EXTRACCIÓN DE TAPÓN VAGINAL O VULVAR"
    },
    {
     "CATALOG_KEY": "9779",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DEL TRACTO GENITAL"
    },
    {
     "CATALOG_KEY": "978",
     "PRO_NOMBRE": "OTRA EXTRACCIÓN NO QUIRÚRGICA DE DISPOSITIVO TERAPÉUTICO"
    },
    {
     "CATALOG_KEY": "9781",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVO DE DRENAJE RETROPERITONEAL"
    },
    {
     "CATALOG_KEY": "9782",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVO DE DRENAJE PERITONEAL"
    },
    {
     "CATALOG_KEY": "9783",
     "PRO_NOMBRE": "EXTRACCIÓN DE SUTURAS DE LA PARED ABDOMINAL"
    },
    {
     "CATALOG_KEY": "9784",
     "PRO_NOMBRE": "EXTRACCIÓN DE SUTURAS DEL TRONCO, NO CLASIFICADAS BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "9785",
     "PRO_NOMBRE": "EXTRACCIÓN DE TAPÓN DE TRONCO, NO CLASIFICADO BAJO OTRO CONCEPTO"
    },
    {
     "CATALOG_KEY": "9786",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DE ABDOMEN"
    },
    {
     "CATALOG_KEY": "9787",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO DE TRONCO"
    },
    {
     "CATALOG_KEY": "9788",
     "PRO_NOMBRE": "EXTRACCIÓN DE DISPOSITIVO DE INMOVILIZACIÓN EXTERNO"
    },
    {
     "CATALOG_KEY": "9789",
     "PRO_NOMBRE": "EXTRACCIÓN DE OTRO DISPOSITIVO TERAPÉUTICO"
    },
    {
     "CATALOG_KEY": "98",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO Y CÁLCULO NO OPERATORIO"
    },
    {
     "CATALOG_KEY": "980",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DEL APARATO DIGESTIVO"
    },
    {
     "CATALOG_KEY": "9801",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DE LA BOCA"
    },
    {
     "CATALOG_KEY": "9802",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DEL ESÓFAGO"
    },
    {
     "CATALOG_KEY": "9803",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DEL ESTÓMAGO E INTESTINO DELGADO"
    },
    {
     "CATALOG_KEY": "9804",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DEL INTESTINO GRUESO"
    },
    {
     "CATALOG_KEY": "9805",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DEL RECTO Y ANO"
    },
    {
     "CATALOG_KEY": "981",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DE OTROS SITIOS"
    },
    {
     "CATALOG_KEY": "9811",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DEL OÍDO"
    },
    {
     "CATALOG_KEY": "9812",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DE LA NARIZ"
    },
    {
     "CATALOG_KEY": "9813",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DE LA FARINGE"
    },
    {
     "CATALOG_KEY": "9814",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DE LA LARINGE"
    },
    {
     "CATALOG_KEY": "9815",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO INTRALUMINAL DE TRÁQUEA Y BRONQUIO"
    },
    {
     "CATALOG_KEY": "9816",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN ÚTERO"
    },
    {
     "CATALOG_KEY": "9817",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN VAGINA"
    },
    {
     "CATALOG_KEY": "9818",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN ESTOMA ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "9819",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN URETRA"
    },
    {
     "CATALOG_KEY": "982",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE OTRO CUERPO EXTRAÑO"
    },
    {
     "CATALOG_KEY": "9820",
     "PRO_NOMBRE": "EXTRACCIÓN DE CUERPO EXTRAÑO, NO ESPECIFICADO DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "9821",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO SUPERFICIAL EN OJO"
    },
    {
     "CATALOG_KEY": "9822",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE OTRO CUERPO EXTRAÑO EN CUELLO Y CABEZA"
    },
    {
     "CATALOG_KEY": "9823",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN VULVA"
    },
    {
     "CATALOG_KEY": "9824",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN PENE O ESCROTO"
    },
    {
     "CATALOG_KEY": "9825",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE OTRO CUERPO EXTRAÑO EN OTRO CONDUCTO SALVO DE ESCROTO, PENE O VULVA"
    },
    {
     "CATALOG_KEY": "9826",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN MIEMBRO EN MANO"
    },
    {
     "CATALOG_KEY": "9827",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO EN MIEMBRO SUPERIOR, SALVO DE LA MANO"
    },
    {
     "CATALOG_KEY": "9828",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO DE PIE"
    },
    {
     "CATALOG_KEY": "9829",
     "PRO_NOMBRE": "EXTRACCIÓN SIN INCISIÓN DE CUERPO EXTRAÑO DE MIEMBRO INFERIOR, SALVO DEL PIE"
    },
    {
     "CATALOG_KEY": "985",
     "PRO_NOMBRE": "LITOTRICIA EXTRACORPÓREA POR ONDAS DE CHOQUE [ESWL]"
    },
    {
     "CATALOG_KEY": "9851",
     "PRO_NOMBRE": "LITOTRICIA POR ONDAS DE CHOQUE EXTRACORPORALES DEL RIÑÓN, DEL URÉTER Y DE LA VEJIGA"
    },
    {
     "CATALOG_KEY": "9852",
     "PRO_NOMBRE": "LITOTRICIA POR ONDAS DE CHOQUE EXTRACORPORALES DE VESÍCULA O CONDUCTO BILIAR"
    },
    {
     "CATALOG_KEY": "9859",
     "PRO_NOMBRE": "LITOTRICIA POR ONDAS DE CHOQUE EXTRACORPORALES EN OTROS SITIOS"
    },
    {
     "CATALOG_KEY": "99",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS NO QUIRÚRGICOS"
    },
    {
     "CATALOG_KEY": "990",
     "PRO_NOMBRE": "TRANSFUSIÓN DE SANGRE Y DE COMPONENTES SANGUÍNEOS"
    },
    {
     "CATALOG_KEY": "9900",
     "PRO_NOMBRE": "TRANSFUSIÓN AUTÓLOGA PERIOPERATIVA DE SANGRE TOTAL O DE COMPONENTES DE LA SANGRE"
    },
    {
     "CATALOG_KEY": "9901",
     "PRO_NOMBRE": "TRANSFUSIÓN DE SANGRE (PARA CAMBIO)"
    },
    {
     "CATALOG_KEY": "9902",
     "PRO_NOMBRE": "TRANSFUSIÓN DE SANGRE AUTÓLOGA RECOGIDA PREVIAMENTE"
    },
    {
     "CATALOG_KEY": "9903",
     "PRO_NOMBRE": "OTRA TRANSFUSIÓN DE SANGRE ENTERA"
    },
    {
     "CATALOG_KEY": "9904",
     "PRO_NOMBRE": "TRANSFUSIÓN DE CONCENTRADO DE HEMATÍES"
    },
    {
     "CATALOG_KEY": "9905",
     "PRO_NOMBRE": "TRANSFUSIÓN DE PLAQUETAS"
    },
    {
     "CATALOG_KEY": "9906",
     "PRO_NOMBRE": "TRANSFUSIÓN DE FACTORES DE COAGULACIÓN"
    },
    {
     "CATALOG_KEY": "9907",
     "PRO_NOMBRE": "TRANSFUSIÓN DE OTRO SUERO"
    },
    {
     "CATALOG_KEY": "9908",
     "PRO_NOMBRE": "TRANSFUSIÓN DE EXPANSOR SANGUÍNEO"
    },
    {
     "CATALOG_KEY": "9909",
     "PRO_NOMBRE": "TRANSFUSIÓN DE OTRA SUSTANCIA"
    },
    {
     "CATALOG_KEY": "991",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE SUSTANCIA TERAPÉUTICA O PROFILÁCTICA"
    },
    {
     "CATALOG_KEY": "9910",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE AGENTE TROMBOLÍTICO"
    },
    {
     "CATALOG_KEY": "9911",
     "PRO_NOMBRE": "INYECCIÓN DE GLOBULINA INMUNE RH"
    },
    {
     "CATALOG_KEY": "9912",
     "PRO_NOMBRE": "INMUNIZACIÓN PARA ALERGIA"
    },
    {
     "CATALOG_KEY": "9913",
     "PRO_NOMBRE": "INMUNIZACIÓN PARA ENFERMEDAD AUTOINMUNE"
    },
    {
     "CATALOG_KEY": "9914",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE GAMMA GLOBULINA"
    },
    {
     "CATALOG_KEY": "9915",
     "PRO_NOMBRE": "INFUSIÓN PARENTERAL DE SUSTANCIAS NUTRITIVAS CONCENTRADAS"
    },
    {
     "CATALOG_KEY": "9916",
     "PRO_NOMBRE": "INYECCIÓN DE ANTÍDOTO"
    },
    {
     "CATALOG_KEY": "9917",
     "PRO_NOMBRE": "INYECCIÓN DE INSULINA"
    },
    {
     "CATALOG_KEY": "9918",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE ELECTROLITOS"
    },
    {
     "CATALOG_KEY": "9919",
     "PRO_NOMBRE": "INYECCIÓN DE ANTICOAGULANTE"
    },
    {
     "CATALOG_KEY": "992",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE OTRA SUSTANCIA TERAPÉUTICA O PROFILÁCTICA"
    },
    {
     "CATALOG_KEY": "9920",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE INHIBIDOR PLAQUETARIO"
    },
    {
     "CATALOG_KEY": "9921",
     "PRO_NOMBRE": "INYECCIÓN DE ANTIBIÓTICO"
    },
    {
     "CATALOG_KEY": "9922",
     "PRO_NOMBRE": "INYECCIÓN DE OTRA SUSTANCIA ANTI-INFECCIOSA"
    },
    {
     "CATALOG_KEY": "9923",
     "PRO_NOMBRE": "INYECCIÓN DE ESTEROIDE"
    },
    {
     "CATALOG_KEY": "9924",
     "PRO_NOMBRE": "INYECCIÓN DE OTRA HORMONA"
    },
    {
     "CATALOG_KEY": "9925",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE SUSTANCIA QUIMIOTERAPÉUTICA CONTRA CÁNCER"
    },
    {
     "CATALOG_KEY": "9926",
     "PRO_NOMBRE": "INYECCIÓN DE TRANQUILIZANTE"
    },
    {
     "CATALOG_KEY": "9927",
     "PRO_NOMBRE": "IONTOFORESIS"
    },
    {
     "CATALOG_KEY": "9928",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE MODIFICADORES DE RESPUESTA BIOLÓGICA (BIOMODULADORES) [BRM] EMPLEDOS COMO AGENTES ANTINEOPLÁSICOS"
    },
    {
     "CATALOG_KEY": "9929",
     "PRO_NOMBRE": "INYECCIÓN O INFUSIÓN DE OTRA SUSTANCIA TERAPÉUTICA O PROFILÁCTICA"
    },
    {
     "CATALOG_KEY": "992A",
     "PRO_NOMBRE": "INFUSIÓN O APLICACIÓN DE SURFACTANTE"
    },
    {
     "CATALOG_KEY": "993",
     "PRO_NOMBRE": "VACUNACIÓN E INOCULACIÓN PROFILÁCTICAS CONTRA CIERTAS ENFERMEDADES BACTERIANAS"
    },
    {
     "CATALOG_KEY": "9931",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA CÓLERA"
    },
    {
     "CATALOG_KEY": "9932",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA FIEBRE TIFOIDEA Y PARATIFOIDEA"
    },
    {
     "CATALOG_KEY": "9933",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA TUBERCULOSIS"
    },
    {
     "CATALOG_KEY": "9934",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA PESTE"
    },
    {
     "CATALOG_KEY": "9935",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA TULAREMIA"
    },
    {
     "CATALOG_KEY": "9936",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE TOXOIDE DIFTÉRICO"
    },
    {
     "CATALOG_KEY": "9937",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA TOSFERINA"
    },
    {
     "CATALOG_KEY": "9938",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE TOXOIDE DE TÉTANOS"
    },
    {
     "CATALOG_KEY": "9939",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE VACUNA COMBINADA DE DIFTERIA-TÉTANOS-TOSFERINA"
    },
    {
     "CATALOG_KEY": "993A",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE VACUNA PENTAVALENTE (DPT, HV, HIP)"
    },
    {
     "CATALOG_KEY": "993B",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA DIFTERIA Y TÉTANOS (TD)"
    },
    {
     "CATALOG_KEY": "993C",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA NEUMOCOCO 23 VALENTE"
    },
    {
     "CATALOG_KEY": "993D",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA NEUMOCOCO CONJUGADO (HEPTAVALENTE)"
    },
    {
     "CATALOG_KEY": "994",
     "PRO_NOMBRE": "VACUNACIÓN E INOCULACIÓN PROFILÁCTICA CONTRA CIERTAS ENFERMEDADES VÍRICAS"
    },
    {
     "CATALOG_KEY": "9941",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE VACUNA CONTRA LA POLIOMIELITIS (SABIN)"
    },
    {
     "CATALOG_KEY": "9942",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA VIRUELA"
    },
    {
     "CATALOG_KEY": "9943",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA FIEBRE AMARILLA"
    },
    {
     "CATALOG_KEY": "9944",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA RABIA"
    },
    {
     "CATALOG_KEY": "9945",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA SARAMPIÓN"
    },
    {
     "CATALOG_KEY": "9946",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA PAROTIDITIS"
    },
    {
     "CATALOG_KEY": "9947",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA RUBÉOLA"
    },
    {
     "CATALOG_KEY": "9948",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE VACUNA CONTRA SARAMPIÓN-PAROTIDITIS-RUBÉOLA"
    },
    {
     "CATALOG_KEY": "994A",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA ROTAVIRUS"
    },
    {
     "CATALOG_KEY": "994B",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA HEPATITIS A (HA)"
    },
    {
     "CATALOG_KEY": "994C",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA HEPATITIS B (HB)"
    },
    {
     "CATALOG_KEY": "994D",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA HEPATITIS C (HC)"
    },
    {
     "CATALOG_KEY": "994E",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA HEPATITIS AB (HAB)"
    },
    {
     "CATALOG_KEY": "994F",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA SARAMPIÓN Y RUBÉOLA (SR) (DOBLE VIRAL)"
    },
    {
     "CATALOG_KEY": "994G",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA EL VIRUS DEL PAPILOMA HUMANO"
    },
    {
     "CATALOG_KEY": "994H",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA VARICELA"
    },
    {
     "CATALOG_KEY": "994I",
     "PRO_NOMBRE": "VACUNACIÓN CONTRA COVID-19 (VIRUS SARS–COV2), NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "994J",
     "PRO_NOMBRE": "VACUNA CONTRA COVID-19 (VIRUS SARS–COV2) ASTRAZENECA/OXFORD (CHAD)"
    },
    {
     "CATALOG_KEY": "994K",
     "PRO_NOMBRE": "VACUNA CONTRA COVID-19 (VIRUS SARS–COV2) BIONTECH/PFIZER"
    },
    {
     "CATALOG_KEY": "994L",
     "PRO_NOMBRE": "VACUNA CONTRA COVID-19 (VIRUS SARS–COV2) CANSINO BIOLOGICS"
    },
    {
     "CATALOG_KEY": "994M",
     "PRO_NOMBRE": "VACUNA CONTRA COVID-19 (VIRUS SARS–COV2) SPUTNIK V"
    },
    {
     "CATALOG_KEY": "994N",
     "PRO_NOMBRE": "OTRA VACUNA ESPECIFICADA CONTRA COVID-19 VIRUS SARS–COV2"
    },
    {
     "CATALOG_KEY": "994O",
     "PRO_NOMBRE": "VACUNA CONTRA COVID-19 (VIRUS SARS–COV2) (JANSSEN, JOHNSON & JOHNSON)"
    },
    {
     "CATALOG_KEY": "994P",
     "PRO_NOMBRE": "VACUNA CONTRA COVID-19 (VIRUS SARS–COV2) (MODERNA)"
    },
    {
     "CATALOG_KEY": "994Q",
     "PRO_NOMBRE": "VACUNA CONTRA COVID-19 (VIRUS SARS–COV2) (SINOVAC)"
    },
    {
     "CATALOG_KEY": "995",
     "PRO_NOMBRE": "OTRA VACUNACIÓN E INOCULACIÓN"
    },
    {
     "CATALOG_KEY": "9951",
     "PRO_NOMBRE": "VACUNACIÓN PROFILÁCTICA CONTRA EL RESFRIADO COMÚN"
    },
    {
     "CATALOG_KEY": "9952",
     "PRO_NOMBRE": "VACUNACIÓN PROFILÁCTICA CONTRA LA GRIPE [INFLUENZA]"
    },
    {
     "CATALOG_KEY": "9953",
     "PRO_NOMBRE": "VACUNACIÓN PROFILÁCTICA CONTRA ENCEFALITIS VÍRICA TRANSMITIDA POR ARTRÓPODOS"
    },
    {
     "CATALOG_KEY": "9954",
     "PRO_NOMBRE": "VACUNACIÓN PROFILÁCTICA CONTRA OTRAS ENFERMEDADES VÍRICAS TRANSMITIDAS POR ARTRÓPODOS"
    },
    {
     "CATALOG_KEY": "9955",
     "PRO_NOMBRE": "ADMINISTRACIÓN PROFILÁCTICA DE VACUNA CONTRA OTRAS ENFERMEDADES"
    },
    {
     "CATALOG_KEY": "9956",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE ANTITOXINA TETÁNICA"
    },
    {
     "CATALOG_KEY": "9957",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE ANTITOXINA DE BOTULISMO"
    },
    {
     "CATALOG_KEY": "9958",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE OTRAS ANTITOXINAS"
    },
    {
     "CATALOG_KEY": "9959",
     "PRO_NOMBRE": "OTRA VACUNACIÓN E INOCULACIÓN"
    },
    {
     "CATALOG_KEY": "996",
     "PRO_NOMBRE": "CONVERSIÓN DEL RITMO CARDIACO"
    },
    {
     "CATALOG_KEY": "9960",
     "PRO_NOMBRE": "RESUSCITACIÓN CARDIOPULMONAR, NO ESPECIFICADA DE OTRA MANERA"
    },
    {
     "CATALOG_KEY": "9961",
     "PRO_NOMBRE": "CARDIOVERSIÓN AURICULAR"
    },
    {
     "CATALOG_KEY": "9962",
     "PRO_NOMBRE": "OTRO CHOQUE ELÉCTRICO AL CORAZÓN"
    },
    {
     "CATALOG_KEY": "9963",
     "PRO_NOMBRE": "MASAJE CARDIACO A TÓRAX CERRADO"
    },
    {
     "CATALOG_KEY": "9964",
     "PRO_NOMBRE": "ESTIMULACIÓN DE SENO CAROTÍDEO"
    },
    {
     "CATALOG_KEY": "9969",
     "PRO_NOMBRE": "OTRA CONVERSIÓN DE RITMO CARDIACO"
    },
    {
     "CATALOG_KEY": "997",
     "PRO_NOMBRE": "AFÉRESIS TERAPÉUTICA U OTRA INYECCIÓN, ADMINISTRACIÓN O INFUSIÓN DE OTRA SUSTANCIA TERAPEÚTICA O PROFILÁCTICA"
    },
    {
     "CATALOG_KEY": "9971",
     "PRO_NOMBRE": "PLASMAFÉRESIS TERAPÉUTICA"
    },
    {
     "CATALOG_KEY": "9972",
     "PRO_NOMBRE": "LEUCOFERESIS TERAPÉUTICA"
    },
    {
     "CATALOG_KEY": "9973",
     "PRO_NOMBRE": "ERITROCITOFÉRESIS TERAPÉUTICA"
    },
    {
     "CATALOG_KEY": "9974",
     "PRO_NOMBRE": "PLAQUETOFÉRESIS TERAPÉUTICA"
    },
    {
     "CATALOG_KEY": "9975",
     "PRO_NOMBRE": "ADMINISTRACIÓN DE AGENTE NEUROPROTECTOR"
    },
    {
     "CATALOG_KEY": "9976",
     "PRO_NOMBRE": "INMUNOADSORCIÓN EXTRACORPÓREA"
    },
    {
     "CATALOG_KEY": "9977",
     "PRO_NOMBRE": "APLICACIÓN O ADMINISTRACIÓN DE SUSTANCIA ANTIADHERENCIA"
    },
    {
     "CATALOG_KEY": "9978",
     "PRO_NOMBRE": "ACUAFÉRESIS"
    },
    {
     "CATALOG_KEY": "9979",
     "PRO_NOMBRE": "OTRA AFÉRESIS TERAPÉUTICA"
    },
    {
     "CATALOG_KEY": "998",
     "PRO_NOMBRE": "PROCEDIMIENTOS FÍSICOS MISCELÁNEOS"
    },
    {
     "CATALOG_KEY": "9981",
     "PRO_NOMBRE": "HIPOTERMIA (CENTRAL) (LOCAL)"
    },
    {
     "CATALOG_KEY": "9982",
     "PRO_NOMBRE": "TERAPIA DE LUZ ULTRAVIOLETA"
    },
    {
     "CATALOG_KEY": "9983",
     "PRO_NOMBRE": "OTRA FOTOTERAPIA"
    },
    {
     "CATALOG_KEY": "9984",
     "PRO_NOMBRE": "AISLAMIENTO"
    },
    {
     "CATALOG_KEY": "9985",
     "PRO_NOMBRE": "HIPERTERMIA PARA TRATAMIENTO DEL CÁNCER"
    },
    {
     "CATALOG_KEY": "9986",
     "PRO_NOMBRE": "COLOCACIÓN INCRUENTA DE UN ESTIMULADOR DEL CRECIMIENTO ÓSEO"
    },
    {
     "CATALOG_KEY": "9988",
     "PRO_NOMBRE": "FOTOFÉRESIS TERAPÉUTICA"
    },
    {
     "CATALOG_KEY": "999",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTOS MISCELÁNEOS"
    },
    {
     "CATALOG_KEY": "9991",
     "PRO_NOMBRE": "ACUPUNTURA PARA ANESTESIA"
    },
    {
     "CATALOG_KEY": "9992",
     "PRO_NOMBRE": "OTRA ACUPUNTURA"
    },
    {
     "CATALOG_KEY": "9993",
     "PRO_NOMBRE": "MASAJE RECTAL (PARA ESPASMO DE MÚSCULO ELEVADOR)"
    },
    {
     "CATALOG_KEY": "9994",
     "PRO_NOMBRE": "MASAJE PROSTÁTICO"
    },
    {
     "CATALOG_KEY": "9995",
     "PRO_NOMBRE": "ESTIRAMIENTO DE PREPUCIO"
    },
    {
     "CATALOG_KEY": "9996",
     "PRO_NOMBRE": "RECOGIDA DE ESPERMA PARA INSEMINACIÓN ARTIFICIAL"
    },
    {
     "CATALOG_KEY": "9997",
     "PRO_NOMBRE": "COLOCACIÓN DE DENTADURA POSTIZA"
    },
    {
     "CATALOG_KEY": "9998",
     "PRO_NOMBRE": "EXTRACCIÓN DE LECHE DE MAMA DE MADRE LACTANTE"
    },
    {
     "CATALOG_KEY": "9999",
     "PRO_NOMBRE": "OTROS PROCEDIMIENTO MISCELÁNEO"
    }
   ]

export default procedimientos