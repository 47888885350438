/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore,sessions } from '../utils/firebase';

/**
 * Función que permite agregar una nueva sesión a la colección
 * @param {String} level Recibe el nivel de permiso del usuario sesionado
 * @param {String} system Recibe el identificador del sistemq sesionado
 * @param {String} token Recibe el token asignado a la sesión
 * @param {String} uh Recibe la unidad hospitalaria a la que pertenece el usaurio sesionado
 * @param {String} user Recibe el identificador del usuario sesionado
 * @returns Devuelve el resultado de la solicitud
 */
export const createSession = async (level,system,token,uh,user) => {
  try {
      await pacientesStore
      .collection(sessions)
      .add({
        level:             level,
        system:            system,
        token:             token,
        uh:                 uh,
        user:               user
      })
      return "success"
  } catch (error) {
      return error
  }
}

/**
 * Función que actualiza el registro de sesión para una unidad hospitalaria
 * @param {String} id Recibe el nivel de permiso del usuario sesionado
 * @param {String} level Recibe el identificador del sistemq sesionado
 * @param {String} system Recibe el token asignado a la sesión
 * @param {String} token Recibe el token asignado a la sesión
 * @param {String} uh Recibe la unidad hospitalaria a la que pertenece el usaurio sesionado
 * @param {String} user Recibe el identificador del usuario sesionado
 * @returns Devuelve el resultado de la solicitud
 */
export const updateSession = async (id,level,system,token,uh,user) => {
    try {
        await pacientesStore
        .collection(sessions)
        .doc(id)
        .update({
            level:             level,
            system:            system,
            token:             token,
            uh:                 uh,
            user:               user
        })
        return "success"
      } catch (error) {
        return error
      }
}