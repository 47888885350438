/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore, resourcesCollection } from "../utils/firebase"

/**
 * Función que actualiza el estado de un quirófano
 * @param {String} id Recibe el identificador unico del resource
 * @param {Boolean} activo Recibe el estado al que se desea actualizar el resource
 * @returns Devuelve el resultado de la solicitud
 */
export const updateResource = async (id, activo) => {
    try {
        await pacientesStore
        .collection(resourcesCollection)
        .doc(id)
        .update({
            Activo:             activo
        })
        return "success"
      } catch (error) {
        return error
      }
}