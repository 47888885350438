/**
 * Librerias
 */
import React, { useEffect, useState } from "react";

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'

/**
 * Componente que muestra la información de los requerimientos del médico tratante para la cirugía
 * @param {Object} param0 Recibe los elementos necesarios para mostrar los requerimientos
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const DetallesModal = ({eventoSeleccionado}) => {

    //Estado que almacena el motivo de la ultima actualización de un evento
    const [concepto, setConcepto] = useState("")
    //Estado que almacena el doagnostico del paciente
    const [diagnostico, setDiagnostico] = useState("")
    //Estado que almacena la duración de la cirugía
    const [duracion, setDuracion] = useState("")
    //Estado que almacena la edad del paciente
    const [edad, setEdad] = useState("")
    //Estado que almacena los estudios transoperatorios
    const [estudios, setEstudios] = useState("")
    //Estado que almacena los requerimientos extras del médico tratatante
    const [extras, setExtras] = useState("")
    //Estado que almacana el equipo quirurgico requerido por el médico tratante
    const [equipoQuirirgico, setEquipoQuirurgico] = useState("")
    const [equipoEspecial, setEquipoEspecial] = useState("")
    const [hemoderivados, setHemoderivados] = useState("")
    //Estado que almacena la fecha de nacimiento del paciente
    const [fecha_nacimiento,setFechaNacimiento] = useState("")
    //Estado que almacena el lado de procedimiento ha realizar (es un campo no requerido)
    const [ladoProcedimiento, setLadoProcedimiento] = useState("")
    //Estado que almacena el nombre del anestesiologo
    const [nombreAnestesiologo, setNombreAnestesiologo] = useState("")
    //const [tipoAnestesiologo, setTipoAnestesiologo] = useState("")
    const [isAnestesiologoExterno, setIsAnestesiologoExterno] = useState(false)
    //Estado que almacena el nombre del paciente
    //Estado que almacena el nombre del paciente
    const [paciente,setPaciente] = useState("")
    //Estado que almacena el quirófano solicitado
    const [quirofano,setQuirofano] = useState("")
    //Estado que almacena el sexo del paciente
    const [sexo,setSexo] = useState("")
    //Estado que almacena quien solicito el ultimo cambio al evento
    const [solicito,setSolicito] = useState("")
    //Estado que almacena el tipo de anestesía
    const [tipoAnestesia, setTipoAnestesia] = useState("")
    //Estado que almacena el tipo de cliente
    const [tipoCliente, setTipoCliente] = useState("")
    //Estado que almacena el tipo de cliente
    const [opcionCliente, setOpcionCliente] = useState("")
    const [procedimiento, setProcedimiento] = useState("")


    useEffect(()=>{
        if(eventoSeleccionado.description){//Se verifica si se ha seleccionado un evento valido
            let description = eventoSeleccionado.description.toString().split('|')
            if(description.length > 1){//Se verifica que el evento cuento con los datos adicionales en la descripción
                //Se descomponen los elementos de la descipción y se almacenan los relacionados con las solicitudes extras
                setQuirofano(description[0].split('=')[1])
                setPaciente(description[2].split('=')[1])
                setProcedimiento(description[3].split('=')[1])
                setEquipoQuirurgico(description[4].split('=')[1])
                setExtras(description[5].split('=')[1])
                if(description[9]){
                    setDuracion(description[9].split('=')[1] + "Hr.")
                    setLadoProcedimiento(description[10].split('=')[1])
                    setNombreAnestesiologo(description[11].split('=')[1])
                    setTipoAnestesia(description[12].split('=')[1])
                    setFechaNacimiento(description[13].split('=')[1])
                    setEdad(description[14].split('=')[1])
                    setSexo(description[15].split('=')[1])
                    setTipoCliente(description[16].split('=')[1])
                    setDiagnostico(description[17].split('=')[1])
                    setSolicito(description[18].split('=')[1])
                    setConcepto(description[19].split('=')[1])
                    if(description[20]){
                        if(description[20].split('=')[1]){
                            setEstudios(description[20].split('=')[1])
                        }
                        else{
                            setEstudios("NA")
                        }
                    }
                    else{
                        setEstudios("NA")
                    }
                    if(description[21]){
                        if(description[21].split('=')[1]){
                            if(description[21].split('=')[1] !== description[16].split('=')[1]){
                                setOpcionCliente(description[21].split('=')[1] + ":")
                            }
                            else{
                                setOpcionCliente("")
                            }
                        }
                        else{
                            setOpcionCliente("")
                        }
                    }
                    else{
                        setOpcionCliente("")
                    }
                    if(description[22]){
                        if(description[22].split('=')[1]){
                            //setTipoAnestesiologo(description[22].split('=')[1])
                            if(description[22].split('=')[1] === "Externo"){
                                setIsAnestesiologoExterno(true)
                            }
                            else{
                                setIsAnestesiologoExterno(false)
                            }
                        }
                        else{
                            //setTipoAnestesiologo("")
                            setIsAnestesiologoExterno(false)
                        }
                    }
                    else{
                        //setTipoAnestesiologo("")
                        setIsAnestesiologoExterno(false)
                    }
                    
                    if(description.length >= 25){
                        if(description[23]){
                            if(description[23].split('=')[1]){
                                setEquipoEspecial(description[23].split('=')[1])
                            }
                            else{
                                setEquipoEspecial("SR")
                            }
                        }
                        else{
                            setEquipoEspecial("SR")
                        }
                        
                        if(description[24]){
                            if(description[24].split('=')[1]){
                                setHemoderivados(description[24].split('=')[1])
                            }
                            else{
                                setHemoderivados("SR")
                            }
                        }
                        else{
                            setHemoderivados("SR")
                        }
                    }
                    else{
                        setEquipoEspecial("SR")
                        setHemoderivados("SR")
                    }
                }
                else{
                    setDuracion("SR")
                    setLadoProcedimiento("NA")
                    setNombreAnestesiologo("NA")
                    setTipoAnestesia("NA")
                    setFechaNacimiento("NA")
                    setEdad("NA")
                    setSexo("NA")
                    setTipoCliente("NA")
                    setDiagnostico("NA")
                    setSolicito("NA")
                    setConcepto("NA")
                    setEstudios("NA")
                    setOpcionCliente("")
                    //setTipoAnestesiologo("")
                    setIsAnestesiologoExterno(false)
                    setEquipoEspecial("SR")
                    setHemoderivados("SR")
                }
            }
            else{//Evento sin datos adicionales en la descripción
                setQuirofano(description[0])
                setPaciente("SR")
                setProcedimiento("SR")
                setEquipoQuirurgico("SR")
                setExtras("NA")
                setDuracion("SR")
                setLadoProcedimiento("NA")
                setNombreAnestesiologo("NA")
                setTipoAnestesia("NA")
                setFechaNacimiento("NA")
                setEdad("NA")
                setSexo("NA")
                setTipoCliente("NA")
                setDiagnostico("NA")
                setSolicito("NA")
                setConcepto("NA")
                setEstudios("NA")
                setOpcionCliente("")
                //setTipoAnestesiologo("")
                setIsAnestesiologoExterno(false)
                setEquipoEspecial("SR")
                setHemoderivados("SR")
            }
        }
    },[eventoSeleccionado])

    /**
     * Función que cambia el estado del modal a oculto
     */
    const closeModal = () => {
        document.getElementById('modal').style.display='none'
    } 

    /**
     * Función que atrapa el evento click de cancelar y dispara el cierre del modal
     * @returns Devuelve el estado del objeto
     */
    const handleCancelarClick = () => (event) => {
        event.preventDefault()
        closeModal()
    }

    return (
        <div id='modal' className='modalRequerimientos'>
            <div className='modal-content-dos'>
                <span className='closeBtn' onClick={handleCancelarClick()}>X</span>
                <br/>
                <form id="msform">
                    <h2 className="modalPacienteTitle">{"Detalles".toUpperCase()}</h2>
                    <h1 className='itemblogTitle'>{quirofano.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Paciente".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{paciente.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Procedimiento".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{procedimiento.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Fecha de nacimiento".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{fecha_nacimiento}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Edad".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{edad}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Sexo".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{sexo.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Tipo de cliente".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{opcionCliente.toUpperCase() +" "+ tipoCliente.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Diagnostico del paciente".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{diagnostico.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Lado del procedimiento".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{ladoProcedimiento.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Anestesiólogo".toUpperCase()}</h1>
                    {
                        isAnestesiologoExterno
                            ?
                                <h1 className='info_itemblog'>{"Externo:"}</h1>
                            :
                                null
                    }
                    <h1 className='info_itemblog'>{nombreAnestesiologo.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Tipo de anestesia".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{tipoAnestesia.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Materiales y medicamentos".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{equipoQuirirgico.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Equipo Especial".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{equipoEspecial.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Hemoderivados".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{hemoderivados.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Duración del procedimiento".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{duracion}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Estudios transoperatorios".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{estudios.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Requerimientos Extras".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{extras.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Ultima modificación".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{concepto.toUpperCase()}</h1>
                    <h1 className='info_itemblogSubtitle'>{"Realizó".toUpperCase()}</h1>
                    <h1 className='info_itemblog'>{solicito.toUpperCase()}</h1>
                    <p className='boton_cancel' onClick={handleCancelarClick()}>Cerrar</p>
                </form>
            </div>
        </div>
    )
}

export default DetallesModal;