/**
 * Librerías
 */
import React, { useEffect, useState } from "react";
import moment from 'moment';
import Swal from "sweetalert2"
// import {Notifications} from 'react-push-notification'
// import addNotification  from 'react-push-notification'

/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore y las colecciones necesarias
import { pacientesStore, surgerycalendarrequest,calendarLog, doctordirectory } from "../utils/firebase";

/**
 * Objetos
 */
//Importar las funcionalidades del objeto de tipo UnidadHospitalaria
import { getUnidadHospitalariaName } from "../objects/unidadHospitalaria";

/**
 * Componentes
 */
//Componente que muestra la información y acciones del calendario
import CalendarSection from "../components/calendarSection";
//Componente que muestra el elemento que permite la selección de fechas
import DateModal from "../components/dateModal";
//Componente que muestra los elementos relacionados con el Footer
import Footer from '../components/footer';
//Componente que muestra los elementos relacionados con el Header
import Header from "../components/header";

/**
 * Page asignada a la visualización del calendario de eventos reservados
 * @returns Component Devuelve el sitio con los elementos y funcionalidad asignada
 */
const CalendarPage = ({userName,userNomHospital,userLevel,uhList,access,isReadOnly,isCorporativo,
  setuserNomHospital,setHospitalSeleccionado,hospitalSeleccionado}) => {

    //Estado que almacena la cantidad de eventos cancelados
    const [cantidadCanceladas, setCantidadCanceladas] = useState(0)
    //Estado que almacena la cantidad de eventos pendientes de realizarse
    const [cantidadPendiente,setCantidadPendiente] = useState(0)
    //Estado que almacena la cantidad de eventos que se están realizando
    const [cantidadProceso,setCantidadProceso] = useState(0)
    //Estado que almacena la cantidad de eventos realizados
    const [cantidadRealizada,setCantidadRealizada] = useState(0)
    //Estado que almacena la lista de eventos obtenidos en un rango de fecha
    const [events,setEvents] = useState([])
    //Estado que almacena la lista de medicos
    const [medicos, setMedicos] = useState([])
    //Estado que almacena la lista de eventos cancelados en un rango de fecha
    const [cancelEvents,setCancelEvents] = useState([])
    //Estado que indica la fecha final del rango deseado
    const [finalDay, setFinalDay] = useState(new Date())
    //Estado que indica la fecha inicial del rango deseado
    const [initialDay,setInitialDay] = useState(new Date())
    //Estado que indica si no se obtuvieron eventos en el rango deseado
    const [isEmpty, setIsEmpty] = useState(false)
    //Estado que indica si esta cargando la lista de eventos
    const [isLoading, setIsLoading] = useState(true)
    //Estado que indica si se ha seleccionado la opción de editar un evento
    const [isMostrarEditar, setIsMostrarEditar] = useState(false)
    const [isMostrarAgendar, setIsMostrarAgendar] = useState(false)
    //Estado que indica si se tiene seleccionada la opción de eventos pendientes
    const [isMostrarEnPendiente,setIsMostrarEnPendiente] = useState(true)
    //Estado que indica si se tiene seleccionada la opción de eventos en proceso
    const [isMostrarEnProceso,setIsMostrarEnProceso] = useState(false)
    //Estado que indica si se mostrará la opción de administración de quirófanos
    const [isMostrarQuirofanos, setIsMostrarQuirofanos] = useState(false)
    //Estado que indica si se tiene seleccionada la opción de eventos realizados
    const [isMostrarRealizado,setIsMostrarRealizado] = useState(false)
    //Estado que infica si se mostrará la opción de administración de usuarios
    const [isMostrarUsuarios, setIsMostrarUsuarios] = useState(false)
    //Estado que indica el rango de fechas seleccionadas
    const [rangeMessage, setRangeMessage] = useState("")
    //Estado que indica si se mostrará la opción de médicos activos
    const [isMostrarMedicos, setIsMostrarMedicos] = useState(false)
    const [editEvents,setEditEvents] = useState([])

    const [isMostrarAgendaVista, setIsMostrarAgendaVista] = useState(false)

    /**
     * Función que solicita la petición del listado de eventos de calendario
     */
    function getCalendarRequest(){
      try{
        pacientesStore
        .collection(surgerycalendarrequest)
        .add({
            created:       new Date()
        })
      }
      catch{
          //console.log('error')
      }
    }

    useEffect(()=>{
        //Obtención del listado de eventos
        pacientesStore.collection("eventos")
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                resultList.push({ id_evento: doc.id, ...doc.data() })
            })
            //console.log(resultList)

            let resultados = []
            for(let i=0;i<resultList.length;i++){
              if(resultList[i].event){
                let prueba = resultList[i].event
                prueba.id_evento = resultList[i].id_evento
                //resultados.push(resultList[i].event)
                resultados.push(prueba)
              }
            }
            

            resultados.sort(function(x, y) {
              var firstDate = new Date(x.start.dateTime),
                SecondDate = new Date(y.start.dateTime);
                
              if (firstDate > SecondDate) return -1;
              if (firstDate < SecondDate) return 1;
              return 0;
            });

            //console.log(resultados)
            setEvents(resultados)
        })
        //Obtención del listado de eventos cancelados
        pacientesStore.collection(calendarLog).where('peticion','==','cancel')
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                resultList.push(doc.data())
            })
            setCancelEvents(resultList)
        })

        pacientesStore.collection(calendarLog).where('peticion','==','update')
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                resultList.push(doc.data())
            })
            setEditEvents(resultList)
        })

        pacientesStore.collection(calendarLog)
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
              let data = doc.data()
                resultList.push({
                  eventid : data.eventId,
                  AppointmentType : data.AppointmentType,
                })
            })

        })

        pacientesStore.collection(doctordirectory)
          .onSnapshot(snap =>{
            const users = []
            snap.forEach(doc => {
                let data = doc.data()
                users.push({ 
                  id: doc.id,
                  Descripcion : data.doctorInfo.Descripcion,
                  idTab_DirectorioMedico : data.doctorInfo.idTab_DirectorioMedico,
                  NumeroTelefonico : data.doctorInfo.NumeroTelefonico,
                  NumeroCelular : data.doctorInfo.NumeroCelular,
                  Extension : data.doctorInfo.Extension,
                  SitioWeb: data.doctorInfo.SitioWeb,
                  Especialidad : data.doctorInfo.Especialidad,
                  Nombre : data.doctorInfo.Nombre,
                  Especialidad2 : data.doctorInfo.Especialidad2,
                  Subespecialidad : data.doctorInfo.Subespecialidad,
                  Hospital : data.doctorInfo.Hospital,
                  CorreoElectronico : data.doctorInfo.CorreoElectronico,
                  Consultorio : data.doctorInfo.Consultorio,
                  Cedula : data.doctorInfo.Cedula,
                  RFC : data.doctorInfo.RFC,
                  Validate: data.doctorInfo.Validate,
                  inputCedulaEspecialidad : data.doctorInfo.inputCedulaEspecialidad,
                  inputCedulaSubEspecialidad : data.doctorInfo.inputCedulaSubEspecialidad,
              })
            })

            setMedicos(users)
        })


        getCalendarRequest()

    },[initialDay,finalDay])

    useEffect(()=>{
      if(finalDay){//Se verifica si se ha seleccionado fecha final
          if(moment(initialDay).format('DD-MM-YYYY') === moment(finalDay).format('DD-MM-YYYY')){//Se verifica si la fecha final es igual a la inicial
            //Se muestra solo la fecha inicial seleccionada  
            setRangeMessage(moment(initialDay).format('DD-MM-YYYY'))
          }
          else{//Se muestra el rango de fechas seleccionado
              setRangeMessage(moment(initialDay).format('DD-MM-YYYY') + " - " + moment(finalDay).format('DD-MM-YYYY'))
          }
          
      }
      else{//Se muestra solo la fecha inicial seleccionada
          setRangeMessage(moment(initialDay).format('DD-MM-YYYY'))
      }
  },[initialDay,finalDay])

    useEffect(()=>{
      if (events.length > 0) {//Se verifica si se cuenta con eventos en el rango de fechas requerido

        let eventosEnMemoria = 0
        if(localStorage.getItem("eventos")){
          eventosEnMemoria = JSON.parse(localStorage.getItem('eventos'))
        }
        if(true){
          //Se obtiene el array con los eventos que ya ha visualizado el usuario
          
                

                if(userLevel !== "superuser"){
                  let eventosUnidadHospitalaria = []
                  events.forEach(
                    evento => {
                      let description = evento.description.toString().split('|')
                      let unidadHospitalariaEventoName = ""
                      if(description.length > 1){
                          if(description[8]){
                            const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                            if(resultado){
                                unidadHospitalariaEventoName = resultado.hospital
                            }
                            else{
                                unidadHospitalariaEventoName = getUhName(evento.organizer.displayName)
  
                            }
                            
                        }
                        else{
                            unidadHospitalariaEventoName = getUhName(evento.organizer.displayName)
                        }
                      }
                      else{
                        unidadHospitalariaEventoName = getUhName(evento.organizer.displayName)
                      }
                      if(userNomHospital.toString().toUpperCase() === unidadHospitalariaEventoName.toString().toUpperCase()){
                        eventosUnidadHospitalaria.push(evento)
                      }
                    }
                  )

                  if(eventosUnidadHospitalaria.length !== eventosEnMemoria){
                    const audioEl = document.getElementById('notificacion')
                    audioEl.play()
                    Swal.fire({
                      icon:"warning",
                      title:"Atención!",
                      confirmButtonText: "Aceptar",
                      confirmButtonColor: "#04afaa",
                      text:"Nuevos eventos disponibles.",
                      allowOutsideClick:false,
                    })
                    .then((res)=>{
                      if(res.isConfirmed){
                        //setInitialDay(new Date(evento.start.dateTime))
                        //setFinalDay(new Date(evento.start.dateTime))
                      }
                    })

                    localStorage.setItem("eventos",eventosUnidadHospitalaria.length)
                  }
                }
                else{
                  if(events.length !== eventosEnMemoria){
                    const audioEl = document.getElementById('notificacion')
                    audioEl.play()
                    Swal.fire({
                      icon:"warning",
                      title:"Atención!",
                      confirmButtonText: "Aceptar",
                      confirmButtonColor: "#04afaa",
                      text:"Nuevos eventos disponibles.",
                      allowOutsideClick:false,
                    })
                    .then((res)=>{
                      if(res.isConfirmed){
                        //setInitialDay(new Date(evento.start.dateTime))
                        //setFinalDay(new Date(evento.start.dateTime))
                      }
                    })
                    localStorage.setItem("eventos",events.length)
                  } 
                  
                }
        }
        //Se guarda en memoria la nueva lista de pacientes
        
        setIsLoading(false)
        setIsEmpty(false)
      } else {//Lista de eventos vacía
        setIsEmpty(true)
        setIsLoading(false)
      }

      /**
       * Función que busca el nombre de la unidad hospitalaría a la que pertenece el evento
       * @param {String} organizer Recibe el nombre del organizador en el calendario
       * @returns String Devuelve el nombre de la unidad hospitalaria encontrada
       */
      function getUhName(organizer){
        try{
            //Se toma el nombre del organizador a partir de donde se encuentre la palabra MAC
            let unidadABuscar= organizer.toUpperCase().split('MAC')[1]
            
            //Se realiza la búsqueda en la lista de unidad hospitalarias disponibles
            let busqueda = []
            uhList.forEach(
                uh => {
                    if(uh.hospital.toUpperCase().includes(unidadABuscar.toUpperCase())){
                        busqueda.push(uh.hospital.toUpperCase())
                    }
                }
            )

            if(busqueda.length > 0){//Se verifica si hay resultados en la búsqueda y se devuelve el valor localizado
                return busqueda
            }
            else{//Se devuelve el nombre del organizador del calendario
                return organizer
            }
        }
        catch{//Errores inesperados se devuelve el nombre del organizador del calendario
            return organizer
        }
      }
    },[events,uhList,userLevel,userNomHospital])

    useEffect(()=>{
      if (medicos.length > 0){
        if(localStorage.getItem("medicos")){
          let medicosEnMemoria = JSON.parse(localStorage.getItem('medicos'))
          

          if(userLevel !== "superuser"){
            let medicosUnidadHospitalaria = []
            medicos.forEach(
              medico => {
                let unidadHospitalariaEventoName = getUnidadHospitalariaName(medico.Hospital,uhList)
                if(userNomHospital.toString().toUpperCase() === unidadHospitalariaEventoName.toString().toUpperCase()){
                  medicosUnidadHospitalaria.push({id:medico.id})
                }
              }
            )
            //hadNewEvent(medicosEnMemoria,medicosUnidadHospitalaria)
            if(medicosUnidadHospitalaria.length !== medicosEnMemoria){
              const audioEl = document.getElementById('notificacion')
              audioEl.play()
              localStorage.setItem("medicos",medicosUnidadHospitalaria.length)
                    Swal.fire({
                        icon:"warning",
                        title:"Atención!",
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#04afaa",
                        text:"Nuevos médicos disponibles.",
                        allowOutsideClick:false,
                      })
                      .then((res)=>{
                        if(res.isConfirmed){
                          //setInitialDay(new Date(evento.start.dateTime))
                          //setFinalDay(new Date(evento.start.dateTime))
                        }
                      })
              
            }
          }
          else{
            //hadNewEvent(medicosEnMemoria,medicos)
          }
        }
        else{
          //localStorage.setItem("medicos",JSON.stringify(medicos))
          localStorage.setItem("medicos",medicos.length)
        }
      }

    },[medicos,userLevel,uhList,userNomHospital,isCorporativo])


    return (
        <>
            <audio id="notificacion">
                <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
            </audio>
            {/* <Notifications /> */}
            <div id="seccionCabeceraLogin" className='seccionCabeceraLogin'>
                <Header userLevel={userLevel.toUpperCase()} userNomHospital={userNomHospital} userName={userName} 
                isCorporativo={isCorporativo} setuserNomHospital={setuserNomHospital} setHospitalSeleccionado={setHospitalSeleccionado}/>
            </div>
            <br />
            <div className='cuerpoPacientes'>
                <CalendarSection tituloInicial={rangeMessage} 
                    tituloFinal={""} 
                    imgSource={"https://hospitalesmac.com/img/icono_16.png"}
                    isLoading={isLoading}
                    isEmpty={isEmpty}
                    events={events}
                    finalDay={finalDay}
                    initialDay={initialDay}
                    isMostrarRealizado={isMostrarRealizado}
                    setIsMostrarRealizado={setIsMostrarRealizado}
                    isMostrarEnProceso={isMostrarEnProceso}
                    setIsMostrarEnProceso={setIsMostrarEnProceso}
                    isMostrarEnPendiente={isMostrarEnPendiente}
                    setIsMostrarEnPendiente={setIsMostrarEnPendiente}
                    cantidadRealizada={cantidadRealizada}
                    setCantidadRealizada={setCantidadRealizada}
                    cantidadProceso={cantidadProceso}
                    setCantidadProceso={setCantidadProceso}
                    cantidadPendiente={cantidadPendiente}
                    setCantidadPendiente={setCantidadPendiente}
                    uhList={uhList}
                    isMostrarQuirofanos={isMostrarQuirofanos}
                    setIsMostrarQuirofanos={setIsMostrarQuirofanos}
                    isMostrarUsuarios={isMostrarUsuarios}
                    setIsMostrarUsuarios={setIsMostrarUsuarios}
                    userLevel={userLevel}
                    nomHospital={userNomHospital}
                    isMostrarEditar={isMostrarEditar}
                    setIsMostrarEditar={setIsMostrarEditar}
                    userName={userName}
                    cancelEvents={cancelEvents}
                    cantidadCanceladas={cantidadCanceladas}
                    setCantidadCanceladas={setCantidadCanceladas}
                    isMostrarMedicos={isMostrarMedicos}
                    setIsMostrarMedicos={setIsMostrarMedicos}
                    isReadOnly={isReadOnly}
                    setIsMostrarAgendaVista={setIsMostrarAgendaVista} isMostrarAgendaVista={isMostrarAgendaVista}
                    editEvents={editEvents} isCorporativo={isCorporativo} setuserNomHospital={setuserNomHospital}
                    isMostrarAgendar={isMostrarAgendar} setIsMostrarAgendar={setIsMostrarAgendar} hospitalSeleccionado={hospitalSeleccionado}/>
                    <DateModal initialDay={initialDay} setInitialDay={setInitialDay} finalDay={finalDay} setFinalDay={setFinalDay}/>
                <Footer />
            </div>
        </>
    )
}

export default CalendarPage;