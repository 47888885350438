/**
 * Librerias
 */
import React, { useEffect, useState } from "react";

/**
 * Styles
 */
import '../styles/header.css'
import { pacientesStore } from "../utils/firebase";

/**
 * Componente que contiene los elmentos relacionados al encabezado del sitios
 * @returns Component Devuelve el componente con los elementos requeridos
 */
const Header = ({userLevel,userNomHospital,userName,isCorporativo,setuserNomHospital,setHospitalSeleccionado}) => {

    const [hospitalesDisponibles, setHospitalesDisponibles] = useState([])

    useEffect(()=>{
        setHospitalesDisponibles([
            {
                "id": "9",
                "hospital": "Corporativo",
                "num_asociado": 6,
                "activo": true,
                "mnemonicos": "COR"
            },
            {
                "id": "20",
                "hospital": "HMAS Los Cabos",
                "num_asociado": "0",
                "mnemonicos": "LCA",
                "activo": true,
                "calendar": "Hospital-HMAS-Los Cabos"
            },
            {
                "id": "21",
                "hospital": "HMAS Querétaro",
                "num_asociado": "0",
                "mnemonicos": "QRO",
                "activo": true,
                "calendar": "Hospital-HMAS-Querétaro"
            },
            {
                "id": "11",
                "calendar": "Hospital-MAC-Aguascalientes Norte",
                "activo": true,
                "hospital": "MAC Aguascalientes Norte",
                "mnemonicos": "IRA",
                "num_asociado": "23",
                "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
            },
            {
                "id": "4",
                "calendar": "Hospital-MAC-Aguascalientes Sur",
                "activo": true,
                "hospital": "MAC Aguascalientes Sur",
                "mnemonicos": "IRA",
                "num_asociado": "23",
                "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
            },
            {
                "id": "1",
                "activo": true,
                "mnemonicos": "CEL",
                "num_asociado": 3,
                "email_calidad": "jose.campos@hospitalesmac.com",
                "hospital": "MAC Celaya",
                "calendar": "Hospital-MAC-Celaya"
            },
            {
                "id": "3",
                "calendar": "Hospital-MAC-Guadalajara",
                "activo": true,
                "hospital": "MAC Guadalajara",
                "mnemonicos": "GDL",
                "num_asociado": "0",
                "email_calidad": "calidad.irapuato@hospitalesmac.com"
            },
            {
                "id": "2",
                "calendar": "Hospital-MAC-Irapuato",
                "activo": true,
                "hospital": "MAC Irapuato",
                "mnemonicos": "IRA",
                "num_asociado": "23",
                "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
            },
            {
                "id": "22",
                "mnemonicos": "LVG",
                "activo": true,
                "num_asociado": 51,
                "hospital": "MAC La Viga",
                "calendar": "Hospital-MAC-La Viga",
                "email_calidad": ""
            },
            {
                "id": "18",
                "num_asociado": 35,
                "activo": true,
                "mnemonicos": "LEO",
                "hospital": "MAC León",
                "calendar": "Hospital-MAC-León"
            },
            {
                "id": "17",
                "mnemonicos": "MOC",
                "calendar": "Hospital-MAC-Los Mochis",
                "num_asociado": 27,
                "activo": true,
                "hospital": "MAC Los Mochis"
            },
            {
                "id": "19",
                "calendar": "Hospital-MAC-Perisur",
                "activo": true,
                "hospital": "MAC Perisur",
                "mnemonicos": "IRA",
                "num_asociado": "23",
                "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
            },
            {
                "id": "7",
                "calendar": "Hospital-MAC-Puebla",
                "activo": true,
                "hospital": "MAC Puebla",
                "mnemonicos": "PUE",
                "num_asociado": "1",
                "email_calidad": "julieta.jaquez@hospitalesmac.com"
            },
            {
                "id": "6",
                "calendar": "Hospital-MAC-San Miguel de Allende",
                "activo": true,
                "hospital": "MAC San Miguel de Allende",
                "mnemonicos": "SMA",
                "num_asociado": "0",
                "email_calidad": "calidad.sma@hospitalesmac.com"
            },
            {
                "id": "15",
                "calendar": "Hospital-MAC-Boca del Río",
                "activo": true,
                "hospital": "MediMAC Boca del Rio",
                "mnemonicos": "BDR",
                "num_asociado": "23",
                "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
            },
            {
                "id": "44",
                "num_asociado": 1,
                "mnemonicos": "CUE",
                "activo": true,
                "calendar": "Hospital-MAC-Cuemanco",
                "hospital": "MediMAC Cuemanco"
            },
            {
                "id": "45",
                "calendar": "Hospital-MAC-Ecatepec",
                "activo": true,
                "hospital": "MediMAC Ecatepec",
                "mnemonicos": "ECA",
                "num_asociado": "18",
                "email_calidad": ""
            },
            {
                "id": "16",
                "calendar": "Hospital-MAC-Guanajuato",
                "activo": true,
                "hospital": "MediMAC Guanajuato",
                "mnemonicos": "GTO",
                "num_asociado": "0",
                "email_calidad": ""
            },
            {
                "id": "42",
                "calendar": "Hospital-MAC-Merida",
                "activo": true,
                "hospital": "MediMAC Merida",
                "mnemonicos": "MER",
                "num_asociado": "12",
                "email_calidad": "angel.adrian@merida.medimac.com"
            },
            {
                "id": "8",
                "calendar": "Hospital-MAC-Mexicali",
                "activo": true,
                "hospital": "MAC Mexicali",
                "mnemonicos": "MEX",
                "num_asociado": "0",
                "email_calidad": ""
            },
            {
                "id": "13",
                "calendar": "Hospital-MAC-Tampico",
                "activo": true,
                "hospital": "MAC Tampico",
                "mnemonicos": "TMP",
                "num_asociado": "34",
                "email_calidad": ""
            },
            {
                "id": "14",
                "hospital": "MediMAC Tlalnepantla",
                "num_asociado": "0",
                "mnemonicos": "TLA",
                "activo": true,
                "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                "identificador": "warrEGfxJRVLrzcRZNsR",
                "calendar": "Hospital-MAC-Tlalnepantla"
            }
        ])

        // setHospitalesDisponibles([
        //     {
        //         "id": "9",
        //         "hospital": "Corporativo",
        //         "num_asociado": 6,
        //         "activo": true,
        //         "mnemonicos": "COR"
        //     },
        //     {
        //         "id": "999",
        //         "calendar": "Hospital-MAC-Integración Procesos Clínicos",
        //         "activo": true,
        //         "hospital": "MAC Integración Procesos Clínicos",
        //         "mnemonicos": "IPC",
        //         "num_asociado": "0",
        //         "email_calidad": ""
        //     },
        // ])
    },[])

    /**
     * Función que atrapa el evento click de salir y dispara el cierre de sesión
     * @returns Devuelve el estado del objeto
     */
     const handleSalirClick = () => (event) => {
        event.preventDefault()
        sessionStorage.clear()
        window.location.reload()
    }

    const handleChange = (e) => {
        setuserNomHospital(e.target.value)
        setHospitalSeleccionado(e.target.value)
      };


    return (
        <header className='header'>
            <div className='maxwidth'>
                <div className='datosheader_2'>
                     <a>
                        <img src="https://hospitalesmac.com/img/logo.png" title="Mac Hospitales" alt="Mac Hospitales" height="45px;" className="logo"/>
                        <div className='maxwidth'>
                            {
                                userLevel
                                    ?
                                        isCorporativo
                                            ?
                                                <h2 className='bienvenido-salida-quirofano'>
                                                    <span className="hospitalName"><select onChange={(e) => handleChange(e)}> 
                                                    {
                                                        hospitalesDisponibles.map(uh =>
                                                            <option value={uh.hospital}>{uh.hospital}</option>
                                                        )
                                                    }
                                                </select>
                                                    </span> {userLevel + ": "} {userName} <span className='salir' onClick={handleSalirClick()}>Salir</span>
                                                </h2>
                                            :
                                                <h2 className='bienvenido-salida-quirofano'><span className="hospitalName"> {userNomHospital}</span> {userLevel + ": "} {userName} <span className='salir' onClick={handleSalirClick()}>Salir</span></h2>
                                        
                                    :
                                        <h2 className='bienvenido-salida-quirofano'>{"Quirófanos Cirugía Programada"}</h2>
                            }
                        </div>
                     </a>                        
                 </div>
            </div>
        </header>
    )
}

export default Header;