/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore, calendarLog } from "../utils/firebase";

/**
 * Función que registra la solicitud de confirmación o cancelación de un evento
 * @param {String} eventId Recibe el identificador unico del evento
 * @param {String} peticion Recibe el tipo de petición a realizar "confirmar" o "cancelar"
 * @param {String} status Recibe el status a actualizar del evento
 * @param {Object} event Recibe el objeto de tipo evento antes de su actualización
 * @returns Devuelve el resultado de la solicitud
 */
export const createCalendarLog = async (eventId,peticion,status,event,AppointmentType,solicitado,create,time,id) => {
    try {
        let newEvent = event
        if(peticion === "confirmar"){
            newEvent.summary = 'Confirmada'
            pacientesStore
            .collection("eventos")
            .doc(id)
            .update({
                event: newEvent
            })
        }
        else{
            if(peticion === 'cancel'){
                pacientesStore
                .collection("eventos")
                .doc(id)
                .delete();
            }
        }
        await pacientesStore
        .collection(calendarLog)
        .add({
            eventId:    eventId,
            peticion:   peticion,
            status:     status,
            afterEvent: event,
            AppointmentType : AppointmentType,
            solicitado : solicitado,
            create:           create,
            time:           time,
        })
        return "success"
    } catch (error) {
        return error
    }
}

/**
 * Función que registra la solicitud de actualización de un evento
 * @param {String} eventId Recibe el identificador unico del evento
 * @param {String} peticion Recibe el tipo de petición a realizar "update"
 * @param {String} status Recibe el status a actualziar del evento
 * @param {Object} event Recibe el objeto de tipo evento antes de su actualización
 * @param {String} AppointmentType Recibe la descripción del evento
 * @param {String} date Recibe la fecha inical del evento
 * @param {String} dateFinal Recibe la fecha final del evento
 * @returns Devuelve el resultado de la solicitud
 */
export const createUpdateCalendarLog = async (eventId,peticion,status,event,AppointmentType,date,dateFinal,create,time,id,fechaAnterior,horaInicialAnterior,horaFinalAnterior) => {
    try {
        let newEvent = event
        
        newEvent.start.dateTime = date
        newEvent.end.dateTime = dateFinal
        newEvent.description = AppointmentType

        pacientesStore
            .collection("eventos")
            .doc(id)
            .update({
                event: newEvent
            })
        await pacientesStore
        .collection(calendarLog)
        .add({
            eventId:    eventId,
            peticion:   peticion,
            status:     status,
            afterEvent: event,
            AppointmentType : AppointmentType,
            date : date,
            dateFinal: dateFinal,
            create:           create,
            time:           time,
            fechaAnterior : fechaAnterior,
            horaInicialAnterior : horaInicialAnterior,
            horaFinalAnterior : horaFinalAnterior
        })
        return "success"
    } catch (error) {
        return error
    }
}