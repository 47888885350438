/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore,usersCollection } from '../utils/firebase';

/**
* Función que solicita el registro de un nuevo usuario a la colección de datos
* @param {String} level Recibe el nivel de acceso asignado al usuario puede ser (superuser,normal)
* @param {String} name Recibe el nombre del usuario a registrar
* @param {String} nomHospital Recibe el identificador de la unidad hospitalaria a la que se asigno el usuario
* @param {String} pass Recibe el password de acceso al sistema del usuario
* @param {String} user Recibe el nick de acceso al sistema del usuario
*/
 export const createUser = async (level,name,nomHospital,pass,user) => {
    try {
        await pacientesStore
        .collection(usersCollection)
        .add({
            Activo:             true,
            Permiso:            level,
            Nombre:             name,
            UnidadHospitalaria: nomHospital,
            Pass:               pass,
            Nick:               user,
            Systems: [
                {
                    id: 2,
                    active: true
                }
            ]
        })
        return "success"
    } catch (error) {
        return error
    }
}

/**
* Función que solicita la actualización de un usuario a la colección de datos
* @param {String} id Recibe el identificador unico del usuario
* @param {String} level Recibe el nivel de acceso asignado al usuario puede ser (superuser,normal)
* @param {String} name Recibe el nombre del usuario a registrar
* @param {String} nomHospital Recibe el identificador de la unidad hospitalaria a la que se asigno el usuario
* @param {String} pass Recibe el password de acceso al sistema del usuario
* @param {String} user Recibe el nick de acceso al sistema del usuario
* @param {String} activo Recibe el estado activo del usuario puede ser (1,0)
*/
export const updateUser = async (id,level,name,nomHospital,pass,user,activo) => {
    try {
        await pacientesStore
        .collection(usersCollection)
        .doc(id)
        .update({
            Activo:             activo,
            Permiso:            level,
            Nombre:             name,
            UnidadHospitalaria: nomHospital,
            Pass:               pass,
            Nick:               user
        })
        return "success"
      } catch (error) {
        return error
      }
}