/**
 * Librerías
 */
import React, { useEffect, useState } from 'react';
import {FaUser} from 'react-icons/fa';
import Swal from "sweetalert2"

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'

/**
 * Objetos
 */
//Importación de las funcionalidades del objeto de tipo unidadHospitalaria
import { getUnidadHospitalariaName, getUnidadHospitalariaObject } from '../objects/unidadHospitalaria';
//Importación de las funcionalidades del objeto de tipo user
import { updateUser } from '../objects/user';


/**
 * Utilerías
 */
//Utilería que contiene funciones necesarias en la lógica del funcionamiento del sitio
import { convertStringToBoolean } from '../utils/functions';

/**
 * Componente que contiene la información y funcionalidad asignada a un usuario del sistema
 * @param {Object} param0 Recibe un objeto de tipo user con su respectiva información y la información de la sesión
 * @returns Component Devuelve el componente con los elementos y funcionalidad requeridos
 */
const Usuario = ({user,uhList,userLevel,nomHospitalUser}) => {

    //Estado que almacena si el usuario corresponde a la unidad hospitalaria del usuario logeado
    const [correspondeHospital,setCorrespondeHospital] = useState(false)
    //Estado que almacena la información del paciente
    const {Permiso,Nombre,UnidadHospitalaria,Activo} = user;
    //Estilo asignado al icono de la tarjeta para usuarios activos
    const styleActive = { color: "black", margin: "5px auto 5px", height: "35px", fontSize:"2.5em"}
    //Estilo asignado al icono de la tarjeta para usuarios activos
    const styleInactive = { color: "red", margin: "5px auto 5px", height: "35px", fontSize:"2.5em"}
    
    useEffect(()=>{
        let isAutorized = false
        if(user.Systems){
            for(let j=0;j<user.Systems.length;j++){
                if(user.Systems[j].id === 2 && user.Systems[j].active){
                    isAutorized = true
                }
            }
        }

        if(userLevel === "superuser"){//Se verifica si el user loegado tiene acceso total
            //Se muestra la tarjeta de usuario
            if(Permiso !== "quirofano" && Permiso !== "recuperación" && isAutorized){
                setCorrespondeHospital(true)
            }
        }
        else{//User como acceso de tipo administrador
            if(UnidadHospitalaria.hospital === 
                getUnidadHospitalariaName(nomHospitalUser,uhList)
                && Permiso !== "superuser" && Permiso !== "quirofano" && Permiso !== "recuperación" && isAutorized){//Se verifica si el usuario pertenece a la misma unidad hospitalaria que el user logeado
                    //Se muestra la tarjeta de usuario
                    setCorrespondeHospital(true)
            }
            else{//No corresponde a la misma unidad hispitalaria
                //Se oculta la tarjeta de usuario
                setCorrespondeHospital(false)
            }
        }
    },[userLevel,nomHospitalUser,uhList,UnidadHospitalaria.hospital,Permiso,user])

    /**
     * Función que atrapa el evento para mostrar la información de un usuario
     * @param {Object} user Recibe un objeto de tipo user que contiene la información del usuario
     * @returns Devuelve el estado del objeto
     */
    const handleMostrarInformaciónClick = (user) => (event) => {
        event.preventDefault()
        let uhOpciones = ""
        if(userLevel === "superuser"){//Se verifica si el user logeado tiene acceso total
            //Se inicializa el select de unidad hospitalaria
            uhOpciones += "<option value=0>-- Seleccione la unidad hospitalaria --</option>"
        }

        uhList.forEach(uh => {
            if(userLevel === "superuser"){//Se verifica si el user logeado tiene acceso total
                //Se muestran en el select de unidades hospitalarias todas las disponibles
                if(uh.id === user.UnidadHospitalaria.id){//Se merca como seleccionada la unidad a la que pertenece el usuario
                    uhOpciones+="<option value="+uh.id+" selected>"+uh.hospital+"</option>"
                }
                else{
                    uhOpciones+="<option value="+uh.id+">"+uh.hospital+"</option>"
                }
            }
            else{//Usuario con acceso de adminitrador
                if(uh.id === user.UnidadHospitalaria.id){//Se muestra en el select de unidad hospitalaria solo a la que esta asignado el user logeado
                    uhOpciones+="<option value="+uh.id+" selected>"+uh.hospital+"</option>"
                }
            }
        })

        let levelOpciones = ""
        if(user.Permiso === "superuser"){//Se verifica si el user es de tipo superuser
            //Se muestra en el select de nivel de permiso todos los niveles disponibles
            if(userLevel === "superuser"){
                levelOpciones += "<option value=superuser selected>SuperUser</option>"
            }
            levelOpciones += "<option value=administrador>Administrador</option>"
            levelOpciones += "<option value=agenda>Agenda</option>"
            levelOpciones += "<option value=calidad>Calidad</option>"
            levelOpciones += "<option value=tablero>Tablero</option>"
            levelOpciones += "<option value=mesa>Mesa</option>"
        }
        else{
            if(user.Permiso === "administrador"){//Se verifica si el user es de tipo administrador y se selecciona la opción Administrador
                if(userLevel === "superuser"){//Si el usuario loegado tiene acceso total se muetsra la opción SuperUser
                    levelOpciones += "<option value=superuser>SuperUser</option>"
                }
                levelOpciones += "<option value=administrador selected>Administrador</option>"
                levelOpciones += "<option value=agenda>Agenda</option>"
                levelOpciones += "<option value=calidad>Calidad</option>"
                levelOpciones += "<option value=tablero>Tablero</option>"
                levelOpciones += "<option value=mesa>Mesa</option>"
            }
            else{//Se selecciona la opción calidad
                if(user.Permiso === "calidad"){
                    if(userLevel === "superuser"){//Si el usuario loegado tiene acceso total se muetsra la opción SuperUser
                        levelOpciones += "<option value=superuser>SuperUser</option>"
                    }
                    levelOpciones += "<option value=administrador>Administrador</option>"
                    if(user.Permiso === "calidad"){
                        levelOpciones += "<option value=calidad selected>Calidad</option>"
                    }
                    levelOpciones += "<option value=agenda>Agenda</option>"
                    levelOpciones += "<option value=tablero>Tablero</option>"
                    levelOpciones += "<option value=mesa>Mesa</option>"
                }
                else{
                    if(userLevel === "superuser"){//Si el usuario loegado tiene acceso total se muetsra la opción SuperUser
                        levelOpciones += "<option value=superuser>SuperUser</option>"
                    }
                    levelOpciones += "<option value=administrador>Administrador</option>"
                    levelOpciones += "<option value=calidad>Calidad</option>"
                    if(user.Permiso === "agenda"){
                        levelOpciones += "<option value=agenda selected>Agenda</option>"
                        levelOpciones += "<option value=tablero>Tablero</option>"
                        levelOpciones += "<option value=mesa>Mesa</option>"
                    }
                    else{
                        if(user.Permiso === "tablero"){
                            levelOpciones += "<option value=agenda>Agenda</option>"
                            levelOpciones += "<option value=tablero selected>Tablero</option>"
                            levelOpciones += "<option value=mesa>Mesa</option>"
                        }
                        else{
                            levelOpciones += "<option value=agenda>Agenda</option>"
                            levelOpciones += "<option value=tablero>Tablero</option>"
                            levelOpciones += "<option value=mesa selected>Mesa</option>"
                        }
                    }
                }
            }
        }

        let activoOpciones = ""
        if(user.Activo){//Se verifica si el user esta activo y se selecciona la opción Activo
            activoOpciones += "<option value=1 selected>Activo</option>"
            activoOpciones += "<option value=0>Inactivo</option>"
        }
        else{//Se selecciona la opción Inactivo
            activoOpciones += "<option value=1>Activo</option>"
            activoOpciones += "<option value=0 selected>Inactivo</option>"
        }

        if(userLevel !== "mesa"){
            Swal.fire({
                customClass:'modalRegistro',
                html:`<div class="form_wrapper">
                    <div class="form_container">
                        <div class="row clearfix">
                            <div class="">
                                <form autocomplete="off" method="post">
                                    <h2 class="modalTitle">Información del usuario</h2>
                                    <br />
                                    <div class="input_field">
                                        <input type="text" name="inputNombre" id="inputNombre" 
                                        placeholder="Ingrese el nombre del usuario" required  class="inputText" autocomplete="off" 
                                        value="`+user.Nombre+`" 
                                        oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')" readonly/>
                                    </div>
                                    <div class="input_field select_option">
                                        <select id="selectNivel">
                                            <option value="0">-- Seleccione el nivel de permiso --</option>
                                            `+levelOpciones+`
                                        </select>
                                        <div class="select_arrow"></div>
                                    </div>
                                    <div class="input_field select_option">
                                        <select id="selectUH">
                                            `+uhOpciones+`
                                        </select>
                                        <div class="select_arrow"></div>
                                    </div>
                                    <div class="input_field">
                                        <input type="text" name="inputNick" id="inputNick" 
                                        placeholder="Ingrese el usuario de acceso" required class="inputText" autocomplete="off" 
                                        value="`+user.Nick+`" 
                                        oninput="this.value=value.replace(/[' ']/g,'')" readonly/>
                                    </div>
                                    <div class="input_field">
                                        <input type="text" name="inputPass" id="inputPass" placeholder="Ingrese el password de acceso" 
                                        required class="inputText" autocomplete="off" value="`+user.Pass+`"
                                        oninput="this.value=value.replace(/[' ']/g,'')" readonly/>
                                    </div>
                                    <div class="input_field select_option">
                                        <select id="selectActivo">
                                            <option value="cero">-- Seleccione el estatus del usuario --</option>
                                            `+activoOpciones+`
                                        </select>
                                        <div class="select_arrow"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>`,
                showDenyButton: true,
                denyButtonText: "Cancelar",
                denyButtonColor: "grey",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#04afaa",
                scrollbarPadding:false,
                width:'500px',
                heightAuto: false,
                focusConfirm: false,
                showCloseButton:true,
                preConfirm: () => {
                    if (document.getElementById('inputNombre').value) {//Se verifica que se haya proporcionado un nombre de usuario valido
                        if(document.getElementById('selectNivel').value 
                            && document.getElementById('selectNivel').value !== "0"){//Se verifica que se haya seleccionado un nivel de permiso valido
                            if(document.getElementById('selectUH').value 
                                && document.getElementById('selectUH').value !== "0"){//Se verifica que se haya seleccionado una unidad hospitalaria valida
                                if(document.getElementById('inputNick').value){//Se verifica que se haya proporcionado un user de acceso valido
                                    if(document.getElementById('inputPass').value){//Se verifica que se haya proporcionado un password de acceso valido
                                        if(document.getElementById('selectActivo').value !== "cero"){//Se verifica que se haya seleccionado un esatdo valido
                                            if(document.getElementById('selectNivel').value !== user.Permiso
                                            || document.getElementById('inputNombre').value !== user.Nombre
                                            || document.getElementById('selectUH').value !== user.UnidadHospitalaria.id
                                            || document.getElementById('inputPass').value !== user.Pass
                                            || document.getElementById('inputNick').value !== user.Nick
                                            || convertStringToBoolean(document.getElementById('selectActivo').value) !== user.Activo){//Se verifica que se hayan realizado cambios
                                                //Solicitar la actualización de un usuario
                                                solicitarActualizarUser()
                                                async function solicitarActualizarUser(){
    
                                                    const response = await updateUser(user.id,
                                                        document.getElementById('selectNivel').value,
                                                        document.getElementById('inputNombre').value.replace(/\s+/g, ' ').trim(),
                                                        getUnidadHospitalariaObject(document.getElementById('selectUH').value,uhList),
                                                        document.getElementById('inputPass').value,
                                                        document.getElementById('inputNick').value,
                                                        convertStringToBoolean(document.getElementById('selectActivo').value))
                                                    
                                                        if(response === "success"){//Actualización de usuario exitosa
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: 'Actualización de usuario',
                                                                text: 'Usuario actualizado de manera exitosa',
                                                                confirmButtonText: "Aceptar",
                                                                confirmButtonColor: "#04afaa",
                                                              })
                                                        }
                                                        else{//Error en la actualización de usuario
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Error',
                                                                text: response,
                                                              })
                                                        }
                                                }
                                            }
                                            else{//Usuario sin cambios presentados
                                                Swal.showValidationMessage('No ha realizado cambios')
                                            }
                                        }
                                        else{//Estado del usuario invalid
                                            Swal.showValidationMessage('Proporcione el estado del usuario')   
                                        }
                                    }
                                    else{//Password de acceso invalido
                                        Swal.showValidationMessage('Proporcione el password de acceso del usuario')   
                                    }
                                }
                                else{//User de acceso invalido
                                    Swal.showValidationMessage('Proporcione el user de acceso del usuario')   
                                }
                            }
                            else{//Unidad Hospitalaria invalida
                                Swal.showValidationMessage('Proporcione la unidad hospitalaria')
                            }
                        }
                        else{//Nivel de acceso invalido
                            Swal.showValidationMessage('Proporcione el nivel de acceso') 
                        }
                     } else {//Nombre de usuario invalido
                       Swal.showValidationMessage('Proporcione el nombre del usuario')   
                     }
                  }
            })
        }
        else{
            Swal.fire({
                customClass:'modalRegistro',
                html:`<div class="form_wrapper">
                    <div class="form_container">
                        <div class="row clearfix">
                            <div class="">
                                <form autocomplete="off" method="post">
                                    <h2 class="modalTitle">Información del usuario</h2>
                                    <br />
                                    <div class="input_field">
                                        <input type="text" name="inputNombre" id="inputNombre" 
                                        placeholder="Ingrese el nombre del usuario" required  class="inputText" autocomplete="off" 
                                        value="`+user.Nombre+`" 
                                        oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                    </div>
                                    <div class="input_field select_option">
                                        <select id="selectNivel">
                                            <option value="0">-- Seleccione el nivel de permiso --</option>
                                            `+levelOpciones+`
                                        </select>
                                        <div class="select_arrow"></div>
                                    </div>
                                    <div class="input_field select_option">
                                        <select id="selectUH">
                                            `+uhOpciones+`
                                        </select>
                                        <div class="select_arrow"></div>
                                    </div>
                                    <div class="input_field">
                                        <input type="text" name="inputNick" id="inputNick" 
                                        placeholder="Ingrese el usuario de acceso" required class="inputText" autocomplete="off" 
                                        value="`+user.Nick+`" 
                                        oninput="this.value=value.replace(/[' ']/g,'')"/>
                                    </div>
                                    <div class="input_field">
                                        <input type="text" name="inputPass" id="inputPass" placeholder="Ingrese el password de acceso" 
                                        required class="inputText" autocomplete="off" value="`+user.Pass+`"
                                        oninput="this.value=value.replace(/[' ']/g,'')"/>
                                    </div>
                                    <div class="input_field select_option">
                                        <select id="selectActivo">
                                            <option value="cero">-- Seleccione el estatus del usuario --</option>
                                            `+activoOpciones+`
                                        </select>
                                        <div class="select_arrow"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>`,
                showDenyButton: true,
                denyButtonText: "Cancelar",
                denyButtonColor: "grey",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#04afaa",
                scrollbarPadding:false,
                width:'500px',
                heightAuto: false,
                focusConfirm: false,
                showCloseButton:true,
                preConfirm: () => {
                    if (document.getElementById('inputNombre').value) {//Se verifica que se haya proporcionado un nombre de usuario valido
                        if(document.getElementById('selectNivel').value 
                            && document.getElementById('selectNivel').value !== "0"){//Se verifica que se haya seleccionado un nivel de permiso valido
                            if(document.getElementById('selectUH').value 
                                && document.getElementById('selectUH').value !== "0"){//Se verifica que se haya seleccionado una unidad hospitalaria valida
                                if(document.getElementById('inputNick').value){//Se verifica que se haya proporcionado un user de acceso valido
                                    if(document.getElementById('inputPass').value){//Se verifica que se haya proporcionado un password de acceso valido
                                        if(document.getElementById('selectActivo').value !== "cero"){//Se verifica que se haya seleccionado un esatdo valido
                                            if(document.getElementById('selectNivel').value !== user.Permiso
                                            || document.getElementById('inputNombre').value !== user.Nombre
                                            || document.getElementById('selectUH').value !== user.UnidadHospitalaria.id
                                            || document.getElementById('inputPass').value !== user.Pass
                                            || document.getElementById('inputNick').value !== user.Nick
                                            || convertStringToBoolean(document.getElementById('selectActivo').value) !== user.Activo){//Se verifica que se hayan realizado cambios
                                                //Solicitar la actualización de un usuario
                                                solicitarActualizarUser()
                                                async function solicitarActualizarUser(){
    
                                                    const response = await updateUser(user.id,
                                                        document.getElementById('selectNivel').value,
                                                        document.getElementById('inputNombre').value.replace(/\s+/g, ' ').trim(),
                                                        getUnidadHospitalariaObject(document.getElementById('selectUH').value,uhList),
                                                        document.getElementById('inputPass').value,
                                                        document.getElementById('inputNick').value,
                                                        convertStringToBoolean(document.getElementById('selectActivo').value))
                                                    
                                                        if(response === "success"){//Actualización de usuario exitosa
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: 'Actualización de usuario',
                                                                text: 'Usuario actualizado de manera exitosa',
                                                                confirmButtonText: "Aceptar",
                                                                confirmButtonColor: "#04afaa",
                                                              })
                                                        }
                                                        else{//Error en la actualización de usuario
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Error',
                                                                text: response,
                                                              })
                                                        }
                                                }
                                            }
                                            else{//Usuario sin cambios presentados
                                                Swal.showValidationMessage('No ha realizado cambios')
                                            }
                                        }
                                        else{//Estado del usuario invalid
                                            Swal.showValidationMessage('Proporcione el estado del usuario')   
                                        }
                                    }
                                    else{//Password de acceso invalido
                                        Swal.showValidationMessage('Proporcione el password de acceso del usuario')   
                                    }
                                }
                                else{//User de acceso invalido
                                    Swal.showValidationMessage('Proporcione el user de acceso del usuario')   
                                }
                            }
                            else{//Unidad Hospitalaria invalida
                                Swal.showValidationMessage('Proporcione la unidad hospitalaria')
                            }
                        }
                        else{//Nivel de acceso invalido
                            Swal.showValidationMessage('Proporcione el nivel de acceso') 
                        }
                     } else {//Nombre de usuario invalido
                       Swal.showValidationMessage('Proporcione el nombre del usuario')   
                     }
                  }
            })
        }
        
    }

    return (
        <>
            {
                correspondeHospital ? 
                (
                    <div className="itemuser" onClick={handleMostrarInformaciónClick(user)}>
                        <div>
                            {/* <img src="http://hospitalesmac.com/img/icono_9.png" title="Pendiente de salida" alt="Pendiente de salida" height="48px" className='imgservi'/> */}
                            {
                                Activo?<FaUser style={styleActive}/>
                                : <FaUser style={styleInactive}/>
                            }
                            <h1 className='info_item_hospital_name'>{getUnidadHospitalariaName(UnidadHospitalaria.id,uhList)}</h1>       
                            <h1 className='itemblogTitle'>{Nombre.toUpperCase()}</h1>                 
                            <h1 className='info_itemblog'>{Permiso.toUpperCase()}</h1>
                        </div>
                    </div>
                )
                : null
            }
        </>
    )
}

export default Usuario;