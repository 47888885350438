/**
 * Librerías
 */
import React, { useEffect, useState } from "react";
import {BsFillCalendarPlusFill} from 'react-icons/bs';
import { HiSearch } from "react-icons/hi";
import moment from 'moment';

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'


/**
 * Components
 */
//Componente que muestra la información de los requerimientos del médico tratante para la cirugía
import DetallesModal from "./detallesModal";

/**
 * Componente asignado al muestreo de la información de un evento
 * @param {Object} param0 Recibe los elementos necesarios para mostrar los detalles de un evento específico
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const CancelEventCard = ({event,finalDay,initialDay,isMostrarRealizado,isMostrarEnProceso,isMostrarEnPendiente,uhList,
    eventoSeleccionado,setEventoSeleccionado,setIsMostrarEditar,nomHospital,userLevel,
    userName,isReadOnly,events}) => {

    //Estado que indica si el evento tiene requerimientos
    const [hadRequerimientos, setHadRequerimientos] = useState(false)
    //Estado que almacena la referencia del médico tratante
    const [medico,setMedico] = useState("")
    //Estado que almacena el nombre del paciente
    const [paciente,setPaciente] = useState("")
    //Estado que almacena el procedimiento a realizar
    const [procedimiento,setProcedimiento] = useState("")
    //Estado que almacena el quirófano solicitado
    const [quirofano,setQuirofano] = useState("")
    //Estado que almacena el teléfono de contacto del médico tratante
    const [telefonoMedico,setTelefonoMedico] = useState("")
    //Estado que almacena el teléfono de contacto del paciente
    const [telefonoPaciente,setTelefonoPaciente] = useState("")
    //Estado que almacena el nombre de la unidad hospitalaria en la que se reserva el evento
    const [unidadHospitalaria,setUnidadHospitalaria] = useState("")
    
    const [isPacific, setIsPacific] = useState(false)
    const [fechaInicio, setFechaInicio] = useState("")
    const [fechaFinal, setFechaFinal] = useState("")

    //Estilo asignado al icono de la tarjeta para pacientes en quirófano
    const styleAltaAdministrativa = { color: "red", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}

    useEffect(()=>{

        var MyDate = new Date();
        if(MyDate.toString().includes("GMT-0700")){
            setIsPacific(true)
        }
        else{
            setIsPacific(false)
        }

        const resultado = uhList.find( uh => uh.calendar === event.UnidadHospitalaria )
        if(resultado){
            setUnidadHospitalaria(resultado.hospital)
        }
        else{
            setUnidadHospitalaria(getUhName(event.organizer.displayName))
        }
        setQuirofano(event.Sala)
        setMedico(event.Medico)
        setPaciente(event.Paciente)
        setProcedimiento(event.Procedimiento)
        setHadRequerimientos(true)

        if(event.TelefonoMedico){
            setTelefonoMedico(event.TelefonoMedico)
        }
        else{
            setTelefonoMedico("SR")
        }

        if(event.TelefonoPaciente){
            setTelefonoPaciente(event.TelefonoPaciente)
        }
        else{
            setTelefonoPaciente("SR")
        }

        if(parseInt(event.start.split('T')[1].split(':')[0]) < 12){
            setFechaInicio(event.start.split('T')[0].split('-')[2] + '-' + event.start.split('T')[0].split('-')[1] + '-' + event.start.split('T')[0].split('-')[0] + ' ' + event.start.split('T')[1].split(':')[0] + ":" + event.start.split('T')[1].split(':')[1] + " am")
        }
        else{
            let hora = parseInt(event.start.split('T')[1].split(':')[0])
            hora = hora - 12
            setFechaInicio(event.start.split('T')[0].split('-')[2] + '-' + event.start.split('T')[0].split('-')[1] + '-' + event.start.split('T')[0].split('-')[0] + ' ' + hora.toString().padStart(2,'0') + ":" + event.start.split('T')[1].split(':')[1] + " pm")
        }

        if(parseInt(event.end.split('T')[1].split(':')[0]) < 12){
            setFechaFinal(event.end.split('T')[0].split('-')[2] + '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0] + ' ' + event.end.split('T')[1].split(':')[0] + ":" + event.end.split('T')[1].split(':')[1] + " am")
        }
        else{
            let hora = parseInt(event.end.split('T')[1].split(':')[0])
            hora = hora - 12
            setFechaFinal(event.end.split('T')[0].split('-')[2] + '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0] + ' ' + hora.toString().padStart(2,'0') + ":" + event.end.split('T')[1].split(':')[1] + " pm")
        }

        function getUhName(organizer){
            try{
                //Se toma el nombre del organizador a partir de donde se encuentre la palabra MAC
                let unidadABuscar= organizer.toUpperCase().split('MAC')[1]
                
                //Se realiza la búsqueda en la lista de unidad hospitalarias disponibles
                let busqueda = []
                uhList.forEach(
                    uh => {
                        if(uh.hospital.toUpperCase().includes(unidadABuscar.toUpperCase())){
                            busqueda.push(uh.hospital.toUpperCase())
                        }
                    }
                )

                if(busqueda.length > 0){//Se verifica si hay resultados en la búsqueda y se devuelve el valor localizado
                    return busqueda
                }
                else{//Se devuelve el nombre del organizador del calendario
                    return organizer
                }
            }
            catch{//Errores inesperados se devuelve el nombre del organizador del calendario
                return organizer
            }
        }
    },[event,initialDay,finalDay,isMostrarRealizado,isMostrarEnProceso,isMostrarEnPendiente,uhList,userLevel,nomHospital])

    /**
     * Función que atrapa el evento click para mostrar los requerimientos y dispara el muestro del modal
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
    const handleShowRequerimientosClick = (evento) => (event) => {
        event.preventDefault()
        let eventoObtenido = {
            "organizer": {
                "email": "c_oepg1ijckdt9pq2k77p2gap6ug@group.calendar.google.com",
                "displayName": "Calendario Quirófanos Hospital MAC Celaya",
                "self": true
            },
            "eventType": "default",
            "creator": {
                "email": "surgeryroomagendahospitalmacce@auto-factura-erps.iam.gserviceaccount.com"
            },
            "created": "2023-09-27T01:06:27.000Z",
            "reminders": {
                "useDefault": true
            },
            "description": "AppointmentType="+quirofano+"|Medico="+medico+"|Paciente="+paciente+"|Procedimiento="+procedimiento+"|Equipo Quirúrgico="+evento.Materiales+"|Extras="+evento.Extras+"|Telefono Medico=6681507387|Telefono Paciente=6871079122|Unidad Hospitalaroa=Hospital-MAC-Los Mochis|Duracion="+evento.Duracion+"|LadoProcedimiento="+evento.LadoProcedimiento+"|Anestesiologo="+evento.Anestesiologo+"|TipoAnestesia="+evento.TipoAnestesia+"|FechaNacimientoPaciente="+evento.FechaNacimiento+"|EdadPaciente="+evento.Edad+"|SexoPaciente="+evento.Sexo+"|TipoCliente="+evento.TipoCliente+"|Diagnostico="+evento.Diagnostico+"|Solicitado="+evento.Realizo+"|Concepto="+evento.UltimaSolicitud+"",
            "etag": "\"3391553575454000\"",
            "iCalUID": "kftg7icjrl1j7u4729manfentg@google.com",
            "updated": "2023-09-27T01:06:27.727Z",
            "summary": "Confirmada",
            "id": "kftg7icjrl1j7u4729manfentg",
            "start": {
                "timeZone": "UTC",
                "dateTime": evento.start
            },
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=a2Z0ZzdpY2pybDFqN3U0NzI5bWFuZmVudGcgY19vZXBnMWlqY2tkdDlwcTJrNzdwMmdhcDZ1Z0Bn",
            "id_evento": "ZwOxuK2YAVr64c3H0rL2",
            "end": {
                "timeZone": "UTC",
                "dateTime": evento.end
            },
            "kind": "calendar#event",
            "sequence": 0
        }
        setEventoSeleccionado(eventoObtenido)
        if(document.getElementById('modal').style.display === "none" || !document.getElementById('modal').style.display){
            document.getElementById('modal').style.display='block'
        }
    }

    return (
        <>
            {
                <div className="itemblogExpired">
                    <div>
                        <BsFillCalendarPlusFill style={styleAltaAdministrativa}/>
                        <h1 className='itemblogTitleEstatus'>{"Cirugía cancelada"}</h1>
                        <h1 className='itemblogTitle'>{quirofano.toUpperCase()}</h1>        
                            <h1 className='info_item_hospital_name'>{unidadHospitalaria}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Solicitado el ".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{moment(event.created).format('DD-MM-YYYY hh:mm:ss a')}</h1>           
                            <h1 className='info_itemblogSubtitle'>{"Médico".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{medico.toUpperCase()}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Teléfono Médico".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{telefonoMedico}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Paciente".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{paciente.toUpperCase()}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Teléfono Paciente".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{telefonoPaciente}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Procedimiento".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{procedimiento.toUpperCase()}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Inicio".toUpperCase()}</h1>
                        {
                            isPacific
                                ?
                                    <h1 className='info_itemblog'>{fechaInicio}</h1>
                                :
                                    <h1 className='info_itemblog'>{fechaInicio}</h1>
                        }
                        <h1 className='info_itemblogSubtitle'>{"Fin".toUpperCase()}</h1>
                        {
                            isPacific
                                ?
                                    <h1 className='info_itemblog'>{fechaFinal}</h1>
                                :
                                    <h1 className='info_itemblog'>{fechaFinal}</h1>
                        }                 
                        {
                            hadRequerimientos 
                                ?
                                    <h1 className='info_itemblogRequerimientos' onClick={handleShowRequerimientosClick(event)}><HiSearch /> { "Detalles".toUpperCase()}</h1> 
                                :
                                    null
                        }
                    </div>
                </div>
            }
            {
                <DetallesModal eventoSeleccionado={eventoSeleccionado}/>
            }
        </>
    )
}

export default CancelEventCard;