/**
 * Librerías
 */
import React, {useState} from 'react';
import Swal from "sweetalert2"
import {FaEye} from 'react-icons/fa';
import {FaEyeSlash} from 'react-icons/fa';

/**
 * Styles
 */
import '../styles/login.css'
import '../styles/tarjetaContenedor.css'

/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore y las colecciones necesarias
import { pacientesStore, usersCollection } from '../utils/firebase';
//import { createCIE } from '../objects/calendarLog';

/**
 * Objetos
 */
//Importar la funcionalidad relacionada a los objetos de tipo session
import { createSession, updateSession } from '../objects/session';

/**
 * Componentes
 */
//Componente que muestra los elementos relacionados con el Footer
import Footer from '../components/footer';
//Componente que muestra los elementos relacionados con el Header
import Header from "../components/header";

const customStyles = {
    margin:"50px auto"
  };

/**
 * Page asignada a la visualización del login de acceso
 * @param {Object} param0 Recibe las funcionalidades para definir la información de sesión
 * @returns Component Devuelve el sitio con los elementos y funcionalidad asignada
 */
const Login = ({setuserNomHospital,setUserName,setUserIslogged,setUserLevel,userIsLogged,sessionList,setIsCorporativo}) => {

    //Estado que almacena el nick de acceso proporcionado por el usuario
    const [nick,setNick] = useState("")
    //Estado que almacena el password de acceso proporcionado por el usuario
    const [pass,setPass] = useState("")
    //Estado que indica si se muestran los caracteres del password de acceso
    const [seMuestra,setSeMuestra] = useState(false)

    //Estilo asignado al icono de la tarjeta para password de acceso
    const styleIcon = { color: "black", margin: "3px auto 3px", height: "18px", fontSize:"3.0em"}


    /**
     * Función que permite comprobar si el usuario tiene acceso y guardar su respectiva sesión
     */
     const getLogin = () => {
        pacientesStore.collection(usersCollection)
            .onSnapshot(snap => {
                const users = []
                snap.forEach(doc => {
                  users.push({ id: doc.id, ...doc.data() })
                })

                let mensaje = "Información de acceso no encontrada"
                let userFound = false
                //setMessage("Información de acceso no encontrada")
                for(let i=0;i<users.length;i++){
                    let unidadHospitalaria = users[i].UnidadHospitalaria
                  if(users[i].Nick === nick && users[i].Pass === pass){
                    let isAutorized = false
                    if(users[i].Systems){
                        for(let j=0;j<users[i].Systems.length;j++){
                            if(users[i].Systems[j].id === 2 && users[i].Systems[j].active){
                                isAutorized = true
                            }
                            if(users[i].Systems[j].id === "2" && users[i].Systems[j].active){
                                isAutorized = true
                            }
                        }
                    }
                    if(users[i].Activo){
                        if(users[i].Permiso !== "quirofano" && users[i].Permiso !== "recuperacion" && isAutorized){
                            let token = genRandonString(32)
                            function genRandonString(length) {
                                var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
                                var charLength = chars.length;
                                var result = '';
                                for ( var i = 0; i < length; i++ ) {
                                   result += chars.charAt(Math.floor(Math.random() * charLength));
                                }
                                return result;
                            }

                            mensaje = "Usuario activado exitosamente"
                            userFound = true
                            if(!userIsLogged){
                                if(users[i].Permiso !== "calidad"){
                                    const resultado = sessionList.find( session => session.uh === unidadHospitalaria.hospital && session.system === 2 && session.level !== "calidad");
                                    if(resultado){
                                        if(resultado.id){
                                            solicitarActualizarSession()
                                            async function solicitarActualizarSession(){
                                                const response = await updateSession(resultado.id,
                                                    users[i].Permiso,
                                                    2,
                                                    token,
                                                    unidadHospitalaria.hospital,
                                                    users[i].id)
                                                
                                                    if(response === "success"){//Actualización de usuario exitosa
                                                        sessionStorage.setItem('id',users[i].id)
                                                        sessionStorage.setItem('nomHospital',unidadHospitalaria.hospital)
                                                        setuserNomHospital(unidadHospitalaria.hospital)
                                                        if(unidadHospitalaria.hospital === "Corporativo"){
                                                            setIsCorporativo(true)
                                                        }
                                                        else{
                                                            setIsCorporativo(false)
                                                        }
                                                        sessionStorage.setItem('name',users[i].Nombre)
                                                        setUserName(users[i].Nombre)
                                                        sessionStorage.setItem('level',users[i].Permiso)
                                                        setUserLevel(users[i].Permiso)
                                                        sessionStorage.setItem('logged',true)
                                                        sessionStorage.setItem('token',token)
                                                        setUserIslogged(true)
                                                    }
                                            }
                                        }
                                        else{
                                            solicitarRegistroSession()
                                            async function solicitarRegistroSession(){
                                                const response = await createSession(users[i].Permiso,
                                                    2,
                                                    token,
                                                    unidadHospitalaria.hospital,
                                                    users[i].id)
                                                
                                                    if(response === "success"){//Actualización de usuario exitosa
                                                        sessionStorage.setItem('id',users[i].id)
                                                        sessionStorage.setItem('nomHospital',unidadHospitalaria.hospital)
                                                        setuserNomHospital(unidadHospitalaria.hospital)
                                                        if(unidadHospitalaria.hospital === "Corporativo"){
                                                            setIsCorporativo(true)
                                                        }
                                                        else{
                                                            setIsCorporativo(false)
                                                        }
                                                        sessionStorage.setItem('name',users[i].Nombre)
                                                        setUserName(users[i].Nombre)
                                                        sessionStorage.setItem('level',users[i].Permiso)
                                                        setUserLevel(users[i].Permiso)
                                                        sessionStorage.setItem('logged',true)
                                                        sessionStorage.setItem('token',token)
                                                        setUserIslogged(true)
                                                    }
                                            }
                                        }
                                    }
                                    else{
                                        solicitarRegistroSession()
                                            async function solicitarRegistroSession(){
                                                const response = await createSession(users[i].Permiso,
                                                    2,
                                                    token,
                                                    unidadHospitalaria.hospital,
                                                    users[i].id)
                                                
                                                    if(response === "success"){//Actualización de usuario exitosa
                                                        sessionStorage.setItem('id',users[i].id)
                                                        sessionStorage.setItem('nomHospital',unidadHospitalaria.hospital)
                                                        setuserNomHospital(unidadHospitalaria.hospital)
                                                        if(unidadHospitalaria.hospital === "Corporativo"){
                                                            setIsCorporativo(true)
                                                        }
                                                        else{
                                                            setIsCorporativo(false)
                                                        }
                                                        sessionStorage.setItem('name',users[i].Nombre)
                                                        setUserName(users[i].Nombre)
                                                        sessionStorage.setItem('level',users[i].Permiso)
                                                        setUserLevel(users[i].Permiso)
                                                        sessionStorage.setItem('logged',true)
                                                        sessionStorage.setItem('token',token)
                                                        setUserIslogged(true)
                                                    }
                                            }
                                    }
                                }
                                else{
                                    const resultado = sessionList.find( session => session.uh === unidadHospitalaria.hospital && session.system === 2 && session.level === users[i].Permiso);
                                    if(resultado){
                                        if(resultado.id){
                                            solicitarActualizarSession()
                                            async function solicitarActualizarSession(){
                                                const response = await updateSession(resultado.id,
                                                    users[i].Permiso,
                                                    2,
                                                    token,
                                                    unidadHospitalaria.hospital,
                                                    users[i].id)
                                                
                                                    if(response === "success"){//Actualización de usuario exitosa
                                                        sessionStorage.setItem('id',users[i].id)
                                                        sessionStorage.setItem('nomHospital',unidadHospitalaria.hospital)
                                                        setuserNomHospital(unidadHospitalaria.hospital)
                                                        if(unidadHospitalaria.hospital === "Corporativo"){
                                                            setIsCorporativo(true)
                                                        }
                                                        else{
                                                            setIsCorporativo(false)
                                                        }
                                                        sessionStorage.setItem('name',users[i].Nombre)
                                                        setUserName(users[i].Nombre)
                                                        sessionStorage.setItem('level',users[i].Permiso)
                                                        setUserLevel(users[i].Permiso)
                                                        sessionStorage.setItem('logged',true)
                                                        sessionStorage.setItem('token',token)
                                                        setUserIslogged(true)
                                                    }
                                            }
                                        }
                                        else{
                                            solicitarRegistroSession()
                                            async function solicitarRegistroSession(){
                                                const response = await createSession(users[i].Permiso,
                                                    2,
                                                    token,
                                                    unidadHospitalaria.hospital,
                                                    users[i].id)
                                                
                                                    if(response === "success"){//Actualización de usuario exitosa
                                                        sessionStorage.setItem('id',users[i].id)
                                                        sessionStorage.setItem('nomHospital',unidadHospitalaria.hospital)
                                                        setuserNomHospital(unidadHospitalaria.hospital)
                                                        if(unidadHospitalaria.hospital === "Corporativo"){
                                                            setIsCorporativo(true)
                                                        }
                                                        else{
                                                            setIsCorporativo(false)
                                                        }
                                                        sessionStorage.setItem('name',users[i].Nombre)
                                                        setUserName(users[i].Nombre)
                                                        sessionStorage.setItem('level',users[i].Permiso)
                                                        setUserLevel(users[i].Permiso)
                                                        sessionStorage.setItem('logged',true)
                                                        sessionStorage.setItem('token',token)
                                                        setUserIslogged(true)
                                                    }
                                            }
                                        }
                                    }
                                    else{
                                        solicitarRegistroSession()
                                            async function solicitarRegistroSession(){
                                                const response = await createSession(users[i].Permiso,
                                                    2,
                                                    token,
                                                    unidadHospitalaria.hospital,
                                                    users[i].id)
                                                
                                                    if(response === "success"){//Actualización de usuario exitosa
                                                        sessionStorage.setItem('id',users[i].id)
                                                        sessionStorage.setItem('nomHospital',unidadHospitalaria.hospital)
                                                        setuserNomHospital(unidadHospitalaria.hospital)
                                                        if(unidadHospitalaria.hospital === "Corporativo"){
                                                            setIsCorporativo(true)
                                                        }
                                                        else{
                                                            setIsCorporativo(false)
                                                        }
                                                        sessionStorage.setItem('name',users[i].Nombre)
                                                        setUserName(users[i].Nombre)
                                                        sessionStorage.setItem('level',users[i].Permiso)
                                                        setUserLevel(users[i].Permiso)
                                                        sessionStorage.setItem('logged',true)
                                                        sessionStorage.setItem('token',token)
                                                        setUserIslogged(true)
                                                    }
                                            }
                                    }
                                }
                            }
                        }
                        else{
                            mensaje = "Usuario solo con acceso al sistema de momentos de quirófano"
                            //setMessage("Usuario solo con acceso al sistema de momentos de quirófano")
                            sessionStorage.setItem('nomHospital','')
                            setuserNomHospital('')
                            setIsCorporativo(false)
                            sessionStorage.setItem('name','')
                            setUserName('')
                            sessionStorage.setItem('level','')
                            setUserLevel('')
                            sessionStorage.setItem('logged',false)
                            setUserIslogged(false)
                        }
                    }
                    else{
                        mensaje = "Usuario sin acceso"
                        //setMessage("Usuario sin acceso")
                        sessionStorage.setItem('nomHospital','')
                        setuserNomHospital('')
                        setIsCorporativo(false)
                        sessionStorage.setItem('name','')
                        setUserName('')
                        sessionStorage.setItem('level','')
                        setUserLevel('')
                        sessionStorage.setItem('logged',false)
                        setUserIslogged(false)
                    }
                  }
                }
                if(!userFound){
                    if(sessionStorage.getItem('logged') !== true){
                        Swal.fire({
                            icon: "warning",
                            title:'Error al acceder',
                            text:mensaje,
                            confirmButtonText:"Aceptar",
                            confirmButtonColor: "#04afaa"
                        })
                    }
                }   
            },(error)=>{
                //setMessage(error)
                sessionStorage.setItem('nomHospital','')
                setuserNomHospital('')
                setIsCorporativo(false)
                sessionStorage.setItem('name','')
                setUserName('')
                sessionStorage.setItem('level','')
                setUserLevel('')
                sessionStorage.setItem('logged',false)
                setUserIslogged(false)
                if(sessionStorage.getItem('logged') !== true){
                    Swal.fire({
                        icon: "error",
                        title:'Error al acceder',
                        text:error,
                        confirmButtonText:"Aceptar",
                        confirmButtonColor: "#04afaa"
                    })
                }
            })
    }


    /**
     * Función que atrapa el evento click de cancelar y limpia el formulario
     * @returns Devuelve el estado del objeto
     */
     const handleCancelarClick = () => (event) => {
        event.preventDefault()
        setNick("")
        setPass("")
        // let i = 0
        // getCie(i)
        // function getCie(i){
        //     if(i<cieList.length){
        //         solicitarRegistroCie()
        //         async function solicitarRegistroCie(){
        //             console.log(cieList[i])
        //             const response = await createCIE(cieList[i])
        //             console.log(response)
        //             i++
        //             getCie(i)
        //         }
        //     }
        // }
    }

    /**
    * Función que atrapa el evento click de ingreso y dispara su validación
    * @returns Devuelve el estado del objeto
    */
     const handleIngresarClick = () => (event) => {
        event.preventDefault()
        if(nick && pass){
            getLogin()
        }
    }

    /**
     * Función que atrapa el evento de mostrar/ocultar caracteres del password
     * @returns Devuelve el estado del objeto
     */
     const handleMostrarClick = () => (event) => {
        event.preventDefault()
        setSeMuestra(!seMuestra)
    }

    return (
        <>
            <div id="seccionCabeceraLogin" className='seccionCabeceraLogin'>
                <Header />
            </div>
            <br />
            <div className='cuerpoPacientes'>
                <div className="login">
                    <form id="msform" onSubmit={handleIngresarClick()} style={customStyles}>
                        <h2 className="itemblogTitle">Accesar</h2>
                        <h3 className="info_itemblog">Usuario</h3>
                        <div className="text-containerLogin">
                            <input type="text" 
                                name="nick" 
                                value={nick} 
                                placeholder="Ingrese su user de acceso"
                                onChange={(event) => setNick(event.target.value.replace(/\s/g, ''))}
                            />
                        </div>
                        <h3 className="info_itemblog">Password</h3>
                        <div className="text-containerLogin">
                            <input 
                                type={seMuestra?"text":"password"} 
                                name="pass" 
                                value={pass} 
                                placeholder="Ingrese su password de acceso"
                                onChange={(event) => setPass(event.target.value.replace(/\s/g, ''))}
                            />
                            <span className='clearBtn' onClick={handleMostrarClick()}>
                                {
                                    seMuestra?<FaEyeSlash style={styleIcon}/>
                                    :<FaEye style={styleIcon}/>
                                }
                            </span>
                        </div>
                        <br />
                        <br />
                        <input type="submit" name="previous" className="previous action-button" value="Ingresar" onClick={handleIngresarClick()}/>
                        <input type="button" name="submit" className="submit limpiar-button" value="Cancelar" onClick={handleCancelarClick()}/>
                    </form>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Login;