/**
* Función que compara que compara si el valor de dos objetos tiene mayor peso dependiente del atributo deseado
* @param {Object} attr Nombre del atributo que se desea evaluar
* @param {Object} obj1 Primer objeto que se desea evaluar
* @param {Object} obj2 Segundo objeto que se desea evaluar
* @returns Devuelve si el primer objeto tiene mayor peso que el segundo objeto
*/
function comparar(attr, obj1, obj2) {
    return obj1[attr].localeCompare(obj2[attr]);
}

/**
* Función que permite ordenar un arreglo según el argumento deseado
* @param {String} attr Nombre del atributo por el que se desea realizar la ordenación
* @returns Devuelve el array ordenado
*/
export const compararInformación = (attr) =>{
    return function (a,b) {
    return comparar(attr,a,b);
    }
}

