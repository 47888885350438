/**
 * Función que devuelve el nombre de la Unidad Hospitalaria requerida
 * @param {String} nomHospital Recibe el número la Unidad Hospitalaria
 * @param {Array} uhList Recibe la lista de unidades hospitalarias
 * @returns String Devuelve el nombre correspondiente a la Unidad Hospitalaria
 */
export const getUnidadHospitalariaName = (nomHospital,uhList) =>{
    if(uhList){//Se verifica que la lista de unidades hospitalarias contenga valor
        if(!isNaN(nomHospital)){//Se verifica que el nomHospital sea numérico
            for(let i=0;i<uhList.length;i++){//Se recorre la lista de unidades hospitalarias
                if(uhList[i].id.toString() === nomHospital.toString()){//Se verifica que el campo nomHospital corresponda con el objeto
                    return uhList[i].hospital//Se devuelve el campo original
                }
            }
            return "Unidad Medica No Encontrada"
        }
        else{//El campo nomHospital es cadena de texto
            for(let i=0;i<uhList.length;i++){//Se recorre la lista de unidades hospitalarias
                if(uhList[i].hospital.toLowerCase() === nomHospital.toLowerCase()){//Se verifica que el campo original corresponda con el valor de nomHospital
                    return uhList[i].hospital//Se devuelve el valor del campo original
                }
            }

            //Se procede a realizar una búsqueda
            let resultadoBusqueda = []
            uhList.forEach(uh => {//Se recorre la lista de unidades hospitalarias
                //let nomHospitalDescompuesto = nomHospital.split(' ')//Se descompone el valor de nomHospital en palabras para realziar búsqueda
                // for(let i=0;i<nomHospitalDescompuesto.length;i++){//Se recorren las palabras obtenidas del nomHospital
                //     if(uh.hospital.toLowerCase().includes(nomHospitalDescompuesto[i].toLowerCase())){//Se verifica si los campos original o city incluyen la palabra y se agregan al array de resultado
                //         resultadoBusqueda.push(uh)
                //     }
                // }
                if(uh.hospital.toLowerCase().includes(nomHospital.toLowerCase())){
                    resultadoBusqueda.push(uh)
                }
            });

            if(resultadoBusqueda.length > 0){//Se verifica si se obtuvo resultados en la búsqueda
                if(resultadoBusqueda.length > 1){//Se verifica si la búsqueda obtuvo mas de una coincidencia
                    if(nomHospital.toLowerCase().includes("aguascalientes")){//Se verifica si el nombHospital corresponde a Aguascalientes
                        if(nomHospital.toLowerCase().includes("sur")){//Si el nomHospital contiene la palabra sur se devuelve la unidad hopitalaria correspondiente a Aguascalientes Sur
                            return "MAC Aguascalientes Sur"
                        }
                        else{//Se devuelve la unidad hospitalaria correspondiente a Aguascalientes Norte
                            return "MAC Aguascalientes Norte"
                        }
                    }
                    else{//Se devuelve que no se encontraron resultados ya que no se tiene certeza del resultado
                        if(nomHospital === "Merida"){
                            return nomHospital
                        }
                        else{
                            return "Unidad Medica No Encontrada"
                        }
                        
                    }
                }
                else{//Se devuelve el valor de original correspondiente al resultado de la búsqueda
                    return resultadoBusqueda[0].hospital
                }
            }   
            else{//Se devuelve que no se encotrarón resultados
                return "Unidad Medica No Encontrada"
            }
            
        }
    }
}

/**
 * Función que devuelve el objeto de la Unidad Hospitalaria requerida
 * @param {String} nomHospital Recibe el número la Unidad Hospitalaria
 * @param {Array} uhList Recibe la lista de unidades hospitalarias
 * @returns Object Devuelve el objeto asociado a la unidad hospitalaria
 */
export const getUnidadHospitalariaObject = (nomHospital, uhList) => {
    let response = null
    
    uhList.forEach(uh => {//Recorrer la lista de unidades hospitalarias
        if(uh.id.toString() === nomHospital.toString()){//Se verifica si el campo nomHospital corresponde al requerido y se asigna el object
            response = uh
        }
    });
    return response
}

/**
 * Función que devuelve el valor al que esta asociado el nombre de una unidad hospitalaria
 * @param {Object} UnidadHospitalaria Recibe el objeto que contiene la unidad hospitalaria
 * @returns String Devuelve el valor asignado al nombre de la unidad
 */
export const getUnidadHospitalariaValue = (UnidadHospitalaria) =>{
    let uhName = ""
    if(typeof UnidadHospitalaria === 'object'){//Verificar si la variable es de tipo Object
        if(UnidadHospitalaria.original){//Verificar si el objeto contiene el campo original y se asigna el valor
            uhName = UnidadHospitalaria.original
        }
        else{
            if(UnidadHospitalaria.city){//Verificar si el objeto contiene el campo city y se asigna el valor
                uhName = UnidadHospitalaria.city
            }
            else{//Se asigna direcatmente el primer valor del objeto
                uhName = UnidadHospitalaria[0]
            }
        }
    }
    else{//Se asgina el valor directamente
        uhName = UnidadHospitalaria
    }
    return uhName
}