/**
 * Librerías
 */
import React, { useEffect, useState } from 'react'
import Swal from "sweetalert2"

/**
 * Styles
 */
import '../styles/pacientesPendientes.css'

/**
 * Servicios
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore, usersCollection } from '../utils/firebase'
//Importar las funcionalidades para la lógica del negocio
import { compararInformación, validarSoloLetras, quitarAcentos } from '../utils/functions'

/**
 * Componentes
 */
//Componente que muestra al usuario que se esta procesando la información del servicio
import Loader from './loader';
//Componente que indica al usuario que no se obtuvo información con sus parámetros de búsqueda
import SinEventos from './sinEventos';
//Componente que contiene la información y funcionalidad asignada a un usuario del sistema
import Usuario from './usuarioCard';

/**
 * Objects
 */
//Importación de las funcionalidades del objeto de tipo unidadHospitalaria
import { getUnidadHospitalariaName, getUnidadHospitalariaObject } from '../objects/unidadHospitalaria';
//Importación de las funcionalidades del objeto de tipo user
import { createUser } from '../objects/user';


/**
 * Componente que contiene la información y funcionalidad asignada a la administración de usuarios
 * @param {Object} param0 Recibe los objetos de la sesión necesarios para el muestro de usuarios
 * @returns Component Devuelve el componente con los elementos y funcionalidad requeridos
 */
const UsuarioSection = ({uhList,userLevel,nomHospital,hospitalSeleccionado}) => {

    //Estado que indica si se hay usuarios que mostrar
    const [hayResultados, setHayResultados] = useState(false)
    //Estado que indica que se esta solicitando información al servicio
    const [isLoader,setIsLoader] = useState(false)
    //Estado que indica si se muestra la opción para limpiar la búsqueda
    //const [seMuestra,setSeMuestra] = useState(true)
    //Estado que almacena los resultados de la búsqueda de usuarios según el parámetros requerido por el usuario
    const [usersAmostrarList,setUsersAmostrarList] = useState([])
    //Estado que almacena los usuarios obtenidos desde la base de datos en una lista
    const [usersList,setUsersList] = useState([])
    //Estado que almacena el parametro de búsqueda para el muestreo de usuarios (si se encuentra vacio se consideran toda la lista de pacientes)
    const [valorBusqueda,setValorBusqueda] = useState("")
    const [isMesa, setIsMesa] = useState(false)

    const [nombre,setNombre] = useState("")

    useEffect(()=>{
        if(userLevel && nomHospital){
            if(userLevel === "mesa"){
                setIsMesa(true)
            }
            else{
                setIsMesa(false)
            }
        }
        else{
            setIsMesa(false)
        }
    },[userLevel,nomHospital])

    useEffect(()=>{
        setValorBusqueda("")
        setIsLoader(true)
        pacientesStore.collection(usersCollection)
        .onSnapshot(snap => {
            const users = []
            snap.forEach(doc => {
                users.push({ id: doc.id, ...doc.data() })
            })

            setIsLoader(false)
            setUsersList(users)
        },(error)=>{
            setIsLoader(false)
            setUsersList([])
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
              })
        })
    },[])


    useEffect(()=>{
        let nombre = ""
        if(hospitalSeleccionado){
            nombre = hospitalSeleccionado
        }
        else{
            nombre = nomHospital
        }

        setNombre(nombre)
        if(usersList.length > 0){//Verificar si existen usuarios
            if(valorBusqueda){//Se desea realizar búsqueda de un usuario específico
                let resultadoBusqueda = []
                usersList.forEach(
                    user =>{
                        if(user.Permiso && validarSoloLetras(user.Nombre) && user.UnidadHospitalaria){
                            if(user.Permiso.toUpperCase().includes(valorBusqueda.toUpperCase())
                                || quitarAcentos(user.Nombre.toUpperCase()).includes(quitarAcentos(valorBusqueda.toUpperCase()))
                                || getUnidadHospitalariaName(user.UnidadHospitalaria.id,uhList).toUpperCase().includes(valorBusqueda.toUpperCase())){
                                    resultadoBusqueda.push(user)
                                }
                        }
                    }
                )

                if(resultadoBusqueda.length > 0){//Se encontraron resultados a la búsqueda deseada
                    setHayResultados(true)
                    //Ordenar los resultados por un valor específico
                    resultadoBusqueda.sort(compararInformación('Nombre'))
                }
                else{//La búsqueda no obtuvo ningún paciente como resultado
                    setHayResultados(false)
                }
                setUsersAmostrarList(resultadoBusqueda)
            }
            else{//Se muestran todos los usuarios disponibles
                usersList.sort(compararInformación('Nombre'))
                let usuariosValidos = 0
                usersList.forEach(
                    user => {
                        let isAutorized = false
                        if(user.Systems){
                            for(let j=0;j<user.Systems.length;j++){
                                if(user.Systems[j].id === 2 && user.Systems[j].active){
                                    isAutorized = true
                                }
                            }
                        }
                        if(userLevel !== "superuser"){//Se verifica si el usuario loegado tiene permisos de tipo administrador
                            if(user.UnidadHospitalaria["hospital"] === 
                                getUnidadHospitalariaName(nombre,uhList) 
                                && user.Permiso !== "quirofano"
                                && user.Permiso !== "recuperación"
                                && isAutorized){//Se verifica si el usuario pertenece a la misma unidad hospitalaria que el user logeado
                                    //Se designa como usuario disponible para vista
                                    usuariosValidos = usuariosValidos + 1
                            }
                        }
                        else{//User logeado con acceso total
                            //Se dedgina como usuario disponible para vista
                            if(user.Permiso !== "quirofano"
                                && user.Permiso !== "recuperación"
                                && isAutorized){
                                    usuariosValidos = usuariosValidos + 1
                                }
                        }
                    }
                )

                if(usuariosValidos > 0){//Se verifica si hay usuario disponles para vista
                    //Se asigna la lista de usuarios disponibles para vista
                    setUsersAmostrarList(usersList)
                    setHayResultados(true)
                }
                else{//Se muestra la tarjeta de sin resultados
                    setUsersAmostrarList([])
                    setHayResultados(false)
                }
            }
        }
        else{//No hay usuarios por mostrar
            setUsersAmostrarList([])
            setHayResultados(false)
        }
    },[usersList,valorBusqueda,uhList,nomHospital,userLevel,hospitalSeleccionado])

    /**
     * Función que atrapa el evento para agregar un usuario
     * @returns Devuelve el estado del objeto
     */
     const handleAgregar = () => (event) => {
        event.preventDefault()
        let uhOpciones = ""
        let levelOpciones = ""
        let pass = generarAleatorios(8)

        if(userLevel === "superuser"){//Se verifica si el usuario logeado tiene acceso total
            //Se muestran en el select de unidades hospitalarias todas las unidades disponibles
            uhOpciones += "<option value="+0+">-- Seleccione la unidad hospitalaria --</option>"
            uhList.forEach(uh => {
                uhOpciones+="<option value="+uh.id+">"+uh.hospital+"</option>"
            })
            //Se muestran en el select de permisos los niveles SuperUser, Administrador y Normal
            levelOpciones += "<option value="+0+">-- Seleccione el nivel de permiso --</option>"
            levelOpciones += "<option value=superuser>SuperUser</option>"
            levelOpciones += "<option value=administrador>Administrador</option>"
            levelOpciones += "<option value=agenda>Agenda</option>"
            levelOpciones += "<option value=calidad>Calidad</option>"
            levelOpciones += "<option value=tablero>Tablero</option>"
            levelOpciones += "<option value=mesa>Mesa</option>"
        }else{//Usuario con acceso de tipo administrador
            //Se muestra en el select de unidad hospitalaria solo a la que pertenene el usuario logeado
            uhList.forEach(uh => {
                if(nombre === uh.hospital){
                    uhOpciones+="<option value="+uh.id+">"+uh.hospital+"</option>"
                }
            })
            //Se muestra en el select de permisos los niveles Administrador y Normal
            levelOpciones += "<option value="+0+">-- Seleccione el nivel de permiso --</option>"
            levelOpciones += "<option value=administrador>Administrador</option>"
            levelOpciones += "<option value=agenda>Agenda</option>"
            levelOpciones += "<option value=calidad>Calidad</option>"
            levelOpciones += "<option value=tablero>Tablero</option>"
            levelOpciones += "<option value=mesa>Mesa</option>"
        }

        Swal.fire({
            customClass:'modalRegistro',
            html:`<div class="form_wrapper">
                <div class="form_container">
                    <div class="row clearfix">
                        <div class="">
                            <form autocomplete="off" method="post">
                                <h2 class="modalTitle">Usuario Nuevo</h2>
                                <br />
                                <div class="input_field">
                                    <input type="text" name="inputNombre" id="inputNombre" placeholder="Ingrese el nombre del usuario" 
                                    required  class="inputText" autocomplete="off"
                                    oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                </div>
                                <div class="input_field select_option">
                                    <select id="selectNivel">
                                        `+levelOpciones+`
                                    </select>
                                    <div class="select_arrow"></div>
                                </div>
                                <div class="input_field select_option">
                                    <select id="selectUH">
                                        `+uhOpciones+`
                                    </select>
                                    <div class="select_arrow"></div>
                                </div>
                                <div class="input_field">
                                    <input type="text" name="inputNick" id="inputNick" placeholder="Ingrese el usuario de acceso" 
                                    required class="inputText" autocomplete="off"
                                    oninput="this.value=value.replace(/[' ']/g,'')"/>
                                </div>
                                <div class="input_field">
                                    <input type="text" name="inputPass" id="inputPass" placeholder="Ingrese el password de acceso" 
                                    required class="inputText" autocomplete="off"
                                    value="`+pass+`" 
                                    oninput="this.value=value.replace(/[' ']/g,'')"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>`,
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "grey",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#04afaa",
            scrollbarPadding:false,
            allowOutsideClick:false,
            width:'500px',
            heightAuto: false,
            focusConfirm: false,
            showCloseButton:true,
            preConfirm: () => {
                if (document.getElementById('inputNombre').value) {//Se verifica que el campo nombre sea valido
                    if(document.getElementById('selectNivel').value 
                        && document.getElementById('selectNivel').value !== "0"){//Se verifica si el nivel de permiso es valido
                        if(document.getElementById('selectUH').value 
                            && document.getElementById('selectUH').value !== "0"){//Se verifica si la unidad hospitalaria es valida
                            if(document.getElementById('inputNick').value){//Se verifica si el user de acceso es valido
                                if(document.getElementById('inputPass').value){//Se verifica si el password de acceso es valido
                                    //Se solicita el registro de usuario
                                    solicitarCreateUser()
                                    async function solicitarCreateUser(){
                                        const response = await createUser(document.getElementById('selectNivel').value,
                                                document.getElementById('inputNombre').value.replace(/\s+/g, ' ').trim(),
                                                getUnidadHospitalariaObject(document.getElementById('selectUH').value,uhList),
                                                document.getElementById('inputPass').value,
                                                document.getElementById('inputNick').value)
                                        if(response === "success"){//Registro de usuario exitoso
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Registro de usuario',
                                                text: 'Usuario registrado de manera exitosa',
                                                confirmButtonText: "Aceptar",
                                                confirmButtonColor: "#04afaa",
                                              })
                                        }
                                        else{//Error en el registro de usuario
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: response,
                                              })
                                        }
                                    }
                                }
                                else{//Password de acceso invalido
                                    Swal.showValidationMessage('Proporcione el password de acceso del usuario')   
                                }
                            }
                            else{//User de acceso invalido
                                Swal.showValidationMessage('Proporcione el user de acceso del usuario')   
                            }
                        }
                        else{//Unidad Hospitalaria invalida
                            Swal.showValidationMessage('Proporcione la unidad hospitalaria')
                        }
                    }
                    else{//Nivel de acceso invalido
                        Swal.showValidationMessage('Proporcione el nivel de acceso') 
                    }
                 } else {//Nombre de usuario invalido
                   Swal.showValidationMessage('Proporcione el nombre del usuario')   
                 }
              }
        })
    }


    function barajar(array) {
        let posicionActual = array.length;
      
        while (0 !== posicionActual) {
          const posicionAleatoria = Math.floor(Math.random() * posicionActual);
          posicionActual--;
          //"truco" para intercambiar los valores sin necesidad de una variable auxiliar
          [array[posicionActual], array[posicionAleatoria]] = [
            array[posicionAleatoria], array[posicionActual]];
        }
        return array;
      }
    
    function generarAleatorios(cantidad) {
        const caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split("");
        barajar(caracteres);
        return caracteres.slice(0,cantidad).join("")
    }

    return (
        <>
            {
                isLoader 
                    ?
                        <Loader/>
                    :
                        hayResultados ?
                            usersAmostrarList.map(user => 
                                <Usuario key={user.id} user={user} uhList={uhList} userLevel={userLevel} nomHospitalUser={nombre}/>
                            )
                    : 
                        <SinEventos/>
            }
            {
                isMesa
                    ?
                        <button className="btn-flotante" title="Agregar usuario" alt="Agregar" onClick={handleAgregar()}>+</button>
                    :
                        null
            }
        </>
    )
}

export default UsuarioSection;