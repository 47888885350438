export const cie = [
    {
        "id": "ad529b34-edfd-a1be-7be2-d6596061627b",
        "NOMBRE": "(OSTEO)ARTROSIS EROSIVA"
    },
    {
        "id": "c40d2cb0-320c-9d14-3fb3-55b567c338af",
        "NOMBRE": "(OSTEO)ARTROSIS PRIMARIA GENERALIZADA"
    },
    {
        "id": "be93c1ba-4304-cd39-f745-5d995d7419a5",
        "NOMBRE": "9999"
    },
    {
        "id": "657ffffd-e35a-5c73-3f40-719a338f2627",
        "NOMBRE": "ABDOMEN AGUDO"
    },
    {
        "id": "a83b7842-01a0-b5a0-3744-741f693aa019",
        "NOMBRE": "ABERTURA ARTIFICIAL, NO ESPECIFICADA"
    },
    {
        "id": "5e178ca5-a91e-7a36-1ea3-12400647a5f0",
        "NOMBRE": "ABERTURAS ARTIFICIALES"
    },
    {
        "id": "a6eb297d-2309-ee5f-a76f-c640112ab64c",
        "NOMBRE": "ABORTADORA HABITUAL"
    },
    {
        "id": "f1ba60af-13e0-3ef1-4d7b-0cc7c9d5bed6",
        "NOMBRE": "ABORTO ESPONTÁNEO"
    },
    {
        "id": "abf3b50b-44a1-9fe1-9002-f6c1fb15afc5",
        "NOMBRE": "ABORTO ESPONTÁNEO COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "53373ab9-d8f6-6b75-2a7e-03fa55f34ca1",
        "NOMBRE": "ABORTO ESPONTÁNEO COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "d81924ca-ab68-605a-cdcd-3c59af023c08",
        "NOMBRE": "ABORTO ESPONTÁNEO COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "6704392b-ca83-dec8-d96f-b40c1f154bd4",
        "NOMBRE": "ABORTO ESPONTÁNEO COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "24f10989-a132-669e-fe44-83ca729b207e",
        "NOMBRE": "ABORTO ESPONTÁNEO COMPLETO O NO ESPECIFICADO, SIN COMPLICACIÓN"
    },
    {
        "id": "8081bc15-6bbf-42a7-dbe2-b93ae8bc5dc5",
        "NOMBRE": "ABORTO ESPONTÁNEO INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "8ecfe24f-31ff-37a3-5012-20bd300d01b2",
        "NOMBRE": "ABORTO ESPONTÁNEO INCOMPLETO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "a1b00898-5687-e661-d1fa-d9774c9e070b",
        "NOMBRE": "ABORTO ESPONTÁNEO INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "360c90ae-38c1-263f-94fa-ce4a8a05fc34",
        "NOMBRE": "ABORTO ESPONTÁNEO INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "6e06b799-bf38-c7cc-f0ba-e9f2de416ea5",
        "NOMBRE": "ABORTO ESPONTÁNEO INCOMPLETO, SIN COMPLICACIÓN"
    },
    {
        "id": "c7062c78-a492-b9b3-8613-3ad7b579d026",
        "NOMBRE": "ABORTO MÉDICO"
    },
    {
        "id": "0af55190-0ce7-4353-2a4b-ab0207e95bc5",
        "NOMBRE": "ABORTO MÉDICO COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "c01c038b-bfe0-4e14-0513-bd9598f315dd",
        "NOMBRE": "ABORTO MÉDICO COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "28769801-6324-c4b5-6ed9-a2c34fadce61",
        "NOMBRE": "ABORTO MÉDICO COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "6bef9e2e-0552-a700-c7d2-71c00c6a4f5b",
        "NOMBRE": "ABORTO MÉDICO COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "3c622b5c-6482-5c7b-f5e8-ae3455a0c7ef",
        "NOMBRE": "ABORTO MÉDICO COMPLETO O NO ESPECIFICADO, SIN COMPLICACIÓN"
    },
    {
        "id": "7b5d7d41-a3f6-dbfc-17b7-fdaf51ee761b",
        "NOMBRE": "ABORTO MÉDICO INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "4dff0f19-6aad-a8ac-b4a8-911bc0f70e0c",
        "NOMBRE": "ABORTO MÉDICO INCOMPLETO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "a8421fe4-51e5-54c6-1e83-bdfc6bbc09b6",
        "NOMBRE": "ABORTO MÉDICO INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "c0604b72-f5b0-106a-bd50-8d21bc1af20f",
        "NOMBRE": "ABORTO MÉDICO INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "2b32c47d-2754-4336-db28-706f342b6763",
        "NOMBRE": "ABORTO MÉDICO INCOMPLETO, SIN COMPLICACIÓN"
    },
    {
        "id": "33002222-5bdb-d1f6-6f54-fe4ecc375288",
        "NOMBRE": "ABORTO NO ESPECIFICADO"
    },
    {
        "id": "9888361d-dd75-86ab-6091-b044a40e5c7b",
        "NOMBRE": "ABORTO NO ESPECIFICADO COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "1e2a7c94-94a6-f5b5-13a1-5f0e9c6ec0d0",
        "NOMBRE": "ABORTO NO ESPECIFICADO COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "f2608929-9398-7dbf-ed22-189003815351",
        "NOMBRE": "ABORTO NO ESPECIFICADO COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "162783ad-0765-3872-c717-e6d6404ea81c",
        "NOMBRE": "ABORTO NO ESPECIFICADO COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "489003fa-fdba-6bf1-bb1f-1f8b29257acd",
        "NOMBRE": "ABORTO NO ESPECIFICADO COMPLETO O NO ESPECIFICADO, SIN COMPLICACIÓN"
    },
    {
        "id": "e35f8b5b-b35e-c243-df48-77d0bde4da0e",
        "NOMBRE": "ABORTO NO ESPECIFICADO INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "80105b80-646a-b754-6021-a8bf93dd50d8",
        "NOMBRE": "ABORTO NO ESPECIFICADO INCOMPLETO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "e89d5f29-56c4-0ddd-2599-5a38aeda0345",
        "NOMBRE": "ABORTO NO ESPECIFICADO INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "cc9a75d1-dc14-c9e7-281b-760bcd1ae683",
        "NOMBRE": "ABORTO NO ESPECIFICADO INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "20999197-a16a-86aa-15f2-7790b07714f8",
        "NOMBRE": "ABORTO NO ESPECIFICADO INCOMPLETO, SIN COMPLICACIÓN"
    },
    {
        "id": "51c1bf52-c518-cbfb-4eb3-339f19a151e6",
        "NOMBRE": "ABORTO RETENIDO"
    },
    {
        "id": "064c56f9-1da0-e618-135f-5210aa70e6ac",
        "NOMBRE": "ABRASIÓN DE LOS DIENTES"
    },
    {
        "id": "53a5db5c-eac6-9db7-8446-56485c064031",
        "NOMBRE": "ABSCESO AMEBIANO DEL CEREBRO"
    },
    {
        "id": "ddadf8d7-929b-8a28-6341-84a18135b7bb",
        "NOMBRE": "ABSCESO AMEBIANO DEL HÍGADO"
    },
    {
        "id": "8baecfc4-742e-1e6a-36b8-c825641fd885",
        "NOMBRE": "ABSCESO AMEBIANO DEL PULMÓN"
    },
    {
        "id": "6c7da536-2f2a-61f8-b05f-f16bffa05cdd",
        "NOMBRE": "ABSCESO ANAL"
    },
    {
        "id": "27a33b06-ac11-eff0-7e46-0c9f48e37fef",
        "NOMBRE": "ABSCESO ANORRECTAL"
    },
    {
        "id": "24db86b0-1ee2-e677-b224-bdb9ab2c5e73",
        "NOMBRE": "ABSCESO CEREBRAL FEOMICÓTICO"
    },
    {
        "id": "57334317-520b-e482-1083-6af96eee4f63",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX"
    },
    {
        "id": "20d0676f-640b-137c-6eaa-db9e0eef1766",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX DE GLÚTEOS"
    },
    {
        "id": "4ee127ef-a706-5e6e-202d-95f0c85bc79d",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX DE LA CARA"
    },
    {
        "id": "16d50bbc-3c89-6222-0414-0ce8e6807304",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX DE MIEMBRO"
    },
    {
        "id": "f8ed6d43-f5fe-b0db-cc70-85b8c4a45ddb",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX DE OTROS SITIOS"
    },
    {
        "id": "7e2bb538-95a6-5a36-28a4-7406975f2457",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "af9391e6-662b-01df-4905-c8782791adbd",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX DEL CUELLO"
    },
    {
        "id": "f37c65bc-a86b-8508-cbdc-6c55698a63c5",
        "NOMBRE": "ABSCESO CUTÁNEO, FURÚNCULO Y ÁNTRAX DEL TRONCO"
    },
    {
        "id": "0daecc18-54f7-7664-5122-a74ffcb1b4a0",
        "NOMBRE": "ABSCESO DE GLÁNDULA SALIVAL"
    },
    {
        "id": "10e2a42c-bb83-3d38-4f69-5abda92707c9",
        "NOMBRE": "ABSCESO DE LA BOLSA SINOVIAL"
    },
    {
        "id": "3f21a21c-8b0d-b374-2703-adb483fb0d2f",
        "NOMBRE": "ABSCESO DE LA GLÁNDULA DE BARTHOLIN"
    },
    {
        "id": "60913144-35b1-5049-3307-2ad893c22fd6",
        "NOMBRE": "ABSCESO DE LA MAMA ASOCIADO CON EL PARTO"
    },
    {
        "id": "3264c3b3-5392-20f0-c89a-67a329ff323a",
        "NOMBRE": "ABSCESO DE LA PRÓSTATA"
    },
    {
        "id": "64c30364-f964-b2ad-db54-aa2cea0a5b10",
        "NOMBRE": "ABSCESO DE LAS REGIONES ANAL Y RECTAL"
    },
    {
        "id": "307251f4-78a8-64aa-ab4c-1600dd40b588",
        "NOMBRE": "ABSCESO DE VAINA TENDINOSA"
    },
    {
        "id": "0397785e-b546-36ef-9a24-ef9b5e7850e0",
        "NOMBRE": "ABSCESO DEL BAZO"
    },
    {
        "id": "f39b93e7-c2fb-c2b4-26ad-44298a0f40ad",
        "NOMBRE": "ABSCESO DEL HÍGADO"
    },
    {
        "id": "939a501e-af2b-dcfa-8547-2c8f371d7454",
        "NOMBRE": "ABSCESO DEL INTESTINO"
    },
    {
        "id": "c2474f1f-5740-fa90-20e0-c7cfd8baf36c",
        "NOMBRE": "ABSCESO DEL MEDIASTINO"
    },
    {
        "id": "942af86b-1b87-dfd2-3e55-936afb4f5b6a",
        "NOMBRE": "ABSCESO DEL OÍDO EXTERNO"
    },
    {
        "id": "9b443e5c-d9df-41ab-9c32-9390f33fd1a3",
        "NOMBRE": "ABSCESO DEL PULMÓN CON NEUMONÍA"
    },
    {
        "id": "c5c9bf8c-9e61-8267-99b0-19e1a3846808",
        "NOMBRE": "ABSCESO DEL PULMÓN SIN NEUMONÍA"
    },
    {
        "id": "deee3d30-b058-82db-5bff-eac5630c8392",
        "NOMBRE": "ABSCESO DEL PULMÓN Y DEL MEDIASTINO"
    },
    {
        "id": "c7d214e6-398a-85c1-473b-c26d6cb5cd93",
        "NOMBRE": "ABSCESO DEL TIMO"
    },
    {
        "id": "4c6e6795-1f7a-bf3e-d5ee-c94742c33012",
        "NOMBRE": "ABSCESO EXTRADURAL Y SUBDURAL, NO ESPECIFICADO"
    },
    {
        "id": "31aa8ef3-ce27-dded-cdd6-6428790d323a",
        "NOMBRE": "ABSCESO INTRAESFINTERIANO"
    },
    {
        "id": "d53d56ff-4074-bdad-5d23-e6c23c504820",
        "NOMBRE": "ABSCESO ISQUIORRECTAL"
    },
    {
        "id": "8a2b9220-3aad-db44-aa69-af276d612364",
        "NOMBRE": "ABSCESO PERIAMIGDALINO"
    },
    {
        "id": "df614ee9-849f-2cc5-2aba-81b4e2ce7f44",
        "NOMBRE": "ABSCESO PERIAPICAL CON FÍSTULA"
    },
    {
        "id": "7e83d9b8-02c0-3831-b360-1ccd35ee9078",
        "NOMBRE": "ABSCESO PERIAPICAL SIN FÍSTULA"
    },
    {
        "id": "49b342b4-b9e1-da6c-f424-c9e2c1c0416e",
        "NOMBRE": "ABSCESO RECTAL"
    },
    {
        "id": "c96421c4-9284-6728-e613-d7913cc4c415",
        "NOMBRE": "ABSCESO RENAL Y PERIRRENAL"
    },
    {
        "id": "36e36de0-68d4-45e2-7537-8753b3b022e2",
        "NOMBRE": "ABSCESO RETROFARÍNGEO Y PARAFARÍNGEO"
    },
    {
        "id": "ad69c0dc-8da8-00ca-c1c6-e93cda5082a3",
        "NOMBRE": "ABSCESO URETRAL"
    },
    {
        "id": "bf31b616-7e3f-8432-151b-05cb288c8f31",
        "NOMBRE": "ABSCESO VULVAR"
    },
    {
        "id": "ddf08d33-191a-0f22-4d56-e3cd4b6c056e",
        "NOMBRE": "ABSCESO Y GRANULOMA INTRACRANEAL"
    },
    {
        "id": "8d95ec4f-2407-03e5-cdc7-1608fd3fee16",
        "NOMBRE": "ABSCESO Y GRANULOMA INTRACRANEAL E INTRARRAQUÍDEO"
    },
    {
        "id": "5b312f8b-6bf2-2a88-5b88-b59f2d17b699",
        "NOMBRE": "ABSCESO Y GRANULOMA INTRACRANEAL E INTRARRAQUÍDEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "35f75382-da6f-c148-ac53-79ca6979f026",
        "NOMBRE": "ABSCESO Y GRANULOMA INTRARRAQUÍDEO"
    },
    {
        "id": "abedb61d-8988-767c-d215-a6effd0c1c87",
        "NOMBRE": "ABSCESO Y QUISTE SUBCUTÁNEO FEOMICÓTICO"
    },
    {
        "id": "b99a4758-92a7-a9c9-6e60-118632910ada",
        "NOMBRE": "ABSCESO, FURÚNCULO Y CARBUNCO DE LA NARIZ"
    },
    {
        "id": "c490ba68-9540-800f-168a-0898aef6c345",
        "NOMBRE": "ABUSO DE SUSTANCIAS QUE NO PRODUCEN DEPENDENCIA"
    },
    {
        "id": "63408458-0fd0-852a-7f70-f8928bb5b761",
        "NOMBRE": "ABUSO FÍSICO"
    },
    {
        "id": "724af0ef-c36f-b453-f08c-9ba304ab7a41",
        "NOMBRE": "ABUSO PSICOLÓGICO"
    },
    {
        "id": "f3243807-4cf9-56ad-c7c2-4a04c8862773",
        "NOMBRE": "ABUSO SEXUAL"
    },
    {
        "id": "2c4437d1-0475-54be-0cff-4db46a63bf25",
        "NOMBRE": "ACALASIA DEL CARDIAS"
    },
    {
        "id": "21f10b14-f017-bb09-9baf-3cd3de7523ed",
        "NOMBRE": "ACANTAMEBIASIS"
    },
    {
        "id": "23248b7f-40ed-9e14-6a92-05edb2e05a9a",
        "NOMBRE": "ACANTOSIS NIGRICANS"
    },
    {
        "id": "1da8840b-1df3-2ad8-e1b8-c10acbc0329e",
        "NOMBRE": "ACCIDENTE DE AERONAVE DE MOTOR, CON OCUPANTE LESIONADO"
    },
    {
        "id": "14f3ae06-2a3a-1388-6f4c-d1aee1026c9b",
        "NOMBRE": "ACCIDENTE DE AERONAVE NO ESPECIFICADA, CON OCUPANTE LESIONADO"
    },
    {
        "id": "334387c8-e6eb-5026-bf1d-191bd13db4ea",
        "NOMBRE": "ACCIDENTE DE AERONAVE SIN MOTOR NO ESPECIFICADA, CON OCUPANTE LESIONADO"
    },
    {
        "id": "b911e7ec-55c9-f6ed-05e2-488d2ce97ff2",
        "NOMBRE": "ACCIDENTE DE AERONAVE SIN MOTOR, CON OCUPANTE LESIONADO"
    },
    {
        "id": "8e3c2325-71b6-6447-68b8-a4cdafe3e874",
        "NOMBRE": "ACCIDENTE DE ALA DELTA, CON OCUPANTE LESIONADO"
    },
    {
        "id": "b3f18a83-57ee-3c7e-732f-bdad0906d041",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN"
    },
    {
        "id": "ad72ba11-2252-7e75-9f5e-1c043333b0b0",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, BALSA INFLABLE (SIN MOTOR)"
    },
    {
        "id": "e85acba2-416f-ffd2-23e5-b37f7634a3b5",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, BARCO DE PASAJEROS"
    },
    {
        "id": "f40fc535-9f40-604f-de2b-e8af48e3b18f",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, BARCO MERCANTE"
    },
    {
        "id": "cf715177-13cb-1f33-5975-b0929e576ef1",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, BOTE DE PESCA"
    },
    {
        "id": "113c518e-90e8-5b11-4ccf-59db62407edd",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, CANOA O KAYAC"
    },
    {
        "id": "6f299a79-0d1f-ed32-4bf8-03564b0f6a66",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, ESQUÍ ACUÁTICO"
    },
    {
        "id": "19478f4d-02da-e78d-39f3-c237679aacab",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, OTRO VEHÍCULO ACUÁTICO CON MOTOR"
    },
    {
        "id": "68f8448e-7037-a7b9-5a45-814cbf700a9c",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, OTRO VEHÍCULO ACUÁTICO SIN MOTOR"
    },
    {
        "id": "67ce62e1-a83f-5f5c-f5af-9e748ccac80c",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, VEHÍCULO ACUÁTICO NO ESPECIFICADO"
    },
    {
        "id": "41c34c6b-b3c8-a352-a97f-b4edd33e9ef2",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA AHOGAMIENTO Y SUMERSIÓN, VELERO"
    },
    {
        "id": "f7b77197-3afb-4189-b87e-91159da08de0",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO"
    },
    {
        "id": "997d08e4-7e0a-beaf-0a1e-4550d0eee5cc",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BALSA INFLABLE (SIN MOTOR)"
    },
    {
        "id": "3610558f-9ecd-b812-5184-6d7093372615",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BARCO DE PASAJEROS"
    },
    {
        "id": "ac3372c4-0883-b65e-daf3-161db981bf6f",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BARCO MERCANTE"
    },
    {
        "id": "544dca2f-bac3-20f3-b2de-9e0cd42edb28",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BOTE DE PESCA"
    },
    {
        "id": "5060fa18-eb8b-8acc-678c-c0b02045acbb",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, CANOA O KAYAK"
    },
    {
        "id": "349c14e6-5eaa-bb6a-c33d-334dc7dfabfa",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, ESQUÍ ACUÁTICO"
    },
    {
        "id": "1ab05085-e1ba-f07b-2fe2-87612b6ed6d6",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, OTRO VEHÍCULO ACUÁTICO CON MOTOR"
    },
    {
        "id": "89c4976d-3530-a56f-f60a-f8017557dce9",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, OTRO VEHÍCULO ACUÁTICO SIN MOTOR"
    },
    {
        "id": "1d8b5ccd-35b6-9e47-7bfd-62563b683c3b",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, VEHÍCULO ACUÁTICO NO ESPECIFICADO"
    },
    {
        "id": "1951e65f-cc8f-f8b0-8e67-7f0d39d6f1ab",
        "NOMBRE": "ACCIDENTE DE EMBARCACIÓN QUE CAUSA OTROS TIPOS DE TRAUMATISMO, VELERO"
    },
    {
        "id": "d4788c14-6528-3512-5973-4f650abfd687",
        "NOMBRE": "ACCIDENTE DE GLOBO AEROSTÁTICO, CON OCUPANTE LESIONADO"
    },
    {
        "id": "81c090c3-97f4-15b9-f3c3-60fe157e5200",
        "NOMBRE": "ACCIDENTE DE HELICÓPTERO CON OCUPANTE LESIONADO"
    },
    {
        "id": "a32b0643-5908-bb46-2037-14d672d44af9",
        "NOMBRE": "ACCIDENTE DE NAVE ESPACIAL, CON OCUPANTE LESIONADO"
    },
    {
        "id": "8bdff6f8-1f45-8176-9ba3-8d09ea5836f2",
        "NOMBRE": "ACCIDENTE DE OTRAS AERONAVES SIN MOTOR, CON OCUPANTE LESIONADO"
    },
    {
        "id": "8e15938e-0eb3-abc4-1e27-60f6e9918587",
        "NOMBRE": "ACCIDENTE DE OTRAS AERONAVES, CON OCUPANTE LESIONADO"
    },
    {
        "id": "f74fa272-4f6e-a10c-5407-15e30d6b5d64",
        "NOMBRE": "ACCIDENTE DE OTROS VEHÍCULOS AÉREOS DE ALAS FIJAS, PRIVADOS, CON OCUPANTE LESIONADO"
    },
    {
        "id": "35f1e5a8-d9bf-6c39-0908-e94e6ea0b6f7",
        "NOMBRE": "ACCIDENTE DE PLANEADOR (SIN MOTOR), CON OCUPANTE LESIONADO"
    },
    {
        "id": "69bfbc99-985f-b69a-a9b6-de7dc000a301",
        "NOMBRE": "ACCIDENTE DE PLANEADOR ULTRA LIVIANO, MICRO LIVIANO O MOTORIZADO, CON OCUPANTE LESIONADO"
    },
    {
        "id": "8b4fb290-241c-6a28-6932-3dd3268760ef",
        "NOMBRE": "ACCIDENTE DE TRÁNSITO DE TIPO ESPECIFICADO PERO DONDE SE DESCONOCE EL MODO DE TRANSPORTE DE LA VÍCTIMA"
    },
    {
        "id": "359522bf-ace1-2962-4e9d-45175620a189",
        "NOMBRE": "ACCIDENTE DE TRANSPORTE NO ESPECIFICADO"
    },
    {
        "id": "75a20b9b-7042-5f7c-b103-4c49c7455e8c",
        "NOMBRE": "ACCIDENTE DE VEHÍCULO AÉREO DE ALAS FIJAS, COMERCIAL, CON OCUPANTE LESIONADO"
    },
    {
        "id": "0eadd276-dabd-ab04-298d-5ccef08ca4e4",
        "NOMBRE": "ACCIDENTE DE VEHÍCULO DE MOTOR O SIN MOTOR, TIPO DE VEHÍCULO NO ESPECIFICADO"
    },
    {
        "id": "685c5d89-2e8a-d37b-6954-fab56a308caf",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN"
    },
    {
        "id": "c3e7d044-9368-6d95-5a5b-babe036dface",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, BALSA INFLABLE (SIN MOTOR)"
    },
    {
        "id": "e91dd95f-0859-a079-1f4a-377e35c03fce",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, BARCO DE PASAJEROS"
    },
    {
        "id": "97428fb6-088d-2ede-a8eb-9ac5d8f5ed0f",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, BARCO MERCANTE"
    },
    {
        "id": "7de5605f-0a24-09d3-22fe-dcbe8e87079c",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, BOTE DE PESCA"
    },
    {
        "id": "4d2346ed-9225-f471-46dd-f4ec5285d573",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, CANOA O KAYAK"
    },
    {
        "id": "5c4ef14a-23f7-85f9-7a36-dce469988ca7",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, ESQUÍ ACUÁTICO"
    },
    {
        "id": "e0dca07b-67ed-38f3-0aea-29ac00317e8f",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, OTRO VEHÍCULO ACUÁTICO CON MOTOR"
    },
    {
        "id": "1b6dde47-6aee-d6fb-7db5-af13340f398b",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, OTRO VEHÍCULO ACUÁTICO SIN MOTOR"
    },
    {
        "id": "6712c85e-6369-e62f-8200-85471f37d9cb",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, VEHÍCULO ACUÁTICO NO ESPECIFICADO"
    },
    {
        "id": "369f562f-f384-2e8b-2d57-63e39a3d67d5",
        "NOMBRE": "ACCIDENTE EN UNA EMBARCACIÓN, SIN ACCIDENTE A LA EMBARCACIÓN, QUE NO CAUSA AHOGAMIENTO O SUMERSIÓN, VELERO"
    },
    {
        "id": "36b01699-6fb1-94e6-a5bb-f4fb6de11eab",
        "NOMBRE": "ACCIDENTE NO DE TRÁNSITO DE TIPO ESPECIFICADO, PERO DONDE SE DESCONOCE EL MODO DE TRANSPORTE DE LA VÍCTIMA"
    },
    {
        "id": "70653b38-f9ba-ad8b-6500-b18aa3f0f75d",
        "NOMBRE": "ACCIDENTE VASCULAR ENCEFÁLICO AGUDO, NO ESPECIFICADO COMO HEMORRÁGICO O ISQUÉMICO"
    },
    {
        "id": "6387f739-e612-cf15-ef7a-6f6b53186e3e",
        "NOMBRE": "ACETONURIA"
    },
    {
        "id": "239ec936-98fd-225a-4ea3-969eabd8fe85",
        "NOMBRE": "ACIDEZ"
    },
    {
        "id": "dc393044-9eef-d65c-e77a-352d7474c607",
        "NOMBRE": "ACIDOSIS"
    },
    {
        "id": "8a39ce60-2227-faf4-bb92-6b1113fd6a7e",
        "NOMBRE": "ACIDOSIS METABÓLICA TARDÍA DEL RECIÉN NACIDO"
    },
    {
        "id": "6b5b1d86-1054-08ee-a011-5ae99a6ee71c",
        "NOMBRE": "ACNÉ"
    },
    {
        "id": "297b9666-3e42-7312-e7fa-0355151d0360",
        "NOMBRE": "ACNÉ CONGLOBADO"
    },
    {
        "id": "1e890750-58f5-5d06-5e7d-af57adfc4064",
        "NOMBRE": "ACNÉ EXCORIADO DE LA MUJER JOVEN"
    },
    {
        "id": "361f303d-6ae8-b520-3f73-808397ec11bf",
        "NOMBRE": "ACNÉ INFANTIL"
    },
    {
        "id": "8ac007f9-4322-bfca-1fc3-2258b0bac814",
        "NOMBRE": "ACNÉ QUELOIDE"
    },
    {
        "id": "d85ccb25-a360-f1c9-d8fc-93cd0d31d6ab",
        "NOMBRE": "ACNÉ TROPICAL"
    },
    {
        "id": "775b8b1f-8b9d-1d11-974e-ece15d798782",
        "NOMBRE": "ACNÉ VARIOLIFORME"
    },
    {
        "id": "ca127e1e-e3f9-ada2-f09d-c49ff6e01e87",
        "NOMBRE": "ACNÉ VULGAR"
    },
    {
        "id": "361a21b3-a8c8-7eb4-555c-04755a7912df",
        "NOMBRE": "ACNÉ, NO ESPECIFICADO"
    },
    {
        "id": "de026a04-1a88-a60c-e081-30ddcb074adc",
        "NOMBRE": "ACONDROGÉNESIS"
    },
    {
        "id": "744a39af-f200-ae47-d4a1-07ef5786466b",
        "NOMBRE": "ACONDROPLASIA"
    },
    {
        "id": "fe20d089-fba4-1a51-23b9-cebedf21dc73",
        "NOMBRE": "ACORTAMIENTO DEL TENDÓN DE AQUILES (ADQUIRIDO)"
    },
    {
        "id": "56ee5438-a878-b234-62ed-b4f0bc1f6d95",
        "NOMBRE": "ACRODERMATITIS CONTINUA"
    },
    {
        "id": "565b54e5-bcb3-5770-d660-881777089269",
        "NOMBRE": "ACRODERMATITIS CRÓNICA ATRÓFICA"
    },
    {
        "id": "fa782f1c-a89b-94c3-f3a7-c3ada32cc168",
        "NOMBRE": "ACRODERMATITIS PAPULAR INFANTIL [GIANNOTTI-CROSTI]"
    },
    {
        "id": "7a406d17-1b84-d329-2c67-1c625a571ced",
        "NOMBRE": "ACROMEGALIA Y GIGANTISMO HIPOFISARIO"
    },
    {
        "id": "2289140c-edef-f8fa-16d6-73244aaf04d6",
        "NOMBRE": "ACTINOMICETOMA"
    },
    {
        "id": "31eff8ec-8d82-972b-135e-d6bf7a77b9f6",
        "NOMBRE": "ACTINOMICOSIS"
    },
    {
        "id": "86f8bc5d-70b5-570f-ca73-832bce970bc2",
        "NOMBRE": "ACTINOMICOSIS ABDOMINAL"
    },
    {
        "id": "0252ef33-06f1-bc47-4081-ebc722a1ffe3",
        "NOMBRE": "ACTINOMICOSIS CERVICOFACIAL"
    },
    {
        "id": "3c6f1f0c-3bdf-98fc-b1f4-9391c6f4e63d",
        "NOMBRE": "ACTINOMICOSIS PULMONAR"
    },
    {
        "id": "dea3b349-e5cb-1d6e-2a7f-35cbd0fc431d",
        "NOMBRE": "ACTINOMICOSIS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "f82414db-3ddd-5f49-c566-39fdb968a83e",
        "NOMBRE": "ACTOS E IDEAS OBSESIVAS MIXTOS"
    },
    {
        "id": "26dd51cb-8e86-ce1d-e3c1-df1c4347dd32",
        "NOMBRE": "ADENOMEGALIA"
    },
    {
        "id": "02daec75-e939-7c52-a160-b47d15eabdef",
        "NOMBRE": "ADENOMEGALIA GENERALIZADA"
    },
    {
        "id": "76c1fffc-8fec-b61b-1246-e057a713aa81",
        "NOMBRE": "ADENOMEGALIA LOCALIZADA"
    },
    {
        "id": "083929e3-0c99-a905-8291-f94607cb0e5c",
        "NOMBRE": "ADENOMEGALIA, NO ESPECIFICADA"
    },
    {
        "id": "72bbcd8b-ab3a-c70f-42c1-297942681726",
        "NOMBRE": "ADENOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "0790c78a-b8c1-baf3-1e4a-f9f7deab6291",
        "NOMBRE": "ADHERENCIAS [BRIDAS] INTESTINALES CON OBSTRUCCIÓN"
    },
    {
        "id": "c74b2e7c-bf26-ced9-af0f-baa1febae249",
        "NOMBRE": "ADHERENCIAS PERITONEALES"
    },
    {
        "id": "9d62edd4-d5e3-f3b5-bc73-bd8f19981f1b",
        "NOMBRE": "ADHERENCIAS PERITONEALES PÉLVICAS CONSECUTIVAS A PROCEDIMIENTOS"
    },
    {
        "id": "225e800a-e04c-2b24-a1d9-c4532142e610",
        "NOMBRE": "ADHERENCIAS PERITONEALES PÉLVICAS FEMENINAS"
    },
    {
        "id": "1d763c7e-1fa2-6192-ef31-9a3f2426f09d",
        "NOMBRE": "ADHERENCIAS POSTOPERATORIAS DE LA VAGINA"
    },
    {
        "id": "baf8ced5-d9fe-230a-2a4b-126ec7243fc2",
        "NOMBRE": "ADIESTRAMIENTO ORTÓPTICO"
    },
    {
        "id": "375f6f11-cac2-c0c4-0bb8-096d9d02a9c0",
        "NOMBRE": "ADIPOSIDAD LOCALIZADA"
    },
    {
        "id": "a2669b99-9809-5e88-4544-cc494eb770e9",
        "NOMBRE": "AFAQUIA"
    },
    {
        "id": "db0bc0fb-c31a-eca6-d7e9-a06a192af4fa",
        "NOMBRE": "AFAQUIA CONGÉNITA"
    },
    {
        "id": "e389ab60-0d64-1417-c932-f6b2985bbf8a",
        "NOMBRE": "AFASIA ADQUIRIDA CON EPILEPSIA [LANDAU-KLEFFNER]"
    },
    {
        "id": "1764e136-a3c6-1f6f-33a6-a3997fb27af7",
        "NOMBRE": "AFECCIÓN ERITEMATOSA, NO ESPECIFICADA"
    },
    {
        "id": "88dca7a1-4149-0f48-0286-980af8155d6e",
        "NOMBRE": "AFECCIÓN HEMORRÁGICA, NO ESPECIFICADA"
    },
    {
        "id": "d381f8dd-2199-586d-e5a2-bf02fd611ec3",
        "NOMBRE": "AFECCIÓN NO ESPECIFICADA DE LA PIEL, PROPIA DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "96f2b6bf-22c2-6864-d9c8-d749d9d65a46",
        "NOMBRE": "AFECCIÓN NO ESPECIFICADA ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "f2a1f7c6-556d-8e7a-2d8b-0d8d4435152a",
        "NOMBRE": "AFECCIÓN NOSOCOMIAL"
    },
    {
        "id": "ebd722c1-6281-caa8-dc43-d5ea996bd6c3",
        "NOMBRE": "AFECCIÓN PLEURAL, NO ESPECIFICADA"
    },
    {
        "id": "fb411bc8-fe69-0b66-e214-e085820f8510",
        "NOMBRE": "AFECCIÓN RELACIONADA CON EL ESTILO DE VIDA"
    },
    {
        "id": "04a9fb80-3b3e-9a00-15ec-7ade7310804f",
        "NOMBRE": "AFECCIÓN RELACIONADA CON EL TRABAJO"
    },
    {
        "id": "d7aac950-9846-5d44-a081-47fae62bc8ba",
        "NOMBRE": "AFECCIÓN RELACIONADA CON LA CONTAMINACIÓN AMBIENTAL"
    },
    {
        "id": "491d7e84-d65e-e94f-ad1a-7c4074adb4ba",
        "NOMBRE": "AFECCIÓN RESPIRATORIA NO ESPECIFICADA DEL RECIÉN NACIDO"
    },
    {
        "id": "f3ce3c0c-9031-a9ef-5586-6bfd66b5c05b",
        "NOMBRE": "AFECCIÓN RESPIRATORIA NO ESPECIFICADA, DEBIDA A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"
    },
    {
        "id": "b24461ae-d800-c451-8c22-50274a10ff28",
        "NOMBRE": "AFECCIONES ALVEOLARES Y ALVEOLOPARIETALES"
    },
    {
        "id": "a8fee406-664b-29a9-ad10-8c496c0174c6",
        "NOMBRE": "AFECCIONES DEGENERATIVAS DEL GLOBO OCULAR"
    },
    {
        "id": "776fe38b-35d8-e883-8db4-683a9f782dbf",
        "NOMBRE": "AFECCIONES EXOFTÁLMICAS"
    },
    {
        "id": "73b1ed69-1f94-394d-a059-bb5dd4fb3df6",
        "NOMBRE": "AFECCIONES INFLAMATORIAS DE LOS MAXILARES"
    },
    {
        "id": "cda17c56-e7cb-2762-954b-668f2a31e9f7",
        "NOMBRE": "AFECCIONES NO ESPECIFICADAS ASOCIADAS CON LOS ÓRGANOS GENITALES FEMENINOS Y EL CICLO MENSTRUAL"
    },
    {
        "id": "cb7eea4a-6f00-34bb-4330-33a8ebb2c3fd",
        "NOMBRE": "AFECCIONES NO INFECCIOSAS DEL PABELLÓN AUDITIVO"
    },
    {
        "id": "0478026d-e75c-d817-dfde-56db4669c98c",
        "NOMBRE": "AFECCIONES RESPIRATORIAS CRÓNICAS DEBIDAS A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"
    },
    {
        "id": "997e65ec-3a97-418b-b938-86ecf0a45bd7",
        "NOMBRE": "AFECCIONES RESPIRATORIAS DEBIDAS A AGENTES EXTERNOS NO ESPECIFICADOS"
    },
    {
        "id": "a2449e23-ed72-fd4d-9f58-b30a54cdc5bc",
        "NOMBRE": "AFECCIONES RESPIRATORIAS DEBIDAS A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"
    },
    {
        "id": "ac0d647c-3e9e-953b-4fa6-76ae9f874c53",
        "NOMBRE": "AFECCIONES RESPIRATORIAS DEBIDAS A OTROS AGENTES EXTERNOS"
    },
    {
        "id": "833cfe50-ee59-b1cc-a09b-060f1c97ed63",
        "NOMBRE": "AFECCIONES RESPIRATORIAS DEBIDAS A OTROS AGENTES EXTERNOS ESPECIFICADOS"
    },
    {
        "id": "6f5eec4b-696b-baff-a12d-270d824e12b6",
        "NOMBRE": "AFECCIONES TUBULARES Y TUBULOINTERSTICIALES INDUCIDAS POR DROGAS Y POR METALES PESADOS"
    },
    {
        "id": "b08ad285-d982-cf18-aeff-1676b4e4605a",
        "NOMBRE": "AFONÍA"
    },
    {
        "id": "34279e90-6895-8c47-c50b-872f8cae11be",
        "NOMBRE": "AGALACTIA"
    },
    {
        "id": "03610521-742b-a412-c61a-1bd6161d65c5",
        "NOMBRE": "AGENESIA DEL PULMÓN"
    },
    {
        "id": "814ca4f2-c11a-11fe-9b2b-6bcac2f1dd45",
        "NOMBRE": "AGENESIA DEL URÉTER"
    },
    {
        "id": "e1367879-aa02-c747-5fdc-e64aa881cd75",
        "NOMBRE": "AGENESIA O HIPOPLASIA DE LA NARIZ"
    },
    {
        "id": "53d04eb8-7b72-9052-07ce-8970e83b415f",
        "NOMBRE": "AGENESIA RENAL Y OTRAS MALFORMACIONES HIPOPLÁSICAS DEL RIÑÓN"
    },
    {
        "id": "c5faccf2-87b7-93e4-0a0b-db6eb673ce2f",
        "NOMBRE": "AGENESIA RENAL, BILATERAL"
    },
    {
        "id": "25e18151-f643-19eb-28e5-ff0a565c79b0",
        "NOMBRE": "AGENESIA RENAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c05a5bdc-2c25-97d3-966a-fccd10576f9e",
        "NOMBRE": "AGENESIA RENAL, UNILATERAL"
    },
    {
        "id": "3265582c-7647-5058-0b77-4995bb9f46dc",
        "NOMBRE": "AGENESIA Y APLASIA DEL CUELLO UTERINO"
    },
    {
        "id": "c1119df7-dcc3-b7d5-6bd5-d1caa2899b57",
        "NOMBRE": "AGENESIA Y APLASIA DEL ÚTERO"
    },
    {
        "id": "106b7221-374e-be3d-7d31-e5fc802a0abe",
        "NOMBRE": "AGENESIA, APLASIA E HIPOPLASIA DE LA VESÍCULA BILIAR"
    },
    {
        "id": "8aaeb307-9bb2-6e55-29c7-83ee8a4a2c53",
        "NOMBRE": "AGENESIA, APLASIA E HIPOPLASIA DEL PÁNCREAS"
    },
    {
        "id": "7767bd06-9bd6-9401-31b9-3325b7613763",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A ANTIBIÓTICO NO ESPECIFICADO"
    },
    {
        "id": "3b67adbc-7c43-7a89-97be-fdb3518f7714",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A METICILINA"
    },
    {
        "id": "83939bcc-de6b-98f9-8e05-0477312e8b07",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A MÚLTIPLES ANTIBIÓTICOS"
    },
    {
        "id": "fe2fabcf-4e56-4cb9-be91-216a72eb6733",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A OTRO ANTIBIÓTICO SIMILAR A LA PENICILINA"
    },
    {
        "id": "5eb26ae1-7f67-6112-ecc7-373bb7f65de9",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A OTRO ANTIBIÓTICO SIMILAR A LA VANCOMICINA"
    },
    {
        "id": "a74c2a92-23d1-02fa-f725-f4bbcdffaa30",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A OTRO ANTIBIÓTICO ÚNICO ESPECIFICADO"
    },
    {
        "id": "7a13c12f-1e8e-74e6-b586-a946edf2d809",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A OTROS ANTIBIÓTICOS Y A LOS ANTIBIÓTICOS NO ESPECIFICADOS"
    },
    {
        "id": "464d7cc6-b50e-1b73-8922-b098d40e5498",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A PENICILINA"
    },
    {
        "id": "3278aeb5-eebc-5cfc-494a-5b33218e5362",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A PENICILINA U ANTIBIÓTICOS SIMILARES"
    },
    {
        "id": "37837de0-e66b-9e41-bc59-766c37b0fe3f",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A VANCOMICINA"
    },
    {
        "id": "eeb949f7-e487-a6ea-be04-d49af9fec4c0",
        "NOMBRE": "AGENTE BACTERIANO RESISTENTE A VANCOMICINA U ANTIBIÓTICOS SIMILARES"
    },
    {
        "id": "d3c43b84-2792-29f3-8185-b96a19f62f70",
        "NOMBRE": "AGENTES VIRALES COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "8c57766d-773d-8933-f829-6bb96084506c",
        "NOMBRE": "AGNOSIA"
    },
    {
        "id": "3edbd378-b557-86f0-467c-ce31cc25e1c3",
        "NOMBRE": "AGORAFOBIA"
    },
    {
        "id": "b2e597e8-25e6-3b50-1adb-5cb5d7c94adb",
        "NOMBRE": "AGOTAMIENTO DEBIDO A ESFUERZO EXCESIVO"
    },
    {
        "id": "ce47f0a1-d407-7d79-0453-f4586e0c4fbe",
        "NOMBRE": "AGOTAMIENTO DEBIDO A EXPOSICIÓN A LA INTEMPERIE"
    },
    {
        "id": "4f742998-69f6-2192-b823-216c7b363cba",
        "NOMBRE": "AGOTAMIENTO POR CALOR DEBIDA A DEPLECIÓN DE SAL"
    },
    {
        "id": "517d3a7b-e1a5-cbba-288d-684414584280",
        "NOMBRE": "AGOTAMIENTO POR CALOR NO ESPECIFICADO"
    },
    {
        "id": "fc08d679-124d-763e-d835-8f4a4be0f4f2",
        "NOMBRE": "AGOTAMIENTO POR CALOR, ANHIDRÓTICO"
    },
    {
        "id": "2063e372-870d-2f16-b54a-0318d5202756",
        "NOMBRE": "AGRANDAMIENTO E HIPERTROFIA DE LAS UÑAS"
    },
    {
        "id": "09e6c078-0075-50f3-31ec-f416c4599c1c",
        "NOMBRE": "AGRANULOCITOSIS"
    },
    {
        "id": "89fdee54-ed11-850f-6b37-2897cf60e38a",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA"
    },
    {
        "id": "e21d4769-8c09-f89b-3832-6bef922deef5",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "aa10110d-24bf-454a-9a06-b26c8f3ee175",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5fd355c8-cfa2-da3f-be0d-8516b949cf72",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, CALLES Y CARRETERAS"
    },
    {
        "id": "6817976c-7b47-1572-be3b-52063629642b",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b7eaf384-e280-5716-26d4-79ece9b5f9ce",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0e4f0fbb-d68d-b961-40f8-f4ef595f2fb8",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, GRANJA"
    },
    {
        "id": "e300e1f6-fd96-c72f-0514-0fbec9a9c4a3",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ef1b036a-e152-b04f-63ad-06e768b207e7",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "31274dd7-47fe-8cc1-96f9-b20d86634700",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "54e948ee-e1e9-c97d-deab-af8bb6509288",
        "NOMBRE": "AGRESIÓN CON DISPARO DE ARMA CORTA, VIVIENDA"
    },
    {
        "id": "477a32d7-26e6-b4f4-905c-baa2b90e0469",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "a77b7e04-7978-461d-e3b0-be1e167f6fbb",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a7ccc358-494b-bd1c-0f83-4de869fd7d77",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f82c07d7-bf15-4630-96b3-fce2a4c2c780",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "27cd58de-2be4-34e2-3026-634d94d55e3a",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c5e292d6-73df-ede2-30d4-5ec66a7f998f",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e913704e-c756-2817-7292-8d8cf0cc1fc3",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "1dae1181-d773-053e-ef48-90a1caeb0800",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "b43e548c-cb5c-5408-f79b-c0139b7c243e",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "bc220d21-0585-5a45-04ce-d34fc894678c",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "32dc22b6-75fb-452e-3fb9-55e2fa5ff7a2",
        "NOMBRE": "AGRESIÓN CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "52c65ea0-d5ae-c089-724e-70950dfe9081",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA"
    },
    {
        "id": "f9bf127e-a5b9-2b30-169c-913513dc8237",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b6ac4e03-65d9-f3ab-7660-8ba3b717d1ba",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f9493fe0-4527-fb28-596a-e656d62b4bc5",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, CALLES Y CARRETERAS"
    },
    {
        "id": "2d6a46fc-1c2a-1099-d75d-c6f98fe65506",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "61d41643-2030-549e-cbf6-b468cce72dd6",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6ae85b02-bca3-3a8b-b403-b44ac295ad0e",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, GRANJA"
    },
    {
        "id": "e4879125-6b9b-d21d-f4cc-9fa680698685",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cc92ed3e-3e6f-77a9-94f9-44cb2ccbf9c5",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a2878a07-40e5-eeb3-c400-507decf83790",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "fdd04204-c067-91e8-10da-0b91afe7cb5d",
        "NOMBRE": "AGRESIÓN CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, VIVIENDA"
    },
    {
        "id": "761a5838-cac1-d96c-8490-b1f067b813f6",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "1a843da5-9902-a83e-fc76-32a0eab2fd34",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b5ad3b71-55b7-6b0a-7de9-ba0fc8409217",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c88ddd76-76c2-d712-5fc6-499108f33b1b",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, CALLES Y CARRETERAS"
    },
    {
        "id": "aa63f711-c2fe-43d9-d471-bef866e2c88e",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e24e6dae-da26-b159-d38e-2e887eaa3f13",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "1134b7c4-e82c-0292-80d2-c9c05ac38237",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, GRANJA"
    },
    {
        "id": "ed3bd60f-9ef8-f39d-eaff-56275806e983",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "03a18c7b-8755-d54f-899a-005d7b32146d",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f490f66a-5e5a-4585-ac63-266d50760772",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d30e5b5f-2eea-cb28-a3f9-c64541f983bc",
        "NOMBRE": "AGRESIÓN CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, VIVIENDA"
    },
    {
        "id": "32db3f35-9933-49de-e1e6-527d8cd34acb",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL"
    },
    {
        "id": "6a249d0c-823a-6a9e-a541-8d6e0514b88d",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "15db565c-266f-1304-336a-b8e7be71480c",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f7b85d9c-2aba-567e-cd51-d55b5ff3d96a",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, CALLES Y CARRETERAS"
    },
    {
        "id": "8b2ba23c-8625-c786-1213-87cb6e3af20e",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b1e39c62-271b-6c54-0967-f163c36d8680",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "5715b248-dc98-4d13-9163-f2e8e96961fd",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, GRANJA"
    },
    {
        "id": "7fb3be77-be9b-1316-78a2-b152b46c5bc7",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "9e5b0221-2ec8-5ec5-3e62-649c0ffaadd4",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "b7c4dad5-8a31-6735-4307-5a316779da36",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "69bcaa5a-0243-75e6-af69-d6a9046959de",
        "NOMBRE": "AGRESIÓN CON FUERZA CORPORAL, VIVIENDA"
    },
    {
        "id": "99a8f648-4e9b-6b49-4e4a-69285035347a",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES"
    },
    {
        "id": "d240de1c-00a8-c2b3-7a39-f3a592aa34ae",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "37ab3538-4bf4-fa47-7f86-2b6157f3f0db",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a7d12e3c-1566-9a60-52a4-8d68521a050f",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, CALLES Y CARRETERAS"
    },
    {
        "id": "a3c75aaf-7ffe-0e94-de97-8986556ee864",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e9fa76f2-cfe4-dad8-7ff6-383031406f50",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "fdcdb2d5-8483-2114-1715-28899b9a53f1",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, GRANJA"
    },
    {
        "id": "227a1556-3193-350e-3a3d-6f6dce94bfeb",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "6b13eccd-7ebc-3c21-c4fa-b469f1635742",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "e29ece57-bc71-6b9b-598f-13f4daaad031",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "20ce8521-f500-99c1-f389-a4f57ba510f2",
        "NOMBRE": "AGRESIÓN CON GASES Y VAPORES, VIVIENDA"
    },
    {
        "id": "988978ac-c2b3-0f7e-f77d-643da771ea62",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS"
    },
    {
        "id": "8c558e3c-6150-a93a-38e2-719a38c19a78",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "160cd47c-d3e1-37f7-3915-08bc0e048ce5",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "bd5e1fb1-d2e8-9c82-f80c-4bfa68aebbb4",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, CALLES Y CARRETERAS"
    },
    {
        "id": "458f9438-3f3b-13f1-a99d-0d26ed9d57f6",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1f488f3c-e2ec-9f24-8cf5-0e435ed00e39",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c61e1740-d8a5-58c2-050c-d2b49fccdf2e",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, GRANJA"
    },
    {
        "id": "e8239460-c493-bbdd-ef37-897d054b24a5",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "bc53b211-2d69-a180-7f42-29533e63ff69",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "3a7f0740-64c7-0149-5049-33281e331262",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8ced1c5d-6d87-276c-46ad-e5f1dae88387",
        "NOMBRE": "AGRESIÓN CON HUMO, FUEGO Y LLAMAS, VIVIENDA"
    },
    {
        "id": "3545899a-7ca4-4e21-1d68-f2333b13d443",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO"
    },
    {
        "id": "e032818a-7495-b224-b59d-3aa83effd3d5",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "9391f4a7-c43c-d1cf-4728-10eed53a5962",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "91447976-0f37-3eb6-d0c7-0a03f56fdc07",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, CALLES Y CARRETERAS"
    },
    {
        "id": "93373fb3-4dbf-00a8-7297-11a3cec4416c",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "52dd321d-74ef-4ea6-de03-58f28e6ad440",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "f79af46c-f3d1-91ee-d1d1-934f63d1b696",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, GRANJA"
    },
    {
        "id": "148f1595-e04f-782e-8c98-7dc9870f6b78",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "020786ca-1341-22db-8737-a002e8ca5485",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9c622c3c-aae8-f741-3619-90759d55b3f5",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "c9be5ed5-f25d-63f3-d94c-eeed546728d9",
        "NOMBRE": "AGRESIÓN CON MATERIAL EXPLOSIVO, VIVIENDA"
    },
    {
        "id": "037351e6-e2b1-f01f-9cfd-74e11078e224",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE"
    },
    {
        "id": "1061c5aa-6125-831f-2910-9b272a65b63c",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f2d8d2b5-8e11-a06f-ce40-d69d8b70c89a",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "530aac0c-5065-5510-c709-42b1fc64c44b",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, CALLES Y CARRETERAS"
    },
    {
        "id": "48ee82ad-403e-20a3-0e80-ccd921fcb87a",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "dbd6d5db-8a01-f66a-9340-2a4babc60052",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "57139839-02fb-169b-fc03-e6559e7adf1a",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, GRANJA"
    },
    {
        "id": "1bb544dd-d118-47a9-1c48-521780030b3b",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "67b171b0-5de8-1982-705f-81897f9873c8",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "090d5977-daf4-d9ee-84d7-bd22ea2ff1ca",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "7e494081-b391-9738-b97a-790aa197d00f",
        "NOMBRE": "AGRESIÓN CON OBJETO CORTANTE, VIVIENDA"
    },
    {
        "id": "8b882693-a9cb-4ae1-700b-1385cba6066d",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO"
    },
    {
        "id": "540a130c-cfd4-8b75-0dee-3be217aa88de",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "85d29ccc-3b58-ad3d-54b4-53a78afd0528",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "33a2dc26-78d5-348a-a7cf-21bfe5008632",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, CALLES Y CARRETERAS"
    },
    {
        "id": "ee35328a-7703-0c4d-1e3e-fe6729ea58f5",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "7cfecf2e-81a3-8c27-7681-c1107d5c4d17",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "df2bfa83-8ce5-fd57-98c2-60226fd58708",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, GRANJA"
    },
    {
        "id": "677d26f4-d45a-b051-c9bb-c8ccb824d93d",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e32f9174-fbf8-49de-e1f7-3703e069483c",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c23c59ef-1594-9fc5-31cc-01f0127a1cc6",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6bcc4d3b-0b71-72b6-cf12-6eae072528f1",
        "NOMBRE": "AGRESIÓN CON OBJETO ROMO O SIN FILO, VIVIENDA"
    },
    {
        "id": "b3f32db9-9783-fa38-6bfc-625bb64975f9",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS"
    },
    {
        "id": "93e08fdb-77e7-7224-5b78-9daf79ef4adc",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "eb1cc3c5-32fa-6c87-40cb-82989a012e57",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "b4d05c08-9cb2-590a-3d4d-6514bc3f52db",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "ae9ece26-b9fd-09d7-c63c-77fc8d837ac8",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "74e66171-1ce8-b125-512a-740caf7bcb08",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "4a06dec1-eaad-b3d0-56f7-19368c9e4230",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, GRANJA"
    },
    {
        "id": "3c7f3c04-156b-ec96-cab5-f44890e00bfb",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "217c7620-bbe3-bcf7-c395-c83641ddd828",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "2cda1b40-ff83-20d8-b53b-df319031fef1",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ba222216-d26a-7c3e-1ed1-70c0ec8071db",
        "NOMBRE": "AGRESIÓN CON OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "69635a2a-eea7-c514-16f0-ff2fca3f43dd",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS"
    },
    {
        "id": "eedd09a4-2b78-4fa4-b832-85d241f8441d",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "58a7cee5-44a2-483e-a047-0d359722b0aa",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a906c54d-311a-293f-d34c-265cb3ad827e",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, CALLES Y CARRETERAS"
    },
    {
        "id": "64ead4ce-b064-e450-0a16-6dd14e84538e",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "021180a2-277c-f720-d26d-53ed6a59d155",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "db476d40-962f-f5c9-2087-36718416d723",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, GRANJA"
    },
    {
        "id": "bfef6d80-342d-4900-594f-0e356f9a909e",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ff838324-4f96-713e-bd58-5f25ae47f1a7",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "86aa97d0-11fd-daa7-2c6a-2972630b5c0f",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "7a540d95-9761-278b-9426-192477c410a5",
        "NOMBRE": "AGRESIÓN CON PLAGUICIDAS, VIVIENDA"
    },
    {
        "id": "bfd6ca22-b136-d2ce-b1ec-aec8eb33172f",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS"
    },
    {
        "id": "9ff79185-0e16-5dc8-d32f-730cd31b5401",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "1c0eda80-a8c1-cc26-de25-f6033f2f5792",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "354f4cc6-16ff-b6ca-e191-377dcf230b6c",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "ae08404f-e7af-4b6c-01a2-0a9c1153dedb",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c163f8d5-3f01-844b-69cf-d40507556f79",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "00cf3bc6-e2db-5721-bebf-45fa19c82b5f",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "51d9fab7-cdbf-db25-b5a6-657125d528df",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "af62dbd7-772e-fa83-a9f6-e0c2e0edac4c",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ef3eac04-55d0-1b5c-b529-5c674d241279",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8b2da6c5-9287-b135-de6f-a49ea8099dd6",
        "NOMBRE": "AGRESIÓN CON PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "5e96ab0d-ddf9-f337-565c-29d43002231a",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA"
    },
    {
        "id": "89ef25c2-073f-46dc-0c56-daf6992e4570",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "cd0e4c65-9f79-c272-74f6-080f9660da78",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "1b5169e8-4212-6edf-eee9-961edfcc1416",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, CALLES Y CARRETERAS"
    },
    {
        "id": "3ed423c4-2959-94b0-5140-357709060a87",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "00e28b25-a431-ae86-0716-f497b69be83d",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0ec7f775-3a2a-e985-7468-a94d3321c800",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, GRANJA"
    },
    {
        "id": "483cf926-52b7-f685-5c96-1250a0eea239",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a76be146-60bb-ab8d-cc4d-6ff49fe166b8",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "48c102e1-3903-cf52-240e-9dfb8b4f18be",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "80bd64e3-9ca1-abdf-75ae-eb028e4a5be1",
        "NOMBRE": "AGRESIÓN CON SUSTANCIA CORROSIVA, VIVIENDA"
    },
    {
        "id": "ef910567-b763-6f8b-916d-8e4f43afdbcf",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES"
    },
    {
        "id": "f4eae120-6dc0-e3dd-8292-f4849c56ee81",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "6c01c042-0b0a-4f07-2ef6-25a372f15caf",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "35a1c9fb-a15a-e6e7-4c1a-e87df1a6066f",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "163a1448-f7c7-731f-6c11-dca3a1e2d0d8",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1e7ba4e9-16d1-28b6-ebd4-196ff88e8901",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a8725691-8780-d737-2019-3dd81f5bd0c4",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, GRANJA"
    },
    {
        "id": "67e192dd-feb5-f1af-f4fd-2cd8e86cda26",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ff48238c-6315-5f53-38a7-72141cd8da68",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c6c1b171-a10c-cad1-8cf7-230a58b828b0",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "69a54225-db32-4c95-3561-46f7d1e67c7a",
        "NOMBRE": "AGRESIÓN CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, VIVIENDA"
    },
    {
        "id": "fef4b036-cf14-5f88-23be-45879a81a470",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN"
    },
    {
        "id": "6b7578d1-1979-6056-e661-ac9603714c22",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7b7295af-40ba-bb34-556d-507e54a3d2c7",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "6dd004d6-7674-28dd-c20b-72bcadc1cb15",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "848a879e-e7c8-857c-fed2-f373069b33d3",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1ae27b6c-8385-ca05-b213-e8bb6092e377",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "77bb9dd2-1538-c984-63ce-c11cd285021a",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, GRANJA"
    },
    {
        "id": "df52d4a5-0e0f-1539-0f0c-746d76bb117d",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "bccf87d3-8075-21b4-ab17-6b8d8770afe3",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ed77c9b6-3e2a-e1f9-3ec2-f2ca8b702146",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "10acfd08-3a23-24d5-3ce0-f327605b4876",
        "NOMBRE": "AGRESIÓN POR AHOGAMIENTO Y SUMERSIÓN, VIVIENDA"
    },
    {
        "id": "bf107359-d1e8-d8a0-be0f-b93f96184fbe",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN"
    },
    {
        "id": "dff1b300-c715-0e72-cd93-843da72ba3ef",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b151f931-d548-be4d-c75d-909510d90e71",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ebb4d53b-e254-6e5a-3051-9543c3ef9178",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "fd24cd2f-e257-03b8-81e1-2674b5f5422f",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "9f44ad5d-7a75-6c22-398a-b2be343f9173",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "93b92155-8c8b-2eee-50db-1ab9e916fbee",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, GRANJA"
    },
    {
        "id": "f17a529d-fac0-36b7-b31a-25462727cc56",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "dfca0cbd-4b76-c6d5-3a8e-25bda39c60c1",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f7b9e590-eb4e-48c9-6033-98c0d7df30df",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "885637ce-3890-6bf8-40eb-ff47a7351b40",
        "NOMBRE": "AGRESIÓN POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, VIVIENDA"
    },
    {
        "id": "e7c3bf28-f9f7-8cc1-52a5-481c3b00b6df",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR"
    },
    {
        "id": "1fa3c867-6122-dbd2-d133-3b5626d1303f",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "13eccfdc-eb6b-d672-f4c2-96dbfd3bed45",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "71af7197-16ff-ac16-ef7e-6c6c1053f89b",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, CALLES Y CARRETERAS"
    },
    {
        "id": "1edc645f-84c0-90e4-094a-8ff36afbf09f",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0364a410-23a2-6366-b26e-91d8dfd01d77",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a9ef8e7c-acbe-4b82-d623-431e5d320639",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, GRANJA"
    },
    {
        "id": "ec8dd911-61f9-07dd-5066-eb68a1a1ceff",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "87ca0c65-c23b-54ba-e969-5eb8c7e42879",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c012c453-5aa8-a96f-6a05-6bb0952dfd7b",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "63c4b8e1-3867-e606-457a-40bc9d29d822",
        "NOMBRE": "AGRESIÓN POR COLISIÓN DE VEHÍCULO DE MOTOR, VIVIENDA"
    },
    {
        "id": "ed95513a-1112-bd0f-9250-ebd4775bb04b",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO"
    },
    {
        "id": "67b86199-0981-30c0-ce76-d93d02e9ccf6",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "35070675-e067-3dc8-c4a2-fd9571db02c8",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "91c65841-f5d9-8c22-62eb-cef165fc38f9",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, CALLES Y CARRETERAS"
    },
    {
        "id": "423dab73-04b7-2a58-10d0-a997fe7c025a",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "9b376661-6103-500c-7482-58d2f95d5f6c",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b79956d4-12b6-5e86-779c-f76973f778fa",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, GRANJA"
    },
    {
        "id": "532d0caf-bb3f-8249-1082-4b4583493e45",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "abf5eb27-d095-8a78-8ed8-75d772bb64ed",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "0325d5a6-f593-181a-b377-9b7ccb689235",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a813a596-da00-29a3-644d-a562858ac880",
        "NOMBRE": "AGRESIÓN POR EMPUJAR O COLOCAR A LA VÍCTIMA DELANTE DE OBJETO EN MOVIMIENTO, VIVIENDA"
    },
    {
        "id": "e4e8d55a-dcda-339b-e5f6-5a11488719f1",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO"
    },
    {
        "id": "044f90ee-9b62-6cf1-5adf-a714c3101074",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0fc60de8-5ba7-5a78-723b-bad806e866f9",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0943bef3-9800-636e-6f52-fcf685577255",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, CALLES Y CARRETERAS"
    },
    {
        "id": "1d3c000b-aed5-0bbc-9ebe-ae466c02aab0",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0e0d1bd4-be89-45a0-1bda-b96578006719",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "d3a8c184-6876-eeb5-3f6d-78244ea0f947",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, GRANJA"
    },
    {
        "id": "87ecc392-d4da-974c-5dc7-607e67ca0e25",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "9eab393f-a5d8-6073-d413-6a489ffef039",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "0544f601-c22b-05a3-91ae-8ce2b2f84e52",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "5d9b8347-86d8-b83e-841f-69c577cc4295",
        "NOMBRE": "AGRESIÓN POR EMPUJÓN DESDE UN LUGAR ELEVADO, VIVIENDA"
    },
    {
        "id": "74a3971d-13a9-420f-246d-52c8b044d066",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS"
    },
    {
        "id": "2ab7b797-030d-0828-c1e3-b5feefd81431",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d5180432-02df-403d-eb1f-60e4a373bc12",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "b35736db-9613-9ddd-ae6c-350181aa537e",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "98260505-b1a2-7803-f39a-20d1544905fe",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3d0b9706-8b78-84ee-4789-81bf739b8824",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "376ce540-53db-104c-e938-1cb914248cbf",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "f0e39ff7-f4e2-c0cc-8188-6ba92f833e6a",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3546caaa-c83c-39cf-1115-a2892c644556",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "45f30882-0c6c-5b1f-63f0-2577e74af4a1",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e3a2d601-6354-bac9-e806-d03f96fd8373",
        "NOMBRE": "AGRESIÓN POR MEDIOS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "45dbfc74-8bdd-7205-7146-7ad2ae6eb2f9",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS"
    },
    {
        "id": "3d2c74c7-c4a4-aa2c-fe6c-cb6d92534f75",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "19d89397-ac07-bd03-b021-0675e1b5015e",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8287c918-53a8-09d9-25a5-06d864055209",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "f888ad53-9ab6-fcc0-46a7-f293dbee86d2",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c5d72207-7f7f-41c1-fe17-cbe1ded4707a",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "3cd74c32-1ba4-35be-aff3-f2c57ff69fe3",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, GRANJA"
    },
    {
        "id": "8f3c3e6c-2ce9-df7d-935f-ad8463d0f164",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7b2e89fb-73e0-3972-feee-f78b5fc6e2e1",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9c89fc3b-361f-07d1-0ab7-b394b9fc3a64",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "4c5eae65-453d-00b8-4a81-ba751ec69aad",
        "NOMBRE": "AGRESIÓN POR OTROS MEDIOS ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "163438a1-7eac-0dc5-5bf1-e52a239718e7",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL"
    },
    {
        "id": "679c5654-4fb3-19c3-b0ce-ded76c815996",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "58324235-b33a-f205-0339-b1c46eaa3854",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ff5faf36-60a4-51be-b5cc-aa8a0005eb30",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, CALLES Y CARRETERAS"
    },
    {
        "id": "3bd547ad-4c8e-a688-f458-100b1062586d",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "026ea5c6-339f-0c2e-8112-401fe5d0c73b",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "fed1bd7b-214b-8f4a-877e-9e41b9bbb1ce",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, GRANJA"
    },
    {
        "id": "db93dc9b-9999-0797-ea93-ef0f738bd5f6",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a4a73547-83c6-2adf-3bd3-56b6072ca332",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d5265346-5c4b-37ce-08c9-2f60c896877e",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "664dbf56-412f-5f9e-11d2-33e3c39b3820",
        "NOMBRE": "AGRESIÓN SEXUAL CON FUERZA CORPORAL, VIVIENDA"
    },
    {
        "id": "4912f800-1aa5-7b9a-a3e5-156d40bbb763",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA"
    },
    {
        "id": "796e71f1-e521-9c01-c45d-fa4ac312bdf9",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "e4c6f830-8061-8cb9-793f-a099044240a4",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c661209d-d7ba-6a83-d2d0-61ab8e46cbe0",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, CALLES Y CARRETERAS"
    },
    {
        "id": "cf36ecbf-fb28-0165-10a9-def478e74ccd",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6721d4be-d6c7-91ad-71a4-e0dcd189fa2b",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b843d84e-1638-751f-6b12-e314afce3ce7",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, GRANJA"
    },
    {
        "id": "86a2619a-64b0-d814-4e91-45cfa40c5f8d",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e44ee9f4-e011-51d1-1043-0afabfafb6b3",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "29411dbb-9186-cbfe-5ae1-b92a2aa76146",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ec8fa6df-e8f8-6e85-d6c5-4459a99402b2",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN LA BAÑERA, VIVIENDA"
    },
    {
        "id": "5315282b-2472-b640-0a9c-5bc713140892",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA"
    },
    {
        "id": "2d4f6406-d3b9-93a8-2f12-ecf9c92f7ff8",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "83b5081a-502c-63b4-3e41-9c81cfd0cb2d",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4111f3d2-7f03-324c-4935-0b18a296b415",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, CALLES Y CARRETERAS"
    },
    {
        "id": "7506f47d-2b10-8f79-f448-a7dc5ea23add",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5baba5a5-60d2-a642-ea15-dae340c1f779",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c8a27690-7a6b-b6aa-85bc-63742c2b7264",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, GRANJA"
    },
    {
        "id": "1a533fab-b6d9-3af3-f593-93ac2c54bc79",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e99eda2f-59a1-dd36-db7f-ddc3e045e6c2",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "3cae5d4e-e8eb-3258-cd43-5a37db1772fc",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "fd88f5e4-a13f-7ec8-70d1-3d77eed3c9da",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN CONSECUTIVOS A CAÍDA EN UNA PISCINA, VIVIENDA"
    },
    {
        "id": "0a1cec2e-5573-b2ed-bc3f-8dcac8e00171",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES"
    },
    {
        "id": "6ac2b0a4-5595-c0cc-5783-c0c4328e9b9a",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "914e016b-101c-4305-8a97-cd5efe0a12c6",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c2520ee1-60c4-b280-710f-b8e635674a89",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, CALLES Y CARRETERAS"
    },
    {
        "id": "128985cd-8743-c68a-4a47-299b9837929b",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "370efa44-ac5f-a995-5ad8-2b85bbd778b6",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "45d80922-180c-2896-d73d-ca5966b725ed",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, GRANJA"
    },
    {
        "id": "f674c4e0-0740-d822-238c-ac918a3a36b1",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ae82ced0-b9ea-684d-fb82-8d4520e2d97d",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "06d0a71e-027d-5aaa-7b06-036d41753c94",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "7872d147-f7e2-4259-ca86-907e70b4af0e",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN AGUAS NATURALES, VIVIENDA"
    },
    {
        "id": "904226d5-40fe-6b6a-c133-575fc68f58e2",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA"
    },
    {
        "id": "8c5de42e-745d-cac1-872e-6107c119afc6",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "22acc3a4-d246-027f-3652-85f6a0065cde",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "b56f60eb-6f3b-a7f2-493a-258fdc7c1e98",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, CALLES Y CARRETERAS"
    },
    {
        "id": "a5519760-687f-95f3-ee64-69b1e8052e59",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "bd3a0f90-b92a-f86d-e9ee-204d3d57ecb7",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b5ff6534-468e-448a-aa65-37be8dec14f8",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, GRANJA"
    },
    {
        "id": "4abe52d3-267b-7c60-5a8d-d1f5e2115cbf",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "18b24533-e1fd-4e04-d63c-2da203dd87da",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d0ab16f9-fbb5-ec84-d501-0a70d3e593b3",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "5c57c519-ea28-d58d-c78d-c4b4ba29bb19",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN LA BAÑERA, VIVIENDA"
    },
    {
        "id": "6df88515-bdf7-b934-c5d1-c678d832e389",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA"
    },
    {
        "id": "5fe8921b-7d86-5ed2-2a0e-42cf0c0156af",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "22db937e-76c9-64b4-c2e8-3d6f091ef2eb",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "13178238-d094-ab7f-3a49-e48343e12726",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, CALLES Y CARRETERAS"
    },
    {
        "id": "4035c259-729e-7795-f621-02b0abc127e1",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "8f742edb-cb70-2663-c117-76242a027903",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "f00e7e44-c973-1a7b-ff0e-4b3edfc94e95",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, GRANJA"
    },
    {
        "id": "e9c48382-f2a0-834a-8463-6219beb63269",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ac93cc16-6a88-f086-0a90-109129e4a1a1",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "6d3d1840-b92e-35fa-43e1-94ef5eb8571f",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "52b32fe6-0a3d-ddb9-9cad-0ae3dd797c39",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN MIENTRAS SE ESTÁ EN UNA PISCINA, VIVIENDA"
    },
    {
        "id": "8c75c178-87a4-a160-72af-1e1519e8f8d7",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS"
    },
    {
        "id": "cb22cc62-99c4-69ca-f63c-b07aea57b6e1",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "38e3ffe9-b152-49ef-8e7f-3db8fe21f30c",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "9b67c2e6-db4a-2ea8-79d8-ad2a534acbc0",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "3bcd51f7-9793-c2f1-2fe4-0739a222e938",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c8a1d0b7-8697-b604-93dd-afddaaa93afa",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "fa5e0f11-86ab-57a5-5892-5a72aa1088a4",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "3a7e8ee8-dff6-5de4-ae1e-66d159e04820",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "0efecb51-05a7-70c9-07c0-4e6aa58c1a0b",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "e8429c8a-5d9f-2465-9b22-2f81c19ebc78",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "2711a894-29a9-85bd-d096-929c3ed2ba43",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "6aab1035-cd7d-38d6-7529-b7561a535ac2",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN NO MORTAL"
    },
    {
        "id": "563b6bb5-d53a-b718-8a1d-71f579bcefac",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES"
    },
    {
        "id": "909ff3a1-6094-0081-cbf2-53df9115abda",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b950e448-9aa6-c637-46ea-c81cffb7e21e",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e368a2b1-7351-c3a7-e40b-5efd8eaf234a",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, CALLES Y CARRETERAS"
    },
    {
        "id": "4dcb9315-d247-2a0e-542d-8602a32bbb24",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e165f0b5-3222-874c-4008-f4f0e0b0c263",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2efa180c-2966-cb85-b456-1c29ea5f5c27",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, GRANJA"
    },
    {
        "id": "dc900357-5e92-153d-9798-b510122cb5a5",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a76843f9-93c2-ed0f-65a6-3ab00d34f949",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5ba44f88-1ca3-6d25-5d2a-a2a96c8f7710",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "27bd277e-9478-9560-63b1-1b3ebabc1713",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN POSTERIOR A CAÍDA EN AGUAS NATURALES, VIVIENDA"
    },
    {
        "id": "8b14bcac-413d-e989-34d6-d928accf4985",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN"
    },
    {
        "id": "973dcf75-e167-7c6c-bfdc-8cad7bfd5113",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, BALSA INFLABLE (SIN MOTOR)"
    },
    {
        "id": "b286a4ce-1923-6fc0-bb7f-3541341735fb",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, BARCO DE PASAJEROS"
    },
    {
        "id": "89f897cd-de45-de74-72c1-5ffa631f808a",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, BARCO MERCANTE"
    },
    {
        "id": "96397a58-2169-4a26-08b1-e860f1406a25",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, BOTE DE PESCA"
    },
    {
        "id": "8a7b64bc-c6a7-c83f-80e2-5f6ac1ea8d1b",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, CANOA O KAYAK"
    },
    {
        "id": "25d49330-f7dc-525c-9669-d79d05c80cb7",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, ESQUÍ ACUÁTICO"
    },
    {
        "id": "d145595f-4859-714c-5c50-ceb6b01655f7",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, OTRO VEHÍCULO ACUÁTICO CON MOTOR"
    },
    {
        "id": "b180b5c6-6789-d42a-deef-c8d9e88bb6a5",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, OTRO VEHÍCULO ACUÁTICO SIN MOTOR"
    },
    {
        "id": "23063fc5-2d9e-74a0-d3b7-e11f338298ad",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, VEHÍCULO ACUÁTICO NO ESPECIFICADO"
    },
    {
        "id": "1d97b53b-2dbb-009a-7754-513f0d497771",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACIÓN, VELERO"
    },
    {
        "id": "dac51807-201a-3150-d14b-5c3405b13e62",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "72f780fa-7844-0271-b66c-d07be0cbd547",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f363e45e-fc9c-4db7-df28-1e5de0c105f8",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "17afe4aa-eebc-4ba4-c25b-7e682a8b1f5f",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "ef3e2610-5d9a-72a6-cecf-1e00b1be0c93",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a7a1546e-0bb0-6d2a-836a-9e4441331ee6",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "77e880f2-dd8f-b098-79d6-e0afa5fe1c0d",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "51f186c1-74ff-6568-1957-6623ea0df0b2",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "036be31a-0243-18a8-f019-cef3f0eeb93d",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "4759e0cd-dd7c-653b-c718-665e459c641e",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b8995b31-6323-1fae-e5f7-498fdb9071ad",
        "NOMBRE": "AHOGAMIENTO Y SUMERSIÓN, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "bdabadcb-9c4c-866e-7f2e-a15aa8423577",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "f25e7794-f650-5bb4-fb6c-5e51291efe69",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "5a6e0018-76b3-4031-45a9-837c77b50069",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "6ba46a32-0bf9-3c66-eaf0-24c4bd83c759",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "cb879044-1648-4d83-f0d6-2ec6d250ef21",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "2d437617-43e7-f7c2-c209-0436cca49662",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "66c61dde-470a-a4e4-f555-e353d6dae985",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "ac69ca3f-d57b-012a-d2c6-bb9d7f44678d",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f9b9ac6d-f07a-5ddd-ba8d-e5640ce6723b",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ac55b32a-90ee-3ff4-93ea-a17903d7ac32",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "602aaa24-45b1-9490-0ac5-5caa91de499e",
        "NOMBRE": "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACIÓN, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "1aa2daaf-581c-4e94-4d38-a723975eb452",
        "NOMBRE": "AINHUM"
    },
    {
        "id": "10dc1197-0adf-f7c1-3423-ad92c60ac1f3",
        "NOMBRE": "AISLAMIENTO"
    },
    {
        "id": "6e2aeb55-4f99-8602-ec3f-87ace4b44653",
        "NOMBRE": "ALBINISMO"
    },
    {
        "id": "6c9ffc06-8671-05d5-df95-738ffa5fb39b",
        "NOMBRE": "ALCALOSIS"
    },
    {
        "id": "a50267e3-2058-114a-c05e-fa2c940a97dd",
        "NOMBRE": "ALCOHOLISMO, NIVEL DE INTOXICACIÓN NO ESPECIFICADO"
    },
    {
        "id": "1b02c54f-a4c3-9e5d-6a45-4a1801854fc6",
        "NOMBRE": "ALERGIA NO ESPECIFICADA"
    },
    {
        "id": "b730bf2b-21f6-cc39-148f-07ea5d08404c",
        "NOMBRE": "ALESQUERIASIS"
    },
    {
        "id": "dec4beb3-2c18-608c-cd4f-88bb0233fc29",
        "NOMBRE": "ALETEO AURICULAR ATÍPICO"
    },
    {
        "id": "202c96cf-843c-5e3c-4b78-1aa4ad0f7e76",
        "NOMBRE": "ALETEO AURICULAR TÍPICO"
    },
    {
        "id": "5d3e2dbc-91df-0ac8-d6b0-170bb6d959fb",
        "NOMBRE": "ALFA TALASEMIA"
    },
    {
        "id": "b0a14121-bb96-deaf-5aca-54c2d9601287",
        "NOMBRE": "ALGONEURODISTROFIA"
    },
    {
        "id": "62a211c7-2f5a-d3cf-0aa0-e47ad7237e19",
        "NOMBRE": "ALGUNAS COMPLICACIONES PRECOCES DE TRAUMATISMOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1879fb03-df40-eb60-4f28-87ba363276b4",
        "NOMBRE": "ALOPECIA (CAPITIS) TOTAL"
    },
    {
        "id": "39edc02f-2093-660b-5704-b535060913f0",
        "NOMBRE": "ALOPECIA ANDRÓGENA"
    },
    {
        "id": "21b959b6-ced2-f6f0-73b8-2763c04e288e",
        "NOMBRE": "ALOPECIA ANDRÓGENA, INDUCIDA POR DROGAS"
    },
    {
        "id": "02b3935d-9239-8b7b-14c4-fced52a4cf6e",
        "NOMBRE": "ALOPECIA ANDRÓGENA, NO ESPECIFICADA"
    },
    {
        "id": "42b4ea2c-8ca5-793e-505b-04e55e546f5c",
        "NOMBRE": "ALOPECIA AREATA"
    },
    {
        "id": "7e96c286-e765-8574-deb8-32d44d5ddc50",
        "NOMBRE": "ALOPECIA AREATA, NO ESPECIFICADA"
    },
    {
        "id": "e8454c87-df0a-8a1c-ce03-a3ac2bae4d06",
        "NOMBRE": "ALOPECIA CICATRICIAL [PÉRDIDA CICATRICIAL DEL PELO]"
    },
    {
        "id": "45da4815-b457-71a3-2604-40fadcde0051",
        "NOMBRE": "ALOPECIA CICATRICIAL, NO ESPECIFICADA"
    },
    {
        "id": "cf5e9512-ab48-07a8-cfca-af8d5512e470",
        "NOMBRE": "ALOPECIA CONGÉNITA"
    },
    {
        "id": "0ea6a47a-e0d9-b3d9-e53a-3e0838bfbe91",
        "NOMBRE": "ALOPECIA MUCINOSA"
    },
    {
        "id": "e7f3d3b2-3208-ee57-e3ee-df7b396d3fb4",
        "NOMBRE": "ALOPECIA UNIVERSAL"
    },
    {
        "id": "2ff52862-1856-c61f-9e82-04564eaf669f",
        "NOMBRE": "ALTERACIÓN CEREBRAL NO ESPECIFICADA DEL RECIÉN NACIDO"
    },
    {
        "id": "80296d3f-aa39-da18-b6d1-32e896ff8d4c",
        "NOMBRE": "ALTERACIÓN NO ESPECIFICADA DE LA REGULACIÓN DE LA TEMPERATURA EN EL RECIÉN NACIDO"
    },
    {
        "id": "f41d59c1-d017-da8c-f676-79e35456e8ab",
        "NOMBRE": "ALTERACIÓN VISUAL, NO ESPECIFICADA"
    },
    {
        "id": "69276177-6b58-a41b-f18b-bc38eb6f858f",
        "NOMBRE": "ALTERACIONES DE LA SECRECIÓN SALIVAL"
    },
    {
        "id": "efa7ada6-0819-85be-61c2-5daf75bfa9f9",
        "NOMBRE": "ALTERACIONES DE LA SENSIBILIDAD CUTÁNEA"
    },
    {
        "id": "069a64aa-d2bb-dd1c-f224-8aab3c008649",
        "NOMBRE": "ALTERACIONES DE LA VISIÓN"
    },
    {
        "id": "9bfe8617-8630-7fc4-156f-2ef39582ce2c",
        "NOMBRE": "ALTERACIONES DE LA VISIÓN EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "998e83a0-382c-6b36-1daf-c8a321dcaf15",
        "NOMBRE": "ALTERACIONES DE LA VOZ"
    },
    {
        "id": "4b3f1f28-5f77-daac-a936-729671e17cee",
        "NOMBRE": "ALTERACIONES DEL EQUILIBRIO DEL POTASIO EN EL RECIÉN NACIDO"
    },
    {
        "id": "a734a681-de9e-0130-0363-8a14433d19dc",
        "NOMBRE": "ALTERACIONES DEL EQUILIBRIO DEL SODIO EN EL RECIÉN NACIDO"
    },
    {
        "id": "c9ff6fe0-a0e2-bc8f-6428-d8d19c7233a2",
        "NOMBRE": "ALTERACIONES DEL HABLA, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f2c9bb78-a471-b9f4-443f-e28eddfcc060",
        "NOMBRE": "ALTERACIONES EN LA ERUPCIÓN DENTARIA"
    },
    {
        "id": "86119975-1c78-a434-8172-0b5bb5f428f8",
        "NOMBRE": "ALTERACIONES EN LA FORMACIÓN DENTARIA"
    },
    {
        "id": "35d3b5d1-96f9-950a-bcc2-086132d6f439",
        "NOMBRE": "ALTERACIONES HEREDITARIAS DE LA ESTRUCTURA DENTARIA, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3016fc1c-f9bf-ef5f-7f61-df684d3434c2",
        "NOMBRE": "ALTERACIONES MORFOLÓGICAS CONGÉNITAS DEL PELO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f39b5dfd-df16-9335-daab-0b05ea58360a",
        "NOMBRE": "ALTERACIONES VISUALES SUBJETIVAS"
    },
    {
        "id": "003ab156-1dbb-7fdd-2c36-c684aeadc1a3",
        "NOMBRE": "ALUCINACIONES AUDITIVAS"
    },
    {
        "id": "f08cdfd8-b994-2baa-19fe-7c2741018162",
        "NOMBRE": "ALUCINACIONES VISUALES"
    },
    {
        "id": "94602329-27e5-0069-e37c-e14f42000e92",
        "NOMBRE": "ALUCINACIONES, NO ESPECIFICADAS"
    },
    {
        "id": "68bf5e31-efe2-e12c-2a46-45904342882f",
        "NOMBRE": "ALUCINOSIS ORGÁNICA"
    },
    {
        "id": "84ca733b-abc2-b177-f71d-84e25719d03d",
        "NOMBRE": "ALUMINOSIS (DEL PULMÓN)"
    },
    {
        "id": "719bfec2-2499-0ade-21f9-fade6d1098ee",
        "NOMBRE": "ALVEOLITIS DEL MAXILAR"
    },
    {
        "id": "559e14be-4a24-98e6-1cbe-b390555de686",
        "NOMBRE": "AMAUROSIS FUGAZ"
    },
    {
        "id": "80933170-1913-b5da-ec4b-e5eafe7f7b70",
        "NOMBRE": "AMBLIOPÍA EX ANOPSIA"
    },
    {
        "id": "b90c4ced-ceb5-fd8b-2d3a-9e9a86e54ce5",
        "NOMBRE": "AMEBIASIS"
    },
    {
        "id": "86a5d381-3d63-d427-2661-b87cfb7d708b",
        "NOMBRE": "AMEBIASIS CUTÁNEA"
    },
    {
        "id": "301701f3-71b8-8c83-8709-2f7333c3ab47",
        "NOMBRE": "AMEBIASIS INTESTINAL CRÓNICA"
    },
    {
        "id": "eda21591-82d5-f00f-3320-755fd9868b9f",
        "NOMBRE": "AMEBIASIS, NO ESPECIFICADA"
    },
    {
        "id": "ca10fede-d1c5-289c-086a-d8949df00a3a",
        "NOMBRE": "AMEBOMA INTESTINAL"
    },
    {
        "id": "c8575307-210e-1bcd-fb2c-6f06983e9cfa",
        "NOMBRE": "AMENAZA DE ABORTO"
    },
    {
        "id": "17aeab11-8c04-0d0c-ed28-3fad65aecc0c",
        "NOMBRE": "AMENORREA PRIMARIA"
    },
    {
        "id": "9167648d-0880-6b0e-483a-b42c8dd7c26d",
        "NOMBRE": "AMENORREA SECUNDARIA"
    },
    {
        "id": "677be2f2-ed3b-9ff7-6741-9c35879eea2b",
        "NOMBRE": "AMENORREA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "ab3d8743-145a-7897-d85d-c4f5e467c124",
        "NOMBRE": "AMIELIA"
    },
    {
        "id": "9b51eb1f-092b-92a3-44b0-af0e8ba55b6f",
        "NOMBRE": "AMIGDALITIS AGUDA"
    },
    {
        "id": "bb97b490-ce8d-e532-67d0-9ab945ef1fe6",
        "NOMBRE": "AMIGDALITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"
    },
    {
        "id": "f6bc7cc5-96c6-63ce-f462-c97df02bfb21",
        "NOMBRE": "AMIGDALITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "aff167c5-8d79-caf4-a824-d16ea3f54501",
        "NOMBRE": "AMIGDALITIS CRÓNICA"
    },
    {
        "id": "752320f9-b8a8-6cff-7ca0-4039d5e3dde1",
        "NOMBRE": "AMIGDALITIS ESTREPTOCÓCICA"
    },
    {
        "id": "397c4f18-5579-7f69-96cc-b673ac5bab4b",
        "NOMBRE": "AMILOIDOSIS"
    },
    {
        "id": "a4c1546a-1815-ab0e-b629-f842aff421fb",
        "NOMBRE": "AMILOIDOSIS DE LA PIEL"
    },
    {
        "id": "8eef5fef-2f72-713d-b2e4-bb818c255219",
        "NOMBRE": "AMILOIDOSIS HEREDOFAMILIAR NEUROPÁTICA"
    },
    {
        "id": "b83637b4-098f-dec4-bef1-596f11d1d5d0",
        "NOMBRE": "AMILOIDOSIS HEREDOFAMILIAR NO ESPECIFICADA"
    },
    {
        "id": "6d255959-4a8c-423b-1751-97986a659268",
        "NOMBRE": "AMILOIDOSIS HEREDOFAMILIAR NO NEUROPÁTICA"
    },
    {
        "id": "199f3f23-60f4-14c4-0339-4741ca06a1b6",
        "NOMBRE": "AMILOIDOSIS LIMITADA A UN ÓRGANO"
    },
    {
        "id": "acb3c5d3-ba39-0ac8-1570-24764b3f5257",
        "NOMBRE": "AMILOIDOSIS SISTÉMICA SECUNDARIA"
    },
    {
        "id": "39f9e3a9-5817-1f89-3e2c-c1d1a7a8414a",
        "NOMBRE": "AMILOIDOSIS, NO ESPECIFICADA"
    },
    {
        "id": "b67b5fed-2942-d683-d39a-25f109cbbc73",
        "NOMBRE": "AMIOTROFIA NEURÁLGICA"
    },
    {
        "id": "196d5009-1d4f-86b0-45a9-6722eb8ae39b",
        "NOMBRE": "AMNESIA ANTERÓGRADA"
    },
    {
        "id": "b802c06d-bcc0-093f-8fce-538909fd807d",
        "NOMBRE": "AMNESIA DISOCIATIVA"
    },
    {
        "id": "fb681ab0-575e-cb5d-bdc7-bf1374f091f9",
        "NOMBRE": "AMNESIA GLOBAL TRANSITORIA"
    },
    {
        "id": "a8a73c17-ad7d-a2a0-292f-a08ff970e3a4",
        "NOMBRE": "AMNESIA RETRÓGRADA"
    },
    {
        "id": "49829f17-7f00-02f5-426b-a3f89db8dc52",
        "NOMBRE": "AMPLITUD DE LAS SUTURAS CRANEALES DEL RECIÉN NACIDO"
    },
    {
        "id": "20d1e3db-2556-0529-99f2-a7f22f06f0e0",
        "NOMBRE": "AMPUTACIÓN DE MIEMBRO(S)"
    },
    {
        "id": "3018e458-2dd2-9251-6e7a-9535f1edfe33",
        "NOMBRE": "AMPUTACIÓN DEL PIE, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "b478942a-ba31-a2ab-f004-60c00dc3fe97",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA A NIVEL DE LA RODILLA"
    },
    {
        "id": "57cf4ed2-6e07-515d-128f-826bee6ac95b",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA A NIVEL DEL CODO"
    },
    {
        "id": "fb4fed7b-d101-bd4c-5880-40c6f5aaa3a6",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA A NIVEL DEL CUELLO"
    },
    {
        "id": "4429149a-6aa2-450c-9a47-eaa72906ed62",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA A NIVEL ENTRE EL HOMBRO Y EL CODO"
    },
    {
        "id": "0160bc41-216e-159f-2140-9678b809fdfa",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA COMBINADA (DE PARTE) DE DEDO(S) CON OTRAS PARTES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "6e02444a-58a5-1479-7b01-182753f276b6",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE AMBAS MANOS"
    },
    {
        "id": "fd85f97f-beee-a5df-7e55-464d0132836b",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE AMBAS PIERNAS [CUALQUIER NIVEL]"
    },
    {
        "id": "c1f3ad08-379f-9fcb-b0fe-29bd43f2defb",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE AMBOS BRAZOS [CUALQUIER NIVEL]"
    },
    {
        "id": "c32e3246-cc1e-7768-7f54-ce20f4bbf905",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE AMBOS PIES"
    },
    {
        "id": "3f68e8f4-34c1-bccd-409f-d271dba2660f",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE CADERA Y MUSLO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "9b6feb63-5293-699e-1a48-8256564fea43",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE DOS A MÁS DEDOS DEL PIE"
    },
    {
        "id": "71c6cd3a-ad8d-ee45-8c09-5cb66e4c5996",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE DOS O MÁS DEDOS SOLAMENTE (COMPLETA) (PARCIAL)"
    },
    {
        "id": "bfb117a9-3fd8-ff40-1d49-a888ce827ebb",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA ARTICULACIÓN DE LA CADERA"
    },
    {
        "id": "b3596f74-0830-888c-ed47-6b8a2e5e6f6b",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "71c7fb81-4111-01b7-e83e-2514d57caa98",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA MANO A NIVEL DE LA MUÑECA"
    },
    {
        "id": "916fe092-c6f4-015b-48cb-0454352af89e",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "6339a47a-b16f-9218-1823-4338778dfd16",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA MUÑECA Y DE LA MANO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "f0e125c6-195a-d349-433e-4b0831a950a7",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA OREJA"
    },
    {
        "id": "a45cc6f0-57b2-4e6d-4105-a66a8bf0a502",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA PIERNA"
    },
    {
        "id": "3b38219e-cc71-c853-eb04-8280dc3efe9f",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE LA PIERNA, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "3f5e921f-529c-60da-9b8d-67023dcb5e61",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "0c1d78fd-c202-3a5d-16c1-439d5dab174b",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "4e5e5ee3-d24e-64c5-25cb-cdc51201fb16",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE MIEMBROS SUPERIOR(ES) E INFERIOR(ES), CUALQUIER COMBINACIÓN [CUALQUIER NIVEL]"
    },
    {
        "id": "3aca3829-bba5-4982-f953-40c453ae3c2e",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE ÓRGANOS GENITALES EXTERNOS"
    },
    {
        "id": "e904a20d-9d0d-8b81-f87a-526d933ad466",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE OTRAS PARTES DE LA CABEZA"
    },
    {
        "id": "d3a11015-5465-8851-9534-14aeeefd7471",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE OTRAS PARTES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "3504a1a0-228f-7913-41bd-3693f6a5ad2b",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE OTRAS PARTES DEL PIE"
    },
    {
        "id": "c414e678-0fc9-65c5-4ac0-d4f10654f44c",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN, REGIÓN LUMBOSACRA Y PELVIS"
    },
    {
        "id": "ac876233-56ca-1f61-b601-67253f818334",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE OTRO DEDO ÚNICO (COMPLETA) (PARCIAL)"
    },
    {
        "id": "b781dbdb-322b-300f-9000-223785e9e06a",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE PARTE DE LA CABEZA"
    },
    {
        "id": "1fe8b3f0-4bbe-230f-9f50-948b76bec591",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE PARTE DEL TÓRAX"
    },
    {
        "id": "4331dc61-8ba1-9ed8-a7e4-ea63b094f0a4",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE PARTE NO ESPECIFICADA DE LA CABEZA"
    },
    {
        "id": "015cf21c-13e3-a80c-44bf-1cf35a0bfb68",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE UN DEDO DEL PIE"
    },
    {
        "id": "0c3944f0-50bb-c74f-7ee6-60394787d80a",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE UN PIE Y LA OTRA PIERNA [CUALQUIER NIVEL, EXCEPTO PIE]"
    },
    {
        "id": "bad537fe-088f-2929-3c60-f45b71cb2167",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DE UNA MANO Y EL OTRO BRAZO [CUALQUIER NIVEL, EXCEPTO MANO]"
    },
    {
        "id": "1753d5cb-9b3d-407a-8514-842b7b9cf35d",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL ANTEBRAZO"
    },
    {
        "id": "bda96069-e9ca-9bc4-8f1a-d110023f5044",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL ANTEBRAZO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "f1a241fc-2557-7545-6443-1db81a4bb96d",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "d7eab8c4-9fba-b4d5-c348-99c32fd2d643",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL HOMBRO Y DEL BRAZO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "cf93dbdf-6a40-42a5-764c-ba701f98a448",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL PIE A NIVEL DEL TOBILLO"
    },
    {
        "id": "1d4a97cf-fa22-7a76-4590-e7c9548ebe27",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "be262284-4e03-45ba-2b8a-18cb242164d6",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL PULGAR (COMPLETA) (PARCIAL)"
    },
    {
        "id": "46a7d56a-bb19-9d3a-6bb0-cb95c306f504",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA DEL TRONCO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "45c874ad-fda1-d6c3-e1da-0812227abbcb",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA EN ALGÚN NIVEL ENTRE LA CADERA Y LA RODILLA"
    },
    {
        "id": "8a175a05-4168-e7f8-da05-f5f782af8006",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA EN ALGÚN NIVEL ENTRE LA RODILLA Y EL TOBILLO"
    },
    {
        "id": "70655dcd-4899-a71e-cd0d-842bb7353c42",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA EN LA ARTICULACIÓN DEL HOMBRO"
    },
    {
        "id": "9cfcc7a0-f5b9-24ef-3381-a7872c253ff2",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA NIVEL ENTRE EL CODO Y LA MUÑECA"
    },
    {
        "id": "0584d245-9f99-a46f-e040-1fa38bbcb1d2",
        "NOMBRE": "AMPUTACIÓN TRAUMÁTICA QUE AFECTA OTRAS COMBINACIONES DE REGIONES DEL CUERPO"
    },
    {
        "id": "919f95b5-b49d-4459-f51c-eb066f5288fe",
        "NOMBRE": "AMPUTACIONES TRAUMÁTICAS MÚLTIPLES, NO ESPECIFICADAS"
    },
    {
        "id": "197449d7-9357-a8e9-9b2b-a70d15307988",
        "NOMBRE": "AMPUTACIONES TRAUMÁTICAS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "78065845-5739-f070-20f7-9bb3ae4abf45",
        "NOMBRE": "ANEMIA APLÁSTICA CONSTITUCIONAL"
    },
    {
        "id": "776bd253-5eb5-84ea-dfd2-2ad40dd2da12",
        "NOMBRE": "ANEMIA APLÁSTICA DEBIDA A OTROS AGENTES EXTERNOS"
    },
    {
        "id": "730df3eb-0e73-8757-6c6f-b90e460df50e",
        "NOMBRE": "ANEMIA APLÁSTICA IDIOPÁTICA"
    },
    {
        "id": "e3c51542-8ac8-6e1e-d84f-f07ae2e4f78a",
        "NOMBRE": "ANEMIA APLÁSTICA INDUCIDA POR DROGAS"
    },
    {
        "id": "c9c50b35-270a-1d6c-5be3-cd1f96bb41ef",
        "NOMBRE": "ANEMIA APLÁSTICA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c4ed9178-3872-2a81-ce3d-920545d16afe",
        "NOMBRE": "ANEMIA CONGÉNITA DEBIDA A PÉRDIDA DE SANGRE FETAL"
    },
    {
        "id": "3b5975ae-ed94-fc54-2f76-7e4269877a18",
        "NOMBRE": "ANEMIA DE LA PREMATURIDAD"
    },
    {
        "id": "44af2243-ed9b-fb06-3884-29aa76fffea0",
        "NOMBRE": "ANEMIA DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "0775fae8-b597-0d5b-c221-fa49dd11e53a",
        "NOMBRE": "ANEMIA DEBIDA A DEFICIENCIA DE GLUCOSA-6-FOSFATO DESHIDROGENASA [G6FD]"
    },
    {
        "id": "9cf22ac1-0e15-2661-0fc7-77666912f566",
        "NOMBRE": "ANEMIA DEBIDA A OTROS TRASTORNOS DEL METABOLISMO DEL GLUTATIÓN"
    },
    {
        "id": "5f29db0e-1816-a46a-1590-2b1b11deb09e",
        "NOMBRE": "ANEMIA DEBIDA A TRASTORNOS DE LAS ENZIMAS GLUCOLÍTICAS"
    },
    {
        "id": "c6ab17a9-77a9-c929-3680-de119ced028b",
        "NOMBRE": "ANEMIA DEBIDA A TRASTORNOS DEL METABOLISMO DE LOS NUCLEÓTIDOS"
    },
    {
        "id": "5b5eca59-9d94-1ab1-d840-aab1968c129a",
        "NOMBRE": "ANEMIA DEBIDA A TRASTORNOS ENZIMÁTICOS"
    },
    {
        "id": "5ee03585-696a-98a0-10a8-0e9557de5567",
        "NOMBRE": "ANEMIA DEBIDA A TRASTORNOS ENZIMÁTICOS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "9e70126c-d1d5-d8f2-baa9-9feda7d872a9",
        "NOMBRE": "ANEMIA DISERITROPOYÉTICA CONGÉNITA"
    },
    {
        "id": "0bdf0c7b-e478-fbd3-805c-1668c0b59413",
        "NOMBRE": "ANEMIA EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "64891d6d-e170-0e6a-9b7a-b4805db6e636",
        "NOMBRE": "ANEMIA EN ENFERMEDADES CRÓNICAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d422575e-3f62-daf9-d1cf-203795774389",
        "NOMBRE": "ANEMIA EN OTRAS ENFERMEDADES CRÓNICAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7edd85b5-407e-3d5b-376e-7ab1ff561a38",
        "NOMBRE": "ANEMIA ESCORBÚTICA"
    },
    {
        "id": "828528b0-c00d-a988-126b-6a36340eada1",
        "NOMBRE": "ANEMIA FALCIFORME CON CRISIS"
    },
    {
        "id": "4ebf53e8-0984-df44-3b02-2a1b255f3321",
        "NOMBRE": "ANEMIA FALCIFORME SIN CRISIS"
    },
    {
        "id": "8180235e-fc1b-39e9-1af6-01e56071980b",
        "NOMBRE": "ANEMIA HEMOLÍTICA ADQUIRIDA"
    },
    {
        "id": "83407d98-811a-9e1d-349f-78beff71e21d",
        "NOMBRE": "ANEMIA HEMOLÍTICA ADQUIRIDA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "90df0a52-064f-3dab-c1f1-77c41ade64b8",
        "NOMBRE": "ANEMIA HEMOLÍTICA AUTOINMUNE INDUCIDA POR DROGAS"
    },
    {
        "id": "2fa99d9e-bf86-af01-2675-064b87cd9537",
        "NOMBRE": "ANEMIA HEMOLÍTICA HEREDITARIA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "e39e5115-a4c8-e293-9637-bb3b7c44ec3c",
        "NOMBRE": "ANEMIA HEMOLÍTICA NO AUTOINMUNE INDUCIDA POR DROGAS"
    },
    {
        "id": "e28caeee-0a94-ad83-03e4-2808b74c1031",
        "NOMBRE": "ANEMIA NUTRICIONAL, NO ESPECIFICADA"
    },
    {
        "id": "bf936256-88bb-ae14-44a9-5f6c9af1ffc1",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE FOLATOS"
    },
    {
        "id": "28a6b7ec-03ce-970b-6ebd-7b9b67ca9783",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE FOLATOS INDUCIDA POR DROGAS"
    },
    {
        "id": "78a4c3e3-10b8-f223-dac3-73b72913e56c",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE FOLATOS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "db6ddacd-d6e9-51b0-4950-955e8cd0e98d",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE HIERRO SECUNDARIA A PÉRDIDA DE SANGRE (CRÓNICA)"
    },
    {
        "id": "1138b5ae-6e72-dad3-2418-d9f5d6e23b72",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE HIERRO SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "9eaf93c1-11ca-bb48-dd4b-6a92cda86c62",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE PROTEÍNAS"
    },
    {
        "id": "4372fdea-f04f-5c23-3a46-c74aa0e5b161",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE VITAMINA B12"
    },
    {
        "id": "65642424-789f-fdbc-3372-77a01764fa90",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE VITAMINA B12 DEBIDA A DEFICIENCIA DEL FACTOR INTRÍNSECO"
    },
    {
        "id": "bed7be70-4bd3-76fe-5eac-fe8f505908f5",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE VITAMINA B12 DEBIDA A MALA ABSORCIÓN SELECTIVA DE VITAMINA B12 CON PROTEINURIA"
    },
    {
        "id": "a627c9c7-77e2-d32d-cc10-ebfd21a7c8b6",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DE VITAMINA B12, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c6d7a6e3-9a05-3384-60bf-10665789f652",
        "NOMBRE": "ANEMIA POR DEFICIENCIA DIETÉTICA DE FOLATOS"
    },
    {
        "id": "47749e92-cdfd-b71f-8441-735ccb55b501",
        "NOMBRE": "ANEMIA POSTHEMORRÁGICA AGUDA"
    },
    {
        "id": "8cf6c3a7-4115-1cac-ea93-c8a7d7bb7841",
        "NOMBRE": "ANEMIA QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "bb91cb39-e521-09dc-5a21-7d2c8a8965d7",
        "NOMBRE": "ANEMIA REFRACTARIA CON DISPLASIA MULTILINAJE"
    },
    {
        "id": "3a1f8637-2a4c-de31-e85f-805f97e9cbc3",
        "NOMBRE": "ANEMIA REFRACTARIA CON EXCESO DE BLASTOS [AREB]"
    },
    {
        "id": "8afe1eba-cfb3-73df-bc14-0053a92d7a31",
        "NOMBRE": "ANEMIA REFRACTARIA CON EXCESO DE BLASTOS CON TRANSFORMACIÓN"
    },
    {
        "id": "649c53ea-6c86-c0d3-fba8-acf80d628624",
        "NOMBRE": "ANEMIA REFRACTARIA CON SIDEROBLASTOS EN FORMA DE ANILLO"
    },
    {
        "id": "604ac159-d644-9b09-d05d-6eb08eb60b53",
        "NOMBRE": "ANEMIA REFRACTARIA SIN SIDEROBLASTOS EN FORMA DE ANILLO, ASÍ DESCRITA"
    },
    {
        "id": "d042d135-17a5-bb99-5fb2-5ca42581d6e9",
        "NOMBRE": "ANEMIA REFRACTARIA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "ef2ad53c-b974-dff1-5b2c-797b0480b178",
        "NOMBRE": "ANEMIA SIDEROBLÁSTICA HEREDITARIA"
    },
    {
        "id": "2918188f-7a75-6429-1030-708358caf87b",
        "NOMBRE": "ANEMIA SIDEROBLÁSTICA SECUNDARIA A OTRA ENFERMEDAD"
    },
    {
        "id": "709b3cd1-4487-231b-0f5c-0ac128a5e2de",
        "NOMBRE": "ANEMIA SIDEROBLÁSTICA SECUNDARIA, DEBIDA A DROGAS Y TOXINAS"
    },
    {
        "id": "a53b5bb8-f053-4c09-ea87-9f15872a893f",
        "NOMBRE": "ANEMIAS POR DEFICIENCIA DE HIERRO"
    },
    {
        "id": "b821a0b1-2bb9-0747-c72a-68d0a8ac0992",
        "NOMBRE": "ANENCEFALIA"
    },
    {
        "id": "b6a883fd-06fa-040c-63a6-2d9069bedd2c",
        "NOMBRE": "ANENCEFALIA Y MALFORMACIONES CONGÉNITAS SIMILARES"
    },
    {
        "id": "e4d9a20f-ca0d-0922-0097-938a66268154",
        "NOMBRE": "ANESTESIA DE LA PIEL"
    },
    {
        "id": "e2664057-4ad7-8000-202f-de10c1e2cca0",
        "NOMBRE": "ANESTESIA DISOCIATIVA Y PÉRDIDA SENSORIAL"
    },
    {
        "id": "950efb90-7477-e38e-e067-73c1f0e09aa8",
        "NOMBRE": "ANETODERMIA DE JADASSOHN-PELLIZZARI"
    },
    {
        "id": "4fe3b869-4e46-6221-a62b-6f3edb390f4d",
        "NOMBRE": "ANETODERMIA DE SCHWENINGER-BUZZI"
    },
    {
        "id": "f7561b1c-d8b8-403a-5190-2334eae46091",
        "NOMBRE": "ANEURISMA CARDÍACO"
    },
    {
        "id": "7edbc3d1-a5e5-a02c-27cf-5906b4cd9da4",
        "NOMBRE": "ANEURISMA CEREBRAL, SIN RUPTURA"
    },
    {
        "id": "fd86b7e9-6240-e28c-3e66-56ae859370d2",
        "NOMBRE": "ANEURISMA DE ARTERIA CORONARIA"
    },
    {
        "id": "65725136-7bbb-da77-5e79-f8823cd9d90f",
        "NOMBRE": "ANEURISMA DE LA AORTA ABDOMINAL, SIN MENCIÓN DE RUPTURA"
    },
    {
        "id": "4579078a-2fb4-d6ba-c7e7-a1bca2e4695d",
        "NOMBRE": "ANEURISMA DE LA AORTA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "67234957-6f3d-00ce-0462-e61feee74773",
        "NOMBRE": "ANEURISMA DE LA AORTA TORÁCICA, SIN MENCIÓN DE RUPTURA"
    },
    {
        "id": "1b8c3ec0-a1e5-7635-ee50-7c742852644c",
        "NOMBRE": "ANEURISMA DE LA AORTA TORACOABDOMINAL, SIN MENCIÓN DE RUPTURA"
    },
    {
        "id": "6c2cc98b-2d51-57cd-c213-220824a7e299",
        "NOMBRE": "ANEURISMA DE LA AORTA, SITIO NO ESPECIFICADO, SIN MENCIÓN DE RUPTURA"
    },
    {
        "id": "a81a1be7-2fcd-f287-9207-30478710263a",
        "NOMBRE": "ANEURISMA DE LA ARTERIA PULMONAR"
    },
    {
        "id": "8caf08b7-00b3-0ac4-1dfc-aabbfea58379",
        "NOMBRE": "ANEURISMA Y DISECCIÓN AÓRTICOS"
    },
    {
        "id": "a7f7a2de-6f53-506b-6981-dea0602cf683",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE ARTERIA DEL MIEMBRO INFERIOR"
    },
    {
        "id": "a2856b3b-e9bd-9a49-d5f7-3dce63fb27af",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE ARTERIA DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "3b3934c2-3504-f2cb-cec8-02b3ffcb09f9",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE ARTERIA ILÍACA"
    },
    {
        "id": "0e491797-e14a-333d-7a88-cda6be019730",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE ARTERIA RENAL"
    },
    {
        "id": "3287aa1b-41ed-024c-3b2b-c0c106ec934d",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE ARTERIA VERTEBRAL"
    },
    {
        "id": "c264e939-80fa-1e80-aaf5-ae2da0f78812",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE LA ARTERIA CARÓTIDA"
    },
    {
        "id": "eb83df8c-8b7d-adf4-81be-5f1a4deeb9d3",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE OTRAS ARTERIAS ESPECIFICADAS"
    },
    {
        "id": "91eca95b-9f5c-114a-6320-baab6403c91a",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE OTRAS ARTERIAS PRECEREBRALES"
    },
    {
        "id": "899e802a-f1b3-adb9-f322-926c5b4633af",
        "NOMBRE": "ANEURISMA Y DISECCIÓN DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "781444ca-a0d9-e50e-3012-f01138a5ef99",
        "NOMBRE": "ANGIÍTIS DEBIDA A HIPERSENSIBILIDAD"
    },
    {
        "id": "71a9c604-35a9-56da-f5da-6805ce4d0378",
        "NOMBRE": "ANGINA DE PECHO"
    },
    {
        "id": "e97b3639-57bf-acad-7824-76829087fb31",
        "NOMBRE": "ANGINA DE PECHO CON ESPASMO DOCUMENTADO"
    },
    {
        "id": "aedb6ee7-9528-f14b-62be-34463268013b",
        "NOMBRE": "ANGINA DE PECHO, NO ESPECIFICADA"
    },
    {
        "id": "2c2916df-6a9b-6535-d029-fd5d91c5559f",
        "NOMBRE": "ANGINA INESTABLE"
    },
    {
        "id": "184987e8-2011-5b8a-10fc-5635772e9baa",
        "NOMBRE": "ANGIODISPLASIA DEL COLON"
    },
    {
        "id": "14aa119e-8d67-121b-2fa2-bf46b1169023",
        "NOMBRE": "ANGIOESTRONGILIASIS DEBIDA A PARASTRONGYLUS CANTONENSIS"
    },
    {
        "id": "c16843bc-100e-834f-d0f8-c811940686d4",
        "NOMBRE": "ANGIOESTRONGILIASIS INTESTINAL"
    },
    {
        "id": "43d5eb53-40b0-3964-faba-6740dc8cff03",
        "NOMBRE": "ANGIOPATÍA CEREBRAL AMILOIDE"
    },
    {
        "id": "eafe3473-43e8-adab-b4c5-9b4910e78f58",
        "NOMBRE": "ANGIOPATÍA PERIFÉRICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "39a23f90-32e5-fbd8-b337-0499eec3c12a",
        "NOMBRE": "ANGIOSARCOMA DEL HÍGADO"
    },
    {
        "id": "df5c83b3-b2cd-85ce-30f4-ae15b8d03d66",
        "NOMBRE": "ANHIDROSIS"
    },
    {
        "id": "5ac324bc-c238-6725-497c-3f27cded3326",
        "NOMBRE": "ANILLO DE HIMEN ESTRECHO"
    },
    {
        "id": "d2d1cbbc-029e-a2f0-ab70-3f42026bba64",
        "NOMBRE": "ANISAQUIASIS"
    },
    {
        "id": "20d15167-cd6e-c343-cc1a-2c6bc2c8c4a2",
        "NOMBRE": "ANISOMETROPÍA Y ANISEICONÍA"
    },
    {
        "id": "b6a63089-e8a3-2cb1-c84c-0e589f19e4f1",
        "NOMBRE": "ANO ECTÓPICO"
    },
    {
        "id": "76b49a09-887b-3b5c-81b1-d513a0b17aae",
        "NOMBRE": "ANODONCIA"
    },
    {
        "id": "bfd0cebb-37f6-e0ab-25ed-82b87e2c959d",
        "NOMBRE": "ANOFTALMÍA, MICROFTALMÍA Y MACROFTALMÍA"
    },
    {
        "id": "5c468623-2812-0012-50e7-4bf2504d44d7",
        "NOMBRE": "ANOMALÍA CROMOSÓMICA, NO ESPECIFICADA"
    },
    {
        "id": "89891f50-939a-a370-02c1-1fe66f1ea810",
        "NOMBRE": "ANOMALÍA DE EBSTEIN"
    },
    {
        "id": "9c9861a3-b601-933d-c467-3639a3c2dbb6",
        "NOMBRE": "ANOMALÍA DE LA PLEURA"
    },
    {
        "id": "c3a4f870-fe55-b7b0-00ed-dc80cc29cba5",
        "NOMBRE": "ANOMALÍA DE LA POSICIÓN DE LA OREJA"
    },
    {
        "id": "8e3f5a97-b5a4-eea3-e591-1719e7d6612b",
        "NOMBRE": "ANOMALÍA DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "08a91010-ce52-0816-681b-956329f318b1",
        "NOMBRE": "ANOMALÍA DE LOS CROMOSOMAS SEXUALES, FENOTIPO MASCULINO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "45f11acb-07f3-1670-1e3c-35f93825844c",
        "NOMBRE": "ANOMALÍA DENTOFACIAL, NO ESPECIFICADA"
    },
    {
        "id": "376d2bbf-cb62-a3d8-69ee-055e41bd517c",
        "NOMBRE": "ANOMALÍA DINÁMICA DEL TRABAJO DE PARTO, NO ESPECIFICADA"
    },
    {
        "id": "89075dac-a245-d52d-f46a-c4ef46f64cfc",
        "NOMBRE": "ANOMALÍAS DE LA FUNCIÓN PUPILAR"
    },
    {
        "id": "d8dca977-4700-3192-a045-cb08a96c2348",
        "NOMBRE": "ANOMALÍAS DE LA FUNCIÓN PUPILAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "12a34669-10ec-bd2d-2f58-54f843558a05",
        "NOMBRE": "ANOMALÍAS DE LA POSICIÓN DEL DIENTE"
    },
    {
        "id": "84a2bb2e-0a15-92dd-bbdb-97ecd79bf81f",
        "NOMBRE": "ANOMALÍAS DE LA RELACIÓN ENTRE LOS ARCOS DENTARIOS"
    },
    {
        "id": "f0c5306e-e1fe-5ca3-7abb-7e2558fec9c5",
        "NOMBRE": "ANOMALÍAS DE LA RELACIÓN MAXILOBASILAR"
    },
    {
        "id": "783ef789-70b3-e400-16ba-b0845d9caafd",
        "NOMBRE": "ANOMALÍAS DEL TAMAÑO Y DE LA FORMA DEL DIENTE"
    },
    {
        "id": "b2fa4400-e8e3-83ff-c562-c142c2723095",
        "NOMBRE": "ANOMALÍAS DENTOFACIALES (INCLUSO LA MALOCLUSIÓN)"
    },
    {
        "id": "d2f33b86-defa-8494-fc70-b89e15bccb6a",
        "NOMBRE": "ANOMALÍAS DENTOFACIALES FUNCIONALES"
    },
    {
        "id": "56a78105-5fb8-9b54-6b7e-5a3f15f4b6ee",
        "NOMBRE": "ANOMALÍAS EVIDENTES DEL TAMAÑO DE LOS MAXILARES"
    },
    {
        "id": "cd6f9b28-dc01-3b2b-c01d-5ab8ca6469b8",
        "NOMBRE": "ANOMALÍAS GENÉTICAS DE LOS LEUCOCITOS"
    },
    {
        "id": "7c1b0730-d935-9865-7dc8-cbb4ea5ee60e",
        "NOMBRE": "ANONIQUIA"
    },
    {
        "id": "b39111d3-48eb-af59-7c03-405b082c0816",
        "NOMBRE": "ANOREXIA"
    },
    {
        "id": "6bd2448a-089a-b86b-632a-89e8045b7e7d",
        "NOMBRE": "ANOREXIA NERVIOSA"
    },
    {
        "id": "5a476014-cf20-7e1b-3f06-996f73dded4d",
        "NOMBRE": "ANOREXIA NERVIOSA ATÍPICA"
    },
    {
        "id": "c6dee507-0cad-26f5-45ae-d4949e74deba",
        "NOMBRE": "ANORMALIDAD DE LA ALBÚMINA"
    },
    {
        "id": "45c6a481-2d8c-ff5e-be19-a3e2b5ff05f3",
        "NOMBRE": "ANORMALIDAD DE LA ALFAFETOPROTEÍNA"
    },
    {
        "id": "e1d265c9-1d09-532d-785f-3eb11ab4f237",
        "NOMBRE": "ANORMALIDAD DE LA GLOBULINA"
    },
    {
        "id": "1c00d241-189d-12da-6b7f-fcc629bdae91",
        "NOMBRE": "ANORMALIDAD DE LOS ERITROCITOS"
    },
    {
        "id": "ba145c6d-a39c-5dea-85a9-4aa81833c807",
        "NOMBRE": "ANORMALIDAD NO ESPECIFICADA DEL TALLO Y DEL COLOR DEL PELO"
    },
    {
        "id": "ce5df122-4326-8b47-ef62-99ebad23dd58",
        "NOMBRE": "ANORMALIDADES DE LA DINÁMICA DEL TRABAJO DE PARTO"
    },
    {
        "id": "5aad632e-d3a5-f521-808d-883f4598bbf7",
        "NOMBRE": "ANORMALIDADES DE LA MARCHA Y DE LA MOVILIDAD"
    },
    {
        "id": "d64ddabc-7c24-0d4d-d12d-7df22470405e",
        "NOMBRE": "ANORMALIDADES DE LA RESPIRACIÓN"
    },
    {
        "id": "2a3dad7d-6390-8d4d-ec95-e6ab4fe45e93",
        "NOMBRE": "ANORMALIDADES DE LOS LEUCOCITOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9293f056-31f1-2531-aaa2-e5b7a3466b81",
        "NOMBRE": "ANORMALIDADES DEL LATIDO CARDÍACO"
    },
    {
        "id": "aa0947d8-04ac-a9b3-9da6-a98c3b476d52",
        "NOMBRE": "ANORMALIDADES DEL TALLO Y DEL COLOR DEL PELO"
    },
    {
        "id": "f48791dd-43f4-4aa7-fe02-417d7e734aa3",
        "NOMBRE": "ANORMALIDADES EN LA PRUEBA DE TOLERANCIA A LA GLUCOSA"
    },
    {
        "id": "a3cc8d68-767a-7639-9186-b6a05071af61",
        "NOMBRE": "ANORMALIDADES NO ESPECIFICADAS DE LAS PROTEÍNAS PLASMÁTICAS"
    },
    {
        "id": "f01eedc4-e253-6b5f-ec97-689849be3811",
        "NOMBRE": "ANOSMIA"
    },
    {
        "id": "6fab5eec-092d-3132-ed37-a76ebec81089",
        "NOMBRE": "ANQUILOGLOSIA"
    },
    {
        "id": "13ce247c-e344-fb49-8ab7-4f99bd243e6d",
        "NOMBRE": "ANQUILOSIS ARTICULAR"
    },
    {
        "id": "d83ab204-1cd3-df0a-26b0-20d84dbbb6f9",
        "NOMBRE": "ANQUILOSIS DENTAL"
    },
    {
        "id": "9932426d-2718-9b14-d290-356a370a8f88",
        "NOMBRE": "ANQUILOSTOMIASIS"
    },
    {
        "id": "8deda20d-06b2-fb14-1a03-db280c1b34fd",
        "NOMBRE": "ANQUILOSTOMIASIS Y NECATORIASIS"
    },
    {
        "id": "ea42b41b-15d1-57c2-6171-667feb0a1746",
        "NOMBRE": "ANURIA TRAUMÁTICA"
    },
    {
        "id": "a39053de-e20b-b797-424d-efdcdfc4306e",
        "NOMBRE": "ANURIA Y OLIGURIA"
    },
    {
        "id": "1fd93313-0c7f-7d40-3691-f3ca340b0fa6",
        "NOMBRE": "AORTITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "67fb3552-1e2c-3bf5-5a73-3f204a50f761",
        "NOMBRE": "APARATOS DE MEDICINA FÍSICA ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "588f7a68-75ca-3bbd-1a01-204c4432f283",
        "NOMBRE": "APARATOS DE MEDICINA FÍSICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "33c51cde-527c-e922-183a-145c4631deb8",
        "NOMBRE": "APARATOS DE MEDICINA FÍSICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "7ccc80ea-1429-ed1a-2819-7a02f3e54fd3",
        "NOMBRE": "APARATOS DE MEDICINA FÍSICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "bd32a23e-4452-8cee-c425-d4ccb5ac412a",
        "NOMBRE": "APARATOS DE MEDICINA FÍSICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "0dbf11e7-6120-fa11-3474-6236bca66d63",
        "NOMBRE": "APARATOS DE MEDICINA FÍSICA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "eae67831-b3e5-db17-5258-3b5356b94f2b",
        "NOMBRE": "APARATOS RADIOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "20a46299-a814-676f-68af-2625a0361fdf",
        "NOMBRE": "APARATOS RADIOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "579aaa2e-ab19-17ca-3d2d-836963e97f87",
        "NOMBRE": "APARATOS RADIOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "c6cded6d-ddd6-7593-6d4e-c97434612c5b",
        "NOMBRE": "APARATOS RADIOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "e97dc2af-9968-1d76-7482-614d4966dc72",
        "NOMBRE": "APARATOS RADIOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "6a092c1a-c977-dd7c-a9e0-c8072f6c607b",
        "NOMBRE": "APARATOS RADIOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "650b377b-f52d-20c2-d0dc-89dc0b1d16b3",
        "NOMBRE": "APARIENCIA PERSONAL EXTRAÑA"
    },
    {
        "id": "4e9e4377-90a3-246f-5fa4-634c9ccca5a5",
        "NOMBRE": "APENDICITIS AGUDA"
    },
    {
        "id": "7d6c57f0-f93b-42cd-bee6-9b2db9ee4113",
        "NOMBRE": "APENDICITIS AGUDA CON ABSCESO PERITONEAL"
    },
    {
        "id": "db3c3b73-4840-99f1-487a-ddc1ae53b10d",
        "NOMBRE": "APENDICITIS AGUDA CON PERITONITIS GENERALIZADA"
    },
    {
        "id": "f5d04ee5-07dd-d2f8-798c-f02f299a7b91",
        "NOMBRE": "APENDICITIS AGUDA CON PERITONITIS GENERALIZADA"
    },
    {
        "id": "a4e94c6b-f173-768c-6d9a-6f66ac3c3ea0",
        "NOMBRE": "APENDICITIS AGUDA CON PERITONITIS LOCALIZADA"
    },
    {
        "id": "ffe24bc8-d3df-9999-20f0-14ec3d95ea82",
        "NOMBRE": "APENDICITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "8b7df4ee-adb5-a664-2f54-45641ab11c2c",
        "NOMBRE": "APENDICITIS, NO ESPECIFICADA"
    },
    {
        "id": "588fae7c-8435-dfe1-98bd-6cb00fd9d11e",
        "NOMBRE": "APLASIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA [ERITROBLASTOPENIA]"
    },
    {
        "id": "2cb994fc-4efd-e286-f81a-7e0e9abcb397",
        "NOMBRE": "APLASIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA, NO ESPECIFICADA"
    },
    {
        "id": "4f23b553-6078-d589-380e-a967f9cd5c85",
        "NOMBRE": "APLASIA CRÓNICA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA"
    },
    {
        "id": "64ae6ba1-012b-e9d4-edb6-74e9bbff47cf",
        "NOMBRE": "APLASIA TRANSITORIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA"
    },
    {
        "id": "9aaf31c9-0648-2212-cc75-f68afbb91b0a",
        "NOMBRE": "APLASIA Y AUSENCIA CONGÉNITA DEL PENE"
    },
    {
        "id": "0bcff848-7733-bae8-b8b1-9e04efe864da",
        "NOMBRE": "APLASTAMIENTO DEL TÓRAX"
    },
    {
        "id": "2b2d0eda-8015-9f71-dd1e-aa48d50d4351",
        "NOMBRE": "APNEA DEL SUEÑO"
    },
    {
        "id": "014da086-9051-e6e3-738d-bfbef2e9af3e",
        "NOMBRE": "APNEA PRIMARIA DEL SUEÑO DEL RECIÉN NACIDO"
    },
    {
        "id": "834f40ca-5c0d-2dd5-5066-43a6f14ad0a7",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA"
    },
    {
        "id": "e1e578a9-ee6f-d3e0-7fd9-dcf127af5745",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0ab99d52-a96b-6daf-d48a-e3ddd96446fa",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "961d54c8-b31b-cacf-ca1b-0ee0ec6d0c9a",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, CALLES Y CARRETERAS"
    },
    {
        "id": "e0613d4f-2967-70fa-65be-762d60120e50",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "d9c923f0-f8b6-18e6-f661-6a8f51bc3433",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "152599ce-1547-4bfb-e1ad-eacceba003a0",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, GRANJA"
    },
    {
        "id": "172f8497-d37d-5f46-d18e-5daf83fda761",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7c90085b-b09d-0e50-2c9f-3a675b53fbe8",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "36d4fae3-9c3b-c7b3-7103-60809dcb3f91",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d09c4baf-f3a4-ca02-82de-b406ae0e846c",
        "NOMBRE": "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA, VIVIENDA"
    },
    {
        "id": "a7adde4a-bf29-8008-51ec-8df35840bc45",
        "NOMBRE": "APRAXIA"
    },
    {
        "id": "6e7540f1-65ec-807d-3ea9-6a3eb5bdac55",
        "NOMBRE": "ARRINENCEFALIA"
    },
    {
        "id": "73d93d62-bdc0-e679-6746-ec808d4b5769",
        "NOMBRE": "ARRITMIA CARDÍACA, NO ESPECIFICADA"
    },
    {
        "id": "08ae8131-65df-9566-d594-ba9b33b3f9b6",
        "NOMBRE": "ARRITMIA POR REENTRADA VENTRICULAR"
    },
    {
        "id": "66f40fa9-1593-34b9-d6c5-3a3e038b210f",
        "NOMBRE": "ARTERITIS CEREBRAL EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6575d9b1-e925-61c2-b3cd-7abebf5c949e",
        "NOMBRE": "ARTERITIS CEREBRAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e4846c28-0d02-359f-6899-7a3d68ff5238",
        "NOMBRE": "ARTERITIS CEREBRAL, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "eb975279-e1ee-929d-abff-114df0aab910",
        "NOMBRE": "ARTERITIS DE CÉLULAS GIGANTES CON POLIMIALGIA REUMÁTICA"
    },
    {
        "id": "a1432578-348c-4455-379b-84584062f50d",
        "NOMBRE": "ARTERITIS, NO ESPECIFICADA"
    },
    {
        "id": "354c480b-1cc6-5ef7-b68c-9978d71bf1bb",
        "NOMBRE": "ARTICULACIÓN INESTABLE"
    },
    {
        "id": "19a6153a-30c9-d685-0336-ca5e932a8688",
        "NOMBRE": "ARTRITIS EN LA ENFERMEDAD DE LYME"
    },
    {
        "id": "ba09733a-399d-b4a6-fb80-410b5ed8a3f4",
        "NOMBRE": "ARTRITIS EN MICOSIS"
    },
    {
        "id": "8f7c145c-bd88-3966-e354-783d49133a80",
        "NOMBRE": "ARTRITIS EN OTRAS ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4f851882-47c7-c478-872d-87a1ffcdce47",
        "NOMBRE": "ARTRITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a3d7e90b-6c0d-37c9-cfd8-481b08126790",
        "NOMBRE": "ARTRITIS EN OTRAS ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5ea60965-c138-2bb3-0a10-cd57a9aa8f76",
        "NOMBRE": "ARTRITIS EN RUBÉOLA"
    },
    {
        "id": "960c0c3b-463e-50eb-e2c4-b38f324ffbf0",
        "NOMBRE": "ARTRITIS JUVENIL"
    },
    {
        "id": "bdaeb186-ad93-5167-8159-1df02da9861a",
        "NOMBRE": "ARTRITIS JUVENIL DE COMIENZO GENERALIZADO"
    },
    {
        "id": "5447d6eb-ad6f-22e2-9353-7d5851bd251e",
        "NOMBRE": "ARTRITIS JUVENIL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "2af3d311-b098-0478-55ef-3ba1faedbce0",
        "NOMBRE": "ARTRITIS JUVENIL EN LA COLITIS ULCERATIVA"
    },
    {
        "id": "90359cfb-a9e7-89f5-2910-2273dea1d5dc",
        "NOMBRE": "ARTRITIS JUVENIL EN LA ENFERMEDAD DE CROHN [ENTERITIS REGIONAL]"
    },
    {
        "id": "d9f6047a-76d8-40b3-d88b-c27ff028e563",
        "NOMBRE": "ARTRITIS JUVENIL EN LA PSORIASIS"
    },
    {
        "id": "ac1417e8-0810-47fa-c964-466f77f697d8",
        "NOMBRE": "ARTRITIS JUVENIL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1480f209-58cf-d296-ce35-556830dd77b7",
        "NOMBRE": "ARTRITIS JUVENIL PAUCIARTICULAR"
    },
    {
        "id": "ec981482-266f-d377-e959-45808f123200",
        "NOMBRE": "ARTRITIS JUVENIL, NO ESPECIFICADA"
    },
    {
        "id": "28be65f1-b493-ea6b-8781-a60e82ef13fd",
        "NOMBRE": "ARTRITIS MENINGOCÓCICA"
    },
    {
        "id": "860dba4b-f8c4-80b0-5b09-c6188eed73b5",
        "NOMBRE": "ARTRITIS MUTILANTE"
    },
    {
        "id": "6d1a88ce-415e-0de1-3b56-d8fdd072d6ac",
        "NOMBRE": "ARTRITIS PIÓGENA"
    },
    {
        "id": "b5961207-abcf-6983-c75b-3310d8d278cc",
        "NOMBRE": "ARTRITIS PIÓGENA, NO ESPECIFICADA"
    },
    {
        "id": "4428e30a-4f74-50df-4c4c-1428a9857e9e",
        "NOMBRE": "ARTRITIS POSTMENINGOCÓCICA"
    },
    {
        "id": "9fef0d66-76e5-a564-9fdc-dc2c5c1c7bb7",
        "NOMBRE": "ARTRITIS REUMATOIDE CON COMPROMISO DE OTROS ÓRGANOS O SISTEMAS"
    },
    {
        "id": "1caaff15-f661-5f34-ed33-0d9ec9c81ea2",
        "NOMBRE": "ARTRITIS REUMATOIDE JUVENIL"
    },
    {
        "id": "96605d8b-79f2-475d-7838-86984730abe4",
        "NOMBRE": "ARTRITIS REUMATOIDE SERONEGATIVA"
    },
    {
        "id": "accf4a8a-0dd3-c2e7-3508-fa1963220d0a",
        "NOMBRE": "ARTRITIS REUMATOIDE SEROPOSITIVA"
    },
    {
        "id": "13ce9a43-7b05-dae3-a8ee-8565cc705b31",
        "NOMBRE": "ARTRITIS REUMATOIDE, NO ESPECIFICADA"
    },
    {
        "id": "e37218e8-dbd7-fe92-9d3e-f2cb335f866a",
        "NOMBRE": "ARTRITIS REUMATOIDEA SEROPOSITIVA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "d1443946-369e-9e6b-7a11-e4f71e882aaf",
        "NOMBRE": "ARTRITIS TUBERCULOSA"
    },
    {
        "id": "e962dc79-fa6e-13c4-2983-9c2bc95f93a6",
        "NOMBRE": "ARTRITIS Y POLIARTRITIS DEBIDAS A OTROS AGENTES BACTERIANOS ESPECIFICADOS"
    },
    {
        "id": "228ac6ac-91e1-69a3-93e0-cdd655c93474",
        "NOMBRE": "ARTRITIS Y POLIARTRITIS ESTAFILOCÓCICA"
    },
    {
        "id": "108c6a69-d3e7-d62f-cd77-b31f3ae12910",
        "NOMBRE": "ARTRITIS Y POLIARTRITIS NEUMOCÓCICA"
    },
    {
        "id": "79e280ba-75e3-6872-1955-e7e31ac0e524",
        "NOMBRE": "ARTRITIS, NO ESPECIFICADA"
    },
    {
        "id": "1f6b6919-1605-bacb-5dfa-8cb6c9479e5d",
        "NOMBRE": "ARTROGRIPOSIS MÚLTIPLE CONGÉNITA"
    },
    {
        "id": "8c941c4a-a2f6-7a9c-c493-bdf301ffe14f",
        "NOMBRE": "ARTROPATÍA CONSECUTIVA A DERIVACIÓN INTESTINAL"
    },
    {
        "id": "096ee293-d24e-043d-65e0-d7311f78887a",
        "NOMBRE": "ARTROPATÍA DIABÉTICA"
    },
    {
        "id": "83d3fde0-df60-1818-7c52-24d9873275e8",
        "NOMBRE": "ARTROPATÍA EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "1e312bfa-a337-2843-0a69-96d7c88b1cbb",
        "NOMBRE": "ARTROPATÍA EN LA AMILOIDOSIS"
    },
    {
        "id": "a64749fe-8ed7-55ad-0037-98609ca05223",
        "NOMBRE": "ARTROPATÍA EN LA COLITIS ULCERATIVA"
    },
    {
        "id": "2a25757c-8b95-f1ea-d6c8-9005163260c9",
        "NOMBRE": "ARTROPATÍA EN LA ENFERMEDAD DE CROHN [ENTERITIS REGIONAL]"
    },
    {
        "id": "0539801d-594e-886a-aca6-aa79ed339181",
        "NOMBRE": "ARTROPATÍA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a70e5d45-879f-12a8-f996-a4f69340ac07",
        "NOMBRE": "ARTROPATÍA EN OTRAS ENFERMEDADES ESPECIFICADAS, CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "84b408aa-ee77-6f95-83f5-54a9aa686f18",
        "NOMBRE": "ARTROPATÍA EN OTROS TRASTORNOS DE LA SANGRE"
    },
    {
        "id": "bab047d9-87d7-9ffe-9e8b-e7f350a37425",
        "NOMBRE": "ARTROPATÍA EN OTROS TRASTORNOS ENDOCRINOS, METABÓLICOS Y NUTRICIONALES"
    },
    {
        "id": "b5bc79f2-b01b-8ded-7084-7228e8404f83",
        "NOMBRE": "ARTROPATÍA EN REACCIONES DE HIPERSENSIBILIDAD CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "bf3ea4b8-f8cf-2762-4373-ddd17dbb4adb",
        "NOMBRE": "ARTROPATÍA GOTOSA DEBIDA A DEFECTOS ENZIMÁTICOS Y A OTROS TRASTORNOS HEREDITARIOS, CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "ef3fa9cd-8d4a-8644-3b06-e2edca3f9dd9",
        "NOMBRE": "ARTROPATÍA HEMOFÍLICA"
    },
    {
        "id": "2b207225-0563-527e-7aa9-7189f75f73c1",
        "NOMBRE": "ARTROPATÍA NEUROPÁTICA"
    },
    {
        "id": "8aa47c20-0508-0b18-fda9-bddd49ff701e",
        "NOMBRE": "ARTROPATÍA POR CRISTALES EN OTROS TRASTORNOS METABÓLICOS"
    },
    {
        "id": "b7cb31ee-a945-808e-0008-2a1e394819aa",
        "NOMBRE": "ARTROPATÍA POR CRISTALES, NO ESPECIFICADA"
    },
    {
        "id": "90faf75e-d126-ed1c-cf3b-6cb8227dc36f",
        "NOMBRE": "ARTROPATÍA POSTDISENTÉRICA"
    },
    {
        "id": "ef65b800-7e84-5dea-41b5-57671b43e08e",
        "NOMBRE": "ARTROPATÍA POSTINFECCIOSA EN SÍFILIS"
    },
    {
        "id": "3cf36d12-15d6-cd86-c117-e2425289a075",
        "NOMBRE": "ARTROPATÍA POSTINMUNIZACIÓN"
    },
    {
        "id": "eb3c0104-6c87-5f02-bb63-25667fc963f5",
        "NOMBRE": "ARTROPATÍA POSTRAUMÁTICA CRÓNICA [DE JACCOUD]"
    },
    {
        "id": "c4517170-c482-64d8-c22e-717df9fe3cb2",
        "NOMBRE": "ARTROPATÍA PSORIÁSICA"
    },
    {
        "id": "0cf200da-2bd8-84c9-bca8-a8306e703424",
        "NOMBRE": "ARTROPATÍA PSORIÁSICA INTERFALÁNGICA DISTAL"
    },
    {
        "id": "38e03863-5f2a-1500-205b-836c9f8b9655",
        "NOMBRE": "ARTROPATÍA REACTIVA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8afc5a04-de90-d85b-0b05-1c1722e21542",
        "NOMBRE": "ARTROPATÍA REACTIVA, NO ESPECIFICADA"
    },
    {
        "id": "e836c000-6a50-4292-c05b-e3549ba3db58",
        "NOMBRE": "ARTROPATÍA TRAUMÁTICA"
    },
    {
        "id": "07ce163f-3b66-8be7-4fe0-abdcea5d4f8b",
        "NOMBRE": "ARTROPATÍAS POSTINFECCIOSAS Y REACTIVAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3ee77bff-6a7f-ec52-0736-c29d244e2b3d",
        "NOMBRE": "ARTROPATÍAS PSORIÁSICAS Y ENTEROPÁTICAS"
    },
    {
        "id": "15313e9a-c08e-69f6-745b-d7a50b65e770",
        "NOMBRE": "ARTROPATÍAS REACTIVAS"
    },
    {
        "id": "20d7d832-7d4a-f64c-f704-43717fda188f",
        "NOMBRE": "ARTROSIS DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA"
    },
    {
        "id": "097f62be-9bbd-63e3-e2a9-c9308da5e30a",
        "NOMBRE": "ARTROSIS DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "06247d28-995a-be5d-6e44-6c863c18b8f5",
        "NOMBRE": "ARTROSIS POSTRAUMÁTICA DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA, BILATERAL"
    },
    {
        "id": "b5642de5-0037-9f97-6e0e-43fd1021293e",
        "NOMBRE": "ARTROSIS POSTRAUMÁTICA DE OTRAS ARTICULACIONES"
    },
    {
        "id": "f0e7b869-aa25-ad41-c2a8-bf0249fc91c3",
        "NOMBRE": "ARTROSIS PRIMARIA DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA, BILATERAL"
    },
    {
        "id": "a3a3b502-57ac-7765-dd59-37f754707404",
        "NOMBRE": "ARTROSIS PRIMARIA DE OTRAS ARTICULACIONES"
    },
    {
        "id": "3ad53b3f-eed5-cefb-7fb2-fa98ca539aa9",
        "NOMBRE": "ARTROSIS SECUNDARIA MÚLTIPLE"
    },
    {
        "id": "8c8fdf6a-fc4c-4652-4ed1-68c07cf21004",
        "NOMBRE": "ARTROSIS, NO ESPECIFICADA"
    },
    {
        "id": "73922c20-eab2-9ea0-5b91-c68ee420b725",
        "NOMBRE": "ASCARIASIS"
    },
    {
        "id": "ba7a8809-2e24-22ae-797f-95daae0ed42a",
        "NOMBRE": "ASCARIASIS CON COMPLICACIONES INTESTINALES"
    },
    {
        "id": "547293a4-ff61-cf8d-f705-f89ab7b1167c",
        "NOMBRE": "ASCARIASIS CON OTRAS COMPLICACIONES"
    },
    {
        "id": "61fab87b-1cfb-0bfe-ff13-cb3d921b2b9f",
        "NOMBRE": "ASCARIASIS, NO ESPECIFICADA"
    },
    {
        "id": "100fc00e-f534-c6ac-88e7-dacecd95918d",
        "NOMBRE": "ASCITIS"
    },
    {
        "id": "32958f95-b6ec-4387-838d-c58f8ae889d8",
        "NOMBRE": "ASESORAMIENTO GENÉTICO"
    },
    {
        "id": "778a9161-6c75-9ab3-eae3-ef3d4ee07a7c",
        "NOMBRE": "ASFIXIA"
    },
    {
        "id": "be945057-c5f5-f420-ad29-4652236997ef",
        "NOMBRE": "ASFIXIA"
    },
    {
        "id": "ed74dc21-9e81-9701-c798-6da9d68866cc",
        "NOMBRE": "ASFIXIA DEL NACIMIENTO"
    },
    {
        "id": "ea5970b4-496c-84cd-f534-45006ac961f5",
        "NOMBRE": "ASFIXIA DEL NACIMIENTO, LEVE Y MODERADA"
    },
    {
        "id": "a1687482-d6a9-a268-3ed0-4f6eb52c2ae4",
        "NOMBRE": "ASFIXIA DEL NACIMIENTO, NO ESPECIFICADA"
    },
    {
        "id": "8c09c505-6075-3103-1bb7-2b8f2e7b270a",
        "NOMBRE": "ASFIXIA DEL NACIMIENTO, SEVERA"
    },
    {
        "id": "79d2d3d4-894b-62e2-ee11-60408297a5ee",
        "NOMBRE": "ASIMETRÍA FACIAL"
    },
    {
        "id": "d13b97bd-b751-1963-68a5-62a2a67b2e7a",
        "NOMBRE": "ASISTENCIA PARA LA ANTICONCEPCIÓN, NO ESPECIFICADA"
    },
    {
        "id": "6ad9466b-4d2a-4ab2-b778-34dde2d8f6d0",
        "NOMBRE": "ASISTENCIA Y AJUSTE DE BOMBA DE INFUSIÓN"
    },
    {
        "id": "0381a0c1-edd5-fa48-49b8-d8d5f6c53192",
        "NOMBRE": "ASISTENCIA Y AJUSTE DE DISPOSITIVO AUDITIVO IMPLANTADO"
    },
    {
        "id": "e36f45d4-afe1-b067-c839-d16371a1e179",
        "NOMBRE": "ASISTENCIA Y AJUSTE DE DISPOSITIVO IMPLANTADO NO ESPECIFICADO"
    },
    {
        "id": "5a6dd024-eed0-248b-7d02-2bbf2e8298c9",
        "NOMBRE": "ASISTENCIA Y AJUSTE DE DISPOSITIVOS CARDÍACOS"
    },
    {
        "id": "97097c08-a7f6-723c-0e3b-61f7150d51f1",
        "NOMBRE": "ASISTENCIA Y AJUSTE DE DISPOSITIVOS DE ACCESO VASCULAR"
    },
    {
        "id": "4fee3b1f-e5b0-7326-0a3e-70f8b370c492",
        "NOMBRE": "ASISTENCIA Y AJUSTE DE DISPOSITIVOS IMPLANTADOS"
    },
    {
        "id": "f27656ff-1526-4f22-23ec-691a8d9f40f5",
        "NOMBRE": "ASISTENCIA Y AJUSTE DE OTROS DISPOSITIVOS IMPLANTADOS"
    },
    {
        "id": "b295dc03-1102-e5c9-be4d-b0af0983e808",
        "NOMBRE": "ASMA"
    },
    {
        "id": "c7b76b30-a145-b042-f84c-52e2f8e3ee44",
        "NOMBRE": "ASMA MIXTA"
    },
    {
        "id": "f03dc1d8-793d-9830-9635-79da8d9a3c05",
        "NOMBRE": "ASMA NO ALÉRGICA"
    },
    {
        "id": "2ef42870-4d9d-9b45-ad25-0e5ad3b38184",
        "NOMBRE": "ASMA PREDOMINANTEMENTE ALÉRGICA"
    },
    {
        "id": "99c728da-4315-556e-653d-d17d5e52476f",
        "NOMBRE": "ASMA, NO ESPECIFICADO"
    },
    {
        "id": "3968e450-8242-835e-db85-83846c84bd4f",
        "NOMBRE": "ASPERGILOSIS"
    },
    {
        "id": "4fd12045-d7bc-97c0-ae45-2e9d8099ed46",
        "NOMBRE": "ASPERGILOSIS AMIGDALINA"
    },
    {
        "id": "b2a146d9-be72-d4ad-69ff-8e4550173726",
        "NOMBRE": "ASPERGILOSIS DISEMINADA"
    },
    {
        "id": "3f7e20f4-5740-d8b3-d77b-a33e4c7916d9",
        "NOMBRE": "ASPERGILOSIS PULMONAR INVASIVA"
    },
    {
        "id": "e41a4835-d8cd-6682-9762-2d5c59b45123",
        "NOMBRE": "ASPERGILOSIS, NO ESPECIFICADA"
    },
    {
        "id": "c0bac65e-9b49-f741-3673-86d612219c23",
        "NOMBRE": "ASPIRACIÓN DE LÍQUIDOS"
    },
    {
        "id": "d0b69778-a5e3-b2e0-5a98-ad68a29d2a3b",
        "NOMBRE": "ASPIRACIÓN NEONATAL DE LECHE Y ALIMENTO REGURGITADO"
    },
    {
        "id": "2d4ee282-c7b0-243f-6722-3ec102f41207",
        "NOMBRE": "ASPIRACIÓN NEONATAL DE LÍQUIDO AMNIÓTICO Y DE MOCO"
    },
    {
        "id": "de2d8e41-406e-d60a-039a-48fec001b768",
        "NOMBRE": "ASPIRACIÓN NEONATAL DE MECONIO"
    },
    {
        "id": "63ad0714-f50f-b4f6-408b-229e9a8daefb",
        "NOMBRE": "ASPIRACIÓN NEONATAL DE SANGRE"
    },
    {
        "id": "59b077e7-f880-92ae-cbbe-bec5431968a5",
        "NOMBRE": "ASTIGMATISMO"
    },
    {
        "id": "c8e6ed27-d69f-d2d8-9fc0-043fa0e94a34",
        "NOMBRE": "ATAQUE CIÁNOTICO DEL RECIÉN NACIDO"
    },
    {
        "id": "bcea1b7b-8fb6-15e5-10b3-86f713223164",
        "NOMBRE": "ATAQUES DE GRAN MAL, NO ESPECIFICADOS (CON O SIN PEQUEÑO MAL)"
    },
    {
        "id": "29128742-c7b8-dd3c-e91e-cfaa19602994",
        "NOMBRE": "ATAQUES DE ISQUEMIA CEREBRAL TRANSITORIA Y SÍNDROMES AFINES"
    },
    {
        "id": "98b65286-a9dd-69e3-1b51-e5b232fb791b",
        "NOMBRE": "ATAXIA CEREBELOSA CON REPARACIÓN DEFECTUOSA DEL ADN"
    },
    {
        "id": "eb2a3190-bfda-2ce1-dc78-29fe01f46b68",
        "NOMBRE": "ATAXIA CEREBELOSA DE INICIACIÓN TARDÍA"
    },
    {
        "id": "fea2be33-644f-ff39-8850-b9c9cb3abb86",
        "NOMBRE": "ATAXIA CEREBELOSA DE INICIACIÓN TEMPRANA"
    },
    {
        "id": "5a35e859-70c2-8e86-e719-7a7fc5ea9f9e",
        "NOMBRE": "ATAXIA CONGÉNITA NO PROGRESIVA"
    },
    {
        "id": "8d359c9c-3dd7-dcf8-d959-78c2719c0f72",
        "NOMBRE": "ATAXIA HEREDITARIA"
    },
    {
        "id": "eaf07ea9-3d63-8599-c80b-76782a3cef14",
        "NOMBRE": "ATAXIA HEREDITARIA, NO ESPECIFICADA"
    },
    {
        "id": "28636939-6db8-162a-7e21-25fb59302fb5",
        "NOMBRE": "ATAXIA, NO ESPECIFICADA"
    },
    {
        "id": "0f0e125c-f9e0-a14f-37a9-ff20340d55eb",
        "NOMBRE": "ATELECTASIA PRIMARIA DEL RECIÉN NACIDO"
    },
    {
        "id": "14a9c4be-abd6-5ab2-31ff-20c0a3f26460",
        "NOMBRE": "ATENCIÓN A LA MADRE POR OTRAS COMPLICACIONES PRINCIPALMENTE RELACIONADAS CON EL EMBARAZO"
    },
    {
        "id": "c9c62a81-f4e9-2dcf-a5cf-87b27d697f1c",
        "NOMBRE": "ATENCIÓN DE CISTOSTOMÍA"
    },
    {
        "id": "f4750394-6181-3244-6ada-b68c26c4ad8c",
        "NOMBRE": "ATENCIÓN DE COLOSTOMÍA"
    },
    {
        "id": "52b8a876-a6e2-2c58-5444-9cc970909700",
        "NOMBRE": "ATENCIÓN DE GASTROSTOMÍA"
    },
    {
        "id": "28260fcf-a755-eeea-ce1d-4f65368472e7",
        "NOMBRE": "ATENCIÓN DE ILEOSTOMÍA"
    },
    {
        "id": "cae89497-e65a-5018-9fbe-1d3fd3d21deb",
        "NOMBRE": "ATENCIÓN DE LOS APÓSITOS Y SUTURAS"
    },
    {
        "id": "711491b4-7620-5af1-32db-e06699ab1bc9",
        "NOMBRE": "ATENCIÓN DE ORIFICIO ARTIFICIAL NO ESPECIFICADO"
    },
    {
        "id": "3b619b0e-d3f2-88a7-78c5-9618a4f0641c",
        "NOMBRE": "ATENCIÓN DE ORIFICIOS ARTIFICIALES"
    },
    {
        "id": "69d4bc67-e5cc-d8f0-2044-a0ca8c0e585c",
        "NOMBRE": "ATENCIÓN DE OTROS ORIFICIOS ARTIFICIALES"
    },
    {
        "id": "cc9a2b56-d4e3-0155-b058-02351cf0ae24",
        "NOMBRE": "ATENCIÓN DE OTROS ORIFICIOS ARTIFICIALES DE LAS VÍAS DIGESTIVAS"
    },
    {
        "id": "03fa501f-ada6-9013-6c4b-9dea2ebe840b",
        "NOMBRE": "ATENCIÓN DE OTROS ORIFICIOS ARTIFICIALES DE LAS VÍAS URINARIAS"
    },
    {
        "id": "51913904-21e1-d000-bbb8-d397259bd183",
        "NOMBRE": "ATENCIÓN DE TRAQUEOSTOMÍA"
    },
    {
        "id": "a51cbc02-d8df-5558-77ff-c9a8a6f732b5",
        "NOMBRE": "ATENCIÓN DE VAGINA ARTIFICIAL"
    },
    {
        "id": "66bdf2ee-d0c9-5fc5-05cf-e4c92d87de20",
        "NOMBRE": "ATENCIÓN DEL EMBARAZO EN UNA ABORTADORA HABITUAL"
    },
    {
        "id": "5d0362b4-2af7-35cb-ad78-f03508315ef8",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) ANORMALIDAD CROMOSÓMICA EN EL FETO"
    },
    {
        "id": "38d93372-4095-8e3d-a768-e96ae89f15f7",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) ANORMALIDAD Y LESIÓN FETAL NO ESPECIFICADA"
    },
    {
        "id": "263c260f-a1f8-e70a-67ef-af6651350bea",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) ENFERMEDAD HEREDITARIA EN EL FETO"
    },
    {
        "id": "f38f0df8-c684-4d4f-bcd2-f62f69129b4e",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) LESIÓN AL FETO DEBIDA A RADIACIÓN"
    },
    {
        "id": "f3c73e01-d4e2-7bf3-b9e0-755a11210236",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) LESIÓN AL FETO DEBIDA AL ALCOHOL"
    },
    {
        "id": "606776eb-48d8-0ad5-694c-529a5f71ee14",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) LESIÓN FETAL DEBIDA A DROGAS"
    },
    {
        "id": "2abc69c3-1be8-cf5b-edad-7bd43bafb4e2",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) LESIÓN FETAL DEBIDA A ENFERMEDAD VÍRICA EN LA MADRE"
    },
    {
        "id": "a452b5f4-5067-74a1-1c7c-7c713473f3ad",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) LESIÓN FETAL DEBIDA A OTROS PROCEDIMIENTOS MÉDICOS"
    },
    {
        "id": "9f8dd4f9-62d7-79c4-cce5-5f5803c85a1e",
        "NOMBRE": "ATENCIÓN MATERNA POR (PRESUNTA) MALFORMACIÓN DEL SISTEMA NERVIOSO CENTRAL EN EL FETO"
    },
    {
        "id": "4c101fdd-3131-60f4-74ff-82567839458b",
        "NOMBRE": "ATENCIÓN MATERNA POR ANOMALÍA CONGÉNITA DEL ÚTERO"
    },
    {
        "id": "6e80a5ab-bfc0-1835-6cad-e61afe5593b6",
        "NOMBRE": "ATENCIÓN MATERNA POR ANORMALIDAD DE LA VAGINA"
    },
    {
        "id": "c35c4ffa-3ea1-b955-e757-cf5799a46e15",
        "NOMBRE": "ATENCIÓN MATERNA POR ANORMALIDAD DE LA VULVA Y DEL PERINEO"
    },
    {
        "id": "c75e7392-3d7b-6af1-682a-13f8ffe078d7",
        "NOMBRE": "ATENCIÓN MATERNA POR ANORMALIDAD NO ESPECIFICADA DE ÓRGANO PELVIANO"
    },
    {
        "id": "d612f70d-dcf9-01ad-9eb2-ed7459cdaf75",
        "NOMBRE": "ATENCIÓN MATERNA POR ANORMALIDAD O LESIÓN FETAL, CONOCIDA O PRESUNTA"
    },
    {
        "id": "4ff01554-c866-a1d6-dc49-ddf411755b93",
        "NOMBRE": "ATENCIÓN MATERNA POR ANORMALIDADES CONOCIDAS O PRESUNTAS DE LOS ÓRGANOS PELVIANOS DE LA MADRE"
    },
    {
        "id": "1ceeca06-5fb6-b6e2-c3f6-e6982b1921f3",
        "NOMBRE": "ATENCIÓN MATERNA POR CABEZA ALTA EN GESTACIÓN A TÉRMINO"
    },
    {
        "id": "13b10985-5d9b-049e-b8d9-69a5b01dba84",
        "NOMBRE": "ATENCIÓN MATERNA POR CICATRIZ UTERINA DEBIDA A CIRUGÍA PREVIA"
    },
    {
        "id": "bfb767e1-5e60-acb0-ed74-8298025cb007",
        "NOMBRE": "ATENCIÓN MATERNA POR CRECIMIENTO FETAL EXCESIVO"
    },
    {
        "id": "b70c59df-37d5-4298-3cde-06302960ec22",
        "NOMBRE": "ATENCIÓN MATERNA POR DÉFICIT DEL CRECIMIENTO FETAL"
    },
    {
        "id": "cdb0861a-7a9a-95d0-c43b-9999a36c92a5",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN CONOCIDA O PRESUNTA"
    },
    {
        "id": "f9dad934-d57e-d6de-0d13-679d28350d8a",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DE ORIGEN NO ESPECIFICADO"
    },
    {
        "id": "1e19ee8f-3b9a-ef13-61ba-ae88de64e40a",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DE OTRO ORIGEN"
    },
    {
        "id": "e30ad9b0-bec6-27bb-e15e-540c2b43f238",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DEBIDA A DEFORMIDAD DE LA PELVIS ÓSEA EN LA MADRE"
    },
    {
        "id": "882c2601-6a4c-2591-6237-2b873bde724a",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DEBIDA A DISMINUCIÓN DEL ESTRECHO INFERIOR DE LA PELVIS"
    },
    {
        "id": "0f6d1b79-8755-d60e-c169-fe495bef85fb",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DEBIDA A DISMINUCIÓN DEL ESTRECHO SUPERIOR DE LA PELVIS"
    },
    {
        "id": "87aa6ec7-2fc7-b21c-8504-38516ea56ab7",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DEBIDA A ESTRECHEZ GENERAL DE LA PELVIS"
    },
    {
        "id": "280b1dbb-6c56-26c0-06fb-0e85d61ef8ff",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DEBIDA A FETO DEMASIADO GRANDE"
    },
    {
        "id": "8b4dcbc7-f2e2-ff03-a213-670e6cba29f2",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DEBIDA A FETO HIDROCEFÁLICO"
    },
    {
        "id": "ed3ef0a3-c788-03a2-b0e6-b088571a51d1",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN DEBIDA A OTRA DEFORMIDAD FETAL"
    },
    {
        "id": "f1b886ea-b662-3d21-9c1c-64d24d617293",
        "NOMBRE": "ATENCIÓN MATERNA POR DESPROPORCIÓN FETOPELVIANA DE ORIGEN MIXTO, MATERNO Y FETAL"
    },
    {
        "id": "ab6a8d40-966d-cbcc-b439-98c23fccb8eb",
        "NOMBRE": "ATENCIÓN MATERNA POR EMBARAZO MÚLTIPLE CON PRESENTACIÓN ANORMAL DE UN FETO O MÁS"
    },
    {
        "id": "fc0692ef-203d-6114-8f7a-a447f6df07c8",
        "NOMBRE": "ATENCIÓN MATERNA POR FETO VIABLE EN EMBARAZO ABDOMINAL"
    },
    {
        "id": "d5621c5d-38c8-28e5-7a32-34c92a525408",
        "NOMBRE": "ATENCIÓN MATERNA POR HIDROPESÍA FETAL"
    },
    {
        "id": "7c8eed2c-a672-90e1-a79d-b00883733927",
        "NOMBRE": "ATENCIÓN MATERNA POR INCOMPETENCIA DEL CUELLO UTERINO"
    },
    {
        "id": "8451ca3c-b121-3ad7-c6d6-e2d4c5e8ef35",
        "NOMBRE": "ATENCIÓN MATERNA POR ISOINMUNIZACIÓN RHESUS"
    },
    {
        "id": "3bed85f0-d76d-5d8f-3d42-f640392368a7",
        "NOMBRE": "ATENCIÓN MATERNA POR MUERTE INTRAUTERINA"
    },
    {
        "id": "f9d08088-f1f4-a815-f22f-1f0b9b8bb7b5",
        "NOMBRE": "ATENCIÓN MATERNA POR OTRA ANORMALIDAD DEL CUELLO UTERINO"
    },
    {
        "id": "e56a5fe2-7e48-ec89-48bb-decb78a0beb7",
        "NOMBRE": "ATENCIÓN MATERNA POR OTRA ISOINMUNIZACIÓN"
    },
    {
        "id": "2fe16163-7fb0-c8b3-e510-55edccb5fc6f",
        "NOMBRE": "ATENCIÓN MATERNA POR OTRAS (PRESUNTAS) ANORMALIDADES Y LESIONES FETALES"
    },
    {
        "id": "30656a6d-6c89-3fe8-15f7-901b20d411a4",
        "NOMBRE": "ATENCIÓN MATERNA POR OTRAS ANORMALIDADES DE LOS ÓRGANOS PELVIANOS"
    },
    {
        "id": "135e3a25-1654-b116-7b4d-bd3f46c6ed2c",
        "NOMBRE": "ATENCIÓN MATERNA POR OTRAS ANORMALIDADES DEL ÚTERO GRÁVIDO"
    },
    {
        "id": "bce3b18c-f9a2-fc63-b968-e7e03c3b6642",
        "NOMBRE": "ATENCIÓN MATERNA POR OTRAS PRESENTACIONES ANORMALES DEL FETO"
    },
    {
        "id": "66d1a55b-85d3-5340-1218-f0511b11bb83",
        "NOMBRE": "ATENCIÓN MATERNA POR OTROS PROBLEMAS FETALES CONOCIDOS O PRESUNTOS"
    },
    {
        "id": "9c380627-caa4-bfcf-bdf7-28bbe9a41098",
        "NOMBRE": "ATENCIÓN MATERNA POR OTROS PROBLEMAS FETALES ESPECIFICADOS"
    },
    {
        "id": "bc4fdd46-4f0a-2d5f-0c45-5b49d9520e6b",
        "NOMBRE": "ATENCIÓN MATERNA POR POSICIÓN FETAL INESTABLE"
    },
    {
        "id": "2c413bdb-50ef-9264-4def-11638b17530e",
        "NOMBRE": "ATENCIÓN MATERNA POR POSICIÓN FETAL OBLICUA O TRANSVERSA"
    },
    {
        "id": "765814eb-4795-3c4b-3985-ec56eaf8d5bd",
        "NOMBRE": "ATENCIÓN MATERNA POR PRESENTACIÓN ANORMAL DEL FETO, CONOCIDA O PRESUNTA"
    },
    {
        "id": "32afd3c5-e75d-c55a-9702-996e5d75cea5",
        "NOMBRE": "ATENCIÓN MATERNA POR PRESENTACIÓN ANORMAL NO ESPECIFICADA DEL FETO"
    },
    {
        "id": "70390a4d-6578-5be5-b26d-4605f27383fd",
        "NOMBRE": "ATENCIÓN MATERNA POR PRESENTACIÓN COMPUESTA"
    },
    {
        "id": "6b0fcd45-ebc5-1418-b7c5-125fda829d9a",
        "NOMBRE": "ATENCIÓN MATERNA POR PRESENTACIÓN DE CARA, DE FRENTE O DE MENTÓN"
    },
    {
        "id": "c54617dd-0f36-c83a-4846-f984a0fbc061",
        "NOMBRE": "ATENCIÓN MATERNA POR PRESENTACIÓN DE NALGAS"
    },
    {
        "id": "942ef1f4-8c74-2a9b-3ecf-58a5d0c9aa63",
        "NOMBRE": "ATENCIÓN MATERNA POR PROBLEMAS FETALES NO ESPECIFICADOS"
    },
    {
        "id": "9577849d-5087-58e2-827a-bba83490ba10",
        "NOMBRE": "ATENCIÓN MATERNA POR SIGNOS DE HIPOXIA FETAL"
    },
    {
        "id": "e3453cb2-0416-dd31-85a7-ac4426484b91",
        "NOMBRE": "ATENCIÓN MATERNA POR TUMOR DEL CUERPO DEL ÚTERO"
    },
    {
        "id": "9e6a1d71-ef23-a6e5-0585-d9f993e18965",
        "NOMBRE": "ATENCIÓN MÉDICA, NO ESPECIFICADA"
    },
    {
        "id": "93ff528e-52df-136e-e5fd-97ebb568c6b6",
        "NOMBRE": "ATENCIÓN NO ESPECIFICADA RELACIONADA CON LA PROCREACIÓN"
    },
    {
        "id": "bd3a4811-3c1c-db1b-a72b-9a806dbfc370",
        "NOMBRE": "ATENCIÓN PALIATIVA"
    },
    {
        "id": "fd4ad385-310f-b672-42fe-b60f61d14b8f",
        "NOMBRE": "ATENCIÓN PARA LA ANTICONCEPCIÓN"
    },
    {
        "id": "afb16204-9a5e-91a9-1423-cbd48f77f2a6",
        "NOMBRE": "ATENCIÓN PARA LA PROCREACIÓN"
    },
    {
        "id": "2c9a42dc-9039-8372-e9c4-ff42cc9f0247",
        "NOMBRE": "ATENCIÓN POR EL USO DE PROCEDIMIENTOS DE REHABILITACIÓN"
    },
    {
        "id": "4c3b84ee-d2db-c299-21af-127f62794442",
        "NOMBRE": "ATENCIÓN POR OTROS PROCEDIMIENTOS DE REHABILITACIÓN"
    },
    {
        "id": "52ba1270-0fc5-4978-bc43-92aec2a9f3a5",
        "NOMBRE": "ATENCIÓN POR PROCEDIMIENTO DE REHABILITACIÓN, NO ESPECIFICADA"
    },
    {
        "id": "f9b7bf3d-bcaf-0626-ec26-0b5b2ee6fe72",
        "NOMBRE": "ATENCIÓN PREPARATORIA PARA TRATAMIENTO SUBSECUENTE, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "dfce60c3-6626-1983-ed88-86ca9224ba33",
        "NOMBRE": "ATENCIÓN Y EXAMEN DE MADRE EN PERIODO DE LACTANCIA"
    },
    {
        "id": "caf73f6c-be28-1e79-3739-039032fb781b",
        "NOMBRE": "ATENCIÓN Y EXAMEN INMEDIATAMENTE DESPUÉS DEL PARTO"
    },
    {
        "id": "ae410de5-8d9c-b369-1f12-9be260d0011f",
        "NOMBRE": "ATEROSCLEROSIS"
    },
    {
        "id": "014e4dbf-c1d4-3bb2-9771-5c3edefdf4c5",
        "NOMBRE": "ATEROSCLEROSIS CEREBRAL"
    },
    {
        "id": "e56a7948-e505-56f7-9dc8-42b358b04240",
        "NOMBRE": "ATEROSCLEROSIS DE LA AORTA"
    },
    {
        "id": "af509310-b621-fe6a-3382-87c79512436b",
        "NOMBRE": "ATEROSCLEROSIS DE LA ARTERIA RENAL"
    },
    {
        "id": "a09025cb-5f6d-1ac4-bd0b-55eba4e28d17",
        "NOMBRE": "ATEROSCLEROSIS DE LAS ARTERIAS DE LOS MIEMBROS"
    },
    {
        "id": "9cf5ec4d-81e6-03e0-ffdc-a32704ad5f94",
        "NOMBRE": "ATEROSCLEROSIS DE OTRAS ARTERIAS"
    },
    {
        "id": "cd6e7c4b-278f-325a-bf7a-99597a3810d7",
        "NOMBRE": "ATEROSCLEROSIS GENERALIZADA Y LA NO ESPECIFICADA"
    },
    {
        "id": "cf89d15b-a610-6486-4806-22aecefab6d8",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS"
    },
    {
        "id": "df5b995f-28a0-322d-c008-88d0172a57d1",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "29657575-29ea-cf5b-cfcd-a3f67480e5c2",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "48f76be0-3f0d-9e2f-e111-89629d941523",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, CALLES Y CARRETERAS"
    },
    {
        "id": "c4dc71c5-505a-7e26-230b-6778ba8ae236",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "33999570-818c-a08b-c360-3eedacab5798",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0b12512c-f3f9-05eb-dfb9-9183defc4efd",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, GRANJA"
    },
    {
        "id": "7981d145-16ef-6a93-3eb6-db42da70c016",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ad0c80aa-324a-b423-b668-77d695a8a414",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c7370c64-5970-e0d1-fbac-76ea81bf17aa",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "15d57bb0-0d45-4996-2476-f19378b68b5a",
        "NOMBRE": "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, VIVIENDA"
    },
    {
        "id": "82ae6834-98a0-4389-97c1-06a848f24374",
        "NOMBRE": "ATRESIA DE LA AORTA"
    },
    {
        "id": "f4f7c1b7-d105-666e-8fb7-2d635bee7574",
        "NOMBRE": "ATRESIA DE LA ARTERIA PULMONAR"
    },
    {
        "id": "7900f6ed-e7fc-f514-a6d3-fe1151b8d6f6",
        "NOMBRE": "ATRESIA DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "019cb6ce-c4ad-9c5e-f315-91c99b6d35b4",
        "NOMBRE": "ATRESIA DE LAS COANAS"
    },
    {
        "id": "ea91545d-6d4d-a68a-eb89-3bfa24393899",
        "NOMBRE": "ATRESIA DE LOS AGUJEROS DE MAGENDIE Y DE LUSCHKA"
    },
    {
        "id": "dd55131e-4ff9-4fcb-84d8-6d4005093227",
        "NOMBRE": "ATRESIA DE LOS CONDUCTOS BILIARES"
    },
    {
        "id": "6db8a243-8ba6-e83d-df1a-293971b8c05c",
        "NOMBRE": "ATRESIA DEL CONDUCTO DEFERENTE"
    },
    {
        "id": "c6fdd5c6-39ba-981d-d75f-e97a6f2275e8",
        "NOMBRE": "ATRESIA DEL ESÓFAGO CON FÍSTULA TRAQUEOESOFÁGICA"
    },
    {
        "id": "b7655f0d-325c-5d61-e01f-bcf6c2658925",
        "NOMBRE": "ATRESIA DEL ESÓFAGO SIN MENCIÓN DE FÍSTULA"
    },
    {
        "id": "da7b65b8-270d-b6ca-4219-85ee7d6c1c87",
        "NOMBRE": "ATRESIA Y ESTENOSIS DEL URÉTER"
    },
    {
        "id": "9159e2e9-b8e0-473b-68e2-930f37365170",
        "NOMBRE": "ATRICIÓN EXCESIVA DE LOS DIENTES"
    },
    {
        "id": "1160e2b7-b8b1-de0b-632a-d475ad2ff015",
        "NOMBRE": "ATROFIA ADQUIRIDA DEL OVARIO Y DE LA TROMPA DE FALOPIO"
    },
    {
        "id": "83f7ff1d-dbd8-37db-74e9-85b138aa3d9a",
        "NOMBRE": "ATROFIA CEREBRAL CIRCUNSCRITA"
    },
    {
        "id": "f2159593-d112-fe9a-5fb4-6c9f60187d52",
        "NOMBRE": "ATROFIA DE GLÁNDULA SALIVAL"
    },
    {
        "id": "de68a6e7-ff1d-bf56-0d5c-cea8e90fce5e",
        "NOMBRE": "ATROFIA DE LA MAMA"
    },
    {
        "id": "168f8193-9ab9-5b63-a76d-610fdc751e7e",
        "NOMBRE": "ATROFIA DE LA PRÓSTATA"
    },
    {
        "id": "96a58666-7b86-c172-67d1-3a648fddc966",
        "NOMBRE": "ATROFIA DE LA VULVA"
    },
    {
        "id": "bdb6032d-6a78-5f6b-67d1-f51495e04fcb",
        "NOMBRE": "ATROFIA DE LAS PAPILAS LINGUALES"
    },
    {
        "id": "85a06057-c2d6-ae79-fbe7-9c3235d16be3",
        "NOMBRE": "ATROFIA DE TIROIDES (ADQUIRIDA)"
    },
    {
        "id": "fe57ca2c-32ad-a74d-c14d-967ffe97b1fd",
        "NOMBRE": "ATROFIA DEL REBORDE ALVEOLAR DESDENTADO"
    },
    {
        "id": "c9e9bc8a-ec1c-fc8f-335a-0490381f39cf",
        "NOMBRE": "ATROFIA DEL TESTÍCULO"
    },
    {
        "id": "8006c3c9-2b71-992d-4e3b-4e32c91999dc",
        "NOMBRE": "ATROFIA MULTISISTÉMICA TIPO CEREBELOSA [ASM-C]"
    },
    {
        "id": "253e35a8-cfc8-8323-2958-a664c040c5fb",
        "NOMBRE": "ATROFIA MUSCULAR ESPINAL INFANTIL, TIPO I (WERDNIG-HOFFMAN)"
    },
    {
        "id": "ad0c8907-5df6-456c-4d5e-7f36d944ccd4",
        "NOMBRE": "ATROFIA MUSCULAR ESPINAL Y SÍNDROMES AFINES"
    },
    {
        "id": "f2c328ce-80c4-7fe4-2ba4-6d89cf50edd5",
        "NOMBRE": "ATROFIA MUSCULAR ESPINAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "eae36101-983e-18e3-e5b1-d4c563165b8c",
        "NOMBRE": "ATROFIA ÓPTICA"
    },
    {
        "id": "f9bc5a87-98b1-5f95-0d7d-ba0ea6184d25",
        "NOMBRE": "ATROFIA ÓPTICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1c64c1b3-252b-4ee7-cdda-b483ed920fd7",
        "NOMBRE": "ATROFIA SISTÉMICA MÚLTIPLE, TIPO PARKINSONIANA [AMS-P]"
    },
    {
        "id": "b305fb56-b637-742d-e381-c68b56e423d9",
        "NOMBRE": "ATROFIA SISTÉMICA QUE AFECTA PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN EL MIXEDEMA"
    },
    {
        "id": "cbfe66c7-93d3-0ea6-a586-d40b1a6701bc",
        "NOMBRE": "ATROFIA SISTÉMICA QUE AFECTA PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e2b2ec99-7764-7d6d-13b4-575cda141c01",
        "NOMBRE": "ATROFIA Y DESGASTE MUSCULARES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "5acbf32c-2e4b-6139-6d13-6fc30fc52d62",
        "NOMBRE": "ATROFIAS SISTÉMICAS QUE AFECTAN PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9140c2d2-dbbb-1334-39ec-d95cceca041e",
        "NOMBRE": "ATROFODERMA DE PASINI Y PRERINI"
    },
    {
        "id": "8b9971f7-af89-fed2-519a-0df7281c7a7a",
        "NOMBRE": "AUMENTO ANORMAL DE PESO"
    },
    {
        "id": "6696c1eb-88bc-b182-ae35-a669c74ef71f",
        "NOMBRE": "AUMENTO EXCESIVO DE PESO EN EL EMBARAZO"
    },
    {
        "id": "93fbe804-af08-7e27-0932-ad635a5ae1c4",
        "NOMBRE": "AUMENTO PEQUEÑO DE PESO EN EL EMBARAZO"
    },
    {
        "id": "46315adc-f7c9-2eae-adbd-ec0063a7440a",
        "NOMBRE": "AUSENCIA ADQUIRIDA (DE PARTE) DEL PULMÓN"
    },
    {
        "id": "24c2fe12-ef7b-5858-9ce9-47f430f54800",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE AMBOS MIEMBROS INFERIORES [CUALQUIER NIVEL, EXCEPTO DEDOS DEL PIE SOLAMENTE]"
    },
    {
        "id": "3d548d10-67a9-3ec6-0111-4f4e04ff4508",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE AMBOS MIEMBROS SUPERIORES [CUALQUIER NIVEL]"
    },
    {
        "id": "deacb426-b9f8-55ae-8a4c-4315261a33ef",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE DEDO(S), [INCLUIDO EL PULGAR], UNILATERAL"
    },
    {
        "id": "4af61f05-7b5d-9a56-b22e-338479607e05",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE MAMA(S)"
    },
    {
        "id": "b81aaf2d-d8af-cbaa-89e5-bded004ca20c",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE MANO Y MUÑECA"
    },
    {
        "id": "5d73b17e-c32c-10e8-8631-72cabb9631f1",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE MIEMBRO SUPERIOR POR ARRIBA DE LA MUÑECA"
    },
    {
        "id": "818a6262-f49e-96b5-514e-af5e79e1f714",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE MIEMBROS"
    },
    {
        "id": "fc54f93b-66d5-762e-96fc-a93e82ae89b3",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE MIEMBROS NO ESPECIFICADOS"
    },
    {
        "id": "d509e89b-182e-421f-b3a9-f597d779fe06",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE MIEMBROS SUPERIORES E INFERIORES [CUALQUIER NIVEL]"
    },
    {
        "id": "b07806a1-cf29-9233-b22b-21140235666c",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE ÓRGANO(S) GENITAL(ES)"
    },
    {
        "id": "81f7e00c-c614-9d2d-33c2-833151c7a3a6",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE ÓRGANOS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "ac325b36-e9e0-acdc-db18-8a6fd5063161",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE OTRAS PARTES DE LAS VÍAS URINARIAS"
    },
    {
        "id": "18a8dd59-a835-a263-d5f7-5e66135fd8a0",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE OTRAS PARTES DEL TUBO DIGESTIVO"
    },
    {
        "id": "387daaf1-9b2a-783f-bbeb-f3fe9a38a4b7",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE OTROS ÓRGANOS"
    },
    {
        "id": "c9753674-5cf4-3e21-a18d-102ef5f01d5b",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE PARTE DE LA CABEZA Y DEL CUELLO"
    },
    {
        "id": "695be089-0cff-7d05-29a4-98ad8ef5a1bc",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE PARTE DEL ESTÓMAGO"
    },
    {
        "id": "5505b3d9-42f5-3a62-ccaa-b86f871409ca",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE PIE Y TOBILLO"
    },
    {
        "id": "06a82065-753b-1a15-0313-aa823606d4a0",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE PIERNA A NIVEL DE O DEBAJO DE LA RODILLA"
    },
    {
        "id": "85d50f02-9168-5c32-dec1-278b3b446e14",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE PIERNA POR ARRIBA DE LA RODILLA"
    },
    {
        "id": "fa6ff439-822c-8c3e-0a0a-08c38dc905c5",
        "NOMBRE": "AUSENCIA ADQUIRIDA DE RIÑÓN"
    },
    {
        "id": "e24d5452-40f6-599e-da7e-d4b476539f60",
        "NOMBRE": "AUSENCIA COMPLETA DE MIEMBRO(S) NO ESPECIFICADO(S)"
    },
    {
        "id": "1c0c701c-7abd-8736-b111-230ade9c8f60",
        "NOMBRE": "AUSENCIA CONGÉNITA COMPLETA DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "e0572587-7931-a1a8-418c-64bc47a1a8b4",
        "NOMBRE": "AUSENCIA CONGÉNITA COMPLETA DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)"
    },
    {
        "id": "f940813d-9247-eb95-d92f-29ef4926f7b9",
        "NOMBRE": "AUSENCIA CONGÉNITA DE LA MAMA CON AUSENCIA DEL PEZÓN"
    },
    {
        "id": "40e6af24-20a0-52be-1819-67b1cefd4259",
        "NOMBRE": "AUSENCIA CONGÉNITA DE LA MANO Y EL (LOS) DEDO(S)"
    },
    {
        "id": "a6fbf894-223e-f4ea-c1d4-8949926694b6",
        "NOMBRE": "AUSENCIA CONGÉNITA DE LA PIERNA Y DEL PIE"
    },
    {
        "id": "254826f2-007c-3100-817b-5bba2adc1d15",
        "NOMBRE": "AUSENCIA CONGÉNITA DE LA VAGINA"
    },
    {
        "id": "bc2537c8-5ca8-c841-4dfe-fadc0596ddfe",
        "NOMBRE": "AUSENCIA CONGÉNITA DE LA VEJIGA Y DE LA URETRA"
    },
    {
        "id": "7e049b37-1de9-7289-3b0a-15a806ef3b8a",
        "NOMBRE": "AUSENCIA CONGÉNITA DE OVARIO"
    },
    {
        "id": "73c193a1-1a50-1d9c-e3f7-466b0761a488",
        "NOMBRE": "AUSENCIA CONGÉNITA DEL ANTEBRAZO Y DE LA MANO"
    },
    {
        "id": "8993ef6e-9b19-57ba-3d4f-5f01385c0db3",
        "NOMBRE": "AUSENCIA CONGÉNITA DEL BRAZO Y DEL ANTEBRAZO CON PRESENCIA DE LA MANO"
    },
    {
        "id": "fceeca1d-4cc9-65d1-f0d8-da2d8c4d22eb",
        "NOMBRE": "AUSENCIA CONGÉNITA DEL MUSLO Y DE LA PIERNA CON PRESENCIA DEL PIE"
    },
    {
        "id": "08570a12-2084-276e-c633-15f5db028f67",
        "NOMBRE": "AUSENCIA CONGÉNITA DEL PABELLÓN (DE LA OREJA)"
    },
    {
        "id": "4d14d9ed-2098-a060-4f44-73d82984566e",
        "NOMBRE": "AUSENCIA CONGÉNITA DEL PIE Y DEDO(S) DEL PIE"
    },
    {
        "id": "7c725de7-ab4e-378f-605d-e7e8634115da",
        "NOMBRE": "AUSENCIA CONGÉNITA, ATRESIA O ESTRECHEZ DEL CONDUCTO AUDITIVO (EXTERNO)"
    },
    {
        "id": "5d060126-d3f9-87c8-6219-bd7ac6b9292a",
        "NOMBRE": "AUSENCIA DE LA TROMPA DE EUSTAQUIO"
    },
    {
        "id": "8f6efeab-7c9e-388f-d40c-8c1d2cc54685",
        "NOMBRE": "AUSENCIA DEL IRIS"
    },
    {
        "id": "912e67d8-2d1a-8a1e-2399-e60a84a81f4d",
        "NOMBRE": "AUSENCIA DEL PEZÓN"
    },
    {
        "id": "0cf7ca1e-86e2-34ac-304f-cefded967718",
        "NOMBRE": "AUSENCIA E HIPOPLASIA CONGÉNITA DE LA ARTERIA UMBILICAL"
    },
    {
        "id": "a678b67b-dcbb-822b-e12f-b488b5fddded",
        "NOMBRE": "AUSENCIA Y AGENESIA DEL APARATO LAGRIMAL"
    },
    {
        "id": "96e780ba-2451-5a3b-ae2c-2529e754dbc9",
        "NOMBRE": "AUSENCIA Y APLASIA DEL TESTÍCULO"
    },
    {
        "id": "94477b81-34a2-92eb-a43a-26a1433f41ef",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DE OTRAS PARTES DEL INTESTINO GRUESO"
    },
    {
        "id": "23da2af7-375c-d08d-2f5d-0d393bf4be2f",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DE OTRAS PARTES ESPECIFICADAS DEL INTESTINO DELGADO"
    },
    {
        "id": "84fb3191-be21-7b24-f90a-1f3513b0567a",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL ANO, CON FÍSTULA"
    },
    {
        "id": "6d616027-7238-75b6-f673-9c1e3e80d53f",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL ANO, SIN FÍSTULA"
    },
    {
        "id": "3055814a-07ce-b16e-e8a3-b1e2f7e8629a",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL DUODENO"
    },
    {
        "id": "5a67e1b6-f729-9dc1-096f-88d9582b22ad",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL ÍLEON"
    },
    {
        "id": "be89d6d8-22cf-5943-9a7d-0357b069c5e7",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL INTESTINO DELGADO"
    },
    {
        "id": "08220a1f-48aa-6947-97b7-5c9b80f4bbb8",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL INTESTINO DELGADO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "f9343c0e-3148-73cc-4e70-2ece1c8ba485",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL INTESTINO GRUESO"
    },
    {
        "id": "40f1f799-9475-f1fe-647c-4849420ed408",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL INTESTINO GRUESO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "88a3e38f-ff67-7c25-986c-7ba4fdaabde7",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL RECTO, CON FÍSTULA"
    },
    {
        "id": "6f19f45f-7aca-321c-03e8-df0436318d3a",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL RECTO, SIN FÍSTULA"
    },
    {
        "id": "20d51463-602d-8e9a-9786-f81089a936ec",
        "NOMBRE": "AUSENCIA, ATRESIA Y ESTENOSIS CONGÉNITA DEL YEYUNO"
    },
    {
        "id": "00ac000c-22ae-6696-d55c-0b8fd9afb6c0",
        "NOMBRE": "AUTISMO ATÍPICO"
    },
    {
        "id": "2d736f68-933e-e910-0115-32162ff4a4b7",
        "NOMBRE": "AUTISMO EN LA NIÑEZ"
    },
    {
        "id": "58f66056-56d5-f663-17c0-43ccaf5ad9db",
        "NOMBRE": "AUTOSENSIBILIZACIÓN CUTÁNEA"
    },
    {
        "id": "507b8811-032c-0ac3-07e4-c4c1e5cb224c",
        "NOMBRE": "AVERSIÓN AL SEXO Y FALTA DE GOCE SEXUAL"
    },
    {
        "id": "d1b69239-c5ec-684f-22a6-6bbe1a3aec84",
        "NOMBRE": "AVULSIÓN DE OJO"
    },
    {
        "id": "9817bd94-d0c4-4700-cba8-20a57dba0ca2",
        "NOMBRE": "AVULSIÓN DEL CUERO CABELLUDO"
    },
    {
        "id": "e87a45e1-ade5-4f8c-c4f7-a3e12cd44792",
        "NOMBRE": "BABESIOSIS"
    },
    {
        "id": "1b234cf4-2d88-57d4-4a66-b81ec4d39466",
        "NOMBRE": "BACILLUS FRAGILIS [B. FRAGILIS] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "876b8a3f-c100-7e4d-c1c0-9f837cc01d95",
        "NOMBRE": "BAGAZOSIS"
    },
    {
        "id": "1c7968b5-221c-ba26-0f87-049061e4d6d2",
        "NOMBRE": "BAJO PESO PARA LA EDAD GESTACIONAL"
    },
    {
        "id": "103e2fe2-fdee-6019-89d8-e0ab375005c5",
        "NOMBRE": "BALANITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "dcbaae91-f34e-5088-b836-62414b4fa393",
        "NOMBRE": "BALANOPOSTITIS"
    },
    {
        "id": "829d5d9e-da5d-5550-b562-ee2c2302e374",
        "NOMBRE": "BALANTIDIASIS"
    },
    {
        "id": "52bd3005-4181-4c85-25a7-dba19f4ac45c",
        "NOMBRE": "BAROTRAUMA OTÍTICO"
    },
    {
        "id": "7ed2159c-b91b-7f64-0822-57e17c71f62c",
        "NOMBRE": "BAROTRAUMA SINUSAL"
    },
    {
        "id": "5fa155b9-0ba0-271c-116b-6e0787fbf45e",
        "NOMBRE": "BARTONELOSIS"
    },
    {
        "id": "1c2ec9de-46c9-db94-7dbb-7550aa3423f4",
        "NOMBRE": "BARTONELOSIS CUTÁNEA Y MUCOCUTÁNEA"
    },
    {
        "id": "c2c025ed-75ae-cfa0-12aa-2070e1feb266",
        "NOMBRE": "BARTONELOSIS SISTÉMICA"
    },
    {
        "id": "bc852bef-c0a0-23de-bc29-130c586dda35",
        "NOMBRE": "BARTONELOSIS, NO ESPECIFICADA"
    },
    {
        "id": "1e9fd07b-7426-efc4-b189-8f775b608acc",
        "NOMBRE": "BERIBERI"
    },
    {
        "id": "a1c426eb-8957-1eb4-153c-a97ede3c99cd",
        "NOMBRE": "BERILIOSIS"
    },
    {
        "id": "006f64f7-9de8-714a-9ac8-006752620aef",
        "NOMBRE": "BETA TALASEMIA"
    },
    {
        "id": "483c805d-abb8-231a-f277-3f28af3f5176",
        "NOMBRE": "BILIURIA"
    },
    {
        "id": "259e02c4-0e5f-24eb-f716-4f763fee3d94",
        "NOMBRE": "BISINOSIS"
    },
    {
        "id": "dcb4f5a4-8adf-8ffb-0890-918c16d5b62c",
        "NOMBRE": "BLASTOMICOSIS"
    },
    {
        "id": "099cae36-fb60-67aa-6b5d-7a9bdc942c0e",
        "NOMBRE": "BLASTOMICOSIS CUTÁNEA"
    },
    {
        "id": "cebba0b6-7250-145c-4b08-6c3a490f5bc2",
        "NOMBRE": "BLASTOMICOSIS DISEMINADA"
    },
    {
        "id": "48daf238-e327-c632-7028-315a1de2a612",
        "NOMBRE": "BLASTOMICOSIS PULMONAR AGUDA"
    },
    {
        "id": "d345af86-c906-a33c-56d4-feffa8524272",
        "NOMBRE": "BLASTOMICOSIS PULMONAR CRÓNICA"
    },
    {
        "id": "1d77aeac-9162-def7-643d-4995c974da8e",
        "NOMBRE": "BLASTOMICOSIS PULMONAR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "8356ad89-5037-a5f9-dab0-6d93f4d59a99",
        "NOMBRE": "BLASTOMICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "b9c891b7-6b06-4bd1-86c6-1d9e0d4718a8",
        "NOMBRE": "BLEFARITIS"
    },
    {
        "id": "aee2ad7d-37a1-ce79-fd5b-5c86db41e486",
        "NOMBRE": "BLEFAROCALASIA"
    },
    {
        "id": "26e871b3-de77-61ab-ec07-abfda6b0e3b5",
        "NOMBRE": "BLEFAROCONJUNTIVITIS"
    },
    {
        "id": "d2ea61c6-eccf-c387-cb29-5cc4052f297d",
        "NOMBRE": "BLEFAROPTOSIS"
    },
    {
        "id": "592250a1-f3a9-0e54-2042-649c7c467a57",
        "NOMBRE": "BLEFAROPTOSIS CONGÉNITA"
    },
    {
        "id": "9ada18af-7d69-ca48-e9d4-bba070d08679",
        "NOMBRE": "BLEFAROSPASMO"
    },
    {
        "id": "bd900cda-4004-8706-002d-5ea822e9d962",
        "NOMBRE": "BLOQUEO AURICULOVENTRICULAR COMPLETO"
    },
    {
        "id": "6f3a937f-29f7-acd8-319c-1823764968ae",
        "NOMBRE": "BLOQUEO AURICULOVENTRICULAR DE PRIMER GRADO"
    },
    {
        "id": "132af844-b4b6-9d2a-bcf7-2e17fd795938",
        "NOMBRE": "BLOQUEO AURICULOVENTRICULAR DE SEGUNDO GRADO"
    },
    {
        "id": "0dfa7f0e-1b27-5853-0dad-bc093f53a445",
        "NOMBRE": "BLOQUEO AURICULOVENTRICULAR Y DE RAMA IZQUIERDA DEL HAZ"
    },
    {
        "id": "d37da76c-9d13-84f9-99bb-c5ad6bd607bc",
        "NOMBRE": "BLOQUEO BIFASCICULAR"
    },
    {
        "id": "7866c100-56f8-5868-e567-736c0c9bce3d",
        "NOMBRE": "BLOQUEO CARDÍACO CONGÉNITO"
    },
    {
        "id": "fb5bd660-dc46-002c-744e-ea9675560235",
        "NOMBRE": "BLOQUEO DE RAMA IZQUIERDA DEL HAZ, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "8cd21541-057d-6064-5071-20138ae9c205",
        "NOMBRE": "BLOQUEO FASCICULAR ANTERIOR IZQUIERDO"
    },
    {
        "id": "caa053bb-fb5c-fd94-ac49-2cbfb1d92060",
        "NOMBRE": "BLOQUEO FASCICULAR DERECHO"
    },
    {
        "id": "bcddcc82-473a-6247-eb0e-50174f9deaa4",
        "NOMBRE": "BLOQUEO FASCICULAR POSTERIOR IZQUIERDO"
    },
    {
        "id": "b93f5598-522d-6b2e-3eaf-c6e2ec5ccaec",
        "NOMBRE": "BLOQUEO INTRAVENTRICULAR NO ESPECIFICADO"
    },
    {
        "id": "8a2877ea-887b-41c4-dba8-43139ff4d7ef",
        "NOMBRE": "BLOQUEO TRIFASCICULAR"
    },
    {
        "id": "59806539-d9ab-01db-33ad-b7d6f0d4b88d",
        "NOMBRE": "BOCA SECA, NO ESPECIFICADA"
    },
    {
        "id": "f4699f70-376f-d11a-e619-b046d9d1162c",
        "NOMBRE": "BOCIO (ENDÉMICO) RELACIONADO CON DEFICIENCIA DE YODO, NO ESPECIFICADO"
    },
    {
        "id": "21942801-f050-f646-d9a8-499282721f29",
        "NOMBRE": "BOCIO DIFUSO (ENDÉMICO) RELACIONADO CON DEFICIENCIA DE YODO"
    },
    {
        "id": "d316aaa2-86eb-36df-d6b8-ffdbd56689bf",
        "NOMBRE": "BOCIO DIFUSO NO TÓXICO"
    },
    {
        "id": "f0e82da8-dcb7-571c-5c1e-3318ffc0a181",
        "NOMBRE": "BOCIO DISHORMOGENÉTICO"
    },
    {
        "id": "a8b8576d-0897-370c-72af-c63db8ef41f5",
        "NOMBRE": "BOCIO MULTINODULAR (ENDÉMICO) RELACIONADO CON DEFICIENCIA DE YODO"
    },
    {
        "id": "a12ba85a-6b90-7e12-78e0-ba993f14e37b",
        "NOMBRE": "BOCIO MULTINODULAR NO TÓXICO"
    },
    {
        "id": "edbbefad-79a9-2621-fe7e-60cfa07172b5",
        "NOMBRE": "BOCIO NEONATAL, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "397892d1-21a6-e677-ecf8-09dce39855f4",
        "NOMBRE": "BOCIO NO TÓXICO, NO ESPECIFICADO"
    },
    {
        "id": "3c3b0f84-b7f7-874c-9067-598d93666287",
        "NOMBRE": "BOTULISMO"
    },
    {
        "id": "6797877c-6319-342a-0b5a-cc513489a765",
        "NOMBRE": "BRADICARDIA, NO ESPECIFICADA"
    },
    {
        "id": "5326f5c9-8f14-bd13-4459-725222edbe6b",
        "NOMBRE": "BROMHIDROSIS"
    },
    {
        "id": "60d32c49-30bf-8ef0-4803-26a2f6379829",
        "NOMBRE": "BRONCOMALACIA CONGÉNITA"
    },
    {
        "id": "06a9e082-b687-2b1b-828d-b07dc54e21e8",
        "NOMBRE": "BRONCONEUMONÍA, NO ESPECIFICADA"
    },
    {
        "id": "5ac3e547-eed3-00bc-6147-87aea19247a6",
        "NOMBRE": "BRONQUIECTASIA"
    },
    {
        "id": "f8a49155-9354-544e-11fe-af37b8e9a459",
        "NOMBRE": "BRONQUIECTASIA CONGÉNITA"
    },
    {
        "id": "b0af668d-9645-f0c5-7f1e-5e259be653ad",
        "NOMBRE": "BRONQUIOLITIS AGUDA"
    },
    {
        "id": "3794dbdb-ee71-dd35-27f4-70ca9f979376",
        "NOMBRE": "BRONQUIOLITIS AGUDA DEBIDA A METANEUMOVIRUS HUMANO"
    },
    {
        "id": "80ca3455-d803-5eae-90cd-b7addc711abd",
        "NOMBRE": "BRONQUIOLITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"
    },
    {
        "id": "28595f32-6532-1393-6d9a-49f0521cdb7a",
        "NOMBRE": "BRONQUIOLITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO"
    },
    {
        "id": "2d923b1a-4b0a-ad09-253b-9bcc6c8ac737",
        "NOMBRE": "BRONQUIOLITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "7c65b841-2071-557e-d1ce-35f0fb42634d",
        "NOMBRE": "BRONQUITIS AGUDA"
    },
    {
        "id": "130674af-3411-e386-ec7e-2e99341c02ed",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A ESTREPTOCOCOS"
    },
    {
        "id": "2d6f4132-14e4-de13-8fa8-bf4b1e6ac1ce",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A HAEMOPHILUS INFLUENZAE"
    },
    {
        "id": "bff2579b-3aef-f363-b07c-1da9d512e019",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A MYCOPLASMA PNEUMONIAE"
    },
    {
        "id": "37b6b0d7-7578-435a-5e11-a594b7a8a853",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"
    },
    {
        "id": "ebab85b6-aca6-69ad-375d-93723fbda668",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A RINOVIRUS"
    },
    {
        "id": "4cf783fe-e685-a2ab-97bb-cf9ecc51960c",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A VIRUS COXSACKIE"
    },
    {
        "id": "b6b1950d-9116-a517-7a74-9dd20a0af183",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A VIRUS ECHO"
    },
    {
        "id": "d90251df-b0a9-2ace-41ba-41f09ad61e75",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A VIRUS PARAINFLUENZA"
    },
    {
        "id": "4400edc7-1d04-6e79-2f05-f4998e38353a",
        "NOMBRE": "BRONQUITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO"
    },
    {
        "id": "6758b3c8-06fb-9e5e-0b54-4ae7f652ff17",
        "NOMBRE": "BRONQUITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "57d4a964-cdc2-735b-5cfa-3b30fbd048b5",
        "NOMBRE": "BRONQUITIS CRÓNICA MIXTA SIMPLE Y MUCOPURULENTA"
    },
    {
        "id": "eabe4310-2603-238d-98da-f98c7d1c914c",
        "NOMBRE": "BRONQUITIS CRÓNICA MUCOPURULENTA"
    },
    {
        "id": "28e3b6d9-f443-c494-e1de-798cad993c49",
        "NOMBRE": "BRONQUITIS CRÓNICA NO ESPECIFICADA"
    },
    {
        "id": "ebdda934-0349-5826-f87e-42de8aff5567",
        "NOMBRE": "BRONQUITIS CRÓNICA SIMPLE"
    },
    {
        "id": "823c1702-a840-0615-92ce-33178434d037",
        "NOMBRE": "BRONQUITIS CRÓNICA SIMPLE Y MUCOPURULENTA"
    },
    {
        "id": "174c7a5b-0761-9fea-fbce-0dede530721b",
        "NOMBRE": "BRONQUITIS Y NEUMONITIS DEBIDAS A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"
    },
    {
        "id": "410aee4e-8803-6764-6d74-89feb936bb8f",
        "NOMBRE": "BRONQUITIS, NO ESPECIFICADA COMO AGUDA O CRÓNICA"
    },
    {
        "id": "08ef7a13-431a-42cf-9813-5d5b9d713a86",
        "NOMBRE": "BRUCELOSIS"
    },
    {
        "id": "34faa1fe-48b7-090e-389d-842bdda2b10f",
        "NOMBRE": "BRUCELOSIS DEBIDA A BRUCELLA ABORTUS"
    },
    {
        "id": "036073d1-2a93-f4a5-0a44-879e6c788d99",
        "NOMBRE": "BRUCELOSIS DEBIDA A BRUCELLA CANIS"
    },
    {
        "id": "83009af1-9253-18ea-4572-ec4d1d4bc060",
        "NOMBRE": "BRUCELOSIS DEBIDA A BRUCELLA MELITENSIS"
    },
    {
        "id": "c47b67c0-581f-2ad1-fa64-b455b28f713b",
        "NOMBRE": "BRUCELOSIS DEBIDA A BRUCELLA SUIS"
    },
    {
        "id": "d1453e72-4b39-9e95-7741-d9f48ce07efb",
        "NOMBRE": "BRUCELOSIS, NO ESPECIFICADA"
    },
    {
        "id": "b9ad24ee-c3a1-193f-fbf7-2827a01919fe",
        "NOMBRE": "BULIMIA NERVIOSA"
    },
    {
        "id": "f98eee91-b285-1eb7-e795-acce206dd492",
        "NOMBRE": "BULIMIA NERVIOSA ATÍPICA"
    },
    {
        "id": "5ba47156-3d99-c039-af85-146700cf7de4",
        "NOMBRE": "BURSITIS DE LA MANO"
    },
    {
        "id": "1f17fc51-4701-ec24-0d01-d0f9ca16aa38",
        "NOMBRE": "BURSITIS DEL HOMBRO"
    },
    {
        "id": "83317964-19e2-a10b-29bd-9b360c0d33e3",
        "NOMBRE": "BURSITIS DEL OLÉCRANON"
    },
    {
        "id": "b2f243e5-f659-c026-a3b7-ff689171381c",
        "NOMBRE": "BURSITIS DEL TROCÁNTER"
    },
    {
        "id": "df8f29c5-6b71-1a0a-7a74-39f62f4840d4",
        "NOMBRE": "BURSITIS GONOCÓCICA"
    },
    {
        "id": "21391d02-8561-733e-501c-4f4e726e39fe",
        "NOMBRE": "BURSITIS REUMATOIDE"
    },
    {
        "id": "704fd2b8-cc41-95e3-072d-92dc30b17004",
        "NOMBRE": "BURSITIS SIFILÍTICA"
    },
    {
        "id": "7e4f405f-78c5-6104-9b63-f82b838f3f89",
        "NOMBRE": "BURSITIS TIBIAL COLATERAL [PELLEGRINI-STIEDA]"
    },
    {
        "id": "ed1e001a-b8b3-8a55-dbd1-39d77d3778a7",
        "NOMBRE": "BURSOPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "154f1a37-6cfd-cbf4-150c-ee32b4c4085e",
        "NOMBRE": "CADERA INESTABLE"
    },
    {
        "id": "c2d445cb-f29d-927e-20f0-f64559faf8b4",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS"
    },
    {
        "id": "596a287a-b924-4632-41ec-262c555d0483",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "cadb58d4-9618-3eba-0196-966037faa8fc",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0931955b-3b10-8840-2d8a-61f21f5e3b81",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, CALLES Y CARRETERAS"
    },
    {
        "id": "f4d4e1b6-5767-68f7-a26f-b87230227673",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "84c45639-9041-32d6-eb3e-b118530047a8",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6288a685-f797-9ce9-c3f7-80c4e61d822c",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, GRANJA"
    },
    {
        "id": "4e25f544-445d-d788-123a-077ca0a5e86c",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a2ee8efb-5681-414a-7cba-81cb1db846f8",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ea14f6a4-88da-7950-202c-d5d716640c12",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "709cf5ca-8adb-f9e8-434e-314691030ee9",
        "NOMBRE": "CAÍDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, VIVIENDA"
    },
    {
        "id": "e3ae0693-196a-6e19-c661-98c87e7f4439",
        "NOMBRE": "CAÍDA DESDE PEÑASCO"
    },
    {
        "id": "a273f1c0-ede3-e5c2-fb43-c9efdfe65c2a",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f86e4a0b-cc16-1edb-3b2e-0459e200d1b6",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a9846c11-4edd-ea19-7086-982d3bcba884",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, CALLES Y CARRETERAS"
    },
    {
        "id": "9308ada3-4691-6699-31c0-85227621ae07",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "02517c9f-615a-2895-5258-e05fdbb97c05",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "d6404653-94f6-cbf8-9da0-b250cb452317",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, GRANJA"
    },
    {
        "id": "db12a14c-fb4e-b57a-ebaa-48c571ad968d",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "32f63e8d-3e38-98b0-09f6-e89a27bcfbf7",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8675adfc-69b4-c944-37b9-5acd1f579c0b",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "730892b3-6c87-ff97-293f-af2c4b9f9c7c",
        "NOMBRE": "CAÍDA DESDE PEÑASCO, VIVIENDA"
    },
    {
        "id": "e7434ec2-c98d-859a-50c5-e556e7a7440f",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL"
    },
    {
        "id": "55a2731e-69d1-1183-390a-0e1641b6be2a",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "3e47dcb3-9add-d812-948f-23ae324fecfb",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "48d394dd-6731-1b18-e84e-3ed3abb4babc",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, CALLES Y CARRETERAS"
    },
    {
        "id": "d9efae80-e1d3-cdc1-8e97-efd40105e6fd",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "aa060be6-6b55-d25d-044f-bdfd05aec42b",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2ae6a76f-e706-4f5c-07a7-3688b3290d02",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, GRANJA"
    },
    {
        "id": "a4ac8eef-c1e4-0bff-03ff-cd94364155e9",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cbcb66f0-84bf-1616-276d-96f954348ef3",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a33c28f9-f7b2-88f4-2d55-f394d013f9ff",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "3e3a156a-cc64-7226-084b-f72f4f1f6e8d",
        "NOMBRE": "CAÍDA DESDE UN ÁRBOL, VIVIENDA"
    },
    {
        "id": "5c51e78f-29c3-0df5-f3af-7b9ba45711a4",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN"
    },
    {
        "id": "91f6d182-a3ad-6e7c-f3dd-ff43c608b04b",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "de240251-d6d4-cd0b-5b14-a029bc6727db",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "464e30b3-f826-df46-8eed-623ec3c7a6b8",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "568e0fb2-3898-cdc5-1b6c-b97673f7e379",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "d6f87162-2e67-7dff-a116-47dfe2ee694d",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ea247733-dfb7-0a77-b625-ba2caa2f1471",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, GRANJA"
    },
    {
        "id": "462fa159-2283-942b-104a-6c7d16e83951",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cd53c79f-b94a-808c-7abd-8b36b664ecff",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "58f92288-70de-de7d-fcd4-538cbfe28a2d",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "7a7fa092-7cc5-fd71-1c91-794e4742ec2b",
        "NOMBRE": "CAÍDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCIÓN, VIVIENDA"
    },
    {
        "id": "06f0e51e-ddd7-c3ff-5f4b-2e906e982c57",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ"
    },
    {
        "id": "58a5325a-447d-28f6-75f8-ccaad86ae0ed",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f006d15f-cef2-fd14-69c2-06a5c355cc4f",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "71d9ea14-cf36-6e08-35c5-0cd6dddedffb",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, CALLES Y CARRETERAS"
    },
    {
        "id": "1c1aff36-ad82-0acf-3849-6ece726530b0",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "fa93e432-a13e-ca9e-a504-d3a1b7b9c5ed",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6cb788b3-e1dd-6be2-444b-5dc03f57b689",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, GRANJA"
    },
    {
        "id": "4d7ed755-1901-b471-63f2-3140b3baed18",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "fff384aa-f996-73af-5e15-17095f25b7fe",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "52b919c8-4a3b-b068-0fe7-25b2ec2434db",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e10cec6c-176f-33e0-51b5-e861df941acb",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZÓN Y TRASPIÉ, VIVIENDA"
    },
    {
        "id": "c045462e-c382-cb84-96d8-c6cd0f71ca1b",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE"
    },
    {
        "id": "500edaf1-ea8e-615b-ef18-cd1e8e6e9930",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8478a07e-d093-2241-daae-7f8780c7d0c5",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2049eab2-0a1c-455f-25c0-8fcde59a3b48",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, CALLES Y CARRETERAS"
    },
    {
        "id": "552e2b34-8578-96bd-f57b-16aa47ae8eb2",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "63e75a50-2194-5fa5-d977-5a26bed2c2c5",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "f717174c-d3d0-cb3b-48a7-cb899bde25e0",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, GRANJA"
    },
    {
        "id": "64dc44fc-9347-2643-96bc-db58fcdf0144",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cc787625-ead8-584d-8243-25424a954568",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9b83dc60-92a7-19b2-57f9-de63d2f351fe",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6d057f96-f4d8-77c3-505d-1aaaa8e6fffe",
        "NOMBRE": "CAÍDA EN EL MISMO NIVEL POR HIELO O NIEVE, VIVIENDA"
    },
    {
        "id": "ba76125d-12fc-7445-05a9-3e87550de0f2",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO"
    },
    {
        "id": "31b97b30-986e-8b75-3140-1beb781c5f6e",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "24a7e291-e1c9-cb2a-9b25-6030896b5b0e",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e09ef478-d481-b9a0-c0be-bd2b33ca805f",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, CALLES Y CARRETERAS"
    },
    {
        "id": "ed0ffcee-34dc-5391-bc31-8149addf7151",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5fdd05da-5d1c-f1f9-bea3-7e21c2882631",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "026e8db4-3163-dd55-fed6-e72822a139e0",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, GRANJA"
    },
    {
        "id": "ebbfa405-4ebb-60f9-2152-3ed5360959a5",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f4ec54cb-74a5-fa00-10ab-043213f14da8",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "20518f03-e37f-97a4-904d-799fb5166804",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "88114b0f-12e8-ef09-35d9-7d3c2cd8d760",
        "NOMBRE": "CAÍDA EN O DESDE ANDAMIO, VIVIENDA"
    },
    {
        "id": "c0b5b78e-04ba-8697-a9d3-b8bab74fe5ea",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA MANUALES"
    },
    {
        "id": "d2b4f19e-35b1-332a-6539-d3d246b33d77",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA O ESCALONES"
    },
    {
        "id": "4738eb2f-e7a8-ba6f-17af-0787822f7737",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "daa57c14-cb53-d571-712e-e1cb2ec89213",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2edeb762-7f9a-4fd9-e013-fd20e17407a9",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, CALLES Y CARRETERAS"
    },
    {
        "id": "7e31dc60-3c60-6adf-0b87-74ef38ff03d6",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a8198be3-edbd-ff0a-8248-da7f86cb5417",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "323d0575-1f85-c237-f886-9eabc241910f",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, GRANJA"
    },
    {
        "id": "8499a9cb-e73b-4c7e-f99e-e2fe6c6af85c",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3e34799d-4986-cc93-0740-deafed157e8d",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "dee6a757-6c03-0b74-04d5-c01fecde511d",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a697bb7d-fef8-ad46-b6d8-9a454eebe609",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERA Y ESCALONES, VIVIENDA"
    },
    {
        "id": "19a1ffbd-f856-7e34-dbd2-165b3ce29491",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "46d2eb1c-aa14-06b0-a999-e4145ba5078e",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "621d5b6f-14ed-f799-3e4d-a234bc0e97ef",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, CALLES Y CARRETERAS"
    },
    {
        "id": "18df9edd-cf82-dba1-b267-ddf7e9a9bbd5",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3fdae3b7-3a46-b879-95f1-66f10215bf27",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ed9c95fd-90ce-5f55-ec87-636a3b50463a",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, GRANJA"
    },
    {
        "id": "0b242e7d-ba5f-b690-52a4-10cb6cff7f5e",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7153702c-36cc-464a-877a-e31a1d688058",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "04d7928b-f987-ad73-3e08-e6711dd4a857",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "c736b72b-2c65-7f8c-7b23-ea262b3ebae7",
        "NOMBRE": "CAÍDA EN O DESDE ESCALERAS MANUALES, VIVIENDA"
    },
    {
        "id": "a47ec6f6-fbf5-1ae9-4f69-8750033ae9af",
        "NOMBRE": "CAÍDA NO ESPECIFICADA"
    },
    {
        "id": "0e340a84-66d7-4b6f-9503-dfe1c79836e2",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d54d476b-ee2c-6c9a-8627-c37b908bda9b",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0b6da53f-bd39-ecc7-77f4-834f9a56ded8",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, CALLES Y CARRETERAS"
    },
    {
        "id": "deee3432-44cb-2cd5-64e4-bf8d950a7997",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "06bdada4-4778-f97b-4ef9-32f966c20d0c",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a5507df1-8861-6f3a-91e4-27a30a754811",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, GRANJA"
    },
    {
        "id": "ff330839-b16f-49b9-05e9-f41fa1f31b73",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "347ecf06-8405-8c21-afa0-bca1224afb4a",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5bfa9022-8e8a-e042-c644-f719aa034d6b",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "21b05654-ce0f-f9a8-78e3-d1642a966043",
        "NOMBRE": "CAÍDA NO ESPECIFICADA, VIVIENDA"
    },
    {
        "id": "0ab14c87-fc6a-1d48-9689-c3cf4050fff8",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA"
    },
    {
        "id": "bfe92032-b0f2-5a6d-beff-cd10ba62fe2d",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7f972394-95c8-2223-b20b-6131eb94e006",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5d88e4f5-cb31-8293-f275-f57a4fb2bd55",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, CALLES Y CARRETERAS"
    },
    {
        "id": "2095ab19-3546-5e34-6ce1-e491c7269ebf",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "7b112173-8941-deaf-abd5-6928aa3aaa1e",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "f530acd7-69d4-e907-2b92-14185b1e92cc",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, GRANJA"
    },
    {
        "id": "25f1b7e4-7ed1-d81d-d900-00024671657d",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "53b235d2-cb4b-dea6-b303-387f82f0553b",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c227bfb1-d454-28b0-7a9a-3c0c9473f981",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "637bd6d3-c556-fb9e-1743-6c7861868f0e",
        "NOMBRE": "CAÍDA POR PATINES PARA HIELO, ESQUÍS, PATINES DE RUEDAS O PATINETA, VIVIENDA"
    },
    {
        "id": "cd7029d2-b4ce-c100-ab4e-f708a3dee8e5",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA"
    },
    {
        "id": "fb503ae5-0f00-3519-7e06-9fe365fc7371",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "ab89cf44-cdb7-e1f2-a8f0-d1db6d66ac4b",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e29f8b23-9e36-f9db-4c62-d0027d807360",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, CALLES Y CARRETERAS"
    },
    {
        "id": "3cbc5421-7658-3c5c-bbb8-1ee7b33f3887",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a2a2eae3-ac98-2a25-7af4-b9f69e216dcd",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0ee48f6f-9746-0f57-d3ff-b27ec8846673",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, GRANJA"
    },
    {
        "id": "96d25e38-430a-1119-d623-dc1c5bd45248",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "46cc2432-8897-ca43-9124-4fb83bdbc423",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9a5fc575-7bd7-6bbf-5a41-01f1fc46834d",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "21e76c04-6524-eaeb-9f2e-a8020ec30312",
        "NOMBRE": "CAÍDA QUE IMPLICA CAMA, VIVIENDA"
    },
    {
        "id": "aadd0c7a-dc5c-345e-db88-6d527ebbd7da",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES"
    },
    {
        "id": "3a648e0b-5d59-5596-0573-67a59eecffd1",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f6456cfc-baa4-e0c6-be09-c1ed8d9570d5",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "9f0e4c4c-b593-f7f0-8f7f-73c1d4749108",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, CALLES Y CARRETERAS"
    },
    {
        "id": "6c0bd0ad-6fe2-5bc8-8092-0bc6eadb9683",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0992deff-6414-7bf3-f0fe-147bf61c0fc8",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e7b78bd2-88d4-9f5c-c559-af29aa056462",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, GRANJA"
    },
    {
        "id": "f826f41c-e248-4c4e-2850-16248ceb085f",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e8e7954b-243d-7cf4-8b9e-5d763fe2ba5e",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "1641debb-6d1a-d6d6-987e-12ad84b08706",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "7d3e9477-3693-446c-ec39-9cd3c76d48be",
        "NOMBRE": "CAÍDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, VIVIENDA"
    },
    {
        "id": "ee19faa2-9c5f-eae1-cf38-0cfc2ffd994f",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE"
    },
    {
        "id": "38bd2139-5b99-7561-fc34-f7ed15668f3f",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "1e84036c-9c40-2536-0d82-b9a33c4e00e3",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "1119b55a-6715-4bde-f0aa-bb1bbf1c23fa",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, CALLES Y CARRETERAS"
    },
    {
        "id": "e502c152-acc9-f3f0-685c-599b0c1f4325",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "cf09761b-40df-08d0-15e9-c2ae1efaaf38",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "035fa777-5464-3853-6d02-eb4b99319aed",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, GRANJA"
    },
    {
        "id": "2a5c6c24-3217-968c-b86f-cf2e1df49136",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7f5d89e5-f49f-986d-27dd-fb160e6722cb",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f485944f-247d-880a-51b6-b9ac972224d9",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "52980bac-50b3-f74a-5a5a-3319173ca478",
        "NOMBRE": "CAÍDA QUE IMPLICA OTRO MUEBLE, VIVIENDA"
    },
    {
        "id": "dd08ce15-76b8-546a-13ba-449f7f4af64f",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA"
    },
    {
        "id": "393d523e-2897-83a4-240b-134f77a0218c",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS"
    },
    {
        "id": "04d28bdc-5ce0-8e9a-470c-44c227d71dfa",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "cc6764e4-7cb8-1516-0926-cddeacf329bb",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "69091071-11f2-366f-7c75-de60a52fed2e",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, CALLES Y CARRETERAS"
    },
    {
        "id": "56fb0bc5-007f-bc2a-85bd-50b17db25e94",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "2e7bd833-280c-db9e-a39d-0e7a3acae7ab",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b691a086-c652-101f-008a-f7179df338ed",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, GRANJA"
    },
    {
        "id": "fa3bc25f-77b3-197c-1c8d-6a45ebb4d6f8",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "85fe02e3-576e-4b33-c8e3-24b61f1f5158",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "be4bbe34-52d0-df85-1491-f788f759ccda",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d7ee44d3-b4c9-e44a-ac91-6b4ffcff3ab9",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA DE RUEDAS, VIVIENDA"
    },
    {
        "id": "021adbb9-5a7e-2331-2465-6efb8a4a81a1",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8277a5c7-5b7b-1550-0c55-81b3000b611e",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ae8a4f65-cfd3-978c-2954-04d223d7787b",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, CALLES Y CARRETERAS"
    },
    {
        "id": "e514031b-3c00-8b7d-3f91-c248ab03e66a",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1345002c-56b9-b983-54c2-38c578b0d11f",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "302f892c-a0a3-a356-76eb-e2e94db007f9",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, GRANJA"
    },
    {
        "id": "940d8e5f-8cd9-6ca7-9ca9-0b680c83c149",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "da02a95b-81f8-3c48-a8ca-bfcf14050ff8",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ab6f4d16-6163-e46c-7996-1d3689513aa7",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "f5342a11-063b-2cf4-62c6-9fbb746faad5",
        "NOMBRE": "CAÍDA QUE IMPLICA SILLA, VIVIENDA"
    },
    {
        "id": "7fb7401d-c1bf-5490-c42f-594d74e9ec9a",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "4fe58a37-a02b-0daa-d795-7ae013b479ea",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a707b8e6-71ad-2280-4dac-f868e39523b8",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e4edcad4-da78-3577-1ce9-a1cfa0316f66",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "a3d2d407-6042-3e89-ca1f-532f813732ad",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "2568ad9e-b300-1387-e344-0ce46c1e753c",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0cc51cee-3f0a-bbb3-d180-66d15bdb9578",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "75109fe2-bdf6-332e-afad-a3035d76f5e6",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a37ff27d-95f0-26f8-c2e4-d89817cec3d1",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a724ef45-91e0-73c1-666e-506da736b9ad",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "857d31ed-b82d-5396-6e0c-d5df7c8c0ac4",
        "NOMBRE": "CAÍDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "8a3d6fff-a192-3b5a-7ecf-6a4323e5eed2",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "fa59cb23-0bb0-581f-b3d5-8512abe5f6e6",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "3ec98846-70c0-0865-b90e-288f0a6d489a",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "42cb91e2-285e-a734-0250-07e302e1a544",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "7241ba0c-1ea2-2bfb-496a-b1354c99dbc3",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "7cf78f37-cef2-eb74-c9c3-e1cf555ee363",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "46a0a4c9-f74f-4bc5-9088-a74faf8b0f00",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "aacfd909-10ab-1aa2-dc2a-98f5d04223d4",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "472ea637-4c08-cfdb-43ac-7b062db58a58",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "600c5e03-247e-1e75-f162-67c7127e5340",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "9665be1d-441c-160f-a881-c01f8f590871",
        "NOMBRE": "CAÍDA, SALTO O EMPUJÓN DESDE LUGAR ELEVADO, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "d68f4431-01d0-9e19-cc9b-06842cead28c",
        "NOMBRE": "CALACIO [CHALAZIÓN]"
    },
    {
        "id": "9934d451-7e5b-77d7-0022-592a6ddbeec5",
        "NOMBRE": "CALAMBRE POR CALOR"
    },
    {
        "id": "a5ad2db8-ecbe-4f89-a47c-cc40eb339556",
        "NOMBRE": "CALAMBRES Y ESPASMOS"
    },
    {
        "id": "e7a13c1a-a6c2-00e9-b367-3415e3c8b323",
        "NOMBRE": "CALCIFICACIÓN Y OSIFICACIÓN DE LOS MÚSCULOS ASOCIADAS CON QUEMADURAS"
    },
    {
        "id": "efe3bf2f-ea75-67d7-c384-4cd37d589d2e",
        "NOMBRE": "CALCIFICACIÓN Y OSIFICACIÓN DEL MÚSCULO"
    },
    {
        "id": "0b386382-be12-f85f-c370-223974ecdb34",
        "NOMBRE": "CALCIFICACIÓN Y OSIFICACIÓN DEL MÚSCULO, NO ESPECIFICADA"
    },
    {
        "id": "47d81a34-0f75-4ce6-4669-e5ebed692bf2",
        "NOMBRE": "CALCIFICACIÓN Y OSIFICACIÓN PARALÍTICA DEL MÚSCULO"
    },
    {
        "id": "aa0f8679-e977-b5c0-4fe5-b8a4b54aa55f",
        "NOMBRE": "CALCINOSIS DE LA PIEL"
    },
    {
        "id": "e4180685-c40c-5207-6029-4b07f7bdca62",
        "NOMBRE": "CÁLCULO DE CONDUCTO BILIAR CON COLANGITIS"
    },
    {
        "id": "8a1821fc-4e95-714c-62ed-5289740704b5",
        "NOMBRE": "CÁLCULO DE CONDUCTO BILIAR CON COLECISTITIS"
    },
    {
        "id": "904be3cc-c06f-29e8-1f72-2a48b000e650",
        "NOMBRE": "CÁLCULO DE CONDUCTO BILIAR SIN COLANGITIS NI COLECISTITIS"
    },
    {
        "id": "a019f31b-26f4-6caa-d267-f1ba13ec2d8b",
        "NOMBRE": "CÁLCULO DE LA PRÓSTATA"
    },
    {
        "id": "d0492717-1d22-1d33-0c0f-615873c39245",
        "NOMBRE": "CÁLCULO DE LA VESÍCULA BILIAR CON COLECISTITIS AGUDA"
    },
    {
        "id": "9eedb2fd-266c-b988-cb16-aef7092fdec4",
        "NOMBRE": "CÁLCULO DE LA VESÍCULA BILIAR CON OTRA COLECISTITIS"
    },
    {
        "id": "30d2f626-e60e-3016-320c-4920aafa9785",
        "NOMBRE": "CÁLCULO DE LA VESÍCULA BILIAR SIN COLECISTITIS"
    },
    {
        "id": "6ffb57ce-61ae-a7d8-7f79-ac5ffd4d8144",
        "NOMBRE": "CÁLCULO DE LAS VÍAS URINARIAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a8450e59-9f7f-b420-6b40-4212824245e2",
        "NOMBRE": "CÁLCULO DE LAS VÍAS URINARIAS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3fb112d5-fb59-369d-991b-5b98af8afd42",
        "NOMBRE": "CÁLCULO DE LAS VÍAS URINARIAS INFERIORES"
    },
    {
        "id": "0bcd35c4-8bad-ff19-174f-73e86897b509",
        "NOMBRE": "CÁLCULO DE LAS VÍAS URINARIAS INFERIORES, NO ESPECIFICADO"
    },
    {
        "id": "50a29003-f5a7-ae90-0bfc-55d9feb7ab19",
        "NOMBRE": "CÁLCULO DEL RIÑÓN"
    },
    {
        "id": "b8ac3ccb-fd5e-ef44-9a3e-5c857cfc6639",
        "NOMBRE": "CÁLCULO DEL RIÑÓN CON CÁLCULO DEL URÉTER"
    },
    {
        "id": "c8c1b2ad-3e53-56f5-13c8-fad03003c388",
        "NOMBRE": "CÁLCULO DEL RIÑÓN Y DEL URÉTER"
    },
    {
        "id": "ef257292-8bb5-9bf7-8882-53090c74c219",
        "NOMBRE": "CÁLCULO DEL URÉTER"
    },
    {
        "id": "7f5c3c6c-09ae-5dc0-9215-884c477d3d7a",
        "NOMBRE": "CÁLCULO EN LA URETRA"
    },
    {
        "id": "f6ca70a7-d1eb-dde6-afd9-91ca44c866ef",
        "NOMBRE": "CÁLCULO EN LA VEJIGA"
    },
    {
        "id": "b3737377-0d7e-2520-9335-44e588683dc7",
        "NOMBRE": "CÁLCULO URINARIO, NO ESPECIFICADO"
    },
    {
        "id": "d32089db-4787-11d1-d906-192ea1eb1f9a",
        "NOMBRE": "CALLOS Y CALLOSIDADES"
    },
    {
        "id": "f20e27d0-3cfd-fd27-d3b0-a1a46f89251a",
        "NOMBRE": "CAMBIO AGUDO DE LA PIEL DEBIDO A RADIACIÓN ULTRAVIOLETA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "7f75fafc-001d-a210-0438-4a2aacf7d07e",
        "NOMBRE": "CAMBIO PERDURABLE DE LA PERSONALIDAD CONSECUTIVO A UNA ENFERMEDAD PSIQUIÁTRICA"
    },
    {
        "id": "c189c51a-bc06-1805-e94f-764d3c21cabc",
        "NOMBRE": "CAMBIO PERDURABLE DE LA PERSONALIDAD DESPUÉS DE UNA EXPERIENCIA CATASTRÓFICA"
    },
    {
        "id": "6f6db9e3-a1dd-f058-2442-61381f1b74db",
        "NOMBRE": "CAMBIO PERDURABLE DE LA PERSONALIDAD, NO ESPECIFICADO"
    },
    {
        "id": "34a28902-91c1-0644-ef63-9c78e7da59b5",
        "NOMBRE": "CAMBIOS DE LA PIEL DEBIDOS A EXPOSICIÓN CRÓNICA A RADIACIÓN NO IONIZANTE"
    },
    {
        "id": "821ccf17-151c-d60b-0054-c0e7f2b93793",
        "NOMBRE": "CAMBIOS DE LA PIEL DEBIDOS A EXPOSICIÓN CRÓNICA A RADIACIÓN NO IONIZANTE, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "3b7b9435-c497-6a6d-9a1c-bb8a5b62e02f",
        "NOMBRE": "CAMBIOS EN LA TEXTURA DE LA PIEL"
    },
    {
        "id": "b13d9cfc-2d02-24b2-8cd5-a442a2b30e9c",
        "NOMBRE": "CAMBIOS EN LAS MEMBRANAS DE LA CÓRNEA"
    },
    {
        "id": "14b22750-0db0-a359-e028-1cd800302969",
        "NOMBRE": "CAMBIOS EN LOS HÁBITOS INTESTINALES"
    },
    {
        "id": "ff075e4a-45a4-4f1c-df8b-59839b2c222b",
        "NOMBRE": "CAMBIOS PERDURABLES DE LA PERSONALIDAD, NO ATRIBUIBLES A LESIÓN O A ENFERMEDAD CEREBRAL"
    },
    {
        "id": "4838935e-4cc1-8b2b-5340-20decad4379c",
        "NOMBRE": "CAMBIOS POSTERUPTIVOS DEL COLOR DE LOS TEJIDOS DENTALES DUROS"
    },
    {
        "id": "aeeee5b6-d444-3632-ae79-8a5e1fb69ba4",
        "NOMBRE": "CANABINOSIS"
    },
    {
        "id": "b84715c9-3761-02d7-a5e9-c0112bc84879",
        "NOMBRE": "CANDIDIASIS"
    },
    {
        "id": "3bfaa020-a6e6-0620-cf7b-69950e0f7018",
        "NOMBRE": "CANDIDIASIS DE LA PIEL Y LAS UÑAS"
    },
    {
        "id": "8b9d6bc3-d9ee-c89d-bc53-136428b26644",
        "NOMBRE": "CANDIDIASIS DE LA VULVA Y DE LA VAGINA"
    },
    {
        "id": "8efde4ec-76ec-4172-fe9a-1aa30e0deaa2",
        "NOMBRE": "CANDIDIASIS DE OTRAS LOCALIZACIONES UROGENITALES"
    },
    {
        "id": "c8ee20ef-6133-0915-b8e1-14542e65cc81",
        "NOMBRE": "CANDIDIASIS DE OTROS SITIOS"
    },
    {
        "id": "bbce7b32-7906-596c-66e2-736cdd2b6861",
        "NOMBRE": "CANDIDIASIS NEONATAL"
    },
    {
        "id": "adad7f07-63f4-772c-10e5-f053a1b6a9bd",
        "NOMBRE": "CANDIDIASIS PULMONAR"
    },
    {
        "id": "bff2fcaa-28bb-382e-3f57-7b9644210bfe",
        "NOMBRE": "CANDIDIASIS, NO ESPECIFICADA"
    },
    {
        "id": "e1a4735c-f454-5095-42c3-2ccb5a6b0a67",
        "NOMBRE": "CAPILARIASIS INTESTINAL"
    },
    {
        "id": "4a4630e2-55f6-a73e-84ff-7203fd5d7975",
        "NOMBRE": "CAPSULITIS ADHESIVA DEL HOMBRO"
    },
    {
        "id": "a5c705b2-66a3-3adc-bff8-35d2393fb83e",
        "NOMBRE": "CAPUT SUCCEDANEUM DEBIDO A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "7b5115dc-031e-e286-e09c-1fe60665affd",
        "NOMBRE": "CAQUEXIA"
    },
    {
        "id": "5df16399-8f11-3c5f-1207-c8f58fe2d17c",
        "NOMBRE": "CARBUNCO [ÁNTRAX]"
    },
    {
        "id": "49044282-ef17-6700-2ccf-d1786ab95f8e",
        "NOMBRE": "CARBUNCO CUTÁNEO"
    },
    {
        "id": "84a8a602-fbd4-ade7-c40d-8c831096e120",
        "NOMBRE": "CARBUNCO GASTROINTESTINAL"
    },
    {
        "id": "58cdca5d-925e-9db5-70c4-1ca5a013e9ae",
        "NOMBRE": "CARBUNCO PULMONAR"
    },
    {
        "id": "c1fd3d1e-b1e0-17e5-802e-bb59625ef830",
        "NOMBRE": "CARBUNCO SÉPTICO"
    },
    {
        "id": "46f848fe-fc70-ae58-6f8e-bf9a8ab32a13",
        "NOMBRE": "CARBUNCO, NO ESPECIFICADO"
    },
    {
        "id": "fdcd39de-d4f7-ab93-a7e6-e9322b6bd0ae",
        "NOMBRE": "CARCINOMA DE CÉLULAS HEPÁTICAS"
    },
    {
        "id": "32cd6c80-182e-0234-8061-80ef14194375",
        "NOMBRE": "CARCINOMA DE VÍAS BILIARES INTRAHEPÁTICAS"
    },
    {
        "id": "353f6798-46fd-8cf8-3c76-14cd49d12628",
        "NOMBRE": "CARCINOMA IN SITU DE LA CAVIDAD BUCAL, DEL ESÓFAGO Y DEL ESTÓMAGO"
    },
    {
        "id": "1eee93e1-cbab-c251-8ff5-e466770e8782",
        "NOMBRE": "CARCINOMA IN SITU DE LA GLÁNDULA TIROIDES Y DE OTRAS GLÁNDULAS ENDOCRINAS"
    },
    {
        "id": "196ca1f4-0296-c63b-d3b5-5e8c358b0679",
        "NOMBRE": "CARCINOMA IN SITU DE LA LARINGE"
    },
    {
        "id": "d307765a-1319-1b26-1620-379a8bfd84ae",
        "NOMBRE": "CARCINOMA IN SITU DE LA MAMA"
    },
    {
        "id": "45b4c069-b787-41d4-3923-4840c568d578",
        "NOMBRE": "CARCINOMA IN SITU DE LA MAMA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "334b1a8c-7a70-6d81-050f-c378ce22687a",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL"
    },
    {
        "id": "b996206d-9480-1c18-fc2b-862c0daaea4d",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"
    },
    {
        "id": "ff38a66e-d6b5-9afb-f4b1-5bdc6f0e1077",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"
    },
    {
        "id": "503dab98-cb25-d9e0-3176-e3128a8e3ffc",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "5406069b-10ee-240c-b243-a9ea7cbd53d0",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DEL CUERO CABELLUDO Y CUELLO"
    },
    {
        "id": "47643c8d-1f0d-3090-542d-9266ccf1c890",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DEL LABIO"
    },
    {
        "id": "9c3fc850-e94b-4dd0-e3f1-53f3119b7f84",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "c7d4da0a-b214-9127-e771-bb7bad436569",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "9e3d0cd2-3ee5-9a6b-0079-71c33990767e",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DEL PÁRPADO Y DE LA COMISURA PALPEBRAL"
    },
    {
        "id": "188223a0-0d97-c076-466e-937d7c0a1bae",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL DEL TRONCO"
    },
    {
        "id": "23db1cf8-2533-efdd-4c0e-09a290e9d791",
        "NOMBRE": "CARCINOMA IN SITU DE LA PIEL, SITIO NO ESPECIFICADO"
    },
    {
        "id": "08b834c4-f70d-7ad0-0543-7e856437fc85",
        "NOMBRE": "CARCINOMA IN SITU DE LA PRÓSTATA"
    },
    {
        "id": "14c6ec94-f778-9a39-e03d-be27260e8ca2",
        "NOMBRE": "CARCINOMA IN SITU DE LA TRÁQUEA"
    },
    {
        "id": "76c9149d-200a-dee6-ad6d-2380d6fdf1a5",
        "NOMBRE": "CARCINOMA IN SITU DE LA UNIÓN RECTOSIGMOIDEA"
    },
    {
        "id": "9ab31e76-5e31-c855-696c-f5107c7441c2",
        "NOMBRE": "CARCINOMA IN SITU DE LA VAGINA"
    },
    {
        "id": "ff36cfe9-9fac-bd49-7c07-cce98407d58c",
        "NOMBRE": "CARCINOMA IN SITU DE LA VEJIGA"
    },
    {
        "id": "562fc998-fb89-5d3a-d1b5-3343214a46ad",
        "NOMBRE": "CARCINOMA IN SITU DE LA VULVA"
    },
    {
        "id": "e8e8b751-ecc6-42b0-3322-0a793364ab85",
        "NOMBRE": "CARCINOMA IN SITU DE ÓRGANOS DIGESTIVOS NO ESPECIFICADOS"
    },
    {
        "id": "43bae19b-35bd-8010-6daf-7ff2b30de8a0",
        "NOMBRE": "CARCINOMA IN SITU DE ÓRGANOS RESPIRATORIOS NO ESPECIFICADOS"
    },
    {
        "id": "c4491a63-b209-a76c-f7bd-c0bec841c781",
        "NOMBRE": "CARCINOMA IN SITU DE OTRAS PARTES DEL SISTEMA RESPIRATORIO"
    },
    {
        "id": "df9615ad-5842-a7b5-881c-bb3ea96cbddb",
        "NOMBRE": "CARCINOMA IN SITU DE OTRAS PARTES ESPECIFICADAS DE ÓRGANOS DIGESTIVOS"
    },
    {
        "id": "e2a6ce52-2bdc-7560-4cb8-cf3c857fc9df",
        "NOMBRE": "CARCINOMA IN SITU DE OTRAS PARTES ESPECIFICADAS DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "7a5eaf96-ee21-a3a6-7d79-4076d055e13a",
        "NOMBRE": "CARCINOMA IN SITU DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL INTESTINO"
    },
    {
        "id": "dc6b1f55-979d-7438-d513-d4af819e8956",
        "NOMBRE": "CARCINOMA IN SITU DE OTROS ÓRGANOS DIGESTIVOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "2010f087-1d68-6c15-ba13-cb630d3ce68b",
        "NOMBRE": "CARCINOMA IN SITU DE OTROS ÓRGANOS GENITALES MASCULINOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "e1abb79e-3ff3-ae0c-685d-8c5ee2d8fadf",
        "NOMBRE": "CARCINOMA IN SITU DE OTROS ÓRGANOS GENITALES Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "6462d022-c6b5-8a45-c99f-2a8e1c5586f0",
        "NOMBRE": "CARCINOMA IN SITU DE OTROS ÓRGANOS URINARIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "e876a1ad-19a9-3abe-cb5d-ccb7c0dc118a",
        "NOMBRE": "CARCINOMA IN SITU DE OTROS SITIOS DE ÓRGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "e82ead02-dd72-a211-cf46-a046fb237efe",
        "NOMBRE": "CARCINOMA IN SITU DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "ebd5117b-c184-41fd-008b-aab01d45ad0e",
        "NOMBRE": "CARCINOMA IN SITU DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "a1115099-2d9b-0ad7-2006-58f49540e1b0",
        "NOMBRE": "CARCINOMA IN SITU DEL ANO Y DEL CONDUCTO ANAL"
    },
    {
        "id": "a2ab034b-9ce7-7c14-dbbd-36f36168d565",
        "NOMBRE": "CARCINOMA IN SITU DEL BRONQUIO Y DEL PULMÓN"
    },
    {
        "id": "6d6f76e7-d513-ebcc-1e68-593e8387122c",
        "NOMBRE": "CARCINOMA IN SITU DEL COLON"
    },
    {
        "id": "c5dc2259-0ff4-7b34-f833-393dcc21c2a4",
        "NOMBRE": "CARCINOMA IN SITU DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "7e486de5-d8aa-1595-f6e9-6da0dd0ab506",
        "NOMBRE": "CARCINOMA IN SITU DEL CUELLO DEL ÚTERO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "598543b8-aa53-71a5-da7a-98aee5183f9b",
        "NOMBRE": "CARCINOMA IN SITU DEL ENDOCÉRVIX"
    },
    {
        "id": "751c9e8d-c48b-5d7d-10c7-0ce5d1c4ce3f",
        "NOMBRE": "CARCINOMA IN SITU DEL ENDOMETRIO"
    },
    {
        "id": "f4b35518-2c66-495d-b61b-9df58468ecb0",
        "NOMBRE": "CARCINOMA IN SITU DEL ESÓFAGO"
    },
    {
        "id": "a18d95b3-bdc7-cf60-05c5-a424d86570b9",
        "NOMBRE": "CARCINOMA IN SITU DEL ESTÓMAGO"
    },
    {
        "id": "c37c597d-cd06-2c05-7c5e-0f5467c0079c",
        "NOMBRE": "CARCINOMA IN SITU DEL EXOCÉRVIX"
    },
    {
        "id": "d890ef0c-a8d8-eeda-90eb-f93a373223ef",
        "NOMBRE": "CARCINOMA IN SITU DEL HÍGADO, DE LA VESÍCULA BILIAR Y DEL CONDUCTO BILIAR"
    },
    {
        "id": "84184437-eb40-5c0b-a692-831a7570bf4d",
        "NOMBRE": "CARCINOMA IN SITU DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE"
    },
    {
        "id": "43abb522-510a-3288-35cf-bef4868c7452",
        "NOMBRE": "CARCINOMA IN SITU DEL OJO"
    },
    {
        "id": "55303217-c049-7642-0014-31e22016dc25",
        "NOMBRE": "CARCINOMA IN SITU DEL PENE"
    },
    {
        "id": "949b12d1-521f-52a0-e3c0-95da7c29982f",
        "NOMBRE": "CARCINOMA IN SITU DEL RECTO"
    },
    {
        "id": "ca97cad9-1356-7a0e-6e86-b4a01fd04fd9",
        "NOMBRE": "CARCINOMA IN SITU DEL SISTEMA RESPIRATORIO Y DEL OÍDO MEDIO"
    },
    {
        "id": "26f0057b-95b3-cb0e-e6e1-98b20fc688fd",
        "NOMBRE": "CARCINOMA IN SITU INTRACANALICULAR"
    },
    {
        "id": "76073df9-18dc-a2f3-9b0c-4dc93c4ee5b5",
        "NOMBRE": "CARCINOMA IN SITU LOBULAR"
    },
    {
        "id": "1f30c63f-820c-f5f3-d925-d878082df0fc",
        "NOMBRE": "CARCINOMA IN SITU, SITIO NO ESPECIFICADO"
    },
    {
        "id": "db838a14-7374-9f04-5b74-d45c459fde6d",
        "NOMBRE": "CARDIOMEGALIA"
    },
    {
        "id": "34380da1-e86d-6a53-7135-88ee2fead296",
        "NOMBRE": "CARDIOMIOPATÍA"
    },
    {
        "id": "58a35d46-0fc4-685c-5cd7-9e12232771dd",
        "NOMBRE": "CARDIOMIOPATÍA ALCOHÓLICA"
    },
    {
        "id": "0a7ec953-119f-1f2a-2b8c-296466c4f1a1",
        "NOMBRE": "CARDIOMIOPATÍA DEBIDA A DROGAS Y OTROS AGENTES EXTERNOS"
    },
    {
        "id": "88c73630-a1a3-d3b7-6eb8-f515688e4461",
        "NOMBRE": "CARDIOMIOPATÍA DILATADA"
    },
    {
        "id": "18a40986-996a-cc19-c666-6573ca4d1c4f",
        "NOMBRE": "CARDIOMIOPATÍA EN EL PUERPERIO"
    },
    {
        "id": "e26f5e0c-fe19-2ecf-35dc-7546ae99cf13",
        "NOMBRE": "CARDIOMIOPATÍA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1a06a6df-ae12-9906-288b-47f011805281",
        "NOMBRE": "CARDIOMIOPATÍA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "eee530ca-8dde-3437-b505-9fbf3b837da9",
        "NOMBRE": "CARDIOMIOPATÍA EN ENFERMEDADES METABÓLICAS"
    },
    {
        "id": "9c6f2af3-6028-7ac8-60be-5616bfe48b10",
        "NOMBRE": "CARDIOMIOPATÍA EN ENFERMEDADES NUTRICIONALES"
    },
    {
        "id": "a031d86f-b695-e28d-2d61-b52a294d0643",
        "NOMBRE": "CARDIOMIOPATÍA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e425376d-e194-b578-292b-bc1e06d4e755",
        "NOMBRE": "CARDIOMIOPATÍA HIPERTRÓFICA OBSTRUCTIVA"
    },
    {
        "id": "b523337c-88e2-23a4-4750-afd2d1128ff6",
        "NOMBRE": "CARDIOMIOPATÍA ISQUÉMICA"
    },
    {
        "id": "4c2d4399-9db2-ba57-179f-6e7696cd0383",
        "NOMBRE": "CARDIOMIOPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "310d1899-0811-877e-c3e0-d442922317d7",
        "NOMBRE": "CARDITIS VIRAL"
    },
    {
        "id": "efde918b-7140-24d4-0eb0-df208f1e849f",
        "NOMBRE": "CARIES CON EXPOSICIÓN PULPAR"
    },
    {
        "id": "2e96ded3-4d37-d4f3-79ce-3e6cbe1d1c2b",
        "NOMBRE": "CARIES DE LA DENTINA"
    },
    {
        "id": "4186f9aa-6e69-d4f3-de7a-916b793d0699",
        "NOMBRE": "CARIES DEL CEMENTO"
    },
    {
        "id": "631597a4-60ba-8903-5a9d-45ed1727de5a",
        "NOMBRE": "CARIES DENTAL"
    },
    {
        "id": "880a7b3a-9c9a-3e13-bd63-ef85e5e16237",
        "NOMBRE": "CARIES DENTAL, NO ESPECIFICADA"
    },
    {
        "id": "bfd8ece3-3bf3-6c3b-059b-79932e993ae6",
        "NOMBRE": "CARIES DENTARIA DETENIDA"
    },
    {
        "id": "cfe55eb3-8e28-7de9-4375-919411932473",
        "NOMBRE": "CARIES LIMITADA AL ESMALTE"
    },
    {
        "id": "269f7747-bf81-9309-3c40-dd81a452ce77",
        "NOMBRE": "CARIOTIPO 45,X"
    },
    {
        "id": "96ae02c4-deca-ce9c-cf9b-6940ab5820bb",
        "NOMBRE": "CARIOTIPO 46,X CON CROMOSOMA SEXUAL ANORMAL EXCEPTO ISO (XQ)"
    },
    {
        "id": "b3c9fe9c-7baf-c536-93fd-388037313dbc",
        "NOMBRE": "CARIOTIPO 46,X ISO (XQ)"
    },
    {
        "id": "5157e752-5bbe-fb1b-f1de-ae545cc8e6da",
        "NOMBRE": "CARIOTIPO 47, XXX"
    },
    {
        "id": "a19bd7c3-0ba5-9511-4fd5-6900de891cc7",
        "NOMBRE": "CARIOTIPO 47, XYY"
    },
    {
        "id": "24984595-c9ae-c732-36fd-c525d8034e84",
        "NOMBRE": "CARÚNCULA URETRAL"
    },
    {
        "id": "17c7ee8e-4e67-d1a3-fa8e-6435d3083bb5",
        "NOMBRE": "CATARATA COMPLICADA"
    },
    {
        "id": "2722b9cf-dcf7-18fa-577f-e033e887988d",
        "NOMBRE": "CATARATA CONGÉNITA"
    },
    {
        "id": "c593024f-118f-e63c-b730-e1512f2a1940",
        "NOMBRE": "CATARATA DIABÉTICA"
    },
    {
        "id": "17069a11-aad6-ad07-430a-8942196fce4b",
        "NOMBRE": "CATARATA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "47ec23af-5396-833c-a8ff-1afa43e9a3d2",
        "NOMBRE": "CATARATA EN OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABÓLICAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "07999c44-9df9-e03c-6d40-a90aeda16864",
        "NOMBRE": "CATARATA INDUCIDA POR DROGAS"
    },
    {
        "id": "791dbbb3-31b1-ab4a-d88a-e00a0f19264e",
        "NOMBRE": "CATARATA INFANTIL, JUVENIL Y PRESENIL"
    },
    {
        "id": "b9c6a0c3-489a-0559-fa78-721017221c90",
        "NOMBRE": "CATARATA RESIDUAL"
    },
    {
        "id": "cf001476-2374-9c42-9fb9-a54cbf3fe078",
        "NOMBRE": "CATARATA SENIL"
    },
    {
        "id": "2b6af6ef-c72e-3025-3b8a-27e7d8807484",
        "NOMBRE": "CATARATA SENIL INCIPIENTE"
    },
    {
        "id": "bee7e79d-f838-d997-f25b-ac6b55feec5d",
        "NOMBRE": "CATARATA SENIL NUCLEAR"
    },
    {
        "id": "13ec54bc-f2cb-9b47-c1a7-372daa2ac126",
        "NOMBRE": "CATARATA SENIL, NO ESPECIFICADA"
    },
    {
        "id": "038c4ae7-c985-37db-2a6b-63a85ba77b19",
        "NOMBRE": "CATARATA SENIL, TIPO MORGAGNIAN"
    },
    {
        "id": "359b3362-a6ec-6ad2-72b4-34ec293483d0",
        "NOMBRE": "CATARATA TRAUMÁTICA"
    },
    {
        "id": "5e9068d1-4c80-78ee-6df0-58dafa9995bf",
        "NOMBRE": "CATARATA Y OTROS TRASTORNOS DEL CRISTALINO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "bb5a6fde-274b-cfc5-77c6-f86114db4519",
        "NOMBRE": "CATARATA, NO ESPECIFICADA"
    },
    {
        "id": "daa6c5e8-9481-b2a6-6246-c6ab2fa26e02",
        "NOMBRE": "CATETERIZACIÓN CARDÍACA"
    },
    {
        "id": "a74643b4-af8e-54d4-1b60-415fd6750568",
        "NOMBRE": "CATETERIZACIÓN URINARIA"
    },
    {
        "id": "29a90842-991d-5afc-2ec5-ecc6f11f1d44",
        "NOMBRE": "CAUSALGIA"
    },
    {
        "id": "95a8ba66-5c7b-a1ca-1793-a3c3bbec4eff",
        "NOMBRE": "CAUSAS DE MORBILIDAD DESCONOCIDAS Y NO ESPECIFICADAS"
    },
    {
        "id": "46d7ab17-70fe-a0c6-1285-d0f5dd6e55be",
        "NOMBRE": "CEFALALGIA INDUCIDA POR LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL EMBARAZO"
    },
    {
        "id": "77850788-4e89-1a1d-02f3-3a0f2c8a6293",
        "NOMBRE": "CEFALALGIA INDUCIDA POR LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL PUERPERIO"
    },
    {
        "id": "9bd34713-3d9d-6669-141c-f66aa4c2ec98",
        "NOMBRE": "CEFALALGIA INDUCIDA POR LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "dd1c546d-2bea-bd2c-ff4c-561602a7b9c7",
        "NOMBRE": "CEFALEA"
    },
    {
        "id": "e84ee628-99e2-b091-e966-49fe6d7b671e",
        "NOMBRE": "CEFALEA DEBIDA A TENSIÓN"
    },
    {
        "id": "3f2d8e2e-90d9-6c22-14c9-941b58322f8f",
        "NOMBRE": "CEFALEA INDUCIDA POR DROGAS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "71db3469-1e8f-d3e3-c9fc-adde416d6080",
        "NOMBRE": "CEFALEA POSTRAUMÁTICA CRÓNICA"
    },
    {
        "id": "eb5e9a52-2b10-8e2b-5e30-3f75b8aa4959",
        "NOMBRE": "CEFALEA VASCULAR, NCOP"
    },
    {
        "id": "0c707f14-cff3-cbdc-adc5-1b8ef2d848e2",
        "NOMBRE": "CEFALOHEMATOMA DEBIDO A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "b1f85654-bed8-6fe8-d692-a69dbed8bf2a",
        "NOMBRE": "CEGUERA BINOCULAR"
    },
    {
        "id": "f7af0260-dcf6-274a-c1a2-ed1feaf2d3eb",
        "NOMBRE": "CEGUERA MONOCULAR"
    },
    {
        "id": "3149e6ee-14ab-2cf4-ed39-ede537cd4141",
        "NOMBRE": "CEGUERA NOCTURNA"
    },
    {
        "id": "774530c2-8ffb-f0e0-dbe6-fda5f99c9921",
        "NOMBRE": "CEGUERA Y DEFICIENCIA VISUAL (BINICULAR O MONOCULAR)"
    },
    {
        "id": "77ec6e93-428f-013e-d192-d215e37a4ee7",
        "NOMBRE": "CELULITIS"
    },
    {
        "id": "497949e4-f4f5-f923-85f6-da986c0e5b7b",
        "NOMBRE": "CELULITIS DE LA CARA"
    },
    {
        "id": "15453364-9240-d05f-cbf3-29add29a7291",
        "NOMBRE": "CELULITIS DE LOS DEDOS DE LA MANO Y DEL PIE"
    },
    {
        "id": "62e21ffc-e8e8-43cd-9ecc-7a092ed35e9d",
        "NOMBRE": "CELULITIS DE OTRAS PARTES DE LOS MIEMBROS"
    },
    {
        "id": "dc1bacc6-2ad3-db47-f0b4-3f1920136bb2",
        "NOMBRE": "CELULITIS DE OTROS SITIOS"
    },
    {
        "id": "f71bac33-11e2-fdff-7f8e-703e042b25a7",
        "NOMBRE": "CELULITIS DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "0a7ff6ca-c061-c30f-c150-663da610c316",
        "NOMBRE": "CELULITIS DEL OÍDO EXTERNO"
    },
    {
        "id": "dd51325d-95cb-3504-5208-bb1356ab3600",
        "NOMBRE": "CELULITIS DEL TRONCO"
    },
    {
        "id": "5cc21c3a-e8bc-0b96-e290-afb1ab66d18c",
        "NOMBRE": "CELULITIS EOSINÓFILIA [WELLS]"
    },
    {
        "id": "84dcac1b-d1e0-2e17-9d08-463c709f59ca",
        "NOMBRE": "CELULITIS Y ABSCESO DE BOCA"
    },
    {
        "id": "a660b9d0-3aa3-795d-aaa6-c7bb34bb1a69",
        "NOMBRE": "CERUMEN IMPACTADO"
    },
    {
        "id": "e84c4593-dcff-6bf3-6f5b-7448ee696bd0",
        "NOMBRE": "CERVICALGIA"
    },
    {
        "id": "5080f181-c8ae-8a2d-8083-44a2f615ed7c",
        "NOMBRE": "CHANCRO BLANDO"
    },
    {
        "id": "3334902e-09ec-8a64-9d13-f6a570f0f451",
        "NOMBRE": "CHASQUIDO DE LA CADERA"
    },
    {
        "id": "5778c3de-e5bf-de68-2e20-2afec94b5403",
        "NOMBRE": "CHOQUE ANAFILÁCTICO DEBIDO A EFECTO ADVERSO DE DROGA O MEDICAMENTO CORRECTO ADMINISTRADO APROPIADAMENTE"
    },
    {
        "id": "375774d3-63d5-c628-1be9-7c025fa86da3",
        "NOMBRE": "CHOQUE ANAFILÁCTICO DEBIDO A REACCIÓN ADVERSA A ALIMENTOS"
    },
    {
        "id": "3dda965a-3356-ac69-efb1-56b439941557",
        "NOMBRE": "CHOQUE ANAFILÁCTICO DEBIDO A SUERO"
    },
    {
        "id": "fbab276c-2a7a-5334-ffb9-0ab566724262",
        "NOMBRE": "CHOQUE ANAFILÁCTICO, NO ESPECIFICADO"
    },
    {
        "id": "929aacad-d494-c9d5-0a85-019d085711f7",
        "NOMBRE": "CHOQUE CARDIOGÉNICO"
    },
    {
        "id": "9b45c575-a5a1-c6ef-504d-d2de2328be59",
        "NOMBRE": "CHOQUE CONSECUTIVO AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "c73c7c58-edf0-54fd-a901-7c2dc98a8681",
        "NOMBRE": "CHOQUE DEBIDO A LA ANESTESIA"
    },
    {
        "id": "2a6e820e-d20d-aff4-e235-95e2c309677d",
        "NOMBRE": "CHOQUE DURANTE O DESPUÉS DEL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "11775fe3-f2d6-0e3e-f3a6-39b761a3c3b9",
        "NOMBRE": "CHOQUE DURANTE O RESULTANTE DE UN PROCEDIMIENTO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "57d27407-b239-ecc7-09c7-bf0b7576accd",
        "NOMBRE": "CHOQUE HIPOVOLÉMICO"
    },
    {
        "id": "f4f52b39-cee3-937b-d46b-97e8802d186a",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA"
    },
    {
        "id": "24280baa-53aa-b5dd-d7f2-e620ce17c4e9",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a9cb2011-3de6-efa9-e235-1297f905541d",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "11416c4e-cb20-4651-00f8-6a3b3a61f090",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, CALLES Y CARRETERAS"
    },
    {
        "id": "ea3e6701-26d2-fc19-2647-1035b8a1380b",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "08dcc540-5e12-aef6-339d-5e4e3b7eaa20",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "68adae4c-5159-1738-7dd8-12d3930d7356",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, GRANJA"
    },
    {
        "id": "8e38a926-5d87-b529-422c-6f2afae4b9af",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ec7616d8-4480-b363-0059-6d47385a2d9f",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a34856e1-14db-bf64-d249-364d1b5651c9",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d13575cf-4119-3473-e4c2-7b2cc58945be",
        "NOMBRE": "CHOQUE O EMPELLÓN CONTRA OTRA PERSONA, VIVIENDA"
    },
    {
        "id": "d2a8a19d-374b-0295-8a1b-5d709c5fde18",
        "NOMBRE": "CHOQUE SÉPTICO"
    },
    {
        "id": "5c4d63ef-bd46-5b9c-b77b-88643c75fb7a",
        "NOMBRE": "CHOQUE TRAUMÁTICO"
    },
    {
        "id": "c402ce76-d066-da6b-d248-c8ed5f924552",
        "NOMBRE": "CHOQUE, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "e8df5d10-e9cf-2576-9e3c-b0ff10dcfbea",
        "NOMBRE": "CHOQUE, NO ESPECIFICADO"
    },
    {
        "id": "d1903e94-e1c5-4deb-a7c7-2a9215be2962",
        "NOMBRE": "CIANOSIS"
    },
    {
        "id": "5f6bf543-1540-0c4f-15c2-d8fbc4ef11af",
        "NOMBRE": "CIÁTICA"
    },
    {
        "id": "e8dec7e5-318b-dbc9-326d-3c43a9ad9c4d",
        "NOMBRE": "CICATRICES CONJUNTIVALES"
    },
    {
        "id": "3ca8feb6-c7a6-1502-a9fa-77f7354c121c",
        "NOMBRE": "CICATRICES CORIORRETINIANAS"
    },
    {
        "id": "2eb0122c-6d41-f404-b177-16b50a185bc8",
        "NOMBRE": "CICATRIZ HIPERTRÓFICA"
    },
    {
        "id": "30cc01de-094b-abe1-2fef-63be221c0241",
        "NOMBRE": "CICATRIZ U OPACIDAD DE LA CÓRNEA, NO ESPECIFICADA"
    },
    {
        "id": "4aad70bc-1008-9224-4a06-7265d06ce2a8",
        "NOMBRE": "CICLISTA (CUALQUIERA) LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"
    },
    {
        "id": "d507968c-0170-9523-f7d0-29e2a48f553f",
        "NOMBRE": "CICLISTA [CUALQUIERA] LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "f73785bb-0abf-5e35-10db-16ea4a77ace3",
        "NOMBRE": "CICLISTA [CUALQUIERA] LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "28a966f0-c67e-58cd-0ee2-dfa801fc5457",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN"
    },
    {
        "id": "c753fbcf-feb4-0a57-44be-8e4382d9bd7d",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b0737fad-9e65-f3da-853e-02d3ce919b01",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c1e72442-8ff4-8606-9dba-3aefa0e28134",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1401e515-227a-b490-1f0b-3c58a199d640",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c15c52e8-2914-6014-cbab-6c6a6ef30a9e",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c4e02768-7d5a-8afd-a90c-a3974a1a4921",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "fa96eab8-8dee-1687-036a-5bb39f73b2ed",
        "NOMBRE": "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "77f37922-4d63-5839-9173-3691afdc42e1",
        "NOMBRE": "CICLISTA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS."
    },
    {
        "id": "d88a0dc1-7151-ab9b-c86f-06313a72fd6d",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "18835aca-b899-9fac-9745-9548516ad898",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CICLISTA NO ESPECIFICADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "4e576abe-1c1c-70d8-9ddf-d450a33353a7",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "cd179947-9d01-7ad9-25da-711a7983f2ee",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "300d3643-6961-fb33-89a8-d7b05b503583",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "938393ad-72d6-7122-3812-ba4d3251d3e3",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3e9a1796-a069-7807-7fd1-e24cb6699160",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a97ced2b-4c64-d187-d6eb-a1e5f831aba8",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "43af1338-1428-b097-d752-5ac99fc203a2",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO"
    },
    {
        "id": "312675e2-2a57-80d5-3095-ca004eb72347",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5de3742d-c6b0-ef7d-8b15-538777924307",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a1c2c5e1-8de0-5cc5-ca2d-2bb5cd77be4b",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5fa11a33-bd4b-85e2-0977-af4c5f5512fb",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "58729b18-ddc7-a3ea-e260-685b858fe816",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5a9800fe-e483-2eee-e392-4918ef8470db",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "88979df6-04af-3a45-b293-0836586d4efd",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OBJETO ESTACIONADO O FIJO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "9281670c-4193-b8f4-db96-ca48917c5cd1",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA"
    },
    {
        "id": "90fe5a9c-bcd9-15ed-de88-8647ee2eda73",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b41fa7a2-3c64-f183-8a77-be88a9ead9f8",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f56f7529-e39e-5b95-0ba8-bd629f13c7ea",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c19f31bb-6190-6ff2-31bb-77bcfc660fde",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c1a4b733-753c-1d32-0118-d8cc8234ae9e",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "4109d908-b086-405e-ede5-132e6b256ad4",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5433893e-09ce-091b-0b12-4d4aead96cdc",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTRO CICLISTA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "784ba30d-243e-ae52-75f1-f8cb2ebf66a2",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "ad038387-09e7-f22c-2c6a-05e69350879e",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "07bc9273-b660-1932-c2f1-8e3b7f791f32",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "24bc74b0-d17a-b215-00e8-5285ef1e98d0",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "7db14ea9-f11a-524e-8969-bbfbed251ee4",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "320706ec-7aa1-0b8d-5e03-0eb3adcb3b23",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "19c52cc8-024d-8ca3-3ad1-65fa873a0765",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "1a85863d-584a-a648-8636-0eb00accb7b0",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "f0eecfe1-7be2-22b2-5501-a0ad77df39a2",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "5d850bec-9f51-231f-1ee2-3811e738ca6f",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6801f1db-6345-dade-e845-1d1491e57a69",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3c74eb9a-bf37-2c4f-f583-3362ea4eb114",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ee0f6453-be03-7dbe-8f45-a1b285955ce1",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b4295c08-e222-8643-d762-2119acc3ea09",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "562ebf08-c884-5122-4f81-5a56e82c930d",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "cb8daca5-c207-2d5b-dfd7-a73e614b70e8",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "e32aecb3-4dc8-8a27-8954-c955ff18a499",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "05c30679-3b0b-b10a-029a-ae96327068b2",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c89b8eda-7378-bc96-ecf8-ccce42347574",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "406b81e5-58b1-4370-e349-955005ec14d5",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c76ef3a0-7c50-a4e7-7307-c971ae7d69c3",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "752b0b2c-8eaa-6049-eaa3-79c65dcf6d64",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "af553317-12c6-2165-d304-662b7cf73b9e",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "26b71422-63f3-b762-0364-deb6ba8e9898",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "072ba206-4f24-e338-c52c-1485ba39e51c",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a0e46d7e-0d57-e9ea-2432-b03ebbd37842",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "e6b53ca9-adbc-b327-4651-9c33637db49b",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b00dbb25-d0a4-d3a5-fc00-5e3e2f2fc626",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0bc44611-4b64-b542-4088-37c8973e80f1",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "94f96294-23be-217d-5f9f-7ba5ad16a52c",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTES NO DE TRÁNSITO"
    },
    {
        "id": "0fbcdeb0-bf67-da08-b155-f5a3421b8c52",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "7c94e8f2-89d2-3010-8e09-f8e96fc39ac3",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS."
    },
    {
        "id": "1540c4b0-df59-ce72-f05d-23fc6909ab5a",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "5beb8d0c-d46a-7d8a-aa40-a15bbecda4a0",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "756a6c21-cb65-73c4-28cb-2a2d28bd9d2e",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0343673c-b55b-198d-4129-55ef270acbc7",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "16a4799d-b4dd-1e1a-9d64-169968217355",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "053d2fd2-0832-5b12-c5bb-f6221b229318",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "bf5f6598-dff2-0a10-cafb-a057237f5539",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "79ee39e4-6554-77ce-d50a-98b4bb6b1174",
        "NOMBRE": "CICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "736a4d4a-38aa-66da-7c6e-105a6f1c8ba5",
        "NOMBRE": "CICLISTA NO ESPECIFICADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADO, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "79b7d0dd-372a-984b-2c0b-a5ae238211ab",
        "NOMBRE": "CICLISTA NO ESPECIFICADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7ba71cf2-cbf6-daac-3ca4-401344242a32",
        "NOMBRE": "CICLITIS POSTERIOR"
    },
    {
        "id": "09cc40d4-7974-f677-ed15-232dbbcb2cf3",
        "NOMBRE": "CICLOTIMIA"
    },
    {
        "id": "cccf0c7b-48cf-f13e-a965-9423d39cba4e",
        "NOMBRE": "CIERTAS COMPLICACIONES PRESENTES POSTERIORES AL INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "51dab05f-1aad-61a0-ac37-4798094804e5",
        "NOMBRE": "CIFOSIS POSTLAMINECTOMÍA"
    },
    {
        "id": "1b3ebd9b-1a75-4239-616a-10dbaf231c94",
        "NOMBRE": "CIFOSIS POSTRADIACIÓN"
    },
    {
        "id": "db1be27c-e047-9486-efed-5427c149043e",
        "NOMBRE": "CIFOSIS POSTURAL"
    },
    {
        "id": "65819998-c549-d089-26fb-10b4a695e8ab",
        "NOMBRE": "CIFOSIS Y LORDOSIS"
    },
    {
        "id": "13dec2da-22b1-0fe4-07aa-875a3075314a",
        "NOMBRE": "CIGOMICOSIS"
    },
    {
        "id": "01bede6a-a9fb-e680-959c-24d0dd7069dd",
        "NOMBRE": "CIGOMICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "4655e997-4380-f48a-1f1b-cc6660d07ef6",
        "NOMBRE": "CIRCUNCISIÓN RITUAL O DE RUTINA"
    },
    {
        "id": "796c59f6-a78a-0c8e-95d0-996fa6c5fd33",
        "NOMBRE": "CIRROSIS BILIAR PRIMARIA"
    },
    {
        "id": "142897de-9fb5-3d60-9b3a-beef18bd388e",
        "NOMBRE": "CIRROSIS BILIAR SECUNDARIA"
    },
    {
        "id": "dc588f8e-9e55-c0f2-f7ea-41f76fda1f34",
        "NOMBRE": "CIRROSIS BILIAR, NO ESPECIFICADA"
    },
    {
        "id": "9e18d182-9084-acda-b30b-d47e6b4741ff",
        "NOMBRE": "CIRROSIS HEPÁTICA ALCOHÓLICA"
    },
    {
        "id": "e074ba10-f279-b5db-13ec-4bc08b1d585f",
        "NOMBRE": "CIRUGÍA PROFILÁCTICA"
    },
    {
        "id": "f2c75db3-bb2f-4231-da00-48edfe908a69",
        "NOMBRE": "CIRUGÍA PROFILÁCTICA NO ESPECIFICADA"
    },
    {
        "id": "2b75a4db-7777-36f2-1d06-139162c97583",
        "NOMBRE": "CIRUGÍA PROFILÁCTICA POR FACTORES DE RIESGO RELACIONADOS CON TUMORES MALIGNOS"
    },
    {
        "id": "8d590693-1820-98ca-87bc-445ad116bd35",
        "NOMBRE": "CIRUGÍA Y OTROS PROCEDIMIENTOS QUIRÚRGICOS COMO LA CAUSA DE REACCIÓN ANORMAL DEL PACIENTE O DE COMPLICACIÓN POSTERIOR, SIN MENCIÓN DE INCIDENTE EN EL MOMENTO DE EFECTUAR EL PROCEDIMIENTO"
    },
    {
        "id": "a0a94acc-bd7d-b41a-b3bd-8541836cc660",
        "NOMBRE": "CISTICERCOSIS"
    },
    {
        "id": "08ed3cde-2a2f-0c4a-fd44-66b2d1e225b9",
        "NOMBRE": "CISTICERCOSIS DE OTROS SITIOS"
    },
    {
        "id": "6e7e58b2-8e40-1657-d914-e306d114fc2f",
        "NOMBRE": "CISTICERCOSIS DEL OJO"
    },
    {
        "id": "a90fe2b4-da53-3da0-b534-01a2682fe39e",
        "NOMBRE": "CISTICERCOSIS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "25314f13-81cd-015b-f5ae-ac9ccd7221b6",
        "NOMBRE": "CISTICERCOSIS, NO ESPECIFICADA"
    },
    {
        "id": "1af8bc48-87ba-e78c-e8b6-26b416702ad4",
        "NOMBRE": "CISTITIS"
    },
    {
        "id": "453a754b-0e73-bfff-5d34-99076f20fd7a",
        "NOMBRE": "CISTITIS AGUDA"
    },
    {
        "id": "8f65d0c5-427e-711a-23ba-d433b2b83c0b",
        "NOMBRE": "CISTITIS INTERSTICIAL (CRÓNICA)"
    },
    {
        "id": "f3c0d3a0-3490-1d72-80f3-b69a087bdf33",
        "NOMBRE": "CISTITIS POR IRRADIACIÓN"
    },
    {
        "id": "e624a40a-0555-c55d-6396-e307fe2c63b3",
        "NOMBRE": "CISTITIS TUBERCULOSA"
    },
    {
        "id": "0f1aef7b-8eca-f426-fb0f-149233e2580d",
        "NOMBRE": "CISTITIS, NO ESPECIFICADA"
    },
    {
        "id": "52369d8d-40f2-b0b0-1dba-37a6b845915b",
        "NOMBRE": "CISTOCELE"
    },
    {
        "id": "78917ac5-623c-139b-76ab-df754f43a0d9",
        "NOMBRE": "CISTOSTOMÍA"
    },
    {
        "id": "9f6a0e21-54a7-f743-9027-e7f7eca20add",
        "NOMBRE": "CLOASMA"
    },
    {
        "id": "c53fe6d1-3861-a446-8f46-bbf3b9e0f298",
        "NOMBRE": "CLONORQUIASIS"
    },
    {
        "id": "e935fcc2-5cfa-5453-d4a2-282264a3b397",
        "NOMBRE": "CLOSTRIDIUM PERFRINGENS [C. PERFRINGENS] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "b1158107-18ff-7a98-b57c-4a2cf9d84a5d",
        "NOMBRE": "COAGULACIÓN INTRAVASCULAR DISEMINADA [SÍNDROME DE DESFIBRINACIÓN]"
    },
    {
        "id": "957b0980-b9d0-5516-e93e-3c067c6ead27",
        "NOMBRE": "COAGULACIÓN INTRAVASCULAR DISEMINADA EN EL FETO Y EL RECIÉN NACIDO"
    },
    {
        "id": "09dacbdc-968d-e499-e806-959fa9200925",
        "NOMBRE": "COARTACIÓN DE LA AORTA"
    },
    {
        "id": "61528e72-30b7-d7ae-ab88-d50b69bda628",
        "NOMBRE": "COCCIDIOIDOMICOSIS"
    },
    {
        "id": "19cad731-52ca-ef46-a6c9-c437b3fae2d4",
        "NOMBRE": "COCCIDIOIDOMICOSIS CUTÁNEA"
    },
    {
        "id": "dd07c2de-e577-c5ef-4eda-eef45cee53ee",
        "NOMBRE": "COCCIDIOIDOMICOSIS DISEMINADA"
    },
    {
        "id": "0137ee46-0cde-0caa-d78c-126fa66d238a",
        "NOMBRE": "COCCIDIOIDOMICOSIS PULMONAR AGUDA"
    },
    {
        "id": "92f2d8b2-1c18-04fc-5a7d-45338bcdd041",
        "NOMBRE": "COCCIDIOIDOMICOSIS PULMONAR CRÓNICA"
    },
    {
        "id": "3118ea40-e565-0af8-d014-88a5614cc11e",
        "NOMBRE": "COCCIDIOIDOMICOSIS PULMONAR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "a9ebb2f8-516e-0f44-b235-0680e8857794",
        "NOMBRE": "COCCIDIOIDOMICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "f5f93897-35fa-abbd-9066-94b8e8fa5c91",
        "NOMBRE": "COLAGENOSIS PERFORANTE REACTIVA"
    },
    {
        "id": "2c160a37-4152-ddf7-dbb8-99734be7313f",
        "NOMBRE": "COLANGITIS"
    },
    {
        "id": "7215fdb7-76f3-b428-0ba3-05956d03cf69",
        "NOMBRE": "COLAPSO PULMONAR"
    },
    {
        "id": "8bd6829d-5c56-bee7-14bd-c8faa9ef2b98",
        "NOMBRE": "COLECISTITIS"
    },
    {
        "id": "a57adcc3-a1c6-6729-76b6-6dc4691c0d34",
        "NOMBRE": "COLECISTITIS AGUDA"
    },
    {
        "id": "f696539b-0ebf-7d98-63ec-392135de9d04",
        "NOMBRE": "COLECISTITIS CRÓNICA"
    },
    {
        "id": "4e9fb792-9ff8-e7a4-268b-3ff92aef74eb",
        "NOMBRE": "COLECISTITIS, NO ESPECIFICADA"
    },
    {
        "id": "8f5d0f08-6cd1-3f78-b336-4905203f107e",
        "NOMBRE": "COLELITIASIS"
    },
    {
        "id": "3061a94c-8acf-f550-c75f-10a65cfdd528",
        "NOMBRE": "CÓLERA"
    },
    {
        "id": "6bcf82e2-1b06-b7ca-8cc7-7d8ef403b37f",
        "NOMBRE": "CÓLERA DEBIDO A VIBRIO CHOLERAE 01, BIOTIPO CHOLERAE"
    },
    {
        "id": "0053c9d4-bcdd-351e-2be0-29467f8f04c2",
        "NOMBRE": "CÓLERA DEBIDO A VIBRIO CHOLERAE 01, BIOTIPO EL TOR"
    },
    {
        "id": "762e6404-16f8-5121-751a-6282ad3e715b",
        "NOMBRE": "CÓLERA, NO ESPECIFICADO"
    },
    {
        "id": "0299d796-c0e3-258f-5089-3d0f721ceae8",
        "NOMBRE": "COLESTEATOMA DEL OÍDO EXTERNO"
    },
    {
        "id": "1f4bd337-1b8c-9617-4923-90247eea4109",
        "NOMBRE": "COLESTEATOMA DEL OÍDO MEDIO"
    },
    {
        "id": "588a9fe2-5abc-d496-fcc8-a25ded5e73ee",
        "NOMBRE": "COLESTEATOMA RECURRENTE DE LA CAVIDAD RESULTANTE DE LA MASTOIDECTOMÍA"
    },
    {
        "id": "2febf149-8439-5016-371a-69aecd55ddca",
        "NOMBRE": "COLESTEROLOSIS DE LA VESÍCULA BILIAR"
    },
    {
        "id": "664048c0-4b00-4106-ebc4-c561d5f98733",
        "NOMBRE": "CÓLICO RENAL, NO ESPECIFICADO"
    },
    {
        "id": "1c91ba64-120c-d85a-50ab-df0f314a882c",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "b835648a-8c62-4a8a-a02b-61f7a9053beb",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a7814bfc-c2f1-edff-c934-47d03d27e664",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ca4c5bbc-23fe-b2d3-d075-b8d49a05f97a",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "5b5adcb8-593f-3477-fd40-da1f3926729e",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "20b4a2d8-d047-d869-ff90-d25617a0067f",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "692fabf2-412b-f4fb-a287-485532551e97",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "4be5cc7d-231e-8695-17af-82e32343139b",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "375514d8-994a-b6b8-dead-efcac38e3b00",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8a24af09-b4d3-6c5b-8936-e594ef79ada6",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8761d3e2-5233-99bb-2b85-22c65970cd45",
        "NOMBRE": "COLISIÓN DE VEHÍCULO DE MOTOR, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "fbc82789-7e85-4a9b-f3ff-46d7fc9fa88a",
        "NOMBRE": "COLITIS AMEBIANA NO DISENTÉRICA"
    },
    {
        "id": "98eb44fb-60fc-00f1-86c5-c27c5f9db224",
        "NOMBRE": "COLITIS DE ETIOLOGÍA INDETERMINADA"
    },
    {
        "id": "333ded9d-c483-f880-1779-46d6dca2562f",
        "NOMBRE": "COLITIS DEL LADO IZQUIERDO"
    },
    {
        "id": "d04dfe6e-c387-299c-f33f-9d5185b3b24a",
        "NOMBRE": "COLITIS ULCERATIVA"
    },
    {
        "id": "86d76448-8679-4a17-effc-367ef5d2de37",
        "NOMBRE": "COLITIS ULCERATIVA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "f502ea28-80c6-0097-a29e-8305bfdbf3cc",
        "NOMBRE": "COLITIS Y GASTROENTERITIS ALÉRGICAS Y DIETÉTICAS"
    },
    {
        "id": "e4ef49e4-5c2d-fece-e2c1-5705d547bfc6",
        "NOMBRE": "COLITIS Y GASTROENTERITIS DEBIDAS A RADIACIÓN"
    },
    {
        "id": "b380f3a9-e1af-c7f2-a075-a6bf1eb71862",
        "NOMBRE": "COLITIS Y GASTROENTERITIS NO INFECCIOSAS, NO ESPECIFICADAS"
    },
    {
        "id": "2b9ab341-370c-c3c4-9db5-f85d66fdcb7b",
        "NOMBRE": "COLITIS Y GASTROENTERITIS TÓXICAS"
    },
    {
        "id": "15bfd835-9fdf-4a08-1e91-3cf899daf615",
        "NOMBRE": "COLOBOMA DEL CRISTALINO"
    },
    {
        "id": "f1497c23-0952-58cf-aafc-d24fee05164e",
        "NOMBRE": "COLOBOMA DEL IRIS"
    },
    {
        "id": "93cf3264-80ae-07f1-ea3f-241658ad351f",
        "NOMBRE": "COLOSTOMÍA"
    },
    {
        "id": "80a2f78f-e735-5a89-6ceb-cefbcb0a6edf",
        "NOMBRE": "COMA HIPOGLICÉMICO NO DIABÉTICO"
    },
    {
        "id": "d195fefb-324e-d642-020b-ca27c0b7bc1d",
        "NOMBRE": "COMA MIXEDEMATOSO"
    },
    {
        "id": "b19061fb-f008-d54f-de96-9875f466c06e",
        "NOMBRE": "COMA NEONATAL"
    },
    {
        "id": "432c637f-7aa4-b532-ab7d-57b654d2e47c",
        "NOMBRE": "COMA, NO ESPECIFICADO"
    },
    {
        "id": "1aff1f42-5658-1a5a-a862-c7e7da15dccc",
        "NOMBRE": "COMPLEJO DE SUBLUXACIÓN (VERTEBRAL)"
    },
    {
        "id": "3237ef60-e4bd-fffa-6827-517e5e13c141",
        "NOMBRE": "COMPLICACIÓN DE PROCEDIMIENTOS, NO ESPECIFICADA"
    },
    {
        "id": "552a23dc-2790-6370-494f-e9b3d04fd002",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE CATÉTER PARA DIÁLISIS VASCULAR"
    },
    {
        "id": "7f7f8891-bdcd-db13-e4be-69e332ca25b8",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE CATÉTER URINARIO (FIJO)"
    },
    {
        "id": "f69d5591-56cb-9afe-ca8c-ab4d8c3e36a8",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE DERIVACIÓN (ANASTOMÓTICA) VENTRICULAR INTRACRANEAL"
    },
    {
        "id": "4f789ebd-da86-f94c-45d5-700848dba2b5",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE DERIVACIÓN DE ARTERIA CORONARIA E INJERTO VALVULAR"
    },
    {
        "id": "61aa3fe9-5518-65a5-5d71-e8b60cd3a00f",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO"
    },
    {
        "id": "0b129145-1705-ae8a-f14c-05cc1e656684",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE DISPOSITIVO DE FIJACIÓN INTERNA DE HUESOS DE UN MIEMBRO"
    },
    {
        "id": "0aa27c0c-bd8b-1968-1dc3-bb460383a802",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE DISPOSITIVO DE FIJACIÓN INTERNA DE OTROS HUESOS"
    },
    {
        "id": "8f490231-8be5-4ec0-d3dd-25ef6cd296f7",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE DISPOSITIVO ELECTRÓNICO CARDÍACO"
    },
    {
        "id": "3134e59a-1525-dd6e-1d1b-3e519d19a43e",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE DISPOSITIVO PROTÉSICO, IMPLANTE E INJERTO GASTROINTESTINAL"
    },
    {
        "id": "502dbace-d111-4c0c-ba02-619626ab3eb8",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE IMPLANTE DE ESTIMULADOR ELECTRÓNICO DEL SISTEMA NERVIOSO"
    },
    {
        "id": "6712db97-806c-d995-d16d-99c9ae89df52",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE INJERTO EN ÓRGANO URINARIO"
    },
    {
        "id": "f8ec1267-3711-fdac-46f2-70719038c5eb",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE LENTES INTRAOCULARES"
    },
    {
        "id": "a18a7f33-bd78-c25b-351b-ede135bab679",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS DISPOSITIVOS E IMPLANTES CARDIOVASCULARES"
    },
    {
        "id": "1da8a72c-2e95-beac-9d0b-bff61838c723",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS DISPOSITIVOS E IMPLANTES URINARIOS"
    },
    {
        "id": "d20a0cda-169b-87cb-2aa2-4e4a8d87a41d",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS DISPOSITIVOS ÓSEOS, IMPLANTES E INJERTOS"
    },
    {
        "id": "06ef9f59-99c6-acf6-2ca7-2f023475f0e9",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS INTERNOS ESPECIFICADOS"
    },
    {
        "id": "1d5465b1-4eb6-511f-5553-1c3459133f08",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS OCULARES"
    },
    {
        "id": "59e39d9f-0c81-9cde-16fa-346a95be7baf",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS ORTOPÉDICOS INTERNOS"
    },
    {
        "id": "54069da1-2d19-2469-b121-9da516559701",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS DISPOSITIVOS, IMPLANTES E INJERTOS EN EL TRACTO GENITAL"
    },
    {
        "id": "67af335e-9f3e-fbd9-1e1a-9a68fa58182b",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE OTROS INJERTOS VASCULARES"
    },
    {
        "id": "9062bf1a-7350-a654-4e32-82369f6f71ab",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE PRÓTESIS ARTICULAR INTERNA"
    },
    {
        "id": "4eaccf53-0fa5-9223-32b1-82c17aaf455b",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE PRÓTESIS DE VÁLVULA CARDÍACA"
    },
    {
        "id": "90439383-e06a-074f-d983-1e3b168b7f55",
        "NOMBRE": "COMPLICACIÓN MECÁNICA DE PRÓTESIS E IMPLANTE DE MAMA"
    },
    {
        "id": "906f3ed1-3633-073c-9bb9-ff93abb606d8",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA ASOCIADA CON LA FECUNDACIÓN ARTIFICIAL"
    },
    {
        "id": "5edb7c8c-1e6b-cfa9-020f-1355ce694c4a",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "dff2bbd9-86cf-5fa6-4d66-cf7e8db6d648",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA DE DISPOSITIVO PROTÉSICO, IMPLANTE E INJERTO CARDIOVASCULAR"
    },
    {
        "id": "89b917f3-225c-72b2-8294-aedc8888c7f0",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA DE DISPOSITIVO PROTÉSICO, IMPLANTE E INJERTO GENITOURINARIO"
    },
    {
        "id": "7c7234ac-f855-09e6-260f-d7def968bb1e",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA DE DISPOSITIVO PROTÉSICO, IMPLANTE E INJERTO INTERNO"
    },
    {
        "id": "c1c6683c-8b7a-2d68-fd05-758ccd880645",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"
    },
    {
        "id": "f3dc4fb4-f1d5-ad21-5401-73cc433eaeb7",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"
    },
    {
        "id": "5695ef29-8483-c5bf-b2ff-8211440cc9f2",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "62be061e-d9c9-24dd-19fb-bfef47bbea64",
        "NOMBRE": "COMPLICACIÓN NO ESPECIFICADA DEL TRABAJO DE PARTO Y DEL PARTO"
    },
    {
        "id": "50cd6e24-66d2-49c8-7443-0de86eeb74fe",
        "NOMBRE": "COMPLICACIÓN PUERPERAL, NO ESPECIFICADA"
    },
    {
        "id": "16378e42-3145-4c52-660c-89bdf2e2515e",
        "NOMBRE": "COMPLICACIÓN RELACIONADA CON EL EMBARAZO, NO ESPECIFICADA"
    },
    {
        "id": "11b4cbdc-0bcc-61bf-b59a-17c826ad5171",
        "NOMBRE": "COMPLICACIÓN VENOSA EN EL PUERPERIO, NO ESPECIFICADA"
    },
    {
        "id": "12d17d7d-fce8-f660-ec7b-66ac3fe4da9f",
        "NOMBRE": "COMPLICACIÓN VENOSA NO ESPECIFICADA EN EL EMBARAZO"
    },
    {
        "id": "934f37f1-9e3f-174a-55d4-d9736b7cd14a",
        "NOMBRE": "COMPLICACIONES ASOCIADAS CON LA FECUNDACIÓN ARTIFICIAL"
    },
    {
        "id": "0e698887-8d9f-3b7d-ff60-83f2ea30d284",
        "NOMBRE": "COMPLICACIONES CARDÍACAS DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"
    },
    {
        "id": "a71647bd-596e-b5e4-f703-6cc829e27609",
        "NOMBRE": "COMPLICACIONES CARDÍACAS DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"
    },
    {
        "id": "f7658500-f0ca-e06d-3c8d-9751335477b7",
        "NOMBRE": "COMPLICACIONES CARDÍACAS DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "d631fb7a-9533-3520-032e-e9da3071205b",
        "NOMBRE": "COMPLICACIONES CONSECUTIVAS A INFUSIÓN, TRANSFUSIÓN E INYECCIÓN TERAPÉUTICA"
    },
    {
        "id": "72c5323e-f0bd-c84a-08e8-b08038323147",
        "NOMBRE": "COMPLICACIONES CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "d32db6e6-6e78-4b68-3119-57c9398c21ac",
        "NOMBRE": "COMPLICACIONES DE DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS CARDIOVASCULARES"
    },
    {
        "id": "11de38c0-59ea-e0bc-b7d1-b2b37cf145be",
        "NOMBRE": "COMPLICACIONES DE DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS ORTOPÉDICOS INTERNOS"
    },
    {
        "id": "c5c84927-4fff-22cc-3fca-7284730ba2ea",
        "NOMBRE": "COMPLICACIONES DE DISPOSITIVOS, IMPLANTES E INJERTOS GENITOURINARIOS"
    },
    {
        "id": "54aa00f3-b44f-efdd-1336-c1c0c2364645",
        "NOMBRE": "COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"
    },
    {
        "id": "20ecd888-9c19-7636-c2c9-5e0eb7ef45a8",
        "NOMBRE": "COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"
    },
    {
        "id": "4c2bade9-43fa-8964-6274-f7113301a906",
        "NOMBRE": "COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "ab4f36d1-3b84-3b1a-e1dd-c4ace6aa4e96",
        "NOMBRE": "COMPLICACIONES DE LA REINSERCIÓN (DE PARTE) DE EXTREMIDAD INFERIOR"
    },
    {
        "id": "ed12cb30-a507-39ef-675f-ab4a60d2ceff",
        "NOMBRE": "COMPLICACIONES DE LA REINSERCIÓN (DE PARTE) DE EXTREMIDAD SUPERIOR"
    },
    {
        "id": "f7e6e0e5-e87b-ccbc-0713-8e27b0ada085",
        "NOMBRE": "COMPLICACIONES DE OTRAS PARTES DEL CUERPO REINSERTADAS"
    },
    {
        "id": "95d44e9e-d09f-1589-337a-6cab5d8b0034",
        "NOMBRE": "COMPLICACIONES DE OTROS DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS INTERNOS"
    },
    {
        "id": "71e6c6c4-35c0-adbd-5c8a-4a2a006b699e",
        "NOMBRE": "COMPLICACIONES DE PROCEDIMIENTOS INTRAUTERINOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "afa12358-39f9-647f-22a2-5731052176cc",
        "NOMBRE": "COMPLICACIONES DE PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "808d14b8-795a-8c5c-608a-332084206475",
        "NOMBRE": "COMPLICACIONES DEL PUERPERIO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fbcdf33d-8fed-955a-aae4-b54abe35cece",
        "NOMBRE": "COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL DEBIDAS A LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"
    },
    {
        "id": "e6e4fd0f-aa11-b1f0-5b7f-bc04217a0596",
        "NOMBRE": "COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL DEBIDAS A LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"
    },
    {
        "id": "57788b0b-838c-c3c0-28d2-df9d3c405fd2",
        "NOMBRE": "COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL POR LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "1265b90c-cae7-d57e-0af0-6b47b420272e",
        "NOMBRE": "COMPLICACIONES EN EL INTENTO DE INTRODUCCIÓN DEL EMBRIÓN EN LA TRANSFERENCIA DE EMBRIONES"
    },
    {
        "id": "c93df596-a5f8-9559-69ea-276c4322e0d6",
        "NOMBRE": "COMPLICACIONES EN EL INTENTO DE INTRODUCCIÓN DEL HUEVO FECUNDADO EN LA FERTILIZACIÓN IN VITRO"
    },
    {
        "id": "425b6c89-c187-ccbb-2987-bb0b8d81c297",
        "NOMBRE": "COMPLICACIONES ESPECÍFICAS DEL EMBARAZO MÚLTIPLE"
    },
    {
        "id": "6c41903e-8703-f95d-e1db-37ff983e467e",
        "NOMBRE": "COMPLICACIONES NO ESPECIFICADAS CONSECUTIVAS A INFUSIÓN, TRANSFUSIÓN E INYECCIÓN TERAPÉUTICA"
    },
    {
        "id": "eced3e2e-0d1d-adb7-3291-5e5602b016d2",
        "NOMBRE": "COMPLICACIONES NO ESPECIFICADAS DE DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS ORTOPÉDICOS INTERNOS"
    },
    {
        "id": "d3c84558-3b37-ed12-9699-56cfe1c58918",
        "NOMBRE": "COMPLICACIONES NO ESPECIFICADAS DE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "b4321b0b-c990-f5d4-22c1-eebb20613615",
        "NOMBRE": "COMPLICACIONES PECULIARES DE LA REINSERCIÓN Y AMPUTACIÓN"
    },
    {
        "id": "f12f9d29-5b79-a8ba-7504-5b7347ba60a6",
        "NOMBRE": "COMPLICACIONES PRECOCES NO ESPECIFICADAS DE LOS TRAUMATISMOS"
    },
    {
        "id": "516ee826-6998-12ad-f775-ba8e7603fe5e",
        "NOMBRE": "COMPLICACIONES PULMONARES DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"
    },
    {
        "id": "ba9fb54a-8c81-15e9-0940-c0fb96fb9886",
        "NOMBRE": "COMPLICACIONES PULMONARES DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"
    },
    {
        "id": "4941cdaf-94c7-a368-42ec-1911f207fdb9",
        "NOMBRE": "COMPLICACIONES VASCULARES CONSECUTIVAS A INFUSIÓN, TRANSFUSIÓN E INYECCIÓN TERAPÉUTICA"
    },
    {
        "id": "167870ca-3270-3513-7bc7-7e8dce1149e4",
        "NOMBRE": "COMPLICACIONES VASCULARES CONSECUTIVAS A PROCEDIMIENTO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6210fa19-a7ae-9a27-d567-e1b12aff50ce",
        "NOMBRE": "COMPLICACIONES VENOSAS Y HEMORROIDES EN EL EMBARAZO"
    },
    {
        "id": "a37d7377-3972-8275-d870-090d6405eea5",
        "NOMBRE": "COMPLICACIONES VENOSAS Y HEMORROIDES EN EL PUERPERIO"
    },
    {
        "id": "260560bf-6582-82c6-bdb5-beb1f1e19ba1",
        "NOMBRE": "COMPLICACIONES Y DESCRIPCIONES MAL DEFINIDAS DE ENFERMEDAD CARDÍACA"
    },
    {
        "id": "756d6217-511a-df65-68ef-312652e29ddf",
        "NOMBRE": "COMPRESIÓN DE VENA"
    },
    {
        "id": "0bd6afbd-1594-b621-4e95-ccc24760cd9b",
        "NOMBRE": "COMPRESIÓN DEL ENCÉFALO"
    },
    {
        "id": "a72a3a07-c902-266f-2145-a152df237303",
        "NOMBRE": "COMPRESIÓN MEDULAR, NO ESPECIFICADA"
    },
    {
        "id": "54bad673-8118-2193-b754-c4f0498c81ba",
        "NOMBRE": "COMPRESIONES DE LAS RAÍCES Y DE LOS PLEXOS NERVIOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "bbf12c3b-9dbf-a8e4-c8bb-d11e6e697ea6",
        "NOMBRE": "COMPRESIONES DE LAS RAÍCES Y PLEXOS NERVIOSOS EN ENFERMEDADES NEOPLÁSICAS"
    },
    {
        "id": "d596fd20-9468-49cc-8027-407ba28cb3e0",
        "NOMBRE": "COMPRESIONES DE LAS RAÍCES Y PLEXOS NERVIOSOS EN LA ESPONDILOSIS"
    },
    {
        "id": "9eb37492-79db-31c9-4104-f35969fbd41d",
        "NOMBRE": "COMPRESIONES DE LAS RAÍCES Y PLEXOS NERVIOSOS EN OTRAS DORSOPATÍAS"
    },
    {
        "id": "bec4fd11-8ac9-78fe-282d-4821363ba250",
        "NOMBRE": "COMPRESIONES DE LAS RAÍCES Y PLEXOS NERVIOSOS EN OTRAS ENFERMEDADES CLASIFICADAS EN ORTA PARTE"
    },
    {
        "id": "86a56a30-467a-1f3d-70dd-70b1a0bb095f",
        "NOMBRE": "COMPRESIONES DE LAS RAÍCES Y PLEXOS NERVIOSOS EN TRASTORNOS DE LOS DISCOS INTERVERTEBRALES"
    },
    {
        "id": "1cd8f163-8636-1f6a-b629-7011f7e5fbf4",
        "NOMBRE": "COMPROMISO DEL PÁRPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a702714e-59df-5048-5afb-9bcd21baa2bb",
        "NOMBRE": "COMPROMISO DEL PÁRPADO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7958f335-53a1-ff05-1569-dad3d52791ee",
        "NOMBRE": "COMPROMISO SISTÉMICO DEL TEJIDO CONJUNTIVO, NO ESPECIFICADO"
    },
    {
        "id": "ac4ecf6f-05af-8432-47f0-b3aa8fc07d61",
        "NOMBRE": "CONCRECIONES APENDICULARES"
    },
    {
        "id": "e9b41212-d2ae-7f6d-7b58-42e61ca21974",
        "NOMBRE": "CONCUSIÓN"
    },
    {
        "id": "bd0b11b1-86f0-d649-6492-460ee65da583",
        "NOMBRE": "CONCUSIÓN Y EDEMA DE LA MÉDULA ESPINAL CERVICAL"
    },
    {
        "id": "f48ede76-53e6-9cc7-6e60-fa6f7597d5cf",
        "NOMBRE": "CONCUSIÓN Y EDEMA DE LA MÉDULA ESPINAL LUMBAR"
    },
    {
        "id": "f99679ba-9f9d-0142-cf7e-dfc1dd8284b1",
        "NOMBRE": "CONCUSIÓN Y EDEMA DE LA MÉDULA ESPINAL TORÁCICA"
    },
    {
        "id": "a2dd4c61-629a-644e-1684-cb3c8d59b967",
        "NOMBRE": "CONDICIÓN DE SALUD POSTERIOR A LA  COVID-19"
    },
    {
        "id": "bd119918-83bd-6e78-6286-3a21f8a26918",
        "NOMBRE": "CONDICIÓN DE SALUD POSTERIOR A LA  COVID-19, NO ESPECIFICADA"
    },
    {
        "id": "5d0076db-a3ce-faeb-b2e5-14826933a484",
        "NOMBRE": "CONDROCALCINOSIS FAMILIAR"
    },
    {
        "id": "580714cf-919e-0e97-03f9-6737019087ed",
        "NOMBRE": "CONDRODISPLASIA PUNCTATA"
    },
    {
        "id": "0eb500cb-a1ba-6a96-b651-9a6dbf893efb",
        "NOMBRE": "CONDRÓLISIS"
    },
    {
        "id": "767d89b5-18c1-e690-dd45-33d613b51bcf",
        "NOMBRE": "CONDROMALACIA"
    },
    {
        "id": "dd24d0b2-0c59-2093-d161-fd072244f6ea",
        "NOMBRE": "CONDROMALACIA DE LA RÓTULA"
    },
    {
        "id": "1ad4ccd7-3e82-e2f6-0713-c23383eb8c89",
        "NOMBRE": "CONDUCTA EXTRAÑA E INEXPLICABLE"
    },
    {
        "id": "208b8eb5-280e-0c5b-09e8-d5e44e23f56d",
        "NOMBRE": "CONDUCTO ARTERIOSO PERSISTENTE"
    },
    {
        "id": "d88f6cf7-b9ca-4484-d822-8fc8b9a70fba",
        "NOMBRE": "CONDUCTOR DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "98cc1b04-821f-3449-cfc3-51aa147c9537",
        "NOMBRE": "CONDUCTOR DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "e97e8a1c-cb04-5f65-a968-4889a06125d7",
        "NOMBRE": "CONDUCTOR DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "be8efd9b-0d63-2da9-0a99-9375570c8899",
        "NOMBRE": "CONDUCTOR DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "dfaa3ebb-719a-e07b-fc66-948a9f781b0e",
        "NOMBRE": "CONDUCTOR DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "86cd3c47-deca-f350-214f-a690646df4f1",
        "NOMBRE": "CONDUCTOR DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9ce66ac6-ddd5-2ad5-ac49-e86c3c5e56e1",
        "NOMBRE": "CONDUCTOR DE MOTOCICLETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9a0100cc-2f5a-30b5-bfb6-b147ee9730fb",
        "NOMBRE": "CONDUCTOR DE MOTOCICLETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "95547aeb-a888-aceb-7908-1fc8fe5f25d3",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO AGRÍCOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "0ee7e3c1-09ee-9bea-271c-c0e88f759c33",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO AGRÍCOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6e5506b0-93f7-4c93-559e-1f87ba5a8056",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5e5be5a6-f26d-5377-3281-e8f4632f3143",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2bc46d9c-6e67-08bd-8453-3137077e4ebc",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO DE PEDAL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "cb4ff1a0-2c02-a78b-4ecb-0719ea8f6cbd",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO DE PEDAL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6ab4b020-44ad-4443-f9b0-d5b2e9a8a9f1",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5face516-af95-dd2c-82bf-d26eb8887ba3",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c0527d82-92e2-9d28-db7e-e1672efcfa85",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c9139ea3-ae07-4fe0-402c-e235a6583abe",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d53704b8-da65-1450-43b8-d35779a7bae4",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "cc19dedc-038f-f553-8205-d72af86ba5df",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "308dfa50-ce07-31ed-1802-53e39e111dec",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2d4a7fd9-4f65-3feb-3be4-8a2cff1327b6",
        "NOMBRE": "CONDUCTOR DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3e15ad21-fa77-245d-f8b6-4a3502648f61",
        "NOMBRE": "CONEXIÓN ANÓMALA DE LA VENA PORTA"
    },
    {
        "id": "62119d2c-5f80-02d4-d782-ed99118f1895",
        "NOMBRE": "CONEXIÓN ANÓMALA DE LAS VENAS PULMONARES, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "fe754e60-4c02-fd48-997d-03b144964087",
        "NOMBRE": "CONEXIÓN ANÓMALA PARCIAL DE LAS VENAS PULMONARES"
    },
    {
        "id": "d4c673b4-178a-ec28-6f08-2ffab4780db2",
        "NOMBRE": "CONEXIÓN ANÓMALA TOTAL DE LAS VENAS PULMONARES"
    },
    {
        "id": "f6d217d1-8733-2b73-6002-eaaf589d9e1a",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXÍGENO"
    },
    {
        "id": "35d2aeda-9279-25f0-f7e0-bb8e742f9a61",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "202db28d-6f12-d8fe-433b-09ec26b67f10",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "413ef050-4d9e-fdcf-aba8-d90ff3beb84f",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, CALLES Y CARRETERAS"
    },
    {
        "id": "06ec9826-2274-29bb-ecd7-10c657b13d7a",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "01ae9eed-2643-bba4-6d38-a7ba2dfa68d1",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "9a11769b-d1d3-6ec6-7050-f7dd7648217f",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, GRANJA"
    },
    {
        "id": "8796d0a6-8ed3-acbc-cfbb-5a9f7f5bcea7",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "11c39573-6e43-13aa-c6ba-89c7e07804aa",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ab2c4232-4768-9d36-caff-5259cb46b6ee",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ac5ffd29-440c-7bd3-0fa9-74c0d4e997c9",
        "NOMBRE": "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, VIVIENDA"
    },
    {
        "id": "f5b9857c-ed2f-bba3-fc80-64f745c204af",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR"
    },
    {
        "id": "daf7cae6-8b8c-7043-6170-bfb9617d4784",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DE LA CABEZA"
    },
    {
        "id": "592c3d1f-dd0f-7623-6745-94f7614593eb",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "74f41023-995b-710d-33a9-ef66d0048b01",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "e79d0177-b55f-7601-7b5f-2626b2c43a89",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DE LA PARED ABDOMINAL, REGIÓN LUMBOSACRA Y PELVIS"
    },
    {
        "id": "c5fa83b2-11ed-ad67-f651-0317c201ca0b",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DE LA RODILLA Y DE LA PIERNA"
    },
    {
        "id": "3fc96590-a69c-9773-63c8-1b6a2c9e3e02",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "07682d1a-1f3b-2fbe-e2bf-a8901fe12b16",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DEL BRAZO"
    },
    {
        "id": "1fb47a8f-a618-3e07-3bba-f4d1c81a52a3",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DEL CUELLO"
    },
    {
        "id": "e4100560-af19-e45d-d5dd-57ff5053a631",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "f9bd8913-5602-a141-50ff-275cdd93a164",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR DEL TÓRAX"
    },
    {
        "id": "a9e074fe-1572-8390-1aa2-814d6fa8c39f",
        "NOMBRE": "CONGELAMIENTO CON NECROSIS TISULAR QUE AFECTA MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "7440bc41-3849-038c-4640-c73c8802207f",
        "NOMBRE": "CONGELAMIENTO NO ESPECIFICADO DE LA CABEZA Y DEL CUELLO"
    },
    {
        "id": "08cf29f5-1a13-a678-9e37-4b69c186a6a4",
        "NOMBRE": "CONGELAMIENTO NO ESPECIFICADO DEL MIEMBRO INFERIOR"
    },
    {
        "id": "950fdea5-8ebc-8360-91e6-e7ea4f5a1e05",
        "NOMBRE": "CONGELAMIENTO NO ESPECIFICADO DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "9e118e68-3d76-93fd-89b2-85b7ce01c851",
        "NOMBRE": "CONGELAMIENTO NO ESPECIFICADO DEL TÓRAX, DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "9295fd52-15ad-8596-3efe-eb656f5bff5b",
        "NOMBRE": "CONGELAMIENTO NO ESPECIFICADO QUE AFECTA MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "4a523fce-f68d-f8ea-924e-342402de97cd",
        "NOMBRE": "CONGELAMIENTO NO ESPECIFICADO, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "69374ae1-1b96-abf6-be0c-a56fcb12fe1d",
        "NOMBRE": "CONGELAMIENTO QUE AFECTA MÚLTIPLES REGIONES DEL CUERPO Y CONGELAMIENTO NO ESPECIFICADO"
    },
    {
        "id": "96760992-ae7f-729b-10bf-33eeb2219d89",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL"
    },
    {
        "id": "a66f31c3-0763-7c84-becb-1f8c92aa247c",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DE LA CABEZA"
    },
    {
        "id": "81e11ec4-3c4d-8ac7-1d3c-ed253f190b7a",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "b2369376-8ee8-6fd9-d12e-894cb67f5e6b",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "31a545e9-ce77-b8e6-de91-830ea1a42628",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DE LA PARED ABDOMINAL, REGIÓN LUMBOSACRA Y PELVIS"
    },
    {
        "id": "f0398d58-aa0b-0d25-33e2-bade6dad2609",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DE LA RODILLA Y DE LA PIERNA"
    },
    {
        "id": "1fae4145-486b-c9eb-52d1-6393b05f2d16",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "9d621234-1ca9-f6f4-632c-bb3d0601e3ea",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DEL BRAZO"
    },
    {
        "id": "7e670fae-20f4-c668-b7d6-fa210789917d",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DEL CUELLO"
    },
    {
        "id": "2a4700a5-99d8-1ec7-cd53-cf60fc4006c8",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "e33cf510-3dc6-f3e3-af60-061ab999bed9",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL DEL TÓRAX"
    },
    {
        "id": "f2b3a23c-b66e-5a94-4495-2a610d06381c",
        "NOMBRE": "CONGELAMIENTO SUPERFICIAL QUE AFECTA MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "e95806b6-7ba9-d9c0-868f-28e026d00e99",
        "NOMBRE": "CONGESTIÓN PASIVA CRÓNICA DEL HÍGADO"
    },
    {
        "id": "19d2f20d-8e46-2227-e6a9-702c14bd2b7b",
        "NOMBRE": "CONGESTIÓN Y HEMORRAGIA DE LA PRÓSTATA"
    },
    {
        "id": "a5685f45-2027-caef-5e23-b25242ecc14d",
        "NOMBRE": "CONJUNTIVITIS"
    },
    {
        "id": "fe7919ff-199b-cecc-9bfa-69538dfce03b",
        "NOMBRE": "CONJUNTIVITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "9bfe44a9-66b3-285c-1416-afd35572c7ee",
        "NOMBRE": "CONJUNTIVITIS ATÓPICA AGUDA"
    },
    {
        "id": "cb2b172c-8ec4-dd02-f725-4fdd0bee1231",
        "NOMBRE": "CONJUNTIVITIS CRÓNICA"
    },
    {
        "id": "6bf2b6e8-6aac-d3aa-5b98-6604443ceb32",
        "NOMBRE": "CONJUNTIVITIS DEBIDA A ADENOVIRUS"
    },
    {
        "id": "4454340c-4564-1740-a7b0-dea8c6550dee",
        "NOMBRE": "CONJUNTIVITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b76d17b8-4f1c-fb34-6199-ad7caa66b928",
        "NOMBRE": "CONJUNTIVITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4f85df90-17c1-59e6-51a8-5ac2e563802e",
        "NOMBRE": "CONJUNTIVITIS EPIDÉMICA AGUDA HEMORRÁGICA (ENTEROVÍRICA)"
    },
    {
        "id": "e09029ad-0f47-ad21-5579-75bcb2bff0b7",
        "NOMBRE": "CONJUNTIVITIS MUCOPURULENTA"
    },
    {
        "id": "30ccdcd2-ece7-d15e-6e2f-cfc8a8a30287",
        "NOMBRE": "CONJUNTIVITIS POR CLAMIDIAS"
    },
    {
        "id": "69552cbd-df8c-4968-4eec-177f4736df98",
        "NOMBRE": "CONJUNTIVITIS VIRAL"
    },
    {
        "id": "85adae84-24aa-fbd0-eec7-579584d08e23",
        "NOMBRE": "CONJUNTIVITIS VIRAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "19fd1227-4f3f-e9b2-4f7a-b8de733296bd",
        "NOMBRE": "CONJUNTIVITIS Y DACRIOCISTITIS NEONATALES"
    },
    {
        "id": "cbe1bcb0-b95c-905b-798d-1d1bb50dc288",
        "NOMBRE": "CONJUNTIVITIS, NO ESPECIFICADA"
    },
    {
        "id": "628f09d3-83f6-6880-bb4f-755d7328a2b2",
        "NOMBRE": "CONSEJO Y ASESORAMIENTO GENERAL SOBRE LA ANTICONCEPCIÓN"
    },
    {
        "id": "4aa82c90-5e1e-be02-c983-d174819945d7",
        "NOMBRE": "CONSEJO Y ASESORAMIENTO GENERAL SOBRE LA PROCREACIÓN"
    },
    {
        "id": "291666b4-8c34-97cc-5c03-0825aca6682b",
        "NOMBRE": "CONSOLIDACIÓN DEFECTUOSA DE FRACTURA"
    },
    {
        "id": "f63c1521-e06c-dae6-321e-0149d3a1928d",
        "NOMBRE": "CONSOLIDACIÓN RETARDADA DE FRACTURA"
    },
    {
        "id": "966bf043-4d04-019b-8680-dba32777c172",
        "NOMBRE": "CONSTIPACIÓN"
    },
    {
        "id": "04e8096a-0b6c-8d9f-f1b7-b05a984a2352",
        "NOMBRE": "CONSULTA PARA ASESORÍA POR ABUSO DE TABACO"
    },
    {
        "id": "3a7a96f8-7320-b606-8cb1-2c8f03c0162c",
        "NOMBRE": "CONSULTA PARA ASESORÍA SOBRE EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"
    },
    {
        "id": "b467509d-919a-efd7-01ab-7eec9d84e874",
        "NOMBRE": "CONSULTA PARA ASESORÍA Y VIGILANCIA POR ABUSO DE ALCOHOL"
    },
    {
        "id": "5b0692d9-d1c0-a1e2-e004-7dd9ef540a82",
        "NOMBRE": "CONSULTA PARA ASESORÍA Y VIGILANCIA POR ABUSO DE DROGAS"
    },
    {
        "id": "fe4ace12-e33d-6be5-b75a-3647e72fdf98",
        "NOMBRE": "CONSULTA PARA ATENCIÓN Y SUPERVISIÓN DE LA SALUD DE OTROS NIÑOS O LACTANTES SANOS"
    },
    {
        "id": "bbf0a399-2874-986e-1886-c60bf868ccdf",
        "NOMBRE": "CONSULTA PARA ATENCIÓN Y SUPERVISIÓN DE LA SALUD DEL NIÑO ABANDONADO"
    },
    {
        "id": "891a7eaa-c829-b544-af94-e4f9be8c8fda",
        "NOMBRE": "CONSULTA PARA INSTRUCCIÓN Y VIGILANCIA DE LA DIETA"
    },
    {
        "id": "8997bf33-a3b1-9825-8436-2996648a6eec",
        "NOMBRE": "CONSULTA PARA REPETICIÓN DE RECETA"
    },
    {
        "id": "0a628b73-48b0-8651-0ad7-21944317f354",
        "NOMBRE": "CONSULTA RELACIONADA CON ACTITUD, CONDUCTA U ORIENTACIÓN SEXUAL"
    },
    {
        "id": "b43328ef-45d4-2c1c-4d72-607f45499166",
        "NOMBRE": "CONSULTA RELACIONADA CON LA ACTITUD SEXUAL"
    },
    {
        "id": "fff35fc1-9c2e-cd87-3adf-711aeec27119",
        "NOMBRE": "CONSULTA RELACIONADA CON LA ORIENTACIÓN Y CONDUCTA SEXUAL DE UNA TERCERA PERSONA"
    },
    {
        "id": "b1fffcc7-0591-3c5e-0a1c-07e445829a7e",
        "NOMBRE": "CONSULTA RELACIONADA CON LA ORIENTACIÓN Y CONDUCTA SEXUAL DEL PACIENTE"
    },
    {
        "id": "da94f8f5-ad99-9f21-81eb-48e39b47e6cb",
        "NOMBRE": "CONSULTA RELACIONADA CON PREOCUPACIONES COMBINADAS SOBRE LA ACTITUD, LA CONDUCTA Y LA ORIENTACIÓN SEXUALES"
    },
    {
        "id": "27661440-e9fc-18b4-1c61-a5bba5396b7d",
        "NOMBRE": "CONSULTA SEXUAL, NO ESPECIFICADA"
    },
    {
        "id": "e5f4fcd9-0b79-d277-ba04-f7be33283e09",
        "NOMBRE": "CONSULTA, NO ESPECIFICADA"
    },
    {
        "id": "c526a3e3-15ef-a757-1fa0-82cea5cd174d",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE"
    },
    {
        "id": "e7490f12-af31-7e4b-aa5b-95cc1981f748",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7d19a5a3-520a-5bf8-51e8-000e0b722369",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e6570706-9171-267d-963b-d1b9ffeb4f08",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, CALLES Y CARRETERAS"
    },
    {
        "id": "1046302f-0104-b842-f035-36a16cad9662",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "d74df86c-b479-59ee-9371-fc2eeef0f27f",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "77465380-d972-9629-f5c6-e2e1639896d8",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, GRANJA"
    },
    {
        "id": "93057f54-b048-d13d-0f3a-14281424feb7",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "2df0ba0d-18d2-931a-7145-56dee04d1677",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5732522e-5fb9-2c4f-62e6-90a965c980a3",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b1b819a2-c607-dbaf-49c1-d393ae1f70af",
        "NOMBRE": "CONTACTO CON AGUA CALIENTE CORRIENTE, VIVIENDA"
    },
    {
        "id": "00ba948d-2bc3-df62-0e00-277749f7a306",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES"
    },
    {
        "id": "5b221e1c-f0ae-7e6c-43b3-1017dbef5377",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "367293c6-1f77-50b4-32b8-e0580b97d1d1",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f51f9c1d-6b12-2d86-9b2f-84597d0b2b59",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "94ec8c0b-f8cb-af0c-e319-69d7d803ce0b",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "4551ad03-1034-3c15-aecd-7fb9c6a9205e",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2cc9990f-5943-0531-3913-e03e1bd6d2a0",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, GRANJA"
    },
    {
        "id": "c6c7b0ec-7039-38b7-7536-8960238ce717",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ec563739-09c9-7faa-73ff-35ee0099b596",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9e2dc038-ba9a-f18f-6570-f3818d1c24d9",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "f47a4e97-251e-de9c-cdf0-3ad38c3ee816",
        "NOMBRE": "CONTACTO CON AIRE Y GASES CALIENTES, VIVIENDA"
    },
    {
        "id": "e1a89080-b98e-58f3-dc49-51eda4f2065d",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES"
    },
    {
        "id": "917883c8-bd88-8472-8509-064376bc1b76",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "36a7ccd7-ea26-6db2-c93c-988f9c019eb8",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "bfb320d5-8aa2-faa2-7125-20b050a88a53",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "ea060791-7983-f23d-1400-5c43c428fdca",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6811042f-c52d-a614-53ef-d5a2b8a79891",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "3686045d-2480-f822-8a41-3ca3c586101f",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, GRANJA"
    },
    {
        "id": "295539db-fc2d-5da8-fa16-463127a7920d",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "d70e5c74-6e4a-4521-490a-f59320267536",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d7dda530-3b10-2877-4c17-31ca9b629f8d",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ade61736-c975-0901-0f12-bdeb6420a807",
        "NOMBRE": "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, VIVIENDA"
    },
    {
        "id": "2da12022-4ffd-0953-68b3-969b8723b1ad",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES"
    },
    {
        "id": "c35bd6ac-ea72-98d0-7ade-9c765f2af66b",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "887d6a5a-47b6-757e-74fc-dd9b18fe5d4c",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4736a215-47f2-1c0a-814d-535e5e4c6fab",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "b512ea8e-3e6d-d1cf-58d6-efa49059d5c1",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "384ecfe2-e3c6-16f6-2541-38bbdbef5b17",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b62c5864-900e-76ea-342d-498657b44534",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, GRANJA"
    },
    {
        "id": "9cebd464-6f60-886f-35b5-4b4f95447145",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "fbb0164c-ba82-9016-9f43-eddd5de1736b",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "edc1f8ed-e944-99aa-979c-9cd0795ae8e1",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8f421412-c230-aba9-9308-a3d94ba37abe",
        "NOMBRE": "CONTACTO CON MÁQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, VIVIENDA"
    },
    {
        "id": "e7ff8a53-b345-003f-ba9b-a605e12e40b9",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "b4d22a7d-a85f-282c-2f06-1d0e51994b55",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7f418bd5-e0ac-a331-3d48-edf3ca0ce14d",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2bd8e887-55d2-7e6b-4c91-d78c8456cc16",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "10063cc2-524d-e98d-2f18-c6d630bc1049",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "bae1efdf-123e-ae41-394d-32aac1416784",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "9cf1b8ee-6086-1af2-7f50-beb784d2181c",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "bf89cb03-bc21-b5f3-162a-84b812752c6a",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "98389e48-2f40-34e6-4762-f74fb13481be",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "e883fd84-3ecb-b6fa-fb3f-6c7e7669da04",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6c160639-83bf-a232-d51b-199101516772",
        "NOMBRE": "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "e241290e-262f-d3bd-e555-dde091c2ad60",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES"
    },
    {
        "id": "98517db9-0867-9c69-55c3-bac719e04d17",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "644985c2-53f9-3380-fdfe-261a907716dd",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5fb5afcf-dff2-55ba-c589-5c08fac13cab",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "bb146d21-422d-de2b-a40d-6b187bf9f7e6",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "9dbbd0f6-e438-b902-0e7e-8f195afceccd",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "81671d7e-937d-3e30-fe06-b828dcf06a82",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, GRANJA"
    },
    {
        "id": "af9abe98-7c6c-3029-df6c-b877881a9827",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "dd70d6a5-e868-afaa-dac6-dd1b5da3edfa",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5139a79f-e477-4232-c660-0daad69749aa",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "fe583c58-b820-e547-26cb-b57525494891",
        "NOMBRE": "CONTACTO CON OTROS LÍQUIDOS CALIENTES, VIVIENDA"
    },
    {
        "id": "f3260c17-a5b8-d4e5-3d9f-0b03547da491",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES"
    },
    {
        "id": "d74dea31-774c-d0e7-b1e6-db2364f07903",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0e4e2c22-e25a-d94c-96e8-9eb39e1de228",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "73389110-ca22-1316-baed-c5de1eed9f15",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "0e39c7fd-78a9-31de-da2d-8104868be27c",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6004d1e4-5d95-52b7-e867-e5c13bf6e400",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2c0d0970-87f2-08f2-d5f1-183f643076be",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, GRANJA"
    },
    {
        "id": "b3f6ea9a-7a1c-9972-c6d3-566012389f56",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "9ad7d3f5-be7e-a379-6511-73971706b74e",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "617ef3e3-348f-e87a-54ce-c11b5955bd58",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ea0e2651-9ce8-fd43-0e3b-0162cfa48b34",
        "NOMBRE": "CONTACTO CON OTROS METALES CALIENTES, VIVIENDA"
    },
    {
        "id": "42e8bc8f-515d-62ca-b223-d68059c10973",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES"
    },
    {
        "id": "32d214c1-9019-7154-6ff2-f326e074d29e",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "ce708b4b-8e28-4ce7-9110-f150043c359d",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "578e2d1f-cd71-b4e4-1cad-dcddca54dd0c",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "195c1f8e-80d0-1fd9-a99d-56743b6781ba",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "cda027d0-1b4c-3b1a-6b66-86131ac5d08f",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c411b9e4-4a88-aa55-876a-aa2b12285f7a",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, GRANJA"
    },
    {
        "id": "440d14f9-b941-691d-e80c-d848f32868ef",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "c548d2cc-3ba8-a7f2-4fd1-c0267bba58d5",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d0c028c0-daa5-3e84-6ff4-1e1f9a331704",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "70b5eba0-a4af-7182-fbdd-b18ddcec1d7c",
        "NOMBRE": "CONTACTO CON RADIADORES, CAÑERÍAS Y ARTEFACTOS PARA CALEFACCIÓN, CALIENTES, VIVIENDA"
    },
    {
        "id": "1d93ea7d-e822-9c29-1c44-4f458df880ad",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES"
    },
    {
        "id": "215289a3-9e54-d83a-7abf-840e1d2077ac",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "c3770e4f-e2bb-4bf5-8cb7-acd84ec5d45f",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "b8ffa239-513d-6123-b70d-735ead26e36d",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "020d60d8-022e-f5db-f814-9504e699e86d",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "48964386-f16e-eaa6-f177-50db6dc94a5b",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "83ca7b7c-27c1-64da-3ba2-ab1a567749b7",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, GRANJA"
    },
    {
        "id": "36ad04d9-53d9-ae8d-031d-d03e178dfd46",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e70bd528-f0ec-e379-e05d-4b50ebd31752",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "b8f4aca3-f3da-4b81-dd00-4e42dbbc0f8a",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "4e039214-3179-cafa-f811-875d8641e5d8",
        "NOMBRE": "CONTACTO CON UTENSILIOS DOMÉSTICOS CALIENTES, VIVIENDA"
    },
    {
        "id": "1427a0a7-729a-c176-8b80-d61d1eef66bc",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES"
    },
    {
        "id": "c79253ec-c8c3-36ee-51d1-fcd7d9f86a39",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "4943828b-7015-7824-a052-fafcc4425f1d",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8fb4a7d2-f66b-8808-63a0-fa6aeaf9a111",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "4b930271-90b6-1094-6db8-caac735698fe",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b4ba9899-059d-98ac-1e29-c25d813fe2d8",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "bfba2e99-7fe6-38b3-fb97-a3eeab1a81ec",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, GRANJA"
    },
    {
        "id": "22ae42a8-ad26-95bd-d325-7c7f25e31487",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "307d1fbd-8faa-09d9-9a9c-df4395a1ad16",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "6e6add22-682e-3d31-4fe1-d0d277564b0e",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "04ad0249-6463-3164-81f7-cb09bc73c5d5",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, VIVIENDA"
    },
    {
        "id": "494228d6-4343-8af9-ba98-b02e3c30f78e",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "cf961933-fb48-fd09-009a-c9b562412f53",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "12cb89d4-4a02-a6b5-d89c-cc0e8eedc32c",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "677d0414-ff30-565b-c44c-2a3451c4ec89",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "535d7b30-e088-4cc9-7608-58b2cc9c5542",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "98edf2fa-1829-462f-e57c-2641546dd4f6",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2d9807d9-c43f-9b29-a04d-6e10cd3c01f9",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "bd6f90e5-c524-3d2f-9cc3-df79b2d08448",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a5f0ff6e-dbae-36e5-36b7-e1ed2c800f67",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "575623c5-a451-38c1-5a04-6c807dd4ea54",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "9178edde-f488-ba41-e18b-479ef3b295ad",
        "NOMBRE": "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "22898e0d-79cb-cc93-ec73-a9dd6818d680",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A ENFERMEDADES INFECCIOSAS CON UN MODO DE TRANSMISIÓN PREDOMINANTEMENTE SEXUAL"
    },
    {
        "id": "f4ac9400-d7cb-9478-97c4-759b1fba0fc5",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A ENFERMEDADES INFECCIOSAS INTESTINALES"
    },
    {
        "id": "dbc3c3c0-92a4-2bdb-31e8-d9fa756a9359",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A ENFERMEDADES TRANSMISIBLES"
    },
    {
        "id": "59778378-278e-b9af-e548-95d4dba73684",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A ENFERMEDADES TRANSMISIBLES NO ESPECIFICADAS"
    },
    {
        "id": "ed17358d-f219-bf55-9952-d689c7f20e92",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A HEPATITIS VIRAL"
    },
    {
        "id": "04843df6-7369-cfdb-d078-d29461cc0a03",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A OTRAS ENFERMEDADES TRANSMISIBLES"
    },
    {
        "id": "93d02d17-ccab-1e20-e32f-3aca9fbdbc50",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A PEDICULOSIS, ACARIASIS Y OTRAS INFESTACIONES"
    },
    {
        "id": "8d3efbff-d168-ded0-cda0-2976a9001966",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A RABIA"
    },
    {
        "id": "85cf197e-3522-3b1a-3960-63f2dea62821",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A RUBÉOLA"
    },
    {
        "id": "f6ed9bd0-e1ab-a224-e218-b036c99569ed",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN A TUBERCULOSIS"
    },
    {
        "id": "32145553-84be-21e1-67ce-0a677cec2019",
        "NOMBRE": "CONTACTO CON Y EXPOSICIÓN AL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"
    },
    {
        "id": "00288a0c-5ba5-60a6-8e11-e03d10b3cc7f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS"
    },
    {
        "id": "1cf13814-b43f-32d6-d5f5-d97fd7b0446e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b96b3208-e7a3-9da8-a425-50254414c04d",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "da08c334-6e4c-e28f-5e4b-e1adb1df4af9",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, CALLES Y CARRETERAS"
    },
    {
        "id": "e8bfa2f4-9d80-d075-015b-93ee0034636b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "4b2778ba-c312-1fea-1872-53c3d6e5d3ad",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "683583e3-183a-aeda-d408-10c8eaf45a9f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, GRANJA"
    },
    {
        "id": "63994820-8861-3655-bb02-ffd78359330a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cebfbbea-911b-82fe-8ac3-1e965586010f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "41f46523-3e69-1c26-bbc8-4326ea12eae4",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6a5f0dd7-fdaf-a712-5b37-bbe984c154b6",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, VIVIENDA"
    },
    {
        "id": "4fb01d97-f101-bdee-1260-5506aeb1e85c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA"
    },
    {
        "id": "1ae19718-549f-3cec-9795-34372e9b5db8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0611ad00-9845-5ce0-3422-322a6761221a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "82fbf7ae-379e-ec26-ce53-3809da7dcecf",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, CALLES Y CARRETERAS"
    },
    {
        "id": "aba585d2-6eca-b87b-ccdb-57784c956513",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e86d315c-63b7-99a5-bace-4806605c1889",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "37ec41d1-c3f5-137f-b114-92149354b71c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, GRANJA"
    },
    {
        "id": "345a2992-a215-f084-a389-e4e268969b54",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "6dee1e75-7600-a515-daed-0b7e3d4fcf33",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "cd3a7a72-fbb1-2389-b493-0e3de22a43d5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d508b9df-6e11-6d22-95db-d253a8c1796f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AGUJA HIPODÉRMICA, VIVIENDA"
    },
    {
        "id": "676b6631-50ab-83f6-e5b4-05abceeaab2d",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS"
    },
    {
        "id": "2c406aee-c07f-6afe-e043-5499806a466b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "dee4ebc8-2b2b-7e57-26f2-163d4773a194",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "16a6fb3c-f1e3-dc08-9daf-f0ded23bf504",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, CALLES Y CARRETERAS"
    },
    {
        "id": "06fdda5d-b003-e5a6-d2c9-ceb00ea49278",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "740f46a1-fa7f-4160-563b-3ae8a92a0a9a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e0cc6966-d5d5-56f9-6b77-289918f8e586",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, GRANJA"
    },
    {
        "id": "52b4f64b-a7b7-f799-8ef8-82fbb402e41f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "bfff2297-c05f-c123-9474-54ca2d7e7e56",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c1397361-91f8-e6b0-d2d1-aec2412a2c94",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b197e527-f89e-35db-9a99-73cb7520bd93",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES MARINOS, VIVIENDA"
    },
    {
        "id": "b18e4847-5388-5755-4cfa-007ea8d38a81",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS"
    },
    {
        "id": "ed7cdcf6-a52b-c887-1dac-0d24659117f5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "67e15b90-1671-efd0-82a4-0b7f9647ff66",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "59fa0888-23e4-fcd0-45a8-21e8d01878b7",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, CALLES Y CARRETERAS"
    },
    {
        "id": "7f4a80fb-8e64-c3cc-318c-d887a461a805",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1aa578e7-55b9-60a1-4278-60159210ac02",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6adb0a1e-e47c-acef-670b-553d59a5a04b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, GRANJA"
    },
    {
        "id": "39c700f3-f24f-a338-c108-47979fa8f3d0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e325d7d6-c709-bfad-2d1b-504a37d7e6a8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "4d5bae78-8270-d9fe-5605-e14ddd2bb7e0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e695752e-98ee-a1b2-f30e-f952a0ffd6d5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, VIVIENDA"
    },
    {
        "id": "20d2ddbf-4c68-375e-1a7e-ab5158887577",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS"
    },
    {
        "id": "f4fd3559-de59-f79f-ca56-9a5dae241f0b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "5e7d0aa0-72ca-7278-88ab-e8ab1028e959",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c4eaf83c-f5e4-955c-20af-e2392ca63180",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "2b49c0ff-7816-7e92-3e6a-b5b66ce8342d",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "ebff20d8-26de-c491-baea-7724f78655a8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "073f22ac-724c-0092-1540-bfa729a5e6d8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "64b6f509-93ef-1b0f-6e71-d842b75e0b54",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "442ddf25-8889-05a7-a9d4-adc98ee91ba7",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "bacf5f15-4a7a-7b36-38d9-d625ff904f3c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "84a65271-cfcb-043e-bfe8-91839f4c9f06",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "a3671c48-49f4-f89f-4e7d-eb19db20701c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS"
    },
    {
        "id": "797c93be-458c-fa2f-20aa-a74f2384d742",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "9bdae6b6-e642-ae91-ef5d-50f12fb76219",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "7fbddee1-efa1-e76a-db5a-ea084c15f69c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, CALLES Y CARRETERAS"
    },
    {
        "id": "da1be34e-4487-192c-28ae-b550b39a84ce",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b4fbeba9-cd6f-917a-0dc8-71a45aea0e85",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a0eb076e-c091-a56a-ea19-66a2b78a1312",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, GRANJA"
    },
    {
        "id": "d48f8096-c178-17a2-2207-5d272fcdd4c9",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "141b7659-90c8-e220-8264-f2617247f714",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d5867504-2072-3616-3aa3-eaf31707d1ee",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "adf2e6e9-e557-3fa7-c300-0637357f46ba",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ARAÑAS VENENOSAS, VIVIENDA"
    },
    {
        "id": "62045a28-a26d-f48d-5e8a-1543cb1135be",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS"
    },
    {
        "id": "92ea9bc9-ccc9-0aa1-40b3-acf976fbe5a3",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "72202cdf-bc23-3c5a-d859-1892d559343b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "09f417a2-53ea-de9f-28c8-ff42f0f58889",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, CALLES Y CARRETERAS"
    },
    {
        "id": "0e81198b-be32-acba-0522-7a7c3a104e7e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3a0e9ce8-c79c-2309-a2b9-a090fd0797b5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c0ab8d57-6c87-f294-dfc4-680313038cd8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, GRANJA"
    },
    {
        "id": "0b4b3f47-2f07-b9f7-920a-2a711ad45849",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "802096c3-b3af-5a7d-8768-eb4de5ee6e9a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "3111ea6d-be54-fd97-a012-9583ece2d524",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "02663357-d815-df1f-aca0-a53af98d9d4f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON AVISPONES, AVISPAS Y ABEJAS, VIVIENDA"
    },
    {
        "id": "c248a53b-0450-a4ea-3a0b-15ff736063f8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES)"
    },
    {
        "id": "9010ac18-e80f-4097-7512-fc94ebdf0d64",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "43596ca8-1203-c87c-eeb7-f70cab616f68",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "b544434f-2d67-dee1-9f14-7d86f4ae2f2e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), CALLES Y CARRETERAS"
    },
    {
        "id": "31f5dc2b-19c6-a444-f40e-8045036ccf70",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5182caad-3c00-f1ab-172a-c667d05211ef",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "68910f33-f596-a484-f0df-db9d3ed89b89",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), GRANJA"
    },
    {
        "id": "e0ccd0e8-47f8-2bc7-50e2-b6c09e2c6a22",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ee80bc53-e060-f1eb-12e8-a8ba08c41926",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), LUGAR NO ESPECIFICADO"
    },
    {
        "id": "34ec70b7-0a01-71f2-cf4f-687cf59626a3",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "43b63a33-40ab-87f6-c102-7844e29df2f5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CENTÍPODOS Y MIRIÁPODOS VENENOSOS (TROPICALES), VIVIENDA"
    },
    {
        "id": "8b2dc0ec-7a58-2c50-00d9-e9aa58737dec",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR"
    },
    {
        "id": "5a21e84c-0b31-70b0-e2cd-0a4092bc7b20",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "dd63f0b9-70c4-02d6-0098-5c795908f0da",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "50f33ad5-aa69-3021-b19b-2561572a8906",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, CALLES Y CARRETERAS"
    },
    {
        "id": "8777ac3f-b883-2332-8a97-9e0758cf8f0c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0b0fc312-8af1-a308-f0fb-9078b3d71c83",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "3e7850a6-bb2d-84e0-17ba-5a17e9b4ad46",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, GRANJA"
    },
    {
        "id": "4c0169c3-5f75-f0a5-265c-7de4b3cf2cb5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7ff07474-9365-0feb-3599-5ead7edb5abf",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9a605d8c-c9b8-78db-c019-1dec464999fb",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b0cf14c6-4f3f-2480-f963-6a04d07f3940",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CORTADORA DE CÉSPED, CON MOTOR, VIVIENDA"
    },
    {
        "id": "ab816d3e-1162-cdcc-f2d0-7af337aceea3",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA O DAGA"
    },
    {
        "id": "5fe9774f-d056-3d98-5fc9-4a668414f6f4",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA, DAGA O PUÑAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "842b9e48-8541-1ba7-f3b9-6856c5bb434f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA, DAGA O PUÑAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "bca24deb-bbac-4753-ba37-f8805ef02b3c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA, DAGA O PUÑAL, CALLES Y CARRETERAS"
    },
    {
        "id": "751ed08b-522c-e049-9b7b-ae0f531f4d06",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA, DAGA O PUÑAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "d3b73f71-f033-8fe3-e1b4-909ad9c56cd5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA, DAGA O PUÑAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2e905a7d-c2e8-63b1-530e-f371d7c6d59b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA, DAGA O PUÑAL, GRANJA"
    },
    {
        "id": "08c9b009-65ab-b88b-05f4-284e8458f744",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON CUCHILLO, ESPADA, DAGA O PUÑAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "98b34eed-8703-74f2-6bd1-f8ec492cfb3a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN O TRANSMISIÓN, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "2cce8c33-7975-5306-200f-12ed0090fd6e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "bcd2295d-e0db-a6eb-c81f-8ca5ab8ac0a5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "25d43dc8-ba97-2368-86e4-e971772c6a14",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, CALLES Y CARRETERAS"
    },
    {
        "id": "2bd25d3a-2298-70d6-6f05-8b4a6ef15057",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "2bf09199-efa1-6c63-5c0e-1295f3522715",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a5c82cf0-d97e-b9e6-ff61-514dcc9995ff",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, GRANJA"
    },
    {
        "id": "7c87cb2b-08d9-cd6f-8dc9-2ee071841224",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "bb2a537a-7fea-db2d-eaab-46c800a87205",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9093793f-8cc9-2a41-169b-61d7b27282ba",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ef9567ca-9302-29d0-c437-b5d1dafadddb",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON DISPOSITIVOS DE ELEVACIÓN Y TRANSMISIÓN, NO CLASIFICADOS EN OTRA PARTE, VIVIENDA"
    },
    {
        "id": "1ae51b3a-4965-b0ed-9f19-d7eb35c6cdb9",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN"
    },
    {
        "id": "0d0e7e1b-3963-2693-568f-953d974a38dc",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "3303a819-cf00-ee2d-cb9c-0fe067253c39",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a6b149d4-3720-5332-73ef-c62f0d1541db",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "60384e3e-dfd2-7f0d-15f7-3a92e2424b37",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "4391a4e5-9086-cc02-f056-8b4b402dc5d4",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0115be33-2df6-3c4f-4211-ed5597a01893",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, GRANJA"
    },
    {
        "id": "a5ec2bc4-cc5d-4cd7-a71c-d2a42cb31ddd",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "8070d4aa-1a9b-75f2-aff0-7d1f01e515fd",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "be2bfc25-b10a-bc21-4960-6854ddd6d3e2",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "4acb1eb7-607a-2122-557b-0ccf97143231",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON ESCORPIÓN, VIVIENDA"
    },
    {
        "id": "4ed48d1a-cfbb-d893-95ff-8c2eff05837c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR"
    },
    {
        "id": "f246d39d-44ea-1cc1-eb3b-5bd23d475d74",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8ea588cf-1d62-d055-04fd-a2662395a215",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "3904fedd-a955-89ed-f8f8-c6ea4e5f30f0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, CALLES Y CARRETERAS"
    },
    {
        "id": "a86afceb-f6cc-a2a1-fe55-663553889b0d",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0c252491-310a-27ae-0e0e-97bf879506ba",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c82a62a1-f67b-31d4-1123-c1e574939df5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, GRANJA"
    },
    {
        "id": "a387051e-2168-4f16-48f6-64c5c40bf239",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "af8c2d5f-3255-7899-7f16-56d8037b32fd",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d0b028d9-78ad-945d-842f-b91b880475c9",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "af972936-b6a2-e673-328d-bec2a589631e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON HERRAMIENTAS MANUALES SIN MOTOR, VIVIENDA"
    },
    {
        "id": "9eccb955-6ad1-7f16-4c25-d29b32e9e5cf",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA"
    },
    {
        "id": "f7e94934-e6d5-fb15-ef32-7071f89c7a1a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b1312902-bdd6-b7f8-c5cd-45cb9e1c075c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0485a248-0a25-f1a3-9ab4-8317d833d9be",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, CALLES Y CARRETERAS"
    },
    {
        "id": "72389ee4-2d2a-45cc-83d7-8214f8016fb8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "bd02b610-9322-c859-831e-6273c9c516f2",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "8a8bddf5-9dad-facf-88d5-06bb47768c5f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, GRANJA"
    },
    {
        "id": "07788445-b87e-d7ed-46d8-34ddc53d513f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "32b0460b-9df6-8714-f0bb-bca14753a75f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8aa97cdf-2787-7763-1852-9329949cb27a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "77a35d10-e031-cbd2-7bfc-f04a3ffaea39",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MAQUINARIA AGRÍCOLA, VIVIENDA"
    },
    {
        "id": "8a8d5258-2f2f-abea-eb75-fe020166fafe",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "fd8589c7-d35b-7352-6f6d-b98ad893aa33",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "6c06d323-d07b-e321-7a3f-ae0b28bf18b0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "3002466d-05de-fe99-aa1a-70f0d4c2085f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "0a6ee67e-cf69-6bcf-9474-a1214ecd1168",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c864a90b-b393-70da-0678-34f59d8fcb86",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "868d0a09-4314-87cc-7b7e-6fecb84d4a35",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "66be0bc6-236c-7e4f-a73f-d0e1864e8b2f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "886ee024-1d49-6675-2851-73b1518de717",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "deb65918-9197-54fb-b0af-0fbe6bb21706",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ef1c09ef-c6ad-c1d2-3472-d757a61391c5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON MATERIAL EXPLOSIVO, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "933fb232-a0bd-cf81-3710-ab1932af27cb",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "5bacc6f7-2e0e-fcf1-8ca6-64305b8c98d5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "aeed25af-1520-b549-e6b2-ab19cd1ae22d",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "cf5d4e37-d527-f92e-0e15-22be9c87d089",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "647185c8-96fa-0696-fb7a-afb552b9d010",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e10571af-fe62-72df-5dd5-b7f288aa9ff8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "51010fec-88a2-9dac-1b74-4fd29892e432",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "cfeb3d0d-7b2d-a3ab-4ee8-ca10e5d2fb33",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "133dd1fa-4dbd-65b9-b712-e121390bce9b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "bdb11ad7-6ec0-b03d-b172-faa3d19bf208",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a9eb4fa7-7351-473b-3a90-30877051878b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO CORTANTE, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "210843dc-105b-4ba0-e5de-a3301c33507a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "e74ab7ee-7c0d-7d49-a98f-72af33431899",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "87717c45-8edf-04a7-eee3-b3d6a7af5bd3",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "874e5059-9971-22b5-176e-6df308625b88",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "9f334aa5-0be7-e300-af70-851790d222e1",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5db13aef-10cd-6984-d4a4-1977b3814cfc",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "fb1e9420-6f13-c7d8-79e4-af4af57b7a1e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "22682073-84bb-81d6-f221-50d30e0bd445",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "c30d0afa-fa86-a927-130d-3ca1cfcac805",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "17e7d1dd-a5c0-55b8-9ef9-ffd6208c1826",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "5e7bb4c2-e1e3-424d-68b7-7209d80adc1a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OBJETO ROMO O SIN FILO, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "1210a5e0-b245-bbec-b71f-72a110374539",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR"
    },
    {
        "id": "ff24a1c8-cfc9-3a0a-75c5-640c0b729f17",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "e8618506-a038-118f-dbd1-92334d30d0c8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "9d858ec6-4fd2-3fdb-4bcd-63fb2d18342b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, CALLES Y CARRETERAS"
    },
    {
        "id": "48e2a3a6-ba1a-ef8d-b244-f0107f372438",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1b8a1d67-188e-1401-052a-51ed811a1bba",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "3625af94-cc90-b4e4-6afc-1648ac98b67c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, GRANJA"
    },
    {
        "id": "7eac081d-2dc7-26b7-5bb9-64d30acb054b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cc753c28-8f5f-c6bc-54d8-725114235930",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5bbbf05c-0979-8093-11e5-f078b87d6437",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "96d447fc-a841-0dfe-6f2d-4d4212e50332",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR, VIVIENDA"
    },
    {
        "id": "856debdb-d6df-e176-5863-94b0a68f29d2",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "1da5b0d2-e21a-31ff-bbda-f48416cf2926",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "92e5088f-9bd2-e4e3-0922-680db2513095",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f62c669a-fa33-4077-4603-94740d370cd0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "3b1d758e-9106-4d90-00d7-48b29d2f168d",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "cd5ff069-f996-98a7-bcf7-eaff4186d7b0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "cc57dfcc-3068-1c62-827b-66af9500eabe",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "f721a784-7be5-db91-f785-6741cf33fc63",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "6fbba3cc-6adf-1318-2a28-13af0442f1ba",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "4fa59f6f-149a-27be-9a23-acebff551ade",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6657f490-9c38-1fdc-60c0-163cfe91911c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "ba762b5e-ed45-e07a-e21a-c242b18c2235",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS"
    },
    {
        "id": "ec66838e-9d6f-5f55-236c-ba28d5f14453",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b227e567-8bb5-7112-3e4e-d29689d5e191",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4fe2c158-4487-9f5e-9aa0-523fc220ac4a",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "569dc5e5-7925-69e2-5884-8788d4088950",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0f859a50-8973-54b7-a0a8-8dc7f8c134ec",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "4a574877-5eb3-debe-5455-798f9999d509",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, GRANJA"
    },
    {
        "id": "632f965e-9d94-112b-36d4-eb82b34c2984",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "9e721bc7-a134-48d0-98be-b0aa056dad21",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "819469ad-c108-5b0b-6033-af5b76aaac2c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "bdb4ccdd-2dbd-4068-7f97-f6bba6a8ebf0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "7016e335-eec7-7803-fc81-baa721d77626",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTRO(S) OBJETOS(S) AFILADO(S) EN OTRA PARTE"
    },
    {
        "id": "d1da52d7-dd80-94a5-a4b3-b033102d6b84",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS"
    },
    {
        "id": "ecfeb934-21a1-d35b-8708-17d346a2d420",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8dc303e0-36b0-ff80-da02-061b53a992b0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "6509a83d-cf82-b2c7-a291-36298e327e12",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "8ba13dbc-c493-9fa9-6e2e-72fca02be40f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b2e6b5e8-faa6-955c-f8e9-cdca49939817",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c4c37eea-862b-95ce-5839-d7b74e082dbb",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, GRANJA"
    },
    {
        "id": "8ae74100-c064-b46b-8fd2-e6d4b67c15b1",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "290ac383-3276-baeb-0a3f-8bdeb0b4b5c4",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a016d23c-5392-0f95-42a5-2d1c532c0544",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b3d71567-94e1-8d73-d317-cbc085fe5cd8",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "0b585310-c7bf-63ef-bd7b-4d6915e8e584",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS"
    },
    {
        "id": "c5218942-c525-b16b-5ba4-2cf4ad3879fe",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0f1bec6d-2092-89bc-8d36-78b9aafd172f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f525a8fc-8a44-2ecf-7ca8-232979f9439c",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, CALLES Y CARRETERAS"
    },
    {
        "id": "eb29b2fd-b816-090e-7856-af4c2f04507e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "40cf04f3-c42b-717b-d1be-b44d9833448e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ae24d042-490d-4a0d-2e8c-2feab8a4e97e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, GRANJA"
    },
    {
        "id": "0469604a-f6c9-0a2a-2c78-c7f4e04b12b4",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "5436de58-4e95-2b07-7a54-1a31ea711aa6",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5964b329-64e2-f5f2-7158-1a061618d5b3",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "bb568875-13e0-28fa-1bdd-5536b666f690",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS ARTRÓPODOS VENENOSOS, VIVIENDA"
    },
    {
        "id": "a0f410da-6a57-f375-631d-0bdc5601daa9",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS OBJETOS AFILADOS"
    },
    {
        "id": "aafbdf26-60d2-ffd3-124b-1272d9365f0f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON OTROS OBJETOS PUNTIAGUDOS NO ESPECIFICADOS"
    },
    {
        "id": "62ce3bf7-2eb1-8b3f-9414-5603619b5255",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS"
    },
    {
        "id": "b7c6024a-3d09-a0a2-079e-7255c9aa42d1",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "158ab108-6aaf-b743-b3f1-ec10ac4626d3",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "153926f0-59a5-a446-920b-3bdb01b8f52e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, CALLES Y CARRETERAS"
    },
    {
        "id": "41a17c00-1050-823d-78e4-aa784be0fa37",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "70399d5a-5d64-5fb5-710a-b4375e55fe15",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "5438d3b9-dec9-6daa-9ae8-a19cd8205317",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, GRANJA"
    },
    {
        "id": "a834c94a-ee84-bd87-11aa-e649dc3394b0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a2fbb400-e13f-79fa-06c8-57c6466cf51f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "e5a1f77a-e6d7-7085-0f02-069dbcf1916b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "89af936c-a052-9583-fec6-d93f17da7838",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON SERPIENTES Y LAGARTOS VENENOSOS, VIVIENDA"
    },
    {
        "id": "96f7ff96-ebf3-b1d3-5438-a9c21e3dde4f",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE"
    },
    {
        "id": "7678d77c-515a-bd3f-e515-ca9e6102865e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f0de99a9-8a6e-d594-1b3a-ccb2e71cd7db",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2e9efa7f-c31f-11c8-ef7f-0b17e6ffca58",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, CALLES Y CARRETERAS"
    },
    {
        "id": "44f1c406-e5eb-272e-0ab3-f461f4ac07a0",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6f52e65f-7cba-661f-6960-332af6cc688b",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "aea416a0-688f-c978-1ea1-add209938218",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, GRANJA"
    },
    {
        "id": "eeb08d18-e0fa-60dc-4c52-d4e638a38613",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "912525fc-b6b1-52d8-f0f4-c3e9049a3cc5",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "58364849-62b0-fff4-7a53-3dabfac4de1e",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "5a6f1df7-b18c-683f-78a9-ce73cb4a09ff",
        "NOMBRE": "CONTACTO TRAUMÁTICO CON VIDRIO CORTANTE, VIVIENDA"
    },
    {
        "id": "57b3282a-e4d2-b1df-8ebc-1402bb9afba3",
        "NOMBRE": "CONTRACCIONES PRIMARIAS INADECUADAS"
    },
    {
        "id": "d7a048c1-e820-3758-e70a-50c4b402c910",
        "NOMBRE": "CONTRACCIONES UTERINAS HIPERTÓNICAS, INCOORDINADAS Y PROLONGADAS"
    },
    {
        "id": "11edcb53-4f5f-ddd7-d083-0b89a468e525",
        "NOMBRE": "CONTRACTURA ARTICULAR"
    },
    {
        "id": "8fb5211c-12ce-5c20-4d2f-5d5604917a32",
        "NOMBRE": "CONTRACTURA MUSCULAR"
    },
    {
        "id": "acac01d1-b8f6-b66f-35e5-0654b02b4a40",
        "NOMBRE": "CONTROL DE SALUD DE RUTINA DEL NIÑO"
    },
    {
        "id": "d275903c-57e3-5405-9421-bffec2ae9c1c",
        "NOMBRE": "CONTROL GENERAL DE SALUD DE RUTINA A INTEGRANTES DE EQUIPOS DEPORTIVOS"
    },
    {
        "id": "44af1c03-6d3d-35ff-f51f-6153251107e1",
        "NOMBRE": "CONTROL GENERAL DE SALUD DE RUTINA A MIEMBROS DE LAS FUERZAS ARMADAS"
    },
    {
        "id": "dfd44c2e-8aa8-8376-3c3b-022e48c06bec",
        "NOMBRE": "CONTROL GENERAL DE SALUD DE RUTINA DE RESIDENTES DE INSTITUCIONES"
    },
    {
        "id": "841ef9ec-55d5-cdc7-8e4e-34042ecc7db9",
        "NOMBRE": "CONTROL GENERAL DE SALUD DE RUTINA DE SUBPOBLACIONES DEFINIDAS"
    },
    {
        "id": "2c7e0e7e-1bba-3302-848f-0ea381ecb98a",
        "NOMBRE": "CONTUSIÓN DE DEDO(S) DE LA MANO CON DAÑO DE LA(S) UÑA(S)"
    },
    {
        "id": "d7c2ffca-9c77-08b6-6759-318acc41d043",
        "NOMBRE": "CONTUSIÓN DE DEDO(S) DE LA MANO, SIN DAÑO DE LA(S) UÑA(S)"
    },
    {
        "id": "707e0f92-6fe7-7175-897e-6e55eba2a3da",
        "NOMBRE": "CONTUSIÓN DE DEDO(S) DEL PIE CON DAÑO DE LA(S) UÑA(S)"
    },
    {
        "id": "d0b63670-4fa0-e6e3-dd8f-8725dcb937b0",
        "NOMBRE": "CONTUSIÓN DE DEDO(S) DEL PIE SIN DAÑO DE LA(S) UÑA(S)"
    },
    {
        "id": "45f20781-8f22-561c-fc2f-02c523fd6c6c",
        "NOMBRE": "CONTUSIÓN DE LA CADERA"
    },
    {
        "id": "d6e69903-5dc3-0176-4661-d692033ad015",
        "NOMBRE": "CONTUSIÓN DE LA GARGANTA"
    },
    {
        "id": "cc914531-abb3-961a-9bd5-f7fca48b093c",
        "NOMBRE": "CONTUSIÓN DE LA MAMA"
    },
    {
        "id": "c7358868-c9a6-9060-6403-a6cb117ed0dd",
        "NOMBRE": "CONTUSIÓN DE LA PARED ABDOMINAL"
    },
    {
        "id": "8f3576ad-3720-a149-3331-d538d322a85d",
        "NOMBRE": "CONTUSIÓN DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "45823dbd-3e5c-6a4a-cd0e-270a1b0462c3",
        "NOMBRE": "CONTUSIÓN DE LA RODILLA"
    },
    {
        "id": "855ce3ef-95e2-1a0b-25a0-5ba89f126f45",
        "NOMBRE": "CONTUSIÓN DE LOS PÁRPADOS Y DE LA REGIÓN PERIOCULAR"
    },
    {
        "id": "3ad19e2d-bf89-f585-ad4c-44d7739f640e",
        "NOMBRE": "CONTUSIÓN DE ÓRGANOS GENITALES EXTERNOS"
    },
    {
        "id": "8b0f9a70-ee21-4f0b-3237-b4760b7869ab",
        "NOMBRE": "CONTUSIÓN DE OTRAS PARTES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "2050b62f-18cc-f5c5-796b-e33247da24c8",
        "NOMBRE": "CONTUSIÓN DE OTRAS PARTES DEL ANTEBRAZO Y DE LAS NO ESPECIFICADAS"
    },
    {
        "id": "6fbef52f-6067-c25e-1f04-156e2d911084",
        "NOMBRE": "CONTUSIÓN DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL PIE"
    },
    {
        "id": "4e6549f3-c8ca-509b-9b7e-35d7b68ffd8f",
        "NOMBRE": "CONTUSIÓN DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA PIERNA"
    },
    {
        "id": "1f788a4e-c9cb-fa2f-9304-66e65208cf70",
        "NOMBRE": "CONTUSIÓN DEL CODO"
    },
    {
        "id": "d569cccb-ad54-1258-7e23-93d2c266e846",
        "NOMBRE": "CONTUSIÓN DEL GLOBO OCULAR Y DEL TEJIDO ORBITARIO"
    },
    {
        "id": "c72edc29-8bf8-1a4c-275f-8b720aed7ac1",
        "NOMBRE": "CONTUSIÓN DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "2c11f415-c768-5ce1-0bc5-bc8d6b5f922b",
        "NOMBRE": "CONTUSIÓN DEL MUSLO"
    },
    {
        "id": "b0c6f0f8-5245-0987-f31d-a444575de42a",
        "NOMBRE": "CONTUSIÓN DEL TOBILLO"
    },
    {
        "id": "d90a5b67-5a5e-90ca-9ebc-657013b98f89",
        "NOMBRE": "CONTUSIÓN DEL TÓRAX"
    },
    {
        "id": "7786251f-c9d5-3ebf-e441-b64a315bd523",
        "NOMBRE": "CONVALECENCIA"
    },
    {
        "id": "3bd84735-4752-3030-bfa0-c1661fe716b6",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A CIRUGÍA"
    },
    {
        "id": "9d68f6d6-847d-9348-8cca-d3fefbd4f087",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A OTROS TRATAMIENTOS"
    },
    {
        "id": "de40313f-2cd0-0530-5fbe-6594fd93e4cb",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A PSICOTERAPIA"
    },
    {
        "id": "3e56e137-1519-c440-d427-cb176c357ea4",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A QUIMIOTERAPIA"
    },
    {
        "id": "3631d9ab-9c82-054d-0ba5-7bc6e417818d",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A RADIOTERAPIA"
    },
    {
        "id": "b3f14b4c-c6a8-6282-c927-e2946961f536",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A TRATAMIENTO COMBINADO"
    },
    {
        "id": "aa2bf32a-5a10-577b-9e5e-85b208da172b",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A TRATAMIENTO DE FRACTURA"
    },
    {
        "id": "6e28a1f5-aa79-073b-f3ac-5113eb3f0453",
        "NOMBRE": "CONVALECENCIA CONSECUTIVA A TRATAMIENTO NO ESPECIFICADO"
    },
    {
        "id": "58ac7ea4-77fe-9fb6-6fe1-d8e583cf6ee9",
        "NOMBRE": "CONVULSIONES DEL RECIÉN NACIDO"
    },
    {
        "id": "22b74941-0888-69f5-ca98-a04f947c5ad7",
        "NOMBRE": "CONVULSIONES DISOCIATIVAS"
    },
    {
        "id": "c0f70493-0f32-9eb3-70a7-691508a87660",
        "NOMBRE": "CONVULSIONES FEBRILES"
    },
    {
        "id": "dfaac35d-99b2-6986-8776-6d16da0b33bb",
        "NOMBRE": "CONVULSIONES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "95129b0b-d04a-524b-1eb4-14373b68e662",
        "NOMBRE": "CORAZÓN TRIAURICULAR"
    },
    {
        "id": "249d091a-9b89-527e-5661-7ab00c2ecc21",
        "NOMBRE": "COREA INDUCIDA POR DROGAS"
    },
    {
        "id": "527d8bac-b2cd-6276-4c9e-d36ad49b2419",
        "NOMBRE": "COREA REUMÁTICA"
    },
    {
        "id": "6abe45d8-c87a-9b23-00a9-faa423d83053",
        "NOMBRE": "COREA REUMÁTICA CON COMPLICACIÓN CARDÍACA"
    },
    {
        "id": "dde7a8bf-9402-63c4-be73-476c2a8b705c",
        "NOMBRE": "COREA REUMÁTICA SIN MENCIÓN DE COMPLICACIÓN CARDÍACA"
    },
    {
        "id": "017b1505-c77b-9082-c6b9-f79f5449b8cf",
        "NOMBRE": "CORIOMENINGITIS LINFOCÍTICA"
    },
    {
        "id": "1fa6edb1-4e7c-a3ba-b79b-2fb9bfa1cc41",
        "NOMBRE": "CORIORRETINITIS DISEMINADA"
    },
    {
        "id": "dd180ada-5f7c-ffab-1712-788213c12e98",
        "NOMBRE": "CORIORRETINITIS FOCAL"
    },
    {
        "id": "1e9e3de2-dc61-7f6b-2955-72fb727b7e60",
        "NOMBRE": "CORIORRETINITIS, NO ESPECIFICADA"
    },
    {
        "id": "96eb480f-7019-8ad7-c695-5ec3f5c1b66e",
        "NOMBRE": "CORONAVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "20b00d33-50b7-fd9b-33b1-d7e335cf5a42",
        "NOMBRE": "CORROSIÓN CON RUPTURA Y DESTRUCCIÓN RESULTANTES DEL GLOBO OCULAR"
    },
    {
        "id": "6ef884af-6610-2805-931f-5d997cfd0c01",
        "NOMBRE": "CORROSIÓN DE LA BOCA Y DE LA FARINGE"
    },
    {
        "id": "80c64117-4a0e-6861-8364-996c08fb5d83",
        "NOMBRE": "CORROSIÓN DE LA CABEZA Y DEL CUELLO, DE PRIMER GRADO"
    },
    {
        "id": "932610da-c9e8-7c23-b6d0-3ff23d8cd58c",
        "NOMBRE": "CORROSIÓN DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO"
    },
    {
        "id": "f357b923-962a-7acd-d65a-8fb2e6c0df5a",
        "NOMBRE": "CORROSIÓN DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO"
    },
    {
        "id": "ac6516ca-b64c-d56b-a20b-1a9aa4ff0755",
        "NOMBRE": "CORROSIÓN DE LA CABEZA Y DEL CUELLO, GRADO NO ESPECIFICADO"
    },
    {
        "id": "02940df1-4851-1eff-db99-4f5b43ca3576",
        "NOMBRE": "CORROSIÓN DE LA CADERA Y MIEMBRO INFERIOR, DE PRIMER GRADO, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "4aa33eb1-a71b-78cc-6622-e4fa90eaece6",
        "NOMBRE": "CORROSIÓN DE LA CADERA Y MIEMBRO INFERIOR, DE SEGUNDO GRADO, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "fe3de0cd-e32b-53f0-c00d-072b5e34f787",
        "NOMBRE": "CORROSIÓN DE LA CADERA Y MIEMBRO INFERIOR, DE TERCER GRADO, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "fbdc99d0-b8d5-a47c-c896-c9ed4c0d046d",
        "NOMBRE": "CORROSIÓN DE LA CADERA Y MIEMBRO INFERIOR, GRADO NO ESPECIFICADO, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "7f5dc988-145f-ad7d-bf2f-6b8b46fb1476",
        "NOMBRE": "CORROSIÓN DE LA CÓRNEA Y SACO CONJUNTIVAL"
    },
    {
        "id": "a0677e81-1aa3-b4e0-fb02-897b2ca28370",
        "NOMBRE": "CORROSIÓN DE LA LARINGE Y DE LA TRÁQUEA"
    },
    {
        "id": "39b9a346-52da-19b9-1f9c-da6c6ca47eee",
        "NOMBRE": "CORROSIÓN DE LA MUÑECA Y DE LA MANO, DE PRIMER GRADO"
    },
    {
        "id": "d0a15350-44ec-df90-7d9d-6fb0453530f5",
        "NOMBRE": "CORROSIÓN DE LA MUÑECA Y DE LA MANO, DE SEGUNDO GRADO"
    },
    {
        "id": "70ffbe13-55fe-4d37-61b6-0fccbedc58dc",
        "NOMBRE": "CORROSIÓN DE LA MUÑECA Y DE LA MANO, DE TERCER GRADO"
    },
    {
        "id": "2dbf7803-583e-3fcb-974e-435b8b23fbe3",
        "NOMBRE": "CORROSIÓN DE LA MUÑECA Y DE LA MANO, GRADO NO ESPECIFICADO"
    },
    {
        "id": "f8400756-da90-5091-b876-b68446330bb2",
        "NOMBRE": "CORROSIÓN DE LAS VÍAS RESPIRATORIAS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "fb3f4f15-68f9-9d87-1b42-14e98ff07eb5",
        "NOMBRE": "CORROSIÓN DE ÓRGANOS GENITOURINARIOS INTERNOS"
    },
    {
        "id": "f1cb43f2-3ec1-6b29-6704-0563b4435893",
        "NOMBRE": "CORROSIÓN DE OTRAS PARTES DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "4ca816fc-68fc-b452-596c-6d26874c6169",
        "NOMBRE": "CORROSIÓN DE OTRAS PARTES DEL OJO Y SUS ANEXOS"
    },
    {
        "id": "62a18e36-960d-bbf7-7dde-5716c5f3743f",
        "NOMBRE": "CORROSIÓN DE OTRAS PARTES DEL TUBO DIGESTIVO"
    },
    {
        "id": "5084510c-608c-7b90-dacc-599df81f55b8",
        "NOMBRE": "CORROSIÓN DE OTROS ÓRGANOS INTERNOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "a7fd133e-5dfb-c57e-00d1-42f7f2add66b",
        "NOMBRE": "CORROSIÓN DE PRIMER GRADO, REGIÓN DEL CUERPO NO ESPECIFICADA"
    },
    {
        "id": "6151b8cb-5f63-6b04-b800-6881f0ac63a9",
        "NOMBRE": "CORROSIÓN DE REGIÓN DEL CUERPO Y GRADO NO ESPECIFICADOS"
    },
    {
        "id": "c5a9d9a8-8a3a-3419-3e22-6e189ceab70c",
        "NOMBRE": "CORROSIÓN DE SEGUNDO GRADO, REGIÓN DEL CUERPO NO ESPECIFICADA"
    },
    {
        "id": "56190a5c-a769-207f-ca7a-b218c1422f6f",
        "NOMBRE": "CORROSIÓN DE TERCER GRADO, REGIÓN DEL CUERPO NO ESPECIFICADA"
    },
    {
        "id": "4dcfb201-536e-f93b-6f39-b1110dca5158",
        "NOMBRE": "CORROSIÓN DEL ESÓFAGO"
    },
    {
        "id": "819f2695-f8be-3dba-cbd9-d908630013d6",
        "NOMBRE": "CORROSIÓN DEL HOMBRO Y MIEMBRO SUPERIOR, DE PRIMER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "5fe1c117-9efd-9487-a983-57cc7ffea65f",
        "NOMBRE": "CORROSIÓN DEL HOMBRO Y MIEMBRO SUPERIOR, DE SEGUNDO GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "9627243d-5b47-ead6-5eee-ffbe120f64f0",
        "NOMBRE": "CORROSIÓN DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "0a339380-62e8-1267-09e1-25a854d98227",
        "NOMBRE": "CORROSIÓN DEL HOMBRO Y MIEMBRO SUPERIOR, GRADO NO ESPECIFICADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "d3fd42a0-9467-0873-da53-176a2c6f1137",
        "NOMBRE": "CORROSIÓN DEL OJO Y SUS ANEXOS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "78049153-4e45-9291-5b31-48793fab3954",
        "NOMBRE": "CORROSIÓN DEL PÁRPADO Y ÁREA PERIOCULAR"
    },
    {
        "id": "af9ba68e-fd56-8466-cd0b-a9d2ec1cd9ba",
        "NOMBRE": "CORROSIÓN DEL TOBILLO Y DEL PIE, DE PRIMER GRADO"
    },
    {
        "id": "2cef26c4-70e9-9248-742d-80be3d2e14eb",
        "NOMBRE": "CORROSIÓN DEL TOBILLO Y DEL PIE, DE SEGUNDO GRADO"
    },
    {
        "id": "dd110da6-2d1e-626d-b791-759c02bfaaf6",
        "NOMBRE": "CORROSIÓN DEL TOBILLO Y DEL PIE, DE TERCER GRADO"
    },
    {
        "id": "79626b1a-b6ef-81c9-2ba0-26daf4ee7735",
        "NOMBRE": "CORROSIÓN DEL TOBILLO Y DEL PIE, GRADO NO ESPECIFICADO"
    },
    {
        "id": "015c61eb-4969-849d-3df1-b3cb04ff5473",
        "NOMBRE": "CORROSIÓN DEL TRONCO, DE PRIMER GRADO"
    },
    {
        "id": "651ac183-2999-3256-1098-2913db6c13fd",
        "NOMBRE": "CORROSIÓN DEL TRONCO, DE SEGUNDO GRADO"
    },
    {
        "id": "2ad45439-62d9-7d65-bb99-b9fd440d6768",
        "NOMBRE": "CORROSIÓN DEL TRONCO, DE TERCER GRADO"
    },
    {
        "id": "4e9bbfc7-2745-79fa-438c-5a5135dd8628",
        "NOMBRE": "CORROSIÓN DEL TRONCO, GRADO NO ESPECIFICADO"
    },
    {
        "id": "2825f945-7f7a-7ad0-a8b3-a168d410d28a",
        "NOMBRE": "CORROSIÓN QUE AFECTA LA LARINGE Y LA TRÁQUEA CON EL PULMÓN"
    },
    {
        "id": "445ebbb1-101c-9288-daa7-2726f711cf09",
        "NOMBRE": "CORROSIONES CLASIFICADAS SEGÚN LA EXTENSIÓN DE LA SUPERFICIE DEL CUERPO AFECTADA"
    },
    {
        "id": "05434785-765d-1533-d787-2cc5af860a81",
        "NOMBRE": "CORROSIONES DE MÚLTIPLES REGIONES, GRADO NO ESPECIFICADO"
    },
    {
        "id": "ec507d9a-ee30-5492-fca9-414347391156",
        "NOMBRE": "CORROSIONES MÚLTIPLES, CON MENCIÓN AL MENOS DE UNA CORROSIÓN DE TERCER GRADO"
    },
    {
        "id": "3dc710f5-e6ca-f716-d00b-2313e7eee8ed",
        "NOMBRE": "CORROSIONES MÚLTIPLES, MENCIONADAS COMO DE NO MÁS DE PRIMER GRADO"
    },
    {
        "id": "e4aabe79-6b62-960e-52c6-7afbf014f25e",
        "NOMBRE": "CORROSIONES MÚLTIPLES, MENCIONADAS COMO DE NO MÁS DE SEGUNDO GRADO"
    },
    {
        "id": "3698ace9-643e-3fe7-3ac2-3676ab8a9629",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 10 AL 19% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "02474c48-bb99-7860-9c3f-ae037bb719da",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 20 AL 29% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "75b4af82-759a-6f0b-238f-ed588d23ee2b",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 30 AL 39% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "62fa208f-ffe2-a3b7-94d4-80507163953e",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 40 AL 49% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "a14f3225-6db5-95e9-296c-1eea50c5aa75",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 50 AL 59% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "86b18dd7-2448-1fb8-b18b-bdc6c6eb12b1",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 60 AL 69% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "679d406a-bc28-0324-2e11-ef93bb576936",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 70 AL 79% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "9bdc9e93-b6cf-50c1-1415-6f59bb8b3f79",
        "NOMBRE": "CORROSIONES QUE AFECTAN DEL 80 AL 89% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "1ae86e86-84bf-fefa-214d-3895705ee5cd",
        "NOMBRE": "CORROSIONES QUE AFECTAN EL 90% O MÁS DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "02164ac6-956f-e793-a796-ba7a683e196f",
        "NOMBRE": "CORROSIONES QUE AFECTAN MENOS DEL 10% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "46da1be1-adca-5b2c-d28e-33eb302665af",
        "NOMBRE": "CORTE, PUNCIÓN, PERFORACIÓN O HEMORRAGIA NO INTENCIONAL DURANTE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "b12b84e7-cd0b-b1c5-63e8-b6addc50ff07",
        "NOMBRE": "COSTILLA CERVICAL"
    },
    {
        "id": "6f098d67-b22b-ba10-2005-0353c9b670ee",
        "NOMBRE": "COVID-19, VIRUS IDENTIFICADO"
    },
    {
        "id": "1e122100-6c76-be4d-fd25-397f3b236717",
        "NOMBRE": "COVID-19, VIRUS NO IDENTIFICADO"
    },
    {
        "id": "68d102d9-4ef4-4a0b-fa61-0b4f2820412f",
        "NOMBRE": "COXA PLANA"
    },
    {
        "id": "df9519cf-6836-0d32-c8f7-2a67262510ec",
        "NOMBRE": "COXARTROSIS [ARTROSIS DE LA CADERA]"
    },
    {
        "id": "d53dfa94-84eb-c118-8a1c-5a7a44836060",
        "NOMBRE": "COXARTROSIS A CONSECUENCIA DE DISPLASIA, BILATERAL"
    },
    {
        "id": "f9dfaa9f-2a30-7598-0dfe-5934af69a556",
        "NOMBRE": "COXARTROSIS POSTRAUMÁTICA, BILATERAL"
    },
    {
        "id": "c406fd92-b073-9fef-fe61-c6eb26435075",
        "NOMBRE": "COXARTROSIS PRIMARIA, BILATERAL"
    },
    {
        "id": "a07f1d19-cd92-3b86-c7fa-13eea3f0a3dc",
        "NOMBRE": "COXARTROSIS, NO ESPECIFICADA"
    },
    {
        "id": "ac7e669f-a9f2-fc03-7fe7-b862eaae4cef",
        "NOMBRE": "CRANEORRAQUISQUISIS"
    },
    {
        "id": "49cf8750-8847-0d77-1fcd-5f3501efa08c",
        "NOMBRE": "CRANEOSINOSTOSIS"
    },
    {
        "id": "05463bb3-610e-a099-f129-f5a9e78513d9",
        "NOMBRE": "CRIOGLOBULINEMIA"
    },
    {
        "id": "9a9c6ebc-098e-b4b2-b813-88d5fcf48d3b",
        "NOMBRE": "CRIPTOCOCOSIS"
    },
    {
        "id": "3faf9245-2ca6-5613-0b4c-c63fc6dcc188",
        "NOMBRE": "CRIPTOCOCOSIS CEREBRAL"
    },
    {
        "id": "5a3ab2dc-f119-3245-189d-fb7809f30c40",
        "NOMBRE": "CRIPTOCOCOSIS CUTÁNEA"
    },
    {
        "id": "45c6d23b-d451-6b83-65f9-ca05159abc16",
        "NOMBRE": "CRIPTOCOCOSIS DISEMINADA"
    },
    {
        "id": "67d29441-a306-b4af-500b-a10d351ed319",
        "NOMBRE": "CRIPTOCOCOSIS ÓSEA"
    },
    {
        "id": "4a210cd2-a29b-9985-5c67-c5cff272ac13",
        "NOMBRE": "CRIPTOCOCOSIS PULMONAR"
    },
    {
        "id": "a2a82c27-3d47-68b6-336b-1808b699cd1f",
        "NOMBRE": "CRIPTOCOCOSIS, NO ESPECIFICADA"
    },
    {
        "id": "c1cfc769-133a-c1e5-e2e4-bae614b28584",
        "NOMBRE": "CRIPTOSPORIDIOSIS"
    },
    {
        "id": "e3e9f269-5af3-8414-bb83-8cbe3f69fe69",
        "NOMBRE": "CRISIS ADDISONIANA"
    },
    {
        "id": "0a247d41-3040-5fd1-784a-10d41a18e2e4",
        "NOMBRE": "CRISIS O TORMENTA TIROTÓXICA"
    },
    {
        "id": "9d07590b-044a-1441-f66f-8c93ca34d0bf",
        "NOMBRE": "CROMHIDROSIS"
    },
    {
        "id": "bf18ea03-b1a6-279a-7110-9f3fc05bf254",
        "NOMBRE": "CROMOMICOSIS CUTÁNEA"
    },
    {
        "id": "d89d9a65-1455-8137-ef02-a9ab6eb0c3a0",
        "NOMBRE": "CROMOMICOSIS Y ABSCESO FEOMICÓTICO"
    },
    {
        "id": "8ba2c3f3-b47a-f72c-07f4-3a87f67f0da5",
        "NOMBRE": "CROMOMICOSIS,NO ESPECIFICADA"
    },
    {
        "id": "92c1c33b-3d77-6186-a7e0-885e3d649359",
        "NOMBRE": "CROMOSOMA REEMPLAZADO POR ANILLO O DICÉNTRICO"
    },
    {
        "id": "6d8aa441-07a5-648f-12c1-7f68fd696ad5",
        "NOMBRE": "CROMOSOMA X FRÁGIL"
    },
    {
        "id": "9894f3a9-dcdc-90ca-9224-bef883e71c63",
        "NOMBRE": "CROMOSOMAS MARCADORES SUPLEMENTARIOS"
    },
    {
        "id": "70f17422-30db-8ce5-ad97-4bd0466e55aa",
        "NOMBRE": "CUADRIPLEJÍA ESPÁSTICA"
    },
    {
        "id": "9444dd25-741e-f817-f1eb-ad1ddc4a7dc1",
        "NOMBRE": "CUADRIPLEJÍA FLÁCIDA"
    },
    {
        "id": "de110445-c094-ff75-60ea-282a07405e74",
        "NOMBRE": "CUADRIPLEJÍA, NO ESPECIFICADA"
    },
    {
        "id": "2dd3b822-ee07-3a28-fea7-6fcc0bb1c0c7",
        "NOMBRE": "CUERPO EXTRAÑO DEJADO ACCIDENTALMENTE EN CAVIDAD CORPORAL O EN HERIDA OPERATORIA CONSECUTIVA A PROCEDIMIENTO"
    },
    {
        "id": "a8caf892-72c7-9a0e-f708-17ce1863cf5d",
        "NOMBRE": "CUERPO EXTRAÑO DEL OÍDO"
    },
    {
        "id": "ae3edf82-0578-ccf2-4f53-99c3c7289f9e",
        "NOMBRE": "CUERPO EXTRAÑO EN BRONQUIOS"
    },
    {
        "id": "24e9919a-e74f-b85d-8d72-3faa16e3771e",
        "NOMBRE": "CUERPO EXTRAÑO EN EL ANO Y EN EL RECTO"
    },
    {
        "id": "faaa417b-4939-fb85-13e9-3c5b787f1eab",
        "NOMBRE": "CUERPO EXTRAÑO EN EL COLON"
    },
    {
        "id": "48b7b340-f508-9583-dd47-de2a5bc63655",
        "NOMBRE": "CUERPO EXTRAÑO EN EL ESÓFAGO"
    },
    {
        "id": "26278d8f-c72d-b9fe-4e15-c37f01a9cf6d",
        "NOMBRE": "CUERPO EXTRAÑO EN EL ESTÓMAGO"
    },
    {
        "id": "57ef683b-91a1-b6d7-0232-7a1e97b32cd5",
        "NOMBRE": "CUERPO EXTRAÑO EN EL INTESTINO DELGADO"
    },
    {
        "id": "ef811f0a-5d3a-d662-df6c-b9fd21a19991",
        "NOMBRE": "CUERPO EXTRAÑO EN EL ORIFICIO NASAL"
    },
    {
        "id": "6c37d98f-b51b-63d6-5a8d-d56e66408767",
        "NOMBRE": "CUERPO EXTRAÑO EN EL SACO CONJUNTIVAL"
    },
    {
        "id": "ca252b26-849c-047d-c795-06b348dacd90",
        "NOMBRE": "CUERPO EXTRAÑO EN EL TUBO DIGESTIVO"
    },
    {
        "id": "122caa0c-23c1-d113-63fe-351b48eafda3",
        "NOMBRE": "CUERPO EXTRAÑO EN EL TUBO DIGESTIVO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "cad06e5c-9a52-f935-d977-822d585666dd",
        "NOMBRE": "CUERPO EXTRAÑO EN EL ÚTERO [CUALQUIER PARTE]"
    },
    {
        "id": "83e91b0f-29b1-4671-a628-c50626b9ce72",
        "NOMBRE": "CUERPO EXTRAÑO EN LA BOCA"
    },
    {
        "id": "6b69cf5b-4a79-c9ff-5bbd-3a4cd56f90b5",
        "NOMBRE": "CUERPO EXTRAÑO EN LA CÓRNEA"
    },
    {
        "id": "d27be415-5a15-8ed7-0850-c31a812e969a",
        "NOMBRE": "CUERPO EXTRAÑO EN LA FARINGE"
    },
    {
        "id": "f3af4c32-69d7-8c55-1061-8c7bc2ca7a1a",
        "NOMBRE": "CUERPO EXTRAÑO EN LA LARINGE"
    },
    {
        "id": "5d1edd35-92f9-e0f0-0469-63ca34f4e012",
        "NOMBRE": "CUERPO EXTRAÑO EN LA TRÁQUEA"
    },
    {
        "id": "be446fbd-638b-ea0f-8e61-633cf203d9eb",
        "NOMBRE": "CUERPO EXTRAÑO EN LA URETRA"
    },
    {
        "id": "a5fc54fe-b403-005c-36d7-3ce9151193dd",
        "NOMBRE": "CUERPO EXTRAÑO EN LA VEJIGA"
    },
    {
        "id": "59414fcb-7a81-1af6-ac89-a4be6cf8706a",
        "NOMBRE": "CUERPO EXTRAÑO EN LA VULVA Y EN LA VAGINA"
    },
    {
        "id": "070d7f56-2922-d5f6-baad-1ea64acf21f6",
        "NOMBRE": "CUERPO EXTRAÑO EN LAS VÍAS GENITOURINARIAS"
    },
    {
        "id": "6f2f4b42-f0fc-fce0-dcb8-a24c6904230a",
        "NOMBRE": "CUERPO EXTRAÑO EN LAS VÍAS GENITOURINARIAS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "f7508077-1b52-4abc-f71e-e742c9e17263",
        "NOMBRE": "CUERPO EXTRAÑO EN LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "63a23e56-2586-6eed-6e0f-02d03c58272d",
        "NOMBRE": "CUERPO EXTRAÑO EN LAS VÍAS RESPIRATORIAS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "32dfea68-bc84-f80d-9178-9769b16e7725",
        "NOMBRE": "CUERPO EXTRAÑO EN OTRAS Y EN MÚLTIPLES PARTES DE LA PARTE EXTERNA DEL OJO"
    },
    {
        "id": "7717623f-5b0e-c4fe-6456-a224de87d9f0",
        "NOMBRE": "CUERPO EXTRAÑO EN OTRAS Y EN MÚLTIPLES PARTES DE LAS VÍAS GENITOURINARIAS"
    },
    {
        "id": "7bb2e6b6-48e5-4e3e-da86-4b1a0a983713",
        "NOMBRE": "CUERPO EXTRAÑO EN OTRAS Y EN MÚLTIPLES PARTES DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "0b51931f-1db8-34a2-8962-e2db842fe60b",
        "NOMBRE": "CUERPO EXTRAÑO EN OTRAS Y EN MÚLTIPLES PARTES DEL TUBO DIGESTIVO"
    },
    {
        "id": "cfebea53-0d5a-8152-fc9e-6e7f9e9edbcb",
        "NOMBRE": "CUERPO EXTRAÑO EN PARTE EXTERNA DEL OJO"
    },
    {
        "id": "6bf9378d-8a3b-279b-c9a2-1b9cb721580f",
        "NOMBRE": "CUERPO EXTRAÑO EN PARTE EXTERNA DEL OJO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "05cb9903-2e9e-20ab-1451-fb2d328ec96b",
        "NOMBRE": "CUERPO EXTRAÑO EN SENO PARANASAL"
    },
    {
        "id": "25e771c6-74d1-bba6-13cb-73593d2df369",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL"
    },
    {
        "id": "5efd233c-c8e1-4992-cd09-3c9d88b9f5c4",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d19dd5b7-aa0c-c244-76ff-fe3e51f43673",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "feb2211b-4a18-b1b4-d078-04f47fce5814",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, CALLES Y CARRETERAS"
    },
    {
        "id": "09d8950a-0ee3-b552-17ff-882db11cbfec",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c3b262f7-db7f-7b33-f0fd-d9eca9191acc",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c96b1e49-9559-ed11-134e-48902c78b5fe",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, GRANJA"
    },
    {
        "id": "22c72ae4-1479-8606-5b1c-d4a6f2395d5f",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "733bb622-0beb-a0dc-e276-343e149d6a3f",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "28aae676-5ea3-5d21-3bd1-a6fe19334a41",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "fcb15eb5-1a41-adcc-be95-9fe65d2ea12e",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA A TRAVÉS DE LA PIEL, VIVIENDA"
    },
    {
        "id": "aca2cbb7-f6db-f8c0-ca4e-72345d491b28",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL"
    },
    {
        "id": "d5f8a198-0a6f-7c80-662d-339bd8d75cc2",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "fbb20a9e-5ec4-4199-2d07-859669c46a30",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e67f0406-0b90-ba54-5769-2f465dc955da",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, CALLES Y CARRETERAS"
    },
    {
        "id": "421eeb3f-bc03-d63c-7b74-93df152ed4b4",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0f5c2fc9-cdcc-c855-9cdc-f67f5f6d1a3c",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a0be96bd-a721-8b5f-32f3-8d5b689f0103",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, GRANJA"
    },
    {
        "id": "be983a4d-e080-148e-aa71-bb542519a526",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "65e96923-ea29-efd6-98cd-c32f07236e12",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f7f58976-a343-e634-fbad-6e42b47aa6d9",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "f8aa4fc9-b0c0-d220-8fcc-25e52d6bebe8",
        "NOMBRE": "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL, VIVIENDA"
    },
    {
        "id": "2ae4701b-28a1-a12b-c7e2-fe7d601452df",
        "NOMBRE": "CUERPO EXTRAÑO RESIDUAL EN TEJIDO BLANDO"
    },
    {
        "id": "a8cd9119-641a-6510-c966-4b62943bc695",
        "NOMBRE": "CUERPO FLOTANTE ARTICULAR"
    },
    {
        "id": "5869fff8-075b-6ba3-dccb-7b8cb7106c05",
        "NOMBRE": "CUERPO FLOTANTE EN LA RODILLA"
    },
    {
        "id": "0f2923cc-3b96-046d-d284-fa423305a536",
        "NOMBRE": "CUIDADO POSTERIOR A LA CIRUGÍA, NO ESPECIFICADO"
    },
    {
        "id": "52f56d8a-b1c4-833c-9509-db11cda061e7",
        "NOMBRE": "CUIDADO POSTERIOR A LA ORTOPEDIA, NO ESPECIFICADO"
    },
    {
        "id": "9bc0376c-3bff-df02-06e3-59c80c56752b",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA"
    },
    {
        "id": "d957ecae-2660-fe85-0b8b-e2a599326cdd",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA DE LA CABEZA Y DEL CUELLO"
    },
    {
        "id": "082ded16-66ff-8e72-3479-24c923f059b7",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA DE LA MAMA"
    },
    {
        "id": "fd938212-948e-1946-d0e0-c74cd2da6241",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA DE LAS EXTREMIDADES INFERIORES"
    },
    {
        "id": "03174633-33f7-5ae0-791a-7e470156b570",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA DE LAS EXTREMIDADES SUPERIORES"
    },
    {
        "id": "c5489455-8265-5cc9-c275-9a3207029423",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA DE OTRAS PARTES ESPECIFICADAS DEL CUERPO"
    },
    {
        "id": "80f1ee5c-ed8e-df48-a169-3085ee149c1e",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA DE OTRAS PARTES ESPECIFICADAS DEL TRONCO"
    },
    {
        "id": "afccb2f0-82c5-71fa-f9ff-d6ddcb016921",
        "NOMBRE": "CUIDADOS POSTERIORES A LA CIRUGÍA PLÁSTICA NO ESPECIFICADA"
    },
    {
        "id": "d4ab2a3a-848b-0a69-d2cf-d42719b14e9b",
        "NOMBRE": "CUIDADOS POSTERIORES A LA EXTRACCIÓN DE PLACA U OTRO DISPOSITIVO DE FIJACIÓN INTERNA EN FRACTURA"
    },
    {
        "id": "d93d7a11-525c-00ff-920b-99bf62193cfc",
        "NOMBRE": "CUIDADOS PREPARATORIOS PARA DIÁLISIS"
    },
    {
        "id": "9005aa9b-945a-d190-15bc-2b0b1ced2613",
        "NOMBRE": "CUIDADOS RELATIVOS AL PROCEDIMIENTO DE DIÁLISIS"
    },
    {
        "id": "51d3db90-3ca3-fad4-24fc-a50a10e4dfb6",
        "NOMBRE": "CURVATURA CONGÉNITA DE HUESO(S) LARGO(S) DEL MIEMBRO INFERIOR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "b4567440-5c19-0f18-3a72-c4c3e346fbe8",
        "NOMBRE": "CURVATURA CONGÉNITA DE LA TIBIA Y DEL PERONÉ"
    },
    {
        "id": "0f211a19-2600-6a48-02f3-ef44458bf6c0",
        "NOMBRE": "CURVATURA CONGÉNITA DEL FÉMUR"
    },
    {
        "id": "d83594d9-9a38-d0db-0df0-ed6268da0cd0",
        "NOMBRE": "DACRIOADENITIS"
    },
    {
        "id": "aff32609-89aa-e591-0bc6-559c867a7d24",
        "NOMBRE": "DEDO EN GATILLO"
    },
    {
        "id": "2bf4dfcb-554c-7616-10cd-77627e7cb957",
        "NOMBRE": "DEDO(S) SUPERNUMERARIO(S) DE LA MANO"
    },
    {
        "id": "12de1a06-cb2c-6aa3-de79-d5f6b1951ebb",
        "NOMBRE": "DEDO(S) SUPERNUMERARIO(S) DEL PIE"
    },
    {
        "id": "6dcac913-ff30-0a04-2bd4-d2d7f7dd42e5",
        "NOMBRE": "DEDOS DE LA MANO DEFORMES"
    },
    {
        "id": "e8519915-db04-f4c5-d800-721a772c2d57",
        "NOMBRE": "DEFECTO CUALITATIVOS DE LAS PLAQUETAS"
    },
    {
        "id": "33f000b9-2199-f0a5-68f0-3740de1e525d",
        "NOMBRE": "DEFECTO DE LA COAGULACIÓN POSTPARTO"
    },
    {
        "id": "fbfc795a-1e3f-e911-eacc-edc4385c70f8",
        "NOMBRE": "DEFECTO DE LA COAGULACIÓN, NO ESPECIFICADO"
    },
    {
        "id": "2febd524-34c6-1374-b8a2-827c0eb83c7c",
        "NOMBRE": "DEFECTO DE LA FUNCIÓN DEL ANTÍGENO-1 DEL LINFOCITO [LFA-1]"
    },
    {
        "id": "545b12d2-efed-0e5b-8308-1f8595eb2674",
        "NOMBRE": "DEFECTO DEL SISTEMA DEL COMPLEMENTO"
    },
    {
        "id": "2c46edb3-1916-07af-5159-1911274a3dd3",
        "NOMBRE": "DEFECTO DEL TABIQUE AORTOPULMONAR"
    },
    {
        "id": "b75c7cc9-f610-ac30-eadd-eb756206005f",
        "NOMBRE": "DEFECTO DEL TABIQUE AURICULAR"
    },
    {
        "id": "0c59e7fa-a02d-d1f8-ad86-90ad0e06d315",
        "NOMBRE": "DEFECTO DEL TABIQUE AURICULAR COMO COMPLICACIÓN PRESENTE POSTERIOR AL INFARTO DEL MIOCARDIO"
    },
    {
        "id": "fbf84f24-2517-0070-26f2-7d5005ce0ada",
        "NOMBRE": "DEFECTO DEL TABIQUE AURICULOVENTRICULAR"
    },
    {
        "id": "6c9ad93b-1bbb-ad15-2270-269079f1cd09",
        "NOMBRE": "DEFECTO DEL TABIQUE CARDÍACO, ADQUIRIDO"
    },
    {
        "id": "ffcd2faa-a9ed-b2d6-a9c6-ca2aed0bf02b",
        "NOMBRE": "DEFECTO DEL TABIQUE VENTRICULAR"
    },
    {
        "id": "492be928-4000-3e6a-254c-baeb1f30891c",
        "NOMBRE": "DEFECTO DEL TABIQUE VENTRICULAR COMO COMPLICACIÓN PRESENTE POSTERIOR AL INFARTO DEL MIOCARDIO"
    },
    {
        "id": "13493386-cab2-7587-1b96-203c84497bb8",
        "NOMBRE": "DEFECTO POR REDUCCIÓN DEL MIEMBRO INFERIOR, NO ESPECIFICADO"
    },
    {
        "id": "037ab7a4-b63b-c57f-3233-3a9067c125f6",
        "NOMBRE": "DEFECTO POR REDUCCIÓN DEL MIEMBRO SUPERIOR, NO ESPECIFICADO"
    },
    {
        "id": "0e4b12f5-9754-caca-0e2c-3f86ec591464",
        "NOMBRE": "DEFECTO POR REDUCCIÓN LONGITUDINAL DE LA TIBIA"
    },
    {
        "id": "79e58c6a-247d-409c-90b9-1404d6983bb0",
        "NOMBRE": "DEFECTO POR REDUCCIÓN LONGITUDINAL DEL CÚBITO"
    },
    {
        "id": "0561d941-9951-74f8-6c0f-9a37d0663d9b",
        "NOMBRE": "DEFECTO POR REDUCCIÓN LONGITUDINAL DEL FÉMUR"
    },
    {
        "id": "344e517e-c12c-8909-a815-68e40a04401a",
        "NOMBRE": "DEFECTO POR REDUCCIÓN LONGITUDINAL DEL PERONÉ"
    },
    {
        "id": "40825116-36f8-c683-3afb-3a592857336c",
        "NOMBRE": "DEFECTO POR REDUCCIÓN LONGITUDINAL DEL RADIO"
    },
    {
        "id": "54f79f23-7f75-309b-af6e-47e3094a099d",
        "NOMBRE": "DEFECTOS DE CATALASA Y PEROXIDASA"
    },
    {
        "id": "c848b277-e3a9-42c0-126d-bb1c3e203900",
        "NOMBRE": "DEFECTOS DE LA DEGRADACIÓN DE GLUCOPROTEÍNAS"
    },
    {
        "id": "23b087e3-c65c-4292-c67b-eac936b1d84e",
        "NOMBRE": "DEFECTOS DEL CAMPO VISUAL"
    },
    {
        "id": "8e9b1244-504a-33b2-66ff-dc005dacf42e",
        "NOMBRE": "DEFECTOS EN LA MODIFICACIÓN POSTRASLACIONAL DE ENZIMAS LISOSOMALES"
    },
    {
        "id": "332c0433-e909-8c11-7ad7-863a3146c242",
        "NOMBRE": "DEFECTOS OBSTRUCTIVOS CONGÉNITOS DE LA PELVIS RENAL Y MALFORMACIONES CONGÉNITAS DEL URÉTER"
    },
    {
        "id": "b182d351-30ef-395d-3c10-7d04332719a2",
        "NOMBRE": "DEFECTOS POR REDUCCIÓN DE MIEMBRO NO ESPECIFICADO"
    },
    {
        "id": "676e4933-eee3-cd09-728a-19781cfa3ec0",
        "NOMBRE": "DEFECTOS POR REDUCCIÓN DEL MIEMBRO INFERIOR"
    },
    {
        "id": "5ae17a29-0197-16b1-18b8-ca14f1a05a42",
        "NOMBRE": "DEFECTOS POR REDUCCIÓN DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "01fb2b57-44ef-c9cf-a864-e6e417a6f28f",
        "NOMBRE": "DEFICIENCIA ADQUIRIDA DE FACTORES DE LA COAGULACIÓN"
    },
    {
        "id": "b6ff5a5b-9bee-a3fa-ce08-e416eada4ab0",
        "NOMBRE": "DEFICIENCIA CONGÉNITA DE LACTASA"
    },
    {
        "id": "43480ef4-c3e5-4f40-6970-9940215ed4dc",
        "NOMBRE": "DEFICIENCIA DE ÁCIDO ASCÓRBICO"
    },
    {
        "id": "1d4b7ff2-c6c9-dad5-1233-814c0ef1a497",
        "NOMBRE": "DEFICIENCIA DE ÁCIDOS GRASOS ESENCIALES [AGE]"
    },
    {
        "id": "3fb9df89-8343-f15d-cfbd-02a7ac7aa97a",
        "NOMBRE": "DEFICIENCIA DE ANTICUERPOS CON INMUNOGLOBULINAS CASI NORMALES O CON HIPERINMUNOGLOBULINEMIA"
    },
    {
        "id": "35ec6f6a-e3af-3039-acad-449ef35d0bd3",
        "NOMBRE": "DEFICIENCIA DE COBRE"
    },
    {
        "id": "6b2771da-ecfd-15db-893b-e588c611cbd7",
        "NOMBRE": "DEFICIENCIA DE CROMO"
    },
    {
        "id": "928457a9-2ae6-4b8c-4c4b-9aba2c9b2d8a",
        "NOMBRE": "DEFICIENCIA DE HIERRO"
    },
    {
        "id": "fe4fa678-d897-15f0-e606-ccd32382f579",
        "NOMBRE": "DEFICIENCIA DE LA ADENOSINA DEAMINASA [ADA]"
    },
    {
        "id": "8e1b02b6-48fc-e4a9-e2c5-1355589e20d4",
        "NOMBRE": "DEFICIENCIA DE LA CLASE I DEL COMPLEJO DE HISTOCOMPATIBILIDAD MAYOR"
    },
    {
        "id": "a41ae2f6-070b-8d01-ee29-cae06db97389",
        "NOMBRE": "DEFICIENCIA DE LA CLASE II DEL COMPLEJO DE HISTOCOMPATIBILIDAD MAYOR"
    },
    {
        "id": "64c2c928-65d7-1c42-ac27-6f8f7cb80f63",
        "NOMBRE": "DEFICIENCIA DE LA FOSFORILASA PURINONUCLEÓSIDA [FPN]"
    },
    {
        "id": "40db5f5b-c6ce-a565-ef85-d1f8f5ab1eb5",
        "NOMBRE": "DEFICIENCIA DE LA VISIÓN CROMÁTICA"
    },
    {
        "id": "92277aa5-7d5b-545a-4d41-4a088d652da9",
        "NOMBRE": "DEFICIENCIA DE LIPOPROTEÍNAS"
    },
    {
        "id": "36030f1e-4c50-1f77-8234-67d446290a57",
        "NOMBRE": "DEFICIENCIA DE MAGNESIO"
    },
    {
        "id": "7302d44c-a71d-79d7-6387-7a0d71b2debe",
        "NOMBRE": "DEFICIENCIA DE MANGANESO"
    },
    {
        "id": "667f4131-0970-950a-e156-3d77b552bdb7",
        "NOMBRE": "DEFICIENCIA DE MOLIBDENO"
    },
    {
        "id": "0dd3cbc7-296f-f888-1019-89196e0db30f",
        "NOMBRE": "DEFICIENCIA DE MÚLTIPLES ELEMENTOS NUTRICIONALES"
    },
    {
        "id": "c1b1618f-44fb-d0b8-2ded-fa027b5bd9e4",
        "NOMBRE": "DEFICIENCIA DE NIACINA [PELAGRA]"
    },
    {
        "id": "fd33385e-8884-04d0-5ace-d8a153cf6e21",
        "NOMBRE": "DEFICIENCIA DE OTRAS VITAMINAS"
    },
    {
        "id": "fa58045b-396f-9f39-95b6-62f09b79a307",
        "NOMBRE": "DEFICIENCIA DE OTRAS VITAMINAS DEL GRUPO B"
    },
    {
        "id": "fad258d4-a932-aff0-279a-5ff3232001d5",
        "NOMBRE": "DEFICIENCIA DE OTRO ELEMENTO NUTRICIONAL, NO ESPECIFICADO"
    },
    {
        "id": "b52d17f0-e07d-d635-1be7-0220649e8f82",
        "NOMBRE": "DEFICIENCIA DE OTROS ELEMENTOS NUTRICIONALES ESPECIFICADOS"
    },
    {
        "id": "2b5daba1-5500-b289-0f01-2b7b6e2047c2",
        "NOMBRE": "DEFICIENCIA DE PIRIDOXINA"
    },
    {
        "id": "f210fb93-45e7-3ecb-e44e-f9433c48bcf6",
        "NOMBRE": "DEFICIENCIA DE RIBOFLAVINA"
    },
    {
        "id": "842bf68c-86fc-d8f7-6ce2-6eaec6eb0524",
        "NOMBRE": "DEFICIENCIA DE TIAMINA"
    },
    {
        "id": "3a847e62-3256-6801-7b5f-87213aa3126a",
        "NOMBRE": "DEFICIENCIA DE TIAMINA, NO ESPECIFICADA"
    },
    {
        "id": "70fedb76-2bec-a8a5-54af-f6b21aeec36e",
        "NOMBRE": "DEFICIENCIA DE TRASCOBALAMINA II"
    },
    {
        "id": "165856d2-8300-f0ec-a0de-8b642037dafe",
        "NOMBRE": "DEFICIENCIA DE VANADIO"
    },
    {
        "id": "5d68fa5d-29c9-8792-b0f5-e05af38c8ebf",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A"
    },
    {
        "id": "4dbe3bd5-0cd5-6011-a7c4-3a600b574e03",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A CON CEGUERA NOCTURNA"
    },
    {
        "id": "9097ebb6-9f46-dab7-9c69-30857dd6ed27",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A CON CICATRICES XEROFTÁLMICAS DE LA CÓRNEA"
    },
    {
        "id": "2a53e4d7-8939-e0e7-7076-ab5c92e9c607",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A CON MANCHA DE BITOT Y XEROSIS CONJUNTIVAL"
    },
    {
        "id": "057d1ad2-7ffc-7194-db71-968e334fd549",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A CON QUERATOMALACIA"
    },
    {
        "id": "c6f781ec-f8a5-ac0f-9674-acc469b22dbf",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A CON ULCERACIÓN CORNEAL Y XEROSIS"
    },
    {
        "id": "d0e1253f-7eff-2754-d545-c6a7d12ef9ae",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A CON XEROSIS CONJUNTIVAL"
    },
    {
        "id": "edca9d92-5661-918f-23e3-756cb9619248",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A CON XEROSIS CORNEAL"
    },
    {
        "id": "ad07ebd7-7cc9-2994-5066-9110c1bdfc52",
        "NOMBRE": "DEFICIENCIA DE VITAMINA A, NO ESPECIFICADA"
    },
    {
        "id": "fe198dc2-2760-580c-0811-80f4dca81cd1",
        "NOMBRE": "DEFICIENCIA DE VITAMINA B, NO ESPECIFICADA"
    },
    {
        "id": "22e54e38-73f3-5a9c-29b4-a5d20af3f658",
        "NOMBRE": "DEFICIENCIA DE VITAMINA D"
    },
    {
        "id": "715f4795-2f91-d6ee-ff2d-b53fd299f3e5",
        "NOMBRE": "DEFICIENCIA DE VITAMINA D, NO ESPECIFICADA"
    },
    {
        "id": "610152f2-bf3d-67b8-1728-faa702e4a6a7",
        "NOMBRE": "DEFICIENCIA DE VITAMINA E"
    },
    {
        "id": "5583e7df-c439-1ccc-8f9e-f5801db1b26b",
        "NOMBRE": "DEFICIENCIA DE VITAMINA K"
    },
    {
        "id": "3d0f63af-9715-aeb9-498d-c0387b52b0da",
        "NOMBRE": "DEFICIENCIA DE VITAMINA, NO ESPECIFICADA"
    },
    {
        "id": "e104c84e-b2a3-48bb-0020-260f826ffea0",
        "NOMBRE": "DEFICIENCIA DIETÉTICA DE CALCIO"
    },
    {
        "id": "eadf6054-60fa-88c3-85aa-acc84c26723b",
        "NOMBRE": "DEFICIENCIA DIETÉTICA DE SELENIO"
    },
    {
        "id": "de429871-251d-0b6e-bc1c-5b2eb84b8b14",
        "NOMBRE": "DEFICIENCIA DIETÉTICA DE ZINC"
    },
    {
        "id": "dbdf9fc6-e59f-6a3c-2e05-c78723943217",
        "NOMBRE": "DEFICIENCIA HEREDITARIA DE OTROS FACTORES DE LA COAGULACIÓN"
    },
    {
        "id": "bc07b191-d47e-064f-bbed-5b19a097aae3",
        "NOMBRE": "DEFICIENCIA HEREDITARIA DEL FACTOR IX"
    },
    {
        "id": "35060e42-5228-18c5-74ea-b4a2cb0b743c",
        "NOMBRE": "DEFICIENCIA HEREDITARIA DEL FACTOR VIII"
    },
    {
        "id": "c7c790f7-3d88-c04a-1a37-6ba601646dbf",
        "NOMBRE": "DEFICIENCIA HEREDITARIA DEL FACTOR XI"
    },
    {
        "id": "d8f10e1a-f09f-ba30-f0dd-8322407593e7",
        "NOMBRE": "DEFICIENCIA NUTRICIONAL, NO ESPECIFICADA"
    },
    {
        "id": "afa3a149-7e8f-fe22-b767-d7e06fd314af",
        "NOMBRE": "DEFICIENCIA SECUNDARIA DE LACTASA"
    },
    {
        "id": "c59cf800-3cce-b1c9-1982-4a3889c853be",
        "NOMBRE": "DEFICIENCIA SELECTIVA DE INMUNOGLOBULINA A [IGA]"
    },
    {
        "id": "0ccca0bc-0086-946c-d513-ba410bfdd87d",
        "NOMBRE": "DEFICIENCIA SELECTIVA DE INMUNOGLOBULINA M [IGM]"
    },
    {
        "id": "e1b49044-230a-de6f-07cb-7cc2143c1099",
        "NOMBRE": "DEFICIENCIA SELECTIVA DE SUBCLASES DE LA INMUNOGLOBULINA G [IGG]"
    },
    {
        "id": "3bcc77f8-33e9-312e-e90e-017893db419b",
        "NOMBRE": "DEFICIENCIA VISUAL LEVE O AUSENTE, BINOCULAR"
    },
    {
        "id": "2492a272-62d3-0584-cf56-837f59cb0b53",
        "NOMBRE": "DEFICIENCIA VISUAL MODERADA, BINOCULAR"
    },
    {
        "id": "a26cd338-d4f6-9b04-9323-27f5cac279bf",
        "NOMBRE": "DEFICIENCIA VISUAL MODERADA, MONOCULAR"
    },
    {
        "id": "ac4f0d6a-ff93-fd8c-cf65-e4d57e9b9c5c",
        "NOMBRE": "DEFICIENCIA VISUAL NO ESPECIFICADA (BINOCULAR)"
    },
    {
        "id": "b5965ee4-29c6-2bf5-3fa2-e08c5fbbd6a4",
        "NOMBRE": "DEFICIENCIA VISUAL SEVERA, BINOCULAR"
    },
    {
        "id": "8bad1821-a664-3820-d3f5-678a91d3c69f",
        "NOMBRE": "DEFICIENCIA VISUAL SEVERA, MONOCULAR"
    },
    {
        "id": "9c2b9325-451e-1488-5eac-9befba04518c",
        "NOMBRE": "DEFICIENCIAS DE OTRAS VITAMINAS DEL GRUPO B"
    },
    {
        "id": "fb552abc-050a-9123-479f-8e7ae1504077",
        "NOMBRE": "DEFICIENCIAS DE OTROS ELEMENTOS NUTRICIONALES"
    },
    {
        "id": "e5950a15-4fc9-a031-1855-cbe4545095c2",
        "NOMBRE": "DEFORMIDAD ADQUIRIDA DE COSTILLAS Y TÓRAX"
    },
    {
        "id": "30d98f00-8482-abed-5762-1605293afe36",
        "NOMBRE": "DEFORMIDAD ADQUIRIDA DE LA NARIZ"
    },
    {
        "id": "c57bbfa8-78a4-2bef-a4af-cacbf1cb6590",
        "NOMBRE": "DEFORMIDAD ADQUIRIDA DE LA PELVIS"
    },
    {
        "id": "cf6b85e9-62d2-1a53-dda0-e839a9bba1f6",
        "NOMBRE": "DEFORMIDAD ADQUIRIDA DEL CUELLO"
    },
    {
        "id": "57abf23c-1f36-3bae-ee51-13c102b8685c",
        "NOMBRE": "DEFORMIDAD ADQUIRIDA DEL MIEMBRO, NO ESPECIFICADA"
    },
    {
        "id": "71eb7cc5-0fbe-7783-3e44-b9555bf2eb4f",
        "NOMBRE": "DEFORMIDAD ADQUIRIDA DEL SISTEMA OSTEOMUSCULAR, NO ESPECIFICADA"
    },
    {
        "id": "91bdc45d-3948-40d6-3ba7-50944b4a267f",
        "NOMBRE": "DEFORMIDAD CONGÉNITA DE LA CADERA, NO ESPECIFICADA"
    },
    {
        "id": "2d0075cf-aad5-fbbb-41d7-439f934951f3",
        "NOMBRE": "DEFORMIDAD CONGÉNITA DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "e563854d-ad35-ab1c-30b4-de42b0bb4f11",
        "NOMBRE": "DEFORMIDAD CONGÉNITA DE LA MANO"
    },
    {
        "id": "0ddf2ff1-3b4f-4ecd-0d47-79a3f61398d3",
        "NOMBRE": "DEFORMIDAD CONGÉNITA DE LA RODILLA"
    },
    {
        "id": "dcaa5957-1dc7-ea96-1870-8df9fd0e636f",
        "NOMBRE": "DEFORMIDAD CONGÉNITA DE LOS PIES, NO ESPECIFICADA"
    },
    {
        "id": "dedf7ffe-f6b7-b811-8364-274f1283c572",
        "NOMBRE": "DEFORMIDAD CONGÉNITA DEL MÚSCULO ESTERNOCLEIDOMASTOIDEO"
    },
    {
        "id": "d8e68b45-087b-91ed-363b-49045d8d0e6a",
        "NOMBRE": "DEFORMIDAD DE DEDO(S) DE LA MANO"
    },
    {
        "id": "af5c07f5-6500-5780-fa5b-4a5053cf868e",
        "NOMBRE": "DEFORMIDAD DE LA ÓRBITA"
    },
    {
        "id": "8eb73a32-c666-a343-f026-4e69a9c24edd",
        "NOMBRE": "DEFORMIDAD EN FLEXIÓN"
    },
    {
        "id": "e2b150bf-9bd6-6f58-1e30-020fc442e612",
        "NOMBRE": "DEFORMIDAD EN VALGO, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "4e7eb95e-18ce-5061-5255-ff9ed48326d8",
        "NOMBRE": "DEFORMIDAD EN VARO, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "875fa66d-b827-e5ef-ada3-d29d2d934cf2",
        "NOMBRE": "DEFORMIDADES ADQUIRIDAS DE LOS DEDOS DE LA MANO Y DEL PIE"
    },
    {
        "id": "e6225328-7d13-35d6-acbf-16fd197ff5cf",
        "NOMBRE": "DEFORMIDADES ADQUIRIDAS DE LOS DEDOS DEL PIE, NO ESPECIFICADAS"
    },
    {
        "id": "ae546926-82d1-1c98-2c43-e339b290297c",
        "NOMBRE": "DEFORMIDADES CONGÉNITAS DE LA CADERA"
    },
    {
        "id": "43d63e38-d99e-25bd-dd38-6ce4ffbf74f7",
        "NOMBRE": "DEFORMIDADES CONGÉNITAS DE LOS PIES"
    },
    {
        "id": "b664d31b-e292-4d43-6316-7327abc2b45c",
        "NOMBRE": "DEFORMIDADES OSTEOMUSCULARES CONGÉNITAS DE LA CABEZA, DE LA CARA, DE LA COLUMNA VERTEBRAL Y DEL TÓRAX"
    },
    {
        "id": "9ed87e42-e107-7d6e-f14f-c306fc8cece2",
        "NOMBRE": "DEGENERACIÓN CEREBRAL SENIL NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "720bc7b5-79dc-5c98-eb15-3ef78b72d87b",
        "NOMBRE": "DEGENERACIÓN COMBINADA SUBAGUDA DE LA MÉDULA ESPINAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "cd4e8b82-733d-8aed-5cde-9de9ea1c7bce",
        "NOMBRE": "DEGENERACIÓN COROIDEA"
    },
    {
        "id": "479f5370-03b6-3344-6142-0b9c0e8607dc",
        "NOMBRE": "DEGENERACIÓN DE LA CÓRNEA"
    },
    {
        "id": "3c743f71-3308-1125-96ea-6d4e14148db6",
        "NOMBRE": "DEGENERACIÓN DE LA MÁCULA Y DEL POLO POSTERIOR DEL OJO"
    },
    {
        "id": "6d46043a-1207-da0f-1150-e9a5640b38d0",
        "NOMBRE": "DEGENERACIÓN DE LA PULPA"
    },
    {
        "id": "310f1b10-ed99-1632-894b-e82e1ea1901d",
        "NOMBRE": "DEGENERACIÓN DE SISTEMAS MÚLTIPLES"
    },
    {
        "id": "20d884f2-0edf-b00c-4365-22ab4b7fb31c",
        "NOMBRE": "DEGENERACIÓN DEL IRIS Y DEL CUERPO CILIAR"
    },
    {
        "id": "a5960098-ae18-7e60-132b-f6266b0ecf6a",
        "NOMBRE": "DEGENERACIÓN DEL SISTEMA NERVIOSO DEBIDA AL ALCOHOL"
    },
    {
        "id": "d59fa9a8-0f7a-4ddd-14ba-f8ebc5c548d2",
        "NOMBRE": "DEGENERACIÓN DEL SISTEMA NERVIOSO, NO ESPECIFICADA"
    },
    {
        "id": "8b5f5099-be8d-458a-3b66-69034c839013",
        "NOMBRE": "DEGENERACIÓN GRASA DEL HÍGADO, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "fe6c001b-5058-a992-aed1-bbd861a24cf0",
        "NOMBRE": "DEGENERACIÓN MIOCÁRDICA"
    },
    {
        "id": "9b7d8f08-707c-983d-cc4c-853abaa5aa8a",
        "NOMBRE": "DEGENERACIÓN PERIFÉRICA DE LA RETINA"
    },
    {
        "id": "f50286af-f693-584c-83cf-ed9b0dee2951",
        "NOMBRE": "DEGENERACIÓN POLIPOIDE DE SENO PARANASAL"
    },
    {
        "id": "87c4567f-9b71-afba-c9f9-5f1d4940866c",
        "NOMBRE": "DEGENERACIONES Y DEPÓSITOS CONJUNTIVALES"
    },
    {
        "id": "f55a3263-3a20-18d0-7386-e2997350a756",
        "NOMBRE": "DEHISCENCIA DE SUTURA DE CESÁREA"
    },
    {
        "id": "3c60f2d7-dfe4-db5c-5d37-a97402c42ec0",
        "NOMBRE": "DEHISCENCIA DE SUTURA OBSTÉTRICA PERINEAL"
    },
    {
        "id": "d4367dc0-9c7d-9520-f749-0a26d6be2e31",
        "NOMBRE": "DELIRIO NO SUPERPUESTO A UN CUADRO DE DEMENCIA, ASÍ DESCRITO"
    },
    {
        "id": "bd483d1b-10ff-7594-d9df-e8e8b7143acc",
        "NOMBRE": "DELIRIO SUPERPUESTO A UN CUADRO DE DEMENCIA"
    },
    {
        "id": "49a2f80e-30c4-6f50-0e2b-d0e43c33630e",
        "NOMBRE": "DELIRIO, NO ESPECIFICADO"
    },
    {
        "id": "1fd8a0f7-d5cd-a97d-bc87-1128c2946f2a",
        "NOMBRE": "DELIRIO, NO INDUCIDO POR ALCOHOL O POR OTRAS SUSTANCIAS PSICOACTIVAS"
    },
    {
        "id": "ebb1304e-5fdd-684d-c85b-8ad13e06ce84",
        "NOMBRE": "DELTA-BETA TALASEMIA"
    },
    {
        "id": "805c6c41-08b5-4266-b046-17872923fc10",
        "NOMBRE": "DEMENCIA , NO ESPECIFICADA"
    },
    {
        "id": "16be7574-2457-fc6f-9304-4436ccedb5f8",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER"
    },
    {
        "id": "5f31085e-65f2-480d-b299-4553050cb3e6",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, ATÍPICA O DE TIPO MIXTO"
    },
    {
        "id": "a4361aad-e049-cecf-aef6-94749de74b61",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, DE COMIENZO TARDÍO"
    },
    {
        "id": "5a26f98b-59ed-5ba7-70a4-155b1d7040a4",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, DE COMIENZO TEMPRANO"
    },
    {
        "id": "3edef72a-8186-83a5-8623-35154e2d5cfa",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA"
    },
    {
        "id": "c5ab16de-b629-c4df-a11d-613e9777e03a",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE CREUTZFELDT-JAKOB"
    },
    {
        "id": "b9d6839e-ec4d-9367-2908-28562c9f8421",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE HUNTINGTON"
    },
    {
        "id": "a141af4f-c088-d18d-e8f1-cbf7fd36bbfa",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE PARKINSON"
    },
    {
        "id": "1c2fbc63-832d-f058-cb87-76defcca9da4",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD DE PICK"
    },
    {
        "id": "6b3b2cb9-3eaf-b22a-7850-89fe03ec049b",
        "NOMBRE": "DEMENCIA EN LA ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"
    },
    {
        "id": "e9ab8fcf-2499-c5db-3224-dc1d0cc48290",
        "NOMBRE": "DEMENCIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "bf489cba-1039-0696-f7a7-ae5b0aa9c84c",
        "NOMBRE": "DEMENCIA EN OTRAS ENFERMEDADES ESPECIFICADAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "579cd12d-98a7-5e31-e75b-7933f503ded7",
        "NOMBRE": "DEMENCIA VASCULAR"
    },
    {
        "id": "eeb43fd6-9ed0-a0c7-d1c6-abe80bd1a0c2",
        "NOMBRE": "DEMENCIA VASCULAR DE COMIENZO AGUDO"
    },
    {
        "id": "29f5ef42-6587-c61f-bf59-9c0dfd05a34b",
        "NOMBRE": "DEMENCIA VASCULAR MIXTA, CORTICAL Y SUBCORTICAL"
    },
    {
        "id": "9abf658b-995d-386e-4d04-7cfccf9073e2",
        "NOMBRE": "DEMENCIA VASCULAR POR INFARTOS MÚLTIPLES"
    },
    {
        "id": "90a0e6a0-32d0-5b7d-c40a-709d792f3ca9",
        "NOMBRE": "DEMENCIA VASCULAR SUBCORTICAL"
    },
    {
        "id": "0c32fdd6-9c75-fed3-bf84-647bd9ccffd8",
        "NOMBRE": "DEMENCIA VASCULAR, NO ESPECIFICADA"
    },
    {
        "id": "f1f50104-4c30-6761-0383-c7e6969b165a",
        "NOMBRE": "DENGUE"
    },
    {
        "id": "a070e9dc-1220-75cc-48ab-2c14df09ee1f",
        "NOMBRE": "DENGUE CON SIGNOS DE ALARMA"
    },
    {
        "id": "9a2b6ea6-7639-9261-f115-9d34353fa51a",
        "NOMBRE": "DENGUE SEVERO"
    },
    {
        "id": "3b5531f8-4fd2-81ee-adcf-c88ddddbe891",
        "NOMBRE": "DENGUE SIN SIGNOS DE ALARMA"
    },
    {
        "id": "00200a28-ddec-2976-2dc6-d24b77aa57b5",
        "NOMBRE": "DENGUE, NO ESPECIFICADO"
    },
    {
        "id": "237b12fc-8cd5-f927-26d1-fb7031856591",
        "NOMBRE": "DEPENDENCIA DE ASPIRADOR"
    },
    {
        "id": "81d87473-abd1-cdde-08c9-b9af9cd99310",
        "NOMBRE": "DEPENDENCIA DE CORAZÓN ARTIFICIAL"
    },
    {
        "id": "ab3a98bb-55db-611f-8bc0-1179eef0b721",
        "NOMBRE": "DEPENDENCIA DE DIÁLISIS RENAL"
    },
    {
        "id": "39f494ad-b653-cd0f-db03-7ab69484232a",
        "NOMBRE": "DEPENDENCIA DE MÁQUINA Y DISPOSITIVO CAPACITANTE, NO ESPECIFICADA"
    },
    {
        "id": "0150d5b8-cbc5-a52c-1158-d2ad2cc574b7",
        "NOMBRE": "DEPENDENCIA DE MÁQUINAS Y DISPOSITIVOS CAPACITANTES, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "793e007f-60f3-5f66-7a25-bd491d75c29b",
        "NOMBRE": "DEPENDENCIA DE OTRAS MÁQUINAS Y DISPOSITIVOS CAPACITANTES"
    },
    {
        "id": "794ce448-0953-baa0-a63a-7e25e3702eea",
        "NOMBRE": "DEPENDENCIA DE RESPIRADOR"
    },
    {
        "id": "443eed6e-9a61-d809-6b3b-34880ec01807",
        "NOMBRE": "DEPENDENCIA DE SILLA DE RUEDAS"
    },
    {
        "id": "f772396c-260a-ddee-f906-c7a52872fdd8",
        "NOMBRE": "DEPLECIÓN DEL VOLUMEN"
    },
    {
        "id": "a96e1103-40cb-05a4-7f2d-268869d4db42",
        "NOMBRE": "DEPÓSITO DE CALCIO EN LA BOLSA SEROSA"
    },
    {
        "id": "2bae8724-9d04-d120-7e6e-015c0c602425",
        "NOMBRE": "DEPÓSITOS [ACRECIONES] EN LOS DIENTES"
    },
    {
        "id": "fb95590d-9fb6-4dd0-c9aa-54ca64ab1ee0",
        "NOMBRE": "DEPÓSITOS CRISTALINOS EN EL CUERPO VÍTREO"
    },
    {
        "id": "68ae34a3-a1f9-7776-b0cd-8ab33fe567c3",
        "NOMBRE": "DEPRESIÓN CEREBRAL NEONATAL"
    },
    {
        "id": "ac6d868c-78de-5f20-22c4-88f9134be47a",
        "NOMBRE": "DEPRESIÓN POSTESQUIZOFRÉNICA"
    },
    {
        "id": "85b84275-57c1-65b4-5d58-6bcaf2fa96d8",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO"
    },
    {
        "id": "c43cab2c-f2a7-2aee-34ed-65e45344ade0",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A ADHESIVOS"
    },
    {
        "id": "f3b7220c-ff88-d24f-47c0-ba260f7f371c",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A ALIMENTOS EN CONTACTO CON LA PIEL"
    },
    {
        "id": "c507ea29-d414-85ae-f853-6a5278252f0c",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A COLORANTES"
    },
    {
        "id": "47c702b9-708a-fc56-06e4-9853a488c046",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A COSMÉTICOS"
    },
    {
        "id": "af09cf0f-06a2-769a-e844-c4609aa4bc21",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A DROGAS EN CONTACTO CON LA PIEL"
    },
    {
        "id": "66439797-6544-b635-5f21-268062547fa6",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A METALES"
    },
    {
        "id": "af34b4a1-19e7-92db-50f9-167760779737",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A OTROS AGENTES"
    },
    {
        "id": "785c51f0-e314-dabd-f536-917ccb2d7176",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A OTROS PRODUCTOS QUÍMICOS"
    },
    {
        "id": "d4033ff3-1b6a-fc34-04b4-4147c8c68573",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO DEBIDA A PLANTAS, EXCEPTO LAS ALIMENTICIAS"
    },
    {
        "id": "b33c41c0-efd0-5498-e1ae-68fe06a66a5b",
        "NOMBRE": "DERMATITIS ALÉRGICA DE CONTACTO, DE CAUSA NO ESPECIFICADA"
    },
    {
        "id": "71de6e3e-4215-dee2-8234-fe8ab0c89988",
        "NOMBRE": "DERMATITIS ATÓPICA"
    },
    {
        "id": "ac9a87ab-52a1-1655-cf84-683ad0ea5d12",
        "NOMBRE": "DERMATITIS ATÓPICA, NO ESPECIFICADA"
    },
    {
        "id": "c817ce02-f828-ee18-0b37-734c3815d7c1",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES"
    },
    {
        "id": "f7f4ba9c-f328-4fc7-330d-09e9aaa42fbf",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DE CAUSA NO ESPECIFICADA"
    },
    {
        "id": "0d5f5acc-c646-caad-3ee4-0d48537efe8b",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A ACEITES Y GRASAS"
    },
    {
        "id": "e397a329-ef62-477a-bb5d-bd492515d595",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A ALIMENTOS EN CONTACTO CON LA PIEL"
    },
    {
        "id": "9b9c1f85-1efb-44cb-9c92-db2445b8ecbf",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A COSMÉTICOS"
    },
    {
        "id": "d82af46c-c9fb-4911-8bfa-9d647861c0b8",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DETERGENTES"
    },
    {
        "id": "8c528ec5-8807-34e4-f0d7-b18a47377fea",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DISOLVENTES"
    },
    {
        "id": "c6167e9e-4554-be56-4d5e-254c73c3553c",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DROGAS EN CONTACTO CON LA PIEL"
    },
    {
        "id": "6b980722-ee4a-e5bf-b6ea-f67b0d759ecd",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A OTROS AGENTES"
    },
    {
        "id": "47c95663-f496-edc2-ece5-fe71899af763",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A OTROS PRODUCTOS QUÍMICOS"
    },
    {
        "id": "911f98ef-f225-39dc-26d4-39992ad59b72",
        "NOMBRE": "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A PLANTAS, EXCEPTO LAS ALIMENTICIAS"
    },
    {
        "id": "087bb04a-efcd-9ae8-2c1b-c02a7f0ab9a6",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA"
    },
    {
        "id": "40b6e805-235d-f414-ebca-472c0562e0db",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A ALIMENTOS EN CONTACTO CON LA PIEL"
    },
    {
        "id": "e0d5862a-8d60-3bc3-e167-60d9f909f143",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A COLORANTES"
    },
    {
        "id": "6b9214b0-8130-c812-710c-7230883093ad",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A COSMÉTICOS"
    },
    {
        "id": "c10a0a69-0425-d670-1071-552df4f0df39",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A DROGAS EN CONTACTO CON LA PIEL"
    },
    {
        "id": "4c6a107e-ac2e-4f2f-3668-98a9921d561b",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A OTROS AGENTES"
    },
    {
        "id": "f1f0a25c-2671-6620-f5a6-e788e9e0e8ea",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A OTROS PRODUCTOS QUÍMICOS"
    },
    {
        "id": "c41b2370-4694-14f1-ac15-08c1bb5b3cdf",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A PLANTAS, EXCEPTO LAS ALIMENTICIAS"
    },
    {
        "id": "de88be81-7e65-7efc-9ddf-71bea03c149b",
        "NOMBRE": "DERMATITIS DE CONTACTO, FORMA Y CAUSA NO ESPECIFICADAS"
    },
    {
        "id": "1373fc58-2166-65b2-101f-44b6413ccdbf",
        "NOMBRE": "DERMATITIS DEBIDA A INGESTIÓN DE ALIMENTOS"
    },
    {
        "id": "aab9c459-ba22-6391-bddf-ce33e9f72a9b",
        "NOMBRE": "DERMATITIS DEBIDA A OTRAS SUSTANCIAS INGERIDAS"
    },
    {
        "id": "e00973ca-bc3c-0905-ed02-fab328ce8148",
        "NOMBRE": "DERMATITIS DEBIDA A SUSTANCIAS INGERIDAS"
    },
    {
        "id": "b325c823-b15e-556c-0c4d-1967bf7597bc",
        "NOMBRE": "DERMATITIS DEBIDA A SUSTANCIAS INGERIDAS NO ESPECIFICADAS"
    },
    {
        "id": "98e32228-aa23-7e75-62c8-56a3a11eeacf",
        "NOMBRE": "DERMATITIS DEL PAÑAL"
    },
    {
        "id": "f9a37402-7114-2752-9837-a58064659488",
        "NOMBRE": "DERMATITIS EXFOLIATIVA"
    },
    {
        "id": "b0f577e4-4a92-0c7e-3236-53700c39a179",
        "NOMBRE": "DERMATITIS FACTICIA"
    },
    {
        "id": "e55d0c30-e5c5-e40f-bd12-2900dfbf0279",
        "NOMBRE": "DERMATITIS HERPETIFORME"
    },
    {
        "id": "cba9bf87-84d3-1d95-0944-2e934d34bfbf",
        "NOMBRE": "DERMATITIS INFECCIOSA"
    },
    {
        "id": "55f80c90-2a45-5dfb-1dba-f5f878c6a45a",
        "NOMBRE": "DERMATITIS NUMULAR"
    },
    {
        "id": "d10f7123-0204-5c58-5c40-3d65aa23ec93",
        "NOMBRE": "DERMATITIS PERIBUCAL"
    },
    {
        "id": "b775dd73-e98b-78a6-2307-c6a0978ec435",
        "NOMBRE": "DERMATITIS POR CERCARIAS"
    },
    {
        "id": "c8c2b1f5-924b-ba62-c04e-a63fd01cc0b9",
        "NOMBRE": "DERMATITIS POR FOTOCONTACTO [DERMATITIS DE BERLOQUE]"
    },
    {
        "id": "ff51bfe2-8317-8062-8320-86954e229db1",
        "NOMBRE": "DERMATITIS PUSTULOSA SUBCORNEAL"
    },
    {
        "id": "6b1a05b8-6514-90e3-0b31-ce55a2ce149c",
        "NOMBRE": "DERMATITIS SEBORREICA"
    },
    {
        "id": "93c909e4-b6f3-916c-2fa7-fae6dd293ba8",
        "NOMBRE": "DERMATITIS SEBORREICA INFANTIL"
    },
    {
        "id": "3247ef9a-66ed-cef2-71dc-796292014d02",
        "NOMBRE": "DERMATITIS SEBORREICA, NO ESPECIFICADA"
    },
    {
        "id": "e5fbbe27-f552-47ef-2a35-9796f5df8a2f",
        "NOMBRE": "DERMATITIS VESICULAR HERPÉTICA"
    },
    {
        "id": "a71be68b-87ef-8e5c-2695-0acc6e3c29db",
        "NOMBRE": "DERMATITIS, NO ESPECIFICADA"
    },
    {
        "id": "7496d8b5-075b-9fc7-7e82-4ec149e48676",
        "NOMBRE": "DERMATO(POLI)MIOSITIS EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "9e54c204-1b3f-8f6c-e94e-9affad03cc96",
        "NOMBRE": "DERMATOARTRITIS LIPOIDE"
    },
    {
        "id": "390391fc-5f5b-dc4b-c15b-e117032ba0bc",
        "NOMBRE": "DERMATOFITOSIS"
    },
    {
        "id": "8f3a469b-5ec4-966f-9da4-07e73a5c6846",
        "NOMBRE": "DERMATOFITOSIS, NO ESPECIFICADA"
    },
    {
        "id": "81ec803f-b8fe-3ef3-954c-42079963f149",
        "NOMBRE": "DERMATOMIOSITIS JUVENIL"
    },
    {
        "id": "17f9a1d3-5e3a-412a-cb27-6e24b62b23df",
        "NOMBRE": "DERMATOPOLIMIOSITIS"
    },
    {
        "id": "b5e00912-f712-7f66-bb96-476bb2972575",
        "NOMBRE": "DERMATOPOLIMIOSITIS, NO ESPECIFICADA"
    },
    {
        "id": "8abded14-5151-f8ec-e169-3b78d4e7bddb",
        "NOMBRE": "DERMATOSIS ACANTOLÍTICA TRANSITORIA [GROVER]"
    },
    {
        "id": "339c5b47-5f05-8e39-6cfa-eddbe9e69b86",
        "NOMBRE": "DERMATOSIS NEUTRÓFILA FEBRIL [SWEET]"
    },
    {
        "id": "da13b750-74ca-cb98-7dbb-d6ca83d24711",
        "NOMBRE": "DERMATOSIS NO INFECCIOSA DEL PÁRPADO"
    },
    {
        "id": "b124c506-ab83-76f7-ba38-8a2f18c53f88",
        "NOMBRE": "DERMATOSIS PURPÚRICA PIGMENTADA"
    },
    {
        "id": "7ec966e2-0bfd-b5ae-1a32-0b39d1c5465b",
        "NOMBRE": "DERRAME ARTICULAR"
    },
    {
        "id": "2e1f4d4e-2600-0f79-badc-dfa5830a0a5d",
        "NOMBRE": "DERRAME PERICÁRDICO (NO INFLAMATORIO)"
    },
    {
        "id": "3960d3d5-9f11-f2b4-b06f-71fd1b600b13",
        "NOMBRE": "DERRAME PLEURAL EN AFECCIONES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4f236c58-c0fa-1628-c99d-9be4693ddac4",
        "NOMBRE": "DERRAME PLEURAL NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "ff6bfd54-253f-a24d-7e0d-32c103b8c2d8",
        "NOMBRE": "DESARREGLO ARTICULAR, NO ESPECIFICADO"
    },
    {
        "id": "10a04425-0198-d5dd-d020-f7ed2307aedf",
        "NOMBRE": "DESCARGA URETRAL"
    },
    {
        "id": "9da7f83d-47b1-f023-8f1b-d9ebcccc400f",
        "NOMBRE": "DESENSIBILIZACIÓN A ALÉRGENOS"
    },
    {
        "id": "e5107099-6db6-af4e-bdf3-fd958e307ced",
        "NOMBRE": "DESEQUILIBRIO DE LOS CONSTITUYENTES EN LA DIETA"
    },
    {
        "id": "27ed1aae-0e3e-f0e1-3f6e-d6a3ddb7805f",
        "NOMBRE": "DESGARRO DE HERIDA OPERATORIA, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "fd184205-a258-29f2-aa15-5e8e7c45ef4a",
        "NOMBRE": "DESGARRO DE LA RETINA SIN DESPRENDIMIENTO"
    },
    {
        "id": "8d246b8e-a160-6b27-1683-1e5d4e217969",
        "NOMBRE": "DESGARRO DE MENISCOS, PRESENTE"
    },
    {
        "id": "7f9a73a8-7e80-5e53-ad78-446655abd656",
        "NOMBRE": "DESGARRO DEL CARTÍLAGO ARTICULAR DE LA RODILLA, PRESENTE"
    },
    {
        "id": "b5d90aa4-300e-753b-0ee5-6b3c25408f55",
        "NOMBRE": "DESGARRO DEL LABRUM DE LA ARTICULACIÓN DEGENERATIVA DEL HOMBRO"
    },
    {
        "id": "4ff1526a-eba5-ea96-cf87-465dabf764ae",
        "NOMBRE": "DESGARRO OBSTÉTRICO DEL CUELLO UTERINO"
    },
    {
        "id": "3fec55cb-890d-095d-3b3c-abba320c5274",
        "NOMBRE": "DESGARRO PERINEAL DE CUARTO GRADO DURANTE EL PARTO"
    },
    {
        "id": "f58bf8ad-b097-d18f-da3d-f04a8d1b8d8e",
        "NOMBRE": "DESGARRO PERINEAL DE PRIMER GRADO DURANTE EL PARTO"
    },
    {
        "id": "a8749e04-ba01-6706-176c-2164e6464688",
        "NOMBRE": "DESGARRO PERINEAL DE SEGUNDO GRADO DURANTE EL PARTO"
    },
    {
        "id": "047f0115-aae6-e1f7-b05d-c8be498e3171",
        "NOMBRE": "DESGARRO PERINEAL DE TERCER GRADO DURANTE EL PARTO"
    },
    {
        "id": "d7f96797-f828-54a4-5e40-2c9a3ffb8524",
        "NOMBRE": "DESGARRO PERINEAL DURANTE EL PARTO"
    },
    {
        "id": "978fc550-13bf-1ca5-f3b4-ac7d41c11d27",
        "NOMBRE": "DESGARRO PERINEAL DURANTE EL PARTO, DE GRADO NO EPECIFICADO"
    },
    {
        "id": "defd8751-1def-8237-05d5-7f3ed2239b37",
        "NOMBRE": "DESGARRO TENTORIAL DEBIDO A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "27a6e152-b171-d2b0-fb41-8a5a9a650c9d",
        "NOMBRE": "DESGARRO VAGINAL OBSTÉTRICO ALTO"
    },
    {
        "id": "8771e947-e0ac-61a6-5dcc-133142cb1d57",
        "NOMBRE": "DESHIDRATACIÓN DEL RECIÉN NACIDO"
    },
    {
        "id": "0c43642d-669a-eee5-758a-cd00e0aaf7cb",
        "NOMBRE": "DESLIZAMIENTO DE LA EPÍFISIS FEMORAL SUPERIOR (NO TRAUMÁTICO)"
    },
    {
        "id": "4fe117ea-1d40-1883-8561-94c0c298a7f6",
        "NOMBRE": "DESMIELINIZACIÓN CENTRAL DEL CUERPO CALLOSO"
    },
    {
        "id": "37c3fa59-492e-0ff1-3540-3f528251fe65",
        "NOMBRE": "DESMIELINIZACIÓN DISEMINADA AGUDA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "03ab5ba9-75fb-246e-5e86-4541a74e2ac4",
        "NOMBRE": "DESMORALIZACIÓN Y APATÍA"
    },
    {
        "id": "bf077a4f-478a-2c1a-f8ed-23a359d86642",
        "NOMBRE": "DESNUTRICIÓN EN EL EMBARAZO"
    },
    {
        "id": "e6ef520a-2e5b-bf0b-536e-604586a8d645",
        "NOMBRE": "DESNUTRICIÓN FETAL, SIN MENCIÓN DE PESO O TALLA BAJOS PARA LA EDAD GESTACIONAL"
    },
    {
        "id": "50a67e2f-c32a-3b5e-5436-ca3f836b243f",
        "NOMBRE": "DESNUTRICIÓN PROTEICOCALÓRICA DE GRADO MODERADO Y LEVE"
    },
    {
        "id": "823f8b8f-7c16-f856-0a36-0e6addc48ea6",
        "NOMBRE": "DESNUTRICIÓN PROTEICOCALÓRICA LEVE"
    },
    {
        "id": "abba08cb-7dfe-7bee-3b2e-88c53b2b040d",
        "NOMBRE": "DESNUTRICIÓN PROTEICOCALÓRICA MODERADA"
    },
    {
        "id": "3da81eae-2f4a-b377-e8fc-03f4df1d1b36",
        "NOMBRE": "DESNUTRICIÓN PROTEICOCALÓRICA SEVERA, NO ESPECIFICADA"
    },
    {
        "id": "a0358069-eda3-abe2-f824-013a3767137c",
        "NOMBRE": "DESNUTRICIÓN PROTEICOCALÓRICA, NO ESPECIFICADA"
    },
    {
        "id": "f3960b1c-9f5e-d1d6-e1ff-ba6d69a0a1f8",
        "NOMBRE": "DESORIENTACIÓN NO ESPECIFICADA"
    },
    {
        "id": "67cf8573-ae32-4afb-52a7-87f961a3a47e",
        "NOMBRE": "DESPLAZAMIENTO CONGÉNITO DEL CRISTALINO"
    },
    {
        "id": "cae9b2e3-2682-d944-452d-fee059019ea8",
        "NOMBRE": "DESPOLARIZACIÓN AURICULAR PREMATURA"
    },
    {
        "id": "04d93021-a978-5537-05ee-ee0ff02abb2b",
        "NOMBRE": "DESPOLARIZACIÓN PREMATURA NODAL"
    },
    {
        "id": "0000118c-797f-41bd-a093-3ec9f03cedbe",
        "NOMBRE": "DESPOLARIZACIÓN VENTRICULAR PREMATURA"
    },
    {
        "id": "7d59278d-5e8d-d7dc-c467-f12e0fe06af9",
        "NOMBRE": "DESPRENDIMIENTO DE LA COROIDES"
    },
    {
        "id": "2db88f0d-9fd8-929b-0366-62501a9802d2",
        "NOMBRE": "DESPRENDIMIENTO DE LA RETINA CON RUPTURA"
    },
    {
        "id": "4515c350-3a28-bdfe-f454-ce9d6a86e3e3",
        "NOMBRE": "DESPRENDIMIENTO DE LA RETINA POR TRACCIÓN"
    },
    {
        "id": "0f5a66a5-e7ee-5785-bcb4-b1b6203c0861",
        "NOMBRE": "DESPRENDIMIENTO PREMATURO DE LA PLACENTA [ABRUPTIO PLACENTAE]"
    },
    {
        "id": "b0356365-89a6-4698-2de5-73ca23050626",
        "NOMBRE": "DESPRENDIMIENTO PREMATURO DE LA PLACENTA CON DEFECTO DE LA COAGULACIÓN"
    },
    {
        "id": "700c1d22-a4f7-7139-756b-e3cc0d9052c9",
        "NOMBRE": "DESPRENDIMIENTO PREMATURO DE LA PLACENTA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "660b4721-2b1c-fc02-ae4d-e824b15ae586",
        "NOMBRE": "DESPRENDIMIENTO SEROSO DE LA RETINA"
    },
    {
        "id": "74175447-4b03-cf81-769e-a573249b91e2",
        "NOMBRE": "DESPRENDIMIENTO Y DESGARRO DE LA RETINA"
    },
    {
        "id": "a88d89b1-e08a-2cb4-7a01-b22c59199c33",
        "NOMBRE": "DESVIACIÓN DEL TABIQUE NASAL"
    },
    {
        "id": "4379f165-82f0-97c7-a301-a13f7a0f0cdc",
        "NOMBRE": "DETENCIÓN DEL CRECIMIENTO EPIFISARIO"
    },
    {
        "id": "facb645d-ce4e-f68a-be8e-792710a35303",
        "NOMBRE": "DETENCIÓN DEL DESARROLLO DEL HUEVO Y MOLA NO HIDATIFORME"
    },
    {
        "id": "6edb8a26-b915-4ebd-d9ed-47973a9849d8",
        "NOMBRE": "DEXTROCARDIA"
    },
    {
        "id": "a7f29a4c-1f23-1f76-c0cd-4dc5516887e1",
        "NOMBRE": "DIABETES INSÍPIDA"
    },
    {
        "id": "a410c2c8-112c-07d9-9f07-b913c198fed7",
        "NOMBRE": "DIABETES INSÍPIDA NEFRÓGENA"
    },
    {
        "id": "157e8230-32eb-2a04-d999-c758b99d0295",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN"
    },
    {
        "id": "a782a354-6a2c-a674-ca2d-d7e27a587885",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON CETOACIDOSIS"
    },
    {
        "id": "50c747de-68bf-b063-fbb2-34b59de4790b",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON COMA"
    },
    {
        "id": "34998d07-cbb7-76f4-af33-e2b1a60d04ca",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON COMPLICACIONES CIRCULATORIAS PERIFÉRICAS"
    },
    {
        "id": "beaedea8-0a42-081c-a576-e6aa4dcb218a",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON COMPLICACIONES MÚLTIPLES"
    },
    {
        "id": "008f751b-b8b5-4d05-3bd4-51c8010d1761",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON COMPLICACIONES NEUROLÓGICAS"
    },
    {
        "id": "6d153fc5-bfff-b943-0220-1b3796465309",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON COMPLICACIONES NO ESPECIFICADAS"
    },
    {
        "id": "24337c99-af7a-25a2-cc76-bd6aa4fa76e0",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON COMPLICACIONES OFTÁLMICAS"
    },
    {
        "id": "f0f2f341-284d-ceb8-9751-e5cdba70edb9",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON COMPLICACIONES RENALES"
    },
    {
        "id": "db668975-9ed8-ec9f-28c8-fdb763d63284",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, CON OTRAS COMPLICACIONES ESPECIFICADAS"
    },
    {
        "id": "0e237579-c4ed-a8c7-6bbe-730c7861db6c",
        "NOMBRE": "DIABETES MELLITUS ASOCIADA CON DESNUTRICIÓN, SIN MENCIÓN DE COMPLICACIÓN"
    },
    {
        "id": "87e242ea-2684-025b-4517-42d9477b1d62",
        "NOMBRE": "DIABETES MELLITUS EN EL EMBARAZO"
    },
    {
        "id": "4bc58ff7-87ee-4028-1b2c-0be5a0ce47c6",
        "NOMBRE": "DIABETES MELLITUS NEONATAL"
    },
    {
        "id": "9a399d22-69b7-f90d-323f-3e44e744a1e6",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON CETOACIDOSIS"
    },
    {
        "id": "5ef7602c-4cc8-581b-76cb-cdcf7b2bbed3",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON COMA"
    },
    {
        "id": "f43ea406-1b91-8caa-9176-645a6c6346d0",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES CIRCULATORIAS PERIFÉRICAS"
    },
    {
        "id": "9a2e99aa-79d0-a95a-00d7-6237479f2a16",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES MÚLTIPLES"
    },
    {
        "id": "a9abd959-cd09-e934-d600-6ed348ee1cce",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES NEUROLÓGICAS"
    },
    {
        "id": "9b259d4a-7ffd-155f-9871-2ba9249b6e1b",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES NO ESPECIFICADAS"
    },
    {
        "id": "28afec03-a3e2-2f21-1d7d-c23f9e651811",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES OFTÁLMICAS"
    },
    {
        "id": "b9f504da-fd08-974a-5ebc-10d65fb75614",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON COMPLICACIONES RENALES"
    },
    {
        "id": "48915648-4fb5-4ab7-2430-f43802f6dedb",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, CON OTRAS COMPLICACIONES ESPECIFICADAS"
    },
    {
        "id": "0bf61331-45e6-ca12-7089-c1c8369f283b",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, EN EL EMBARAZO"
    },
    {
        "id": "2c3b9042-2c1b-0cb7-20db-3b80cb6e9ae7",
        "NOMBRE": "DIABETES MELLITUS NO ESPECIFICADA, SIN MENCIÓN DE COMPLICACIÓN"
    },
    {
        "id": "b933e721-c1c6-6880-691e-593297aa30d1",
        "NOMBRE": "DIABETES MELLITUS PREEXISTENTE RELACIONADA CON DESNUTRICIÓN, EN EL EMBARAZO"
    },
    {
        "id": "1ce8dc8e-747c-ad34-9d5c-abe4b3752ced",
        "NOMBRE": "DIABETES MELLITUS PREEXISTENTE TIPO 1, EN EL EMBARAZO"
    },
    {
        "id": "870838b8-8c70-f849-1cd3-713cc77be587",
        "NOMBRE": "DIABETES MELLITUS PREEXISTENTE TIPO 2, EN EL EMBARAZO"
    },
    {
        "id": "85f4ce0b-a6f5-9123-3722-20b9d1c6ac5d",
        "NOMBRE": "DIABETES MELLITUS PREEXISTENTE, SIN OTRA ESPECIFICACIÓN, EN EL EMBARAZO"
    },
    {
        "id": "77e6e6b5-0892-be38-998b-213675be7f40",
        "NOMBRE": "DIABETES MELLITUS QUE SE ORIGINA CON EL EMBARAZO"
    },
    {
        "id": "870ac327-ae2e-a0e2-8e6d-705e63fa13c2",
        "NOMBRE": "DIABETES MELLITUS TIPO 1"
    },
    {
        "id": "bf5e2a62-dbe4-395c-60f2-e191d86e718a",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON CETOACIDOSIS"
    },
    {
        "id": "c5cbd59f-2f43-cda2-4700-483d86e4d698",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON COMA"
    },
    {
        "id": "a6fdaed8-a685-d01d-5717-6ef7eedba022",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON COMPLICACIONES CIRCULATORIAS PERIFÉRICAS"
    },
    {
        "id": "166312b5-820c-cfb8-0048-379cfeb2dbad",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON COMPLICACIONES MÚLTIPLES"
    },
    {
        "id": "927397ac-9b7a-3ef8-eea4-800decc89d59",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON COMPLICACIONES NEUROLÓGICAS"
    },
    {
        "id": "604b4b27-bbc6-479a-0f92-278258e68d95",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON COMPLICACIONES NO ESPECIFICADAS"
    },
    {
        "id": "893735e2-4b46-1a10-d004-9987afa85cae",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON COMPLICACIONES OFTÁLMICAS"
    },
    {
        "id": "a94c70ef-527c-1297-f161-58b2dd74cdfd",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON COMPLICACIONES RENALES"
    },
    {
        "id": "9c7dd4ff-a9ef-cc78-cc69-9e42718103ff",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, CON OTRAS COMPLICACIONES ESPECIFICADAS"
    },
    {
        "id": "c8c3d84a-e0c7-f7c4-0d3d-31d6ab2c485f",
        "NOMBRE": "DIABETES MELLITUS TIPO 1, SIN MENCIÓN DE COMPLICACIÓN"
    },
    {
        "id": "fa0922dd-ab90-827e-2c4e-29e616f913db",
        "NOMBRE": "DIABETES MELLITUS TIPO 2"
    },
    {
        "id": "1463d6d8-c45c-c2da-ae17-011640f04d80",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON CETOACIDOSIS"
    },
    {
        "id": "e161dd4f-c5d9-1df3-ef09-b482ba6eb0d4",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON COMA"
    },
    {
        "id": "da52b32c-702d-1280-2a17-a22d4713c78e",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON COMPLICACIONES CIRCULATORIAS PERIFÉRICAS"
    },
    {
        "id": "04793054-b780-2cce-d6c2-d923ef31072c",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON COMPLICACIONES MÚLTIPLES"
    },
    {
        "id": "3cf6b7bf-8bc5-0062-1f10-b2910e7da354",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON COMPLICACIONES NEUROLÓGICAS"
    },
    {
        "id": "313238ea-6937-daf6-2830-8b01c2374eff",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON COMPLICACIONES NO ESPECIFICADAS"
    },
    {
        "id": "ef0e516d-2880-df29-41a0-4fd14a278966",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON COMPLICACIONES OFTÁLMICAS"
    },
    {
        "id": "87d038c2-832c-9404-71c1-bcfbebcbbbbe",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON COMPLICACIONES RENALES"
    },
    {
        "id": "8d0c2f03-2c9a-a267-59bc-e88a02012416",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, CON OTRAS COMPLICACIONES ESPECIFICADAS"
    },
    {
        "id": "1dbb6ef8-3c98-c675-e705-bc565a0fac02",
        "NOMBRE": "DIABETES MELLITUS TIPO 2, SIN MENCIÓN DE COMPLICACIÓN"
    },
    {
        "id": "c4b66f2b-4deb-df86-29d2-6c834b2050b5",
        "NOMBRE": "DIABETES MELLITUS, NO ESPECIFICADA"
    },
    {
        "id": "4f43c1a2-b71a-ddbf-6fe5-c59c288dad2c",
        "NOMBRE": "DIÁLISIS EXTRACORPÓREA"
    },
    {
        "id": "3e212dae-fc71-a2c4-af4f-f8a25b4eb8d5",
        "NOMBRE": "DIÁLISIS RENAL"
    },
    {
        "id": "c4a4d3fd-a9f6-b2a0-fe37-7987e6982a4c",
        "NOMBRE": "DIARREA FUNCIONAL"
    },
    {
        "id": "bb22b88e-fa34-ad4d-829a-f564e798aaeb",
        "NOMBRE": "DIARREA NEONATAL NO INFECCIOSA"
    },
    {
        "id": "c8a753e4-6b8a-6912-028c-c918f3768494",
        "NOMBRE": "DIARREA Y GASTROENTERITIS DE PRESUNTO ORIGEN INFECCIOSO"
    },
    {
        "id": "8ed84815-bb1d-e135-1956-9b4ffa407e96",
        "NOMBRE": "DIÁSTASIS DEL MÚSCULO"
    },
    {
        "id": "a2e6b7f9-397b-019a-db48-16aa24a8f9d7",
        "NOMBRE": "DIASTEMATOMIELIA"
    },
    {
        "id": "224fb316-d03e-8585-60bd-75849edd676f",
        "NOMBRE": "DICROCOELIASIS"
    },
    {
        "id": "a9c7a19c-ffe2-17ce-a4ee-a11fb1c9daad",
        "NOMBRE": "DIENTES IMPACTADOS"
    },
    {
        "id": "9c80d7a6-bbe5-c285-b76a-f20c5ad42ecf",
        "NOMBRE": "DIENTES INCLUIDOS"
    },
    {
        "id": "ce2444d4-3788-84b8-06b1-6fbd4d294172",
        "NOMBRE": "DIENTES INCLUIDOS E IMPACTADOS"
    },
    {
        "id": "c55753df-4d16-0643-81a9-35a5cf23f02d",
        "NOMBRE": "DIENTES MOTEADOS"
    },
    {
        "id": "65086c5f-85cc-2bc1-641f-260413dbfbbe",
        "NOMBRE": "DIENTES SUPERNUMERARIOS"
    },
    {
        "id": "183c1b1e-0af4-9d3f-c9ed-a9ff5b1637a8",
        "NOMBRE": "DIFICULTAD NEONATAL EN LA LACTANCIA MATERNA"
    },
    {
        "id": "7393504b-d729-77e2-8d6b-5925750c3d8d",
        "NOMBRE": "DIFICULTAD PARA CAMINAR, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "15cae842-0fb7-e3e6-9a82-3c9258499e63",
        "NOMBRE": "DIFICULTAD RESPIRATORIA DEL RECIÉN NACIDO"
    },
    {
        "id": "beeddad5-10df-3ff9-646f-1d5658260a4b",
        "NOMBRE": "DIFICULTAD RESPIRATORIA DEL RECIÉN NACIDO, NO ESPECIFICADA"
    },
    {
        "id": "f38d999b-5ce6-6f15-80ed-f795acd307f9",
        "NOMBRE": "DIFICULTADES Y MALA ADMINISTRACIÓN DE LA ALIMENTACIÓN"
    },
    {
        "id": "72e796b7-892d-5b18-9d5f-aff3870162ef",
        "NOMBRE": "DIFILOBOTRIASIS INTESTINAL"
    },
    {
        "id": "1d47f9ad-3e01-3826-1c21-257c7164e0f0",
        "NOMBRE": "DIFILOBOTRIASIS Y ESPARGANOSIS"
    },
    {
        "id": "39cfd5ae-dd9a-e9d5-9439-e305d93e9957",
        "NOMBRE": "DIFTERIA"
    },
    {
        "id": "dcf7b88c-18b1-4d00-fdf3-108e80540ed5",
        "NOMBRE": "DIFTERIA CUTÁNEA"
    },
    {
        "id": "48d86589-fef9-5919-b7bc-62d9de3ff12c",
        "NOMBRE": "DIFTERIA FARÍNGEA"
    },
    {
        "id": "90b9172a-3394-1f00-665e-bff59e6ea66f",
        "NOMBRE": "DIFTERIA LARÍNGEA"
    },
    {
        "id": "6be119f7-57fe-05e9-215a-3c4640834b18",
        "NOMBRE": "DIFTERIA NASOFARÍNGEA"
    },
    {
        "id": "cd902260-539a-652d-fea0-e8382caac6d2",
        "NOMBRE": "DIFTERIA, NO ESPECIFICADA"
    },
    {
        "id": "35d5a03d-974b-3f17-6d3a-79ee1f24788b",
        "NOMBRE": "DILATACIÓN AGUDA DEL ESTÓMAGO"
    },
    {
        "id": "a66c9940-1df1-98f3-0497-679f6df1ba79",
        "NOMBRE": "DILATACIÓN CONGÉNITA DEL ESÓFAGO"
    },
    {
        "id": "fb592109-1669-3d65-6966-a7f5b73b4481",
        "NOMBRE": "DILUCIÓN INCORRECTA DE LÍQUIDO DURANTE UNA INFUSIÓN"
    },
    {
        "id": "c872a41d-4518-ae2f-1fb5-9eaddac3ca51",
        "NOMBRE": "DIPILIDIASIS"
    },
    {
        "id": "6c478fec-824b-5687-9d68-26b93524a468",
        "NOMBRE": "DIPLEJÍA DE LOS MIEMBROS SUPERIORES"
    },
    {
        "id": "f5621cf4-2edc-b4e0-2d50-318524b51aff",
        "NOMBRE": "DIPLOPÍA"
    },
    {
        "id": "31fc00f9-4411-0b8b-fabf-2aa415692e82",
        "NOMBRE": "DISARTRIA Y ANARTRIA"
    },
    {
        "id": "b2313448-9794-6279-24aa-390d377edfe1",
        "NOMBRE": "DISAUTONOMÍA FAMILIAR [SÍNDROME DE RILEY-DAY]"
    },
    {
        "id": "090a146c-2bc6-d110-8a90-61fdf1fecd4e",
        "NOMBRE": "DISCITIS, NO ESPECIFICADA"
    },
    {
        "id": "c568b7e6-948b-f0fd-0e9d-92152ab198a4",
        "NOMBRE": "DISCONTINUIDAD Y DISLOCACIÓN DE LOS HUESECILLOS DEL OÍDO"
    },
    {
        "id": "eeaf86ab-3949-608a-dc57-0a8bd68e1113",
        "NOMBRE": "DISCORDANCIA DE LA CONEXIÓN AURICULOVENTRICULAR"
    },
    {
        "id": "2d84d16e-9faf-7a46-c1e0-1b13ca3142f6",
        "NOMBRE": "DISCORDANCIA DE LA CONEXIÓN VENTRICULOARTERIAL"
    },
    {
        "id": "e9869598-dd2b-6c4f-2afe-3fa277767b17",
        "NOMBRE": "DISECCIÓN DE AORTA (CUALQUIER PARTE)"
    },
    {
        "id": "9cbea010-0ff5-f278-368f-568c2f6f79cd",
        "NOMBRE": "DISECCIÓN DE ARTERIAS CEREBRALES, SIN RUPTURA"
    },
    {
        "id": "00a2b764-8290-2caf-d2dc-95fa56a86762",
        "NOMBRE": "DISENTERÍA AMEBIANA AGUDA"
    },
    {
        "id": "4dabf18f-00a0-53e8-e0f0-70e806a20655",
        "NOMBRE": "DISFAGIA"
    },
    {
        "id": "b25498a5-a4a6-ddf0-7609-d9091f53eaf7",
        "NOMBRE": "DISFAGIA SIDEROPÉNICA"
    },
    {
        "id": "a4f43e24-beb9-7681-c9a3-1a257ba7697b",
        "NOMBRE": "DISFASIA Y AFASIA"
    },
    {
        "id": "9ecf15b6-2722-2b33-76bc-1a69643086df",
        "NOMBRE": "DISFONÍA"
    },
    {
        "id": "14e89918-6615-6c18-7f7b-cd5292170315",
        "NOMBRE": "DISFUNCIÓN AUTONÓMICA SOMATOMORFA"
    },
    {
        "id": "b708796e-105c-bc00-75b9-96e34662b9f7",
        "NOMBRE": "DISFUNCIÓN DE COLOSTOMÍA O ENTEROSTOMÍA"
    },
    {
        "id": "485eede1-029d-0df8-62c5-528bdafca480",
        "NOMBRE": "DISFUNCIÓN DEL LABERINTO"
    },
    {
        "id": "7e93eeaa-460f-4967-f4a7-68726c92cd60",
        "NOMBRE": "DISFUNCIÓN HIPOTALÁMICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "ea0be9d7-e470-57b7-06ef-d930982a2afd",
        "NOMBRE": "DISFUNCIÓN NEUROMUSCULAR DE LA VEJIGA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "1aa64b3b-cc79-19fc-c5a7-7a072becfa1e",
        "NOMBRE": "DISFUNCIÓN NEUROMUSCULAR DE LA VEJIGA, NO ESPECIFICADA"
    },
    {
        "id": "81696f70-0b8f-50e2-3d1a-fdc13f484ca5",
        "NOMBRE": "DISFUNCIÓN ORGÁSMICA"
    },
    {
        "id": "351d3cf4-788d-d3ee-2844-bea3efe217be",
        "NOMBRE": "DISFUNCIÓN OVÁRICA"
    },
    {
        "id": "5f5f1d60-f00b-140b-e034-90a825268191",
        "NOMBRE": "DISFUNCIÓN OVÁRICA, NO ESPECIFICADA"
    },
    {
        "id": "acb220b2-d0ea-1161-687e-f2286fb9da1b",
        "NOMBRE": "DISFUNCIÓN POLIGLANDULAR"
    },
    {
        "id": "4fef58ca-4376-dd01-e685-b55ee55be6e4",
        "NOMBRE": "DISFUNCIÓN POLIGLANDULAR, NO ESPECIFICADA"
    },
    {
        "id": "5a7eb2cf-89f0-9583-9fd2-2d8695bfc241",
        "NOMBRE": "DISFUNCIÓN SEGMENTAL O SOMÁTICA"
    },
    {
        "id": "cf8e9c7b-ab4b-8a08-092f-4795e62a8a5f",
        "NOMBRE": "DISFUNCIÓN SEXUAL NO OCASIONADA POR TRASTORNO NI ENFERMEDAD ORGÁNICOS"
    },
    {
        "id": "907b1b86-59fc-d4ee-b2d5-2b3220dbbfc4",
        "NOMBRE": "DISFUNCIÓN SEXUAL NO OCASIONADA POR TRASTORNO NI POR ENFERMEDAD ORGÁNICOS, NO ESPECIFICADA"
    },
    {
        "id": "90f1e849-3ba9-ed9d-5594-bc54dd877b74",
        "NOMBRE": "DISFUNCIÓN TESTICULAR"
    },
    {
        "id": "6ab2ba3c-563e-b682-ebab-085c7839f620",
        "NOMBRE": "DISFUNCIÓN TESTICULAR, NO ESPECIFICADA"
    },
    {
        "id": "85ff4cc0-aa4f-d5ff-5cf9-d122d4404c97",
        "NOMBRE": "DISHIDROSIS [PONFÓLIX]"
    },
    {
        "id": "e80ccba3-1bb2-692d-3651-a8b4261c8874",
        "NOMBRE": "DISLEXIA Y ALEXIA"
    },
    {
        "id": "d6ae1234-9a32-82b9-8006-b4e91a5632be",
        "NOMBRE": "DISLEXIA Y OTRAS DISFUNCIONES SIMBÓLICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a8d2c173-3498-ad38-588a-dd2b939db3f5",
        "NOMBRE": "DISMENORREA PRIMARIA"
    },
    {
        "id": "79ba9cf3-116d-065d-903c-f3dd17a31a45",
        "NOMBRE": "DISMENORREA SECUNDARIA"
    },
    {
        "id": "6b0c1cb2-2e89-87ae-f0cb-d91e20c490d4",
        "NOMBRE": "DISMENORREA, NO ESPECIFICADA"
    },
    {
        "id": "62888d20-02da-0c51-536d-75bc39cfdbb8",
        "NOMBRE": "DISMINUCIÓN DE LA AGUDEZA VISUAL, SIN ESPECIFICACIÓN"
    },
    {
        "id": "c8185c94-1a38-17bb-66d1-797e55939bdc",
        "NOMBRE": "DISMORFISMO DEBIDO A WARFARINA"
    },
    {
        "id": "30675fd8-94e8-fe75-4112-f49754725683",
        "NOMBRE": "DISNEA"
    },
    {
        "id": "cad5f5f3-300c-44ce-b8d4-eeb90906cd95",
        "NOMBRE": "DISOSTOSIS CRANEOFACIAL"
    },
    {
        "id": "867f4f3d-8255-6245-640b-ccaca30b4500",
        "NOMBRE": "DISOSTOSIS MAXILOFACIAL"
    },
    {
        "id": "b45df304-8911-7e42-0e45-38067b54f7ce",
        "NOMBRE": "DISOSTOSIS OCULOMAXILAR"
    },
    {
        "id": "29ebff1f-2f9d-4131-a004-2da6eae83f23",
        "NOMBRE": "DISPAREUNIA"
    },
    {
        "id": "2e0798c6-2274-8c5c-e92d-d8e23c49513e",
        "NOMBRE": "DISPAREUNIA NO ORGÁNICA"
    },
    {
        "id": "df6ef830-8ad6-8458-53f2-2be8e3a7dfb7",
        "NOMBRE": "DISPARO DE ARMA CORTA"
    },
    {
        "id": "e7f45c5a-0544-ffec-e1a4-fd790e7fb964",
        "NOMBRE": "DISPARO DE ARMA CORTA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8cf8004f-47ba-b470-80a3-bb0e8145cfaf",
        "NOMBRE": "DISPARO DE ARMA CORTA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f789a415-cd67-b81e-123b-201950368ca4",
        "NOMBRE": "DISPARO DE ARMA CORTA, CALLES Y CARRETERAS"
    },
    {
        "id": "a3cbdce3-6c04-0090-98c7-8b55781d68f8",
        "NOMBRE": "DISPARO DE ARMA CORTA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0a9e8bff-a271-3853-39f6-d06d7f88d35d",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "488e58dc-1b15-710e-1245-10636f1cc7ef",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d557f18f-af8a-d301-51fa-819d9c331563",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a8b1d8b2-2904-2d1a-0958-e89b7cf903f7",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "ccd9aeb9-2294-79e5-efab-d350d14e10c1",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "7e717001-9913-0ceb-ff33-166b7028beed",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "851bc084-fa21-d1e7-7483-e2b212cab463",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "e34e1fb6-3aad-f9a4-9bc6-f89dd7cdb56e",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "c70d7417-c9ab-41ad-9a88-a9def555c98c",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "70b5f2c5-a67b-0519-9105-25fd00bba440",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "98deb6f6-3819-801f-a5ea-e554df801887",
        "NOMBRE": "DISPARO DE ARMA CORTA, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "bfbecafb-889e-6bfb-56ec-bec5f11c39c9",
        "NOMBRE": "DISPARO DE ARMA CORTA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2ea9b774-523b-c475-b35c-8ce459afde54",
        "NOMBRE": "DISPARO DE ARMA CORTA, GRANJA"
    },
    {
        "id": "8fd62893-3052-f6f3-611e-3ade07bc7f83",
        "NOMBRE": "DISPARO DE ARMA CORTA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "2a729dfb-dae0-eb53-9ad8-6d1b0f5eb9b0",
        "NOMBRE": "DISPARO DE ARMA CORTA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "de03fee9-bd5c-1994-458b-d3b4fcbca036",
        "NOMBRE": "DISPARO DE ARMA CORTA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "63b6d380-2d93-de6f-84dc-833a80a5e230",
        "NOMBRE": "DISPARO DE ARMA CORTA, VIVIENDA"
    },
    {
        "id": "3fdfb24e-57f0-3b01-da36-dbd2fac01339",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "32f3f209-111c-1a72-2eef-fce6de6b53ef",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "c5e8038f-5823-7646-d1f9-70fe2c7dfea2",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "c6c2b6f9-edf9-f4a7-0497-30267270395d",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "353307c3-1701-cdc3-0b6b-70251ebfdf18",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "4a211d62-f86a-b7c1-7a97-e6e4afb719e2",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6a1d2eec-1a6e-1d1c-73bd-fc5d4bd332a8",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "fb311269-809c-8513-bc98-15e675d4e2f0",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0e2a9856-d990-8bb2-ad71-701c5dafb0a2",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8bcbb886-215b-ec8e-cd65-bdaa8ab44720",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "a4c134a3-342c-41a0-3228-becf91024656",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1561eeeb-676e-ff73-ea56-2c1fc8fd5dca",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "f95049b8-2a42-8818-1329-97211db79b69",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "14c8047b-ee27-724b-b53f-6c67ec9a6f1a",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "b3ec6fea-8a1c-ffc9-e705-eefcd5eb90e2",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "fdc50a66-70ca-cb7f-ef0d-b77018424536",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "29e1266d-27cb-389e-e651-26d22677cd68",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "18a5ec08-b506-3dec-61fc-8b1cfaf599b8",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "0f3a97b2-11a3-5bd5-a958-35a3a1679a00",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e7d81cf6-3412-448a-bd8c-c55edddd5f43",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "bd08173e-5079-e277-557f-b9fd508cab55",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "cb35f61d-002a-8fb9-d522-7f073ae1a05f",
        "NOMBRE": "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "e2ee245b-737f-0812-8adf-2a77c279828b",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA"
    },
    {
        "id": "bfbc1b7e-9ec4-3df8-b305-5c74296c14ad",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "aaf62624-2483-c8e3-ccf4-98b73409d4be",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c92ff5c4-a88e-7019-2ce0-0fb5c995ae7e",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, CALLES Y CARRETERAS"
    },
    {
        "id": "6306e97d-8395-d4cc-08fe-5c7c919a02ee",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "28054a18-8e1f-ab6a-e1d3-aa3862afde4e",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "e9f1edf4-6b74-a8ba-297d-ad0689b37944",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8d9912e9-f1b4-b5fc-563f-359a344c9259",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "41f28427-b590-20ae-d838-b513932a7721",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "c16cb488-ba13-6dc2-4836-9507b6dc142b",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1ed4eacb-62aa-baf8-5008-b17f5b7371f0",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "8cb78b95-eeb8-e73a-074d-e40345ecfbda",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "4200da4d-d01f-c7a6-7a50-9fb8aa38b4ca",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "53f3ef8e-af2e-ad2f-a7de-f4b7d97e0d21",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "4666b7c8-7d74-56ed-e389-1fa445700d8a",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "20d6a938-6375-f1d7-5498-c699c786f6cb",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "a9e01d3b-c7d4-a434-4722-5b946241ae9e",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c3955356-649b-ee00-e2fc-db22186256a6",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, GRANJA"
    },
    {
        "id": "657ad485-b712-9630-f0d0-c5919ba37b68",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "014a7c70-75e5-5815-0da3-21faf2c0c62f",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "7ec194d9-2a50-c721-ec47-08d1cb7c617b",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6bcf3ba9-9fe9-e88a-abd2-d33e78928a8e",
        "NOMBRE": "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, VIVIENDA"
    },
    {
        "id": "73b24e88-e7b1-df94-80ff-017a7feb7a1d",
        "NOMBRE": "DISPEPSIA FUNCIONAL"
    },
    {
        "id": "f75b31d0-c045-c403-28cb-3afc686c6021",
        "NOMBRE": "DISPLASIA BRONCOPULMONAR ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "24920197-30e1-3d0d-0974-16ea3972efc8",
        "NOMBRE": "DISPLASIA CERVICAL LEVE"
    },
    {
        "id": "5a34f650-9c1b-a985-7bf2-366c2dbc64e8",
        "NOMBRE": "DISPLASIA CERVICAL MODERADA"
    },
    {
        "id": "c4e0aae5-3642-5dce-6f58-a9c3ba8106f9",
        "NOMBRE": "DISPLASIA CERVICAL SEVERA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "40633b1b-51da-64f2-6f5e-71966ee6555a",
        "NOMBRE": "DISPLASIA CONDROECTODÉRMICA"
    },
    {
        "id": "d9d3833a-9a73-f203-6d9e-71e85b39ad04",
        "NOMBRE": "DISPLASIA DE LA PRÓSTATA"
    },
    {
        "id": "377deaf8-19be-b692-9a7d-f75dabed2d1c",
        "NOMBRE": "DISPLASIA DE LA VAGINA, NO ESPECIFICADA"
    },
    {
        "id": "6a2b1543-1b46-1c62-8610-5ffb0a39d894",
        "NOMBRE": "DISPLASIA DE LA VULVA, NO ESPECIFICADA"
    },
    {
        "id": "0dc53d80-12c7-590f-da43-1921cbb5ed87",
        "NOMBRE": "DISPLASIA DEL CUELLO DEL ÚTERO, NO ESPECIFICADA"
    },
    {
        "id": "b9d4a22b-1f67-323b-2c52-5dd1705c3f4f",
        "NOMBRE": "DISPLASIA DEL CUELLO UTERINO"
    },
    {
        "id": "3cd7596a-843e-6bee-8271-554081ca2e3c",
        "NOMBRE": "DISPLASIA DIAFISARIA PROGRESIVA"
    },
    {
        "id": "809cd9ac-8ff9-7770-6993-ab81b8e124b1",
        "NOMBRE": "DISPLASIA DISTRÓFICA"
    },
    {
        "id": "8253ce73-a4a4-8851-a440-d9a30de55d07",
        "NOMBRE": "DISPLASIA ECTODÉRMICA (ANHIDRÓTICA)"
    },
    {
        "id": "2213da88-fa0e-2175-97ae-91f0423f744e",
        "NOMBRE": "DISPLASIA ESPONDILOEPIFISARIA"
    },
    {
        "id": "9e1bf1c3-8a0b-d299-4c39-26d118eeeccb",
        "NOMBRE": "DISPLASIA FIBROMUSCULAR ARTERIAL"
    },
    {
        "id": "68e085f3-e697-6482-e5fc-16930bd5f50c",
        "NOMBRE": "DISPLASIA FIBROSA (MONOSTÓTICA)"
    },
    {
        "id": "d4c1b99c-b3e2-a286-17b7-cd11e2a85305",
        "NOMBRE": "DISPLASIA MAMARIA BENIGNA"
    },
    {
        "id": "682aca2e-d184-e940-0321-4a24048642be",
        "NOMBRE": "DISPLASIA MAMARIA BENIGNA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "893c2021-e8c0-33ef-6dd4-21dbffa042a5",
        "NOMBRE": "DISPLASIA METAFISARIA"
    },
    {
        "id": "488c7317-b421-2fec-88b3-189f8bba28c0",
        "NOMBRE": "DISPLASIA OPTICOSEPTAL"
    },
    {
        "id": "27697305-4087-507f-e1b7-a09da68b7629",
        "NOMBRE": "DISPLASIA POLIOSTÓTICA FIBROSA"
    },
    {
        "id": "163a563e-1336-8ac5-1349-d780eec70055",
        "NOMBRE": "DISPLASIA RENAL"
    },
    {
        "id": "9862283d-c5de-03b7-839b-9d7b1fe19f1b",
        "NOMBRE": "DISPLASIA VAGINAL LEVE"
    },
    {
        "id": "809ee254-a554-49df-3891-f6dc419deebb",
        "NOMBRE": "DISPLASIA VAGINAL MODERADA"
    },
    {
        "id": "d4e5157b-4a80-2c4c-645a-a69f3aacb2c2",
        "NOMBRE": "DISPLASIA VAGINAL SEVERA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "91ec975c-6557-b5ed-c4a3-3305d3e687cc",
        "NOMBRE": "DISPLASIA VULVAR LEVE"
    },
    {
        "id": "a26f1adb-1c16-cd70-3629-689d38ee25d9",
        "NOMBRE": "DISPLASIA VULVAR MODERADA"
    },
    {
        "id": "309aacf6-a382-abfd-17e6-705ced79b1ef",
        "NOMBRE": "DISPLASIA VULVAR SEVERA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "a07ea999-5155-942a-c53a-a80510e3f5e9",
        "NOMBRE": "DISPONÍA BUCOFACIAL IDIOPÁTICA"
    },
    {
        "id": "cb0bab26-ed6f-5f77-c498-280647c28bd3",
        "NOMBRE": "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "41a005c0-08de-6f35-c296-42a7051ee4bb",
        "NOMBRE": "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "54cacb12-af7d-5aff-9f6f-c2e3a10717b3",
        "NOMBRE": "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "4adc7a32-bc1e-7311-3f44-796275410bcf",
        "NOMBRE": "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "6a1b2b8b-8b73-fa42-e8df-97abac4514dd",
        "NOMBRE": "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "9408f15f-9963-9564-7700-3959a415c09d",
        "NOMBRE": "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "c8b716e1-26df-beba-d3da-13d776502cbc",
        "NOMBRE": "DISPOSITIVOS DE ANESTESIOLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "62004ce2-2139-0133-724a-ea971c7a3dc1",
        "NOMBRE": "DISPOSITIVOS DE ANESTESIOLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "86e2a64f-ede0-90dd-a207-fdb5f2523a06",
        "NOMBRE": "DISPOSITIVOS DE ANESTESIOLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "fffb64b0-9ba4-3643-f95c-28fa3c84d470",
        "NOMBRE": "DISPOSITIVOS DE ANESTESIOLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "c099c1f1-33f2-7003-4c99-935abeb532da",
        "NOMBRE": "DISPOSITIVOS DE ANESTESIOLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "85d6e025-87b3-27e1-0b2c-98cea4b5ac29",
        "NOMBRE": "DISPOSITIVOS DE ANESTESIOLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "d362b21c-13a1-6a13-efd6-5c086d0d172b",
        "NOMBRE": "DISPOSITIVOS DE CIRUGÍA GENERAL Y PLÁSTICA ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "478fe6df-b711-f7ed-f404-9c718bdf1d34",
        "NOMBRE": "DISPOSITIVOS DE CIRUGÍA GENERAL Y PLÁSTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "f051ef05-34af-f360-78f2-ac8b55593795",
        "NOMBRE": "DISPOSITIVOS DE CIRUGÍA GENERAL Y PLÁSTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "dd4a91d6-b3d9-6522-8445-d6f80be64eed",
        "NOMBRE": "DISPOSITIVOS DE CIRUGÍA GENERAL Y PLÁSTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "46581a46-15d5-f716-fae4-37472e925dc4",
        "NOMBRE": "DISPOSITIVOS DE CIRUGÍA GENERAL Y PLÁSTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "45dce36d-5496-6c20-24cc-d510fea1c87e",
        "NOMBRE": "DISPOSITIVOS DE CIRUGÍA GENERAL Y PLÁSTICA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "ab46d8e2-60c7-3b75-ea00-c960704426f2",
        "NOMBRE": "DISPOSITIVOS DE GASTROENTEROLOGÍA Y UROLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "57c95205-aaa1-c12b-c041-137a634bca92",
        "NOMBRE": "DISPOSITIVOS DE GASTROENTEROLOGÍA Y UROLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "ae2e62bb-7ecd-054b-3b03-d18dbde08dce",
        "NOMBRE": "DISPOSITIVOS DE GASTROENTEROLOGÍA Y UROLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "bfcd1261-4d34-3e5d-80f2-c9ff862fdf01",
        "NOMBRE": "DISPOSITIVOS DE GASTROENTEROLOGÍA Y UROLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "ec01a1e1-1b63-5258-c331-d44f022ec5de",
        "NOMBRE": "DISPOSITIVOS DE GASTROENTEROLOGÍA Y UROLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "4c3132ae-6307-ed23-08d5-6da33a436461",
        "NOMBRE": "DISPOSITIVOS DE GASTROENTEROLOGÍA Y UROLOGÍA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "83617c7e-96c3-973a-4dfd-9f24946ba168",
        "NOMBRE": "DISPOSITIVOS GINECOLÓGICOS Y OBSTÉTRICOS ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "2dd6f96b-b907-c5dc-e0d5-a1fd3e57a114",
        "NOMBRE": "DISPOSITIVOS GINECOLÓGICOS Y OBSTÉTRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "7f245bf9-a44b-8882-f73c-474d37612ef1",
        "NOMBRE": "DISPOSITIVOS GINECOLÓGICOS Y OBSTÉTRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "147b2845-9750-70be-c0c7-9100ffc39277",
        "NOMBRE": "DISPOSITIVOS GINECOLÓGICOS Y OBSTÉTRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "518da824-231f-2758-a274-c3d2933f65b4",
        "NOMBRE": "DISPOSITIVOS GINECOLÓGICOS Y OBSTÉTRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "cddb2904-ac6a-311c-d852-ee5261722317",
        "NOMBRE": "DISPOSITIVOS GINECOLÓGICOS Y OBSTÉTRICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "ef3d2cb7-a757-06fb-6faf-be27e2abec07",
        "NOMBRE": "DISPOSITIVOS NEUROLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "5e20f042-1eda-f726-9a9c-041911ad6a95",
        "NOMBRE": "DISPOSITIVOS NEUROLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "db07870f-cb4c-37ba-c016-95ac70bbe511",
        "NOMBRE": "DISPOSITIVOS NEUROLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "31ee7312-f234-03df-36d1-66b258bc135e",
        "NOMBRE": "DISPOSITIVOS NEUROLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "7d96254a-cbd0-25e1-a81f-326508cf8e55",
        "NOMBRE": "DISPOSITIVOS NEUROLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "6875f2aa-321d-09e7-77b3-b96f984ad68f",
        "NOMBRE": "DISPOSITIVOS NEUROLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "801de48b-f890-99ba-07bd-b11fb879203e",
        "NOMBRE": "DISPOSITIVOS OFTÁLMICOS ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "85472f53-67a8-5ed5-3cf7-31f755425ea6",
        "NOMBRE": "DISPOSITIVOS OFTÁLMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "565bc48e-5e91-16a2-d23e-0d6ed34555a1",
        "NOMBRE": "DISPOSITIVOS OFTÁLMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "6ec34c4b-b180-2602-6ace-77a0da141c01",
        "NOMBRE": "DISPOSITIVOS OFTÁLMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "2a176c35-808f-a8e6-1a15-e903cef286f0",
        "NOMBRE": "DISPOSITIVOS OFTÁLMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "51e97898-d96c-bd10-9408-2a1ef264df85",
        "NOMBRE": "DISPOSITIVOS OFTÁLMICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "b3789ef0-b644-bfc8-da99-ff37dfecd9b0",
        "NOMBRE": "DISPOSITIVOS ORTOPÉDICOS ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "7e8a295b-98fd-d900-a4a3-33830ff42e00",
        "NOMBRE": "DISPOSITIVOS ORTOPÉDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "2efc58f5-5783-8585-24b4-184fdbd2bb70",
        "NOMBRE": "DISPOSITIVOS ORTOPÉDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "7f8e4c5f-c2eb-8b57-28f3-c9ce62934eb4",
        "NOMBRE": "DISPOSITIVOS ORTOPÉDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "0b4fe8a3-963a-c09a-568c-43b68432bad9",
        "NOMBRE": "DISPOSITIVOS ORTOPÉDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "4db2c254-8f6e-bc02-a050-dc50cec42b28",
        "NOMBRE": "DISPOSITIVOS ORTOPÉDICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "14d1852d-7556-a150-76ec-e1bc90eee172",
        "NOMBRE": "DISPOSITIVOS OTORRINOLARINGOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "c4dc9940-7465-745a-41d5-76622056459f",
        "NOMBRE": "DISPOSITIVOS OTORRINOLARINGOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "9ca122b4-1b6b-bac5-9090-9a8f3f498894",
        "NOMBRE": "DISPOSITIVOS OTORRINOLARINGOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "58911757-9fb4-a249-9f29-8570f7478d21",
        "NOMBRE": "DISPOSITIVOS OTORRINOLARINGOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "5654df0e-cfb7-af3a-681a-cd1e4bfb8683",
        "NOMBRE": "DISPOSITIVOS OTORRINOLARINGOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "744e79a7-b9de-f7bb-bd69-5730fd2adcb3",
        "NOMBRE": "DISPOSITIVOS OTORRINOLARINGOLÓGICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "65a748e0-b51e-e886-d081-3f0d576da2e0",
        "NOMBRE": "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "8be5f830-17e2-dd48-2b85-6f2be8a89def",
        "NOMBRE": "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "4ecb27d2-208d-b045-ab52-b578b745823a",
        "NOMBRE": "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "b9c0596d-d995-6a30-e611-114fa8a87aed",
        "NOMBRE": "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "d9475947-aeb0-a7b1-a8d7-45cc97e31e21",
        "NOMBRE": "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "3fd2388f-5d42-6aba-5d37-7d85b866ed3f",
        "NOMBRE": "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "b5ed869b-fc54-6db8-fdc6-95a1dbcccc0d",
        "NOMBRE": "DISQUINESIA DEL ESÓFAGO"
    },
    {
        "id": "508d5963-f641-b6b8-02af-0ae5825b6542",
        "NOMBRE": "DISREFLEXIA AUTONÓMICA"
    },
    {
        "id": "8fdf6d23-d21a-bb2a-205d-6d81cdb54960",
        "NOMBRE": "DISRITMIA CARDÍACA NEONATAL"
    },
    {
        "id": "16bc646c-a564-8dba-79e9-cef06324cd6c",
        "NOMBRE": "DISTENSIÓN DE LA TROMPA DE EUSTAQUIO"
    },
    {
        "id": "f6009b8f-4cf5-b69f-4435-d23aa0280d43",
        "NOMBRE": "DISTENSIÓN MUSCULAR"
    },
    {
        "id": "78ee6c44-e161-71b3-8d39-42ad4dfafcaf",
        "NOMBRE": "DISTIMIA"
    },
    {
        "id": "931a1b88-f4af-89e2-8cc3-de0df2040259",
        "NOMBRE": "DISTONÍA"
    },
    {
        "id": "1216471f-fe17-1010-34c3-e6f07638e2bf",
        "NOMBRE": "DISTONÍA IDIOPÁTICA FAMILIAR"
    },
    {
        "id": "b14d2c16-2323-ce54-81bb-26cd0bbc3b3d",
        "NOMBRE": "DISTONÍA IDIOPÁTICA NO FAMILIAR"
    },
    {
        "id": "39085fbd-89ee-f762-c041-4ccecc22e2ec",
        "NOMBRE": "DISTONÍA INDUCIDA POR DROGAS"
    },
    {
        "id": "a8b8fef7-bdc4-e10a-c3b3-07e2d68e81b9",
        "NOMBRE": "DISTONÍA, NO ESPECIFICADA"
    },
    {
        "id": "e34560b2-5068-f619-bbdc-85afcbd121f1",
        "NOMBRE": "DISTROFIA COROIDEA HEREDITARIA"
    },
    {
        "id": "d211d023-9c9f-4387-9260-002f7b41f593",
        "NOMBRE": "DISTROFIA HEREDITARIA DE LA CÓRNEA"
    },
    {
        "id": "7db178a4-d59a-e59c-f6a0-e3dcbd1ba1f8",
        "NOMBRE": "DISTROFIA HEREDITARIA DE LA RETINA"
    },
    {
        "id": "c19888b2-fb4e-c28c-b861-7fa279ec8e97",
        "NOMBRE": "DISTROFIA MUSCULAR"
    },
    {
        "id": "67171ad8-625e-36f0-6d6f-511fe5ee57d6",
        "NOMBRE": "DISTROFIA UNGUEAL"
    },
    {
        "id": "d36a53d6-3599-65ea-4b9b-aa76af83510e",
        "NOMBRE": "DISURIA"
    },
    {
        "id": "fe82b927-71e7-5229-400d-8a5232a4baef",
        "NOMBRE": "DIVERTÍCULO CONGÉNITO DE LA VEJIGA"
    },
    {
        "id": "f98f5347-18cc-8ccc-2faf-c8a45e975020",
        "NOMBRE": "DIVERTÍCULO DE LA URETRA"
    },
    {
        "id": "685f4b49-4b52-da9a-d5c0-30c98c0ea9fb",
        "NOMBRE": "DIVERTÍCULO DE LA VEJIGA"
    },
    {
        "id": "2513ec50-f79a-da73-50e3-7e379c5be44a",
        "NOMBRE": "DIVERTÍCULO DE MECKEL"
    },
    {
        "id": "d6900ebb-34be-f32d-4602-3326fb202b58",
        "NOMBRE": "DIVERTÍCULO DEL APÉNDICE"
    },
    {
        "id": "e2f25031-bd00-a7b0-d431-07012c973efa",
        "NOMBRE": "DIVERTÍCULO DEL ESÓFAGO"
    },
    {
        "id": "93d4abb9-1c2b-5f4c-7af3-ce8c75d3aa70",
        "NOMBRE": "DIVERTÍCULO DEL ESÓFAGO, ADQUIRIDO"
    },
    {
        "id": "7b9bc717-57ac-fdc1-ecf4-b0a07b40dcb2",
        "NOMBRE": "DIVERTÍCULO FARÍNGEO"
    },
    {
        "id": "1e56abf3-e633-299c-2119-d42ddc63dc1c",
        "NOMBRE": "DIVERTÍCULO GÁSTRICO"
    },
    {
        "id": "2a0d86ab-d400-3480-2f76-17f659e1d63e",
        "NOMBRE": "DOLICOCEFALIA"
    },
    {
        "id": "1a7cf6b3-6151-28b2-09fd-46bf8e48682e",
        "NOMBRE": "DOLOR ABDOMINAL LOCALIZADO EN PARTE SUPERIOR"
    },
    {
        "id": "3696a51d-2fbb-c6ea-a4b5-51c7ddd4ae67",
        "NOMBRE": "DOLOR ABDOMINAL Y PÉLVICO"
    },
    {
        "id": "fca1d9fa-6fd6-9142-86e1-5a54ec2887d6",
        "NOMBRE": "DOLOR AGUDO"
    },
    {
        "id": "53651b58-7d97-e27b-d084-002381d8b806",
        "NOMBRE": "DOLOR ASOCIADO CON LA MICCIÓN"
    },
    {
        "id": "dddcb749-483b-c693-6b6c-e9cee61e6ce6",
        "NOMBRE": "DOLOR CRÓNICO INTRATABLE"
    },
    {
        "id": "da47883c-c69f-3687-acc2-8c207cb57b14",
        "NOMBRE": "DOLOR DE GARGANTA"
    },
    {
        "id": "aba55e60-5ab8-11f6-57e7-673ba59f166d",
        "NOMBRE": "DOLOR DE GARGANTA Y EN EL PECHO"
    },
    {
        "id": "9a093979-000a-fdef-32aa-b51736036723",
        "NOMBRE": "DOLOR EN ARTICULACIÓN"
    },
    {
        "id": "e8780116-f425-83a9-9130-3628528785a8",
        "NOMBRE": "DOLOR EN EL PECHO AL RESPIRAR"
    },
    {
        "id": "f1f60d10-71a2-3422-d86a-1484d3aac3e0",
        "NOMBRE": "DOLOR EN EL PECHO, NO ESPECIFICADO"
    },
    {
        "id": "99e697f3-4b92-6f0e-3e6d-289c6fc41e84",
        "NOMBRE": "DOLOR EN LA COLUMNA DORSAL"
    },
    {
        "id": "ec097c14-b428-a167-289b-30a97d314ad6",
        "NOMBRE": "DOLOR EN MIEMBRO"
    },
    {
        "id": "9c50cca5-7ea6-5fee-ebd4-10dc7e3af366",
        "NOMBRE": "DOLOR FACIAL ATÍPICO"
    },
    {
        "id": "6bbd8c1b-2554-411b-f671-277ec436643f",
        "NOMBRE": "DOLOR INTERMENSTRUAL"
    },
    {
        "id": "bfdce34c-73ba-8a99-59fa-901d0045f173",
        "NOMBRE": "DOLOR LOCALIZADO EN OTRAS PARTES INFERIORES DEL ABDOMEN"
    },
    {
        "id": "5925e333-d759-2338-4778-4ae2b21b8c41",
        "NOMBRE": "DOLOR OCULAR"
    },
    {
        "id": "f293272e-034b-681a-c28b-33fac3e04e4f",
        "NOMBRE": "DOLOR PÉLVICO Y PERINEAL"
    },
    {
        "id": "fbf594f0-c325-ca1e-d058-a7755408da48",
        "NOMBRE": "DOLOR PRECORDIAL"
    },
    {
        "id": "a69e2f53-8d8e-50bc-c304-4f2e38c6753e",
        "NOMBRE": "DOLOR Y OTRAS AFECCIONES RELACIONADAS CON LOS ÓRGANOS GENITALES FEMENINOS Y CON EL CICLO MENSTRUAL"
    },
    {
        "id": "d812ac91-e1e7-dae3-a763-bd6eeaa63821",
        "NOMBRE": "DOLOR, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "7acfd394-abf0-f4ea-143c-f21dd6e8804c",
        "NOMBRE": "DOLOR, NO ESPECIFICADO"
    },
    {
        "id": "d85fe529-df1b-0b06-e140-6f1a36919225",
        "NOMBRE": "DONANTE DE CORAZÓN"
    },
    {
        "id": "14ee5c86-006f-0bd7-b5e3-2f9d8d7c3afa",
        "NOMBRE": "DONANTE DE CÓRNEA"
    },
    {
        "id": "30d518b1-99f6-81f0-699f-0d749bed2bc1",
        "NOMBRE": "DONANTE DE HÍGADO"
    },
    {
        "id": "0422efb5-b9b4-299a-a61a-972f56fe1b02",
        "NOMBRE": "DONANTE DE HUESO"
    },
    {
        "id": "db4dfc14-9afb-6a44-cc56-779fe9e047b1",
        "NOMBRE": "DONANTE DE MÉDULA ÓSEA"
    },
    {
        "id": "410f10d5-6b7f-2fa3-9bf2-d55944515d5b",
        "NOMBRE": "DONANTE DE ÓRGANO O TEJIDO NO ESPECIFICADO"
    },
    {
        "id": "909855a5-be7e-1b56-6f62-103c67eb32ee",
        "NOMBRE": "DONANTE DE OTROS ÓRGANOS O TEJIDOS"
    },
    {
        "id": "80c2718f-6d07-03f2-a50b-bed9f890beb2",
        "NOMBRE": "DONANTE DE PIEL"
    },
    {
        "id": "f2018f9c-609c-66fc-ab85-3391facd7bec",
        "NOMBRE": "DONANTE DE RIÑÓN"
    },
    {
        "id": "ef7f7289-9e84-c5ad-1846-23cd15b8c48e",
        "NOMBRE": "DONANTE DE SANGRE"
    },
    {
        "id": "a20f1e21-31a4-2fe1-0be0-85539d144652",
        "NOMBRE": "DONANTES DE ÓRGANOS Y TEJIDOS"
    },
    {
        "id": "59443639-94d6-c90f-1164-b94cda10ae36",
        "NOMBRE": "DORSALGIA"
    },
    {
        "id": "07aab2ab-e393-7829-25f5-966fe57b52ad",
        "NOMBRE": "DORSALGIA, NO ESPECIFICADA"
    },
    {
        "id": "052b9d23-a73d-5628-876b-85ca6ebe13b3",
        "NOMBRE": "DORSOPATÍA DEFORMANTE, NO ESPECIFICADA"
    },
    {
        "id": "7a19e4bd-51da-544a-372c-2a5f47f19735",
        "NOMBRE": "DORSOPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "7d9a50ff-2a25-9dc3-63f6-e43cfeccab86",
        "NOMBRE": "DRACONTIASIS"
    },
    {
        "id": "cab8dd7f-5d8a-d1c4-d886-4f3f1cc2fdab",
        "NOMBRE": "DUODENITIS"
    },
    {
        "id": "253df9ce-4d40-b37a-9f2d-042505af909b",
        "NOMBRE": "DUPLICACIÓN DE LA VAGINA"
    },
    {
        "id": "1d8a50db-eeb5-b495-ffab-21cff6da665c",
        "NOMBRE": "DUPLICACIÓN DEL INTESTINO"
    },
    {
        "id": "1ca44a0e-8b0b-a1fe-d53c-0a9b55f3a2d8",
        "NOMBRE": "DUPLICACIÓN DEL URÉTER"
    },
    {
        "id": "486363e4-87a9-d863-8a52-b5074e9c3999",
        "NOMBRE": "DUPLICACIÓN DEL ÚTERO CON DUPLICACIÓN DEL CUELLO UTERINO Y DE LA VAGINA"
    },
    {
        "id": "9316ca9c-5855-4bb6-bf35-2bdcc798ddf6",
        "NOMBRE": "DUPLICACIONES CON OTROS REORDENAMIENTOS COMPLEJOS"
    },
    {
        "id": "91899436-c7ae-f4cd-cd5d-594f816cac88",
        "NOMBRE": "DUPLICACIONES VISIBLES SOLO EN LA PROMETAFASE"
    },
    {
        "id": "cea9392d-d7dc-9503-f3da-c54e64bb2007",
        "NOMBRE": "ECLAMPSIA"
    },
    {
        "id": "601840c2-aa42-5f3f-c68c-2c4947409028",
        "NOMBRE": "ECLAMPSIA DURANTE EL TRABAJO DE PARTO"
    },
    {
        "id": "8d40ef07-7dfb-1f9e-bb4a-45472aa88087",
        "NOMBRE": "ECLAMPSIA EN EL EMBARAZO"
    },
    {
        "id": "1c7b6f7b-cca0-ff33-9f41-8485bc94c196",
        "NOMBRE": "ECLAMPSIA EN EL PUERPERIO"
    },
    {
        "id": "c54cd33f-b359-7907-173d-d174b15fce11",
        "NOMBRE": "ECLAMPSIA, EN PERÍODO NO ESPECIFICADO"
    },
    {
        "id": "fa902761-9f65-976a-a524-629caf3baca3",
        "NOMBRE": "ECTASIA DE CONDUCTO MAMARIO"
    },
    {
        "id": "4bef2d6d-ecd0-769c-7c0f-1b76acf300c9",
        "NOMBRE": "ECTOPIA TESTICULAR"
    },
    {
        "id": "23a89083-5551-a10c-76f9-1220cef7a073",
        "NOMBRE": "ECTROPIÓN CONGÉNITO"
    },
    {
        "id": "5d842f2d-fe8d-d381-31c5-a4bb9045cd18",
        "NOMBRE": "ECTROPIÓN DEL PÁRPADO"
    },
    {
        "id": "f558bf67-7617-1ee4-4e90-b18b3dd93076",
        "NOMBRE": "ECZEMA HERPÉTICO"
    },
    {
        "id": "03f836e2-766f-fa9c-6650-a9b362633f71",
        "NOMBRE": "EDEMA ANGIONEURÓTICO"
    },
    {
        "id": "fff7ca26-d57f-9fa9-fdad-993a617a5470",
        "NOMBRE": "EDEMA CEREBRAL"
    },
    {
        "id": "ef941d5c-47f9-31f9-1c5a-0124355cd5d8",
        "NOMBRE": "EDEMA CEREBRAL DEBIDO A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "4a11467b-eedc-b442-e49f-38216d95976f",
        "NOMBRE": "EDEMA CEREBRAL TRAUMÁTICO"
    },
    {
        "id": "b7325088-8067-b2fe-9879-9fa8acf05445",
        "NOMBRE": "EDEMA DE LARINGE"
    },
    {
        "id": "ab5ff123-2da7-9600-a878-3eba9aa8fe84",
        "NOMBRE": "EDEMA GENERALIZADO"
    },
    {
        "id": "6094e26f-00c1-650c-e389-44d38155ae1c",
        "NOMBRE": "EDEMA GESTACIONAL"
    },
    {
        "id": "88b1e1c8-59ea-8251-17f9-913d534b3807",
        "NOMBRE": "EDEMA GESTACIONAL CON PROTEINURIA"
    },
    {
        "id": "65f8d67c-7586-b544-e3dc-2ce540d3e07c",
        "NOMBRE": "EDEMA LOCALIZADO"
    },
    {
        "id": "69f81f42-0fd8-ea85-3d61-ef287ac695e5",
        "NOMBRE": "EDEMA POR CALOR"
    },
    {
        "id": "11c8eb9d-57e7-9bf4-0015-936ca326c9a9",
        "NOMBRE": "EDEMA PULMONAR"
    },
    {
        "id": "747739fb-802e-47c8-f652-5beac458927f",
        "NOMBRE": "EDEMA PULMONAR AGUDO DEBIDO A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"
    },
    {
        "id": "329b7aa7-afbe-787d-b9ec-e9b545183a46",
        "NOMBRE": "EDEMA Y PROTEINURIA GESTACIONALES [INDUCIDOS POR EL EMBARAZO] SIN HIPERTENSIÓN"
    },
    {
        "id": "15e9bd01-09f0-0e2f-4f00-a401a1db5e97",
        "NOMBRE": "EDEMA, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "9a282fa4-637c-1e6c-834d-e7c096f93182",
        "NOMBRE": "EDEMA, NO ESPECIFICADO"
    },
    {
        "id": "4c43f17c-37d4-8e45-e5d2-96a356bf76a2",
        "NOMBRE": "EFECTO ADVERSO NO ESPECIFICADO"
    },
    {
        "id": "fa806404-d0ff-9828-5aa7-778c48a539e4",
        "NOMBRE": "EFECTO ADVERSO NO ESPECIFICADO DE DROGA O MEDICAMENTO"
    },
    {
        "id": "127319dd-8f30-b72b-238a-4cef15370be2",
        "NOMBRE": "EFECTO DE LA PRESIÓN DEL AIRE Y DEL AGUA, NO ESPECIFICADO"
    },
    {
        "id": "966920e5-8af7-d418-ed4f-4994dd4c2529",
        "NOMBRE": "EFECTO DE LA REDUCCIÓN DE LA TEMPERATURA, NO ESPECIFICADO"
    },
    {
        "id": "c3b5aa09-af40-0ee5-fd70-f1a1237883a9",
        "NOMBRE": "EFECTO DEL CALOR Y DE LA LUZ, NO ESPECIFICADO"
    },
    {
        "id": "5083ad44-6b4b-1462-cecd-63b9b1f86bd6",
        "NOMBRE": "EFECTO TÓXICO DE ÁCIDOS CORROSIVOS Y SUSTANCIAS ÁCIDAS SIMILARES"
    },
    {
        "id": "683a3ea4-c53b-9972-8925-05b02b8b0fe8",
        "NOMBRE": "EFECTO TÓXICO DE AFLATOXINA Y OTRAS MICOTOXINAS CONTAMINANTES DE ALIMENTOS"
    },
    {
        "id": "e1258e79-462c-4ef3-184e-10ba749d2f20",
        "NOMBRE": "EFECTO TÓXICO DE ÁLCALIS CÁUSTICOS Y SUSTANCIAS ALCALINAS SIMILARES"
    },
    {
        "id": "77b5d156-6de0-9399-1192-166d08a4d7e5",
        "NOMBRE": "EFECTO TÓXICO DE ALIMENTOS MARINOS NO ESPECIFICADOS"
    },
    {
        "id": "ccd0e6bc-698e-7ade-1c75-2823e80be649",
        "NOMBRE": "EFECTO TÓXICO DE BAYAS INGERIDAS"
    },
    {
        "id": "9a5f819a-4a1b-ba4e-1638-84a042ec7917",
        "NOMBRE": "EFECTO TÓXICO DE CETONAS"
    },
    {
        "id": "f0cef41c-5ce6-69f4-fe55-370b2e608667",
        "NOMBRE": "EFECTO TÓXICO DE CLOROFLUOROCARBUROS"
    },
    {
        "id": "ae843f8c-b02b-9596-3c7d-a20c31d9f1c1",
        "NOMBRE": "EFECTO TÓXICO DE DERIVADOS HALOGENADOS DE HIDROCARBUROS ALIFÁTICOS Y AROMÁTICOS, NO ESPECIFICADOS"
    },
    {
        "id": "32a3f5bc-7307-03dc-aaca-4a29162e285c",
        "NOMBRE": "EFECTO TÓXICO DE DETERGENTES Y JABONES"
    },
    {
        "id": "d93a8057-7eef-1c91-11e4-0f8974fd7eed",
        "NOMBRE": "EFECTO TÓXICO DE DISOLVENTES ORGÁNICOS"
    },
    {
        "id": "06f1f18b-fa15-1bf1-a475-33a8e557e326",
        "NOMBRE": "EFECTO TÓXICO DE DISOLVENTES ORGÁNICOS, NO ESPECIFICADOS"
    },
    {
        "id": "e9b362b9-3d3d-5539-9b7a-ff36b1390f4c",
        "NOMBRE": "EFECTO TÓXICO DE FENOL Y HOMÓLOGOS DEL FENOL"
    },
    {
        "id": "badc501a-479f-f4b8-7c0c-8ed5c4554f43",
        "NOMBRE": "EFECTO TÓXICO DE GASES, HUMOS Y VAPORES NO ESPECIFICADOS"
    },
    {
        "id": "a6440a35-ace6-40d3-44f9-c9ec3fa691b1",
        "NOMBRE": "EFECTO TÓXICO DE GLICOLES"
    },
    {
        "id": "b38a475f-f18e-8e03-5b72-b3763a8685ec",
        "NOMBRE": "EFECTO TÓXICO DE HERBICIDAS Y FUNGICIDAS"
    },
    {
        "id": "de28a45d-7c48-ffe3-5bc2-092985e1382e",
        "NOMBRE": "EFECTO TÓXICO DE HOMÓLOGOS DEL BENCENO"
    },
    {
        "id": "ddd79f6e-43ac-b592-1fbf-9d42d8ebf9e7",
        "NOMBRE": "EFECTO TÓXICO DE HONGOS INGERIDOS"
    },
    {
        "id": "3dfeb348-4363-0043-fc35-025ae79aca4b",
        "NOMBRE": "EFECTO TÓXICO DE INSECTICIDAS HALOGENADOS"
    },
    {
        "id": "5efa7be3-22ce-a3b7-fe22-c9c6028d6e58",
        "NOMBRE": "EFECTO TÓXICO DE INSECTICIDAS ORGANOFOSFORADOS Y CARBAMATOS"
    },
    {
        "id": "6c3fde20-eb61-bbe1-e8ff-756e2f2a087a",
        "NOMBRE": "EFECTO TÓXICO DE LA ESTRICNINA Y SUS SALES"
    },
    {
        "id": "bd297fa1-4e74-e0e4-fac3-12387ba97236",
        "NOMBRE": "EFECTO TÓXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFÁTICOS Y AROMÁTICOS"
    },
    {
        "id": "bb4846d1-c9f1-cee7-6d8f-12ea35e4f899",
        "NOMBRE": "EFECTO TÓXICO DE METAL, NO ESPECIFICADO"
    },
    {
        "id": "78a83afb-014d-18e6-6505-b635a63ea5a9",
        "NOMBRE": "EFECTO TÓXICO DE METALES"
    },
    {
        "id": "b7766585-dcb4-505a-c284-0f6b33366930",
        "NOMBRE": "EFECTO TÓXICO DE NITRODERIVADOS Y AMINODERIVADOS DEL BENCENO Y SUS HOMÓLOGOS"
    },
    {
        "id": "407d1d75-5f41-4913-0759-2b62ff84390d",
        "NOMBRE": "EFECTO TÓXICO DE NITROGLICERINA Y OTROS ÁCIDOS Y ÉSTERES NÍTRICOS"
    },
    {
        "id": "a278de97-054f-c006-60cf-c314b97adc7a",
        "NOMBRE": "EFECTO TÓXICO DE OTRA(S) (PARTES DE) PLANTA(S) INGERIDA(S)"
    },
    {
        "id": "6cfaa03b-ddb2-8398-22c0-6416ce4df2a2",
        "NOMBRE": "EFECTO TÓXICO DE OTRAS SUSTANCIAS ESPECIFICADAS"
    },
    {
        "id": "f6a959f8-331a-9b58-7b06-13d738d49851",
        "NOMBRE": "EFECTO TÓXICO DE OTRAS SUSTANCIAS INORGÁNICAS"
    },
    {
        "id": "3dc04334-ed37-10c3-3706-19082e7449ef",
        "NOMBRE": "EFECTO TÓXICO DE OTRAS SUSTANCIAS INORGÁNICAS, ESPECIFICADAS"
    },
    {
        "id": "0a9ac1d1-df0c-1ee6-3e2b-88140c7cd98c",
        "NOMBRE": "EFECTO TÓXICO DE OTRAS SUSTANCIAS NOCIVAS ESPECIFICADAS INGERIDAS COMO ALIMENTO"
    },
    {
        "id": "577042c3-c72b-090d-79c7-4c5de4de79cd",
        "NOMBRE": "EFECTO TÓXICO DE OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO"
    },
    {
        "id": "44d49d57-0434-834f-0b45-4755db8f6fda",
        "NOMBRE": "EFECTO TÓXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "1a316986-556f-f7d7-b21f-877f520c311f",
        "NOMBRE": "EFECTO TÓXICO DE OTROS ALCOHOLES"
    },
    {
        "id": "6206125f-a789-7e4a-7806-91c92c8f99a7",
        "NOMBRE": "EFECTO TÓXICO DE OTROS ALIMENTOS MARINOS"
    },
    {
        "id": "4dc48615-dac6-2ce5-e554-a14cbdf9d17b",
        "NOMBRE": "EFECTO TÓXICO DE OTROS COMPUESTOS ORGÁNICOS CORROSIVOS"
    },
    {
        "id": "0e057027-ea01-e3db-3ea5-fbc2f6bededb",
        "NOMBRE": "EFECTO TÓXICO DE OTROS DERIVADOS HALOGENADOS DE HIDROCARBUROS ALIFÁTICOS"
    },
    {
        "id": "aeb8dd94-7aad-d2a7-ae86-f01ec62e1cde",
        "NOMBRE": "EFECTO TÓXICO DE OTROS DERIVADOS HALOGENADOS DE HIDROCARBUROS AROMÁTICOS"
    },
    {
        "id": "396bca38-d8b3-d50e-62a5-68024859f155",
        "NOMBRE": "EFECTO TÓXICO DE OTROS DISOLVENTES ORGÁNICOS"
    },
    {
        "id": "49971da4-36ef-8a4b-3f88-c7722fbc6e4c",
        "NOMBRE": "EFECTO TÓXICO DE OTROS GASES, HUMOS Y VAPORES"
    },
    {
        "id": "a44e3449-a88d-da13-ece1-49f392a0f06b",
        "NOMBRE": "EFECTO TÓXICO DE OTROS GASES, HUMOS Y VAPORES ESPECIFICADOS"
    },
    {
        "id": "1bb570c2-7822-4dfa-2106-43feeb18800c",
        "NOMBRE": "EFECTO TÓXICO DE OTROS INSECTICIDAS"
    },
    {
        "id": "d0461ba0-97de-becd-115b-4443eae478c5",
        "NOMBRE": "EFECTO TÓXICO DE OTROS METALES"
    },
    {
        "id": "c1961a36-0f40-03a4-8c24-466364b78862",
        "NOMBRE": "EFECTO TÓXICO DE OTROS PLAGUICIDAS"
    },
    {
        "id": "5eaf58e6-04e8-f976-252e-ebce5dfd1605",
        "NOMBRE": "EFECTO TÓXICO DE ÓXIDOS DE NITRÓGENO"
    },
    {
        "id": "a0aa17af-4c16-2560-85bb-b21300cb8d5d",
        "NOMBRE": "EFECTO TÓXICO DE PINTURAS Y COLORANTES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "10d681b2-064b-3ab9-e122-e5da31ed6d41",
        "NOMBRE": "EFECTO TÓXICO DE PLAGUICIDA, NO ESPECIFICADO"
    },
    {
        "id": "b93eaaa3-3186-ee46-6e29-795b005d9412",
        "NOMBRE": "EFECTO TÓXICO DE PLAGUICIDAS (PESTICIDAS)"
    },
    {
        "id": "d52cb53b-401e-1191-c017-32b90c8c0faa",
        "NOMBRE": "EFECTO TÓXICO DE PRODUCTOS DEL PETRÓLEO"
    },
    {
        "id": "812a46ae-45b9-9183-97bd-c6f4e4c586d3",
        "NOMBRE": "EFECTO TÓXICO DE RODENTICIDAS"
    },
    {
        "id": "dd0d68c4-16b5-512e-4783-216887124d84",
        "NOMBRE": "EFECTO TÓXICO DE SUSTANCIA CORROSIVA, NO ESPECIFICADA"
    },
    {
        "id": "89f3933b-2c00-5312-a90f-3ac1ec219ae2",
        "NOMBRE": "EFECTO TÓXICO DE SUSTANCIA INORGÁNICA, NO ESPECIFICADA"
    },
    {
        "id": "4f9d6fc5-c85d-6990-04e7-07f53c937008",
        "NOMBRE": "EFECTO TÓXICO DE SUSTANCIA NO ESPECIFICADA"
    },
    {
        "id": "466d7574-2772-142a-38de-9c52f7cfe496",
        "NOMBRE": "EFECTO TÓXICO DE SUSTANCIA NOCIVA INGERIDA COMO ALIMENTO, NO ESPECIFICADA"
    },
    {
        "id": "8e2ec1fe-2efc-6e8f-27ae-1cf2adb35bd4",
        "NOMBRE": "EFECTO TÓXICO DE SUSTANCIAS CORROSIVAS"
    },
    {
        "id": "1aaaeafa-307b-dd87-534c-2e2013454012",
        "NOMBRE": "EFECTO TÓXICO DE SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTOS MARINOS"
    },
    {
        "id": "c5164357-f537-8828-7576-481413e900dc",
        "NOMBRE": "EFECTO TÓXICO DEL ÁCIDO CIANHÍDRICO"
    },
    {
        "id": "c1f7efb5-75df-21ad-772f-bd7708d674a4",
        "NOMBRE": "EFECTO TÓXICO DEL ALCOHOL"
    },
    {
        "id": "7268541e-471f-899d-4366-f19fedb83703",
        "NOMBRE": "EFECTO TÓXICO DEL ALCOHOL, NO ESPECIFICADO"
    },
    {
        "id": "2e59321c-14cc-cb60-7c6f-ebaaa5835c0d",
        "NOMBRE": "EFECTO TÓXICO DEL ARSÉNICO Y SUS COMPUESTOS"
    },
    {
        "id": "ed1aefce-f1c1-f3b1-45d6-0c701204279b",
        "NOMBRE": "EFECTO TÓXICO DEL BENCENO"
    },
    {
        "id": "6de8d33a-32e2-c988-b9f4-3c291796dc5b",
        "NOMBRE": "EFECTO TÓXICO DEL BERILIO Y SUS COMPUESTOS"
    },
    {
        "id": "78831902-3f08-12e0-43e5-93663ab852af",
        "NOMBRE": "EFECTO TÓXICO DEL BISULFURO DE CARBONO"
    },
    {
        "id": "85d75842-ce35-49ce-df88-600294f3db0e",
        "NOMBRE": "EFECTO TÓXICO DEL CADMIO Y SUS COMPUESTOS"
    },
    {
        "id": "d45770da-db28-ce78-ba8a-009b2b535ffe",
        "NOMBRE": "EFECTO TÓXICO DEL CIANURO"
    },
    {
        "id": "d290d759-e1aa-75ba-4a52-328cef9e316f",
        "NOMBRE": "EFECTO TÓXICO DEL CLORO GASEOSO"
    },
    {
        "id": "2847129b-b028-4d0d-cc2d-ecb2b7e6feb3",
        "NOMBRE": "EFECTO TÓXICO DEL CLOROFORMO"
    },
    {
        "id": "3019f864-c59e-2a26-c748-e905ba2b31c2",
        "NOMBRE": "EFECTO TÓXICO DEL COBRE Y SUS COMPUESTOS"
    },
    {
        "id": "a1d3d8b9-2d49-fc63-df0f-1ccc121952bc",
        "NOMBRE": "EFECTO TÓXICO DEL CONTACTO CON ANIMAL VENENOSO NO ESPECIFICADO"
    },
    {
        "id": "dcfae421-4ff7-71a7-2485-4d105ff07247",
        "NOMBRE": "EFECTO TÓXICO DEL CONTACTO CON ANIMALES VENENOSOS"
    },
    {
        "id": "5d0e6d93-10f0-3462-e2e0-e63464d08e31",
        "NOMBRE": "EFECTO TÓXICO DEL CONTACTO CON OTROS ANIMALES MARINOS"
    },
    {
        "id": "3a9c5e1e-ef54-47ae-1144-6dbf6fa941f8",
        "NOMBRE": "EFECTO TÓXICO DEL CONTACTO CON PECES"
    },
    {
        "id": "99aa3681-d5b0-8ea7-678c-c0c7faefdcf4",
        "NOMBRE": "EFECTO TÓXICO DEL CROMO Y SUS COMPUESTOS"
    },
    {
        "id": "c6e6ba30-055c-7212-f58d-fdb330af0840",
        "NOMBRE": "EFECTO TÓXICO DEL DICLOROETANO"
    },
    {
        "id": "abcf26ae-d603-7948-dd2e-576701d90e39",
        "NOMBRE": "EFECTO TÓXICO DEL DIÓXIDO DE CARBONO"
    },
    {
        "id": "b6150e7e-0432-a6fc-fca8-49da399b34e4",
        "NOMBRE": "EFECTO TÓXICO DEL DIÓXIDO DE SULFURO"
    },
    {
        "id": "e905bdfd-621e-450d-850c-cf2b061daf2d",
        "NOMBRE": "EFECTO TÓXICO DEL ESTAÑO Y SUS COMPUESTOS"
    },
    {
        "id": "2bcdbaed-6a42-e05b-3f00-59f045f58a13",
        "NOMBRE": "EFECTO TÓXICO DEL ETANOL"
    },
    {
        "id": "90f2446e-0b29-bde4-9cb2-88f5e9fad647",
        "NOMBRE": "EFECTO TÓXICO DEL FORMALDEHÍDO"
    },
    {
        "id": "45850b25-1080-5d28-2759-bca081415396",
        "NOMBRE": "EFECTO TÓXICO DEL FÓSFORO Y SUS COMPUESTOS"
    },
    {
        "id": "c341d4ef-5890-082a-5c10-3eb04470b96e",
        "NOMBRE": "EFECTO TÓXICO DEL GAS DE FLÚOR Y FLUORURO DE HIDRÓGENO"
    },
    {
        "id": "cd89255c-74fa-852e-4f9d-f7e3d76ad9a0",
        "NOMBRE": "EFECTO TÓXICO DEL GAS LACRIMÓGENO"
    },
    {
        "id": "140f81fd-a6e0-b60a-f49a-908ecf50d6e2",
        "NOMBRE": "EFECTO TÓXICO DEL LICOR DE ALCOHOL INSUFICIENTEMENTE DESTILADO"
    },
    {
        "id": "e021dcb3-0ba7-ee45-f43b-17cedaa7e934",
        "NOMBRE": "EFECTO TÓXICO DEL MANGANESO Y SUS COMPUESTOS"
    },
    {
        "id": "a0ec904a-5ef6-024c-3083-bdeebeb82295",
        "NOMBRE": "EFECTO TÓXICO DEL MERCURIO Y SUS COMPUESTOS"
    },
    {
        "id": "5ebed4c3-52d9-bf36-712d-639e9f48b7c2",
        "NOMBRE": "EFECTO TÓXICO DEL METANOL"
    },
    {
        "id": "9fa0b2ac-a4bb-22ab-bb52-1701fcc11296",
        "NOMBRE": "EFECTO TÓXICO DEL MONÓXIDO DE CARBONO"
    },
    {
        "id": "cd901aef-3e96-01ce-98cb-25b79ababba1",
        "NOMBRE": "EFECTO TÓXICO DEL PLOMO Y SUS COMPUESTOS"
    },
    {
        "id": "88a90e14-57da-019d-4e7d-10fe0aab1668",
        "NOMBRE": "EFECTO TÓXICO DEL PROPANOL-2"
    },
    {
        "id": "eb715fb4-f355-6fb4-871d-476cd259238d",
        "NOMBRE": "EFECTO TÓXICO DEL SULFURO DE HIDRÓGENO"
    },
    {
        "id": "6cb98ccc-ea28-8a18-a22f-fdc5793ed2a7",
        "NOMBRE": "EFECTO TÓXICO DEL TABACO Y LA NICOTINA"
    },
    {
        "id": "6d214e2a-5d65-d72b-cd33-41f3cbb2ee76",
        "NOMBRE": "EFECTO TÓXICO DEL TETRACLOROETILENO"
    },
    {
        "id": "19a794d4-b1aa-4a1d-b4b5-8512773c3b10",
        "NOMBRE": "EFECTO TÓXICO DEL TETRACLORURO DE CARBONO"
    },
    {
        "id": "b7ddda7b-b661-1fa5-7737-1210eafca4e5",
        "NOMBRE": "EFECTO TÓXICO DEL TRICLOROETILENO"
    },
    {
        "id": "3cb41b30-a413-f5cf-a75f-e7b469c886bd",
        "NOMBRE": "EFECTO TÓXICO DEL VENENO DE ARAÑAS"
    },
    {
        "id": "e5f284d0-9846-5375-8102-889b2bf78827",
        "NOMBRE": "EFECTO TÓXICO DEL VENENO DE ESCORPIÓN"
    },
    {
        "id": "4dde57ca-dc34-bbf9-e3c5-f8a42eac8d3e",
        "NOMBRE": "EFECTO TÓXICO DEL VENENO DE OTROS ARTRÓPODOS"
    },
    {
        "id": "3748017a-3114-fa7e-68e7-454bfa9b421b",
        "NOMBRE": "EFECTO TÓXICO DEL VENENO DE OTROS REPTILES"
    },
    {
        "id": "a427d3a2-1074-2195-6afe-a9d78f2ff71f",
        "NOMBRE": "EFECTO TÓXICO DEL VENENO DE SERPIENTE"
    },
    {
        "id": "84da3244-5f0c-a53b-ec3b-011266dc381b",
        "NOMBRE": "EFECTO TÓXICO DEL ZINC Y SUS COMPUESTOS"
    },
    {
        "id": "e5820b9e-6b67-c6f8-1210-8d775c7821cc",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTE SISTÉMICO PRIMARIO NO ESPECIFICADO"
    },
    {
        "id": "9c228c91-db10-8c7e-7a78-a4638bc5f125",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES ACIDIFICANTES Y ALCALINIZANTES"
    },
    {
        "id": "b1727fff-2516-ac63-8ae4-613675ad5402",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES ANTICOLINESTERASA"
    },
    {
        "id": "7a8086d7-343c-2953-0e50-c7ef09214f21",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES BLOQUEADORES NEURO-ADRENÉRGICOS QUE ACTÚAN CENTRALMENTE, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "3e6e3c20-aa0f-6e1e-47fe-7a3acd958bcd",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES ELECTROLÍTICOS, CALÓRICOS Y DEL EQUILIBRIO HÍDRICO"
    },
    {
        "id": "1e71d70e-0b19-1a59-c00d-af10ed21b9bc",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES INMUNOSUPRESORES"
    },
    {
        "id": "0655552d-8adf-cef9-387a-3834fff71a44",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES QUE ACTÚAN PRIMARIAMENTE SOBRE LOS MÚSCULOS LISOS Y ESTRIADOS Y SOBRE EL SISTEMA RESPIRATORIO"
    },
    {
        "id": "1e687451-7c7e-cc53-c0f0-28c72528fb79",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN EL METABOLISMO DEL ÁCIDO ÚRICO"
    },
    {
        "id": "ea0c87f5-9ea5-d4d5-86c1-e49b12276562",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN LA CALCIFICACIÓN"
    },
    {
        "id": "16287d30-c8fa-eb80-8b9a-44a244fa124d",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE EL EQUILIBRIO HÍDRICO Y EL METABOLISMO MINERAL Y DEL ÁCIDO ÚRICO"
    },
    {
        "id": "0f93288e-b3eb-5d5b-69e3-7fcf2c2e0e49",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE EL SISTEMA CARDIOVASCULAR"
    },
    {
        "id": "d7559473-1611-ba1d-d822-5ff8a2133b57",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE EL SISTEMA GASTROINTESTINAL"
    },
    {
        "id": "381e8f69-6350-4216-5e0e-3848c24f7783",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE LOS CONSTITUYENTES DE LA SANGRE"
    },
    {
        "id": "295f667b-31b1-a131-c040-6cd6c4f5ba99",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES SISTÉMICOS PRIMARIOS"
    },
    {
        "id": "a52d2e01-c6b3-aa3b-149d-e8a204d0dd2c",
        "NOMBRE": "EFECTOS ADVERSOS DE AGENTES TÓPICOS QUE AFECTAN PRIMARIAMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS, Y DROGAS OFTALMOLÓGICAS, OTORRINOLARINGOLÓGICAS Y DENTALES"
    },
    {
        "id": "f0110b45-0088-632b-0a31-ebe50840756f",
        "NOMBRE": "EFECTOS ADVERSOS DE AGONISTAS [ESTIMULANTES] PREDOMINANTEMENTE ALFA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "acf9444a-4f6f-39da-decc-c35609ae55ea",
        "NOMBRE": "EFECTOS ADVERSOS DE AGONISTAS [ESTIMULANTES] PREDOMINANTEMENTE BETA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "f4f38302-2379-c245-57ba-ff1dd1c639af",
        "NOMBRE": "EFECTOS ADVERSOS DE AMINOGLUCÓSIDOS"
    },
    {
        "id": "94e48780-854a-0bf7-ab05-475cb050061b",
        "NOMBRE": "EFECTOS ADVERSOS DE ANALÉPTICOS"
    },
    {
        "id": "824e9a05-3650-6837-fe13-3a621e02e3aa",
        "NOMBRE": "EFECTOS ADVERSOS DE ANDRÓGENOS Y CONGÉNERES ANABÓLICOS"
    },
    {
        "id": "ab3f7af8-2a27-feec-8794-d34140b22874",
        "NOMBRE": "EFECTOS ADVERSOS DE ANESTÉSICOS NO ESPECIFICADOS"
    },
    {
        "id": "266853f4-5bf6-7a71-5af7-b77407602340",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTAGONISTAS [BLOQUEADORES] ALFA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "322e49b5-f801-315b-5d4a-328ee20d0074",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTAGONISTAS [BLOQUEADORES] BETA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "6187fef0-9670-e433-73df-eb1a1f382807",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTAGONISTAS DE ANTICOAGULANTES, VITAMINA K Y OTROS COAGULANTES"
    },
    {
        "id": "06fe9bfc-e0b6-a174-9ef6-8b98505e72d2",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTAGONISTAS DE OPIÁCEOS"
    },
    {
        "id": "3a307a32-44c6-5c60-0e78-198de8902e92",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIALÉRGICOS Y ANTIEMÉTICOS"
    },
    {
        "id": "eb6b1b74-fd30-099b-2f1b-1d8826238de3",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIASMÁTICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "c3273e3f-b1ab-e988-5336-e0281d78128d",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIBIÓTICO SISTÉMICO NO ESPECIFICADO"
    },
    {
        "id": "3122eb0c-8947-3ea6-93ae-59b07c2cc999",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIBIÓTICOS ANTIMICÓTICOS USADOS SISTÉMICAMENTE"
    },
    {
        "id": "215d8a46-c010-1efa-b933-03325a17148f",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIBIÓTICOS SISTÉMICOS"
    },
    {
        "id": "d1c13a4a-97b5-42ed-2341-21f2a3db43b4",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTICOAGULANTES"
    },
    {
        "id": "f2bd94a4-299f-7d7d-24ab-9f7557f56f73",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTICONCEPTIVOS ORALES"
    },
    {
        "id": "e6517784-9b41-2909-a5ff-c135b0c0efcc",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIDEPRESIVOS INHIBIDORES DE LA MONOAMINOOXIDASA"
    },
    {
        "id": "b331a317-ff56-59a3-24e9-3e10efed5bc0",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIDEPRESIVOS TRICÍCLICOS Y TETRACÍCLICOS"
    },
    {
        "id": "e0338102-ae5a-3eb8-05fc-169afe7a8b23",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTÍDOTOS Y AGENTES QUELANTES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "f74b96d2-0292-6a9b-26ec-ac2a1076c734",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIGONADOTROPINAS, ANTIESTRÓGENOS Y ANTIANDRÓGENOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "0eb3155c-d2c1-c87c-dc19-9f1ad01c428c",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIHELMÍNTICOS"
    },
    {
        "id": "c50bb239-8b71-f70e-2af7-3383104ba772",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTÉMICOS NO ESPECIFICADOS"
    },
    {
        "id": "cfa49841-9a2b-ff8c-2e2f-04f8c68e3aa9",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIMETABOLITOS ANTINEOPLÁSICOS"
    },
    {
        "id": "3662a0a4-7d51-f10e-9434-52fcfc022e08",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIPRURIGINOSOS"
    },
    {
        "id": "d9c1368a-ed42-36ca-165e-46a5d293e6a2",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTIPSICÓTICOS Y NEUROLÉPTICOS FENOTIAZÍNICOS"
    },
    {
        "id": "9624a72c-0e4d-a5a7-d9a1-908660f60393",
        "NOMBRE": "EFECTOS ADVERSOS DE ANTITUSÍGENOS"
    },
    {
        "id": "c7397ed7-8feb-15b1-699a-d16f6510262c",
        "NOMBRE": "EFECTOS ADVERSOS DE ASTRINGENTES Y DETERGENTES LOCALES"
    },
    {
        "id": "f7630e8b-05b2-268d-9a78-45c0513ace0a",
        "NOMBRE": "EFECTOS ADVERSOS DE BARBITÚRICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "bb7e91fe-5b72-8324-b8d1-bc7a136e735d",
        "NOMBRE": "EFECTOS ADVERSOS DE BENZODIAZEPINAS"
    },
    {
        "id": "e8051abf-e2fe-fd0d-fa07-fad44a6770d0",
        "NOMBRE": "EFECTOS ADVERSOS DE BLOQUEADORES DE LOS RECEPTORES H2 DE HISTAMINA"
    },
    {
        "id": "0ec6444a-7b2c-eb54-7924-d0ffb5405676",
        "NOMBRE": "EFECTOS ADVERSOS DE BLOQUEADORES DEL CANAL DEL CALCIO"
    },
    {
        "id": "d90522e8-43c4-654b-093d-6ac874a46219",
        "NOMBRE": "EFECTOS ADVERSOS DE CEFALOSPORINAS Y OTROS ANTIBIÓTICOS BETA-LACTÁMICOS"
    },
    {
        "id": "ffde6da2-ac89-4635-16f5-3920e5d396f2",
        "NOMBRE": "EFECTOS ADVERSOS DE COMPUESTOS DEL BROMO"
    },
    {
        "id": "5d4bac58-3224-8873-e7ce-a3a7c21d4a5f",
        "NOMBRE": "EFECTOS ADVERSOS DE DEPRESORES DEL APETITO [ANORÉXICOS]"
    },
    {
        "id": "a84e6193-bb74-f448-6780-5707255fab38",
        "NOMBRE": "EFECTOS ADVERSOS DE DERIVADOS CLORALES"
    },
    {
        "id": "e24e6587-64d4-ad77-39d6-63ac94eb7097",
        "NOMBRE": "EFECTOS ADVERSOS DE DERIVADOS DE LA HIDANTOÍNA"
    },
    {
        "id": "9dc9ab26-5da4-28c5-8506-07f2d79119ee",
        "NOMBRE": "EFECTOS ADVERSOS DE DERIVADOS DEL ÁCIDO PROPIÓNICO"
    },
    {
        "id": "97a90fb1-efdf-ef68-6300-18ba086cf357",
        "NOMBRE": "EFECTOS ADVERSOS DE DESOXIBARBITÚRICOS"
    },
    {
        "id": "2ea3d160-ca66-95bc-653a-be9cd47cccad",
        "NOMBRE": "EFECTOS ADVERSOS DE DIGESTIVOS"
    },
    {
        "id": "ee32b7d1-00a3-2942-2501-5a889da01749",
        "NOMBRE": "EFECTOS ADVERSOS DE DISUASIVOS DEL ALCOHOL"
    },
    {
        "id": "a2064eab-091a-ddb4-13a9-2e89a24e35d8",
        "NOMBRE": "EFECTOS ADVERSOS DE DIURÉTICOS DE ASA [\"HIGH-CEILING\"]"
    },
    {
        "id": "e89f6126-4e87-6da4-3059-79faec5ec10d",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANALGÉSICAS, ANTIPIRÉTICAS Y ANTIINFLAMATORIAS"
    },
    {
        "id": "03002442-f81b-7ad0-38f1-3a5b86c215cf",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANALGÉSICAS, ANTIPIRÉTICAS Y ANTIINFLAMATORIAS NO ESPECIFICADAS"
    },
    {
        "id": "2fe7bd70-2a9b-7307-277e-e977943480c4",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIDIARREICAS"
    },
    {
        "id": "61ba5fcf-bc3d-c0af-7446-a6028d8f0e82",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIEPILÉPTICAS Y ANTIPARKINSONIANAS"
    },
    {
        "id": "d1c09fa5-40b0-a6ca-8df7-db570e18da7a",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIESPÁSTICAS"
    },
    {
        "id": "4fd83e3e-e23e-8d67-36e2-29983839db09",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIHIPERLIPIDÉMICAS Y ANTIARTERIOSCLERÓTICAS"
    },
    {
        "id": "166a0d12-b30f-a5a2-a3a4-40576865b253",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIMICOBACTERIANAS"
    },
    {
        "id": "af5b3639-a7bb-398c-3ff3-ccaa45f93ff1",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIMICÓTICAS, ANTIINFECCIOSAS, Y ANTIINFLAMATORIAS DE USO LOCAL, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ae47cff6-76bb-41c3-77ec-e5d488acfd59",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIPALÚDICAS Y AGENTES QUE ACTÚAN SOBRE OTROS PROTOZOARIOS DE LA SANGRE"
    },
    {
        "id": "33c568ed-af3f-5a2e-571c-9c4ee65980a3",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIPARKINSONIANAS"
    },
    {
        "id": "106eaaa6-91b0-9043-1760-a86fff890593",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTITIROIDEAS"
    },
    {
        "id": "59872b86-f06e-6a50-ba54-b5154f2aa49e",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTITROMBÓTICAS [INHIBIDORAS DE LA AGREGACIÓN PLAQUETARIA]"
    },
    {
        "id": "16258c95-acde-6786-7416-845cc4d28d0e",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIVARICOSAS, INCLUSIVE AGENTES ESCLEROSANTES"
    },
    {
        "id": "da4764ae-c1a2-b791-b0ea-270d57bee082",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS ANTIVIRALES"
    },
    {
        "id": "bd2d7a27-df21-3749-376d-f04c10a6cf3d",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS BLOQUEADORAS GANGLIONARES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b741652a-538b-d170-681b-12e97cb15c3c",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS CONTRA EL RESFRIADO COMÚN"
    },
    {
        "id": "7d7fe7c9-8974-be75-26b2-21bceab36abe",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS DENTALES, DE APLICACIÓN TÓPICA"
    },
    {
        "id": "2b6eaefd-21cf-0902-e314-0e2b638e2c5b",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS HIPOGLUCEMIANTES ORALES E INSULINA [ANTIDIABÉTICAS]"
    },
    {
        "id": "f9b19be7-2b69-09fa-f952-4b7e9bf79046",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS LIPOTRÓPICAS"
    },
    {
        "id": "ea14df8d-1134-a6e5-f477-d60a75e1882c",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS O MEDICAMENTOS NO ESPECIFICADOS"
    },
    {
        "id": "e00bbebc-ed39-55a7-4c3a-e8e2c77dd1ba",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS OXITÓCICAS"
    },
    {
        "id": "dbf33967-4e91-c124-315a-2ab3b0ea83fa",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS PSICOTRÓPICAS NO ESPECIFICADAS"
    },
    {
        "id": "ad07ea01-24f4-268c-7fe8-c1214aa11242",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS PSICOTRÓPICAS NO ESPECIFICADAS EN OTRA PARTE"
    },
    {
        "id": "935786d0-6fcc-9221-e22d-5dd1bf4982a4",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS QUE AFECTAN PRIMARIAMENTE EL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "d3c70150-c854-b633-4cb3-a4dc3c4ab039",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS SEDANTES, HIPNÓTICAS Y ANSIOLÍTICAS"
    },
    {
        "id": "44f3889a-8445-b73a-cee6-14ce4c334549",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS SEDANTES, HIPNÓTICAS Y ANSIOLÍTICAS NO ESPECIFICADAS"
    },
    {
        "id": "a36e4d94-a1df-dd3b-a0a5-f5a9c91957b5",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS TROMBOLÍTICAS"
    },
    {
        "id": "1e3f1f95-e75e-3f34-6230-96f0b91678f0",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS Y PREPARADOS OFTALMOLÓGICOS"
    },
    {
        "id": "ff198539-092e-1334-8502-7e3f7de1b832",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS Y PREPARADOS OTORRINOLARINGOLÓGICOS"
    },
    {
        "id": "416716a6-c147-9da7-ab70-5940b3a62326",
        "NOMBRE": "EFECTOS ADVERSOS DE DROGAS Y PREPARADOS QUERATOLÍTICOS, QUERATOPLÁSTICOS Y OTROS PARA EL TRATAMIENTO DEL CABELLO"
    },
    {
        "id": "daacca6a-02a2-0129-354e-1b7d9616c0dc",
        "NOMBRE": "EFECTOS ADVERSOS DE EMÉTICOS"
    },
    {
        "id": "83a7354c-3733-47d4-f5df-24a751e803bc",
        "NOMBRE": "EFECTOS ADVERSOS DE EMOLIENTES, DEMULCENTES Y PROTECTORES"
    },
    {
        "id": "24c6030b-9c66-b43a-5d80-d3b4c175ddd1",
        "NOMBRE": "EFECTOS ADVERSOS DE ENZIMAS NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3be67326-0443-0800-445f-12d4c637644a",
        "NOMBRE": "EFECTOS ADVERSOS DE ESTIMULANTE NO ESPECIFICADO DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "4c1eaf70-90a8-a52b-e2e6-78bdd1fb0e80",
        "NOMBRE": "EFECTOS ADVERSOS DE ESTIMULANTES DEL SISTEMA NERVIOSO CENTRAL, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "4d6eaf2d-6b3f-56ec-696c-3e1b77bfea53",
        "NOMBRE": "EFECTOS ADVERSOS DE EXCIPIENTES FARMACÉUTICOS"
    },
    {
        "id": "f7c09a4d-05a6-3e3b-8854-ad0097440856",
        "NOMBRE": "EFECTOS ADVERSOS DE EXPECTORANTES"
    },
    {
        "id": "e4e7882b-8506-06ee-4f50-4dff0eafc918",
        "NOMBRE": "EFECTOS ADVERSOS DE GASES ANESTÉSICOS LOCALES"
    },
    {
        "id": "083e799e-f473-df9f-1171-d6eee78c2650",
        "NOMBRE": "EFECTOS ADVERSOS DE GASES ANESTÉSICOS PARENTERALES"
    },
    {
        "id": "bc52ead8-db54-c76f-cba9-435e92080961",
        "NOMBRE": "EFECTOS ADVERSOS DE GASES ANESTÉSICOS POR INHALACIÓN"
    },
    {
        "id": "34ef51c1-160a-f9fb-a970-5c8961f735d3",
        "NOMBRE": "EFECTOS ADVERSOS DE GASES ANESTÉSICOS Y TERAPÉUTICOS"
    },
    {
        "id": "87cbdafc-535c-c6d9-6242-97a94c79eea6",
        "NOMBRE": "EFECTOS ADVERSOS DE GASES TERAPÉUTICOS"
    },
    {
        "id": "de9a17de-e07d-ab94-4ab5-81bca08ae420",
        "NOMBRE": "EFECTOS ADVERSOS DE GLUCOCORTICOIDES Y ANÁLOGOS SINTÉTICOS"
    },
    {
        "id": "c66380cf-3ed8-afe6-c260-804cf0055e43",
        "NOMBRE": "EFECTOS ADVERSOS DE GLUCÓSIDOS CARDIOTÓNICOS Y DROGAS DE ACCIÓN SIMILAR"
    },
    {
        "id": "b4316365-7493-5ce3-4873-7b64b1420650",
        "NOMBRE": "EFECTOS ADVERSOS DE HORMONAS TIROIDEAS Y SUSTITUTOS"
    },
    {
        "id": "b6fa7c77-09ce-c1f0-85b7-39ae998dfff7",
        "NOMBRE": "EFECTOS ADVERSOS DE HORMONAS Y SUS SUSTITUTOS SINTÉTICOS Y ANTAGONISTAS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "f297f3ed-6270-3d24-f18e-d58f6247a692",
        "NOMBRE": "EFECTOS ADVERSOS DE IMINOESTILBENOS"
    },
    {
        "id": "bcfaf5ef-7f50-7b7b-41fc-49766fdfc641",
        "NOMBRE": "EFECTOS ADVERSOS DE INHIBIDORES DE ENZIMA CONVERTIDORA ANGIOTENSINA"
    },
    {
        "id": "52281d7d-c20a-07f8-b077-bacea9f8774b",
        "NOMBRE": "EFECTOS ADVERSOS DE LA INMUNOGLOBULINA"
    },
    {
        "id": "abd2ab89-199c-d31b-121d-423c9a8c5931",
        "NOMBRE": "EFECTOS ADVERSOS DE LA VACUNA BCG"
    },
    {
        "id": "255ad747-0c22-fde5-b4b5-f637f069b3d6",
        "NOMBRE": "EFECTOS ADVERSOS DE LA VACUNA CONTRA EL CÓLERA"
    },
    {
        "id": "22118aa4-f860-7276-ca60-047581d462bb",
        "NOMBRE": "EFECTOS ADVERSOS DE LA VACUNA CONTRA EL TÉTANOS"
    },
    {
        "id": "0efba06f-4d3e-e097-f19c-2adf82911a8e",
        "NOMBRE": "EFECTOS ADVERSOS DE LA VACUNA CONTRA LA DIFTERIA"
    },
    {
        "id": "5927bd1c-2829-7d0d-5cf7-f8a964728f88",
        "NOMBRE": "EFECTOS ADVERSOS DE LA VACUNA CONTRA LA PESTE"
    },
    {
        "id": "98d32111-26c7-f08f-7f29-475a498a6a8a",
        "NOMBRE": "EFECTOS ADVERSOS DE LA VACUNA CONTRA TOS FERINA, INCLUSIVE COMBINACIONES CON COMPONENTE PERTUSIS"
    },
    {
        "id": "848243c9-c071-62f9-7a4e-e3359867093b",
        "NOMBRE": "EFECTOS ADVERSOS DE LA VACUNA TIFOIDEA Y PARATIFOIDEA"
    },
    {
        "id": "2c85ce53-1fc0-f077-016e-115558185b4b",
        "NOMBRE": "EFECTOS ADVERSOS DE LAXANTES ESTIMULANTES"
    },
    {
        "id": "dca0814b-133b-61f1-4da0-5d35afbabede",
        "NOMBRE": "EFECTOS ADVERSOS DE LAXANTES SALINOS Y OSMÓTICOS"
    },
    {
        "id": "4b41cc08-3b35-1e8a-1403-ead5bbfe8ed8",
        "NOMBRE": "EFECTOS ADVERSOS DE LOS ANTIRREUMÁTICOS"
    },
    {
        "id": "324901b1-b2ea-1f62-3608-075b55c05dc0",
        "NOMBRE": "EFECTOS ADVERSOS DE LOS BLOQUEADORES DE MINERALOCORTICOIDES [ANTAGONISTAS DE LA ALDOSTERONA]"
    },
    {
        "id": "d9fc98ea-826b-ef1a-8b8f-c0c0b438313d",
        "NOMBRE": "EFECTOS ADVERSOS DE LOS DERIVADOS DE LA BENZOTIADIAZINA"
    },
    {
        "id": "30e09d02-944d-383c-b8d1-624d2cd1b4ea",
        "NOMBRE": "EFECTOS ADVERSOS DE LOS DERIVADOS DEL 4-AMINOFENOL"
    },
    {
        "id": "0e3687c3-1506-7b0c-fc42-9dba8b9bd126",
        "NOMBRE": "EFECTOS ADVERSOS DE LOS INHIBIDORES DE LA ANHIDRASA CARBÓNICA"
    },
    {
        "id": "3f02cd14-f733-cdeb-cda6-f8659145ced2",
        "NOMBRE": "EFECTOS ADVERSOS DE LOS MACRÓLIDOS"
    },
    {
        "id": "cdac147c-6a1e-7481-d623-5d3d32738b6f",
        "NOMBRE": "EFECTOS ADVERSOS DE LOS SUSTITUTOS DEL PLASMA"
    },
    {
        "id": "3c01d661-7ede-39ab-806b-e9e6bc9153c3",
        "NOMBRE": "EFECTOS ADVERSOS DE MEDIOS DE CONTRASTE PARA RAYOS X"
    },
    {
        "id": "65abfdd8-466a-f9b4-4286-af874ff34bcd",
        "NOMBRE": "EFECTOS ADVERSOS DE METILXANTINAS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "28ac6f6f-a6f6-2e8f-16ff-d497c47fa633",
        "NOMBRE": "EFECTOS ADVERSOS DE MEZCLAS SEDANTES E HIPNÓTICAS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "f3b71d1b-2712-6f0c-7b6b-a3d72a0a4da4",
        "NOMBRE": "EFECTOS ADVERSOS DE MINERALOCORTICOIDES"
    },
    {
        "id": "1f7a3eab-9b88-1a78-cb7f-bc57868221ac",
        "NOMBRE": "EFECTOS ADVERSOS DE NEUROLÉPTICOS DE LA BUTIROFENONA Y TIOXANTINA"
    },
    {
        "id": "63a936fd-756f-5226-164d-788664a281ba",
        "NOMBRE": "EFECTOS ADVERSOS DE OPIÁCEOS Y ANALGÉSICOS RELACIONADOS"
    },
    {
        "id": "395ae7a9-c386-0896-402f-e60940460a03",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIARRÍTMICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f2a56421-4e3f-1422-7daa-d112d1987f85",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIHIPERTENCIVAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e4157e28-73d8-d36e-ccb3-61186ee7b81d",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIINFLAMATORIAS NO ESTEROIDES [DAINE]"
    },
    {
        "id": "5a2bfdc0-7023-8c53-9212-8ad9aa935042",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS ANTINEOPLÁSICAS"
    },
    {
        "id": "ae81945c-8a8e-454f-ab0b-a25c74d91c20",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIPROTOZOARIAS"
    },
    {
        "id": "8fe2daa1-14dd-8710-fce1-0ea62c525c68",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3791bc4b-0c66-314f-7d39-4388b16b6adc",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS SEDANTES, HIPNÓTICAS Y ANSIOLÍTICAS"
    },
    {
        "id": "cb760125-1fcc-de89-9c89-007ce03622d6",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS Y MEDICAMENTOS"
    },
    {
        "id": "472e58f1-aa74-e15b-8622-4e443719d78f",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS DROGAS Y MEDICAMENTOS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "c599a8a7-f21c-9f60-8a92-60ab60f3294f",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS HORMONAS ANTAGONISTAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "a8d9f00f-3a22-90c8-3dc1-f30a8af16959",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS HORMONAS Y SUS SUSTITUTOS SINTÉTICOS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "e55bf3a2-0492-ce2b-8561-99a9be52aec9",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS VACUNAS BACTERIANAS, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "a5a981c8-402c-d88f-8c0a-aae3e9f77359",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS VACUNAS Y SUSTANCIAS BIOLÓGICAS ESPECIFICADAS"
    },
    {
        "id": "fa7ab795-f648-5eb6-8309-e7014af1824b",
        "NOMBRE": "EFECTOS ADVERSOS DE OTRAS VACUNAS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "1189624a-580e-6cc0-a78a-a6b9cd1545e3",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES DIAGNÓSTICOS"
    },
    {
        "id": "b3603aa0-33cd-a48d-e0f3-f1d07161b135",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES QUE ACTÚAN PRIMARIAMENTE SOBRE EL SISTEMA RESPIRATORIO, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "bb82b58b-01d1-7ae2-0d25-25caf45e2549",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES QUE ACTÚAN PRIMARIAMENTE SOBRE LOS MÚSCULOS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "f5c3541b-20bb-3b8f-2b45-1225bcb9510f",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN EL SISTEMA GASTROINTESTINAL, NO ESPECIFICADOS"
    },
    {
        "id": "97cc7959-1b22-3923-68ad-3974900abde4",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN LOS CONSTITUYENTES DE LA SANGRE, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "8e97f8e2-395e-988b-4318-8c027028876a",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN PRIMARIAMENTE AL SISTEMA GASTROINTESTINAL"
    },
    {
        "id": "18a82a86-24ba-69a8-b4be-cf8f2bd1a980",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES SISTÉMICOS PRIMARIOS NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "0b0975cf-7748-fab9-7020-c3a8cc4ee2bf",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES TÓPICOS"
    },
    {
        "id": "76ba8ab1-e096-a267-d2bd-d03fff310c61",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS AGENTES TÓPICOS NO ESPECIFICADOS"
    },
    {
        "id": "16c50aed-4a31-8fdc-d986-04df93b81531",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ANALGÉSICOS Y ANTIPIRÉTICOS"
    },
    {
        "id": "0824be14-2151-4d18-8ac0-2e15abe106e2",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ANTIBIÓTICOS SISTÉMICOS"
    },
    {
        "id": "95457719-4a6a-2eb4-0020-072f152846b7",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ANTIDEPRESIVOS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "544464f9-0ad9-90c9-7bcb-6ab58a274711",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ANTIEPILÉPTICOS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "92a05290-13e9-e8c8-5520-f3f074fbe0e4",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTÉMICOS"
    },
    {
        "id": "bd10c765-dfa7-ee12-7360-c142f34b73a5",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ANTIINFECCIOSOS Y ANTIPARASTARIOS SISTÉMICOS ESPECIFICADOS"
    },
    {
        "id": "9fdf2944-c7ee-53fa-b72c-47789584e9a7",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ANTIPSICÓTICOS Y NEUROLÉPTICOS"
    },
    {
        "id": "14c6d241-a203-4812-5573-6075c1e53aa3",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS DIURÉTICOS"
    },
    {
        "id": "af862aa9-b0f8-f5ca-0904-6cb8fa480b2f",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ESTIMULANTES DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "b0606498-0c88-3029-85b6-9aaa034533d9",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS ESTRÓGENOS Y PROGESTÁGENOS"
    },
    {
        "id": "42368a41-2d56-ae2f-0991-c80180ee4856",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS GASES ANESTÉSICOS GENERALES, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "439c95db-46fd-cecd-8abc-87f0f4f8e135",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS LAXANTES"
    },
    {
        "id": "c25ab0be-14a7-20cb-5e3b-7ccebbcc2e60",
        "NOMBRE": "EFECTOS ADVERSOS DE OTROS PARASIMPATICOMIMÉTICOS [COLINÉRGICOS]"
    },
    {
        "id": "a897a729-a421-07ba-563d-b1d969340cac",
        "NOMBRE": "EFECTOS ADVERSOS DE OXAZOLIDINADIONAS"
    },
    {
        "id": "b03390e1-4a5b-73db-8090-0cb04e75e25d",
        "NOMBRE": "EFECTOS ADVERSOS DE PARALDEHÍDO"
    },
    {
        "id": "badf3f76-7f3c-eae6-dc2d-306a711ff80a",
        "NOMBRE": "EFECTOS ADVERSOS DE PARASIMPATICOLÍTICOS [ANTICOLINÉRGICOS Y ANTIMUSCARÍNICOS] Y ESPASMOLÍTICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "95706348-ea94-efab-6ff0-666f626ba2fd",
        "NOMBRE": "EFECTOS ADVERSOS DE PENICILINAS"
    },
    {
        "id": "a23b909b-0621-dd10-4aea-8e745497832d",
        "NOMBRE": "EFECTOS ADVERSOS DE PREPARACIONES CON HIERRO Y OTROS PREPARADOS CONTRA LA ANEMIA HIPOCRÓMICA"
    },
    {
        "id": "b6b017ae-d2fb-88ec-efda-77c98078e057",
        "NOMBRE": "EFECTOS ADVERSOS DE PRODUCTOS NATURALES ANTINEOPLÁSICOS"
    },
    {
        "id": "b0cb1ff1-0573-5dcc-2633-864537bfc8ff",
        "NOMBRE": "EFECTOS ADVERSOS DE PSICODISLÉPTICOS [ALUCINÓGENOS]"
    },
    {
        "id": "e31065be-0246-7639-cad7-dd68549d78f1",
        "NOMBRE": "EFECTOS ADVERSOS DE PSICOESTIMULANTES CON ABUSO POTENCIAL"
    },
    {
        "id": "6b003e4b-cdfb-9715-5163-93397f0579da",
        "NOMBRE": "EFECTOS ADVERSOS DE RELAJANTES DE LOS MÚSCULOS ESTRIADOS [AGENTES BLOQUEADORES NEUROMUSCULARES]"
    },
    {
        "id": "67cfee7c-091f-4ec3-fb24-60d6bc465caa",
        "NOMBRE": "EFECTOS ADVERSOS DE RIFAMICINAS"
    },
    {
        "id": "bba2bb74-80f3-c692-baeb-499020a29440",
        "NOMBRE": "EFECTOS ADVERSOS DE SALES MINERALES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6698b391-64c2-a872-76c7-fbeee5d04531",
        "NOMBRE": "EFECTOS ADVERSOS DE SALICILATOS"
    },
    {
        "id": "177598ac-3cec-cd56-cf9f-bc78537c6426",
        "NOMBRE": "EFECTOS ADVERSOS DE SANGRE NATURAL Y PRODUCTOS SANGUÍNEOS"
    },
    {
        "id": "9215d248-fb49-84ae-8edd-ffd640edfeae",
        "NOMBRE": "EFECTOS ADVERSOS DE SUCCINAMIDAS"
    },
    {
        "id": "69bc94cf-86e6-84d9-1ba6-4364d873d3b3",
        "NOMBRE": "EFECTOS ADVERSOS DE SULFONAMIDAS"
    },
    {
        "id": "d5644e7b-85fb-b810-5146-0505912a194e",
        "NOMBRE": "EFECTOS ADVERSOS DE TETRACICLINAS"
    },
    {
        "id": "b31f2493-fcae-67e3-9878-99c29d93e52b",
        "NOMBRE": "EFECTOS ADVERSOS DE VACUNAS ANTIPROTOZOARIAS"
    },
    {
        "id": "94755b6d-16ea-5257-9760-bcfbae7037d4",
        "NOMBRE": "EFECTOS ADVERSOS DE VACUNAS BACTERIANAS"
    },
    {
        "id": "65ba8d5f-4975-45e2-fca5-95637c61f13c",
        "NOMBRE": "EFECTOS ADVERSOS DE VACUNAS BACTERIANAS MIXTAS, EXCEPTO COMBINACIONES CON UN COMPONENTE PERTUSIS"
    },
    {
        "id": "b902d8bc-40ba-f326-1f53-dcc58fcbe8e0",
        "NOMBRE": "EFECTOS ADVERSOS DE VACUNAS CONTRA RICKETTSSIAS"
    },
    {
        "id": "bbd5e5b7-65ca-526c-6255-f37f58a9d4e2",
        "NOMBRE": "EFECTOS ADVERSOS DE VACUNAS O SUSTANCIAS BIOLÓGICAS NO ESPECIFICADAS"
    },
    {
        "id": "88908cf4-c82f-a3f5-2b47-0581d2801d95",
        "NOMBRE": "EFECTOS ADVERSOS DE VACUNAS VIRALES"
    },
    {
        "id": "c0ece5dc-ed43-51b7-eda0-d0913b6d4940",
        "NOMBRE": "EFECTOS ADVERSOS DE VASODILATADORES CORONARIOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "539525b9-4aa9-821f-0e75-7424c48fa219",
        "NOMBRE": "EFECTOS ADVERSOS DE VASODILATADORES PERIFÉRICOS"
    },
    {
        "id": "16df382f-2d2b-30a8-e23b-9d64bf511893",
        "NOMBRE": "EFECTOS ADVERSOS DE VITAMINA B12, ÁCIDO FÓLICO Y OTROS PREPARADOS CONTRA LA ANEMIA MEGALOBLÁSTICA"
    },
    {
        "id": "8715f6d7-dbb5-4321-5d8c-46e9e2a5f9ce",
        "NOMBRE": "EFECTOS ADVERSOS DE VITAMINAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "667ce100-46ff-bb32-161d-434062ec99de",
        "NOMBRE": "EFECTOS ADVERSOS DEL ÁCIDO VALPROICO"
    },
    {
        "id": "a21f47c2-91c0-13b9-156b-42c35fe082fd",
        "NOMBRE": "EFECTOS ADVERSOS DEL GRUPO DE CLORAMFENICOL"
    },
    {
        "id": "26b79e48-c475-9141-039f-cfc98aa68763",
        "NOMBRE": "EFECTOS ADVERSOS OTRAS DROGAS ANTIÁCIDAS E INHIBIDORAS DE LA SECRECIÓN GÁSTRICA"
    },
    {
        "id": "041d6298-af46-c3fc-ac40-73d341ab5239",
        "NOMBRE": "EFECTOS ADVERSOS OTRAS DROGAS QUE AFECTAN PRIMARIAMENTE EL SISTEMA NERVIOSO AUTÓNOMO, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "2eedc8fe-bec0-9347-b4f9-b518b4449223",
        "NOMBRE": "EFECTOS ADVERSOS OTROS AGENTES QUE AFECTAN PRIMARIAMENTE EL SISTEMA CARDIOVASCULAR, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "1b79b56d-3086-9736-5a31-cca90a781e2b",
        "NOMBRE": "EFECTOS ADVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "8afef828-f5b3-bbd5-cbef-7378d7026ef6",
        "NOMBRE": "EFECTOS DE LA CORRIENTE ELÉCTRICA"
    },
    {
        "id": "12ef6b46-177c-ac94-b0b7-b8a76b5db59f",
        "NOMBRE": "EFECTOS DE LA PRESIÓN DEL AIRE Y DE LA PRESIÓN DEL AGUA"
    },
    {
        "id": "3c282650-9e74-6436-e28d-68a06463b5e0",
        "NOMBRE": "EFECTOS DE LA SED"
    },
    {
        "id": "37a42165-bb07-94c8-48cf-b04a92119206",
        "NOMBRE": "EFECTOS DE LA VIBRACIÓN"
    },
    {
        "id": "a2641bb6-d597-aa31-9983-15112d3f0949",
        "NOMBRE": "EFECTOS DE LÍQUIDOS CON ALTA PRESIÓN"
    },
    {
        "id": "65663086-a35f-e4ec-1218-f32620bb9782",
        "NOMBRE": "EFECTOS DE OTRAS CAUSAS EXTERNAS"
    },
    {
        "id": "910bcb84-7345-5ddb-002c-f7a67010c99e",
        "NOMBRE": "EFECTOS DE OTRAS PRIVACIONES"
    },
    {
        "id": "a01ec41e-31d5-836b-d42b-f53065c15a04",
        "NOMBRE": "EFECTOS DE PRIVACIÓN, NO ESPECIFICADOS"
    },
    {
        "id": "6eb2f307-3ae6-4381-7d43-6d4255875362",
        "NOMBRE": "EFECTOS DEL CALOR Y DE LA LUZ"
    },
    {
        "id": "d48789d4-8d8a-3055-061e-3efc8061e885",
        "NOMBRE": "EFECTOS DEL HAMBRE"
    },
    {
        "id": "24fdf78d-40c7-cfab-a414-08a9bcfcb4a5",
        "NOMBRE": "EFECTOS DEL RAYO"
    },
    {
        "id": "b107a230-8dfa-a751-2bc3-dcb5a5668132",
        "NOMBRE": "EFECTOS DEL RUIDO SOBRE EL OÍDO INTERNO"
    },
    {
        "id": "581752bc-861d-23dd-fbad-e3b5a5f67b0d",
        "NOMBRE": "EFECTOS NO ESPECIFICADOS DE CAUSAS EXTERNAS"
    },
    {
        "id": "3c3c8381-6d4f-f2c1-1ac5-610819a3f5a0",
        "NOMBRE": "EFECTOS NO ESPECIFICADOS DE LA RADIACIÓN"
    },
    {
        "id": "77d97068-155b-c04c-c2d7-5cb2f53db911",
        "NOMBRE": "EFECTOS TÓXICOS DEL CONTACTO CON OTROS ANIMALES VENENOSOS"
    },
    {
        "id": "2c41cf0c-141d-9ac7-f5d4-b01b3cae2921",
        "NOMBRE": "EFÉLIDE"
    },
    {
        "id": "6318cdf1-7dd1-f41d-a80c-dfb5891a7871",
        "NOMBRE": "EJECUCIÓN LEGAL"
    },
    {
        "id": "45e710f8-82f5-4973-e485-22a81f40bd3f",
        "NOMBRE": "ELABORACIÓN DE SÍNTOMAS FÍSICOS POR CAUSAS PSICOLÓGICAS"
    },
    {
        "id": "aa09eb5e-ad99-a8d0-23d7-a03817fa19a0",
        "NOMBRE": "ELASTOSIS SERPIGINOSA PERFORANTE"
    },
    {
        "id": "7b1f844d-e8cb-8551-1cb3-adc1bc9ba898",
        "NOMBRE": "ELEVACIÓN DE LOS NIVELES DE DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS EN LA ORINA"
    },
    {
        "id": "98d17500-334d-c9d7-6cc0-c8d5de5315d7",
        "NOMBRE": "ELEVACIÓN DE LOS NIVELES DE TRANSAMINASAS O DESHIDROGENASA LÁCTICA [DHL]"
    },
    {
        "id": "9d736216-3d9e-3702-a9f3-f396998bfebf",
        "NOMBRE": "ELIPTOCITOSIS HEREDITARIA"
    },
    {
        "id": "e4f18123-9613-8bb2-9f66-e5f8f7b49ed6",
        "NOMBRE": "ELONGACIÓN HIPERTROFICA DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "06f8ed2c-6cb9-c283-f826-97a7b7bfcfd3",
        "NOMBRE": "EMBARAZO (AÚN) NO CONFIRMADO"
    },
    {
        "id": "9e491ece-3c02-d927-f027-869f00d607bd",
        "NOMBRE": "EMBARAZO ABDOMINAL"
    },
    {
        "id": "547123e8-eb64-bc87-358b-793af324fbda",
        "NOMBRE": "EMBARAZO CONFIRMADO"
    },
    {
        "id": "89ee4c66-b984-a010-ee80-96dc7df37c4e",
        "NOMBRE": "EMBARAZO CUÁDRUPLE"
    },
    {
        "id": "c81ce7db-3f2c-c0ab-3ff8-39edd59033e5",
        "NOMBRE": "EMBARAZO DOBLE"
    },
    {
        "id": "c486a996-382f-a701-aab8-86695795b9ac",
        "NOMBRE": "EMBARAZO ECTÓPICO"
    },
    {
        "id": "6d2b160b-29b8-fc19-e7c6-a1a9764e2cfb",
        "NOMBRE": "EMBARAZO ECTÓPICO, NO ESPECIFICADO"
    },
    {
        "id": "bab23120-7c09-6ab9-a269-e9f4258fae9b",
        "NOMBRE": "EMBARAZO MÚLTIPLE"
    },
    {
        "id": "ad95a9ba-dab2-d41f-3a72-39e113bfa7b8",
        "NOMBRE": "EMBARAZO MÚLTIPLE, NO ESPECIFICADO"
    },
    {
        "id": "02ffbe61-75c2-63a7-56a1-6b6dcf9e77f2",
        "NOMBRE": "EMBARAZO OVÁRICO"
    },
    {
        "id": "c6ce20e2-83a1-4a3e-f54c-af02f32ddc14",
        "NOMBRE": "EMBARAZO PROLONGADO"
    },
    {
        "id": "4cf08979-1eae-e1e2-7e39-5e21dbf91b8b",
        "NOMBRE": "EMBARAZO QUE CONTINÚA DESPUÉS DE LA MUERTE INTRAUTERINA DE UN FETO O MÁS"
    },
    {
        "id": "84bea5bd-cae3-c43e-570e-17f1d9d9ace8",
        "NOMBRE": "EMBARAZO QUE CONTINÚA DESPUÉS DEL ABORTO DE UN FETO O MÁS"
    },
    {
        "id": "efd70ae9-8447-d5c6-8fa3-4fa66b9fc371",
        "NOMBRE": "EMBARAZO TRIPLE"
    },
    {
        "id": "71fd1f3c-d601-02ea-f021-52a1bd99eefa",
        "NOMBRE": "EMBARAZO TUBÁRICO"
    },
    {
        "id": "0c6e54ab-9dd4-a908-905c-7379217c3769",
        "NOMBRE": "EMBOLIA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "1ea69d2f-77ec-c235-beed-492650fcde18",
        "NOMBRE": "EMBOLIA DE COÁGULO SANGUÍNEO, OBSTÉTRICA"
    },
    {
        "id": "a4c2a7b7-edc0-142d-dcef-1e884479cfba",
        "NOMBRE": "EMBOLIA DE LÍQUIDO AMNIÓTICO"
    },
    {
        "id": "db5c8279-8796-c44d-6672-323b4985a864",
        "NOMBRE": "EMBOLIA GASEOSA (TRAUMÁTICA)"
    },
    {
        "id": "e076d577-a5f7-3c4d-5b4c-4a84c98cff31",
        "NOMBRE": "EMBOLIA GASEOSA CONSECUTIVA A INFUSIÓN, TRANSFUSIÓN E INYECCIÓN TERAPÉUTICA"
    },
    {
        "id": "dcc9a017-71d6-3170-42d1-640fd8832780",
        "NOMBRE": "EMBOLIA GASEOSA, OBSTÉTRICA"
    },
    {
        "id": "a81d6d50-b5de-e8ce-acb9-353df1abe30e",
        "NOMBRE": "EMBOLIA GRASA (TRAUMÁTICA)"
    },
    {
        "id": "3fd4a0e5-25f2-0685-5926-4ad742b8bf22",
        "NOMBRE": "EMBOLIA OBSTÉTRICA"
    },
    {
        "id": "a2c9ef8b-dc3a-1f41-071e-703ccb7bfb2d",
        "NOMBRE": "EMBOLIA PULMONAR"
    },
    {
        "id": "86db9f1f-821a-31d2-a8a4-3ee164d499b4",
        "NOMBRE": "EMBOLIA PULMONAR CON MENCIÓN DE CORAZÓN PULMONAR AGUDO"
    },
    {
        "id": "8e121141-6ff3-ef7e-1464-03599c857cd5",
        "NOMBRE": "EMBOLIA PULMONAR SIN MENCIÓN DE CORAZÓN PULMONAR AGUDO"
    },
    {
        "id": "32f45f6b-082b-508d-3f82-e7dfb712dae8",
        "NOMBRE": "EMBOLIA SÉPTICA Y PIÉMICA, OBSTÉTRICA"
    },
    {
        "id": "45ef7098-9dc1-709d-0099-b8b7a9c28365",
        "NOMBRE": "EMBOLIA Y TROMBOSIS ARTERIALES"
    },
    {
        "id": "e3f430be-155a-573e-036a-d56b4ce47ae2",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE ARTERIA ILÍACA"
    },
    {
        "id": "38a507d3-fcb4-90a5-0bee-16109c5b02a1",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE ARTERIA NO ESPECIFICADA"
    },
    {
        "id": "bdd84198-22fe-f992-809b-8714ad59b4db",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS INFERIORES"
    },
    {
        "id": "55a839a1-3ad8-87ab-4e9b-43d930cada67",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS SUPERIORES"
    },
    {
        "id": "3b435776-a8f4-cc4d-6662-489ec4d613c7",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS, NO ESPECIFICADAS"
    },
    {
        "id": "c17202c4-dee3-36c2-cb3f-6f4e09b79b6e",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE LA AORTA ABDOMINAL"
    },
    {
        "id": "3f56e5c6-3c53-3a9d-aa54-8d7be0d45218",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE OTRAS ARTERIAS"
    },
    {
        "id": "af223447-533a-75bb-7489-125f094d21a0",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE OTRAS PORCIONES Y LAS NO ESPECIFICADAS DE LA AORTA"
    },
    {
        "id": "22fde834-b375-8509-193d-f9d31b039e83",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE OTRAS VENAS ESPECIFICADAS"
    },
    {
        "id": "72cb8086-33c4-2012-ad8b-ab4adeaf8e96",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE VENA CAVA"
    },
    {
        "id": "249874ea-3e9d-7f9c-ac60-81444c2348ff",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE VENA NO ESPECIFICADA"
    },
    {
        "id": "9128eaff-9b19-5b12-2139-331818fddd1f",
        "NOMBRE": "EMBOLIA Y TROMBOSIS DE VENA RENAL"
    },
    {
        "id": "558aec04-8d71-72ef-ae03-89e706df22c7",
        "NOMBRE": "ENANISMO TANATOFÓRICO"
    },
    {
        "id": "4ae3e192-8f4b-617c-a221-be200b6f5154",
        "NOMBRE": "ENANISMO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "4ea9b222-7080-6d7a-e936-93ab4b75ab42",
        "NOMBRE": "ENCEFALITIS AGUDA DISEMINADA"
    },
    {
        "id": "278ae90c-41dc-4346-76a9-95ce88de9a91",
        "NOMBRE": "ENCEFALITIS AUSTRALIANA"
    },
    {
        "id": "0a3251e1-7031-76ba-91b2-8a0c03469752",
        "NOMBRE": "ENCEFALITIS CENTROEUROPEA TRANSMITIDA POR GARRAPATAS"
    },
    {
        "id": "dc24d942-ddc7-8f9e-2f64-967d656e72bb",
        "NOMBRE": "ENCEFALITIS DE CALIFORNIA"
    },
    {
        "id": "8e58fb15-72e8-b469-c346-f81b99410b39",
        "NOMBRE": "ENCEFALITIS DE SAN LUIS"
    },
    {
        "id": "f3c92d45-529f-6e0d-03d8-f7d56bc3c9f4",
        "NOMBRE": "ENCEFALITIS DEBIDA A HERPES ZOSTER"
    },
    {
        "id": "ce4132a7-7eb5-e969-002f-260eaa96fd7a",
        "NOMBRE": "ENCEFALITIS DEBIDA A VARICELA"
    },
    {
        "id": "a9918a08-4a84-f909-6205-8ea8ec528f9f",
        "NOMBRE": "ENCEFALITIS DEL LEJANO ORIENTE TRANSMITIDA POR GARRAPATAS [ENCEFALITIS PRIMAVEROESTIVAL RUSA]"
    },
    {
        "id": "4521ed04-ce09-74a9-343d-c6bbc645a4e5",
        "NOMBRE": "ENCEFALITIS ENTEROVIRAL"
    },
    {
        "id": "451779e5-90da-3807-7167-610bbd6a1000",
        "NOMBRE": "ENCEFALITIS EQUINA DEL ESTE"
    },
    {
        "id": "df4b79bb-09d2-014f-d0be-e1b9b49ca587",
        "NOMBRE": "ENCEFALITIS EQUINA DEL OESTE"
    },
    {
        "id": "5621f773-7c65-dbdc-0af2-8d3c2c92560d",
        "NOMBRE": "ENCEFALITIS HERPÉTICA"
    },
    {
        "id": "29e4a6c8-154d-d142-3283-e47e17d4cf71",
        "NOMBRE": "ENCEFALITIS JAPONESA"
    },
    {
        "id": "bda9efdc-b60d-b2fe-ed50-29785c43926c",
        "NOMBRE": "ENCEFALITIS POR ADENOVIRUS"
    },
    {
        "id": "4dfb3036-f110-6f2e-eea8-89583906e311",
        "NOMBRE": "ENCEFALITIS POR PAROTIDITIS"
    },
    {
        "id": "91210f60-bca4-8dbd-5086-3b426fa9197c",
        "NOMBRE": "ENCEFALITIS VIRAL TRANSMITIDA POR ARTRÓPODOS, SIN OTRA ESPECIFCACIÓN"
    },
    {
        "id": "b9281723-9573-b742-9c1b-a910a7c57653",
        "NOMBRE": "ENCEFALITIS VIRAL TRANSMITIDA POR GARRAPATAS"
    },
    {
        "id": "65c5808e-ac57-d24c-6ee8-bdb25a0b5ace",
        "NOMBRE": "ENCEFALITIS VIRAL TRANSMITIDA POR GARRAPATAS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "a7433e5d-c66f-4d82-9f23-1be0ebee977c",
        "NOMBRE": "ENCEFALITIS VIRAL TRANSMITIDA POR MOSQUITOS"
    },
    {
        "id": "c5b4597c-9ce6-089c-725d-47c692ca9e4b",
        "NOMBRE": "ENCEFALITIS VIRAL TRANSMITIDA POR MOSQUITOS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "0f6c40e4-4145-a235-4a6e-53a112fa1844",
        "NOMBRE": "ENCEFALITIS VIRAL, NO ESPECIFICADA"
    },
    {
        "id": "120892ee-05db-5d23-8ba2-425c327b94e5",
        "NOMBRE": "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS"
    },
    {
        "id": "cb5a3f9a-727d-1fce-11d7-313356799ab6",
        "NOMBRE": "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8c14139d-5624-2366-b094-ff6cc8560494",
        "NOMBRE": "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a2784be9-1349-9bc9-677f-b25dbca13536",
        "NOMBRE": "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "921a3cf2-46f8-09c6-73cc-c134b213533c",
        "NOMBRE": "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e25cd773-b323-dacb-c07d-bafb969a04a8",
        "NOMBRE": "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "86037101-d69f-5326-0632-5ef2e48a0ca9",
        "NOMBRE": "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS, NO ESPECIFICADAS"
    },
    {
        "id": "0134a8b6-e0f0-9606-936b-7d6216afa87e",
        "NOMBRE": "ENCEFALOCELE"
    },
    {
        "id": "63a23bed-9bf4-8db0-ba99-a4314269874b",
        "NOMBRE": "ENCEFALOCELE DE OTROS SITIOS"
    },
    {
        "id": "2bee5d48-5803-ddfe-7d30-11a059da7f8b",
        "NOMBRE": "ENCEFALOCELE FRONTAL"
    },
    {
        "id": "e21cb90c-6560-c64d-d7d2-89ab1ff61026",
        "NOMBRE": "ENCEFALOCELE NASOFRONTAL"
    },
    {
        "id": "5ce2b5c2-de7a-179e-4eb0-afc7af69bff7",
        "NOMBRE": "ENCEFALOCELE OCCIPITAL"
    },
    {
        "id": "87220873-e250-54c3-11c2-64b0d5975394",
        "NOMBRE": "ENCEFALOCELE, NO ESPECIFICADO"
    },
    {
        "id": "e42a47be-9e96-6013-d28e-c28f3ef05f29",
        "NOMBRE": "ENCEFALOPATÍA DE WERNICKE"
    },
    {
        "id": "a5073278-8981-78b7-06df-34a060001c99",
        "NOMBRE": "ENCEFALOPATÍA HIPERTENSIVA"
    },
    {
        "id": "5d46ffa7-15c0-408a-5b84-b42d03f25b24",
        "NOMBRE": "ENCEFALOPATÍA HIPOXICO ISQUÉMICA DEL RECIÉN NACIDO"
    },
    {
        "id": "c0ed395f-fc65-1bfb-38e6-706587e8fc75",
        "NOMBRE": "ENCEFALOPATÍA NO ESPECIFICADA"
    },
    {
        "id": "824dd7e8-d20a-eea0-f7fa-baffd388f9b2",
        "NOMBRE": "ENCEFALOPATÍA TÓXICA"
    },
    {
        "id": "45261dc4-a15b-fb61-e697-19a13fddc7f5",
        "NOMBRE": "ENCONDROMATOSIS"
    },
    {
        "id": "2550bbb8-d1bd-6941-e22d-70dcd95a7435",
        "NOMBRE": "ENCOPRESIS NO ORGÁNICA"
    },
    {
        "id": "e3df35c8-3a51-f71b-c7ab-3daa01bc09b5",
        "NOMBRE": "ENCORDAMIENTO CONGÉNITO DEL PENE"
    },
    {
        "id": "b92a9e5d-dd70-e3a5-725b-71effa86ab29",
        "NOMBRE": "ENDOCARDITIS AGUDA Y SUBAGUDA"
    },
    {
        "id": "c88d4daf-6fa8-89c6-13c0-afb675fe0051",
        "NOMBRE": "ENDOCARDITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "576e0b6c-e1b0-878a-98b1-1febcf7ee420",
        "NOMBRE": "ENDOCARDITIS DEBIDA A CANDIDA"
    },
    {
        "id": "e9fc790f-9c36-9ee9-564e-7d148d9004fb",
        "NOMBRE": "ENDOCARDITIS INFECCIOSA AGUDA Y SUBAGUDA"
    },
    {
        "id": "9be0b3f3-ad3f-579f-a11c-795841878e0e",
        "NOMBRE": "ENDOCARDITIS REUMÁTICA AGUDA"
    },
    {
        "id": "929daad2-4c0d-da14-73a0-42f71beae1af",
        "NOMBRE": "ENDOCARDITIS Y TRASTORNOS VALVULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e8e8b329-2427-4e21-a357-3192186b4645",
        "NOMBRE": "ENDOCARDITIS, VÁLVULA NO ESPECIFICADA"
    },
    {
        "id": "af5cec77-2b50-7a7b-e115-20623386c4f8",
        "NOMBRE": "ENDOCARDITIS, VÁLVULA NO ESPECIFICADA, EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f163bfd0-867c-b99c-3f54-d9b3cd3ad228",
        "NOMBRE": "ENDOFTALMITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fcaa37c3-7bb3-5568-c4c8-9b110b8cd6d8",
        "NOMBRE": "ENDOFTALMITIS PURULENTA"
    },
    {
        "id": "97ea028e-d101-2599-f0b8-746bc9bb72ee",
        "NOMBRE": "ENDOMETRIOSIS"
    },
    {
        "id": "058e97b8-60bd-8c83-6dad-a273a5f35901",
        "NOMBRE": "ENDOMETRIOSIS DE LA TROMPA DE FALOPIO"
    },
    {
        "id": "dcd170a2-7304-e2c5-a0d4-2393b89d5e45",
        "NOMBRE": "ENDOMETRIOSIS DEL INTESTINO"
    },
    {
        "id": "b0b7e171-ec86-c393-cb81-d1d12357efd5",
        "NOMBRE": "ENDOMETRIOSIS DEL OVARIO"
    },
    {
        "id": "2525eaeb-1d82-fc43-bab1-550b66b3c2f3",
        "NOMBRE": "ENDOMETRIOSIS DEL PERITONEO PÉLVICO"
    },
    {
        "id": "c7592460-2085-11c6-3ce4-b1311c5bfc48",
        "NOMBRE": "ENDOMETRIOSIS DEL TABIQUE RECTOVAGINAL Y DE LA VAGINA"
    },
    {
        "id": "35bbc139-a290-8c1c-3eee-a4e5ffa204b6",
        "NOMBRE": "ENDOMETRIOSIS DEL ÚTERO"
    },
    {
        "id": "71c1b459-66d3-00e0-4648-6bb0219f1955",
        "NOMBRE": "ENDOMETRIOSIS EN CICATRIZ CUTÁNEA"
    },
    {
        "id": "2e95568d-decc-6522-4a70-37a1fe74fc35",
        "NOMBRE": "ENDOMETRIOSIS, NO ESPECIFICADA"
    },
    {
        "id": "a1868a5d-7294-3034-d47f-76970f21b602",
        "NOMBRE": "ENFERMEDAD ADHESIVA DEL OÍDO MEDIO"
    },
    {
        "id": "b20b7024-2cba-6769-6092-b5de676a968e",
        "NOMBRE": "ENFERMEDAD ALCOHÓLICA DEL HÍGADO"
    },
    {
        "id": "909002b0-6b47-afab-24aa-2b3b2732782f",
        "NOMBRE": "ENFERMEDAD ATEROSCLERÓTICA DEL CORAZÓN"
    },
    {
        "id": "44592410-1b12-f183-d542-6732cdbd37d8",
        "NOMBRE": "ENFERMEDAD CARDÍACA DEBIDA A MENINGOCOCO"
    },
    {
        "id": "8a187b5e-f145-6ba0-8f61-6e4684ca831f",
        "NOMBRE": "ENFERMEDAD CARDÍACA HIPERTENSIVA"
    },
    {
        "id": "a166ea56-3122-b6b3-6082-da96139345af",
        "NOMBRE": "ENFERMEDAD CARDÍACA HIPERTENSIVA CON INSUFICIENCIA CARDÍACA (CONGESTIVA)"
    },
    {
        "id": "f5c31669-be5d-341d-3247-93402ef3bf8b",
        "NOMBRE": "ENFERMEDAD CARDÍACA HIPERTENSIVA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "c78532c9-f438-1221-6fe1-d24fd07ca540",
        "NOMBRE": "ENFERMEDAD CARDÍACA HIPERTENSIVA SIN INSUFICIENCIA CARDÍACA (CONGESTIVA)"
    },
    {
        "id": "ccbe5818-7694-80c8-1ceb-d1cf141cb525",
        "NOMBRE": "ENFERMEDAD CARDÍACA, NO ESPECIFICADA"
    },
    {
        "id": "a1e7aba4-3c80-0a3a-1581-35cf384eac19",
        "NOMBRE": "ENFERMEDAD CARDIORRENAL HIPERTENSIVA"
    },
    {
        "id": "526d38e5-67e6-1814-1fd8-b91bbdee518c",
        "NOMBRE": "ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA CARDÍACA (CONGESTIVA)"
    },
    {
        "id": "dbc70c12-12ae-462f-08af-16026616ed2e",
        "NOMBRE": "ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA CARDÍACA (CONGESTIVA) E INSUFICIENCIA RENAL"
    },
    {
        "id": "31426063-16ee-d34c-6932-1b103162f3bd",
        "NOMBRE": "ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA RENAL"
    },
    {
        "id": "bee3f935-1e74-e622-312b-28951fab01b4",
        "NOMBRE": "ENFERMEDAD CARDIORRENAL HIPERTENSIVA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "076085ce-51bc-153b-1e1c-6a478db77f71",
        "NOMBRE": "ENFERMEDAD CARDIORRENAL HIPERTENSIVA, NO ESPECIFICADA"
    },
    {
        "id": "924f1ca8-da45-f6da-4a6e-e030fd00b7be",
        "NOMBRE": "ENFERMEDAD CARDIOVASCULAR ATEROSCLERÓTICA, ASÍ DESCRITA"
    },
    {
        "id": "079e2516-f724-1fef-9d97-1653e0b18ebe",
        "NOMBRE": "ENFERMEDAD CARDIOVASCULAR, NO ESPECIFICADA"
    },
    {
        "id": "941c65be-b387-203c-db33-6a61a22eb153",
        "NOMBRE": "ENFERMEDAD CELÍACA"
    },
    {
        "id": "ea25a257-9db4-f7cc-e1de-028e50814674",
        "NOMBRE": "ENFERMEDAD CEREBROVASCULAR, NO ESPECIFICADA"
    },
    {
        "id": "0290eeb2-060a-6af8-da31-369bbdeecccb",
        "NOMBRE": "ENFERMEDAD CIFOSCOLIÓTICA DEL CORAZÓN"
    },
    {
        "id": "4fdd49c7-a4a9-cb38-6c42-1d6a6f4b381a",
        "NOMBRE": "ENFERMEDAD CRÓNICA DE LAS AMÍGDALAS Y DE LAS ADENOIDES, NO ESPECIFICADA"
    },
    {
        "id": "d8e124d5-01c9-a16d-d2a7-0c82d31b0985",
        "NOMBRE": "ENFERMEDAD DE ALZHEIMER"
    },
    {
        "id": "cd943548-8764-4218-b119-e60272499be2",
        "NOMBRE": "ENFERMEDAD DE ALZHEIMER DE COMIENZO TARDÍO"
    },
    {
        "id": "098662e0-5bfe-fce6-f149-566e07a731a5",
        "NOMBRE": "ENFERMEDAD DE ALZHEIMER DE COMIENZO TEMPRANO"
    },
    {
        "id": "1c57c5b1-64cf-057c-c5e2-70de35a763df",
        "NOMBRE": "ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA"
    },
    {
        "id": "cf348914-c16a-acaf-a2af-674a91c5c209",
        "NOMBRE": "ENFERMEDAD DE BEHCET"
    },
    {
        "id": "77d22203-210f-f554-b19f-8387b91530a0",
        "NOMBRE": "ENFERMEDAD DE CADENA PESADA ALFA"
    },
    {
        "id": "1887a897-26a3-dae7-da7b-748c6e709f0d",
        "NOMBRE": "ENFERMEDAD DE CHAGAS"
    },
    {
        "id": "2b362bef-6699-34bf-eacc-4ce5ae342fd3",
        "NOMBRE": "ENFERMEDAD DE CHAGAS (CRÓNICA) QUE AFECTA AL CORAZÓN"
    },
    {
        "id": "6b86bc53-9a76-9539-11e3-4def9091327a",
        "NOMBRE": "ENFERMEDAD DE CHAGAS (CRÓNICA) QUE AFECTA AL SISTEMA DIGESTIVO"
    },
    {
        "id": "42c65363-df75-478f-bc98-84327f381e26",
        "NOMBRE": "ENFERMEDAD DE CHAGAS (CRÓNICA) QUE AFECTA AL SISTEMA NERVIOSO"
    },
    {
        "id": "a88074ac-a535-c869-925e-73049b7ce741",
        "NOMBRE": "ENFERMEDAD DE CHAGAS (CRÓNICA) QUE AFECTA OTROS ÓRGANOS"
    },
    {
        "id": "3ea5b494-7132-c074-c4fc-3628ef2fa878",
        "NOMBRE": "ENFERMEDAD DE CHAGAS AGUDA QUE AFECTA AL CORAZÓN"
    },
    {
        "id": "2f96decb-e0ec-214f-88fa-2638960f9a7a",
        "NOMBRE": "ENFERMEDAD DE CHAGAS AGUDA QUE NO AFECTA AL CORAZÓN"
    },
    {
        "id": "cef6af33-12ae-285f-d17c-f0ec444262f4",
        "NOMBRE": "ENFERMEDAD DE CREUTZFELDT-JAKOB"
    },
    {
        "id": "575b5323-6672-0ac8-cf2d-f982fbb9074e",
        "NOMBRE": "ENFERMEDAD DE CROHN [ENTERITIS REGIONAL]"
    },
    {
        "id": "6ea3e861-38b2-0b23-ff25-649365658294",
        "NOMBRE": "ENFERMEDAD DE CROHN DEL INTESTINO DELGADO"
    },
    {
        "id": "bbb1a498-6316-2c13-2e0b-fa3d26704a82",
        "NOMBRE": "ENFERMEDAD DE CROHN DEL INTESTINO GRUESO"
    },
    {
        "id": "2372f41f-efdc-bc8f-008c-7a3bf6b6fad9",
        "NOMBRE": "ENFERMEDAD DE CROHN, NO ESPECIFICADA"
    },
    {
        "id": "8b44a65b-eb54-4b07-cadc-29030bcb5d64",
        "NOMBRE": "ENFERMEDAD DE CUSHING DEPENDIENTE DE LA HIPÓFISIS"
    },
    {
        "id": "d0310c9d-3efe-86b8-e62e-2dd1e7753366",
        "NOMBRE": "ENFERMEDAD DE GLÁNDULA SALIVAL, NO ESPECIFICADA"
    },
    {
        "id": "eca6f8fc-6425-c87e-6f05-387b8ee5f6f4",
        "NOMBRE": "ENFERMEDAD DE HALLERVORDEN-SPATZ"
    },
    {
        "id": "ebe6e010-0e46-682a-8a98-5de2088d9ffc",
        "NOMBRE": "ENFERMEDAD DE HIRSCHSPRUNG"
    },
    {
        "id": "22abb80d-a96f-f2e5-0340-291fe12a0639",
        "NOMBRE": "ENFERMEDAD DE HUNTINGTON"
    },
    {
        "id": "133f5976-9fe8-bfad-8473-b2fd495fe424",
        "NOMBRE": "ENFERMEDAD DE KASCHIN-BECK"
    },
    {
        "id": "449875e8-ee04-f9d2-480a-5c7ba03842f8",
        "NOMBRE": "ENFERMEDAD DE KIENBÖCK DEL ADULTO"
    },
    {
        "id": "17c0dc62-9aa4-f12b-a5cd-398c094f81b1",
        "NOMBRE": "ENFERMEDAD DE LA GLÁNDULA DE BARTHOLIN, NO ESPECIFICADA"
    },
    {
        "id": "904c0227-44bd-9260-2f0f-d879490d19c8",
        "NOMBRE": "ENFERMEDAD DE LA LENGUA, NO ESPECIFICADA"
    },
    {
        "id": "8b67ecae-ef6f-0f7c-acab-c6596c9033c7",
        "NOMBRE": "ENFERMEDAD DE LA MÉDULA ESPINAL, NO ESPECIFICADA"
    },
    {
        "id": "3a190fe5-59c6-56f4-157e-7b768d0e1538",
        "NOMBRE": "ENFERMEDAD DE LA ORINA EN JARABE DE ARCE"
    },
    {
        "id": "0adc55e4-6bd1-4d0e-1faf-b5d1c7c21611",
        "NOMBRE": "ENFERMEDAD DE LA SANGRE Y DE LOS ÓRGANOS HEMATOPOYÉTICOS, NO ESPECIFICADA"
    },
    {
        "id": "75620fe1-747f-437f-b5f8-f75f9194a199",
        "NOMBRE": "ENFERMEDAD DE LA SELVA KYASANUR"
    },
    {
        "id": "191a53d4-b4b7-3a11-8e8a-ed4f86a8b3d9",
        "NOMBRE": "ENFERMEDAD DE LA VÁLVULA TRICÚSPIDE, NO ESPECIFICADA"
    },
    {
        "id": "9a63d8c3-6432-ac64-a74e-74bae44d036a",
        "NOMBRE": "ENFERMEDAD DE LA VESÍCULA BILIAR, NO ESPECIFICADA"
    },
    {
        "id": "19cb21b4-6c6f-73d1-143b-88d02fbc9118",
        "NOMBRE": "ENFERMEDAD DE LAS VÍAS AÉREAS DEBIDA A OTROS POLVOS ORGÁNICOS ESPECÍFICOS"
    },
    {
        "id": "a029e69c-69a3-f250-05bb-14dcf9fc8b8a",
        "NOMBRE": "ENFERMEDAD DE LAS VÍAS BILIARES, NO ESPECIFICADA"
    },
    {
        "id": "80caa2a9-5ff3-7c10-9e4c-cc12248608fe",
        "NOMBRE": "ENFERMEDAD DE LAS VÍAS RESPIRATORIAS SUPERIORES, NO ESPECIFICADA"
    },
    {
        "id": "0a7780e6-3bbb-b571-4df9-244209e0e0b7",
        "NOMBRE": "ENFERMEDAD DE LOS HUESOS POR ALUMINIO"
    },
    {
        "id": "d361184d-f82d-479e-a441-530633167517",
        "NOMBRE": "ENFERMEDAD DE LOS LEGIONARIOS"
    },
    {
        "id": "cfb08cc6-06d5-6449-a5f7-48d656a3296f",
        "NOMBRE": "ENFERMEDAD DE LOS LEGIONARIOS NO NEUMÓNICA (FIEBRE DE PONTIAC)"
    },
    {
        "id": "854af44f-05aa-3337-237c-fbce4f891576",
        "NOMBRE": "ENFERMEDAD DE LOS MAXILARES, NO ESPECIFICADA"
    },
    {
        "id": "577f85c6-f36e-6f4f-2f59-8b2050c725b0",
        "NOMBRE": "ENFERMEDAD DE LOS TRABAJADORES DEL LINO"
    },
    {
        "id": "387192e8-576b-5839-0847-1ba371684143",
        "NOMBRE": "ENFERMEDAD DE LOS VASOS CAPILARES, NO ESPECIFICADA"
    },
    {
        "id": "88905cd1-3540-2dfb-580b-1b1700a73a50",
        "NOMBRE": "ENFERMEDAD DE LOS VASOS PULMONARES, NO ESPECIFICADA"
    },
    {
        "id": "7e668971-1f4a-26db-14ca-4a639d122ad4",
        "NOMBRE": "ENFERMEDAD DE LYME"
    },
    {
        "id": "2e2ac992-6fc2-a976-bc96-bf4bc8a76aa6",
        "NOMBRE": "ENFERMEDAD DE MENIERE"
    },
    {
        "id": "8260b21d-8dee-4517-daa1-756c14afa68c",
        "NOMBRE": "ENFERMEDAD DE MOYAMOYA"
    },
    {
        "id": "a5ee3c5a-afce-4021-5cd2-b5c0e109035f",
        "NOMBRE": "ENFERMEDAD DE MÚLTIPLES VÁLVULAS, NO ESPECIFICADA"
    },
    {
        "id": "254e4e5e-722c-e93b-2b8b-2d3944e30469",
        "NOMBRE": "ENFERMEDAD DE PAGET DE LOS HUESOS [OSTEÍTIS DEFORMANTE]"
    },
    {
        "id": "dd47db03-e7b8-fae0-d5f5-eaf21c8a8bba",
        "NOMBRE": "ENFERMEDAD DE PAGET DE OTROS HUESOS"
    },
    {
        "id": "798b49e3-1e78-f846-e03e-24b83cc0c5b6",
        "NOMBRE": "ENFERMEDAD DE PAGET DEL CRÁNEO"
    },
    {
        "id": "ec40b253-5c9b-2d7e-67f7-6df11750a139",
        "NOMBRE": "ENFERMEDAD DE PARKINSON"
    },
    {
        "id": "3db04445-41fa-87cf-21c3-1e85bf26cbff",
        "NOMBRE": "ENFERMEDAD DE REFSUM"
    },
    {
        "id": "40a9a746-2e61-b652-8f02-2e194ce9c5aa",
        "NOMBRE": "ENFERMEDAD DE REITER"
    },
    {
        "id": "ea62361a-26f5-0870-16da-7182114ce59e",
        "NOMBRE": "ENFERMEDAD DE SÉZARY"
    },
    {
        "id": "074b5d33-7005-c993-d04f-c804f9430023",
        "NOMBRE": "ENFERMEDAD DE STILL DE COMIENZO EN EL ADULTO"
    },
    {
        "id": "a4c8a8dd-25db-b712-639c-d61252c2e5ee",
        "NOMBRE": "ENFERMEDAD DE TRANSMISIÓN SEXUAL NO ESPECIFICADA"
    },
    {
        "id": "f9252616-5e07-998b-7494-7717ed4a9007",
        "NOMBRE": "ENFERMEDAD DE VON WILLEBRAND"
    },
    {
        "id": "fd8f7d41-ab89-3c79-b108-d547ad75503c",
        "NOMBRE": "ENFERMEDAD DEBIDA A ANQUILOSTOMAS, NO ESPECIFICADA"
    },
    {
        "id": "68b6a65a-fce4-cd78-cbab-bfd92a8eabe1",
        "NOMBRE": "ENFERMEDAD DEBIDA A PROTOZOARIOS, NO ESPECIFICADA"
    },
    {
        "id": "f31cd840-57a3-1e5c-2330-6a03101b86a5",
        "NOMBRE": "ENFERMEDAD DEBIDA A VIRUS CITOMEGÁLICO"
    },
    {
        "id": "f7f31f9d-05ab-518c-9f8d-75e369f36ce7",
        "NOMBRE": "ENFERMEDAD DEGENERATIVA DE LOS NÚCLEOS DE LA BASE, NO ESPECIFICADA"
    },
    {
        "id": "830d4a0c-1a0c-258d-4586-6ef40c9e99a7",
        "NOMBRE": "ENFERMEDAD DEL ALMACENAMIENTO DE GLUCÓGENO"
    },
    {
        "id": "eba86abd-4bf9-d976-22fc-851fa69c1677",
        "NOMBRE": "ENFERMEDAD DEL ANO Y DEL RECTO, NO ESPECIFICADA"
    },
    {
        "id": "82189654-03ae-ae8b-7661-961e77a0a06c",
        "NOMBRE": "ENFERMEDAD DEL APÉNDICE, NO ESPECIFICADA"
    },
    {
        "id": "84a494f6-fc47-a0d1-7646-b60eb25b636a",
        "NOMBRE": "ENFERMEDAD DEL BAZO, NO ESPECIFICADA"
    },
    {
        "id": "20dbaed4-f5e4-55b5-e90a-ad95a46e6ccb",
        "NOMBRE": "ENFERMEDAD DEL ESÓFAGO, NO ESPECIFICADA"
    },
    {
        "id": "165c0ce1-fa68-795a-853d-14144f88a1e1",
        "NOMBRE": "ENFERMEDAD DEL ESTÓMAGO Y DEL DUODENO, NO ESPECIFICADA"
    },
    {
        "id": "7b9df02f-ae34-5406-7e61-d9a558a24cc9",
        "NOMBRE": "ENFERMEDAD DEL HÍGADO, NO ESPECIFICADA"
    },
    {
        "id": "20bd47ae-7b3b-07a5-7040-e882813bb8be",
        "NOMBRE": "ENFERMEDAD DEL INTESTINO, NO ESPECIFICADA"
    },
    {
        "id": "7c8fd4fd-c198-24c7-b87c-0e9590b3fcc0",
        "NOMBRE": "ENFERMEDAD DEL PÁNCREAS, NO ESPECIFICADA"
    },
    {
        "id": "3a2bdcb5-4256-478d-794d-0ff8edc51659",
        "NOMBRE": "ENFERMEDAD DEL PERICARDIO, NO ESPECIFICADA"
    },
    {
        "id": "0fbdc241-0546-1419-8546-0a1e8533dddb",
        "NOMBRE": "ENFERMEDAD DEL PERIODONTO, NO ESPECIFICADA"
    },
    {
        "id": "3d6158b8-2725-a48e-068c-15b935a85b9a",
        "NOMBRE": "ENFERMEDAD DEL REFLUJO GASTROESOFÁGICO"
    },
    {
        "id": "0e84703b-d62d-930a-76d9-f9a2208ead91",
        "NOMBRE": "ENFERMEDAD DEL REFLUJO GASTROESOFÁGICO CON ESOFAGITIS"
    },
    {
        "id": "39a37817-6cb4-77d7-2377-429c0714ad4e",
        "NOMBRE": "ENFERMEDAD DEL REFLUJO GASTROESOFÁGICO SIN ESOFAGITIS"
    },
    {
        "id": "c09781e8-d3a3-8e56-0a19-837a7c6855ab",
        "NOMBRE": "ENFERMEDAD DEL RÍO ROSS"
    },
    {
        "id": "3c9d8d9c-f72c-cf79-ea3d-ba5b267e558b",
        "NOMBRE": "ENFERMEDAD DEL SISTEMA DIGESTIVO, NO ESPECIFICADA"
    },
    {
        "id": "3a1940c7-ea1f-0e6e-697b-c1d396df719b",
        "NOMBRE": "ENFERMEDAD DEL TIMO, NO ESPECIFICADA"
    },
    {
        "id": "7d26ec6e-57e5-3da5-eb3c-9a763c77f39f",
        "NOMBRE": "ENFERMEDAD DESMIELINIZANTE DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADA"
    },
    {
        "id": "b014b8ed-f9af-21b7-5bb0-018394336bba",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DE AMBOS INTESTINOS CON PERFORACIÓN Y ABSCESO"
    },
    {
        "id": "407f5826-f7af-2e9d-a83f-445f85da8213",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DE AMBOS INTESTINOS, SIN PERFORACIÓN NI ABSCESO"
    },
    {
        "id": "98ed20bc-0a26-1033-d70c-393818101067",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DEL INTESTINO"
    },
    {
        "id": "f23db65f-aae1-e75b-cc40-83b08249c4cf",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DEL INTESTINO DELGADO CON PERFORACIÓN Y ABSCESO"
    },
    {
        "id": "3c7fa0ca-2760-9fcb-3942-9c8e2db38d44",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DEL INTESTINO DELGADO SIN PERFORACIÓN NI ABSCESO"
    },
    {
        "id": "869ea366-43e8-fd8b-f74a-dc7b8a8699c8",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DEL INTESTINO GRUESO CON PERFORACIÓN Y ABSCESO"
    },
    {
        "id": "fce5bf17-3280-cd98-ec16-4f860df992be",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DEL INTESTINO GRUESO SIN PERFORACIÓN NI ABSCESO"
    },
    {
        "id": "f5221137-265c-31d3-44c7-c5a09224ced8",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DEL INTESTINO, PARTE NO ESPECIFICADA, CON PERFORACIÓN Y ABSCESO"
    },
    {
        "id": "e0223818-2cc9-2a9b-a537-983ee9d72192",
        "NOMBRE": "ENFERMEDAD DIVERTICULAR DEL INTESTINO, PARTE NO ESPECIFICADA, SIN PERFORACIÓN NI ABSCESO"
    },
    {
        "id": "159d76f0-e08b-5377-146c-058915a1e44e",
        "NOMBRE": "ENFERMEDAD ENDOMIOCÁRDICA (EOSINOFÍLICA)"
    },
    {
        "id": "8cc35d2f-314e-af8e-27da-6848854facae",
        "NOMBRE": "ENFERMEDAD FLICTENULAR CRÓNICA DE LA INFANCIA"
    },
    {
        "id": "4cf06551-cb01-3cca-708f-5c1dba00f327",
        "NOMBRE": "ENFERMEDAD HEMOLÍTICA DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "2ae01bf9-2dfb-e514-3b60-3c6bf27fc839",
        "NOMBRE": "ENFERMEDAD HEMOLÍTICA DEL FETO Y DEL RECIÉN NACIDO, NO ESPECIFICADA"
    },
    {
        "id": "7f39ab66-3fd7-7b99-d65a-c76c2a594569",
        "NOMBRE": "ENFERMEDAD HEMORRÁGICA DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "4fc9bb06-5dd4-41c7-f80c-b8ba209f226b",
        "NOMBRE": "ENFERMEDAD HEPÁTICA ALCOHÓLICA, NO ESPECIFICADA"
    },
    {
        "id": "8f0e5073-5558-3982-6295-7cc35570dd10",
        "NOMBRE": "ENFERMEDAD HERPÉTICA DISEMINADA"
    },
    {
        "id": "c0b25d53-920c-4cc3-3fa2-eb7245ccb31d",
        "NOMBRE": "ENFERMEDAD INFECCIOSA Y PARASITARIA CONGÉNITA, NO ESPECIFICADA"
    },
    {
        "id": "4b394738-38a1-7976-6d5a-204806c3006e",
        "NOMBRE": "ENFERMEDAD INFECCIOSA Y PARASITARIA MATERNA NO ESPECIFICADA QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "f0e75448-3dec-eb27-5b6e-58d52571f6c3",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA AGUDA DEL ÚTERO"
    },
    {
        "id": "9d407738-4772-c561-f599-ac5fece741b3",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA CRÓNICA DEL ÚTERO"
    },
    {
        "id": "38623e06-5c1c-685e-fff8-7c691200950a",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA DE LA PRÓSTATA, NO ESPECIFICADA"
    },
    {
        "id": "f04c464e-cd18-0a32-11c4-f2e2bd601774",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA DEL CUELLO UTERINO"
    },
    {
        "id": "b36ac197-658e-f76d-6d39-875ad4ba99a8",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA DEL HÍGADO, NO ESPECIFICADA"
    },
    {
        "id": "c21835f4-8c2b-c09a-ff73-c5431ea05fcb",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA DEL ÚTERO, EXCEPTO DEL CUELLO UTERINO"
    },
    {
        "id": "03e42a5e-cc3e-46f3-d010-9d61e6a6ae33",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA DEL ÚTERO, NO ESPECIFICADA"
    },
    {
        "id": "ee3cea72-e9da-714f-4d13-b201b2a7fa32",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA PÉLVICA FEMENINA POR CLAMIDIAS"
    },
    {
        "id": "69d43715-e2e8-5ce5-de77-76ddb5e583c0",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA PÉLVICA FEMENINA POR GONOCOCOS"
    },
    {
        "id": "016b83f6-64d5-bfc8-fa07-b9caa77c54bf",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA PÉLVICA FEMENINA POR SÍFILIS"
    },
    {
        "id": "ecd1413a-c73b-573c-dbb3-536e40aced62",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA PÉLVICA FEMENINA POR TUBERCULOSIS"
    },
    {
        "id": "ba817c75-d61d-6a65-b5eb-ff36403b46c1",
        "NOMBRE": "ENFERMEDAD INFLAMATORIA PÉLVICA FEMENINA, NO ESPECIFICADA"
    },
    {
        "id": "08644497-d8bf-f2c2-a589-00f19da34195",
        "NOMBRE": "ENFERMEDAD INMUNOPROLIFERATIVA DEL INTESTINO DELGADO"
    },
    {
        "id": "5dde66a0-a3f2-f7e8-47de-08d7ca9633c8",
        "NOMBRE": "ENFERMEDAD INMUNOPROLIFERATIVA MALIGNA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "807eeb87-15ac-70e7-359f-8101631f3abf",
        "NOMBRE": "ENFERMEDAD INTESTINAL DEBIDA A PROTOZOARIOS, NO ESPECIFICADA"
    },
    {
        "id": "fa8166b3-7685-3cae-99b5-6ef5d9d2a65c",
        "NOMBRE": "ENFERMEDAD ISQUÉMICA AGUDA DEL CORAZÓN, NO ESPECIFICADA"
    },
    {
        "id": "7f32cebe-2c7f-c07e-4e20-a882c0e5e643",
        "NOMBRE": "ENFERMEDAD ISQUÉMICA CRÓNICA DEL CORAZÓN"
    },
    {
        "id": "ebd84246-74f3-c00a-ce56-e3cb571861f8",
        "NOMBRE": "ENFERMEDAD ISQUÉMICA CRÓNICA DEL CORAZÓN, NO ESPECIFICADA"
    },
    {
        "id": "46fcceee-1dee-e1b2-b8c6-a4ed7ed3e121",
        "NOMBRE": "ENFERMEDAD MENINGOCÓCICA"
    },
    {
        "id": "2d911cce-7ba1-ce0d-cdf7-bf3551707c72",
        "NOMBRE": "ENFERMEDAD MENINGOCÓCICA, NO ESPECIFICADA"
    },
    {
        "id": "50d6ce3b-8458-4cdc-d616-0bd6c2028e55",
        "NOMBRE": "ENFERMEDAD MIELODISPLÁSICA Y MIELOPROLIFERATIVA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "83a0f606-5efc-94e6-9896-ceb8f6dfe738",
        "NOMBRE": "ENFERMEDAD MIELOPROLIFERATIVA CRÓNICA"
    },
    {
        "id": "473b40ee-f6e8-afa6-2713-c6e4bcfaed41",
        "NOMBRE": "ENFERMEDAD NO ESPECIFICADA DE LOS TEJIDOS DENTALES DUROS"
    },
    {
        "id": "61d5c6d8-6825-e78b-0d2f-e9fabffb8c9d",
        "NOMBRE": "ENFERMEDAD NO INFLAMATORIA DEL OVARIO, DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO, NO ESPECIFICADA"
    },
    {
        "id": "793b953f-3d7c-646c-dd13-abc1ccb2eda5",
        "NOMBRE": "ENFERMEDAD ÓSEA DE PAGET, HUESOS NO ESPECIFICADOS"
    },
    {
        "id": "2ac0c51b-0051-cc39-ebfd-953a74f282dc",
        "NOMBRE": "ENFERMEDAD PARASITARIA, NO ESPECIFICADA"
    },
    {
        "id": "ad7c4435-c676-a623-5ab6-f8de0fd21ac3",
        "NOMBRE": "ENFERMEDAD POR DEPÓSITO DE HIDROXIAPATITA"
    },
    {
        "id": "50004026-d587-9f69-9c7b-383919efedec",
        "NOMBRE": "ENFERMEDAD POR DESCOMPRESIÓN [DE LOS CAJONES SUMERGIDOS]"
    },
    {
        "id": "6e049a23-07da-5a83-dbd8-b55dad7e4ab7",
        "NOMBRE": "ENFERMEDAD POR EL VIRUS DE EBOLA"
    },
    {
        "id": "65949bb5-7411-216b-8c47-08c035038f91",
        "NOMBRE": "ENFERMEDAD POR EL VIRUS DE MARBURG"
    },
    {
        "id": "d2dd47cf-95a1-52d6-6c82-2f9384ebb944",
        "NOMBRE": "ENFERMEDAD POR EL VIRUS DEL  ZIKA, CONGÉNITA"
    },
    {
        "id": "d1b86103-5e3c-c937-5637-f79620e98e19",
        "NOMBRE": "ENFERMEDAD POR EL VIRUS DEL ZIKA"
    },
    {
        "id": "33552a5c-560e-a500-5a0c-59209f802aca",
        "NOMBRE": "ENFERMEDAD POR RASGUÑO DE GATO"
    },
    {
        "id": "1b9baba0-0a27-4bee-9ea5-5b1d602e61ab",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN ANORMALIDADES INMUNOLÓGICAS Y HEMATOLÓGICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f5a233cd-23c2-ef75-e0e5-c49d14ec6ba0",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN CANDIDIASIS"
    },
    {
        "id": "97cf11f4-8ce3-51f5-a76a-530b9322c79c",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN ENCEFALOPATÍA"
    },
    {
        "id": "ba9ae3e5-07bc-82a9-ecad-dec85114b37b",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD INFECCIOSA O PARASITARIA NO ESPECIFICADA"
    },
    {
        "id": "683e958b-f09f-685f-c451-511a60ba5be8",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD POR CITOMEGALOVIRUS"
    },
    {
        "id": "7cc68837-761f-5407-fa9c-e69972c490d6",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDADES MÚLTIPLES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "402cf6dd-cd32-2625-3ca4-3276f8a3de60",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN INFECCIÓN POR MICOBACTERIAS"
    },
    {
        "id": "2698a31d-7717-f8c3-1284-3bf859b49372",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN INFECCIONES MÚLTIPLES"
    },
    {
        "id": "950d5374-3238-e430-3ca3-dbc6a698a2dd",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN LINFADENOPATÍA GENERALIZADA (PERSISTENTE)"
    },
    {
        "id": "b762f1ef-398b-4f23-45a0-d1e737b9d46f",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN LINFOMA DE BURKITT"
    },
    {
        "id": "76bb2ad6-f954-54a3-486a-85706f685f26",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN NEUMONÍA POR PNEUMOCYSTIS CARINII"
    },
    {
        "id": "b108c6d9-860a-cce2-689a-1f2616d35f70",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN NEUMONITIS LINFOIDE INTERSTICIAL"
    },
    {
        "id": "918e9160-496b-7365-fa0b-ab56a2e7dab0",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS AFECCIONES ESPECIFICADAS"
    },
    {
        "id": "48eda154-ee24-a686-6052-70c2f20b57f7",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS ENFERMEDADES INFECCIOSAS O PARASITARIAS"
    },
    {
        "id": "076ec36f-8b8a-5faa-8691-53ec6f15467b",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS INFECCIONES BACTERIANAS"
    },
    {
        "id": "e026c040-4de6-89aa-2e4e-30e875f79d66",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS INFECCIONES VIRALES"
    },
    {
        "id": "7587359a-63c2-de8f-f574-624a3fb58548",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS MICOSIS"
    },
    {
        "id": "1ef8ca0e-f8ab-11db-e89c-7e5d714f3fd5",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTROS TIPOS DE LINFOMA NO HODGKIN"
    },
    {
        "id": "fec02890-9028-f354-507d-8f296552cdad",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTROS TUMORES MALIGNOS"
    },
    {
        "id": "68ef2f89-c91c-6daf-e190-a3f5aaf63bb0",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYÉTICO Y TEJIDOS RELACIONADOS"
    },
    {
        "id": "88f07d12-1d1f-8712-6ba1-6d3cb1f4a89c",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN SARCOMA DE KAPOSI"
    },
    {
        "id": "d0c0faab-6ba5-bcdc-0717-ced8371cb99a",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN SÍNDROME CAQUÉCTICO"
    },
    {
        "id": "9c97b455-3f5e-b763-695a-2fbe40a154ed",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN TUMORES MALIGNOS MÚLTIPLES"
    },
    {
        "id": "466667d2-955f-da6d-d4f4-fcb3634c718c",
        "NOMBRE": "ENFERMEDAD POR VIH, RESULTANTE EN TUMORES MALIGNOS NO ESPECIFICADOS"
    },
    {
        "id": "d69919f9-d6b3-114c-bc32-4b323a79f54a",
        "NOMBRE": "ENFERMEDAD POR VIRUS CHIKUNGUNYA"
    },
    {
        "id": "674dd142-87e7-4a9d-196e-84829f471628",
        "NOMBRE": "ENFERMEDAD POR VIRUS CITOMEGÁLICO, NO ESPECIFICADA"
    },
    {
        "id": "193b6113-b57f-fe5d-dcbc-97357c2ead60",
        "NOMBRE": "ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH] QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "6ee3c37c-39af-667b-925a-838e099bf977",
        "NOMBRE": "ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH], RESULTANTE EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS"
    },
    {
        "id": "8306e9c8-bee3-03a9-2cb9-9692b4d13e7f",
        "NOMBRE": "ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH], RESULTANTE EN OTRAS AFECCIONES"
    },
    {
        "id": "6c515034-4742-5336-05b5-9ac3ef982752",
        "NOMBRE": "ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH], RESULTANTE EN OTRAS ENFERMEDADES ESPECIFICADAS"
    },
    {
        "id": "d67d3b63-1e7f-b950-261c-d9bb89fb3c78",
        "NOMBRE": "ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH], RESULTANTE EN TUMORES MALIGNOS"
    },
    {
        "id": "d5cc54dc-e9dc-2d14-b4dd-9ead80bede4b",
        "NOMBRE": "ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH], SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "97d1c35a-998f-8eb0-f938-d4a6a4d4d24a",
        "NOMBRE": "ENFERMEDAD POR VIRUS DE OROPOUCHE"
    },
    {
        "id": "487be8fe-d741-2b16-7353-f80f239c1855",
        "NOMBRE": "ENFERMEDAD POR VIRUS ROCÍO"
    },
    {
        "id": "9c10f981-26e3-5064-db6e-d759e95fc9cd",
        "NOMBRE": "ENFERMEDAD PULMONAR DEL CORAZÓN, NO ESPECIFICADA"
    },
    {
        "id": "97d01e4d-ed0c-fcdb-dea9-3eebfca5e1f5",
        "NOMBRE": "ENFERMEDAD PULMONAR INTERSTICIAL, NO ESPECIFICADA"
    },
    {
        "id": "e926017b-3838-574f-6f36-e9c62887199a",
        "NOMBRE": "ENFERMEDAD PULMONAR OBSTRUCTIVA CRÓNICA CON EXACERBACIÓN AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "788e4a9e-1572-3a99-70f7-b3ec6c557475",
        "NOMBRE": "ENFERMEDAD PULMONAR OBSTRUCTIVA CRÓNICA CON INFECCIÓN AGUDA DE LAS VÍAS RESPIRATORIAS INFERIORES"
    },
    {
        "id": "9ea930ef-443d-c3e6-5e9b-3f603674be3c",
        "NOMBRE": "ENFERMEDAD PULMONAR OBSTRUCTIVA CRÓNICA, NO ESPECIFICADA"
    },
    {
        "id": "e66c5633-72c8-e49c-ae05-54b64916cd11",
        "NOMBRE": "ENFERMEDAD PULMONAR REUMATOIDE"
    },
    {
        "id": "bc187f75-ed54-734d-57c2-9d6cfed99242",
        "NOMBRE": "ENFERMEDAD QUÍSTICA DEL HÍGADO"
    },
    {
        "id": "c03abbc5-5aba-6a8f-2bce-3c001e3f9707",
        "NOMBRE": "ENFERMEDAD QUÍSTICA DEL RIÑÓN"
    },
    {
        "id": "3fec0583-348e-89c1-2067-3e6195132094",
        "NOMBRE": "ENFERMEDAD QUÍSTICA DEL RIÑÓN, NO ESPECIFICADA"
    },
    {
        "id": "47e6874c-b253-ac13-d3dd-e63eaa7fad50",
        "NOMBRE": "ENFERMEDAD RENAL CRÓNICA"
    },
    {
        "id": "637ad332-2b39-6f00-f85e-5f444eb3268d",
        "NOMBRE": "ENFERMEDAD RENAL CRÓNICA, ETAPA 1"
    },
    {
        "id": "0b760c6c-514f-01dd-0d42-fc65369cfb1d",
        "NOMBRE": "ENFERMEDAD RENAL CRÓNICA, ETAPA 2"
    },
    {
        "id": "9576afdc-d2a5-b199-5f2a-5d7ed61fb064",
        "NOMBRE": "ENFERMEDAD RENAL CRÓNICA, ETAPA 3"
    },
    {
        "id": "d4e604a9-2eb4-2c1e-eb25-7ecb326e5cd7",
        "NOMBRE": "ENFERMEDAD RENAL CRÓNICA, ETAPA 4"
    },
    {
        "id": "e8bc41eb-0805-fbd6-e60b-5039d3176d9c",
        "NOMBRE": "ENFERMEDAD RENAL CRÓNICA, ETAPA 5"
    },
    {
        "id": "4a8273d1-7118-caf4-4781-81a316b52825",
        "NOMBRE": "ENFERMEDAD RENAL CRÓNICA, NO ESPECIFICADA"
    },
    {
        "id": "dc5c105f-4acc-c902-6669-141833c8adac",
        "NOMBRE": "ENFERMEDAD RENAL HIPERTENSIVA"
    },
    {
        "id": "8d66ac8f-8369-036b-0653-d141848a2d56",
        "NOMBRE": "ENFERMEDAD RENAL HIPERTENSIVA CON INSUFICIENCIA RENAL"
    },
    {
        "id": "fafd4a99-fd8e-949c-9b0d-ee37c1829e7e",
        "NOMBRE": "ENFERMEDAD RENAL HIPERTENSIVA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "5a063ff4-ebf8-87ad-db1b-950053680ce6",
        "NOMBRE": "ENFERMEDAD RENAL HIPERTENSIVA SIN INSUFICIENCIA RENAL"
    },
    {
        "id": "0d4ee080-26bf-2c22-1d83-7bd65612a68f",
        "NOMBRE": "ENFERMEDAD RENAL TUBULOINTERSTICIAL, NO ESPECIFICADA"
    },
    {
        "id": "f3f894ba-4ae5-feed-c24c-e2865059d0b3",
        "NOMBRE": "ENFERMEDAD RESPIRATORIA CRÓNICA NO ESPECIFICADA ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "c1514bf5-9d50-6ba1-3b66-c369777ac92f",
        "NOMBRE": "ENFERMEDAD RESPIRATORIA CRÓNICA ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "ffb199b2-5523-5b97-68b4-6bbb43144a6a",
        "NOMBRE": "ENFERMEDAD REUMÁTICA AGUDA DEL CORAZÓN, NO ESPECIFICADA"
    },
    {
        "id": "d3fdedf1-6922-9653-9bc2-7d8ca3bd1250",
        "NOMBRE": "ENFERMEDAD REUMÁTICA DEL CORAZÓN, NO ESPECIFICADA"
    },
    {
        "id": "51216bcb-b8db-76c1-7c97-869f84cd28ce",
        "NOMBRE": "ENFERMEDAD REUMATOIDE DEL PULMÓN"
    },
    {
        "id": "cee795ea-6a8a-5973-8a8c-34aa36e4a9ab",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO"
    },
    {
        "id": "eb095133-9728-dd21-cd4d-a7e504730f20",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON CIRROSIS Y FIBROSIS DEL HÍGADO"
    },
    {
        "id": "d978f2ce-b7b6-b08a-5a85-a72e4e940bfd",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON HEPATITIS AGUDA"
    },
    {
        "id": "d9838cbf-3f61-5faa-73b8-bfa9d70a317f",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON HEPATITIS CRÓNICA ACTIVA"
    },
    {
        "id": "715c300f-a2e7-2a69-1881-19129edeb935",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON HEPATITIS CRÓNICA LOBULAR"
    },
    {
        "id": "134f86b1-f46c-4249-7e71-91469bb136ed",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON HEPATITIS CRÓNICA PERSISTENTE"
    },
    {
        "id": "785e2717-c1ba-2ddc-d727-e2e290d4cc9c",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON HEPATITIS NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "3590322d-a869-071a-1bcf-4671bf223d62",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON NECROSIS HEPÁTICA"
    },
    {
        "id": "94c2ed50-7cfe-ba26-6566-aff59b35fe3b",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO CON OTROS TRASTORNOS HEPÁTICOS"
    },
    {
        "id": "cfca1ab5-05df-197e-32e2-647bb27ad03f",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO, CON COLESTASIS"
    },
    {
        "id": "a6f0ca1a-be05-1e6c-5896-2834992a681b",
        "NOMBRE": "ENFERMEDAD TÓXICA DEL HÍGADO, NO ESPECIFICADA"
    },
    {
        "id": "330eaa0e-c1d4-5636-78b3-639ff94bf8d7",
        "NOMBRE": "ENFERMEDAD VALVULAR AÓRTICA REUMÁTICA, NO ESPECIFICADA"
    },
    {
        "id": "34bf0d12-6e85-5fba-4430-ed3108dfe262",
        "NOMBRE": "ENFERMEDAD VALVULAR MITRAL, NO ESPECIFICADA"
    },
    {
        "id": "2705f13d-720b-2985-dd64-68cefeb122ec",
        "NOMBRE": "ENFERMEDAD VASCULAR PERIFÉRICA, NO ESPECIFICADA"
    },
    {
        "id": "df80e3d5-4e41-4c29-0310-7b1be6b101aa",
        "NOMBRE": "ENFERMEDAD VENO-OCLUSIVA DEL HÍGADO"
    },
    {
        "id": "613d44dc-e98f-d6c6-af6f-8808b0877e67",
        "NOMBRE": "ENFERMEDAD VIRAL CONGÉNITA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "2e3719d0-fab7-44f3-a46c-7cebc0330120",
        "NOMBRE": "ENFERMEDAD ZOONÓTICA BACTERIANA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "36dd0847-4618-9a48-a1b7-b614f50bcff4",
        "NOMBRE": "ENFERMEDADES CAUSADAS POR PROTOZOARIOS QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "8a0bdccc-5ec7-6f64-3980-87990371aa7c",
        "NOMBRE": "ENFERMEDADES CRÓNICAS DE LAS AMÍGDALAS Y DE LAS ADENOIDES"
    },
    {
        "id": "c2b110c7-1264-463f-b8c1-2fac9ad2a278",
        "NOMBRE": "ENFERMEDADES DE LA GLÁNDULA DE BARTHOLIN"
    },
    {
        "id": "0668c5b7-a805-c421-c9b9-9dc5e1b050ca",
        "NOMBRE": "ENFERMEDADES DE LA LENGUA"
    },
    {
        "id": "19ab6d3b-26da-afe9-c3b0-9244b421e89f",
        "NOMBRE": "ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "b22f7a07-69f2-e287-0c94-1d808144d895",
        "NOMBRE": "ENFERMEDADES DE LA PULPA Y DE LOS TEJIDOS PERIAPICALES"
    },
    {
        "id": "d4fa4095-8f26-758b-a54f-a1f3c01a9d6d",
        "NOMBRE": "ENFERMEDADES DE LA TRÁQUEA Y DE LOS BRONQUIOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4995e851-c2c9-d433-c6f0-44bec8c1b240",
        "NOMBRE": "ENFERMEDADES DE LAS CUERDAS VOCALES Y DE LA LARINGE, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ce8758d1-2857-a7ec-5e97-f694cea9c1e8",
        "NOMBRE": "ENFERMEDADES DE LAS GLÁNDULAS SALIVALES"
    },
    {
        "id": "a759d49a-afd1-7380-132b-1ae56793db9a",
        "NOMBRE": "ENFERMEDADES DE LAS NEURONAS MOTORAS"
    },
    {
        "id": "fb8116b2-79d6-a7ff-27c4-b47c1c2feb1c",
        "NOMBRE": "ENFERMEDADES DE LAS VÍAS AÉREAS DEBIDAS A POLVOS ORGÁNICOS ESPECÍFICOS"
    },
    {
        "id": "3843c7e0-883a-a56c-2e38-fb889c1b52c1",
        "NOMBRE": "ENFERMEDADES DE LOS LABIOS"
    },
    {
        "id": "6dd40398-7b45-0eba-ed07-d7ba6fddceee",
        "NOMBRE": "ENFERMEDADES DE LOS VASOS CAPILARES"
    },
    {
        "id": "009ed571-683c-31f7-0e60-6f492ee41659",
        "NOMBRE": "ENFERMEDADES DEL BAZO"
    },
    {
        "id": "72056fd4-6585-e429-51df-e98611d560e1",
        "NOMBRE": "ENFERMEDADES DEL MEDIASTINO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1b53a844-72e8-d086-4dd8-2dd3e7209484",
        "NOMBRE": "ENFERMEDADES DEL SISTEMA CIRCULATORIO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PURPERIO"
    },
    {
        "id": "e4f15968-79d3-9ac7-049a-4dc3254c93c8",
        "NOMBRE": "ENFERMEDADES DEL SISTEMA DIGESTIVO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "bbc231be-978b-44ac-4144-15dbd778c996",
        "NOMBRE": "ENFERMEDADES DEL SISTEMA RESPIRATORIO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "790c07c0-d078-398d-aa52-e20d423f5590",
        "NOMBRE": "ENFERMEDADES DEL TIMO"
    },
    {
        "id": "488082a5-f904-f9c8-79cf-651dd6e20ae5",
        "NOMBRE": "ENFERMEDADES ENDOCRINAS, DE LA NUTRICIÓN Y DEL METABOLISMO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "0f191201-fb5e-b11d-ec5f-0405bd3ec190",
        "NOMBRE": "ENFERMEDADES INFLAMATORIAS DE LA PRÓSTATA"
    },
    {
        "id": "acc96475-f6ed-62ae-b92a-b76514a6c5aa",
        "NOMBRE": "ENFERMEDADES INMUNOPROLIFERATIVAS MALIGNAS"
    },
    {
        "id": "631bac56-382a-ea15-f91f-eba9a2462296",
        "NOMBRE": "ENFERMEDADES MATERNAS INFECCIOSAS Y PARASITARIAS CLASIFICABLES EN OTRA PARTE, PERO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "8b83ed93-3c9c-44d0-90d2-0ca2b4a44531",
        "NOMBRE": "ENFERMEDADES REUMÁTICAS DE LA VÁLVULA AÓRTICA"
    },
    {
        "id": "87b44d3c-0f52-c999-336a-bc2a56c7acc5",
        "NOMBRE": "ENFERMEDADES REUMÁTICAS DE LA VÁLVULA MITRAL"
    },
    {
        "id": "779b33f4-86cd-942f-2b0d-f0c802e4be35",
        "NOMBRE": "ENFERMEDADES REUMÁTICAS DE LA VÁLVULA TRICÚSPIDE"
    },
    {
        "id": "cb9026e6-50a6-d56c-1be1-45457d8f00f7",
        "NOMBRE": "ENFERMEDADES REUMÁTICAS DEL ENDOCARDIO, VÁLVULA NO ESPECIFICADA"
    },
    {
        "id": "952b9c2c-8c27-18bc-4621-5b7e1cce117c",
        "NOMBRE": "ENFERMEDADES VALVULARES MÚLTIPLES"
    },
    {
        "id": "8d70c5d5-ad85-a01f-7832-71c493d964c2",
        "NOMBRE": "ENFERMEDADES VIRALES CONGÉNITAS"
    },
    {
        "id": "831284a9-0201-d27f-dadc-bc32ab737d38",
        "NOMBRE": "ENFERMEDED FEBRIL EXANTEMÁTICA"
    },
    {
        "id": "c3dce64a-5f93-8ad5-ea3f-5ed29a566f44",
        "NOMBRE": "ENFISEMA"
    },
    {
        "id": "f6956136-787b-5586-f57f-4441cd63c7f8",
        "NOMBRE": "ENFISEMA CENTROLOBULAR"
    },
    {
        "id": "65a4f07a-a872-e7ef-bf74-67f3d8c19e67",
        "NOMBRE": "ENFISEMA COMPENSATORIO"
    },
    {
        "id": "40ea9dad-461f-68f1-905a-5bb4dad00697",
        "NOMBRE": "ENFISEMA INTERSTICIAL"
    },
    {
        "id": "c81ea6cc-ed93-9df0-c8e1-53cca90e3fa3",
        "NOMBRE": "ENFISEMA INTERSTICIAL ORIGINADO EN EL PERÍODO PERINATAL"
    },
    {
        "id": "9608a1a1-5555-9a42-b20e-99385256b4e9",
        "NOMBRE": "ENFISEMA INTERSTICIAL Y AFECCIONES RELACIONADAS, ORIGINADAS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "d9c201f8-11cd-8e36-c672-d507cc90efa4",
        "NOMBRE": "ENFISEMA PANLOBULAR"
    },
    {
        "id": "0abefffb-ba0b-b309-cfae-c2b50c105923",
        "NOMBRE": "ENFISEMA SUBCUTÁNEO TRAUMÁTICO"
    },
    {
        "id": "9fa13d89-67e7-d57e-833a-663adaaf0b1c",
        "NOMBRE": "ENFISEMA, NO ESPECIFICADO"
    },
    {
        "id": "5800c1d7-68d7-164f-f52c-a9562c5be8ea",
        "NOMBRE": "ENGROSAMIENTO EPIDÉRMICO, NO ESPECIFICADO"
    },
    {
        "id": "8651b57a-d7cc-100f-10f1-b7eddb77b3b8",
        "NOMBRE": "ENOFTALMIA"
    },
    {
        "id": "fe90c9b2-8862-3586-c319-5c40807a9688",
        "NOMBRE": "ENTERITIS DEBIDA A ADENOVIRUS"
    },
    {
        "id": "d3d291d4-f097-fec2-c08a-8e0071b0d246",
        "NOMBRE": "ENTERITIS DEBIDA A CAMPYLOBACTER"
    },
    {
        "id": "30aa74e8-7bf0-8482-9818-9b1ad84fde7c",
        "NOMBRE": "ENTERITIS DEBIDA A ROTAVIRUS"
    },
    {
        "id": "0a6dde25-ab50-de64-225d-ba7d8bc8654c",
        "NOMBRE": "ENTERITIS DEBIDA A SALMONELLA"
    },
    {
        "id": "ed372e3e-2277-4ba5-bed1-dae0dd1e4887",
        "NOMBRE": "ENTERITIS DEBIDA A YERSINIA ENTEROCOLITICA"
    },
    {
        "id": "e33273f7-cd8a-f52c-1b32-df33a0cfdc96",
        "NOMBRE": "ENTEROBIASIS"
    },
    {
        "id": "6ba76af6-aae2-fc7d-aeb9-89ea167f0970",
        "NOMBRE": "ENTEROCELE VAGINAL"
    },
    {
        "id": "7ed3cb81-5cef-be89-d05d-6334ba0e297e",
        "NOMBRE": "ENTEROCOLITIS DEBIDA A CLOSTRIDIUM DIFFICILE"
    },
    {
        "id": "a475a9d9-61a6-9868-234f-e469bc51a1e0",
        "NOMBRE": "ENTEROCOLITIS NECROTIZANTE DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "50f7a096-e5af-5828-1426-aaa7a8907f3f",
        "NOMBRE": "ENTEROPTOSIS"
    },
    {
        "id": "bded4bb7-63ab-526f-be9e-64098b64cc5c",
        "NOMBRE": "ENTEROVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "79606137-4b31-b34e-be8f-09a570e01afb",
        "NOMBRE": "ENTESOPATÍA DEL MIEMBRO INFERIOR, NO ESPECIFICADA"
    },
    {
        "id": "869ada73-2c1e-ff6f-0dc0-8e21bea6cf68",
        "NOMBRE": "ENTESOPATÍA VERTEBRAL"
    },
    {
        "id": "582d38ba-35ce-38c5-708a-72960a06684d",
        "NOMBRE": "ENTESOPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "56a66a7a-86f5-60df-1e83-80c5c0c67873",
        "NOMBRE": "ENTESOPATÍAS DEL MIEMBRO INFERIOR, EXCLUIDO EL PIE"
    },
    {
        "id": "05b651dd-56a3-8285-552e-983938574bde",
        "NOMBRE": "ENTROPIÓN CONGÉNITO"
    },
    {
        "id": "fde367c4-5e22-1869-50cf-1c3c71e2fcbf",
        "NOMBRE": "ENTROPIÓN Y TRIQUIASIS PALPEBRAL"
    },
    {
        "id": "09d60f32-46ef-c03d-fd2c-59cac37798ac",
        "NOMBRE": "ENURESIS NO ORGÁNICA"
    },
    {
        "id": "a7d15a3d-c70f-0711-9641-dd42e7e91f0f",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL DE OTROS GASES Y VAPORES ESPECIFICADOS"
    },
    {
        "id": "70d7fdfe-4473-4f4a-b92d-137b7e155d31",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR GASES Y VAPORES SIN ESPECIFICAR"
    },
    {
        "id": "5345a143-59dc-2a48-9805-fbfbc8e2ab73",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS"
    },
    {
        "id": "a3602bdb-73cf-6466-024d-249fc98b18b3",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "4b18fe19-15e1-20ba-00bb-b7caa3b0520e",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "690aa371-0e3d-5911-cede-9c6b93f3eaba",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, CALLES Y CARRETERAS"
    },
    {
        "id": "8f92c65e-6271-404c-5297-39efcbaef7a8",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a88f5ebc-4791-15b3-3fb8-deb4702227e6",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "37c8885b-87ef-3577-a599-8ae3db59fae0",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, GRANJA"
    },
    {
        "id": "416341c5-7bc5-bdc2-6215-2c4a7f12179f",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "72189305-32b1-8be6-cd32-be23ff59b3c0",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c6a1af23-eae8-aaa8-c360-1ee52f7d6b8e",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "daec0752-c7cf-b10b-c055-d4bbff605d54",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, VIVIENDA"
    },
    {
        "id": "fbdd45b2-943b-0376-fd9e-ef76a3ca6b07",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES"
    },
    {
        "id": "20095a08-5ab0-241e-7c50-9a92b790b073",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7c22de13-bbb4-738b-9285-b5609cf3464d",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ce8e464f-4b28-ca5c-b729-8a9e69d81aa5",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, CALLES Y CARRETERAS"
    },
    {
        "id": "55b5d7da-2cdd-8a96-c404-5a5475d5bfbc",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "ccb65f9a-2cbe-6ada-2818-3a9b457dd815",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ac1f25f4-65b7-a711-0806-48acbb6fcab9",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, GRANJA"
    },
    {
        "id": "d4502d5e-ec2c-300e-6013-68de5019b167",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "1a336103-f136-c318-da54-c74b6f71d6fe",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "0dd81b62-a53d-f564-f59b-75c9be8342f8",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b69f5190-3405-7488-c42a-5a68ba15ecfc",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, VIVIENDA"
    },
    {
        "id": "92bfc68d-45ab-cf0b-1865-84fafb47b16c",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "74fc4a14-d38a-c23f-51fd-5c522a189528",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f63ffe95-f97f-de1b-839b-6450029d4131",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "25572e74-6fc8-a272-984a-e03182e0d134",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, CALLES Y CARRETERAS"
    },
    {
        "id": "7b1d0e7e-bd31-1c28-78ed-1b50974b1f0d",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "4fc3675c-274e-546f-4a39-20d35e69d7a9",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "fd3649a1-4e15-ce4b-652f-d9c653691ca4",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, GRANJA"
    },
    {
        "id": "4a71b929-d4c2-9743-17c7-a1c0185a3364",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "86ee3e75-ff48-6c7d-3a64-98fa22f76667",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "3c59f61e-9bda-4b21-178e-925f15032f9f",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ce5f5c8b-a80a-2706-a69b-b5a309f20349",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, VIVIENDA"
    },
    {
        "id": "c1fa19ec-8e87-f75c-02e6-8103b022dd97",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A MONOXIDO DE CARBONO DE COMBUSTIÓN DE ESCAPE DE MOTOR"
    },
    {
        "id": "7665865b-ca34-7f19-db95-062e8cdcd362",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A MONOXIDO DE CARBONO DE FUENTES INESPECÍFICAS"
    },
    {
        "id": "f36bc2c6-012e-87f5-0f3c-6694f901d8a7",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A MONOXIDO DE CARBONO DE OTRAS FUENTES"
    },
    {
        "id": "b17ad090-08a4-dcfb-5c7d-011b6e12b12c",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A MONOXIDO DE CARBONO DE OTROS COMBUSTIBLES DOMÉSTICOS"
    },
    {
        "id": "5d80bd17-e7bc-34c9-3403-4068e3242c5b",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A MONOXIDO DE CARBONO POR GAS DOMÉSTICO"
    },
    {
        "id": "654c9b90-63d5-bc88-a0d2-635ee24d01d9",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A MONOXIDO DE CARBONO Y OTROS GASES Y VAPORES"
    },
    {
        "id": "fec8e148-5840-f63a-2c21-1c9c7dee4b0a",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "ca344c07-2b08-5d04-5193-ddc5ad5b2cf8",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "824da326-5ba9-b6c5-0b43-208f7cf855fb",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "031ad9ba-4b3d-09de-4ed4-5da773f17dc7",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, CALLES Y CARRETERAS"
    },
    {
        "id": "8b150813-2a70-d744-ab53-5cb2c3860bde",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "4777a774-6a03-af6a-6e14-026c43de73b5",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "49615a48-4a27-dd5b-447e-4c7c901962cf",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, GRANJA"
    },
    {
        "id": "07d5dd05-3b26-cfcf-1b34-95ce12cf5eae",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "574c93ba-3a35-1adb-aa39-020142e1e358",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "67adee26-656a-5e7a-a531-f630c1440e9d",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "fdec67ad-55f6-9d94-0258-7e45ff9530e7",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, VIVIENDA"
    },
    {
        "id": "d1180a3c-6bf3-a7b6-104f-9631b5cfb617",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "32bdd47e-0a29-2ebe-fd29-13d45229404f",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "56e2db31-ffe7-7305-7165-4a722f26ae0b",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "758d0ddd-a3ac-f3dc-b9c4-8835a48df0bf",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, CALLES Y CARRETERAS"
    },
    {
        "id": "e2f78294-b054-7cd2-8674-7fe07b125494",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "cf9516c9-b985-a36b-0540-fe1ae11ff8b1",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "4f628edd-de39-82b4-20f9-e460fce16557",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, GRANJA"
    },
    {
        "id": "7990db31-2b95-0a73-9c4a-8b2345c6f71c",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "0ecf2da7-34dd-68fd-2d02-4eca5b213de7",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "91d897a9-1ce8-86f8-627e-03e28e619852",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "c4bf30f1-abc8-96a3-3550-ed063f5f7bae",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, VIVIENDA"
    },
    {
        "id": "ed0e2945-4544-a17d-dfd1-ce98ad38c574",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "980738cd-d176-0710-3bcd-1f9b0fc442b9",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "dd36bc5a-f886-cb18-9cca-694b6178d0d5",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "6452ad83-4f9e-66e5-18ec-62ae95535fe0",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "de56ea70-1ad5-a1ed-5d7b-ac7042481f4c",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "af55e1bb-24d7-fbc2-315d-484b9b05925a",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6e6e3f91-502a-2092-0fe6-59221105c4bc",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "2d98bd86-da7f-275b-e95d-48800c673742",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "fac650f4-d824-abdc-ad01-70dadac3b21b",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ad51955b-d307-a032-c9f3-4e770a441a8d",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "355de00f-220a-2ab7-0ae6-943da83dc2bf",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "4307ef99-0283-6903-b0f0-f7c6bfe2191d",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "59aad067-4e67-b7b5-185b-e37dfc8a4a8a",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b4c85437-24ba-6d18-7870-6321789da041",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, GRANJA"
    },
    {
        "id": "d94bc4e2-ad47-9892-6b60-de36eaba6cd1",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "dc34739f-71a8-567b-9fa4-c47f56046c5c",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "76a2fad1-8b4f-d522-06e3-35517161b9ca",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c40ab15a-8683-5373-f77a-df34c8bf373e",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "8ddb8d24-63c3-c95a-90bb-6c363f308382",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "286cb581-7397-7c66-5040-90dcf035edc0",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "f06c7c16-d57d-dd19-e25b-248868ead806",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "754a8c1a-fde4-dfec-42e5-1b132bda3163",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f03c8c6f-36f4-ce6d-d20d-260567d8b636",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "3a8278b5-ec84-fbda-97f5-8c43a42fa4cf",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "dc8ce56a-db07-bf32-022f-ece0f87604d1",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "a131780c-9192-b74a-a274-b4e4f8c1e907",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS"
    },
    {
        "id": "37f17b8e-76cf-f7b0-6b4a-f90ee31d0cd3",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "88f7328d-c616-12e0-91b1-6e756b795497",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "d0c38594-a620-0e7a-043a-c8e4107544db",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, CALLES Y CARRETERAS"
    },
    {
        "id": "ce3c656a-2e1f-e58d-37c7-98dd99ec346f",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "533d0cad-1ecc-8b8b-c707-ddbc7457ff45",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "09411709-8c31-6e98-ee74-645f7ae93d4b",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, GRANJA"
    },
    {
        "id": "2e867a36-b09e-0595-ac51-11b94600761a",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "1d028d2b-46b2-4042-4a59-24e40b40a37e",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "1eeb66aa-aa65-d414-2224-f1e03d20ba06",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "40e47981-2291-0aef-2e26-9f11f189e5e0",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN A PLAGUICIDAS, VIVIENDA"
    },
    {
        "id": "ed605295-0c9c-d23d-c71b-7e0277a381f5",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL"
    },
    {
        "id": "d3b02e3b-94d1-b30b-a36a-dcde313f8d8a",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "997aa03a-222a-9f76-77bc-e7a52ec0afb0",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "af4c0234-ec62-b9ff-aa15-4a8e13f02fd5",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, CALLES Y CARRETERAS"
    },
    {
        "id": "dae3af64-30c2-65f8-b0da-6fdff60841dc",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "ced09232-8604-eed9-0d0d-4db3a04448b3",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "efb85c59-59f7-3fed-2308-960da056a6bc",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, GRANJA"
    },
    {
        "id": "42a720c8-b8bf-fb4b-5f4a-cd75db836393",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "d6f1afba-631b-89c1-83dc-c5e9a4afe9c9",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f5cc2f1a-c61e-2c0b-b16e-efd024a7eddd",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "47ac44f7-106d-552e-ffb4-2c3a7409ded0",
        "NOMBRE": "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICIÓN AL ALCOHOL, VIVIENDA"
    },
    {
        "id": "710a7b98-b041-123e-0c9b-0facd0343a77",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS"
    },
    {
        "id": "9b25455e-ec86-8ffc-a418-5e321732e57a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "57fe457d-75d2-96e6-3111-e18111919181",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "7a152442-3104-c6e2-2624-95f18e5a2220",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, CALLES Y CARRETERAS"
    },
    {
        "id": "fa74993b-13ce-4226-fc4d-b8b7e8e4e762",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "44cc51fb-4cc1-4e70-fb68-7c0354085b42",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "cb924515-5be6-61a2-20f8-cec0add7e77f",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, GRANJA"
    },
    {
        "id": "a42055df-5dfa-9e8e-34ab-e9253c2c4f72",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "0d5a787a-44e3-fb7a-61ca-9522666e8777",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "105bd844-780b-0c46-5b74-be7c2c50b308",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "463a2ce7-5d67-a9cf-23ae-8cb216cec03e",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, VIVIENDA"
    },
    {
        "id": "882ada1c-f37e-777e-085c-dc805c6cf838",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES"
    },
    {
        "id": "76786505-3299-4f03-29d8-3d58b97e792e",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "9e7d20f8-3e65-6968-fdd0-5e02e590cb37",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2c9b4f02-8ffb-01c4-d250-1940c6e68833",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, CALLES Y CARRETERAS"
    },
    {
        "id": "44b743fe-de7c-7517-6ff8-04e42fa9f094",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "68eb9858-ebf5-903d-f8a3-affd30683981",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "30b8bebd-41b9-f1d1-8380-960a465de788",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, GRANJA"
    },
    {
        "id": "74867f31-f52a-4d3f-9394-bb6f34a45f5a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7e91579f-f104-33fa-f669-2bbdb537ded1",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a1d40fa6-4992-7cd2-516c-2d45ae0696ff",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d1e7a30c-8579-833e-5a43-44b96aa3599c",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, VIVIENDA"
    },
    {
        "id": "b259b403-b7d4-a112-1d3e-cc6bbc6a3627",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fd893998-2b92-27f4-327d-e719a012d042",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "1311408a-667d-6b18-2281-8e21456c31f2",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8aba2f5c-b1b4-99bf-f373-44f2beb12b9c",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, CALLES Y CARRETERAS"
    },
    {
        "id": "0dcd238e-89f3-f113-f690-f3efe6c5dcee",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "470bca96-8302-df7d-2e65-a31694d3a5ac",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "7a245c7e-5e09-0ac7-eac4-3502764c09c9",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, GRANJA"
    },
    {
        "id": "9cc7c514-5218-40d1-0e5b-124b166d57d2",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "958442ac-9ad9-896d-6e46-2fb3eff3ee3f",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ae85056a-0072-25d7-771a-f87a7f983931",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "44020f50-4a1f-afd1-aadd-6652c604c2e1",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, VIVIENDA"
    },
    {
        "id": "e2c9b379-7b73-a282-d296-a790a62e0b92",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "62a4ed9c-492d-73fe-c09c-3c4e7bcefc89",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f1fb833a-5be5-f697-a406-0e7dfc77c363",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "07885bb2-93a1-42e5-8126-a50ce8744eaa",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, CALLES Y CARRETERAS"
    },
    {
        "id": "ae70b9e0-1ba0-10c7-e0e2-9969378e6e24",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "871577bb-2bb0-21d8-98d2-df7e42882c85",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "7c39f971-7f50-5a69-9063-d1fffb96ba6b",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, GRANJA"
    },
    {
        "id": "eaa62de2-e28d-fbcc-ef05-1bf9e9b2d26a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "9b897b35-3fd5-c384-ef04-638d6116f29d",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "db153428-13c5-eed0-738e-0131b2c6a6dd",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e901eb80-757f-3c3d-6ee7-4bae75153d61",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, VIVIENDA"
    },
    {
        "id": "5ac32148-ab64-fcb8-d5b5-709480765991",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "75398181-2b0a-fe39-4174-3f031663a69a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "6d006daf-0e10-e5da-34db-fdfd0672f919",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "48797c91-349b-1186-0f75-4a20221b7353",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, CALLES Y CARRETERAS"
    },
    {
        "id": "93918e48-10b7-8a5f-7f16-7357b6b1c11f",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "603d9157-15ae-58e1-0b8d-10a0fe473434",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "9d67243a-b928-89e3-8351-783d3306ef56",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, GRANJA"
    },
    {
        "id": "06a5cf40-6f59-9e22-da32-fc24bdd671a8",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e60cb93c-65ba-c8c0-c05e-e0dc332d980b",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "0ef1848f-d4c5-cc82-8401-f50bc2802631",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "7a793ad2-1337-53ce-5c03-03a63c762cb7",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, VIVIENDA"
    },
    {
        "id": "4e32b120-e3ef-bab3-6bd5-afef3aa03dee",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "2dfc663d-c9f5-1981-e912-c3023859d8a6",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "18aee845-dde5-cdc6-08d4-d87d4f1e6983",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0d9268b7-f3be-54a2-b505-d244d09cdcb1",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "be5bc2a4-b8f8-f176-9878-3e78ca9d8c3a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "db11210b-204b-9403-8a50-3f1a5f659601",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "32f61c3e-5c9f-8c8f-e9df-79ce82d30e1d",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "91f367a5-5a15-11c6-dea1-6b9f8a5a33f0",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "2f89a0fc-61e3-98dc-12c9-9011fea3b630",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c25a6331-4492-1861-e424-d41a3c1a3a39",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "30a3b47f-1f6e-4bf1-b4cf-3f1d583a4fad",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LOS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "7e089987-3a37-7849-0fc6-dbe09364e9ef",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES"
    },
    {
        "id": "321df577-6f25-f1ea-690c-029fa4b8cdd5",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "6f457935-82dc-5dff-efec-1fe962d49330",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4ba81d92-682e-cdfc-27a4-dfaa5e3b9511",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, CALLES Y CARRETERAS"
    },
    {
        "id": "b3d3c327-647c-65b3-f19b-25d2c8528fa7",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "34dca130-95d6-a95e-9dd4-b4be24dda947",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0fae6f7d-0da7-85c6-5ce1-c5e8ee798bd5",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, GRANJA"
    },
    {
        "id": "af459392-a664-86cc-53c8-5bed8efc9d07",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "d561b5f1-e92a-ba33-f587-a436d572e55a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "cb490f4e-1b18-2554-5f3b-1e30acf8617a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "4af7ca19-e285-d177-2ca3-c5551d84f0b6",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, VIVIENDA"
    },
    {
        "id": "d1ef72a8-1bed-adeb-7a2c-9d02c707d26d",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "5bf2559d-b103-9e5b-3d89-12d592b92bd0",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "33dadcdd-6744-9c0f-6d0d-3f3b4f628a6f",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "da5b05d6-b7f7-105a-3d2d-096e845b6e5a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "8a52cabc-9597-09c4-77a4-67fff1ad8f3c",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c690b9f5-b138-210f-b67d-076172069958",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a737f01c-e2bb-dea6-364d-56d358117bbb",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "699693db-171e-5d93-a50c-20b2ea1f3f9e",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3d2958a8-ed2f-656a-6094-359ac2012943",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "b4db615b-5c0f-a91b-e272-5ff9542ec81a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b3b36f8b-49a6-6d1d-7a21-618b7bf7a25b",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "e3c0e077-37f1-8916-453f-0cebfe1e8aa0",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS"
    },
    {
        "id": "f509f474-c6eb-fd4b-8184-7864b1f0187b",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8f66f191-d493-efc2-6230-5987e8d9d6cf",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ac26d580-ce1d-1d93-2362-15ef90fb7227",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, CALLES Y CARRETERAS"
    },
    {
        "id": "8b7d3eab-7b91-5153-36e8-1ab1370a8863",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3fbde9a3-947d-6e21-904b-345e03b5772f",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6f1cae7f-03b4-2e35-e5f1-a394ddcb29d6",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, GRANJA"
    },
    {
        "id": "bec7a3d0-9dfe-0b07-8945-f7c9f1473887",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "c47e0bc1-1ee1-5d11-80dc-d23779a2689d",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a936bd5a-d946-d101-8981-af7e25f723ac",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "606808f7-8b5e-d8d1-2f91-d329e827cc5e",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN A PLAGUICIDAS, VIVIENDA"
    },
    {
        "id": "b1f45f33-e3e1-5a90-df2d-72fdd7f5bef0",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL"
    },
    {
        "id": "f7472164-c273-d3e7-2caf-aa84cbbaabef",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "803a7a55-c1aa-c89e-802e-e0c9356a1685",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4d45a439-4c09-5db7-218c-1122d26ef7d5",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, CALLES Y CARRETERAS"
    },
    {
        "id": "0f87ebd7-f82d-05dd-5b0c-28ab703b91df",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3e2c96b6-5940-80ea-a67c-c9e95961906c",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "aa830893-485e-0e69-9d08-49e18d58fc3b",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, GRANJA"
    },
    {
        "id": "1a9b5078-25a4-6216-393a-b856fc4867ae",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "0095b754-c842-4833-35cf-05032c95609a",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "69ecc527-d1a6-837a-9f46-e6a633299aaf",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "54a3a299-27eb-1b33-efa3-5f24532739bf",
        "NOMBRE": "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICIÓN AL ALCOHOL, VIVIENDA"
    },
    {
        "id": "7922f448-4fbe-7c64-6325-40c9ff6f3f0d",
        "NOMBRE": "ENVENENAMIENTO CIGUATERO POR PESCADO"
    },
    {
        "id": "524ff003-6a1c-a770-de45-8f10092d671d",
        "NOMBRE": "ENVENENAMIENTO ESCOMBROIDEO POR PESCADO"
    },
    {
        "id": "d8e2ed9f-c6d9-136a-2cda-f771d92efea5",
        "NOMBRE": "ENVENENAMIENTO POR ACIDO LISÉRGICO [LSD]"
    },
    {
        "id": "1d040001-9941-921e-080d-26a0d9ab0b5f",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES ANTICOLINESTERASA"
    },
    {
        "id": "dcbd02d6-8a1b-7ba6-d9e7-184e17d313db",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES CON ACCIÓN PRINCIPAL SOBRE LOS MÚSCULOS LISOS Y ESQUELÉTICOS Y SOBRE EL SISTEMA RESPIRATORIO"
    },
    {
        "id": "06310caf-1bf3-4791-063e-301e470618db",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES DE ACCIÓN CENTRAL Y BLOQUEADORES NEURONALES ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "9a28a72c-f3b4-10b8-a3f2-1818c2a0add9",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES DEL EQUILIBRIO HIDROLÍTICO, ELECTROLÍTICO Y CALÓRICO"
    },
    {
        "id": "a6c7fd6a-4d40-fa7e-adc1-6b59c69b8ada",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES DIAGNÓSTICOS"
    },
    {
        "id": "869c7b00-421a-e858-33de-1d813c65a9ae",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES NO ESPECIFICADOS QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL"
    },
    {
        "id": "e29b33ef-c502-16d9-535d-d1d9ea2a014e",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTÉMICOS Y HEMATOLÓGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "b53890ee-271e-728f-d2cc-ac50f87d396b",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTÉMICOS Y HEMATOLÓGICOS, NO ESPECIFICADOS"
    },
    {
        "id": "f8e20dfd-dbb1-4292-901f-aaa76693c86f",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR"
    },
    {
        "id": "64282a18-a175-2250-6d82-c429e0a03f80",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL"
    },
    {
        "id": "06165358-f7a9-640d-e11d-0efbefd1943f",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES TÓPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLÓGICAS, OTORRINOLARINGOLÓGICAS Y DENTALES"
    },
    {
        "id": "ff5e69af-bb37-eb89-33ed-a95f7368b04b",
        "NOMBRE": "ENVENENAMIENTO POR AGENTES TÓPICOS, NO ESPECIFICADOS"
    },
    {
        "id": "0800d71b-8404-ebb4-4d86-6d933c8f2b2a",
        "NOMBRE": "ENVENENAMIENTO POR AGONISTAS, PREDOMINANTEMENTE ALFA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "32fad781-5b8e-e0d3-ef6d-33181b5dd828",
        "NOMBRE": "ENVENENAMIENTO POR AGONISTAS, PREDOMINANTEMENTE BETA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "504d67da-0580-9d01-1b30-a9bdddf95794",
        "NOMBRE": "ENVENENAMIENTO POR AMINOGLUCÓSIDOS"
    },
    {
        "id": "06f0e218-10e3-c7f5-793d-b779f1ba163d",
        "NOMBRE": "ENVENENAMIENTO POR ANALÉPTICOS Y ANTAGONISTAS DEL OPIO"
    },
    {
        "id": "a24d6ddf-90fe-e8f4-d8d8-7410bdcbb4d6",
        "NOMBRE": "ENVENENAMIENTO POR ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS"
    },
    {
        "id": "27e6c3dd-e422-cc9e-00c5-586cac80f600",
        "NOMBRE": "ENVENENAMIENTO POR ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, NO ESPECIFICADOS"
    },
    {
        "id": "961e7d18-9b19-8a5a-e833-35dbda78a67b",
        "NOMBRE": "ENVENENAMIENTO POR ANDRÓGENOS Y SUS CONGÉNERES ANABÓLICOS"
    },
    {
        "id": "9e59e512-209b-8e0c-9b21-0134e028accb",
        "NOMBRE": "ENVENENAMIENTO POR ANESTÉSICOS INTRAVENOSOS"
    },
    {
        "id": "51ab38eb-0131-c188-b6a8-a976c4faf604",
        "NOMBRE": "ENVENENAMIENTO POR ANESTÉSICOS LOCALES"
    },
    {
        "id": "f0a9e21a-18bf-82da-a6ab-017bbe78affa",
        "NOMBRE": "ENVENENAMIENTO POR ANESTÉSICOS POR INHALACIÓN"
    },
    {
        "id": "aed7483c-c422-542b-f84c-f98b26186360",
        "NOMBRE": "ENVENENAMIENTO POR ANESTÉSICOS Y GASES TERAPÉUTICOS"
    },
    {
        "id": "d0d48ab2-c00e-6b7c-d86c-301ad1c06022",
        "NOMBRE": "ENVENENAMIENTO POR ANESTÉSICOS, NO ESPECIFICADOS"
    },
    {
        "id": "5611424e-e3a1-80a8-ac57-52b7483ccea2",
        "NOMBRE": "ENVENENAMIENTO POR ANTAGONISTAS ALFA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "4d9a51d4-47b0-801b-3a00-b08cf305fa9b",
        "NOMBRE": "ENVENENAMIENTO POR ANTAGONISTAS BETA-ADRENÉRGICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "877cf15e-66ab-ed67-1b3c-e14ceb5d52bc",
        "NOMBRE": "ENVENENAMIENTO POR ANTAGONISTAS DE ANTICOAGULANTES, VITAMINA K Y OTROS COAGULANTES"
    },
    {
        "id": "0a2fd374-b308-988b-75ce-0437319fb643",
        "NOMBRE": "ENVENENAMIENTO POR ANTAGONISTAS DEL RECEPTOR H2 DE HISTAMINA"
    },
    {
        "id": "71fd71e7-4cf4-97bb-e993-8056609944f5",
        "NOMBRE": "ENVENENAMIENTO POR ANTIASMÁTICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "04c90560-9a5b-510b-cfeb-503fa6c85844",
        "NOMBRE": "ENVENENAMIENTO POR ANTIBIÓTICOS ANTIMICÓTICOS USADOS SISTÉMICAMENTE"
    },
    {
        "id": "60a289d6-5230-fcb5-fe5e-a7f4997fe4cc",
        "NOMBRE": "ENVENENAMIENTO POR ANTIBIÓTICOS DEL GRUPO DEL CLORAMFENICOL"
    },
    {
        "id": "fafa3d4c-b090-2455-bdc0-06c2a9a6641d",
        "NOMBRE": "ENVENENAMIENTO POR ANTIBIÓTICOS SISTÉMICOS"
    },
    {
        "id": "1b8412a3-3b52-a4e3-5d76-cc84f25116d6",
        "NOMBRE": "ENVENENAMIENTO POR ANTIBIÓTICOS SISTÉMICOS, NO ESPECIFICADOS"
    },
    {
        "id": "5839ebb1-941e-cc7a-3178-8ba6d0894908",
        "NOMBRE": "ENVENENAMIENTO POR ANTICOAGULANTES"
    },
    {
        "id": "1f35b07c-5a08-1c95-6a31-d3466437884b",
        "NOMBRE": "ENVENENAMIENTO POR ANTICONCEPTIVOS ORALES"
    },
    {
        "id": "275dd4bf-eb8f-186d-35cf-492578130e3b",
        "NOMBRE": "ENVENENAMIENTO POR ANTIDEPRESIVOS INHIBIDORES DE LA MONOAMINOXIDASA"
    },
    {
        "id": "dd4c4358-8b22-4f8b-7cef-2d68e9cfb590",
        "NOMBRE": "ENVENENAMIENTO POR ANTIDEPRESIVOS TRICÍCLICOS Y TETRACÍCLICOS"
    },
    {
        "id": "09285854-dbf9-c8a2-32a8-a50e80d41f59",
        "NOMBRE": "ENVENENAMIENTO POR ANTÍDOTOS Y AGENTES QUELANTES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "16a7501a-0dd4-5742-3977-b61d5593ef12",
        "NOMBRE": "ENVENENAMIENTO POR ANTIEPILÉPTICOS MIXTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "dc91e273-0344-a6fb-9771-db957dc689bc",
        "NOMBRE": "ENVENENAMIENTO POR ANTIEPILÉPTICOS Y DROGAS HIPNÓTICO-SEDANTES, NO ESPECIFICADOS"
    },
    {
        "id": "0abdebe4-4172-7c16-bdc2-cd39f7e68b90",
        "NOMBRE": "ENVENENAMIENTO POR ANTIEPILÉPTICOS, HIPNÓTICOS-SEDANTES Y DROGAS ANTIPARKINSONIANAS"
    },
    {
        "id": "513c7338-c978-9c42-763b-0206978743fd",
        "NOMBRE": "ENVENENAMIENTO POR ANTIGONADOTROFINAS, ANTIESTRÓGENOS Y ANTIANDRÓGENOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "bed07cff-56e3-b357-886c-81bd7a557bb0",
        "NOMBRE": "ENVENENAMIENTO POR ANTIHELMÍNTICOS"
    },
    {
        "id": "2662a544-7b33-daff-51f2-8cc468fefd68",
        "NOMBRE": "ENVENENAMIENTO POR ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTÉMICOS, NO ESPECIFICADOS"
    },
    {
        "id": "256b43a6-3b54-2b86-1b88-bf3f4c0add33",
        "NOMBRE": "ENVENENAMIENTO POR ANTIPALÚDICOS Y DROGAS DE ACCIÓN CONTRA OTROS PROTOZOARIOS SANGUÍNEOS"
    },
    {
        "id": "5dd48791-a1c2-a81b-1112-63c55b74c253",
        "NOMBRE": "ENVENENAMIENTO POR ANTIPRURÍTICOS"
    },
    {
        "id": "916a6eb2-3d91-5073-7971-33638d94d56a",
        "NOMBRE": "ENVENENAMIENTO POR ANTIPSICÓTICOS Y NEUROLÉPTICOS FENOTIACÍNICOS"
    },
    {
        "id": "ee42dfc2-108a-8f29-cac6-7f4e4aef860a",
        "NOMBRE": "ENVENENAMIENTO POR ANTIRREUMÁTICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "7fa97ac7-0d52-4a61-3309-3c2929a6bdd7",
        "NOMBRE": "ENVENENAMIENTO POR ANTITUSÍGENOS"
    },
    {
        "id": "cd23ef83-37dd-265b-1a04-705cdfea20c4",
        "NOMBRE": "ENVENENAMIENTO POR ASTRINGENTES Y DETERGENTES LOCALES"
    },
    {
        "id": "318b2080-00ae-39ce-3bfb-37b108eb404a",
        "NOMBRE": "ENVENENAMIENTO POR BARBITÚRICOS"
    },
    {
        "id": "5b551090-ef94-1a7a-f0cb-5d428f515e24",
        "NOMBRE": "ENVENENAMIENTO POR BENZODIAZEPINAS"
    },
    {
        "id": "88860a1c-962c-b8fd-2e5e-2cc9d73a9ff2",
        "NOMBRE": "ENVENENAMIENTO POR BLOQUEADORES DEL CANAL DEL CALCIO"
    },
    {
        "id": "7f438c7d-c539-cf84-4b68-9740e5727633",
        "NOMBRE": "ENVENENAMIENTO POR BUTIROFENONA Y NEUROLÉPTICOS TIOXANTÉNICOS"
    },
    {
        "id": "9dd5263d-c857-5a90-4306-00ebd6935412",
        "NOMBRE": "ENVENENAMIENTO POR CANNABIS (DERIVADOS)"
    },
    {
        "id": "2159a941-6986-5460-a567-9b721dcfa12b",
        "NOMBRE": "ENVENENAMIENTO POR CEFALOSPORINAS Y OTROS ANTIBIÓTICOS BETA-LACTÁMICOS"
    },
    {
        "id": "8173d1b9-98a5-64d7-696d-33790c28df32",
        "NOMBRE": "ENVENENAMIENTO POR COCAÍNA"
    },
    {
        "id": "5b622af3-a9b2-6147-6929-c240c24503ca",
        "NOMBRE": "ENVENENAMIENTO POR DEPRESORES DEL APETITO"
    },
    {
        "id": "e8c15a82-c29c-d527-29a4-2203fc7c62ed",
        "NOMBRE": "ENVENENAMIENTO POR DERIVADOS DE LA HIDANTOÍNA"
    },
    {
        "id": "3c04c51d-6b54-1e7a-de0a-427b06e56b9c",
        "NOMBRE": "ENVENENAMIENTO POR DERIVADOS DE LA PIRAZOLONA"
    },
    {
        "id": "3be10928-e240-398b-7449-4ff4c4088eca",
        "NOMBRE": "ENVENENAMIENTO POR DERIVADOS DEL PARAAMINOFENOL"
    },
    {
        "id": "61ac9ec3-f4df-01ca-0e53-7382bbc6fa9b",
        "NOMBRE": "ENVENENAMIENTO POR DIGESTIVOS"
    },
    {
        "id": "c9e8adaf-8854-3822-0dc3-de567538fba7",
        "NOMBRE": "ENVENENAMIENTO POR DIURÉTICOS DEL ASA [DINTEL ALTO]"
    },
    {
        "id": "3df236d9-8c7a-731b-78da-adb4d47a4823",
        "NOMBRE": "ENVENENAMIENTO POR DIURÉTICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS NO ESPECIFICADAS"
    },
    {
        "id": "53ea0748-d55b-5440-1422-d21c23d03c2a",
        "NOMBRE": "ENVENENAMIENTO POR DROGA PSICOTRÓPICA, NO ESPECIFICADA"
    },
    {
        "id": "8d69650d-1b8e-06d1-a97e-9c5ec1de09a0",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTIALÉRGICAS Y ANTIEMÉTICAS"
    },
    {
        "id": "4ac7edc3-b754-8402-52e5-f8906732299d",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTIDIARREICAS"
    },
    {
        "id": "1b9576a1-b04a-17d4-2c8d-ef7696b6116c",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTILIPÉMICAS Y ANTIARTERIOSCLERÓTICAS"
    },
    {
        "id": "96a21858-b1b5-62b9-c9b6-6b2a92eb1cca",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTIMICOBACTERIANAS"
    },
    {
        "id": "6c9b6f71-e9b2-6a0a-3fde-f9ed381ec626",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTINEOPLÁSICAS E INMUNOSUPRESORAS"
    },
    {
        "id": "6702923c-26eb-ca48-10f2-159551fc15bb",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTIPARKINSONIANAS Y OTROS DEPRESORES DEL TONO MUSCULAR CENTRAL"
    },
    {
        "id": "315dcf5a-5b27-0b68-5a32-dac35e231a29",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTITIROIDEAS"
    },
    {
        "id": "1f8e9e08-b822-f201-ba89-088c9076266a",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTIVARICOSAS, INCLUSIVE AGENTES ESCLEROSANTES"
    },
    {
        "id": "c038ff63-f6e1-858b-b62f-3c19ab1f5cc3",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS ANTIVIRALES"
    },
    {
        "id": "971081a0-4d41-9836-7497-327a5275e9e1",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS BLOQUEADORAS GANGLIONARES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "63f66845-cf8f-9f62-318a-029534a80f6c",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS CONTRA EL CATARRO COMÚN"
    },
    {
        "id": "555ec293-618f-b7ff-a0f6-62ceee7ae92c",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS DENTALES, APLICADAS TÓPICAMENTE"
    },
    {
        "id": "a63d32d0-0061-e57e-f283-621c428a3e16",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS LOCALES ANTIMICÓTICAS, ANTIINFECCIOSAS Y ANTIINFLAMATORIAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "334644ec-41d5-20d0-4fbe-1f0184019734",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS OXITÓCICAS"
    },
    {
        "id": "dc837879-3e3b-3729-18f7-66c9f5b6b3e2",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS QUE AFECTAN EL METABOLISMO DEL ÁCIDO ÚRICO"
    },
    {
        "id": "bc266ff2-7018-c416-6a8d-8d15d8d1bac1",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS QUE AFECTAN LA FIBRINÓLISIS"
    },
    {
        "id": "fac64798-f6b7-6e67-8c45-0231511ac66b",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "09fce883-7787-f0bc-d91e-933bf1e19afe",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS Y PREPARACIONES OFTALMOLÓGICAS"
    },
    {
        "id": "e27cbd38-c392-2e59-b135-6366b1132ffc",
        "NOMBRE": "ENVENENAMIENTO POR DROGAS Y PREPARACIONES OTORRINOLARINGOLÓGICAS"
    },
    {
        "id": "ae6c10bf-c3ab-3580-7aa6-bf5728ffa9c4",
        "NOMBRE": "ENVENENAMIENTO POR EMÉTICOS"
    },
    {
        "id": "e08677d9-8ba2-38e7-66b4-40d1beef2aad",
        "NOMBRE": "ENVENENAMIENTO POR EMOLIENTES, DEMULCENTES Y PROTECTORES"
    },
    {
        "id": "deb0840f-d60b-c58e-5a06-6a3d92e919d4",
        "NOMBRE": "ENVENENAMIENTO POR ENZIMAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5a9be9ce-f6aa-4889-48de-1825c663e77b",
        "NOMBRE": "ENVENENAMIENTO POR EXPECTORANTES"
    },
    {
        "id": "0e6c44ec-d9a7-b013-1691-c5334a97cbde",
        "NOMBRE": "ENVENENAMIENTO POR GASES TERAPÉUTICOS"
    },
    {
        "id": "16095ba1-e76c-3a59-fac8-19353e33538d",
        "NOMBRE": "ENVENENAMIENTO POR GLUCOCORTICOIDES Y ANÁLOGOS SINTÉTICOS"
    },
    {
        "id": "65c82845-a76f-477b-77b1-62f4b4137eeb",
        "NOMBRE": "ENVENENAMIENTO POR GLUCÓSIDOS CARDIOTÓNICOS Y MEDICAMENTOS DE ACCIÓN SIMILAR"
    },
    {
        "id": "7ad8cb07-b864-847a-f5fb-0fb43a6fc28c",
        "NOMBRE": "ENVENENAMIENTO POR HEROÍNA"
    },
    {
        "id": "aee8fe5f-27f2-4ce3-fec2-8c3bf7dfe1a4",
        "NOMBRE": "ENVENENAMIENTO POR HIERRO Y SUS COMPUESTOS"
    },
    {
        "id": "16d66ccf-3e80-1da8-8d6d-dc27ed20ccc9",
        "NOMBRE": "ENVENENAMIENTO POR HORMONAS TIROIDEAS Y SUSTITUTOS"
    },
    {
        "id": "d110ad62-a975-b99a-c73f-72d80a4aeaac",
        "NOMBRE": "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTÉTICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "41932d63-b610-e2fd-f77f-b6653a248425",
        "NOMBRE": "ENVENENAMIENTO POR IMINOSTILBENOS"
    },
    {
        "id": "855694f2-bf74-2d5f-6091-bd80f48e131c",
        "NOMBRE": "ENVENENAMIENTO POR INHIBIDORES DE LA ANHIDRASA DEL ÁCIDO CARBÓNICO, BENZOTIAZIDAS Y OTROS DIURÉTICOS"
    },
    {
        "id": "7aff9528-1a80-0edd-131e-f1cc2d10632b",
        "NOMBRE": "ENVENENAMIENTO POR INHIBIDORES DE LA ENZIMA CONVERTIDORA DE LA ANGIOTENSINA"
    },
    {
        "id": "5d81d162-4094-6459-2f39-3b4fe4abb65c",
        "NOMBRE": "ENVENENAMIENTO POR INSULINA Y DROGAS HIPOGLUCEMIANTES ORALES [ANTIDIABÉTICAS]"
    },
    {
        "id": "c9f4fbf9-2374-5797-4cf5-47a05d8b7d48",
        "NOMBRE": "ENVENENAMIENTO POR LAXANTES ESTIMULANTES"
    },
    {
        "id": "a7b6d285-eac6-a3f9-1ecd-1035ce41be1e",
        "NOMBRE": "ENVENENAMIENTO POR LAXANTES SALINOS Y OSMÓTICOS"
    },
    {
        "id": "0e0f55ac-6fb0-16f2-0fbd-42bf83abd39b",
        "NOMBRE": "ENVENENAMIENTO POR MACRÓLIDOS"
    },
    {
        "id": "970fb1b2-b94e-013e-0576-26fe62bb732c",
        "NOMBRE": "ENVENENAMIENTO POR METADONA"
    },
    {
        "id": "80fe1b18-c908-287b-9c59-e8e89bb0a3af",
        "NOMBRE": "ENVENENAMIENTO POR MINERALOCORTICOIDES Y SUS ANTAGONISTAS"
    },
    {
        "id": "6e87e91b-7780-e80d-0311-03dc41ad02b1",
        "NOMBRE": "ENVENENAMIENTO POR NARCÓTICOS Y PSICODISLÉPTICOS (ALUCINÓGENOS)"
    },
    {
        "id": "de0e6c02-ebce-385e-84e6-7d83843ae6a0",
        "NOMBRE": "ENVENENAMIENTO POR OPIO"
    },
    {
        "id": "56f2474f-7a20-a5c1-18c3-0e2018fb877f",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS ANTIÁCIDAS Y QUE INHIBEN LA SECRECIÓN GÁSTRICA"
    },
    {
        "id": "cd08e0de-d41e-378f-0c08-28a46cc6e71c",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS ANTIARRÍTMICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "21dcc184-b954-94a7-d897-16ae4e570fc8",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS ANTIHIPERTENSIVAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "37e38c22-828d-199e-1cfd-52cf17d2435b",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS ANTIIFLAMATORIAS NO ESTEROIDEAS [DAINE]"
    },
    {
        "id": "8424e591-6552-5e77-e636-c550e7762f93",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS ANTIPROTOZOARIAS"
    },
    {
        "id": "719733fa-1486-ac87-28a0-8936f578764d",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "640961b1-cce3-96ca-add8-6873a44bd03c",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS Y LAS NO ESPECIFICADAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "b1c40ab0-76ce-6f4d-8790-ddc479f3d18d",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS DROGAS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "a2531d3e-6d50-2f57-eeb5-4731cd0599d5",
        "NOMBRE": "ENVENENAMIENTO POR OTRAS HORMONAS Y SUSTITUTOS SINTÉTICOS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "b496baea-8d72-734e-02bc-7ba007020594",
        "NOMBRE": "ENVENENAMIENTO POR OTROS AGENTES PRINCIPALMENTE SISTÉMICOS Y HEMATOLÓGICOS"
    },
    {
        "id": "8fbc9d96-f6bf-12d2-8fda-b819f09c2ba3",
        "NOMBRE": "ENVENENAMIENTO POR OTROS AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL"
    },
    {
        "id": "0d379312-c80c-4628-9712-100d773cad5d",
        "NOMBRE": "ENVENENAMIENTO POR OTROS AGENTES TÓPICOS"
    },
    {
        "id": "4f913066-b60f-da1c-2626-dff28c772281",
        "NOMBRE": "ENVENENAMIENTO POR OTROS AGENTES Y LOS NO ESPECIFICADOS DE ACCIÓN PRINCIPAL SOBRE EL SISTEMA RESPIRATORIO"
    },
    {
        "id": "36579b71-a958-2d90-8eba-2d8517388d6c",
        "NOMBRE": "ENVENENAMIENTO POR OTROS AGENTES Y LOS NO ESPECIFICADOS QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR"
    },
    {
        "id": "8c24a6e3-d6cd-ded0-5b57-c888ea72104e",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANALGÉSICOS NO NARCÓTICOS Y ANTIPIRÉTICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "fd50f689-6147-d97f-7258-212154e10a03",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANESTÉSICOS GENERALES Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "a64b1943-9376-4f84-e087-656847228acf",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANTAGONISTAS DE LAS HORMONAS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "14a5263f-167a-c3e5-8341-d8856d926681",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANTIBIÓTICOS SISTÉMICOS"
    },
    {
        "id": "d8060ca0-ca26-68fa-d3af-1096fe34e730",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANTIDEPRESIVOS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "efb712f1-8f6b-b37a-1196-3beecd00de56",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANTIEPILÉPTICOS Y DROGAS HIPNÓTICO-SEDANTES"
    },
    {
        "id": "29c9b7ad-90c3-dff6-9788-d49937d59693",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTÉMICOS"
    },
    {
        "id": "6c4bfb72-256a-714d-62d1-9896cc644dba",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTÉMICOS ESPECIFICADOS"
    },
    {
        "id": "5aad9a9e-b44c-c53d-a1de-fe335bf2f1c8",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ANTIPSICÓTICOS Y NEUROLÉPTICOS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "361de996-60b8-a25a-ebce-773fa585b155",
        "NOMBRE": "ENVENENAMIENTO POR OTROS ESTRÓGENOS Y PROGESTÓGENOS"
    },
    {
        "id": "57fabffe-540f-3f21-16fc-ab26f267cc5a",
        "NOMBRE": "ENVENENAMIENTO POR OTROS LAXANTES"
    },
    {
        "id": "0b4b9447-2826-1da0-b1e8-5b6659916416",
        "NOMBRE": "ENVENENAMIENTO POR OTROS MEDICAMENTOS Y LOS NO ESPECIFICADOS DE ACCIÓN PRINCIPAL SOBRE LOS MÚSCULOS"
    },
    {
        "id": "e81f65b5-9d5c-fe55-336f-6a49b54147b9",
        "NOMBRE": "ENVENENAMIENTO POR OTROS NARCÓTICOS SINTÉTICOS"
    },
    {
        "id": "016538c8-a15a-fd32-27a8-4eda5024439e",
        "NOMBRE": "ENVENENAMIENTO POR OTROS NARCÓTICOS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "12cd3c85-7c34-285c-66c0-d40398f1b402",
        "NOMBRE": "ENVENENAMIENTO POR OTROS OPIÁCEOS"
    },
    {
        "id": "40be6a7a-c916-7a0f-9629-d44e5d412db1",
        "NOMBRE": "ENVENENAMIENTO POR OTROS PARASIMPATICOLÍTICOS [ANTICOLINÉRGICOS Y ANTIMUSCARÍNICOS] Y ESPASMOLÍTICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "1842c855-a802-9855-803c-7191266dc3ab",
        "NOMBRE": "ENVENENAMIENTO POR OTROS PARASIMPÁTICOMIMÉTICOS [COLINÉRGICOS]"
    },
    {
        "id": "092201c0-d26e-06ac-a8c4-7a84e65ea850",
        "NOMBRE": "ENVENENAMIENTO POR OTROS PSICODISLÉPTICOS Y LOS NO ESPECIFICADOS [ALUCINÓGENOS]"
    },
    {
        "id": "19ced29d-650b-4740-b09a-58401ca275ea",
        "NOMBRE": "ENVENENAMIENTO POR PENICILINAS"
    },
    {
        "id": "ab9b8f2a-6918-ec05-a72a-5e873f423983",
        "NOMBRE": "ENVENENAMIENTO POR PSICOESTIMULANTES CON ABUSO POTENCIAL"
    },
    {
        "id": "98af1e47-d029-4d87-b9d0-14c7b85c6acf",
        "NOMBRE": "ENVENENAMIENTO POR PSICOTRÓPICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "3f6e0799-4c09-ebae-db7b-3ac8d9898349",
        "NOMBRE": "ENVENENAMIENTO POR QUERATOLÍTICOS, QUERATOPLÁSTICOS, DROGAS Y OTRAS PREPARACIONES PARA EL TRATAMIENTO DEL CABELLO"
    },
    {
        "id": "c02610aa-1de4-f6e8-6edd-f0f79fa11d84",
        "NOMBRE": "ENVENENAMIENTO POR RELAJANTES MUSCULOESQUELÉTICOS [AGENTES BLOQUEADORES NEUROMUSCULARES]"
    },
    {
        "id": "41594060-afbf-2af2-18b0-824baaba5b89",
        "NOMBRE": "ENVENENAMIENTO POR RIFAMICINAS"
    },
    {
        "id": "99b18b00-7bd2-dfc4-9c50-75ff95aa18cd",
        "NOMBRE": "ENVENENAMIENTO POR SALICILATOS"
    },
    {
        "id": "1abd376a-8d57-97cb-f597-d1ea5fe440d8",
        "NOMBRE": "ENVENENAMIENTO POR SUCCINAMIDAS Y DERIVADOS DE LA OXAZOLIDINA"
    },
    {
        "id": "56649506-31c1-8a05-071c-09a2a91e994f",
        "NOMBRE": "ENVENENAMIENTO POR SULFONAMIDAS"
    },
    {
        "id": "79d51b7e-3dd4-45b5-441e-c9e69553240d",
        "NOMBRE": "ENVENENAMIENTO POR TETRACICLINAS"
    },
    {
        "id": "d783afc3-954a-cb7e-2ca4-1319cef85a65",
        "NOMBRE": "ENVENENAMIENTO POR VASODILATADORES CORONARIOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "ccebd1fb-eb7c-97bc-516a-2938d99aa5d2",
        "NOMBRE": "ENVENENAMIENTO POR VASODILATADORES PERIFÉRICOS"
    },
    {
        "id": "c93af695-5c7c-7205-a1eb-80d07c909983",
        "NOMBRE": "ENVENENAMIENTO POR VITAMINAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9da7b76c-f072-65df-6b4f-d8342713348f",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "92dad5a5-b3e3-05c3-8e09-90d1695bef76",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d14a4190-9cf1-9ce7-1b92-a8e6f7a59a36",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "95aad05d-ca5e-c2d9-51d4-01818cd822c4",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "946f81fc-ec9e-e0a5-877f-c183210afdfa",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e3bd2fdf-3148-ae4a-e042-51b7d4860bbc",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "1666164a-3fc4-0f8d-5471-1eecda68660f",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "a54be98c-d0ca-c0e2-d23d-6e0324d31e5c",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "6456b5a1-542c-e24a-5041-21b21a3edcec",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "10580efd-e3e0-1214-424c-cd32113f7bca",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "32960eb5-f398-4224-c9ff-9b6b4482da20",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A ANALGÉSICOS NO NARCÓTICOS, ANTIPIRÉTICOS Y ANTIRREUMÁTICOS, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "533eadc6-de99-b14a-c682-fde378918c5c",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "00850934-f916-13dc-1b58-3894ed2b2174",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "bcd35ea5-bfb2-3566-efe5-38e66b0ae5c6",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "7f942dea-f514-3f2f-075a-08f9cb5b1194",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "2d983773-0a8c-37f9-f5e6-487cab7ae937",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "00ebbaf6-0464-e359-318c-5dc37b69fa6b",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "4bb7d673-dd5d-e674-467e-c33add2bee00",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "8eab510e-9bbc-49c5-4f43-1fb8dc435492",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3e892528-8ccd-7476-bc8e-6a8a9b117725",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d0b15a14-9552-6a8c-db92-49faba7db0ae",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "f6961bee-2313-0d07-b2e1-5c6d3ebb0025",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DISOLVENTES ORGÁNICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "35215960-02fb-3795-9683-b90ca1646b4b",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "9f879bd6-3336-2a8d-e5bd-73e087c7d0ab",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7906e8a2-a42d-d835-18c4-11cf7a5f7fec",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4ffabee0-bab7-44e7-e3b5-abee84e9e508",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "cabe1a13-2183-b8d3-65e5-f8b102570019",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6976bc4a-673e-a8dc-8947-3c871e774a95",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "34e7c145-2d05-bb58-b851-15ff245c8c1f",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "f6c24d8f-f2db-00bc-249c-e5743db4da34",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "d998019a-7962-0d8b-4157-a3f4ce4d7368",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8afff14a-4974-eede-dc14-00ed2da481bd",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a113e90b-5d32-ed37-f6c2-d1dfbc2f91e7",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A DROGAS ANTIEPILÉPTICAS, SEDANTES, HIPNÓTICAS, ANTIPARKINSONIANAS Y PSICOTRÓPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "d337a336-4a74-c99e-ddf8-6b487865ffeb",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "43c193b5-2269-e08d-5a32-3726255647d3",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7290558f-dee4-ad2e-10e6-5d624d4e8487",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "cd6efe09-3bba-330e-9f80-a06c6b390d80",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "6aabd153-194f-056f-98b3-8f33eec3d740",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e0f3b0df-893e-6cec-3ea4-409e6f1137cb",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "119c9fb6-4fc7-2e12-76fb-05341c9a9995",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "7bc0a9a9-2765-84a2-b889-97377c21a770",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "04462c24-6239-4605-09c0-5b3a97e5eba9",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "b2c7e259-ac66-cf85-7ec7-bf4b08c82a95",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "576ee862-5186-7436-72f6-f2d434022554",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A NARCÓTICOS Y PSICODISLÉPTICOS [ALUCINÓGENOS], NO CLASIFICADOS EN OTRA PARTE, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "5e079055-49ea-6e9e-9561-03b3c1ad34b7",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "8947e86f-4da3-c66c-8772-94fdf2eec9b0",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a3c4c4d1-e4ad-abeb-4e5f-7f61c4a7702e",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c3e220db-bcd7-fdcb-1391-17374fc1b1bf",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "84c9c7f8-8e22-bb9b-7468-78e69b1174e6",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c0c6c4f5-f9ef-27f2-4206-8928bab68512",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "61df9732-4915-a3af-5325-c65c0d9ccbfe",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "8af94fcc-f5c7-84df-9ede-70d511507e4f",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a275d58d-cda9-26bb-5988-5dd68e74df76",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "33195417-8824-fa92-a669-bbd0eead0696",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b532be95-8849-db9e-6837-696b5ff814a4",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS QUE ACTÚAN SOBRE EL SISTEMA NERVIOSO AUTÓNOMO, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "b0b64796-adfb-9f37-730b-f95d47063380",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "86cb6dd3-8df4-4573-1e52-1f3bcf3dc5bf",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "e55d937a-c2e2-b37b-0417-a8c7a78380c8",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "60434d31-bd5f-87d7-485a-56e0bdbda709",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "219fc703-66be-a794-d0ce-02b314f48d41",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "87da4365-1971-7490-35ee-ce875d0fc696",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b904b5d7-c895-7460-f832-e5e0a75b64af",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "ee8eac36-78e2-56e1-6159-8b3096458e7b",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ec482fec-8a4f-c2a8-ad89-9abc26b588ee",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8cd09cdf-8536-0753-8ab5-a8f581777b2b",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "0cc3b13e-a3ec-3926-77c3-af2cfaec7a9e",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS, Y LAS NO ESPECIFICADAS, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "b137bd80-bc81-8676-fd28-4a2ca3d7fc3d",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "9e9ce1b7-7434-9f55-ada2-0386348b0998",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7cb3d3b6-27e6-7566-bce9-4929f787a448",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "711a7fb0-eccf-bd19-f164-0b0ddb6a19b0",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "c23b6bc1-64bb-6199-a4a5-4a0ff2b7b0ed",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "468033df-7543-0832-b5a9-8faa7c9db7f1",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ff9baa2b-23cb-ef46-e9a8-227a741f82c8",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "35921dc0-0ee0-3f1f-1430-8e171bf18da5",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "53ddf785-e052-d44f-83c4-36d954bb9262",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "4aabf41b-c84a-e1dd-3dcb-4a8194ce3e45",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "cec4f913-14bd-71b2-7f74-b86f7696cb7f",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS GASES Y VAPORES, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "11f905f1-dfe2-11d9-59e4-dd88e960c749",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "2ab8120e-16e4-d9d1-0fdd-4d448744af5d",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "ec46a836-1f75-c4aa-2e36-9dec3477ee32",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a0de4d46-9134-3935-3fd3-99918d9f5860",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "8a5474fb-cda2-926a-19fd-8e8534b8618b",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a49e1ebb-3c08-a820-df95-06890f7bd616",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "31aea537-f3e4-9b50-65e4-d6ae7a9038e2",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "4de93ffc-94d1-e932-2ea0-4065696487e8",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ba0f864c-6abf-7bb4-d737-4841c17867f6",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "62598d40-cee7-ccb6-a0f2-40e08dcecd02",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "32180881-304c-318c-a9ba-184bfce417ab",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A OTROS PRODUCTOS QUÍMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "2de1775a-c61d-d563-d830-cbe435bf68f2",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "3d913376-9bf7-a258-dec6-42cda12d2441",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "5f779a74-4c9e-af9c-6655-bb1757966673",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5d6d15c2-3136-5a43-81bd-653794801674",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "5f200e08-5f23-f7e2-9175-b1edb96859cc",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "7f738e58-f574-b59d-a0fb-31aba598c931",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a05bcf9c-79c4-da2a-8b96-c06d2e6b9d01",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "36e14903-c6e3-ae90-4dc6-a705200bb27c",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "b8fb55a4-29f1-cc0c-113f-a92bbe95bedc",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "b44f9480-4f86-ba47-4500-bb27ce2e67f9",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8a0f99ad-311b-29ea-495e-308a5736507b",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN A PLAGUICIDAS, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "25892abb-1c4a-cda3-5f50-9169e6a2a4f4",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "08e03408-abf3-2c01-3a39-64d947dc7f92",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f1fcde90-d8c9-13fe-4e60-8b1c8d3ee9ec",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e442cdf1-dac1-c432-39f7-1f82e744f27b",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "18441e99-3e8b-eaf6-67af-fa4acb1a7330",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "db17f65c-9ae9-5e68-1786-970ba2e9caf6",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2f3012ac-8268-e4e1-77b1-c9785f6ec74d",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "ec95d439-834c-d789-cbc1-5c0aa92d0487",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a14b9831-2d03-a825-6c93-e409ae27c9d7",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "bfde1bde-90c3-2b2a-3d35-e79614097b36",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "9dc2ffc1-c1bc-3554-30f3-a0cf8dcb2ad1",
        "NOMBRE": "ENVENENAMIENTO POR, Y EXPOSICIÓN AL ALCOHOL, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "334b03fe-7b88-f090-6ab3-65e1dda02737",
        "NOMBRE": "EOSINOFILIA"
    },
    {
        "id": "7e61d8d2-9d4a-88b1-0b81-40d4b1d5753a",
        "NOMBRE": "EOSINOFILIA PULMONAR, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "1f2429a9-cbbe-2d8f-f922-43f8abfad95c",
        "NOMBRE": "EPICONDILITIS LATERAL"
    },
    {
        "id": "a216cec2-78db-afb3-550b-7dfa43c57ead",
        "NOMBRE": "EPICONDILITIS MEDIA"
    },
    {
        "id": "2a1edbc7-562a-f1d4-f4e1-84626c641285",
        "NOMBRE": "EPIDERMÓLISIS BULLOSA"
    },
    {
        "id": "cec437a6-ac3a-d310-6ebf-7a3bf8cee8de",
        "NOMBRE": "EPIDERMÓLISIS BULLOSA ADQUIRIDA"
    },
    {
        "id": "088bc2d6-f7a0-bd36-3c73-5a7c1788b025",
        "NOMBRE": "EPIDERMÓLISIS BULLOSA DISTRÓFICA"
    },
    {
        "id": "b148ebd4-5f58-ecf7-23f9-4a3c66a92631",
        "NOMBRE": "EPIDERMÓLISIS BULLOSA LETAL"
    },
    {
        "id": "9c225782-8723-1e1a-0378-de416301015f",
        "NOMBRE": "EPIDERMÓLISIS BULLOSA SIMPLE"
    },
    {
        "id": "c368a963-b2ca-3bcd-6dc1-972fca981b0a",
        "NOMBRE": "EPIDERMÓLISIS BULLOSA, NO ESPECIFICADA"
    },
    {
        "id": "8dd93a6d-1963-b15e-0232-443322498bc8",
        "NOMBRE": "EPÍFORA"
    },
    {
        "id": "91007f97-873d-d888-f455-8762f8e4358a",
        "NOMBRE": "EPIGLOTITIS AGUDA"
    },
    {
        "id": "1bfd6120-eec4-2810-b177-258234e24a4a",
        "NOMBRE": "EPILEPSIA"
    },
    {
        "id": "b7fe0217-d59c-8a62-18ba-f9874cf6b5f2",
        "NOMBRE": "EPILEPSIA Y SÍNDROMES EPILÉPTICOS IDIOPÁTICOS GENERALIZADOS"
    },
    {
        "id": "e9684097-6751-e90b-8877-333c05ee489c",
        "NOMBRE": "EPILEPSIA Y SÍNDROMES EPILÉPTICOS IDIOPÁTICOS RELACIONADOS CON LOCALIZACIONES (FOCALES)(PARCIALES) Y CON ATAQUES DE INICIO LOCALIZADO"
    },
    {
        "id": "6c375d2c-9852-bdd8-5d2e-b56fb8e3428b",
        "NOMBRE": "EPILEPSIA Y SÍNDROMES EPILÉPTICOS SINTOMÁTICOS RELACIONADOS CON LOCALIZACIONES (FOCALES)(PARCIALES) Y CON ATAQUES PARCIALES COMPLEJOS"
    },
    {
        "id": "7c464085-2cee-9c61-8671-59f773216d9c",
        "NOMBRE": "EPILEPSIA Y SÍNDROMES EPILÉPTICOS SINTOMÁTICOS RELACIONADOS CON LOCALIZACIONES (FOCALES)(PARCIALES) Y CON ATAQUES PARCIALES SIMPLES"
    },
    {
        "id": "1cba5191-a216-62fa-280d-6bf5fce50131",
        "NOMBRE": "EPILEPSIA, TIPO NO ESPECIFICADO"
    },
    {
        "id": "d404e20d-4ae2-da1b-ab05-e7b01ce9d620",
        "NOMBRE": "EPISCLERITIS"
    },
    {
        "id": "189eb64b-3c95-311f-3c67-be7f1dbc6a14",
        "NOMBRE": "EPISODIO DEPRESIVO"
    },
    {
        "id": "22805528-b9b1-dcd7-d5d7-92b144fbe494",
        "NOMBRE": "EPISODIO DEPRESIVO GRAVE CON SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "8aaedaf8-f6f4-8b3c-ee69-628ded9d1f7c",
        "NOMBRE": "EPISODIO DEPRESIVO GRAVE SIN SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "377e8637-dd3f-c1e7-8a7e-6375a7373256",
        "NOMBRE": "EPISODIO DEPRESIVO LEVE"
    },
    {
        "id": "15aca02e-ffef-78ab-30a1-8edabd40c726",
        "NOMBRE": "EPISODIO DEPRESIVO MODERADO"
    },
    {
        "id": "e3d6accd-28fd-1394-88af-5f16267a1d34",
        "NOMBRE": "EPISODIO DEPRESIVO, NO ESPECIFICADO"
    },
    {
        "id": "fa91f504-fe36-6e37-5f4c-770977e72419",
        "NOMBRE": "EPISODIO MANÍACO"
    },
    {
        "id": "562c5d40-4dd0-b398-999c-5ef27e4a2bee",
        "NOMBRE": "EPISODIO MANÍACO, NO ESPECIFICADO"
    },
    {
        "id": "df058fbf-150c-e396-94fc-e5b45aabfa6d",
        "NOMBRE": "EPISPADIAS"
    },
    {
        "id": "a9e89548-b0b6-8669-2d8b-072dbd8a1997",
        "NOMBRE": "EPISTAXIS"
    },
    {
        "id": "c07fd256-a6a0-6536-42d9-ba2835c46891",
        "NOMBRE": "EQUIMOSIS DEL CUERO CABELLUDO DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "aa67c060-2a24-1d97-62ef-f7bb7a460160",
        "NOMBRE": "EQUIMOSIS ESPONTÁNEA"
    },
    {
        "id": "eb2c5a3b-d263-a255-91f6-1d9b267d8cd8",
        "NOMBRE": "EQUINOCOCOSIS"
    },
    {
        "id": "96ebe8c5-21d4-6c68-c3b7-199964a08732",
        "NOMBRE": "EQUINOCOCOSIS DEL HÍGADO, NO ESPECIFICADA"
    },
    {
        "id": "d26fec42-7669-5751-965d-0b502e2badf6",
        "NOMBRE": "EQUINOCOCOSIS, OTRA Y LA NO ESPECIFICADA"
    },
    {
        "id": "6662510e-cb5e-53b7-cefa-1f254dfc2841",
        "NOMBRE": "ERISIPELA"
    },
    {
        "id": "47319120-b1e6-59d1-55bb-a9a35e9f1e64",
        "NOMBRE": "ERISIPELOIDE"
    },
    {
        "id": "f58f8214-8d14-d2c2-323e-78d5dca3fdf1",
        "NOMBRE": "ERISIPELOIDE CUTÁNEO"
    },
    {
        "id": "cced1d23-1607-dc13-8f2a-3c9f013ca7a4",
        "NOMBRE": "ERISIPELOIDE, NO ESPECIFICADA"
    },
    {
        "id": "6072d009-1843-79ce-1010-39468aa57436",
        "NOMBRE": "ERITEMA AB IGNE [DERMATITIS AB IGNE]"
    },
    {
        "id": "de27f809-b336-2022-e3c4-e2650db803f7",
        "NOMBRE": "ERITEMA ANULAR CENTRÍFUGO"
    },
    {
        "id": "a4d91d98-e4b0-d150-3f1c-a1143ed806aa",
        "NOMBRE": "ERITEMA ELEVATUM DIUTINUM"
    },
    {
        "id": "e643cce2-8bcd-ed32-106e-8d92b5761ff9",
        "NOMBRE": "ERITEMA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "58995486-8cc9-70da-db3a-420abe0ae6fb",
        "NOMBRE": "ERITEMA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "10120bef-a69e-4e36-93b9-e9490cd628e6",
        "NOMBRE": "ERITEMA INFECCIOSO [QUINTA ENFERMEDAD]"
    },
    {
        "id": "28c52b55-591c-1004-9dff-af28ded169ec",
        "NOMBRE": "ERITEMA INTERTRIGO"
    },
    {
        "id": "a0d7ef26-9c16-ac51-7316-714ef2ce8b1e",
        "NOMBRE": "ERITEMA MARGINADO"
    },
    {
        "id": "8c1db84a-3f8c-b15b-027c-1431dcba147b",
        "NOMBRE": "ERITEMA MARGINADO EN LA FIEBRE REUMÁTICA AGUDA"
    },
    {
        "id": "a8d06ae7-2dc5-774d-3273-51e5cd38dfad",
        "NOMBRE": "ERITEMA MULTIFORME"
    },
    {
        "id": "1f174e8c-fc40-8e3d-9c79-2f39e3a47f38",
        "NOMBRE": "ERITEMA MULTIFORME FLICTENULAR"
    },
    {
        "id": "db2f5dfd-d3ec-8ce9-d81e-792126ff2562",
        "NOMBRE": "ERITEMA MULTIFORME NO FLICTENULAR"
    },
    {
        "id": "ac4153d0-7ae9-cbec-8915-17440cede365",
        "NOMBRE": "ERITEMA MULTIFORME, NO ESPECIFICADO"
    },
    {
        "id": "3f3e3643-fc39-edb5-dbc6-d63af300d849",
        "NOMBRE": "ERITEMA NUDOSO"
    },
    {
        "id": "f217f51d-bffe-eb74-e8c6-c282908869cf",
        "NOMBRE": "ERITEMA TÓXICO"
    },
    {
        "id": "a0798d63-a141-8497-2363-4da773bf8dfd",
        "NOMBRE": "ERITEMA TÓXICO NEONATAL"
    },
    {
        "id": "b17b83ef-1906-42c9-ce40-24d17550e324",
        "NOMBRE": "ERITRASMA"
    },
    {
        "id": "c4c51746-0816-247b-b2b1-4d258efdbd63",
        "NOMBRE": "ERITREMIA CRÓNICA"
    },
    {
        "id": "75d88fd3-9cfb-2fc6-66f9-02d82db7e706",
        "NOMBRE": "ERITROCITOSIS FAMILIAR"
    },
    {
        "id": "074a04af-338b-9094-2361-a9cf9fef1819",
        "NOMBRE": "ERITRODERMIA ICTIOSIFORME VESICULAR CONGÉNITA"
    },
    {
        "id": "d6112744-75df-ff9b-6630-f44e9be704ce",
        "NOMBRE": "EROSIÓN DE LOS DIENTES"
    },
    {
        "id": "e49268e2-27a5-6d3e-8116-41be82f6554b",
        "NOMBRE": "EROSIÓN Y ECTROPIÓN DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "3d908cf0-255e-bbea-5847-180b331ef38c",
        "NOMBRE": "ERUPCIÓN CUTÁNEA GENERALIZADA DEBIDA A DROGAS Y MEDICAMENTOS"
    },
    {
        "id": "789a792e-8eb2-ee91-52e4-15a368cc02e6",
        "NOMBRE": "ERUPCIÓN CUTÁNEA LOCALIZADA DEBIDA A DROGAS Y MEDICAMENTOS"
    },
    {
        "id": "5124a9c7-249a-a7b0-8e62-e1f49c3560ed",
        "NOMBRE": "ERUPCIÓN POLIMORFA A LA LUZ"
    },
    {
        "id": "8fa4006a-7f33-10a3-96c8-f4b144bc3e8c",
        "NOMBRE": "ESCABIOSIS"
    },
    {
        "id": "2185d74a-6c0f-f127-df16-f1a1b2c77e63",
        "NOMBRE": "ESCARLATINA"
    },
    {
        "id": "13dfcfb4-e882-e4d2-ad23-6053a2f306c5",
        "NOMBRE": "ESCHERICHIA COLI [E. COLI] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "cba28a0e-6707-d8fe-ac29-df32a8372f92",
        "NOMBRE": "ESCLEREMA NEONATAL"
    },
    {
        "id": "6dd8658e-d224-b206-7415-c8ea14813220",
        "NOMBRE": "ESCLERITIS"
    },
    {
        "id": "46d0aba4-efd2-bb44-6152-3ea95f380ac2",
        "NOMBRE": "ESCLERITIS Y EPISCLERITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fdec1bd5-96f2-627a-e80d-f31af10cb35f",
        "NOMBRE": "ESCLERODACTILIA"
    },
    {
        "id": "fccfda47-ea03-9340-57bb-7fda7901b81a",
        "NOMBRE": "ESCLERODERMA LINEAL"
    },
    {
        "id": "eb8a32f1-2fdf-15cb-33ac-8ee507e15d88",
        "NOMBRE": "ESCLERODERMA LOCALIZADO [MORFEA]"
    },
    {
        "id": "dd9f038f-418a-708c-c7bd-4d9ad6c31652",
        "NOMBRE": "ESCLEROSIS CONCÉNTRICA (BALÓ)"
    },
    {
        "id": "1d8ba3d9-e433-d007-e082-5bfa0fc260fa",
        "NOMBRE": "ESCLEROSIS DIFUSA"
    },
    {
        "id": "a64acf38-29da-ed80-f09c-6fadc8d4a724",
        "NOMBRE": "ESCLEROSIS HEPÁTICA"
    },
    {
        "id": "1d49e8a5-a06b-f04b-64a2-826cbfcf3715",
        "NOMBRE": "ESCLEROSIS MÚLTIPLE"
    },
    {
        "id": "61168c9b-df7f-7151-675a-7f30c0926782",
        "NOMBRE": "ESCLEROSIS SISTÉMICA"
    },
    {
        "id": "bc22a4c5-4e12-732f-0566-e4910a60e86b",
        "NOMBRE": "ESCLEROSIS SISTÉMICA INDUCIDA POR DROGAS O PRODUCTOS QUÍMICOS"
    },
    {
        "id": "0cccfbba-4219-4027-abfa-ec5ec2fd58b8",
        "NOMBRE": "ESCLEROSIS SISTÉMICA PROGRESIVA"
    },
    {
        "id": "65a9f6ed-855d-6f55-5e79-a3fa2556ea3d",
        "NOMBRE": "ESCLEROSIS SISTÉMICA, NO ESPECIFICADA"
    },
    {
        "id": "33a880f6-c8c9-ccb3-6c09-4ca60ca2be34",
        "NOMBRE": "ESCLEROSIS TUBEROSA"
    },
    {
        "id": "4de2a91f-dbc3-a020-77b4-4b8cca79129d",
        "NOMBRE": "ESCLERÓTICA AZUL"
    },
    {
        "id": "a1ab3d33-f2fe-c63f-af2b-27cf2cc1066d",
        "NOMBRE": "ESCOLIOSIS"
    },
    {
        "id": "46ebc796-4a7e-9b4f-da07-3ed458be86df",
        "NOMBRE": "ESCOLIOSIS CONGÉNITA DEBIDA A MALFORMACIÓN CONGÉNITA ÓSEA"
    },
    {
        "id": "eda3ee0d-1cc2-3044-8e13-ac606d3c0f39",
        "NOMBRE": "ESCOLIOSIS IDIOPÁTICA INFANTIL"
    },
    {
        "id": "ed9f7494-296a-6bd1-d83e-e08665061a4d",
        "NOMBRE": "ESCOLIOSIS IDIOPÁTICA JUVENIL"
    },
    {
        "id": "dce3a8b7-1e86-a2b7-01df-0de9acf4ffa5",
        "NOMBRE": "ESCOLIOSIS NEUROMUSCULAR"
    },
    {
        "id": "ea7e784a-02dc-c672-ab44-e853e1a6f72d",
        "NOMBRE": "ESCOLIOSIS POSTRRADIACIÓN"
    },
    {
        "id": "39030dc1-0426-9995-5f09-16c08f0e4c19",
        "NOMBRE": "ESCOLIOSIS TORACOGÉNICA"
    },
    {
        "id": "51dde1a7-cf9e-95df-c5e4-4559cc67c94c",
        "NOMBRE": "ESCOLIOSIS, NO ESPECIFICADA"
    },
    {
        "id": "8a76c2d4-9548-c1df-5a0a-129df1c23fe2",
        "NOMBRE": "ESFEROCITOSIS HEREDITARIA"
    },
    {
        "id": "0a676eb0-fc2c-08b5-2994-5199cee22f3e",
        "NOMBRE": "ESFEROFAQUIA"
    },
    {
        "id": "ee2df5bb-fd63-4cd4-0e6e-40f65b0d330c",
        "NOMBRE": "ESFINGOLIPIDOSIS, NO ESPECIFICADA"
    },
    {
        "id": "61e27ff6-57a2-fe6d-0048-3ac764d4b394",
        "NOMBRE": "ESGUINCE Y TORCEDURA DE LA MUÑECA"
    },
    {
        "id": "05182a26-1ee0-dc8e-ad39-e0a9951f5e3d",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE ARTICULACIONES Y LIGAMENTOS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA CABEZA"
    },
    {
        "id": "132fd1d2-9721-0014-bfff-e12042eadc1b",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE ARTICULACIONES Y LIGAMENTOS DE OTROS SITIOS ESPECIFICADOS Y DE LOS NO ESPECIFICADOS DEL CUELLO"
    },
    {
        "id": "389d3223-d91e-2f3f-17c6-c6652e9e9842",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE COLUMNA TORÁCICA"
    },
    {
        "id": "238a238a-07f9-0551-e189-9348e583e4f1",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE COSTILLAS Y ESTERNÓN"
    },
    {
        "id": "08385c13-bddf-31f8-2c45-14926c5085b8",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE DEDO(S) DE LA MANO"
    },
    {
        "id": "48b5d993-c9d8-9e7e-7b96-ecce4251cb7c",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE DEDO(S) DEL PIE"
    },
    {
        "id": "f377c416-3b4e-7173-370f-db2543f68fb4",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA ARTICULACIÓN ACROMIOCLAVICULAR"
    },
    {
        "id": "dae97025-ab5a-67a6-b40a-9d9262e3496a",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA ARTICULACIÓN DEL HOMBRO"
    },
    {
        "id": "42fdee44-c8a7-7ec6-95a9-e109c2d5731a",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA ARTICULACIÓN ESTERNOCLAVICULAR"
    },
    {
        "id": "e2ea7a35-fa34-54e9-3b3e-888621f7f630",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA ARTICULACIÓN SACROILIACA"
    },
    {
        "id": "4b15d560-2001-4ff9-f214-995c7367b1ca",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA CADERA"
    },
    {
        "id": "75a00bf5-7808-baa5-0647-6d1591026a55",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA COLUMNA CERVICAL"
    },
    {
        "id": "c95bdff5-256c-9805-1aef-dc3953aacc22",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA COLUMNA LUMBAR"
    },
    {
        "id": "72e1a9a6-a47e-da80-409f-70ef8a26beed",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE LA REGIÓN TIROIDEA"
    },
    {
        "id": "19cfe6b0-3bdb-8bf1-4add-8f6077b1bc54",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LA NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y DE LA PELVIS"
    },
    {
        "id": "c3e0d169-fc8d-bb83-c9ab-43c70ca47e79",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CINTURA ESCAPULAR"
    },
    {
        "id": "3b9ca937-c5e0-cfe0-893b-3b32c41abcc2",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "1530eecb-7735-c572-e739-d86f1fa0a53d",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TÓRAX"
    },
    {
        "id": "1101088e-2512-5c31-2b89-8f6c399fadd3",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA RODILLA"
    },
    {
        "id": "45076797-c128-ad8b-9369-23ba20ddb7fb",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DE OTROS SITIOS Y LOS NO ESPECIFICADOS DEL PIE"
    },
    {
        "id": "996b953e-2cde-8496-4ce9-9d9bfa1cc063",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DEL CODO"
    },
    {
        "id": "1c28e1f2-645d-3114-b676-c53ab7a81840",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DEL MAXILAR"
    },
    {
        "id": "c9a334d6-acbb-a625-727c-b1386c427ff5",
        "NOMBRE": "ESGUINCES Y TORCEDURAS DEL TOBILLO"
    },
    {
        "id": "993a3242-f3a3-46e3-fd0a-ac2f0c8ed49f",
        "NOMBRE": "ESGUINCES Y TORCEDURAS QUE COMPROMETEN EL LIGAMENTO CRUZADO (ANTERIOR) (POSTERIOR) DE LA RODILLA"
    },
    {
        "id": "f2bf00b3-bc41-9871-1e88-91b220fce132",
        "NOMBRE": "ESGUINCES Y TORCEDURAS QUE COMPROMETEN LOS LIGAMENTOS LATERALES (EXTERNO) (INTERNO) DE LA RODILLA"
    },
    {
        "id": "46945605-5e5a-3915-4be8-e3162611bb6d",
        "NOMBRE": "ESOFAGITIS"
    },
    {
        "id": "21cd6397-20b1-fe18-3cae-3059a9699637",
        "NOMBRE": "ESOFAGITIS TUBERCULOSA"
    },
    {
        "id": "1e71ebce-84a3-7b95-af8c-aa743263ccef",
        "NOMBRE": "ESÓFAGO DE BARRETT"
    },
    {
        "id": "596f75da-3af4-af9c-0de8-2ea871acefc8",
        "NOMBRE": "ESPARGANOSIS"
    },
    {
        "id": "ce993aa3-267a-c78a-a697-eec9d76d0f45",
        "NOMBRE": "ESPASMO ANAL"
    },
    {
        "id": "ad6ef6c3-a073-f98d-96c1-748cbb161d58",
        "NOMBRE": "ESPASMO DEL ESFÍNTER DE ODDI"
    },
    {
        "id": "e9188102-a0a7-587d-d634-8a41f90da22b",
        "NOMBRE": "ESPASMO DEL PÍLORO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "8e8a4cbb-d40d-3da3-40b3-f3fb935b1cbc",
        "NOMBRE": "ESPASMO HEMIFACIAL CLÓNICO"
    },
    {
        "id": "1d081738-b69c-0197-ad05-815897cf2012",
        "NOMBRE": "ESPASMO LARÍNGEO"
    },
    {
        "id": "89b1e5fe-b3fd-73e4-d775-040ba14e2228",
        "NOMBRE": "ESPERMATOCELE"
    },
    {
        "id": "e285314c-736f-0cef-208a-067ab9878372",
        "NOMBRE": "ESPINA BÍFIDA"
    },
    {
        "id": "2b89ad15-4d57-4c4f-8605-4cdb53b71761",
        "NOMBRE": "ESPINA BÍFIDA CERVICAL CON HIDROCÉFALO"
    },
    {
        "id": "b196d3ea-cc63-c7ca-6749-daf52f55c202",
        "NOMBRE": "ESPINA BÍFIDA CERVICAL SIN HIDROCÉFALO"
    },
    {
        "id": "67809bfd-1108-17f4-af48-29eccd623d7f",
        "NOMBRE": "ESPINA BÍFIDA CON HIDROCÉFALO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "b0e89e70-b311-ebe1-2084-b2f05e752e9d",
        "NOMBRE": "ESPINA BÍFIDA LUMBAR CON HIDROCÉFALO"
    },
    {
        "id": "d95fd84e-13bd-bd23-2ff2-dbf9174c5fb0",
        "NOMBRE": "ESPINA BÍFIDA LUMBAR SIN HIDROCÉFALO"
    },
    {
        "id": "06f1164a-528e-2ba5-1d7f-e5a47a3fc479",
        "NOMBRE": "ESPINA BÍFIDA OCULTA"
    },
    {
        "id": "92389cbf-e0e4-d29c-2963-8dc6ea221599",
        "NOMBRE": "ESPINA BÍFIDA SACRA CON HIDROCÉFALO"
    },
    {
        "id": "731f7614-1846-bcb9-cb0c-d662293a1830",
        "NOMBRE": "ESPINA BÍFIDA SACRA SIN HIDROCÉFALO"
    },
    {
        "id": "9ba614a5-b158-4eb2-386b-6b9332402eb0",
        "NOMBRE": "ESPINA BÍFIDA TORÁCICA CON HIDROCÉFALO"
    },
    {
        "id": "24743f76-591f-6552-651d-44f2678a7624",
        "NOMBRE": "ESPINA BÍFIDA TORÁCICA SIN HIDROCÉFALO"
    },
    {
        "id": "cad13bf2-1a16-fcf8-5781-e47c9786e9fa",
        "NOMBRE": "ESPINA BÍFIDA, NO ESPECIFICADA"
    },
    {
        "id": "e75a00e0-7706-614b-3094-283d2a4fed33",
        "NOMBRE": "ESPIRILOSIS"
    },
    {
        "id": "ac6faef5-32e4-7219-9ebf-4a47f4a15ae8",
        "NOMBRE": "ESPLENOMEGALIA CONGESTIVA CRÓNICA"
    },
    {
        "id": "af6337bc-f04b-556b-0f48-14b2ccc6e25c",
        "NOMBRE": "ESPLENOMEGALIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "7edc8067-520e-8472-30c5-55bb45bc7fdc",
        "NOMBRE": "ESPOLÓN CALCÁNEO"
    },
    {
        "id": "b4e1e6cc-ba04-36f8-90a0-ed5846f99c45",
        "NOMBRE": "ESPOLÓN DE LA CRESTA ILIACA"
    },
    {
        "id": "393c0e04-29d9-30c3-44a6-00c0b0f600fb",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE"
    },
    {
        "id": "f4d159a4-077f-0203-5871-ea4bd061f62e",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE"
    },
    {
        "id": "4d625184-3e9a-d345-309d-0382ff2511c5",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE JUVENIL"
    },
    {
        "id": "bb7441a1-7db6-82f0-bc40-2485952fa129",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN CERVICAL"
    },
    {
        "id": "de422ed2-884b-6070-0502-0760f65142c0",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN CERVICOTORÁCICA"
    },
    {
        "id": "5930df4b-43bc-b056-a4f7-403803d0ea8a",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN LUMBAR"
    },
    {
        "id": "f78b1152-c4c3-34bb-18b2-50a18a397c75",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN LUMBOSACRA"
    },
    {
        "id": "568d2147-6767-26b6-1460-b22ebcbabd3f",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN OCCIPITOCERVICAL"
    },
    {
        "id": "330d11d7-a1e8-2615-0c62-8970c091a6d2",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN SACRA Y SACROCOCCÍGEA"
    },
    {
        "id": "aa30d701-8afd-2118-9e64-eacd6a53cab8",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN TORÁCICA"
    },
    {
        "id": "e33d8fde-cbfc-cd96-6774-f083fa921a45",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, REGIÓN TORACOLUMBAR"
    },
    {
        "id": "3421f964-9e96-a905-b525-c234c8e50993",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, SITIO NO ESPECIFICADO"
    },
    {
        "id": "fe034934-9eda-6fce-ce22-db29583aeb82",
        "NOMBRE": "ESPONDILITIS ANQUILOSANTE, SITIOS MÚLTIPLES DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "146040ab-7179-fb16-3f56-7ac5b63e4ec4",
        "NOMBRE": "ESPONDILITIS POR BRUCELOSIS"
    },
    {
        "id": "b79df3a9-799d-44b8-1d2c-95126360b977",
        "NOMBRE": "ESPONDILITIS POR ENTEROBACTERIAS"
    },
    {
        "id": "a42b4438-03bc-84e3-c519-92742da8594e",
        "NOMBRE": "ESPONDILITIS PSORIÁSICA"
    },
    {
        "id": "29d1ce64-0672-1ed5-e862-37171c505c43",
        "NOMBRE": "ESPONDILÓLISIS"
    },
    {
        "id": "ada716c0-ff92-106a-9a63-bf4380926a08",
        "NOMBRE": "ESPONDILOLISTESIS"
    },
    {
        "id": "eb51870e-2143-daf7-8aef-90a056bb5cb6",
        "NOMBRE": "ESPONDILOLISTESIS CONGÉNITA"
    },
    {
        "id": "bc3da18a-0fe8-5d66-5097-de7c28487af8",
        "NOMBRE": "ESPONDILOPATÍA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7cbb9572-ce6d-b754-2c62-a90123d90f7a",
        "NOMBRE": "ESPONDILOPATÍA EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "47cbb6a4-073d-9393-f78e-97746dcfe7cc",
        "NOMBRE": "ESPONDILOPATÍA INFLAMATORIA, NO ESPECIFICADA"
    },
    {
        "id": "a9a20f9f-3c31-a6e9-cb39-d489c3321415",
        "NOMBRE": "ESPONDILOPATÍA INTERESPINOSA (VÉRTEBRAS \"EN BESO\")"
    },
    {
        "id": "45219808-fbc1-1381-4df3-62b6aa4afb1d",
        "NOMBRE": "ESPONDILOPATÍA NEUROPÁTICA"
    },
    {
        "id": "21aa5e1a-46a3-2a8d-b6fe-f2bafdb3ab91",
        "NOMBRE": "ESPONDILOPATÍA TRAUMÁTICA"
    },
    {
        "id": "fc929e0a-04e2-9cec-c9db-34bafba02de0",
        "NOMBRE": "ESPONDILOPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "55b4e354-6355-6abb-02ca-45a13a2bd15c",
        "NOMBRE": "ESPONDILOPATÍAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ff7e8304-45e6-2946-503e-ff72d3872967",
        "NOMBRE": "ESPONDILOSIS"
    },
    {
        "id": "fcff2f67-9216-c8a1-c50a-9e2a906020ef",
        "NOMBRE": "ESPONDILOSIS, NO ESPECIFICADA"
    },
    {
        "id": "337c5798-34dc-b11d-cd32-26c62e4bad55",
        "NOMBRE": "ESPOROTRICOSIS"
    },
    {
        "id": "a0fb1e2c-0b2d-0b7d-72fd-7aa0a1679aa8",
        "NOMBRE": "ESPOROTRICOSIS DISEMINADA"
    },
    {
        "id": "77766bb1-e045-4b4b-3ddf-41330d097ca4",
        "NOMBRE": "ESPOROTRICOSIS LINFOCUTÁNEA"
    },
    {
        "id": "8a0b911e-d863-5916-1745-00b5d114216d",
        "NOMBRE": "ESPOROTRICOSIS PULMONAR"
    },
    {
        "id": "b065b75a-1896-a2c2-c858-ceebfff0a0af",
        "NOMBRE": "ESPOROTRICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "dfceff53-2849-783d-0cb5-66e73d5dc3b0",
        "NOMBRE": "ESPRUE TROPICAL"
    },
    {
        "id": "90c45f8f-8d2c-11f6-2c0f-76542b75d5f3",
        "NOMBRE": "ESPUTO ANORMAL"
    },
    {
        "id": "e664239a-02bc-f3a2-a854-462bf71aeebc",
        "NOMBRE": "ESQUISTOSOMIASIS [BILHARZIASIS]"
    },
    {
        "id": "022f0990-acd6-55ab-5850-02ef154ac628",
        "NOMBRE": "ESQUISTOSOMIASIS DEBIDA A SCHISTOSOMA HAEMATOBIUM (ESQUISTOSOMIASIS URINARIA)"
    },
    {
        "id": "1c090814-b10f-0b21-d265-f5a45ae53126",
        "NOMBRE": "ESQUISTOSOMIASIS DEBIDA A SCHISTOSOMA JAPONICUM"
    },
    {
        "id": "b4f880ec-177c-4eed-c79c-8f7b0d1bba16",
        "NOMBRE": "ESQUISTOSOMIASIS DEBIDA A SCHISTOSOMA MANSONI [ESQUISTOSOMIASIS INTESTINAL]"
    },
    {
        "id": "29617dd3-06e2-268d-4211-32fc089d127e",
        "NOMBRE": "ESQUISTOSOMIASIS, NO ESPECIFICADA"
    },
    {
        "id": "be1c3067-856e-08a0-868f-bee7d4cccf7c",
        "NOMBRE": "ESQUIZOFRENIA"
    },
    {
        "id": "ee04dd58-89bf-8561-3336-51b4ebf153dd",
        "NOMBRE": "ESQUIZOFRENIA CATATÓNICA"
    },
    {
        "id": "adbd6f3c-0a1f-9082-9596-ad158ad2b5a2",
        "NOMBRE": "ESQUIZOFRENIA HEBEFRÉNICA"
    },
    {
        "id": "80638f07-dd66-1bcf-3778-dd323de4914b",
        "NOMBRE": "ESQUIZOFRENIA INDIFERENCIADA"
    },
    {
        "id": "2730d901-cf1e-2ce6-d786-4c6957b5e07d",
        "NOMBRE": "ESQUIZOFRENIA PARANOIDE"
    },
    {
        "id": "9cad3ea4-7e5b-d45b-5ffe-993cea6fcb7a",
        "NOMBRE": "ESQUIZOFRENIA RESIDUAL"
    },
    {
        "id": "c788234a-3a86-1dde-cef2-779adb02d919",
        "NOMBRE": "ESQUIZOFRENIA SIMPLE"
    },
    {
        "id": "e6a08156-37a8-d57e-2f33-e408cbce831c",
        "NOMBRE": "ESQUIZOFRENIA, NO ESPECIFICADA"
    },
    {
        "id": "a86ef8b4-8594-4eee-0401-8dff05d33452",
        "NOMBRE": "ESTADO ACTIVO DE TRACOMA"
    },
    {
        "id": "3ede09a5-2dfe-21f2-cda1-afc97e560293",
        "NOMBRE": "ESTADO ASMÁTICO"
    },
    {
        "id": "2f40a332-3032-4afd-ba54-eb631506766a",
        "NOMBRE": "ESTADO DE ARTRODESIS"
    },
    {
        "id": "8421c06a-3a23-45ac-5c87-f9594c0369ec",
        "NOMBRE": "ESTADO DE DERIVACIÓN INTESTINAL O ANASTOMOSIS"
    },
    {
        "id": "5b806832-40a6-0ba5-d322-417ffcf3d404",
        "NOMBRE": "ESTADO DE EMBARAZO, INCIDENTAL"
    },
    {
        "id": "4574d7f7-ec2b-fccc-5186-ca5427504a3b",
        "NOMBRE": "ESTADO DE GRAN MAL EPILÉPTICO"
    },
    {
        "id": "a98a22ee-ec58-e96b-fe22-7961733994bb",
        "NOMBRE": "ESTADO DE INFECCIÓN ASINTOMÁTICA POR EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"
    },
    {
        "id": "808da1c4-1cf8-1802-b42c-dab6db42c606",
        "NOMBRE": "ESTADO DE MAL EPILÉPTICO"
    },
    {
        "id": "317b2db5-338c-fd6f-c1c6-4b901df5de57",
        "NOMBRE": "ESTADO DE MAL EPILÉPTICO DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "274fbb92-3f16-1433-755f-6792070766cb",
        "NOMBRE": "ESTADO DE MAL EPILÉPTICO PARCIAL COMPLEJO"
    },
    {
        "id": "307ba811-f839-85a8-ce39-35e51c197f6b",
        "NOMBRE": "ESTADO DE PEQUEÑO MAL EPILÉPTICO"
    },
    {
        "id": "0c75b7c7-9739-fd3c-81f9-a572a0585666",
        "NOMBRE": "ESTADO INICIAL DE TRACOMA"
    },
    {
        "id": "3983ee93-20c3-c379-8bc2-f8d8c06a42aa",
        "NOMBRE": "ESTADO MIGRAÑOSO"
    },
    {
        "id": "2c0fe0c9-9e35-9ca2-4583-958135a254e3",
        "NOMBRE": "ESTADOS ASOCIADOS CON MENOPAUSIA ARTIFICIAL"
    },
    {
        "id": "90b133bc-9757-30d5-1341-d9d7d74f514b",
        "NOMBRE": "ESTADOS MENOPÁUSICOS Y CLIMATÉRICOS FEMENINOS"
    },
    {
        "id": "5479f13b-d864-aa1e-bc3a-9d542b8c0443",
        "NOMBRE": "ESTAFILOCOCO NO ESPECIFICADO, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "055b24c2-f191-bad0-4590-13ca658a8db5",
        "NOMBRE": "ESTAÑOSIS"
    },
    {
        "id": "4cda0c48-4999-2918-def4-caab43f5a85c",
        "NOMBRE": "ESTATURA ALTA CONSTITUCIONAL"
    },
    {
        "id": "84ad6cb8-fcee-da21-1f47-b21754f84adf",
        "NOMBRE": "ESTEATOCISTOMA MÚLTIPLE"
    },
    {
        "id": "d944fc60-6eeb-3859-eb6b-34f043fcce6a",
        "NOMBRE": "ESTEATORREA PANCREÁTICA"
    },
    {
        "id": "69f3027e-a01e-7878-5026-9f7dca553e2d",
        "NOMBRE": "ESTENOSIS (DE LA VÁLVULA) AÓRTICA"
    },
    {
        "id": "6a0f05b7-4d9c-1365-83b3-e82ec80ba03e",
        "NOMBRE": "ESTENOSIS (DE LA VÁLVULA) AÓRTICA CON INSUFICIENCIA"
    },
    {
        "id": "bd8cebac-62b6-9617-bad9-8785391920d4",
        "NOMBRE": "ESTENOSIS (DE LA VÁLVULA) MITRAL, NO REUMÁTICA"
    },
    {
        "id": "2ae32b63-0b2e-f3cf-8698-ca0d9a20d46f",
        "NOMBRE": "ESTENOSIS ADQUIRIDA DEL CONDUCTO AUDITIVO EXTERNO"
    },
    {
        "id": "4f6251c4-5108-e3c4-bedc-383885a071e4",
        "NOMBRE": "ESTENOSIS AÓRTICA REUMÁTICA"
    },
    {
        "id": "a9796f76-01de-aff0-92fa-0ad8eecffcec",
        "NOMBRE": "ESTENOSIS AÓRTICA REUMÁTICA CON INSUFICIENCIA"
    },
    {
        "id": "35302745-62af-3a97-389c-5910faed64dd",
        "NOMBRE": "ESTENOSIS CON INSUFICIENCIA NO REUMÁTICA (DE LA VÁLVULA) TRICÚSPIDE"
    },
    {
        "id": "30f8cbb8-1b69-78ca-eb0f-29fdcdf2763f",
        "NOMBRE": "ESTENOSIS CONGÉNITA DE LA ARTERIA RENAL"
    },
    {
        "id": "9ee4591c-489f-6b95-984b-980a50f459e4",
        "NOMBRE": "ESTENOSIS CONGÉNITA DE LA VÁLVULA AÓRTICA"
    },
    {
        "id": "3b3138fa-84de-41ca-fbcc-2f5effafdf74",
        "NOMBRE": "ESTENOSIS CONGÉNITA DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "f7490725-2a85-8cd2-7788-0a38eccd0562",
        "NOMBRE": "ESTENOSIS CONGÉNITA DE LA VÁLVULA TRICÚSPIDE"
    },
    {
        "id": "1dc77ef4-0f2b-23a6-088b-b00b94816095",
        "NOMBRE": "ESTENOSIS CONGÉNITA DE LA VENA CAVA"
    },
    {
        "id": "9c0768df-ba57-fed2-44ee-a94fd5f52eac",
        "NOMBRE": "ESTENOSIS CONGÉNITA DE LOS BRONQUIOS"
    },
    {
        "id": "fda80eff-06a5-614a-d5d9-da633d872659",
        "NOMBRE": "ESTENOSIS DE LA AORTA"
    },
    {
        "id": "3ceff70f-89c1-88ba-4888-13aaf73e1c24",
        "NOMBRE": "ESTENOSIS DE LA ARTERIA PULMONAR"
    },
    {
        "id": "c2b048cb-7487-4ffb-8ea4-67d35c60a5b4",
        "NOMBRE": "ESTENOSIS DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "a059ce86-8bc6-25cb-9565-207461fad3c9",
        "NOMBRE": "ESTENOSIS DE LA VÁLVULA PULMONAR CON INSUFICIENCIA"
    },
    {
        "id": "4ab54263-b47f-1ca4-6c58-9d674bd0931c",
        "NOMBRE": "ESTENOSIS DE LOS AGUJEROS INTERVERTEBRALES POR TEJIDO CONJUNTIVO O POR DISCO INTERVERTEBRAL"
    },
    {
        "id": "4a501361-c305-1123-3348-c934ab116256",
        "NOMBRE": "ESTENOSIS DEL ANO Y DEL RECTO"
    },
    {
        "id": "5224b5e3-1852-2164-f124-07e7660cf594",
        "NOMBRE": "ESTENOSIS DEL CANAL NEURAL POR DISCO INTERVERTEBRAL"
    },
    {
        "id": "5450ecc4-04d3-19df-5d38-d63aba6b716b",
        "NOMBRE": "ESTENOSIS DEL CANAL NEURAL POR TEJIDO CONJUNTIVO"
    },
    {
        "id": "3486d45e-775c-6327-1d8c-d4f8c93ce8c0",
        "NOMBRE": "ESTENOSIS DEL INFUNDÍBULO PULMONAR"
    },
    {
        "id": "6fe7907c-2d5c-822d-1a65-8bd5091995ba",
        "NOMBRE": "ESTENOSIS E INSUFICIENCIA DE LAS VÍAS LAGRIMALES"
    },
    {
        "id": "c07c2a16-21eb-f3d7-99f5-d0afe7df878c",
        "NOMBRE": "ESTENOSIS E INSUFICIENCIA TRICÚSPIDE"
    },
    {
        "id": "17fd115e-df72-a111-63e6-be4b35651dc0",
        "NOMBRE": "ESTENOSIS ESPINAL"
    },
    {
        "id": "a7568352-5357-e2cd-1e29-b7e1a87e8929",
        "NOMBRE": "ESTENOSIS HIPERTRÓFICA CONGÉNITA DEL PÍLORO"
    },
    {
        "id": "fbf2776e-a428-8c6f-ec85-2e9ef80b96ca",
        "NOMBRE": "ESTENOSIS LARÍNGEA"
    },
    {
        "id": "2bd48420-8981-cda3-148d-c04c4274a2e8",
        "NOMBRE": "ESTENOSIS MITRAL"
    },
    {
        "id": "73e2de58-a2c3-855d-150d-e01c9998cee5",
        "NOMBRE": "ESTENOSIS MITRAL CON INSUFICIENCIA"
    },
    {
        "id": "5c891cda-246e-6f1a-b953-421359abfddc",
        "NOMBRE": "ESTENOSIS MITRAL CONGÉNITA"
    },
    {
        "id": "898df04a-9025-9fd5-3b9a-5b639433b898",
        "NOMBRE": "ESTENOSIS NO REUMÁTICA (DE LA VÁLVULA) TRICÚSPIDE"
    },
    {
        "id": "d36bb16b-fc51-fafb-2e0c-f651b05d715a",
        "NOMBRE": "ESTENOSIS ÓSEA DEL CANAL NEURAL"
    },
    {
        "id": "596c8e58-e29d-13dd-4228-65d926eb16b1",
        "NOMBRE": "ESTENOSIS ÓSEA Y SUBLUXACIÓN DE LOS AGUJEROS INTERVERTEBRALES"
    },
    {
        "id": "1bdcdf32-a710-5161-2548-f1365cc21007",
        "NOMBRE": "ESTENOSIS PILÓRICA HIPERTRÓFICA DEL ADULTO"
    },
    {
        "id": "e600cbc0-57cd-b94a-6421-946f6e9dbe16",
        "NOMBRE": "ESTENOSIS SUBAÓRTICA CONGÉNITA"
    },
    {
        "id": "3abec635-9b6e-b386-bb84-37dd6616c893",
        "NOMBRE": "ESTENOSIS SUBGLÓTICA CONGÉNITA"
    },
    {
        "id": "19a5dfbf-703e-66ad-799c-9dbe68fb501f",
        "NOMBRE": "ESTENOSIS SUBGLÓTICA CONSECUTIVA A PROCEDIMIENTOS"
    },
    {
        "id": "8e3aaded-219d-d742-efb5-9f30dab68dd0",
        "NOMBRE": "ESTENOSIS TRICÚSPIDE"
    },
    {
        "id": "7c129159-5eff-b542-1651-f8386624473d",
        "NOMBRE": "ESTENOSIS Y ESTRECHEZ CONGÉNITAS DEL CONDUCTO LAGRIMAL"
    },
    {
        "id": "9ff52515-b94f-474d-e20b-01397f90857d",
        "NOMBRE": "ESTERILIDAD EN EL VARÓN"
    },
    {
        "id": "73f6e1e7-442f-77f7-9517-a95e9c051581",
        "NOMBRE": "ESTERILIZACIÓN"
    },
    {
        "id": "7a33f49d-b126-792b-e0ad-95949fa22a95",
        "NOMBRE": "ESTOMATITIS AFTOSA RECURRENTE"
    },
    {
        "id": "d8dc2b4c-d4f7-617b-cedb-dca22b3e634d",
        "NOMBRE": "ESTOMATITIS CANDIDIÁSICA"
    },
    {
        "id": "6ad44490-a7c9-0bb3-4711-395510ef3103",
        "NOMBRE": "ESTOMATITIS ULCERATIVA NECROTIZANTE"
    },
    {
        "id": "4b43d063-4310-dcfc-46c8-f10a9717ccba",
        "NOMBRE": "ESTOMATITIS VESICULAR ENTEROVIRAL CON EXANTEMA"
    },
    {
        "id": "b72a270a-2275-65b5-2e90-251290ed7dbc",
        "NOMBRE": "ESTOMATITIS Y LESIONES AFINES"
    },
    {
        "id": "5707a152-d33a-a155-bee8-cdd22725b1b7",
        "NOMBRE": "ESTORNUDO"
    },
    {
        "id": "12ab2aa6-9cf2-9f18-6009-a10f42d7aff2",
        "NOMBRE": "ESTRABISMO CONCOMITANTE CONVERGENTE"
    },
    {
        "id": "25f99e2e-b167-870a-2916-db7fb0688dcf",
        "NOMBRE": "ESTRABISMO CONCOMITANTE DIVERGENTE"
    },
    {
        "id": "9a8438af-f9e7-c0a9-585b-0168a32f46e0",
        "NOMBRE": "ESTRABISMO MECÁNICO"
    },
    {
        "id": "83f2b8ed-646f-3ad9-4466-52e62a578902",
        "NOMBRE": "ESTRABISMO PARALÍTICO"
    },
    {
        "id": "89e48a49-6064-fe2f-fb05-316ed40a0d63",
        "NOMBRE": "ESTRABISMO PARALÍTICO, NO ESPECIFICADO"
    },
    {
        "id": "ec1d2c43-948d-9c80-024f-c510f3dfaa8a",
        "NOMBRE": "ESTRABISMO VERTICAL"
    },
    {
        "id": "618a5a0e-17c7-6903-456f-7aabc43e5101",
        "NOMBRE": "ESTRABISMO, NO ESPECIFICADO"
    },
    {
        "id": "54126324-ae4e-7fc2-f69b-1ff3ac5bc825",
        "NOMBRE": "ESTRECHEZ ARTERIAL"
    },
    {
        "id": "ebc3ae8a-2d28-a05a-f686-2cf666a9c36c",
        "NOMBRE": "ESTRECHEZ O ESTENOSIS CONGÉNITA DEL ESÓFAGO"
    },
    {
        "id": "dd6dbeb9-78ae-eba5-6e86-766ca5c5758e",
        "NOMBRE": "ESTRECHEZ O ESTENOSIS DEL ESTÓMAGO EN RELOJ DE ARENA"
    },
    {
        "id": "c6120340-0e90-793c-845b-70479dbf3313",
        "NOMBRE": "ESTRECHEZ URETRAL"
    },
    {
        "id": "c62f4373-7780-ece8-e15d-129f3dc2d0a4",
        "NOMBRE": "ESTRECHEZ URETRAL CONSECUTIVA A PROCEDIMIENTOS"
    },
    {
        "id": "55983360-cc79-b62a-88ed-88bd8352ec6e",
        "NOMBRE": "ESTRECHEZ URETRAL POSTINFECCIÓN, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "25daa7e4-64df-78e3-c0e5-fdb3f98fbc16",
        "NOMBRE": "ESTRECHEZ URETRAL POSTRAUMÁTICA"
    },
    {
        "id": "3e4ab06b-6c28-b3de-c6cb-9e985b7be446",
        "NOMBRE": "ESTRECHEZ URETRAL, NO ESPECIFICADA"
    },
    {
        "id": "6e7ed87c-e7b7-ec78-2605-94c64bafffaa",
        "NOMBRE": "ESTRECHEZ Y ATRESIA DE LA VAGINA"
    },
    {
        "id": "850d77df-c3dc-a211-8563-6495f21d06a2",
        "NOMBRE": "ESTRECHEZ Y ESTENOSIS CONGÉNITA DE LOS CONDUCTOS BILIARES"
    },
    {
        "id": "ea93f654-02cf-ee23-0062-32175cfe4736",
        "NOMBRE": "ESTRECHEZ Y ESTENOSIS DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "306cf9f5-fa5e-2caa-6140-12f4047de342",
        "NOMBRE": "ESTREPTOBACILOSIS"
    },
    {
        "id": "764407db-ed08-05e0-4fbf-42189ff0674f",
        "NOMBRE": "ESTREPTOCOCO NO ESPECIFICADO COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "8c0e89e7-0fe5-5b24-e5d1-babf5c3ef735",
        "NOMBRE": "ESTREPTOCOCO, GRUPO A, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "5f416f18-0541-5211-e33d-b4f86afc832f",
        "NOMBRE": "ESTREPTOCOCO, GRUPO B, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "b09a333f-b735-6288-273d-f67a92481fde",
        "NOMBRE": "ESTREPTOCOCO, GRUPO D Y ENTEROCOCOS, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "91eecd0c-f97d-4c5c-7b06-bf382d23d88e",
        "NOMBRE": "ESTREPTOCOCOS Y ESTAFILOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "acf379f7-0c0e-fe6a-27c6-c5f795e777c5",
        "NOMBRE": "ESTRÍAS ATRÓFICAS"
    },
    {
        "id": "db89818c-838f-dc2c-b0f7-dbf7048c12fb",
        "NOMBRE": "ESTRIDOR"
    },
    {
        "id": "231562c1-1a67-d024-225b-3e32cc9aba61",
        "NOMBRE": "ESTRIDOR LARÍNGEO CONGÉNITO"
    },
    {
        "id": "2b89d2f5-d8a0-9e9d-0cde-80bc3ac0e84d",
        "NOMBRE": "ESTRONGILOIDIASIS"
    },
    {
        "id": "4031426b-5d7c-7457-8f7a-fad2f46c1bec",
        "NOMBRE": "ESTRONGILOIDIASIS CUTÁNEA"
    },
    {
        "id": "3794c292-5157-7c3c-ac23-988957c7d696",
        "NOMBRE": "ESTRONGILOIDIASIS DISEMINADA"
    },
    {
        "id": "4fd4aa14-8536-f867-b79f-bde30afbdc11",
        "NOMBRE": "ESTRONGILOIDIASIS INTESTINAL"
    },
    {
        "id": "91b5c51b-cc05-10e6-8fe3-8f97d6ded754",
        "NOMBRE": "ESTRONGILOIDIASIS, NO ESPECIFICADA"
    },
    {
        "id": "77e81b31-b1d1-73ea-9dbc-e0e8d430b07f",
        "NOMBRE": "ESTUPOR"
    },
    {
        "id": "4b69e017-16ef-aa3e-7748-284079ebd367",
        "NOMBRE": "ESTUPOR DISOCIATIVO"
    },
    {
        "id": "516a7170-47a9-6d92-5eb1-0c868d1dc7f6",
        "NOMBRE": "EUMICETOMA"
    },
    {
        "id": "41ae78ce-3a28-a929-b0bf-3caaabd93091",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "c2b75a30-8609-8c80-3e8f-6d7f0665f0f3",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "e7d5e246-4360-4621-6e29-94547670e7a9",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "b563ae94-2233-c294-7050-4e413f7517cd",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "2abde005-4cab-7295-f990-b9c5c68d3c94",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "02914ae5-f598-aad5-5589-36c787c092eb",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "66111d61-d3be-28d5-6aec-91581922e718",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "3205ba7d-c1df-7b72-02e3-b0e7343977a1",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "37463c12-3663-1108-28ae-3420070c0815",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "3cabc9ec-1552-0a30-651d-cdb90cda3bb8",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "455033c3-2d24-e464-536a-ea7b0a318ca5",
        "NOMBRE": "EVENTO NO ESPECIFICADO, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "e633333d-3390-9f22-8403-e83f5ea1fd59",
        "NOMBRE": "EVIDENCIA DE ALCOHOLISMO DETERMINADA POR EL NIVEL DE ALCOHOL EN LA SANGRE"
    },
    {
        "id": "fd38531b-6de6-aaee-0e03-aaa5c9d21302",
        "NOMBRE": "EVIDENCIA DE ALCOHOLISMO DETERMINADA POR EL NIVEL DE INTOXICACIÓN"
    },
    {
        "id": "fd331bab-eea1-b25b-ac58-001709b4a01a",
        "NOMBRE": "EVIDENCIAS DE LABORATORIO DEL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"
    },
    {
        "id": "999ab53c-8a9b-75f8-57d5-87ce05487b6b",
        "NOMBRE": "EXAMEN DE DONANTE POTENCIAL DE ÓRGANO O TEJIDO"
    },
    {
        "id": "93db7b63-6935-e21e-1471-439676453ed5",
        "NOMBRE": "EXAMEN DE LA PRESIÓN SANGUÍNEA"
    },
    {
        "id": "3a14bf3a-89f5-4b95-8d49-b0689d2464a6",
        "NOMBRE": "EXAMEN DE LABORATORIO"
    },
    {
        "id": "0521846b-4f98-7ad9-4318-e3ef6c598933",
        "NOMBRE": "EXAMEN DE OÍDOS Y DE LA AUDICIÓN"
    },
    {
        "id": "06ed83fb-6650-d9f1-a6f5-aacff3d6a5c6",
        "NOMBRE": "EXAMEN DE OJOS Y DE LA VISIÓN"
    },
    {
        "id": "8b2cd989-542c-de6f-128b-f965108515f0",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA CIERTOS TRASTORNOS DEL DESARROLLO EN EL NIÑO"
    },
    {
        "id": "8fb9ee68-ef5e-d9c2-1cfc-9fe3e6e8ab25",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA DIABETES MELLITUS"
    },
    {
        "id": "60fa3bbf-a7eb-97d5-368c-92b52336963e",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"
    },
    {
        "id": "7ea0362f-5a79-9aae-b6f6-dc6c293be41e",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES DE LA SANGRE Y ÓRGANOS HEMATOPOYÉTICOS Y CIERTOS TRASTORNOS DEL MECANISMO DE LA INMUNIDAD"
    },
    {
        "id": "b80b38d5-6ab2-463a-f8fe-60ddbd1c479c",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES INFECCIOSAS INTESTINALES"
    },
    {
        "id": "ff9aafd1-0224-441b-ae1e-dc82ac965cde",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES INFECCIOSAS Y PARASITARIAS"
    },
    {
        "id": "1a53fca0-9263-59ad-35e9-69d8dce0ecbf",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES INFECCIOSAS Y PARASITARIAS NO ESPECIFICADAS"
    },
    {
        "id": "4bcb994c-ddcd-5968-9f1f-58f2a664ec9b",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA INFECCIONES DE TRANSMISIÓN PREDOMINANTEMENTE SEXUAL"
    },
    {
        "id": "95415e3c-8706-5cc2-f652-e79078e9def5",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA MALFORMACIONES CONGÉNITAS, DEFORMIDADES Y ANOMALÍAS CROMOSÓMICAS"
    },
    {
        "id": "c493f904-233b-b4d5-70ab-b90744a8a196",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES BACTERIANAS"
    },
    {
        "id": "31b913ca-f419-ea0b-9bb2-48e8f37b0d79",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES DEBIDAS A PROTOZOARIOS Y HELMINTOS"
    },
    {
        "id": "5f054933-3ee1-ca3e-5607-133fbd32ff78",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS"
    },
    {
        "id": "2dc29a1a-2fd5-fad8-1ac1-d3c69eeb920b",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES VIRALES"
    },
    {
        "id": "5b6e2305-79d6-f471-32ae-607c269dfc27",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES Y TRASTORNOS"
    },
    {
        "id": "0efe9e29-8c7a-0bcc-6779-afacae83ca73",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES Y TRASTORNOS ESPECIFICADOS"
    },
    {
        "id": "441afe84-361c-0d74-9341-8f7dbb6c7d83",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS CARDIOVASCULARES"
    },
    {
        "id": "9967a536-aea6-1c61-d45f-efc610ea86c5",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DE LA NUTRICIÓN"
    },
    {
        "id": "a03ee55e-1459-cab5-9297-1daaf69d4f01",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DEL OJO Y DEL OÍDO"
    },
    {
        "id": "391e5eb6-6afa-de8b-2d12-eadaf01d57b3",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "0aad34d4-ea80-4827-37e6-7d3793196392",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUBERCULOSIS RESPIRATORIA"
    },
    {
        "id": "1624c436-4762-9253-b6d5-e6bbb7f7b55b",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE ESTÓMAGO"
    },
    {
        "id": "e52ce170-c494-de72-2747-5cfd7c469609",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA MAMA"
    },
    {
        "id": "a70fbd9e-7296-9491-fc27-a1b503d617d5",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA PRÓSTATA"
    },
    {
        "id": "007cc3b6-0828-7f61-5114-19b5222f0ea4",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA VEJIGA"
    },
    {
        "id": "573f0c87-eb01-51b1-14ce-5c1fed7ba4a8",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "3a0e3f7f-bf3c-acc6-6cad-8b3b6fdef2b5",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DEL CUELLO UTERINO"
    },
    {
        "id": "29fd5cef-3bdf-666b-4db1-fe6b660d031c",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DEL INTESTINO"
    },
    {
        "id": "b241c2bb-2347-4d41-dbc7-4cb14a655bd0",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMORES"
    },
    {
        "id": "73f9db7e-2737-fb04-dda7-6e363c959d28",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMORES DE ÓRGANOS RESPIRATORIOS"
    },
    {
        "id": "dbda954b-cb24-5e64-2c62-89d49ad27cbf",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL PARA TUMORES DE OTROS SITIOS"
    },
    {
        "id": "7fdd5835-7164-8d56-4ba5-4ce3587cd835",
        "NOMBRE": "EXAMEN DE PESQUISA ESPECIAL, NO ESPECIFICADO"
    },
    {
        "id": "418b3dfe-c17e-4e89-99d9-e2a187dfb096",
        "NOMBRE": "EXAMEN DE SALUD OCUPACIONAL"
    },
    {
        "id": "2a1497af-a010-f838-9002-c3571a65be9f",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A CIRUGÍA POR OTRAS AFECCIONES"
    },
    {
        "id": "78e6d341-17c0-c210-0cba-8a12b574f07c",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A CIRUGÍA POR TUMOR MALIGNO"
    },
    {
        "id": "9932c10b-5f6a-c1cc-f9e8-420fbc6e6eec",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO POR OTRAS AFECCIONES"
    },
    {
        "id": "8d1c9686-acb4-0e08-1cc7-b56ce5d11381",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO POR TUMOR MALIGNO"
    },
    {
        "id": "7a60357a-54eb-ab51-9f38-d4a51e59fa68",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A PSICOTERAPIA"
    },
    {
        "id": "0ef5e1dd-bb27-30e1-4fe8-d6a69b893b41",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A QUIMIOTERAPIA POR OTRAS AFECCIONES"
    },
    {
        "id": "863d2137-f42d-8851-c3b0-d435ad369d55",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A QUIMIOTERAPIA POR TUMOR MALIGNO"
    },
    {
        "id": "ff3c03a0-8cf7-c06f-a7d2-9c0552fd4f62",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A RADIOTERAPIA POR OTRAS AFECCIONES"
    },
    {
        "id": "b6fd3ca4-216d-7e8a-efb1-8ff7911ffff7",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A RADIOTERAPIA POR TUMOR MALIGNO"
    },
    {
        "id": "dfac6b3e-c0da-3e26-feb9-3c13b6516cd7",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO COMBINADO POR OTRAS AFECCIONES"
    },
    {
        "id": "0dd2085e-a6a2-8bb9-a85b-72afe3615876",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO COMBINADO POR TUMOR MALIGNO"
    },
    {
        "id": "f8fb6f05-eb11-5897-589e-70a1288d832e",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO DE FRACTURA"
    },
    {
        "id": "e59a8d61-6d2b-1f2c-8f95-6d9d2f0bc724",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO NO ESPECIFICADO POR OTRAS AFECCIONES"
    },
    {
        "id": "d5f1830f-c132-d2e0-7994-8c13b5fe88aa",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO NO ESPECIFICADO POR TUMOR MALIGNO"
    },
    {
        "id": "c6bc96b0-dfd2-bb37-1378-0c066f962c72",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO POR OTRAS AFECCIONES DIFERENTES A TUMORES MALIGNOS"
    },
    {
        "id": "3fa14e08-e585-787a-e2bb-9ef9d2380e9b",
        "NOMBRE": "EXAMEN DE SEGUIMIENTO CONSECUTIVO AL TRATAMIENTO POR TUMOR MALIGNO"
    },
    {
        "id": "1ecdb521-92a2-2923-7982-26527f166391",
        "NOMBRE": "EXAMEN DEL ESTADO DE DESARROLLO DEL ADOLESCENTE"
    },
    {
        "id": "850e388a-9f84-7fc7-c1a1-75f38ecebbfe",
        "NOMBRE": "EXAMEN DURANTE EL PERÍODO DE CRECIMIENTO RÁPIDO EN LA INFANCIA"
    },
    {
        "id": "8d0c8d25-d0dd-5333-fd38-d607fdf72358",
        "NOMBRE": "EXAMEN ESPECIAL NO ESPECIFICADO"
    },
    {
        "id": "88f2aaa5-9a48-6a87-c193-ca101f7f8011",
        "NOMBRE": "EXAMEN GENERAL E INVESTIGACIÓN DE PERSONAS SIN QUEJAS O SIN DIAGNÓSTICO INFORMADO"
    },
    {
        "id": "bb7985bc-225c-4720-d122-ebd8c288d4d5",
        "NOMBRE": "EXAMEN GINECOLÓGICO (GENERAL) (DE RUTINA)"
    },
    {
        "id": "b459f1ad-74c1-9fab-4006-06b594c112d4",
        "NOMBRE": "EXAMEN MÉDICO GENERAL"
    },
    {
        "id": "e5b0a1cc-48b4-270c-301e-f17aadf79b03",
        "NOMBRE": "EXAMEN ODONTOLÓGICO"
    },
    {
        "id": "e3074203-be19-7a7c-8f48-303fa2e98e82",
        "NOMBRE": "EXAMEN PARA ADMISIÓN A INSTITUCIONES EDUCATIVAS"
    },
    {
        "id": "e4aca862-5ba7-902c-edea-fd0aaa33da0b",
        "NOMBRE": "EXAMEN PARA ADMISIÓN A INSTITUCIONES RESIDENCIALES"
    },
    {
        "id": "b7d68a4d-7a56-e011-6eb7-adb8bced7218",
        "NOMBRE": "EXAMEN PARA COMPARACIÓN Y CONTROL NORMALES EN PROGRAMA DE INVESTIGACIÓN CLÍNICA"
    },
    {
        "id": "f49a2843-5c8a-6ca5-0bae-c27bb59e183b",
        "NOMBRE": "EXAMEN PARA FINES ADMINISTRATIVOS, NO ESPECIFICADO"
    },
    {
        "id": "9803ac22-583d-2c18-aa79-fed3c4dfc81d",
        "NOMBRE": "EXAMEN PARA FINES DE SEGUROS"
    },
    {
        "id": "0c716bfe-d49f-f29e-b7be-d686a443fb09",
        "NOMBRE": "EXAMEN PARA OBTENCIÓN DE LICENCIA DE CONDUCIR"
    },
    {
        "id": "0838d2e3-6698-0c72-943e-6a2b57fd4b38",
        "NOMBRE": "EXAMEN PARA PARTICIPACIÓN EN COMPETENCIAS DEPORTIVAS"
    },
    {
        "id": "2b4894b5-e6bd-7d6d-41bd-1428057fa9b3",
        "NOMBRE": "EXAMEN PARA RECLUTAMIENTO EN LAS FUERZAS ARMADAS"
    },
    {
        "id": "5c79a003-cb62-9e01-8832-6d49d5ddad8d",
        "NOMBRE": "EXAMEN PREEMPLEO"
    },
    {
        "id": "97f401d2-6a08-71fa-8cfb-97b3025736e4",
        "NOMBRE": "EXAMEN PSIQUIÁTRICO GENERAL, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "04055b9f-324c-edf8-60ec-3af7f099d910",
        "NOMBRE": "EXAMEN PSIQUIÁTRICO GENERAL, SOLICITADO POR UNA AUTORIDAD"
    },
    {
        "id": "9970a63f-4361-ed34-55e4-c517c5b78d0e",
        "NOMBRE": "EXAMEN RADIOLÓGICO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "4f71347d-46c0-f0dc-c60a-67b71410d828",
        "NOMBRE": "EXAMEN Y ATENCIÓN DEL POSTPARTO"
    },
    {
        "id": "158ca1a0-8646-be94-4044-100d806ca9f1",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN CONSECUTIVOS A ACCIDENTE DE TRABAJO"
    },
    {
        "id": "0286f634-b4c6-38e5-b760-79ee04e02170",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN CONSECUTIVOS A ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "76577996-a387-e793-8fa1-7c0e5440cf90",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN CONSECUTIVOS A DENUNCIA DE VIOLACIÓN Y SEDUCCIÓN"
    },
    {
        "id": "bae97dbf-2a7b-4698-0af0-6c37f9f0f7f4",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN CONSECUTIVOS A OTRA LESIÓN INFLIGIDA"
    },
    {
        "id": "b133fb58-3399-bc26-dabf-6d943d007820",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN CONSECUTIVOS A OTRO ACCIDENTE"
    },
    {
        "id": "8d3d114f-d032-4362-0412-6489a48caebd",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN POR OTRAS RAZONES"
    },
    {
        "id": "44563555-d1fc-860b-fa72-ac1a7737309a",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN POR OTRAS RAZONES ESPECIFICADAS"
    },
    {
        "id": "a43ccfec-b400-4091-a5a0-ad8705ca7465",
        "NOMBRE": "EXAMEN Y OBSERVACIÓN POR RAZONES NO ESPECIFICADAS"
    },
    {
        "id": "a8eab431-dffd-d910-3c07-b8a364d93a26",
        "NOMBRE": "EXAMEN Y PRUEBA DEL EMBARAZO"
    },
    {
        "id": "9299464c-6397-220d-b682-31bb1323fb6e",
        "NOMBRE": "EXÁMENES Y CONTACTOS PARA FINES ADMINISTRATIVOS"
    },
    {
        "id": "d2627153-b08d-0dc9-bc93-55fb791f931f",
        "NOMBRE": "EXANTEMA SÚBITO [SEXTA ENFERMEDAD]"
    },
    {
        "id": "0e6d7a1a-5d12-3ffe-3f65-f73daf2aeebb",
        "NOMBRE": "EXCESIVA CANTIDAD DE SANGRE U OTRO LÍQUIDO ADMINISTRADO DURANTE UNA INFUSIÓN O TRANSFUSIÓN"
    },
    {
        "id": "e8b0221f-fbfe-7ba3-242b-185090509d61",
        "NOMBRE": "EXCESO DE ANDRÓGENOS"
    },
    {
        "id": "434367ae-b596-e761-645e-832f94f80f13",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS"
    },
    {
        "id": "79b7846a-382f-42dc-6797-5c1e700e1352",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "9fd29cda-26fc-6929-4ee5-d426c68f46a3",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "d5886156-6f38-24ac-c217-0d70b8e29fdc",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, CALLES Y CARRETERAS"
    },
    {
        "id": "cded0ee8-375e-b99e-abc2-a8530b5c5454",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "38e9533d-d42f-876c-7dba-543c70a83cb1",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "1cafcec2-014b-b247-0f61-786cdf4cb081",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, GRANJA"
    },
    {
        "id": "3bf14b13-3187-2a74-2fe2-ea07a2977985",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "afb6a463-31b9-2a5a-75ce-0af9273e96d8",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "52ad9197-9cd3-25fd-b093-fc243c939a07",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "67ea9a4c-c5d9-a9db-ff5f-dcebbc17ae47",
        "NOMBRE": "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, VIVIENDA"
    },
    {
        "id": "4b414388-3ab6-eb2c-e1dd-e2de2edabfb9",
        "NOMBRE": "EXCESO DE ESTRÓGENOS"
    },
    {
        "id": "990e271e-45b9-0864-a1e5-5d49fef7aa5c",
        "NOMBRE": "EXCESO E INSUFICIENCIA DE LA CONVERGENCIA OCULAR"
    },
    {
        "id": "54407e32-c671-6d62-2bcf-e88295fe76de",
        "NOMBRE": "EXFOLIACIÓN DE LOS DIENTES DEBIDA A CAUSAS SISTÉMICAS"
    },
    {
        "id": "42bb8308-8da6-7446-7c25-1a533ba2f636",
        "NOMBRE": "EXHIBICIONISMO"
    },
    {
        "id": "de486584-04b7-c662-1f57-8a297aeaaa68",
        "NOMBRE": "EXOFTALMIA HIPERTIROIDEA"
    },
    {
        "id": "ed496b8e-07b8-5ef6-e8d9-6bbc5f2638e9",
        "NOMBRE": "EXÓNFALOS"
    },
    {
        "id": "a0080d61-dcff-7bcd-b0fb-9e62984df9e5",
        "NOMBRE": "EXOSTOSIS CONGÉNITA MÚLTIPLE"
    },
    {
        "id": "93a27564-1534-e86b-b808-f2e901d42bac",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0086e534-f6da-021d-fe18-adead030624c",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "6e47e984-ecb8-4726-98b8-54c790033a46",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, CALLES Y CARRETERAS"
    },
    {
        "id": "7759993e-f50d-7122-7869-f710e13c46db",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "631cbdd9-a12f-98b9-13d2-11dadfdf6d97",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6db90c4f-97ed-ec10-4544-ffdc65cac72b",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, GRANJA"
    },
    {
        "id": "952689d1-c65a-7075-1e36-a3ee1e385d50",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "2f66ab19-7b3d-230b-8c7f-2d545411a169",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9010a1de-ac32-765f-9c21-766d54f3ce42",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ce704fd7-da0a-6084-881f-edd11405e8b0",
        "NOMBRE": "EXPLOSIÓN DE FUEGOS ARTIFICIALES, VIVIENDA"
    },
    {
        "id": "cb17d2fa-f549-64b4-2ad6-fa5bf8bc612d",
        "NOMBRE": "EXPLOSIÓN DE FUEROS ARTIFICIALES"
    },
    {
        "id": "7e502dbd-f764-594a-d14f-33de68028403",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES"
    },
    {
        "id": "ecb43e59-5ff1-6963-c357-2a4e95cad3d8",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "6849ca01-57b9-c850-46ed-3bae82084ff8",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "cd8178ad-2f57-ac78-d1b4-6bbd36c9e23e",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, CALLES Y CARRETERAS"
    },
    {
        "id": "9b83d0ab-aaa6-7ca8-9a7f-12e3c40ff56b",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "79267952-e6ef-e8ba-c373-ac182c92a8e1",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "4bdaceb5-411f-ae29-9ac0-4d5323da4862",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, GRANJA"
    },
    {
        "id": "e7db63b4-cda0-618c-6ffb-8849de16e84e",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cf88b877-4479-2627-5719-fb53ee321293",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "32c2b68f-c33b-adaf-554d-4963a0ccb8e2",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "f9879486-16ef-c1ed-5400-127edbdf8159",
        "NOMBRE": "EXPLOSIÓN DE OTROS MATERIALES, VIVIENDA"
    },
    {
        "id": "b6d77321-38cc-c6f3-c8c1-107d5b456720",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA"
    },
    {
        "id": "7c5334de-eea1-7e40-5000-141458b77a2a",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "17e93379-16ed-5857-f8d1-f8ac086dd23e",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "d1404554-18da-84c5-4d5b-3b4d743526f9",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, CALLES Y CARRETERAS"
    },
    {
        "id": "f56741a4-943d-8cf1-765c-3d11e1fd937e",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "508ce75e-fab8-984c-6ece-3ab4279ccff8",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "29d0b227-b93a-6eb4-0636-35d150cdbdf3",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, GRANJA"
    },
    {
        "id": "11336b7b-4410-8ea2-7069-324d35966e6a",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f99f60e3-437e-5fcc-ae87-ee67e1804f35",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "76f1c365-5743-695d-734e-932c549443a2",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "66e7e9f7-0fc1-c01d-7534-983609a164f3",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CALDERA, VIVIENDA"
    },
    {
        "id": "703f2cf9-9530-d519-31c6-c568bac4670b",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS"
    },
    {
        "id": "dd093952-cdca-3500-f1a7-b2e8460f099e",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "74d7724a-e9f3-1ccb-6b31-11e0efc17d5d",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "68cf8bbf-f507-af59-1303-863147887ac9",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, CALLES Y CARRETERAS"
    },
    {
        "id": "f762d8a8-178a-4938-2b7d-0bdbb2cd280d",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "00d066a1-da24-8a00-2ce1-67712d4fa38a",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "aec3909c-f69d-d1ec-67b9-29da3f3a3f5e",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, GRANJA"
    },
    {
        "id": "7c96b72a-9802-21bd-f477-35cdeb959732",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cae586fc-74c3-1d65-ca11-b5081a30bce0",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "179b84bf-2fa0-d4db-af75-7dd6722be901",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "90da5500-5d50-edb6-bc79-84bc2d9692ac",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE CILINDRO CON GAS, VIVIENDA"
    },
    {
        "id": "74c022b5-336b-2e0a-dd09-9157f46aab74",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA"
    },
    {
        "id": "35475250-8e8e-8c86-d242-5865ffb3d22d",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "74665592-71c4-2c36-280e-523d14f18eca",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "933859bd-7c40-e39b-6780-f40643a1ec8c",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, CALLES Y CARRETERAS"
    },
    {
        "id": "f5097bbd-9e2c-c48d-752b-15b942501cc8",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "260de4f5-4990-c8ef-6a24-7c703111a861",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "602064ce-b8eb-b094-d619-500b5cdfe799",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, GRANJA"
    },
    {
        "id": "9022cec4-0db3-1b80-add2-c413c8b356eb",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3bdc05ca-882e-27b0-ef63-4102282beda3",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "56cee984-c49a-88e7-0822-24dff6dbce81",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "5eea5b9c-c213-a483-fe3a-666d13d8aa05",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE NEUMÁTICO, TUBO O MANGUERA DE GOMA PRESURIZADA, VIVIENDA"
    },
    {
        "id": "af240314-f53c-147b-9376-db52475a3b0c",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADO"
    },
    {
        "id": "a9b9cd7c-fa5a-74c1-4545-21f1b0d7a944",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a92f5f08-4ba1-4cdc-6571-ab6198dd55b9",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "3e646a2e-d863-8b63-b266-233f79fd14ed",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "1098285b-2b2c-00fe-8107-86b06dfe4751",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "709c4bca-d4b6-075b-7c24-2342eeadf6d8",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "1ff36cb9-8b4e-b9af-b014-eae612205d1c",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, GRANJA"
    },
    {
        "id": "6d8c81d9-34d1-4ad5-b581-cb7f84f7172d",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7f4e9a08-7943-e329-6b79-9b7ef0d19a1c",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "301bf10f-d96b-7e8b-09ba-f7ae715792c0",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "239d5534-146a-0180-cfc7-2de036314003",
        "NOMBRE": "EXPLOSIÓN Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "f5458107-b943-ce98-0a7b-267f98ef1b3d",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL"
    },
    {
        "id": "402e81e8-9093-628f-cb0e-329872c0e3a2",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "017b7ddc-d097-e6de-abc0-0acf7bd46df7",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8374a311-4a8c-937f-7155-a7f24e0a4374",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, CALLES Y CARRETERAS"
    },
    {
        "id": "e16cf82e-a6fd-33f9-612f-b9adc2feeb1f",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "559e9a6b-d525-66d2-8d54-1ac01effa732",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "0d375ea4-593e-21f0-9e3c-86bc0ae119a6",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, GRANJA"
    },
    {
        "id": "30ba5c33-75a9-5b2e-c06e-646c46bb04a0",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "31841d74-5e28-61e5-9483-80213307c865",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "bed461b1-b471-fbd8-ff48-feac17ec9828",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b4263e46-d088-987e-e14d-be12c9c35658",
        "NOMBRE": "EXPOSICIÓN A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, VIVIENDA"
    },
    {
        "id": "26dcca40-4a95-52a2-dd2b-7070c1c61eb3",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN"
    },
    {
        "id": "1562bd67-265e-10ad-59b8-f15d48eda815",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "2b90c51e-edfb-8410-96a9-2fd205176f3e",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2ed421bb-5457-a7e5-2c70-70625d764509",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "ea5becc7-d609-7c6e-f196-223a0fe97aef",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e9753afc-b96c-8945-c4e0-9f8dba9e9b30",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "89bbbbcc-b363-6c39-5f47-52a8b388c012",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, GRANJA"
    },
    {
        "id": "a5c7e4ae-8313-3e3d-18fd-f14050a7a45b",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "0fa6b27a-6c9f-ae33-0b58-17d83926bae6",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8799168c-d61e-8561-96f5-e1f9ca77eebc",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "885607a3-08bb-a247-b9f1-3a4d3464adc8",
        "NOMBRE": "EXPOSICIÓN A CHORRO DE ALTA PRESIÓN, VIVIENDA"
    },
    {
        "id": "9603b1cc-44f6-0ecc-d898-83cd63a58deb",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA"
    },
    {
        "id": "60d3fba4-4c92-12ee-4427-1e6064c78a4a",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "bbff23d3-7a6a-422b-4a31-679035c94ad2",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "05bacf6a-61b9-1103-af25-f7eda949cf39",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, CALLES Y CARRETERAS"
    },
    {
        "id": "afd14341-8a8a-7459-c96e-327510fa449b",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "72a54181-d771-f09c-2818-f7547e26046c",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "14fe60a4-909d-2639-0c9b-6bca9c129e27",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, GRANJA"
    },
    {
        "id": "d1b581cd-a395-2d51-e6be-cd93164e8f8e",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "d7e3eb32-4857-5b58-337d-99ab07e545ea",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "13af17ed-3a5f-64b9-86c5-0c0bfa884779",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ade68ff6-2791-7b45-16f6-6f85ba330a4b",
        "NOMBRE": "EXPOSICIÓN A CORRIENTE ELÉCTRICA NO ESPECIFICADA, VIVIENDA"
    },
    {
        "id": "b5037298-7c34-0335-771d-8b649f65d00d",
        "NOMBRE": "EXPOSICIÓN A FACTORES DE RIESGO OCUPACIONAL"
    },
    {
        "id": "612cf874-3cc1-42c5-bd54-e2bbb5df3e1c",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS"
    },
    {
        "id": "591d7f58-bc1f-dd6d-96c9-461a38af8378",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS QUE CAUSAN OTRAS LESIONES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "340ee889-c6b1-5663-8c6c-f79d8956f2c6",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "666c1b76-a343-a9e8-b236-364e69f44997",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a54ea17d-aac6-b4bf-fd3d-18e3da884682",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "d5ae634a-3e99-b6df-6ec0-bba9cdc9685b",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a5200084-1fcd-fdb1-86dd-afce14c037a0",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e697a0e7-3492-b6b2-87cb-5ea7db917280",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "f10eb2c0-b609-3bfc-cfeb-b6e22a082e8b",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "90d5d2c4-a2f9-9db1-1544-9f030de3e755",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e8537be5-5d94-d960-8c0b-bb628974366b",
        "NOMBRE": "EXPOSICIÓN A FACTORES NO ESPECIFICADOS, QUE CAUSAN FRACTURA"
    },
    {
        "id": "4ac4f9be-4499-ae21-5e80-955e503954f8",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL"
    },
    {
        "id": "ed0d4e33-7cfe-88cb-686f-a4178ed884ca",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "fc80003a-135b-1438-6545-35e0975cab99",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "238336a0-1e74-d999-ba3b-20b209127d50",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, CALLES Y CARRETERAS"
    },
    {
        "id": "d90d1f95-767d-a795-4fde-4f37dc21ebcf",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0303dd91-8b19-f480-b263-daf952ba52e2",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "bf8726ea-1637-867a-fe92-8dee6976b2ad",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, GRANJA"
    },
    {
        "id": "f3a4bb1f-4a64-fb12-0587-78eaf67fd083",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "11ade6ee-41d4-1da6-ddae-6b047de6a8a3",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "78071f2e-3704-c174-6eae-4e3176e4bfa9",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "c590aa5e-a178-26c9-df77-0c56c884ba99",
        "NOMBRE": "EXPOSICIÓN A FRÍO EXCESIVO DE ORIGEN ARTIFICIAL, VIVIENDA"
    },
    {
        "id": "bdab112d-c70c-cf32-3e50-fb576b147c3d",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN"
    },
    {
        "id": "2b3230bb-c3af-b64a-2638-e84db0a62441",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "2a52e863-b0ba-ec33-48e7-9345c57c7dc8",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "61eca769-702d-d003-2ecc-6e457cdec103",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "9f0114ab-836c-8c94-5898-685a83bba029",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3b733c62-ea37-afca-c112-2c3eb9575a0e",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "3af50a22-05a6-42b8-6323-227e3c88c360",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, GRANJA"
    },
    {
        "id": "409cbd65-8130-3e05-9abe-e0919f5d9754",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "dc1345fd-a667-e2b0-515d-4a61d9c4face",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ff2f713d-a4df-9d94-c627-d0b192e7ad05",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b4a5c809-ae82-b172-5de6-b07cd0aceb25",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, VIVIENDA"
    },
    {
        "id": "64f69968-f7c6-868f-88f8-f096ff856a05",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN"
    },
    {
        "id": "2bb2d24d-5d92-0713-c5ec-dcda469adc95",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "14007b5c-b19e-d9d0-8095-9744b78149fa",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "9a691938-307f-2372-d4f4-e8cba81e37f8",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "4e409c08-d1c5-6e68-ead4-971af8d9f2fb",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "99cac5db-7b64-a32a-a420-401b0812f9b8",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "1cbb3f1e-b473-a3b6-9ef1-c9e5c256d11f",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, GRANJA"
    },
    {
        "id": "984de6ee-9a58-d5c2-f0b7-ede7a289bb7d",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "0efe44f0-e1b8-549e-a744-20ba2c847fc9",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "231f54f8-796a-f743-b73c-b8cc1daf32f6",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d7ffed2f-e062-7672-e292-c399b6102978",
        "NOMBRE": "EXPOSICIÓN A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, VIVIENDA"
    },
    {
        "id": "a8579410-e65c-3323-25b9-bca522366fd3",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN"
    },
    {
        "id": "3280221d-4560-966c-924f-57bdb02481e7",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "1e4c80b3-dc91-5bf9-748a-14efe5842a90",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "406cecad-23f0-e04c-642b-14f1758692b5",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "8e677f17-a116-cfcc-163d-d0b138a3f2b4",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "f12e88e8-5349-47a0-c76a-ea9451a62fd2",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "3477e6a4-9431-0d65-4644-ddb757e907b9",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, GRANJA"
    },
    {
        "id": "10085f87-faeb-f756-7792-28d85701dd9e",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "96ab4986-56f0-0564-d60a-7bf37dc4086c",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "07b603ce-6313-ad50-008d-5a0a5429f606",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a0d1a411-755d-f972-f774-eec58788226d",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCIÓN, VIVIENDA"
    },
    {
        "id": "cb706fa2-5552-cdab-06a0-f0a585317a1b",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN"
    },
    {
        "id": "3b492573-c1c2-e773-34f1-040182ddae1f",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f8e08674-bb7a-2a23-766b-1fe184d83476",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5989614f-01fa-8367-46d6-f6057d69fb3d",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "5ab75791-c0b6-6e42-112d-4be89c344c70",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5c39f21f-1d40-9668-07e1-b7e87e4337d9",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "d24dda6b-51c1-4c06-6610-7e067582cda6",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, GRANJA"
    },
    {
        "id": "1836baa8-e5c6-b86a-115c-58cd2ea0f4e4",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a599ce1f-812e-148e-4677-880f6e04a60a",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f3bb070c-b4c6-efda-7ce4-55a01d6721ba",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "c02daf5a-10e3-474c-c520-56721a54539c",
        "NOMBRE": "EXPOSICIÓN A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCIÓN, VIVIENDA"
    },
    {
        "id": "a728e452-5f63-ea9e-3c85-27b92ce2168c",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL"
    },
    {
        "id": "89509054-b048-58a8-3e0b-049d7ab427b1",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "53216d03-9a67-3aac-38c5-3828af3f2fcb",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "7e6a6bc3-2276-6b75-7507-c9d2ab04e3d9",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, CALLES Y CARRETERAS"
    },
    {
        "id": "3b542083-fef2-4299-48ca-0691bb7f6d7e",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "aacfcd01-626c-d3d3-b260-524a7cecdfc4",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "9ba3f91b-70d7-1401-aa22-c999e430e46d",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, GRANJA"
    },
    {
        "id": "208b2fda-7a44-ec36-2d06-8c7a9d654e71",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "61c3d448-5f49-f94c-cb7b-467544bf191b",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8a26d417-219d-5ac4-38ba-0333f4202688",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "28d20c3a-6cb0-8c6c-2338-5badcf7f0d2a",
        "NOMBRE": "EXPOSICIÓN A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL, VIVIENDA"
    },
    {
        "id": "799faefd-15c7-bfe7-6588-bbf85389bfff",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS"
    },
    {
        "id": "532700e3-27aa-caa1-c820-8a227de48064",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "ef4667e4-5fb1-deb0-d0d7-a8203c31c3b5",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2b08426c-56dc-54fe-a37c-bcada577da62",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "5e02f927-8bdd-ffcd-5def-ef39c4be5a68",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e372f3cc-a155-2f22-e9db-606aab7e69b0",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "18c3d1fe-dbad-8bdb-c4bf-d29dfa94d2db",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "7796ffe3-767a-db1f-9a3f-c9afa6344282",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ecbc2460-d4f1-65c8-355d-9b9f0a8d038c",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "71c460b3-65c7-04cb-bdf2-ed60f7869ca0",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d5c6b2a8-c9f6-f613-72c6-54839e72f61b",
        "NOMBRE": "EXPOSICIÓN A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "79a985a7-c3df-3003-3e3b-7daf598836ce",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE"
    },
    {
        "id": "28cce6ab-cb1e-2040-ec22-dc5d27a2474a",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d1693f22-09c1-33c1-338f-743163c64ba2",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ac5d981a-6f97-975b-ee81-0d047438f222",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, CALLES Y CARRETERAS"
    },
    {
        "id": "ab1882b6-cde7-979d-5977-04d3fb59542e",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5a790210-289c-c213-d5ce-078e2703bcc1",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "7838a06a-12c4-21c6-637e-de3b05da9f75",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, GRANJA"
    },
    {
        "id": "a7002761-d5f0-aec1-668a-cfdfd2a23506",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3d5dfbad-0ab7-c196-663a-8290202c7ff7",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f0418de3-4a73-aee1-c66f-c9e515a30624",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6e5b87c8-eaea-d11a-101c-bfcdc794f34b",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN DE MATERIAL ALTAMENTE INFLAMABLE, VIVIENDA"
    },
    {
        "id": "b5d58ca7-2980-012b-514d-1411cd508516",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS"
    },
    {
        "id": "d33e2edf-ec66-8fec-a9c7-c68c9e08f2e7",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "19878f60-6759-ac94-39c3-ca4937783745",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c86929ab-2247-3e17-4a8f-2ee58d56e52b",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, CALLES Y CARRETERAS"
    },
    {
        "id": "ee125319-37b7-360e-092d-e88b6d3ccd32",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "4cb3c362-e9e1-8f68-494f-f23f23de862b",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b0acc4e1-26b6-6ceb-f027-9589f039d3db",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, GRANJA"
    },
    {
        "id": "93749cb0-8b8d-a25c-ae0c-bce31fc3667e",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "12b01290-6b21-f3ed-d837-aa61b5bcea5b",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "87e6ed31-7691-5386-a88a-00338da53e7c",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "4d37ac67-ed79-92c9-980f-3ea68cf8ca7d",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE OTRAS ROPAS Y ACCESORIOS, VIVIENDA"
    },
    {
        "id": "0aa4c302-10e7-3645-9c95-a2aac514e336",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR"
    },
    {
        "id": "8cc9f85f-629d-1e48-6263-b52caae85d74",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "cb70712f-7137-7942-0610-cf9399e2ddc5",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "423c2ccc-7f38-54bc-d3f9-b8ce0d37b7db",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, CALLES Y CARRETERAS"
    },
    {
        "id": "ff94fb93-5076-281f-8358-c0e97ab04c02",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "312e842b-9c09-f6c0-46ab-9f57deff8e00",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "def6b49f-37b8-cff2-343e-db5b62a3f2a4",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, GRANJA"
    },
    {
        "id": "0a6047f5-dd40-1027-dd63-724c4bd2cf9b",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "807c88be-b406-840a-f6a5-cbd619dabd5c",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "1eeec35b-0495-950e-c52a-59b6c74a3027",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8a0e00fd-22b8-6c4b-4e68-80185c161151",
        "NOMBRE": "EXPOSICIÓN A IGNICIÓN O FUSIÓN DE ROPAS DE DORMIR, VIVIENDA"
    },
    {
        "id": "92e5c968-32c6-1a93-73b1-d4c1b0bbdd15",
        "NOMBRE": "EXPOSICIÓN A LA RADIACIÓN"
    },
    {
        "id": "42a3254f-4f56-e845-cd08-2d3781c5293b",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA"
    },
    {
        "id": "8423a9aa-d5ab-6311-cdb6-4789e53bbe09",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7896d3af-af65-fc94-6e3f-8160d76fca11",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "19749161-9974-7273-9456-538ce05f1def",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, CALLES Y CARRETERAS"
    },
    {
        "id": "ac6ce8e6-6716-e75f-2eeb-20639af65714",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b282142f-2823-b937-b721-462ebaf200e2",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b93fc7db-7c0f-21ad-cddb-52fe84635e64",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, GRANJA"
    },
    {
        "id": "eb162e30-3f7b-b1d1-08f3-cefa49965a4c",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "4d23dabe-0e6b-56c3-9ea1-e73666ea43d3",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "433dbb50-e1cc-94e2-4200-0e15bc9cc03b",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "97e0134f-eb79-ad2c-5b6e-bf575c21c748",
        "NOMBRE": "EXPOSICIÓN A LÍNEAS DE TRANSMISIÓN ELÉCTRICA, VIVIENDA"
    },
    {
        "id": "c0e85ecd-78fa-b94d-d694-e19e87bd1a63",
        "NOMBRE": "EXPOSICIÓN A OTRAS CONTAMINACIONES DEL AMBIENTE FÍSICO"
    },
    {
        "id": "54b568c4-3e86-dca2-dc5c-ffb672fe3217",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS"
    },
    {
        "id": "c36d7018-6294-fcbd-e194-f6a745e58432",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "52a31f94-b315-6fdc-a6e3-bd752082f201",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "12eddac7-ca01-7111-2578-002a1a5e8dc4",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "ee7c2d16-df16-f9c1-0fbb-7ea3dc7b7bb2",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b41554dc-1182-ceaa-d5b2-cbd3f1fee887",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c3321838-47bb-c414-c17d-99eaf6c421ed",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, GRANJA"
    },
    {
        "id": "78f38a07-408d-e5c0-aa1f-c9895676ff93",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "11edd2df-e1d3-b2bb-94bc-cd9f5891e8ea",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c39da444-14eb-a40e-2511-2ca4777b5280",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "0ef4b702-e83d-c9ff-5282-a02e6a050093",
        "NOMBRE": "EXPOSICIÓN A OTRAS CORRIENTES ELÉCTRICAS ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "66863a68-0a6a-da74-11a0-a211c471d911",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "3357f439-9d99-0230-3ea1-cddb5c7dd4b8",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "73e7280a-9a69-fe17-1075-1a61957ba587",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ec293c7c-2e93-a992-c3e0-2c24207b507f",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "8be53334-b2e8-9bc0-35af-1c73dbc870d0",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "d5d08c88-d5bf-c925-6184-64d2b6dc46b1",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "482fbdad-9f37-afe1-fef3-b839e6dc0b66",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "6dd4e117-53ca-2c70-184e-767f40089efe",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "68f9d9fd-c49f-70d8-9b90-fc1072fbcfa2",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d58f093b-bbfd-85d6-c35e-a32576d1c988",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8d9d5e03-f266-7adf-5ef4-7695d3af835f",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "f30ef49b-f8fc-9823-dc35-268d609a7462",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "b61483d9-b0d8-806d-5ef8-a2520a11a5b0",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "54f8c76f-ac8e-26c7-f065-75e4a0970268",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "75eab624-96b7-73eb-e0f9-349b5cd13f0c",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "8f44cfae-acf5-4b2b-bd4d-0e86fc160e17",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6fe1b368-8a91-0dcf-4c48-6d00a280418d",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b7c2368c-089e-5094-81fa-98c7dd4a8231",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "293b034b-3c7b-0f22-0917-66635a53ce08",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "fce59fd5-8d72-c4e5-6d9f-5d7dd6cca483",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f26455a3-deb7-e2c1-894e-33481930e9fc",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "26a0f979-d813-f9fe-0318-20c7a5c91566",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS ANIMADAS, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "e170d85d-310d-b10c-c7ed-cc6deb31b66c",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "782f5378-8b97-6864-7920-523158b3b2b4",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "4917fbe3-3acd-8451-4ee3-eeb93318d1ca",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0e9923ec-4eb5-3a63-e15d-0d428d813ba9",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "29e4ac44-07e5-253d-bd4a-56958caff8ed",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "b45aacb9-1309-ae51-9e93-8649d887a20a",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "7ee9cb4d-ca79-646e-fa9e-492d19df4351",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "4a499702-3d98-9783-8b06-f530a2c702da",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f136ee00-a6ae-df48-8517-9d437310500b",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a056229d-e937-4346-d6af-4c6e6de0a996",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a3fceaad-a401-6715-5c98-8400500f03f7",
        "NOMBRE": "EXPOSICIÓN A OTRAS FUERZAS MECÁNICAS INANIMADAS, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "18d05083-4423-229e-644b-bce7bb236885",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL"
    },
    {
        "id": "fe5f43ec-4275-c263-232d-f817f0d470f2",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "310fd7fa-bbab-e157-2aaa-8d0d72f400a0",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "126cbe12-51fb-a0df-715e-c14d9cd17414",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, CALLES Y CARRETERAS"
    },
    {
        "id": "98f53b7e-3f89-68ed-aaef-4e53b3464976",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a3c7149e-534d-4800-a42a-eef82917d829",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "acb459e0-2ea0-6e24-a2a7-f88d7e3538dc",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, GRANJA"
    },
    {
        "id": "bf645fa3-d7f9-63c7-6ed0-8bc50e6564c9",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "00ce53ab-ea64-ed5e-b9d3-dc86d98227c9",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a505ef89-6b0d-2644-b913-96daf3c206a2",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "adfe27df-d09e-7c07-f1e5-ee0be7c26c53",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL, VIVIENDA"
    },
    {
        "id": "6b27a294-5a60-6cc1-36fc-8b23711e943c",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS"
    },
    {
        "id": "f6915177-dea0-5f55-8f36-6e1598b7ca36",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d9a1f90a-5360-d981-8eb1-99995c7fea6a",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5b11c20b-99f1-61aa-4c50-6ea9bd618712",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "aa28b873-470c-11f6-c841-1404a4e0e25b",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "8fd3d807-f4d1-bc6b-c1ce-4b5be46dc409",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6d7e9175-9e16-0638-caf0-b21ecdd61807",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, GRANJA"
    },
    {
        "id": "a2bd4fa9-28b2-485b-9a6e-0a19c0a52353",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "22979215-d06b-43ac-fd78-bbd87fdfe191",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "1c34ee9d-7b5d-3b5b-fb81-2930dd69aee7",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d0dd9e4e-b2c9-cb9f-f79b-b96291e98b0a",
        "NOMBRE": "EXPOSICIÓN A OTROS FACTORES ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "2da5394f-125d-6851-0656-1dcdaef75b7c",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS"
    },
    {
        "id": "115b95fa-4d73-654c-863e-5aab39811a1e",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f2076fae-3279-4556-8f82-036b43f2cfd0",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "eb8725a0-a9e8-1f52-68f9-004e260583f2",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "f0092572-9a15-3682-11dd-3b27b9d2b54e",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e99e4961-523f-202f-2c02-f504ff1bdb01",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e214fbf5-98cd-8006-28c6-ffa15a392d9f",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, GRANJA"
    },
    {
        "id": "014c1336-a47e-b4e2-3182-f452b35073c5",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7c5d005f-b8b5-6dea-4d53-b42ab959def0",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "e67b80bf-2b16-2a84-76d7-b2dd2f536e8f",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a3fabd59-057a-d466-03ff-a9baa0c737c3",
        "NOMBRE": "EXPOSICIÓN A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "56cbd0ec-a2d8-8712-a771-1fa1d2df700b",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE"
    },
    {
        "id": "5114d4ee-286f-4788-60a0-fc7a611a4bd0",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "4c0ac050-7ddf-0242-a17a-cbfdefa1b770",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c32473ec-774e-1782-a498-2e0540a77b9c",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, CALLES Y CARRETERAS"
    },
    {
        "id": "10294cbf-42bb-fb76-fb5d-79bc9d4ac382",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "da756fce-8d36-1a81-f3aa-35bd37f4d389",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "43cf24e9-e7ee-6a41-d021-c8a0db743cd1",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, GRANJA"
    },
    {
        "id": "502ca60a-d4cb-a2ec-6eb1-9e5f317a2d51",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "d03fba5a-d249-0886-2110-c3bb208e2fc2",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "2be7fb07-d3e0-cef9-c346-e3b8bf9e2363",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "54c95546-462a-2ce1-47a2-49c3f2112db0",
        "NOMBRE": "EXPOSICIÓN A OTROS TIPOS DE RADIACIÓN NO IONIZANTE, VIVIENDA"
    },
    {
        "id": "0db92ebc-6375-a969-0575-33218a79fb85",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE"
    },
    {
        "id": "1346048d-3430-0ce9-9b2d-1679c72b9563",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d6d96c72-05c2-b476-4553-cc489e0a33ea",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "71b3abb2-9485-3c11-1f6a-c630c88ddd8e",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, CALLES Y CARRETERAS"
    },
    {
        "id": "8d27ce39-58dd-96ee-be4f-9a2e0f796d9e",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3ae1c920-6589-2567-fc90-f83b5c16d8fc",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "345b1027-da68-6cbe-1b0b-ca629eea0173",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, GRANJA"
    },
    {
        "id": "b3439c7f-85b8-9abf-2e11-24908b6e4e97",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "c0a8c021-0c0f-7b56-d978-f0ad1bdec268",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "1b9cad2f-b566-c12f-504c-2c55db93d18d",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d5938a87-b0b6-25ea-3c30-dfffd2c7c562",
        "NOMBRE": "EXPOSICIÓN A PRESIÓN DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESIÓN DEL AIRE, VIVIENDA"
    },
    {
        "id": "653ba19a-70fd-03b9-83ce-de783962d074",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "9c3bf615-44e0-068a-92be-05b2eb1c9559",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "5ad8c57e-1acc-751f-814f-ab2648b3b3b8",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "58e14ba2-96be-5617-3d2b-2048884ed158",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, CALLES Y CARRETERAS"
    },
    {
        "id": "82ef7685-c3bb-c736-81bb-bdd8db65de29",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "9232683e-2ef2-03bd-32e9-f9f902427472",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b97397b0-40be-e116-0ca7-744b73142a57",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, GRANJA"
    },
    {
        "id": "edc2017b-005a-01b3-63cf-1a8b26f29ae3",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "bb0f4c3e-a7c7-af43-2431-dac64a6a7484",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "0374baf7-a034-352c-8b2f-dcda3bdb6255",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e2be87b4-4ac3-13ff-de42-5a9b8093a9a8",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN DE TIPO NO ESPECIFICADO, VIVIENDA"
    },
    {
        "id": "230d73b9-701c-80f7-bdcc-19ed54e33b66",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE"
    },
    {
        "id": "13c3119b-5046-f8aa-7d1c-9743712b0010",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a6b25180-0296-7742-09d0-07fa1ab087cd",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8ff9237c-3fa5-a91a-e222-e4e8db38d102",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, CALLES Y CARRETERAS"
    },
    {
        "id": "dc9e5d0e-9c7d-e355-0f42-5316a4758f73",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "d3cf28e5-06a1-1bb0-03a0-235519bd19b3",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "94f96dcb-dfc9-aee7-d436-d052c915820c",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, GRANJA"
    },
    {
        "id": "38d3debc-aa5b-5eab-1d5f-5fe970d7c46a",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "31ae1cb9-f412-ad94-1624-94442206883e",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a5772c57-a219-9a4d-d3d5-d898e3d95cd2",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "42ac0d4d-911b-0dd4-60ec-9b792c5b6635",
        "NOMBRE": "EXPOSICIÓN A RADIACIÓN IONIZANTE, VIVIENDA"
    },
    {
        "id": "782e0fb2-900b-03e2-8ada-d9142a227e3d",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES"
    },
    {
        "id": "1eb30442-bc20-f601-2870-0362f67c17da",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "1ff093f9-b797-2348-c978-2fb264eed88a",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4a4c686b-fba7-ee1f-be7a-dc0913149090",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, CALLES Y CARRETERAS"
    },
    {
        "id": "f2ac30b4-41bd-8cda-f5d2-8af95ad8b361",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "86a89445-148a-39b3-a6cb-817e56471fbe",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "03417503-9558-0fc2-b713-ded46cec97bf",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, GRANJA"
    },
    {
        "id": "c9a5c9d8-9d9d-1e6d-6c70-76962ebbb616",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f26e696c-c2f1-7444-e130-a109eec795e4",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ae08331a-2a5a-693c-a5bb-4950a276eae4",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ae0606a6-84de-626d-0816-41b011242313",
        "NOMBRE": "EXPOSICIÓN A RAYOS SOLARES, VIVIENDA"
    },
    {
        "id": "60083c49-8076-174f-d8c3-8124d86aae7e",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES"
    },
    {
        "id": "97c89fe4-0c8e-33ac-c9b0-81a65661e04a",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "95b165f1-970a-5e3e-09a0-0cd929bc01e0",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4623c843-a615-b141-1ba0-60aafdc10a04",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, CALLES Y CARRETERAS"
    },
    {
        "id": "c6cbef20-3e39-f34e-4148-a82992bf6bd1",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "8ec342fd-02b6-1a72-6241-3b7c28482c5e",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "135b6da8-99f6-42a5-8b5f-11b92c6d133b",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, GRANJA"
    },
    {
        "id": "dd0f3382-b5c6-5982-38de-1939587de6fa",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "d237d869-c264-6208-732a-0ae1a79264ea",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "ea97b4b0-42ec-21b7-7ea8-2d8a436c189a",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "270bbd56-86b7-2714-870b-ed05e12bd36e",
        "NOMBRE": "EXPOSICIÓN A VIBRACIONES, VIVIENDA"
    },
    {
        "id": "08ebf725-8043-4024-1d18-8548e6cb9a91",
        "NOMBRE": "EXPOSICIÓN AL AGUA CONTAMINADA"
    },
    {
        "id": "48963dea-54a3-9a89-7ef8-d5062badbb43",
        "NOMBRE": "EXPOSICIÓN AL AIRE CONTAMINADO"
    },
    {
        "id": "2bf08746-9dd5-bc20-8cd6-6ef7ed0cb14f",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO"
    },
    {
        "id": "53bd1be5-edd0-7acd-a9ee-4839b048fa0e",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "58e0c968-07d2-5e7b-d2e1-f6f3d67ccdc3",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "147c838e-2e9e-c311-26f4-564c8ed6cc50",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, CALLES Y CARRETERAS"
    },
    {
        "id": "e29a1e56-236e-9c5f-4b12-2f13518378ec",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "adaf6655-97f9-8e7a-91fe-9f2b04bd7d63",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "81a1c5be-067e-01b8-4ec3-137388d7c86b",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, GRANJA"
    },
    {
        "id": "cecac553-3495-1341-bba4-7f958bf402cc",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a21288ea-f9b7-f00e-96c9-671cede35181",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c1eec822-245f-1585-7267-f0a424fc2e54",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "870b1f05-92dd-fcb5-c59b-b17f7c65aa11",
        "NOMBRE": "EXPOSICIÓN AL CALOR NATURAL EXCESIVO, VIVIENDA"
    },
    {
        "id": "305c677a-e7da-6a97-4551-6031cad9c432",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO"
    },
    {
        "id": "8208225b-feb3-38ba-c317-656c85f8a9cf",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f138b955-04e9-2c04-b2be-4b8b05f1f7f5",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2e90f901-3162-a6ce-dcbd-7d094b3788d3",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, CALLES Y CARRETERAS"
    },
    {
        "id": "29f08ead-f6df-f64c-fcab-da89f3805c70",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "54feb2a4-2b37-4e4e-d296-742b48b4b6a4",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "cdf85421-d792-08bf-dabd-de2353bde6a0",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, GRANJA"
    },
    {
        "id": "afcbd613-ef56-5976-d3e7-daf8e2179a4a",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "88708262-3cb3-f42b-3b37-c07d9e8e449e",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "08739747-23c8-cd89-21d1-ba0f20087f9e",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "3c32181c-3847-a6b3-b303-afec32d2a164",
        "NOMBRE": "EXPOSICIÓN AL FRÍO NATURAL EXCESIVO, VIVIENDA"
    },
    {
        "id": "526bede4-2a24-6f18-2c40-f9a791019046",
        "NOMBRE": "EXPOSICIÓN AL HUMO DEL TABACO"
    },
    {
        "id": "830e4413-46f8-9a77-28db-5e542cd80fba",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "69da54c7-8dcc-af42-d37c-e46e33e9f960",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "631c8877-5218-0d82-2ea2-dc82e044eeed",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "96a36c43-93ad-31d1-a31b-77ef6fb0e57e",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "92f61090-608f-f88b-5618-def903f36b8c",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "6db2aaaa-bfbb-c50b-1a9a-72bdb6f7eada",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "506353ad-52ec-9c0a-b989-0ecb70b55ad7",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "29ce186a-3198-3fd1-78b0-662de071da0a",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "4a1ca171-c5aa-7068-2bed-7d4bdbdfa3b9",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "041780e6-e42d-670a-54f4-92c0076fb031",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "3d0f23cb-70b3-72d8-8608-7acc41c5c8c3",
        "NOMBRE": "EXPOSICIÓN AL HUMO, FUEGO Y LLAMAS, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "06603da1-93ea-0ff3-58a6-fa648bb354ab",
        "NOMBRE": "EXPOSICIÓN AL RUIDO"
    },
    {
        "id": "36ce24fb-dcc5-becf-4d07-f81958d9ddd6",
        "NOMBRE": "EXPOSICIÓN AL RUIDO"
    },
    {
        "id": "91522a22-9e45-aaf0-eef7-45cc049df704",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7ab668d0-b968-9d43-6395-56b559023865",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "34ae6c70-8382-f9f9-93aa-a6801963089c",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, CALLES Y CARRETERAS"
    },
    {
        "id": "b86c7e38-7d49-ebf5-1bf1-8e9cd53d1530",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "03a02b45-e101-27c0-3700-1ac6a26b1192",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "eb1e370f-c4aa-338f-8a90-14aa30c2dc43",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, GRANJA"
    },
    {
        "id": "79eb0b5e-2345-ac28-d449-9efa3003abea",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "a0cea7b2-945d-6cf1-e560-5f757744136b",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "6d582e99-4536-fc42-6104-f26c65c24803",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "9c06084c-b9eb-44a6-13d8-e2d873c0b667",
        "NOMBRE": "EXPOSICIÓN AL RUIDO, VIVIENDA"
    },
    {
        "id": "4f60e930-a933-515c-2c90-bdf9cf3d45b5",
        "NOMBRE": "EXPOSICIÓN AL SUELO CONTAMINADO"
    },
    {
        "id": "399006d9-2fe8-384d-e7da-428428e17766",
        "NOMBRE": "EXPOSICIÓN INADVERTIDA DEL PACIENTE A RADIACIÓN DURANTE ATENCIÓN MÉDICA"
    },
    {
        "id": "7da3a1bd-97eb-5fd4-33b7-2e2d30676599",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A AGENTES TÓXICOS EN AGRICULTURA"
    },
    {
        "id": "0292c7a7-4a6f-5e49-911b-86683479f936",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A AGENTES TÓXICOS EN OTRAS INDUSTRIAS"
    },
    {
        "id": "07336bc9-9263-5c82-58c4-4e0600140cb9",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A FACTOR DE RIESGO NO ESPECIFICADO"
    },
    {
        "id": "3ffe8e71-e908-e523-41bd-27c699e13774",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A LA RADIACIÓN"
    },
    {
        "id": "c928af67-26d8-8e72-b259-385a19828b9f",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A LA VIBRACIÓN"
    },
    {
        "id": "9b349044-c7e5-c6d3-5a46-098e72472e04",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A OTRO CONTAMINANTE DEL AIRE"
    },
    {
        "id": "561d90a4-5f1b-548e-b714-bd820b36839a",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A OTROS FACTORES DE RIESGO"
    },
    {
        "id": "cd92114f-d596-cdc2-cc51-e8eedf11da6a",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL A TEMPERATURA EXTREMA"
    },
    {
        "id": "d845f3a1-d591-3329-21bf-34e12a0fc3d6",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL AL POLVO"
    },
    {
        "id": "cc2c0aa5-e6e6-452c-99b9-7a060dfddffe",
        "NOMBRE": "EXPOSICIÓN OCUPACIONAL AL RUIDO"
    },
    {
        "id": "f66f3ef5-ea43-d182-8c36-2fd4ee658606",
        "NOMBRE": "EXTENSIÓN DE CERTIFICADO MÉDICO"
    },
    {
        "id": "c8b3a3c2-661e-6139-375d-f461eecec064",
        "NOMBRE": "EXTRACCIÓN DE NALGAS"
    },
    {
        "id": "22152387-ace6-f6a0-48f9-35296828a24f",
        "NOMBRE": "EXTRACCIÓN MENSTRUAL"
    },
    {
        "id": "2bef8569-5f16-3d16-1648-397100f29e7b",
        "NOMBRE": "EXTRAVASACIÓN DE LA ORINA"
    },
    {
        "id": "cc29614c-5e97-a796-08f6-3f81e9f0d86d",
        "NOMBRE": "EXTROFIA DE LA VEJIGA URINARIA"
    },
    {
        "id": "7665914d-d066-e764-ff54-87a618a7a9cc",
        "NOMBRE": "EYACULACIÓN PRECOZ"
    },
    {
        "id": "3ec95f8d-1f7f-6248-43e4-2cffc356c6d3",
        "NOMBRE": "FACIES COMPRIMIDA"
    },
    {
        "id": "6cfee4eb-dfbe-5406-d55c-d19c07c02051",
        "NOMBRE": "FACOMATOSIS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "46f964fc-c908-2a23-ad3d-3c2f92d5966c",
        "NOMBRE": "FACOMATOSIS, NO ESPECIFICADA"
    },
    {
        "id": "52de0679-09f7-d92f-f0b6-9ec09c743185",
        "NOMBRE": "FACTORES PSICOLÓGICOS Y DEL COMPORTAMIENTO ASOCIADOS CON TRASTORNOS O ENFERMEDADES CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "9380c393-5e50-fbe2-94e4-1dddd1dabb92",
        "NOMBRE": "FALLA DE LA INDUCCIÓN MÉDICA DEL ABORTO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "3a17f705-6c48-404b-0309-dafe17f3e36e",
        "NOMBRE": "FALLA DE LA INDUCCIÓN MÉDICA DEL ABORTO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "23a6d1be-c7ae-22e8-8a4d-41443b405055",
        "NOMBRE": "FALLA DE LA INDUCCIÓN MÉDICA DEL ABORTO, COMPLICADO POR INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "b2a1222c-a19d-73b9-c881-cda4495e59e5",
        "NOMBRE": "FALLA DE LA INDUCCIÓN MÉDICA DEL ABORTO, CON OTRAS COMPLICACIONES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "3da990c2-31ec-655a-ece4-14d49420dca5",
        "NOMBRE": "FALLA DE LA INDUCCIÓN MÉDICA DEL ABORTO, SIN COMPLICACIÓN"
    },
    {
        "id": "f03a36d0-080a-c4b0-818f-01ea2405ff64",
        "NOMBRE": "FALLA DE LA RESPUESTA GENITAL"
    },
    {
        "id": "b016ddc1-3f8f-8000-5fea-7d8e1539be7d",
        "NOMBRE": "FALLA EN EL CONTROL DE TEMPERATURA, EN TAPONAMIENTOS Y APLICACIONES LOCALES"
    },
    {
        "id": "633e053a-1d5d-a220-805b-9a23aadf4d6d",
        "NOMBRE": "FALLA EN LA DOSIFICACIÓN DURANTE ATENCIÓN MÉDICA Y QUIRÚRGICA NO ESPECIFICADA"
    },
    {
        "id": "041d3d66-c0d7-ff53-6040-80337d606480",
        "NOMBRE": "FALLA EN LA DOSIFICACIÓN DURANTE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "ebff0c10-0d8b-4f9b-b375-7c3936ecea29",
        "NOMBRE": "FALLA EN LA DOSIFICACIÓN DURANTE OTRAS ATENCIONES MÉDICAS Y QUIRÚRGICAS"
    },
    {
        "id": "9747fbe8-fa30-7af4-4e0c-c0a67302edbd",
        "NOMBRE": "FALLA EN LA DOSIFICACIÓN EN ELECTROCHOQUE O EN CHOQUE INSULÍNICO"
    },
    {
        "id": "0d26acb7-cf99-d468-d01d-0ccf325432cd",
        "NOMBRE": "FALLA EN LA INTRODUCCIÓN O REMOCIÓN DE OTRO TUBO O INSTRUMENTO"
    },
    {
        "id": "e008ed18-4831-4043-cf37-3b798abef95b",
        "NOMBRE": "FALLA EN LA SUTURA O LIGADURA DURANTE OPERACIÓN QUIRÚRGICA"
    },
    {
        "id": "75b54d33-9e86-ee0e-b1b7-77e7d027343c",
        "NOMBRE": "FALLA O DIFICULTAD DE INTUBACIÓN DURANTE EL PUERPERIO"
    },
    {
        "id": "325a6532-d328-8fe5-883e-f2d0bc2039c9",
        "NOMBRE": "FALLA O DIFICULTAD DE LA INTUBACIÓN"
    },
    {
        "id": "421730bd-c738-3185-7bb3-502189fb6209",
        "NOMBRE": "FALLA O DIFICULTAD EN LA INTUBACIÓN DURANTE EL EMBARAZO"
    },
    {
        "id": "69c6516d-bb27-dd1c-600e-6c8838043f22",
        "NOMBRE": "FALLA O DIFICULTAD EN LA INTUBACIÓN DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "f70884ff-adcd-b7ea-4a2c-8a51cb7caeb6",
        "NOMBRE": "FALLA Y RECHAZO DE OTROS ÓRGANOS Y TEJIDOS TRASPLANTADOS"
    },
    {
        "id": "e93ad34f-4605-75ad-f855-521edaae0996",
        "NOMBRE": "FALLA Y RECHAZO DE TRASPLANTE DE CORAZÓN"
    },
    {
        "id": "e7a9dc63-b30e-6afe-5412-74981cf0c858",
        "NOMBRE": "FALLA Y RECHAZO DE TRASPLANTE DE HÍGADO"
    },
    {
        "id": "aaa13008-fb14-68ba-e049-1ca80c558ce0",
        "NOMBRE": "FALLA Y RECHAZO DE TRASPLANTE DE ÓRGANO Y TEJIDO NO ESPECIFICADO"
    },
    {
        "id": "f6305535-630f-91ae-4309-87db5072a349",
        "NOMBRE": "FALLA Y RECHAZO DE TRASPLANTE DE PULMÓN-CORAZÓN"
    },
    {
        "id": "6c1d5ac7-ec59-5995-03ff-dd9489fd8e49",
        "NOMBRE": "FALLA Y RECHAZO DE TRASPLANTE DE RIÑÓN"
    },
    {
        "id": "9e83b354-42c3-8a11-9893-ece703d7ec75",
        "NOMBRE": "FALLA Y RECHAZO DEL TRASPLANTE DE ÓRGANOS Y TEJIDOS"
    },
    {
        "id": "1fe627e2-a129-dbd9-807c-67ac1d20604b",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE ASPIRACIÓN, PUNCIÓN Y OTRA CATETERIZACIÓN"
    },
    {
        "id": "1c1f78f4-5384-d91f-e72a-7f1836c68f63",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE ATENCIÓN MÉDICA Y QUIRÚRGICA NO ESPECIFICADA"
    },
    {
        "id": "3bc9f8b0-2936-cd13-d585-92fb25495926",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE CATETERIZACIÓN CARDÍACA"
    },
    {
        "id": "9c8fe0de-af10-36f7-f1dc-3aff6700bd5c",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE DIÁLISIS RENAL U OTRA PERFUSIÓN"
    },
    {
        "id": "fc52a386-7250-cb4f-0d34-ff41c9f4ecb3",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE EXAMEN ENDOSCÓPICO"
    },
    {
        "id": "abdd0075-4d22-1b5b-a0df-887bd25ac802",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE INFUSIÓN O TRANSFUSIÓN"
    },
    {
        "id": "eeb6771e-b2dc-f3a9-6cdc-4c2f1b5b101c",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE INYECCIÓN O INMUNIZACIÓN"
    },
    {
        "id": "0789786b-a1a2-90a8-7b58-c41c3a13b318",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "eeb0a21d-d622-79cd-aba5-2d94de9deae3",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE OPERACIÓN QUIRÚRGICA"
    },
    {
        "id": "ef1b599f-eeaf-fee7-2dd3-eb724a698e8d",
        "NOMBRE": "FALLAS EN LA ESTERILIZACIÓN DURANTE OTRAS ATENCIONES MÉDICAS Y QUIRÚRGICAS"
    },
    {
        "id": "46019a41-c805-5b66-c3f1-6345da877262",
        "NOMBRE": "FALSO POSITIVO EN LA PRUEBA SEROLÓGICA PARA SÍFILIS"
    },
    {
        "id": "0bc8498a-3f75-1f2a-6aed-624581950cd4",
        "NOMBRE": "FALSO TRABAJO DE PARTO"
    },
    {
        "id": "db6c4944-d734-1869-e8f0-38b2a67cfe97",
        "NOMBRE": "FALSO TRABAJO DE PARTO A LAS 37 Y MÁS SEMANAS COMPLETAS DE GESTACIÓN"
    },
    {
        "id": "fb5e13ae-45c1-2c60-7e90-b281098fde32",
        "NOMBRE": "FALSO TRABAJO DE PARTO ANTES DE LAS 37 SEMANAS COMPLETAS DE GESTACIÓN"
    },
    {
        "id": "bbd89f8c-6b86-1f94-7eca-ac464faa8e47",
        "NOMBRE": "FALSO TRABAJO DE PARTO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "333db4cb-5695-a68d-cff0-afe1834ade5f",
        "NOMBRE": "FALTA DE CONSOLIDACIÓN DE FRACTURA [SEUDOARTROSIS]"
    },
    {
        "id": "58bf22ae-fe04-d107-3a9e-d08764e9a2f2",
        "NOMBRE": "FALTA DEL DESARROLLO FISIOLÓGICO NORMAL ESPERADO"
    },
    {
        "id": "abf0748e-122d-214a-a2b6-730f5bdd03b9",
        "NOMBRE": "FALTA DEL DESARROLLO FISIOLÓGICO NORMAL ESPERADO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "088f1782-c497-8a1b-a935-2dc1a67cda69",
        "NOMBRE": "FALTA O PÉRDIDA DEL DESEO SEXUAL"
    },
    {
        "id": "f5d17eec-63d7-bb24-3831-fd5c96d6e99a",
        "NOMBRE": "FARFULLEO"
    },
    {
        "id": "b9e9b25b-8ee6-6775-bac4-d1c74109bf0a",
        "NOMBRE": "FARINGITIS AGUDA"
    },
    {
        "id": "b85266b4-2a5a-e442-8c75-9af2bfbb46cd",
        "NOMBRE": "FARINGITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"
    },
    {
        "id": "f0c224ff-1679-d8ec-d329-76420143a398",
        "NOMBRE": "FARINGITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "2d280db6-853b-5d2f-1cd1-a083cb7d0b17",
        "NOMBRE": "FARINGITIS CRÓNICA"
    },
    {
        "id": "a5474264-5e21-742b-eefa-36cc51016060",
        "NOMBRE": "FARINGITIS ESTREPTOCÓCICA"
    },
    {
        "id": "f4331ec2-90af-6503-95c2-bb21223648be",
        "NOMBRE": "FARINGITIS GONOCÓCICA"
    },
    {
        "id": "10154436-53b5-4737-bc24-7e0de251f525",
        "NOMBRE": "FARINGITIS VESICULAR ENTEROVÍRICA"
    },
    {
        "id": "0aff70c1-fe8c-3c8b-4d2f-35e70866c67c",
        "NOMBRE": "FARINGOCONJUNTIVITIS VIRAL"
    },
    {
        "id": "3061dbe7-9879-ccb1-f3d3-81250f5c25c0",
        "NOMBRE": "FASCICULACIÓN"
    },
    {
        "id": "7eb73c75-41c1-fe39-0938-0320a402c3a4",
        "NOMBRE": "FASCIOLIASIS"
    },
    {
        "id": "17ab3ce0-ed6d-2cb0-9645-88176129851b",
        "NOMBRE": "FASCIOLOPSIASIS"
    },
    {
        "id": "0093b05f-4857-43da-6d11-1b1ede6ee489",
        "NOMBRE": "FASCITIS DIFUSA (EOSINOFÍLICA)"
    },
    {
        "id": "446838fb-682d-183d-eac1-0c87f9a40fce",
        "NOMBRE": "FASCITIS NECROTIZANTE"
    },
    {
        "id": "befdaa76-5112-2e88-e143-254123be42fa",
        "NOMBRE": "FASCITIS NODULAR"
    },
    {
        "id": "10a6ee80-0eb2-57c4-44d9-a7242765f5b8",
        "NOMBRE": "FASCITIS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "ff1fb75a-7fa0-ec36-43be-27daa4fd0e93",
        "NOMBRE": "FATIGA POR CALOR, TRANSITORIA"
    },
    {
        "id": "ebebd7a3-2a6e-eebe-4f4c-a3a029d50034",
        "NOMBRE": "FECUNDACIÓN IN VITRO"
    },
    {
        "id": "8ee08d71-127e-892b-5c65-8da5afa02425",
        "NOMBRE": "FENILCETONURIA CLÁSICA"
    },
    {
        "id": "5584a55a-36a3-67ea-0b80-ab07c47d7692",
        "NOMBRE": "FETICHISMO"
    },
    {
        "id": "823e27fc-3193-3568-c6ea-ab12549833fc",
        "NOMBRE": "FETO ARLEQUÍN"
    },
    {
        "id": "1dcec4fb-711f-0d76-2389-6dc6c792726c",
        "NOMBRE": "FETO PAPIRÁCEO"
    },
    {
        "id": "2ae6a00b-095f-331e-3713-be241584f220",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADO POR COMPLICACIONES DE LA PLACENTA, DEL CORDÓN UMBILICAL Y DE LAS MEMBRANAS"
    },
    {
        "id": "f89602fb-d4ba-4be1-7656-28c32a5afec7",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADO POR CONDICIONES DE LA MADRE NO NECESARIAMENTE RELACIONADAS CON EL EMBARAZO PRESENTE"
    },
    {
        "id": "eebcc733-d3f8-4892-f286-dd3dd376e1e7",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR AFECCIÓN MATERNA NO ESPECIFICADA"
    },
    {
        "id": "d598c966-2ed3-6dde-512d-36b758ecf651",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR ALCOHOLISMO DE LA MADRE"
    },
    {
        "id": "063a3edf-55d0-1e37-66c9-c9b805c4dce8",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR ANESTESIA Y ANALGESIA MATERNA EN EL EMBARAZO, TRABAJO DE PARTO Y EN EL PARTO"
    },
    {
        "id": "896fc708-cf18-59d4-f020-3635ccfa7b75",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR ANORMALIDAD NO ESPECIFICADA DE LAS MEMBRANAS"
    },
    {
        "id": "1ffe50f9-7bd6-27aa-fffd-8604f701ab82",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR COMPLICACIONES MATERNAS DEL EMBARAZO"
    },
    {
        "id": "b70a7353-4670-b839-aa5a-141d037a51b5",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR COMPLICACIONES MATERNAS NO ESPECIFICADAS DEL EMBARAZO"
    },
    {
        "id": "d3c3f2e1-51f5-95d2-ab01-83ea1635674c",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR COMPLICACIONES NO ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO"
    },
    {
        "id": "add31317-4c03-b99c-1864-c67db6f9a550",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR CONTRACCIONES UTERINAS ANORMALES"
    },
    {
        "id": "5738d9af-03df-9695-095d-1b085d9937c8",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR CORIOAMNIONITIS"
    },
    {
        "id": "987d0105-3b54-4215-f5cf-34f3715bf73e",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR DROGADICCIÓN MATERNA"
    },
    {
        "id": "c1d8748a-2f5b-5da5-8343-466c282f7bd9",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR EL USO MATERNO DE SUSTANCIAS QUÍMICAS NUTRICIONALES"
    },
    {
        "id": "35a9351a-9714-39ed-f9d2-feaaf1d3ad68",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR EMBARAZO ECTÓPICO"
    },
    {
        "id": "eec5fa60-ec49-91e9-57df-d166105dfdb7",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR EMBARAZO MÚLTIPLE"
    },
    {
        "id": "ce3ece02-558e-e3cf-81e9-742aa6089cb5",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR ENFERMEDADES INFECCIOSAS Y PARASITARIAS DE LA MADRE"
    },
    {
        "id": "7e8fb93c-952d-b9a9-1015-d394ab0cb6b3",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR ENFERMEDADES RENALES Y DE LAS VÍAS URINARIAS DE LA MADRE"
    },
    {
        "id": "e9294185-6bc8-e095-feb1-8f05af0f2989",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR EXPOSICIÓN MATERNA A SUSTANCIAS QUÍMICAS AMBIENTALES"
    },
    {
        "id": "77146c4a-60d4-bf04-320b-9089bf2b7e44",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR INCOMPETENCIA DEL CUELLO UTERINO"
    },
    {
        "id": "7432e168-0203-d457-a02f-5df44b0538ea",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR INFLUENCIAS NOCIVAS DE LA MADRE, NO ESPECIFICADAS"
    },
    {
        "id": "5ffe50f9-52c4-1c05-dddc-198303f60514",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR INFLUENCIAS NOCIVAS TRANSMITIDAS A TRAVÉS DE LA PLACENTA O DE LA LECHE MATERNA"
    },
    {
        "id": "12ecf53a-6b53-ff91-e88f-53795ae435d6",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR MUERTE MATERNA"
    },
    {
        "id": "629c6ac7-c3ea-edde-99be-ed3b5eea705f",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OLIGOHIDRAMNIOS"
    },
    {
        "id": "cb516bc3-d890-24f9-50de-86d1ef0b90f9",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRA COMPRESIÓN DEL CORDÓN UMBILICAL"
    },
    {
        "id": "ca8fc9c1-8068-dda7-f192-12f76205da83",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRA PRESENTACIÓN ANÓMALA, POSICIÓN ANÓMALA Y DESPROPORCIÓN DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "9ac1da56-68df-b5ee-7e0d-0081e955a22c",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS AFECCIONES MATERNAS"
    },
    {
        "id": "28f4c8ed-0aa8-d596-3756-74b7b2f14bb9",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS ANORMALIDADES DE LAS MEMBRANAS"
    },
    {
        "id": "15bb99f6-2288-ce86-39c4-722107dec203",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS ANORMALIDADES MORFOLÓGICAS Y FUNCIONALES DE LA PLACENTA Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "d404a2fe-cc18-bb49-1389-2c30a0fc0367",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS COMPLICACIONES DEL CORDÓN UMBILICAL Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "088c6a67-5e8d-5ac1-a3b5-57a9e39b86fb",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS COMPLICACIONES DEL TRABAJO DE PARTO Y DEL PARTO"
    },
    {
        "id": "a64c97c9-68e5-50c8-27d6-e458ae8b0ab6",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS COMPLICACIONES ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO"
    },
    {
        "id": "0188d4fb-0335-f3e9-4a80-8fd226559bb2",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS COMPLICACIONES MATERNAS DEL EMBARAZO"
    },
    {
        "id": "f760e473-6dd3-7553-21a3-8a9df2650ff6",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS ENFERMEDADES CIRCULATORIAS Y RESPIRATORIAS DE LA MADRE"
    },
    {
        "id": "55e92183-b207-4ea5-7439-808ffa62cd43",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS FORMAS DE DESPRENDIMIENTO Y DE HEMORRAGIA PLACENTARIOS"
    },
    {
        "id": "3db2819f-74e9-81c2-7396-c1453abce497",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS INFLUENCIAS NOCIVAS DE LA MADRE"
    },
    {
        "id": "5ada80f1-6b42-2617-2c44-0309b85fd4ac",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRAS MEDICACIONES MATERNAS"
    },
    {
        "id": "03763c9f-c278-73e1-5918-547ac40976fa",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR OTRO PROCEDIMIENTO MÉDICO EN LA MADRE, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "905b1334-5121-7e40-8b7d-1f61aa06456b",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PARTO CON FÓRCEPS"
    },
    {
        "id": "baee3da6-0fcb-59bc-8eeb-c8c3c6b73798",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PARTO CON VENTOSA EXTRACTORA"
    },
    {
        "id": "4226eee3-83d9-79e1-2c55-a179e9918ff3",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PARTO POR CESÁREA"
    },
    {
        "id": "11c28834-c009-965b-dca9-ffded0549b9b",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PARTO PRECIPITADO"
    },
    {
        "id": "cb64a37d-d8bb-3d0e-246d-6b482dc778a1",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PARTO Y EXTRACCIÓN DE NALGAS"
    },
    {
        "id": "530ada88-ffd1-51ab-a536-1078d6c23644",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PLACENTA PREVIA"
    },
    {
        "id": "6b2659d2-4276-9a65-0ae4-f0157a62c284",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR POLIHIDRAMNIOS"
    },
    {
        "id": "fec6d605-a0e5-7683-5de8-4fb07d11ef45",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PRESENTACIÓN ANÓMALA ANTES DEL TRABAJO DE PARTO"
    },
    {
        "id": "e2a71dce-5ced-cb79-6ed6-37b1e37dc228",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PROCEDIMIENTO QUIRÚRGICO EN LA MADRE"
    },
    {
        "id": "3fcd2241-5e38-0c37-4ae7-5d770840f134",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR PROLAPSO DEL CORDÓN UMBILICAL"
    },
    {
        "id": "053f16a4-67f2-05cb-5516-6222b2d1e842",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR RUPTURA PREMATURA DE LAS MEMBRANAS"
    },
    {
        "id": "4c5b2a2c-1504-9a9c-e281-e3738aeb63db",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR SÍNDROMES DE TRANSFUSIÓN PLACENTARIA"
    },
    {
        "id": "35b6d885-7840-14c8-b425-cb88a31e4b10",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR TABAQUISMO DE LA MADRE"
    },
    {
        "id": "2b5d3fbb-e8b7-b8dd-6b77-b669e5f88d53",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR TRASTORNOS HIPERTENSIVOS DE LA MADRE"
    },
    {
        "id": "35049309-9860-fd71-77b6-e08937ecd480",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR TRASTORNOS NUTRICIONALES DE LA MADRE"
    },
    {
        "id": "b702d863-0c3a-cc18-ea36-ae5427c05505",
        "NOMBRE": "FETO Y RECIÉN NACIDO AFECTADOS POR TRAUMATISMO DE LA MADRE"
    },
    {
        "id": "95b642c3-2eb8-433c-42e9-6b4a63a55ee6",
        "NOMBRE": "FIBRILACIÓN AURICULAR CRÓNICA"
    },
    {
        "id": "e92a1bea-f27b-61db-fb1f-a56adf68f21c",
        "NOMBRE": "FIBRILACIÓN AURICULAR PAROXÍSTICA"
    },
    {
        "id": "bd3c3bd6-0433-58c4-9822-01db682601db",
        "NOMBRE": "FIBRILACIÓN AURICULAR PERSISTENTE"
    },
    {
        "id": "74baf687-f157-26ee-0456-0b71e1dd56be",
        "NOMBRE": "FIBRILACIÓN Y ALETEO AURICULAR"
    },
    {
        "id": "e08e0282-985c-e367-2eaa-8f75a48ee7e1",
        "NOMBRE": "FIBRILACIÓN Y ALETEO AURICULAR"
    },
    {
        "id": "8feb25d9-1516-6230-2a94-747b6d9c07bc",
        "NOMBRE": "FIBRILACIÓN Y ALETEO AURICULAR, NO ESPECIFICADO"
    },
    {
        "id": "fdcf69a8-3f3c-1059-05fb-7a639a367081",
        "NOMBRE": "FIBRILACIÓN Y ALETEO VENTRICULAR"
    },
    {
        "id": "efff80f7-847f-7574-e906-700dcb27a6f2",
        "NOMBRE": "FIBROADENOSIS DE MAMA"
    },
    {
        "id": "019d30c0-9b5d-0027-8386-c8e0ad1ae101",
        "NOMBRE": "FIBROELASTOSIS ENDOCÁRDICA"
    },
    {
        "id": "cb1bfc8f-f6ce-9a2b-ae15-097c92068db9",
        "NOMBRE": "FIBROESCLEROSIS DE MAMA"
    },
    {
        "id": "a5f5a8ca-264a-db14-e237-6cc75be89d43",
        "NOMBRE": "FIBROMATOSIS DE LA APONEUROSIS PALMAR [DUPUYTREN]"
    },
    {
        "id": "7490370f-527a-a97d-229e-c6c57d76c30a",
        "NOMBRE": "FIBROMATOSIS DE LA APONEUROSIS PLANTAR"
    },
    {
        "id": "eccaac0b-d3fa-bd0c-8269-ec9c9d9e6b5d",
        "NOMBRE": "FIBROMATOSIS SEUDOSARCOMATOSA"
    },
    {
        "id": "4f4d1b56-41af-49c7-a05f-59261c9ca258",
        "NOMBRE": "FIBROMIALGIA"
    },
    {
        "id": "6b18bf00-b035-aaab-832f-694bfa739202",
        "NOMBRE": "FIBROSCLEROSIS MULTIFOCAL"
    },
    {
        "id": "717bbc10-c4c3-b798-5d59-6e55dc6e4aae",
        "NOMBRE": "FIBROSIS (DEL PULMÓN) DEBIDA A BAUXITA"
    },
    {
        "id": "613deb56-7122-bb6c-a48e-43883a35e275",
        "NOMBRE": "FIBROSIS (DEL PULMÓN) DEBIDA A GRAFITO"
    },
    {
        "id": "018bdf24-1c4c-4f6d-7a69-76fb690c18a1",
        "NOMBRE": "FIBROSIS DE LA SUBMUCOSA BUCAL"
    },
    {
        "id": "90a9c940-71a4-b1e7-3e9b-a7cba09ddaba",
        "NOMBRE": "FIBROSIS HEPÁTICA"
    },
    {
        "id": "f13b5e8d-2c31-7af7-ae2f-9071efe661a2",
        "NOMBRE": "FIBROSIS HEPÁTICA CON ESCLEROSIS HEPÁTICA"
    },
    {
        "id": "83b1a1bd-cfc5-69eb-834b-be2f7405791d",
        "NOMBRE": "FIBROSIS QUÍSTICA"
    },
    {
        "id": "84e56372-5777-003d-c876-6b369cd87251",
        "NOMBRE": "FIBROSIS QUÍSTICA CON MANIFESTACIONES INTESTINALES"
    },
    {
        "id": "e2a6866d-d0bc-639b-3f77-28e1001d7796",
        "NOMBRE": "FIBROSIS QUÍSTICA CON MANIFESTACIONES PULMONARES"
    },
    {
        "id": "1a31000e-f5af-c03d-2f6e-4c0b35e84fc6",
        "NOMBRE": "FIBROSIS QUÍSTICA CON OTRAS MANIFESTACIONES"
    },
    {
        "id": "667f4918-5a1c-57bb-921c-d23618327de8",
        "NOMBRE": "FIBROSIS QUÍSTICA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "520607b7-9ae6-656a-be93-926b84638f45",
        "NOMBRE": "FIBROSIS Y AFECCIONES CICATRICIALES DE LA PIEL"
    },
    {
        "id": "dea561ab-38d3-091d-844a-24b27568cf3c",
        "NOMBRE": "FIBROSIS Y CIRROSIS DEL HÍGADO"
    },
    {
        "id": "712f2372-aeb7-3ab8-72e3-a22acaf58d84",
        "NOMBRE": "FIBROSIS Y ESCLEROSIS DEL HÍGADO, ALCOHÓLICA"
    },
    {
        "id": "b1f8af33-aa3b-5ceb-5131-2f42c59366a4",
        "NOMBRE": "FIBROTÓRAX"
    },
    {
        "id": "27865039-c2cf-0393-a342-8560198321eb",
        "NOMBRE": "FIEBRE AMARILLA"
    },
    {
        "id": "6b3f9475-12ce-5661-3d61-c3b02a6df1ef",
        "NOMBRE": "FIEBRE AMARILLA SELVÁTICA"
    },
    {
        "id": "7c84eca0-bdb9-f815-4240-5bdfc462cad9",
        "NOMBRE": "FIEBRE AMARILLA URBANA"
    },
    {
        "id": "9859ddb4-1659-9b2c-5434-04d330450e26",
        "NOMBRE": "FIEBRE AMARILLA, NO ESPECIFICADA"
    },
    {
        "id": "ba33718b-96c7-84cf-02d0-9ff5fb7a80ef",
        "NOMBRE": "FIEBRE CON ESCALOFRÍO"
    },
    {
        "id": "02a83c8b-371d-d617-2145-ac85ae51906a",
        "NOMBRE": "FIEBRE DE COLORADO TRANSMITIDA POR GARRAPATAS"
    },
    {
        "id": "9b639996-7123-bf56-0bf7-bf9ebc71c7b3",
        "NOMBRE": "FIEBRE DE LAS TRINCHERAS"
    },
    {
        "id": "be00f0c0-d2a1-11a9-dfaf-550d1b28feaf",
        "NOMBRE": "FIEBRE DE LASSA"
    },
    {
        "id": "0535335f-01de-21be-bd8d-7ddb8e435ecc",
        "NOMBRE": "FIEBRE DE O'NYONG-NYONG"
    },
    {
        "id": "f9998f2d-3596-c9d5-cb89-00175772c35c",
        "NOMBRE": "FIEBRE DE OTRO ORIGEN Y DE ORIGEN DESCONOCIDO"
    },
    {
        "id": "020035fa-39eb-a1f6-4a3b-37867a4b9493",
        "NOMBRE": "FIEBRE DEL DENGUE [DENGUE CLÁSICO]"
    },
    {
        "id": "97cc6540-ecf9-fafa-43c4-5b55b302b60f",
        "NOMBRE": "FIEBRE DEL DENGUE HEMORRÁGICO"
    },
    {
        "id": "3107fa4c-9eeb-0656-26f1-82596af5200c",
        "NOMBRE": "FIEBRE DEL VALLE DEL RIFT"
    },
    {
        "id": "395e6c4b-7887-9f19-aa2e-1e86df159c7d",
        "NOMBRE": "FIEBRE EQUINA VENEZOLANA"
    },
    {
        "id": "ca56f48c-616c-ff10-cefc-b4ff6c72ec4f",
        "NOMBRE": "FIEBRE EXANTEMÁTICA ENTEROVIRAL [EXANTEMA DE BOSTON]"
    },
    {
        "id": "d1d2ae72-17a7-1aa0-3c0b-abff26146b44",
        "NOMBRE": "FIEBRE HEMORRÁGICA DE CRIMEA-CONGO"
    },
    {
        "id": "4bc7da66-a140-cce8-ddc4-3c8ef06e519e",
        "NOMBRE": "FIEBRE HEMORRÁGICA DE JUNÍN"
    },
    {
        "id": "4fde4b47-c242-5db0-3d57-b39099cc06ba",
        "NOMBRE": "FIEBRE HEMORRÁGICA DE MACHUPO"
    },
    {
        "id": "78a5e07a-3bf2-9ab8-36da-56d8f1ef234a",
        "NOMBRE": "FIEBRE HEMORRÁGICA DE OMSK"
    },
    {
        "id": "acaa880b-4387-7a8f-7383-2e5139f39e2f",
        "NOMBRE": "FIEBRE HEMORRÁGICA POR ARENAVIRUS"
    },
    {
        "id": "bc8ddd49-8008-031c-8552-97fc623cfb0d",
        "NOMBRE": "FIEBRE HEMORRÁGICA POR ARENAVIRUS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "535f80db-9aef-499e-147e-b9c966661272",
        "NOMBRE": "FIEBRE INDUCIDA POR DROGAS"
    },
    {
        "id": "8a6a2878-6db5-a480-5641-94747b8fac71",
        "NOMBRE": "FIEBRE MACULOSA [RICKETTSIOSIS TRANSMITIDA POR GARRAPATAS]"
    },
    {
        "id": "0724eef2-dd4d-d2b1-e8d5-91ae8e8a039d",
        "NOMBRE": "FIEBRE MACULOSA DEBIDA A RICKETTSIA AUSTRALIS"
    },
    {
        "id": "065c78a3-7161-de73-ccda-9489402f9c25",
        "NOMBRE": "FIEBRE MACULOSA DEBIDA A RICKETTSIA CONORII"
    },
    {
        "id": "f1ded5c2-1c60-4dd4-62ef-494cff963af6",
        "NOMBRE": "FIEBRE MACULOSA DEBIDA A RICKETTSIA RICKETTSII"
    },
    {
        "id": "be83053e-a9b6-3b9e-da5f-a6a3abc136cc",
        "NOMBRE": "FIEBRE MACULOSA DEBIDA A RICKETTSIA SIBERICA"
    },
    {
        "id": "7d722c7c-249f-4a5d-9fc2-5aa619246d8a",
        "NOMBRE": "FIEBRE MACULOSA, NO ESPECIFICADA"
    },
    {
        "id": "84654d78-e498-44e5-6c72-1a00efb05897",
        "NOMBRE": "FIEBRE PARATIFOIDEA A"
    },
    {
        "id": "ba27c82a-bedf-2539-d1ea-fe88963069ef",
        "NOMBRE": "FIEBRE PARATIFOIDEA B"
    },
    {
        "id": "3d316f9b-9f8b-e576-ff7d-d9cd19e578f5",
        "NOMBRE": "FIEBRE PARATIFOIDEA C"
    },
    {
        "id": "c2958574-58f9-f975-98bc-104c31dc7613",
        "NOMBRE": "FIEBRE PARATIFOIDEA, NO ESPECIFICADA"
    },
    {
        "id": "ee468ec5-1573-8c31-d45c-8d079f10879d",
        "NOMBRE": "FIEBRE PERSISTENTE"
    },
    {
        "id": "59675f89-0f45-ee71-e43e-d0558f7bc710",
        "NOMBRE": "FIEBRE POR MORDEDURA DE RATA"
    },
    {
        "id": "97744eb5-3833-b3ca-1ddf-f814c86c8535",
        "NOMBRE": "FIEBRE POR MORDEDURA DE RATA, NO ESPECIFICADA"
    },
    {
        "id": "4e6fe657-29c9-dc83-b8d7-bc51e72e9bcb",
        "NOMBRE": "FIEBRE PURPÚRICA BRASILEÑA"
    },
    {
        "id": "5da76ce0-b5d2-d574-4018-97baa0fd3304",
        "NOMBRE": "FIEBRE Q"
    },
    {
        "id": "07616bda-29be-2d38-da27-ae2ddeda39a8",
        "NOMBRE": "FIEBRE RECURRENTE TRANSMITIDA POR GARRAPATAS"
    },
    {
        "id": "78d86a91-5250-de97-008d-afa610f091e4",
        "NOMBRE": "FIEBRE RECURRENTE TRANSMITIDA POR PIOJOS"
    },
    {
        "id": "072a6274-9c1a-9e75-18ac-c0104981c8df",
        "NOMBRE": "FIEBRE RECURRENTE, NO ESPECIFICADA"
    },
    {
        "id": "b3242298-0bac-5cc2-5aac-d77aeaddd062",
        "NOMBRE": "FIEBRE REUMÁTICA CON COMPLICACIÓN CARDÍACA"
    },
    {
        "id": "dbe5c588-1d09-da4e-689e-b69635e2c1db",
        "NOMBRE": "FIEBRE REUMÁTICA SIN MENCIÓN DE COMPLICACIÓN CARDÍACA"
    },
    {
        "id": "3225889b-a7ba-bac6-9060-68b01e292460",
        "NOMBRE": "FIEBRE TIFOIDEA"
    },
    {
        "id": "b3289c53-be14-9587-7b7e-7dc47ff089b8",
        "NOMBRE": "FIEBRE TRANSMITIDA POR FLEBÓTOMOS"
    },
    {
        "id": "42f5abd2-bc11-7006-ca2d-504c6f33e8a7",
        "NOMBRE": "FIEBRE VIRAL HEMORRÁGICA, NO ESPECIFICADA"
    },
    {
        "id": "960b092a-6cb7-9fa0-9e60-6ae65aab6e05",
        "NOMBRE": "FIEBRE VIRAL TRANSMITIDA POR ARTRÓPODOS, NO ESPECIFICADA"
    },
    {
        "id": "d09912ae-5d0e-732b-c212-800ba125a788",
        "NOMBRE": "FIEBRE VIRAL TRANSMITIDA POR MOSQUITO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "44a6384e-2430-52ba-add6-932e8193e0b7",
        "NOMBRE": "FIEBRE, NO ESPECIFICADA"
    },
    {
        "id": "849529e8-d210-a22a-b439-144ab590e8c0",
        "NOMBRE": "FIEBRES HEMORRÁGICAS CON SÍNDROME RENAL"
    },
    {
        "id": "08bf435c-70e7-a582-648d-3c28c5459840",
        "NOMBRE": "FIEBRES RECURRENTES"
    },
    {
        "id": "800f66e5-17ed-7d38-6506-e42fe9cafb64",
        "NOMBRE": "FIEBRES TIFOIDEA Y PARATIFOIDEA"
    },
    {
        "id": "d67d34b3-f54a-1432-fc78-12d545b42426",
        "NOMBRE": "FILARIASIS"
    },
    {
        "id": "fba79902-97b2-897d-3523-13b1820941c8",
        "NOMBRE": "FILARIASIS DEBIDA A BRUGIA MALAYI"
    },
    {
        "id": "2fd278cd-de21-59e7-a344-0a56d9fd7262",
        "NOMBRE": "FILARIASIS DEBIDA A BRUGIA TIMORI"
    },
    {
        "id": "ecfcea4b-e50c-ad3f-af7d-763f45701ba6",
        "NOMBRE": "FILARIASIS DEBIDA A WUCHERERIA BANCROFTI"
    },
    {
        "id": "0a061937-a87e-6b13-eaff-299786b1589b",
        "NOMBRE": "FILARIASIS, NO ESPECIFICADA"
    },
    {
        "id": "a639596a-c6a1-a0ee-01c5-5798035ca864",
        "NOMBRE": "FÍSTULA ANAL"
    },
    {
        "id": "7bb1f848-ab6d-abc9-3f53-967d41f96f74",
        "NOMBRE": "FÍSTULA ANORRECTAL"
    },
    {
        "id": "9a288276-b36a-0a26-66d5-6d56e10a0e8e",
        "NOMBRE": "FÍSTULA ARTERIA HEPÁTICA-VENA PORTA"
    },
    {
        "id": "55d16215-476d-c90a-5265-dc20066632da",
        "NOMBRE": "FÍSTULA ARTERIOVENOSA DE LOS VASOS PULMONARES"
    },
    {
        "id": "5f464f78-fec9-d39f-3778-cb5de6453ef2",
        "NOMBRE": "FÍSTULA ARTERIOVENOSA, ADQUIRIDA"
    },
    {
        "id": "906eb831-7f54-807e-6e32-90024957ec22",
        "NOMBRE": "FÍSTULA ARTICULAR"
    },
    {
        "id": "47b797bc-c565-a333-de0f-cbad1868af1f",
        "NOMBRE": "FÍSTULA CONGÉNITA DEL RECTO Y DEL ANO"
    },
    {
        "id": "8779e3c5-d885-edfa-d3e4-dded233d3515",
        "NOMBRE": "FÍSTULA CONGÉNITA ENTRE EL ÚTERO Y EL TRACTO DIGESTIVO Y URINARIO"
    },
    {
        "id": "7247bd3a-1cc1-bced-d084-482e5d2bb730",
        "NOMBRE": "FÍSTULA DE GLÁNDULA SALIVAL"
    },
    {
        "id": "98cdceaf-0a57-83e9-fdc1-a6708d605549",
        "NOMBRE": "FÍSTULA DE LA URETRA"
    },
    {
        "id": "92a6945f-1535-ea9e-6100-9cc89bf48390",
        "NOMBRE": "FÍSTULA DE LA VAGINA AL INTESTINO DELGADO"
    },
    {
        "id": "6eb97bb9-1732-20e8-1fb4-ba8945102fcf",
        "NOMBRE": "FÍSTULA DE LA VAGINA AL INTESTINO GRUESO"
    },
    {
        "id": "3dd72d42-5902-fa41-d796-c83bbb94289a",
        "NOMBRE": "FÍSTULA DE LA VEJIGA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "c80b2612-69ec-c148-108a-fb35b0a81018",
        "NOMBRE": "FÍSTULA DE LA VESÍCULA BILIAR"
    },
    {
        "id": "6bc3de2d-afdf-91be-4b1d-056ad5ba6105",
        "NOMBRE": "FÍSTULA DEL APÉNDICE"
    },
    {
        "id": "427e96dd-417e-2707-3574-2a58d888e906",
        "NOMBRE": "FÍSTULA DEL CONDUCTO BILIAR"
    },
    {
        "id": "7176f0eb-9dfe-d7be-274b-bedcf11a0571",
        "NOMBRE": "FÍSTULA DEL ESTÓMAGO Y DEL DUODENO"
    },
    {
        "id": "9d16016d-e6b3-a8ce-e6c7-ea0175866212",
        "NOMBRE": "FÍSTULA DEL INTESTINO"
    },
    {
        "id": "dbd2a787-5b4d-985f-01aa-23ff1c0add8f",
        "NOMBRE": "FÍSTULA DEL LABERINTO"
    },
    {
        "id": "a6a7f57b-de01-baea-37be-ee1c5750b0de",
        "NOMBRE": "FÍSTULA DEL TRACTO GENITAL FEMENINO A LA PIEL"
    },
    {
        "id": "48a3bd0b-6bc6-277d-9dbe-702d2068c3cd",
        "NOMBRE": "FÍSTULA DEL TRACTO GENITAL FEMENINO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "7c0d6c2b-ae59-d519-ef90-dad14c02e88d",
        "NOMBRE": "FÍSTULA RECTAL"
    },
    {
        "id": "793cd582-0246-9513-56cb-0f3097fc9a82",
        "NOMBRE": "FÍSTULA RECTOVAGINAL CONGÉNITA"
    },
    {
        "id": "6c59f27a-ad41-a5fe-48f1-669da8627a47",
        "NOMBRE": "FÍSTULA TRAQUEOESOFÁGICA CONGÉNITA SIN MENCIÓN DE ATRESIA"
    },
    {
        "id": "860a2c8b-df13-a4c2-7cbe-2600749ed90b",
        "NOMBRE": "FÍSTULA VESICOINTESTINAL"
    },
    {
        "id": "767b8b0b-8958-72b0-b941-88b4c74e5356",
        "NOMBRE": "FÍSTULA VESICOVAGINAL"
    },
    {
        "id": "c7821aa7-768b-a648-ebc2-bb812a8df1fc",
        "NOMBRE": "FÍSTULAS QUE AFECTAN EL TRACTO GENITAL FEMENINO"
    },
    {
        "id": "70f26fc4-a716-53b1-b090-31f94b608a8f",
        "NOMBRE": "FISURA ANAL AGUDA"
    },
    {
        "id": "5976cb01-5830-bb6b-1145-15c49cb7b5a1",
        "NOMBRE": "FISURA ANAL CRÓNICA"
    },
    {
        "id": "5e932274-6177-1c8b-b4d5-5b85f96483f6",
        "NOMBRE": "FISURA ANAL, NO ESPECIFICADA"
    },
    {
        "id": "210f0dd0-f4f5-191e-aac1-afc698d5aa4d",
        "NOMBRE": "FISURA DE LA ÚVULA"
    },
    {
        "id": "028d7cbc-88ca-0af8-4764-c7fb71fea54e",
        "NOMBRE": "FISURA DEL PALADAR"
    },
    {
        "id": "80d0b37c-b34a-3ec4-79cc-b6a1e5c0ea0c",
        "NOMBRE": "FISURA DEL PALADAR BLANDO"
    },
    {
        "id": "25c25f11-e867-1be4-682a-757a2eaba26e",
        "NOMBRE": "FISURA DEL PALADAR BLANDO CON LABIO LEPORINO BILATERAL"
    },
    {
        "id": "85d2d282-58dc-ab51-d4e0-44def5dcb7a8",
        "NOMBRE": "FISURA DEL PALADAR BLANDO CON LABIO LEPORINO UNILATERAL"
    },
    {
        "id": "97ec538b-6e40-56d8-c58b-46a670f5e100",
        "NOMBRE": "FISURA DEL PALADAR BLANDO, BILATERAL"
    },
    {
        "id": "886c6908-f53a-87c8-b3d2-32fd688693b0",
        "NOMBRE": "FISURA DEL PALADAR CON LABIO LEPORINO"
    },
    {
        "id": "4ed824b1-f988-30fa-6904-08992414c535",
        "NOMBRE": "FISURA DEL PALADAR CON LABIO LEPORINO BILATERAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "50f00311-1f1d-3357-db49-59139f06bf45",
        "NOMBRE": "FISURA DEL PALADAR CON LABIO LEPORINO UNILATERAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "be01ccf9-cdf4-6c56-d76e-2731101acb32",
        "NOMBRE": "FISURA DEL PALADAR DURO"
    },
    {
        "id": "ecb81bd0-a351-c609-29ae-81c5cedc9717",
        "NOMBRE": "FISURA DEL PALADAR DURO CON LABIO LEPORINO BILATERAL"
    },
    {
        "id": "0906f196-6346-d7a1-8a14-f6f5df5a575f",
        "NOMBRE": "FISURA DEL PALADAR DURO CON LABIO LEPORINO UNILATERAL"
    },
    {
        "id": "652bff9a-b42c-e24e-eb8b-2c0c87e50798",
        "NOMBRE": "FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO"
    },
    {
        "id": "8db7e412-eb0f-2bc7-ccea-9e7eca10fc22",
        "NOMBRE": "FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO CON LABIO LEPORINO BILATERAL"
    },
    {
        "id": "b4ccbfca-6d4b-f1c1-2367-94d4fbd5e91a",
        "NOMBRE": "FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO CON LABIO LEPORINO UNILATERAL"
    },
    {
        "id": "10427bdd-8659-4710-a228-b2c8965aeed0",
        "NOMBRE": "FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO, BILATERAL"
    },
    {
        "id": "a0735b36-13ae-51a6-3c52-7116089929b5",
        "NOMBRE": "FISURA DEL PALADAR DURO, BILATERAL"
    },
    {
        "id": "9c8f6979-632d-97d4-0bd6-5e4559f7f32b",
        "NOMBRE": "FISURA DEL PALADAR, BILATERAL SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "2227a40a-7f0f-9f89-4ebc-48fa64d6dbfb",
        "NOMBRE": "FISURA DEL PALADAR, LÍNEA MEDIA"
    },
    {
        "id": "b27d9ac8-9fb5-ad9a-c162-ad3892adc216",
        "NOMBRE": "FISURA DEL PALADAR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "88b04ae0-197c-97c9-d1a0-7a6f5073cb5a",
        "NOMBRE": "FISURA Y FÍSTULA DE LAS REGIONES ANAL Y RECTAL"
    },
    {
        "id": "96e0334f-5ec5-5f00-8446-4389374beb96",
        "NOMBRE": "FISURA Y FÍSTULA DEL PEZÓN"
    },
    {
        "id": "4561906e-2205-fcc0-8df7-250f6e1bec65",
        "NOMBRE": "FISURAS DEL PEZÓN ASOCIADAS CON EL PARTO"
    },
    {
        "id": "2b0913ce-9d7c-5a07-88c5-8147790bd7e0",
        "NOMBRE": "FLATULENCIA Y AFECCIONES AFINES"
    },
    {
        "id": "a06aa206-0dcb-3b4a-d4e3-28e0c6dd4598",
        "NOMBRE": "FLEBECTASIA CONGÉNITA"
    },
    {
        "id": "6363cef9-c544-424a-839b-d29d41e7951e",
        "NOMBRE": "FLEBITIS DE LA VENA PORTA"
    },
    {
        "id": "31f4d7df-9253-f401-70fc-23b2001b7075",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS"
    },
    {
        "id": "91f29ebb-302c-0dfe-c2fe-3f876f601b42",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS DE LA VENA FEMORAL"
    },
    {
        "id": "c7e0cf89-d658-65c2-c49f-7947c8fde29c",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS DE LOS MIEMBROS INFERIORES, NO ESPECIFICADA"
    },
    {
        "id": "32ad0803-c441-b918-5c4b-f43b38ca9203",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS DE OTROS SITIOS"
    },
    {
        "id": "9e639b6c-7916-ee44-ea61-1005fed9efb2",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS DE OTROS VASOS PROFUNDOS DE LOS MIEMBROS INFERIORES"
    },
    {
        "id": "78ef4953-9e56-5b55-08f0-fdca05809d62",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "015b41c1-f9ae-6961-653b-ea161a57c737",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS DE VASOS SUPERFICIALES DE LOS MIEMBROS INFERIORES"
    },
    {
        "id": "568010f1-4d8e-e9cb-5bf5-6717a68db319",
        "NOMBRE": "FLEBITIS Y TROMBOFLEBITIS INTRACRANEAL E INTRARRAQUÍDEA"
    },
    {
        "id": "425abb14-cd2f-6f20-fdee-653b0cfc3e50",
        "NOMBRE": "FLEBOTROMBOSIS PROFUNDA EN EL EMBARAZO"
    },
    {
        "id": "656a92ec-ff8d-c53a-5cd6-39138b49e963",
        "NOMBRE": "FLEBOTROMBOSIS PROFUNDA EN EL PUERPERIO"
    },
    {
        "id": "4a3ed6a9-9c36-fce8-f55d-f8a6179ff4b5",
        "NOMBRE": "FLUOROSIS DEL ESQUELETO"
    },
    {
        "id": "aad8c0e5-045c-f7b0-aa74-7dc4956f5a78",
        "NOMBRE": "FOBIAS ESPECÍFICAS (AISLADAS)"
    },
    {
        "id": "c4bfa642-4274-e6c7-0e76-11fab7dfaa1a",
        "NOMBRE": "FOBIAS SOCIALES"
    },
    {
        "id": "ba3a3f75-2291-2983-6a4f-f68d9a8cf3f8",
        "NOMBRE": "FOCOMELIA, MIEMBRO(S) NO ESPECIFICADO(S)"
    },
    {
        "id": "8c9bdad9-60b2-d398-0b1f-c589aa67c96d",
        "NOMBRE": "FOLICULITIS DECALVANTE"
    },
    {
        "id": "148b0096-9eaf-c608-da59-bd6e1d154c56",
        "NOMBRE": "FOLICULITIS ULERITEMATOSA RETICULADA"
    },
    {
        "id": "117a5c98-c65b-cf67-6683-0f1ed0bf01f1",
        "NOMBRE": "FORMACIÓN ANORMAL DE TEJIDO DURO EN LA PULPA"
    },
    {
        "id": "ebb8c5d2-9d5e-1a92-d6e9-1069a0fb2889",
        "NOMBRE": "FRACASO DE LA INDUCCIÓN DEL TRABAJO DE PARTO"
    },
    {
        "id": "5f3982e8-6c7e-b67f-1bbb-320369684f7a",
        "NOMBRE": "FRACASO DE LA INDUCCIÓN INSTRUMENTAL DEL TRABAJO DE PARTO"
    },
    {
        "id": "0b56c138-6c23-0f07-044c-be4bbc084e74",
        "NOMBRE": "FRACASO DE LA INDUCCIÓN MÉDICA DEL TRABAJO DE PARTO"
    },
    {
        "id": "27062ee7-cdf2-40db-b5b1-32cd0dd5246f",
        "NOMBRE": "FRACASO DE LA PRUEBA DEL TRABAJO DE PARTO, NO ESPECIFICADA"
    },
    {
        "id": "5916dd0f-3bee-193a-4caa-e3d554ac2afa",
        "NOMBRE": "FRACASO NO ESPECIFICADO DE LA APLICACIÓN DE FÓRCEPS O DE VENTOSA EXTRACTORA"
    },
    {
        "id": "69f2bade-a08c-dc51-b59a-f5bcb351f4a8",
        "NOMBRE": "FRACASO NO ESPECIFICADO DE LA INDUCCIÓN DEL TRABAJO DE PARTO"
    },
    {
        "id": "c2fb11a2-86e8-67c1-f4a8-58c20591f3e6",
        "NOMBRE": "FRACTURA A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "ad5568e0-2525-8793-aca8-8f64df038eb0",
        "NOMBRE": "FRACTURA DE COSTILLA"
    },
    {
        "id": "34e3247d-6b36-6717-7d7f-9b87d231026b",
        "NOMBRE": "FRACTURA DE HUESO DEL METATARSO"
    },
    {
        "id": "2d183272-5577-84ab-fb6f-aaf4c6e04dc3",
        "NOMBRE": "FRACTURA DE HUESO POSTERIOR A INSERCIÓN O IMPLANTE ORTOPÉDICO, PRÓTESIS ARTICULAR O PLACA ÓSEA"
    },
    {
        "id": "0cb6508c-c5e4-6599-ca85-cd697b599d95",
        "NOMBRE": "FRACTURA DE HUESOS DEL CRÁNEO Y DE LA CARA"
    },
    {
        "id": "0d06490e-5e63-9614-c11b-4eaaf82e7e6b",
        "NOMBRE": "FRACTURA DE LA BASE DEL CRÁNEO"
    },
    {
        "id": "2615b01d-2ba8-798b-3a6f-f067ea8cb2b0",
        "NOMBRE": "FRACTURA DE LA BÓVEDA DEL CRÁNEO"
    },
    {
        "id": "0887f52b-08bc-81ee-a5fc-8d2e20c00488",
        "NOMBRE": "FRACTURA DE LA CLAVÍCULA"
    },
    {
        "id": "12df2cf4-1b80-f9bc-cee5-80e72795e87b",
        "NOMBRE": "FRACTURA DE LA CLAVÍCULA DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "dc7b78b7-11e5-c9c4-d6d7-357882670787",
        "NOMBRE": "FRACTURA DE LA COLUMNA LUMBAR Y DE LA PELVIS"
    },
    {
        "id": "98f1d6df-0956-b134-fe52-5ab4697cb6d4",
        "NOMBRE": "FRACTURA DE LA COLUMNA VERTEBRAL, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "b91f347d-adfe-2eb0-48fb-3586181a2b89",
        "NOMBRE": "FRACTURA DE LA DIÁFISIS DE LA TIBIA"
    },
    {
        "id": "e0378316-df25-8610-c7ba-32b906154bde",
        "NOMBRE": "FRACTURA DE LA DIÁFISIS DEL CÚBITO"
    },
    {
        "id": "d8c7b19a-ae99-0dfc-5d6d-c2508f93bec7",
        "NOMBRE": "FRACTURA DE LA DIÁFISIS DEL CÚBITO Y DEL RADIO"
    },
    {
        "id": "70e925f0-6664-8024-c390-d7a6c058ced6",
        "NOMBRE": "FRACTURA DE LA DIÁFISIS DEL FÉMUR"
    },
    {
        "id": "06406608-c24a-2a5a-76bf-0c8b5ac0d371",
        "NOMBRE": "FRACTURA DE LA DIÁFISIS DEL HÚMERO"
    },
    {
        "id": "4c801418-6a9f-3a8e-9f53-8c57db0619ca",
        "NOMBRE": "FRACTURA DE LA DIÁFISIS DEL RADIO"
    },
    {
        "id": "cb383fa5-2824-c7ae-6b42-2b82824d1968",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS INFERIOR DE LA TIBIA"
    },
    {
        "id": "8f2e7a53-6af9-1ed2-35d5-071499853f60",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS INFERIOR DEL CÚBITO Y DEL RADIO"
    },
    {
        "id": "650d9e36-106c-ae2e-30d6-491543bde65f",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS INFERIOR DEL FÉMUR"
    },
    {
        "id": "d07ad28c-b9b9-e023-593d-531a66b84d46",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS INFERIOR DEL HÚMERO"
    },
    {
        "id": "f47a303a-09eb-988e-2f9b-70eefa2e3751",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS INFERIOR DEL RADIO"
    },
    {
        "id": "60f300d8-1e9a-dcca-f7c3-f2dd36b220b4",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS SUPERIOR DE LA TIBIA"
    },
    {
        "id": "47d67af5-a865-8728-aa74-254a98fe36e7",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS SUPERIOR DEL CÚBITO"
    },
    {
        "id": "3120e832-a3dd-bf25-d6a5-8c2d597a6fa7",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS SUPERIOR DEL HÚMERO"
    },
    {
        "id": "1ed2d694-434b-e25b-9737-a41d0ab88681",
        "NOMBRE": "FRACTURA DE LA EPÍFISIS SUPERIOR DEL RADIO"
    },
    {
        "id": "e99e3173-554d-7214-cf7e-448ebf49b9b4",
        "NOMBRE": "FRACTURA DE LA PIERNA, INCLUSIVE DEL TOBILLO"
    },
    {
        "id": "4e219a9d-19c5-282d-1ccf-b8e900ac1cf0",
        "NOMBRE": "FRACTURA DE LA PIERNA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "f4142cbf-d056-235c-e538-b0bdc77cb507",
        "NOMBRE": "FRACTURA DE LA PRIMERA VÉRTEBRA CERVICAL"
    },
    {
        "id": "50cb093d-0004-cdf8-e189-7534b0730df3",
        "NOMBRE": "FRACTURA DE LA RÓTULA"
    },
    {
        "id": "83adcc1d-59ab-6caa-49bf-cc3a3f87cadf",
        "NOMBRE": "FRACTURA DE LA SEGUNDA VÉRTEBRA CERVICAL"
    },
    {
        "id": "c34f98ab-9dc1-8d66-77a9-c057a4c53779",
        "NOMBRE": "FRACTURA DE LAS COSTILLAS, DEL ESTERNÓN Y DE LA COLUMNA TORÁCICA [DORSAL]"
    },
    {
        "id": "3c55f6f2-c52c-1d20-47b3-fc31cf0b1fbf",
        "NOMBRE": "FRACTURA DE LOS DIENTES"
    },
    {
        "id": "f52da7cc-92e1-c291-dbcc-bd3ca95999d5",
        "NOMBRE": "FRACTURA DE LOS HUESOS DE LA NARIZ"
    },
    {
        "id": "f19c33e1-a8d6-d033-7f99-c1b5f21ecd22",
        "NOMBRE": "FRACTURA DE LOS HUESOS DE OTRO(S) DEDO(S) DEL PIE"
    },
    {
        "id": "1ee06f18-e51a-0078-0b7c-2cda1cca9d97",
        "NOMBRE": "FRACTURA DE LOS HUESOS DEL DEDO GORDO DEL PIE"
    },
    {
        "id": "e4748409-ce06-b6e8-9dc9-4b8961f1f8a9",
        "NOMBRE": "FRACTURA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "36271b90-b685-9296-7871-374615db49ad",
        "NOMBRE": "FRACTURA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "e2363f69-b3c3-898a-1751-792fabc079f7",
        "NOMBRE": "FRACTURA DE OTRAS PARTES DE LA PIERNA"
    },
    {
        "id": "7f56edc4-f4c9-da04-1112-a3d01ddac36a",
        "NOMBRE": "FRACTURA DE OTRAS PARTES DEL ANTEBRAZO"
    },
    {
        "id": "ef935613-b716-c3c2-6219-a4c086910ece",
        "NOMBRE": "FRACTURA DE OTRAS PARTES DEL CUELLO"
    },
    {
        "id": "a036d248-286e-0033-2331-07b170c97b89",
        "NOMBRE": "FRACTURA DE OTRAS PARTES DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "a17fd8d3-e62b-fa11-000d-d984c916f69d",
        "NOMBRE": "FRACTURA DE OTRAS PARTES DEL TÓRAX ÓSEO"
    },
    {
        "id": "f5a6e4fd-515f-2522-6545-770a6784a199",
        "NOMBRE": "FRACTURA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y DE LA PELVIS"
    },
    {
        "id": "7bb534e9-90bb-d2e3-2fca-714405b19613",
        "NOMBRE": "FRACTURA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "8d90cff5-9650-c234-59d6-d29d4b9e2b44",
        "NOMBRE": "FRACTURA DE OTRAS VÉRTEBRAS CERVICALES ESPECIFICADAS"
    },
    {
        "id": "931c8f32-d63f-4b68-a91c-eaf1063e5428",
        "NOMBRE": "FRACTURA DE OTRO DEDO DE LA MANO"
    },
    {
        "id": "fab687b7-9708-f364-600b-173832c83114",
        "NOMBRE": "FRACTURA DE OTRO(S) HUESO(S) DEL CARPO"
    },
    {
        "id": "c732c303-8f9d-fde8-be8e-fdc200eb8f81",
        "NOMBRE": "FRACTURA DE OTRO(S) HUESO(S) DEL TARSO"
    },
    {
        "id": "717919cd-754e-7cfc-c626-9a2b778f5d47",
        "NOMBRE": "FRACTURA DE OTROS HUESOS DEL CRÁNEO Y DE LA CARA"
    },
    {
        "id": "424f1473-4bbb-bef9-c601-ec63b06a5fa2",
        "NOMBRE": "FRACTURA DE OTROS HUESOS METACARPIANOS"
    },
    {
        "id": "0c67e6b6-8f25-e764-9c2e-bfcb462b7777",
        "NOMBRE": "FRACTURA DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "ade6a2e6-303b-18d0-0ce4-f549d508a930",
        "NOMBRE": "FRACTURA DE VÉRTEBRA LUMBAR"
    },
    {
        "id": "afeb6767-4366-c365-889e-6653742f03bf",
        "NOMBRE": "FRACTURA DE VÉRTEBRA POR FATIGA"
    },
    {
        "id": "f787337b-9e11-40ee-b7a7-826cf9e1c8b7",
        "NOMBRE": "FRACTURA DE VÉRTEBRA TORÁCICA"
    },
    {
        "id": "d07d9e19-59f1-9f50-7a34-c6c28c6f4718",
        "NOMBRE": "FRACTURA DEL ACETÁBULO"
    },
    {
        "id": "013ff459-170a-7e21-561f-87e94fb96801",
        "NOMBRE": "FRACTURA DEL ANTEBRAZO"
    },
    {
        "id": "0bdc1697-8908-2f36-adbb-ed1db7cee043",
        "NOMBRE": "FRACTURA DEL ANTEBRAZO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "85541ee7-8871-fdb8-5425-c45c3a1e758e",
        "NOMBRE": "FRACTURA DEL ASTRÁGALO"
    },
    {
        "id": "547cc163-6e3f-cf06-62c2-e25aeb471371",
        "NOMBRE": "FRACTURA DEL CALCÁNEO"
    },
    {
        "id": "46a8b2f9-b1ff-9c3f-86f8-fe835d1abcc9",
        "NOMBRE": "FRACTURA DEL CÓCCIX"
    },
    {
        "id": "1276dedb-c487-b4fb-6b3f-f896b6d2fcbd",
        "NOMBRE": "FRACTURA DEL CRÁNEO DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "789f922d-11b5-3e71-33ee-7a10c854f670",
        "NOMBRE": "FRACTURA DEL CRÁNEO Y DE LOS HUESOS DE LA CARA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "c14adecc-db19-728c-1669-8a242d8cd9b3",
        "NOMBRE": "FRACTURA DEL CUELLO"
    },
    {
        "id": "cdadae35-5f29-4b22-2d47-69c8fcab9115",
        "NOMBRE": "FRACTURA DEL CUELLO DE FÉMUR"
    },
    {
        "id": "06f5507c-0164-4290-d99d-aec478871820",
        "NOMBRE": "FRACTURA DEL CUELLO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "3755518e-02d4-cd68-411b-44511e7746fa",
        "NOMBRE": "FRACTURA DEL ESTERNÓN"
    },
    {
        "id": "06fdcb72-c133-c2f5-97d0-52441776c9a0",
        "NOMBRE": "FRACTURA DEL FÉMUR"
    },
    {
        "id": "ec4926a4-ab8e-8e67-4e78-fb7166f93727",
        "NOMBRE": "FRACTURA DEL FÉMUR, PARTE NO ESPECIFICADA"
    },
    {
        "id": "11ae575a-c201-8882-289a-de3a58886e01",
        "NOMBRE": "FRACTURA DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "165352b0-8c7d-15f4-ef1d-71bf01fc1a37",
        "NOMBRE": "FRACTURA DEL HOMBRO Y DEL BRAZO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "a68bbbc6-1b15-ac31-a491-7d9e00310ac4",
        "NOMBRE": "FRACTURA DEL HUESO ESCAFOIDES [NAVICULAR] DE LA MANO"
    },
    {
        "id": "1efadec0-6230-d47c-7033-dba29c756088",
        "NOMBRE": "FRACTURA DEL HUESO ILIACO"
    },
    {
        "id": "6ba18f84-2061-f58c-b61a-13632d7e0578",
        "NOMBRE": "FRACTURA DEL MALAR Y DEL HUESO MAXILAR SUPERIOR"
    },
    {
        "id": "18eeeabb-9ed4-7bcc-9357-44ef2dafece7",
        "NOMBRE": "FRACTURA DEL MALÉOLO EXTERNO"
    },
    {
        "id": "e55e59b4-8d49-e426-4f00-a0cc1fb5404a",
        "NOMBRE": "FRACTURA DEL MALÉOLO INTERNO"
    },
    {
        "id": "996d35ba-0050-0c0a-e363-ec4443debb33",
        "NOMBRE": "FRACTURA DEL MAXILAR INFERIOR"
    },
    {
        "id": "28b5a426-a7de-9b08-e21f-54710884d7b1",
        "NOMBRE": "FRACTURA DEL OMÓPLATO"
    },
    {
        "id": "02383f19-2ad4-8b1f-f639-dca0089a9828",
        "NOMBRE": "FRACTURA DEL PERONÉ SOLAMENTE"
    },
    {
        "id": "11388e53-71da-2305-b7da-eb4df9184d76",
        "NOMBRE": "FRACTURA DEL PIE, EXCEPTO DEL TOBILLO"
    },
    {
        "id": "861a5a7d-0f30-c75b-5e68-d9b92b04b9a0",
        "NOMBRE": "FRACTURA DEL PIE, NO ESPECIFICADA"
    },
    {
        "id": "83389d2f-5e5c-b286-e492-fa7e9db895aa",
        "NOMBRE": "FRACTURA DEL PRIMER METACARPIANO"
    },
    {
        "id": "2de0d283-40fd-2bd5-a679-4e3c50346a73",
        "NOMBRE": "FRACTURA DEL PUBIS"
    },
    {
        "id": "9ae20c7f-4773-87e9-fd81-5dae9d18dfee",
        "NOMBRE": "FRACTURA DEL PULGAR"
    },
    {
        "id": "883816b5-d232-6a42-9fda-1816ea26b486",
        "NOMBRE": "FRACTURA DEL SACRO"
    },
    {
        "id": "6687e82f-6eb4-07f2-f9ed-b0998944dd30",
        "NOMBRE": "FRACTURA DEL SUELO DE LA ÓRBITA"
    },
    {
        "id": "230b1e85-2dff-d317-ba2a-6003f21e9b77",
        "NOMBRE": "FRACTURA DEL TÓRAX ÓSEO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "283fa15b-e1f3-ef67-e6d3-c2c56c1b21b3",
        "NOMBRE": "FRACTURA ÓSEA EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "bc0c9955-211d-3000-55c4-5042791cd394",
        "NOMBRE": "FRACTURA PATOLÓGICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "133e2e71-0ef5-1539-4551-ce7833460967",
        "NOMBRE": "FRACTURA PERTROCANTERIANA"
    },
    {
        "id": "eaf27d42-5abf-5a1c-60d7-9ac60adb18f3",
        "NOMBRE": "FRACTURA POR TENSIÓN, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "aaab2927-6970-8eaf-0653-8a3548ceae3f",
        "NOMBRE": "FRACTURA SUBTROCANTERIANA"
    },
    {
        "id": "c8015f00-9554-de72-baf3-9c8329beee04",
        "NOMBRE": "FRACTURAS DE OTRAS PARTES DEL FÉMUR"
    },
    {
        "id": "4c1796fd-52ed-1bff-a5f3-6a3f3bb45459",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE COLUMNA CERVICAL"
    },
    {
        "id": "a6806f28-c9f3-19f4-5479-a90b6d3eca69",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE COLUMNA TORÁCICA"
    },
    {
        "id": "452a473b-37f5-6029-3220-93451b199e45",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE COSTILLAS"
    },
    {
        "id": "75e544d5-4ac7-11c7-6ea1-a1e4a7e1e3ed",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE HUESOS METACARPIANOS"
    },
    {
        "id": "98289e36-ad2e-50b2-9a9a-aebe346cc370",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE LA CLAVÍCULA, DEL OMÓPLATO Y DEL HÚMERO"
    },
    {
        "id": "68f96b92-4f47-58fa-7979-4b54abf66ede",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE LA COLUMNA LUMBAR Y DE LA PELVIS"
    },
    {
        "id": "5975b286-7592-d8dc-212a-49bed6cd4e97",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE LA PIERNA"
    },
    {
        "id": "c136ba41-530a-927d-f9bc-b8eab4efc039",
        "NOMBRE": "FRACTURAS MÚLTIPLES DE LOS DEDOS DE LA MANO"
    },
    {
        "id": "5502a591-b59c-0249-3f98-fec050253948",
        "NOMBRE": "FRACTURAS MÚLTIPLES DEL ANTEBRAZO"
    },
    {
        "id": "a34fa556-3df9-2b5e-410d-e83d39b0ce60",
        "NOMBRE": "FRACTURAS MÚLTIPLES DEL FÉMUR"
    },
    {
        "id": "603a29d9-5304-adb2-cfc5-b005ed229547",
        "NOMBRE": "FRACTURAS MÚLTIPLES DEL PIE"
    },
    {
        "id": "db3e2927-5da1-c29a-4f97-ac3c6fdb3dc2",
        "NOMBRE": "FRACTURAS MÚLTIPLES QUE COMPROMETEN EL CRÁNEO Y LOS HUESOS DE LA CARA"
    },
    {
        "id": "29055de5-8fef-7aae-5b19-d8387be27357",
        "NOMBRE": "FRACTURAS MÚLTIPLES, NO ESPECIFICADAS"
    },
    {
        "id": "b731e08e-bccb-2576-ca45-55465a78f6ee",
        "NOMBRE": "FRACTURAS QUE AFECTAN EL TÓRAX CON LA REGIÓN LUMBOSACRA Y LA PELVIS"
    },
    {
        "id": "bd737f53-3cb4-1ac3-feab-123168844b43",
        "NOMBRE": "FRACTURAS QUE AFECTAN EL TÓRAX CON LA REGIÓN LUMBOSACRA Y LA PELVIS CON MIEMBRO(S)"
    },
    {
        "id": "b177a740-a9e4-f71f-fef7-fa78f63ed410",
        "NOMBRE": "FRACTURAS QUE AFECTAN LA CABEZA CON EL CUELLO"
    },
    {
        "id": "097d7201-fe63-bb16-5f69-a0019c1f6286",
        "NOMBRE": "FRACTURAS QUE AFECTAN MÚLTIPLES REGIONES DE AMBOS MIEMBROS INFERIORES"
    },
    {
        "id": "838425e6-1cc6-b407-01e5-4aab968984c3",
        "NOMBRE": "FRACTURAS QUE AFECTAN MÚLTIPLES REGIONES DE AMBOS MIEMBROS SUPERIORES"
    },
    {
        "id": "1d59e108-29d4-a7d2-cbb0-d87d0df4b268",
        "NOMBRE": "FRACTURAS QUE AFECTAN MÚLTIPLES REGIONES DE MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "8161c959-5cf4-881b-0f7c-320fd70b7dfb",
        "NOMBRE": "FRACTURAS QUE AFECTAN MÚLTIPLES REGIONES DE UN MIEMBRO INFERIOR"
    },
    {
        "id": "ec9bdab4-777b-ebc0-866b-57bcb81e8907",
        "NOMBRE": "FRACTURAS QUE AFECTAN MÚLTIPLES REGIONES DE UN MIEMBRO SUPERIOR"
    },
    {
        "id": "50446091-3f17-0987-f12e-25dae6e14b1c",
        "NOMBRE": "FRACTURAS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "64e13e03-d351-df74-b17f-ddd900763eca",
        "NOMBRE": "FRACTURAS QUE AFECTAN OTRAS COMBINACIONES DE LAS REGIONES DEL CUERPO"
    },
    {
        "id": "09b9b620-2d52-72fc-c44b-6fb3a46d6c2b",
        "NOMBRE": "FRAMBESIA"
    },
    {
        "id": "05384d2e-efb7-36f1-3857-0ba10a1a2307",
        "NOMBRE": "FRAMBESIA LATENTE"
    },
    {
        "id": "26ae9d7c-e93b-7cd8-4bfe-9f3b3a96c8c8",
        "NOMBRE": "FRAMBESIA, NO ESPECIFICADA"
    },
    {
        "id": "67b0f145-d74c-29ad-ffb6-2173a06d6c7e",
        "NOMBRE": "FUGA DISOCIATIVA"
    },
    {
        "id": "f12600d2-eaf2-ae4e-5c46-1b30f7748f4a",
        "NOMBRE": "FUNCIONAMIENTO DEFECTUOSO DE LA TRAQUEOSTOMÍA"
    },
    {
        "id": "36ade154-b264-308f-9b39-27eb8ce253b2",
        "NOMBRE": "FUSIÓN DE LABIOS DE LA VULVA"
    },
    {
        "id": "e1b467bf-a495-a78a-e1dd-60ac5eaaaa00",
        "NOMBRE": "FUSIÓN DE LOS DEDOS DE LA MANO"
    },
    {
        "id": "ab0e01b3-5ea0-2a48-bfc2-6f83a2a09068",
        "NOMBRE": "FUSIÓN DE LOS DEDOS DEL PIE"
    },
    {
        "id": "39a98b19-65fe-4660-73fe-742dd8d4ee68",
        "NOMBRE": "GALACTORREA"
    },
    {
        "id": "833e3a54-df94-6bcb-e5e4-f9275562f00e",
        "NOMBRE": "GALACTORREA NO ASOCIADA CON EL PARTO"
    },
    {
        "id": "d131851d-4ef2-0641-f3fc-95254f0b6aae",
        "NOMBRE": "GAMMOPATÍA MONOCLONAL DE SIGNIFICADO INCIERTO [GMSI]"
    },
    {
        "id": "3e7c863f-0971-50a6-3a4c-e3b333b259a3",
        "NOMBRE": "GANGLIÓN"
    },
    {
        "id": "4c44bfea-f577-3af6-3135-d8f6eedd0e3d",
        "NOMBRE": "GANGLIONITIS GENICULADA"
    },
    {
        "id": "80d72c30-64e1-9fd4-b3a1-f8688b1974d9",
        "NOMBRE": "GANGLIOSIDOSIS GM2"
    },
    {
        "id": "4d4735bd-31c4-4d6a-5f56-e24bd9aaf26d",
        "NOMBRE": "GANGOSA"
    },
    {
        "id": "872b174a-b57f-6185-8b4c-1ccecd545413",
        "NOMBRE": "GANGRENA GASEOSA"
    },
    {
        "id": "0f5d01b6-2800-ec6d-4d8b-ec92ef71b4c3",
        "NOMBRE": "GANGRENA Y NECROSIS DEL PULMÓN"
    },
    {
        "id": "4b5b0781-78cb-9528-1556-099ad7bc4101",
        "NOMBRE": "GANGRENA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "5a702f21-709b-3bc3-faf8-1b4895785bd2",
        "NOMBRE": "GASTRITIS AGUDA HEMORRÁGICA"
    },
    {
        "id": "1b8c51de-9bee-8cb2-c916-aa59614933ec",
        "NOMBRE": "GASTRITIS ALCOHÓLICA"
    },
    {
        "id": "5ad0bd20-fe3e-4849-b447-990f778a8c33",
        "NOMBRE": "GASTRITIS CRÓNICA ATRÓFICA"
    },
    {
        "id": "b3c916cf-5ec5-c6c4-d763-a66c2c6ad7d4",
        "NOMBRE": "GASTRITIS CRÓNICA SUPERFICIAL"
    },
    {
        "id": "6314e04c-acde-d109-77e1-2a7bbf203424",
        "NOMBRE": "GASTRITIS CRÓNICA, NO ESPECIFICADA"
    },
    {
        "id": "2bcc2489-f0c4-0fb1-e7fe-4f24ba526521",
        "NOMBRE": "GASTRITIS Y DUODENITIS"
    },
    {
        "id": "4f83b5b6-dbba-3969-96f1-596467394ed5",
        "NOMBRE": "GASTRITIS, NO ESPECIFICADA"
    },
    {
        "id": "1e850b4f-2366-ed73-4dce-9929f7970d06",
        "NOMBRE": "GASTRODUODENITIS, NO ESPECIFICADA"
    },
    {
        "id": "68eb3927-dbc0-9e40-b947-558e3df01a09",
        "NOMBRE": "GASTROENTERITIS Y COLITIS DE ORIGEN NO ESPECIFICADO"
    },
    {
        "id": "262fb78f-5b9a-d235-03f6-611c3743ed17",
        "NOMBRE": "GASTROENTEROPATÍA AGUDA DEBIDA AL AGENTE DE NORWALK"
    },
    {
        "id": "35b4eb80-f972-0573-b412-f4781445e6c5",
        "NOMBRE": "GASTROSQUISIS"
    },
    {
        "id": "f8f14527-a1ab-6f6d-1259-d0d4f5bc5d4c",
        "NOMBRE": "GASTROSTOMÍA"
    },
    {
        "id": "ce3e1c1e-06d7-db4c-06b0-d604ef007da1",
        "NOMBRE": "GEMELOS SIAMESES"
    },
    {
        "id": "91c6f6ae-1a20-d82a-fab6-49fb4f5e53e8",
        "NOMBRE": "GEMELOS, AMBOS NACIDOS MUERTOS"
    },
    {
        "id": "bac9e872-a877-c5a7-05e8-4690c4ec863a",
        "NOMBRE": "GEMELOS, AMBOS NACIDOS VIVOS"
    },
    {
        "id": "e9207d78-cd7c-7e92-c940-466c66da84b1",
        "NOMBRE": "GEMELOS, LUGAR DE NACIMIENTO NO ESPECIFICADO"
    },
    {
        "id": "a5cafc1a-efe3-411b-11f6-be71785a2809",
        "NOMBRE": "GEMELOS, NACIDOS EN HOSPITAL"
    },
    {
        "id": "9bc0c94a-fb04-398e-00e7-6c106069bf1a",
        "NOMBRE": "GEMELOS, NACIDOS FUERA DE HOSPITAL"
    },
    {
        "id": "a309cdc6-578f-9d8b-7e10-90706a207785",
        "NOMBRE": "GEMELOS, UN NACIDO VIVO Y UN NACIDO MUERTO"
    },
    {
        "id": "1b5ce266-caf8-33b6-629d-7ad4f753cb98",
        "NOMBRE": "GEOTRICOSIS"
    },
    {
        "id": "7ae15965-52b3-6e4c-b5d5-0a9649dab8e5",
        "NOMBRE": "GIARDIASIS [LAMBLIASIS]"
    },
    {
        "id": "25389970-cc6a-5129-0448-c367aef7af28",
        "NOMBRE": "GINGIVITIS AGUDA"
    },
    {
        "id": "2018b7c7-2352-5fc0-ec4f-77d3bdc92213",
        "NOMBRE": "GINGIVITIS CRÓNICA"
    },
    {
        "id": "bdbc8f9e-2f91-0b73-7d42-72e76c20a742",
        "NOMBRE": "GINGIVITIS Y ENFERMEDADES PERIODONTALES"
    },
    {
        "id": "8a1a1fc4-de1e-3bb2-aaa5-fc989aab569b",
        "NOMBRE": "GINGIVOESTOMATITIS Y FARINGOAMIGDALITIS HERPÉTICA"
    },
    {
        "id": "58af3492-dfea-3d31-62a6-dd35c4be7adc",
        "NOMBRE": "GLAUCOMA"
    },
    {
        "id": "778de79a-cfd3-7eb9-4a9a-3970c2f026bc",
        "NOMBRE": "GLAUCOMA CONGÉNITO"
    },
    {
        "id": "b86bdf47-85a1-24fa-f0ba-dab130d47549",
        "NOMBRE": "GLAUCOMA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8d4c9f38-f1c6-8c90-2a38-56a9937ba2cc",
        "NOMBRE": "GLAUCOMA EN ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABÓLICAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e21ab0f2-b7c6-ba1c-53d4-ac3cc6719840",
        "NOMBRE": "GLAUCOMA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "2ea39da2-fe25-e1c0-f791-0e63b3ca6a38",
        "NOMBRE": "GLAUCOMA PRIMARIO DE ÁNGULO ABIERTO"
    },
    {
        "id": "de935bb0-d998-cf15-b660-e98e3f011c8f",
        "NOMBRE": "GLAUCOMA PRIMARIO DE ÁNGULO CERRADO"
    },
    {
        "id": "86406332-9bef-2ba8-7d86-916016391e75",
        "NOMBRE": "GLAUCOMA SECUNDARIO A DROGAS"
    },
    {
        "id": "612d7536-1bc9-60e9-d921-f549f3f3faa5",
        "NOMBRE": "GLAUCOMA SECUNDARIO A INFLAMACIÓN OCULAR"
    },
    {
        "id": "8bfe73d8-9138-746a-5347-2e150ac54418",
        "NOMBRE": "GLAUCOMA SECUNDARIO A OTROS TRASTORNOS DEL OJO"
    },
    {
        "id": "0586fe87-1258-d229-d678-eb88663aa17e",
        "NOMBRE": "GLAUCOMA SECUNDARIO A TRAUMATISMO OCULAR"
    },
    {
        "id": "2eb88609-4b9d-c4f9-1b2b-609e29d32def",
        "NOMBRE": "GLAUCOMA, NO ESPECIFICADO"
    },
    {
        "id": "e660dc6b-2b91-7835-2e56-3ab16cb1e6fd",
        "NOMBRE": "GLOBO OCULAR QUÍSTICO"
    },
    {
        "id": "c1bc2e27-e56c-d080-91ba-a895cef0c811",
        "NOMBRE": "GLOSITIS"
    },
    {
        "id": "c85db4ae-0b9b-3307-ec54-734ec810a9fc",
        "NOMBRE": "GLOSITIS ROMBOIDEA MEDIANA"
    },
    {
        "id": "b7c58d3f-cec5-3a68-6d6e-7ef4327ae3a2",
        "NOMBRE": "GLOSODINIA"
    },
    {
        "id": "69ae0d91-c97c-10b2-bf1a-dd635a983662",
        "NOMBRE": "GLUCOSURIA"
    },
    {
        "id": "8e222bdc-ab9a-fcd4-9f62-df9b1a9b7e81",
        "NOMBRE": "GNATOSTOMIASIS"
    },
    {
        "id": "d0acdcd6-59bb-4153-921f-4e54b22c20df",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES"
    },
    {
        "id": "ae387f23-d839-0c53-e319-2f18a74c2be9",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "6efb37c7-1577-d9df-9d5f-76f9f7c41ec5",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0fa7b254-2546-97d0-577c-5a340b5a9770",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, CALLES Y CARRETERAS"
    },
    {
        "id": "5b3d4be8-8a8c-d556-1bd7-3296a774b4e4",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "52f40499-a92c-0d10-1e2d-c6a47ea8f4c3",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "82310d4b-51f7-fcc9-0355-ecd37ed06f49",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, GRANJA"
    },
    {
        "id": "faa7ab28-1b07-7d23-8a98-3d400b8c750f",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f87bdeda-36e5-aeee-d022-516ba1a9a720",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "cbcdfc44-3f04-0f36-cc83-1fb887affa9e",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ec5a52d5-a8fe-da87-5f67-f6ce6140b13c",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, VIVIENDA"
    },
    {
        "id": "175deb9d-1b50-9910-c619-ecbf98c072c9",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS"
    },
    {
        "id": "4215a097-85d0-8964-bb91-e1f5d81e3365",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "bbf0e092-9711-fc69-bdbc-756d311a3d40",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f92972f0-5541-386c-4118-6818ee53849f",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, CALLES Y CARRETERAS"
    },
    {
        "id": "bd4b78b3-3989-6b96-5a59-9973e9242006",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5728aca5-ec21-f76f-6c40-d2956a69feee",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "87bab8b3-a4dc-16f1-c8a8-74f892885233",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, GRANJA"
    },
    {
        "id": "5bf88069-df6e-be9c-c6fb-2c88e7eee391",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "108ddf3f-5aca-f9a9-536d-f1e1ec7855b0",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "163de1de-0120-7009-9fe7-4effbdf4f6de",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "04187f34-c917-e1d9-f40a-1810dd2c1fdc",
        "NOMBRE": "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, VIVIENDA"
    },
    {
        "id": "a431aa3f-2542-70d8-d7b1-0890245259dc",
        "NOMBRE": "GOLPE DE CALOR E INSOLACIÓN"
    },
    {
        "id": "3471d1bc-22e8-c991-3ca6-eecc89cf23af",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE"
    },
    {
        "id": "0761e1bb-f2a1-55bd-d591-914b9c08dcef",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "53ac2e09-1196-c17f-4797-0c708312c0bc",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "0533bf0e-2379-d3cc-984e-2d04ba190c38",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, CALLES Y CARRETERAS"
    },
    {
        "id": "658c8fcf-d578-004c-66d0-9509ab5583c8",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "25a5f7f6-f94f-50e5-6773-19828573afc1",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "31fe007c-ad7f-3bc5-0a28-0965c1f6f8c3",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, GRANJA"
    },
    {
        "id": "fc52f5be-1e6c-b96c-1a55-3fdc92602360",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "71ec7ebd-1e84-d6c2-4da0-2b3bc6492f9e",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "59191bea-e738-22fc-c1e5-a3e370fc3bf0",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e9c1c11e-6f1c-1df7-f475-30a7c1bc9609",
        "NOMBRE": "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, VIVIENDA"
    },
    {
        "id": "9e605b60-3bc9-4bf0-94ca-c770c63b32eb",
        "NOMBRE": "GOMA Y ÚLCERAS DE FRAMBESIA"
    },
    {
        "id": "63368a13-d28c-86b3-ad5a-3df675bbdd64",
        "NOMBRE": "GONARTROSIS [ARTROSIS DE LA RODILLA]"
    },
    {
        "id": "dc40a437-1949-810f-62ad-73e144bf58ec",
        "NOMBRE": "GONARTROSIS POSTRAUMÁTICA, BILATERAL"
    },
    {
        "id": "df362203-4d98-f08b-5ac2-4e40a7a12e30",
        "NOMBRE": "GONARTROSIS PRIMARIA, BILATERAL"
    },
    {
        "id": "08ad1245-4b9c-ea13-dc2f-092c490959da",
        "NOMBRE": "GONARTROSIS, NO ESPECIFICADA"
    },
    {
        "id": "cfcee1aa-9d5e-9604-0d03-afb7f6f1e3de",
        "NOMBRE": "GONORREA QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "59587b13-ada8-9763-a015-92a6af6c1e70",
        "NOMBRE": "GOTA"
    },
    {
        "id": "7240c24a-6988-1c54-caea-ff61065272d5",
        "NOMBRE": "GOTA DEBIDA A ALTERACIÓN DE LA FUNCIÓN RENAL"
    },
    {
        "id": "68216c3a-87d6-f6dd-31c8-4c45e57b98a7",
        "NOMBRE": "GOTA IDIOPÁTICA"
    },
    {
        "id": "9b009e3e-c4a1-27cb-2c2b-630be20500e2",
        "NOMBRE": "GOTA INDUCIDA POR DROGAS"
    },
    {
        "id": "4e5413dd-56be-ea29-35e1-3d204fa8d35f",
        "NOMBRE": "GOTA SATURNINA"
    },
    {
        "id": "10313c8a-8598-0717-0ea1-d99284f9590e",
        "NOMBRE": "GOTA, NO ESPECIFICADA"
    },
    {
        "id": "54b81343-736c-6908-f21d-259ceb6d8ab0",
        "NOMBRE": "GRANULOMA ACTÍNICO"
    },
    {
        "id": "c963688b-9d77-f1e3-c132-5f7b2fd8f204",
        "NOMBRE": "GRANULOMA ANULAR"
    },
    {
        "id": "0e189c08-2b80-7ae3-1d17-3b7b8787134a",
        "NOMBRE": "GRANULOMA CENTRAL DE CÉLULAS GIGANTES"
    },
    {
        "id": "8511f024-0ae3-5d5c-0b11-472afe6e20ec",
        "NOMBRE": "GRANULOMA FACIAL (GRANULOMA EOSINÓFILO DE LA PIEL)"
    },
    {
        "id": "dfdbbb60-b903-e425-065a-73411a7877ec",
        "NOMBRE": "GRANULOMA INGUINAL"
    },
    {
        "id": "2e53e528-3401-4180-70d4-bed94e24f096",
        "NOMBRE": "GRANULOMA LETAL DE LA LÍNEA MEDIA"
    },
    {
        "id": "37b0ad1b-6706-a97a-8b30-a7e497c14503",
        "NOMBRE": "GRANULOMA PIÓGENO"
    },
    {
        "id": "222d14fd-1e75-7709-425e-a572ecb755a0",
        "NOMBRE": "GRANULOMA POR CUERPO EXTRAÑO EN LA PIEL Y EN EL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "d386149d-795a-e04d-4201-c7fa09c3e557",
        "NOMBRE": "GRANULOMA POR CUERPO EXTRAÑO EN TEJIDO BLANDO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "aec2f3bb-3a01-880b-16ce-c715efcab049",
        "NOMBRE": "GRANULOMA Y LESIONES SEMEJANTES DE LA MUCOSA BUCAL"
    },
    {
        "id": "a2fb1041-9205-e901-a9f8-ce26b031fb59",
        "NOMBRE": "GRANULOMATOSIS DE WEGENER"
    },
    {
        "id": "52e2c1e7-64a5-1875-2c14-1d484e24145b",
        "NOMBRE": "HAEMOPHILUS INFLUENZAE [H. INFLUENZAE] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "e57a73fa-a2ad-cc15-ce8e-e34e8b6e7b39",
        "NOMBRE": "HALITOSIS"
    },
    {
        "id": "e48991de-b65d-564d-0b94-bc3c7725f47e",
        "NOMBRE": "HALLAZGO ANORMAL EN LA QUÍMICA SANGUÍNEA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "535d6ade-e280-2edc-7a42-1afedb87bbdc",
        "NOMBRE": "HALLAZGO ANORMAL NO ESPECIFICADO EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "30bc9225-9be1-e4dc-e250-d7c0858eecb1",
        "NOMBRE": "HALLAZGO BIOQUÍMICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "bf716135-0e7d-ea70-d868-7cee9eb435d3",
        "NOMBRE": "HALLAZGO CITOLÓGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "b7912f2e-7a94-3bbd-8c30-edc93dbbb093",
        "NOMBRE": "HALLAZGO CROMOSÓMICO O GENÉTICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "faa787ae-8f7a-1da6-125f-facc0a4a003a",
        "NOMBRE": "HALLAZGO DE AGENTES ESTEROIDES EN LA SANGRE"
    },
    {
        "id": "6405cd27-4615-19d4-9e92-3d18de29a163",
        "NOMBRE": "HALLAZGO DE ALCOHOL EN LA SANGRE"
    },
    {
        "id": "b65455f3-8efe-0541-bfcf-8c5c748b415d",
        "NOMBRE": "HALLAZGO DE ALUCINÓGENOS EN LA SANGRE"
    },
    {
        "id": "5fe12f64-0c7e-c468-c033-2fc102293340",
        "NOMBRE": "HALLAZGO DE COCAÍNA EN LA SANGRE"
    },
    {
        "id": "aeb54217-b2b0-adb7-847d-1bc2d14030aa",
        "NOMBRE": "HALLAZGO DE DROGAS OPIÁCEAS EN LA SANGRE"
    },
    {
        "id": "cbd07a09-97d1-5dbb-a02b-148f697a5736",
        "NOMBRE": "HALLAZGO DE DROGAS PSICOTRÓPICAS EN LA SANGRE"
    },
    {
        "id": "714c6452-7ef3-a193-6efb-c1af7d19c020",
        "NOMBRE": "HALLAZGO DE DROGAS Y OTRAS SUSTANCIAS QUE NORMALMENTE NO SE ENCUENTRAN EN LA SANGRE"
    },
    {
        "id": "da2aa476-dc06-dc9a-c097-2903abf0eccb",
        "NOMBRE": "HALLAZGO DE NIVELES ANORMALES DE METALES PESADOS EN LA SANGRE"
    },
    {
        "id": "65eb93d1-7415-2c9b-c4a5-0305435a166b",
        "NOMBRE": "HALLAZGO DE OTRAS DROGAS POTENCIALMENTE ADICTIVAS EN LA SANGRE"
    },
    {
        "id": "ebac69e3-397b-ad2c-27f4-ecf0ec66450d",
        "NOMBRE": "HALLAZGO DE OTRAS SUSTANCIAS ESPECIFICADAS QUE NORMALMENTE NO SE ENCUENTRAN EN LA SANGRE"
    },
    {
        "id": "0a6657f5-eeb4-f7a4-0256-5e9665f2f3d6",
        "NOMBRE": "HALLAZGO DE SUSTANCIA NO ESPECIFICADA QUE NORMALMENTE NO SE ENCUENTRA EN LA SANGRE"
    },
    {
        "id": "e70853b8-1c22-c749-05ab-1f1093dc02a8",
        "NOMBRE": "HALLAZGO HEMATOLÓGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "b847f6e5-b405-84fe-cc3e-9ceeebe0a5b1",
        "NOMBRE": "HALLAZGO RADIOLÓGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "556975a7-ea28-3bd5-ceee-6aa365c9c890",
        "NOMBRE": "HALLAZGO ULTRASÓNICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "82a0d0a3-2f08-412f-7a16-8042ff7ffbc5",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE LA MAMA"
    },
    {
        "id": "6b044b8d-d80a-9bb1-712c-09e3825ce425",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE LOS MIEMBROS"
    },
    {
        "id": "ae252743-9ba8-b0b3-6ed1-99e5165efb13",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE LOS ÓRGANOS URINARIOS"
    },
    {
        "id": "8842deb2-d82a-012e-5f06-dd43db3c9ef6",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE OTRAS ESTRUCTURAS DEL CUERPO"
    },
    {
        "id": "70fefdcc-bd2a-7519-bf72-a6e44c6f7971",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE OTRAS ESTRUCTURAS ESPECIFICADAS DEL CUERPO"
    },
    {
        "id": "15e23e4d-bd45-2437-b431-e3691bc6bc03",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE OTRAS PARTES DE LAS VÍAS DIGESTIVAS"
    },
    {
        "id": "e05e2edc-cb74-3f0c-be8a-b4925a58d72c",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE OTRAS PARTES DEL SISTEMA OSTEOMUSCULAR"
    },
    {
        "id": "0d06bf01-ba3d-59cc-8def-896dfb0da866",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DE OTRAS REGIONES ABDOMINALES, INCLUIDO EL RETROPERITONEO"
    },
    {
        "id": "f7e64beb-3ebd-a54f-ab27-88ddcf4535bd",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DEL CORAZÓN Y DE LA CIRCULACIÓN CORONARIA"
    },
    {
        "id": "4baa5106-5f0e-2d6c-d0b2-e07958afef61",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DEL CRÁNEO Y DE LA CABEZA, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "40f28c46-449e-4e70-9e38-fd583adbc0c5",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DEL HÍGADO Y DE LAS VÍAS BILIARES"
    },
    {
        "id": "4adcc9f6-7497-ec56-3108-28d7708daa80",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DEL PULMÓN"
    },
    {
        "id": "fce69f46-6616-3acb-08d5-6c8b6dca04a6",
        "NOMBRE": "HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "97ea4c4a-f234-da30-5c63-3fbfd0a86e6d",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL EXAMEN CITOLÓGICO E HISTOLÓGICO DE LA ORINA"
    },
    {
        "id": "555e2b8d-7da2-ab0d-60f9-26baa96e5537",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL EXAMEN MICROBIOLÓGICO DE LA ORINA"
    },
    {
        "id": "0e147bc6-e1f0-d187-f18e-fb10de8eb23e",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "5268e48d-ad46-fd4a-e94a-c0f4c9dd4879",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO"
    },
    {
        "id": "901cef78-3ad3-7756-cfc8-8f428383a253",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, HALLAZGOS ANORMALES NO ESPECIFICADOS"
    },
    {
        "id": "30eb4ef1-7a0d-a223-9c51-86307639cdb1",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, HALLAZGOS CITOLÓGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"
    },
    {
        "id": "e75d6b32-99d9-cdbc-44b7-0c2e0696b2d0",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, HALLAZGOS HISTOLÓGICOS ANORMALES"
    },
    {
        "id": "c4c597fe-1736-d439-714e-ab4d5ad371aa",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, HALLAZGOS INMUNOLÓGICOS ANORMALES"
    },
    {
        "id": "226e57b4-5e7a-95cb-44e6-93b862b4af84",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, HALLAZGOS MICROBIOLÓGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"
    },
    {
        "id": "1c3fce40-af39-46ce-c9b8-86422444b2ec",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, NIVEL ANORMAL DE ENZIMAS"
    },
    {
        "id": "9307f5f5-c683-1fba-7fd6-88accd70308a",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, NIVEL ANORMAL DE HORMONAS"
    },
    {
        "id": "6845a320-d347-675c-1a1b-aca2ea9c881c",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "3e4bd09c-272b-120a-c378-7daabe8e9443",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"
    },
    {
        "id": "6c8c3ec9-b719-fa4e-37ad-2cf517b06018",
        "NOMBRE": "HALLAZGOS ANORMALES EN EL LÍQUIDO CEFALORRAQUÍDEO, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSÓMICOS ANORMALES"
    },
    {
        "id": "5a9a39f2-451a-1ecd-4f2f-d07e7bbbea3b",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRA TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, HALLAZGOS ANORMALES NO ESPECIFICADOS"
    },
    {
        "id": "5536baab-5d57-d992-38bd-91c238978f07",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRA TOMADAS DE OTROS ÓRGANOS , SISTEMAS Y TEJIDOS, HALLAZGOS MICROBIOLÓGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"
    },
    {
        "id": "3f0c0f4a-a57a-ea25-9732-fb81a6b47880",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRA TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS CITOLÓGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"
    },
    {
        "id": "05d788b8-8004-f304-f342-88cac27974ea",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESIVOS Y DE LA CAVIDAD ABDOMINAL, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "67f164dc-eb3f-af78-65de-f362ad352410",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL"
    },
    {
        "id": "abf6c9a8-7cab-6039-aa33-e30f70a7b4f2",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, HALLAZGOS CITOLÓGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"
    },
    {
        "id": "1b3a61a5-a7c4-8855-abfc-e7cefc2fdcaa",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, HALLAZGOS HISTOLÓGICOS ANORMALES"
    },
    {
        "id": "22657bcc-3edb-8081-b0d2-c41ef510788f",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, HALLAZGOS INMUNOLÓGICOS ANORMALES"
    },
    {
        "id": "937787d2-3183-6fdc-2472-950810384b0a",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, HALLAZGOS MICROBIOLÓGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"
    },
    {
        "id": "c88db70f-fa72-a20d-cd50-d162cd8c4e09",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, NIVEL ANORMAL DE ENZIMAS"
    },
    {
        "id": "9c9e0505-eaaa-ca68-f781-85a456463079",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, NIVEL ANORMAL DE HORMONAS"
    },
    {
        "id": "8a5c59dd-35d6-be91-b861-81dfb960b092",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"
    },
    {
        "id": "cb045e40-1ebc-89f2-8ffd-402b5228d402",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSÓMICOS ANORMALES"
    },
    {
        "id": "52c71d91-bc77-416a-b2d3-c02837cfbc22",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS"
    },
    {
        "id": "bcf55830-6e4f-e6b3-c2c9-53275c24b109",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, HALLAZGOS ANORMALES, NO ESPECIFICADOS"
    },
    {
        "id": "22d1970c-a5db-7174-047d-6ad910c649b7",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, HALLAZGOS HISTOLÓGICOS ANORMALES"
    },
    {
        "id": "0b8fe438-e192-1352-53be-434f969c04d5",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, HALLAZGOS INMUNOLÓGICOS ANORMALES"
    },
    {
        "id": "2ae4910a-417b-8791-14e8-9baebb06c2b2",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, HALLAZGOS MICROBIOLÓGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"
    },
    {
        "id": "71085e7f-ffa4-e426-00a8-6d5d28124698",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE ENZIMAS"
    },
    {
        "id": "ae18b54d-ee80-c171-5ee2-0f8810ab1e61",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE HORMONAS"
    },
    {
        "id": "2b82815a-5f3c-0f6a-cd25-adfb8c33f212",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS."
    },
    {
        "id": "f90654a9-3eb7-072a-a5a4-e9e5f21ba77f",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"
    },
    {
        "id": "8b3594d1-50a5-e781-46de-87331942766d",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSÓMICOS ANORMALES"
    },
    {
        "id": "62c18f7f-68a3-46a3-cfe7-2ee11ed20c40",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "acb44163-a890-1762-be7f-aa367f599641",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, HALLAZGOS ANORMALES NO ESPECIFICADOS"
    },
    {
        "id": "7b562322-fa85-2835-0735-7a8327dc8bcb",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, HALLAZGOS CITOLÓGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"
    },
    {
        "id": "fc18940d-4d89-ae5e-d495-5a8afd20bb48",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, HALLAZGOS HISTOLÓGICOS ANORMALES"
    },
    {
        "id": "1cc9896e-c7eb-c657-ccb4-3561d7152d08",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, HALLAZGOS INMUNOLÓGICOS ANORMALES"
    },
    {
        "id": "3eec9d2b-e8f1-c0f3-7a39-e9e420dc84fb",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, HALLAZGOS MICROBIOLÓGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"
    },
    {
        "id": "643371ae-0ef2-f1b6-8831-7779cfd5a561",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE ENZIMAS"
    },
    {
        "id": "6094a966-2433-1180-62a5-42074407f955",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE HORMONAS"
    },
    {
        "id": "2657b5eb-3e3b-b278-9e5f-6ba8bf3e056b",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "757cd5f1-58e9-72ae-e773-b3d83db017da",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"
    },
    {
        "id": "4c3d6758-fadc-6c00-f400-fc13960bd248",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES MASCULINOS, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSÓMICOS ANORMALES"
    },
    {
        "id": "4e4e2c27-2aed-2d24-10a2-95f7c2e0b623",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIAS Y TORÁCICOS OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSÓMICOS ANORMALES"
    },
    {
        "id": "8d7d0029-8d35-21c9-d1b0-ead7c7b5db0a",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS"
    },
    {
        "id": "68ce0316-8ee7-3218-6158-134310f5aced",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, HALLAZGOS ANORMALES NO ESPECIFICADOS"
    },
    {
        "id": "5f6b5a0d-a40a-94bd-78b3-ef74270e6d0e",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, HALLAZGOS CITOLÓGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"
    },
    {
        "id": "6ec036e6-5e1e-b216-5673-21c72c033cf7",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, HALLAZGOS HISTOLÓGICOS ANORMALES"
    },
    {
        "id": "4fcd1350-2e63-e0c7-9ce7-e0b5f44b3c02",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, HALLAZGOS INMUNOLÓGICOS ANORMALES"
    },
    {
        "id": "cb7ddc2d-516b-6118-a52d-a28a697561d1",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, HALLAZGOS MICROBIOLÓGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"
    },
    {
        "id": "0a64b04e-f51e-7194-ef51-7778d7529e5e",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, NIVEL ANORMAL DE ENZIMAS"
    },
    {
        "id": "29a3b61b-7fe0-f0eb-720a-59a108296241",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, NIVEL ANORMAL DE HORMONAS"
    },
    {
        "id": "fe380724-c87f-cfad-62a7-05e335f45f64",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"
    },
    {
        "id": "48511d60-a879-556e-dfb7-99df28e56c47",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ÓRGANOS RESPIRATORIOS Y TORÁCICOS,NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "b0fa3f08-159c-788d-00f4-5d9df09e4f52",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS"
    },
    {
        "id": "06f8a58b-795c-d31a-5a93-1739722857b1",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS ANORMALES NO ESPECIFICADOS."
    },
    {
        "id": "8bea9ac0-7f02-4a07-b04e-35db312bb384",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS HISTOLÓGICOS ANORMALES"
    },
    {
        "id": "9e3c2632-3e9e-7008-fe10-388b897df6eb",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS INMUNOLÓGICOS ANORMALES"
    },
    {
        "id": "1841b5a8-9845-80b7-cb12-9a3ff6117437",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL DE ENZIMAS"
    },
    {
        "id": "228165de-6677-ddfd-8a3c-86dff76e8ef6",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL DE HORMONAS"
    },
    {
        "id": "53d4bd15-0abd-35ec-f8f3-c53c3f7aa835",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "d297a06e-55e8-b4c1-bb8d-4eebf01e9ee9",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"
    },
    {
        "id": "d784210d-592a-b51d-4184-ea417dda4af3",
        "NOMBRE": "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ÓRGANOS, SISTEMAS Y TEJIDOS, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSÓMICOS ANORMALES"
    },
    {
        "id": "fea4a33c-4736-ddac-c64e-57f9c020d946",
        "NOMBRE": "HALLAZGOS ANORMALES.EN MUESTRAS TOMADAS DE ÓRGANOS GENITALES FEMENINOS, HALLAZGOS CITOLÓGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"
    },
    {
        "id": "3aa7e682-3cc6-30f8-bd5d-cd0fe8f6d270",
        "NOMBRE": "HALLAZGOS INMUNOLÓGICOS ANORMALES NO ESPECIFICADOS EN SUERO"
    },
    {
        "id": "1ed910f9-f2e8-f51f-b1d2-e2745734f3e3",
        "NOMBRE": "HALLUX RIGIDUS"
    },
    {
        "id": "d7c8da74-8ef5-7a0c-0bd8-b21d3191c962",
        "NOMBRE": "HALLUX VALGUS (ADQUIRIDO)"
    },
    {
        "id": "c4f4546a-efb0-6353-82c9-6f159e1e4b39",
        "NOMBRE": "HELICOBACTER PYLORI [H.PYLORI] COMO LA CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "3ecc4c39-b925-ff97-34fc-53903d8c1c02",
        "NOMBRE": "HELMINTIASIS INTESTINAL MIXTA"
    },
    {
        "id": "2fd63d96-52bd-7a92-dd80-372849577edc",
        "NOMBRE": "HELMINTIASIS INTESTINAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "00e37531-58fe-12fd-12d9-2c79c325b020",
        "NOMBRE": "HELMINTIASIS, NO ESPECIFICADA"
    },
    {
        "id": "e3dd363d-2067-cb12-c978-612bd639a2b1",
        "NOMBRE": "HEMANGIOMA Y LINFANGIOMA DE CUALQUIER SITIO"
    },
    {
        "id": "2b89864d-b696-e2c3-1966-a03050d9ef26",
        "NOMBRE": "HEMANGIOMA, DE CUALQUIER SITIO"
    },
    {
        "id": "cffbec01-2281-18d1-1678-0bdf27ac7beb",
        "NOMBRE": "HEMARTROSIS"
    },
    {
        "id": "8ff7ae4e-dfe7-70c8-60a5-eced06722199",
        "NOMBRE": "HEMATEMESIS"
    },
    {
        "id": "ca80c015-9f3e-4ebe-1b69-ee76eb45931c",
        "NOMBRE": "HEMATEMESIS NEONATAL"
    },
    {
        "id": "26158813-8dab-3dc6-8fc1-64603dbc0f56",
        "NOMBRE": "HEMATEMESIS Y MELENA NEONATALES DEBIDAS A LA DEGLUCIÓN DE SANGRE MATERNA"
    },
    {
        "id": "144e27ca-9cf8-7beb-a239-ce6bcf99d801",
        "NOMBRE": "HEMATOCOLPOS"
    },
    {
        "id": "cb31d43b-f206-efc2-1032-7e93ef91432d",
        "NOMBRE": "HEMATOMA DE HERIDA QUIRÚRGICA OBSTÉTRICA"
    },
    {
        "id": "11d31f5a-3ef3-41d8-8e52-3a05746dc9bb",
        "NOMBRE": "HEMATOMA DEL LIGAMENTO ANCHO"
    },
    {
        "id": "42098218-644c-8ad1-cbd1-245ba17d00bc",
        "NOMBRE": "HEMATOMA OBSTÉTRICO DE LA PELVIS"
    },
    {
        "id": "82edfbfc-2fe2-600d-d00a-2b5ef7e740f7",
        "NOMBRE": "HEMATÓMETRA"
    },
    {
        "id": "0c149eb5-37cb-c362-7d21-bb2e80a473ff",
        "NOMBRE": "HEMATOSALPINX"
    },
    {
        "id": "615b9ef8-2a48-015b-8627-bb03e394be30",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE"
    },
    {
        "id": "ac5c2318-b3e9-88a2-2bbf-8f09feffb039",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "5d4a4afb-a060-d82f-3aed-f41fa2dd3e40",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "e1e27594-bedb-b8e5-93d4-c5c6cc74046a",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "70fb5311-cb3c-2e30-48c3-fd70324a71ee",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "17fb2384-cede-df69-c0d0-611097151664",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "0d4f1808-43b6-b372-15cb-5d9b6705148d",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "284f6ca8-e8f4-2023-33bb-f0a896017016",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "968c0c10-3131-7647-2cbc-0df04a45b314",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "eee8d0d5-af0e-7bbf-467d-a003989a1b16",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, NO ESPECIFICADA"
    },
    {
        "id": "9a736809-8ea0-0f9b-d770-efee78c217a7",
        "NOMBRE": "HEMATURIA RECURRENTE Y PERSISTENTE, OTRAS"
    },
    {
        "id": "60613ff3-ed1f-f368-9350-ac89f6aba3a4",
        "NOMBRE": "HEMATURIA, NO ESPECIFICADA"
    },
    {
        "id": "d5aaa584-cc90-9f6c-8fa0-c10e41b95e8d",
        "NOMBRE": "HEMIPLEJÍA"
    },
    {
        "id": "49e8f06e-7d6f-06fe-8ca6-6bc27e8fcfee",
        "NOMBRE": "HEMIPLEJÍA ESPÁSTICA"
    },
    {
        "id": "041f2f6c-c4d3-4381-45ec-5061639e3ac4",
        "NOMBRE": "HEMIPLEJÍA FLÁCIDA"
    },
    {
        "id": "f660e003-c02c-b0c2-19fc-6e2159798f34",
        "NOMBRE": "HEMIPLEJÍA, NO ESPECIFICADA"
    },
    {
        "id": "039feb6b-4dc5-ea3b-8a21-c1b2e3386d03",
        "NOMBRE": "HEMOGLOBINURIA"
    },
    {
        "id": "bdf349b6-af78-7de3-bfb6-ccb08643cf95",
        "NOMBRE": "HEMOGLOBINURIA DEBIDA A HEMÓLISIS POR OTRAS CAUSAS EXTERNAS"
    },
    {
        "id": "cd0e9725-8320-bfae-0ad8-36c9e84cc11e",
        "NOMBRE": "HEMOGLOBINURIA PAROXÍSTICA NOCTURNA [MARCHIAFAVA- MICHELI]"
    },
    {
        "id": "5426bd74-e585-8c07-9233-73dd07983723",
        "NOMBRE": "HEMONEUMOTÓRAX TRAUMÁTICO"
    },
    {
        "id": "6cc84d54-b4c2-9ff2-8037-0c870ed8c2b8",
        "NOMBRE": "HEMOPERICARDIO COMO COMPLICACIÓN PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "551f7286-4622-4589-ad5b-e1d5b3c077c0",
        "NOMBRE": "HEMOPERICARDIO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "6b31151f-6389-951d-9dfd-ce90dcf2b8e2",
        "NOMBRE": "HEMOPERITONEO"
    },
    {
        "id": "d5de9314-57d0-b6cb-92be-4514715b1083",
        "NOMBRE": "HEMOPTISIS"
    },
    {
        "id": "0e5acfd6-eb93-a5a8-913a-5cc9548eb1c1",
        "NOMBRE": "HEMORRAGIA ANTEPARTO CON DEFECTO DE LA COAGULACIÓN"
    },
    {
        "id": "8eea954a-52db-c3d2-27d2-34af4fc33384",
        "NOMBRE": "HEMORRAGIA ANTEPARTO, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "ab623c28-6293-e735-532e-33fada4fb6a6",
        "NOMBRE": "HEMORRAGIA ANTEPARTO, NO ESPECIFICADA"
    },
    {
        "id": "e42a03ad-6268-aff2-da42-d9629fc3398d",
        "NOMBRE": "HEMORRAGIA CEREBELOSA Y DE LA FOSA POSTERIOR (NO TRAUMÁTICA) DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "7ae1f3af-783d-9c0e-fbbe-3e98505e95d7",
        "NOMBRE": "HEMORRAGIA CEREBRAL DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "11ca2ca3-30f6-bc3f-bf1f-7968e8b6fa31",
        "NOMBRE": "HEMORRAGIA CONJUNTIVAL"
    },
    {
        "id": "b85dd8e1-4c8d-f7ca-0e6b-d407cceaa5b5",
        "NOMBRE": "HEMORRAGIA CUTÁNEA NEONATAL"
    },
    {
        "id": "8949d741-ba67-cdb4-1d5a-fdf49c6dfbe3",
        "NOMBRE": "HEMORRAGIA DE LA GARGANTA"
    },
    {
        "id": "81726691-300f-121c-c1c3-47218fa6d689",
        "NOMBRE": "HEMORRAGIA DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "c5e104a6-b86e-b9da-8b61-83aeb940abb3",
        "NOMBRE": "HEMORRAGIA DE LAS VÍAS RESPIRATORIAS, NO ESPECIFICADA"
    },
    {
        "id": "65bd8908-9ccc-b986-112f-1ecd02598595",
        "NOMBRE": "HEMORRAGIA DE OTROS SITIOS DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "77f06de7-c618-fea3-16ac-379276d49397",
        "NOMBRE": "HEMORRAGIA DEL ANO Y DEL RECTO"
    },
    {
        "id": "4ea6d7f9-3dbb-724b-64fb-96e50437d8d1",
        "NOMBRE": "HEMORRAGIA DEL TERCER PERÍODO DEL PARTO"
    },
    {
        "id": "3bbb52ef-575a-d296-156c-8cc684599887",
        "NOMBRE": "HEMORRAGIA DEL VÍTREO"
    },
    {
        "id": "1948a7ca-0e0d-655f-c63f-80b4656c93b0",
        "NOMBRE": "HEMORRAGIA DEL VÍTREO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "78eb1a3c-e040-5b6a-2866-e1551980587f",
        "NOMBRE": "HEMORRAGIA EPICRANEAL SUBAPONEURÓTICA DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "c58c85b7-ba27-0644-e87a-82720992a0cd",
        "NOMBRE": "HEMORRAGIA EPIDURAL"
    },
    {
        "id": "52306cd3-9b24-a95d-677a-ccc703f51d69",
        "NOMBRE": "HEMORRAGIA EXCESIVA EN PERÍODO PREMENOPÁUSICO"
    },
    {
        "id": "40b71edc-9004-cb39-7e74-8b22925296e6",
        "NOMBRE": "HEMORRAGIA EXCESIVA O TARDÍA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "3607f9f2-2065-b31a-571b-639191bf1482",
        "NOMBRE": "HEMORRAGIA EXTRADURAL NO TRAUMÁTICA"
    },
    {
        "id": "321ba391-bbfa-255e-2aef-cdb33bff5b39",
        "NOMBRE": "HEMORRAGIA FETAL HACIA EL OTRO GEMELO"
    },
    {
        "id": "a5e08449-2087-ee76-a781-d73a29bdf674",
        "NOMBRE": "HEMORRAGIA FETAL HACIA LA CIRCULACIÓN MATERNA"
    },
    {
        "id": "916826a7-fed1-c6cc-047a-6689b8578738",
        "NOMBRE": "HEMORRAGIA GASTROINTESTINAL, NO ESPECIFICADA"
    },
    {
        "id": "5408aefc-d0b5-0aab-6393-ff8e813eb54b",
        "NOMBRE": "HEMORRAGIA INTRACEREBRAL (NO TRAUMÁTICA) DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "f3a2e66b-103a-0a9e-dd18-e1ff7ab85ec0",
        "NOMBRE": "HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, CORTICAL"
    },
    {
        "id": "98ae3a27-72eb-1e05-a87c-531161e11500",
        "NOMBRE": "HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, NO ESPECIFICADA"
    },
    {
        "id": "a4d425f5-9bfb-1266-f11e-2351f2d58dbf",
        "NOMBRE": "HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, SUBCORTICAL"
    },
    {
        "id": "70c1d03b-2c6f-422f-59f3-14ff3f875227",
        "NOMBRE": "HEMORRAGIA INTRACRANEAL (NO TRAUMÁTICA) DEL FETO Y DEL RECIÉN NACIDO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "cf5daaaa-7b4a-c4ad-654b-65d08c18d58d",
        "NOMBRE": "HEMORRAGIA INTRACRANEAL (NO TRAUMÁTICA), NO ESPECIFICADA"
    },
    {
        "id": "a15a3a63-7835-dc6e-5b9c-07e3deeda9d8",
        "NOMBRE": "HEMORRAGIA INTRACRANEAL NO TRAUMÁTICA DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "20756c5b-6345-e534-3f23-b7b97f67c543",
        "NOMBRE": "HEMORRAGIA INTRAENCEFÁLICA"
    },
    {
        "id": "f7891f5f-3b35-0872-9e60-fb31e10e595a",
        "NOMBRE": "HEMORRAGIA INTRAENCEFÁLICA DE LOCALIZACIONES MÚLTIPLES"
    },
    {
        "id": "3eaad6d1-e93a-fd28-5a82-88555d1ad0f0",
        "NOMBRE": "HEMORRAGIA INTRAENCEFÁLICA EN CEREBELO"
    },
    {
        "id": "45730714-1287-03e5-f582-3b7977ffaf33",
        "NOMBRE": "HEMORRAGIA INTRAENCEFÁLICA EN TALLO CEREBRAL"
    },
    {
        "id": "a9f54d35-4257-0228-b5d3-4f6b2d786cab",
        "NOMBRE": "HEMORRAGIA INTRAENCEFÁLICA, INTRAVENTRICULAR"
    },
    {
        "id": "55f8e9d0-1c2c-b41d-7054-f7416b7916ba",
        "NOMBRE": "HEMORRAGIA INTRAENCEFÁLICA, NO ESPECIFICADA"
    },
    {
        "id": "18980ddd-a1d5-5ecc-6b8e-52c2dc32e4c2",
        "NOMBRE": "HEMORRAGIA INTRAPARTO CON DEFECTOS DE LA COAGULACIÓN"
    },
    {
        "id": "d06fb23d-c4fb-c62e-0d46-6859381b756c",
        "NOMBRE": "HEMORRAGIA INTRAPARTO, NO ESPECIFICADA"
    },
    {
        "id": "f3d312d6-f034-0153-98da-ed04898a1e90",
        "NOMBRE": "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMÁTICA) DEL FETO Y DEL RECIÉN NACIDO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "d13dde5f-62a9-22cc-e9c0-196ef7ffe994",
        "NOMBRE": "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMÁTICA) GRADO 1, DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "6842e027-f33a-05d9-5d19-b3e042e26dfa",
        "NOMBRE": "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMÁTICA) GRADO 2, DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "61d4ad28-4434-b99c-efc0-910fccf32d2a",
        "NOMBRE": "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMÁTICA) GRADO 3, DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "1a033893-d6f0-d382-8239-9f233a301abd",
        "NOMBRE": "HEMORRAGIA INTRAVENTRICULAR DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "87f4db6a-92a1-6c1c-7c2b-0fc166f35eff",
        "NOMBRE": "HEMORRAGIA NEONATAL, NO ESPECIFICADA"
    },
    {
        "id": "cc28a0c9-44f4-7f97-7280-375796cd25cb",
        "NOMBRE": "HEMORRAGIA POR OVULACIÓN"
    },
    {
        "id": "1c05941f-d6a0-015b-1235-d0aea8e7350d",
        "NOMBRE": "HEMORRAGIA POSTCOITO Y POSTCONTACTO"
    },
    {
        "id": "8f071c42-68b6-6392-b7d3-4c44be7e476a",
        "NOMBRE": "HEMORRAGIA POSTMENOPÁUSICA"
    },
    {
        "id": "f0f8d9fc-1b1f-e146-0b88-680640f947ab",
        "NOMBRE": "HEMORRAGIA POSTPARTO"
    },
    {
        "id": "78a8b917-c35e-2674-9c68-6e48448e9aa3",
        "NOMBRE": "HEMORRAGIA POSTPARTO SECUNDARIA O TARDÍA"
    },
    {
        "id": "4952694f-d194-e107-e6c8-649dd1c061f1",
        "NOMBRE": "HEMORRAGIA PRECOZ DEL EMBARAZO"
    },
    {
        "id": "3009e84f-4174-c899-bfab-c9e3fe24254f",
        "NOMBRE": "HEMORRAGIA PRECOZ DEL EMBARAZO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "10d24521-6b6b-88b3-3338-ae33f0088cf7",
        "NOMBRE": "HEMORRAGIA PULMONAR MASIVA ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "cf4aceb1-75ac-588f-9af4-07bcc7e6bc19",
        "NOMBRE": "HEMORRAGIA PULMONAR NO ESPECIFICADA, ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "4cdd1600-861d-1740-c06d-03f6f4e8b827",
        "NOMBRE": "HEMORRAGIA PULMONAR ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "37c33118-c670-01a5-b00a-3cf44258c6f4",
        "NOMBRE": "HEMORRAGIA RECTAL NEONATAL"
    },
    {
        "id": "39b76391-bf17-e8df-d084-830d32bc4b1c",
        "NOMBRE": "HEMORRAGIA RETINIANA"
    },
    {
        "id": "36cdb99c-3b05-558a-aa25-dc6c3d1e8537",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA"
    },
    {
        "id": "06b1b081-d2f9-7921-34cb-654d7c42fe22",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA (NO TRAUMÁTICA) DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "5f2093fc-64ec-531c-325c-8d026bcb8bc6",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE ARTERIA BASILAR"
    },
    {
        "id": "9a98362c-3a65-d134-3f21-f3cc389f3fec",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE ARTERIA CEREBRAL MEDIA"
    },
    {
        "id": "db206cb8-dc4a-ca64-d7fa-36b71a05f21d",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE ANTERIOR"
    },
    {
        "id": "d33f777e-3ecf-2eb2-ebe0-b5a7271d9c95",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE POSTERIOR"
    },
    {
        "id": "17328328-dda4-1b7a-a860-3c420b819f30",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE ARTERIA INTRACRANEAL NO ESPECIFICADA"
    },
    {
        "id": "ee9f0c3e-335c-46f7-6da1-26e6108c8fe9",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE ARTERIA VERTEBRAL"
    },
    {
        "id": "088e7e43-cf48-18e0-a360-1414f0a4650d",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE OTRAS ARTERIAS INTRACRANEALES"
    },
    {
        "id": "62f2906a-f2c1-52df-a3f1-8fa0ee03291b",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DE SIFÓN Y BIFURCACIÓN CAROTÍDEA"
    },
    {
        "id": "f93297cf-d1cb-a57b-5935-7b2507ed8b01",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "ea77cbb6-488e-4bb3-54fd-0e0bf2d6cd04",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA TRAUMÁTICA"
    },
    {
        "id": "03e28d6d-11ca-891b-8d57-891a73907a79",
        "NOMBRE": "HEMORRAGIA SUBARACNOIDEA, NO ESPECIFICADA"
    },
    {
        "id": "ef612aa3-1282-4b9d-cf20-cf737f4934c3",
        "NOMBRE": "HEMORRAGIA SUBDURAL DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "7b1f6bab-9e55-0aef-d9fd-7c32bc63a325",
        "NOMBRE": "HEMORRAGIA SUBDURAL NO TRAUMÁTICA"
    },
    {
        "id": "1bdd90f6-78dc-4b01-7c43-d464af942de1",
        "NOMBRE": "HEMORRAGIA SUBDURAL TRAUMÁTICA"
    },
    {
        "id": "cfc3d1ba-7c93-3fc7-76d8-c74d83a1e8fa",
        "NOMBRE": "HEMORRAGIA SUPRARRENAL NEONATAL"
    },
    {
        "id": "2497a5f1-247b-bd0f-b1a6-f26aab5177be",
        "NOMBRE": "HEMORRAGIA TRAQUEOBRONQUIAL ORIGINADA EN EL PERÍODO PERINATAL"
    },
    {
        "id": "c81aaf06-2e2f-c9d3-397d-f2badd08fe78",
        "NOMBRE": "HEMORRAGIA TRAUMÁTICA SECUNDARIA Y RECURRENTE"
    },
    {
        "id": "3f76b902-e722-1397-ae14-923332490635",
        "NOMBRE": "HEMORRAGIA UMBILICAL DEL RECIÉN NACIDO"
    },
    {
        "id": "1431b26e-3dcf-7333-0088-7b24d5002507",
        "NOMBRE": "HEMORRAGIA UMBILICAL DEL RECIÉN NACIDO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "14b70cef-cd88-9bc4-7acb-152060e59389",
        "NOMBRE": "HEMORRAGIA UMBILICAL MASIVA DEL RECIÉN NACIDO"
    },
    {
        "id": "fdcaf4fb-18c0-8e12-94e7-182823b2cc93",
        "NOMBRE": "HEMORRAGIA VAGINAL NEONATAL"
    },
    {
        "id": "3abdd424-9bab-b42c-3886-6de218534dcf",
        "NOMBRE": "HEMORRAGIA VAGINAL Y UTERINA ANORMAL, NO ESPECIFICADA"
    },
    {
        "id": "7e18d459-d794-f5cc-7a11-937b0fb3bd09",
        "NOMBRE": "HEMORRAGIA Y HEMATOMA QUE COMPLICAN UN PROCEDIMIENTO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "e8100eec-e039-c9fc-d71a-76f28aa7583d",
        "NOMBRE": "HEMORRAGIA Y LACERACIÓN INTRACRANEAL DEBIDAS A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "c6ec85fe-ecdb-c750-b93d-855183d41937",
        "NOMBRE": "HEMORRAGIA Y LACERACIÓN INTRACRANEALES NO ESPECIFICADAS, DEBIDAS A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "a3bb83bf-bde8-d89c-a8d6-70c95f887197",
        "NOMBRE": "HEMORRAGIA Y RUPTURA DE LA COROIDES"
    },
    {
        "id": "e5d8d00f-5933-4dd7-c874-1ff8cf199908",
        "NOMBRE": "HEMORRAGIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "55f369b8-e5e5-02c9-67d2-72a0ee0b5bb2",
        "NOMBRE": "HEMORROIDES"
    },
    {
        "id": "b2fad5db-eef5-e290-8656-64008645ca90",
        "NOMBRE": "HEMORROIDES DE CUARTO GRADO"
    },
    {
        "id": "c4adcb7b-8a4f-144b-5be9-612a2b0611bd",
        "NOMBRE": "HEMORROIDES DE PRIMER GRADO"
    },
    {
        "id": "f0ae06b5-825e-33e0-aa23-09a25c1ee176",
        "NOMBRE": "HEMORROIDES DE SEGUNDO GRADO"
    },
    {
        "id": "ff6e3913-54eb-16fd-7f66-335a9a22f12d",
        "NOMBRE": "HEMORROIDES DE TERCER GRADO"
    },
    {
        "id": "73f9dc0a-e28a-4006-bcb0-5d272e43b579",
        "NOMBRE": "HEMORROIDES EN EL EMBARAZO"
    },
    {
        "id": "a37452ac-d529-e5ee-04ba-512b0178bd4c",
        "NOMBRE": "HEMORROIDES EN EL PUERPERIO"
    },
    {
        "id": "12920ce4-595b-02fd-72f9-007eb71820cf",
        "NOMBRE": "HEMORROIDES EXTERNAS CON OTRAS COMPLICACIONES"
    },
    {
        "id": "2753f6a5-20cb-382e-b10f-7cb72f9b1570",
        "NOMBRE": "HEMORROIDES EXTERNAS SIN COMPLICACIÓN"
    },
    {
        "id": "a0060fef-83e6-4530-c50c-997b5eedbe87",
        "NOMBRE": "HEMORROIDES EXTERNAS TROMBOSADAS"
    },
    {
        "id": "675d45df-1aff-cef2-8d85-b16a97823751",
        "NOMBRE": "HEMORROIDES INTERNAS CON OTRAS COMPLICACIONES"
    },
    {
        "id": "6d177a23-8db9-18f0-ec79-5fa9d0a14205",
        "NOMBRE": "HEMORROIDES INTERNAS SIN COMPLICACIÓN"
    },
    {
        "id": "8c83f082-7a8e-c368-c13a-022630ba3232",
        "NOMBRE": "HEMORROIDES INTERNAS TROMBOSADAS"
    },
    {
        "id": "1197ad78-63eb-235d-8650-656e31965c91",
        "NOMBRE": "HEMORROIDES NO ESPECIFICADAS, CON OTRAS COMPLICACIONES"
    },
    {
        "id": "2c8e4f35-97af-8cb3-c6f2-3a15e1a885e5",
        "NOMBRE": "HEMORROIDES NO ESPECIFICADAS, SIN COMPLICACIÓN"
    },
    {
        "id": "3c884798-15c6-09c2-7a53-e5c40a85d7f5",
        "NOMBRE": "HEMORROIDES TROMBOSADAS NO ESPECIFICADAS"
    },
    {
        "id": "7291d09c-e774-6c3d-a2ba-16147ecfc1f6",
        "NOMBRE": "HEMORROIDES Y TROMBOSIS VENOSA PERIANAL"
    },
    {
        "id": "61f167c4-8dee-4fbf-f58c-e7b7edf2b310",
        "NOMBRE": "HEMORROIDES, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "841dc214-b4a0-9a90-66d2-3a14020dc1b5",
        "NOMBRE": "HEMOTÓRAX"
    },
    {
        "id": "2664c37f-93e0-cef4-f0c7-544028674ec0",
        "NOMBRE": "HEMOTÓRAX TRAUMÁTICO"
    },
    {
        "id": "5be91f2d-31e5-b6ff-9ccb-642d80f158ef",
        "NOMBRE": "HENDIDURA, FISURA O MUESCA DE LA NARIZ"
    },
    {
        "id": "43b01881-b6bb-9c4f-648b-e798bfdbdfab",
        "NOMBRE": "HEPATITIS AGUDA TIPO A"
    },
    {
        "id": "0c65182f-867b-24ed-8d18-e4064d5a7cd3",
        "NOMBRE": "HEPATITIS AGUDA TIPO A, CON COMA HEPÁTICO"
    },
    {
        "id": "f3e91a3a-ea76-2d51-7fd1-0fe1f91fbe40",
        "NOMBRE": "HEPATITIS AGUDA TIPO A, SIN COMA HEPÁTICO"
    },
    {
        "id": "73e0d3c8-a9d6-bdec-efbd-a8e121268442",
        "NOMBRE": "HEPATITIS AGUDA TIPO B"
    },
    {
        "id": "4722b087-bcd8-38b1-790b-04971925385c",
        "NOMBRE": "HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCIÓN), CON COMA HEPÁTICO"
    },
    {
        "id": "33fe1112-b083-5fba-f6d0-10b902eeb74f",
        "NOMBRE": "HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCIÓN), SIN COMA HEPÁTICO"
    },
    {
        "id": "53052053-1df0-4d63-0b5d-f0884bf5bfee",
        "NOMBRE": "HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA Y SIN COMA HEPÁTICO"
    },
    {
        "id": "21953faa-b808-3d46-2eb5-68deb8c64f0e",
        "NOMBRE": "HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA, CON COMA HEPÁTICO"
    },
    {
        "id": "586a197b-49ca-abf0-e95f-a44a2376d515",
        "NOMBRE": "HEPATITIS AGUDA TIPO C"
    },
    {
        "id": "9b41a44e-cfcf-c27c-27ac-3deec26d9579",
        "NOMBRE": "HEPATITIS AGUDA TIPO E"
    },
    {
        "id": "7211e0bd-9e0a-9979-d32d-e689383e74af",
        "NOMBRE": "HEPATITIS ALCOHÓLICA"
    },
    {
        "id": "b4d1f0d4-1f74-a8cc-7b9a-38e7b5af69e9",
        "NOMBRE": "HEPATITIS AUTOINMUNE"
    },
    {
        "id": "e5cb9fe1-7f09-8d2f-ca12-c92928a9d436",
        "NOMBRE": "HEPATITIS CRÓNICA ACTIVA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "db4f8a54-94a8-418a-5b2e-541b15b202ba",
        "NOMBRE": "HEPATITIS CRÓNICA LOBULAR, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "57548be6-a46e-29f4-6e6d-00aac649fc85",
        "NOMBRE": "HEPATITIS CRÓNICA PERSISTENTE, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "83e7b3d2-05de-31d5-f4c5-3f1ead5320fe",
        "NOMBRE": "HEPATITIS CRÓNICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "19c0343c-9b97-609d-bc5e-99990242cf19",
        "NOMBRE": "HEPATITIS CRÓNICA, NO ESPECIFICADA"
    },
    {
        "id": "488cae34-1120-ed5f-a117-372155724a4c",
        "NOMBRE": "HEPATITIS DEBIDA A TOXOPLASMA"
    },
    {
        "id": "3c453b9e-61c6-ded0-e5aa-68cdd26304bb",
        "NOMBRE": "HEPATITIS DEBIDA A VIRUS CITOMEGÁLICO"
    },
    {
        "id": "8ee2c652-9abf-15bf-31cd-8d2a9fcde725",
        "NOMBRE": "HEPATITIS GRANULOMATOSA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "e7291b28-c3e2-03ee-1900-04633678766c",
        "NOMBRE": "HEPATITIS REACTIVA NO ESPECIFICADA"
    },
    {
        "id": "1c7adce5-368a-7449-5acd-11b92f3017de",
        "NOMBRE": "HEPATITIS VIRAL AGUDA NO ESPECIFICADA"
    },
    {
        "id": "ccb1e10d-68f9-7cd7-9962-b4294e5ec884",
        "NOMBRE": "HEPATITIS VIRAL CONGÉNITA"
    },
    {
        "id": "8825ecb6-4563-6653-2a37-6f18b7c61fa9",
        "NOMBRE": "HEPATITIS VIRAL CRÓNICA"
    },
    {
        "id": "8946fe6d-f60e-2387-9e50-a1cd7c8ef9d6",
        "NOMBRE": "HEPATITIS VIRAL CRÓNICA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "684541c2-e7c0-5971-028c-81945e9eebb7",
        "NOMBRE": "HEPATITIS VIRAL NO ESPECIFICADA CON COMA HEPÁTICO"
    },
    {
        "id": "19f5fcce-fc09-c735-a521-8e363f2a2bb6",
        "NOMBRE": "HEPATITIS VIRAL NO ESPECIFICADA SIN COMA HEPÁTICO"
    },
    {
        "id": "456afaaa-2875-3281-7afa-978af1e64944",
        "NOMBRE": "HEPATITIS VIRAL QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "5c73f4a3-8cce-8969-4f58-8acd122f5cac",
        "NOMBRE": "HEPATITIS VIRAL TIPO B CRÓNICA, CON AGENTE DELTA"
    },
    {
        "id": "7d550ac1-3df3-65b1-3dc7-ee99478553e2",
        "NOMBRE": "HEPATITIS VIRAL TIPO B CRÓNICA, SIN AGENTE DELTA"
    },
    {
        "id": "8f6d68a1-3319-000d-74c8-0b54b72fd311",
        "NOMBRE": "HEPATITIS VIRAL TIPO C CRÓNICA"
    },
    {
        "id": "fff8ef2f-6102-526b-3e80-5a0c5e25670b",
        "NOMBRE": "HEPATITIS VIRAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "6ca76c69-3dcf-a009-34de-a44582dd381d",
        "NOMBRE": "HEPATOBLASTOMA"
    },
    {
        "id": "958094b4-b072-3592-dd8d-96773005ef3c",
        "NOMBRE": "HEPATOMEGALIA CON ESPLENOMEGALIA, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "93130b55-217a-cd1c-d251-92eb54d44dac",
        "NOMBRE": "HEPATOMEGALIA Y ESPLENOMEGALIA, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d4b80363-7374-05ff-5558-bc42d8c6c340",
        "NOMBRE": "HEPATOMEGALIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "00e1151f-6080-99f9-d718-cdd2243dc939",
        "NOMBRE": "HERIDA DE CUELLO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "6a5ad152-b11c-2262-279e-3e1015af5fe9",
        "NOMBRE": "HERIDA DE DEDO(S) DE LA MANO, CON DAÑO DE LA(S) UÑA(S)"
    },
    {
        "id": "7ab0ad67-1149-ff8a-323b-147d2f69a999",
        "NOMBRE": "HERIDA DE DEDO(S) DE LA MANO, SIN DAÑO DE LA(S) UÑA(S)"
    },
    {
        "id": "257d666f-1cfa-8909-a45c-fd4bb4bb6d0e",
        "NOMBRE": "HERIDA DE DEDO(S) DEL PIE CON DAÑO(S) DE LA(S) UÑA(S)"
    },
    {
        "id": "2001ee86-7276-28e2-2927-fb680d79561b",
        "NOMBRE": "HERIDA DE DEDO(S) DEL PIE SIN DAÑO(S) DE LA(S) UÑA(S)"
    },
    {
        "id": "20b404cc-416e-24ae-c36b-0bf92c30c890",
        "NOMBRE": "HERIDA DE LA CABEZA"
    },
    {
        "id": "a1897fc8-f7f5-26d8-72b1-ef63f78b733d",
        "NOMBRE": "HERIDA DE LA CABEZA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "36f123ad-7e49-ab7a-2045-7f6087fcff8c",
        "NOMBRE": "HERIDA DE LA CADERA"
    },
    {
        "id": "c8e2075e-ae6a-6cbf-5be8-d79450bb48f3",
        "NOMBRE": "HERIDA DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "9ad179f0-12eb-2cf3-0617-ac39c88595b2",
        "NOMBRE": "HERIDA DE LA MAMA"
    },
    {
        "id": "51f3bcf2-ca75-7860-98d9-d2b0e3cdf55c",
        "NOMBRE": "HERIDA DE LA MEJILLA Y DE LA REGIÓN TEMPOROMANDIBULAR"
    },
    {
        "id": "b561f5f6-8029-1cde-fdeb-88a6678410ac",
        "NOMBRE": "HERIDA DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "e3da2cbc-d533-e958-dd81-9d48b9df9c48",
        "NOMBRE": "HERIDA DE LA MUÑECA Y DE LA MANO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "5ad0e526-5615-ff5e-38e1-c1cc8bf79b6d",
        "NOMBRE": "HERIDA DE LA NARIZ"
    },
    {
        "id": "2657e26c-af23-d48a-c2ca-d1fbf5e94f6e",
        "NOMBRE": "HERIDA DE LA PARED ABDOMINAL"
    },
    {
        "id": "9023bdee-716f-3a06-7dcb-97d404a8c9a3",
        "NOMBRE": "HERIDA DE LA PARED ANTERIOR DEL TÓRAX"
    },
    {
        "id": "50239e94-5d73-3e53-06db-eea827689b9b",
        "NOMBRE": "HERIDA DE LA PARED POSTERIOR DEL TÓRAX"
    },
    {
        "id": "55f2f594-70e0-036b-2e75-fc29da0ca847",
        "NOMBRE": "HERIDA DE LA PIERNA"
    },
    {
        "id": "93f7d9e9-a935-7ac4-4ab3-cbb0932af165",
        "NOMBRE": "HERIDA DE LA PIERNA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "3989fc74-c3cc-73ff-e1b2-a3164f693817",
        "NOMBRE": "HERIDA DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "a3577689-f72a-5f5a-ffb1-0b1645ef2abe",
        "NOMBRE": "HERIDA DE LA RODILLA"
    },
    {
        "id": "84dc9dfd-65fd-e0d7-d909-95fb5f5ac0b9",
        "NOMBRE": "HERIDA DE LA VAGINA Y DE LA VULVA"
    },
    {
        "id": "abdee830-444c-59aa-5f07-596c9a78fdbb",
        "NOMBRE": "HERIDA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "b84701d7-17dc-17f6-8992-43bc13b1702d",
        "NOMBRE": "HERIDA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "f9fbd622-561b-50a9-bb6f-c85e1144b17b",
        "NOMBRE": "HERIDA DE OTRAS PARTES DE LA CABEZA"
    },
    {
        "id": "272fe770-c3e7-3015-ee68-55bd83187cb2",
        "NOMBRE": "HERIDA DE OTRAS PARTES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "5a8120a9-6c93-6687-dd11-869b8027c42b",
        "NOMBRE": "HERIDA DE OTRAS PARTES DE LA PIERNA"
    },
    {
        "id": "98964e9b-dbb1-bc4b-6230-0aa158bac65a",
        "NOMBRE": "HERIDA DE OTRAS PARTES DEL ANTEBRAZO"
    },
    {
        "id": "b129098b-b032-b23f-5a65-5ce6986b9dd2",
        "NOMBRE": "HERIDA DE OTRAS PARTES DEL PIE"
    },
    {
        "id": "88b69ca5-77fc-9170-fb5e-cf98a04a421c",
        "NOMBRE": "HERIDA DE OTRAS PARTES DEL TÓRAX"
    },
    {
        "id": "45b1c5a1-afcc-956f-1e27-27cf187ab5a6",
        "NOMBRE": "HERIDA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CINTURA PÉLVICA"
    },
    {
        "id": "1ed6a206-fe3e-afa7-3141-6df4d25a6b2d",
        "NOMBRE": "HERIDA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "1d7db8c3-93b9-6356-08f8-e2966f34d2d5",
        "NOMBRE": "HERIDA DE OTROS ÓRGANOS GENITALES EXTERNOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "073a163d-1743-5e05-4c72-c352f588c3af",
        "NOMBRE": "HERIDA DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "839200f5-4b05-4c8d-2eb2-c8124d9ac686",
        "NOMBRE": "HERIDA DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "fae1b0b9-f282-2d3c-8efd-27a545c7f5da",
        "NOMBRE": "HERIDA DEL ANTEBRAZO Y DEL CODO"
    },
    {
        "id": "6d4266b5-70ec-8f8d-4867-fe2910afe938",
        "NOMBRE": "HERIDA DEL ANTEBRAZO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "3a6b69e5-30be-2eec-83e4-a56d22b27473",
        "NOMBRE": "HERIDA DEL BRAZO"
    },
    {
        "id": "9417211a-1204-2b82-7448-f8deeec6d647",
        "NOMBRE": "HERIDA DEL CODO"
    },
    {
        "id": "6584584e-a337-9e97-2142-b7c70d5aacdb",
        "NOMBRE": "HERIDA DEL CUELLO"
    },
    {
        "id": "9436da06-e202-6157-de1c-05f20488160c",
        "NOMBRE": "HERIDA DEL CUERO CABELLUDO"
    },
    {
        "id": "99101bb6-2377-aa56-ec85-194ab8bfdec9",
        "NOMBRE": "HERIDA DEL ESCROTO Y DE LOS TESTÍCULOS"
    },
    {
        "id": "7f737ce3-33f5-3a16-d7ff-3368eeeaa51e",
        "NOMBRE": "HERIDA DEL HOMBRO"
    },
    {
        "id": "6da4503a-49fd-d74d-cc1e-d1eeba46efa4",
        "NOMBRE": "HERIDA DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "e5e656d2-0534-2038-9b6d-1521eaca8dc1",
        "NOMBRE": "HERIDA DEL LABIO Y DE LA CAVIDAD BUCAL"
    },
    {
        "id": "e3807de3-b405-1172-32c9-071a03dae284",
        "NOMBRE": "HERIDA DEL MUSLO"
    },
    {
        "id": "49f5b06f-5d5c-aa47-dc79-137120b5ac26",
        "NOMBRE": "HERIDA DEL OÍDO"
    },
    {
        "id": "7b8c959b-e88a-2a7f-17aa-f52a1b40bd34",
        "NOMBRE": "HERIDA DEL PÁRPADO Y DE LA REGIÓN PERIOCULAR"
    },
    {
        "id": "c316ae05-b487-9f6f-4d53-032ffe7d4475",
        "NOMBRE": "HERIDA DEL PENE"
    },
    {
        "id": "28572765-20dd-96b2-7339-cb1d06ca6508",
        "NOMBRE": "HERIDA DEL TOBILLO"
    },
    {
        "id": "043abb81-1595-5213-73bd-e71f3b43348e",
        "NOMBRE": "HERIDA DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "f1726825-637c-0338-6525-20e7b5757f42",
        "NOMBRE": "HERIDA DEL TÓRAX"
    },
    {
        "id": "1cfa45c7-6811-b0c9-83e9-b85ecf2c1e06",
        "NOMBRE": "HERIDA DEL TÓRAX, PARTE NO ESPECIFICADA"
    },
    {
        "id": "bac97774-a241-a1c4-47f4-2fd59b1c65db",
        "NOMBRE": "HERIDA DEL TRONCO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "1bf6c9cb-209c-f820-4874-cce608506cba",
        "NOMBRE": "HERIDA MÚLTIPLE DE LA PARED TORÁCICA"
    },
    {
        "id": "1c3c853d-d307-6fbd-a933-139a71c92042",
        "NOMBRE": "HERIDA PENETRANTE DE LA ÓRBITA CON O SIN CUERPO EXTRAÑO"
    },
    {
        "id": "1e3e2151-742c-ef7a-3195-ce204edf7ac1",
        "NOMBRE": "HERIDA PENETRANTE DEL GLOBO OCULAR CON CUERPO EXTRAÑO"
    },
    {
        "id": "ceb03698-8aab-84fe-6c65-d3ef7279bd6b",
        "NOMBRE": "HERIDA PENETRANTE DEL GLOBO OCULAR SIN CUERPO EXTRAÑO"
    },
    {
        "id": "39286297-d31a-ef5d-6421-09ccd59af738",
        "NOMBRE": "HERIDA QUE COMPROMETE LA FARINGE Y EL ESÓFAGO CERVICAL"
    },
    {
        "id": "a383cdfb-5ab2-d452-5925-73c9a8fafbe5",
        "NOMBRE": "HERIDA QUE COMPROMETE LA GLÁNDULA TIROIDES"
    },
    {
        "id": "7ddfd120-108d-9f11-7f20-7aebb3183707",
        "NOMBRE": "HERIDA QUE COMPROMETE LA LARINGE Y LA TRÁQUEA"
    },
    {
        "id": "4ad95384-e9a1-509c-337d-f398123d4d94",
        "NOMBRE": "HERIDAS DE OTRAS PARTES DEL CUELLO"
    },
    {
        "id": "5fe049d8-fe08-5394-2a27-0e870686b711",
        "NOMBRE": "HERIDAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN"
    },
    {
        "id": "f03d8352-cf45-effb-b691-703dc2d54051",
        "NOMBRE": "HERIDAS MÚLTIPLES DE LA CABEZA"
    },
    {
        "id": "d87b34bd-48f7-6849-9b39-30f13769c7f6",
        "NOMBRE": "HERIDAS MÚLTIPLES DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "d789b006-0888-2074-6aea-57aea3f2f516",
        "NOMBRE": "HERIDAS MÚLTIPLES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "e78e54fe-b05f-bcf4-c2db-e46ab0e7d52d",
        "NOMBRE": "HERIDAS MÚLTIPLES DE LA PIERNA"
    },
    {
        "id": "94e20b8a-1bf1-0124-1a67-585cc6db17d9",
        "NOMBRE": "HERIDAS MÚLTIPLES DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "8cdf0b4b-e3d3-281c-9dfe-2277b839ec03",
        "NOMBRE": "HERIDAS MÚLTIPLES DEL ANTEBRAZO"
    },
    {
        "id": "0d4b667b-1548-029d-ddc9-a59a0c892a17",
        "NOMBRE": "HERIDAS MÚLTIPLES DEL CUELLO"
    },
    {
        "id": "42ec8047-a4fe-4e72-7a59-2a73687f4201",
        "NOMBRE": "HERIDAS MÚLTIPLES DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "767e507b-d86b-7229-8055-a3bc4c53dc01",
        "NOMBRE": "HERIDAS MÚLTIPLES DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "8193bc1b-855f-bbb0-4978-c64b9f1eb74f",
        "NOMBRE": "HERIDAS MÚLTIPLES, NO ESPECIFICADAS"
    },
    {
        "id": "edfb07e4-0b4b-6fc9-1b20-55e898abb361",
        "NOMBRE": "HERIDAS QUE AFECTAN EL TÓRAX CON EL ABDOMEN, LA REGIÓN LUMBOSACRA Y LA PELVIS"
    },
    {
        "id": "6032ced3-f1f3-e63c-3805-96ed69172374",
        "NOMBRE": "HERIDAS QUE AFECTAN LA CABEZA CON EL CUELLO"
    },
    {
        "id": "1ee9ce2e-308b-2cc4-fc25-befa860370a6",
        "NOMBRE": "HERIDAS QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "1127ea07-5a39-5949-212a-432ea3490271",
        "NOMBRE": "HERIDAS QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)"
    },
    {
        "id": "9bbdc8ef-161e-28b1-25a0-7f332402b784",
        "NOMBRE": "HERIDAS QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "67cbc997-45ef-330f-7d1e-7324ae4cefbe",
        "NOMBRE": "HERIDAS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "50bf4d05-5056-1ae0-c170-501635b957cc",
        "NOMBRE": "HERIDAS QUE AFECTAN OTRAS COMBINACIONES DE LAS REGIONES DEL CUERPO"
    },
    {
        "id": "caefee7a-222e-c04c-0f19-9e12f38fa000",
        "NOMBRE": "HERMAFRODITA VERDADERO 46, XX"
    },
    {
        "id": "7de2f5de-50d8-ebfd-9a37-f9f670501725",
        "NOMBRE": "HERMAFRODITISMO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "310a552f-ebc9-13e9-6d9c-ea7e4b7ab0f2",
        "NOMBRE": "HERNIA ABDOMINAL NO ESPECIFICADA, CON GANGRENA"
    },
    {
        "id": "4378d94f-03f8-6d3d-107d-cc95ed6847db",
        "NOMBRE": "HERNIA ABDOMINAL NO ESPECIFICADA, CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "37a6b848-43d9-e94b-019f-f57b8e33e428",
        "NOMBRE": "HERNIA ABDOMINAL NO ESPECIFICADA, SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "4cfbe8ba-351e-5190-f2f7-164a74e4528f",
        "NOMBRE": "HERNIA DIAFRAGMÁTICA"
    },
    {
        "id": "b1433aef-ba33-f126-3b05-05d798503740",
        "NOMBRE": "HERNIA DIAFRAGMÁTICA CON GANGRENA"
    },
    {
        "id": "bd820eff-e8ff-3ad0-10e1-e28136fcb9ea",
        "NOMBRE": "HERNIA DIAFRAGMÁTICA CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "d3f0eee0-77d8-0c94-a70e-362d42ab65b8",
        "NOMBRE": "HERNIA DIAFRAGMÁTICA CONGÉNITA"
    },
    {
        "id": "4a7ee731-c8c6-4ec3-e4d0-a450b0f562f4",
        "NOMBRE": "HERNIA DIAFRAGMÁTICA SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "fa4692f2-15ad-a66c-565a-c6c0fce042d3",
        "NOMBRE": "HERNIA FEMORAL"
    },
    {
        "id": "f4e5ecac-5ad4-56e6-8b00-8ad1b668edb1",
        "NOMBRE": "HERNIA FEMORAL BILATERAL, CON GANGRENA"
    },
    {
        "id": "bd934f4e-336d-e1f6-13d2-148f867a8aec",
        "NOMBRE": "HERNIA FEMORAL BILATERAL, CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "99b7033f-b6b9-60d7-2860-7bb19fc993a4",
        "NOMBRE": "HERNIA FEMORAL BILATERAL, SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "2c1f786f-b26b-e4f5-55ee-5a5813f8186a",
        "NOMBRE": "HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, CON GANGRENA"
    },
    {
        "id": "20303dcf-ad6d-5be1-1fd1-a5fd9d502fad",
        "NOMBRE": "HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "b80dc495-121a-3687-4288-cebed1b187f7",
        "NOMBRE": "HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "120162fa-f86e-931e-891b-986be4db3fc5",
        "NOMBRE": "HERNIA HIATAL CONGÉNITA"
    },
    {
        "id": "172af44e-6db1-4621-9511-599b7ee035e5",
        "NOMBRE": "HERNIA INCISIONAL CON GANGRENA"
    },
    {
        "id": "2eed949c-1465-236e-2431-4c004ce8e5ce",
        "NOMBRE": "HERNIA INCISIONAL CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "3e9c574e-e221-1062-557a-321954deafb1",
        "NOMBRE": "HERNIA INCISIONAL SIN OBSTRUCCIÓN O GANGRENA"
    },
    {
        "id": "56cad8d0-9aa3-2c25-ee39-7fdc3612af2f",
        "NOMBRE": "HERNIA INGUINAL"
    },
    {
        "id": "9a7bd648-3cdd-406b-2fca-275a928a5fdf",
        "NOMBRE": "HERNIA INGUINAL BILATERAL CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "73a07923-2ca5-d645-77a1-3e8d84c8f9fa",
        "NOMBRE": "HERNIA INGUINAL BILATERAL, CON GANGRENA"
    },
    {
        "id": "5eaba25f-ad41-b1c6-8789-c74b32eec066",
        "NOMBRE": "HERNIA INGUINAL BILATERAL, SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "c527ad15-7b01-4488-a20a-4aa44803cd72",
        "NOMBRE": "HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, CON GANGRENA"
    },
    {
        "id": "ceed8650-ea6d-a2e0-2b9f-c71b06a0dd8a",
        "NOMBRE": "HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "d788c574-84e5-9b7c-48d1-a5fc326edcbe",
        "NOMBRE": "HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "348ba7cb-6743-29ae-1b6b-6044d13dab87",
        "NOMBRE": "HERNIA NO ESPECIFICADA DE LA CAVIDAD ABDOMINAL"
    },
    {
        "id": "d2b5fa04-3c78-11fd-abea-803a6677af86",
        "NOMBRE": "HERNIA PARAESTOMAL CON GANGRENA"
    },
    {
        "id": "d47163a5-0419-b8d5-9512-0620fb60a2f7",
        "NOMBRE": "HERNIA PARAESTOMAL CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "d503392a-84bb-9825-b1b9-aea2a760089a",
        "NOMBRE": "HERNIA PARAESTOMAL SIN OBSTRUCCIÓN O GANGRENA"
    },
    {
        "id": "896ffce7-ad63-2f70-f7e0-67b66908460d",
        "NOMBRE": "HERNIA UMBILICAL"
    },
    {
        "id": "3ce2a7e9-9f4b-2a9a-fc7e-d046e01e28ed",
        "NOMBRE": "HERNIA UMBILICAL CON GANGRENA"
    },
    {
        "id": "a837a88b-c5e4-da58-a097-681bf41ee127",
        "NOMBRE": "HERNIA UMBILICAL CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "5e1160b8-a8eb-ef32-df10-7336cb29970e",
        "NOMBRE": "HERNIA UMBILICAL SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "79853701-d7a4-026f-edb6-3024dcb3e3ca",
        "NOMBRE": "HERNIA VENTRAL"
    },
    {
        "id": "fd4438d0-0080-e9e3-be80-5a91df4455f5",
        "NOMBRE": "HERPES GESTACIONAL"
    },
    {
        "id": "ffa45756-c2be-aa49-16a5-c54abcd74ef4",
        "NOMBRE": "HERPES ZOSTER"
    },
    {
        "id": "6c23a347-ccbf-fdef-c4cb-40942e4803da",
        "NOMBRE": "HERPES ZOSTER CON OTRAS COMPLICACIONES"
    },
    {
        "id": "c5e12ea7-8f26-64ec-e8f9-c66bc77e2b4c",
        "NOMBRE": "HERPES ZOSTER CON OTROS COMPROMISOS DEL SISTEMA NERVIOSO"
    },
    {
        "id": "408e8b0a-cd8a-4ade-b0b0-972b41f0cf26",
        "NOMBRE": "HERPES ZOSTER DISEMINADO"
    },
    {
        "id": "b8019533-4788-6ee2-0874-eaf15c7df5dd",
        "NOMBRE": "HERPES ZOSTER OCULAR"
    },
    {
        "id": "25cb5b34-ffdf-7c77-50fc-1e9d0df3212d",
        "NOMBRE": "HERPES ZOSTER SIN COMPLICACIONES"
    },
    {
        "id": "fab71921-7675-cbdf-434f-dc7a3a73a232",
        "NOMBRE": "HETEROFORIA"
    },
    {
        "id": "4ebc1f00-2ef5-b8c1-0429-093e3978706b",
        "NOMBRE": "HETEROTROPÍA INTERMITENTE"
    },
    {
        "id": "f8ca31d7-e6cb-82ed-fa3c-65fb6ddc8a50",
        "NOMBRE": "HIDRADENITIS SUPURATIVA"
    },
    {
        "id": "dde9f3b9-8df5-1424-5138-f8e759f0821a",
        "NOMBRE": "HIDRARTROSIS INTERMITENTE"
    },
    {
        "id": "67798edc-b49f-3a87-13fc-d23a212634e2",
        "NOMBRE": "HIDROCEFALIA ADQUIRIDA DEL RECIÉN NACIDO"
    },
    {
        "id": "d59402af-c991-7ba6-3265-7e8f5f0ecdb3",
        "NOMBRE": "HIDROCÉFALO"
    },
    {
        "id": "4369f9b5-4fc9-8c94-2cd1-208a06eadcfe",
        "NOMBRE": "HIDROCÉFALO COMUNICANTE"
    },
    {
        "id": "1f22c665-4227-c884-0ea1-4ad9c7d0a5d8",
        "NOMBRE": "HIDROCÉFALO CONGÉNITO"
    },
    {
        "id": "ab4c1125-093e-eead-d9cd-6edcf55d061a",
        "NOMBRE": "HIDROCÉFALO CONGÉNITO, NO ESPECIFICADO"
    },
    {
        "id": "619b7bd2-15c7-643c-00e0-64ef4a7b9770",
        "NOMBRE": "HIDROCÉFALO DE PRESIÓN NORMAL"
    },
    {
        "id": "02f26199-ce36-ea27-670c-19e3a9b67bbe",
        "NOMBRE": "HIDROCÉFALO EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "4734f7b8-295f-6644-c87d-eeae4da09344",
        "NOMBRE": "HIDROCÉFALO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "884b6bda-8994-b719-b766-ba5d4be55d6f",
        "NOMBRE": "HIDROCÉFALO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ad414cd3-ee92-1451-78b5-196e710746dd",
        "NOMBRE": "HIDROCÉFALO OBSTRUCTIVO"
    },
    {
        "id": "f6b1e3c9-1427-94a6-af3e-162a8e3b21f3",
        "NOMBRE": "HIDROCÉFALO POSTRAUMÁTICO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "df878dd4-d314-9229-413c-b14bc2d465a5",
        "NOMBRE": "HIDROCÉFALO, NO ESPECIFICADO"
    },
    {
        "id": "32afe494-588b-89c6-5001-c570e46f636a",
        "NOMBRE": "HIDROCELE CONGÉNITO"
    },
    {
        "id": "0c85f892-fbd2-2180-0b63-14ce6a1893db",
        "NOMBRE": "HIDROCELE ENQUISTADO"
    },
    {
        "id": "0f550115-8729-a43b-d8bc-b3824b7262a5",
        "NOMBRE": "HIDROCELE INFECTADO"
    },
    {
        "id": "3c250004-a948-8baf-b477-517c1693bc8b",
        "NOMBRE": "HIDROCELE Y ESPERMATOCELE"
    },
    {
        "id": "909e512f-a044-fbe4-542a-f2fdd86e93b1",
        "NOMBRE": "HIDROCELE, NO ESPECIFICADO"
    },
    {
        "id": "b7605537-7497-3c4d-8809-263dde0102f5",
        "NOMBRE": "HIDROMIELIA"
    },
    {
        "id": "0dcc2605-5053-e07b-ac4e-3e94105743af",
        "NOMBRE": "HIDRONEFROSIS CON ESTRECHEZ URETERAL, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "50f083d3-9df3-4a56-b830-b545ab73fd2f",
        "NOMBRE": "HIDRONEFROSIS CON OBSTRUCCIÓN DE LA UNIÓN URÉTERO-PÉLVICA"
    },
    {
        "id": "bcab9bd5-1a71-7ef6-97f4-67e562f7af73",
        "NOMBRE": "HIDRONEFROSIS CON OBSTRUCCIÓN POR CÁLCULOS DEL RIÑÓN Y DEL URÉTER"
    },
    {
        "id": "330c56f0-68dd-a068-d3a4-44e5aaa30b24",
        "NOMBRE": "HIDRONEFROSIS CONGÉNITA"
    },
    {
        "id": "cf131f5e-0c61-c8e4-5eda-7264444d9302",
        "NOMBRE": "HIDROPESÍA DE LA VESÍCULA BILIAR"
    },
    {
        "id": "4e5a2f1e-6b0f-5128-8f92-998e8e9b1af4",
        "NOMBRE": "HIDROPESÍA FETAL DEBIDA A ENFERMEDAD HEMOLÍTICA"
    },
    {
        "id": "0991f92c-06b9-4863-d06a-50f7a4c86ee4",
        "NOMBRE": "HIDROPESÍA FETAL DEBIDA A INCOMPATIBILIDAD"
    },
    {
        "id": "580c5b62-2e08-8ea7-4329-14e037406dfd",
        "NOMBRE": "HIDROPESÍA FETAL DEBIDA A OTRAS ENFERMEDADES HEMOLÍTICAS ESPECIFICADAS Y A LAS NO ESPECIFICADAS"
    },
    {
        "id": "ef816d48-1ab6-9b54-4bae-2dc4ab8859e3",
        "NOMBRE": "HIDROPESÍA FETAL NO DEBIDA A ENFERMEDAD HEMOLÍTICA"
    },
    {
        "id": "cb1f8ac4-5e0d-39a3-6e73-ed5134fdde18",
        "NOMBRE": "HIDROURÉTER"
    },
    {
        "id": "acae9d14-de75-cdf9-6ae6-822e8d633587",
        "NOMBRE": "HIFEMA"
    },
    {
        "id": "50a89e7b-5804-5e33-8089-6b4085528097",
        "NOMBRE": "HÍGADO ALCOHÓLICO ADIPOSO"
    },
    {
        "id": "3b1bca40-9e87-d5fd-0611-d45aa1883cd9",
        "NOMBRE": "HIMEN IMPERFORADO"
    },
    {
        "id": "1b502d0f-5d68-41ba-bd45-712968d0c86d",
        "NOMBRE": "HIMENOLEPIASIS"
    },
    {
        "id": "c8ebf580-53ca-7152-a51e-4c1ccfa52ee9",
        "NOMBRE": "HIPERACTIVIDAD"
    },
    {
        "id": "4dc8e8d5-f807-5c4f-cb45-2abfb496e9e5",
        "NOMBRE": "HIPERALDOSTERONISMO"
    },
    {
        "id": "6629eaa9-4f7f-3077-57e5-e196f8119264",
        "NOMBRE": "HIPERALDOSTERONISMO PRIMARIO"
    },
    {
        "id": "09076650-9611-c722-fe12-0c7866939212",
        "NOMBRE": "HIPERALDOSTERONISMO SECUNDARIO"
    },
    {
        "id": "8b058c10-b3ae-6b8b-7b09-2f052035e61f",
        "NOMBRE": "HIPERALDOSTERONISMO, NO ESPECIFICADO"
    },
    {
        "id": "c724de29-1aef-be9c-1053-8aaa83da3962",
        "NOMBRE": "HIPERALIMENTACIÓN DEL RECIÉN NACIDO"
    },
    {
        "id": "996e6d25-4eb6-3217-dad6-268f921f4f69",
        "NOMBRE": "HIPERBILIRRUBINEMIA, CON O SIN ICTERICIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "02a64829-81c6-dec0-6ef6-848057b81651",
        "NOMBRE": "HIPERBILIRUBINAEMIA CON MENCIÓN DE ICTERICIA, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "6334c04b-bc06-3aa8-c41e-e4358b698e8c",
        "NOMBRE": "HIPERBILIRUBINAEMIA SIN MENCIÓN DE ICTERICIA, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "effb3822-652f-4b52-4ff2-9898cdf8dff0",
        "NOMBRE": "HIPERCAROTINEMIA"
    },
    {
        "id": "1f884d54-f058-60ce-0638-e92adecda2e8",
        "NOMBRE": "HIPERCEMENTOSIS"
    },
    {
        "id": "14f6927f-2f81-ee60-b476-570916139b73",
        "NOMBRE": "HIPERCOLESTEROLEMIA PURA"
    },
    {
        "id": "c9349be8-92b9-9495-0caa-18baf6f18c51",
        "NOMBRE": "HIPEREMESIS GRAVÍDICA CON TRASTORNOS METABÓLICOS"
    },
    {
        "id": "2d35a672-020d-3d67-c803-c1e4c29dea20",
        "NOMBRE": "HIPEREMESIS GRAVÍDICA LEVE"
    },
    {
        "id": "ecef5996-868b-1e0d-39b1-9c9226eac93c",
        "NOMBRE": "HIPEREMESIS GRAVÍDICA TARDÍA"
    },
    {
        "id": "894e1302-ba18-6977-2d77-9b03a71ef256",
        "NOMBRE": "HIPERESPLENISMO"
    },
    {
        "id": "9f557438-b12f-b59d-3fa9-d64aa557a738",
        "NOMBRE": "HIPERESTESIA"
    },
    {
        "id": "b3995478-948f-2b90-db49-564e99e98bfe",
        "NOMBRE": "HIPERESTIMULACIÓN DE OVARIOS"
    },
    {
        "id": "967e2a1b-8589-7dad-bb7f-d68d72a53937",
        "NOMBRE": "HIPERFAGIA ASOCIADA CON OTRAS ALTERACIONES PSICOLÓGICAS"
    },
    {
        "id": "e2402fa3-630c-e028-a1f9-e88d220dd0c3",
        "NOMBRE": "HIPERFUNCIÓN DE LA GLÁNDULA HIPÓFISIS"
    },
    {
        "id": "3448feb2-7355-2c9b-0b86-8854c1a27c2d",
        "NOMBRE": "HIPERFUNCIÓN DE LA GLÁNDULA HIPÓFISIS, NO ESPECIFICADA"
    },
    {
        "id": "e9e9235e-9cf1-e72b-6b46-ed6e09de1d0d",
        "NOMBRE": "HIPERFUNCIÓN DE LA MÉDULA SUPRARRENAL"
    },
    {
        "id": "e20deb01-8f90-1a8d-fb45-d84ce549b37d",
        "NOMBRE": "HIPERFUNCIÓN POLIGLANDULAR"
    },
    {
        "id": "e019726d-3ab2-e77b-1153-9e7efd81ef78",
        "NOMBRE": "HIPERFUNCIÓN TESTICULAR"
    },
    {
        "id": "dc8aad72-76da-e4f8-2b11-ffddbaf84897",
        "NOMBRE": "HIPERGAMMAGLOBULINEMIA POLICLONAL"
    },
    {
        "id": "722c7161-d74f-5b72-a8f5-b6998bf5f1b9",
        "NOMBRE": "HIPERGAMMAGLOBULINEMIA, NO ESPECIFICADA"
    },
    {
        "id": "42fbb306-c603-8d49-ef81-fefa204a87dd",
        "NOMBRE": "HIPERGLICEMIA, NO ESPECIFICADA"
    },
    {
        "id": "f409729d-c4ec-7b00-0dea-f0b320c7c570",
        "NOMBRE": "HIPERGLICERIDEMIA PURA"
    },
    {
        "id": "0d477dba-0d33-982d-383b-9971285f2345",
        "NOMBRE": "HIPERHIDROSIS"
    },
    {
        "id": "8db24d3e-053e-b7dd-655f-8da8d9acd8e4",
        "NOMBRE": "HIPERHIDROSIS GENERALIZADA"
    },
    {
        "id": "63a8d051-2ac2-5823-cda4-3b071173a547",
        "NOMBRE": "HIPERHIDROSIS LOCALIZADA"
    },
    {
        "id": "86e53b79-d272-14d0-b89d-a4985de9484a",
        "NOMBRE": "HIPERHIDROSIS, NO ESPECIFICADA"
    },
    {
        "id": "4957a826-837e-41e4-7e86-7e494d812ec0",
        "NOMBRE": "HIPERLIPIDEMIA MIXTA"
    },
    {
        "id": "d1760ffd-e8ac-87af-e3fa-95dc5293ac2e",
        "NOMBRE": "HIPERLIPIDEMIA NO ESPECIFICADA"
    },
    {
        "id": "3173cd08-875a-7618-b58b-2f72eaff5c72",
        "NOMBRE": "HIPERMETROPÍA"
    },
    {
        "id": "1ab8df8b-9f7f-920e-e44c-e83367178be7",
        "NOMBRE": "HIPERNASALIDAD E HIPONASALIDAD"
    },
    {
        "id": "c8d0b428-aa2b-528c-226a-81fe9fa75910",
        "NOMBRE": "HIPEROSMOLARIDAD E HIPERNATREMIA"
    },
    {
        "id": "4da1cd7b-6abc-c2bb-8a6d-0cebb99170e0",
        "NOMBRE": "HIPEROSTOSIS ANQUILOSANTE [FORESTIER]"
    },
    {
        "id": "eae84784-2b04-5702-602e-1ae2d8997a49",
        "NOMBRE": "HIPEROSTOSIS DEL CRÁNEO"
    },
    {
        "id": "56955f38-d272-f384-973e-bf06337aed31",
        "NOMBRE": "HIPERPARATIROIDISMO PRIMARIO"
    },
    {
        "id": "33dce93a-c331-f193-9f9f-f5c8286bc526",
        "NOMBRE": "HIPERPARATIROIDISMO SECUNDARIO NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "2584eb9e-67d0-4877-393b-6002569fab12",
        "NOMBRE": "HIPERPARATIROIDISMO Y OTROS TRASTORNOS DE LA GLÁNDULA PARATIROIDES"
    },
    {
        "id": "281a6975-edbc-61c3-03a6-dbd9a17d8684",
        "NOMBRE": "HIPERPARATIROIDISMO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "4d24936a-b779-e6cc-8931-c1d49356f053",
        "NOMBRE": "HIPERPIGMENTACIÓN POSTINFLAMATORIA"
    },
    {
        "id": "76ebaa49-59af-41a4-3f2a-71a88e9c479b",
        "NOMBRE": "HIPERPLASIA ADENOMATOSA DEL ENDOMETRIO"
    },
    {
        "id": "b2f62620-9403-6b9a-75ce-e66ed0448621",
        "NOMBRE": "HIPERPLASIA DE GLÁNDULA DEL ENDOMETRIO"
    },
    {
        "id": "1e2b449e-f5e6-6ddd-d148-c1a5f1d01c33",
        "NOMBRE": "HIPERPLASIA DE LA PRÓSTATA"
    },
    {
        "id": "ebc3f8da-d177-d371-f872-347ae8035c19",
        "NOMBRE": "HIPERPLASIA DEL APÉNDICE"
    },
    {
        "id": "5bb53cce-8a07-dbc4-4238-aa363f19efa8",
        "NOMBRE": "HIPERPLASIA GINGIVAL"
    },
    {
        "id": "0a228b05-ad9e-3548-21bb-648d5ebc5cfd",
        "NOMBRE": "HIPERPLASIA IRRITATIVA DE LA MUCOSA BUCAL"
    },
    {
        "id": "133badd3-f594-6aa9-8aa8-691a12d3aafa",
        "NOMBRE": "HIPERPLASIA PERSISTENTE DEL TIMO"
    },
    {
        "id": "71b52bda-531a-2211-d54c-620928bb37a3",
        "NOMBRE": "HIPERPLASIA RENAL Y RIÑÓN GIGANTE"
    },
    {
        "id": "05334a3d-39f6-fad9-d3ac-34faa457f564",
        "NOMBRE": "HIPERPOTASEMIA"
    },
    {
        "id": "c13bffa4-2237-3293-30c7-b266d6094ac6",
        "NOMBRE": "HIPERPROLACTINEMIA"
    },
    {
        "id": "8e7a2dc8-2a2a-b49e-3ba3-dceec4adddff",
        "NOMBRE": "HIPERQUERATOSIS DE FRAMBESIA"
    },
    {
        "id": "e2842ba2-4964-cc4a-f8c2-5e682e4c4a84",
        "NOMBRE": "HIPERQUILOMICRONEMIA"
    },
    {
        "id": "5b15c165-193e-b343-aa49-4ba5de10feab",
        "NOMBRE": "HIPERSECRECIÓN DE CALCITONINA"
    },
    {
        "id": "3a2ab4dc-c11f-fcba-b2f6-f70d23b4d3e2",
        "NOMBRE": "HIPERSOMNIO NO ORGÁNICO"
    },
    {
        "id": "0e6732db-0fa7-68fd-86e0-401cb53c7f96",
        "NOMBRE": "HIPERTELORISMO"
    },
    {
        "id": "d9c3ebb4-e837-6518-8c90-9f14380a2227",
        "NOMBRE": "HIPERTENSIÓN ESENCIAL (PRIMARIA)"
    },
    {
        "id": "d7e22496-14a4-78e4-a4ae-84bed814cdf3",
        "NOMBRE": "HIPERTENSIÓN ESENCIAL PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "725343f8-51b4-885e-9f32-46e0d950802c",
        "NOMBRE": "HIPERTENSIÓN GESTACIONAL [INDUCIDA POR EL EMBARAZO]"
    },
    {
        "id": "333018a0-8fe3-9ff7-7c6b-4b7c0f602167",
        "NOMBRE": "HIPERTENSIÓN INTRACRANEAL BENIGNA"
    },
    {
        "id": "aa19e413-04d9-342e-824b-ce866c3435a3",
        "NOMBRE": "HIPERTENSIÓN MATERNA, NO ESPECIFICADA"
    },
    {
        "id": "2d525835-a607-585a-ea05-32a6fc7b0744",
        "NOMBRE": "HIPERTENSIÓN NEONATAL"
    },
    {
        "id": "8e44d3ec-f39f-7fec-852b-4a7d775f0046",
        "NOMBRE": "HIPERTENSIÓN PORTAL"
    },
    {
        "id": "2a35c2cf-30ae-c10f-5835-db1cdae5b836",
        "NOMBRE": "HIPERTENSIÓN PREEXISTENTE NO ESPECIFICADA, QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "c2536603-9690-c0db-0e70-d0b0fa36da74",
        "NOMBRE": "HIPERTENSIÓN PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "26abf2b8-2920-7b67-482b-c17663d15d2d",
        "NOMBRE": "HIPERTENSIÓN PULMONAR PRIMARIA"
    },
    {
        "id": "01dcee37-c06a-c87f-790f-e291649d1892",
        "NOMBRE": "HIPERTENSIÓN RENOVASCULAR"
    },
    {
        "id": "d0d1261c-47f1-4381-dcc5-5a2eb3bef334",
        "NOMBRE": "HIPERTENSIÓN SECUNDARIA"
    },
    {
        "id": "5e1dcc1f-dc97-889d-5fb1-d233b428bdff",
        "NOMBRE": "HIPERTENSIÓN SECUNDARIA A OTROS TRASTORNOS RENALES"
    },
    {
        "id": "34af502c-9294-fc3f-da3c-35392977478b",
        "NOMBRE": "HIPERTENSIÓN SECUNDARIA A TRASTORNOS ENDOCRINOS"
    },
    {
        "id": "f03dbf3f-3251-7a61-7d0c-0ec79b55f925",
        "NOMBRE": "HIPERTENSIÓN SECUNDARIA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "87d3564f-e69c-0511-44ce-97d1902c2442",
        "NOMBRE": "HIPERTENSIÓN SECUNDARIA, NO ESPECIFICADA"
    },
    {
        "id": "acb31132-ca6c-a4c5-a18d-fd178dee06d8",
        "NOMBRE": "HIPERTERMIA DEL RECIÉN NACIDO INDUCIDA POR LAS CONDICIONES AMBIENTALES"
    },
    {
        "id": "4db17498-99b0-9bfd-3a1d-7062cc121b05",
        "NOMBRE": "HIPERTERMIA MALIGNA DEBIDA A LA ANESTESIA"
    },
    {
        "id": "4a83545f-fbb1-b856-bd66-1c6f1a920935",
        "NOMBRE": "HIPERTIROIDISMO NEONATAL TRANSITORIO"
    },
    {
        "id": "a57843e0-37eb-afd1-34a7-7521cac4c9a7",
        "NOMBRE": "HIPERTONÍA CONGÉNITA"
    },
    {
        "id": "132c9034-6b2a-351e-cb77-651c7b2b3c45",
        "NOMBRE": "HIPERTRICOSIS"
    },
    {
        "id": "139c5ac9-236a-599b-86d8-db8e6766ae76",
        "NOMBRE": "HIPERTRICOSIS LANUGINOSA ADQUIRIDA"
    },
    {
        "id": "16478dd8-0b22-cb3b-1a50-fd2750f5ea18",
        "NOMBRE": "HIPERTRICOSIS LOCALIZADA"
    },
    {
        "id": "bd144f9b-6e5b-4012-00c0-c10c68839680",
        "NOMBRE": "HIPERTRICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "88af5f3a-5586-030c-5deb-35cee02ca279",
        "NOMBRE": "HIPERTROFIA DE GLÁNDULA SALIVAL"
    },
    {
        "id": "ec34fdbc-37b1-cc43-058b-52747cf09f18",
        "NOMBRE": "HIPERTROFIA DE LA MAMA"
    },
    {
        "id": "280ba9f3-e222-ee60-752f-fd5bbd2bfcd8",
        "NOMBRE": "HIPERTROFIA DE LA VULVA"
    },
    {
        "id": "66bde273-66c8-b14b-b582-2900333539ec",
        "NOMBRE": "HIPERTROFIA DE LAS ADENOIDES"
    },
    {
        "id": "f85a59a8-844a-4ddf-e45f-5d9f0d7b9916",
        "NOMBRE": "HIPERTROFIA DE LAS AMÍGDALAS"
    },
    {
        "id": "5dd459f4-10a1-d638-7f2f-2982559241cf",
        "NOMBRE": "HIPERTROFIA DE LAS AMÍGDALAS CON HIPERTROFIA DE LAS ADENOIDES"
    },
    {
        "id": "a5081307-8e7c-b953-1a5e-22e739952f30",
        "NOMBRE": "HIPERTROFIA DE LAS PAPILAS LINGUALES"
    },
    {
        "id": "54d35f37-4545-3ca3-ee43-21eb85b7fe46",
        "NOMBRE": "HIPERTROFIA DE LOS CORNETES NASALES"
    },
    {
        "id": "82da4faa-c523-37d2-3344-2430604a2ada",
        "NOMBRE": "HIPERTROFIA DE PAQUETE ADIPOSO (INFRARROTULIANO)"
    },
    {
        "id": "3f151ecf-7bc4-88d7-fc09-374a087dc5d3",
        "NOMBRE": "HIPERTROFIA DEL HUESO"
    },
    {
        "id": "291af1f0-359c-9602-fd42-fbf72100cd24",
        "NOMBRE": "HIPERTROFIA DEL ÚTERO"
    },
    {
        "id": "972351fa-7526-e28f-deba-072a65bee212",
        "NOMBRE": "HIPERTROFIA SINOVIAL, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "927db8d3-4c43-84fa-c115-0462897d9e18",
        "NOMBRE": "HIPERURICEMIA SIN SIGNOS DE ARTRITIS INFLAMATORIA Y ENFERMEDAD TOFÁCEA"
    },
    {
        "id": "84167f41-1c2e-b3a3-c8bd-4c9d25816a12",
        "NOMBRE": "HIPERVENTILACIÓN"
    },
    {
        "id": "bc76e81c-6389-651b-e99c-04f096b5e113",
        "NOMBRE": "HIPERVITAMINOSIS A"
    },
    {
        "id": "594d467d-d992-9b2d-5d05-4924c62271fb",
        "NOMBRE": "HIPERVITAMINOSIS D"
    },
    {
        "id": "e2db5a31-4cba-0764-cad7-5866374e7da0",
        "NOMBRE": "HIPO"
    },
    {
        "id": "2e0de659-e19f-4085-7bad-298826dc47a0",
        "NOMBRE": "HIPOACUSIA CONDUCTIVA BILATERAL"
    },
    {
        "id": "51220828-a1db-5221-b0a6-ed9901a243b5",
        "NOMBRE": "HIPOACUSIA CONDUCTIVA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "abf80e65-dd9f-561f-d0d6-ca1ee75c786a",
        "NOMBRE": "HIPOACUSIA CONDUCTIVA, UNILATERAL CON AUDICIÓN IRRESTRICTA CONTRALATERAL"
    },
    {
        "id": "d5c6f02f-9dc8-880b-1ef5-da4f69956367",
        "NOMBRE": "HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, BILATERAL"
    },
    {
        "id": "4af01d96-aa17-916a-96f9-c51cd12fcd4e",
        "NOMBRE": "HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, NO ESPECIFICADA"
    },
    {
        "id": "e99251f7-7ecc-0ce3-2873-12fc66c1baac",
        "NOMBRE": "HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, UNILATERAL CON AUDICIÓN IRRESTRICTA CONTRALATERAL"
    },
    {
        "id": "c4714148-538f-ca07-e4e3-f189b5666b86",
        "NOMBRE": "HIPOACUSIA NEUROSENSORIAL, BILATERAL"
    },
    {
        "id": "13c91b06-8dfc-16d0-8ac2-35558921c253",
        "NOMBRE": "HIPOACUSIA NEUROSENSORIAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c4bda79c-857c-471e-c6c7-ac137b2183bf",
        "NOMBRE": "HIPOACUSIA NEUROSENSORIAL, UNILATERAL CON AUDICIÓN IRRESTRICTA CONTRALATERAL"
    },
    {
        "id": "aace24c0-c357-84ee-6926-595781e84fff",
        "NOMBRE": "HIPOACUSIA OTOTÓXICA"
    },
    {
        "id": "6d8c26f0-216b-52b8-cab2-0a5ff73da995",
        "NOMBRE": "HIPOACUSIA SÚBITA IDIOPÁTICA"
    },
    {
        "id": "e383e1c5-0cd2-a55e-a151-b9a2f989b2e7",
        "NOMBRE": "HIPOACUSIA, NO ESPECIFICADA"
    },
    {
        "id": "437afcc5-8dd9-f6ec-ee23-d8c951976529",
        "NOMBRE": "HIPOALIMENTACIÓN DEL RECIÉN NACIDO"
    },
    {
        "id": "38029e60-02b8-bb21-75c7-cc765fb6adb5",
        "NOMBRE": "HIPOCALCEMIA DEL RECIÉN NACIDO DEBIDA A LA LECHE DE VACA"
    },
    {
        "id": "21affd78-3765-144a-5c4e-dd6b351eda30",
        "NOMBRE": "HIPOCAUSIA CONDUCTIVA Y NEUROSENSORIAL"
    },
    {
        "id": "51861452-d336-6554-b135-52be55b3eed8",
        "NOMBRE": "HIPOESPLENISMO"
    },
    {
        "id": "b2ac6bec-40f8-62ec-342f-b776c79a2b87",
        "NOMBRE": "HIPOESTESIA DE LA PIEL"
    },
    {
        "id": "ea370a0b-af8c-5e17-c84d-1042f590bc06",
        "NOMBRE": "HIPOFUNCIÓN ADRENOCORTICAL [MÉDULA SUPRARRENAL] CONSECUTIVA A PROCEDIMIENTOS"
    },
    {
        "id": "b2cc7ac0-0aae-e848-d90e-adae1c6c274c",
        "NOMBRE": "HIPOFUNCIÓN TESTICULAR"
    },
    {
        "id": "9fefaaef-b477-7c45-5d27-297de0104ab7",
        "NOMBRE": "HIPOFUNCIÓN TESTICULAR CONSECUTIVA A PROCEDIMIENTOS"
    },
    {
        "id": "83e54f5f-8484-12c0-b143-c1a3f20eeea3",
        "NOMBRE": "HIPOFUNCIÓN Y OTROS TRASTORNOS DE LA GLÁNDULA HIPÓFISIS"
    },
    {
        "id": "18003cba-e6de-3e71-d619-0a213ba90e96",
        "NOMBRE": "HIPOGALACTIA"
    },
    {
        "id": "d8933cc4-f36f-b7d6-86b2-a8cb90a72485",
        "NOMBRE": "HIPOGAMMAGLOBULINEMIA HEREDITARIA"
    },
    {
        "id": "5d8ba3f8-0b5d-6c67-aa96-20f42aed5ca3",
        "NOMBRE": "HIPOGAMMAGLOBULINEMIA NO FAMILIAR"
    },
    {
        "id": "58409783-bc51-3d9d-b2af-9351a95e7dbd",
        "NOMBRE": "HIPOGAMMAGLOBULINEMIA TRANSITORIA DE LA INFANCIA"
    },
    {
        "id": "16013725-1174-5474-3c02-8b5e54b5daec",
        "NOMBRE": "HIPOGLICEMIA NEONATAL YATROGÉNICA"
    },
    {
        "id": "b43c13a1-1b3b-e613-8c93-0ee2a7d05ba1",
        "NOMBRE": "HIPOGLICEMIA SIN COMA, INDUCIDA POR DROGAS"
    },
    {
        "id": "fba30126-1b90-d85c-348f-2a548e3bbca7",
        "NOMBRE": "HIPOGLICEMIA, NO ESPECIFICADA"
    },
    {
        "id": "14ec40e6-da9e-85cf-73ff-d2814e01faa3",
        "NOMBRE": "HIPOINSULINEMIA CONSECUTIVA A PROCEDIMIENTOS"
    },
    {
        "id": "fdd25bd4-e644-1d2c-7f22-08884d52120d",
        "NOMBRE": "HIPOMAGNESEMIA NEONATAL"
    },
    {
        "id": "13c96b91-b03e-e77f-59ac-6d1321b4cb7c",
        "NOMBRE": "HIPOMANÍA"
    },
    {
        "id": "b7e007d8-2374-a23a-5afc-7a99e74b4d1c",
        "NOMBRE": "HIPOPARATIROIDISMO"
    },
    {
        "id": "4ce4a33c-9f5a-1196-1f2a-9b6da6c3ab48",
        "NOMBRE": "HIPOPARATIROIDISMO CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "ce558622-7dfa-c85c-440c-6df1b306b203",
        "NOMBRE": "HIPOPARATIROIDISMO IDIOPÁTICO"
    },
    {
        "id": "923f88b6-e1d0-e4f5-135e-f11d78033247",
        "NOMBRE": "HIPOPARATIROIDISMO NEONATAL TRANSITORIO"
    },
    {
        "id": "6a2e9eb8-a0a2-8e8d-acca-0a37bf1795f7",
        "NOMBRE": "HIPOPARATIROIDISMO, NO ESPECIFICADO"
    },
    {
        "id": "decb68ff-71cf-6cfe-1100-403175d916db",
        "NOMBRE": "HIPOPITUITARISMO"
    },
    {
        "id": "844272ce-06d3-4a1f-3ca4-13ba31f00a99",
        "NOMBRE": "HIPOPITUITARISMO CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "2bf86a4f-1cc1-b173-1c16-9c98b8006daa",
        "NOMBRE": "HIPOPITUITARISMO INDUCIDO POR DROGAS"
    },
    {
        "id": "97bd1676-e4d3-328d-0069-523c65e25702",
        "NOMBRE": "HIPOPLASIA DEL TESTÍCULO Y DEL ESCROTO"
    },
    {
        "id": "3ff42f7f-6ede-1d3d-3c2f-a86855ecc43e",
        "NOMBRE": "HIPOPLASIA LARÍNGEA"
    },
    {
        "id": "fd98a71a-44ad-4412-9bd1-f15ae5e9390a",
        "NOMBRE": "HIPOPLASIA RENAL, BILATERAL"
    },
    {
        "id": "efac8c9c-8b02-0ef3-7a97-8b519405e737",
        "NOMBRE": "HIPOPLASIA RENAL, NO ESPECIFICADA"
    },
    {
        "id": "6c20aae7-23c9-cc44-851b-d97689077dfc",
        "NOMBRE": "HIPOPLASIA RENAL, UNILATERAL"
    },
    {
        "id": "09489873-69b9-d6d4-1039-1a5fc5ca57d9",
        "NOMBRE": "HIPOPLASIA Y DISPLASIA DE LA MÉDULA ESPINAL"
    },
    {
        "id": "7f50feef-e08f-2070-9690-4f7054fb079c",
        "NOMBRE": "HIPOPLASIA Y DISPLASIA PULMONAR"
    },
    {
        "id": "0e73baf7-f05f-32a8-3559-35bf5159d67b",
        "NOMBRE": "HIPOPOTASMIA"
    },
    {
        "id": "1b70a5e1-930c-26b9-ac47-4a1423a8a811",
        "NOMBRE": "HIPOSMOLARIDAD E HIPONATREMIA"
    },
    {
        "id": "83bcd49c-a4c0-17fb-f06d-21c1f0f8f258",
        "NOMBRE": "HIPOSPADIAS"
    },
    {
        "id": "2d97862a-8aae-f038-6c66-1c6351bbe1ab",
        "NOMBRE": "HIPOSPADIAS DEL GLANDE"
    },
    {
        "id": "60932e4e-3c9b-e6a3-bbb6-bdef27a9333c",
        "NOMBRE": "HIPOSPADIAS PENEANA"
    },
    {
        "id": "e497a539-ae36-0445-9723-daad4b79c5e6",
        "NOMBRE": "HIPOSPADIAS PENOSCROTAL"
    },
    {
        "id": "a4915093-f309-47ca-04fb-85fb9bf9d03b",
        "NOMBRE": "HIPOSPADIAS PERINEAL"
    },
    {
        "id": "d78704c7-b535-a590-50da-7f7122077687",
        "NOMBRE": "HIPOSPADIAS, NO ESPECIFICADA"
    },
    {
        "id": "9fc7df98-4b64-1f2d-5fe3-4d2032b06c6c",
        "NOMBRE": "HIPOTENSIÓN"
    },
    {
        "id": "2f98f6a8-2266-6dd1-a609-3045717112cb",
        "NOMBRE": "HIPOTENSIÓN DEBIDA A DROGAS"
    },
    {
        "id": "8019526b-4256-3707-a318-996f6f69b153",
        "NOMBRE": "HIPOTENSIÓN IDIOPÁTICA"
    },
    {
        "id": "51d72fea-af8d-3655-bdfe-9e3236087bc4",
        "NOMBRE": "HIPOTENSIÓN INTRACRANEAL POSTERIOR A ANASTOMOSIS VENTRICULAR"
    },
    {
        "id": "aba90e47-969c-b718-c348-aee1e0b4b90f",
        "NOMBRE": "HIPOTENSIÓN ORTOSTÁTICA"
    },
    {
        "id": "6c583b8c-f62a-a3f0-de84-3011a20bdce2",
        "NOMBRE": "HIPOTENSIÓN, NO ESPECIFICADA"
    },
    {
        "id": "73c2b0c1-b6a0-4c1f-851f-4ba27c68980f",
        "NOMBRE": "HIPOTERMIA"
    },
    {
        "id": "7d9c3fbd-340f-f0f6-4819-0a50509182b3",
        "NOMBRE": "HIPOTERMIA DEL RECIÉN NACIDO"
    },
    {
        "id": "87be8534-fe05-994a-93a6-60e471161634",
        "NOMBRE": "HIPOTERMIA DEL RECIÉN NACIDO, NO ESPECIFICADA"
    },
    {
        "id": "5572dc97-d1f2-9400-b5e0-85862dbfcf09",
        "NOMBRE": "HIPOTERMIA NO ASOCIADA CON BAJA TEMPERATURA DEL AMBIENTE"
    },
    {
        "id": "f598aa15-ffc1-4c71-d65f-426722d41647",
        "NOMBRE": "HIPOTIROIDISMO CONGÉNITO CON BOCIO DIFUSO"
    },
    {
        "id": "852256b1-76f0-f330-b391-63e69a1241ee",
        "NOMBRE": "HIPOTIROIDISMO CONGÉNITO SIN BOCIO"
    },
    {
        "id": "da013e99-2241-d849-b5f1-852dc7d10688",
        "NOMBRE": "HIPOTIROIDISMO CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "c2f52118-6c08-9b1c-1920-036c1f02286c",
        "NOMBRE": "HIPOTIROIDISMO DEBIDO A MEDICAMENTOS Y A OTRAS SUSTANCIAS EXÓGENAS"
    },
    {
        "id": "556a44ab-b37f-8493-0d3b-2f9b911e71dc",
        "NOMBRE": "HIPOTIROIDISMO POSTINFECCIOSO"
    },
    {
        "id": "5bcc5511-6e31-6a0b-30c0-9ef5ff64297f",
        "NOMBRE": "HIPOTIROIDISMO SUBCLÍNICO POR DEFICIENCIA DE YODO"
    },
    {
        "id": "dee9f885-b39e-b58c-f684-ec6f57f1125b",
        "NOMBRE": "HIPOTIROIDISMO, NO ESPECIFICADO"
    },
    {
        "id": "0758fbe4-addb-3775-6bbc-31168f2b1c2c",
        "NOMBRE": "HIPOTONÍA CONGÉNITA"
    },
    {
        "id": "e2b4ea47-68aa-193a-59d6-d4347c70a597",
        "NOMBRE": "HIPOTONÍA OCULAR"
    },
    {
        "id": "34397e72-5cd5-d694-0402-b519256a3a38",
        "NOMBRE": "HIPOXIA INTRAUTERINA"
    },
    {
        "id": "2b26a17b-3dca-05ce-7ca1-2db80a980459",
        "NOMBRE": "HIPOXIA INTRAUTERINA NOTADA POR PRIMERA VEZ ANTES DEL INICIO DEL TRABAJO DE PARTO"
    },
    {
        "id": "a6a80562-a57b-eff0-b11f-74d1b837177f",
        "NOMBRE": "HIPOXIA INTRAUTERINA NOTADA POR PRIMERA VEZ DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "3738ff92-bf99-5fba-6f18-7553e25be53a",
        "NOMBRE": "HIPOXIA INTRAUTERINA, NO ESPECIFICADA"
    },
    {
        "id": "6d1e9282-63bf-cae2-f829-b9134a6f68e4",
        "NOMBRE": "HIRSUTISMO"
    },
    {
        "id": "be70b49a-36f9-ded6-1fac-86d6d5a30ba5",
        "NOMBRE": "HIRUDINIASIS EXTERNA"
    },
    {
        "id": "5bb9809f-fe6b-b373-5dde-6aa9b9a45d00",
        "NOMBRE": "HIRUDINIASIS INTERNA"
    },
    {
        "id": "f3041c40-d530-c296-166a-c5823be5786d",
        "NOMBRE": "HISTIOCITOSIS DE CÉLULAS DE LANGERHANS MULTIFOCAL Y MULTISISTÉMICA (DISEMINADA) [ENFERMEDAD DE LETTERER-SIWE]"
    },
    {
        "id": "7e8436bf-93f9-0560-94a2-28ab827156a1",
        "NOMBRE": "HISTIOCITOSIS DE CÉLULAS DE LANGERHANS MULTIFOCAL Y UNISISTÉMICA"
    },
    {
        "id": "0f275f23-1cc4-465e-3d25-b12f2ffc271d",
        "NOMBRE": "HISTIOCITOSIS DE CÉLULAS DE LANGERHANS UNIFOCAL"
    },
    {
        "id": "7468a6cf-f738-4332-e832-2dacd0cbc878",
        "NOMBRE": "HISTIOCITOSIS DE LAS CÉLULAS DE LANGERHANS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "9f6b9cff-d0ad-b73d-49be-4045b9da86c2",
        "NOMBRE": "HISTIOCITOSIS MALIGNA"
    },
    {
        "id": "10a4f469-a723-1a9b-14e1-f94db29e8d0e",
        "NOMBRE": "HISTOPLASMOSIS"
    },
    {
        "id": "cc8adb9e-fdc9-845f-bea8-03fffa3adbe3",
        "NOMBRE": "HISTOPLASMOSIS DEBIDA A HISTOPLASMA CAPSULATUM, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "da597a2f-f0c3-216d-e03c-ce96e99e965e",
        "NOMBRE": "HISTOPLASMOSIS, NO ESPECIFICADA"
    },
    {
        "id": "55d3a8c1-f4f0-d552-ade1-2f5c45e47cc1",
        "NOMBRE": "HISTORIA FAMILIAR DE ABUSO DE ALCOHOL"
    },
    {
        "id": "5d7fba24-ac9e-f88a-08f9-0b8c9491fcbd",
        "NOMBRE": "HISTORIA FAMILIAR DE ABUSO DE OTRAS SUSTANCIAS"
    },
    {
        "id": "78d0666d-07bb-bef2-18ee-9eb1e9514fa8",
        "NOMBRE": "HISTORIA FAMILIAR DE ABUSO DE OTRAS SUSTANCIAS PSICOACTIVAS"
    },
    {
        "id": "b5735a35-8117-589c-12b7-6eb0e1a9ad0a",
        "NOMBRE": "HISTORIA FAMILIAR DE ABUSO DEL TABACO"
    },
    {
        "id": "957c70bd-f5f8-d051-87ef-ba5779dfb1ba",
        "NOMBRE": "HISTORIA FAMILIAR DE APOPLEJÍA"
    },
    {
        "id": "8d8875bd-da3a-dd5e-7f74-358a7e62cf33",
        "NOMBRE": "HISTORIA FAMILIAR DE ARTRITIS Y OTRAS ENFERMEDADES DEL SISTEMA OSTEOMUSCULAR Y TEJIDO CONJUNTIVO"
    },
    {
        "id": "97a6c11c-aea1-e726-b7f2-32ffc0fea72f",
        "NOMBRE": "HISTORIA FAMILIAR DE ASMA Y DE OTRAS ENFERMEDADES CRÓNICAS DE LAS VÍAS RESPIRATORIAS INFERIORES"
    },
    {
        "id": "3cd756c8-6ecf-3c75-ac6b-004d4ea4e975",
        "NOMBRE": "HISTORIA FAMILIAR DE CEGUERA O PÉRDIDA DE LA VISIÓN"
    },
    {
        "id": "e333e2e5-1b15-c8bd-7b38-f4da3527f6cf",
        "NOMBRE": "HISTORIA FAMILIAR DE CIERTAS DISCAPACIDADES Y ENFERMEDADES CRÓNICAS INCAPACITANTES"
    },
    {
        "id": "f71a708d-5402-fd22-c4ed-c60965c43932",
        "NOMBRE": "HISTORIA FAMILIAR DE CONSANGUINIDAD"
    },
    {
        "id": "0d338075-588f-0e58-fefa-68dc30f19e70",
        "NOMBRE": "HISTORIA FAMILIAR DE DIABETES MELLITUS"
    },
    {
        "id": "04a45f36-b530-9eb3-51d2-97f943753caa",
        "NOMBRE": "HISTORIA FAMILIAR DE ENFERMEDAD ISQUÉMICA DEL CORAZÓN Y OTRAS ENFERMEDADES DEL SISTEMA CIRCULATORIO"
    },
    {
        "id": "e7133d08-8737-b4ad-c454-9e3f221c3c86",
        "NOMBRE": "HISTORIA FAMILIAR DE ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "b6635371-2953-b9f3-7497-559f18f071f1",
        "NOMBRE": "HISTORIA FAMILIAR DE ENFERMEDADES DE LA SANGRE Y DE LOS ÓRGANOS HEMATOPOYÉTICOS Y DE CIERTOS TRASTORNOS DEL MECANISMO INMUNOLÓGICO"
    },
    {
        "id": "0fd80f9f-4844-0323-00f3-137d6919d9be",
        "NOMBRE": "HISTORIA FAMILIAR DE ENFERMEDADES DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "fafdd70e-43a9-7ca8-20f9-21ccdbe94ec2",
        "NOMBRE": "HISTORIA FAMILIAR DE ENFERMEDADES DEL SISTEMA RESPIRATORIO"
    },
    {
        "id": "c22f99e5-3003-fc4a-5aae-3df639c41f3d",
        "NOMBRE": "HISTORIA FAMILIAR DE EPILEPSIA Y OTRAS ENFERMEDADES DEL SISTEMA NERVIOSO"
    },
    {
        "id": "cdebce6d-bc3b-c8bc-adf8-54865ff7be14",
        "NOMBRE": "HISTORIA FAMILIAR DE INFECCIÓN POR EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"
    },
    {
        "id": "8debdd61-38f6-137a-00ad-3c491588710f",
        "NOMBRE": "HISTORIA FAMILIAR DE LEUCEMIA"
    },
    {
        "id": "09de21ec-dec9-d059-4cb7-36c7d0821df4",
        "NOMBRE": "HISTORIA FAMILIAR DE MALFORMACIONES CONGÉNITAS, DEFORMIDADES Y OTRAS ANOMALÍAS CROMOSÓMICAS"
    },
    {
        "id": "d4bbdb86-4e54-659d-8e50-fba6b7f87194",
        "NOMBRE": "HISTORIA FAMILIAR DE OTRAS AFECCIONES"
    },
    {
        "id": "c0550ed6-8c71-12e0-2341-3f0b194f7c07",
        "NOMBRE": "HISTORIA FAMILIAR DE OTRAS AFECCIONES ESPECIFICADAS"
    },
    {
        "id": "7fa099de-44fd-edea-588e-eae91375aaf5",
        "NOMBRE": "HISTORIA FAMILIAR DE OTRAS DISCAPACIDADES Y ENFERMEDADES CRÓNICAS INCAPACITANTES NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9486326c-443f-cc5e-24c6-a14acb0e0962",
        "NOMBRE": "HISTORIA FAMILIAR DE OTRAS ENFERMEDADES DEL SISTEMA GENITOURINARIO"
    },
    {
        "id": "f12dc23a-019e-1a9b-48f7-46844067d6c5",
        "NOMBRE": "HISTORIA FAMILIAR DE OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABÓLICAS"
    },
    {
        "id": "c760d96d-7fd2-8f0b-a61b-b29e7237c6ea",
        "NOMBRE": "HISTORIA FAMILIAR DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS"
    },
    {
        "id": "567f52ce-2dff-42d4-27b5-08be0f9e6d36",
        "NOMBRE": "HISTORIA FAMILIAR DE OTROS TRASTORNOS ESPECÍFICOS"
    },
    {
        "id": "7675d372-5de9-2d9a-7845-12351a89767f",
        "NOMBRE": "HISTORIA FAMILIAR DE OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "95bff1f3-5c47-29fd-932a-b9f9c0128512",
        "NOMBRE": "HISTORIA FAMILIAR DE OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYÉTICO Y TEJIDOS RELACIONADOS"
    },
    {
        "id": "c1294890-797c-c8aa-5cdf-377fbc92b47c",
        "NOMBRE": "HISTORIA FAMILIAR DE RETARDO MENTAL"
    },
    {
        "id": "32dcc2c6-2254-9e27-2c8e-52fec12f4fc2",
        "NOMBRE": "HISTORIA FAMILIAR DE SORDERA O PÉRDIDA DE LA AUDICIÓN"
    },
    {
        "id": "31ddd596-96be-efea-9845-7162c085653c",
        "NOMBRE": "HISTORIA FAMILIAR DE TRASTORNOS DE LOS OJOS Y DE LOS OÍDOS"
    },
    {
        "id": "c7299319-08de-d85e-3ce7-c0396bcf49a3",
        "NOMBRE": "HISTORIA FAMILIAR DE TRASTORNOS DEL RIÑÓN Y DEL URÉTER"
    },
    {
        "id": "33831671-46c6-9365-88d3-86179e58994a",
        "NOMBRE": "HISTORIA FAMILIAR DE TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "59d0332b-e24f-9a58-49a0-7c090f5b6c89",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO"
    },
    {
        "id": "e38b494c-0689-448a-cc73-761cadec6d8d",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO DE MAMA"
    },
    {
        "id": "3bd77d1a-dd88-2fc4-c28b-b4fa7bf6b563",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO DE ÓRGANOS DIGESTIVOS"
    },
    {
        "id": "abdaafbe-cdad-df37-29b1-6b31102cf9e2",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO DE ÓRGANOS GENITALES"
    },
    {
        "id": "7e11a2f4-9e8e-2cfd-dbb3-84a161b1372d",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO DE OTROS ÓRGANOS O SISTEMAS ESPECIFICADOS"
    },
    {
        "id": "11b647f4-d95a-8154-114f-5a143008b1a4",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO DE OTROS ÓRGANOS RESPIRATORIOS E INTRATORÁCICOS"
    },
    {
        "id": "9ccf66aa-972a-a81d-d530-761e715e7a5a",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO DE TRÁQUEA, BRONQUIOS Y PULMÓN"
    },
    {
        "id": "4523d837-055f-d595-d422-2b341ae93990",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO DE VÍAS URINARIAS"
    },
    {
        "id": "07b1e799-6a8c-4c03-4588-014df8aeb3fb",
        "NOMBRE": "HISTORIA FAMILIAR DE TUMOR MALIGNO, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "1742f630-2a29-4bcd-903a-5f4bddc10e54",
        "NOMBRE": "HISTORIA PERSONAL DE ABUSO DE SUSTANCIAS PSICOACTIVAS"
    },
    {
        "id": "a73f5337-ee9f-f1f3-b00a-01ddc2aa6a8f",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A AGENTE ANALGÉSICO"
    },
    {
        "id": "1ef99caa-049b-619c-950f-58b96213a433",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A AGENTE ANESTÉSICO"
    },
    {
        "id": "935a4786-0d42-37ea-89f2-7e43d723c08c",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A AGENTE NARCÓTICO"
    },
    {
        "id": "ccfb6914-16bf-8dad-21ea-fa35b3511210",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "394ad076-3431-f2f2-71f6-a95a2008ce31",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS NO ESPECIFICADAS"
    },
    {
        "id": "386f87ee-3e8b-3018-8b6c-4cdf0691604d",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "1b8f31f6-4fd2-7d6e-e77e-ea6cb3c1c22d",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A OTROS AGENTES ANTIBIÓTICOS"
    },
    {
        "id": "225865ee-3690-4d65-5cda-82fe2a9f288b",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A OTROS AGENTES ANTIINFECCIOSOS"
    },
    {
        "id": "f19bd667-586c-32ce-beff-ee1b30946f90",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A PENICILINA"
    },
    {
        "id": "472c2797-4ba0-6953-4492-b21f59a12a1f",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A SUERO O VACUNA"
    },
    {
        "id": "23a49310-5778-c907-7ed8-32865cdb9195",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA A SULFONAMIDAS"
    },
    {
        "id": "75cb1f18-fe27-c0a5-6dfd-e09c4cb481e9",
        "NOMBRE": "HISTORIA PERSONAL DE ALERGIA, NO DEBIDA A DROGAS NI A SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "b6ff31fc-0f39-4063-6b2f-770d3274e3a3",
        "NOMBRE": "HISTORIA PERSONAL DE ALGUNAS OTRAS ENFERMEDADES"
    },
    {
        "id": "2f03c5aa-1013-aca0-33be-5fbb7370eb78",
        "NOMBRE": "HISTORIA PERSONAL DE ANTICONCEPCIÓN"
    },
    {
        "id": "92cd36f8-1048-8825-6a9b-2594a630904c",
        "NOMBRE": "HISTORIA PERSONAL DE CICLO SUEÑO-VIGILIA NO SALUDABLE"
    },
    {
        "id": "205ccb49-413e-8cfa-d08f-08e90ed4a8bf",
        "NOMBRE": "HISTORIA PERSONAL DE CIERTAS AFECCIONES ORIGINADAS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "70f54d3d-78dc-f891-6b8f-7e7fbd2f3957",
        "NOMBRE": "HISTORIA PERSONAL DE CIRUGÍA MAYOR NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "2b7a7de1-7227-8134-ccee-dcf5940a7f82",
        "NOMBRE": "HISTORIA PERSONAL DE COMPLICACIONES DEL EMBARAZO, DEL PARTO Y DEL PUERPERIO"
    },
    {
        "id": "e8a2f8af-6239-f37e-6b2b-1cdf01f9b0cf",
        "NOMBRE": "HISTORIA PERSONAL DE COVID-19"
    },
    {
        "id": "470c75d5-88d6-3fd5-b678-ba0305cbc858",
        "NOMBRE": "HISTORIA PERSONAL DE COVID-19, NO ESPECIFICADA"
    },
    {
        "id": "44b787b8-1cc4-c3c5-df74-19dcc1b2ccb2",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "a2d0aa07-602d-f45e-d0fc-35b894a8904f",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DE LA SANGRE Y DE LOS ÓRGANOS HEMATOPOYÉTICOS Y DE CIERTOS TRASTORNOS DEL MECANISMO INMUNOLÓGICO"
    },
    {
        "id": "94eb82b7-b9d0-a0b8-1794-8739e85b30d4",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA CIRCULATORIO"
    },
    {
        "id": "760225c6-5c4a-312c-ed3a-4d91eec536d2",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "2070a984-4ae9-b20a-b3b7-65db4caeaf4a",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA GENITOURINARIO"
    },
    {
        "id": "4c55bb77-b1a5-cdaf-d089-d1dc6acc148f",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA NERVIOSO Y DE LOS ÓRGANOS DE LOS SENTIDOS"
    },
    {
        "id": "d215edf7-5432-b4c3-508c-950dd07aa755",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA OSTEOMUSCULAR Y DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "cd745752-af13-b2e1-9c7b-17842e7dc694",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA RESPIRATORIO"
    },
    {
        "id": "a70d1624-7892-fded-b0ad-0f67e94c9fe1",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABÓLICAS"
    },
    {
        "id": "d0038058-6911-e0f9-a39b-3cce8ddbf049",
        "NOMBRE": "HISTORIA PERSONAL DE ENFERMEDADES INFECCIOSAS Y PARASITARIAS"
    },
    {
        "id": "7292423c-67d6-8f52-981e-26ea58ba01c2",
        "NOMBRE": "HISTORIA PERSONAL DE FACTORES DE RIESGO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "22da6a78-f32f-61a5-4be6-5c33f0ab2682",
        "NOMBRE": "HISTORIA PERSONAL DE HIGIENE PERSONAL DEFICIENTE"
    },
    {
        "id": "3ba9ccfb-7afc-7a37-dee0-5ffa494bef63",
        "NOMBRE": "HISTORIA PERSONAL DE INCUMPLIMIENTO DEL RÉGIMEN O TRATAMIENTO MÉDICO"
    },
    {
        "id": "44e947ee-6038-721e-4e05-4f0eb6dd3b16",
        "NOMBRE": "HISTORIA PERSONAL DE IRRADIACIÓN"
    },
    {
        "id": "2d437e46-61fb-de0a-5225-cdecc6bfe6fc",
        "NOMBRE": "HISTORIA PERSONAL DE LESIÓN AUTOINFLIGIDA INTENCIONALMENTE"
    },
    {
        "id": "72c0f310-30fb-dda3-f40b-336fccf2d49d",
        "NOMBRE": "HISTORIA PERSONAL DE LEUCEMIA"
    },
    {
        "id": "03660329-775b-afc2-e91f-d664740c3cb5",
        "NOMBRE": "HISTORIA PERSONAL DE MALFORMACIONES CONGÉNITAS, DEFORMIDADES Y ANOMALÍAS CROMOSÓMICAS"
    },
    {
        "id": "361ab4ce-d8f7-968a-3a87-f0f0e343fc87",
        "NOMBRE": "HISTORIA PERSONAL DE MEDIDAS DE REHABILITACIÓN"
    },
    {
        "id": "b95dbcfe-fd44-80af-b16d-83f0700a5107",
        "NOMBRE": "HISTORIA PERSONAL DE OTRAS AFECCIONES ESPECIFICADAS"
    },
    {
        "id": "0af53490-0a61-144a-28e5-4f9a7ac803a5",
        "NOMBRE": "HISTORIA PERSONAL DE OTRAS ENFERMEDADES Y AFECCIONES"
    },
    {
        "id": "75556601-a57d-4711-e989-394fd38dee3b",
        "NOMBRE": "HISTORIA PERSONAL DE OTRO TRAUMA FÍSICO"
    },
    {
        "id": "2ea5af71-1cc2-6ca5-f252-a83c9d28cd3b",
        "NOMBRE": "HISTORIA PERSONAL DE OTROS FACTORES DE RIESGO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "694da5f4-9e98-fd53-6bc9-f4fb84298d37",
        "NOMBRE": "HISTORIA PERSONAL DE OTROS TRASTORNOS MENTALES O DEL COMPORTAMIENTO"
    },
    {
        "id": "e2378868-eb6c-616a-73d6-50d4d7147b19",
        "NOMBRE": "HISTORIA PERSONAL DE OTROS TRATAMIENTOS MÉDICOS"
    },
    {
        "id": "f002a073-8898-d4cf-63a5-abff07013ab2",
        "NOMBRE": "HISTORIA PERSONAL DE OTROS TUMORES"
    },
    {
        "id": "4b4aa1a3-8b87-d4b5-6aeb-884a444ce011",
        "NOMBRE": "HISTORIA PERSONAL DE OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYÉTICO Y TEJIDOS RELACIONADOS"
    },
    {
        "id": "ed8cc3b1-ab91-c118-f1e1-7b857fe09c29",
        "NOMBRE": "HISTORIA PERSONAL DE QUIMIOTERAPIA POR ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "65fd7418-ef8f-c04f-48ca-994e1d9491e7",
        "NOMBRE": "HISTORIA PERSONAL DE TRATAMIENTO MÉDICO"
    },
    {
        "id": "b2df79db-a98a-5333-a53f-17a30318914b",
        "NOMBRE": "HISTORIA PERSONAL DE TRATAMIENTO MÉDICO NO ESPECIFICADO"
    },
    {
        "id": "0d6c85ca-ac30-fb2d-a7dc-558ec5a3d6d1",
        "NOMBRE": "HISTORIA PERSONAL DE TRAUMA PSICOLÓGICO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "c3415dcf-9864-770e-ec57-1893465ce5c9",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO"
    },
    {
        "id": "7464f211-4772-be57-fdf0-977617f5743b",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO DE MAMA"
    },
    {
        "id": "59cd77eb-6dd9-d282-8aa0-3bc0f0c58870",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO DE ÓRGANOS DIGESTIVOS"
    },
    {
        "id": "26af2157-60ef-9d27-efde-16b4cee131e8",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO DE ÓRGANOS GENITALES"
    },
    {
        "id": "d6e7fac4-15bb-d9b2-8882-057b12091511",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO DE OTROS ÓRGANOS RESPIRATORIOS E INTRATORÁCICOS"
    },
    {
        "id": "ff70e7e7-dff4-fee5-5d12-45d54ee8c4f5",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO DE OTROS ÓRGANOS Y SISTEMAS"
    },
    {
        "id": "c5df6eda-5447-18a2-3985-ee53ba5987a3",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO DE TRÁQUEA, BRONQUIOS Y PULMÓN"
    },
    {
        "id": "28c5a3be-7150-dcb5-90b8-0437aa90e78e",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO DE VÍAS URINARIAS"
    },
    {
        "id": "e1bde894-06a3-5118-04fc-a108a7c0fbd9",
        "NOMBRE": "HISTORIA PERSONAL DE TUMOR MALIGNO, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "07dda384-cacd-d015-93f8-b968e7b0420e",
        "NOMBRE": "HISTORIA PERSONAL DE USO (PRESENTE) DE ANTICOAGULANTES POR LARGO TIEMPO"
    },
    {
        "id": "2c6f44c6-7588-e30f-91b4-9b8891e1508f",
        "NOMBRE": "HISTORIA PERSONAL DE USO (PRESENTE) DE OTROS MEDICAMENTOS POR LARGO TIEMPO"
    },
    {
        "id": "47c23822-6d00-9843-c01e-bb329a3bfbd8",
        "NOMBRE": "HISTORIAL PERSONAL DE LA MUTILACIÓN GENITAL FEMENINA"
    },
    {
        "id": "d44f7b9e-26a2-c48e-bd22-010f8cba0efd",
        "NOMBRE": "HOLOPROSENCEFALIA"
    },
    {
        "id": "a9992101-208e-2f49-1f30-0e8b32c951d5",
        "NOMBRE": "HOMBRE CON CROMOSOMA SEXUAL ESTRUCTURALMENTE ANORMAL"
    },
    {
        "id": "cb0bf0f8-2646-ce9c-83a8-0c9ffb5051af",
        "NOMBRE": "HOMBRE CON MOSAICO DE CROMOSOMAS SEXUALES"
    },
    {
        "id": "26127c90-9ed6-215e-3ede-1bf69f7902e4",
        "NOMBRE": "HOSTILIDAD"
    },
    {
        "id": "9cb1df8e-2588-d5a2-0748-55a3c753ae83",
        "NOMBRE": "HURTO PATOLÓGICO [CLEPTOMANÍA]"
    },
    {
        "id": "5d7b0d2d-a2d9-41fe-5327-c5c0977c2fbd",
        "NOMBRE": "ICTERICIA NEONATAL ASOCIADA CON EL PARTO ANTES DE TÉRMINO"
    },
    {
        "id": "bc497875-a950-3c79-ab51-8aa446fec51f",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A CONTUSIÓN"
    },
    {
        "id": "aed0b981-8bd8-151c-5001-af947cbf8d46",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A DEGLUCIÓN DE SANGRE MATERNA"
    },
    {
        "id": "4f0f8ed8-4a0f-0e70-5c06-d7c59a16ac66",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A DROGAS O TOXINAS TRANSMITIDAS POR LA MADRE O ADMINISTRADAS AL RECIÉN NACIDO"
    },
    {
        "id": "ab33dd06-6b6b-43b6-c044-7a8db3489a09",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A HEMÓLISIS EXCESIVA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "b8307144-58a3-b4c8-f939-0d0e7d89d66f",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A HEMORRAGIA"
    },
    {
        "id": "bd77a53e-b252-cf55-a8e8-9929f569fca4",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A INFECCIÓN"
    },
    {
        "id": "aa7d36a4-631c-00da-9c9a-bf81ef7f3407",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A OTRA LESIÓN HEPÁTICA ESPECIFICADA O NO"
    },
    {
        "id": "69e65c21-2624-d0aa-3b61-ba89bbe25119",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A OTRAS HEMÓLISIS EXCESIVAS"
    },
    {
        "id": "1e447693-06ba-54e6-2bce-2c44b98614c6",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A OTRAS HEMÓLISIS EXCESIVAS ESPECIFICADAS"
    },
    {
        "id": "ce6d8e57-370f-9098-db0f-609c1c4d3478",
        "NOMBRE": "ICTERICIA NEONATAL DEBIDA A POLICITEMIA"
    },
    {
        "id": "826695d0-5d4b-f227-27ae-c5936b476836",
        "NOMBRE": "ICTERICIA NEONATAL POR INHIBIDOR DE LA LECHE MATERNA"
    },
    {
        "id": "24f424a5-1287-b22f-88dc-56a6ace1e523",
        "NOMBRE": "ICTERICIA NEONATAL POR OTRAS CAUSAS ESPECIFICADAS"
    },
    {
        "id": "6dfff67f-3dcd-348a-eabc-0c4f26aa8bee",
        "NOMBRE": "ICTERICIA NEONATAL POR OTRAS CAUSAS Y POR LAS NO ESPECIFICADAS"
    },
    {
        "id": "a05d528e-a460-14ef-da73-3865a8e545e0",
        "NOMBRE": "ICTERICIA NEONATAL, NO ESPECIFICADA"
    },
    {
        "id": "58dc5db7-9354-018b-2b37-6dc204e992bf",
        "NOMBRE": "ICTERICIA NO ESPECIFICADA"
    },
    {
        "id": "5f53ef6e-4c59-2323-0dae-cf18a11bff0e",
        "NOMBRE": "ICTIOSIS ADQUIRIDA"
    },
    {
        "id": "8c84b023-3aec-fa68-a4c6-f7ee15eece3d",
        "NOMBRE": "ICTIOSIS CONGÉNITA"
    },
    {
        "id": "3f94f935-fe81-bcfa-df1a-63244d5a9cc6",
        "NOMBRE": "ICTIOSIS CONGÉNITA, NO ESPECIFICADA"
    },
    {
        "id": "e8b7e468-c5d9-105f-e8dd-ebc2ee9123fe",
        "NOMBRE": "ICTIOSIS LAMELAR"
    },
    {
        "id": "f03e0c21-14e9-33e9-3bb8-cab1717c8fa8",
        "NOMBRE": "ICTIOSIS LIGADA AL CROMOSOMA X"
    },
    {
        "id": "a593253c-cdf4-f297-d212-5090b1cd3496",
        "NOMBRE": "ICTIOSIS VULGAR"
    },
    {
        "id": "7ca34eb1-f12d-fee1-f269-9a999e2b8412",
        "NOMBRE": "ÍLEO MECONIAL EN LA FIBROSIS QUÍSTICA (E84.1†)"
    },
    {
        "id": "8c7af1e5-2191-d68c-3730-8a1fd7a25ebf",
        "NOMBRE": "ÍLEO PARALÍTICO"
    },
    {
        "id": "128e7586-1b77-ef76-5cea-70257e1191ed",
        "NOMBRE": "ILEO PARALÍTICO Y OBSTRUCCIÓN INTESTINAL SIN HERNIA"
    },
    {
        "id": "612b267e-5f17-7b61-aee3-54c068511369",
        "NOMBRE": "ÍLEO POR CÁLCULO BILIAR"
    },
    {
        "id": "72235135-49d4-372b-3019-b21b79c669cb",
        "NOMBRE": "ÍLEO TRANSITORIO DEL RECIÉN NACIDO"
    },
    {
        "id": "6225f3d5-7036-c88d-8f30-c7114d5a9fc8",
        "NOMBRE": "ÍLEO, NO ESPECIFICADO"
    },
    {
        "id": "dd9e4e05-ec7a-d55d-08a3-4899ab55c69d",
        "NOMBRE": "ILEOCOLITIS (CRÓNICA) ULCERATIVA"
    },
    {
        "id": "4d3fd7ac-1676-92be-a163-a2e91fa72394",
        "NOMBRE": "ILEOSTOMÍA"
    },
    {
        "id": "06e343a9-b7b6-9308-e8a5-93acb91d691c",
        "NOMBRE": "IMPETIGINIZACIÓN DE OTRAS DERMATOSIS"
    },
    {
        "id": "0b571566-4572-ba64-b82b-611cd4670058",
        "NOMBRE": "IMPÉTIGO"
    },
    {
        "id": "58c14030-3eb5-1c2e-a10f-044144ccc690",
        "NOMBRE": "IMPÉTIGO [CUALQUIER SITIO ANATÓMICO] [CUALQUIER ORGANISMO]"
    },
    {
        "id": "f50f852f-817a-b812-babd-ddcf7768e7b7",
        "NOMBRE": "IMPOTENCIA DE ORIGEN ORGÁNICO"
    },
    {
        "id": "c853b3f6-e0b6-6412-cca6-a65c812bdae1",
        "NOMBRE": "IMPULSO SEXUAL EXCESIVO"
    },
    {
        "id": "bc6e917c-492c-653c-3315-a1fe3300ddc8",
        "NOMBRE": "INCIDENTE DURANTE ADMINISTRACIÓN DE ENEMA"
    },
    {
        "id": "a7981adc-0cec-b702-5d8a-9e0f9850a617",
        "NOMBRE": "INCIDENTE DURANTE ASPIRACIÓN, PUNCIÓN Y OTRA CATETERIZACIÓN"
    },
    {
        "id": "81ae8755-f0ec-72ec-7606-0c838d97e00f",
        "NOMBRE": "INCIDENTE DURANTE ATENCIÓN MÉDICA Y QUIRÚRGICA NO ESPECIFICADA"
    },
    {
        "id": "05f58aae-8496-797e-f58d-4813704ff223",
        "NOMBRE": "INCIDENTE DURANTE CATETERIZACIÓN CARDÍACA"
    },
    {
        "id": "9de59377-1151-1673-8622-8af1fb817d16",
        "NOMBRE": "INCIDENTE DURANTE DIÁLISIS RENAL U OTRA PERFUCIÓN"
    },
    {
        "id": "cbb6572c-7983-9dbe-3206-3cbcf019d3b8",
        "NOMBRE": "INCIDENTE DURANTE EXAMEN ENDOSCÓPICO"
    },
    {
        "id": "41255fa8-6594-a852-eaec-f17f0f64827a",
        "NOMBRE": "INCIDENTE DURANTE INFUSIÓN O TRANSFUSIÓN"
    },
    {
        "id": "7678809a-45f6-b713-2cb2-81dcb7454aff",
        "NOMBRE": "INCIDENTE DURANTE INYECCIÓN O INMUNIZACIÓN"
    },
    {
        "id": "08ffadf8-c2df-4708-aa9c-2ddbe5c1b40c",
        "NOMBRE": "INCIDENTE DURANTE OPERACIÓN QUIRÚRGICA"
    },
    {
        "id": "e70773a3-035d-c771-9dc4-5f6a08834b09",
        "NOMBRE": "INCIDENTE DURANTE OTRAS ATENCIONES MÉDICAS Y QUIRÚRGICAS"
    },
    {
        "id": "fbedcf4b-5312-55bf-41d6-246a3976b77a",
        "NOMBRE": "INCIDENTES NO ESPECIFICADOS DURANTE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "4973f53c-5cab-1eaf-920b-7e5734629a2e",
        "NOMBRE": "INCOMPATIBILIDAD ABO DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "29e79b23-dd5b-cc00-ba9e-0ce96263ab4b",
        "NOMBRE": "INCOMPATIBILIDAD RH DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "ca79c919-ecf2-60b8-03e2-2a19428d2769",
        "NOMBRE": "INCOMPETENCIA DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "43c0113e-0f2c-e68e-5ce3-e3103e57b60d",
        "NOMBRE": "INCONTINENCIA FECAL"
    },
    {
        "id": "80cd4b48-7242-eedc-76c1-b6dd4305521a",
        "NOMBRE": "INCONTINENCIA PIGMENTARIA"
    },
    {
        "id": "498c5df3-a1e4-14fe-69d8-687489f2885d",
        "NOMBRE": "INCONTINENCIA URINARIA POR TENSIÓN"
    },
    {
        "id": "251c7b3a-d266-e546-5f94-9320437d8eda",
        "NOMBRE": "INCONTINENCIA URINARIA, NO ESPECIFICADA"
    },
    {
        "id": "3a96303b-85ba-c8f3-dc47-46a2efdefa23",
        "NOMBRE": "INDIVIDUOS CON HETEROCROMATINA MARCADORA"
    },
    {
        "id": "18a80ee2-b5e4-b77c-eedf-f488a71f76af",
        "NOMBRE": "INDIVIDUOS CON SITIO FRÁGIL AUTOSÓMICO"
    },
    {
        "id": "d81c42d1-df4a-6278-efa6-c25db682f704",
        "NOMBRE": "INDURACIÓN PLÁSTICA DEL PENE"
    },
    {
        "id": "d16a2b66-857b-7d2e-7131-864fd2f53236",
        "NOMBRE": "INERCIA UTERINA SECUNDARIA"
    },
    {
        "id": "cd832a30-152a-ced4-74a3-fe53bddf2db1",
        "NOMBRE": "INESTABILIDAD CRÓNICA DE LA RODILLA"
    },
    {
        "id": "536ecba6-98aa-626b-e982-991d052976a6",
        "NOMBRE": "INESTABILIDAD DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "5e946b26-268d-9f2f-2845-40279ba818c5",
        "NOMBRE": "INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "311c36e5-c6a1-dda7-4cce-b5cbc68592d7",
        "NOMBRE": "INFARTO AGUDO DEL MIOCARDIO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "6e65a6b9-fd73-3267-3eff-86d2f5093d82",
        "NOMBRE": "INFARTO AGUDO TRANSMURAL DEL MIOCARDIO DE OTROS SITIOS"
    },
    {
        "id": "1e1c6b30-59a0-077c-b407-0b228e4804f0",
        "NOMBRE": "INFARTO ANTIGUO DEL MIOCARDIO"
    },
    {
        "id": "c654eaf2-4817-0e66-11a2-3f3d9f0c8ba5",
        "NOMBRE": "INFARTO CEREBRAL"
    },
    {
        "id": "0943b4d6-c00c-f955-863f-db5fa54b95e0",
        "NOMBRE": "INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS CEREBRALES"
    },
    {
        "id": "4c7897e6-d5b9-16f4-0bd1-daee5ff75144",
        "NOMBRE": "INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS PRECEREBRALES"
    },
    {
        "id": "144f43eb-8a71-a21d-c44f-1cb6b7a10511",
        "NOMBRE": "INFARTO CEREBRAL DEBIDO A OCLUSIÓN O ESTENOSIS NO ESPECIFICADA DE ARTERIAS CEREBRALES"
    },
    {
        "id": "4da23278-456d-7f6f-a426-aa2efc068653",
        "NOMBRE": "INFARTO CEREBRAL DEBIDO A OCLUSIÓN O ESTENOSIS NO ESPECIFICADA DE ARTERIAS PRECEREBRALES"
    },
    {
        "id": "175f3161-d68f-d973-c924-8e2c156d8f00",
        "NOMBRE": "INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS CEREBRALES"
    },
    {
        "id": "eb6cdb1c-e5d8-28a8-b186-4e339da11283",
        "NOMBRE": "INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS PRECEREBRALES"
    },
    {
        "id": "45ee0d51-e064-e4d3-6a94-7c8b0ed2440d",
        "NOMBRE": "INFARTO CEREBRAL DEBIDO A TROMBOSIS DE VENAS CEREBRALES, NO PIÓGENO"
    },
    {
        "id": "898e4494-310f-9e3d-e90a-1f457151d523",
        "NOMBRE": "INFARTO CEREBRAL, NO ESPECIFICADO"
    },
    {
        "id": "bfe28649-c7c7-fd74-dd71-9cca9302138d",
        "NOMBRE": "INFARTO DEL BAZO"
    },
    {
        "id": "297b6d1d-a582-f4bb-adb5-4c2ecba65ad8",
        "NOMBRE": "INFARTO DEL HÍGADO"
    },
    {
        "id": "ece264f6-d219-b04d-baf7-628bdc38ba99",
        "NOMBRE": "INFARTO ISQUÉMICO DEL MÚSCULO"
    },
    {
        "id": "46384759-1f97-82ad-3623-d4417e7bc598",
        "NOMBRE": "INFARTO SUBENDOCÁRDICO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "fbc44666-4dba-d538-3e90-03d8f6b52e57",
        "NOMBRE": "INFARTO SUBSECUENTE DEL MIOCARDIO"
    },
    {
        "id": "c42e1a82-342d-1d41-eb05-3d1956c19569",
        "NOMBRE": "INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED ANTERIOR"
    },
    {
        "id": "f1a4a918-182f-8043-49f7-33f18d906aac",
        "NOMBRE": "INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED INFERIOR"
    },
    {
        "id": "2afb4d09-9e55-01dd-7991-e56480cde093",
        "NOMBRE": "INFARTO SUBSECUENTE DEL MIOCARDIO DE OTROS SITIOS"
    },
    {
        "id": "40396a78-c725-7822-9d93-43a72e0f78be",
        "NOMBRE": "INFARTO SUBSECUENTE DEL MIOCARDIO, DE PARTE NO ESPECIFICADA"
    },
    {
        "id": "166d888c-8e5a-b1a3-e0e4-e64912624d35",
        "NOMBRE": "INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED ANTERIOR"
    },
    {
        "id": "e7a04809-0bad-c85c-9ed5-7a6d45b84d73",
        "NOMBRE": "INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED INFERIOR"
    },
    {
        "id": "b6512c55-8f79-e894-d173-def143990b0a",
        "NOMBRE": "INFARTO TRANSMURAL AGUDO DEL MIOCARDIO, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "727561c6-4af4-75a2-5de0-4b0f7060108f",
        "NOMBRE": "INFECCIÓN (SUPERINFECCIÓN) AGUDA POR AGENTE DELTA EN LA HEPATITIS B CRÓNICA"
    },
    {
        "id": "ca69d6c3-9bf6-6a6c-0b63-af9f2f1695e9",
        "NOMBRE": "INFECCIÓN AGUDA DE LAS VÍAS RESPIRATORIAS SUPERIORES, NO ESPECIFICADA"
    },
    {
        "id": "507d5a45-ff14-2e77-f572-3e4f8edf1a8a",
        "NOMBRE": "INFECCIÓN AGUDA NO ESPECIFICADA DE LAS VÍAS RESPIRATORIAS INFERIORES"
    },
    {
        "id": "f66ae1ad-7867-e48a-f03a-0de25ece0156",
        "NOMBRE": "INFECCIÓN AMEBIANA DE OTRAS LOCALIZACIONES"
    },
    {
        "id": "b79c8cb1-283b-9310-34b3-ba7f957b7f23",
        "NOMBRE": "INFECCIÓN ANOGENITAL DEBIDA A VIRUS DEL HERPES [HERPES SIMPLE]"
    },
    {
        "id": "9b25d0e0-8a7a-f82d-97c3-d9851b79b940",
        "NOMBRE": "INFECCIÓN ANOGENITAL POR VIRUS DEL HERPES SIMPLE, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c8ec6df6-3983-1777-e100-a594db78628d",
        "NOMBRE": "INFECCIÓN ASOCIADA CON INSEMINACIÓN ARTIFICIAL"
    },
    {
        "id": "73fd60ae-3564-c5df-b712-6e3b92669be3",
        "NOMBRE": "INFECCIÓN BACTERIANA DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "04372131-360e-5180-8b68-9cd0b2adff1a",
        "NOMBRE": "INFECCIÓN BACTERIANA, NO ESPECIFICADA"
    },
    {
        "id": "3cb27da7-5092-b6b0-c849-5c0dcd6f44c4",
        "NOMBRE": "INFECCIÓN CITOMEGALOVÍRICA CONGÉNITA"
    },
    {
        "id": "fa7d032c-7977-e324-c7e8-647bad8510a1",
        "NOMBRE": "INFECCIÓN CONSECUTIVA A INMUNIZACIÓN"
    },
    {
        "id": "186033e5-bb4f-45be-1354-ab859aa5699a",
        "NOMBRE": "INFECCIÓN CONSECUTIVA A PROCEDIMIENTO, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "ebcdae98-2fa7-522c-98cc-2fbaf8bb929b",
        "NOMBRE": "INFECCIÓN CUTÁNEA NEONATAL"
    },
    {
        "id": "73bf8f03-e7e8-e737-6878-ff22107e765a",
        "NOMBRE": "INFECCIÓN CUTÁNEA POR MICOBACTERIAS"
    },
    {
        "id": "ecf34f45-eaae-7fd6-b2c6-bff7e38f3ac0",
        "NOMBRE": "INFECCIÓN DE DISCO INTERVERTEBRAL (PIÓGENA)"
    },
    {
        "id": "6d50d166-e3e9-35d8-9ef4-469483ea8e95",
        "NOMBRE": "INFECCIÓN DE FARINGE DEBIDA A CLAMIDIAS"
    },
    {
        "id": "6caa35d0-14e0-8cc3-37dc-cdd25a06a933",
        "NOMBRE": "INFECCIÓN DE GENITALES Y TRAYECTO UROGENITAL DEBIDA A VIRUS DEL HERPES [HERPES SIMPLE]"
    },
    {
        "id": "3c5662f3-0f09-6cb8-3276-f8b962f8ff86",
        "NOMBRE": "INFECCIÓN DE HERIDA QUIRÚRGICA OBSTÉTRICA"
    },
    {
        "id": "253a9c71-d687-d038-70cb-6439b790acf3",
        "NOMBRE": "INFECCIÓN DE HUESO DEBIDA A ECHINOCOCCUS GRANULOSUS"
    },
    {
        "id": "c8390a05-eacb-d7dc-db94-35fa90289c3a",
        "NOMBRE": "INFECCIÓN DE LA BOLSA AMNIÓTICA O DE LAS MEMBRANAS"
    },
    {
        "id": "fbf1c060-730b-14b5-9487-3741ae43e87a",
        "NOMBRE": "INFECCIÓN DE LA PIEL PERIANAL Y RECTO POR VIRUS DEL HERPES SIMPLE"
    },
    {
        "id": "c450bb2d-e171-56b1-af97-7918d63b6c4f",
        "NOMBRE": "INFECCIÓN DE LA URETRA EN EL EMBARAZO"
    },
    {
        "id": "a178270c-4b4f-7d20-35c1-9dad8ed22cce",
        "NOMBRE": "INFECCIÓN DE LA VEJIGA URINARIA EN EL EMBARAZO"
    },
    {
        "id": "99efac1d-a09f-38b6-934c-ad216c39393a",
        "NOMBRE": "INFECCIÓN DE LAS VÍAS GENITOURINARIAS EN EL EMBARAZO"
    },
    {
        "id": "d4d4337f-3d2d-f021-d450-e9f49cb54b67",
        "NOMBRE": "INFECCIÓN DE LAS VÍAS URINARIAS CONSECUTIVA AL PARTO"
    },
    {
        "id": "c9b67a2a-5d4a-7b07-1f02-7eb0b484a872",
        "NOMBRE": "INFECCIÓN DE MUÑÓN DE AMPUTACIÓN"
    },
    {
        "id": "fc802957-ba9b-29a9-f49d-db789fd41146",
        "NOMBRE": "INFECCIÓN DE OTRAS PARTES DE LAS VÍAS URINARIAS EN EL EMBARAZO"
    },
    {
        "id": "d638065e-254a-abef-d0f0-cf74b37a5289",
        "NOMBRE": "INFECCIÓN DE OTRO ÓRGANO Y DE SITIOS MÚLTIPLES DEBIDA A ECHINOCOCCUS GRANULOSUS"
    },
    {
        "id": "8d655d1b-02c0-1a26-7cde-586112b79a10",
        "NOMBRE": "INFECCIÓN DE OTRO ÓRGANO Y DE SITIOS MÚLTIPLES DEBIDA A ECHINOCOCCUS MULTILOCULARIS"
    },
    {
        "id": "ced1e372-3481-6f0e-736d-1961a06cea66",
        "NOMBRE": "INFECCIÓN DE TRANSMISIÓN SEXUAL DE OTROS SITIOS DEBIDA A CLAMIDIAS"
    },
    {
        "id": "89e3df12-f375-54b6-9b53-ea1753f6e2ab",
        "NOMBRE": "INFECCIÓN DE VÍAS URINARIAS, SITIO NO ESPECIFICADO"
    },
    {
        "id": "5c5aa6ef-79af-0311-3558-17b63d981767",
        "NOMBRE": "INFECCIÓN DEBIDA A ADENOVIRUS, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "3b50d6ee-76bf-fd78-1d9b-94113dd73cdf",
        "NOMBRE": "INFECCION DEBIDA A CHLAMYDIA PSITTACI"
    },
    {
        "id": "726b5d9b-cc59-8e67-9353-9676738c3abd",
        "NOMBRE": "INFECCIÓN DEBIDA A CORONAVIRUS, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "5f7afaec-e8fa-9e5b-5693-0485a1913a62",
        "NOMBRE": "INFECCIÓN DEBIDA A ECHINOCOCCUS GRANULOSUS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c79e654a-0c4a-031e-25e7-3abec5b120c4",
        "NOMBRE": "INFECCIÓN DEBIDA A ECHINOCOCCUS MULTILOCULARIS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "7e229063-b5e7-e050-ad16-0ab076af21dd",
        "NOMBRE": "INFECCIÓN DEBIDA A ENTEROVIRUS, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "58b19d75-8721-8743-6d36-8eb05bf279ad",
        "NOMBRE": "INFECCIÓN DEBIDA A ESCHERICHIA COLI ENTEROHEMORRÁGICA"
    },
    {
        "id": "edf82ba1-bdc4-54de-a1b7-509439fb5df3",
        "NOMBRE": "INFECCIÓN DEBIDA A ESCHERICHIA COLI ENTEROINVASIVA"
    },
    {
        "id": "3b87019b-a3bf-6ecc-96ef-2ab9e76ffd53",
        "NOMBRE": "INFECCIÓN DEBIDA A ESCHERICHIA COLI ENTEROPATÓGENA"
    },
    {
        "id": "199f6382-e934-0ff0-1832-ea0b554b59b1",
        "NOMBRE": "INFECCIÓN DEBIDA A ESCHERICHIA COLI ENTEROTOXÍGENA"
    },
    {
        "id": "c30976b9-5b72-43d8-ddcf-005a3045f45e",
        "NOMBRE": "INFECCIÓN DEBIDA A HISTOPLASMA DUBOISII"
    },
    {
        "id": "2093b97b-33bb-686d-a29f-75cdf9e5d33e",
        "NOMBRE": "INFECCIÓN DEBIDA A PAPOVAVIRUS,DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "e9e75e8b-2764-9be6-beeb-21af608dd1ea",
        "NOMBRE": "INFECCIÓN DEBIDA A PARVOVIRUS, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "093a5dcf-7da5-8b9b-c870-6cb9505ea788",
        "NOMBRE": "INFECCIÓN DEBIDA A SALMONELLA, NO ESPECIFICADA"
    },
    {
        "id": "c6ef4080-76e7-fbef-e797-3e48b8e757fe",
        "NOMBRE": "INFECCIÓN DEBIDA A TAENIA SAGINATA"
    },
    {
        "id": "de37b5b4-b218-1c63-3609-af26681ee084",
        "NOMBRE": "INFECCIÓN DEBIDA A TREMATODOS, NO ESPECIFICADA"
    },
    {
        "id": "eb36d123-30fd-ad0e-4018-955996133dc6",
        "NOMBRE": "INFECCIÓN DEBIDA AL VIRUS DEL HERPES, NO ESPECIFICADA"
    },
    {
        "id": "5188b025-7c40-f8c1-710c-ba2a7519d35a",
        "NOMBRE": "INFECCIÓN DEL ANO Y DEL RECTO DEBIDA A CLAMIDIAS"
    },
    {
        "id": "fc1af92c-3b26-dac8-837e-83c5ce9568ab",
        "NOMBRE": "INFECCIÓN DEL HÍGADO DEBIDA A ECHINOCOCCUS GRANULOSUS"
    },
    {
        "id": "970b699f-8789-2df8-c815-abf55708815f",
        "NOMBRE": "INFECCIÓN DEL HÍGADO DEBIDA A ECHINOCOCCUS MULTILOCULARIS"
    },
    {
        "id": "0a6b1c5b-56fd-98dc-99bd-d588e73ad824",
        "NOMBRE": "INFECCIÓN DEL PELVIPERITONEO Y OTROS ÓRGANOS GENITOURINARIOS DEBIDA A CLAMIDIAS"
    },
    {
        "id": "b71b7922-2997-f6a5-263b-1dea7971302f",
        "NOMBRE": "INFECCIÓN DEL PULMÓN DEBIDA A ECHINOCOCCUS GRANULOSUS"
    },
    {
        "id": "ab575bc2-6433-e7a8-fd01-a9cd9520450c",
        "NOMBRE": "INFECCIÓN DEL RIÑÓN EN EL EMBARAZO"
    },
    {
        "id": "dd9c082f-8f6d-c1cd-627e-285bfbd223dc",
        "NOMBRE": "INFECCIÓN DEL TRACTO GENITOURINARIO INFERIOR DEBIDA A CLAMIDIAS"
    },
    {
        "id": "5b650686-c90d-f7a5-2eb4-dffd7dcb1b38",
        "NOMBRE": "INFECCIÓN DISEMINADA DEBIDA A HISTOPLASMA CAPSULATUM"
    },
    {
        "id": "e84bccc5-a098-353f-4c42-16712d2c363d",
        "NOMBRE": "INFECCIÓN E INFESTACIÓN PARASITARIAS DE LA ÓRBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "24a7498f-d94a-2718-fede-cdae06d68435",
        "NOMBRE": "INFECCIÓN ESTAFILOCÓCICA, SITIO NO ESPECIFICADO"
    },
    {
        "id": "68994824-797e-9f2c-9938-cd1223654f43",
        "NOMBRE": "INFECCIÓN ESTREPTOCÓCICA Y ENTEROCÓCICA DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "2b152b3d-d897-5bc8-b4f7-5e3f828842d2",
        "NOMBRE": "INFECCIÓN FILÁRICA DE LA CONJUNTIVA"
    },
    {
        "id": "43d4226a-103a-34bb-c7b0-631137f076f9",
        "NOMBRE": "INFECCIÓN GENITAL EN EL EMBARAZO"
    },
    {
        "id": "52f60dc8-726b-a858-7f83-68ed91ee4591",
        "NOMBRE": "INFECCIÓN GENITAL Y PELVIANA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "37f26608-8888-ed4c-e6f4-4e981f0286f6",
        "NOMBRE": "INFECCIÓN GONOCÓCICA"
    },
    {
        "id": "aca315f4-1430-2b8b-1768-6f3a65c23387",
        "NOMBRE": "INFECCIÓN GONOCÓCICA DEL ANO Y DEL RECTO"
    },
    {
        "id": "10d54225-d602-f661-704b-4d0710bd91f7",
        "NOMBRE": "INFECCIÓN GONOCÓCICA DEL OJO"
    },
    {
        "id": "65d1132d-83c8-d371-c176-f2b82611f652",
        "NOMBRE": "INFECCIÓN GONOCÓCICA DEL SISTEMA OSTEOMUSCULAR"
    },
    {
        "id": "735a423a-e6ef-69d5-dbc7-75b085b1305c",
        "NOMBRE": "INFECCIÓN GONOCÓCICA DEL TRACTO GENITOURINARIO INFERIOR CON ABSCESO PERIURETRAL Y DE GLÁNDULAS ACCESORIAS"
    },
    {
        "id": "8bd0b609-ed78-2cf9-0a78-d98f4da167e5",
        "NOMBRE": "INFECCIÓN GONOCÓCICA DEL TRACTO GENITOURINARIO INFERIOR SIN ABSCESO PERIURETRAL Y DE GLÁNDULA ACCESORIA"
    },
    {
        "id": "65e6cca8-4140-c5a6-716a-b318c0320736",
        "NOMBRE": "INFECCIÓN GONOCÓCICA, NO ESPECIFICADA"
    },
    {
        "id": "8240eed4-77da-b9bb-93f0-68ecdcbc9045",
        "NOMBRE": "INFECCIÓN INTESTINAL BACTERIANA, NO ESPECIFICADA"
    },
    {
        "id": "98c0fd62-f8d6-221c-dff4-79ff2f7e8c58",
        "NOMBRE": "INFECCIÓN INTESTINAL VIRAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "16b9d592-10aa-e7b4-dcee-0a65854f9d14",
        "NOMBRE": "INFECCIÓN INTRAAMNIÓTICA DEL FETO, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "f3b4eab0-c3b9-4bfe-e6c4-c284a580d368",
        "NOMBRE": "INFECCIÓN LOCAL DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO, NO ESPECIFICADA"
    },
    {
        "id": "62aed4d3-7208-ed03-92d1-abe887974aa5",
        "NOMBRE": "INFECCIÓN NEONATAL DE LAS VÍAS URINARIAS"
    },
    {
        "id": "cf09e148-4a4c-3fdb-8a4f-67a3e949be79",
        "NOMBRE": "INFECCIÓN NO ESPECIFICADA DE LAS VÍAS URINARIAS EN EL EMBARAZO"
    },
    {
        "id": "5edc5855-54f8-24c0-13a4-acdff08220d3",
        "NOMBRE": "INFECCIÓN O INFESTACIÓN PARASITARIA DEL PÁRPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7f88459a-cbe2-6219-6f1e-373b51e11368",
        "NOMBRE": "INFECCIÓN POR CLAMIDIAS, NO ESPECIFICADA"
    },
    {
        "id": "e6fcbf21-3753-9596-91d0-3be9056a1fd2",
        "NOMBRE": "INFECCIÓN POR ESPIROQUETA, NO ESPECIFICADA"
    },
    {
        "id": "ccd180b3-ae90-a676-2130-5b2dd91e793c",
        "NOMBRE": "INFECCIÓN POR HAEMOPHILUS INFLUENZAE, SITIO NO ESPECIFICADO"
    },
    {
        "id": "2b39303f-a2ad-ae5c-191b-29232b52eb66",
        "NOMBRE": "INFECCIÓN POR MICOBACTERIA, NO ESPECIFICADA"
    },
    {
        "id": "13d0de47-1e99-3ef9-bbeb-8f17d9f630a4",
        "NOMBRE": "INFECCIÓN POR MICOPLASMA, SITIO NO ESPECIFICADO"
    },
    {
        "id": "0e55b4d4-82a6-0d57-0eb8-6795ab8779b3",
        "NOMBRE": "INFECCIÓN POR VIRUS DEL OESTE DEL NILO"
    },
    {
        "id": "150c967b-9435-c5bd-6685-a243a063ffd5",
        "NOMBRE": "INFECCIÓN POSTRAUMÁTICA DE HERIDA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "3c38d91e-e367-a89c-15b0-a7734ba663f5",
        "NOMBRE": "INFECCIÓN PROPIA DEL PERÍODO PERINATAL, NO ESPECIFICADA"
    },
    {
        "id": "3ba3e94c-cf34-19b7-142a-a23a97d5ed43",
        "NOMBRE": "INFECCIÓN PULMONAR AGUDA DEBIDA A HISTOPLASMA CAPSULATUM"
    },
    {
        "id": "299f5e7d-f706-8267-3728-9e91f284bc35",
        "NOMBRE": "INFECCIÓN PULMONAR CRÓNICA DEBIDA A HISTOPLASMA CAPSULATUM"
    },
    {
        "id": "fd73a86f-7c8f-62a2-dda2-e8e9610d1b23",
        "NOMBRE": "INFECCIÓN PULMONAR DEBIDA A HISTOPLASMA CAPSULATUM, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "aba4d5ee-e113-a8e5-3e63-35451bbe791d",
        "NOMBRE": "INFECCIÓN TUBERCULOSA DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "cc1f556c-9645-ed0b-d139-52a3121700e6",
        "NOMBRE": "INFECCIÓN VIRAL DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "45301d85-1280-d203-bb21-2cef97fd6003",
        "NOMBRE": "INFECCIÓN VIRAL DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADA"
    },
    {
        "id": "405a04a3-ed40-3f94-7755-18cd69001323",
        "NOMBRE": "INFECCIÓN VIRAL NO ESPECIFICADA, CARACTERIZADA POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS"
    },
    {
        "id": "cc01f288-d3a2-44f5-6420-41ed803358b2",
        "NOMBRE": "INFECCIÓN VIRAL, NO ESPECIFICADA"
    },
    {
        "id": "251bd504-2d7b-9124-ad44-53090c30a717",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A DISPOSITIVO DE FIJACIÓN INTERNA [CUALQUIER SITIO]"
    },
    {
        "id": "aeaadf75-dc7a-53c9-cbf7-9cdd805dd0b6",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A DISPOSITIVO PROTÉSICO, IMPLANTE E INJERTO EN EL SISTEMA URINARIO"
    },
    {
        "id": "c52e7efe-b66f-0d6a-e035-2d68415e7c86",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A DISPOSITIVO PROTÉSICO, IMPLANTE E INJERTO EN EL TRACTO GENITAL"
    },
    {
        "id": "f68e0704-e2d4-2b45-2ecd-c3cc57f117b2",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A OTROS DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS INTERNOS"
    },
    {
        "id": "836423fa-1502-a403-9b72-ad24d7f5bc4e",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A OTROS DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS ORTOPÉDICOS INTERNOS"
    },
    {
        "id": "5e1e959d-c85e-44dd-a453-30beb45ae620",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A OTROS DISPOSITIVOS, IMPLANTES E INJERTOS CARDIOVASCULARES"
    },
    {
        "id": "a800d8c0-0a92-daf9-3e79-6cf3b05ec31f",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A PRÓTESIS ARTICULAR INTERNA"
    },
    {
        "id": "7049e3d5-1ef1-fc18-e59f-c88fdb625089",
        "NOMBRE": "INFECCIÓN Y REACCIÓN INFLAMATORIA DEBIDAS A PRÓTESIS DE VÁLVULA CARDÍACA"
    },
    {
        "id": "a38a2324-0e61-04e5-53fc-5baeb4263c25",
        "NOMBRE": "INFECCIONES AGUDAS DE LAS VÍAS RESPIRATORIAS SUPERIORES, DE SITIOS MÚLTIPLES O NO ESPECIFICADOS"
    },
    {
        "id": "4dd17e58-483b-237b-3e1a-e23d80d845e1",
        "NOMBRE": "INFECCIONES CONGÉNITAS POR VIRUS DEL HERPES SIMPLE"
    },
    {
        "id": "eddd5947-7435-e8a9-d865-a394e725ad76",
        "NOMBRE": "INFECCIONES CONSECUTIVAS A INFUSIÓN, TRANSFUSIÓN E INYECCIÓN TERAPÉUTICA"
    },
    {
        "id": "8fca7ab6-49d3-ce87-9c80-0a2433648041",
        "NOMBRE": "INFECCIONES DE LA MAMA ASOCIADAS CON EL PARTO"
    },
    {
        "id": "3aed8d4f-d62d-eba1-5553-bf533dba9a4e",
        "NOMBRE": "INFECCIONES DEBIDAS A CESTODOS, NO ESPECIFICADA"
    },
    {
        "id": "3a824c32-7cab-1ef7-a96d-22d820a242f1",
        "NOMBRE": "INFECCIONES DEBIDAS A OTRAS MICOBACTERIAS"
    },
    {
        "id": "a3e90fab-bd82-0104-0ea6-b6c494587687",
        "NOMBRE": "INFECCIONES DEBIDAS A RETROVIRUS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "61ad8f26-e272-39cd-ad5d-390f4e70a49f",
        "NOMBRE": "INFECCIONES DEL PEZÓN ASOCIADOS CON EL PARTO"
    },
    {
        "id": "cd131732-a0dd-7b05-32c4-51ea90749ed9",
        "NOMBRE": "INFECCIONES DEL SISTEMA NERVIOSO CENTRAL POR VIRUS ATÍPICO"
    },
    {
        "id": "b8f2403a-29ec-4557-bbcd-9a03c64d487a",
        "NOMBRE": "INFECCIONES DEL SISTEMA NERVIOSO CENTRAL POR VIRUS ATÍPICO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "4664f0b8-03b5-4e79-f58d-9e04810f9733",
        "NOMBRE": "INFECCIONES DEL TRACTO GENITOURINARIO DEBIDAS A CLAMIDIAS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "759b3967-5df9-0502-e9fe-1c3dd95c28d1",
        "NOMBRE": "INFECCIONES DIRECTAS DE LA ARTICULACIÓN EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b897879d-505d-72f6-e57b-1f1b7baa59f6",
        "NOMBRE": "INFECCIONES HERPÉTICA [HERPES SIMPLE]"
    },
    {
        "id": "a06b0d00-ee9a-41a5-f93f-52b464cc27a5",
        "NOMBRE": "INFECCIONES INTESTINALES DEBIDAS A VIRUS Y OTROS ORGANISMOS ESPECIFICADOS"
    },
    {
        "id": "09181e84-93f7-2ed4-0cee-281d6b39896d",
        "NOMBRE": "INFECCIONES LOCALIZADAS DEBIDAS A SALMONELLA"
    },
    {
        "id": "2c220a38-a942-b44d-8072-b60b7cee0c00",
        "NOMBRE": "INFECCIONES POR MICOBACTERIAS PULMONARES"
    },
    {
        "id": "93daa970-33c8-74ff-2598-2efc29b87f5d",
        "NOMBRE": "INFECCIONES RESPIRATORIAS, NO CLASIFICADAS EN OTRO SITIO"
    },
    {
        "id": "64446e98-4dd3-49b2-a188-a7e33ed6dc74",
        "NOMBRE": "INFELICIDAD"
    },
    {
        "id": "1e8a1875-0621-6702-14c5-bd0323121eb3",
        "NOMBRE": "INFERTILIDAD FEMENINA"
    },
    {
        "id": "e5a40863-3b7d-bf36-8bd9-1585246fee55",
        "NOMBRE": "INFERTILIDAD FEMENINA ASOCIADA CON FACTORES MASCULINOS"
    },
    {
        "id": "d133c792-9f22-c46a-a984-404d7c8f3778",
        "NOMBRE": "INFERTILIDAD FEMENINA ASOCIADA CON FALTA DE OVULACIÓN"
    },
    {
        "id": "335cddd5-c71e-613c-1888-b4fd1c06ff99",
        "NOMBRE": "INFERTILIDAD FEMENINA DE ORIGEN CERVICAL"
    },
    {
        "id": "3433607e-9003-a669-d616-b9ae1946f863",
        "NOMBRE": "INFERTILIDAD FEMENINA DE ORIGEN TUBÁRICO"
    },
    {
        "id": "0005d1ef-7e9e-0dea-d1d5-a7cff3970cef",
        "NOMBRE": "INFERTILIDAD FEMENINA DE ORIGEN UTERINO"
    },
    {
        "id": "cc464c28-0651-45ea-fcb9-8f609db77d4c",
        "NOMBRE": "INFERTILIDAD FEMENINA DE OTRO ORIGEN"
    },
    {
        "id": "a764f292-ca53-526b-a252-32ea4da6e68e",
        "NOMBRE": "INFERTILIDAD FEMENINA, NO ESPECIFICADA"
    },
    {
        "id": "f0e226da-1185-c481-b75b-80da102773b7",
        "NOMBRE": "INFESTACIÓN, NO ESPECIFICADA"
    },
    {
        "id": "f2a6e3c2-3e92-ae1c-f8a3-de9f0f98ee8d",
        "NOMBRE": "INFLAMACIÓN AGUDA DE LA ÓRBITA"
    },
    {
        "id": "f9c2f25c-c1d7-c60d-0ab5-0847647ceeaf",
        "NOMBRE": "INFLAMACIÓN AGUDA Y LA NO ESPECIFICADA DE LAS VÍAS LAGRIMALES"
    },
    {
        "id": "66dd921f-21ea-fcd5-52b4-b605a77d974a",
        "NOMBRE": "INFLAMACIÓN CORIORRETINIANA"
    },
    {
        "id": "b09fb229-a95e-e2b8-ff1d-dfd56e3d9914",
        "NOMBRE": "INFLAMACIÓN CORIORRETINIANA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7955088f-1100-afe7-92e4-36451be7e1a4",
        "NOMBRE": "INFLAMACIÓN CRÓNICA DE LAS VÍAS LAGRIMALES"
    },
    {
        "id": "78677331-4626-ef44-8a2d-35b0733b1c90",
        "NOMBRE": "INFLAMACIÓN DEL PÁRPADO, NO ESPECIFICADA"
    },
    {
        "id": "e68f6ae3-7787-b660-f72b-e31855300c9c",
        "NOMBRE": "INFLAMACIÓN RESPIRATORIA SUPERIOR DEBIDA A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "342a5f16-b2d5-3b56-e23d-3113051fbd3c",
        "NOMBRE": "INFLAMACIÓN Y OBSTRUCCIÓN DE LA TROMPA DE EUSTAQUIO"
    },
    {
        "id": "ccd54b07-d7ed-60d2-f0ca-582d0586bde3",
        "NOMBRE": "INFLUENZA CON NEUMONÍA, DEBIDA A OTRO VIRUS DE LA INFLUENZA ESTACIONAL IDENTIFICADO"
    },
    {
        "id": "77b809e4-a856-3aa0-f5b9-dad59604928c",
        "NOMBRE": "INFLUENZA CON NEUMONÍA, VIRUS NO IDENTIFICADO"
    },
    {
        "id": "d8b5eea0-20d9-ca85-caf9-caf37ea33f66",
        "NOMBRE": "INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, DEBIDA A VIRUS DE LA INFLUENZA ESTACIONAL IDENTIFICADO"
    },
    {
        "id": "0bdfd35e-578b-97fc-af9e-60d0fa0058b3",
        "NOMBRE": "INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, VIRUS NO IDENTIFICADO"
    },
    {
        "id": "87a65d45-bfe5-56bb-1a96-fcccf8486680",
        "NOMBRE": "INFLUENZA CON OTRAS MANIFESTACIONES, VIRUS NO IDENTIFICADO"
    },
    {
        "id": "c058a351-da3d-0619-7aa6-4fae399d674a",
        "NOMBRE": "INFLUENZA DEBIDA A VIRUS DE LA INFLUENZA ESTACIONAL IDENTIFICADO"
    },
    {
        "id": "3c913ded-375f-703c-965e-08874636cd6e",
        "NOMBRE": "INFLUENZA DEBIDA A VIRUS DE LA INFLUENZA ZOONÓTICO O PANDÉMICO IDENTIFICADOS"
    },
    {
        "id": "f2e9b15a-0486-f8ac-92f8-6377bb3f4e48",
        "NOMBRE": "INFLUENZA DEBIDA A VIRUS NO IDENTIFICADO"
    },
    {
        "id": "9447d031-14d9-8230-f277-a4cf5569a032",
        "NOMBRE": "INFLUENZA, CON OTRAS MANIFESTACIONES, DEBIDA A VIRUS DE LA INFLUENZA ESTACIONAL IDENTIFICADO"
    },
    {
        "id": "55e7c9c8-c267-4194-cccf-2c028192a548",
        "NOMBRE": "INGESTA INSUFICIENTE DE ALIMENTOS Y AGUA DEBIDA A DESCUIDO PERSONAL"
    },
    {
        "id": "764dc4d4-d075-c5b6-7cfd-0c47a228a9ed",
        "NOMBRE": "INGURGITACIÓN MAMARIA DEL RECIÉN NACIDO"
    },
    {
        "id": "958e4153-3bc2-4093-ca4f-082819fb81ff",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS"
    },
    {
        "id": "6bae6a40-0ecb-256d-36ee-f8979a7f11cf",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "e0a729fb-6dbf-96d4-2817-45c8cba036a4",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c1994839-fbb3-a29c-a84f-e7a13a63f8b4",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, CALLES Y CARRETERAS"
    },
    {
        "id": "eb4c6be2-45fd-e7dd-fbf2-0dacfc988957",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "3ce62018-3d11-f1fe-e15a-e0dc5eba1bf8",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "95ff0630-2e09-da92-1336-6f4b0227e5c0",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, GRANJA"
    },
    {
        "id": "62b39534-4fda-ee71-2877-30c04b40d41d",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "fb421279-e4c1-caca-ed5d-cc1e6a3a622a",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c1eb22de-e72a-2694-b8e3-26f6019024a5",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ddd29023-8826-532e-d675-56c29596372d",
        "NOMBRE": "INHALACIÓN DE CONTENIDOS GÁSTRICOS, VIVIENDA"
    },
    {
        "id": "8e55443a-2ed9-93b8-2cbf-f9ea5b23b4d0",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "d3735cc5-5082-525a-a626-10ba43106332",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "eed360f9-6ab3-b0c9-3d63-2ff256ab8bc4",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f862a2fd-8635-204b-8307-789f8b7138ba",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, CALLES Y CARRETERAS"
    },
    {
        "id": "d5aef158-99ad-70d1-0602-180a602b13d1",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1c5e812c-3842-1893-d160-9a79377797ca",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2e133ff2-2e38-d03f-b7ce-a59a76e4925c",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, GRANJA"
    },
    {
        "id": "4c0242d5-7b33-036c-860e-3e75d135ffbc",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "5f98fca7-e2e5-68d2-d402-987d9343fced",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a34bf1f1-3d0b-0178-ce97-e881b1e79d0d",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "9b035f98-e5ac-8160-9fe8-57a7fbcbe9ff",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE ALIMENTO QUE CAUSA OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, VIVIENDA"
    },
    {
        "id": "d1395bee-fc8b-4fad-3149-b192e9392d23",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "f6687b88-c817-cba6-5812-00a29a90ff32",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "172f63c3-d955-a232-7e14-99449da1a3da",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "6a214fcb-b6b1-9495-b692-d6e18ecef80b",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, CALLES Y CARRETERAS"
    },
    {
        "id": "5a2da6d9-4473-78cb-5ae2-c20171ab6708",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a3d83acd-c4bf-d59f-8f6d-a430732a0cbf",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, ESCUELAS, OTRAS INSTITUCIÓN Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b919e3e6-e0bc-9f35-6966-b2374a91f17d",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, GRANJA"
    },
    {
        "id": "587fc077-e8bc-59b0-2010-59fc51fc5eb1",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cf737c81-99bf-6cb6-7822-31783ee1a771",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a30f6dd0-bcfd-9ddc-b99e-97ab68b21f8c",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a36448cc-e468-4312-dae8-b494ec2ddb97",
        "NOMBRE": "INHALACIÓN E INGESTIÓN DE OTROS OBJETOS QUE CAUSAN OBSTRUCCIÓN DE LAS VÍAS RESPIRATORIAS, VIVIENDA"
    },
    {
        "id": "10f9f7c3-3cea-a3b4-2c99-6aa2728961a9",
        "NOMBRE": "INIENCEFALIA"
    },
    {
        "id": "e655f746-753d-74e1-b4c9-42ec1e43b855",
        "NOMBRE": "INMATURIDAD EXTREMA"
    },
    {
        "id": "9031c10d-b1fb-8596-7292-cb4658e9a203",
        "NOMBRE": "INMOVILIDAD"
    },
    {
        "id": "7812dad4-c36e-04da-b729-65e229315cd4",
        "NOMBRE": "INMUNIZACIÓN NO REALIZADA"
    },
    {
        "id": "1461f23e-3337-4adb-0742-a935120159d8",
        "NOMBRE": "INMUNIZACIÓN NO REALIZADA POR CONTRAINDICACIÓN"
    },
    {
        "id": "936bd1ee-ef68-4d84-0ca8-1b881019983a",
        "NOMBRE": "INMUNIZACIÓN NO REALIZADA POR DECISIÓN DEL PACIENTE, POR MOTIVOS DE CREENCIA O PRESIÓN DE GRUPO"
    },
    {
        "id": "8f5015cf-ceba-b41d-0a86-388a68c2d1ab",
        "NOMBRE": "INMUNIZACIÓN NO REALIZADA POR DECISIÓN DEL PACIENTE, POR OTRAS RAZONES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "803e327a-0b56-2436-eaf1-cdfe2c963731",
        "NOMBRE": "INMUNIZACIÓN NO REALIZADA POR OTRAS RAZONES"
    },
    {
        "id": "debc451d-0e91-38b8-179b-15823db9a25c",
        "NOMBRE": "INMUNIZACIÓN NO REALIZADA POR RAZÓN NO ESPECIFICADA"
    },
    {
        "id": "245b8292-8581-a7c6-dabe-f5b63cb81b4b",
        "NOMBRE": "INMUNODEFICIENCIA ASOCIADA CON DEFECTOS MAYORES NO ESPECIFICADOS"
    },
    {
        "id": "5039eed4-68fe-506f-ab11-6689a3a11939",
        "NOMBRE": "INMUNODEFICIENCIA ASOCIADA CON OTROS DEFECTOS MAYORES"
    },
    {
        "id": "1fc4d1ed-9d3c-400a-f48c-17d9fcdc72d0",
        "NOMBRE": "INMUNODEFICIENCIA ASOCIADA CON OTROS DEFECTOS MAYORES ESPECIFICADOS"
    },
    {
        "id": "a81f3cc8-8dae-7e13-3912-041e97463b06",
        "NOMBRE": "INMUNODEFICIENCIA COMBINADA SEVERA [IDCS] CON CIFRA BAJA O NORMAL DE LINFOCITOS B"
    },
    {
        "id": "ea40e806-6fd6-4aca-cd01-792342ec9d57",
        "NOMBRE": "INMUNODEFICIENCIA COMBINADA SEVERA [IDCS] CON DISGENESIA RETICULAR"
    },
    {
        "id": "57363827-b247-54b4-b2b9-6038bce799fe",
        "NOMBRE": "INMUNODEFICIENCIA COMBINADA SEVERA [IDCS] CON LINFOCITOPENIA T Y B"
    },
    {
        "id": "77a38d84-3424-6325-d23a-93bf65a79c55",
        "NOMBRE": "INMUNODEFICIENCIA COMBINADA, NO ESPECIFICADA"
    },
    {
        "id": "bebac675-f9b8-59ba-a618-cc4e3f08d463",
        "NOMBRE": "INMUNODEFICIENCIA CON ENANISMO MICROMÉLICO [MIEMBROS CORTOS]"
    },
    {
        "id": "24af1f6f-aa9c-e8fa-cd84-3c6a9b9912f5",
        "NOMBRE": "INMUNODEFICIENCIA CON INCREMENTO DE INMUNOGLOBULINA M [IGM]"
    },
    {
        "id": "c9ce9e6f-e786-1f50-7a0f-69cf4ee1bf37",
        "NOMBRE": "INMUNODEFICIENCIA CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS"
    },
    {
        "id": "88088716-870e-aa2b-3f10-1b43bddfec72",
        "NOMBRE": "INMUNODEFICIENCIA CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS, NO ESPECIFICADA"
    },
    {
        "id": "25b77cbc-5b85-22d3-3550-2b42fa01ca8a",
        "NOMBRE": "INMUNODEFICIENCIA CONSECUTIVA A RESPUESTA DEFECTUOSA HEREDITARIA CONTRA EL VIRUS DE EPSTEIN-BARR"
    },
    {
        "id": "881f589c-0074-2597-9360-5c8979630d62",
        "NOMBRE": "INMUNODEFICIENCIA VARIABLE COMÚN"
    },
    {
        "id": "079bae15-8cf6-a39e-711b-a98bbb197a2f",
        "NOMBRE": "INMUNODEFICIENCIA VARIABLE COMÚN CON AUTOANTICUERPOS ANTI-B O ANTI-T"
    },
    {
        "id": "2ab5b0fb-9677-3975-e45d-cad3e9b556e8",
        "NOMBRE": "INMUNODEFICIENCIA VARIABLE COMÚN CON PREDOMINIO DE ANORMALIDADES EN EL NÚMERO Y LA FUNCIÓN DE LOS LINFOCITOS B"
    },
    {
        "id": "f7c97aba-91e5-68e2-e416-876aec9c8096",
        "NOMBRE": "INMUNODEFICIENCIA VARIABLE COMÚN CON PREDOMINIO DE TRASTORNOS INMUNORREGULADORES DE LOS LINFOCITOS T"
    },
    {
        "id": "ba20bba3-3ba5-9efe-9f60-fa9f281187e6",
        "NOMBRE": "INMUNODEFICIENCIA VARIABLE COMÚN, NO ESPECIFICADA"
    },
    {
        "id": "090f460e-bd22-6744-bd96-96daa6d4735c",
        "NOMBRE": "INMUNODEFICIENCIA, NO ESPECIFICADA"
    },
    {
        "id": "0deff095-3c3c-6ac6-6e10-43c75750e139",
        "NOMBRE": "INMUNODEFICIENCIAS COMBINADAS"
    },
    {
        "id": "7e62b55b-d859-a4a8-a1cb-9806247f532d",
        "NOMBRE": "INMUNOTERAPIA PROFILÁCTICA"
    },
    {
        "id": "cc16f5f8-a660-f5cc-5344-85da8b356873",
        "NOMBRE": "INQUIETUD Y AGITACIÓN"
    },
    {
        "id": "e36848d4-2d0d-a43a-c767-69248a576e2e",
        "NOMBRE": "INSEMINACIÓN ARTIFICIAL"
    },
    {
        "id": "79ac6340-0074-d1f2-f4e6-f4ead00a35c2",
        "NOMBRE": "INSERCIÓN DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)"
    },
    {
        "id": "338272b4-e3b8-0998-ed6d-6d94ac96d820",
        "NOMBRE": "INSERCIÓN DE SONDA GÁSTRICA O DUODENAL"
    },
    {
        "id": "13e563bc-6af7-4044-fe57-a640f5696805",
        "NOMBRE": "INSOMNIO NO ORGÁNICO"
    },
    {
        "id": "9940d95f-63a8-5e4f-f692-f50d2597b57f",
        "NOMBRE": "INSUFICIENCIA (DE LA VÁLVULA) AÓRTICA"
    },
    {
        "id": "09c6c891-c2b8-8128-4ebd-e43604398a51",
        "NOMBRE": "INSUFICIENCIA (DE LA VÁLVULA) MITRAL"
    },
    {
        "id": "0ebdaac6-c812-e1a7-6fe8-29fd80d01957",
        "NOMBRE": "INSUFICIENCIA AÓRTICA REUMÁTICA"
    },
    {
        "id": "121cc8cb-bee6-9b53-344b-42b672b8ab04",
        "NOMBRE": "INSUFICIENCIA CARDÍACA"
    },
    {
        "id": "75c0db45-02b0-32ff-42e5-dfc676031094",
        "NOMBRE": "INSUFICIENCIA CARDÍACA CONGESTIVA"
    },
    {
        "id": "a5224cb3-4671-3936-3987-4efe28c66b10",
        "NOMBRE": "INSUFICIENCIA CARDÍACA NEONATAL"
    },
    {
        "id": "5193be7a-0ca9-a89e-e5a2-415a54764207",
        "NOMBRE": "INSUFICIENCIA CARDÍACA, NO ESPECIFICADA"
    },
    {
        "id": "199c2224-a94c-c09a-3bf6-035a7fb2b183",
        "NOMBRE": "INSUFICIENCIA CONGÉNITA DE LA VÁLVULA AÓRTICA"
    },
    {
        "id": "ed9c006a-ee6a-7ea5-2b2c-b4b9b5b9907b",
        "NOMBRE": "INSUFICIENCIA CONGÉNITA DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "71271aa9-f525-afbb-9ad3-d192aa99c575",
        "NOMBRE": "INSUFICIENCIA CORTICOSUPRARRENAL INDUCIDA POR DROGAS"
    },
    {
        "id": "d8e4b5f9-b43c-2283-6d3e-131b901b562b",
        "NOMBRE": "INSUFICIENCIA CORTICOSUPRARRENAL PRIMARIA"
    },
    {
        "id": "2539428d-0476-9a9d-33cf-286eb35dd32f",
        "NOMBRE": "INSUFICIENCIA DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "bb7b33af-39ac-7162-4bce-568fea0f6abe",
        "NOMBRE": "INSUFICIENCIA HEPÁTICA AGUDA O SUBAGUDA"
    },
    {
        "id": "1b8a04da-c427-6041-6d3d-1dd2fb9b42ce",
        "NOMBRE": "INSUFICIENCIA HEPÁTICA ALCOHÓLICA"
    },
    {
        "id": "7ef99db8-90ec-7360-e117-cc5c236293c6",
        "NOMBRE": "INSUFICIENCIA HEPÁTICA CRÓNICA"
    },
    {
        "id": "49f3b1cc-047e-98ff-12c7-2a6a97d095c5",
        "NOMBRE": "INSUFICIENCIA HEPÁTICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "f6470fdb-da40-e979-87a2-e10c95590fd6",
        "NOMBRE": "INSUFICIENCIA HEPÁTICA, NO ESPECIFICADA"
    },
    {
        "id": "c2a0e4e6-fcd9-1d59-cc82-f92c0905b6dc",
        "NOMBRE": "INSUFICIENCIA MITRAL CONGÉNITA"
    },
    {
        "id": "dc2ec580-137a-4652-f0da-af30959ecb11",
        "NOMBRE": "INSUFICIENCIA MITRAL REUMÁTICA"
    },
    {
        "id": "164daeb1-815b-415c-1b2d-ec85e69813c3",
        "NOMBRE": "INSUFICIENCIA NO REUMÁTICA (DE LA VÁLVULA) TRICÚSPIDE"
    },
    {
        "id": "5cf31558-8b7d-c9da-c100-c19d6a99b7d8",
        "NOMBRE": "INSUFICIENCIA OVÁRICA CONSECUTIVA A PROCEDIMIENTOS"
    },
    {
        "id": "1861460d-b46b-4e27-589b-d76280c648b1",
        "NOMBRE": "INSUFICIENCIA OVÁRICA PRIMARIA"
    },
    {
        "id": "f5595f52-bf19-0cff-9930-7db20c85e529",
        "NOMBRE": "INSUFICIENCIA POLIGLANDULAR AUTOINMUNE"
    },
    {
        "id": "f2f3033a-55da-0c6b-3b3e-f85b6c775a43",
        "NOMBRE": "INSUFICIENCIA PULMONAR AGUDA CONSECUTIVA A CIRUGÍA TORÁCICA"
    },
    {
        "id": "ae74df56-42db-cbfa-3ad9-3d68a0ff76c4",
        "NOMBRE": "INSUFICIENCIA PULMONAR AGUDA CONSECUTIVA DEBIDA A CIRUGÍA EXTRATORÁCICA"
    },
    {
        "id": "b17c4ed5-af62-863f-37e0-af7d1481af08",
        "NOMBRE": "INSUFICIENCIA PULMONAR CRÓNICA CONSECUTIVA A CIRUGÍA"
    },
    {
        "id": "05ca2114-2d72-9988-d08c-a03d0fc75e16",
        "NOMBRE": "INSUFICIENCIA RENAL AGUDA"
    },
    {
        "id": "5084ba40-bb77-b74b-1783-bc9b6b69b5f0",
        "NOMBRE": "INSUFICIENCIA RENAL AGUDA CON NECROSIS CORTICAL AGUDA"
    },
    {
        "id": "b034fbaa-d6a2-ca10-261e-3983dfc2e80e",
        "NOMBRE": "INSUFICIENCIA RENAL AGUDA CON NECROSIS MEDULAR"
    },
    {
        "id": "5cca38ff-433d-415b-b061-2c1821a92f21",
        "NOMBRE": "INSUFICIENCIA RENAL AGUDA CON NECROSIS TUBULAR"
    },
    {
        "id": "a0d268c8-a1af-d62c-678a-3c84f4ec341d",
        "NOMBRE": "INSUFICIENCIA RENAL AGUDA POSTPARTO"
    },
    {
        "id": "3c768a65-6d27-41e0-5268-6c8fa97071be",
        "NOMBRE": "INSUFICIENCIA RENAL AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "1a794f11-d425-43b1-761d-f5acfa7d46a1",
        "NOMBRE": "INSUFICIENCIA RENAL CONGÉNITA"
    },
    {
        "id": "3ee97de9-6e6c-2771-349c-0c07b5a1fa50",
        "NOMBRE": "INSUFICIENCIA RENAL CONSECUTIVA A PROCEDIMIENTOS"
    },
    {
        "id": "36458971-707f-7cec-0550-7dd014fc614c",
        "NOMBRE": "INSUFICIENCIA RENAL CONSECUTIVA AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "5ac407d1-a756-caa0-9319-0cfc9fa36a96",
        "NOMBRE": "INSUFICIENCIA RENAL NO ESPECIFICADA"
    },
    {
        "id": "33de2313-b8d9-88a6-89ba-b0e5739856b0",
        "NOMBRE": "INSUFICIENCIA RENAL TERMINAL"
    },
    {
        "id": "535152e3-d285-1f27-0ef6-8559bb6a86b3",
        "NOMBRE": "INSUFICIENCIA RESPIRATORIA AGUDA"
    },
    {
        "id": "6063ba1c-628e-0077-0351-e50235019d1c",
        "NOMBRE": "INSUFICIENCIA RESPIRATORIA CRÓNICA"
    },
    {
        "id": "429f576a-d87b-f5fc-5379-500c045543d3",
        "NOMBRE": "INSUFICIENCIA RESPIRATORIA DEL RECIÉN NACIDO"
    },
    {
        "id": "2f0b9e37-8bf3-7a81-9029-5db7cfee6c6a",
        "NOMBRE": "INSUFICIENCIA RESPIRATORIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "82dc43c2-4bb0-eb81-6436-13464fb05722",
        "NOMBRE": "INSUFICIENCIA RESPIRATORIA, NO ESPECIFICADA"
    },
    {
        "id": "df224cf7-2986-3b4b-0bc1-aa46b5dede32",
        "NOMBRE": "INSUFICIENCIA TRICÚSPIDE"
    },
    {
        "id": "1a58400c-3095-532e-ebee-9927b3360062",
        "NOMBRE": "INSUFICIENCIA VENOSA (CRÓNICA) (PERIFÉRICA)"
    },
    {
        "id": "f3b436a0-0b35-466c-5ed3-9e1a40531f14",
        "NOMBRE": "INSUFICIENCIA VENTRICULAR IZQUIERDA"
    },
    {
        "id": "10d05366-ca46-c182-e993-5fc18d62a875",
        "NOMBRE": "INTENTO FALLIDO DE ABORTO"
    },
    {
        "id": "7bf11da9-c856-2d41-6fde-fbc00b544a6b",
        "NOMBRE": "INTERVENCIÓN LEGAL"
    },
    {
        "id": "de977ea4-b96b-f32b-0a33-dfcf1e06036a",
        "NOMBRE": "INTERVENCIÓN LEGAL CON DISPARO DE ARMA DE FUEGO"
    },
    {
        "id": "d8beae49-2374-7182-9dcb-d9fd038f4dd8",
        "NOMBRE": "INTERVENCIÓN LEGAL CON EXPLOSIVOS"
    },
    {
        "id": "ebe90d80-ff23-f169-7e78-3582abb47c0c",
        "NOMBRE": "INTERVENCIÓN LEGAL CON GAS"
    },
    {
        "id": "ed31b7a7-33c5-11c9-e57a-9663faf4bdae",
        "NOMBRE": "INTERVENCIÓN LEGAL CON OBJETOS CORTANTES"
    },
    {
        "id": "7f7b33f7-ffc2-b737-5bf3-15a7bde92f9b",
        "NOMBRE": "INTERVENCIÓN LEGAL CON OBJETOS ROMOS O SIN FILO"
    },
    {
        "id": "4377599f-2b3b-4fac-9c77-ffc9b49e3650",
        "NOMBRE": "INTERVENCIÓN LEGAL CON OTROS MEDIOS ESPECIFICADOS"
    },
    {
        "id": "abc8027f-b3dc-c71e-1304-afe408b5dc4c",
        "NOMBRE": "INTERVENCIÓN LEGAL, MEDIOS NO ESPECIFICADOS"
    },
    {
        "id": "28213361-9d7b-6937-977e-642801f5145f",
        "NOMBRE": "INTESTINO NEUROGÉNICO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "376fa51c-b2a2-3895-ac51-46088a5ff2f3",
        "NOMBRE": "INTOLERANCIA A LA LACTOSA"
    },
    {
        "id": "3d35be85-5065-f0d9-6bbf-8c5e603b3a54",
        "NOMBRE": "INTOLERANCIA A LA LACTOSA, NO ESPECIFICADA"
    },
    {
        "id": "34138887-18cd-a6f1-7ae7-b3cd95a8feec",
        "NOMBRE": "INTOXICACIÓN ALCOHÓLICA LEVE"
    },
    {
        "id": "026526d3-425e-548b-a43d-bb0db5d3019d",
        "NOMBRE": "INTOXICACIÓN ALCOHÓLICA MODERADA"
    },
    {
        "id": "0b0103df-1dff-5e99-a141-54e13f610b90",
        "NOMBRE": "INTOXICACIÓN ALCOHÓLICA MUY SEVERA"
    },
    {
        "id": "f214b742-89b1-eb5b-2c1f-0910daed9bff",
        "NOMBRE": "INTOXICACIÓN ALCOHÓLICA SEVERA"
    },
    {
        "id": "e8b8d326-b06e-3072-8d06-cbbe6a031782",
        "NOMBRE": "INTOXICACIÓN ALIMENTARIA BACTERIANA, NO ESPECIFICADA"
    },
    {
        "id": "df552ddf-9d55-43cc-32e5-aa868c44256e",
        "NOMBRE": "INTOXICACIÓN ALIMENTARIA DEBIDA A BACILLUS CEREUS"
    },
    {
        "id": "e5e75ff1-dd68-4871-ecfe-92fbeb706e4f",
        "NOMBRE": "INTOXICACIÓN ALIMENTARIA DEBIDA A CLOSTRIDIUM PERFRINGENS [CLOSTRIDIUM WELCHII]"
    },
    {
        "id": "c5b4bf84-5873-ca33-6c98-db864e692877",
        "NOMBRE": "INTOXICACIÓN ALIMENTARIA DEBIDA A VIBRIO PARAHAEMOLYTICUS"
    },
    {
        "id": "cdc7729d-83a0-6e16-b578-c1eb50796fdd",
        "NOMBRE": "INTOXICACIÓN ALIMENTARIA ESTAFILOCÓCICA"
    },
    {
        "id": "134f460f-db40-d255-4f55-14f1779454f4",
        "NOMBRE": "INTOXICACIÓN ALIMENTARIA POR CLENBUTEROL"
    },
    {
        "id": "021480db-eec6-0bbd-a9f4-66fa4cd677cd",
        "NOMBRE": "INTOXICACIÓN ALIMENTARIA POR MAREA ROJA"
    },
    {
        "id": "2bc63c9d-378e-69b7-eb5c-dc3992b16c22",
        "NOMBRE": "INVAGINACIÓN"
    },
    {
        "id": "0252ad2a-7af5-0b80-e73c-1d0fa5bbfd26",
        "NOMBRE": "INVERSIÓN CROMOSÓMICA EN INDIVIDUO NORMAL"
    },
    {
        "id": "538757d4-5f2f-6e11-3756-2d585a0058e3",
        "NOMBRE": "INVERSIÓN DEL ÚTERO"
    },
    {
        "id": "2af9e88f-f696-e88b-d58d-4768c6f347b4",
        "NOMBRE": "INVERSIÓN DEL ÚTERO, POSTPARTO"
    },
    {
        "id": "d1ca8aa5-33e5-a22a-a7ee-32dd3a6ba8a6",
        "NOMBRE": "INVESTIGACIÓN Y PRUEBA PARA LA PROCREACIÓN"
    },
    {
        "id": "f6809226-f873-1a2e-df86-e6dcf750710d",
        "NOMBRE": "IRIDOCICLITIS"
    },
    {
        "id": "21475464-4ee2-ae7a-1d7e-22b44ca6da6e",
        "NOMBRE": "IRIDOCICLITIS AGUDA Y SUBAGUDA"
    },
    {
        "id": "18c2e69f-88fe-f651-9196-ab02c5dff057",
        "NOMBRE": "IRIDOCICLITIS CRÓNICA"
    },
    {
        "id": "7661007a-4990-2685-e1a2-d8d585d25425",
        "NOMBRE": "IRIDOCICLITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "67a6e1ca-b5ab-b104-3fb5-74ce2059ca58",
        "NOMBRE": "IRIDOCICLITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a1daae1f-2793-1bc0-371b-26c4e38d62d5",
        "NOMBRE": "IRIDOCICLITIS INDUCIDA POR TRASTORNO DEL CRISTALINO"
    },
    {
        "id": "fc380ff4-1bf1-ef98-2141-d656d798cfd7",
        "NOMBRE": "IRIDOCICLITIS, NO ESPECIFICADA"
    },
    {
        "id": "70f4a3b0-92a9-65d9-4934-f13fa0750662",
        "NOMBRE": "IRRITABILIDAD CEREBRAL NEONATAL"
    },
    {
        "id": "4c77ae48-58ec-e42f-6cbd-95a2ae21cb64",
        "NOMBRE": "IRRITABILIDAD Y ENOJO"
    },
    {
        "id": "456c381e-2d6e-9021-3de0-3fe6eb012882",
        "NOMBRE": "ISOMERISMO DE LOS APÉNDICES AURICULARES"
    },
    {
        "id": "b78eddfb-b6bf-79b7-48b7-0572cee56960",
        "NOMBRE": "ISOSPORIASIS"
    },
    {
        "id": "eebe2226-b148-62db-58b5-874d4e4b9a52",
        "NOMBRE": "ISQUEMIA CEREBRAL NEONATAL"
    },
    {
        "id": "dcbda0e1-b647-5ad2-f946-090e9fb5b8cb",
        "NOMBRE": "ISQUEMIA CEREBRAL TRANSITORIA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "0c796286-97f3-cd3e-e3a8-3444bb6b72e3",
        "NOMBRE": "ISQUEMIA E INFARTO DEL RIÑÓN"
    },
    {
        "id": "17641407-c368-6cb1-7e4b-e7eb4520cec8",
        "NOMBRE": "ISQUEMIA MIOCÁRDICA TRANSITORIA DEL RECIÉN NACIDO"
    },
    {
        "id": "a8dbc576-83af-6eda-7cfb-402d4abccf58",
        "NOMBRE": "ISQUEMIA SILENTE DEL MIOCARDIO"
    },
    {
        "id": "bc9c510d-7c53-edf5-f750-35f358ec4a99",
        "NOMBRE": "ISQUEMIA TRAUMÁTICA DE MÚSCULO"
    },
    {
        "id": "5d98a686-210f-984d-4616-3b9e6433b751",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO EN ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "9b427db3-1f33-e4c2-3156-47ec76711f32",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "81592a4f-f2d7-b7e6-ba06-7a80464774fe",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR CAÍDA (O POR SER DESPEDIDO) DEL ANIMAL O DEL VEHÍCULO DE TRACCIÓN ANIMAL, EN ACCIDENTE SIN COLISIÓN"
    },
    {
        "id": "34415303-e55c-a49d-e5a5-aca10a064285",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, VEHÍCULO DE TRANSPORTE PESADO, O AUTOBÚS"
    },
    {
        "id": "c98a9c01-a15f-7c4b-93b4-51129396ce52",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO"
    },
    {
        "id": "fbec63fd-f8c5-2b44-7096-36e80507c0d8",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR ESPECIFICADOS"
    },
    {
        "id": "dda1fcca-ed41-c31d-f985-fb94126d284f",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "115966ad-5891-d045-2b4a-9b983da9d89a",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "17b2a4a1-1902-c74b-23f7-ed834ac44496",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "92e33ee4-663f-df48-9dc6-4ed1a084fba4",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "01016d2f-2064-4d89-525f-cca43ed66d8f",
        "NOMBRE": "JINETE U OCUPANTE DE VEHÍCULO DE TRACCIÓN ANIMAL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "a7c0d142-336b-d606-d111-eb2430570e74",
        "NOMBRE": "JUEGO PATOLÓGICO"
    },
    {
        "id": "b7372a52-317b-62ad-6b67-4195044533b7",
        "NOMBRE": "KERNICTERUS"
    },
    {
        "id": "817f339b-b954-7fdb-3180-235352a87a43",
        "NOMBRE": "KERNICTERUS DEBIDO A INCOMPATIBILIDAD"
    },
    {
        "id": "9c889e0b-be70-f0a2-aaa7-3867923d6444",
        "NOMBRE": "KERNICTERUS DEBIDO A OTRAS CAUSAS ESPECIFICADAS"
    },
    {
        "id": "1f08363b-b069-fd6d-557e-934e28b16c7e",
        "NOMBRE": "KERNICTERUS, NO ESPECIFICADO"
    },
    {
        "id": "bab1887e-06e4-a7e3-bde6-8ae87d29dbe8",
        "NOMBRE": "KLEBSIELLA PNEUMONIAE [K. PNEUMONIAE] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "3329ad3d-33d0-4129-e2bc-8e1528891495",
        "NOMBRE": "KWASHIORKOR"
    },
    {
        "id": "9e0c0443-19ba-64d3-9f12-66ae6bb9b869",
        "NOMBRE": "KWASHIORKOR MARASMÁTICO"
    },
    {
        "id": "70c8b6a9-090f-4945-c8e3-86158dc3ea27",
        "NOMBRE": "LABERINTITIS"
    },
    {
        "id": "1398e9c3-ccdb-98cd-dd8e-00ef943af9bb",
        "NOMBRE": "LABIO LEPORINO"
    },
    {
        "id": "ee9c73cf-4b5f-bca3-dff4-0773ab54caff",
        "NOMBRE": "LABIO LEPORINO, BILATERAL"
    },
    {
        "id": "b885a204-1e27-b1ab-f911-5f7f3b1b5e59",
        "NOMBRE": "LABIO LEPORINO, LÍNEA MEDIA"
    },
    {
        "id": "05eef1ee-c2b5-c4fb-fd3a-d425963941f4",
        "NOMBRE": "LABIO LEPORINO, UNILATERAL"
    },
    {
        "id": "203fe80e-6e78-bf76-57a3-a7ae0f152a4c",
        "NOMBRE": "LACERACIÓN ANTIGUA DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "d5686a60-107a-12a6-655a-79f8751d33a9",
        "NOMBRE": "LACERACIÓN OCULAR SIN PROLAPSO O PÉRDIDA DEL TEJIDO INTRAOCULAR"
    },
    {
        "id": "ec711329-b630-0c6c-d9b7-6ddb0d5814fd",
        "NOMBRE": "LACERACIÓN Y RUPTURA OCULAR CON PROLAPSO O PÉRDIDA DEL TEJIDO INTRAOCULAR"
    },
    {
        "id": "0cf9699f-a050-a340-b49f-831ae413d62c",
        "NOMBRE": "LAGOFTALMOS"
    },
    {
        "id": "f55c7025-72cc-7d71-8b61-9813effd9a4c",
        "NOMBRE": "LARINGITIS AGUDA"
    },
    {
        "id": "a5c1c44b-cfa6-cba9-8a59-e26722ec20f2",
        "NOMBRE": "LARINGITIS CRÓNICA"
    },
    {
        "id": "0c96ba74-9c2b-6b7d-d2cd-aba8bd4129e5",
        "NOMBRE": "LARINGITIS OBSTRUCTIVA AGUDA [CRUP] Y EPIGLOTITIS"
    },
    {
        "id": "9eb1b104-edfa-4849-15ed-1effbc0505d0",
        "NOMBRE": "LARINGITIS OBSTRUCTIVA, AGUDA [CRUP]"
    },
    {
        "id": "e7121f50-23ba-b3e7-570d-9c12326fca9c",
        "NOMBRE": "LARINGITIS Y LARINGOTRAQUEÍTIS CRÓNICAS"
    },
    {
        "id": "a5835833-eb2c-c078-51c7-7aa9eba60b7e",
        "NOMBRE": "LARINGITIS Y TRAQUEÍTIS AGUDAS"
    },
    {
        "id": "eb52bca0-ed2b-083c-544a-293df1531b29",
        "NOMBRE": "LARINGOCELE"
    },
    {
        "id": "239a71a1-2848-2b7e-fcbd-8f44c42a7b95",
        "NOMBRE": "LARINGOFARINGITIS AGUDA"
    },
    {
        "id": "182928bc-2ca6-42ee-40b4-b9c446655ea8",
        "NOMBRE": "LARINGOMALASIA CONGÉNITA"
    },
    {
        "id": "6a26ede8-c65a-b621-d4c0-6b6454e6c2d6",
        "NOMBRE": "LARINGOTRAQUEÍTIS AGUDA"
    },
    {
        "id": "3ac7ea08-31b3-150f-e2a5-241a75e39183",
        "NOMBRE": "LARINGOTRAQUEÍTIS CRÓNICA"
    },
    {
        "id": "5fb148b5-f6f0-ce24-ff8b-4ccd3d2f806d",
        "NOMBRE": "LARVA MIGRANS VISCERAL"
    },
    {
        "id": "3a46a058-3727-659c-cdb4-a94929ef32d7",
        "NOMBRE": "LECTURA DE PRESIÓN BAJA NO ESPECÍFICA"
    },
    {
        "id": "58ff0a6d-2369-1c06-1fad-d2ffeb27f8ba",
        "NOMBRE": "LECTURA DE PRESIÓN SANGUÍNEA ANORMAL, SIN DIAGNÓSTICO"
    },
    {
        "id": "5f9141c4-0157-47fa-1c7e-5567ce2f61cd",
        "NOMBRE": "LECTURA ELEVADA DE LA PRESIÓN SANGUÍNEA, SIN DIAGNÓSTICO DE HIPERTENSIÓN"
    },
    {
        "id": "dc4cb0a6-33e5-2da8-2565-88eac0e0a03d",
        "NOMBRE": "LEIOMIOMA DEL ÚTERO"
    },
    {
        "id": "1b0f197c-f111-6ec1-e6fa-873fbb734d1c",
        "NOMBRE": "LEIOMIOMA DEL ÚTERO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "f8f20324-b43d-8049-a4b9-4d1dfe4d4750",
        "NOMBRE": "LEIOMIOMA INTRAMURAL DEL ÚTERO"
    },
    {
        "id": "efd1484f-50b5-2457-a5ff-6842df4aee78",
        "NOMBRE": "LEIOMIOMA SUBMUCOSO DEL ÚTERO"
    },
    {
        "id": "37fbbdc5-247f-8f2b-5619-9ab410a31fd6",
        "NOMBRE": "LEIOMIOMA SUBSEROSO DEL ÚTERO"
    },
    {
        "id": "5ddcb087-9919-0473-1e73-08842070cd0c",
        "NOMBRE": "LEISHMANIASIS"
    },
    {
        "id": "34a3122c-03b0-c1d0-f8a6-160192cb49b5",
        "NOMBRE": "LEISHMANIASIS CUTÁNEA"
    },
    {
        "id": "85f6825e-acfe-9adb-63a0-9e0ae3a75ae9",
        "NOMBRE": "LEISHMANIASIS MUCOCUTÁNEA"
    },
    {
        "id": "4ab567bb-db2d-4671-c293-e55f210f40b0",
        "NOMBRE": "LEISHMANIASIS VISCERAL"
    },
    {
        "id": "3b28b232-4973-2d3a-d7bc-b29d10e85543",
        "NOMBRE": "LEISHMANIASIS, NO ESPECIFICADA"
    },
    {
        "id": "a01aae31-103d-5ff6-b6f9-440df7a65731",
        "NOMBRE": "LENGUA GEOGRÁFICA"
    },
    {
        "id": "60ace639-e76f-d2e8-3c59-c93721ea5f31",
        "NOMBRE": "LENGUA PLEGADA"
    },
    {
        "id": "b7fc5637-0881-d48e-3d95-b25c764ca02d",
        "NOMBRE": "LENTITUD EN LA INGESTIÓN DE ALIMENTOS DEL RECIÉN NACIDO"
    },
    {
        "id": "1c365fc2-c1c1-5114-e47d-415acdecc471",
        "NOMBRE": "LENTITUD Y POBRE RESPUESTA"
    },
    {
        "id": "ec5a6a66-203f-edd1-6c2f-8a393f996dbd",
        "NOMBRE": "LEPRA [ENFERMEDAD DE HANSEN]"
    },
    {
        "id": "aa91c2d6-de7c-eace-1435-1e09b9d1a274",
        "NOMBRE": "LEPRA INDETERMINADA"
    },
    {
        "id": "ad14ec6a-309a-0a62-5795-b26c439bba19",
        "NOMBRE": "LEPRA LEPROMATOSA"
    },
    {
        "id": "03d74ee1-803a-7d09-40f5-efe46d52c05d",
        "NOMBRE": "LEPRA LEPROMATOSA LIMÍTROFE"
    },
    {
        "id": "94840c30-b33f-f2bc-3fc7-84461ccdd5ae",
        "NOMBRE": "LEPRA LIMÍTROFE"
    },
    {
        "id": "274eabea-dce6-c388-3d05-e2a140dcd904",
        "NOMBRE": "LEPRA TUBERCULOIDE"
    },
    {
        "id": "7c4e1d18-3814-d0ea-77d8-174f6a33c91d",
        "NOMBRE": "LEPRA TUBERCULOIDE LIMÍTROFE"
    },
    {
        "id": "f4d3b744-ae0b-fbf2-55b8-e53367e7eefc",
        "NOMBRE": "LEPRA, NO ESPECIFICADA"
    },
    {
        "id": "235d0a28-2d53-fca9-5c3a-5a7fcf101a83",
        "NOMBRE": "LEPTOSPIROSIS"
    },
    {
        "id": "361f10a4-8614-ab55-1869-88201dac2200",
        "NOMBRE": "LEPTOSPIROSIS ICTEROHEMORRÁGICA"
    },
    {
        "id": "233a0440-804d-d218-9a2e-b57c6f94fa50",
        "NOMBRE": "LEPTOSPIROSIS, NO ESPECIFICADA"
    },
    {
        "id": "db7ef395-71e9-d387-9b4d-3b317799639b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO"
    },
    {
        "id": "d3dd88ef-94e7-98d0-84d6-f13474297f93",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "edf4a467-e9b6-7811-5d34-58092260a90c",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "fe096bc8-32b5-623d-9423-dd68c8e6bd7c",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, CALLES Y CARRETERAS"
    },
    {
        "id": "d673a63a-6959-9757-4f10-28cfb625b050",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "00effe3e-7258-6450-1378-769fe8b230cb",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2d044266-019e-e7f9-016c-412b2d494258",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, GRANJA"
    },
    {
        "id": "6e611a50-957a-85a8-08c6-6a0efac752bc",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "6bb190bc-1ec5-ca7f-cd55-978a5c114fcc",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "cf63f996-6768-9f6a-10cf-744a27ba9d58",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "621a954c-fec7-7e35-f2c4-e8167a4c6b77",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, VIVIENDA"
    },
    {
        "id": "f3142eaf-8f9e-0c2a-3b48-111cab0926b2",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSIÓN"
    },
    {
        "id": "9a48224e-1a2a-b1f4-d779-6527b9424e3a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "b35fad29-ef22-2a6a-b6ea-07d7644630d2",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSIÓN, GRANJA"
    },
    {
        "id": "f5bbbe6e-8e67-36a9-b512-6ad4ce61cc22",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "4ecafdb0-e7a9-653d-8947-6f5476491ba1",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e815d905-adab-512f-c730-55b80fd6e6e4",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO, Y SUMERSIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8975abb5-ec51-ca0c-b807-840691eded00",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO, Y SUMERSIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "b9d595a8-88f1-8cba-c60f-e23296b3ccd7",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO, Y SUMERSIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "792b132e-0935-6f95-b711-bec46f0087c2",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO, Y SUMERSIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ecb674bb-33c5-33ee-7c68-40a8fef0a40c",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO, Y SUMERSIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "b9bf74b6-4209-4ff1-138a-0adad20e7037",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO, Y SUMERSIÓN, VIVIENDA"
    },
    {
        "id": "19fd0452-759b-dcbf-6cf0-0e312f88d0ca",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN"
    },
    {
        "id": "a5cd5d69-db01-ebdc-f1dd-188138bcd5dc",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN VIVIENDA"
    },
    {
        "id": "db47f2cb-35b6-6c7c-3dde-983cee50eac4",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "3d606bbd-1acd-3b9b-0808-54f31c528ac1",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5f9bf202-45b9-42b9-f957-a0d5f1e02ef0",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "3023c7cb-3153-4652-fda4-74303775dae7",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "ec3c97b0-b44c-7ec2-afcd-53b619a7834c",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "fe20644d-c589-9d13-48cd-f5905e5d5522",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, GRANJA"
    },
    {
        "id": "ec30034f-f8c2-8a29-0a0f-e11c61f87554",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "04aa62ae-cc96-38cb-53ff-c58e882d8de6",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "45da7ce7-0ab7-f9a6-778c-01bc6170e99f",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b9612e1b-02b4-90ca-a869-5d4b65963560",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO"
    },
    {
        "id": "a6b7a58d-6fda-5b50-244c-1a5fb9cdbd7f",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "24a80d5e-c80c-388a-f398-28536028b5eb",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5601a974-05a4-f471-a109-441e53e71ab4",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, CALLES Y CARRETERAS"
    },
    {
        "id": "95162614-1504-db6c-8f0f-c2a41ddafdaf",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "f53f7a9a-7873-24b5-acfc-004c5d9a2f7b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "942f1a47-f0f6-49fb-55f8-52bb0393a64c",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, GRANJA"
    },
    {
        "id": "2bb3b7ff-352f-67be-d9d1-6867626a6ea5",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "df170c05-578c-000f-5721-111d5c6d38ad",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5cbe6865-bb5a-dfb0-4c1d-30221c0aaecb",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e58868d5-796f-f952-7308-e88e25ca445f",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, VIVIENDA"
    },
    {
        "id": "3a3eff4a-c3ab-5279-bcad-b7e5b0554414",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR"
    },
    {
        "id": "4a3ec7e2-2e8f-f409-9070-055f55f8528b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "6bf076f4-8798-616f-e1f2-aa1dfc6a81d7",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "515df67d-5615-2706-8c4b-31fd33e16c47",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, CALLES Y CARRETERAS"
    },
    {
        "id": "8509363a-db35-09c8-fb33-e7d0bad88c69",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "9a90dd5d-45b8-ff6d-4cc1-c948e28c6bf0",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "787340cd-9256-aa58-23b4-812ea4db2a41",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, GRANJA"
    },
    {
        "id": "351c94c5-ae2d-fc50-8da9-82aa83b1b1be",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "dc357f22-0e96-0f5e-6e18-a0bb32efea17",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "07dedb1f-bb8c-7958-11f5-7ab67c56f96d",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e5b16ac0-d0d3-1973-39d9-da27056a8195",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR COLISIÓN DE VEHÍCULO DE MOTOR, VIVIENDA"
    },
    {
        "id": "6c0045ed-ca33-e617-63cc-249d41f1baf7",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA"
    },
    {
        "id": "10f5a78f-377b-3197-7a33-47d8a8de6e05",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8dfade5b-62f6-ef2b-1769-39005546bf75",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2cf0ed21-bb3a-9279-d80e-d7138cfd2df6",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, CALLES Y CARRETERAS"
    },
    {
        "id": "9f4cfaf0-1891-930b-c004-57e1bf345eab",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "7ae3b76e-5852-1c78-ff6d-c70a5e347ace",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "cf3f7a81-e734-0ec5-8b96-db55eb9529c5",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, GRANJA"
    },
    {
        "id": "99349851-512c-f1d7-86a9-a9f0894fee7b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "fb8bb994-79d9-c5be-c56f-d2dfe251d4d4",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "b36e5c84-e59d-c4e8-7b15-bf5a9d304d30",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "160837ba-7618-c243-dbf3-49a7a6d3d942",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, VIVIENDA"
    },
    {
        "id": "dc26cde8-6d5a-1bec-64af-1313daf241f3",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "7fd208a0-8976-9a70-5840-a7068a913c9f",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "d81c3d3d-68f0-4c47-dbfe-1c1a7e2b2773",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "59c2dce9-da7f-662b-2722-77de615fcfe9",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, CALLES Y CARRETERAS"
    },
    {
        "id": "6b148229-b44f-daee-8321-52103165e8ca",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "71acc7eb-778f-d9f0-4543-e3b5c210e3b1",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "16ecf63b-9e2b-a72a-4826-5f1e496647d8",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, GRANJA"
    },
    {
        "id": "bf4169f2-fe71-796c-74da-2faca17c204e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "4c45f070-78a9-f699-d454-9aac0495055d",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c7615585-e3e1-6d0e-6d2c-a5845b1802c8",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b8f96e2a-49bb-3200-5bf5-fbd1e6aa1d5a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, VIVIENDA"
    },
    {
        "id": "00e16a59-f763-391e-562f-5337e5867b76",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA"
    },
    {
        "id": "3519bc3e-ea58-9cb0-9954-876611f11d1a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "548dbd9e-1d33-f277-8bb6-04ecad9dbf4e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "3df58ef9-b11e-cde9-5cee-c3112a992937",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, CALLES Y CARRETERAS"
    },
    {
        "id": "e91cf667-231a-d62c-9f19-6dd98e9a3209",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "7f27e16e-29a8-f10e-fb30-652826ac3bca",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "fa579e26-179d-c950-4dbf-513b91ea184e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, GRANJA"
    },
    {
        "id": "79852b70-86c8-ae14-69bc-6ad05fadc16e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "c6dd2f09-d06b-8143-cb88-e1956ff94ef7",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "e584c4bf-598f-7f54-1db7-5c1ff8f07d3b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "1a0964cc-268a-98b2-edba-2c961d651659",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, VIVIENDA"
    },
    {
        "id": "e8060cd2-d11f-572f-4ac6-feed8b102c77",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS"
    },
    {
        "id": "033346dc-639c-6d99-4f4d-387a6a0fd3d7",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "124803e3-fa1e-4c93-9bb8-371daf2e6f13",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "79b00991-cfe7-bbad-eb4f-7fa234e28950",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, CALLES Y CARRETERAS"
    },
    {
        "id": "bf8f9f23-207c-4e76-bb1b-b5777a18c253",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "0c746203-c3f1-dab4-b234-fa5786a0131d",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "4b9e219f-a352-5aa2-55c8-33fdb8fe93eb",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, GRANJA"
    },
    {
        "id": "4d192b69-0e1c-b1fc-e93b-ac2dcb18c1ac",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cea3f70a-f92c-7fee-be7d-3cd554a2e083",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "582eb159-3010-6208-7096-61acc60b42ff",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8a5924a6-f1f0-0e75-e46e-0f1676e0906a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, VIVIENDA"
    },
    {
        "id": "c6608e70-f531-0774-0c48-da226a058d84",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO"
    },
    {
        "id": "2bd1d45a-41a9-15ee-72a8-248bf7198c1c",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "a3f27e79-5179-bca0-39d6-5dcce6cab4c8",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2e57bf3e-d5c0-9384-0c2f-31eac3411f29",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, CALLES Y CARRETERAS"
    },
    {
        "id": "34cb831e-28d5-6a00-6e8e-ec67d4dcaebf",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "895bde4f-0f82-ca7c-fe53-fe8ae45e98d3",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "4e967ce0-aa71-8e29-8e11-82d9f331c27b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, GRANJA"
    },
    {
        "id": "e52004f4-beb6-f378-ea95-07b1461198b9",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "cdeb9416-7476-47f8-ce85-25401eb5008a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "de01de82-01e2-35dd-0ea4-2b54c19691f0",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a59dfaea-92cf-0e8b-980c-ce2fc6041067",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, VIVIENDA"
    },
    {
        "id": "167e6e48-5b9d-128b-64b1-82dd878548e2",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS"
    },
    {
        "id": "132a8e11-6ae8-e904-b485-662fbdde0e90",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "fc466156-4b93-05a9-0b28-e16fadfff22f",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c7b8bb1d-8e6e-5160-d5ae-036f8d4af6db",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "c49edd23-078f-8def-d7aa-d8adab535589",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a4847929-49f8-d601-4881-fe6c483d922b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "8c40fe4e-7d6b-b98c-5887-66f049062349",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, GRANJA"
    },
    {
        "id": "b5835307-b77f-a4f6-bbf4-a21805781e39",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e1600777-2527-bce2-bfa3-517dfec777cf",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "67333b66-ea78-ee7c-63ff-c5e76efdff57",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e6b65fdd-ff59-6780-8bd4-8356c375130c",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "d639c360-726a-8530-c3c3-a63488072364",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE"
    },
    {
        "id": "99bd9065-6776-8b43-52c3-6b9e1e269da4",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "73745e5b-8f89-ac24-aa09-e5aac2cd1d66",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a07c2ae1-dbbe-03e5-9f13-ecdaa15b9f54",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, CALLES Y CARRETERAS"
    },
    {
        "id": "729b77ef-ffbb-89ab-7995-ca03ffbd5e1f",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "947ed4c3-646f-e21e-5de4-d34c0ca7feb4",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "28af4562-7f9a-899f-64d0-a63bd815d44a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, GRANJA"
    },
    {
        "id": "fc60aaf1-c976-493c-51b5-8624eea4c297",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "50ca1bd5-1ed9-187c-72e5-826d25d80f4b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "80ae9844-b008-0ce9-72ec-41894e0d5ba2",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "06094ee0-c8fe-db67-fb8e-d966e8fc2340",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, VIVIENDA"
    },
    {
        "id": "393e8997-ecee-6326-7077-db93ed2c6476",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO"
    },
    {
        "id": "f450ed09-f4d1-df93-6ecf-271ff6fe24fe",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "9f5d7968-34ad-e731-4e12-f795a81da917",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "ba15eb16-cf9b-58a5-042a-7553912140f5",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, CALLES Y CARRETERAS"
    },
    {
        "id": "8d6c2e2b-e3e1-e764-beda-d12ade43a90e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "78a57f55-255f-e2ee-f3ec-d082dd1878c3",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "60ea419c-89bc-f05a-e682-7e434c9ee4e3",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, GRANJA"
    },
    {
        "id": "c94808c5-83a3-c906-360b-ad038ce217c8",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "245d3d1b-56cc-4b43-f3da-462e2805e922",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a0f6e8bf-1d43-ac63-ad1a-a1284d2d7c10",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8ebf5ef9-bac1-7813-dee1-29b7df928daa",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, VIVIENDA"
    },
    {
        "id": "b914f7a9-9973-3853-3dcd-b7894202d41d",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS"
    },
    {
        "id": "3bb634e2-4197-069d-b919-cd97a182c8e0",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "909763b8-51aa-3ece-a1fb-f22765a7aec3",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "394706a4-36dc-ab1f-6d7e-b8eefe816c51",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "073df276-7461-b68e-7548-f4e7fc78cfdf",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a1f1b28b-1119-fd77-334d-b3b961c20ccd",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "bc5ca9bd-4fb4-ba7b-1d21-cdd67e67905e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, GRANJA"
    },
    {
        "id": "c53063d5-ce22-010c-6e48-a2e168e9147a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "4a91f6e8-fb82-815c-7e11-fcded7cf210a",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "85b0d8b1-ea16-ee2a-d3c6-14d912bcf57e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "c4cb962c-f5b1-ca18-8c63-6c631b719210",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "4d16d919-b4ea-c6d1-92da-c7e043818a2e",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES"
    },
    {
        "id": "f4c937bf-2dd2-e880-1b80-ac5c80d4053b",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "e874401a-1e05-a5cd-d16b-2d1881a9f785",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "5275b1dd-1375-2b6b-e3f8-9534de7cfb89",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, CALLES Y CARRETERAS"
    },
    {
        "id": "06a3d8e7-913a-e9f8-a779-c944cea38e92",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "19bdd07e-c16a-81e8-88ed-dbad529207c9",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e6c4382e-5aba-af6d-2da6-0f761fb1c53f",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, GRANJA"
    },
    {
        "id": "38de9745-6ea0-78ca-5d0d-daae988e4668",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3aa476a8-e4de-cb73-ab7a-2b67c3ea2244",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d9d879f7-c712-9a8c-2ba2-2a35814a0fa2",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "dfc4a905-754f-7b37-be22-db0a4c64f0f6",
        "NOMBRE": "LESIÓN AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, VIVIENDA"
    },
    {
        "id": "08d6b926-be33-e024-28a4-53ba318876ba",
        "NOMBRE": "LESIÓN BIOMECÁNICA, NO ESPECIFICADA"
    },
    {
        "id": "8d84718f-c7e6-9352-40fa-4b91ffa06964",
        "NOMBRE": "LESIÓN CEREBRAL ANÓXICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "8dbba40b-22df-7291-efd2-0e96e77b9475",
        "NOMBRE": "LESIÓN DE ÓRGANOS O TEJIDOS DE LA PELVIS CONSECUTIVO AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "37c3a018-94a8-6508-7be0-993b89e93f65",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA AMÍGDALA"
    },
    {
        "id": "b39edd06-1888-44ec-877e-2c35131ad28f",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA HIPOFARINGE"
    },
    {
        "id": "39a013fe-b9ef-3176-7102-4990d6432187",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA LARINGE"
    },
    {
        "id": "cb3717e7-da5f-2a8d-d245-304b40b4d04b",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA LENGUA"
    },
    {
        "id": "eb41ffca-c182-16c6-6071-a7f58981bd54",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA MAMA"
    },
    {
        "id": "ee23311e-db3e-b365-5847-22a50a626f61",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA NASOFARINGE"
    },
    {
        "id": "e415b1c7-695b-bdec-5cd4-37ba327b9e0b",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA OROFARINGE"
    },
    {
        "id": "f15e8642-9708-16ce-cc96-73c5b4e3405f",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA PIEL"
    },
    {
        "id": "cea56f7b-cc56-9ac4-d8e0-2696837613f5",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA VEJIGA"
    },
    {
        "id": "ec1cef21-9ab7-8a08-2789-63670a753d40",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LA VULVA"
    },
    {
        "id": "4ec38722-6515-7d46-e9cb-ca7f1b824f62",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LAS GLÁNDULAS SALIVALES MAYORES"
    },
    {
        "id": "aff3075f-de9e-65bf-09d5-6654ebd7343b",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LAS VÍAS BILIARES"
    },
    {
        "id": "80195c9d-c248-f6e6-173d-3fb46b602be9",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS BRONQUIOS Y DEL PULMÓN"
    },
    {
        "id": "ee366a03-7a0b-e210-36c0-65f2eb931dbe",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS HUESOS Y DE LOS CARTÍLAGOS ARTICULARES DE LOS MIEMBROS"
    },
    {
        "id": "c4d9003b-ba64-c2dc-6ac7-7231ac87d78a",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS NERVIOS PERIFÉRICOS Y DEL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "da67deec-51a8-80a9-a8b1-928d9bd65ab7",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS ÓRGANOS DIGESTIVOS"
    },
    {
        "id": "ef4165ac-7367-c01d-11a2-0974c0d9574c",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS ÓRGANOS GENITALES FEMENINOS"
    },
    {
        "id": "9a0ef8c3-f11d-f31d-899f-cde02677077d",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "f014fc0a-20db-fa4f-cadf-3acfb3aa9da0",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS ÓRGANOS RESPIRATORIOS E INTRATORÁCICOS"
    },
    {
        "id": "5a0830e8-dcc7-b7d1-e3ed-083faaa0c16b",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS ÓRGANOS URINARIOS"
    },
    {
        "id": "3e13251f-ccb2-289b-6529-3c8ef0ffed3d",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE LOS SENOS PARANASALES"
    },
    {
        "id": "5ab05105-b00f-63f5-96d9-96afc5834365",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA"
    },
    {
        "id": "937d084b-d8c6-fd9c-301a-80ffbb6d9ce6",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL ANO, DEL CONDUCTO ANAL Y DEL RECTO"
    },
    {
        "id": "d1bb80f5-b73d-2ca9-9241-01bec5ec5da6",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL COLON"
    },
    {
        "id": "290b73ac-8bc1-f86e-5b7c-2fec105fc040",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "6cc3396c-a4f5-51da-3bf7-c0598346db88",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL CUERPO DEL ÚTERO"
    },
    {
        "id": "b549de9f-b6d9-82ae-5199-ead9b4077bf4",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL ENCÉFALO"
    },
    {
        "id": "25c0ca6d-f71c-1123-730f-9aa8afd7c4df",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL ENCÉFALO Y OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "712142a3-3c19-b7f2-d851-fb77ec148261",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL ESÓFAGO"
    },
    {
        "id": "d50fa206-7703-6437-d61f-926b68ce1fe3",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL ESTÓMAGO"
    },
    {
        "id": "973e0416-7452-1d08-6bbe-1b6ba4364838",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL HUESO Y DEL CARTÍLAGO ARTICULAR"
    },
    {
        "id": "fbe05ac7-3a4d-61c7-fbdb-7a98a987797a",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL INTESTINO DELGADO"
    },
    {
        "id": "ba1d25c6-f5fc-ed47-6c4f-1856f0e08921",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL LABIO"
    },
    {
        "id": "42511e89-0589-5562-66ef-448780b541c6",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE"
    },
    {
        "id": "c2acbaec-cc1d-790a-36d1-02eed86b50e5",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL OJO Y SUS ANEXOS"
    },
    {
        "id": "7028a4ff-e493-932b-fb2d-827d4f1bceaa",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL PALADAR"
    },
    {
        "id": "accf173d-af48-d963-7d06-3cbb18193ce9",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL PÁNCREAS"
    },
    {
        "id": "3cd8e950-f0c1-f561-f1e5-4c46e2c0fd24",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL PENE"
    },
    {
        "id": "c885a7a8-26c0-9eca-0713-f3787be8cb5d",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL PERITONEO Y DEL RETROPERITONEO"
    },
    {
        "id": "a81bea48-2b27-b789-1267-49721bbb5365",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL PISO DE LA BOCA"
    },
    {
        "id": "e1ffff8b-8a7d-2a0d-4aec-f7d84bfbff9b",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS DEL TEJIDO CONJUNTIVO Y DEL TEJIDO BLANDO"
    },
    {
        "id": "787924d4-7a5d-fd1b-172d-ab39261a92dd",
        "NOMBRE": "LESIÓN DE SITIOS CONTIGUOS MAL DEFINIDOS"
    },
    {
        "id": "63b7d718-6d45-a99f-03ea-8924676c7591",
        "NOMBRE": "LESIÓN DE SITOS CONTIGUOS DEL CORAZÓN, DEL MEDIASTINO Y DE LA PLEURA"
    },
    {
        "id": "c2be8537-f392-e7f7-e4b9-ec74ba0509cf",
        "NOMBRE": "LESIÓN DEL BAZO DURANTE EL NACIMIENTO"
    },
    {
        "id": "c89a66b8-9b17-1612-1fbf-7007fb45c8a9",
        "NOMBRE": "LESIÓN DEL HÍGADO DURANTE EL NACIMIENTO"
    },
    {
        "id": "913de00b-7ed5-554a-dae1-e80cbfed715d",
        "NOMBRE": "LESIÓN DEL HOMBRO, NO ESPECIFICADA"
    },
    {
        "id": "f0929ed2-febb-9c97-f35c-a0b0dd3ffe7a",
        "NOMBRE": "LESIÓN DEL NERVIO CIÁTICO"
    },
    {
        "id": "e564ab01-8e56-ccf3-11e9-6eb8114af996",
        "NOMBRE": "LESIÓN DEL NERVIO CIÁTICO POPLÍTEO EXTERNO"
    },
    {
        "id": "0ccb96d1-7804-948c-99ad-6ae2f07603a7",
        "NOMBRE": "LESIÓN DEL NERVIO CIÁTICO POPLÍTEO INTERNO"
    },
    {
        "id": "657e4078-18b0-18da-7832-d57e01f2ad5f",
        "NOMBRE": "LESIÓN DEL NERVIO CRURAL"
    },
    {
        "id": "abbdbde6-9cd3-85af-2a3a-10ba4c3569e3",
        "NOMBRE": "LESIÓN DEL NERVIO CUBITAL"
    },
    {
        "id": "c361a41c-3898-b56a-330c-4890d01bc7df",
        "NOMBRE": "LESIÓN DEL NERVIO PLANTAR"
    },
    {
        "id": "5283641f-f8c5-5305-407b-06a05e00f640",
        "NOMBRE": "LESIÓN DEL NERVIO RADIAL"
    },
    {
        "id": "b6c9410e-15fa-0f12-12a7-fbbea77e75f3",
        "NOMBRE": "LESIÓN NO ESPECIFICADA DEL ENCÉFALO, DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "3260b81b-fdef-f0ad-689f-19520110c4cf",
        "NOMBRE": "LESIÓN QUE OCUPA EL ESPACIO INTRACRANEAL"
    },
    {
        "id": "5f0ffc6b-1300-6458-d0a8-8a1486dfd63a",
        "NOMBRE": "LESIONES BIOMECÁNICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c2078c92-258e-da91-9188-cdac506c510b",
        "NOMBRE": "LESIONES DE LA ENCÍA Y DE LA ZONA EDÉNTULA ASOCIADAS CON TRAUMATISMO"
    },
    {
        "id": "cf63714b-84a0-2380-58ce-8a4bf9baafe3",
        "NOMBRE": "LESIONES DEL HOMBRO"
    },
    {
        "id": "378ca1df-506f-dc75-5a5a-357b9fc261f4",
        "NOMBRE": "LESIONES FRAMBÉSICAS DE LOS HUESOS Y DE LAS ARTICULACIONES"
    },
    {
        "id": "fcf01a60-3731-193c-9e81-c93693edbff5",
        "NOMBRE": "LESIONES INICIALES DE FRAMBESIA"
    },
    {
        "id": "401dbd31-329d-ea0c-4e1f-15e2ad0d8922",
        "NOMBRE": "LESIONES INTERMEDIAS DE LA PINTA"
    },
    {
        "id": "6fb7892c-8eb6-bd01-243c-a68f0df84d63",
        "NOMBRE": "LESIONES MIXTAS DE LA PINTA"
    },
    {
        "id": "67824015-1c20-33fe-1012-b7200c4aeac6",
        "NOMBRE": "LESIONES PAPILOMATOSAS MÚLTIPLES Y FRAMBESIA CON PASO DE CANGREJO"
    },
    {
        "id": "9f5c9c41-5d00-a070-2c67-bb6fc61bd287",
        "NOMBRE": "LESIONES PRIMARIAS DE LA PINTA"
    },
    {
        "id": "ce37fac8-41ee-0885-82d4-a054a6a4547b",
        "NOMBRE": "LESIONES TARDÍAS DE LA PINTA"
    },
    {
        "id": "996f6fbb-8164-529e-b8b7-ce41553aefba",
        "NOMBRE": "LEUCEMIA AGUDA, CÉLULAS DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "b73d417e-0128-137b-0cab-32b4a44c51c8",
        "NOMBRE": "LEUCEMIA CRÓNICA, CÉLULAS DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "11bae118-0f6c-1afa-82c8-dda500886716",
        "NOMBRE": "LEUCEMIA DE CÉLULAS DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "aefb279b-afd7-a9a2-1d51-8e85dc676f02",
        "NOMBRE": "LEUCEMIA DE CÉLULAS PLASMÁTICAS"
    },
    {
        "id": "b4fa8b65-b744-08fc-14b8-f82152e39dfc",
        "NOMBRE": "LEUCEMIA DE CÉLULAS VELLOSAS"
    },
    {
        "id": "00ab120a-252a-a586-1bf3-6efd053299fb",
        "NOMBRE": "LEUCEMIA DE MASTOCITOS"
    },
    {
        "id": "75a16ff9-9052-276c-b988-5db2b46c0e02",
        "NOMBRE": "LEUCEMIA EOSINOFÍLICA CRÓNICA [SÍNDROME HIPEREOSINOFÍLICO]"
    },
    {
        "id": "d125f8e9-4af2-4873-9701-6ebea19e02bc",
        "NOMBRE": "LEUCEMIA ERITROIDE AGUDA"
    },
    {
        "id": "5a6aafd5-1294-16c1-442d-c04e4a677ddf",
        "NOMBRE": "LEUCEMIA LINFOBLÁSTICA AGUDA [LLA]"
    },
    {
        "id": "9c9f5539-ecad-6f80-924b-38f55b5530a1",
        "NOMBRE": "LEUCEMIA LINFOCÍTICA CRÓNICA DE CÉLULA TIPO B"
    },
    {
        "id": "407faf6f-6d20-4675-3b8b-36b102016618",
        "NOMBRE": "LEUCEMIA LINFOCÍTICA SUBAGUDA"
    },
    {
        "id": "6bdf127c-f468-72d0-0e5c-4d9e4bc0dd62",
        "NOMBRE": "LEUCEMIA LINFOIDE"
    },
    {
        "id": "ae0d81b7-2113-66a3-e4f1-cd30fc349956",
        "NOMBRE": "LEUCEMIA LINFOIDE, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "d62d14c5-79a7-1428-6525-e2915a2995cd",
        "NOMBRE": "LEUCEMIA MEGACARIOBLÁSTICA AGUDA"
    },
    {
        "id": "0be49a9b-bf5f-a3a3-5619-521a32d3d8c9",
        "NOMBRE": "LEUCEMIA MIELOBLÁSTICA AGUDA [LMA]"
    },
    {
        "id": "2a5f0171-1937-6521-d36a-6925d773fe79",
        "NOMBRE": "LEUCEMIA MIELOIDE"
    },
    {
        "id": "a5bfd6ef-a00d-bce1-610d-a099650bdb14",
        "NOMBRE": "LEUCEMIA MIELOIDE AGUDA CON ANORMALIDAD 11Q23"
    },
    {
        "id": "1f59a82c-d506-074a-31ac-b0d23852edf4",
        "NOMBRE": "LEUCEMIA MIELOIDE AGUDA CON DISPLASIA MULTILINAJE"
    },
    {
        "id": "62a9333e-0c5d-3b32-9fdd-2355c540229b",
        "NOMBRE": "LEUCEMIA MIELOIDE CRÓNICA [LMC], BCR/ABL-POSITIVO"
    },
    {
        "id": "b9101fe5-df36-b731-44ed-16ce7733a5cb",
        "NOMBRE": "LEUCEMIA MIELOIDE CRÓNICA ATÍPICA, BCR/ABL-NEGATIVO"
    },
    {
        "id": "363c206a-39d7-0367-0d3b-d6729bcf7afb",
        "NOMBRE": "LEUCEMIA MIELOIDE, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "ea7c6a25-456f-aba4-a963-2a8ac0c157e8",
        "NOMBRE": "LEUCEMIA MIELOMONOCÍTICA AGUDA"
    },
    {
        "id": "9591b567-4983-50fe-a07b-8176489db19e",
        "NOMBRE": "LEUCEMIA MIELOMONOCÍTICA CRÓNICA"
    },
    {
        "id": "56e6ff78-55aa-bce1-95d5-bab1debacc41",
        "NOMBRE": "LEUCEMIA MIELOMONOCÍTICA JUVENIL"
    },
    {
        "id": "131a390a-c209-67d5-1258-351d86c7b552",
        "NOMBRE": "LEUCEMIA MONOCÍTICA"
    },
    {
        "id": "2562593d-e09c-185b-3c0e-cc9a90067c14",
        "NOMBRE": "LEUCEMIA MONOCÍTICA SUBAGUDA"
    },
    {
        "id": "5852a0c2-d8e8-e691-d164-b8f7d690dfd6",
        "NOMBRE": "LEUCEMIA MONOCÍTICA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "76c054e4-b68f-8c36-542b-e77526ab81fa",
        "NOMBRE": "LEUCEMIA MONOCÍTICA/MONOBLÁSTICA AGUDA"
    },
    {
        "id": "3e0538cc-9592-da74-2abe-0cd599e2ad29",
        "NOMBRE": "LEUCEMIA PROLINFOCÍTICA DE CÉLULA TIPO B"
    },
    {
        "id": "93155e0c-54bf-0c26-162d-2fe75f62cfc8",
        "NOMBRE": "LEUCEMIA PROLINFOCÍTICA DE CÉLULAS TIPO T"
    },
    {
        "id": "e5ada79c-dd4a-4843-b34b-998a8d86c4c9",
        "NOMBRE": "LEUCEMIA PROMIELOCÍTICA AGUDA [LPA]"
    },
    {
        "id": "548aae06-038e-dd6b-7d91-618b182feffd",
        "NOMBRE": "LEUCEMIA SUBAGUDA, CÉLULAS DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "614f52a4-06da-6ba7-5093-a86bdd557284",
        "NOMBRE": "LEUCEMIA TIPO BURKITT DE CÉLULAS B MADURAS"
    },
    {
        "id": "c4968302-d63e-be7e-5ac0-f352785be3f9",
        "NOMBRE": "LEUCEMIA, NO ESPECIFICADA"
    },
    {
        "id": "265ba02d-a5f2-dd65-6dc7-f4f13b7e4824",
        "NOMBRE": "LEUCEMIA/LINFOMA DE CÉLULAS T ADULTAS [HTLV-1-ASOCIADO]"
    },
    {
        "id": "6825f0bd-f328-fa2d-fa5e-a0ee718bf2b0",
        "NOMBRE": "LEUCODERMÍA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "d5c0cc55-eb4f-3fb9-cef7-21e355123b54",
        "NOMBRE": "LEUCOENCEFALITIS HEMORRÁGICA AGUDA Y SUBAGUDA [HURST]"
    },
    {
        "id": "8a7c445f-7246-4941-a420-2efbe02376ec",
        "NOMBRE": "LEUCOENCEFALOPATÍA MULTIFOCAL PROGRESIVA"
    },
    {
        "id": "6bbf8c56-5429-276d-c822-5b8edb46b6fb",
        "NOMBRE": "LEUCOENCEFALOPATÍA VASCULAR PROGRESIVA"
    },
    {
        "id": "88a71f0f-dc62-12bf-b2e3-9a7b306685cd",
        "NOMBRE": "LEUCOMA ADHERENTE"
    },
    {
        "id": "a295c950-aeac-f5b8-8230-715313f4e0e4",
        "NOMBRE": "LEUCOMALACIA CEREBRAL NEONATAL"
    },
    {
        "id": "865d54f0-5a21-8859-42fe-64a774e6bb99",
        "NOMBRE": "LEUCONIQUIA CONGÉNITA"
    },
    {
        "id": "0f0946f2-4b22-ea17-afad-ad7ab31838fd",
        "NOMBRE": "LEUCOPLASIA DE LA VAGINA"
    },
    {
        "id": "04b694cb-4690-e059-9db6-cc3dabe87ec1",
        "NOMBRE": "LEUCOPLASIA DE LA VULVA"
    },
    {
        "id": "6245c5f4-9244-35cb-ef9b-8a889b533bd1",
        "NOMBRE": "LEUCOPLASIA DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "060a5561-9d31-64ce-fe86-c886ae7d5e82",
        "NOMBRE": "LEUCOPLASIA DEL PENE"
    },
    {
        "id": "31c52806-bed4-0963-6814-e29fad1b2964",
        "NOMBRE": "LEUCOPLASIA PILOSA"
    },
    {
        "id": "f7156517-0fff-b401-557e-8d2a7b177ef6",
        "NOMBRE": "LEUCOPLASIA Y OTRAS ALTERACIONES DEL EPITELIO BUCAL, INCLUYENDO LA LENGUA"
    },
    {
        "id": "98bea53c-6483-d1fe-3ef9-a094b30d5b98",
        "NOMBRE": "LEVOCARDIA"
    },
    {
        "id": "9c11ae1d-7e79-20af-09d9-4d0913db258c",
        "NOMBRE": "LÍNEAS DE BEAU"
    },
    {
        "id": "48c990c5-86c4-0107-62cd-e95cce97d2e6",
        "NOMBRE": "LINFADENITIS AGUDA"
    },
    {
        "id": "79145d06-1915-78a4-5677-d9819c362920",
        "NOMBRE": "LINFADENITIS AGUDA DE CARA, CABEZA Y CUELLO"
    },
    {
        "id": "cf663f97-dce8-b4ab-c231-19491754a411",
        "NOMBRE": "LINFADENITIS AGUDA DE OTROS SITIOS"
    },
    {
        "id": "624b3422-59f2-e52c-b768-2dfe250abe74",
        "NOMBRE": "LINFADENITIS AGUDA DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "c5929799-ed08-ab90-b643-f1d164b2b0c1",
        "NOMBRE": "LINFADENITIS AGUDA DEL MIEMBRO INFERIOR"
    },
    {
        "id": "a45384e7-2f32-5dd9-c414-bdf3e993784c",
        "NOMBRE": "LINFADENITIS AGUDA DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "dbf6ea3c-90dc-a9c9-5a42-12c6910d405d",
        "NOMBRE": "LINFADENITIS AGUDA DEL TRONCO"
    },
    {
        "id": "485ef724-876c-93f4-4cea-4a0b9512826a",
        "NOMBRE": "LINFADENITIS CRÓNICA, EXCEPTO LA MESENTÉRICA"
    },
    {
        "id": "67345ace-8ca2-c984-58b0-c9f8a4d3fa1e",
        "NOMBRE": "LINFADENITIS INESPECÍFICA"
    },
    {
        "id": "9f5d6aca-edcf-ec36-fa25-5dcb99f9417b",
        "NOMBRE": "LINFADENITIS INESPECÍFICA NO ESPECIFICADA"
    },
    {
        "id": "bb472935-4ef3-c15c-b8c2-bfad17234ff9",
        "NOMBRE": "LINFADENITIS MESENTÉRICA INESPECÍFICA"
    },
    {
        "id": "e71d69f3-6433-3fec-0a84-10e47f5954f0",
        "NOMBRE": "LINFADENOPATÍA PERIFÉRICA TUBERCULOSA"
    },
    {
        "id": "49f1bd41-1b34-b154-5a30-0af3b68bfee5",
        "NOMBRE": "LINFANGIOMA, DE CUALQUIER SITIO"
    },
    {
        "id": "feb2bb98-fbce-ac08-b749-c638c4977dfd",
        "NOMBRE": "LINFANGITIS"
    },
    {
        "id": "a518304b-6e25-0c4a-8721-00e7935773d4",
        "NOMBRE": "LINFEDEMA HEREDITARIO"
    },
    {
        "id": "8909988c-4600-6f2d-4850-1f9147d3fba9",
        "NOMBRE": "LINFEDEMA, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "a2989c8f-2985-7433-1163-2219767b46a2",
        "NOMBRE": "LINFOGRANULOMA (VENÉREO) POR CLAMIDIAS"
    },
    {
        "id": "c119cdcb-ac76-2cfe-7e26-672f9ab8858d",
        "NOMBRE": "LINFOHISTIOCITOSIS HEMOFAGOCÍTICA"
    },
    {
        "id": "dfc19544-f35d-4f4b-ea1c-cbeb54d0a967",
        "NOMBRE": "LINFOMA ANAPLÁSICO DE CÉLULAS GRANDES ALK-NEGATIVO"
    },
    {
        "id": "361d01ed-dd61-be9e-1c6e-b41b2a5a10b3",
        "NOMBRE": "LINFOMA ANAPLÁSICO DE CÉLULAS GRANDES ALK-POSITIVO"
    },
    {
        "id": "f9ff2d57-a19d-2791-18b1-f5e9a1eb3ba0",
        "NOMBRE": "LINFOMA ANGIOINMUNOBLÁSTICO DE CÉLULAS T"
    },
    {
        "id": "e3cf11ac-218a-8969-e0c9-13933141ca56",
        "NOMBRE": "LINFOMA BLÁSTICO DE CÉLULAS NK"
    },
    {
        "id": "83fa96e1-b60c-0d37-e8ed-7818748cbd76",
        "NOMBRE": "LINFOMA CENTRO FOLICULAR CUTÁNEO"
    },
    {
        "id": "3993a52b-f736-f956-75f4-096cf7dcc284",
        "NOMBRE": "LINFOMA CENTRO FOLICULAR DIFUSO"
    },
    {
        "id": "e5d1cd40-c993-b334-45c2-a213f946fe7a",
        "NOMBRE": "LINFOMA CUTÁNEO DE CÉLULAS T, NO ESPECIFICADO"
    },
    {
        "id": "926db6d3-9587-9bab-b267-659808a80332",
        "NOMBRE": "LINFOMA DE BURKITT"
    },
    {
        "id": "f3254cf0-6986-ab2a-6719-424eba1cff7f",
        "NOMBRE": "LINFOMA DE CÉLULAS B EXTRANODAL DE ZONA MARGINAL DE TEJIDO LINFOIDE ASOCIADO A MUCOSAS [LINFOMA TLAM]"
    },
    {
        "id": "1cbce392-df0e-44eb-9a08-287e913ce178",
        "NOMBRE": "LINFOMA DE CÉLULAS B PEQUEÑAS"
    },
    {
        "id": "755120fd-5f0c-fd66-4b1d-c88c589e57aa",
        "NOMBRE": "LINFOMA DE CÉLULAS B, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "afe83cb8-9c2e-facb-094f-25504b9dc253",
        "NOMBRE": "LINFOMA DE CÉLULAS DEL MANTO"
    },
    {
        "id": "a7597cdb-0d97-e3e5-5472-16613003616c",
        "NOMBRE": "LINFOMA DE CÉLULAS GRANDES B DIFUSO"
    },
    {
        "id": "a8994f37-62ae-e2af-4803-99123ee06477",
        "NOMBRE": "LINFOMA DE CÉLULAS T TIPO ENTEROPATÍA (INTESTINAL)"
    },
    {
        "id": "846a1593-4b73-fdd3-2f8d-ce008eadf618",
        "NOMBRE": "LINFOMA DE CÉLULAS T TIPO PANICULITIS SUBCUTÁNEA"
    },
    {
        "id": "9e5365ee-3aa2-f5f3-aca3-90c19ff00b4f",
        "NOMBRE": "LINFOMA DE CÉLULAS T/NK MADURAS"
    },
    {
        "id": "2c75c820-e098-697b-72e4-a2c4799fdec5",
        "NOMBRE": "LINFOMA DE CÉLULAS T/NK MADURAS, NO ESPECIFICADO"
    },
    {
        "id": "762d74cc-eb03-3d24-8529-4fbdc8009e6e",
        "NOMBRE": "LINFOMA DE HODGKIN"
    },
    {
        "id": "eeef7dbb-6229-6568-1e85-514ba7284b0f",
        "NOMBRE": "LINFOMA DE HODGKIN CLÁSICO CON CELULARIDAD MIXTA"
    },
    {
        "id": "9498105c-d547-4565-5e9e-ccf317d8f807",
        "NOMBRE": "LINFOMA DE HODGKIN CLÁSICO CON DEPLECIÓN LINFOCÍTICA"
    },
    {
        "id": "2ee3240f-8881-790e-203b-156d766e36f7",
        "NOMBRE": "LINFOMA DE HODGKIN CLÁSICO CON ESCLEROSIS NODULAR"
    },
    {
        "id": "1f0d5425-ce90-0ed6-2d82-638063a05629",
        "NOMBRE": "LINFOMA DE HODGKIN CLÁSICO RICO EN LINFOCITOS"
    },
    {
        "id": "248c1c65-41dd-db37-30d9-8e5d2d77adb2",
        "NOMBRE": "LINFOMA DE HODGKIN CON PREDOMINIO LINFOCÍTICO NODULAR"
    },
    {
        "id": "3a9c933b-1a8d-f2af-9d3b-0ea63d1b01d6",
        "NOMBRE": "LINFOMA DE HODGKIN, NO ESPECIFICADO"
    },
    {
        "id": "029c5cba-166d-f7e7-89c4-6ea3ebac2003",
        "NOMBRE": "LINFOMA DE ZONA T"
    },
    {
        "id": "285275c7-6034-0cc2-a073-bea082e70265",
        "NOMBRE": "LINFOMA EXTRANODAL DE CÉLULAS T/NK, TIPO NASAL"
    },
    {
        "id": "83c18ce1-5423-e9f6-5648-58f139f2764e",
        "NOMBRE": "LINFOMA FOLICULAR"
    },
    {
        "id": "de0efbf3-348e-7b7f-b6b1-faf3c236485e",
        "NOMBRE": "LINFOMA FOLICULAR GRADO I"
    },
    {
        "id": "1b61865d-0811-2d81-224b-e67ce7092f65",
        "NOMBRE": "LINFOMA FOLICULAR GRADO II"
    },
    {
        "id": "21ae26f2-1b2d-b6c4-df68-35377a784c17",
        "NOMBRE": "LINFOMA FOLICULAR GRADO III NO ESPECIFICADO"
    },
    {
        "id": "4c154780-a967-7a0f-b238-269713f0d284",
        "NOMBRE": "LINFOMA FOLICULAR GRADO IIIA"
    },
    {
        "id": "192c7423-909a-1032-e70b-660e95c91c6a",
        "NOMBRE": "LINFOMA FOLICULAR GRADO IIIB"
    },
    {
        "id": "e5307629-66e3-b9c0-8b9d-4851bd6d672c",
        "NOMBRE": "LINFOMA FOLICULAR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "e7410a2a-fe2b-696b-b8e5-ffee68b28e4c",
        "NOMBRE": "LINFOMA HEPATOESPLÉNICO DE CÉLULAS T"
    },
    {
        "id": "3bd13e23-2223-474e-d1a0-16d36f050a68",
        "NOMBRE": "LINFOMA HISTIOCÍTICO VERDADERO"
    },
    {
        "id": "a1bfa5f8-8522-d7a5-b88d-dbdd0d71257e",
        "NOMBRE": "LINFOMA LINFOBLÁSTICO (DIFUSO)"
    },
    {
        "id": "d3f13a25-bb38-d239-30c5-2c38a5e769db",
        "NOMBRE": "LINFOMA LINFOEPITELIOIDE"
    },
    {
        "id": "57f1b58a-02d0-ac85-3c2d-b559b1770342",
        "NOMBRE": "LINFOMA MEDIASTINAL DE CÉLULAS B GRANDES (DEL TIMO)"
    },
    {
        "id": "a9bd6695-9eea-db37-c4d0-e4ff5479898b",
        "NOMBRE": "LINFOMA NO FOLICULAR"
    },
    {
        "id": "25ad7fd8-3283-fa81-5cd0-58aa72093d6c",
        "NOMBRE": "LINFOMA NO FOLICULAR (DIFUSO), SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "01706809-ab81-e7e6-04a9-c028eb252805",
        "NOMBRE": "LINFOMA NO HODGKIN DE OTRO TIPO Y EL NO ESPECIFICADO"
    },
    {
        "id": "cca8334d-55c4-011c-2458-accadada9c4a",
        "NOMBRE": "LINFOMA NO HODGKIN INDIFERENCIADO (DIFUSO)"
    },
    {
        "id": "c8fd1fd6-5628-c9ab-b2d7-82ee0da52152",
        "NOMBRE": "LINFOMA NO HODGKIN INMUNOBLÁSTICO (DIFUSO)"
    },
    {
        "id": "e2a8564b-984b-dbed-3b47-c26c3b31f779",
        "NOMBRE": "LINFOMA NO HODGKIN MIXTO, DE CÉLULAS PEQUEÑAS Y GRANDES (DIFUSO)"
    },
    {
        "id": "ff0e92a9-22b2-9183-7986-ebd58423bdbc",
        "NOMBRE": "LINFOMA NO HODGKIN, NO ESPECIFICADO"
    },
    {
        "id": "080ebe8d-84bd-b023-88f8-d74bc475222c",
        "NOMBRE": "LINFOMA PERIFÉRICO DE CÉLULAS T, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "2fdaea37-15ba-e494-1a4c-b7cdebe013b4",
        "NOMBRE": "LINFOSARCOMA"
    },
    {
        "id": "e89884ac-4530-4a7a-63a4-079f0c6e89e5",
        "NOMBRE": "LIPODISTROFIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "a0700fc5-6cb3-833b-a995-4cc5ef4432fd",
        "NOMBRE": "LIPOFUSCINOSIS CEROIDE NEURONAL"
    },
    {
        "id": "bcb4b722-ee90-25fb-a6f8-0069740e365e",
        "NOMBRE": "LIPOMATOSIS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "bc50d488-619b-763e-a35f-cbbf73b1a178",
        "NOMBRE": "LIQUEN ESCLEROSO Y ATRÓFICO"
    },
    {
        "id": "5c09f4fc-97f6-bdf7-1e77-89589885c583",
        "NOMBRE": "LIQUEN ESTRIADO"
    },
    {
        "id": "d6799888-f940-63bb-6fd5-03252882407d",
        "NOMBRE": "LIQUEN NÍTIDO"
    },
    {
        "id": "ee655bf6-0134-cd65-1718-08332e06a45f",
        "NOMBRE": "LIQUEN PLANO"
    },
    {
        "id": "f87a8e91-94be-136e-3f53-a457741a8df6",
        "NOMBRE": "LIQUEN PLANO FLICTENULAR"
    },
    {
        "id": "3ba799d6-5088-23b8-a3f6-81635b9be04f",
        "NOMBRE": "LIQUEN PLANO HIPERTRÓFICO"
    },
    {
        "id": "759eecae-cce3-d5da-1a51-d20b7729ba49",
        "NOMBRE": "LIQUEN PLANO PILARIS"
    },
    {
        "id": "c042ecbf-e888-5217-87e4-89231677ac4c",
        "NOMBRE": "LIQUEN PLANO SUBAGUDO (ACTIVO)"
    },
    {
        "id": "651d793c-5a5d-fed6-44d9-7c0b6d78d58e",
        "NOMBRE": "LIQUEN PLANO, NO ESPECIFICADO"
    },
    {
        "id": "9db49950-19e7-de77-caf1-e59a1487fc7a",
        "NOMBRE": "LIQUEN ROJO MONILIFORME"
    },
    {
        "id": "6c132b9a-eafb-9d11-9d01-cfc1cc076080",
        "NOMBRE": "LIQUEN SIMPLE CRÓNICO"
    },
    {
        "id": "c528d913-ba3e-3403-3d20-81bfd4cb9a08",
        "NOMBRE": "LIQUEN SIMPLE CRÓNICO Y PRURIGO"
    },
    {
        "id": "033758a4-5c29-e3f3-e2c0-bbdb7322a1f4",
        "NOMBRE": "LÍQUIDO ERRÓNEO USADO EN INFUSIÓN"
    },
    {
        "id": "75632b4a-a13a-c213-310e-e0f54bdcae39",
        "NOMBRE": "LISTERIOSIS"
    },
    {
        "id": "8649f32f-ac65-7763-836f-f7b9589d6f9c",
        "NOMBRE": "LISTERIOSIS CONGÉNITA (DISEMINADA)"
    },
    {
        "id": "52e62751-de46-6db8-6692-b6acd15464a0",
        "NOMBRE": "LISTERIOSIS CUTÁNEA"
    },
    {
        "id": "97f4c489-aa0c-d118-1bcd-eea96e258555",
        "NOMBRE": "LISTERIOSIS, NO ESPECIFICADA"
    },
    {
        "id": "a850a02c-dab8-e659-4c33-6519577bc0de",
        "NOMBRE": "LITIASIS URINARIA EN ESQUISTOSOMIASIS [BILHARZIASIS]"
    },
    {
        "id": "7052c57a-8de7-907a-c2c8-083ab0c0afc3",
        "NOMBRE": "LOAIASIS"
    },
    {
        "id": "7f250b75-3120-feb4-ec36-7b775f117d83",
        "NOMBRE": "LOBOMICOSIS"
    },
    {
        "id": "00f24f9d-e85d-2410-1588-4535f7d559f6",
        "NOMBRE": "LÓBULO PULMONAR SUPERNUMERARIO"
    },
    {
        "id": "31091d1f-60a4-6b24-4842-19bd65798bc2",
        "NOMBRE": "LONGITUD DESIGUAL DE LOS MIEMBROS (ADQUIRIDA)"
    },
    {
        "id": "cd0dc77b-02b1-a96f-3fd5-2897b01eed2b",
        "NOMBRE": "LORDOSIS POSTQUIRÚRGICA"
    },
    {
        "id": "97aa2dcf-f818-6ee1-f0f8-a53a1d7eb327",
        "NOMBRE": "LORDOSIS, NO ESPECIFICADA"
    },
    {
        "id": "9f8cffee-575b-ae3d-c8ec-c19d94d20828",
        "NOMBRE": "LUMBAGO CON CIÁTICA"
    },
    {
        "id": "5b8d5a8c-c9b3-0960-ce19-cfbc22b8e971",
        "NOMBRE": "LUMBAGO NO ESPECIFICADO"
    },
    {
        "id": "ee8cbe50-780f-a4a0-8356-407db2e7f37f",
        "NOMBRE": "LUPUS ERITEMATOSO"
    },
    {
        "id": "2883b245-80a4-6c10-9c43-554e2a4c636a",
        "NOMBRE": "LUPUS ERITEMATOSO CUTÁNEO SUBAGUDO"
    },
    {
        "id": "d0f02947-82b9-1c97-30c8-c4447d2e7306",
        "NOMBRE": "LUPUS ERITEMATOSO DISCOIDE"
    },
    {
        "id": "8771cfc7-859d-fa07-d503-ab5782c4c73a",
        "NOMBRE": "LUPUS ERITEMATOSO SISTÉMICO"
    },
    {
        "id": "b86c0d7b-528f-ed06-30f9-bcd41cfe0563",
        "NOMBRE": "LUPUS ERITEMATOSO SISTÉMICO CON COMPROMISO DE ÓRGANOS O SISTEMAS"
    },
    {
        "id": "a2083a63-adc2-00b8-23a7-24aa6dfd8db4",
        "NOMBRE": "LUPUS ERITEMATOSO SISTÉMICO, INDUCIDO POR DROGAS"
    },
    {
        "id": "e2fcdadb-e5b9-7f6b-5e64-c15cdad80a45",
        "NOMBRE": "LUPUS ERITEMATOSO SISTÉMICO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "6230b643-24ee-4747-7a60-24276d69f1e0",
        "NOMBRE": "LUXACIÓN CONGÉNITA DE LA CADERA, BILATERAL"
    },
    {
        "id": "f78b9ad8-9b04-e277-7aab-ae33504d07cf",
        "NOMBRE": "LUXACIÓN CONGÉNITA DE LA CADERA, NO ESPECIFICADA"
    },
    {
        "id": "a4a764d1-9614-5d4c-6051-825bdde6e776",
        "NOMBRE": "LUXACIÓN CONGÉNITA DE LA CADERA, UNILATERAL"
    },
    {
        "id": "2df7dca2-3f0c-5eb0-2198-3df1635dbf88",
        "NOMBRE": "LUXACIÓN DE ARTICULACIÓN SACROCOCCÍGEA Y SACROILIACA"
    },
    {
        "id": "900dd03f-ebd8-e4bd-fbb8-b3ce9bddc7c6",
        "NOMBRE": "LUXACIÓN DE DEDO(S) DEL PIE"
    },
    {
        "id": "d3b671b8-f183-80f8-0fcb-23af49df4992",
        "NOMBRE": "LUXACIÓN DE DEDOS DE LA MANO"
    },
    {
        "id": "d5c1cad1-112b-2930-303b-f45249c830bc",
        "NOMBRE": "LUXACIÓN DE DIENTE"
    },
    {
        "id": "7258480d-fe5a-93b1-3eb5-8a6746d884e8",
        "NOMBRE": "LUXACIÓN DE LA ARTICULACIÓN ACROMIOCLAVICULAR"
    },
    {
        "id": "c896f933-132e-2c16-29d3-5a5fb7a0c93b",
        "NOMBRE": "LUXACIÓN DE LA ARTICULACIÓN DEL HOMBRO"
    },
    {
        "id": "0f9338cc-6fcd-7f46-4cef-59202021053e",
        "NOMBRE": "LUXACIÓN DE LA ARTICULACIÓN DEL TOBILLO"
    },
    {
        "id": "113709f6-57f6-168a-8208-1efb363e5c4f",
        "NOMBRE": "LUXACIÓN DE LA ARTICULACIÓN ESTERNOCLAVICULAR"
    },
    {
        "id": "7182f509-2db8-e959-7c23-5762768c6cd2",
        "NOMBRE": "LUXACIÓN DE LA CABEZA DEL RADIO"
    },
    {
        "id": "316d3f34-bbcf-ea81-33e8-6dc9b9868e34",
        "NOMBRE": "LUXACIÓN DE LA CADERA"
    },
    {
        "id": "5ebd7bbe-1944-b975-07e0-b8efbc2d2d1c",
        "NOMBRE": "LUXACIÓN DE LA MUÑECA"
    },
    {
        "id": "c2458af8-82ea-8b58-f739-e4740bee43ef",
        "NOMBRE": "LUXACIÓN DE LA RODILLA"
    },
    {
        "id": "444014bf-1b43-8617-3fbb-55454bdeeaff",
        "NOMBRE": "LUXACIÓN DE LA RÓTULA"
    },
    {
        "id": "5e6e58c3-bb8f-2820-56dd-85f78de762ba",
        "NOMBRE": "LUXACIÓN DE OTRAS PARTES DE LA CINTURA ESCAPULAR Y DE LAS NO ESPECIFICADAS"
    },
    {
        "id": "ef61c1f0-c45d-77b7-880f-0908f03c71bf",
        "NOMBRE": "LUXACIÓN DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CABEZA"
    },
    {
        "id": "7dadcb9a-7f18-efbe-e049-ad7a29484967",
        "NOMBRE": "LUXACIÓN DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y DE LA PELVIS"
    },
    {
        "id": "ae4a2a83-5ea8-de8a-fabb-87d872c4218c",
        "NOMBRE": "LUXACIÓN DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TÓRAX"
    },
    {
        "id": "d274d219-ccea-7686-371d-c14e2591c84e",
        "NOMBRE": "LUXACIÓN DE OTROS SITIOS Y LOS NO ESPECIFICADOS DEL PIE"
    },
    {
        "id": "ddabf80e-190e-9f02-e66d-7cb74d8a25e8",
        "NOMBRE": "LUXACIÓN DE VÉRTEBRA CERVICAL"
    },
    {
        "id": "440a783d-e0c7-28da-dd5c-1ffab3484f78",
        "NOMBRE": "LUXACIÓN DE VÉRTEBRA LUMBAR"
    },
    {
        "id": "e72e96ba-c0aa-491d-a029-74907b072271",
        "NOMBRE": "LUXACIÓN DE VÉRTEBRA TORÁCICA"
    },
    {
        "id": "41ad79bc-b7a0-0cd9-b417-3332e525f6ce",
        "NOMBRE": "LUXACIÓN DEL CARTÍLAGO SEPTAL DE LA NARIZ"
    },
    {
        "id": "be1cb3b6-d5f7-2b0f-e317-d543ec188e50",
        "NOMBRE": "LUXACIÓN DEL CODO, NO ESPECIFICADA"
    },
    {
        "id": "dcb861c5-5071-6884-e450-aed6ee3339fb",
        "NOMBRE": "LUXACIÓN DEL CRISTALINO"
    },
    {
        "id": "bca56bc0-7fec-69ec-fde3-7e322951bfe6",
        "NOMBRE": "LUXACIÓN DEL MAXILAR"
    },
    {
        "id": "d93ee580-654c-56d9-5fc8-da858fad2015",
        "NOMBRE": "LUXACIÓN RECIDIVANTE DE LA RÓTULA"
    },
    {
        "id": "97b260c2-fff6-61bb-ed94-479a67f80dd6",
        "NOMBRE": "LUXACIÓN Y SUBLUXACIÓN PATOLÓGICA DE LA ARTICULACIÓN, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "649ebbb1-4b4f-6769-192b-85b385d93246",
        "NOMBRE": "LUXACIÓN Y SUBLUXACIÓN RECIDIVANTE DE LA ARTICULACIÓN"
    },
    {
        "id": "133746d5-4c51-bc54-bd6d-83c35f21c2bc",
        "NOMBRE": "LUXACIÓN, ESGUINCE O TORCEDURA DE ARTICULACIÓN O LIGAMENTO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "8dc78a09-4e22-c193-446e-a8afb03cd430",
        "NOMBRE": "LUXACIÓN, ESGUINCE O TORCEDURA DE ARTICULACIÓN Y LIGAMENTOS DEL TRONCO, NO ESPECIFICADO"
    },
    {
        "id": "acbd0def-28ca-45fc-4468-f127ceb16b0a",
        "NOMBRE": "LUXACIÓN, ESGUINCE O TORCEDURA DE ARTICULACIÓN Y LIGAMENTOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "2c992936-fe69-9bc6-8d7f-05820c071977",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y DE LIGAMENTOS DE LA CABEZA"
    },
    {
        "id": "3a3926eb-d099-5806-a2e9-84b79aca8b7e",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "898944c0-2d72-f260-fc19-cff75b86cd0a",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE LA CINTURA ESCAPULAR"
    },
    {
        "id": "ec59071d-6dcd-3f07-e90d-965eb819a0cc",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE LA COLUMNA LUMBAR Y DE LA PELVIS"
    },
    {
        "id": "3133d270-dda3-059c-d3d7-00a1e771b096",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE LA RODILLA"
    },
    {
        "id": "2d544770-8d5e-2968-99ad-7592d8453f32",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL CODO"
    },
    {
        "id": "d327f4ca-6fba-8e63-44de-8fdd8e88ab46",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL CUELLO"
    },
    {
        "id": "f0b7fa59-462f-89b1-2dff-3647afefda04",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "9dcc5825-f7a1-5717-d650-eee9525d8a6f",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL TÓRAX"
    },
    {
        "id": "3e0fc5bb-8822-08ae-6e5c-3bc4bd56a7ba",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE LA ARTICULACIÓN Y DE LOS LIGAMENTOS DE LA CADERA"
    },
    {
        "id": "f78b8826-7e90-b0b6-9b7a-b2132a26e524",
        "NOMBRE": "LUXACIÓN, ESGUINCE Y TORCEDURA DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "50d9eefd-3c99-4780-3ffd-c8a97e921231",
        "NOMBRE": "LUXACIONES DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL CUELLO"
    },
    {
        "id": "487b8dd2-2d71-da5e-b055-c0cd23252d53",
        "NOMBRE": "LUXACIONES MÚLTIPLES DE DEDOS DE LA MANO"
    },
    {
        "id": "c218165d-e17e-2ff7-db20-9263ae6d415b",
        "NOMBRE": "LUXACIONES MÚLTIPLES DEL CUELLO"
    },
    {
        "id": "7c3ae47a-c3b0-6bb1-5e75-6e112e149ceb",
        "NOMBRE": "LUXACIONES, TORCEDURAS ESGUINCES QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "67fbe39c-564f-1a54-9ccc-24b9f0ba4590",
        "NOMBRE": "LUXACIONES, TORCEDURAS Y ESGUINCES MÚLTIPLES, NO ESPECIFICADOS"
    },
    {
        "id": "f1837b20-e464-1924-41e9-a02bb4b387ee",
        "NOMBRE": "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN EL TÓRAX CON LA REGIÓN LUMBOSACRA Y LA PELVIS"
    },
    {
        "id": "93696d20-1edc-3bdf-f8cb-2fcd184869b4",
        "NOMBRE": "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN LA CABEZA CON EL CUELLO"
    },
    {
        "id": "14f036cc-c3db-726b-fe27-1174bf959794",
        "NOMBRE": "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "b3946217-9382-aa1a-c7bd-44a27d9115a0",
        "NOMBRE": "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBROS(S) SUPERIOR(ES)"
    },
    {
        "id": "8dea31fc-0d47-a396-20c2-821bd694dc8c",
        "NOMBRE": "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "54b53fd1-dc67-e305-b4ac-d4d14a9278f6",
        "NOMBRE": "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"
    },
    {
        "id": "e1158336-9a7d-e2b9-9738-742ac28175bc",
        "NOMBRE": "MACROCEFALIA"
    },
    {
        "id": "21403888-82b1-7844-dc76-1d206acac896",
        "NOMBRE": "MACROFTALMÍA"
    },
    {
        "id": "e1b9875a-c4c2-e16e-7c0e-0a75f00bc65d",
        "NOMBRE": "MACROGLOBULINEMIA DE WALDENSTRÖM"
    },
    {
        "id": "2b40f329-c814-043e-ba0d-b15315b9216f",
        "NOMBRE": "MACROGLOSIA"
    },
    {
        "id": "125f3934-47cb-abc7-3738-6fd8764b342d",
        "NOMBRE": "MACROQUEILIA"
    },
    {
        "id": "ffecc377-35c0-d9f9-f37c-447dd390d433",
        "NOMBRE": "MACROSTOMÍA"
    },
    {
        "id": "3a6707d3-16ee-9275-7208-c01423b3af7b",
        "NOMBRE": "MACROTIA"
    },
    {
        "id": "993283e0-97f7-3df9-7770-54376e99cb6e",
        "NOMBRE": "MAL DEL MOVIMIENTO"
    },
    {
        "id": "7b7261ea-661a-1b1f-ada9-461ed57cc6bf",
        "NOMBRE": "MAL FUNCIONAMIENTO DE ESTOMA EXTERNO DE VÍAS URINARIAS"
    },
    {
        "id": "fe38c12d-3352-38a2-bfab-4974aadb6b48",
        "NOMBRE": "MALA POSICIÓN DEL URÉTER"
    },
    {
        "id": "c072746a-ccf2-2e39-d426-121f36013632",
        "NOMBRE": "MALA POSICION DEL ÚTERO"
    },
    {
        "id": "7a234f32-8520-1c67-ff16-1b1535320ae5",
        "NOMBRE": "MALABSORCIÓN DEBIDA A INTOLERANCIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "05756441-1f16-1d1b-b2aa-6359fecd922a",
        "NOMBRE": "MALABSORCIÓN INTESTINAL"
    },
    {
        "id": "d07e045a-5999-79b3-907c-29acb49a7011",
        "NOMBRE": "MALABSORCIÓN INTESTINAL, NO ESPECIFICADA"
    },
    {
        "id": "dda6e0cb-d73f-d152-c91b-a2e9c00a179b",
        "NOMBRE": "MALABSORCIÓN POSTQUIRÚRGICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "0deceb25-9add-c90f-4866-724b476e0e39",
        "NOMBRE": "MALESTAR Y FATIGA"
    },
    {
        "id": "94775aa7-430a-a0a0-ffe8-1230c89b308d",
        "NOMBRE": "MALFORMACIÓN ARTERIOVENOSA DE LOS VASOS CEREBRALES"
    },
    {
        "id": "30f40882-0b36-1273-f83c-18b0bde4ee31",
        "NOMBRE": "MALFORMACIÓN ARTERIOVENOSA DE LOS VASOS PRECEREBRALES"
    },
    {
        "id": "634d5956-6601-2c73-4a0f-cd3acfa99e5b",
        "NOMBRE": "MALFORMACIÓN ARTERIOVENOSA PERIFÉRICA"
    },
    {
        "id": "33282e5e-b8ec-65d8-a4ba-ee4b0408800f",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA CARA Y DEL CUELLO, NO ESPECIFICADA"
    },
    {
        "id": "0784cfe9-ea7e-f037-feff-7df29910f731",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA COROIDES"
    },
    {
        "id": "a209c85a-4675-4da9-6411-93911029c084",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA LARINGE, NO ESPECIFICADA"
    },
    {
        "id": "f877ee5b-b39a-b3db-eea7-69a37702a961",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA MAMA, NO ESPECIFICADA"
    },
    {
        "id": "a3a71bf3-e526-474e-2147-30e8849a96ea",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA MÉDULA ESPINAL, NO ESPECIFICADA"
    },
    {
        "id": "2daa7c2e-1b7a-1a10-dafa-d828287dc96c",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA NARIZ, NO ESPECIFICADA"
    },
    {
        "id": "2e502326-0b09-77e5-c938-a62df5367d91",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA ÓRBITA"
    },
    {
        "id": "c68166b0-7663-a070-e5de-3966829eda3b",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA PARTE SUPERIOR DEL TUBO DIGESTIVO, NO ESPECIFICADA"
    },
    {
        "id": "70b48bc4-f57d-0259-4358-40f6665bdb19",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA PIEL, NO ESPECIFICADA"
    },
    {
        "id": "4a03bec5-e2b2-71d7-07b5-54bdd24f0439",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA RETINA"
    },
    {
        "id": "86457e43-05cd-e416-64ee-97132b13d581",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA RODILLA"
    },
    {
        "id": "30d34919-9ded-f553-4a65-de87068aa56e",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LA VÁLVULA TRICÚSPIDE, NO ESPECIFICADA"
    },
    {
        "id": "58f2217e-fc81-5f52-b65c-df8ef7893582",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LAS CÁMARAS CARDÍACAS Y SUS CONEXIONES, NO ESPECIFICADA"
    },
    {
        "id": "82275aae-2934-60e8-f326-31d565a91aeb",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LAS FANERAS, NO ESPECIFICADA"
    },
    {
        "id": "5db6264c-f935-4974-6e64-743f234f82ce",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LAS GRANDES ARTERIAS, NO ESPECIFICADA"
    },
    {
        "id": "ca60112e-6c01-f821-2276-405d05981421",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LAS GRANDES VENAS, NO ESPECIFICADA"
    },
    {
        "id": "f22a7194-0e70-1a29-ad36-3647d846b683",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LAS VÁLVULAS AÓRTICA Y MITRAL, NO ESPECIFICADA"
    },
    {
        "id": "e2ab760e-df27-1af3-a289-12c1b219604c",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LOS GENITALES FEMENINOS, NO ESPECIFICADA"
    },
    {
        "id": "152f059f-8fe0-eff9-b9ff-708e5e777817",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LOS HUESECILLOS DEL OÍDO"
    },
    {
        "id": "77150ad8-f183-ac67-f9ac-a228931221e1",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE LOS ÓRGANOS GENITALES MASCULINOS, NO ESPECIFICADA"
    },
    {
        "id": "978e9e50-6866-1b5a-777f-451d3bbe104b",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DE MIEMBRO(S), NO ESPECIFICADA"
    },
    {
        "id": "5c42175e-cbb7-17e0-3637-f0afabfb4020",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL APARATO URINARIO, NO ESPECIFICADA"
    },
    {
        "id": "5f9bb48a-548b-26a4-b540-77aaccd1de17",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL CLÍTORIS"
    },
    {
        "id": "5e073ca0-c5e7-4631-f1c0-f7b1f7f4b453",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL CORAZÓN, NO ESPECIFICADA"
    },
    {
        "id": "5a68b6e6-9e92-8aed-a02c-4bccc205ea7a",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL CRISTALINO, NO ESPECIFICADA"
    },
    {
        "id": "c238c639-be82-dd92-5709-d8b555de1c36",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL DISCO ÓPTICO"
    },
    {
        "id": "275bada2-01ae-fff4-1736-120c80d87066",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL ENCÉFALO, NO ESPECIFICADA"
    },
    {
        "id": "16c9de28-e26a-e3ca-399a-115305e0fe5c",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL ESÓFAGO, NO ESPECIFICADA"
    },
    {
        "id": "02dce0a8-faff-fe36-eee1-18c07e5dbeaf",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL ESTERNÓN"
    },
    {
        "id": "be53402a-d7ac-c851-2154-75b7b8704941",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL ESTÓMAGO, NO ESPECIFICADA"
    },
    {
        "id": "442703a6-c938-3b06-b58c-16bc051a91e6",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL HUMOR VÍTREO"
    },
    {
        "id": "cee543f2-8c62-7288-bcee-1fb4ce33472f",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL INTESTINO, NO ESPECIFICADA"
    },
    {
        "id": "9c5a1f47-0447-41d6-dc5f-fe2b319fd030",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL OÍDO INTERNO"
    },
    {
        "id": "1267d0e7-3653-82c7-645b-91d0835fead1",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL OÍDO QUE CAUSA ALTERACIÓN DE LA AUDICIÓN, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "dd562061-7156-80a8-6098-dc590506c541",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL OÍDO, NO ESPECIFICADA"
    },
    {
        "id": "003d09c9-3032-fec8-26fe-b119af01b9e6",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL PULMÓN, NO ESPECIFICADA"
    },
    {
        "id": "c76f3aed-85c1-fe6c-1e70-f9001c4d452e",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL RIÑÓN, NO ESPECIFICADA"
    },
    {
        "id": "5e8a4f4f-1169-33df-7cab-ca8602d56b03",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL SEGMENTO ANTERIOR DEL OJO, NO ESPECIFICADA"
    },
    {
        "id": "873ac08f-1a99-3267-7fde-d9cc80aea778",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL SEGMENTO POSTERIOR DEL OJO, NO ESPECIFICADA"
    },
    {
        "id": "9b83f2e7-0d40-c4b0-cfe8-c207d23663c2",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL SISTEMA CIRCULATORIO, NO ESPECIFICADA"
    },
    {
        "id": "3755fdd7-5453-78e6-ece9-6db3c5e5397b",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL SISTEMA DIGESTIVO, NO ESPECIFICADA"
    },
    {
        "id": "43d1bb44-c281-2b24-d97a-11e8e86007f3",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL SISTEMA OSTEOMUSCULAR, NO ESPECIFICADA"
    },
    {
        "id": "847bb06e-1edf-44fc-2d24-67b1dab1d0a3",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL SISTEMA RESPIRATORIO, NO ESPECIFICADA"
    },
    {
        "id": "8c657820-fa1d-8bf8-f964-d1857c688956",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL SISTEMA VASCULAR PERIFÉRICO, NO ESPECIFICADA"
    },
    {
        "id": "eaef561e-76c0-3074-5bcd-7dbb5fa88039",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL TABIQUE CARDÍACO, NO ESPECIFICADA"
    },
    {
        "id": "0fb10d79-2877-dbf8-a40f-3df75f32a10d",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL TÓRAX ÓSEO, NO ESPECIFICADA"
    },
    {
        "id": "2f595f04-bd4b-734f-9c42-40ae914172ef",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA DEL ÚTERO Y DEL CUELLO UTERINO, NO ESPECIFICADA"
    },
    {
        "id": "5c6019c0-c4bd-2341-9920-b2bf5a6d7d86",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA NO ESPECIFICADA DE LOS HUESOS DEL CRÁNEO Y DE LA CARA"
    },
    {
        "id": "de910cd1-ed2c-62ae-cc48-65b8aa3f5753",
        "NOMBRE": "MALFORMACIÓN CONGÉNITA, NO ESPECIFICADA"
    },
    {
        "id": "bd73d95c-5e63-6c07-7656-1830db95787c",
        "NOMBRE": "MALFORMACIÓN DE LA PLACENTA"
    },
    {
        "id": "b5a02635-b1c1-cd0c-6f02-c328d2a8d1ef",
        "NOMBRE": "MALFORMACIÓN DE LOS VASOS CORONARIOS"
    },
    {
        "id": "95e9dceb-f4f8-c6b6-febf-2d4986473aff",
        "NOMBRE": "MALFORMACIÓN DEL URACO"
    },
    {
        "id": "ad8d240b-7c8b-efe8-94b4-2dc1a03809de",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA COLUMNA VERTEBRAL Y TÓRAX ÓSEO"
    },
    {
        "id": "2933ac1d-1905-5fb1-327e-984fdef82129",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA FIJACIÓN DEL INTESTINO"
    },
    {
        "id": "b4d0c603-6be8-bc2a-dc24-9bdee3a38842",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "2c67c4ae-06e5-49cf-a56b-7df0cc0cae37",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA LARINGE"
    },
    {
        "id": "8839aafb-116d-8e4b-f9d3-b1ebc76b7d0e",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA MAMA"
    },
    {
        "id": "e60eac10-8be2-7b61-b6c1-36df7c8fb45c",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA NARIZ"
    },
    {
        "id": "829e9430-eefb-920c-b36f-f1b141bb0afb",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA TRÁQUEA Y DE LOS BRONQUIOS"
    },
    {
        "id": "2a4d3aaf-c00a-6a55-e808-c083b5f2ebef",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA VÁLVULAS PULMONAR Y TRICÚSPIDE"
    },
    {
        "id": "b2c62c31-8737-d3ae-0b4b-6ee4d33b7945",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LA VESÍCULA BILIAR, DE LOS CONDUCTOS BILIARES Y DEL HÍGADO"
    },
    {
        "id": "fbbd7e62-48df-a85e-6f97-be330c0ed662",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LAS CÁMARAS CARDÍACAS Y SUS CONEXIONES"
    },
    {
        "id": "c93ef5a1-e8ff-3ed2-2ade-07958c861ff9",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LAS GLÁNDULAS Y DE LOS CONDUCTOS SALIVALES"
    },
    {
        "id": "20650c8e-791b-07af-2353-41851585875b",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LAS GRANDES ARTERIAS"
    },
    {
        "id": "07060dc1-96dd-8d8b-33c4-74405cbb3919",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LAS GRANDES VENAS"
    },
    {
        "id": "a7349ac6-6f24-6221-6200-15a6c98707aa",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LAS VÁLVULAS AÓRTICA Y MITRAL"
    },
    {
        "id": "8894d173-7a36-6bc4-13b3-e6e49a202c0d",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LOS LABIOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6085c49b-eb41-29a7-7b0a-afc44fcd5877",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LOS OVARIOS, DE LAS TROMPAS DE FALOPIO Y DE LOS LIGAMENTOS ANCHOS"
    },
    {
        "id": "e933b39a-bc5c-e219-e369-88612f51ca0c",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LOS PÁRPADOS, DEL APARATO LAGRIMAL Y DE LA ÓRBITA"
    },
    {
        "id": "3d8e549b-620c-141c-0c9d-79d3864b9880",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE LOS TABIQUES CARDÍACOS"
    },
    {
        "id": "8d6d4690-504d-052d-f5f1-bf85c38f04ab",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DE OTRAS GLÁNDULAS ENDOCRINAS"
    },
    {
        "id": "01865dae-16be-9b6d-7abc-0a4cc0715c5c",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL BAZO"
    },
    {
        "id": "e8fd2f84-f9d6-030b-c906-bbafdd3b6966",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL CRISTALINO"
    },
    {
        "id": "73fca6f3-5bd0-7c80-a594-cc6ebeda6314",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL CUERPO CALLOSO"
    },
    {
        "id": "1c13a734-6893-8eec-cc40-95e5c5740591",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL ESÓFAGO"
    },
    {
        "id": "c55c7d3d-0301-5a1c-fd20-8062e7e6eaf9",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL OÍDO QUE CAUSAN LA ALTERACIÓN DE LA AUDICIÓN"
    },
    {
        "id": "a49f026a-aa11-cad5-c117-f89e43d4803b",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL OJO, NO ESPECIFICADAS"
    },
    {
        "id": "5f08c5ed-7a3d-eb3d-8688-26814187663c",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL PALADAR, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ba532859-1fb7-5f79-c733-b5b3c6293820",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL PULMÓN"
    },
    {
        "id": "7c113f9d-b9ae-f785-98fd-eba7acbc0774",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL SEGMENTO ANTERIOR DEL OJO"
    },
    {
        "id": "4b03fff8-236d-a3e1-747c-c455c7a2d3fa",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL SEGMENTO POSTERIOR DEL OJO"
    },
    {
        "id": "75e4e6fd-94ca-8d41-16ae-b9892efcb7b4",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL SISTEMA OSTEOMUSCULAR, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1815fb4d-ebc3-aec6-14ca-0fdacd9d8bde",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS DEL ÚTERO Y DEL CUELLO UTERINO"
    },
    {
        "id": "292d7aa5-118e-2fdb-d58d-178865006e3d",
        "NOMBRE": "MALFORMACIONES CONGÉNITAS MÚLTIPLES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4c01b41c-b293-8a51-9cc1-0081012d3b0d",
        "NOMBRE": "MALFORMACIONES DEL ACUEDUCTO DE SILVIO"
    },
    {
        "id": "0d31c485-0a73-e0ea-630a-9ba6ae436311",
        "NOMBRE": "MALOCLUSIÓN DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "7d36ab58-f019-9cce-6867-f9d168dd492d",
        "NOMBRE": "MAMA SUPERNUMERARIA"
    },
    {
        "id": "a2fdf20a-7392-4dd4-e002-90c9e16944c8",
        "NOMBRE": "MANCHAS CAFÉ CON LECHE"
    },
    {
        "id": "fa58d213-0bcf-28b7-d530-cb1ca039bc51",
        "NOMBRE": "MANÍA CON SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "f2ac8eab-1876-3ca5-0c2e-7c68a23c8cfa",
        "NOMBRE": "MANÍA SIN SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "eff363bf-d957-d32b-f0da-2e04b0d28af9",
        "NOMBRE": "MANIFESTACIONES PULMONARES AGUDAS DEBIDAS A RADIACIÓN"
    },
    {
        "id": "ce668cb7-881e-9c61-7f67-77b12b26535d",
        "NOMBRE": "MANIFESTACIONES PULMONARES CRÓNICAS Y OTRAS MANIFESTACIONES DEBIDAS A RADIACIÓN"
    },
    {
        "id": "56a768da-c883-5538-c6a8-a556aa414c4a",
        "NOMBRE": "MANO EN PINZA DE LANGOSTA"
    },
    {
        "id": "1636bc7c-a285-50e1-0fe3-21e91eb285ff",
        "NOMBRE": "MANO O PIE EN GARRA O EN TALIPES, PIE EQUINOVARO O ZAMBO ADQUIRIDOS"
    },
    {
        "id": "dfc55daf-d52d-4838-707f-d99b0599cf3e",
        "NOMBRE": "MANO Y PIE DE INMERSIÓN"
    },
    {
        "id": "6fbe52f3-95ea-50b0-aa58-80aca3f2c0e9",
        "NOMBRE": "MANSONELIASIS"
    },
    {
        "id": "5d5e7852-e199-13d8-f7f0-4aa140f92cf2",
        "NOMBRE": "MARASMO NUTRICIONAL"
    },
    {
        "id": "42707d32-916d-59c3-1e61-a377f39ebed8",
        "NOMBRE": "MARCAS RESIDUALES EN LA PIEL, DE HEMORROIDES"
    },
    {
        "id": "21399202-7f61-2f89-c2b9-56ec599cd050",
        "NOMBRE": "MARCHA ATÁXICA"
    },
    {
        "id": "38c75a30-a484-682c-31a8-2e1c529cc6df",
        "NOMBRE": "MARCHA PARALÍTICA"
    },
    {
        "id": "125f35d2-8ad7-c8e7-0f9c-76ed43caeb65",
        "NOMBRE": "MAREO Y DESVANECIMIENTO"
    },
    {
        "id": "0b20ea7a-5741-ec10-358d-7713a8c547f5",
        "NOMBRE": "MASA NO ESPECIFICADA EN LA MAMA"
    },
    {
        "id": "a7a8ce19-337a-7c74-b8d1-7b8161d08b29",
        "NOMBRE": "MASTITIS INFECCIOSA NEONATAL"
    },
    {
        "id": "dc8758ba-3ec6-4387-d97b-789059c5ba07",
        "NOMBRE": "MASTITIS NO PURULENTA ASOCIADA CON EL PARTO"
    },
    {
        "id": "a10ec84a-afef-da56-4633-87d25d7c3f74",
        "NOMBRE": "MASTOCITOSIS"
    },
    {
        "id": "6f08caac-9042-c68d-0859-4bc14d641bfe",
        "NOMBRE": "MASTODINIA"
    },
    {
        "id": "c6bbf59a-39ce-7c21-854b-707920f4f3e7",
        "NOMBRE": "MASTOIDITIS AGUDA"
    },
    {
        "id": "23265fe4-ff04-140b-d5cc-a83bc97fd7e8",
        "NOMBRE": "MASTOIDITIS CRÓNICA"
    },
    {
        "id": "79635f20-2f44-409d-3568-247306e73ae9",
        "NOMBRE": "MASTOIDITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9077e238-fa9e-c7b7-5f49-3afb460de053",
        "NOMBRE": "MASTOIDITIS Y AFECCIONES RELACIONADAS"
    },
    {
        "id": "9cd267a3-b7a0-a58a-997f-c94b2cbe17fa",
        "NOMBRE": "MASTOIDITIS, NO ESPECIFICADA"
    },
    {
        "id": "56f43733-4df2-b7fa-7193-696ac3a3e94b",
        "NOMBRE": "MASTOPATÍA QUÍSTICA DIFUSA"
    },
    {
        "id": "a9f238aa-dd87-6d5b-e47e-1fd727cfa2a9",
        "NOMBRE": "MEDICAMENTO O SUSTANCIA BIOLÓGICA CONTAMINADO EN INFUSIÓN O TRANSFUSIÓN"
    },
    {
        "id": "a62aac7c-3ff4-c1de-e553-13c4a03b9c98",
        "NOMBRE": "MEDICAMENTO O SUSTANCIA BIOLÓGICA CONTAMINADO, ADMINISTRADO POR MEDIOS NO ESPECIFICADOS"
    },
    {
        "id": "ab438918-b21e-e13c-a28f-f1c277c26751",
        "NOMBRE": "MEDICAMENTO O SUSTANCIA BIOLÓGICA CONTAMINADO, ADMINISTRADO POR OTROS MEDIOS"
    },
    {
        "id": "283f7e53-722c-3549-0390-3e61b30473bc",
        "NOMBRE": "MEDICAMENTO O SUSTANCIA BIOLÓGICA CONTAMINADO, INYECTADO O USADO PARA INMUNIZACIÓN"
    },
    {
        "id": "c08c3007-be76-8e0e-1138-c4dad828b651",
        "NOMBRE": "MEDICAMENTOS O SUSTANCIAS BIOLÓGICAS CONTAMINADOS"
    },
    {
        "id": "c4066f29-0658-688d-4113-cd26f7ac693e",
        "NOMBRE": "MEDIDA PROFILÁCTICA NO ESPECIFICADA"
    },
    {
        "id": "bed9cfea-968a-9d54-a48f-978b2c701277",
        "NOMBRE": "MEGACOLON EN LA ENFERMEDAD DE CHAGAS"
    },
    {
        "id": "378d6578-a82b-5cf5-a912-8665d1789334",
        "NOMBRE": "MEGACOLON, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "638f0e47-288b-3694-ff93-d5685a5120f1",
        "NOMBRE": "MEGAESÓFAGO EN LA ENFERMEDAD DE CHAGAS"
    },
    {
        "id": "92a22ed3-df0b-6eba-d459-7302bcdbff86",
        "NOMBRE": "MEGALENCEFALIA"
    },
    {
        "id": "84de53fd-5b79-ef76-a378-040ae0e98d16",
        "NOMBRE": "MEGALOURÉTER CONGÉNITO"
    },
    {
        "id": "7934d96c-87c5-9f8e-ad1d-d450f6e43c3b",
        "NOMBRE": "MELANOMA IN SITU"
    },
    {
        "id": "ef3a4782-141f-3617-7479-418a5b261f0b",
        "NOMBRE": "MELANOMA IN SITU DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"
    },
    {
        "id": "16e2396e-5883-bfd5-6a05-0d404dcb2e94",
        "NOMBRE": "MELANOMA IN SITU DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"
    },
    {
        "id": "fa5fdf6a-d2b9-6d38-68a6-e58883e8f9bd",
        "NOMBRE": "MELANOMA IN SITU DE OTROS SITIOS"
    },
    {
        "id": "7f9ec28c-2116-ca4c-6102-2e48831aae7e",
        "NOMBRE": "MELANOMA IN SITU DEL CUERO CABELLUDO Y DEL CUELLO"
    },
    {
        "id": "4826537b-0ecd-927a-e769-af51a774955e",
        "NOMBRE": "MELANOMA IN SITU DEL LABIO"
    },
    {
        "id": "0f06e168-2373-a9a7-3bb0-e0d892e817b2",
        "NOMBRE": "MELANOMA IN SITU DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "663eec4e-8641-023c-d049-d04df0ea7a98",
        "NOMBRE": "MELANOMA IN SITU DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "0b1db835-db1c-30a4-05f1-20c68933314c",
        "NOMBRE": "MELANOMA IN SITU DEL PÁRPADO Y DE LA COMISURA PALPEBRAL"
    },
    {
        "id": "1298b675-13de-d7c9-bc19-9e8f1ef1a01f",
        "NOMBRE": "MELANOMA IN SITU DEL TRONCO"
    },
    {
        "id": "9ae400a5-7f15-bf0d-3a6d-c81cbca96fd0",
        "NOMBRE": "MELANOMA IN SITU, SITIO NO ESPECIFICADO"
    },
    {
        "id": "82a128df-39ea-f0dc-9545-f70d4deb10c0",
        "NOMBRE": "MELANOMA MALIGNO DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"
    },
    {
        "id": "523c9e90-b35e-8b73-7052-da9c7d1274ba",
        "NOMBRE": "MELANOMA MALIGNO DE LA PIEL"
    },
    {
        "id": "927939e0-cdc6-0838-535e-d718f4b7dfd8",
        "NOMBRE": "MELANOMA MALIGNO DE LAS OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA CARA"
    },
    {
        "id": "db240d0c-94ed-6158-1e64-e121a31de9e6",
        "NOMBRE": "MELANOMA MALIGNO DE PIEL, SITIO NO ESPECIFICADO"
    },
    {
        "id": "7e6af130-34a8-c5df-20e8-744e7e68e968",
        "NOMBRE": "MELANOMA MALIGNO DE SITIOS CONTIGUOS DE LA PIEL"
    },
    {
        "id": "2bbb2965-7606-825b-58b0-78e90c6dfd60",
        "NOMBRE": "MELANOMA MALIGNO DEL CUERO CABELLUDO Y DEL CUELLO"
    },
    {
        "id": "c8bd6b30-7446-c64c-b15b-c908faf92340",
        "NOMBRE": "MELANOMA MALIGNO DEL LABIO"
    },
    {
        "id": "48bf256f-7b10-1f80-3b6e-e43650ccf231",
        "NOMBRE": "MELANOMA MALIGNO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "bf5c2859-60fc-7301-1e38-aca32ae7479d",
        "NOMBRE": "MELANOMA MALIGNO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "88a3a4a8-e424-9116-4947-e70e4e3a04d5",
        "NOMBRE": "MELANOMA MALIGNO DEL PÁRPADO, INCLUIDA LA COMISURA PALPEBRAL"
    },
    {
        "id": "1b4db36d-0f34-3ba4-475b-38ba63a934f7",
        "NOMBRE": "MELANOMA MALIGNO DEL TRONCO"
    },
    {
        "id": "0408734e-f47b-8e21-c41e-c75f263eff08",
        "NOMBRE": "MELENA"
    },
    {
        "id": "b26e6bf5-8826-5d96-10b2-8ddec13b5af9",
        "NOMBRE": "MELENA NEONATAL"
    },
    {
        "id": "06b437b3-89e6-678a-577c-6adf89ca25b0",
        "NOMBRE": "MELIOIDOSIS AGUDA Y FULMINANTE"
    },
    {
        "id": "d26d8307-7f30-d197-2041-3686566133a8",
        "NOMBRE": "MELIOIDOSIS SUBAGUDA Y CRÓNICA"
    },
    {
        "id": "3c9ea3be-caab-dfc8-45e7-f8fd77e367a3",
        "NOMBRE": "MELIOIDOSIS, NO ESPECIFICADA"
    },
    {
        "id": "dacef109-3570-a15c-4ca6-8b806e3cdd75",
        "NOMBRE": "MEMBRANA INTERDIGITAL DE LA MANO"
    },
    {
        "id": "bcc93e8e-1b17-f5bb-a178-cbb20cbc1dc7",
        "NOMBRE": "MEMBRANA INTERDIGITAL DEL PIE"
    },
    {
        "id": "c7b47ae3-9a6a-88b1-7a44-94724089f78b",
        "NOMBRE": "MEMBRANAS PUPILARES"
    },
    {
        "id": "d5e4c0fd-a6b4-97eb-db29-64e75fb2be89",
        "NOMBRE": "MENINGISMO"
    },
    {
        "id": "dd426901-5370-c168-8a5e-a45765275626",
        "NOMBRE": "MENINGITIS APIÓGENA"
    },
    {
        "id": "5f85366a-b1dd-66a4-cd44-c449ea51aa87",
        "NOMBRE": "MENINGITIS BACTERIANA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "954f0ce5-ecc9-877b-db2c-c76796865f1b",
        "NOMBRE": "MENINGITIS BACTERIANA, NO ESPECIFICADA"
    },
    {
        "id": "bf3c5061-79d9-65e1-477b-723831f939c6",
        "NOMBRE": "MENINGITIS CRÓNICA"
    },
    {
        "id": "0f55f713-b32e-a7ab-a97b-c030150feb15",
        "NOMBRE": "MENINGITIS DEBIDA A ADENOVIRUS"
    },
    {
        "id": "723bbe56-ddc9-c069-1ce5-744d65bd10bc",
        "NOMBRE": "MENINGITIS DEBIDA A CANDIDA"
    },
    {
        "id": "7e9ea0ff-2d98-9598-fa5f-1ef93e669d0b",
        "NOMBRE": "MENINGITIS DEBIDA A COCCIDIOIDOMICOSIS"
    },
    {
        "id": "54de6b87-738f-b4b7-85dc-2ae206add61e",
        "NOMBRE": "MENINGITIS DEBIDA A HERPES ZOSTER"
    },
    {
        "id": "36771c5c-7f36-7a6b-18fb-0cec211996a7",
        "NOMBRE": "MENINGITIS DEBIDA A OTRAS CAUSAS Y A LAS NO ESPECIFICADAS"
    },
    {
        "id": "7d0dc6f6-2d85-8b9c-6f2d-12c7d7f3b01c",
        "NOMBRE": "MENINGITIS DEBIDA A VARICELA"
    },
    {
        "id": "f176398a-5843-294b-53b9-c5c75fbd9168",
        "NOMBRE": "MENINGITIS DEBIDAS A OTRAS CAUSAS ESPECIFICADAS"
    },
    {
        "id": "84e9c166-0b45-151b-a227-658f73de658f",
        "NOMBRE": "MENINGITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b534bd66-4952-175e-1dc3-6cbedb2ce613",
        "NOMBRE": "MENINGITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b0f11d04-a3e4-1bdc-5183-9c0084e8fade",
        "NOMBRE": "MENINGITIS EN MICOSIS"
    },
    {
        "id": "d6f54aac-c0cb-c1e0-512c-a8ec05670b96",
        "NOMBRE": "MENINGITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "86faaa01-5b87-4732-3436-3943aa3741a3",
        "NOMBRE": "MENINGITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ecc56eb6-44cd-3c19-1666-716ec415476a",
        "NOMBRE": "MENINGITIS ENTEROVIRAL"
    },
    {
        "id": "32893633-faaa-0cf2-fb34-2ffaecb34766",
        "NOMBRE": "MENINGITIS ESTAFILOCÓCICA"
    },
    {
        "id": "502b0cd9-61a1-749a-e80e-46f27236dccb",
        "NOMBRE": "MENINGITIS ESTREPTOCÓCICA"
    },
    {
        "id": "12104ef4-57af-9a75-5267-aacee8245eeb",
        "NOMBRE": "MENINGITIS HERPÉTICA"
    },
    {
        "id": "eaf46c98-bffb-df92-45b4-574fbfdddc3a",
        "NOMBRE": "MENINGITIS MENINGOCÓCICA"
    },
    {
        "id": "e7a16edd-b13f-d005-aaa9-a49193be689b",
        "NOMBRE": "MENINGITIS NEUMOCÓCICA"
    },
    {
        "id": "27ad51b4-294b-3031-452f-98b5a436aebf",
        "NOMBRE": "MENINGITIS POR HEMÓFILOS"
    },
    {
        "id": "0bafae57-99dc-8d28-1a66-f1f871a5c423",
        "NOMBRE": "MENINGITIS POR PAROTIDITIS"
    },
    {
        "id": "451bfa31-c4c6-b1c4-ae80-acb91101617e",
        "NOMBRE": "MENINGITIS POR PESTE"
    },
    {
        "id": "4eecd62e-fbe5-eb25-cdbe-7aab8e7e1210",
        "NOMBRE": "MENINGITIS RECURRENTE BENIGNA [MOLLARET]"
    },
    {
        "id": "57a846ec-c01a-625a-b66b-5d737ceea417",
        "NOMBRE": "MENINGITIS TUBERCULOSA"
    },
    {
        "id": "68a9ff6f-d751-80c2-913a-0229692a3a92",
        "NOMBRE": "MENINGITIS VIRAL"
    },
    {
        "id": "b10a0f71-22c9-d2a6-84de-4628a6962e9d",
        "NOMBRE": "MENINGITIS VIRAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "0ed98eb5-1a10-3a1b-63cb-7e302acc0d4d",
        "NOMBRE": "MENINGITIS Y MENINGOENCEFALITIS LISTERIANA"
    },
    {
        "id": "0f7930ba-e637-1048-5a11-57eb8e37b680",
        "NOMBRE": "MENINGITIS, NO ESPECIFICADA"
    },
    {
        "id": "7cc14d7f-7a43-4c30-b1a1-83cbd691134d",
        "NOMBRE": "MENINGOCOCEMIA AGUDA"
    },
    {
        "id": "dcb7f6ae-486b-37d6-6706-d909fe95ce17",
        "NOMBRE": "MENINGOCOCEMIA CRÓNICA"
    },
    {
        "id": "999bdba6-932f-9f44-6b9e-60beaf9c31d4",
        "NOMBRE": "MENINGOCOCEMIA, NO ESPECIFICADA"
    },
    {
        "id": "bbfb1c88-98a2-716e-bb8f-ff13358d42f0",
        "NOMBRE": "MENINGOENCEFALITIS DEBIDA A TOXOPLASMA"
    },
    {
        "id": "fe352162-344c-a5e3-8a0e-4fe2533c0cd9",
        "NOMBRE": "MENINGOENCEFALITIS Y MENINGOMIELITIS BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5e29b0c7-b63a-eb80-aed3-ee6b41dd922c",
        "NOMBRE": "MENISCO DISCOIDE (CONGÉNITO)"
    },
    {
        "id": "2db54344-387e-6b9a-e9b6-869c8f84c893",
        "NOMBRE": "MENISCO QUÍSTICO"
    },
    {
        "id": "fdf0c505-1914-faf7-dde2-868dfef1a947",
        "NOMBRE": "MENSTRUACIÓN AUSENTE, ESCASA O RARA"
    },
    {
        "id": "f84ea6d5-50ca-f262-2af0-118f265bdbb7",
        "NOMBRE": "MENSTRUACIÓN EXCESIVA EN LA PUBERTAD"
    },
    {
        "id": "eb7d6d5d-2161-4fe5-8610-1c0bb13d3a1f",
        "NOMBRE": "MENSTRUACIÓN EXCESIVA Y FRECUENTE CON CICLO IRREGULAR"
    },
    {
        "id": "ff662c30-dbed-6bd9-5020-7bdb65a4d37b",
        "NOMBRE": "MENSTRUACIÓN EXCESIVA Y FRECUENTE CON CICLO REGULAR"
    },
    {
        "id": "fecb4f94-3c43-db1a-10c7-dd13c00d6043",
        "NOMBRE": "MENSTRUACIÓN EXCESIVA, FRECUENTE E IRREGULAR"
    },
    {
        "id": "684385c6-b2e1-0c71-9318-7942e51af3fb",
        "NOMBRE": "MENSTRUACIÓN IRREGULAR, NO ESPECIFICADA"
    },
    {
        "id": "e9463949-254d-b805-2f89-81b3952f48dd",
        "NOMBRE": "MERALGIA PARESTÉSICA"
    },
    {
        "id": "bb5a51ef-73a6-cf99-9d8f-46cf802b3f84",
        "NOMBRE": "MESOTELIOMA"
    },
    {
        "id": "eb391e9c-7cf9-5454-55b9-dd8f26e40ae1",
        "NOMBRE": "MESOTELIOMA DE LA PLEURA"
    },
    {
        "id": "6ae79aa8-bcf2-89e3-44dc-59bd01e52ccb",
        "NOMBRE": "MESOTELIOMA DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "38b3c49d-e78b-3185-2ca0-955763f06dab",
        "NOMBRE": "MESOTELIOMA DEL PERICARDIO"
    },
    {
        "id": "2536c276-03af-e68f-e164-f78d1c4a9ad9",
        "NOMBRE": "MESOTELIOMA DEL PERITONEO"
    },
    {
        "id": "651fadfb-4049-04a4-46ca-b1b76c4fb2ca",
        "NOMBRE": "MESOTELIOMA, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "9d8ed48b-de12-26ff-efe6-d57205109886",
        "NOMBRE": "METAHEMOGLOBINEMIA"
    },
    {
        "id": "b119a67c-a80a-d0d5-a993-e5bcdba115a3",
        "NOMBRE": "METAHEMOGLOBINEMIA CONGÉNITA"
    },
    {
        "id": "e676794f-4833-44c6-16c1-6c106cd9ab1f",
        "NOMBRE": "METAHEMOGLOBINEMIA, NO ESPECIFICADA"
    },
    {
        "id": "babde336-2ca6-8ec0-53e2-7c594cde5dea",
        "NOMBRE": "METATARSALGIA"
    },
    {
        "id": "4d5aacc2-0d5d-ed32-0d4f-8d13409b6f2b",
        "NOMBRE": "METATARSUS VARUS"
    },
    {
        "id": "d4eb1fd2-5ad4-bbf1-df05-8b7710327823",
        "NOMBRE": "MIALGIA"
    },
    {
        "id": "57dfa02a-c1c8-fd36-5eb0-2224b521da43",
        "NOMBRE": "MIALGIA EPIDÉMICA"
    },
    {
        "id": "9220880c-cde2-e45e-c435-2c5980e64fcf",
        "NOMBRE": "MIASIS"
    },
    {
        "id": "4cb47e03-a26a-5bc9-5f52-4afe7ddc6687",
        "NOMBRE": "MIASIS AURAL"
    },
    {
        "id": "9eaac0fc-9a22-6ee5-bd6c-1b43b268c456",
        "NOMBRE": "MIASIS CUTÁNEA"
    },
    {
        "id": "9cdb0f8b-bd80-aa2a-640b-a5a62c1afe0e",
        "NOMBRE": "MIASIS DE OTROS SITIOS"
    },
    {
        "id": "5e65aee3-a19b-ee1c-987e-5e20b786dc56",
        "NOMBRE": "MIASIS EN HERIDAS"
    },
    {
        "id": "a99b6186-239d-0d79-7bca-ba28ead8f8a5",
        "NOMBRE": "MIASIS NASOFARÍNGEA"
    },
    {
        "id": "29555829-f697-2352-85e4-43b4dece1e74",
        "NOMBRE": "MIASIS OCULAR"
    },
    {
        "id": "49d7ba4b-623e-6e7e-6874-b13f3803bb4f",
        "NOMBRE": "MIASIS, NO ESPECIFICADA"
    },
    {
        "id": "98bc9070-2b00-c457-2311-7112509e2328",
        "NOMBRE": "MIASTENIA CONGÉNITA O DEL DESARROLLO"
    },
    {
        "id": "3562c019-51cf-e39f-c5d3-476cf442f7c8",
        "NOMBRE": "MIASTENIA GRAVE NEONATAL TRANSITORIA"
    },
    {
        "id": "01b936dd-842d-2563-b523-47f3a421e095",
        "NOMBRE": "MIASTENIA GRAVIS"
    },
    {
        "id": "c16f6a1d-5446-dbe4-bb8b-8fadfdf5fd27",
        "NOMBRE": "MIASTENIA GRAVIS Y OTROS TRASTORNOS NEUROMUSCULARES"
    },
    {
        "id": "13dd115a-3d01-4e53-4c58-a7a99f1f8fbc",
        "NOMBRE": "MICCIÓN DOLOROSA, NO ESPECIFICADA"
    },
    {
        "id": "13afba5f-8ac0-bc00-39a8-eecb66259f4f",
        "NOMBRE": "MICETOMA"
    },
    {
        "id": "a882059b-d150-ae11-7b78-601d0e1329f3",
        "NOMBRE": "MICETOMA, NO ESPECIFICADO"
    },
    {
        "id": "f2bd40f0-dc2f-615f-93f5-bcc85acb4bd7",
        "NOMBRE": "MICOSIS FUNGOIDE"
    },
    {
        "id": "2423720f-bf22-0142-4349-47543a4c1cda",
        "NOMBRE": "MICOSIS OPORTUNISTAS"
    },
    {
        "id": "67f165db-574c-7b74-3d6b-42ea5d580d87",
        "NOMBRE": "MICOSIS SUPERFICIAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "32beb904-6417-5e3f-deb7-b862d506d157",
        "NOMBRE": "MICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "1cfc3cf2-b316-d18f-981c-abf10bcb10b6",
        "NOMBRE": "MICROANGIOPATÍA TROMBÓTICA"
    },
    {
        "id": "02c4d464-abd6-99d3-679a-f6a6fbd3de44",
        "NOMBRE": "MICROCEFALIA"
    },
    {
        "id": "38ce8a2a-7af6-9436-5a9f-39b76d7dc696",
        "NOMBRE": "MICROFTALMÍA"
    },
    {
        "id": "21c9f45c-1fc0-b500-e202-0fc44356b2f6",
        "NOMBRE": "MICROQUEILIA"
    },
    {
        "id": "fb385217-313a-5156-3b91-5e7fff086d8b",
        "NOMBRE": "MICROSTOMÍA"
    },
    {
        "id": "d68cb74b-375e-0eae-509d-686031a5b88b",
        "NOMBRE": "MICROTIA"
    },
    {
        "id": "02e55f8a-46eb-74ac-570c-b02dafc639e5",
        "NOMBRE": "MIELINÓLISIS CENTRAL PONTINA"
    },
    {
        "id": "c1a3a87a-651e-ed53-d128-6a5953d6e0ed",
        "NOMBRE": "MIELITIS NECROTIZANTE SUBAGUDA"
    },
    {
        "id": "c77c190b-1165-9b7a-9f05-f4bc3dea1ff0",
        "NOMBRE": "MIELITIS TRANSVERSA AGUDA EN ENFERMEDAD DESMIELINIZANTE DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "7a9da81b-9d51-7846-2fd5-2222475c394a",
        "NOMBRE": "MIELOFIBROSIS AGUDA"
    },
    {
        "id": "7d5df70a-79e3-f5cd-4ba4-e4733b0ff23b",
        "NOMBRE": "MIELOMA MÚLTIPLE"
    },
    {
        "id": "46cd43b3-90d3-247f-3e5f-f4124add2c28",
        "NOMBRE": "MIELOMA MÚLTIPLE Y TUMORES MALIGNOS DE CÉLULAS PLASMÁTICAS"
    },
    {
        "id": "20a104a8-344b-32c3-b9ed-da08b929b659",
        "NOMBRE": "MIELOPATÍA ASOCIADA AL VIRUS LINFOTRÓPICO DE CÉLULAS T HUMANAS"
    },
    {
        "id": "308a6d5f-68bb-4324-7c8e-a1c04da95352",
        "NOMBRE": "MIELOPATÍA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c0887d9a-afc1-1acc-f29f-f22c75ff14ee",
        "NOMBRE": "MIELOPATÍAS VASCULARES"
    },
    {
        "id": "a56a82bc-ec13-4489-81bb-22ef441c2fde",
        "NOMBRE": "MIGRAÑA"
    },
    {
        "id": "e784e1d9-7059-ba21-d108-1faaac1ea597",
        "NOMBRE": "MIGRAÑA COMPLICADA"
    },
    {
        "id": "c9fd185e-9437-7c06-49c8-f61130c3355d",
        "NOMBRE": "MIGRAÑA CON AURA [MIGRAÑA CLÁSICA]"
    },
    {
        "id": "b4e02b8e-bb5d-13a5-f5ef-13ff88c9a477",
        "NOMBRE": "MIGRAÑA SIN AURA [MIGRAÑA COMÚN]"
    },
    {
        "id": "53226fd2-232b-5281-bd44-871f98f46c50",
        "NOMBRE": "MIGRAÑA, NO ESPECIFICADA"
    },
    {
        "id": "c04da758-c11a-13b2-4643-5e8b38d9bd9c",
        "NOMBRE": "MILIARIA APOCRINA"
    },
    {
        "id": "4e41c5b9-f294-3e73-25a7-158fbb60ff96",
        "NOMBRE": "MILIARIA CRISTALINA"
    },
    {
        "id": "a4e9b376-18e0-5caf-423a-838a8a19150e",
        "NOMBRE": "MILIARIA PROFUNDA"
    },
    {
        "id": "f3412eb1-d3a9-e0d1-f4f1-53064dc2472a",
        "NOMBRE": "MILIARIA RUBRA"
    },
    {
        "id": "256aef25-74b8-c06f-66c6-2254d6048f4a",
        "NOMBRE": "MILIARIA, NO ESPECIFICADA"
    },
    {
        "id": "44100387-b6aa-36bb-db7e-1f920dbb36ed",
        "NOMBRE": "MIOCARDITIS AGUDA"
    },
    {
        "id": "9cd23e88-ea29-494e-fc1b-c48bcb92d37f",
        "NOMBRE": "MIOCARDITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "98c0004a-da69-6b59-5342-a65371db0789",
        "NOMBRE": "MIOCARDITIS AISLADA"
    },
    {
        "id": "b2456140-ce56-75f9-bee1-fc858dce7127",
        "NOMBRE": "MIOCARDITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5e0ed0f8-e90c-cc55-b4ce-5a9f24a74b3a",
        "NOMBRE": "MIOCARDITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9daaaa50-0736-2254-7c3a-2bc27d311415",
        "NOMBRE": "MIOCARDITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "449f0ae5-5de8-51cd-5040-5de5e21a78c1",
        "NOMBRE": "MIOCARDITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3040227e-fede-16d9-c4ed-5a9e6329476f",
        "NOMBRE": "MIOCARDITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "eb3ea802-60e4-a069-7fe9-032f854afa09",
        "NOMBRE": "MIOCARDITIS INFECCIOSA"
    },
    {
        "id": "8416f85b-f9c7-1de4-15ff-fb053a9a4177",
        "NOMBRE": "MIOCARDITIS REUMÁTICA"
    },
    {
        "id": "d4562b46-985c-fc15-8cc8-f60a2443ee34",
        "NOMBRE": "MIOCARDITIS REUMÁTICA AGUDA"
    },
    {
        "id": "6036e341-9ad4-87c0-b0d7-1ed25746fc13",
        "NOMBRE": "MIOCARDITIS, NO ESPECIFICADA"
    },
    {
        "id": "ddc5587c-4900-ce94-b1bc-d18660eb4e62",
        "NOMBRE": "MIOCLONÍA"
    },
    {
        "id": "82a9cd5d-7fb5-8912-b4d9-cf598f05fb86",
        "NOMBRE": "MIOGLOBINURIA"
    },
    {
        "id": "96629204-4e89-f85c-adc2-572f59836b9a",
        "NOMBRE": "MIOPATÍA ALCOHÓLICA"
    },
    {
        "id": "17d8d943-8e8b-1b86-e3e9-a2631e739567",
        "NOMBRE": "MIOPATÍA DEBIDA A OTROS AGENTES TÓXICOS"
    },
    {
        "id": "37b6893d-71bd-4b94-04cd-d24b9dce23c4",
        "NOMBRE": "MIOPATÍA EN ENFERMEDADES ENDOCRINAS"
    },
    {
        "id": "f76fb75e-c980-b86a-4072-f158e0954619",
        "NOMBRE": "MIOPATÍA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "2163175f-e0b1-25fe-5540-2afe9b0adb70",
        "NOMBRE": "MIOPATÍA EN ENFERMEDADES METABÓLICAS"
    },
    {
        "id": "a37b7c6e-5f80-4872-ea33-714db450a241",
        "NOMBRE": "MIOPATÍA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3d011850-94fb-1cdd-c184-3496995d824e",
        "NOMBRE": "MIOPATÍA INDUCIDA POR DROGAS"
    },
    {
        "id": "6228749a-ec66-1419-df2b-648968736a3a",
        "NOMBRE": "MIOPATÍA INFLAMATORIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "4d74b7af-2f58-c977-d3ab-3a7c04205cec",
        "NOMBRE": "MIOPATÍA MITOCÓNDRICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "24ef1bff-7d41-314b-b6e9-3e45d8cff2f7",
        "NOMBRE": "MIOPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "3bdf4729-2614-0f65-d599-a0c38480dea6",
        "NOMBRE": "MIOPATÍAS CONGÉNITAS"
    },
    {
        "id": "d24486dd-0f8c-5d55-d661-ad936d9a53a5",
        "NOMBRE": "MIOPÍA"
    },
    {
        "id": "4cddae7b-ddae-8863-0fb4-03246eed2bf7",
        "NOMBRE": "MIOPÍA DEGENERATIVA"
    },
    {
        "id": "d4926ec8-8edf-0c86-da57-f86dc700c483",
        "NOMBRE": "MIOQUIMIA FACIAL"
    },
    {
        "id": "3f1841af-a141-8933-bfd6-921403cebb0c",
        "NOMBRE": "MIOSITIS"
    },
    {
        "id": "6d8ac0fe-f691-cc5f-cc4b-2cbc5d23c800",
        "NOMBRE": "MIOSITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fab9529d-55d8-fbf0-b5b5-12630434b873",
        "NOMBRE": "MIOSITIS EN INFECCIONES POR PROTOZOARIOS Y PARÁSITOS CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "cd157838-1743-125a-588b-59b1d075d5c3",
        "NOMBRE": "MIOSITIS EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fb638ec6-5d2c-ed90-0ce6-c736a3ba15e9",
        "NOMBRE": "MIOSITIS EN SARCOIDOSIS"
    },
    {
        "id": "384cd9d4-9601-1879-ec28-487bba1e9c54",
        "NOMBRE": "MIOSITIS INFECCIOSA"
    },
    {
        "id": "915989ef-9234-9008-cf17-9bfa70607a03",
        "NOMBRE": "MIOSITIS INTERSTICIAL"
    },
    {
        "id": "2338e892-fbd7-5b7d-f396-bceee848d79a",
        "NOMBRE": "MIOSITIS OSIFICANTE PROGRESIVA"
    },
    {
        "id": "de669bbc-34e0-a583-ae3f-ae06f9f132ba",
        "NOMBRE": "MIOSITIS OSIFICANTE TRAUMÁTICA"
    },
    {
        "id": "ad9f1587-74db-76a7-81f8-22310328fffd",
        "NOMBRE": "MIOSITIS, NO ESPECIFICADA"
    },
    {
        "id": "6f9d6154-59fd-ba26-8799-410759394e35",
        "NOMBRE": "MIRINGITIS AGUDA"
    },
    {
        "id": "37fb7c05-6927-cfd3-153e-1f2877165b0f",
        "NOMBRE": "MIRINGITIS CRÓNICA"
    },
    {
        "id": "91a6c405-2d64-5287-e3b2-f0ed82fec9e9",
        "NOMBRE": "MOLA HIDATIFORME"
    },
    {
        "id": "dae89978-8eb0-6352-543c-2b3479c8cda2",
        "NOMBRE": "MOLA HIDATIFORME CLÁSICA"
    },
    {
        "id": "6d632949-7fc9-a1f8-ca1e-c851d4529a3e",
        "NOMBRE": "MOLA HIDATIFORME, INCOMPLETA O PARCIAL"
    },
    {
        "id": "8e46b4f3-2124-c831-45f5-46a5757fdb10",
        "NOMBRE": "MOLA HIDATIFORME, NO ESPECIFICADA"
    },
    {
        "id": "361c338e-bd1c-d803-0256-784ee01532a9",
        "NOMBRE": "MOLUSCO CONTAGIOSO"
    },
    {
        "id": "0b11a6ac-52bc-df9c-fa9c-50af737c76e7",
        "NOMBRE": "MONOARTRITIS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "1b93bf04-953b-9a05-ef03-33feda0fadf7",
        "NOMBRE": "MONONEURITIS MÚLTIPLE"
    },
    {
        "id": "252a5cd1-478e-cdab-3831-f11471c95e31",
        "NOMBRE": "MONONEUROPATÍA DEL MIEMBRO INFERIOR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "a9028060-3743-27c7-0b31-ebac46bf870c",
        "NOMBRE": "MONONEUROPATÍA DEL MIEMBRO SUPERIOR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "59301e81-1814-c423-a840-70459f6ac180",
        "NOMBRE": "MONONEUROPATÍA DIABÉTICA"
    },
    {
        "id": "8790e41a-ba16-2c3c-e208-175cc431b713",
        "NOMBRE": "MONONEUROPATÍA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6e820182-721b-4506-a3fa-387a026e9d46",
        "NOMBRE": "MONONEUROPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "ab126026-a660-393d-0f6f-7b18c4a0f966",
        "NOMBRE": "MONONEUROPATÍAS DEL MIEMBRO INFERIOR"
    },
    {
        "id": "f5173497-7ff1-133b-3443-afa889581e2d",
        "NOMBRE": "MONONEUROPATÍAS DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "f61a058f-cbb5-6570-8eb7-e0837dda4da0",
        "NOMBRE": "MONONUCLEOSIS DEBIDA A HERPES VIRUS GAMMA"
    },
    {
        "id": "fcc3bca3-c4e7-f40b-8cd6-399d854def51",
        "NOMBRE": "MONONUCLEOSIS INFECCIOSA"
    },
    {
        "id": "88f38fbb-6bf0-bbcc-693f-8ed40b0edd11",
        "NOMBRE": "MONONUCLEOSIS INFECCIOSA, NO ESPECIFICADA"
    },
    {
        "id": "23a9a285-944d-605d-7b4e-8a2e6a4c3989",
        "NOMBRE": "MONONUCLEOSIS POR CITOMEGALOVIRUS"
    },
    {
        "id": "ac1b20d7-77a0-f6e8-7b5e-e8f2810421d6",
        "NOMBRE": "MONOPLEJÍA DE MIEMBRO INFERIOR"
    },
    {
        "id": "116cf5b4-33d3-7957-e505-5b920e83592c",
        "NOMBRE": "MONOPLEJÍA DE MIEMBRO SUPERIOR"
    },
    {
        "id": "3d57baa1-beb9-2292-d33c-0acbd9776482",
        "NOMBRE": "MONOPLEJÍA, NO ESPECIFICADA"
    },
    {
        "id": "381dcd6f-567c-c57a-fb70-886a7334b5d9",
        "NOMBRE": "MONOSOMÍA COMPLETA DE UN CROMOSOMA, MOSAICO (POR FALTA DE DISYUNCIÓN MITÓTICA)"
    },
    {
        "id": "1ae56efa-b0e2-5dc6-9a73-7ed10e0f5819",
        "NOMBRE": "MONOSOMÍA COMPLETA DE UN CROMOSOMA, POR FALTA DE DISYUNCIÓN MEIÓTICA"
    },
    {
        "id": "b5a27a6d-c197-5641-9352-edb17af2dfe3",
        "NOMBRE": "MONOSOMÍAS Y SUPRESIONES DE LOS AUTOSOMAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d601420e-29c8-789d-3d68-e1e002e1c39c",
        "NOMBRE": "MORDEDURA DE RATA"
    },
    {
        "id": "02af8f48-6567-0f80-aa05-a0b5f504aee7",
        "NOMBRE": "MORDEDURA DE RATA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "2832272e-3b4c-f6ad-0623-066e8a969c4c",
        "NOMBRE": "MORDEDURA DE RATA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a419e6d3-572b-a96c-cb6e-c2a584071103",
        "NOMBRE": "MORDEDURA DE RATA, CALLES Y CARRETERAS"
    },
    {
        "id": "a62ac763-7a04-e2b8-509f-f53d69409464",
        "NOMBRE": "MORDEDURA DE RATA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "9cf0f09c-871c-9264-dac2-b1c24107a9e7",
        "NOMBRE": "MORDEDURA DE RATA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "d7482e83-39ff-3e04-1048-1d169c0c9eba",
        "NOMBRE": "MORDEDURA DE RATA, GRANJA"
    },
    {
        "id": "d0ea2e30-7518-6988-c278-d7afea19a76d",
        "NOMBRE": "MORDEDURA DE RATA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "00c1fbb7-f3fb-54c1-7568-91a9a6f3a0be",
        "NOMBRE": "MORDEDURA DE RATA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "74972446-2c13-5bce-ea93-82232bd8a51c",
        "NOMBRE": "MORDEDURA DE RATA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e5fdc4da-d956-eb26-264d-e9115bcd3f30",
        "NOMBRE": "MORDEDURA DE RATA, VIVIENDA"
    },
    {
        "id": "123b5502-c13d-0eed-e203-72384faba10e",
        "NOMBRE": "MORDEDURA DEL LABIO Y DE LA MEJILLA"
    },
    {
        "id": "6124f81e-f05e-a385-d82c-3ad7753d50ed",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES"
    },
    {
        "id": "e50eb2f9-baa2-41a4-3c2b-4e8e19fb2d0c",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "c847d634-8cab-44f9-b75d-727e7fc64393",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "cfe82ed3-2521-053f-0887-9d13ea42dace",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, CALLES Y CARRETERAS"
    },
    {
        "id": "3cc36137-d087-9ad2-40e2-8b691f1a93f3",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "30b1a857-7e72-65dc-3a1e-7ca4383621ea",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "c9a48f79-f4b7-3512-ca89-9da0c9d181b3",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, GRANJA"
    },
    {
        "id": "ce31e5b5-62d3-e76d-81d9-72904a2b6a6d",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "78d870f3-0580-93ab-71e0-f53bbcd67a0d",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "346c834a-3043-cb73-c003-153a3feb9613",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "05684683-5047-7e43-188f-1392d86f66de",
        "NOMBRE": "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, VIVIENDA"
    },
    {
        "id": "e1de0f12-ebf3-249c-6ce6-f27d09db35a2",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN"
    },
    {
        "id": "b9935591-662c-ece1-75dd-677aa885af7a",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "3f6e9ffc-c730-cc6f-b9d6-9ffdc5157681",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "dfc3189f-eea2-3955-9745-4e843aa38823",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, CALLES Y CARRETERAS"
    },
    {
        "id": "a4ef51bc-b19c-47c2-7a62-203706933b3e",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5dc19cac-dd2a-0da3-c418-a45a1f596a9d",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "7017de52-4ded-01d2-3ee1-43a0b93089c1",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, GRANJA"
    },
    {
        "id": "06cf0ba8-cd34-3dcc-d370-6663264e96fc",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f401925d-f194-3b60-1314-a4620c649c62",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "99d1d279-893c-d9c0-20f9-b6fc94b70094",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "fe2ba890-f8ad-af97-7543-a34996c3cd0e",
        "NOMBRE": "MORDEDURA O ATAQUE DE COCODRILO O CAIMÁN, VIVIENDA"
    },
    {
        "id": "791702ac-65fc-ce0e-b1a9-0f8f257f5a90",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS"
    },
    {
        "id": "5022f293-2a00-f362-fadf-a95642c90d01",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "8176e2b3-c061-dce3-2648-ed34589ea788",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "519096fa-a3de-0843-39d4-a181adbeccdd",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, CALLES Y CARRETERAS"
    },
    {
        "id": "88c86ef7-aedf-8dfb-3043-9154dc24a7cd",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "744c38af-b473-8222-ec5f-8162d1c0276e",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "98750c00-979b-1d5f-19bb-8e73523c9af6",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, GRANJA"
    },
    {
        "id": "aa7b8874-76b6-c411-0ee3-d8804f093f3a",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "62c77063-2e9f-10b5-6b43-7580afb26daa",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "e34f5d04-29ad-2de1-8b0e-bd3f4fda430a",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "e19aa872-f3e8-3508-0a77-95e2fc3c508c",
        "NOMBRE": "MORDEDURA O ATAQUE DE OTROS MAMÍFEROS, VIVIENDA"
    },
    {
        "id": "10bfb786-8972-0b87-aa76-6875cc7a3b93",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO"
    },
    {
        "id": "16baccde-4a93-28f0-a027-7cc4ee9b1ddb",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "65aa535c-91ab-3173-c301-3d6fe0d2c7a7",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "02cd1c31-c1c2-e6e8-7cb2-568cbc737764",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, CALLES Y CARRETERAS"
    },
    {
        "id": "7dad2dbe-3510-14d6-0c5a-ad1003359f1f",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "970e841b-218f-cda0-0eaf-d96778fa3b4b",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e8bd0157-12ea-6e87-9f1f-736d93c85592",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, GRANJA"
    },
    {
        "id": "299e4540-e935-2438-8e86-3dd61f652932",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7c1b48a7-b930-402c-4f5f-b551167db4cd",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f546c946-2f7a-2ce3-3292-68106c45b143",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "5a3ac535-f386-e4fe-7264-5bc38dc5d449",
        "NOMBRE": "MORDEDURA O ATAQUE DE PERRO, VIVIENDA"
    },
    {
        "id": "a23767b0-7ba7-12c6-4a14-69bb62d98717",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS"
    },
    {
        "id": "428794a2-d4a4-747f-0ccc-128c77b98648",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "e07224bd-c0bb-f5c8-5204-f439f82a98ff",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2a1c50f0-1a18-1042-caa8-e005b20968fe",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, CALLES Y CARRETERAS"
    },
    {
        "id": "4192fc2e-1760-79c0-c546-5811f0ffec71",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5def56af-fe05-4c55-68cb-0300d621e9e4",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "860a88e8-d2ab-b8b7-0cca-ca65ee1bcfcb",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, GRANJA"
    },
    {
        "id": "35b63592-a4a4-6783-8b78-c761642e87ba",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "18527826-40f1-96bb-74e2-192e225a8b29",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a4c2d6c3-13f0-6983-5e46-f6d975fc3efb",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "1d39f374-4948-79b0-5725-79dc710c814d",
        "NOMBRE": "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTRÓPODOS NO VENENOSOS, VIVIENDA"
    },
    {
        "id": "677e399c-f171-e9e4-d3c4-f18720f8f1b2",
        "NOMBRE": "MOSAICO 45, X/46,XX O XY"
    },
    {
        "id": "5f789506-2945-1cd3-5564-e8be140e1b41",
        "NOMBRE": "MOSAICO 45,X/OTRA(S) LÍNEA(S) CELULAR(ES) CON CROMOSOMA SEXUAL ANORMAL"
    },
    {
        "id": "c9aac322-f42a-b93a-0a32-93400db0cd00",
        "NOMBRE": "MOSAICO, LÍNEAS CON NÚMERO VARIABLE DE CROMOSOMAS X"
    },
    {
        "id": "c7f0b92f-61c8-8e33-f1be-cb1621d8cf09",
        "NOMBRE": "MOTOCICLISTA [CUALQUIERA] LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "16d660d6-eed6-f414-d9c1-b03a1e86ce09",
        "NOMBRE": "MOTOCICLISTA [CUALQUIERA] LESIONADO EN ACCIDENTE NO DE TRÁNSITO, NO ESPECIFICADO"
    },
    {
        "id": "49d0c59e-5d16-666d-1d75-8409dabd678b",
        "NOMBRE": "MOTOCICLISTA [CUALQUIERA] LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "79bcfacc-a3c4-6b5b-74b7-00ff58ce2d8c",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN"
    },
    {
        "id": "8eafca85-456d-bc8b-b2b9-a7f7c096ea3c",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "cc1abbd6-32a9-4945-7a26-93cbb113a03a",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3083ff8c-c8a7-6c7a-7154-1ebee9f5476a",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "68fb778a-6cf1-660c-8010-e5ce7b1f455a",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c7746a3e-6e99-dcdd-07ce-36756e9fda64",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "053a96da-e7ac-248c-1ea0-380a5187070d",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "30bf0ac1-1bf4-6732-c129-9f9f50a8485d",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "70a7f946-6520-83af-cd16-b98309453b9a",
        "NOMBRE": "MOTOCICLISTA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "9c0a7fd8-ad4f-1f2d-51bc-8845c82c9830",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "7967b2fc-1a29-d6e7-da39-7c6fb92021e0",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f2c6d42c-65b7-1f64-7ed3-dc67b484367d",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "53eb4731-6b6d-e597-15e5-2b030c20d4df",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, MOTOCICLISTA NO ESPECIFICADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "96f5ffce-2fc3-d80b-5b57-d479b0358ced",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, MOTOCICLISTA NO ESPECIFICADO, LESIÓN EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b19bf330-93f7-bbea-0b2c-60a754590c7e",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "29522277-5297-250d-d2e9-799e88909cf8",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6908ac88-d07a-d9bc-493b-37c3185ee2f8",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "cd5a74f1-1ce4-d7b1-ff04-96984f99c9cc",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO"
    },
    {
        "id": "03be19b2-a783-759a-38ed-e0c8d989022c",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "613b44c1-4ff7-635e-dfce-2ee0c6748e53",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "06aa2cb6-7384-0dff-6bb3-8af80191dabd",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "06118970-555d-6780-6e58-0df0bc425e54",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5e75ad01-c7ea-9a23-b8f7-a01c52ea53cd",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "0e8ef6a9-049e-e396-82b5-fad6d5fa4e4d",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "99874927-6968-5d33-84dc-ab88ddeaa832",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "4ea4c684-3461-e522-d89b-c1ea13ea6175",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "9977149e-8a11-1c72-af8b-8c8771e26249",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "32879f83-92a5-7313-f915-2b4f7a506f44",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ec7c09cc-91d0-8eec-b6c1-1197f92fd1cc",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "95d12927-5077-7100-4544-f72537918777",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f467394e-d1e6-5530-71ac-7e55fa10dbcd",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "846e70b3-9fd6-f8e4-9f52-455da5609986",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3f3c94ff-a353-6e0f-ef27-378f58b4a94b",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "8ec53e53-a447-063a-9cc7-1c201555abad",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "a457e004-9234-5707-d8dc-01c412109200",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d3f832ee-b583-020d-badd-fdbabd1f671c",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ec1524ab-77e9-51ef-4d4b-4c6689fa346d",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "47385b46-3d4f-e331-e8fd-c3afbb662a12",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "df4b62db-111c-dc5a-74c8-fa65d2d03ff7",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "faae4f13-d591-0c53-a85a-dd7c36fd909f",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "8e51f34d-b8bb-508f-0215-1072f3e01d31",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "274de980-7e6b-1496-1beb-a5dffa6b0194",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "551ecd06-e36a-ad3d-52ac-b1f60b482505",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "7830afaf-fcca-f6ed-a8f9-9bb2d47ac58a",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "034d0be8-a22a-9317-c35a-ef3c1ab5fad4",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "cd19b748-be61-5931-44b0-87bbf32a7bc2",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "530b151a-13e3-2bb3-3ad9-fc068e0fbf54",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "44f940dc-b002-db36-b072-5b52cea9cded",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "446120e6-964a-1596-2ad4-85db50dafb45",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "48dfc7f9-7407-50f5-02e8-13be9c30930c",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "1f25d361-f774-d428-e246-35fe5688e2cc",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "da9fa344-9e4d-cafc-b31d-004767e38bd5",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "af1c4579-e4b0-b50b-37cd-f923575e7123",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3c3295d2-d4b3-9016-5591-b3e6a4bdb184",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b694130b-6eac-3be2-10e1-186ff12c4ca9",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "96f59779-cd49-5153-963f-c0e3b11faa3c",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6f1d8f43-dc6e-e300-4185-d6bef6cd124f",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "f632affb-6d66-4a12-e897-f11bfb558375",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "b3288c92-1d2f-9798-39cc-ebc80bd9330f",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "253cbbe5-41b4-4e34-def5-6a6c41a38301",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7ed222b2-dea6-0ae0-1188-92b095b0efec",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "304f9873-6d6a-6094-c0e3-c76e0dd257b8",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "99aa15f5-b2ea-72c6-64ed-246c69318cca",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6ced5a9d-6847-8d17-3361-42d585390478",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "8a1bbde0-6004-6b50-1fc2-274d3c1880fa",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "456b2eac-c977-ded5-71ac-6d6ed0cba6df",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "b8fb2260-05ce-65d7-65c1-e7fc17103120",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "95c504ea-8536-7504-3b14-42f9779eb12f",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "15d7eec5-e86e-fc2d-b704-c3e9d4532039",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "97f2d264-0c38-70c8-170f-88f5d02fcba0",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "eb023319-1907-3864-74bd-db2967a106e4",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "37cfb444-0835-05b1-e50d-b6ecec43a0dc",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "eb777027-b71e-1da6-0d26-41d20782432e",
        "NOMBRE": "MOTOCICLISTA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "a64b12c2-7c09-3f9a-3349-873a497f218a",
        "NOMBRE": "MOTOCICLISTA NO ESPECIFICADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "9d23a252-ef86-9347-9832-2d8461a95f8d",
        "NOMBRE": "MOTOCICLISTA NO ESPECIFICADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3692b47c-aaca-6692-78a2-c56b23860cc2",
        "NOMBRE": "MOVIMIENTOS ANORMALES DE LA CABEZA"
    },
    {
        "id": "cf4c9cf3-5b53-5e7f-d8ef-01eca26b2d10",
        "NOMBRE": "MOVIMIENTOS INVOLUNTARIOS ANORMALES"
    },
    {
        "id": "a0fd0c84-fa48-6975-88f1-dcf15d2dc91d",
        "NOMBRE": "MUCINOSIS DE LA PIEL"
    },
    {
        "id": "a7224130-aced-e805-d89b-4300b6af3cea",
        "NOMBRE": "MUCOCELE DE GLÁNDULA SALIVAL"
    },
    {
        "id": "376f7494-55c4-cbfb-79a8-826f8bd38820",
        "NOMBRE": "MUCOPOLISACARIDOSIS NO ESPECIFICADA"
    },
    {
        "id": "d184cb1b-fc6b-c353-5402-09867a33b6e2",
        "NOMBRE": "MUCOPOLISACARIDOSIS TIPO I"
    },
    {
        "id": "eb31a1ae-e09c-6db6-dc4b-2af5cb049289",
        "NOMBRE": "MUCOPOLISACARIDOSIS TIPO II"
    },
    {
        "id": "3f07da65-396f-bf74-f039-c593bba2389d",
        "NOMBRE": "MUCORMICOSIS CUTÁNEA"
    },
    {
        "id": "59eb833d-e9ae-4f12-2211-e2e45ff56a41",
        "NOMBRE": "MUCORMICOSIS DISEMINADA"
    },
    {
        "id": "adb17ddc-e41f-e471-10a6-c450b80897ec",
        "NOMBRE": "MUCORMICOSIS GASTROINTESTINAL"
    },
    {
        "id": "3888c05a-6bb5-b029-218b-4c8cf2885f7e",
        "NOMBRE": "MUCORMICOSIS PULMONAR"
    },
    {
        "id": "9dffae20-5a9e-4ef8-fa57-9e26ec5e3b12",
        "NOMBRE": "MUCORMICOSIS RINOCEREBRAL"
    },
    {
        "id": "282ac4fa-95bc-4588-9a06-a0d2c83332ab",
        "NOMBRE": "MUCORMICOSIS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "20acec10-d6f0-411b-61df-73c4d9c387a2",
        "NOMBRE": "MUCOSITIS ORAL (ULCERATIVA)"
    },
    {
        "id": "a0cb85bb-6a39-fae1-e005-7c9f027419be",
        "NOMBRE": "MUERMO"
    },
    {
        "id": "4a83214e-aba5-cc17-f364-d239a7ae91ae",
        "NOMBRE": "MUERMO Y MELIOIDOSIS"
    },
    {
        "id": "a99e2fdb-b4c2-fb0b-27ef-b68ba261f6d9",
        "NOMBRE": "MUERTE CARDÍACA SÚBITA, ASÍ DESCRITA"
    },
    {
        "id": "f22213fd-49d6-545a-076d-add15345ba8f",
        "NOMBRE": "MUERTE FETAL DE CAUSA NO ESPECIFICADA"
    },
    {
        "id": "90a9a2a5-06ef-5d85-65c5-8b46463faf55",
        "NOMBRE": "MUERTE INSTANTÁNEA"
    },
    {
        "id": "bba57868-1d51-f0f3-5c35-809495644f23",
        "NOMBRE": "MUERTE MATERNA DEBIDA A CUALQUIER CAUSA OBSTÉTRICA QUE OCURRE DESPUÉS DE 42 DÍAS PERO ANTES DE UN AÑO DEL PARTO"
    },
    {
        "id": "f6372454-e600-d6c0-0815-4648f50a73d6",
        "NOMBRE": "MUERTE MATERNA DEBIDA A CUALQUIER CAUSA OBSTÉTRICA QUE OCURRE DESPUÉS DE 42 DÍAS PERO ANTES DE UN AÑO DEL PARTO"
    },
    {
        "id": "644d6ed6-ea2e-9bc9-e7be-b6beaeed44b7",
        "NOMBRE": "MUERTE OBSTÉTRICA DE CAUSA NO ESPECIFICADA"
    },
    {
        "id": "74ae2e06-c8c4-427a-19ba-2b5421c01922",
        "NOMBRE": "MUERTE POR CAUSA OBSTÉTRICA DIRECTA QUE OCURRE DESPUÉS DE 42 DÍAS PERO ANTES DE UN AÑO DEL PARTO"
    },
    {
        "id": "0c1f3f9c-866a-1fb7-cffa-912f77b647a1",
        "NOMBRE": "MUERTE POR CAUSA OBSTÉTRICA INDIRECTA QUE OCURRE DESPUÉS DE 42 DÍAS PERO ANTES DE UN AÑO DEL PARTO"
    },
    {
        "id": "edeb8765-0128-56f3-fbc9-46492099a8df",
        "NOMBRE": "MUERTE POR CAUSA OBSTÉTRICA NO ESPECIFICADA, QUE OCURRE DESPUÉS DE 42 DÍAS PERO ANTES DE UN AÑO DEL PARTO"
    },
    {
        "id": "874c3ca0-47b6-647b-8a8e-02a8f4af2043",
        "NOMBRE": "MUERTE POR SECUELAS DE CAUSA OBSTÉTRICA DIRECTA"
    },
    {
        "id": "39eeaf24-2434-5326-df34-070670c63935",
        "NOMBRE": "MUERTE POR SECUELAS DE CAUSA OBSTÉTRICA INDIRECTA"
    },
    {
        "id": "c43c63fe-6dde-bf06-0a33-dbe5f04bc36c",
        "NOMBRE": "MUERTE POR SECUELAS DE CAUSA OBSTÉTRICA NO ESPECIFICADA"
    },
    {
        "id": "5a27c42a-4eb9-cdb3-146e-8e15dff9ba61",
        "NOMBRE": "MUERTE POR SECUELAS DE CAUSAS OBSTÉTRICAS"
    },
    {
        "id": "4e39a22d-ccb1-8595-43c7-143a0792395b",
        "NOMBRE": "MUERTE POR SECUELAS DE CAUSAS OBSTÉTRICAS DIRECTAS"
    },
    {
        "id": "ff653094-c3a5-fc12-c69e-6b4f563cf986",
        "NOMBRE": "MUERTE QUE OCURRE EN MENOS DE 24 HORAS DEL INICIO DE LOS SÍNTOMAS, NO EXPLICADA DE OTRA FORMA"
    },
    {
        "id": "6ed803c4-b8cf-6831-6ddf-c17f8459aafc",
        "NOMBRE": "MUERTE SIN ASISTENCIA"
    },
    {
        "id": "c6ad3a26-015d-7410-5d95-63074f9d9b41",
        "NOMBRE": "MUESTRA DE SANGRE"
    },
    {
        "id": "94ee879a-e319-5fa1-0f9e-3ef6a88d225b",
        "NOMBRE": "MUJER CON CARIOTIPO 46, XY"
    },
    {
        "id": "9840446a-4b13-341c-c04c-c2517e35788d",
        "NOMBRE": "MUJER CON MÁS DE TRES CROMOSOMAS X"
    },
    {
        "id": "7eefa588-2d4d-7802-ae49-667e692ee46b",
        "NOMBRE": "MUÑECA O PIE EN PÉNDULO (ADQUIRIDO)"
    },
    {
        "id": "fb876a4a-4a00-2466-1bca-e4010b990023",
        "NOMBRE": "MUTISMO ELECTIVO"
    },
    {
        "id": "6eac580b-2df4-b3bd-95d3-6e7955a7d953",
        "NOMBRE": "MUY BAJO NIVEL DE HIGIENE PERSONAL"
    },
    {
        "id": "415d31ba-907c-9388-21a2-de1838309713",
        "NOMBRE": "MYCOPLASMA PNEUMONIAE [M. PNEUMONIAE] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "bfd5f9ce-c255-0952-a5c4-aed7c6222d8a",
        "NOMBRE": "NACIDO MUERTO, ÚNICO"
    },
    {
        "id": "6f6c0c3b-3847-8f5c-0b29-19db5fd70b13",
        "NOMBRE": "NACIDO VIVO, ÚNICO"
    },
    {
        "id": "f8da86cc-5521-38c5-b44b-97fbacd53df1",
        "NOMBRE": "NACIDOS VIVOS SEGÚN LUGAR DE NACIMIENTO"
    },
    {
        "id": "88458246-23f0-baac-1ecf-1143f33e6b1a",
        "NOMBRE": "NAEGLERIASIS"
    },
    {
        "id": "e6ddd04d-9e9f-ed81-31b0-75d9feafb1b6",
        "NOMBRE": "NARCOLEPSIA Y CATAPLEXIA"
    },
    {
        "id": "de63182a-6001-448e-9d6c-da90e33064bc",
        "NOMBRE": "NÁUSEA Y VÓMITO"
    },
    {
        "id": "b1306965-15f1-d3c6-ffbe-7694b1cbd35e",
        "NOMBRE": "NECATORIASIS"
    },
    {
        "id": "0ccf2f6c-e0a4-73af-096a-e0d75b92f302",
        "NOMBRE": "NECESIDAD DE ASISTENCIA DEBIDA A MOVILIDAD REDUCIDA"
    },
    {
        "id": "72a8e85c-ff13-bcee-14e1-5ba8a07b6aeb",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA CIERTAS ENFERMEDADES VIRALES"
    },
    {
        "id": "cdcebad3-3322-4fb3-54ff-3295bc54b0f2",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA COMBINACIONES DE ENFERMEDADES INFECCIOSAS"
    },
    {
        "id": "c440c2ec-f3b7-71e9-98b1-26d9fb210dc9",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA COMBINACIONES NO ESPECIFICADAS DE ENFERMEDADES INFECCIOSAS"
    },
    {
        "id": "a0cc1656-71b0-8f55-8614-522f9e4f59c8",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA DIFTERIA-PERTUSSIS-TÉTANOS COMBINADOS [DPT]"
    },
    {
        "id": "54f8a623-b00e-c527-514a-29ff001e5218",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA DIFTERIA-PERTUSSIS-TÉTANOS Y POLIOMIELITIS [DPT + POLIO]"
    },
    {
        "id": "0fbcf65f-47bb-634d-ac32-be3fce2a5255",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA DIFTERIA-PERTUSSIS-TÉTANOS Y TIFOIDEA-PARATIFOIDEA [DPT + TAB]"
    },
    {
        "id": "ada6a531-5df4-1b33-17b6-19b2c4f07509",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA EL CÓLERA Y LA TIFOIDEA-PARATIFOIDEA [CÓLERA + TAB]"
    },
    {
        "id": "da473f67-3811-49a3-b44c-f544d5b4a4e1",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA ENFERMEDAD BACTERIANA ÚNICA"
    },
    {
        "id": "43e47b18-9135-4ac2-ca98-8ed3cc1c3c00",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA ENFERMEDAD INFECCIOSA NO ESPECIFICADA"
    },
    {
        "id": "0e70cae3-538d-f4a9-86a6-f99a1c03835b",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA COVID-19"
    },
    {
        "id": "845f7d41-828c-ae59-611e-426a382960af",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA COVID-19, NO ESPECIFICADA"
    },
    {
        "id": "283e3dd8-b010-1963-cea5-0cd9c03b7523",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA ENCEFALITIS VIRAL TRANSMITIDA POR ARTRÓPODOS"
    },
    {
        "id": "dd8ef225-687c-c11a-9e05-6abfbbca62b7",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA FIEBRE AMARILLA"
    },
    {
        "id": "3ec184d9-ad9c-8c31-6311-a754d739e231",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA HEPATITIS VIRAL"
    },
    {
        "id": "5ad54535-7622-bfb3-5877-b85d8f149051",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA INFLUENZA [GRIPE]"
    },
    {
        "id": "e3134a82-cea8-993f-cb70-35333d0c637b",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA LEISHMANIASIS"
    },
    {
        "id": "58b38419-8ef4-e084-27c8-27327ec0f868",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA PESTE"
    },
    {
        "id": "718775e2-102e-bb9e-39cd-a7d37f3ef3e2",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA POLIOMIELITIS"
    },
    {
        "id": "a98e1b28-19f8-7798-2e68-8e2edfecae19",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA RABIA"
    },
    {
        "id": "52838775-17ea-8b9a-3b41-aa441216438b",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA TUBERCULOSIS [BCG]"
    },
    {
        "id": "f460dc0f-6557-ab33-901f-5908eb1b4b94",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA LA TULAREMIA"
    },
    {
        "id": "6cb76069-a47e-555f-6a1b-c876d2e94931",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA OTRAS COMBINACIONES DE ENFERMEDADES INFECCIOSAS"
    },
    {
        "id": "2da4fc66-ac58-1096-55f3-b82a1ccdb475",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA OTRAS ENFERMEDADES INFECCIOSAS ÚNICAS"
    },
    {
        "id": "c0854fd1-985f-aa4d-b0a9-a2109a2dffa2",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA OTRAS ENFERMEDADES INFECCIOSAS ÚNICAS ESPECIFICADAS"
    },
    {
        "id": "f86fda22-103b-3512-6cb8-88738755ebd3",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA OTRAS ENFERMEDADES VIRALES ÚNICAS"
    },
    {
        "id": "725e7345-3287-d230-9566-f53b7dab8a51",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA OTRAS ENFERMEDADES VIRALES ÚNICAS ESPECIFICADAS"
    },
    {
        "id": "4bf4cd55-1b57-5376-9b3c-b0a893ebb5fe",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN CONTRA SARAMPIÓN-PAROTIDITIS-RUBÉOLA- [SPR] [MMR]"
    },
    {
        "id": "12faec08-a742-2b61-27ec-eb409d7c32b6",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA EL CÓLERA"
    },
    {
        "id": "414b07e2-28cd-1a97-5ae2-86e4305b7a4b",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA EL SARAMPIÓN"
    },
    {
        "id": "60b42bc7-4442-a6a1-1f25-89931e1d4a28",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA EL TÉTANOS"
    },
    {
        "id": "0c796a3e-4ce1-6055-6143-2002ab572384",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA LA DIFTERIA"
    },
    {
        "id": "41a90385-a38e-4463-5749-818bc075cce1",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA LA PAROTIDITIS"
    },
    {
        "id": "43a736e3-70f6-0691-b1d4-8967da90eb88",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA LA RUBÉOLA"
    },
    {
        "id": "5bb6e2bb-f86b-ba59-7e19-3b989c566daa",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA LA TIFOIDEA-PARATIFOIDEA [TAB]"
    },
    {
        "id": "ae7b3e09-0bfa-a61b-7a63-c98505e0bf49",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA LA TOS FERINA"
    },
    {
        "id": "27091db5-4d05-ed7b-3ec6-59f70f57d8bf",
        "NOMBRE": "NECESIDAD DE INMUNIZACIÓN SÓLO CONTRA OTRA ENFERMEDAD BACTERIANA"
    },
    {
        "id": "b5a6709c-dd7e-adf1-e8e2-7acd26ad1199",
        "NOMBRE": "NECESIDAD DE OTRAS MEDIDAS PROFILÁCTICAS"
    },
    {
        "id": "3e2992af-862a-8144-8e25-3254af664a38",
        "NOMBRE": "NECROBIOSIS LIPÍDICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "255ab137-f3c4-f9ff-fbed-6503dc0e853f",
        "NOMBRE": "NECRÓLISIS EPIDÉRMICA TÓXICA [LYELL]"
    },
    {
        "id": "1b998e17-1424-7a49-e164-566471cf155a",
        "NOMBRE": "NECROSIS ARTERIAL"
    },
    {
        "id": "6f60a0c8-7975-65dc-64f6-0620398e9059",
        "NOMBRE": "NECROSIS ASÉPTICA IDIOPÁTICA ÓSEA"
    },
    {
        "id": "a3e243d7-5678-2356-f990-5da74ef99599",
        "NOMBRE": "NECROSIS DE LA PULPA"
    },
    {
        "id": "9673f710-7a2e-4429-4c4a-d2955795de3f",
        "NOMBRE": "NECROSIS DE MUÑÓN DE AMPUTACIÓN"
    },
    {
        "id": "7da2ec64-6c66-a6df-579a-67ebd3fe67c6",
        "NOMBRE": "NECROSIS GRASA DE LA MAMA"
    },
    {
        "id": "16b4ae44-5e07-0764-7664-babc3d4edbe6",
        "NOMBRE": "NECROSIS GRASA SUBCUTÁNEA DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "4ce25563-704b-0161-6a33-075d1ebae65a",
        "NOMBRE": "NECROSIS HEMORRÁGICA CENTRAL DEL HÍGADO"
    },
    {
        "id": "2f6d270b-0253-91f8-0145-ce7ad0bdb22b",
        "NOMBRE": "NEFRITIS TUBULOINTERSTICIAL AGUDA"
    },
    {
        "id": "593a8355-6f6d-c60a-624a-d11c10f8fcc5",
        "NOMBRE": "NEFRITIS TUBULOINTERSTICIAL CRÓNICA"
    },
    {
        "id": "020aab10-430b-110b-792c-bb8bec9e428c",
        "NOMBRE": "NEFRITIS TUBULOINTERSTICIAL CRÓNICA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "fdcbf36c-d86f-f6dd-33df-31504d68219a",
        "NOMBRE": "NEFRITIS TUBULOINTERSTICIAL, NO ESPECIFICADA COMO AGUDA O CRÓNICA"
    },
    {
        "id": "94700116-25df-e51a-83ce-0ae88a9ea037",
        "NOMBRE": "NEFROPATÍA DE LOS BALCANES"
    },
    {
        "id": "f12e8ce8-b214-e606-f1ce-8d9b7af3c68b",
        "NOMBRE": "NEFROPATÍA HEREDITARIA NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "ae0b61e8-a08c-6a48-9854-ed0dec94dd69",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "f32c41fe-09fd-7a03-9adf-655a1a032c83",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "ae2e26dd-800f-5f5c-f374-1047d3bcacce",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "c3f6261d-8fe8-57a3-bb4d-90e5c44472f4",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "93e3b5f8-0ae2-1089-b009-46c009006cca",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "189df8d2-b8c8-3277-ac41-af6da459e95a",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "b738c8fa-a050-c505-0af0-2ec2df53a089",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "f66ed613-ffd2-d481-c5eb-a4d545a844e1",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "991c5576-8502-3b1b-21e0-3540732b48ae",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, NO ESPECIFICADA"
    },
    {
        "id": "1b442d20-ede6-35ca-998b-5a4b753a2d05",
        "NOMBRE": "NEFROPATÍA HEREDITARIA, NCOP, OTRAS"
    },
    {
        "id": "5d1815bb-08d9-e502-fdf5-a14a9f9f7a99",
        "NOMBRE": "NEFROPATÍA INDUCIDA POR ANALGÉSICOS"
    },
    {
        "id": "4614f33c-334e-8f6f-b9da-a5169197f466",
        "NOMBRE": "NEFROPATÍA INDUCIDA POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS NO ESPECIFICADAS"
    },
    {
        "id": "1cbae88f-f6a2-9714-3e1c-d8e98b49fb7d",
        "NOMBRE": "NEFROPATÍA INDUCIDA POR METALES PESADOS"
    },
    {
        "id": "dd5c3c0f-7dc8-4404-ac15-df1ac0f03f9f",
        "NOMBRE": "NEFROPATÍA INDUCIDA POR OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "5e812e98-8a51-7aa1-4941-63e8c3bde216",
        "NOMBRE": "NEFROPATÍA TÓXICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "1d2c6cde-142f-5139-4aa6-d80481c11349",
        "NOMBRE": "NEGLIGENCIA O ABANDONO"
    },
    {
        "id": "2fd9bb35-c6dd-4618-0a14-cf2a1b53e393",
        "NOMBRE": "NEGLIGENCIA Y ABANDONO"
    },
    {
        "id": "932c417b-f86a-b109-59d9-f88cf875a444",
        "NOMBRE": "NEGLIGENCIA Y ABANDONO POR CONOCIDO O AMIGO"
    },
    {
        "id": "24523d52-815c-b5ea-c2a6-55a1ec7c3d1d",
        "NOMBRE": "NEGLIGENCIA Y ABANDONO POR ESPOSO O PAREJA"
    },
    {
        "id": "4f853c4f-52cc-ca44-5605-3d3336569c02",
        "NOMBRE": "NEGLIGENCIA Y ABANDONO POR OTRA PERSONA ESPECIFICADA"
    },
    {
        "id": "6c9037ac-93c9-9897-044f-eef8c5d28a5c",
        "NOMBRE": "NEGLIGENCIA Y ABANDONO POR PADRE O MADRE"
    },
    {
        "id": "c8748ed5-1ea5-280f-d564-e5b4982ff564",
        "NOMBRE": "NEGLIGENCIA Y ABANDONO POR PERSONA NO ESPECIFICADA"
    },
    {
        "id": "ee699015-f203-8351-b902-9bd8f99d3794",
        "NOMBRE": "NEOVASCULARIZACIÓN DE LA CÓRNEA"
    },
    {
        "id": "3899ad37-e204-129e-3500-a17ffa767ddb",
        "NOMBRE": "NERVIOSISMO"
    },
    {
        "id": "280c9171-3194-dbe5-5171-26957dd5dc0e",
        "NOMBRE": "NEUMOCISTOSIS (J17.2*)"
    },
    {
        "id": "466e16d0-608c-7d2e-5acc-09d9289b10dc",
        "NOMBRE": "NEUMOCISTOSIS (J17.3*)"
    },
    {
        "id": "f8add422-d499-09d8-931f-91de6d04f8e3",
        "NOMBRE": "NEUMOCONIOSIS ASOCIADA CON TUBERCULOSIS"
    },
    {
        "id": "4b803745-fc7b-b0c5-d6d7-7576b9ce0878",
        "NOMBRE": "NEUMOCONIOSIS DE LOS MINEROS DEL CARBÓN"
    },
    {
        "id": "c0e67529-2ff2-0618-afc7-985d466fe55b",
        "NOMBRE": "NEUMOCONIOSIS DEBIDA A OTROS POLVOS INORGÁNICOS"
    },
    {
        "id": "f827ebc2-8e8a-b696-62e9-7720431f59af",
        "NOMBRE": "NEUMOCONIOSIS DEBIDA A OTROS POLVOS INORGÁNICOS ESPECIFICADOS"
    },
    {
        "id": "e09d3402-3381-63c6-1038-a5ee57f74a6a",
        "NOMBRE": "NEUMOCONIOSIS DEBIDA A OTROS POLVOS QUE CONTIENEN SÍLICE"
    },
    {
        "id": "9b7cdc1b-7489-808b-9e1f-8852fb0eb679",
        "NOMBRE": "NEUMOCONIOSIS DEBIDA A POLVO DE SÍLICE"
    },
    {
        "id": "fff80ee7-6f5b-9125-4c9e-6943ddd390db",
        "NOMBRE": "NEUMOCONIOSIS DEBIDA A POLVO DE TALCO"
    },
    {
        "id": "1385bfe8-274d-55ce-64cf-e2c079b5f587",
        "NOMBRE": "NEUMOCONIOSIS DEBIDA AL ASBESTO Y A OTRAS FIBRAS MINERALES"
    },
    {
        "id": "6aa18475-2e9a-25dc-e4f2-25cf377c3a07",
        "NOMBRE": "NEUMOCONIOSIS, NO ESPECIFICADA"
    },
    {
        "id": "4f47b4d9-8237-ba7f-1916-efe40a3240fb",
        "NOMBRE": "NEUMOMEDIASTINO ORIGINADO EN EL PERÍODO PERINATAL"
    },
    {
        "id": "765b9367-6336-e5b9-4595-3c30389e2a1d",
        "NOMBRE": "NEUMONÍA BACTERIANA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "5b8eb304-6339-2e65-9451-72f020fbf761",
        "NOMBRE": "NEUMONÍA BACTERIANA, NO ESPECIFICADA"
    },
    {
        "id": "b735e200-624b-25dd-876e-1a693d9c96d7",
        "NOMBRE": "NEUMONÍA CONGÉNITA"
    },
    {
        "id": "aeb1e92b-771a-7c5c-133f-62da470dae29",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A AGENTE VIRAL"
    },
    {
        "id": "763c7722-2eb7-8f8e-93ab-df7bf6167b27",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A CHLAMYDIA"
    },
    {
        "id": "7c5c3559-5661-1bdd-9a7b-2ddfe2759e2b",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A ESCHERICHIA COLI"
    },
    {
        "id": "231c58ea-244c-48ea-0b1a-0c2b4a849c24",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A ESTAFILOCOCOS"
    },
    {
        "id": "c7e87731-0750-0eca-5559-8924c6d398e3",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A ESTREPTOCOCOS DEL GRUPO B"
    },
    {
        "id": "d2fce73c-d66b-ed11-792b-520b211cbe93",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A OTROS AGENTES BACTERIANOS"
    },
    {
        "id": "5a75faca-efee-8221-a6ad-9f982f2f2efa",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A OTROS ORGANISMOS"
    },
    {
        "id": "d3d8b35d-e8b9-4105-fde6-8fbac85fbb12",
        "NOMBRE": "NEUMONÍA CONGÉNITA DEBIDA A PSEUDOMONAS"
    },
    {
        "id": "d84f736a-e9ac-20a9-7b1f-91ab15c25e74",
        "NOMBRE": "NEUMONÍA CONGÉNITA, ORGANISMO NO ESPECIFICADO"
    },
    {
        "id": "868230d4-19f9-9e13-cd69-07765f2049b6",
        "NOMBRE": "NEUMONÍA DEBIDA A ADENOVIRUS"
    },
    {
        "id": "22c51f4e-f9a2-a6e2-ae48-e9168ad9b4a4",
        "NOMBRE": "NEUMONÍA DEBIDA A CLAMIDIAS"
    },
    {
        "id": "b79ae162-2c62-1a08-f403-e98f2c7ea98c",
        "NOMBRE": "NEUMONÍA DEBIDA A ESCHERICHIA COLI"
    },
    {
        "id": "3de65e5c-fded-0e07-74ec-056217d42439",
        "NOMBRE": "NEUMONÍA DEBIDA A ESTAFILOCOCOS"
    },
    {
        "id": "2d91f5ed-3f25-eac2-f45c-803d30115278",
        "NOMBRE": "NEUMONÍA DEBIDA A ESTREPTOCOCOS DEL GRUPO B"
    },
    {
        "id": "915bb994-6957-3478-3d5c-8cb460ff8afa",
        "NOMBRE": "NEUMONÍA DEBIDA A HAEMOPHILUS INFLUENZAE"
    },
    {
        "id": "d5fe4971-ccd0-4894-1392-fc6a5d5d4da0",
        "NOMBRE": "NEUMONÍA DEBIDA A KLEBSIELLA PNEUMONIAE"
    },
    {
        "id": "10e7323f-0c86-c90d-c595-dfada7e83744",
        "NOMBRE": "NEUMONÍA DEBIDA A METANEUMOVIRUS HUMANO"
    },
    {
        "id": "83c787b4-e22c-d841-66df-088bf76a7cfa",
        "NOMBRE": "NEUMONÍA DEBIDA A MYCOPLASMA PNEUMONIAE"
    },
    {
        "id": "9eeb5a6c-0ff1-b32d-ee4d-acf0a622bc20",
        "NOMBRE": "NEUMONÍA DEBIDA A OTRAS BACTERIAS AERÓBICAS GRAMNEGATIVAS"
    },
    {
        "id": "d9ee1ba9-e0fa-6f29-4de3-b21722ff4ff3",
        "NOMBRE": "NEUMONÍA DEBIDA A OTROS ESTREPTOCOCOS"
    },
    {
        "id": "15e0c3d5-5471-4698-ec56-e936484cf29e",
        "NOMBRE": "NEUMONÍA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS ESPECIFICADOS"
    },
    {
        "id": "bc201b2a-c60a-bfe9-1e84-dddbbbd4f171",
        "NOMBRE": "NEUMONÍA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "69680466-fdbb-9bce-2b79-010f0d1832a1",
        "NOMBRE": "NEUMONÍA DEBIDA A OTROS VIRUS"
    },
    {
        "id": "4a30ca4f-7a4f-4fd6-bef8-4f1de54120b6",
        "NOMBRE": "NEUMONÍA DEBIDA A PSEUDOMONAS"
    },
    {
        "id": "9d2639a0-682e-f89d-b6b6-600e7d0d8505",
        "NOMBRE": "NEUMONÍA DEBIDA A STREPTOCOCCUS PNEUMONIAE"
    },
    {
        "id": "68c5aabb-4f7e-df19-9590-9597a39e43df",
        "NOMBRE": "NEUMONÍA DEBIDA A VARICELA"
    },
    {
        "id": "e10535c1-16eb-8310-8f9e-75b413278ad2",
        "NOMBRE": "NEUMONÍA DEBIDA A VIRUS PARAINFLUENZA"
    },
    {
        "id": "f751bca1-5a40-32d5-2b85-75e5bf9043d6",
        "NOMBRE": "NEUMONÍA DEBIDA A VIRUS SINCITIAL RESPIRATORIO"
    },
    {
        "id": "14614e0a-3344-a3a6-b8bf-61f24e187617",
        "NOMBRE": "NEUMONÍA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f47926b5-f5a0-93d0-0181-40899832d2cf",
        "NOMBRE": "NEUMONÍA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "85bdcb03-19bf-83a4-155c-bc3e1839bac1",
        "NOMBRE": "NEUMONÍA EN ENFERMEDADES PARASITARIAS"
    },
    {
        "id": "a7b09e1f-479b-6425-1dbe-e8393b88ce38",
        "NOMBRE": "NEUMONÍA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0bd9bea8-e935-d471-21bc-7ade2afec763",
        "NOMBRE": "NEUMONÍA EN MICOSIS"
    },
    {
        "id": "62c37760-9fbb-5a9a-4ca7-46e0ea70e16c",
        "NOMBRE": "NEUMONÍA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "85dd47b4-4d55-6593-b823-4ef06bb39426",
        "NOMBRE": "NEUMONÍA HIPOSTÁTICA, NO ESPECIFICADA"
    },
    {
        "id": "40ef287d-3176-64ad-7214-e2f792bd1460",
        "NOMBRE": "NEUMONÍA LOBAR, NO ESPECIFICADA"
    },
    {
        "id": "fc49c4f1-d335-5dd6-10a3-afaf23883848",
        "NOMBRE": "NEUMONÍA VIRAL, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "7fdf8588-997d-acde-9f78-62fd1a8f162b",
        "NOMBRE": "NEUMONÍA VIRAL, NO ESPECIFICADA"
    },
    {
        "id": "5a972194-dba8-95e0-fc31-58557bc53ee6",
        "NOMBRE": "NEUMONÍA, NO ESPECIFICADA"
    },
    {
        "id": "fd06ba6b-34fc-5277-5a40-520b9ef7ee34",
        "NOMBRE": "NEUMONÍA, ORGANISMO NO ESPECIFICADO"
    },
    {
        "id": "995c1997-0938-df4a-201d-e5373e4d54b8",
        "NOMBRE": "NEUMONITIS DE LA VENTILACIÓN DEBIDA AL ACONDICIONADOR Y HUMIDIFICADOR DEL AIRE"
    },
    {
        "id": "2e88bb22-64e2-a4bc-68e7-cf3bf2015e8e",
        "NOMBRE": "NEUMONITIS DEBIDA A ASPIRACIÓN DE ACEITES Y ESENCIAS"
    },
    {
        "id": "73713b20-8c58-cf14-2e61-8b4b130c2147",
        "NOMBRE": "NEUMONITIS DEBIDA A ASPIRACIÓN DE ALIMENTO O VÓMITO"
    },
    {
        "id": "4af33202-904e-f92f-d4cd-29be2c16f912",
        "NOMBRE": "NEUMONITIS DEBIDA A ASPIRACIÓN DE OTROS SÓLIDOS Y LÍQUIDOS"
    },
    {
        "id": "f13e4c88-36a6-9bf1-e4b5-0775c8695a4e",
        "NOMBRE": "NEUMONITIS DEBIDA A HIPERSENSIBILIDAD A POLVO ORGÁNICO NO ESPECIFICADO"
    },
    {
        "id": "49a7adc8-e074-6608-6c91-05eb4b7b4295",
        "NOMBRE": "NEUMONITIS DEBIDA A HIPERSENSIBILIDAD AL POLVO ORGÁNICO"
    },
    {
        "id": "ac584677-eccf-4548-adfd-b4df89a8226d",
        "NOMBRE": "NEUMONITIS DEBIDA A SÓLIDOS Y LÍQUIDOS"
    },
    {
        "id": "735fec14-efaa-e108-bda0-09d472d19dd8",
        "NOMBRE": "NEUMONITIS DEBIDA A VIRUS CITOMEGÁLICO"
    },
    {
        "id": "252e9623-f8ad-e689-ec45-798e92e2b542",
        "NOMBRE": "NEUMONITIS DEBIDAS A HIPERSENSIBILIDAD A OTROS POLVOS ORGÁNICOS"
    },
    {
        "id": "384b5f37-99f2-d571-1446-a506a94d16c3",
        "NOMBRE": "NEUMONITIS POR ASPIRACIÓN DEBIDA A LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "20ac6412-5a14-93f2-89bc-647543bfa724",
        "NOMBRE": "NEUMOPERICARDIO ORIGINADO EN EL PERÍODO PERINATAL"
    },
    {
        "id": "088451c7-110a-20eb-a1c0-6c59ab407c49",
        "NOMBRE": "NEUMOTÓRAX"
    },
    {
        "id": "20301a64-dcb7-c5e9-cf48-8ac443947cde",
        "NOMBRE": "NEUMÓTORAX ESPONTÁNEO A PRESIÓN"
    },
    {
        "id": "baa9c592-c282-5b2b-4e92-40684c3aedbd",
        "NOMBRE": "NEUMOTÓRAX ORIGINADO EN EL PERÍODO PERINATAL"
    },
    {
        "id": "ecc3087e-23c7-1b48-6c4d-6a216f072491",
        "NOMBRE": "NEUMOTÓRAX TRAUMÁTICO"
    },
    {
        "id": "f3880cfb-63c5-d1de-ba29-995cf8bc51ee",
        "NOMBRE": "NEUMOTÓRAX, NO ESPECIFICADO"
    },
    {
        "id": "5069f4a5-7355-d9cd-d62a-018889b74d63",
        "NOMBRE": "NEURALGIA DEL TRIGÉMINO"
    },
    {
        "id": "d4e6a1e9-3722-6ee3-399d-a44f6a2f6cda",
        "NOMBRE": "NEURALGIA POSTHERPES ZOSTER"
    },
    {
        "id": "c881bbe9-66da-8608-e59a-6047a9d14d4d",
        "NOMBRE": "NEURALGIA Y NEURITIS, NO ESPECIFICADAS"
    },
    {
        "id": "f2b76791-9b93-07a8-f0a4-62a501cad745",
        "NOMBRE": "NEURASTENIA"
    },
    {
        "id": "babb4d81-0ba5-9c30-7596-dc849203b0c6",
        "NOMBRE": "NEURITIS DEL NERVIO AUDITIVO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "79a892c6-f514-708b-3606-ca51d8d1bd30",
        "NOMBRE": "NEURITIS ÓPTICA"
    },
    {
        "id": "ab92b2e9-d4a5-eca9-d12b-39304ba78c2a",
        "NOMBRE": "NEURITIS RETROBULBAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b514d74e-e10f-a94c-ba0f-efebe307039b",
        "NOMBRE": "NEUROFIBROMATOSIS (NO MALIGNA)"
    },
    {
        "id": "e9c2d19e-ed60-49b7-afd7-e5787ce2f3e2",
        "NOMBRE": "NEUROMA DE MUÑÓN DE AMPUTACIÓN"
    },
    {
        "id": "72af4229-2014-701f-bf2f-293cca0d3b7a",
        "NOMBRE": "NEUROMIELITIS ÓPTICA [DEVIC]"
    },
    {
        "id": "ec8605ed-8e9c-1459-1e93-94e3a5cc27ae",
        "NOMBRE": "NEUROMIOPATÍA Y NEUROPATÍA PARANEOPLÁSICA"
    },
    {
        "id": "6580ff77-bad3-cae1-0b2c-d130a4feeeb2",
        "NOMBRE": "NEURONITIS VESTIBULAR"
    },
    {
        "id": "c9c09f96-4ce5-018b-b4bc-a131ec899b14",
        "NOMBRE": "NEUROPATÍA AL SUERO"
    },
    {
        "id": "5a870308-1568-dce4-5476-59b0a6694878",
        "NOMBRE": "NEUROPATÍA ASOCIADA CON ATAXIA HEREDITARIA"
    },
    {
        "id": "3bd31831-1c58-075d-d0d4-f71bc0385b09",
        "NOMBRE": "NEUROPATÍA AUTÓNOMA PERIFÉRICA IDIOPÁTICA"
    },
    {
        "id": "b27c8866-de2e-5744-9245-8baf0328a310",
        "NOMBRE": "NEUROPATÍA AUTONÓMICA EN ENFERMEDADES METABÓLICA Y ENDÓCRINAS"
    },
    {
        "id": "69911774-b76b-87af-9697-920d364152ff",
        "NOMBRE": "NEUROPATÍA HEREDITARIA E IDIOPÁTICA"
    },
    {
        "id": "eae23c58-5a31-68b6-9b9e-a384d3445593",
        "NOMBRE": "NEUROPATÍA HEREDITARIA E IDIOPÁTICA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "4be984ba-29bc-a04c-1831-85ae37c62372",
        "NOMBRE": "NEUROPATÍA HEREDITARIA MOTORA Y SENSORIAL"
    },
    {
        "id": "70384cff-2149-d2f3-9a6a-878a61288f01",
        "NOMBRE": "NEUROPATÍA INTERCOSTAL"
    },
    {
        "id": "d2b2c2f2-cd3f-2dd8-169a-fa5d7a92ab9e",
        "NOMBRE": "NEUROPATÍA PROGRESIVA IDIOPÁTICA"
    },
    {
        "id": "c7a5f163-7698-52c8-9b64-0da4c49fa6b0",
        "NOMBRE": "NEUROSÍFILIS ASINTOMÁTICA"
    },
    {
        "id": "8a33ed6f-e1c4-487d-9855-e759d323842d",
        "NOMBRE": "NEUROSÍFILIS CONGÉNITA TARDÍA [NEUROSÍFILIS JUVENIL]"
    },
    {
        "id": "262c7552-a412-ce9f-7f8b-61b78690d830",
        "NOMBRE": "NEUROSÍFILIS NO ESPECIFICADA"
    },
    {
        "id": "821ed629-e436-3bf1-2395-47dd4ec45c9a",
        "NOMBRE": "NEUROSÍFILIS SINTOMÁTICA"
    },
    {
        "id": "54885611-4887-35ad-fdee-24d3a66c4438",
        "NOMBRE": "NEUTROPENIA NEONATAL TRANSITORIA"
    },
    {
        "id": "13287fa7-02d4-50b1-727b-c78531a5805c",
        "NOMBRE": "NEVO MELANOCÍTICO"
    },
    {
        "id": "6c41990a-a4f3-0b6c-3520-a837802fe7a1",
        "NOMBRE": "NEVO MELANOCÍTICO DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"
    },
    {
        "id": "db0cda30-ec5d-cf9a-55d2-e52a208605db",
        "NOMBRE": "NEVO MELANOCÍTICO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"
    },
    {
        "id": "b80b34eb-8956-d84c-dfb5-28b57158feeb",
        "NOMBRE": "NEVO MELANOCÍTICO DEL CUERO CABELLUDO Y DEL CUELLO"
    },
    {
        "id": "47b6b231-b118-04f4-aa1a-394eafe9df84",
        "NOMBRE": "NEVO MELANOCÍTICO DEL LABIO"
    },
    {
        "id": "765b198c-348b-c280-6cf8-ba870c9ca5d4",
        "NOMBRE": "NEVO MELANOCÍTICO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "0224594d-011a-2317-3e2b-ebefcb20b012",
        "NOMBRE": "NEVO MELANOCÍTICO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "158f913c-f0cb-7c64-0f47-d25829a3270f",
        "NOMBRE": "NEVO MELANOCÍTICO DEL PÁRPADO, INCLUIDA LA COMISURA PALPEBRAL"
    },
    {
        "id": "1697d7c6-8f03-65ac-dd37-496d408edeee",
        "NOMBRE": "NEVO MELANOCÍTICO DEL TRONCO"
    },
    {
        "id": "58c49bce-3b14-2b15-d163-e920a2b7bf16",
        "NOMBRE": "NEVO MELANOCÍTICO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "f6eda9a7-d215-4e6f-3db1-a2380e87c3fb",
        "NOMBRE": "NEVO NO NEOPLÁSICO, CONGÉNITO"
    },
    {
        "id": "37c32203-6c7d-05f2-9bb6-3322910a12f0",
        "NOMBRE": "NEVO, NO NEOPLÁSICO"
    },
    {
        "id": "93de9c0e-67c8-63ce-16a8-2cec22a73a60",
        "NOMBRE": "NISTAGMO Y OTROS MOVIMIENTOS OCULARES IRREGULARES"
    },
    {
        "id": "bccb2391-4abe-0403-58fd-5f4932443eb8",
        "NOMBRE": "NIVEL ANORMAL DE ENZIMAS EN SUERO"
    },
    {
        "id": "5a97e447-b951-863d-12cf-451e090b1416",
        "NOMBRE": "NIVEL ANORMAL DE ENZIMAS EN SUERO, NO ESPECIFICADO"
    },
    {
        "id": "4df6c8f5-3e39-576d-b206-78711b6f8d0e",
        "NOMBRE": "NIVEL ANORMAL DE MINERAL EN LA SANGRE"
    },
    {
        "id": "682f036e-dbb3-6c16-2c61-61a4edec31cb",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 100 A 119 MG/100 ML"
    },
    {
        "id": "bcff6636-42e5-ffdf-36b7-9c941eb8d668",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 120 A 199 MG/100 ML"
    },
    {
        "id": "272e5fc1-2f5c-c3e8-75e5-eb9757d3c9bc",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 20 A 39 MG/100 ML"
    },
    {
        "id": "e41c3450-1c27-922d-de4e-c3f7732c853a",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 200 A 239 MG/100 ML"
    },
    {
        "id": "25c6c474-0af3-cea5-2b40-9e312962e377",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 240 MG/100 ML O MÁS"
    },
    {
        "id": "a8a8eb0f-bd1f-ea10-71f7-ee74e94a5cc5",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 40 A 59 MG/100 ML"
    },
    {
        "id": "652d950c-2e56-85e7-7502-9930902a9d72",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 60 A 79 MG/100 ML"
    },
    {
        "id": "5a889bc2-ad19-66ad-d849-3dcc8a4f219d",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE DE 80 A 99 MG/100 ML"
    },
    {
        "id": "6c08d3e3-4fdb-7792-ff10-286f5fd5d8e4",
        "NOMBRE": "NIVEL DE ALCOHOL EN LA SANGRE MENOR DE 20 MG/100 ML"
    },
    {
        "id": "58164b3e-9b28-e502-7a12-375bc8dbf6fd",
        "NOMBRE": "NIVEL ELEVADO DE GLUCOSA EN SANGRE"
    },
    {
        "id": "c056dc6e-2262-2339-b735-f7dc8df32489",
        "NOMBRE": "NIVELES ANORMALES DE OTRAS ENZIMAS EN SUERO"
    },
    {
        "id": "4caab0a7-82df-d8fe-bf4b-dde3bd8ca0c4",
        "NOMBRE": "NIVELES ANORMALES EN LA ORINA DE SUSTANCIAS DE ORIGEN PRINCIPALMENTE NO MEDICINAL"
    },
    {
        "id": "9ca843d2-a3ef-5360-bb55-81dd0dc80e3e",
        "NOMBRE": "NO ADMINISTRACIÓN DE DROGAS, MEDICAMENTOS O SUSTANCIAS BIOLÓGICAS NECESARIAS"
    },
    {
        "id": "3808e6b9-d4df-d775-6408-c5ac6c19540e",
        "NOMBRE": "NO ADMINISTRACIÓN DE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "3051d665-bf28-224c-c793-e65c9d2f16cd",
        "NOMBRE": "NOCARDIOSIS"
    },
    {
        "id": "253833c1-74c0-a37a-0312-b7d3f8980f31",
        "NOMBRE": "NOCARDIOSIS CUTÁNEA"
    },
    {
        "id": "5430a460-edd1-a1df-4cd5-c20947e33ade",
        "NOMBRE": "NOCARDIOSIS PULMONAR"
    },
    {
        "id": "da5ff2dc-7401-f35b-c2be-ab189104e3dc",
        "NOMBRE": "NOCARDIOSIS, NO ESPECIFICADA"
    },
    {
        "id": "72da68c2-80c9-9d7b-d444-bdb41663d69a",
        "NOMBRE": "NÓDULO REUMATOIDE"
    },
    {
        "id": "862101c9-75eb-0b8c-fec6-67abec118351",
        "NOMBRE": "NÓDULO TIROIDEO SOLITARIO NO TÓXICO"
    },
    {
        "id": "4f2877ce-583b-79fb-35cc-6dfc2044cce8",
        "NOMBRE": "NÓDULOS DE BOUCHARD (CON ARTROPATÍA)"
    },
    {
        "id": "6e360a2e-2956-67ea-df4c-6060d653445a",
        "NOMBRE": "NÓDULOS DE HEBERDEN (CON ARTROPATÍA)"
    },
    {
        "id": "79a96388-6ef3-61dd-63f4-91e11bdfaff9",
        "NOMBRE": "NÓDULOS DE LAS CUERDAS VOCALES"
    },
    {
        "id": "7e84bd56-b9d8-2ca4-6e21-b518b29c6fdb",
        "NOMBRE": "NÓDULOS DE SCHMORL"
    },
    {
        "id": "0bf720aa-02f8-3c41-e902-2995bb76cebd",
        "NOMBRE": "NÓDULOS INTERFALÁNGICOS"
    },
    {
        "id": "963ae5a9-e050-e8db-b575-8971099c22c8",
        "NOMBRE": "OBESIDAD"
    },
    {
        "id": "5f80ca2f-2782-ce9d-b40e-a0c4f02a3431",
        "NOMBRE": "OBESIDAD DEBIDA A EXCESO DE CALORÍAS"
    },
    {
        "id": "6de20113-3935-46f6-e66b-41449253d3fb",
        "NOMBRE": "OBESIDAD EXTREMA CON HIPOVENTILACIÓN ALVEOLAR"
    },
    {
        "id": "e3a6a50c-8643-7a61-9995-8345c5c7a1e7",
        "NOMBRE": "OBESIDAD INDUCIDA POR DROGAS"
    },
    {
        "id": "35295f11-0188-1e81-ca93-36d36c9a6ef1",
        "NOMBRE": "OBESIDAD, NO ESPECIFICADA"
    },
    {
        "id": "3b1b42c0-a5e8-211a-8005-c1d8b732d807",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE ASPIRACIÓN, PUNCIÓN Y OTRA CATETERIZACIÓN"
    },
    {
        "id": "e9edf8e7-6393-bc0d-eafd-62a4f393293e",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE ATENCIÓN MÉDICA Y QUIRÚRGICA NO ESPECIFICADA"
    },
    {
        "id": "abccaeee-3ad0-7456-93b3-29742093db4c",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE CATETERIZACIÓN CARDÍACA"
    },
    {
        "id": "26ce6b59-80ea-3978-034f-e18e9c22f96b",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE DIÁLISIS RENAL U OTRA PERFUSIÓN"
    },
    {
        "id": "83166b14-3963-a8a9-9c4b-194f9e52ca51",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE EXAMEN ENDOSCÓPICO"
    },
    {
        "id": "1b0f7c47-859c-c597-e78f-25a536117f14",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE INFUSIÓN O TRANSFUSIÓN"
    },
    {
        "id": "349d31d9-2e73-e19b-644f-3fb5b38421cf",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE INYECCIÓN O INMUNIZACIÓN"
    },
    {
        "id": "127080cb-4448-e36f-574c-974a7235714e",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "0f1bc38b-d7c5-effe-6091-2da5bcf434b6",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE OPERACIÓN QUIRÚRGICA"
    },
    {
        "id": "6120488b-d480-1dfb-157e-2147a73f05de",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE OTRAS ATENCIONES MÉDICAS Y QUIRÚRGICAS"
    },
    {
        "id": "8b4958e7-dc30-f291-53cc-4ea6c3c7e87a",
        "NOMBRE": "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE REMOCIÓN DE CATÉTER O TAPONAMIENTO"
    },
    {
        "id": "23240c55-dfc8-4eca-3e26-abee568eee31",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE EFECTOS TÓXICOS DE SUSTANCIAS INGERIDAS"
    },
    {
        "id": "5951046b-1474-dbea-20c6-627c95dd36b3",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE ENFERMEDAD O AFECCIÓN NO ESPECIFICADA"
    },
    {
        "id": "26219e3d-8403-8dbc-6ee1-ba51b8585649",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE INFARTO DE MIOCARDIO"
    },
    {
        "id": "fb048636-ceb2-d62c-77a0-a4c565488a32",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE OTRAS ENFERMEDADES CARDIOVASCULARES"
    },
    {
        "id": "a3fda0b2-a47c-f996-6799-6003a18e987f",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE OTRAS ENFERMEDADES Y AFECCIONES"
    },
    {
        "id": "e36af8c1-3c5e-48d7-c903-1fe67026363b",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE TRASTORNO DEL SISTEMA NERVIOSO"
    },
    {
        "id": "95db4fce-a346-e54f-69dd-dd30bd5af5ae",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE TRASTORNO MENTAL Y DEL COMPORTAMIENTO"
    },
    {
        "id": "94d66d16-2b8a-11e7-0092-dfc2a1525819",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE TUBERCULOSIS"
    },
    {
        "id": "93c764ed-a141-b1a0-bffc-d0102fccfb5d",
        "NOMBRE": "OBSERVACIÓN POR SOSPECHA DE TUMOR MALIGNO"
    },
    {
        "id": "d2288d10-194a-5def-4f5d-dddcac1ce477",
        "NOMBRE": "OBSERVACIÓN Y EVALUACIÓN MÉDICAS POR SOSPECHA DE ENFERMEDADES Y AFECCIONES, DESCARTADAS"
    },
    {
        "id": "8d895446-5894-2a85-64c3-b8d59d6ac447",
        "NOMBRE": "OBSTRUCCIÓN DE CUELLO DE LA VEJIGA"
    },
    {
        "id": "b7e45a97-e3a5-8966-54a0-c2f04ca805de",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS"
    },
    {
        "id": "11e6e48e-9126-bcbd-53ab-0a157b45d9e8",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "266209b7-4d25-eefa-d11b-28a7823d14dc",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "eb0df2a9-f05b-1e6d-a087-c9cb81967894",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, CALLES Y CARRETERAS"
    },
    {
        "id": "5209e017-fcf5-23b2-cb51-bc54eede988c",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1b3770b9-2712-a9f0-c6bb-b87ca76aa3b0",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "64326693-87b3-56ac-f815-1e567283acfb",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, GRANJA"
    },
    {
        "id": "784f6701-900c-972d-402b-e344bbc0e7cf",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "245868f3-de9f-1a79-4b00-d7c8e24b20ea",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "50d98e72-0f75-c43d-4201-ac5010a2df2c",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "1b48c247-a36a-74fb-271b-beac41c1654f",
        "NOMBRE": "OBSTRUCCIÓN DE LA RESPIRACIÓN DEBIDA A HUNDIMIENTO, CAÍDA DE TIERRA U OTRAS SUSTANCIAS, VIVIENDA"
    },
    {
        "id": "417887b7-2805-f19b-89e1-65c24d899300",
        "NOMBRE": "OBSTRUCCIÓN DE LA TROMPA DE EUSTAQUIO"
    },
    {
        "id": "3f206b93-4013-c08b-0d5f-ba56c7a76089",
        "NOMBRE": "OBSTRUCCIÓN DE LA VESÍCULA BILIAR"
    },
    {
        "id": "e356a91a-2cd9-3f65-7d91-4a3dc11a4226",
        "NOMBRE": "OBSTRUCCIÓN DEL CONDUCTO BILIAR"
    },
    {
        "id": "6dc27ebc-abd4-9900-d84f-9dcc716c5f02",
        "NOMBRE": "OBSTRUCCIÓN DEL DUODENO"
    },
    {
        "id": "cff4f857-19c2-b719-5eaa-bfbbec9967cb",
        "NOMBRE": "OBSTRUCCIÓN DEL ESÓFAGO"
    },
    {
        "id": "b2ace6e0-f034-cfd3-88f7-0f4e1cfc97c0",
        "NOMBRE": "OBSTRUCCIÓN INTESTINAL DEBIDA A LA LECHE ESPESA"
    },
    {
        "id": "fd6bfba9-5eb8-32cc-6958-f89b038486a5",
        "NOMBRE": "OBSTRUCCIÓN INTESTINAL DEL RECIÉN NACIDO, NO ESPECIFICADA"
    },
    {
        "id": "86885ada-96cd-b09d-c36e-dc010927f02a",
        "NOMBRE": "OBSTRUCCIÓN INTESTINAL POSTOPERATORIA"
    },
    {
        "id": "9c8a898a-975a-2a66-b747-63b4a2b5ef8e",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN"
    },
    {
        "id": "8ea74afd-a97c-7ee8-6f60-1e797ed4e44c",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "fe526798-31ed-9d05-384e-9e3d953cb2ef",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "09a1e8f0-158d-a9a1-4b43-b4f79fa597a4",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "551ff654-a088-1a03-b649-7499a6e5d15f",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e7da29e0-38e9-6426-b901-3aa2d862125f",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "9e690f96-c4cf-e1d4-d97d-6411832a7f25",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, GRANJA"
    },
    {
        "id": "d758251b-e77a-34ed-7c32-d969510c417b",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "b5f3a4f6-2d7f-e0dc-d8e1-f3171a226a9a",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c2cbf4b9-5427-7bac-2733-ee1fd32865cd",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "d907456d-7b5b-2860-3645-56280cf11e37",
        "NOMBRE": "OBSTRUCCIÓN NO ESPECIFICADA DE LA RESPIRACIÓN, VIVIENDA"
    },
    {
        "id": "5249f316-5b16-c16c-09fa-4d970abf1f7e",
        "NOMBRE": "OCLUSIÓN ARTERIAL TRANSITORIA DE LA RETINA"
    },
    {
        "id": "a20a732b-fe7c-a238-7778-2d7302bba13f",
        "NOMBRE": "OCLUSIÓN DE LA ARTERIA CENTRAL DE LA RETINA"
    },
    {
        "id": "cc1dbd48-fb1a-413b-1fd7-aea743f6a30c",
        "NOMBRE": "OCLUSIÓN VASCULAR DE LA RETINA"
    },
    {
        "id": "bf3ee160-ba22-134f-64c9-f16a5afc8ba1",
        "NOMBRE": "OCLUSIÓN VASCULAR RETINIANA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "8a5111c8-73ef-e0cc-f290-727c41ef7620",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE ARTERIA BASILAR"
    },
    {
        "id": "6a4ec83a-3272-8379-d4fb-e0c66b64e34c",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE ARTERIA CARÓTIDA"
    },
    {
        "id": "f10d6ad5-0efc-a7f6-b073-e47bd2105424",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE ARTERIA CEREBRAL NO ESPECIFICADA"
    },
    {
        "id": "38aa54bf-16ca-6be8-ae92-be6fc0ac45ca",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE ARTERIA PRECEREBRAL NO ESPECIFICADA"
    },
    {
        "id": "6e8c0a43-67ef-1585-3022-48cdc5a41a12",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE ARTERIA VERTEBRAL"
    },
    {
        "id": "c025899f-4fca-fc77-7c06-f3fb87603c72",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE ARTERIAS CEREBELOSAS"
    },
    {
        "id": "cb374852-e669-aa96-1193-da3d20e22d2a",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE LA ARTERIA CEREBRAL ANTERIOR"
    },
    {
        "id": "e917967a-4800-2c0e-db40-23dadfaaf347",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE LA ARTERIA CEREBRAL MEDIA"
    },
    {
        "id": "105f80e4-def2-9e40-e8d0-6a3e0283edf9",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE LA ARTERIA CEREBRAL POSTERIOR"
    },
    {
        "id": "1fde8957-0da6-d5dd-1a25-c5e1dddf0339",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE LAS ARTERIAS CEREBRALES SIN OCASIONAR INFARTO CEREBRAL"
    },
    {
        "id": "d7ef29ca-f603-8d33-9a08-30a2adf7fba0",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE LAS ARTERIAS PRECEREBRALES SIN OCASIONAR INFARTO CEREBRAL"
    },
    {
        "id": "f9558950-185b-9e72-03fa-21ce2c9f8bd3",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE OTRAS ARTERIAS CEREBRALES"
    },
    {
        "id": "215110f1-496d-1a99-fab9-94ed1dfbf6d3",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS DE OTRAS ARTERIAS PRECEREBRALES"
    },
    {
        "id": "77f75694-9ad4-f7bf-ee9d-801dedacd0c4",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS MÚLTIPLE BILATERAL DE ARTERIAS CEREBRALES"
    },
    {
        "id": "f61bd307-2046-5326-5fb3-2098b29ff747",
        "NOMBRE": "OCLUSIÓN Y ESTENOSIS MÚLTIPLE BILATERAL DE ARTERIAS PRECEREBRALES"
    },
    {
        "id": "b74cb09c-b9a7-66fb-cb8d-c95b7fb679a9",
        "NOMBRE": "OCULOPATÍA DEBIDA A TOXOPLASMA"
    },
    {
        "id": "d63f0ebb-cba5-4e5f-f4b7-b9ec755866b4",
        "NOMBRE": "OCULOPATÍA HERPÉTICA"
    },
    {
        "id": "0717aa50-38d5-cb91-5fb0-64c08827c615",
        "NOMBRE": "OCULOPATÍA SIFILÍTICA CONGÉNITA TARDÍA"
    },
    {
        "id": "89c14515-8a29-cdad-5d65-474035accca4",
        "NOMBRE": "OCUPANTE (CUALQUIERA) DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "7645df9a-b40f-c13d-d6ad-f1741a897911",
        "NOMBRE": "OCUPANTE (CUALQUIERA) DE AUTOMÓVIL LESIONADO EN ACCIDENTE NO DE TRÁNSITO, NO ESPECIFICADO"
    },
    {
        "id": "adbecd86-1e5b-ea7d-ef4c-098a2614d039",
        "NOMBRE": "OCUPANTE (CUALQUIERA) DE AUTOMÓVIL LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "aab1e361-3a35-c7b5-b673-9295fbdd9b3d",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "c6e8998f-a233-3e6a-5f74-6638036bb53c",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE AUTOBÚS LESIONADO EN ACCIDENTE NO DE TRÁNSITO, NO ESPECIFICADO"
    },
    {
        "id": "7accb2df-44b6-6f5f-7b11-8c7d4a6025e8",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE AUTOBÚS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "f9587316-c2c4-6bb6-959b-b3fc569a1540",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "e7cf9f2d-65ae-ea52-bcfe-8d682bd6fb0e",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE NO DE TRÁNSITO, NO ESPECIFICADO"
    },
    {
        "id": "7f664a20-5c8d-3b5b-7940-17f8640adc39",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE CAMIONETA O FURGONETA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "f53daca7-edc5-d226-5e11-0c9ec8f0f04a",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "e370746c-2711-628a-1e36-1d1e74fa4f5a",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE NO DE TRÁNSITO, NO ESPECIFICADO"
    },
    {
        "id": "faab1c53-4b92-e4b8-6725-c48a341b6726",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "5636bf44-0cd6-1db4-17e4-fccd79b3d137",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "c2ffa328-2d9f-941e-5aae-e774dc615612",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO, NO ESPECIFICADO"
    },
    {
        "id": "b5478e04-edf7-f9d1-74a3-7369a5a37424",
        "NOMBRE": "OCUPANTE [CUALQUIERA] DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "904ec608-04dc-558e-2c47-6f032354d91e",
        "NOMBRE": "OCUPANTE DE AERONAVE LESIONADO EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE AÉREO"
    },
    {
        "id": "c2b70429-924e-c9cd-4c72-273d73e768ae",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE O SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2982e82e-6eef-fed0-daaf-c02ffcd4f155",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN"
    },
    {
        "id": "aa941afa-c8ac-b4e3-e029-a09ed955b727",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "17f3938f-05e8-08f9-a4d8-6a3136a4e1cb",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "58bc1470-30ff-d7dd-b59f-1cef82d8d085",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "afbcf5ce-44a5-378f-79f0-f006ba60d4cc",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "88e0a8dd-066b-2549-5700-01a345de0e8e",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "87ed54a7-50f7-f51e-1f21-88bcbd6755dc",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "4111f53f-7984-2d63-6a4d-55727898370b",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "4f0dc6bb-ffbc-e9fe-f3b2-b111c6808675",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9f103136-7121-d828-2c83-d983c5d29f38",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "c87bbd77-2a8f-d700-1e80-3e07e64065d2",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "1c167bbd-da32-37da-9be5-14f2c76ab183",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "8c89ecd4-5599-e056-7fd6-2d42a994f737",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b673a146-29b8-8f9d-91e8-c997b3f91813",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1c150ace-63eb-91d3-a483-b46babed1142",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "abab82e9-54f5-e020-d8d3-9260296a5861",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5bdafc8e-ea01-7079-f5d7-bdb020d4f32f",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "96c0455a-23e7-f5c8-dd8d-75cd3b40e634",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "6fd69152-6920-48c1-f3e4-b904f99bec9e",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ba030e36-fa7a-4164-d393-37b1c019c833",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a0341299-ceff-b596-c350-25c25f63aa62",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO"
    },
    {
        "id": "cd76c64d-5802-c768-8b6f-a1960b2716b0",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "e6bfa48d-a60f-04d9-7229-7c90651efba7",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6404327c-931b-beb5-6eab-c5d7bff584f9",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ad915d88-0784-4a51-2785-1998fab77c8c",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "374b05c7-acba-edd8-1a2c-2d36d528890f",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "709c89e3-d76f-dfe8-9701-36c7617fbf59",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "07de2f3f-4995-f008-221e-1605e50a4cbb",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "c1a23725-3a11-f141-8844-ac548b486872",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f8227069-e531-f64b-acfb-a60d643042a7",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "15bf04ec-25a0-6967-7c5c-c2000e74bc10",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "333edecc-6b80-2689-d181-e919a9d5832a",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "8bf4eec4-96e7-6599-bcfc-8d6cbfe89e9f",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7320acb9-3293-4964-8285-016e16414d08",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a200e59b-78bf-fcce-b3d3-2bf8fd35b451",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "165ff50e-cd97-4fbd-77f1-1831cb695b74",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f5e7b3e1-4d8e-6c49-c8a6-c4032d921d01",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "434ce4cd-0336-fbbb-3f0e-7dc801d4f43e",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "ec22c98e-592c-6ce8-03bf-8778a158fcae",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ff7f53e0-3f00-ec2c-f594-b4bab5332e4f",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ad31ae07-4959-53a5-c4ee-5fa8018f1deb",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "72827a0a-15d1-761e-39a7-9bbc4a7d7756",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ae85b520-87cb-66cb-1072-b7faae280a0d",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "959d2aff-dc7d-5a16-c068-81a22ffee949",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "08014db6-d8b1-1d23-4ffa-fbc14e19e5b8",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "be070a2f-3b48-b71a-e8a2-4db8d5f3532e",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "fb3fbb98-cd8e-f52f-192d-f3ba7e860463",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "cdd15beb-1dcb-7057-ed75-81a1f2cae88c",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "d6b7173b-eede-f827-f24d-52b0481ab2eb",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "38752655-568e-fd7e-720a-ebf869e76c78",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "420a9482-7fb3-8847-0bcc-3fae7844add1",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "6ee10f4d-d6d3-70eb-6738-cab0ba5613f1",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "958d6c02-0db0-758b-f88e-ec6e83539ba5",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "1ef9a4d1-5bc3-be7d-ea5e-1955c3a45da7",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6aa8758c-c039-b5e4-d1ca-c48450dea8b7",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d78cf0cc-539e-0f5e-1a36-99ff609a4cd9",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "109e8598-8f34-9216-9503-a5d68ba2082b",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5cd7a4ee-d8bc-cdc9-4206-b705b6937989",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "81f4565a-8425-790d-8a78-615e63f0f15f",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "78c937a6-38e1-cfd6-b70d-1de82c94f623",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0f10a68c-6a55-209d-92c4-d39f13b9be16",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "8f412916-0db4-a0fc-f714-798b17c774a0",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1c9900d5-821a-887d-23ac-a73a89efb6fd",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "99ec4d69-019f-ced3-397f-4e75c4b4f802",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "66c2dafc-d0ff-7d2a-6458-471c71c87fdc",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d0be10cc-dd33-be90-407c-e26d5fc89ac6",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "467197f0-34b5-c002-b477-0af748e42621",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "06bad7d4-7bf9-bd3a-a57f-228820d05149",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "69990a42-bf7a-ed04-ba4a-494813712313",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "e13f9115-4a02-2d96-f9dc-270216c21269",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO TRÁNSITO"
    },
    {
        "id": "804f52f4-be93-aeff-3d59-5b84ed34a1cf",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "39dcb363-f610-c1d2-00dd-568a16292258",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "63597176-86fc-5787-f0a9-8602a9c14a9b",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "1b74448e-6404-a8bc-5c57-ea06ae9b750a",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5857a0ff-6af1-26ba-daee-bf32d4bbcc79",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7d5c949e-d538-bc16-7ca4-7751cafe43e1",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2ebb983e-780b-270c-c0c3-807d74f00690",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b9e2fdfc-6d68-fe44-a9f8-d53266314974",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "21eddb86-c995-02a9-be90-92c29704862c",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3e06cb7c-8b77-a518-720b-de624531aa2a",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d6470171-adfd-81be-ee7a-e3c2a178b1e8",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "f2331e20-6ff5-5c2b-d4e2-8d4b873eadfe",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b6a0f70d-c992-f1dd-fe7b-ea02089bba98",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "561a0875-c9d8-cfd5-2ac5-4a7da8021287",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "844b5b59-e8a6-d315-06a9-0b4e649d4131",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE AUTOBÚS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "821531df-1da4-a93c-18e3-ae0850fb2733",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "8ac75042-e51b-ed1b-22fc-5589c90d1d14",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "4595e3e6-c3f7-8b3c-adc2-9ad8214b0e6f",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "625393c6-c87c-a046-a5fb-748f76ca0739",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2f1a9284-ac14-2c43-31eb-a6342023318c",
        "NOMBRE": "OCUPANTE DE AUTOBÚS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "61770682-4d1e-fa21-0e4e-b5eb554fa79f",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN"
    },
    {
        "id": "ae33aac4-88cf-7622-6e67-5cb489b9bf0e",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "9fe44da5-ac8e-42ce-25a1-c93752b8f8d1",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "29282a5d-d11d-1d5f-a897-ee4e2a9a8bef",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "98bcba5f-9948-3f88-5c6f-d659139fbfde",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f6c49810-479d-0313-888b-36a554222be5",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6a0e9724-2adb-04f0-6db3-96e939bcb7cc",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b18e7b4f-838e-b9b0-f9ad-29785ea31040",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "239795c9-9319-431f-1625-3a8e58ad8b8d",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "4d988d28-b75b-7c19-9d0a-8de06bdcd91b",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "4281ebf1-48e7-d0f1-3ad8-6df908249b07",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "6c996736-d8b7-3f0e-6f3a-8cf57ed7ddb1",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO"
    },
    {
        "id": "adcc95b3-ea4d-9bf8-a69d-bb63a97e9367",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6d7343d0-bcea-455c-9bb6-a71479ffaa43",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a217a263-6d0a-c0ed-efb2-186ab0ad0026",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "bdba2719-8cec-6d06-5df1-c3b0d3d046a7",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9a9cb38d-a9f1-d523-23d1-e79fee36c784",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a5b4cca3-42d2-b66a-4b48-55b82bfa3faf",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "387f82f6-5b7f-9bde-be8b-e31e2bdf3b17",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "b4cf24c3-17ae-17e9-12a5-178d22fc64d7",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "98f0625b-f876-df78-6691-4328df534d78",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b4ad7a92-6a12-0205-f052-f36b724b9d62",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "acb03b5e-fbf8-f169-ba9d-d9ea4c333d5d",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "4b05aa5e-a7c1-1b83-7b40-505219ff5a4f",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0254dab2-54bc-3f45-cb81-c2a649f27b24",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "676502b9-0d1f-d41b-8b58-47b0dc3c9e24",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b3885ec3-bfde-ecfa-47b9-fe50ffccacc7",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "cefe8b8a-2004-e983-cb1c-a111e4d5e4f2",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "640c59a4-5e95-6fdf-24ac-4e7601427963",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "50140767-4ee8-fb52-b862-e2bd298b2924",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "854c7df6-8b3e-d1c6-c1d3-5b5e00f0f853",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTRO AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "65a3422f-3e68-6c10-2b4b-6028d2271f61",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "1a48697e-4a09-94ef-bb2e-94f9c84623c9",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c2d3f45b-2674-96d5-39bb-926a64df5a7d",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "04126eb0-5e72-e2a5-6bfa-ec2b399c5548",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c818fd08-1e8a-9617-8dd1-c07e42f66630",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "92298c08-828e-7616-5e19-483a8fbea184",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "7cdf7158-e9e9-1dbf-c603-8d3d8a0d94b3",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d9c57571-b931-d0f5-7110-478fc5f68e39",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "fcf1faf4-8720-4ad9-f649-2d33aa5c7f7a",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d3a22cb4-3afe-9528-9758-2fec4768b94d",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "602ba384-ffdf-3135-f2cf-c01000859d60",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "874fce7f-0e54-a1bb-a5d0-d4b2aefe60a9",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a9ef0b40-d0f4-23d2-404f-271ec64c92a2",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "1b5e262d-07af-49c4-8504-d1ec54c41734",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "8b038882-8f6e-8398-1170-ee42da197724",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "65ba33de-13e1-ffa2-ec1d-db8a3a1a51e6",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3df976ba-764e-4bfd-d748-1d41f065b4f5",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6dbbc686-5f04-b064-cc35-1b2f5ae4b91d",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "c678880d-d574-73e5-f007-d13aade6255d",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "485b1d44-7b57-1f41-0be8-4a01fef001ef",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d38f9dea-0caf-4d60-331f-65e006664cc4",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "866d870d-b365-16b1-8cee-c66ea680119b",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2691c5e0-31bc-c80f-0ef2-5f49b12ed221",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "73567ed2-3f9e-0b00-4788-9e155507531a",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "919390d1-3dab-3543-4c24-3f7d5cf4b9c0",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7d4ab958-12df-2af7-32e2-72fadbb9b560",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2b114e66-2d11-fffe-8113-4c46947c5286",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "e64fb38c-a249-0303-aa36-ed639ef7d3ca",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "c9dc6e3f-c8da-1fb9-15c2-2e69866ab650",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "e00a35f9-a116-b03f-d950-05b291a7fc49",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "257994fc-c720-c9e1-9e3c-946aac82cb62",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "e01d414d-f213-f4b2-178c-971e54c28f88",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "105fd828-19c7-b68d-8b2c-714c78dbd635",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9a9259ac-ff39-bb57-d597-533381fde61b",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ff84c723-2472-b612-f28b-ff85bc0eb6bc",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f8c616ec-e8bf-a72d-705b-f88c7f48849a",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "98eee23b-3939-3fa9-4c3f-4a241d647d26",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "e199a29b-0228-31b3-1fa9-7b6b8dcd01a3",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "16b0d3fd-eed3-d881-a1b3-833b3f9ceb81",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "bd3f68e7-f1f3-6682-fa71-3b9f90dc3ee6",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "fcf80dc1-034a-c640-53ae-625404cbaad9",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "78462072-7851-f25d-759d-ccfb645eb2f8",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "595f8422-fa37-364c-861c-77be1c91c099",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d431b265-f5c9-cb71-bd8a-dc89d85501b7",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "058ae03a-aaa9-fbd7-d255-1d69065e6732",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f44ce118-ba5d-a11b-4ea5-fe8595308f6f",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "171e5aef-d6e6-b356-6658-022c23887c95",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c01840f9-b614-720a-7a55-cf1906d7b36b",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "190d83e9-1034-a042-c298-77dfb37b8c6f",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5a0ffee8-4e6a-a3a1-fb56-fb7864084890",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5d60c8f6-3774-0c43-5434-d4eef92145cb",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "e6fe2f61-ef81-384d-ffd9-7a99b6949f2e",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS , CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "30bdd537-2afa-6a0c-8698-f2c2a6f0d349",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS , OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d7cbb24e-1f98-cb98-da15-22eb781a0efe",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS , OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0148a5a0-5baf-8cb3-2928-6519598ffd53",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS , PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "262bb21b-d1f6-2bac-35c6-9d47884bf076",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS , PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "a5a5a114-b423-0546-5727-20fc42e54eb5",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "80079a0f-42e6-94a2-d37b-fdd8bf9e9684",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a7416d0f-8a0e-f886-4104-c9f160855b65",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ed40d69e-8365-3edb-979f-632acaab1433",
        "NOMBRE": "OCUPANTE DE AUTOMÓVIL LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9bd5c885-01fa-ef86-af96-714e03b7e714",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN"
    },
    {
        "id": "94a163b6-a415-1286-497e-caf8521aaf6e",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "7346b2b2-d1b5-7888-3bc9-8dc8d7ddbdad",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5175b1fd-12f7-9cad-f518-48d8039e3724",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a24afbc7-465e-58d3-cd24-30385190509c",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b70d2f1c-48ba-5276-82c8-3f906acddf98",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "0ee7986b-bdf8-b018-a432-6eb7b4c42668",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "bdce0a65-13f4-e543-165f-fa33ea703bf9",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "a0d0996d-71b3-27d6-525a-8386a99328a6",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d2562e07-979f-112a-e783-395ccf753799",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ca069325-345a-0670-2c76-4b3d0f089337",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "e1c27d8e-dd54-c642-5225-8eef6f35c37c",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "b61df498-c233-649c-1f7d-c89fcd4dda68",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "23732a4a-3a2d-c82d-79df-0604387d2edb",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "503e9746-513e-71a7-376a-af8ea2a81663",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5909a4d8-ff89-e6f2-5a0e-e60e65ad9fd0",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "95245157-6cfc-f0b8-feb7-c2825b803be8",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ffe52014-430d-d8fd-9024-f75eb60f22ef",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "11d2c654-37ef-c4a7-f812-525d995d4988",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "baf5821a-b997-aa35-1c04-d8ad6b60cba5",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b69f7193-935c-8b10-5558-eaf5315dd426",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "aa1926c9-f21a-224e-1d02-027bbd532dc3",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3bcf8816-b995-412f-de77-bf039d9f7947",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6f247957-3877-c727-c243-8acd2767cecc",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "28924b57-c9e0-688e-7cc5-55a645d21fda",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONANDO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "fa377774-9127-ac17-b2b9-c127dd1f2881",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2b32cb87-f976-2eec-965e-8a5aba3d65cc",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c1aad80d-4942-40d5-5170-4a007a435ab1",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "1a4688b7-eaad-2e0b-e91e-e0b25fa83b3e",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "99167431-52f3-a2b0-f18e-6178a5229ee0",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "001d7624-983d-064c-ac33-74e2f1745d27",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "fe9c783c-dc04-e078-c9b4-df3e6e122f14",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3de1d679-7785-a868-dcba-ea4106129494",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "31f82bc0-7307-8637-1cd3-34e0e17c0ac4",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "7dc0c5ec-931c-56da-dfdf-f8de7066a7d8",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6d94fc59-3fe1-7389-04b9-1908285c9fb5",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "77d182bf-0e16-d229-ac19-307cfeaf2ea6",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "585a6214-53f6-af8f-2879-2922fa8cc242",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "27186a92-dd28-75d6-f7c9-23f8f8b89de7",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "06bcce57-7b1f-e032-7f28-8d0551cb0df3",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "73819a61-f114-37b1-9135-b0460d51b9ec",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "d3f01274-95f4-9b2b-c238-6e6095e715b6",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "fe968513-ebdf-0619-66af-b6c269ddc771",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3e37569d-ccc3-b48b-084d-4273df0767dc",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "489db98b-42ca-bcc4-17c3-e1f5597b544c",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "01ad7426-0da7-9e48-5e76-f49cb77e8f22",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2f5706c4-6d69-9abd-0f02-4f87ea7d572d",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "10783581-aed9-fdd5-cbc9-50686167b305",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "33442e4b-9cd1-3033-9e93-4404ad9e65d0",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "80d62dc7-31cd-f645-6ddb-f790954b8186",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a71552e5-169f-b9c6-a10d-77b8d5e13515",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "4d4ad9ab-f717-bdad-b570-bdc2949ad1ce",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a039af39-1b7e-a32d-6f80-f3e6fc891a4e",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "05384428-874a-e35b-87e5-7a6b0e7017df",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2635e856-dbc7-2a7c-9234-9a19652b35a5",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7bc14206-e777-253f-2e13-abce08984237",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a33e1a4c-938d-c827-dd22-b62d13a11196",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "738b89ad-03ec-3e5c-2337-c7648fdf3e94",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "869b46e9-3453-241b-1d35-3b0fedea39ef",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b529a80f-9134-014d-edf9-468f52320457",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "fc20e124-2170-7579-29a1-bca27b53dea7",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "6d90a08a-9661-c842-9d26-fe6026c00385",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "360486b0-492c-ee78-2ab4-1e54f42f8515",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "85de4780-cb77-3f46-a2d1-502c22926a1a",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c12c5ece-a76b-3936-0010-bfefd31aa010",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7086cc30-5152-751b-3048-ef56b5e029bf",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "4a688e19-34db-f74c-a9b0-24f5740ec0be",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c3c52c86-b36b-7b7d-4138-1b72cec137a9",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "50d8fcd3-8303-cf53-d781-c7f2fc535ef7",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "13bd8d54-5433-f500-c6b8-f8780b771fc5",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "8685c961-3070-13b6-9a09-131e3df410e5",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "2c692b11-0124-943b-b7ab-02b363cf40eb",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "03ec6004-3079-59dd-b0d5-e9cf91a82976",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "38d30f31-9222-c8e4-5286-7cc47208f327",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "565c333f-063c-8cbc-ec61-84eb112233d9",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "bb41ded1-17b9-c57c-b62a-6e8af6dda361",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f9bdbc8d-e004-ad7c-dcf9-2172a2f8e4af",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5453e757-b6b0-8a2c-61cc-84cda63a7778",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "2d6b5259-bad2-f27f-1058-e3414ee1f102",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "18188e85-1ae2-d9af-51c7-81f0263e91a1",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "51a3b83e-2e69-4c2c-9570-3c63c73c7b1b",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "569e2e27-a5fb-6ede-4798-ffc9f14f3eca",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6322d9dc-b73a-8297-f920-deff36ef4fe3",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2bac2c57-f1b6-1b59-999a-13b0884f3126",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ede805a0-0154-0e49-1f9b-6b6adbf0fc08",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f6731d4f-ed5a-ec54-1ff2-aa057ea02c87",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "aba72b7b-4187-617a-2ef8-4772d7df5fa6",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2748dfb5-0352-e888-d5c3-360447a240fb",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "308c35f3-fe7e-9de8-9c98-5b985a4bec23",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b1ef05c8-9f9e-d28d-bd79-5f0fdc5a59c6",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b1f7fe3e-a983-e534-9068-73b86baea8ca",
        "NOMBRE": "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN OBJETO FIJO O ESTACIONADO"
    },
    {
        "id": "e551b8c9-fb9c-415f-ff42-345770338b96",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "ff82a690-9f5c-b3da-6af0-4d7e306398ce",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO EN ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "251e8700-b241-5a06-75c3-d9872ad55391",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, ESPECIFICADOS"
    },
    {
        "id": "4b1cc1b6-833d-2dda-f597-1b72d409e32a",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO POR CAÍDA DENTRO DEL TRANVÍA"
    },
    {
        "id": "31af3693-5386-9dfb-3a34-587930e87a43",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO POR CAÍDA DESDE EL TRANVÍA"
    },
    {
        "id": "ebb193e9-85ea-903d-ea95-d5bde17fb699",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO POR COLISIÓN CON OTROS OBJETOS"
    },
    {
        "id": "2d8aa735-3198-4d0b-d73b-191941551972",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d8795e7e-5116-92d6-b9e5-a1ae9e2e7302",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5528786c-b807-02fa-25e5-d5fa91a17443",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO POR COLISIÓN CON, O GOLPEADO POR VAGÓN"
    },
    {
        "id": "c7d0ac3a-5f68-7085-64f9-c5fe8b8eb6fe",
        "NOMBRE": "OCUPANTE DE TRANVÍA LESIONADO POR DESCARRILAMIENTO, SIN COLISIÓN ANTERIOR"
    },
    {
        "id": "70be0930-f128-a783-fa5a-4762eca72b50",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO EN ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "f5fce13f-9020-dac0-2309-8f6db7c1b1ff",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO EN ACCIDENTE FERROVIARIO NO ESPECIFICADO"
    },
    {
        "id": "f8ec52ae-0d56-629f-91c9-fc7061f47e18",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO EN DESCARRILAMIENTO SIN COLISIÓN ANTERIOR"
    },
    {
        "id": "4d7259fb-c960-7fca-164e-a2a529c32e55",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO EN OTROS ACCIDENTES FERROVIARIOS ESPECIFICADOS"
    },
    {
        "id": "56a25468-1808-1d94-ed04-67bfacb711dc",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO POR CAÍDA DENTRO DEL TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "ac2b347f-bc94-5efe-dd18-bcb64030daa5",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO POR CAÍDA DESDE EL TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "b3e7101b-75b6-0eda-3aa3-eebcea2fe9cc",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO POR COLISIÓN CON OTROS OBJETOS"
    },
    {
        "id": "de1c5bd4-cd60-24a6-1410-cae73e971626",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b0a30864-aac9-7b57-d2a1-68388976b1d7",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "620a7e74-4515-5e55-0176-e04055519a5e",
        "NOMBRE": "OCUPANTE DE TREN O VEHÍCULO DE RIELES LESIONADO POR COLISIÓN CON, O GOLPEADO POR VAGÓN"
    },
    {
        "id": "0e85ec60-b678-7838-588e-f42e649df88a",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN"
    },
    {
        "id": "7dd60020-75bf-75a5-b692-e877ed35ea03",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "87755d29-1389-8b12-2695-0e446ed98f3c",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "426f4bda-bb28-d8c5-d1e9-ff716f848957",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ad1ef619-64d1-945b-80e4-075f4a398158",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5b626326-009d-df18-5a85-1f43abfed784",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "8ba08c71-20b1-a6c7-1347-03d4d082e6b7",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ae13b603-e652-4488-604d-d6a843e6f532",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "1217a262-97a5-ae67-0764-7f40b13d56d7",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a951f903-3172-54ce-d401-fbad08132ff8",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a4b08e07-89d2-4d14-1c4f-e2f183d9da6d",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "9cc4dd83-3caf-6198-4ec3-a8b061c03684",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "03badd42-a265-86ac-9670-7afda201dac4",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "9e8908c3-5ccb-6fb9-23e3-980c9772cc6b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "8f604676-62f8-6fef-61ae-f774ca2e11a0",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f548e16b-0b45-e9c0-ca20-d9c2370dfe32",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "828119a4-8e11-0b9e-8ece-72aef15571d6",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "daed9075-63ea-7972-b709-94238fb78916",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "38d43858-a1b5-3867-72c6-bbe158c164a8",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "ddc187da-c023-a386-2344-f92f17f57378",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ae2e9112-362c-a940-2487-bc413101b617",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a5b07b60-995d-d547-b3fb-2b04ccce466a",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO"
    },
    {
        "id": "a2b641f5-ded8-260f-463f-ddd66c0fd91d",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5cbc91a1-760d-21bc-9973-5047c0335415",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "76635ceb-8ff2-01d6-f458-aff059042f10",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "30b27006-3e4a-084a-c373-fa2306838bdc",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d8a7f365-c3c2-825b-93ff-cfb020bfa929",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "14c931c2-b5b4-068b-909a-8c718d7813d9",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0ca4f08b-85f6-75d8-30e1-20cdc92aed85",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "4e84f17a-8847-1a12-da6d-8a17ac98da60",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "eae10db1-8eac-3c3a-a195-9dfde0d026c4",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "4ac13a0f-4c03-2219-6bfc-d3502cf4c2bc",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "33070127-36b1-6bd4-b157-2da2341c6aa4",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "51d59a32-813c-9a51-7753-c72dec54a813",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "52611674-2d48-c279-721a-31f8e618fa0b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ca9740df-9427-6fa3-1592-b99961a264a5",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "dff0c0ee-a99b-6b20-fe1c-1a99589c7a3f",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "7085bf1a-d256-f891-ca41-34ad2f1124c8",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ad35aa52-0de9-940f-47fd-7c58cdb64992",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "3837e8e5-6a94-2199-5a23-0c908f9aef74",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f97fe0c6-7865-e7e3-829d-dafe83b832b7",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f34841a4-f68b-83e7-f2ef-aeb0d5152a44",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "ebb202c5-869e-8e75-75d0-e32d5e23a2f5",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6cb82be3-abf0-e31c-cab6-74f293365f06",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "e0f1eaa9-50d4-c75a-a8f1-292795f05d54",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "cd5daafc-ed7a-03b0-f060-94b0d4179ab6",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5f066652-ca09-6ca1-bedf-a39ba393c3c4",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "e4a98312-bb20-9373-1b39-9e2b8c00afc2",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a011b13a-a3be-af42-64e9-7286cf9989ee",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "6648abc6-39ea-00eb-40a0-7d7ca54d40b6",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a61d674d-b3b6-6f53-86e5-16d81c24bceb",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "86f37563-9838-be0d-ac53-f91dfb0d67bc",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "acaab037-0eb3-036d-6a3d-975ebd130ed8",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "0c776058-ddfc-6366-b611-c1b3e6488237",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "845881c1-2c5a-87ab-bad5-7ab806047168",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2538330c-91a8-a145-531e-d8dca61b8906",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2d8e13d9-ebbb-eb5f-df7c-e582a52087d9",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "53cce924-e58f-18ab-1448-3cfaa68a8749",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b4d23acc-98de-faef-502b-08a46cdd8215",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "7875309b-6f03-0036-88d0-e6dd25e02c1a",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b7199f3b-c77f-ad95-99f8-8e87a85f7a48",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "23a48870-6f2f-4e6e-6c16-5655719b2483",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "7a8e6ff1-29f1-9115-606b-b9e7da5c7a0c",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "0b6beca3-8e9a-6f2c-fe7d-3f647fcfae5d",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "91a34143-dafa-a11c-f7c1-40868910ec9d",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "dfc56989-2ae8-c2fa-e92b-26418f161d91",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7757b94e-e83f-f552-5904-ba535346e762",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b0e0673d-ee97-a772-0041-9c999f908a53",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3d0c0797-67c0-e1c8-48d0-c196bd47d718",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "b22d10ba-6911-703d-23d4-49aa3806a7a7",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "868e5f1a-2f90-8542-e517-65fef472d477",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "28c585f4-878a-3547-c0c9-54776dc317fa",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "28f4da10-3b15-9fde-ceaa-f63345bdb27f",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "791f0656-97d2-78f2-a06a-8af77618bc4c",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "da50b6db-ed26-ada6-ede1-092845b74e85",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "bfd80d29-f98d-2504-e592-46f740a133d3",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d86682eb-90fb-669d-c5f7-aeaf8d3a6681",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "518f788e-779c-c9de-46a4-583b7b1dd3ae",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "cf2f9458-45c1-e735-678b-be87053e177b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "d3084286-12ce-4bbc-2795-796f091af295",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1a695d72-d6d5-1d92-6442-265aa86abe42",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "b1090d49-61ce-5cd3-0369-7da313236447",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "4cae9d7f-2831-13b8-293a-27fdcedea19f",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "9c666167-247b-8624-9c27-b8282eae8848",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f7225d0e-c03a-bfda-e3b8-758914fc0680",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1f98c271-9236-9b9f-3132-da16694b5dc3",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a3681344-11aa-43b3-c8fb-bc60d0c10bfc",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "8028a4a0-2952-70ea-8594-a60ed2230501",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "328f8051-7cdc-9a28-fc77-398f72ae8c5b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "4384948e-e283-18da-8d4e-567ed5c81a35",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "26b3b168-9858-6ab2-2dd0-f44891b37ea5",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "58a87642-a566-e95c-1ea5-c1cd15d79267",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN"
    },
    {
        "id": "59bf78e9-b474-c84b-9c7c-011b25c1f34a",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5291b9cd-1456-5789-f000-846d72d71990",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "dcce3e1a-035b-d0d2-c220-c4661acb2d11",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f0f3d1e4-5aae-5954-2107-2bfff0cc3bee",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3e22676f-b740-dd8e-2680-af8c82072cde",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "05f0acb7-2eae-896f-d20f-6e88a1ffa46f",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2f004ce5-67a1-8993-08fb-90d2ddc2b2e3",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "172c3440-8989-0484-be66-c73de2ea4cc8",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "80b71769-389b-3be9-8220-d437aca483ba",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISIÓN, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "bdc29a61-5bc7-72bc-6c7d-1dba748c1cd6",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "d5c60d84-9d3f-fa78-52ed-575da5351c83",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "07075295-7d2c-15c9-b4e2-51c64019997d",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "66d0c26b-d276-c5f3-f800-5608d0784ae7",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f6b546c4-36c6-bbd8-8a63-994d3449c094",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "e09ae839-399a-1962-0be8-fa2890b2f7f5",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d8332c23-9563-3b8a-bd76-7024e0b8f972",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d038391e-bb39-a3bd-ada1-35b3370f45ed",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "7c68936c-fe02-b15f-dea6-847b02f6c5e1",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "8e53e86f-c9b0-7a34-1a29-6fe9775a3bc6",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "314f0617-a3cf-5042-f7bb-51995e31f3f2",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "49a251ee-a809-3c69-f649-0787c3f2f770",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO"
    },
    {
        "id": "00e70767-3fc9-a4ae-ea6c-ee0991d474b7",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c6235466-c962-c526-9eb8-9c2f7b53e87b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "13534751-926b-fe22-b21b-1ab21aa65a42",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "e2ed2368-8692-0043-27ae-58971bf63bc1",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "cf5a748d-85c6-66cc-0bd5-2534988355e5",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "64f16322-b04b-e2ac-c02d-eb1ab57b69b9",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "594b36f2-3948-359f-bf58-e3249d34d99d",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "37cba2d6-e651-3694-1df3-4d6706e9362b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "d6724508-0ec4-4a9e-91c8-94f10b8666e1",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "1a22156e-0d0f-803c-a7db-76aac59db63e",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "15f73495-f871-599c-69f2-5ac7f16cf2ed",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c38092d5-aaa5-8813-7064-f277815bb653",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0701cd26-00b4-cc0b-c1c4-d93de289720f",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "eb61d850-db93-aaea-9e72-64d12efd5331",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3f52dbc6-63a0-60be-5e40-283c32759059",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "256da613-89fe-7938-9097-665c14bb27d5",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a6beee02-61f9-31a6-6d82-618c8156de1e",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "2bd834ec-0504-6c25-2ffc-e37b81d1bb22",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "493bfb9b-b685-c07e-054f-633332db1435",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTRO VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5a51bf7a-86fd-f5dc-b35b-c8523c76dd16",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "3675f674-c02a-531d-8cda-26b96266828f",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3dab7043-f942-0cd9-41cb-8bbb8103ff58",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9206182b-eef5-d364-ab99-79d880a0bf47",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "0f5a495d-f952-b959-4026-60d567f6f848",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, OCUPANTE NO ESPECIFICADO, DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "00fb6c18-601c-8b6c-b531-b6a68b76686b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "4be32813-ddb9-5ade-4955-6fb74c287884",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f5a8b9ad-7a2f-d7eb-5a14-3c4310458f9c",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "4fdca6a2-465b-fcc6-1340-408f8062908e",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a55da4dd-4757-73af-a91b-3a375378cf72",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2fd434f7-5c91-5606-7445-1fc7e0b40f1a",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL"
    },
    {
        "id": "555cf567-76d3-7a67-a144-8ae5ca3c086f",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "87591688-ef4e-6901-bee3-6e96a3bb0579",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d4a89a95-387a-eee5-7a20-56dc1e390d13",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a76855de-6d4d-886b-a483-39f9e5792608",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "dbb15c62-0fbc-ed31-9d1b-bd67f002e66e",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "28577f4f-f498-fa0b-7c34-bf4429026a47",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6957fcf2-500f-006c-2a50-fbcffd649e83",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "1e4efd0d-a7bc-b1f3-176b-22cef38f6192",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2306cde9-6f41-c5a0-9fd2-9e97eab98cb4",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON PEATÓN O ANIMAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c0b21872-29a6-9a5b-3cc4-19c16066d6e2",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "3d389d15-26e6-5f6c-c933-3d5a4d3f6023",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3d9bbf6e-aeea-0895-c655-254183a50e24",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a81c88a5-897f-5040-0562-1d700671ebd6",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5630cd59-d64b-7c79-d8dc-e277dfc15788",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6f00059e-bc05-9517-ace4-7aab20cac2fd",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f811c56f-0cf3-d334-def7-a891a8fe8930",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "77af8fd1-31ce-92dd-c07e-e93c9c4406c9",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "7cba8c66-d820-b3a5-5c05-4f4b2898b654",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "90cea925-999a-b39c-4e42-a987178a1c70",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6fde7c2b-a2a2-5ea2-b77d-3987f6872449",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "0eaa4e63-abe7-d5ea-19f5-3b86b09907d3",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "2259d99b-a437-8209-ac77-0eb0af6232a4",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "577a6707-fd24-018c-1f74-b83fb26a1dde",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "062431ac-f39f-efce-34be-243f2b928142",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a286d4aa-9e39-4c4b-847f-2279d9f45920",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "3b48c600-dfa9-f19e-c5b1-fa3fbdfa53ab",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2ced7ab2-7262-bc77-95b6-75dfccf10679",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "ddec999d-75b5-13f7-5102-cb7de7513087",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "02643239-a128-a9c7-1f3f-8316f8845a52",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0edae408-0d98-73f4-6cc4-85dfd09ef0e2",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "ef631801-d734-ea0d-0d92-d82f7e1b8bc2",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "887070df-b541-e078-9939-ba1e97cb5554",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "82987328-b990-bfc4-f1e5-cc0f704d6958",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "30e42374-ef95-8006-1518-6a7f55513eef",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "76384db3-1ba2-331a-6199-1648e72b8b46",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "09b13d48-c70a-99bc-2586-0af4613f1029",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PASAJERO LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "424bd119-6583-a104-9da2-c6828eb54f6b",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO"
    },
    {
        "id": "c470ac1d-fa1a-1c2d-c10c-e83d6fb6c339",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1d6f1273-4415-4216-3122-f1d3ee3f51e0",
        "NOMBRE": "OCUPANTE DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, PERSONA QUE VIAJA FUERA DEL VEHÍCULO, LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "d2a0abc5-a1b4-0576-3020-2d39a7a209cf",
        "NOMBRE": "OCUPANTE DE VEHÍCULO ESPECIAL (DE MOTOR) PARA CONSTRUCCIÓN LESIONADO EN ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "17da06ef-621d-8d77-0d12-35c1945e69b3",
        "NOMBRE": "OCUPANTE DE VEHÍCULO ESPECIAL (DE MOTOR) PARA USO PRINCIPALMENTE EN AGRICULTURA LESIONADO EN ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "a851d1f8-f35e-67fc-8c4b-b0e81033cbec",
        "NOMBRE": "OCUPANTE DE VEHÍCULO ESPECIAL (DE MOTOR) PARA USO PRINCIPALMENTE EN PLANTAS INDUSTRIALES LESIONADO EN ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "6235dfe4-7bee-5ba7-50e6-d0bd8e335370",
        "NOMBRE": "OCUPANTE DE VEHÍCULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRANSPORTE"
    },
    {
        "id": "0618550b-b19a-8661-7aee-43fe19b97d18",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "4765a3ad-5359-5232-977b-c36cae2c1ee9",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "56ee9940-2245-35be-757d-af59b8a2f629",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "fe26aac3-840d-df60-9d08-9b8f0084244b",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "4ae31f34-fe25-45f2-bf99-8a4ae3884c5e",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "862b6f05-044c-ac07-3787-b6b129fcc5bc",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "68a90189-9319-4879-b9c3-94b007cc3fd4",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO AGRÍCOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5189db4f-ac5a-1fc2-723c-545af26e8bb9",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO AGRÍCOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c4e86fa9-6d66-5c3d-dfdb-f0a418dcc169",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "7f03eae6-7371-8da7-9d6b-caf92d39a8fc",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "2dde62c8-6cfb-496e-e545-2b78f7ad4c76",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c85c82c2-986c-6a29-e24a-ca3301d0af5a",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "20387f7b-6ea2-d572-b19a-5291c320b99b",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "975a78e4-6e73-fe22-b0a6-867daa2cbeca",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "4b63a63c-976f-ec54-9788-010eac5b8c64",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "082d4e96-2f59-7a18-2739-06d03757fbff",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1ba99591-9f48-49c4-91ab-b015d3fe1efb",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "8faf0937-9c8f-2ff5-48a7-22b65f22c805",
        "NOMBRE": "OCUPANTE NO ESPECIFICADO DEL VEHÍCULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "fcc7b924-3ce9-95c0-02c3-08108be5f2d8",
        "NOMBRE": "ODONTOCLASIA"
    },
    {
        "id": "121696ec-aab7-e619-a25a-4af8617c1666",
        "NOMBRE": "OFIASIS"
    },
    {
        "id": "8ab8c644-5583-b803-f9ca-a8489f795a82",
        "NOMBRE": "OFTALMOPLEJÍA EXTERNA PROGRESIVA"
    },
    {
        "id": "ae8612e4-9d79-b67f-281c-d60496aa8e57",
        "NOMBRE": "OFTALMOPLEJÍA INTERNUCLEAR"
    },
    {
        "id": "0f76af48-8a2b-b368-af73-61324a507acd",
        "NOMBRE": "OFTALMOPLEJÍA SUPRANUCLEAR PROGRESIVA [STEELE-RICHARDSON-OLSZEWSKI]"
    },
    {
        "id": "14b079be-9e31-8740-dd9f-b2b5cd799087",
        "NOMBRE": "OFTALMOPLEJÍA TOTAL (EXTERNA)"
    },
    {
        "id": "1a67a7e8-b417-93b4-fa2e-3ce3fd6761d2",
        "NOMBRE": "OLIGOHIDRAMNIOS"
    },
    {
        "id": "d08b5956-46a9-e745-fd85-fd8fdd04822a",
        "NOMBRE": "OLIGOMENORREA PRIMARIA"
    },
    {
        "id": "1e9ba114-8045-876b-51d5-af68fedcfb0a",
        "NOMBRE": "OLIGOMENORREA SECUNDARIA"
    },
    {
        "id": "981e96bc-5598-8912-3f63-58516fd2ffe3",
        "NOMBRE": "OLIGOMENORREA, NO ESPECIFICADA"
    },
    {
        "id": "845fae36-14fd-b69b-ae95-626a3d012656",
        "NOMBRE": "ONCOCERCOSIS"
    },
    {
        "id": "a4745045-c64a-b405-c358-12b6c8c879d3",
        "NOMBRE": "ONFALITIS DEL RECIÉN NACIDO CON O SIN HEMORRAGIA LEVE"
    },
    {
        "id": "c76c77a9-b22d-59b2-be82-91c1f5f7a0f6",
        "NOMBRE": "ONICOGRIPOSIS"
    },
    {
        "id": "2e6675ac-2306-d559-e6fa-2a4cc9208217",
        "NOMBRE": "ONICÓLISIS"
    },
    {
        "id": "1aedcce4-1c7c-b6ca-5c7d-91fbaf602bd0",
        "NOMBRE": "OPACIDAD CORNEAL CONGÉNITA"
    },
    {
        "id": "74f561f6-3ca8-fd42-8c86-15e985990230",
        "NOMBRE": "OPACIDADES Y CICATRICES CORNEALES"
    },
    {
        "id": "de80aeaf-dd68-3f81-b3a2-eefe5fad5486",
        "NOMBRE": "OPERACIÓN DE GUERRA NO ESPECIFICADA"
    },
    {
        "id": "e383f9b3-6e39-b20f-dc29-0d11b6b5c7c3",
        "NOMBRE": "OPERACIÓN DESTRUCTIVA PARA FACILITAR EL PARTO"
    },
    {
        "id": "a051e4b9-16cd-b4a3-617f-ef7c52158735",
        "NOMBRE": "OPERACIÓN QUIRÚRGICA CON ANASTOMOSIS, DERIVACIÓN O INJERTO"
    },
    {
        "id": "e98fcbdc-0aeb-6a01-c783-2d58f4b84a47",
        "NOMBRE": "OPERACIÓN QUIRÚRGICA CON FORMACIÓN DE ESTOMA EXTERNO"
    },
    {
        "id": "6644a3f1-fea2-2978-41c5-534c8189d693",
        "NOMBRE": "OPERACIÓN QUIRÚRGICA CON IMPLANTE DE UN DISPOSITIVO ARTIFICIAL INTERNO"
    },
    {
        "id": "b889c823-7fb0-b77d-ba1d-c65afa1e6ef7",
        "NOMBRE": "OPERACIÓN QUIRÚRGICA CON TRASPLANTE DE UN ÓRGANO COMPLETO"
    },
    {
        "id": "e064aa8d-cf6d-0e15-b797-65703a7ecf03",
        "NOMBRE": "OPERACIONES DE GUERRA"
    },
    {
        "id": "90a63911-d279-9c45-2432-f0df3c2ab694",
        "NOMBRE": "OPERACIONES DE GUERRA CON ARMAS BIOLÓGICAS"
    },
    {
        "id": "549ad490-86a3-ded6-d22d-355c5201bc9c",
        "NOMBRE": "OPERACIONES DE GUERRA CON ARMAS NUCLEARES"
    },
    {
        "id": "010cb1a0-9db5-2c1d-54f5-ee31db5f601c",
        "NOMBRE": "OPERACIONES DE GUERRA CON ARMAS QUÍMICAS Y OTRAS FORMAS DE GUERRA NO CONVENCIONAL"
    },
    {
        "id": "f23c4049-c2ea-f298-1d01-ab63c946c8cb",
        "NOMBRE": "OPERACIONES DE GUERRA CON DESTRUCCIÓN DE AERONAVE"
    },
    {
        "id": "b3333feb-a182-89b5-5ae1-9cbc7453cddc",
        "NOMBRE": "OPERACIONES DE GUERRA CON DISPARO DE ARMA DE FUEGO Y OTRAS FORMAS DE GUERRA CONVENCIONAL"
    },
    {
        "id": "74e4fd00-e87c-729f-6b95-160643b73cc1",
        "NOMBRE": "OPERACIONES DE GUERRA CON EXPLOSIÓN DE ARMAMENTO NAVAL"
    },
    {
        "id": "e8bbef8a-be69-d381-c81c-b65da122b5ad",
        "NOMBRE": "OPERACIONES DE GUERRA CON FUEGO Y SUSTANCIAS INCENDIARIAS Y CALIENTES"
    },
    {
        "id": "cad7e98f-95bf-54b2-6334-a92d3153e121",
        "NOMBRE": "OPERACIONES DE GUERRA CON OTRAS EXPLOSIONES Y ESQUIRLAS"
    },
    {
        "id": "336ce4dc-6614-fd64-fc41-ebd5be246980",
        "NOMBRE": "OPERACIONES DE GUERRA QUE OCURREN DESPUÉS DEL CESE DE HOSTILIDADES"
    },
    {
        "id": "c520409e-4b38-f6dc-3514-3fd40cd602d2",
        "NOMBRE": "OPISTORQUIASIS"
    },
    {
        "id": "435dbbfc-2f30-5f79-e176-2bb4f3450cbb",
        "NOMBRE": "OREJA EN COLIFLOR"
    },
    {
        "id": "ad348d85-ec19-c94e-846a-b12d9b816020",
        "NOMBRE": "OREJA PROMINENTE"
    },
    {
        "id": "3adac483-3f37-f77e-fc84-25a4d2b3388e",
        "NOMBRE": "OREJA SUPERNUMERARIA"
    },
    {
        "id": "75d44149-a11d-ceb7-a649-a68edd8da883",
        "NOMBRE": "ÓRGANO O TEJIDO TRASPLANTADO NO ESPECIFICADO"
    },
    {
        "id": "5d17e3f0-6825-5861-64f9-95123a616950",
        "NOMBRE": "ÓRGANOS Y TEJIDOS TRASPLANTADOS"
    },
    {
        "id": "01012370-8c62-e9b2-f7bd-d7426200d206",
        "NOMBRE": "ORIENTACIÓN SEXUAL EGODISTÓNICA"
    },
    {
        "id": "fe9ac3aa-3055-c9e7-50db-151cf394fa9c",
        "NOMBRE": "ORQUITIS POR PAROTIDITIS"
    },
    {
        "id": "be943d6a-7646-a589-c0e9-c5708acec387",
        "NOMBRE": "ORQUITIS Y EPIDIDIMITIS"
    },
    {
        "id": "c5ac80f9-7210-7467-0767-8097f383ebd5",
        "NOMBRE": "ORQUITIS, EPIDIDIMITIS Y ORQUIEPIDIDIMITIS CON ABSCESO"
    },
    {
        "id": "8b5de69d-6b94-c893-e0ca-5aaace5144f5",
        "NOMBRE": "ORQUITIS, EPIDIDIMITIS Y ORQUIEPIDIDIMITIS SIN ABSCESO"
    },
    {
        "id": "b5cb7262-efbc-8ab2-7e10-6c91b5a3d567",
        "NOMBRE": "ORZUELO Y CALACIO"
    },
    {
        "id": "510ec2e7-32f7-3c98-d45d-23a155be130b",
        "NOMBRE": "ORZUELO Y OTRAS INFLAMACIONES PROFUNDAS DEL PÁRPADO"
    },
    {
        "id": "2ad45aa3-6988-02b7-5885-62fbbef11552",
        "NOMBRE": "OSTEÍTIS CONDENSANTE"
    },
    {
        "id": "b3f9335f-1c76-9b8e-c6c9-944b62400d18",
        "NOMBRE": "OSTEÍTIS DEFORMANTE EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "570b0c97-3bd4-3ff5-2787-8b1facac9732",
        "NOMBRE": "OSTEOCONDRITIS DISECANTE"
    },
    {
        "id": "c88816b7-e6c9-70e5-07f3-51e391c5ab12",
        "NOMBRE": "OSTEOCONDRODISPLASIA CON DEFECTO DEL CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "a9aeb939-9667-1ae4-8eef-27675eb22537",
        "NOMBRE": "OSTEOCONDRODISPLASIA CON DEFECTOS DEL CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "a952f163-522f-31ef-04f0-8c6b9210ad9a",
        "NOMBRE": "OSTEOCONDRODISPLASIA, NO ESPECIFICADA"
    },
    {
        "id": "bd5cf013-ab26-318e-f97a-920d5e7b6c79",
        "NOMBRE": "OSTEOCONDROPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "e0e6eccc-9ed8-ecb9-2b20-4ea8d2afc82f",
        "NOMBRE": "OSTEOCONDROSIS DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "13e6a90b-688b-6d55-dafc-ed53754aebf1",
        "NOMBRE": "OSTEOCONDROSIS DE LA COLUMNA VERTEBRAL DEL ADULTO"
    },
    {
        "id": "b1606b62-609b-6b3f-c891-f1f5a6ec22dd",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA CABEZA DEL FÉMUR [LEGG-CALVÉ-PERTHES]"
    },
    {
        "id": "747b1d3c-fdc6-b16f-37bd-7e28c6aab964",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA CADERA Y DE LA PELVIS"
    },
    {
        "id": "09191bc6-a379-8576-3fde-af8e5aea7be4",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA CADERA Y DE LA PELVIS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "186ec0d1-64b4-ae99-cb5a-b75de2e7fcca",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "5aff145b-1648-03f7-eaae-6d16a5e59071",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA MANO"
    },
    {
        "id": "287af7bf-5780-d069-2418-d75021e2cb87",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA PELVIS"
    },
    {
        "id": "d97877fe-421b-3461-3140-7064c4c5d212",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA RÓTULA"
    },
    {
        "id": "2967a982-caa8-7619-40bf-94bbb1ac2c3b",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DE LA TIBIA Y DEL PERONÉ"
    },
    {
        "id": "0f7ca78d-c3f7-5d6e-0923-9629d2025661",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DEL CÚBITO Y DEL RADIO"
    },
    {
        "id": "0b352709-e8c0-d707-e2a3-45928911bcd0",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DEL HUMERO"
    },
    {
        "id": "c0d3b2b9-098f-6dd6-ecb1-e93bf505fa97",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DEL METATARSO"
    },
    {
        "id": "18b9091c-3f66-fc6f-300d-08970b5771f3",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL DEL TARSO"
    },
    {
        "id": "51437a8b-184a-4fd9-b962-b9dfe57b4b39",
        "NOMBRE": "OSTEOCONDROSIS JUVENIL, NO ESPECIFICADA"
    },
    {
        "id": "7cd5ffbc-3591-565e-8158-587133c27d1a",
        "NOMBRE": "OSTEOCONDROSIS VERTEBRAL, NO ESPECIFICADA"
    },
    {
        "id": "13cc6c0f-141f-294b-494a-24b649eacc6f",
        "NOMBRE": "OSTEODISTROFIA RENAL"
    },
    {
        "id": "76ee47d9-5430-5719-5724-6daa85ed1420",
        "NOMBRE": "OSTEOFITO"
    },
    {
        "id": "29d46bf4-625e-dcf9-3f4d-7277cef53f42",
        "NOMBRE": "OSTEOGÉNESIS IMPERFECTA"
    },
    {
        "id": "71236a24-fcf0-20aa-6e97-dc6c57a86a6c",
        "NOMBRE": "OSTEÓLISIS"
    },
    {
        "id": "0f55d8ab-1c15-1e31-5b6c-a0222f15b60e",
        "NOMBRE": "OSTEOMALACIA DEL ADULTO"
    },
    {
        "id": "dc362508-59c3-a7db-4bfc-8ab4ea666bbe",
        "NOMBRE": "OSTEOMALACIA DEL ADULTO DEBIDA A DESNUTRICIÓN"
    },
    {
        "id": "99ae1c7c-cc33-2d7a-7adc-d7f49e451e8c",
        "NOMBRE": "OSTEOMALACIA DEL ADULTO DEBIDA A MALABSORCIÓN"
    },
    {
        "id": "50b96de3-8f3a-b4fb-838f-463c149b7ab0",
        "NOMBRE": "OSTEOMALACIA DEL ADULTO, NO ESPECIFICADA"
    },
    {
        "id": "fdf1c1ad-7e0c-2041-9ec7-5eb2a0cc7c39",
        "NOMBRE": "OSTEOMALACIA PUERPERAL"
    },
    {
        "id": "ea73d941-9965-448b-c6f7-7dc6396a58d5",
        "NOMBRE": "OSTEOMALACIA SENIL"
    },
    {
        "id": "fd07ec0b-7002-5ba8-8a35-2f87a6a8d505",
        "NOMBRE": "OSTEOMIELITIS"
    },
    {
        "id": "a6663282-0dbd-8c25-9c54-a6ea865dab24",
        "NOMBRE": "OSTEOMIELITIS CRÓNICA CON DRENAJE DEL SENO"
    },
    {
        "id": "5e60774f-ee49-2ebf-0df3-325fb688b2da",
        "NOMBRE": "OSTEOMIELITIS DE VÉRTEBRA"
    },
    {
        "id": "f4cbfb9b-e49b-1a6e-5ed5-36124d2e599d",
        "NOMBRE": "OSTEOMIELITIS HEMATÓGENA AGUDA"
    },
    {
        "id": "ac435809-d396-201e-e0e2-3d370e30376e",
        "NOMBRE": "OSTEOMIELITIS MULTIFOCAL CRÓNICA"
    },
    {
        "id": "a69ba199-8e82-56b9-fe51-872034e07722",
        "NOMBRE": "OSTEOMIELITIS SUBAGUDA"
    },
    {
        "id": "bd82e1d5-2063-2425-eded-6ae9fe427140",
        "NOMBRE": "OSTEOMIELITIS, NO ESPECIFICADA"
    },
    {
        "id": "0aff08a1-57f8-5a8c-568f-55a78cb17d45",
        "NOMBRE": "OSTEOMIELOFIBROSIS"
    },
    {
        "id": "a24835cd-07fc-f445-8ea7-56f93622113e",
        "NOMBRE": "OSTEONECROSIS"
    },
    {
        "id": "bf619def-725c-38b8-ba4f-d9f96e8ddf69",
        "NOMBRE": "OSTEONECROSIS DEBIDA A DROGAS"
    },
    {
        "id": "0b0372f8-d73f-cb09-23fb-d7d1d0f009e4",
        "NOMBRE": "OSTEONECROSIS DEBIDA A HEMOGLOBINOPATÍA"
    },
    {
        "id": "1340f50b-bba9-4af5-fc68-2c0d520b2cd8",
        "NOMBRE": "OSTEONECROSIS DEBIDA A TRAUMATISMO PREVIO"
    },
    {
        "id": "6e32d71f-c255-c06c-a8a1-9ff2b2d12d92",
        "NOMBRE": "OSTEONECROSIS EN LA ENFERMEDAD CAUSADA POR DESCOMPRESIÓN"
    },
    {
        "id": "3bd1a49d-985c-f27e-dd4c-c0d77c55b3d9",
        "NOMBRE": "OSTEONECROSIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "11bca152-4901-47f5-13be-df63fd06e0ad",
        "NOMBRE": "OSTEONECROSIS, NO ESPECIFICADA"
    },
    {
        "id": "3650dece-8233-00b1-1728-22c176d5c0fd",
        "NOMBRE": "OSTEOPATÍA A CONSECUENCIA DE POLIOMIELITIS"
    },
    {
        "id": "86e47321-1e4d-b733-8d6a-6b23e1c78979",
        "NOMBRE": "OSTEOPATÍA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "38f55468-29b9-2549-e0f9-7092c6622ac8",
        "NOMBRE": "OSTEOPATÍA EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d3d57b9f-3587-3fc3-c7b0-b1fdffefb5d8",
        "NOMBRE": "OSTEOPATÍAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5d980c6f-05b5-6b8c-26ad-aa0b38099565",
        "NOMBRE": "OSTEOPETROSIS"
    },
    {
        "id": "36722020-dca0-9db3-d997-ac9685991162",
        "NOMBRE": "OSTEOPOROSIS CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "8ad11405-9630-7b0f-ccb7-5b659dcb896d",
        "NOMBRE": "OSTEOPOROSIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ee73fdf0-ad91-db01-345a-a4b0bb27123c",
        "NOMBRE": "OSTEOPOROSIS EN MIELOMATOSIS MÚLTIPLE"
    },
    {
        "id": "1276e8de-f2ad-b23b-3148-344acf8514ec",
        "NOMBRE": "OSTEOPOROSIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6136bf20-fa0d-a570-c13d-b0b708076067",
        "NOMBRE": "OSTEOPOROSIS EN TRASTORNOS ENDOCRINOS"
    },
    {
        "id": "68aa8695-b675-0d50-84bb-f8faed823895",
        "NOMBRE": "OSTEOPOROSIS IDIOPÁTICA, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "941d7a63-7521-4234-6bdc-9b83dc8c3fef",
        "NOMBRE": "OSTEOPOROSIS IDIOPÁTICA, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "d9a145dc-766c-6c0b-6297-f7f9e9bbffc0",
        "NOMBRE": "OSTEOPOROSIS INDUCIDA POR DROGAS, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "4f04286f-f35f-04c3-ed80-2683228a7edd",
        "NOMBRE": "OSTEOPOROSIS INDUCIDA POR DROGAS, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "b7784a49-d539-0dfd-438f-ba5423326f65",
        "NOMBRE": "OSTEOPOROSIS LOCALIZADA [LEQUESNE], SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "a00ac013-9e45-4477-c4c9-1884824b1159",
        "NOMBRE": "OSTEOPOROSIS NO ESPECIFICADA, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "83e3746e-ca86-656b-0a70-3ef067e15694",
        "NOMBRE": "OSTEOPOROSIS NO ESPECIFICADA, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "6477a8a4-db8f-5ef5-2c31-e494d7ef855d",
        "NOMBRE": "OSTEOPOROSIS POR DESUSO, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "745b6c2a-ea6d-82e4-3cb7-c3530064d814",
        "NOMBRE": "OSTEOPOROSIS POR DESUSO, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "b02afcfc-3b00-8c99-72ad-2e7e40905ecf",
        "NOMBRE": "OSTEOPOROSIS POR MALABSORCIÓN POSTQUIRÚRGICA, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "0711d24c-aab4-7768-2cab-9b6ca0c04933",
        "NOMBRE": "OSTEOPOROSIS POR MALABSORCIÓN POSTQUIRÚRGICA, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "6af3c19d-340d-83e4-0b4d-d4640793e64e",
        "NOMBRE": "OSTEOPOROSIS POSTMENOPÁUSICA, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "b133aadb-35d1-e687-1bb3-f178681161de",
        "NOMBRE": "OSTEOPOROSIS POSTMENOPÁUSICA, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "257ba215-5453-3e28-8e82-372af6b62cd4",
        "NOMBRE": "OSTEOPOROSIS POSTOOFORECTOMÍA, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "48f4475a-faa6-14bf-1bea-0c5e00b03329",
        "NOMBRE": "OSTEOPOROSIS POSTOOFORECTOMÍA, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "91e520c6-8473-00e4-8c2b-763f89819b84",
        "NOMBRE": "OSTEOPOROSIS SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "cf3c0d7e-d5f8-5ebf-838b-efb6ad84b2c3",
        "NOMBRE": "OTALGIA"
    },
    {
        "id": "ac45c755-ce74-197c-7e09-6b740cd5bc4a",
        "NOMBRE": "OTALGIA Y SECRECIÓN DEL OÍDO"
    },
    {
        "id": "afe014b0-7960-4193-8a61-651f504e44a6",
        "NOMBRE": "OTITIS EXTERNA"
    },
    {
        "id": "dc8b7c29-3370-6d1e-6fc1-d994ee0edbb9",
        "NOMBRE": "OTITIS EXTERNA AGUDA, NO INFECCIOSA"
    },
    {
        "id": "0084d7a5-5f2f-a6e1-aa05-b2adb01aac5c",
        "NOMBRE": "OTITIS EXTERNA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c04a6201-be47-9ace-c40d-e16e248b1fc8",
        "NOMBRE": "OTITIS EXTERNA MALIGNA"
    },
    {
        "id": "7bd97b0d-90ba-afda-bcf8-8ddb05f53a9d",
        "NOMBRE": "OTITIS EXTERNA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "69fe11a9-5a2a-c123-0170-b8d864d050a4",
        "NOMBRE": "OTITIS MEDIA AGUDA SEROSA"
    },
    {
        "id": "6a2e31ff-385e-d028-eafb-eb5e63a311ca",
        "NOMBRE": "OTITIS MEDIA CRÓNICA MUCOIDE"
    },
    {
        "id": "35ed4a31-4e27-5702-d912-216f4cfeb92a",
        "NOMBRE": "OTITIS MEDIA CRÓNICA SEROSA"
    },
    {
        "id": "b217e747-22ef-c75c-3d7f-bcc1ec1eea41",
        "NOMBRE": "OTITIS MEDIA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4f260c6a-7617-4505-fd51-c7689c8d6fbf",
        "NOMBRE": "OTITIS MEDIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f8139bc4-c5bd-aea7-bc3e-0e37e85db0a6",
        "NOMBRE": "OTITIS MEDIA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0a55c1ba-11db-d9d5-54d5-e7ce8ad72dfd",
        "NOMBRE": "OTITIS MEDIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b4f598af-b8eb-0223-ea48-eb3290ec3f6c",
        "NOMBRE": "OTITIS MEDIA NO SUPURATIVA"
    },
    {
        "id": "438c2a73-11cc-f961-405c-6bd44d1add0b",
        "NOMBRE": "OTITIS MEDIA NO SUPURATIVA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "0a3c21ae-3274-56ab-4677-34a106325fe5",
        "NOMBRE": "OTITIS MEDIA SUPURATIVA AGUDA"
    },
    {
        "id": "493b9e2c-5f6d-2c80-b53e-0431de8e50d7",
        "NOMBRE": "OTITIS MEDIA SUPURATIVA CRÓNICA ATICOANTRAL"
    },
    {
        "id": "a2bb7c1e-3c28-0f9e-4809-80d4e9e5efc4",
        "NOMBRE": "OTITIS MEDIA SUPURATIVA Y LA NO ESPECIFICADA"
    },
    {
        "id": "aea78e63-e7ae-2b64-4bf5-720e5aab1ede",
        "NOMBRE": "OTITIS MEDIA SUPURATIVA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "032986b2-64bd-2d2a-7da0-ac0b8270f1e4",
        "NOMBRE": "OTITIS MEDIA TUBOTIMPÁNICA SUPURATIVA CRÓNICA"
    },
    {
        "id": "884a348d-61d0-822b-3833-7b9afbad5728",
        "NOMBRE": "OTITIS MEDIA, NO ESPECIFICADA"
    },
    {
        "id": "4289c474-9601-9e44-0aa4-71e6b5ddf4a5",
        "NOMBRE": "OTITITS EXTERNA EN ENFERMEDADES VÍRALES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0e4bdc6c-186d-dc82-d9f4-45f3f16e3645",
        "NOMBRE": "OTITITS EXTERNA EN MICOSIS"
    },
    {
        "id": "86ad7c21-1cb0-c720-b8c6-ec6c7aef1bc6",
        "NOMBRE": "OTITITS EXTERNA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a3a259f9-d680-5060-25cc-603f1f019da1",
        "NOMBRE": "OTITITS EXTERNA EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fc411858-6923-5836-d03f-c6fab5609072",
        "NOMBRE": "OTORRAGIA"
    },
    {
        "id": "6f6919c7-a874-6700-4416-777e44f95099",
        "NOMBRE": "OTORREA"
    },
    {
        "id": "7cec84e3-ffbd-82f7-1fe8-0b54d2a38ced",
        "NOMBRE": "OTOSCLEROSIS"
    },
    {
        "id": "95b6882f-b0ac-f051-6e5f-ef4358bf73f3",
        "NOMBRE": "OTOSCLEROSIS COCLEAR"
    },
    {
        "id": "06bdad2c-6878-1934-c304-699e4e3d4625",
        "NOMBRE": "OTOSCLEROSIS QUE AFECTA LA VENTANA OVAL, NO OBLITERANTE"
    },
    {
        "id": "ec35a064-905b-eec7-14ee-81cb4e36f0f6",
        "NOMBRE": "OTOSCLEROSIS QUE AFECTA LA VENTANA OVAL, OBLITERANTE"
    },
    {
        "id": "0b8f9b95-e772-6f61-cd16-826bd1607581",
        "NOMBRE": "OTOSCLEROSIS, NO ESPECIFICADA"
    },
    {
        "id": "8e289e9f-6e19-25e7-a0aa-b0fee2655f33",
        "NOMBRE": "OTRA AMNESIA"
    },
    {
        "id": "a8bfd77a-0e58-419c-e991-0f1b70b9b263",
        "NOMBRE": "OTRA ATENCIÓN ESPECIFICADA PARA LA PROCREACIÓN"
    },
    {
        "id": "e7ed4b10-26d1-22b3-7d42-4e39256236db",
        "NOMBRE": "OTRA ATENCIÓN MÉDICA"
    },
    {
        "id": "f8d47300-e4a9-cfb1-45aa-c3b550c1c63e",
        "NOMBRE": "OTRA CIRUGÍA PROFILÁCTICA"
    },
    {
        "id": "cb5cf756-b034-990f-1520-85089e162225",
        "NOMBRE": "OTRA CIRUGÍA RECONSTRUCTIVA"
    },
    {
        "id": "830cbc11-4c53-f5a8-b6a9-724c1fe94f03",
        "NOMBRE": "OTRA COXARTROSIS POSTRAUMÁTICA"
    },
    {
        "id": "a4cb8044-53a1-f7be-fcc9-fa580abe4419",
        "NOMBRE": "OTRA COXARTROSIS SECUNDARIA, BILATERAL"
    },
    {
        "id": "8375526a-0f80-98c4-6bd4-d8c53c90a8d9",
        "NOMBRE": "OTRA DUPLICACIÓN DEL ÚTERO"
    },
    {
        "id": "135e4208-3d55-2539-b5d4-8e04c9a9fe49",
        "NOMBRE": "OTRA HIPERACTIVIDAD CORTICOSUPRARRENAL"
    },
    {
        "id": "784f456e-69b1-19ac-34d1-a647e7780db5",
        "NOMBRE": "OTRA HIPERLIPIDEMIA"
    },
    {
        "id": "2d58a495-3fda-9c0e-c095-efdaafd8a499",
        "NOMBRE": "OTRA HIPOCALCEMIA NEONATAL"
    },
    {
        "id": "547f71a5-79ed-69ad-43a2-326cb088a5c6",
        "NOMBRE": "OTRA MALFORMACIÓN CONGÉNITA DE LA COLUMNA VERTEBRAL, NO ASOCIADA CON ESCOLIOSIS"
    },
    {
        "id": "49d46094-83f4-d381-d75d-4dcab2216553",
        "NOMBRE": "OTRA OTITIS MEDIA AGUDA, NO SUPURATIVA"
    },
    {
        "id": "33f30c4d-56fa-cf8d-9ba4-ae1924509da4",
        "NOMBRE": "OTRA PÉRDIDA NO CICATRICIAL DEL PELO"
    },
    {
        "id": "e763c775-0ec9-f854-8651-ff88b4810700",
        "NOMBRE": "OTRA QUIMIOTERAPIA"
    },
    {
        "id": "9ce85479-51db-dfa1-9deb-6c0e1a561ce1",
        "NOMBRE": "OTRA QUIMIOTERAPIA PROFILÁCTICA"
    },
    {
        "id": "550bc7c2-b5c6-483b-61b7-71b0722335d2",
        "NOMBRE": "OTRA REACCIÓN A LA PUNCIÓN ESPINAL Y LUMBAR"
    },
    {
        "id": "8ffef552-2b2c-d19b-7ee1-0da53446d35c",
        "NOMBRE": "OTRA REACCIÓN ADVERSA A ALIMENTOS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "262d978c-701a-5ad8-74b7-a834f1c5067e",
        "NOMBRE": "OTRA RINITIS ALÉRGICA ESTACIONAL"
    },
    {
        "id": "794d43ec-a09c-af15-b22c-8621dab817b9",
        "NOMBRE": "OTRA RUPTURA ESPONTÁNEA DEL (DE LOS) LIGAMENTO(S) DE LA RODILLA"
    },
    {
        "id": "d7dbe759-84a2-5e95-51fc-d247da8c02c2",
        "NOMBRE": "OTRA TROMBOFILIA"
    },
    {
        "id": "0e3e5db9-ae9e-e929-bad0-8fd1d9fea392",
        "NOMBRE": "OTRAS (TENO)SINOVITIS INFECCIOSAS"
    },
    {
        "id": "29bdee93-c154-f0cb-4f8d-f3e25447747b",
        "NOMBRE": "OTRAS ABERTURAS ARTIFICIALES"
    },
    {
        "id": "c34bd46e-d12b-e2ae-92a1-5684aa124b9a",
        "NOMBRE": "OTRAS ACARIASIS"
    },
    {
        "id": "5f76cef8-d803-413b-46f5-0934a735729b",
        "NOMBRE": "OTRAS ADHERENCIAS Y DESGARROS DEL IRIS Y DEL CUERPO CILIAR"
    },
    {
        "id": "75855443-2b00-e1d5-c0f6-0297139eeb80",
        "NOMBRE": "OTRAS AFECCIONES DE LA PIEL ESPECIFICAS DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "66632215-a930-fdc0-9a21-43ad58f02333",
        "NOMBRE": "OTRAS AFECCIONES DE LA PLEURA"
    },
    {
        "id": "aa649c60-84b4-e6a5-78cd-72873fc4f6d9",
        "NOMBRE": "OTRAS AFECCIONES ERITEMATOSAS"
    },
    {
        "id": "a7a6d2ad-2f6f-60e4-c63d-c97688ed39fc",
        "NOMBRE": "OTRAS AFECCIONES ERITEMATOSAS ESPECIFICADAS"
    },
    {
        "id": "735e71b8-d957-b025-75ab-955606d18f8e",
        "NOMBRE": "OTRAS AFECCIONES ESPECIFICADAS ASOCIADAS CON LOS ÓRGANOS GENITALES FEMENINOS Y EL CICLO MENSTRUAL"
    },
    {
        "id": "ef5dbeb8-6c2c-b298-0edc-98a5c206ac9c",
        "NOMBRE": "OTRAS AFECCIONES ESPECIFICADAS DE LA PIEL, PROPIAS DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "914225ec-00e2-2c48-5319-15d49673bad7",
        "NOMBRE": "OTRAS AFECCIONES ESPECIFICADAS DE LA PLEURA"
    },
    {
        "id": "1df71192-ed51-172e-066a-ae9fa08fe276",
        "NOMBRE": "OTRAS AFECCIONES ESPECIFICADAS DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTÉN"
    },
    {
        "id": "d730e1ec-11a6-3cb7-f7f2-9d82d4820029",
        "NOMBRE": "OTRAS AFECCIONES ESPECIFICADAS ORIGINADAS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "7a702e2a-d3b9-69e5-2bfe-bff0a490d9a3",
        "NOMBRE": "OTRAS AFECCIONES HEMORRÁGICAS ESPECIFICADAS"
    },
    {
        "id": "2387b8f3-1cac-74f5-b0c2-555339b55853",
        "NOMBRE": "OTRAS AFECCIONES INFLAMATORIAS DE LA VAGINA Y DE LA VULVA"
    },
    {
        "id": "f3dc12a7-3bab-6293-250f-66624f199366",
        "NOMBRE": "OTRAS AFECCIONES ORIGINADAS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "12f23d40-bc50-a106-2acf-a847569f05b9",
        "NOMBRE": "OTRAS AFECCIONES RELACIONADAS CON EL ENFISEMA INTERSTICIAL, ORIGINADAS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "fbfc4c29-642a-f423-dc08-9f6967c2f0ae",
        "NOMBRE": "OTRAS AFECCIONES RELACIONADAS CON LA POLIARTERITIS NUDOSA"
    },
    {
        "id": "fd57a741-dec4-7475-d289-56ffc90963be",
        "NOMBRE": "OTRAS AFECCIONES RESPIRATORIAS AGUDAS Y SUBAGUDAS DEBIDAS A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"
    },
    {
        "id": "89d0f505-1dc4-da7b-e28b-f31bfc8f34cc",
        "NOMBRE": "OTRAS AFECCIONES RESPIRATORIAS DEBIDAS A INHALACIÓN DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"
    },
    {
        "id": "39ee87ce-9147-4fa5-50c7-feed78d41adb",
        "NOMBRE": "OTRAS ALOPECIAS ANDRÓGENAS"
    },
    {
        "id": "1130f912-f99e-3dc2-57a8-a00c21870043",
        "NOMBRE": "OTRAS ALOPECIAS AREATAS"
    },
    {
        "id": "21146a79-2424-3ceb-f2f3-d76c2f8d7d36",
        "NOMBRE": "OTRAS ALOPECIAS CICATRICIALES"
    },
    {
        "id": "d753a2f2-9b66-a9b9-d61e-be4321ecc4c0",
        "NOMBRE": "OTRAS ALTERACIONES CEREBRALES DEL RECIÉN NACIDO"
    },
    {
        "id": "00ed14f2-2433-ddac-4721-8ee82e625823",
        "NOMBRE": "OTRAS ALTERACIONES CEREBRALES ESPECIFICADAS DEL RECIÉN NACIDO"
    },
    {
        "id": "4dec13ef-a171-ccae-f585-256d9bf7f285",
        "NOMBRE": "OTRAS ALTERACIONES DE LA REGULACIÓN DE LA TEMPERATURA EN EL RECIÉN NACIDO"
    },
    {
        "id": "d3661ce3-a910-a027-f80b-fbb04bde95c1",
        "NOMBRE": "OTRAS ALTERACIONES DE LA SENSIBILIDAD CUTÁNEA Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "f4916cd5-d913-9949-38b4-127651bc9707",
        "NOMBRE": "OTRAS ALTERACIONES DE LA VOZ Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "78001ed3-d680-d5c1-660b-3c4881e74566",
        "NOMBRE": "OTRAS ALTERACIONES DEL GUSTO Y DEL OLFATO Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "99daa32c-8c02-ca27-8e96-a55c8240eddd",
        "NOMBRE": "OTRAS ALTERACIONES DEL HABLA Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "e8046b4f-e481-7c9a-0137-9766410745b7",
        "NOMBRE": "OTRAS ALTERACIONES ELECTROLITICAS TRANSITORIAS DEL RECIÉN NACIDO"
    },
    {
        "id": "c1e36211-c046-672c-51aa-d962cdfb1934",
        "NOMBRE": "OTRAS ALTERACIONES ESPECIFICADAS DE LA REGULACIÓN DE LA TEMPERATURA DEL RECIÉN NACIDO"
    },
    {
        "id": "ef14f9aa-ab3c-68a7-a714-4f5fb7394486",
        "NOMBRE": "OTRAS ALTERACIONES FUNCIONALES CONSECUTIVAS A CIRUGÍA CARDÍACA"
    },
    {
        "id": "b01e38c7-ef91-b0f3-b3e5-b1109b859485",
        "NOMBRE": "OTRAS ALTERACIONES METABÓLICAS TRANSITORIAS DEL RECIÉN NACIDO"
    },
    {
        "id": "814340f7-b4de-0017-5f8d-127baef684f9",
        "NOMBRE": "OTRAS ALTERACIONES METABÓLICAS Y ELECTROLÍTICAS NEONATALES TRANSITORIAS"
    },
    {
        "id": "5914ff97-61f6-d4a0-3ef7-8f985477c9af",
        "NOMBRE": "OTRAS ALTERACIONES VISUALES"
    },
    {
        "id": "3fb5db20-5e85-a656-f26b-3e82df8beeb4",
        "NOMBRE": "OTRAS ALUCINACIONES"
    },
    {
        "id": "c7b54fbc-7ead-4bd7-cac1-0e49f2d5f2c3",
        "NOMBRE": "OTRAS AMILOIDOSIS"
    },
    {
        "id": "e93d48a3-4fd9-85ef-2ab8-ae285eeb04a9",
        "NOMBRE": "OTRAS ANEMIAS"
    },
    {
        "id": "aa4c622d-ea89-bd04-c044-106647463d73",
        "NOMBRE": "OTRAS ANEMIAS APLÁSTICAS"
    },
    {
        "id": "b110cbc5-84ed-3449-c6db-46b18459edc7",
        "NOMBRE": "OTRAS ANEMIAS APLÁSTICAS ESPECIFICADAS"
    },
    {
        "id": "52841ce2-3003-4e25-26a2-6ebbcb1a638d",
        "NOMBRE": "OTRAS ANEMIAS CONGÉNITAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "986a83bd-e3c4-439f-99cf-280c279dd833",
        "NOMBRE": "OTRAS ANEMIAS DEBIDAS A TRASTORNOS ENZIMÁTICOS"
    },
    {
        "id": "5d1664ab-8392-52c4-4b10-fee73f2b5a0f",
        "NOMBRE": "OTRAS ANEMIAS ESPECIFICADAS"
    },
    {
        "id": "bf80744d-da10-9625-244a-a27dac7fd917",
        "NOMBRE": "OTRAS ANEMIAS HEMOLÍTICAS ADQUIRIDAS"
    },
    {
        "id": "03ce0532-4c57-cfb6-8606-1a992236a485",
        "NOMBRE": "OTRAS ANEMIAS HEMOLÍTICAS AUTOINMUNES"
    },
    {
        "id": "7835b3db-58e6-46a0-139c-df02b17fafc0",
        "NOMBRE": "OTRAS ANEMIAS HEMOLÍTICAS HEREDITARIAS"
    },
    {
        "id": "7bcda20a-4811-3e4b-d0a2-7cd75f6464c0",
        "NOMBRE": "OTRAS ANEMIAS HEMOLÍTICAS HEREDITARIAS ESPECIFICADAS"
    },
    {
        "id": "ce7d19ba-485f-0ce7-b1cd-22fa7d1cc5b3",
        "NOMBRE": "OTRAS ANEMIAS HEMOLÍTICAS NO AUTOINMUNES"
    },
    {
        "id": "27847e3e-0532-628d-9add-13efb6d280ff",
        "NOMBRE": "OTRAS ANEMIAS MEGALOBLÁSTICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0326eaf6-b4a8-bcd9-eb4e-50b2bc2b52c1",
        "NOMBRE": "OTRAS ANEMIAS NUTRICIONALES"
    },
    {
        "id": "5e70e696-ad92-6dfb-d53a-e744f726d668",
        "NOMBRE": "OTRAS ANEMIAS NUTRICIONALES ESPECIFICADAS"
    },
    {
        "id": "a8d284ed-fe5b-102d-7f49-3a4db26463bc",
        "NOMBRE": "OTRAS ANEMIAS POR DEFICIENCIA DE FOLATOS"
    },
    {
        "id": "a781f0be-b1d5-8337-5c94-2a9d0fea05b9",
        "NOMBRE": "OTRAS ANEMIAS POR DEFICIENCIA DE HIERRO"
    },
    {
        "id": "4d6465f5-d0a3-dd76-fb3a-a76716b771f2",
        "NOMBRE": "OTRAS ANEMIAS POR DEFICIENCIA DE VITAMINA B12"
    },
    {
        "id": "cee1c659-b20f-bd8e-e499-c45ba51f8121",
        "NOMBRE": "OTRAS ANEMIAS POR DEFICIENCIA DIETÉTICA DE VITAMINA B12"
    },
    {
        "id": "ac04d535-afc7-027f-b871-b0d82b69e48d",
        "NOMBRE": "OTRAS ANEMIAS SIDEROBLÁSTICAS"
    },
    {
        "id": "556759c8-4958-6c97-9b83-b213c307c4bb",
        "NOMBRE": "OTRAS ANOFTALMÍAS"
    },
    {
        "id": "ddaca3c6-86c8-0a86-3af3-3a81a8183f1e",
        "NOMBRE": "OTRAS ANOMALÍAS CONGÉNITAS DE LA COLA DE CABALLO"
    },
    {
        "id": "c02d6ac3-682e-23a0-4579-2c0a53d41257",
        "NOMBRE": "OTRAS ANOMALÍAS CONGÉNITAS DEL (DE LOS) MIEMBRO(S)"
    },
    {
        "id": "af2ee7ef-3ea2-9466-bf3f-4ee5ea2b97ca",
        "NOMBRE": "OTRAS ANOMALÍAS CROMOSÓMICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "557d1818-67c8-7a8b-3bfd-53bd55f59c15",
        "NOMBRE": "OTRAS ANOMALÍAS DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, ESPECIFICADAS"
    },
    {
        "id": "55b44fd9-6032-93f7-42b8-b535d03d1598",
        "NOMBRE": "OTRAS ANOMALÍAS DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "eaed0ac0-8e63-29b5-eb88-683aecea268b",
        "NOMBRE": "OTRAS ANOMALÍAS DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO MASCULINO, ESPECIFICADAS"
    },
    {
        "id": "03b1380c-ecfc-6738-5834-7e75037d7c21",
        "NOMBRE": "OTRAS ANOMALÍAS DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO MASCULINO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4b839635-429c-a314-81b4-5b882ee39216",
        "NOMBRE": "OTRAS ANOMALÍAS DE LOS CROMOSOMAS, ESPECIFICADAS"
    },
    {
        "id": "362b5f09-1a00-4c65-5a85-bcb308dc754b",
        "NOMBRE": "OTRAS ANOMALÍAS DENTOFACIALES"
    },
    {
        "id": "7e4fccdd-9c2d-9fd6-d1cb-d2349725904b",
        "NOMBRE": "OTRAS ANOMALÍAS DINÁMICAS DEL TRABAJO DE PARTO"
    },
    {
        "id": "4dbca6e1-4bf7-2cf1-8160-9e502eb7d365",
        "NOMBRE": "OTRAS ANOMALÍAS HIPOPLÁSICAS DEL ENCÉFALO"
    },
    {
        "id": "48017896-fa33-224d-c4d3-595937946508",
        "NOMBRE": "OTRAS ANORMALIDADES ADQUIRIDAS DE LOS HUESECILLOS DEL OÍDO"
    },
    {
        "id": "8a50f459-ee50-bb17-17a5-dabeb6b73eb4",
        "NOMBRE": "OTRAS ANORMALIDADES DE LA MARCHA Y DE LA MOVILIDAD Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "9a92fe63-2061-ca13-1eab-7f240c5de798",
        "NOMBRE": "OTRAS ANORMALIDADES DE LA RESPIRACIÓN Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "7e1384e7-7fdf-1ea6-3096-eb2a32672894",
        "NOMBRE": "OTRAS ANORMALIDADES DE LAS PROTEÍNAS PLASMÁTICAS"
    },
    {
        "id": "7a5b5eb7-8ed2-b139-63b1-07dc4741e8b2",
        "NOMBRE": "OTRAS ANORMALIDADES DEL LATIDO CARDÍACO Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "4c3ebff9-50b4-e5fa-0e04-6367f5b5fa58",
        "NOMBRE": "OTRAS ANORMALIDADES DEL TALLO Y DEL COLOR DEL PELO"
    },
    {
        "id": "249b8791-a975-e221-25e5-e5ac411dcd0c",
        "NOMBRE": "OTRAS ANORMALIDADES ESPECIFICADAS DE LAS PROTEÍNAS PLASMÁTICAS"
    },
    {
        "id": "b1f5cb32-9dc5-944f-f7a9-efbbe12c81cc",
        "NOMBRE": "OTRAS ANORMALIDADES FECALES"
    },
    {
        "id": "90983378-8c7c-f34d-c31d-724bac121fa6",
        "NOMBRE": "OTRAS APENDICITIS AGUDAS, Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "ccf88efa-0467-3815-8ba5-44cd546ab762",
        "NOMBRE": "OTRAS APLASIAS ADQUIRIDAS, EXCLUSIVAS DE LA SERIE ROJA"
    },
    {
        "id": "d1fbce0e-8183-3da9-ae54-dc81b8b7c4db",
        "NOMBRE": "OTRAS APNEAS DEL RECIÉN NACIDO"
    },
    {
        "id": "d55a8c12-8a71-4e7c-364b-322692924e68",
        "NOMBRE": "OTRAS ARRITMIAS CARDIACAS"
    },
    {
        "id": "e5c15a13-47c6-8805-06b1-004bf27d0f0f",
        "NOMBRE": "OTRAS ARRITMIAS CARDIACAS ESPECIFICADAS"
    },
    {
        "id": "2a0b6dfc-42af-dd6f-c0dc-83048e78c28e",
        "NOMBRE": "OTRAS ARTERITIS DE CÉLULAS GIGANTES"
    },
    {
        "id": "7b3bf843-0e46-f726-6779-34849a750e0f",
        "NOMBRE": "OTRAS ARTRITIS"
    },
    {
        "id": "872b8636-bb94-306a-1d8a-8760436a6ef8",
        "NOMBRE": "OTRAS ARTRITIS ESPECIFICADAS"
    },
    {
        "id": "bfa54e93-52b8-1302-5a4c-8d9bcfff1099",
        "NOMBRE": "OTRAS ARTRITIS JUVENILES"
    },
    {
        "id": "ed4444d0-1e47-ee6d-9e6a-65d9b7efaffe",
        "NOMBRE": "OTRAS ARTRITIS REUMATOIDEAS SEROPOSITIVAS"
    },
    {
        "id": "7829a825-4ce4-58ed-6e76-af7cc9e06a1f",
        "NOMBRE": "OTRAS ARTRITIS REUMATOIDES"
    },
    {
        "id": "4a9c938c-deb4-fb75-d2f1-d72d8586c804",
        "NOMBRE": "OTRAS ARTRITIS REUMATOIDES ESPECIFICADAS"
    },
    {
        "id": "fa19aaec-65c6-ff4c-66ca-067ac82f9d42",
        "NOMBRE": "OTRAS ARTRITIS Y POLIARTRITIS ESTREPTOCÓCICAS"
    },
    {
        "id": "6ed230e5-977f-b01d-3298-17e23f3536d0",
        "NOMBRE": "OTRAS ARTROPATÍAS ENTEROPÁTICAS"
    },
    {
        "id": "abcfa6af-9ac0-8e34-cd10-bbb6d076e422",
        "NOMBRE": "OTRAS ARTROPATÍAS ESPECÍFICAS"
    },
    {
        "id": "03b08d1f-97a2-683e-c7bb-3cd5c4197d43",
        "NOMBRE": "OTRAS ARTROPATÍAS ESPECÍFICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d9bc945f-5c21-6459-7c26-ebee172dad9a",
        "NOMBRE": "OTRAS ARTROPATÍAS POR CRISTALES"
    },
    {
        "id": "7f1bdc4e-b8ab-6e41-918b-1e73da616c5b",
        "NOMBRE": "OTRAS ARTROPATÍAS POR CRISTALES, ESPECIFICADAS"
    },
    {
        "id": "cf9aeac9-75c8-8734-3fc3-bf68552bd746",
        "NOMBRE": "OTRAS ARTROPATÍAS POSTINFECCIOSAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "51f0c906-13c4-00f2-40aa-1181f24d3a3c",
        "NOMBRE": "OTRAS ARTROPATÍAS PSORIÁSICAS"
    },
    {
        "id": "8043a2f2-7b36-8f75-a383-9213c1e849d5",
        "NOMBRE": "OTRAS ARTROPATÍAS REACTIVAS"
    },
    {
        "id": "a73fad36-22f9-8fe1-76d7-19b612ff52e4",
        "NOMBRE": "OTRAS ARTROSIS"
    },
    {
        "id": "ff565307-a1a1-e80c-5298-9d7c38ae76d9",
        "NOMBRE": "OTRAS ARTROSIS ESPECIFICADAS"
    },
    {
        "id": "c1eb8308-2f46-3caf-77a7-3ba2ccdea58b",
        "NOMBRE": "OTRAS ARTROSIS POSTRAUMÁTICAS DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA"
    },
    {
        "id": "527f42a1-ba8a-bff4-9a27-24227a9f8908",
        "NOMBRE": "OTRAS ARTROSIS PRIMARIAS DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA"
    },
    {
        "id": "8663c1d8-b491-6b7d-d56a-5754e7c184ed",
        "NOMBRE": "OTRAS ARTROSIS SECUNDARIAS"
    },
    {
        "id": "a39b6ee3-0434-4eac-2e14-0f85149d80e8",
        "NOMBRE": "OTRAS ARTROSIS SECUNDARIAS DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA"
    },
    {
        "id": "2a3f17f9-2da5-d49f-e177-399eae4238c1",
        "NOMBRE": "OTRAS ARTROSIS SECUNDARIAS DE LA PRIMERA ARTICULACIÓN CARPOMETACARPIANA, BILATERALES"
    },
    {
        "id": "471f9574-b133-8ec0-6583-79049d6ab465",
        "NOMBRE": "OTRAS ASPERGILOSIS PULMONARES"
    },
    {
        "id": "a4fe739f-3838-7dd6-6be6-98ee801b6404",
        "NOMBRE": "OTRAS ATAXIAS HEREDITARIAS"
    },
    {
        "id": "ead47f5a-a564-3390-ec19-f342e95b5ada",
        "NOMBRE": "OTRAS ATELECTASIAS DEL RECIÉN NACIDO Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "8fb2b474-7171-3eef-950d-67afe715d5aa",
        "NOMBRE": "OTRAS ATENCIONES ESPECIFICADAS PARA LA ANTICONCEPCIÓN"
    },
    {
        "id": "b3906df1-007b-ae88-fcc6-e3fa18d00b93",
        "NOMBRE": "OTRAS ATENCIONES MÉDICAS ESPECIFICADAS"
    },
    {
        "id": "d5d5941e-6956-d394-bfb8-3bcdf4412fe2",
        "NOMBRE": "OTRAS ATRESIAS Y ESTENOSIS DE LA URETRA Y DEL CUELLO DE LA VEJIGA"
    },
    {
        "id": "201e4480-c421-cc91-b626-76a6e67dae67",
        "NOMBRE": "OTRAS ATROFIAS MUSCULARES ESPINALES HEREDITARIAS"
    },
    {
        "id": "2fd70713-8223-2cd7-87ef-0dc6493da661",
        "NOMBRE": "OTRAS ATROFIAS MUSCULARES ESPINALES Y SÍNDROMES AFINES"
    },
    {
        "id": "4cc2cd18-62d7-493b-448a-fc4ef63a93fa",
        "NOMBRE": "OTRAS ATROFIAS SISTÉMICAS QUE AFECTAN EL SISTEMA NERVIOSO CENTRAL EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "0ab3aa8a-0191-086b-be49-e3284d4d1e86",
        "NOMBRE": "OTRAS BRUCELOSIS"
    },
    {
        "id": "f8220229-c55b-fdfd-fc70-6632c6a0d819",
        "NOMBRE": "OTRAS BURSITIS DE LA CADERA"
    },
    {
        "id": "8a3a77c4-09fd-ff33-2d1f-1ac904c9964c",
        "NOMBRE": "OTRAS BURSITIS DE LA RODILLA"
    },
    {
        "id": "21175998-3fe0-2e72-a4a3-781d9d2c34be",
        "NOMBRE": "OTRAS BURSITIS DEL CODO"
    },
    {
        "id": "9b7305c7-ab25-8f99-798c-6513964aaf89",
        "NOMBRE": "OTRAS BURSITIS INFECCIOSAS"
    },
    {
        "id": "240fe7b8-aade-c78a-01f9-3706260bea97",
        "NOMBRE": "OTRAS BURSITIS PRERROTULIANAS"
    },
    {
        "id": "83d3c77e-a9da-ac38-3221-10e21b3b5009",
        "NOMBRE": "OTRAS BURSITIS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d83c43c8-dc70-ab7d-8420-450581365b41",
        "NOMBRE": "OTRAS BURSOPATÍAS"
    },
    {
        "id": "5785a98e-77ef-8b92-160b-cee60d8ae2a7",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO"
    },
    {
        "id": "b5aec694-66d3-c523-cd59-1e3d59a6ff3a",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "387f60b3-5457-3771-d065-56a658600d86",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "d7e177b9-729a-18f4-90eb-e25f1f019786",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, CALLES Y CARRETERAS"
    },
    {
        "id": "3a51abf2-195b-2307-5d4c-cb4b435596c0",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "35ca1f7e-9000-7d94-ea7e-8c2e0bd9aaab",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "bc381e7c-1607-627b-7094-ad57f64b42b0",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, GRANJA"
    },
    {
        "id": "db8a50bf-0dc5-3c18-81e9-ef3e41185169",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "b936b33f-140a-a992-cc4a-c8f2f47bcef9",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9c33361d-d825-a629-7358-38f982dbfa12",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "66d7a073-ef4d-d443-82f2-c07ea23b1b08",
        "NOMBRE": "OTRAS CAÍDAS DE UN NIVEL A OTRO, VIVIENDA"
    },
    {
        "id": "fea86c38-d465-1863-6362-5f96208c71fe",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL"
    },
    {
        "id": "696b21df-0465-ed3e-27b2-585326830c81",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA"
    },
    {
        "id": "e7c7be4f-c9a1-b061-a6be-7b3eb427b513",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "14b4606b-7cfc-0e5f-8a37-06f1b27329a5",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "951bf96f-7368-9971-9cb0-3bac86b0a397",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, CALLES Y CARRETERAS"
    },
    {
        "id": "a5e63308-374e-8fa5-9043-fc72d3168ef5",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e6d22253-b478-7061-cea5-a1025613931b",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "20c03c5a-9ffa-e866-0914-839215b46305",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, GRANJA"
    },
    {
        "id": "bc443f7b-fb5a-4a7c-869d-df41d8351fe6",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "3ea722cd-72c0-eb7a-fef3-cefcc485a8c4",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "3007580a-0754-17c0-0aa0-15b8daa4e63c",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "22f4ddaf-8ef5-1fac-b05c-38973eac3988",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL POR COLISIÓN CON O POR EMPUJÓN DE OTRA PERSONA, VIVIENDA"
    },
    {
        "id": "2cd8d161-b52a-05c3-aa62-565d182e6634",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "c6d3e059-f97d-c914-2d7d-2e15658a0f81",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "d7097bda-be8a-bb25-1c32-9703135528f7",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, CALLES Y CARRETERAS"
    },
    {
        "id": "6c368741-227a-2035-f34f-64163c10ed1c",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "13bc444a-b5eb-dee9-7af1-5ddfa7073128",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "471c2953-7815-6901-f383-a0376f4f5470",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, GRANJA"
    },
    {
        "id": "51794652-798c-0502-8b06-9ec5fc3579f2",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "b0ea8cdb-ff93-6b54-07d1-05c4c4c10c1c",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "1969023b-3060-9b9c-ecd5-23461d5c6032",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "08f0732b-a605-7196-dc29-f42b796bc78c",
        "NOMBRE": "OTRAS CAÍDAS EN EL MISMO NIVEL, VIVIENDA"
    },
    {
        "id": "a7f8efc5-de21-db04-ba51-9311ed84ce00",
        "NOMBRE": "OTRAS CALCIFICACIONES DEL MÚSCULO"
    },
    {
        "id": "e5567327-fc5e-8496-6c6b-710a3155291a",
        "NOMBRE": "OTRAS CARDIOMIOPATÍAS"
    },
    {
        "id": "c40c30bd-b913-e4bb-49d9-4849f48ffd09",
        "NOMBRE": "OTRAS CARDIOMIOPATÍAS HIPERTRÓFICAS"
    },
    {
        "id": "dbd9b1b0-6c46-8a91-2dce-f338ad68dffa",
        "NOMBRE": "OTRAS CARDIOMIOPATÍAS RESTRICTIVAS"
    },
    {
        "id": "885d1a61-2358-60a4-ad13-38bb23a51df5",
        "NOMBRE": "OTRAS CARIES DENTALES"
    },
    {
        "id": "3c87bbab-9729-844a-4e18-39185fa59648",
        "NOMBRE": "OTRAS CATARATAS"
    },
    {
        "id": "e830bbb7-8b43-1fbb-ea8a-42257bbd0f3d",
        "NOMBRE": "OTRAS CATARATAS SENILES"
    },
    {
        "id": "d1a3115d-0f10-c898-3a0c-ff2683ed0138",
        "NOMBRE": "OTRAS CAUSAS MAL DEFINIDAS Y LAS NO ESPECIFICADAS DE MORTALIDAD"
    },
    {
        "id": "8a9f4f58-0988-4f82-18b3-faf60a185929",
        "NOMBRE": "OTRAS CIFOSIS SECUNDARIAS"
    },
    {
        "id": "cb434644-bb2b-cc37-8e49-384dd8fc240b",
        "NOMBRE": "OTRAS CIFOSIS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "60e2a573-b012-90c4-2e15-325e227b165a",
        "NOMBRE": "OTRAS CIGOMICOSIS"
    },
    {
        "id": "661865a8-c58b-fddd-9f07-d4b28a2e20d6",
        "NOMBRE": "OTRAS CIRROSIS DEL HÍGADO Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "e3d94824-51bd-a6a3-5d66-a5daca41d351",
        "NOMBRE": "OTRAS CIRUGÍAS PLÁSTICAS POR RAZONES ESTÉTICAS"
    },
    {
        "id": "0213be42-034d-0159-f97d-d92b06506e4a",
        "NOMBRE": "OTRAS CISTITIS"
    },
    {
        "id": "f464c4bf-2465-740a-e1ef-f7bcfed38456",
        "NOMBRE": "OTRAS CISTITIS CRÓNICAS"
    },
    {
        "id": "cacb540c-3147-d521-fa3a-4d91d9848158",
        "NOMBRE": "OTRAS COLECISTITIS"
    },
    {
        "id": "b15a6fae-0a01-6366-e1ee-d406305a413b",
        "NOMBRE": "OTRAS COLELITIASIS"
    },
    {
        "id": "aad4a05b-dc40-0754-f9a4-25c92692a75c",
        "NOMBRE": "OTRAS COLITIS ULCERATIVAS"
    },
    {
        "id": "68cdbca8-fc76-1443-9e81-1472547af9b5",
        "NOMBRE": "OTRAS COLITIS Y GASTROENTERITIS NO INFECCIOSAS"
    },
    {
        "id": "a675c128-1dac-1eff-5a95-062d1b02c087",
        "NOMBRE": "OTRAS COLITIS Y GASTROENTERITIS NO INFECCIOSAS ESPECIFICADAS"
    },
    {
        "id": "009603ae-a187-8a46-8aac-910b554af8a5",
        "NOMBRE": "OTRAS COMPLICACIONES ASOCIADAS CON LA FECUNDACIÓN ARTIFICIAL"
    },
    {
        "id": "f7a12855-7802-4e31-428a-3f46fd0d894a",
        "NOMBRE": "OTRAS COMPLICACIONES CONSECUTIVAS A INFUSIÓN, TRANSFUSIÓN E INYECCIÓN TERAPÉUTICA"
    },
    {
        "id": "ef9b8f73-59c2-ded5-1316-8a4d4261756c",
        "NOMBRE": "OTRAS COMPLICACIONES CONSECUTIVAS A INMUNIZACIÓN, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a1b079a7-a918-32e0-8d3d-0dad2c018dcf",
        "NOMBRE": "OTRAS COMPLICACIONES CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "587fa3e0-c34d-a31e-6fc3-dd7d4487c01b",
        "NOMBRE": "OTRAS COMPLICACIONES DE DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS GENITOURINARIOS"
    },
    {
        "id": "1ea3d082-76d4-96a1-484b-e36071209212",
        "NOMBRE": "OTRAS COMPLICACIONES DE DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS INTERNOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1b631970-2550-51aa-5787-615535dddc09",
        "NOMBRE": "OTRAS COMPLICACIONES DE DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS ORTOPÉDICOS INTERNOS"
    },
    {
        "id": "49d5b671-f9f8-136d-b268-d89fe79a143e",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ANESTESIA"
    },
    {
        "id": "8185e645-6e04-3f4f-1c31-a95288b56b4f",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"
    },
    {
        "id": "ba0cf71f-f78c-5fba-be7d-44d0693931d1",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"
    },
    {
        "id": "630217dc-1dac-e6fa-024b-84770e852372",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "d892240a-ba0b-e256-4162-76ce622ebcbf",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL EMBARAZO"
    },
    {
        "id": "29f491a5-2b4c-e8ca-3b17-10d06cb7bcd8",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL PUERPERIO"
    },
    {
        "id": "f3f39902-caec-e955-0de5-e1259bde537e",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "dc1e1eae-2472-9592-0987-e225879ffeb3",
        "NOMBRE": "OTRAS COMPLICACIONES DE LA ATENCIÓN MÉDICA Y QUIRÚRGICA, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fbe35af5-f269-09a6-d6db-10338ce7b71b",
        "NOMBRE": "OTRAS COMPLICACIONES DE PROCEDIMIENTOS Y DE CIRUGÍA OBSTÉTRICA"
    },
    {
        "id": "d54e87f3-dafd-5ca5-8590-b67afa57bb4c",
        "NOMBRE": "OTRAS COMPLICACIONES DE PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8feea359-3644-aaa0-c570-73a6987eeda6",
        "NOMBRE": "OTRAS COMPLICACIONES DEL TRABAJO DE PARTO Y DEL PARTO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "06557b6f-990a-d619-44a0-5b1872e8c031",
        "NOMBRE": "OTRAS COMPLICACIONES ESPECIFICADAS DE DISPOSITIVOS PROTÉSICOS, IMPLANTES E INJERTOS CARDIOVASCULARES"
    },
    {
        "id": "fd508725-2af5-c069-497a-718388c237d8",
        "NOMBRE": "OTRAS COMPLICACIONES ESPECIFICADAS DE LA ATENCIÓN MÉDICA Y QUIRÚRGICA, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "16fcd09f-7ba3-5ff3-3df1-632757d2926d",
        "NOMBRE": "OTRAS COMPLICACIONES ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO"
    },
    {
        "id": "14590a38-a442-1809-d557-dfd9b68e5210",
        "NOMBRE": "OTRAS COMPLICACIONES ESPECIFICADAS RELACIONADAS CON EL EMBARAZO"
    },
    {
        "id": "026b06c4-9069-4456-4d47-3550577c98c0",
        "NOMBRE": "OTRAS COMPLICACIONES ESPECÍFICAS DEL EMBARAZO MÚLTIPLE"
    },
    {
        "id": "5f54a0df-76b6-5661-ad39-66a04f168771",
        "NOMBRE": "OTRAS COMPLICACIONES PRECOCES DE LOS TRAUMATISMOS"
    },
    {
        "id": "85ae30d7-2058-6bd0-5e32-954a62ace65f",
        "NOMBRE": "OTRAS COMPLICACIONES PRESENTES POSTERIORES AL INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "eaa461dd-8fdf-74e9-be1e-a77eeb77675b",
        "NOMBRE": "OTRAS COMPLICACIONES PUERPERALES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "663809f3-e309-e39d-3179-a5f635aab139",
        "NOMBRE": "OTRAS COMPLICACIONES PULMONARES DEBIDAS A LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "7682a3d9-8111-621e-c6f5-38ce312913cb",
        "NOMBRE": "OTRAS COMPLICACIONES VENOSAS CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "8aea2b02-bf90-5e0d-ab50-9df6781db862",
        "NOMBRE": "OTRAS COMPLICACIONES VENOSAS EN EL EMBARAZO"
    },
    {
        "id": "1454a432-33cd-0b0d-bbb7-7522f5d2a1ca",
        "NOMBRE": "OTRAS COMPLICACIONES VENOSAS EN EL PUERPERIO"
    },
    {
        "id": "34d0c08a-48ff-328d-63c9-03217b3ee831",
        "NOMBRE": "OTRAS COMPLICACIONES Y LAS NO ESPECIFICADAS DE MUÑÓN DE AMPUTACIÓN"
    },
    {
        "id": "cce27781-6a38-422c-db4c-a82f9f3ab1dc",
        "NOMBRE": "OTRAS CONDROCALCINOSIS"
    },
    {
        "id": "5165338d-ea24-c468-731e-834a046b003e",
        "NOMBRE": "OTRAS CONJUNTIVITIS"
    },
    {
        "id": "052f2e54-3314-b799-9711-1065fe213e44",
        "NOMBRE": "OTRAS CONJUNTIVITIS AGUDAS"
    },
    {
        "id": "7b240061-b47e-43bf-316a-2c0ccad367eb",
        "NOMBRE": "OTRAS CONJUNTIVITIS VIRALES"
    },
    {
        "id": "9d6a0beb-3764-8e7f-b00e-8c1f31523002",
        "NOMBRE": "OTRAS CONSULTAS ESPECIFICADAS"
    },
    {
        "id": "6822b816-fe31-6b17-7d64-39437e4ec628",
        "NOMBRE": "OTRAS CONSULTAS SEXUALES ESPECÍFICAS"
    },
    {
        "id": "219d8533-5cdb-8915-4a7e-0ad5694dc56f",
        "NOMBRE": "OTRAS CONTRACTURAS DE TENDÓN (VAINA)"
    },
    {
        "id": "147517f5-93b5-fdf1-3d8f-d41a300d9302",
        "NOMBRE": "OTRAS CONVULSIONES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "d4ad76cd-877f-fff3-fbfc-e77fd8cd535e",
        "NOMBRE": "OTRAS COREAS"
    },
    {
        "id": "f7fc8a2c-8528-56c2-4e8a-15f0fee43824",
        "NOMBRE": "OTRAS CORIORRETINITIS"
    },
    {
        "id": "d39fff25-4de0-c64f-bf14-0f4b5f4966ce",
        "NOMBRE": "OTRAS COXARTROSIS DISPLÁSICAS"
    },
    {
        "id": "da64b671-2909-9a21-b2b7-6a1ce3f76d0d",
        "NOMBRE": "OTRAS COXARTROSIS PRIMARIAS"
    },
    {
        "id": "24d5517d-5b36-8b41-8cb7-3ffa94f970de",
        "NOMBRE": "OTRAS COXARTROSIS SECUNDARIAS"
    },
    {
        "id": "2b9947e4-38e6-e835-cafc-865aae6367fd",
        "NOMBRE": "OTRAS DEFICIENCIAS DE VITAMINAS"
    },
    {
        "id": "169ec908-51a9-f77f-3474-1b09165b2dce",
        "NOMBRE": "OTRAS DEFICIENCIAS NUTRICIONALES"
    },
    {
        "id": "1b158341-37e9-2c19-6e21-c1414ff37fe5",
        "NOMBRE": "OTRAS DEFICIENCIAS NUTRICIONALES ESPECIFICADAS"
    },
    {
        "id": "3ba8834a-2fed-c37f-0678-b2ec5364597d",
        "NOMBRE": "OTRAS DEFORMIDADES (ADQUIRIDAS) DEL (DE LOS) DEDO(S) DEL PIE"
    },
    {
        "id": "8f18605f-aaab-07b3-c1a0-0e626d4e2148",
        "NOMBRE": "OTRAS DEFORMIDADES ADQUIRIDAS DE LA CABEZA"
    },
    {
        "id": "cccbd471-8f2a-49c9-0fa1-e8f2325e0509",
        "NOMBRE": "OTRAS DEFORMIDADES ADQUIRIDAS DE LOS MIEMBROS"
    },
    {
        "id": "b36d77a2-60f2-e7f3-5104-4a31df98be5a",
        "NOMBRE": "OTRAS DEFORMIDADES ADQUIRIDAS DE LOS MIEMBROS, ESPECIFICADAS"
    },
    {
        "id": "471ac551-fc0d-32eb-7842-8881eaba6996",
        "NOMBRE": "OTRAS DEFORMIDADES ADQUIRIDAS DEL SISTEMA OSTEOMUSCULAR Y DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "80f055cd-3151-d843-3026-adfe137c51ec",
        "NOMBRE": "OTRAS DEFORMIDADES ADQUIRIDAS DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "408ec422-68ed-5239-4d09-78531a18687e",
        "NOMBRE": "OTRAS DEFORMIDADES ADQUIRIDAS ESPECIFICADAS DEL SISTEMA OSTEOMUSCULAR"
    },
    {
        "id": "eb9b5b7f-9f01-31ff-a525-71a71c398203",
        "NOMBRE": "OTRAS DEFORMIDADES CONGÉNITAS DE LA CADERA"
    },
    {
        "id": "4d6f182a-2638-69c5-7529-f7e0735a3201",
        "NOMBRE": "OTRAS DEFORMIDADES CONGÉNITAS DE LOS PIES"
    },
    {
        "id": "0e1f8116-c491-5659-5fd8-3fe6511c7ff5",
        "NOMBRE": "OTRAS DEFORMIDADES CONGÉNITAS DEL CRÁNEO, DE LA CARA Y DE LA MANDÍBULA"
    },
    {
        "id": "f2b398b2-b513-27f9-af41-c46e2a930198",
        "NOMBRE": "OTRAS DEFORMIDADES CONGÉNITAS DEL TÓRAX"
    },
    {
        "id": "e2f6d021-1c4c-e979-fe0e-0e2509b38e8f",
        "NOMBRE": "OTRAS DEFORMIDADES CONGÉNITAS OSTEOMUSCULARES, ESPECIFICADAS"
    },
    {
        "id": "ac2a8eb4-99c0-085e-d14f-51b3af2a9edd",
        "NOMBRE": "OTRAS DEFORMIDADES DE LA CÓRNEA"
    },
    {
        "id": "fbaa239e-2cec-189e-a257-4bb16063f466",
        "NOMBRE": "OTRAS DEFORMIDADES DEL HALLUX (ADQUIRIDAS)"
    },
    {
        "id": "bf8cc1df-42b2-c478-24e1-50fc13a05c90",
        "NOMBRE": "OTRAS DEFORMIDADES DEL PABELLÓN AURICULAR"
    },
    {
        "id": "0c1f22f5-b0b5-27fd-2eb7-bbfb4aa958ce",
        "NOMBRE": "OTRAS DEFORMIDADES OSTEOMUSCULARES CONGÉNITAS"
    },
    {
        "id": "0b883112-c232-e1fc-c984-2ff878859ad8",
        "NOMBRE": "OTRAS DEFORMIDADES VALGUS CONGÉNITAS DE LOS PIES"
    },
    {
        "id": "cf334115-d8ec-02dc-e45d-a43fe187984c",
        "NOMBRE": "OTRAS DEFORMIDADES VARUS CONGÉNITAS DE LOS PIES"
    },
    {
        "id": "ecf163f4-0d79-b363-86bf-a6e3de6d5dcb",
        "NOMBRE": "OTRAS DEGENERACIONES DE DISCO CERVICAL"
    },
    {
        "id": "a49ebf4a-a852-ee09-c755-cfa9f18dedd5",
        "NOMBRE": "OTRAS DEGENERACIONES ESPECIFICADAS DE DISCO INTERVERTEBRAL"
    },
    {
        "id": "e6482572-4c5b-3c25-ae10-29deaeedddc4",
        "NOMBRE": "OTRAS DEMENCIA VASCULARES"
    },
    {
        "id": "3cfaf82a-6799-31c6-7a72-4203a4c0abfe",
        "NOMBRE": "OTRAS DERMATITIS"
    },
    {
        "id": "b5e1b861-0a86-c2b9-6a12-eed7416ee930",
        "NOMBRE": "OTRAS DERMATITIS ATÓPICAS"
    },
    {
        "id": "075a5f7d-cb36-70c5-05f5-74a002fee105",
        "NOMBRE": "OTRAS DERMATITIS ESPECIFICADAS"
    },
    {
        "id": "22d91a9c-f25c-fdc7-e4f1-aa31200c0fd4",
        "NOMBRE": "OTRAS DERMATITIS SEBORREICAS"
    },
    {
        "id": "a3080198-8865-d401-8033-682c7115bfba",
        "NOMBRE": "OTRAS DERMATOFITOSIS"
    },
    {
        "id": "f06dc8ac-84a5-088b-6b36-c7244021b9f8",
        "NOMBRE": "OTRAS DERMATOMIOSITIS"
    },
    {
        "id": "508e8a13-7a2b-554c-3ccb-4af9da84e472",
        "NOMBRE": "OTRAS DESMIELINIZACIONES AGUDAS DISEMINADAS ESPECIFICADAS"
    },
    {
        "id": "ab305960-a803-2907-099e-d62f05f34c1a",
        "NOMBRE": "OTRAS DESMIELINIZACIONES DISEMINADAS AGUDAS"
    },
    {
        "id": "5b7fcd08-75de-5c54-0ed3-427a9057a1dc",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS"
    },
    {
        "id": "199addf6-e56a-c7dd-58c3-482ae262aa4b",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON CETOACIDOSIS"
    },
    {
        "id": "48e9d090-55c3-c877-ba71-68dd555dfc39",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMA"
    },
    {
        "id": "a1cfd1e5-adf8-8f58-1818-6df5add656a6",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMPLICACIONES CIRCULATORIAS PERIFÉRICAS"
    },
    {
        "id": "f86e8033-a181-5a1e-8aa1-fab5fe45436b",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMPLICACIONES MÚLTIPLES"
    },
    {
        "id": "6d18ac62-7a3e-0ec4-8140-b12cf64219b1",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMPLICACIONES NEUROLÓGICAS"
    },
    {
        "id": "147a4190-799f-0761-95a7-540102fa3d18",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMPLICACIONES NO ESPECIFICADAS"
    },
    {
        "id": "647f24e5-1080-c550-7018-1ed10b0f3692",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMPLICACIONES OFTÁLMICAS"
    },
    {
        "id": "1362c12c-c0d3-d88a-8042-932beb343299",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON COMPLICACIONES RENALES"
    },
    {
        "id": "1b2a46bf-54dc-c560-ce26-f21230e30bf0",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, CON OTRAS COMPLICACIONES ESPECIFICADAS"
    },
    {
        "id": "de0e745b-a36e-a8b7-d02e-2260e0517edd",
        "NOMBRE": "OTRAS DIABETES MELLITUS ESPECIFICADAS, SIN MENCIÓN DE COMPLICACIÓN"
    },
    {
        "id": "3d4561a9-de6f-d079-acbc-07678294c49c",
        "NOMBRE": "OTRAS DIÁLISIS"
    },
    {
        "id": "05181579-c683-2c60-c632-8a493cbb7dc6",
        "NOMBRE": "OTRAS DIFICULTADES DE LA MICCIÓN"
    },
    {
        "id": "c4c3fd0a-63df-e26e-ce3b-1d1a23d97200",
        "NOMBRE": "OTRAS DIFICULTADES RESPIRATORIAS DEL RECIÉN NACIDO"
    },
    {
        "id": "2b95ea1b-b1bc-5a15-a657-6449b24b1674",
        "NOMBRE": "OTRAS DIFTERIAS"
    },
    {
        "id": "54bd207c-ff05-15e9-b090-da406f47db13",
        "NOMBRE": "OTRAS DISFUNCIONES NEUROMUSCULARES DE LA VEJIGA"
    },
    {
        "id": "0b3df1ba-980d-9bbf-8f7a-ce00aaf1117b",
        "NOMBRE": "OTRAS DISFUNCIONES OVÁRICAS"
    },
    {
        "id": "b35e13e3-7d5d-1c6c-ef5e-efbb9d4b082b",
        "NOMBRE": "OTRAS DISFUNCIONES POLIGLANDULARES"
    },
    {
        "id": "7c28159f-c0b4-823c-a805-31ef62ed3e3c",
        "NOMBRE": "OTRAS DISFUNCIONES SEXUALES, NO OCASIONADAS POR TRASTORNO NI POR ENFERMEDAD ORGÁNICOS"
    },
    {
        "id": "9557de6d-eeba-aed8-ac6b-ad6261eee7c7",
        "NOMBRE": "OTRAS DISFUNCIONES SIMBÓLICAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "97ac3656-549f-25d4-2c81-aa74acfe2bd9",
        "NOMBRE": "OTRAS DISFUNCIONES TESTICULARES"
    },
    {
        "id": "0d31e4eb-4f8f-f90d-8708-7ca87d83ab01",
        "NOMBRE": "OTRAS DISPLASIAS MAMARIAS BENIGNAS"
    },
    {
        "id": "232de146-0710-f043-1f87-4a8d7af769e8",
        "NOMBRE": "OTRAS DISTONÍAS"
    },
    {
        "id": "a1be04dc-f667-0f4f-6d4d-e1bd665f60ea",
        "NOMBRE": "OTRAS DORSALGIAS"
    },
    {
        "id": "46ffa783-fc65-324d-26a0-124ed0500d06",
        "NOMBRE": "OTRAS DORSOPATÍAS DEFORMANTES"
    },
    {
        "id": "17dcb6fa-992c-c6bb-2658-496c5d58cbd7",
        "NOMBRE": "OTRAS DORSOPATÍAS DEFORMANTES DE LA COLUMNA VERTEBRAL ESPECIFICADAS"
    },
    {
        "id": "b269e1d5-850b-2102-6505-ffc92edd5c00",
        "NOMBRE": "OTRAS DORSOPATÍAS ESPECIFICADAS"
    },
    {
        "id": "d8f5b2ac-97fd-5d68-14bd-a0939983fdc1",
        "NOMBRE": "OTRAS DORSOPATÍAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "79afd7a5-d930-0503-2e81-482b71cfb923",
        "NOMBRE": "OTRAS EMBOLIAS OBSTÉTRICAS"
    },
    {
        "id": "2c271b3d-1e13-9b89-260a-ca488abf826e",
        "NOMBRE": "OTRAS EMBOLIAS Y TROMBOSIS VENOSAS"
    },
    {
        "id": "a778c432-98c2-c801-21de-f1f65f77e5ec",
        "NOMBRE": "OTRAS ENCEFALITIS VIRALES ESPECIFCADAS"
    },
    {
        "id": "9ae9e290-458b-3ef6-b1f0-4c1610141833",
        "NOMBRE": "OTRAS ENCEFALITIS VIRALES TRANSMITIDA POR GARRAPATAS"
    },
    {
        "id": "a8b5401b-7491-5b93-d693-bf2a5b41dad9",
        "NOMBRE": "OTRAS ENCEFALITIS VIRALES TRANSMITIDAS POR MOSQUITOS"
    },
    {
        "id": "4924991f-770e-a9d5-e46f-a4e4e775a67d",
        "NOMBRE": "OTRAS ENCEFALITIS VIRALES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f12a49f0-7292-b951-1699-ed631bb4b7a4",
        "NOMBRE": "OTRAS ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS"
    },
    {
        "id": "0f277a87-59ba-e196-f0d5-abb81e10341b",
        "NOMBRE": "OTRAS ENDOFTALMITIS"
    },
    {
        "id": "4407dbe2-eecf-ebc9-372b-581440cb9bb1",
        "NOMBRE": "OTRAS ENDOMETRIOSIS"
    },
    {
        "id": "2c07bbf2-6d09-b355-7604-c1d39ef01643",
        "NOMBRE": "OTRAS ENFERMEDADES BACTERIANAS ESPECIFICADAS"
    },
    {
        "id": "f5326579-ea62-64ff-eb62-5d16a8a5811d",
        "NOMBRE": "OTRAS ENFERMEDADES BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "be11b97d-7460-4307-ab28-bf25dda22ca2",
        "NOMBRE": "OTRAS ENFERMEDADES CARDIACAS MAL DEFINIDAS"
    },
    {
        "id": "6556eb4c-a883-d063-52e2-01875cdad251",
        "NOMBRE": "OTRAS ENFERMEDADES CARDIOPULMONARES"
    },
    {
        "id": "bf0deba5-13e0-74f1-5d17-b69af1b2db07",
        "NOMBRE": "OTRAS ENFERMEDADES CARDIOPULMONARES ESPECIFICADAS"
    },
    {
        "id": "507a0ecd-bb56-a2b7-9151-8a794c225a68",
        "NOMBRE": "OTRAS ENFERMEDADES CAUSADAS POR CLAMIDIAS"
    },
    {
        "id": "592178cd-9889-18de-b51c-759e4d962d63",
        "NOMBRE": "OTRAS ENFERMEDADES CEREBROVASCULARES"
    },
    {
        "id": "b7d9f741-56d7-af1b-6ec7-9075a5a8d746",
        "NOMBRE": "OTRAS ENFERMEDADES CEREBROVASCULARES ESPECIFICADAS"
    },
    {
        "id": "2ad38ef1-eb22-ccd0-fb96-928000961fff",
        "NOMBRE": "OTRAS ENFERMEDADES CRÓNICAS DE LAS AMÍGDALAS Y DE LAS ADENOIDES"
    },
    {
        "id": "0fb9c4b3-61e5-9915-659b-159859f08e50",
        "NOMBRE": "OTRAS ENFERMEDADES DE CADENA PESADA"
    },
    {
        "id": "dd50e58a-53a6-6714-a1bc-e8370ae1a267",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA FARINGE"
    },
    {
        "id": "796e26bc-7d10-f4a0-e32a-ef4e9797fd45",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA GLÁNDULA DE BARTHOLIN"
    },
    {
        "id": "b11d6b1d-9508-c7b7-c1dc-8373085fef90",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA LARINGE"
    },
    {
        "id": "f039048c-c5aa-3809-aba8-dffd83c46a9d",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA LENGUA"
    },
    {
        "id": "506482c7-78fa-7cdf-f43d-abb687248d0a",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA MÉDULA ESPINAL"
    },
    {
        "id": "69d52ddf-3eef-72f6-1d9e-08a66f560311",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA SANGRE Y DE LOS ÓRGANOS HEMATOPOYÉTICOS"
    },
    {
        "id": "11cb59a5-6f0b-b1c2-cf6c-b954ff37c41e",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA SANGRE Y ÓRGANOS HEMATOPOYÉTICOS Y CIERTOS TRASTORNOS QUE AFECTAN EL SISTEMA INMUNITARIO CUANDO COMPLICAN EL EMBARAZO, EL PARTO Y PUERPERIO"
    },
    {
        "id": "a7d518c9-6f7d-55b1-cd1f-3a2e51c9d53a",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA VÁLVULA MITRAL"
    },
    {
        "id": "4d1d482a-34e6-0794-6719-2124ad5a4d32",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA VÁLVULA TRICÚSPIDE"
    },
    {
        "id": "4a946edf-732e-95ba-e865-ec1e0d2ba14d",
        "NOMBRE": "OTRAS ENFERMEDADES DE LA VESÍCULA BILIAR"
    },
    {
        "id": "153b4d6e-3493-b524-eb9f-f6c801480834",
        "NOMBRE": "OTRAS ENFERMEDADES DE LAS CUERDAS VOCALES"
    },
    {
        "id": "95f7f589-2074-7f13-65e3-c582c799e5a8",
        "NOMBRE": "OTRAS ENFERMEDADES DE LAS GLÁNDULAS SALIVALES"
    },
    {
        "id": "d5e08521-8bdb-2c45-30b5-7bbfaad9f341",
        "NOMBRE": "OTRAS ENFERMEDADES DE LAS VÍAS BILIARES"
    },
    {
        "id": "2720852b-a430-e0b9-0578-d727027224ff",
        "NOMBRE": "OTRAS ENFERMEDADES DE LAS VÍAS RESPIRATORIAS SUPERIORES"
    },
    {
        "id": "e8a3c53d-a065-b40d-15b5-1765cac90ff4",
        "NOMBRE": "OTRAS ENFERMEDADES DE LOS CAPILARES"
    },
    {
        "id": "46ddc5f4-9185-e27d-00b3-e08cf50bbf7b",
        "NOMBRE": "OTRAS ENFERMEDADES DE LOS INTESTINOS"
    },
    {
        "id": "b787a05d-47d8-fd14-f24d-35f8e75af125",
        "NOMBRE": "OTRAS ENFERMEDADES DE LOS LABIOS Y DE LA MUCOSA BUCAL"
    },
    {
        "id": "87952586-ecfc-a417-de17-93fa69421d1f",
        "NOMBRE": "OTRAS ENFERMEDADES DE LOS MAXILARES"
    },
    {
        "id": "29cc3ae4-1bb9-52f4-d0cc-845eee556a7f",
        "NOMBRE": "OTRAS ENFERMEDADES DE LOS TEJIDOS DUROS DE LOS DIENTES"
    },
    {
        "id": "ac72fcae-7b06-aedb-ff29-076032e4d487",
        "NOMBRE": "OTRAS ENFERMEDADES DE LOS VASOS PULMONARES"
    },
    {
        "id": "5503bfff-8394-f54a-8cb1-d966fe560769",
        "NOMBRE": "OTRAS ENFERMEDADES DE MÚLTIPLES VÁLVULAS"
    },
    {
        "id": "0e2eee8c-c47a-65d5-c0fc-e860ea77dec6",
        "NOMBRE": "OTRAS ENFERMEDADES DE TRANSMISIÓN PREDOMINANTEMENTE SEXUAL, ESPECIFICADAS"
    },
    {
        "id": "5798f555-d330-d1b7-1065-08f98d27be15",
        "NOMBRE": "OTRAS ENFERMEDADES DE TRANSMISIÓN PREDOMINANTEMENTE SEXUAL, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "31ec4cac-ab9c-f3c5-d3b4-b98ee743a232",
        "NOMBRE": "OTRAS ENFERMEDADES DE TRANSMISIÓN SEXUAL DEBIDAS A CLAMIDIAS"
    },
    {
        "id": "eda6b9b8-0008-4ec1-ff24-640df6612040",
        "NOMBRE": "OTRAS ENFERMEDADES DEBIDAS A ANQUILOSTOMAS"
    },
    {
        "id": "f5085e5b-e2ba-a4bb-a861-fe0171e1a24d",
        "NOMBRE": "OTRAS ENFERMEDADES DEBIDAS A PROTOZOARIOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "77178c5e-7fee-3419-a868-fc8a075f8854",
        "NOMBRE": "OTRAS ENFERMEDADES DEBIDAS A VIRUS CITOMEGÁLICO"
    },
    {
        "id": "26f05f04-ded3-db0b-d752-6b24b07aec6c",
        "NOMBRE": "OTRAS ENFERMEDADES DEGENERATIVAS DE LOS NÚCLEOS DE LA BASE"
    },
    {
        "id": "965b523a-bd52-e665-6af8-e25157694ae5",
        "NOMBRE": "OTRAS ENFERMEDADES DEGENERATIVAS DEL SISTEMA NERVIOSO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6999f855-6bbe-eb0e-5328-3982886c1129",
        "NOMBRE": "OTRAS ENFERMEDADES DEGENERATIVAS ESPECIFICADAS DEL SISTEMA NERVIOSO"
    },
    {
        "id": "a2ce2a04-867f-bd23-2598-e332e3d6385b",
        "NOMBRE": "OTRAS ENFERMEDADES DEGENERATIVAS ESPECIFICAS DE LOS NÚCLEOS DE LA BASE"
    },
    {
        "id": "a3e56f0b-b2ce-d003-fbdd-e58ff1da52f5",
        "NOMBRE": "OTRAS ENFERMEDADES DEL ANO Y DEL RECTO"
    },
    {
        "id": "897b8a33-762a-999e-28be-95ef9efdd18d",
        "NOMBRE": "OTRAS ENFERMEDADES DEL APÉNDICE"
    },
    {
        "id": "0ea284b5-7ddf-4497-1db2-aa5ccd42fd70",
        "NOMBRE": "OTRAS ENFERMEDADES DEL BAZO"
    },
    {
        "id": "8cde587e-7c79-b104-9c89-d414686c58ec",
        "NOMBRE": "OTRAS ENFERMEDADES DEL ESÓFAGO"
    },
    {
        "id": "d0d522a5-4036-b995-e8ce-da3abffa1874",
        "NOMBRE": "OTRAS ENFERMEDADES DEL ESTÓMAGO Y DEL DUODENO"
    },
    {
        "id": "909413c1-7bfe-2a41-8908-7f13d96cecde",
        "NOMBRE": "OTRAS ENFERMEDADES DEL HÍGADO"
    },
    {
        "id": "b510807a-0911-044a-14c3-59b380b0430b",
        "NOMBRE": "OTRAS ENFERMEDADES DEL PÁNCREAS"
    },
    {
        "id": "6fe9106a-896e-de88-883d-b325fc697c0f",
        "NOMBRE": "OTRAS ENFERMEDADES DEL PERICARDIO"
    },
    {
        "id": "c0e5b092-4bb9-0a15-ed90-bade35f98a20",
        "NOMBRE": "OTRAS ENFERMEDADES DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "f8c6279e-85f3-d0a8-1675-9ccd2cddc2bb",
        "NOMBRE": "OTRAS ENFERMEDADES DEL TIMO"
    },
    {
        "id": "d4f489aa-412c-08e2-d400-81313a3594bd",
        "NOMBRE": "OTRAS ENFERMEDADES DESMIELINIZANTES DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "c118979e-771d-b137-29f1-f0b2ead2dffb",
        "NOMBRE": "OTRAS ENFERMEDADES DESMIELINIZANTES DEL SISTEMA NERVIOSO CENTRAL, ESPECIFICADAS"
    },
    {
        "id": "23c903c7-31eb-8ffb-c035-fdc270b645b7",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS CON COMPROMISO SISTÉMICO DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "07f2e7f2-f9ce-fa41-f48d-b2e8edc6ccb7",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS CON PARTICIPACIÓN DEL TEJIDO LINFORRETICULAR Y DEL TEJIDO RETICULOHISTIOCÍTICO"
    },
    {
        "id": "bb9d7bc8-0cab-e52d-c985-c397a84e8101",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LA MÉDULA ESPINAL"
    },
    {
        "id": "9a6ee7c2-8123-76cd-a9ca-dd5892c9df24",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LA SANGRE Y DE LOS ÓRGANOS HEMATOPOYÉTICOS"
    },
    {
        "id": "bac7008a-295c-38ed-41de-4c88d233b550",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LA VESÍCULA BILIAR"
    },
    {
        "id": "97d1b734-0203-7206-436b-0801dff3e50d",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LAS VÍAS BILIARES"
    },
    {
        "id": "290c0931-39be-0939-d6cc-10238bba3b94",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LAS VÍAS RESPIRATORIAS SUPERIORES"
    },
    {
        "id": "8b8dd304-5b16-a142-12f8-52d4f19001cb",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LOS MAXILARES"
    },
    {
        "id": "a1046b62-a36d-e7e1-6fe4-1d38fa4d55b5",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LOS TEJIDOS DUROS DE LOS DIENTES"
    },
    {
        "id": "11cfdad7-93df-4041-d868-092a393d3a4c",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DE LOS VASOS PULMONARES"
    },
    {
        "id": "619b0de5-924c-e96f-abe3-8c5498b949d0",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEBIDAS A PROTOZOARIOS"
    },
    {
        "id": "b382d216-983b-8fce-409c-effd6b773b04",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL ANO Y DEL RECTO"
    },
    {
        "id": "14ebdfc3-6171-8476-5f6b-d06f3b828aa5",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL APÉNDICE"
    },
    {
        "id": "2564779d-7d28-0bf1-46de-d22fe7097ca3",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL ESÓFAGO"
    },
    {
        "id": "47a57059-3b18-ce93-c059-6894e52ca6b9",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL ESTÓMAGO Y DEL DUODENO"
    },
    {
        "id": "7a895aa0-06d5-ce6d-e6cf-995dbc2f1593",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL HÍGADO"
    },
    {
        "id": "a9fe1d51-990d-5142-16c7-77968376acae",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL INTESTINO"
    },
    {
        "id": "703e1667-fdcf-cbc0-0891-721dc8adafe6",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL PÁNCREAS"
    },
    {
        "id": "aa7ebbb5-0a18-56e3-aac2-b1cbaf4b4aab",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL PERICARDIO"
    },
    {
        "id": "b6226159-afea-2f35-de31-f21da3d577ce",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "a5c38aad-9465-af3a-52ed-5e216da86d56",
        "NOMBRE": "OTRAS ENFERMEDADES ESPECIFICADAS Y AFECCIONES QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "7a9269f7-b2ba-f7f4-9f7c-cc4c1206e0c8",
        "NOMBRE": "OTRAS ENFERMEDADES HEMOLÍTICAS DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "258875cf-b5d5-b78b-694b-df0d78a9e5da",
        "NOMBRE": "OTRAS ENFERMEDADES INFECCIOSAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "c2194c9b-51c0-774c-26cd-18c614e8cd02",
        "NOMBRE": "OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CONGÉNITAS"
    },
    {
        "id": "ef5eeae9-778c-1fb8-82cd-480235a9bcb2",
        "NOMBRE": "OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS MATERNAS QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "67c7b920-e4a8-2e29-b1df-44c0c485af65",
        "NOMBRE": "OTRAS ENFERMEDADES INFLAMATORIAS DE LA PRÓSTATA"
    },
    {
        "id": "b14eb397-617c-66dd-5839-ca18e06b377e",
        "NOMBRE": "OTRAS ENFERMEDADES INFLAMATORIAS DEL HÍGADO"
    },
    {
        "id": "7cb0997b-825b-3256-8a22-d886f93ea9b5",
        "NOMBRE": "OTRAS ENFERMEDADES INFLAMATORIAS DEL HÍGADO, ESPECIFICADAS"
    },
    {
        "id": "ef20983c-1ee4-c9b2-7710-d7a90a4f11ff",
        "NOMBRE": "OTRAS ENFERMEDADES INFLAMATORIAS PÉLVICAS FEMENINAS"
    },
    {
        "id": "2b7cb43f-a7ac-4073-35e5-19978aab2942",
        "NOMBRE": "OTRAS ENFERMEDADES INMUNOPROLIFERATIVAS MALIGNAS"
    },
    {
        "id": "e4e42cc4-7cee-6be2-afa4-19f0e0f15c64",
        "NOMBRE": "OTRAS ENFERMEDADES INTESTINALES DEBIDAS A PROTOZOARIOS"
    },
    {
        "id": "26a62192-54bb-ab83-819e-d0f5fb22b88d",
        "NOMBRE": "OTRAS ENFERMEDADES INTESTINALES ESPECIFICADAS DEBIDAS A PROTOZOARIOS"
    },
    {
        "id": "443c3917-b0a1-7f79-0106-1d100ab46eae",
        "NOMBRE": "OTRAS ENFERMEDADES ISQUÉMICAS AGUDAS DEL CORAZÓN"
    },
    {
        "id": "71319f9a-9e5b-2721-71dd-7db67ce3cf6b",
        "NOMBRE": "OTRAS ENFERMEDADES MATERNAS CLASIFICABLES EN OTRA PARTE, PERO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "70ddfccd-f947-edb1-1c99-3c9e58aa5836",
        "NOMBRE": "OTRAS ENFERMEDADES NEONATALES INFECCIOSAS O PARASITARIAS ESPECIFICADAS"
    },
    {
        "id": "a1ed2fb5-781b-b0b1-b495-30ddc5930762",
        "NOMBRE": "OTRAS ENFERMEDADES PÉLVICAS INFLAMATORIAS FEMENINAS"
    },
    {
        "id": "1bd18562-b3b2-cfb5-9192-82b3e0dc3fb6",
        "NOMBRE": "OTRAS ENFERMEDADES PERIODONTALES"
    },
    {
        "id": "cf0231ff-b136-24d8-dce8-2c6f1bfa210e",
        "NOMBRE": "OTRAS ENFERMEDADES POR CLAMIDIAS"
    },
    {
        "id": "75d27f67-b089-47b3-2cb2-d5affaa3a440",
        "NOMBRE": "OTRAS ENFERMEDADES PULMONARES INTERSTICIALES"
    },
    {
        "id": "dfa0d0f1-f492-bb47-db1e-4ddaa0b5ad32",
        "NOMBRE": "OTRAS ENFERMEDADES PULMONARES INTERSTICIALES CON FIBROSIS"
    },
    {
        "id": "f022a603-f140-1e41-119e-de83ca673fb1",
        "NOMBRE": "OTRAS ENFERMEDADES PULMONARES INTERSTICIALES ESPECIFICADAS"
    },
    {
        "id": "e65d5e12-f875-0f78-b615-053a8848d555",
        "NOMBRE": "OTRAS ENFERMEDADES PULMONARES OBSTRUCTIVAS CRÓNICAS"
    },
    {
        "id": "644cd265-3710-4fe5-791d-d44faf3f3b8e",
        "NOMBRE": "OTRAS ENFERMEDADES PULMONARES OBSTRUCTIVAS CRÓNICAS ESPECIFICADAS"
    },
    {
        "id": "a6e08421-0575-db6f-3eae-dcb562592bef",
        "NOMBRE": "OTRAS ENFERMEDADES RENALES QUÍSTICAS"
    },
    {
        "id": "132629d8-81f4-23e2-ada5-78761c45fd9b",
        "NOMBRE": "OTRAS ENFERMEDADES RENALES TUBULOINTERSTICIALES"
    },
    {
        "id": "be374659-897e-037f-fcdf-c3d1e8ede7fe",
        "NOMBRE": "OTRAS ENFERMEDADES RENALES TUBULOINTERSTICIALES ESPECIFICADAS"
    },
    {
        "id": "d51e6573-7b00-1d91-c2c3-8eb154b5d4ba",
        "NOMBRE": "OTRAS ENFERMEDADES RESPIRATORIAS CRÓNICAS ORIGINADAS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "8003f938-e00b-55f6-5dde-8e58ce05906c",
        "NOMBRE": "OTRAS ENFERMEDADES REUMÁTICAS AGUDAS DEL CORAZÓN"
    },
    {
        "id": "19fa8ad3-e9c2-fed5-6284-79581ab8a617",
        "NOMBRE": "OTRAS ENFERMEDADES REUMÁTICAS DE LA VÁLVULA AÓRTICA"
    },
    {
        "id": "6710cac6-2036-9941-ed22-a5228457c50b",
        "NOMBRE": "OTRAS ENFERMEDADES REUMÁTICAS DEL CORAZÓN"
    },
    {
        "id": "0d1b4693-990a-d3e0-b2d0-33919fa52746",
        "NOMBRE": "OTRAS ENFERMEDADES REUMÁTICAS ESPECIFICADAS DEL CORAZÓN"
    },
    {
        "id": "ee318025-0799-eb89-5ffd-35484ae1ed25",
        "NOMBRE": "OTRAS ENFERMEDADES VASCULARES PERIFÉRICAS"
    },
    {
        "id": "7209bccc-8bbc-d8d4-c08f-a2768a6b998e",
        "NOMBRE": "OTRAS ENFERMEDADES VASCULARES PERIFÉRICAS ESPECIFICADAS"
    },
    {
        "id": "da933ff0-dac4-2789-e809-11f36de1c3af",
        "NOMBRE": "OTRAS ENFERMEDADES VIRALES CONGÉNITAS"
    },
    {
        "id": "1028bf28-8ebf-7292-0296-2ef7853a9eb7",
        "NOMBRE": "OTRAS ENFERMEDADES VIRALES ESPECIFICADAS"
    },
    {
        "id": "31a1dea9-ef55-1195-c56d-8e23f9842d7d",
        "NOMBRE": "OTRAS ENFERMEDADES VIRALES QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "e7933921-2a0f-9b82-f781-59f0922a0297",
        "NOMBRE": "OTRAS ENFERMEDADES VIRALES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a3eb7efb-3245-a0a2-5a62-da1ef1f3b405",
        "NOMBRE": "OTRAS ENFERMEDADES Y LAS NO ESPECIFICADAS DE LA PULPA Y DEL TEJIDO PERIAPICAL"
    },
    {
        "id": "b6edd395-230d-70d7-f7e0-ae828953e2ba",
        "NOMBRE": "OTRAS ENFERMEDADES ZOONÓTICAS BACTERIANAS ESPECIFICADAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0565edd2-2073-92a2-9238-d887e5a4a587",
        "NOMBRE": "OTRAS ENFERMEDADES ZOONÓTICAS BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c7f9a36d-2ba9-6874-c621-35f2c265e614",
        "NOMBRE": "OTRAS ENTERITIS VIRALES"
    },
    {
        "id": "5443a378-e582-5ac2-62d8-fc06511bb32a",
        "NOMBRE": "OTRAS ENTESOPATÍAS"
    },
    {
        "id": "41b2e853-2664-c0a5-e434-9424cd237a36",
        "NOMBRE": "OTRAS ENTESOPATÍAS DEL MIEMBRO INFERIOR, EXCLUIDO EL PIE"
    },
    {
        "id": "5adb6a63-3037-2ba0-853a-53e19408da5f",
        "NOMBRE": "OTRAS ENTESOPATÍAS DEL PIE"
    },
    {
        "id": "d2312655-7a3d-6f9e-ffbd-97bec8803370",
        "NOMBRE": "OTRAS ENTESOPATÍAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4c26bad6-b725-e04d-de7c-00e906afe3a4",
        "NOMBRE": "OTRAS EPIDERMÓLISIS BULLOSAS"
    },
    {
        "id": "c264df32-c7a3-1a81-2d62-b747809bb78f",
        "NOMBRE": "OTRAS EPILEPSIAS"
    },
    {
        "id": "1aa1e624-2120-7d1f-2ff2-6fe24180f988",
        "NOMBRE": "OTRAS EPILEPSIAS Y SÍNDROMES EPILÉPTICOS GENERALIZADOS"
    },
    {
        "id": "eb8e0710-06f1-2434-d868-3f010f85d167",
        "NOMBRE": "OTRAS ESCOLIOSIS IDIOPÁTICAS"
    },
    {
        "id": "9e29b85d-d286-f3a1-970d-2b9f739aad38",
        "NOMBRE": "OTRAS ESCOLIOSIS SECUNDARIAS"
    },
    {
        "id": "c47835bf-1c08-ff6a-ea7b-0e5aa54e7f64",
        "NOMBRE": "OTRAS ESFINGOLIPIDOSIS"
    },
    {
        "id": "11dd1f74-82ac-8c71-cf0e-7de4647c898c",
        "NOMBRE": "OTRAS ESPONDILOPATÍAS"
    },
    {
        "id": "c54ee5d7-0718-b43e-3d75-191339e192c8",
        "NOMBRE": "OTRAS ESPONDILOPATÍAS ESPECIFICADAS"
    },
    {
        "id": "2a355c8c-b259-9e5a-5907-ebbc87c2fab7",
        "NOMBRE": "OTRAS ESPONDILOPATÍAS INFECCIOSAS"
    },
    {
        "id": "471466e2-8538-04e0-9cfc-739dd3a2ec11",
        "NOMBRE": "OTRAS ESPONDILOPATÍAS INFLAMATORIAS"
    },
    {
        "id": "93b8ea7f-ca06-3ac9-b62b-d3d45db3a2aa",
        "NOMBRE": "OTRAS ESPONDILOPATÍAS INFLAMATORIAS ESPECIFICADAS"
    },
    {
        "id": "93242871-ca3f-48fb-473d-dbd283d5ef97",
        "NOMBRE": "OTRAS ESPONDILOSIS"
    },
    {
        "id": "2983c8cb-b113-315c-72fe-c60acfbc2ecb",
        "NOMBRE": "OTRAS ESPONDILOSIS CON MIELOPATÍA"
    },
    {
        "id": "5cbe00a7-d3c4-6f8c-b364-59aea7e97b83",
        "NOMBRE": "OTRAS ESPONDILOSIS CON RADICULOPATÍA"
    },
    {
        "id": "a2938207-42ae-e5d2-4b02-3158dd5a3354",
        "NOMBRE": "OTRAS ESQUISTOSOMIASIS"
    },
    {
        "id": "4a3fbc4b-0161-5d29-e7e8-51cd41f9c8ba",
        "NOMBRE": "OTRAS ESQUIZOFRENIAS"
    },
    {
        "id": "cb5e01bf-6dea-2961-2797-9949430580a5",
        "NOMBRE": "OTRAS ESTRECHECES URETRALES"
    },
    {
        "id": "2f531690-9fe4-bfc5-bb16-44fb28d6f6ea",
        "NOMBRE": "OTRAS FACOMATOSIS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "fd9efb40-67af-2018-b681-0b79407b7c52",
        "NOMBRE": "OTRAS FALLAS DE COORDINACIÓN"
    },
    {
        "id": "2a31aa13-bf6a-f6a4-b710-ae65f5a8d89c",
        "NOMBRE": "OTRAS FALLAS DE LA COORDINACIÓN Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "e472de9a-62ca-138f-02ca-4fbba004fa86",
        "NOMBRE": "OTRAS FALTAS DEL DESARROLLO FISIOLÓGICO NORMAL ESPERADO"
    },
    {
        "id": "2e102a1f-48d8-3cd3-68c1-8551c46333c8",
        "NOMBRE": "OTRAS FIEBRES ESPECIFICADAS"
    },
    {
        "id": "f8f1affb-442e-1f81-0aba-a039b0c04da0",
        "NOMBRE": "OTRAS FIEBRES HEMORRÁGICAS POR ARENAVIRUS"
    },
    {
        "id": "9b5f9695-f7c3-891c-7bf1-c9e1a44ba24a",
        "NOMBRE": "OTRAS FIEBRES HEMORRÁGICAS VIRALES ESPECIFICADAS"
    },
    {
        "id": "23828500-f5fe-b288-1f94-b861eedbe169",
        "NOMBRE": "OTRAS FIEBRES MACULOSAS"
    },
    {
        "id": "8bb90ba5-3d4b-aa76-505d-8d59737b7c94",
        "NOMBRE": "OTRAS FIEBRES VIRALES ESPECIFICADAS TRANSMITIDAS POR ARTRÓPODOS"
    },
    {
        "id": "c380c494-be93-0da0-745f-6836d3e885ce",
        "NOMBRE": "OTRAS FIEBRES VIRALES ESPECIFICADAS TRANSMITIDAS POR MOSQUITOS"
    },
    {
        "id": "4151df11-b5e6-941f-ef96-91beaa1a1453",
        "NOMBRE": "OTRAS FIEBRES VIRALES HEMORRÁGICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "500bda64-6c2a-540f-5554-56bc833684f3",
        "NOMBRE": "OTRAS FIEBRES VIRALES TRANSMITIDAS POR ARTRÓPODOS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c2f55c54-df29-27cc-5f0b-053341444415",
        "NOMBRE": "OTRAS FIEBRES VIRALES TRANSMITIDAS POR MOSQUITOS"
    },
    {
        "id": "9645ab50-21e6-0035-c0dc-3586944f9f81",
        "NOMBRE": "OTRAS FILARIASIS"
    },
    {
        "id": "35ad77a1-ee72-0efa-c841-786d8155c3e5",
        "NOMBRE": "OTRAS FÍSTULAS DE LAS VÍAS GENITOURINARIAS FEMENINAS"
    },
    {
        "id": "be708232-9e98-a573-a17f-e537755ad3ca",
        "NOMBRE": "OTRAS FÍSTULAS DEL TRACTO GENITAL FEMENINO"
    },
    {
        "id": "88848829-af5b-db68-f1bc-f7618d12e8d0",
        "NOMBRE": "OTRAS FÍSTULAS DEL TRACTO GENITAL FEMENINO AL TRACTO INTESTINAL"
    },
    {
        "id": "b8989003-db82-ee94-0a9a-600d84a7d084",
        "NOMBRE": "OTRAS FORMAS DE ACTINOMICOSIS"
    },
    {
        "id": "fa698f65-64f0-dd61-830e-b22f5118c0eb",
        "NOMBRE": "OTRAS FORMAS DE ASPERGILOSIS"
    },
    {
        "id": "f3fee1df-4582-7431-504c-2a1951ace346",
        "NOMBRE": "OTRAS FORMAS DE BARTONELOSIS"
    },
    {
        "id": "aa02d194-fc4c-1d47-0664-f34b0b50378c",
        "NOMBRE": "OTRAS FORMAS DE BLASTOMICOSIS"
    },
    {
        "id": "d44d6a74-1320-3b54-e027-7a5d2cd20c74",
        "NOMBRE": "OTRAS FORMAS DE CARBUNCO"
    },
    {
        "id": "2eb81b1b-5747-9779-dcfa-0ee50fd2d5ea",
        "NOMBRE": "OTRAS FORMAS DE CHOQUE"
    },
    {
        "id": "90fa27fd-c6cc-3d86-73ef-0c6334d5b9f4",
        "NOMBRE": "OTRAS FORMAS DE COCCIDIOIDOMICOSIS"
    },
    {
        "id": "142e12ec-97a5-0432-0090-ff60de1bf4a3",
        "NOMBRE": "OTRAS FORMAS DE CRIPTOCOCOSIS"
    },
    {
        "id": "111d6d3c-f27e-3283-f046-1322a56588cc",
        "NOMBRE": "OTRAS FORMAS DE CROMOMICOSIS"
    },
    {
        "id": "25c2aff3-5ebd-a961-62e1-f1e2934e7ee6",
        "NOMBRE": "OTRAS FORMAS DE ENFERMEDAD ISQUÉMICA AGUDA DEL CORAZÓN"
    },
    {
        "id": "769e8581-8bb3-deaa-2d5e-3665395c2b53",
        "NOMBRE": "OTRAS FORMAS DE ENFERMEDAD ISQUÉMICA CRÓNICA DEL CORAZÓN"
    },
    {
        "id": "a371b976-5cba-9120-5ed0-b8eb124cf0c7",
        "NOMBRE": "OTRAS FORMAS DE ENFERMEDAD MENINGOCÓCICA"
    },
    {
        "id": "8d9cef1d-e698-44b3-a300-a052e35b39d8",
        "NOMBRE": "OTRAS FORMAS DE ERISIPELOIDE"
    },
    {
        "id": "0a296693-6171-d110-fde3-d7d8a9cc8116",
        "NOMBRE": "OTRAS FORMAS DE ESCLEROSIS SISTÉMICA"
    },
    {
        "id": "3bdd01c1-0a73-149a-c5fd-f548ac576db0",
        "NOMBRE": "OTRAS FORMAS DE ESCOLIOSIS"
    },
    {
        "id": "189c97d7-9bcb-74b3-a17d-634fd6254cba",
        "NOMBRE": "OTRAS FORMAS DE ESPOROTRICOSIS"
    },
    {
        "id": "11ea2091-11be-6f80-4efd-79731497849d",
        "NOMBRE": "OTRAS FORMAS DE ESTOMATITIS"
    },
    {
        "id": "cbbf087e-5831-9d85-4204-e4efe109fc2e",
        "NOMBRE": "OTRAS FORMAS DE INFECCIONES HERPÉTICAS"
    },
    {
        "id": "21a3a47f-2ab6-a5cc-37fd-8398c25c5c33",
        "NOMBRE": "OTRAS FORMAS DE LEPRA"
    },
    {
        "id": "5c073201-1b08-9ba4-496b-a0859157a4ba",
        "NOMBRE": "OTRAS FORMAS DE LEPTOSPIROSIS"
    },
    {
        "id": "d2125224-f498-3f98-b5e8-f434d28eeff9",
        "NOMBRE": "OTRAS FORMAS DE LISTERIOSIS"
    },
    {
        "id": "78683d78-846d-6c8e-e620-3ed187c6b4bf",
        "NOMBRE": "OTRAS FORMAS DE LUPUS ERITEMATOSO SISTÉMICO"
    },
    {
        "id": "64028bae-d4fc-319a-eea2-26bda385243d",
        "NOMBRE": "OTRAS FORMAS DE NOCARDIOSIS"
    },
    {
        "id": "b02bf9f2-5436-4d83-cbd2-e931d18091b5",
        "NOMBRE": "OTRAS FORMAS DE OCLUSIÓN DE LA ARTERIA DE LA RETINA"
    },
    {
        "id": "d79bec1b-e09b-4686-7c12-875b6bfd429c",
        "NOMBRE": "OTRAS FORMAS DE PARACOCCIDIOIDOMICOSIS"
    },
    {
        "id": "c073cd9b-be38-e4d2-f5f8-d32571a490d3",
        "NOMBRE": "OTRAS FORMAS DE PERICARDITIS AGUDA"
    },
    {
        "id": "d51a25e1-4517-29b1-58d3-feb2355e9fba",
        "NOMBRE": "OTRAS FORMAS DE PESTE"
    },
    {
        "id": "2d0e94bc-7eb4-ee23-75c9-f9679193020b",
        "NOMBRE": "OTRAS FORMAS DE SÍFILIS CONGÉNITA TARDÍA, SINTOMÁTICA"
    },
    {
        "id": "a9784756-416b-b48e-4c2c-68023dfee768",
        "NOMBRE": "OTRAS FORMAS DE TULAREMIA"
    },
    {
        "id": "41b3e844-6546-4bd6-dac4-b7fb708feef0",
        "NOMBRE": "OTRAS FORMAS ESPECIFICADAS DE ANGINA DE PECHO"
    },
    {
        "id": "14bc6ad1-febe-4262-2325-773fe4bd27c2",
        "NOMBRE": "OTRAS FORMAS ESPECIFICADAS DE CATARATA"
    },
    {
        "id": "26311da7-783c-566e-5d3c-0c827de87d9a",
        "NOMBRE": "OTRAS FORMAS ESPECIFICADAS DE TEMBLOR"
    },
    {
        "id": "2d499484-39d3-279f-c129-d68526016bc9",
        "NOMBRE": "OTRAS FUSIONES DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "76fadd20-cf16-8912-9ac9-7c7e7bbf19bc",
        "NOMBRE": "OTRAS GANGLIOSIDOSIS"
    },
    {
        "id": "a3a661ca-73db-1044-87a9-1563e683d3f9",
        "NOMBRE": "OTRAS GASTRITIS"
    },
    {
        "id": "8b24f375-6fa2-1fbf-db66-8a5bbbe5b498",
        "NOMBRE": "OTRAS GASTRITIS AGUDAS"
    },
    {
        "id": "04534984-f53d-2734-82af-be75a987200d",
        "NOMBRE": "OTRAS GASTROENTERITIS Y COLITIS DE ORIGEN INFECCIOSO"
    },
    {
        "id": "31bdd209-569b-1b4e-b472-66ea8cda6c40",
        "NOMBRE": "OTRAS GASTROENTERITIS Y COLITIS DE ORIGEN INFECCIOSO Y NO ESPECIFICADO"
    },
    {
        "id": "72a3acde-d41a-4277-2bee-cc816cf639a1",
        "NOMBRE": "OTRAS GONARTROSIS POSTRAUMÁTICAS"
    },
    {
        "id": "65485e42-8e3c-30b8-2a5a-f2f6b245f2df",
        "NOMBRE": "OTRAS GONARTROSIS PRIMARIAS"
    },
    {
        "id": "93523cdf-eb5b-d57f-05f7-45085d4b1e55",
        "NOMBRE": "OTRAS GONARTROSIS SECUNDARIAS"
    },
    {
        "id": "c7e236fe-ea9f-8e45-d06b-f5b0a0c18000",
        "NOMBRE": "OTRAS GONARTROSIS SECUNDARIAS, BILATERALES"
    },
    {
        "id": "921fb48a-b149-5a92-e5d6-7578ea4d69f7",
        "NOMBRE": "OTRAS GOTAS SECUNDARIAS"
    },
    {
        "id": "a8c9d391-28c8-f5fb-c1f6-cdb3094dd495",
        "NOMBRE": "OTRAS HELMINTIASIS ESPECIFICADAS"
    },
    {
        "id": "25fd74eb-3f9d-1753-ba6f-60ab09094e51",
        "NOMBRE": "OTRAS HELMINTIASIS INTESTINALES ESPECIFICADAS"
    },
    {
        "id": "ec36ca50-9e69-ad69-0d94-da0cb751c9e7",
        "NOMBRE": "OTRAS HELMINTIASIS INTESTINALES, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "34ce94c8-7727-83a7-5e8e-70ec66c5c612",
        "NOMBRE": "OTRAS HEMOGLOBINOPATÍAS"
    },
    {
        "id": "621d7106-35ee-a9c1-1c1a-4235a679e2d0",
        "NOMBRE": "OTRAS HEMORRAGIAS ANTEPARTO"
    },
    {
        "id": "495818d6-7dae-1319-0940-f6877cff49e6",
        "NOMBRE": "OTRAS HEMORRAGIAS FETALES Y NEONATALES ESPECIFICADAS"
    },
    {
        "id": "a72490e2-17b2-5d6a-fc45-8630251561b4",
        "NOMBRE": "OTRAS HEMORRAGIAS GASTROINTESTINALES NEONATALES"
    },
    {
        "id": "a6d17ddc-2264-eb38-715b-6c92af30b27f",
        "NOMBRE": "OTRAS HEMORRAGIAS INTRACRANEALES (NO TRAUMÁTICAS) DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "0aacaba4-36f9-24dd-0042-cab0fb6a3173",
        "NOMBRE": "OTRAS HEMORRAGIAS INTRACRANEALES NO TRAUMÁTICAS"
    },
    {
        "id": "46b6900b-a558-239d-ced7-58d7f39bbfc1",
        "NOMBRE": "OTRAS HEMORRAGIAS INTRAENCEFÁLICAS"
    },
    {
        "id": "1daa842e-228f-59ee-b874-e61e97442efc",
        "NOMBRE": "OTRAS HEMORRAGIAS INTRAPARTO"
    },
    {
        "id": "832589a3-cf01-900e-875c-fa4bdc2583ea",
        "NOMBRE": "OTRAS HEMORRAGIAS NEONATALES"
    },
    {
        "id": "2853ff77-7b37-a57b-189d-46d8cdf448f5",
        "NOMBRE": "OTRAS HEMORRAGIAS POSTPARTO INMEDIATAS"
    },
    {
        "id": "64202795-8266-39d3-6ec0-6bb4dcf67003",
        "NOMBRE": "OTRAS HEMORRAGIAS PRECOCES DEL EMBARAZO"
    },
    {
        "id": "add07048-3d91-4d35-0174-f25d4a64ba32",
        "NOMBRE": "OTRAS HEMORRAGIAS PULMONARES ORIGINADAS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "e4318d24-e5cd-a96e-54b3-fd93bdd5b6bc",
        "NOMBRE": "OTRAS HEMORRAGIAS SUBARACNOIDEAS"
    },
    {
        "id": "205306e7-7a43-b9cb-40dc-69043c8b7d02",
        "NOMBRE": "OTRAS HEMORRAGIAS UMBILICALES DEL RECIÉN NACIDO"
    },
    {
        "id": "cc686e67-e010-1505-d123-5766fa01efdc",
        "NOMBRE": "OTRAS HEMORRAGIAS UTERINAS O VAGINALES ANORMALES"
    },
    {
        "id": "7add140f-f750-1d6b-6652-1e27e593029d",
        "NOMBRE": "OTRAS HEMORRAGIAS UTERINAS O VAGINALES ANORMALES ESPECIFICADAS"
    },
    {
        "id": "e0621200-2a1b-efec-7ade-1c0f9d26e07d",
        "NOMBRE": "OTRAS HEMORRAGIAS Y LACERACIONES INTRACRANEALES DEBIDAS A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "c2b3f82d-c207-a711-484a-fa68db046909",
        "NOMBRE": "OTRAS HEMORROIDES ESPECIFICADAS"
    },
    {
        "id": "41b32e5b-291a-9d67-4301-1ad143554b2b",
        "NOMBRE": "OTRAS HEPATITIS CRÓNICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "872ab931-d64f-fffd-1668-cca17e6c6aa5",
        "NOMBRE": "OTRAS HEPATITIS VIRALES AGUDAS"
    },
    {
        "id": "099252fa-3c75-8b84-fe4a-3e8d0d664167",
        "NOMBRE": "OTRAS HEPATITIS VIRALES AGUDAS ESPECIFICADAS"
    },
    {
        "id": "1b6cabe5-91b9-f075-2a41-f6df6f792fc4",
        "NOMBRE": "OTRAS HEPATITIS VIRALES CRÓNICAS"
    },
    {
        "id": "9d470f9f-9122-4fdb-50aa-99e0877ece94",
        "NOMBRE": "OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL"
    },
    {
        "id": "b9578aa4-d4ea-1025-dab3-5ce0a940e6de",
        "NOMBRE": "OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, CON GANGRENA"
    },
    {
        "id": "e03084a3-0bfe-92cf-bc5f-8c83bf146f07",
        "NOMBRE": "OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "369f1240-be64-e1c0-ccd1-9b4a7120ad5d",
        "NOMBRE": "OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, SIN OBSTRUCCIÓN NI GANGRENA"
    },
    {
        "id": "7fabd832-27da-5e30-3d88-f03eacb70775",
        "NOMBRE": "OTRAS HERNIAS VENTRALES Y LAS NO ESPECIFICADAS CON GANGRENA"
    },
    {
        "id": "5062b88c-72c7-9a73-c6ac-33db9e94a366",
        "NOMBRE": "OTRAS HERNIAS VENTRALES Y LAS NO ESPECIFICADAS CON OBSTRUCCIÓN, SIN GANGRENA"
    },
    {
        "id": "1b0cd35c-b539-5a32-22c2-fd67f87fa297",
        "NOMBRE": "OTRAS HERNIAS VENTRALES Y LAS NO ESPECIFICADAS SIN OBSTRUCCIÓN O GANGRENA"
    },
    {
        "id": "0e9c1557-dccd-f845-9dc7-7f215f1513ed",
        "NOMBRE": "OTRAS HETEROTROPÍAS O LAS NO ESPECIFICADAS"
    },
    {
        "id": "7e754ede-502f-0942-9995-142a144ffcde",
        "NOMBRE": "OTRAS HIDRONEFROSIS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "5c8eef7d-e7db-1867-d379-3e34237f5b5d",
        "NOMBRE": "OTRAS HIPERFENILALANINEMIAS"
    },
    {
        "id": "8aa4a748-2f0b-3ed9-0adb-bb76f09249d5",
        "NOMBRE": "OTRAS HIPERFUNCIONES DE LA GLÁNDULA HIPÓFISIS"
    },
    {
        "id": "8f594cb9-f26b-ef5a-cb63-9ecafd199d98",
        "NOMBRE": "OTRAS HIPERSECRECIONES DE HORMONAS INTESTINALES"
    },
    {
        "id": "8eb5c29d-501e-e8b0-1d46-e777e752db7b",
        "NOMBRE": "OTRAS HIPERTENSIONES PULMONARES SECUNDARIAS"
    },
    {
        "id": "907ef935-bdec-1e9f-a952-5bc96c705da6",
        "NOMBRE": "OTRAS HIPERTRICOSIS"
    },
    {
        "id": "0a9ff715-40ae-a854-59a6-e2064dd43f28",
        "NOMBRE": "OTRAS HIPOACUSIAS"
    },
    {
        "id": "12b282e8-2309-6f75-1836-161a51941910",
        "NOMBRE": "OTRAS HIPOACUSIAS ESPECIFICADAS"
    },
    {
        "id": "5e8737c1-c9de-9d7c-e97d-986a37abcf1d",
        "NOMBRE": "OTRAS HIPOGLICEMIAS"
    },
    {
        "id": "23c2425b-b4f8-2c24-68a2-03477b2d88a6",
        "NOMBRE": "OTRAS HIPOGLICEMIAS NEONATALES"
    },
    {
        "id": "b71f43b8-8249-90d2-900f-910c70d01d9d",
        "NOMBRE": "OTRAS HIPOSPADIAS"
    },
    {
        "id": "910448ce-7446-8e34-3da7-f511092035e1",
        "NOMBRE": "OTRAS HIPOTERMIAS DEL RECIÉN NACIDO"
    },
    {
        "id": "ea348d8b-cecc-4891-92bf-5c41ef79fd01",
        "NOMBRE": "OTRAS ICTIOSIS CONGÉNITAS"
    },
    {
        "id": "fa853a45-d030-4dd2-6f23-9eda8bdd7f2f",
        "NOMBRE": "OTRAS INCONTINENCIAS URINARIAS ESPECIFICADAS"
    },
    {
        "id": "ba62f863-a39d-e4c2-f1fc-226621647761",
        "NOMBRE": "OTRAS INERCIAS UTERINAS"
    },
    {
        "id": "fd63d65f-f1c4-fa09-7535-57060d39f2f8",
        "NOMBRE": "OTRAS INESTABILIDADES ARTICULARES"
    },
    {
        "id": "2d5db056-a340-ed3e-cb49-35d9c3fe8e50",
        "NOMBRE": "OTRAS INFECCIONES AGUDAS DE SITIOS MÚLTIPLES DE LAS VÍAS RESPIRATORIAS SUPERIORES"
    },
    {
        "id": "29360e7b-dca9-f04e-de51-e818125ce609",
        "NOMBRE": "OTRAS INFECCIONES BACTERIANAS DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "6ca7c769-68dd-089d-95ba-f79ba4a8a02e",
        "NOMBRE": "OTRAS INFECCIONES CAUSADAS POR ESPIROQUETAS"
    },
    {
        "id": "dda64b94-09ec-e6b6-1ff3-d348f5e8b10a",
        "NOMBRE": "OTRAS INFECCIONES CON UN MODO DE TRANSMISIÓN PREDOMINANTEMENTE SEXUAL QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO"
    },
    {
        "id": "8a15d662-1f38-d72f-b07a-80be4a7624c7",
        "NOMBRE": "OTRAS INFECCIONES DE LAS VÍAS GENITOURINARIAS CONSECUTIVAS AL PARTO"
    },
    {
        "id": "f863bdc9-558a-6092-b79f-b0ed5907a230",
        "NOMBRE": "OTRAS INFECCIONES DE VINCENT"
    },
    {
        "id": "e3729393-7b1b-9b74-622a-21ec2c0230e6",
        "NOMBRE": "OTRAS INFECCIONES DEBIDAS A CESTODOS"
    },
    {
        "id": "7d976e2c-8d0f-4425-72db-b7528d0313e8",
        "NOMBRE": "OTRAS INFECCIONES DEBIDAS A CESTODOS ESPECIFICADAS"
    },
    {
        "id": "a4e5b16f-88fc-cfe3-a216-cdfcd9ec5357",
        "NOMBRE": "OTRAS INFECCIONES DEBIDAS A ORTOPOXVIRUS"
    },
    {
        "id": "33818691-2fe6-986c-f6fa-bd4e6db62e69",
        "NOMBRE": "OTRAS INFECCIONES DEBIDAS A SALMONELLA"
    },
    {
        "id": "32c327b2-007a-764b-6528-83246d64dbc7",
        "NOMBRE": "OTRAS INFECCIONES DEBIDAS A TREMATODOS"
    },
    {
        "id": "f094ae20-c2c9-ced4-97f6-ebfdfcef3b58",
        "NOMBRE": "OTRAS INFECCIONES DEL SISTEMA NERVIOSO CENTRAL POR VIRUS ATÍPICO"
    },
    {
        "id": "60ff3216-b2ec-a34b-4e2a-fa40932fc764",
        "NOMBRE": "OTRAS INFECCIONES DURANTE EL TRABAJO DE PARTO"
    },
    {
        "id": "9f2ebeb8-7ee1-7d93-0802-004925244732",
        "NOMBRE": "OTRAS INFECCIONES ESPECIFICADAS COMO DEBIDAS A SALMONELLA"
    },
    {
        "id": "a8091f39-ed97-b020-4715-13eb93936d22",
        "NOMBRE": "OTRAS INFECCIONES ESPECIFICADAS DEBIDAS A TREMATODOS"
    },
    {
        "id": "03c05559-0393-edb0-430b-2b82e8e23ef9",
        "NOMBRE": "OTRAS INFECCIONES ESPECIFICADAS POR ESPIROQUETAS"
    },
    {
        "id": "dbf75603-0817-98ab-c2bb-9e22a3e47fcd",
        "NOMBRE": "OTRAS INFECCIONES ESPECIFICADAS PROPIAS DEL PERÍODO PERINATAL"
    },
    {
        "id": "5b085904-ddfc-6a28-4f22-10b67b36c714",
        "NOMBRE": "OTRAS INFECCIONES ESPECIFICAS DEL PERÍODO PERINATAL"
    },
    {
        "id": "f273de11-4871-8809-c96f-a88564b1ed11",
        "NOMBRE": "OTRAS INFECCIONES GENITALES CONSECUTIVAS AL PARTO"
    },
    {
        "id": "231200d7-46f8-7e23-e2ba-1ceb65133480",
        "NOMBRE": "OTRAS INFECCIONES GONOCÓCICAS"
    },
    {
        "id": "df678ad4-70a2-fed3-be7e-6fa6c17adcc8",
        "NOMBRE": "OTRAS INFECCIONES INTESTINALES BACTERIANAS"
    },
    {
        "id": "242bdc7c-0d17-5627-ddd5-0f2ad3d83651",
        "NOMBRE": "OTRAS INFECCIONES INTESTINALES BACTERIANAS ESPECIFICADAS"
    },
    {
        "id": "038728fe-56c9-ef84-763f-94e3d83b7b0b",
        "NOMBRE": "OTRAS INFECCIONES INTESTINALES DEBIDAS A ESCHERICHIA COLI"
    },
    {
        "id": "796caa4b-7eb1-08c5-0a77-c20cce733d7c",
        "NOMBRE": "OTRAS INFECCIONES INTESTINALES ESPECIFICADAS"
    },
    {
        "id": "ce3c0f97-3dce-62a0-fffd-378b3ce2e102",
        "NOMBRE": "OTRAS INFECCIONES LOCALES DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "f0419af5-b870-7802-55e7-522da815381f",
        "NOMBRE": "OTRAS INFECCIONES LOCALES ESPECIFICADAS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "6a8dcb43-3f03-558b-8208-3c62aab9626b",
        "NOMBRE": "OTRAS INFECCIONES POR MICOBACTERIAS"
    },
    {
        "id": "59404c14-a7d1-3d2f-652d-60c9be50f3b0",
        "NOMBRE": "OTRAS INFECCIONES PUERPERALES"
    },
    {
        "id": "08aa8d05-f32e-9108-dc7c-88b84f0a485b",
        "NOMBRE": "OTRAS INFECCIONES PUERPERALES ESPECIFICADAS"
    },
    {
        "id": "75aca8ed-d81d-90ea-3715-007cae77c3a9",
        "NOMBRE": "OTRAS INFECCIONES VIRALES DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "f352431e-2d38-0ec7-a406-aad10b3b54d6",
        "NOMBRE": "OTRAS INFECCIONES VIRALES DEL SISTEMA NERVIOSO CENTRAL, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0c0cd5a6-dd21-180c-5cfd-4cb7be29862f",
        "NOMBRE": "OTRAS INFECCIONES VIRALES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "df51b270-7fd4-bd1d-e6b9-05c7fe2955c5",
        "NOMBRE": "OTRAS INFECCIONES VIRALES ESPECIFICADAS, CARACTERIZADAS POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS"
    },
    {
        "id": "e5af4d2f-f884-924a-6a9d-96eb2c4c88c6",
        "NOMBRE": "OTRAS INFECCIONES Y LAS NO ESPECIFICADAS DE LAS VÍAS GENITOURINARIAS EN EL EMBARAZO"
    },
    {
        "id": "f8888b55-baff-90d8-db20-1cfdb11a0407",
        "NOMBRE": "OTRAS INFECIONES VÍRICAS CARACTERIZADAS POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "eb1834fe-5db9-098b-e6a2-6157cea311cd",
        "NOMBRE": "OTRAS INFESTACIONES"
    },
    {
        "id": "73dc5cd9-843d-c14e-98d5-0d346ea4cf3f",
        "NOMBRE": "OTRAS INFESTACIONES DEBIDAS A ARTRÓPODOS"
    },
    {
        "id": "16b4810a-cd8a-a3a2-78c0-0669600a2a93",
        "NOMBRE": "OTRAS INFESTACIONES ESPECIFICADAS"
    },
    {
        "id": "3ac94398-1376-62c8-a3d9-90bfd9f522e7",
        "NOMBRE": "OTRAS INFLAMACIONES DEL PÁRPADO"
    },
    {
        "id": "d5b3cb8b-6af9-00ed-4a2c-4d9c305f3590",
        "NOMBRE": "OTRAS INFLAMACIONES ESPECIFICADAS DE LA VAGINA Y DE LA VULVA"
    },
    {
        "id": "c11e61dd-c314-3bc7-ac90-84d63368a791",
        "NOMBRE": "OTRAS INFLAMACIONES ESPECIFICADAS DEL PÁRPADO"
    },
    {
        "id": "f86a8a33-0bb3-95a6-3c80-afccabb119b6",
        "NOMBRE": "OTRAS INMUNODEFICIENCIAS"
    },
    {
        "id": "6db53b04-5bca-fb97-e293-f4cde4d92223",
        "NOMBRE": "OTRAS INMUNODEFICIENCIAS COMBINADAS"
    },
    {
        "id": "f566ccae-944f-bf8b-3202-d297e5cea8f2",
        "NOMBRE": "OTRAS INMUNODEFICIENCIAS CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS"
    },
    {
        "id": "667ca902-d0b1-a726-8110-9455780f3f32",
        "NOMBRE": "OTRAS INMUNODEFICIENCIAS ESPECIFICADAS"
    },
    {
        "id": "5b3217c7-6223-38e6-6a6d-9885c18ae3b5",
        "NOMBRE": "OTRAS INMUNODEFICIENCIAS VARIABLES COMUNES"
    },
    {
        "id": "d4c587f3-5a9e-918d-86eb-9a18a7bd4b3a",
        "NOMBRE": "OTRAS INSUFICIENCIAS CORTICOSUPRARRENALES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "92b3c676-dce9-4503-ffd2-d9652de4dd5c",
        "NOMBRE": "OTRAS INSUFICIENCIAS RENALES AGUDAS"
    },
    {
        "id": "c5b63126-f542-20ed-accc-7107af0aa41c",
        "NOMBRE": "OTRAS INSUFICIENCIAS RENALES CRÓNICAS"
    },
    {
        "id": "4dfb55e6-9f61-5ae7-aa9e-50330e0045fb",
        "NOMBRE": "OTRAS INTOXICACIONES ALIMENTARIAS BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "245056eb-e306-2b46-1d30-507c06d0f1e3",
        "NOMBRE": "OTRAS INTOXICACIONES ALIMENTARIAS DEBIDAS A BACTERIAS ESPECIFICADAS"
    },
    {
        "id": "b9b61078-8c5c-06fe-4c5c-6b9c49b63145",
        "NOMBRE": "OTRAS IRIDOCICLITIS ESPECIFICADAS"
    },
    {
        "id": "5114049d-700b-4dc6-82b8-47648efdcdf1",
        "NOMBRE": "OTRAS ISQUEMIAS CEREBRALES TRANSITORIAS Y SÍNDROMES AFINES"
    },
    {
        "id": "81b4376a-2379-c492-a70d-b6602dd61c00",
        "NOMBRE": "OTRAS LESIONES ARTICULARES ESPECÍFICAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c03fa15c-2421-435d-ead0-c346897d815b",
        "NOMBRE": "OTRAS LESIONES BIOMECÁNICAS"
    },
    {
        "id": "4819a083-e0fa-25bc-a432-5be290fb5292",
        "NOMBRE": "OTRAS LESIONES DEL HOMBRO"
    },
    {
        "id": "64aef5c8-0176-4074-7cb4-b037d8f0a945",
        "NOMBRE": "OTRAS LESIONES DEL NERVIO MEDIANO"
    },
    {
        "id": "ce8eddc6-5734-1593-f826-4f23f3b683d4",
        "NOMBRE": "OTRAS LESIONES ESPECIFICADAS DEL ENCÉFALO, DEBIDAS A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "89807c9c-1c77-7542-bf3b-1a94a7994240",
        "NOMBRE": "OTRAS LESIONES PRECOCES DE LA PIEL EN LA FRAMBESIA"
    },
    {
        "id": "0798c952-4f5a-ff9f-304f-58dde0407375",
        "NOMBRE": "OTRAS LESIONES Y LAS NO ESPECIFICADAS DE LA MUCOSA BUCAL"
    },
    {
        "id": "51696532-f161-cc18-ac31-40b6f6e40af2",
        "NOMBRE": "OTRAS LEUCEMIAS DE CÉLULAS DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "ec84c637-c0a1-da43-660d-65ced5972ee8",
        "NOMBRE": "OTRAS LEUCEMIAS DE TIPO CELULAR ESPECIFICADO"
    },
    {
        "id": "e91adbda-d65e-0694-0c62-9996161eae35",
        "NOMBRE": "OTRAS LEUCEMIAS ESPECIFICADAS"
    },
    {
        "id": "79d6f098-9842-e3da-fddb-13ba80b52560",
        "NOMBRE": "OTRAS LEUCEMIAS LINFOIDES"
    },
    {
        "id": "31cfe19f-3740-6dd6-e3f4-3a92b63b143a",
        "NOMBRE": "OTRAS LEUCEMIAS MIELOIDES"
    },
    {
        "id": "d8877af8-9f7d-c6b6-6c3c-29f46283815e",
        "NOMBRE": "OTRAS LEUCEMIAS MONOCÍTICAS"
    },
    {
        "id": "efc469d3-b5d6-ca35-cb26-695065650b8e",
        "NOMBRE": "OTRAS LINFADENITIS INESPECÍFICAS"
    },
    {
        "id": "add619d4-198a-fe55-5e4d-d772ad8962fa",
        "NOMBRE": "OTRAS LORDOSIS"
    },
    {
        "id": "176596c5-e5b5-6859-bbbb-0e57dd3ff37d",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA AORTA"
    },
    {
        "id": "30a5d145-a1ec-7eb7-e4f7-2c19fab164e4",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA ARTERIA PULMONAR"
    },
    {
        "id": "3d4ab3a8-ec44-b40e-bcc2-06222a55d16b",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA ARTERIA RENAL"
    },
    {
        "id": "68577949-0731-bd20-6902-355626aad790",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA BOCA"
    },
    {
        "id": "f4504ce6-4be4-4efd-2e1b-ae4d791f7a8f",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA CÓRNEA"
    },
    {
        "id": "cee9e9d8-daae-8b20-98f2-b36d339ef2fa",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA FARINGE"
    },
    {
        "id": "0010ee3b-8740-8272-2d2b-8767e1d036ae",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA LARINGE"
    },
    {
        "id": "3592a3d0-ca9a-20de-9bc5-1417bd2b09c1",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA LENGUA"
    },
    {
        "id": "becdf47e-03ec-372f-0d82-0285d6c68ad3",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA LENGUA, DE LA BOCA Y DE LA FARINGE"
    },
    {
        "id": "ba041459-2826-abd8-055c-58e8b78d202a",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA MAMA"
    },
    {
        "id": "7b5aa77e-d681-3f26-debe-99af6d05f6d4",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA MÉDULA ESPINAL"
    },
    {
        "id": "c7ddac0d-06c7-3de1-f248-9aeb8648121d",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA NARIZ"
    },
    {
        "id": "fe631363-d105-95d3-f931-07be1b2f91a1",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA PARED ABDOMINAL"
    },
    {
        "id": "7162a2ef-e002-1adb-e8a4-e6fa88b2605f",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA PARTE SUPERIOR DEL TUBO DIGESTIVO"
    },
    {
        "id": "dd103d1b-3fb9-4f06-f4c4-b218dedef0cd",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA PARTE SUPERIOR DEL TUBO DIGESTIVO"
    },
    {
        "id": "104a652e-7c9d-ecaa-53f1-fa065fba0503",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA PIEL"
    },
    {
        "id": "dacd934f-21ad-c939-9fa7-97a23bf7ee28",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA PIEL, ESPECIFICADAS"
    },
    {
        "id": "b2c6160c-3a28-596a-c2ec-766b7b753309",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA TRÁQUEA"
    },
    {
        "id": "e6b66c9a-c2b9-799a-49b5-8a1da43fd65b",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO"
    },
    {
        "id": "942fbf5b-bf2a-173c-0dfb-f69214a71fbc",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA VAGINA"
    },
    {
        "id": "ac9872e3-b9e1-76c4-bb44-b12fe9b61454",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "d9a73a29-b595-d3ac-c7af-317e5f4ca998",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA VÁLVULA TRICÚSPIDE"
    },
    {
        "id": "6654bebb-c653-4bd9-ae63-1f5f0afc1b26",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA VEJIGA Y DE LA URETRA"
    },
    {
        "id": "21fe8dc4-c3f2-f5bb-8252-913d6a131a28",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA VESÍCULA BILIAR"
    },
    {
        "id": "1c4da7ff-dc8c-0bab-d276-7cddc58c1370",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LA VULVA"
    },
    {
        "id": "8297428e-07a5-7da0-9cbf-fde12325298e",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS CÁMARAS CARDÍACAS Y SUS CONEXIONES"
    },
    {
        "id": "93060399-9acf-dcca-72db-bcc2ddcdc796",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS COSTILLAS"
    },
    {
        "id": "e636817d-75ce-c2e3-a88f-d4581501df39",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS FANERAS"
    },
    {
        "id": "1e914d2e-3f32-5cb1-6105-bad36da9675f",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS FANERAS, ESPECIFICADAS"
    },
    {
        "id": "274781a3-3914-608f-f236-803b9e1f4e06",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS GRANDES ARTERIAS"
    },
    {
        "id": "391a7746-9b0e-562a-a9d1-af195e8de7f2",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS GRANDES VENAS"
    },
    {
        "id": "fa82cdfb-7964-239a-feda-f08b08a920ba",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS UÑAS"
    },
    {
        "id": "5c574e3b-18d5-51d8-c860-ac02041f8ab6",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LAS VÁLVULAS AÓRTICA Y MITRAL"
    },
    {
        "id": "5c2e4892-9e6a-6da6-ab0f-6bd9d461371f",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS BRONQUIOS"
    },
    {
        "id": "cb4ad731-0d52-0c26-8ab3-714d817ab33a",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS CONDUCTOS BILIARES"
    },
    {
        "id": "7a58bc9f-0b1a-5d07-1ff3-c8630a7a4c17",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS CONDUCTOS DEFERENTES, DEL EPIDÍDIMO, DE LAS VESÍCULAS SEMINALES Y DE LA PRÓSTATA"
    },
    {
        "id": "1e09a3fb-8426-c89e-03d9-e171ef89d6d7",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS HUESOS DEL CRÁNEO Y DE LA CARA"
    },
    {
        "id": "813b9168-fd0c-a7b7-7d4c-1d3c32eaa0bf",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS ÓRGANOS GENITALES FEMENINOS"
    },
    {
        "id": "52b060f8-1c0e-490b-2996-bd5fcdb8b936",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS ÓRGANOS GENITALES FEMENINOS, ESPECIFICADAS"
    },
    {
        "id": "0b3cae0e-29c0-195a-d235-f3bfe19be0aa",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "51784120-1b8f-b747-fae5-c948dfe68961",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS ÓRGANOS GENITALES MASCULINOS, ESPECIFICADAS"
    },
    {
        "id": "4bc4114e-460e-3c15-8611-2d3dfd6d66d2",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS OVARIOS"
    },
    {
        "id": "d2107791-7209-31a5-eb85-d5cd1d5acb2a",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS PÁRPADOS"
    },
    {
        "id": "ecc44eed-ac98-a90b-71a0-14ac3f67dfee",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS TABIQUES CARDÍACOS"
    },
    {
        "id": "6cf91f32-471c-f311-11a5-f8dc39736bea",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DE LOS TESTÍCULOS Y DEL ESCROTO"
    },
    {
        "id": "cfe615f1-bac4-0436-c55a-cd7a5080d5ef",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL (DE LOS) MIEMBRO(S) INFERIOR(ES), INCLUIDA LA CINTURA PELVIANA"
    },
    {
        "id": "b489011e-0895-868d-1cb4-90a4ab23349f",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES), INCLUIDA LA CINTURA ESCAPULAR"
    },
    {
        "id": "1e2820d6-40ee-824a-43cd-d2b44f2a9927",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL APARATO LAGRIMAL"
    },
    {
        "id": "c7ede554-b924-9e75-f5ee-9859f85d6486",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL APARATO URINARIO, ESPECIFICADAS"
    },
    {
        "id": "1c717043-ae4f-8d7f-140f-558bfdc7e688",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL CORAZÓN"
    },
    {
        "id": "cc458ac2-0c3b-7caf-c6fd-9f98e656a33d",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL CORAZÓN, ESPECIFICADAS"
    },
    {
        "id": "4e0c9e21-2237-445f-696e-1745d86dc970",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL CRISTALINO"
    },
    {
        "id": "094ecdfd-7b2a-c201-143a-626d8bdb06eb",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL DIAFRAGMA"
    },
    {
        "id": "8f1d4eaa-439d-4ac8-a571-aa324e44f8ce",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL ENCÉFALO"
    },
    {
        "id": "974a361f-07ae-f1a7-5adb-922b115f9c38",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL ENCÉFALO, ESPECIFICADAS"
    },
    {
        "id": "6a114764-2ea4-922e-de7e-b8fb6402d6db",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL ESÓFAGO"
    },
    {
        "id": "f0359ebd-bd45-0cf8-41b1-8c3eae50307d",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL ESTÓMAGO, ESPECIFICADAS"
    },
    {
        "id": "d852425d-ec42-8a7e-ffb1-50813781ebb2",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL HÍGADO"
    },
    {
        "id": "1836ac29-ae9b-e43a-cf8f-2cba494e2dc1",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL INTESTINO"
    },
    {
        "id": "6f876125-e28b-1dd4-adf8-422791271828",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL INTESTINO, ESPECIFICADAS"
    },
    {
        "id": "7e54a208-8885-a6f2-32de-758e527feddd",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL IRIS"
    },
    {
        "id": "2d26c16c-5d62-1c55-9d30-b3f0352ede4a",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL LA CARA Y DEL CUELLO"
    },
    {
        "id": "94ea6bdf-474d-865f-d98d-7e83cc5e3402",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL OÍDO"
    },
    {
        "id": "04b8824e-339c-3b57-e94c-38185cb6df24",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL OÍDO MEDIO"
    },
    {
        "id": "cb06fe7a-ba97-8aab-5005-6a09c130d500",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL OÍDO, ESPECIFICADAS"
    },
    {
        "id": "68c57ffe-6818-0c66-b9d0-cc0c7ed2eae0",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL OJO"
    },
    {
        "id": "32b43f42-8baa-d6a7-ba2e-4e353266f9f4",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL OJO, ESPECIFICADAS"
    },
    {
        "id": "c6cb8623-b62a-ddcc-a5c4-0664eaea853a",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL PÁNCREAS Y DEL CONDUCTO PANCREÁTICO"
    },
    {
        "id": "e3161b0e-9635-cb40-0c1c-471658254a59",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL PELO"
    },
    {
        "id": "23a3a392-ad31-74e5-fc7b-754efc51aee5",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL PENE"
    },
    {
        "id": "cbb6c946-e403-1765-f923-92b49db82eef",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL PULMÓN"
    },
    {
        "id": "e53f663e-27eb-95ca-7a59-1f51fc798ba0",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL RIÑÓN"
    },
    {
        "id": "aafadbc0-ddc6-b9e2-d59b-5aed7ddd8d80",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL RIÑÓN, ESPECIFICADAS"
    },
    {
        "id": "e484b4b5-fbd5-bf87-1ca3-40c81d379cb6",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SEGMENTO ANTERIOR DEL OJO"
    },
    {
        "id": "ecaae592-a6a1-8507-48cb-0e028fdb04b9",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SEGMENTO POSTERIOR DEL OJO"
    },
    {
        "id": "baab2d74-cd93-32e6-2334-ff918b19d607",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA CIRCULATORIO"
    },
    {
        "id": "2a387643-4f4d-6694-0bfe-705a8ba9189d",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA CIRCULATORIO, ESPECIFICADAS"
    },
    {
        "id": "561c9ce1-272b-7791-392f-a31150a84b3d",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "1a229bb1-928f-a31b-1e1d-68853d59503b",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA DIGESTIVO, ESPECIFICADAS"
    },
    {
        "id": "de0178b3-adb0-6aa3-374f-5685aea3a32b",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA NERVIOSO"
    },
    {
        "id": "2b0df14a-6107-456f-db73-6be0ae931612",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA NERVIOSO, ESPECIFICADAS"
    },
    {
        "id": "7606eef6-a0e0-4fcb-a8ed-e622f4afc4e1",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA NERVIOSO, NO ESPECIFICADA"
    },
    {
        "id": "437cfb44-01a8-2882-ce70-5a61395bfe1a",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA OSTEOMUSCULAR"
    },
    {
        "id": "31f32a5e-100a-78be-89aa-0656495fde20",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA RESPIRATORIO"
    },
    {
        "id": "d0482a70-772a-fabe-e9ab-4b15e5c6e433",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA URINARIO"
    },
    {
        "id": "21238397-000c-2e4d-d63a-67acb410fa5f",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA VASCULAR PERIFÉRICO"
    },
    {
        "id": "79ec406f-a2b2-4044-5f99-c7c80956146b",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL SISTEMA VASCULAR PERIFÉRICO, ESPECIFICADAS"
    },
    {
        "id": "9ed77b80-34fe-4fbb-b01e-6b15ddce4805",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL TÓRAX ÓSEO"
    },
    {
        "id": "233e922d-6b79-bd08-8991-0c00b69e6300",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL URÉTER"
    },
    {
        "id": "cf851f2a-5842-e530-8fdf-b4b2a9dd1e4d",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS DEL ÚTERO Y DEL CUELLO UTERINO"
    },
    {
        "id": "8ae33a6e-877e-3b92-9533-e42841380193",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS ESPECIFICADAS DE CARA Y CUELLO"
    },
    {
        "id": "36d269db-4e9c-60df-e31e-21a30b6667bb",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS ESPECIFICADAS DE LA MÉDULA ESPINAL"
    },
    {
        "id": "ace2c01c-f82f-d834-46fe-e02b94894e95",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS ESPECIFICADAS DE LOS HUESOS DEL CRÁNEO Y DE LA CARA"
    },
    {
        "id": "f4855cae-8cd4-457e-e0c5-d1185bff71ff",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS ESPECIFICADAS DEL (DE LOS) MIEMBRO(S)"
    },
    {
        "id": "8434cef2-6135-a8b6-207a-282b00760e6e",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS ESPECIFICADAS DEL SISTEMA RESPIRATORIO"
    },
    {
        "id": "f1b81b6b-148a-ee5e-eab9-33566651de80",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS, ESPECIFICADAS"
    },
    {
        "id": "9cb72583-1996-6727-c307-cef7a41f0acf",
        "NOMBRE": "OTRAS MALFORMACIONES CONGÉNITAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9951b621-8b60-ae37-ba19-1bb1634e2ca4",
        "NOMBRE": "OTRAS MALFORMACIONES DE LAS HENDIDURAS BRANQUIALES"
    },
    {
        "id": "d575f090-0688-961c-1303-43f99961d7ee",
        "NOMBRE": "OTRAS MALFORMACIONES DE LOS VASOS CEREBRALES"
    },
    {
        "id": "5c4f75bd-a675-29bf-ce8e-a51f889a81ba",
        "NOMBRE": "OTRAS MALFORMACIONES DE LOS VASOS PRECEREBRALES"
    },
    {
        "id": "cdd222f0-a63e-7d4e-e0f7-54babbe9cc1a",
        "NOMBRE": "OTRAS MANIFESTACIONES DE DEFICIENCIA DE VITAMINA A"
    },
    {
        "id": "a1af22df-1672-4e20-7554-b8fd466eb493",
        "NOMBRE": "OTRAS MANIFESTACIONES DE FRAMBESIA"
    },
    {
        "id": "7fe22509-842c-3408-8ea9-482a9d7b9b55",
        "NOMBRE": "OTRAS MANIFESTACIONES DE LA DEFICIENCIA DE TIAMINA"
    },
    {
        "id": "f68fe9c9-8c9b-9860-cd4c-5b943e9be210",
        "NOMBRE": "OTRAS MANIFESTACIONES OCULARES DE DEFICIENCIA DE VITAMINA A"
    },
    {
        "id": "7147eb66-cfe1-9ac1-b8fe-84f183844939",
        "NOMBRE": "OTRAS MASTOIDITIS Y AFECCIONES RELACIONADAS"
    },
    {
        "id": "7514e104-6c47-a633-3987-7e71952a94a1",
        "NOMBRE": "OTRAS MEDIDAS PROFILÁCTICAS ESPECIFICADAS"
    },
    {
        "id": "675650be-fce9-fe67-e5f0-a95d8feccc2f",
        "NOMBRE": "OTRAS MELIOIDOSIS"
    },
    {
        "id": "c60104dd-b606-ec5d-d5f3-14732a15ce8e",
        "NOMBRE": "OTRAS MENINGITIS BACTERIANAS"
    },
    {
        "id": "b4e99a95-b865-db68-3e48-b7031f11c77c",
        "NOMBRE": "OTRAS MENINGITIS VIRALES"
    },
    {
        "id": "df8a72b9-aa37-d519-3625-37602a8da87f",
        "NOMBRE": "OTRAS MENSTRUACIONES IRREGULARES ESPECIFICADAS"
    },
    {
        "id": "6bffd274-d1a5-9380-5899-45ec9e495337",
        "NOMBRE": "OTRAS METAHEMOGLOBINEMIAS"
    },
    {
        "id": "24e7835e-e7dd-6e81-cb42-962df45db82c",
        "NOMBRE": "OTRAS MICOSIS ESPECIFICADAS"
    },
    {
        "id": "984eb3e3-5fa6-fdbe-c2a7-200ad536762b",
        "NOMBRE": "OTRAS MICOSIS SUPERFICIALES"
    },
    {
        "id": "b00e4fa6-7ef2-7e3e-6666-83e1bac6d9cf",
        "NOMBRE": "OTRAS MICOSIS SUPERFICIALES ESPECIFICADAS"
    },
    {
        "id": "626ef90c-a1eb-b167-5d89-7f4cc5b4a94a",
        "NOMBRE": "OTRAS MICOSIS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "26a9ea87-09e5-98c5-35d5-f8c9f25a255e",
        "NOMBRE": "OTRAS MIGRAÑAS"
    },
    {
        "id": "f7e2234d-408f-f829-c948-78c827babdfe",
        "NOMBRE": "OTRAS MIOCARDITIS AGUDAS"
    },
    {
        "id": "266fe5a8-3648-1f74-6a8e-55bdac4af9b1",
        "NOMBRE": "OTRAS MIOPATÍAS"
    },
    {
        "id": "be15c752-ffe5-5457-8223-e0a3f243fe3d",
        "NOMBRE": "OTRAS MIOPATÍAS ESPECIFICADAS"
    },
    {
        "id": "b1fe1c18-88fd-0cc8-c6fb-3b3299436d94",
        "NOMBRE": "OTRAS MIOSITIS"
    },
    {
        "id": "bc87adf7-81a9-1a79-3b9e-1cc3cf725dd4",
        "NOMBRE": "OTRAS MONONEUROPATÍAS"
    },
    {
        "id": "9b47aa65-1737-423d-0fb2-3d8e47c50c54",
        "NOMBRE": "OTRAS MONONEUROPATÍAS DEL MIEMBRO INFERIOR"
    },
    {
        "id": "513a312d-1086-5b8e-a57d-7bbee0c51a09",
        "NOMBRE": "OTRAS MONONEUROPATÍAS DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "268be2ac-b1d2-09a0-d075-088c3fb0a2db",
        "NOMBRE": "OTRAS MONONEUROPATÍAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a48cd773-51b5-07ae-27f8-55103a146e49",
        "NOMBRE": "OTRAS MONONEUROPATÍAS ESPECIFICADAS"
    },
    {
        "id": "5b3bf85c-4c49-939e-ed52-0423fcc98381",
        "NOMBRE": "OTRAS MONONUCLEOSIS INFECCIOSAS"
    },
    {
        "id": "c2d87e67-a2ce-477a-8895-01d28ebd2840",
        "NOMBRE": "OTRAS MUCOPOLISACARIDOSIS"
    },
    {
        "id": "dd059535-5d53-0930-3508-b83b062ab52e",
        "NOMBRE": "OTRAS MUERTES SÚBITAS DE CAUSA DESCONOCIDA"
    },
    {
        "id": "a21ce79c-92e3-e724-d0c0-79b03d98057e",
        "NOMBRE": "OTRAS NEFRITIS TUBULOINTERSTICIALES CRÓNICAS"
    },
    {
        "id": "7d64d2a6-4493-45d4-22e0-32e96f085037",
        "NOMBRE": "OTRAS NEUMONÍAS BACTERIANAS"
    },
    {
        "id": "d60a4c8e-9434-d24a-9f19-b8bb6e2dce59",
        "NOMBRE": "OTRAS NEUMONÍAS, DE MICROORGANISMO NO ESPECIFICADO"
    },
    {
        "id": "b84850d0-1606-2949-b1ed-1510e740aa93",
        "NOMBRE": "OTRAS NEUROPATÍAS HEREDITARIAS E IDIOPÁTICAS"
    },
    {
        "id": "e361a812-aeeb-79c5-eb90-0ebd18d3e7e3",
        "NOMBRE": "OTRAS OBSTRUCCIONES DEL INTESTINO"
    },
    {
        "id": "a74d7a59-6e6b-d221-ddc8-f5dd06cc1d90",
        "NOMBRE": "OTRAS OBSTRUCCIONES DEL TRABAJO DE PARTO"
    },
    {
        "id": "97b1becb-5581-d4e3-e6db-e7ba69f6f5c7",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN"
    },
    {
        "id": "e7cc555c-cd61-ce63-248b-dfd7270a9228",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f6fd7950-c06f-3630-b54b-234625671aa4",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "03499d78-c1a6-7561-c974-d305193103a1",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "4b4a5c13-93f8-c27e-4062-f28819ef65ad",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "bd09fbfb-5ca4-3019-fc2f-17da9c670c84",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "8f1c75d2-4836-149e-cee4-ebafb0900f58",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, GRANJA"
    },
    {
        "id": "c1af2992-b8bb-483c-e322-a924f7edc5cd",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "47de740d-88cb-a62c-2195-05c036291f97",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "0e5ff165-8c3a-92cd-622f-943ecc38ad33",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "96abb3b7-d7d3-9d60-7d30-14445211229e",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACIÓN, VIVIENDA"
    },
    {
        "id": "30e555ce-bf02-95b7-0732-b9d8a747a5a7",
        "NOMBRE": "OTRAS OBSTRUCCIONES ESPECIFICADAS DEL TRABAJO DE PARTO"
    },
    {
        "id": "e94a12c2-75ed-9fb7-8d8b-6393b578b7f8",
        "NOMBRE": "OTRAS OBSTRUCCIONES INTESTINALES DEL RECIÉN NACIDO"
    },
    {
        "id": "2fe5aeef-cb56-64bf-a970-edb135c17514",
        "NOMBRE": "OTRAS OBSTRUCCIONES INTESTINALES ESPECIFICADAS DEL RECIÉN NACIDO"
    },
    {
        "id": "5a4540e9-d60a-92a2-d685-701302fdc7f4",
        "NOMBRE": "OTRAS OBSTRUCCIONES INTESTINALES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "20448529-4988-a304-e73a-5a162c91b033",
        "NOMBRE": "OTRAS OCLUSIONES VASCULARES RETINIANAS"
    },
    {
        "id": "81d31590-2f2a-80dd-7a96-3d2a6b4eff02",
        "NOMBRE": "OTRAS OPACIDADES CENTRALES DE LA CÓRNEA"
    },
    {
        "id": "b56d8560-b71c-f31f-2cea-43657a8bc34d",
        "NOMBRE": "OTRAS OPACIDADES O CICATRICES DE LA CÓRNEA"
    },
    {
        "id": "27aad49a-79bc-aa44-9005-338afa8d408d",
        "NOMBRE": "OTRAS OPACIDADES VÍTREAS"
    },
    {
        "id": "459dd28b-e19a-d393-278b-041417f12a58",
        "NOMBRE": "OTRAS OSIFICACIONES DEL MÚSCULO"
    },
    {
        "id": "9e626c9b-d90f-1dd1-7ee9-a7a568dd4704",
        "NOMBRE": "OTRAS OSTEOARTROPATIAS HIPERTRÓFICAS"
    },
    {
        "id": "02da00f5-72a2-8203-73aa-65fe8775cbc3",
        "NOMBRE": "OTRAS OSTEOCONDRODISPLASIAS"
    },
    {
        "id": "78652701-51d1-baf3-c3b4-d3004476d904",
        "NOMBRE": "OTRAS OSTEOCONDRODISPLASIAS CON DEFECTOS DEL CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "bd58ec10-028f-0954-e7a0-fbdf81457e8b",
        "NOMBRE": "OTRAS OSTEOCONDRODISPLASIAS ESPECIFICADAS"
    },
    {
        "id": "f42f0278-409b-6601-5ecb-38bb54eea90d",
        "NOMBRE": "OTRAS OSTEOCONDROPATÍAS"
    },
    {
        "id": "f58a0114-858b-7dc5-b95a-0761db9f5dbd",
        "NOMBRE": "OTRAS OSTEOCONDROPATÍAS ESPECIFICADAS"
    },
    {
        "id": "e71902d0-2c05-7716-fe7b-df5e8488578d",
        "NOMBRE": "OTRAS OSTEOCONDROSIS JUVENILES"
    },
    {
        "id": "ef741847-9e57-78e0-f83b-c71ccfad688c",
        "NOMBRE": "OTRAS OSTEOCONDROSIS JUVENILES DE LA CADERA Y DE LA PELVIS"
    },
    {
        "id": "a47715b7-9cf5-0904-8b8b-db6f883d0ef6",
        "NOMBRE": "OTRAS OSTEOCONDROSIS JUVENILES DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "093e30b5-56bb-62d4-dcbd-085e8ee4c457",
        "NOMBRE": "OTRAS OSTEOCONDROSIS JUVENILES ESPECIFICADAS"
    },
    {
        "id": "04737b01-1436-062b-40f5-a6b928d0065a",
        "NOMBRE": "OTRAS OSTEOMALACIAS DEL ADULTO"
    },
    {
        "id": "25a95f6b-4b43-b18c-e26c-c72f84d9c77e",
        "NOMBRE": "OTRAS OSTEOMALACIAS DEL ADULTO INDUCIDAS POR DROGAS"
    },
    {
        "id": "0c4eab6c-548c-859c-7971-6a7ec3eda5ca",
        "NOMBRE": "OTRAS OSTEOMIELITIS"
    },
    {
        "id": "1bd7741c-8f76-c009-ae13-83211b29991a",
        "NOMBRE": "OTRAS OSTEOMIELITIS AGUDAS"
    },
    {
        "id": "770bdad0-8557-f011-fd10-c8cdc556ffca",
        "NOMBRE": "OTRAS OSTEOMIELITIS CRÓNICAS"
    },
    {
        "id": "a0c12ba1-46ab-8998-70cf-380f303dfefe",
        "NOMBRE": "OTRAS OSTEOMIELITIS HEMATÓGENAS CRÓNICAS"
    },
    {
        "id": "fbb470d8-42d7-d380-a00d-af126a3f508c",
        "NOMBRE": "OTRAS OSTEONECROSIS"
    },
    {
        "id": "c551d100-350b-0a80-0b0f-7f8511d1040b",
        "NOMBRE": "OTRAS OSTEONECROSIS SECUNDARIAS"
    },
    {
        "id": "1d5ddfed-c0b6-ed7a-2ae2-ef22d98d333e",
        "NOMBRE": "OTRAS OSTEOPOROSIS, CON FRACTURA PATOLÓGICA"
    },
    {
        "id": "16865d7b-0ed2-9455-6522-e1fc181d5488",
        "NOMBRE": "OTRAS OSTEOPOROSIS, SIN FRACTURA PATOLÓGICA"
    },
    {
        "id": "9021e166-f0f4-c30d-50ec-118934d32739",
        "NOMBRE": "OTRAS OTITIS EXTERNAS"
    },
    {
        "id": "0fb0621b-f669-1cba-1159-666d75e9ccd0",
        "NOMBRE": "OTRAS OTITIS EXTERNAS INFECCIOSAS"
    },
    {
        "id": "94bab75f-91db-6674-846c-ad5c429c01a0",
        "NOMBRE": "OTRAS OTITIS MEDIAS CRÓNICAS NO SUPURATIVAS"
    },
    {
        "id": "db2e2a8a-2c46-f260-bbd8-7f532d76eaca",
        "NOMBRE": "OTRAS OTITIS MEDIAS CRÓNICAS SUPURATIVAS"
    },
    {
        "id": "88ba3438-698b-c1bb-3a41-e3bea3a5593d",
        "NOMBRE": "OTRAS OTOSCLEROSIS"
    },
    {
        "id": "81d677d5-09fd-f554-92a0-c1f4a554a6e9",
        "NOMBRE": "OTRAS PANCREATITIS AGUDAS"
    },
    {
        "id": "18a54623-199f-80c1-bc67-5fbc29779a18",
        "NOMBRE": "OTRAS PANCREATITIS CRÓNICAS"
    },
    {
        "id": "3dc7d218-a860-4239-6b82-0bf596a66b8a",
        "NOMBRE": "OTRAS PARAPSORIASIS"
    },
    {
        "id": "cc084fee-7526-8916-69a9-8b2cc27f45bc",
        "NOMBRE": "OTRAS PERCEPCIONES AUDITIVAS ANORMALES"
    },
    {
        "id": "11470f61-c013-8e47-d047-df3a27ecbbaa",
        "NOMBRE": "OTRAS PÉRDIDAS DE SANGRE FETAL"
    },
    {
        "id": "1cedd8f8-7c62-134b-7954-a9d7999f347c",
        "NOMBRE": "OTRAS PÉRDIDAS ESPECIFICADAS NO CICATRICIALES DEL PELO"
    },
    {
        "id": "c1865657-bf35-0919-4e69-8a4ab83ca86b",
        "NOMBRE": "OTRAS PERFORACIONES DE LA MEMBRANA TIMPÁNICA"
    },
    {
        "id": "0bffb23f-0dc9-e5f0-7d27-aaf21b0addeb",
        "NOMBRE": "OTRAS PERFORACIONES MARGINALES DE LA MEMBRANA TIMPÁNICA"
    },
    {
        "id": "94173b4f-8ad3-c4e8-fa77-ff6b952a47f3",
        "NOMBRE": "OTRAS PERITONITIS"
    },
    {
        "id": "4e5d720d-716e-b931-8630-dd6b30be38f3",
        "NOMBRE": "OTRAS PERITONITIS NEONATALES"
    },
    {
        "id": "857efdd1-5286-a791-37a7-2bb3e0449ff4",
        "NOMBRE": "OTRAS PESQUISAS PRENATALES BASADAS EN AMNIOCENTESIS"
    },
    {
        "id": "525c29d7-47cd-4349-22fd-7049203635af",
        "NOMBRE": "OTRAS PESQUISAS PRENATALES ESPECIFICAS"
    },
    {
        "id": "eb2851c0-dcd6-7e3c-36f0-2d0a6aba7517",
        "NOMBRE": "OTRAS POLIARTROSIS"
    },
    {
        "id": "54901883-1b78-4ea5-7f53-19afddd8e64e",
        "NOMBRE": "OTRAS POLINEUROPATÍAS"
    },
    {
        "id": "905f0453-2a22-d903-9776-ffd82c4d8308",
        "NOMBRE": "OTRAS POLINEUROPATÍAS ESPECIFICADAS"
    },
    {
        "id": "a1eb4f82-4c4c-29f1-5322-431a4b7c30c4",
        "NOMBRE": "OTRAS POLINEUROPATÍAS INFLAMATORIAS"
    },
    {
        "id": "d11aea52-fe2f-f29a-4cb8-1d096b0db096",
        "NOMBRE": "OTRAS POLIOMIELITIS AGUDAS PARALÍTICAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "715a9da7-fabb-e5a7-28aa-5be68832d9a2",
        "NOMBRE": "OTRAS PORFIRIAS"
    },
    {
        "id": "9943ce53-a648-d0b1-8d17-8b234196b70a",
        "NOMBRE": "OTRAS PSORIASIS"
    },
    {
        "id": "7160ad87-8e31-6b69-820d-a4879b5a2b3f",
        "NOMBRE": "OTRAS PÚRPURAS NO TROMBOCITOPÉNICAS"
    },
    {
        "id": "89cb5271-60e3-e17e-8974-b47550e70f0b",
        "NOMBRE": "OTRAS QUEMADURAS SOLARES"
    },
    {
        "id": "3cac252d-6540-83ee-c102-096a85b417a3",
        "NOMBRE": "OTRAS QUERATITIS"
    },
    {
        "id": "33252074-6e52-6884-0459-2b1ee51f3b45",
        "NOMBRE": "OTRAS QUERATITIS SUPERFICIALES SIN CONJUNTIVITIS"
    },
    {
        "id": "c00b0272-371f-5ec2-a930-99af5b99ccba",
        "NOMBRE": "OTRAS REACCIONES AL ESTRÉS GRAVE"
    },
    {
        "id": "b43eee91-7681-b860-7ed4-a8cd976eb2be",
        "NOMBRE": "OTRAS REACCIONES AL SUERO"
    },
    {
        "id": "c8726ec4-c541-7fa7-f14f-4b7cafa7fb93",
        "NOMBRE": "OTRAS RETINOPATÍAS PROLIFERATIVAS"
    },
    {
        "id": "c20d590c-0459-cf36-13e8-f8c998ec21c9",
        "NOMBRE": "OTRAS RICKETTSIOSIS"
    },
    {
        "id": "03fc04de-9ea1-082a-ff3e-4b43e01764b7",
        "NOMBRE": "OTRAS RICKETTSIOSIS ESPECIFICADAS"
    },
    {
        "id": "24753768-e677-225f-b4cc-cee81eef3c65",
        "NOMBRE": "OTRAS RINITIS ALÉRGICAS"
    },
    {
        "id": "e67cd11f-067e-a2a8-7d49-10949292cdc0",
        "NOMBRE": "OTRAS ROSÁCEAS"
    },
    {
        "id": "5863c98c-177e-0126-51a0-c56aab5699ab",
        "NOMBRE": "OTRAS SEPSIS"
    },
    {
        "id": "ba37d978-bde5-77ce-22b7-c465c535723b",
        "NOMBRE": "OTRAS SEPSIS ESPECIFICADAS"
    },
    {
        "id": "1ca40059-faaf-e628-50f0-567be54ab19f",
        "NOMBRE": "OTRAS SEPSIS ESTREPTOCÓCICAS"
    },
    {
        "id": "a7d64b6f-83ae-099e-830e-8e32aaf5daca",
        "NOMBRE": "OTRAS SHIGELOSIS"
    },
    {
        "id": "85391bf7-77b1-12a6-9e77-73a2d62183fa",
        "NOMBRE": "OTRAS SÍFILIS SECUNDARIAS"
    },
    {
        "id": "e27e7b94-7dab-56ca-5b38-a29f5dfd62cc",
        "NOMBRE": "OTRAS SÍFILIS TARDÍAS SINTOMÁTICAS"
    },
    {
        "id": "6398369b-861e-5478-3112-35123718f21e",
        "NOMBRE": "OTRAS SÍFILIS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "19904e1d-0314-52ff-5552-5c65778cd075",
        "NOMBRE": "OTRAS SINOVITIS Y TENOSINOVITIS"
    },
    {
        "id": "1ac5eaaf-49df-c3d7-b2bf-051624408de2",
        "NOMBRE": "OTRAS SINUSITIS AGUDAS"
    },
    {
        "id": "809b2ae3-761e-716f-db55-da31c80656b7",
        "NOMBRE": "OTRAS SINUSITIS CRÓNICAS"
    },
    {
        "id": "9512f357-0a07-d870-612f-385ba26bccfd",
        "NOMBRE": "OTRAS SUBLUXACIONES ATLANTO-AXOIDEAS RECURRENTES"
    },
    {
        "id": "15604930-e739-0966-1bad-09e22efb1f56",
        "NOMBRE": "OTRAS SUBLUXACIONES VERTEBRALES RECURRENTES"
    },
    {
        "id": "3be996ea-75e4-5a62-b571-a5ea5fb61f42",
        "NOMBRE": "OTRAS SUPRESIONES DE LOS AUTOSOMAS"
    },
    {
        "id": "f63a58dd-55fc-413a-3ee4-3b92b9beb376",
        "NOMBRE": "OTRAS SUPRESIONES DE PARTE DE UN CROMOSOMA"
    },
    {
        "id": "e6224c8e-41c9-ab69-97e9-4b1b639ea588",
        "NOMBRE": "OTRAS TALASEMIAS"
    },
    {
        "id": "db7b8497-1d30-7a19-3744-e87e701489a6",
        "NOMBRE": "OTRAS TERAPIAS FÍSICAS"
    },
    {
        "id": "c02369dd-828c-e655-e518-c64d4cd5ead9",
        "NOMBRE": "OTRAS TIROIDITIS CRÓNICAS"
    },
    {
        "id": "8dfb2297-a068-1112-2d72-c1262cd714e4",
        "NOMBRE": "OTRAS TIROTOXICOSIS"
    },
    {
        "id": "b54af5c9-57dd-5b6e-8af6-267b8878e54f",
        "NOMBRE": "OTRAS TRISOMÍAS Y TRISOMÍAS PARCIALES DE LOS AUTOSOMAS, ESPECIFICADAS"
    },
    {
        "id": "518af7c5-c7ae-2657-5c8c-1b59a9a8aa4b",
        "NOMBRE": "OTRAS TRISOMÍAS Y TRISOMÍAS PARCIALES DE LOS AUTOSOMAS, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "89c97e06-3676-6d51-cb7e-7c8281f0c36a",
        "NOMBRE": "OTRAS TROMBOCITOPENIAS PRIMARIAS"
    },
    {
        "id": "bad330be-e12e-9dbc-0615-407c4d32a9e9",
        "NOMBRE": "OTRAS TUBERCULOSIS DEL SISTEMA NERVIOSO"
    },
    {
        "id": "ac889801-8094-187d-f89f-309c5500dd45",
        "NOMBRE": "OTRAS TUBERCULOSIS MILIARES"
    },
    {
        "id": "ec512ea5-599f-f669-e02e-8d6a66d4b2db",
        "NOMBRE": "OTRAS TUBERCULOSIS RESPIRATORIAS, CONFIRMADAS BACTERIOLÓGICA E HISTOLÓGICAMENTE"
    },
    {
        "id": "8b4cc48b-a208-3db7-ae1f-13a6f62d209d",
        "NOMBRE": "OTRAS TUBERCULOSIS RESPIRATORIAS, SIN MENCIÓN DE CONFIRMACIÓN"
    },
    {
        "id": "88a75d52-dd5b-ec10-9e3f-1258878cb8c1",
        "NOMBRE": "OTRAS URETRITIS"
    },
    {
        "id": "86fe80ac-d7df-bbc1-2c9e-aabbb77d8b98",
        "NOMBRE": "OTRAS UROPATÍAS OBSTRUCTIVAS Y POR REFLUJO"
    },
    {
        "id": "d7817b62-ad41-1c05-7bf4-b5442ce70b2e",
        "NOMBRE": "OTRAS URTICARIAS"
    },
    {
        "id": "cd14bd87-706c-e637-2dcc-17f6afd862a3",
        "NOMBRE": "OTRAS VARIANTES DEL SÍNDROME DE TURNER"
    },
    {
        "id": "786665a7-d355-1f9d-06a1-5684bb8be78f",
        "NOMBRE": "OTRAS VASCULITIS LIMITADAS A LA PIEL"
    },
    {
        "id": "2d170aae-0262-2439-e153-93b00eadf23b",
        "NOMBRE": "OTRAS VASCULOPATÍAS NECROTIZANTES"
    },
    {
        "id": "c6dfb4e6-e9ca-ec4e-2c02-d371cc520e1f",
        "NOMBRE": "OTRAS VASCULOPATÍAS NECROTIZANTES ESPECIFICADAS"
    },
    {
        "id": "af62e700-584c-a664-d891-3aba8767945d",
        "NOMBRE": "OTRO ABORTO"
    },
    {
        "id": "b5e0faf9-64da-c81f-813c-93ecfd7ec308",
        "NOMBRE": "OTRO ABORTO COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "6d558fd6-427b-8c87-02b2-6698dfb949ae",
        "NOMBRE": "OTRO ABORTO COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "95066084-6987-ae4f-11b5-a0d62e5fce3a",
        "NOMBRE": "OTRO ABORTO COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "66fc0a3a-dc21-4f18-5c85-786770bb1659",
        "NOMBRE": "OTRO ABORTO COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "2efd8c4c-c940-ff2b-1fec-3495a9dd97a4",
        "NOMBRE": "OTRO ABORTO COMPLETO O NO ESPECIFICADO, SIN COMPLICACIÓN"
    },
    {
        "id": "fdc8875d-5716-05b8-cd7a-f95462c5cc33",
        "NOMBRE": "OTRO ABORTO INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "5d6123c3-1916-f2d6-efc8-5f8de283b6a1",
        "NOMBRE": "OTRO ABORTO INCOMPLETO, COMPLICADO POR EMBOLIA"
    },
    {
        "id": "c9205926-743b-af1f-0834-efe2ef7a9783",
        "NOMBRE": "OTRO ABORTO INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "8464f02b-92ec-33a3-6695-736143817fc9",
        "NOMBRE": "OTRO ABORTO INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "e007fd82-9e51-b38a-c05c-776f1a550c22",
        "NOMBRE": "OTRO ABORTO INCOMPLETO, SIN COMPLICACIÓN"
    },
    {
        "id": "780acee7-5409-1389-ecab-c2f2ab045230",
        "NOMBRE": "OTRO COMPROMISO SISTÉMICO DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "24618a31-d653-6abe-8c01-f88ef26eeb7c",
        "NOMBRE": "OTRO DOLOR CRÓNICO"
    },
    {
        "id": "dd809f39-c3db-da17-ba28-0583aedcb73e",
        "NOMBRE": "OTRO HOMBRE CON CARIOTIPO 46, XX"
    },
    {
        "id": "1fb24f7f-0ce8-7b64-4b38-f662bc32bce7",
        "NOMBRE": "OTRO HUÉSPED EN SERVICIOS DE SALUD"
    },
    {
        "id": "55909e28-a362-288b-c1d9-f5e22062ab36",
        "NOMBRE": "OTRO PALUDISMO [MALARIA] CONFIRMADO PARASITOLÓGICAMENTE"
    },
    {
        "id": "9823f539-bc07-f63d-f891-03e7283ba537",
        "NOMBRE": "OTRO PALUDISMO CONFIRMADO PARASITOLÓGICAMENTE, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "8cf062aa-a2fe-6b9d-458c-68f78f0eac6e",
        "NOMBRE": "OTRO PALUDISMO GRAVE Y COMPLICADO DEBIDO A PLASMODIUM FALCIPARUM"
    },
    {
        "id": "d44675de-b9e2-44ad-41fd-b816b9f0eb6c",
        "NOMBRE": "OTRO PARKINSONISMO SECUNDARIO INDUCIDO POR DROGAS"
    },
    {
        "id": "15d2f356-889a-fef6-2032-9e340fd8db6b",
        "NOMBRE": "OTRO PESO BAJO AL NACER"
    },
    {
        "id": "99955c7a-af33-1133-b04e-cc7b9586370c",
        "NOMBRE": "OTRO SÍNDROME DE INTESTINO IRRITABLE Y EL NO ESPECIFICADO"
    },
    {
        "id": "ff521d81-0b9c-daa2-8341-470101ac7a98",
        "NOMBRE": "OTRO TRASTORNO DESINTEGRATIVO DE LA NIÑEZ"
    },
    {
        "id": "5ad246cd-329c-5a80-321e-9734aa05d715",
        "NOMBRE": "OTRO TRASTORNO PSICÓTICO AGUDO, CON PREDOMINIO DE IDEAS DELIRANTES"
    },
    {
        "id": "8a936a8e-c6b6-6e41-ebb0-d348cc2b7524",
        "NOMBRE": "OTRO TRAUMA OBSTÉTRICO"
    },
    {
        "id": "5f59ead4-4f89-6476-daa6-134e0a61c881",
        "NOMBRE": "OTRO TRAUMATISMO DE LA MÉDULA ESPINAL LUMBAR"
    },
    {
        "id": "21c8e991-4831-882d-c5d9-61a235e94626",
        "NOMBRE": "OTRO TRAUMATISMO DEL PLEXO BRAQUIAL DURANTE EL NACIMIENTO"
    },
    {
        "id": "6fc20416-482e-00ed-db42-24bbb73b4618",
        "NOMBRE": "OTRO(S) DEDO(S) DEL PIE EN MARTILLO (ADQUIRIDOS)"
    },
    {
        "id": "5cabffd6-e1c0-cb17-b1cb-2f6f03d49d0a",
        "NOMBRE": "OTROS ABSCESOS DE LA FARINGE"
    },
    {
        "id": "e55d0079-2567-4bf8-e735-828eca590f79",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE AÉREO ESPECIFICADOS"
    },
    {
        "id": "b3eedfac-61d9-57c3-7995-7d9386ea165c",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE AÉREO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "0ec50677-48f1-8825-874c-cd06ab10d5e0",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"
    },
    {
        "id": "7547a3e0-0454-304d-9138-716ab2ac7413",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "5ce73050-b057-08e8-e91c-8140577cc096",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BALSA INFLABLE (SIN MOTOR)"
    },
    {
        "id": "0061f1ed-b34f-01b5-7767-3fed1d9b6973",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BARCO DE PASAJEROS"
    },
    {
        "id": "3ec9f662-ae2d-a199-8522-90f8c6e3b7c5",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BARCO MERCANTE"
    },
    {
        "id": "fe969068-2465-4823-35fe-5951d1af9e45",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BOTE DE PESCA"
    },
    {
        "id": "8507d2af-693e-825a-e90f-6c5b5f95b211",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, CANOA O KAYAK"
    },
    {
        "id": "403a29a1-096c-755c-ae22-96f15a377185",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, ESQUÍ ACUÁTICO"
    },
    {
        "id": "9b9accc0-4c71-47d1-df34-e38e1cfa5712",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, OTRO VEHÍCULO ACUÁTICO CON MOTOR"
    },
    {
        "id": "47cbe0eb-b7f5-6b01-b760-a33ace03d420",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, OTRO VEHÍCULO ACUÁTICO SIN MOTOR"
    },
    {
        "id": "f7ac6761-be93-891a-17bb-49b5fb7f17d6",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, VEHÍCULO ACUÁTICO NO ESPECIFICADO"
    },
    {
        "id": "297f22b4-de6d-1bcb-464c-f8026a40ae72",
        "NOMBRE": "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, VELERO"
    },
    {
        "id": "90f58867-a636-8ddc-7101-15f68c623f5a",
        "NOMBRE": "OTROS ACNÉS"
    },
    {
        "id": "5bb8c54d-520a-3fd4-57e6-b580e1a0f91a",
        "NOMBRE": "OTROS AGENTES BACTERIANOS ESPECIFICADOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "f43c4b99-3107-8ca6-a74a-e30cd9c6e618",
        "NOMBRE": "OTROS AGENTES BACTERIANOS ESPECIFICADOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "1f8ec257-9252-7800-a76e-db2ba7cc038d",
        "NOMBRE": "OTROS AGENTES INFECCIOSOS ESPECIFICADOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "364dd468-542c-90ac-27b6-63cdfd78db31",
        "NOMBRE": "OTROS AGENTES VIRALES COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "f5fc7c7c-6bb8-deda-9cae-5e7abb859272",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS"
    },
    {
        "id": "1d4e4dc3-9b90-32e9-2c3f-fa474a7c3bbe",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "edcbc66a-d4e9-376f-bad6-b1ff5fd89c4f",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "8c245a02-dbb3-e06f-0e3a-573dd38e38b5",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, CALLES Y CARRETERAS"
    },
    {
        "id": "6e478ec7-0c8e-4bd8-b9c3-da81954aed08",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "07b57a21-bb8e-74d4-ad79-750b054b0905",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2b01812e-8b47-b37b-ca17-4e7f266574ad",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, GRANJA"
    },
    {
        "id": "92f06153-19b8-adcc-8d0c-ba57a57f9b72",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "fd26348a-0223-9215-48bc-1fd3ccaf30c8",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "4f1d7656-4453-bcc7-6a15-5af602f06681",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "2155eeee-cdee-69f5-3a90-a11bb230adcf",
        "NOMBRE": "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, VIVIENDA"
    },
    {
        "id": "7c2bec84-28c2-4822-6878-2cb87d8a38f4",
        "NOMBRE": "OTROS ANEURISMAS Y ANEURISMAS DISECANTES"
    },
    {
        "id": "097c23f3-0196-81b0-fed1-108b8b173981",
        "NOMBRE": "OTROS BOCIOS NO TÓXICOS"
    },
    {
        "id": "87dccc20-aabf-f441-b5b3-0294b6b70381",
        "NOMBRE": "OTROS BOCIOS NO TÓXICOS ESPECIFICADOS"
    },
    {
        "id": "b63eb376-7d7a-81d1-c920-943daa3f66b6",
        "NOMBRE": "OTROS CÁLCULOS DE LAS VÍAS URINARIAS INFERIORES"
    },
    {
        "id": "22d13a14-c9c3-d58c-9ce9-bee5fc2075e0",
        "NOMBRE": "OTROS CAMBIOS AGUDOS DE LA PIEL DEBIDOS A RADIACIÓN ULTRAVIOLETA"
    },
    {
        "id": "e5b89408-a183-9be8-8e16-f319b8c6299c",
        "NOMBRE": "OTROS CAMBIOS AGUDOS ESPECIFICADOS DE LA PIEL DEBIDOS A RADIACIÓN ULTRAVIOLETA"
    },
    {
        "id": "35d6acd4-6d82-c759-6c50-cf39572a8568",
        "NOMBRE": "OTROS CAMBIOS DE LA PIEL DEBIDOS A EXPOSICIÓN CRÓNICA A RADIACIÓN NO IONIZANTE"
    },
    {
        "id": "282c7ac7-08ea-1885-a5c7-3b78d7686494",
        "NOMBRE": "OTROS CAMBIOS DE LA PIEL Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "168d2056-bec1-026b-b26f-28a63b973d1d",
        "NOMBRE": "OTROS CAMBIOS DE LAS VÍAS LAGRIMALES"
    },
    {
        "id": "de6c2aad-8806-4615-e30f-7b54fca26ba8",
        "NOMBRE": "OTROS CAMBIOS EN LA PIEL"
    },
    {
        "id": "65c48969-1242-63df-294c-e414670f972f",
        "NOMBRE": "OTROS CAMBIOS PERDURABLES DE LA PERSONALIDAD"
    },
    {
        "id": "f714116b-f981-8948-b4f8-78d0cdd8d389",
        "NOMBRE": "OTROS CARCINOMAS ESPECIFICADOS DEL HÍGADO"
    },
    {
        "id": "842c1311-4a9f-da77-85b9-5910335695f0",
        "NOMBRE": "OTROS CARCINOMAS IN SITU DE LA MAMA"
    },
    {
        "id": "6edf6875-b556-9a62-6e55-9c156dc77ff2",
        "NOMBRE": "OTROS CONTROLES GENERALES DE SALUD DE RUTINA DE OTRAS SUBPOBLACIONES DEFINIDAS"
    },
    {
        "id": "f078558e-f7de-e878-dee1-959907a1b9ab",
        "NOMBRE": "OTROS CUIDADOS ESPECIFICADOS POSTERIORES A LA CIRUGÍA"
    },
    {
        "id": "ff5bcdfe-9895-646c-ca06-504838ae0619",
        "NOMBRE": "OTROS CUIDADOS ESPECIFICADOS POSTERIORES A LA ORTOPEDIA"
    },
    {
        "id": "e601f04b-f061-a240-c005-749293a1391a",
        "NOMBRE": "OTROS CUIDADOS POSTERIORES A LA CIRUGÍA"
    },
    {
        "id": "d5e1439a-c599-de91-6d19-5dd3d3e12a4a",
        "NOMBRE": "OTROS CUIDADOS POSTERIORES A LA ORTOPEDIA"
    },
    {
        "id": "d289362b-61c8-77e9-c873-772dbb84d156",
        "NOMBRE": "OTROS DEFECTOS DE LA COAGULACIÓN"
    },
    {
        "id": "197f37d1-b86e-ba9e-68d2-fa68e94994dd",
        "NOMBRE": "OTROS DEFECTOS ESPECIFICADOS DE LA COAGULACIÓN"
    },
    {
        "id": "5d6ba1c0-4917-1b12-c501-9f5f12022d40",
        "NOMBRE": "OTROS DEFECTOS OBSTRUCTIVOS DE LA PELVIS RENAL Y DEL URÉTER"
    },
    {
        "id": "e3af7272-99ea-01f6-8025-fef1d0925f0d",
        "NOMBRE": "OTROS DEFECTOS POR REDUCCIÓN DE MIEMBRO(S) NO ESPECIFICADO(S)"
    },
    {
        "id": "e443e6c3-67eb-69ce-de2c-b42120c28852",
        "NOMBRE": "OTROS DEFECTOS POR REDUCCIÓN DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "457ec1bc-8c74-be95-c90b-d716c7a51da2",
        "NOMBRE": "OTROS DEFECTOS POR REDUCCIÓN DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)"
    },
    {
        "id": "6f9e9145-e6af-8332-8492-edbd23ac5f53",
        "NOMBRE": "OTROS DELIRIOS"
    },
    {
        "id": "29f8b17b-426f-59b7-e63d-f04e8275639a",
        "NOMBRE": "OTROS DESARREGLOS DE LA RÓTULA"
    },
    {
        "id": "0beffa67-cc1a-f7d1-bc0a-61b579a285d2",
        "NOMBRE": "OTROS DESGARROS (NO TRAUMÁTICOS) DEL MÚSCULO"
    },
    {
        "id": "0d8908d7-d30e-30f4-8119-06fbdf883544",
        "NOMBRE": "OTROS DESPLAZAMIENTOS DE DISCO CERVICAL"
    },
    {
        "id": "fd5d4843-f20c-c78e-d09f-f11313cde163",
        "NOMBRE": "OTROS DESPLAZAMIENTOS ESPECIFICADOS DE DISCO INTERVERTEBRAL"
    },
    {
        "id": "80f868bf-6fd7-e7a3-12fc-f1d7c9c3920f",
        "NOMBRE": "OTROS DESPRENDIMIENTOS DE LA RETINA"
    },
    {
        "id": "d421e41a-2b03-4cae-629a-d99db7a0c4d7",
        "NOMBRE": "OTROS DESPRENDIMIENTOS PREMATUROS DE LA PLACENTA"
    },
    {
        "id": "98c8fc6f-39a8-691a-3708-dd688daa638d",
        "NOMBRE": "OTROS DISPOSITIVOS MÉDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS"
    },
    {
        "id": "dfa0a756-d49e-47d5-7a6b-55abaecf28b9",
        "NOMBRE": "OTROS DISPOSITIVOS MÉDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNÓSTICO Y MONITOREO"
    },
    {
        "id": "acf3d9c9-42f0-21cc-7a1d-072b159759f5",
        "NOMBRE": "OTROS DISPOSITIVOS MÉDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "59b85432-297a-6df0-624f-014a370321bc",
        "NOMBRE": "OTROS DISPOSITIVOS MÉDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTÉSICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"
    },
    {
        "id": "fb9e418d-85ec-84e9-8a8a-9938ec90a62c",
        "NOMBRE": "OTROS DISPOSITIVOS MÉDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPÉUTICOS (NO QUIRÚRGICOS) Y DE REHABILITACIÓN"
    },
    {
        "id": "31435edd-dbf8-c216-6a5e-090b9ff0d7ee",
        "NOMBRE": "OTROS DISPOSITIVOS MÉDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRÚRGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"
    },
    {
        "id": "4242441c-86e7-5d69-44f7-26fa393ae5f4",
        "NOMBRE": "OTROS DOLORES ABDOMINALES Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "01552e35-1689-3954-cb5b-6369a1b83540",
        "NOMBRE": "OTROS DOLORES EN EL PECHO"
    },
    {
        "id": "1cfb9ba2-c434-a5b2-b411-6da73a794d76",
        "NOMBRE": "OTROS EDEMAS DE LA CÓRNEA"
    },
    {
        "id": "903a98a3-d003-1caa-f700-4c0614e4b845",
        "NOMBRE": "OTROS EDEMAS Y LOS NO ESPECIFICADOS, PROPIOS DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "103cfabe-a71f-50dd-e6fb-20f4cfe3bc22",
        "NOMBRE": "OTROS EFECTOS ADVERSOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "2e775f56-8bfd-5f4d-23ab-794618d76455",
        "NOMBRE": "OTROS EFECTOS DE LA PRESIÓN DEL AIRE Y DEL AGUA"
    },
    {
        "id": "4e79be5a-8fdc-28ed-fb12-2a1063f2674e",
        "NOMBRE": "OTROS EFECTOS DE LA REDUCCIÓN DE LA TEMPERATURA"
    },
    {
        "id": "5b4018b9-7cfa-8694-a0b8-eb50c4d4440d",
        "NOMBRE": "OTROS EFECTOS DE PRIVACIÓN"
    },
    {
        "id": "a9c98b92-46fd-4b1d-3998-c73838c00503",
        "NOMBRE": "OTROS EFECTOS DEL CALOR Y DE LA LUZ"
    },
    {
        "id": "85a11c1a-76af-b6ca-acb5-fda48f7b8715",
        "NOMBRE": "OTROS EFECTOS ESPECIFICADOS DE LA REDUCCIÓN DE LA TEMPERATURA"
    },
    {
        "id": "8c8f74db-f3ff-b4df-023f-2b780304d92b",
        "NOMBRE": "OTROS EFECTOS ESPECIFICADOS DE OTRAS CAUSAS EXTERNAS"
    },
    {
        "id": "0bba8659-887b-ae7f-366c-fc0dc8833d6f",
        "NOMBRE": "OTROS EFECTOS Y LOS NO ESPECIFICADOS DE LA GRAN ALTITUD"
    },
    {
        "id": "7c23e419-d8c8-dfdf-8c0c-9ae6fec9507b",
        "NOMBRE": "OTROS EMBARAZOS ECTÓPICOS"
    },
    {
        "id": "32af2d12-dbf4-e7e0-9809-1b588cbd37c6",
        "NOMBRE": "OTROS EMBARAZOS MÚLTIPLES"
    },
    {
        "id": "9fbb3fb2-e8e6-a1e6-3dc5-4d734d486495",
        "NOMBRE": "OTROS ENGROSAMIENTOS EPIDÉRMICOS ESPECIFICADOS"
    },
    {
        "id": "653ebd52-ba19-5dee-6d8e-c819a16a8e89",
        "NOMBRE": "OTROS ENVENENAMIENTOS POR PESCADOS Y MARISCOS"
    },
    {
        "id": "2b45bfac-956e-534d-d18a-7f118466a24a",
        "NOMBRE": "OTROS EPISODIOS DEPRESIVOS"
    },
    {
        "id": "bb6ee830-9875-8478-5e42-5ce7c8b5e9b0",
        "NOMBRE": "OTROS EPISODIOS MANÍACOS"
    },
    {
        "id": "6dfd421c-12bf-558d-4b00-d147e15b3999",
        "NOMBRE": "OTROS ERITEMAS FIGURADOS CRÓNICOS"
    },
    {
        "id": "9fe6a550-cd1c-7cb6-ff97-25c50cc15609",
        "NOMBRE": "OTROS ERITEMAS MULTIFORMES"
    },
    {
        "id": "b30573fe-458e-e2c7-567c-8b9e51e5a808",
        "NOMBRE": "OTROS ESTADOS EPILÉPTICOS"
    },
    {
        "id": "6cffa423-7a28-760c-a1e9-85f6234db8e7",
        "NOMBRE": "OTROS ESTADOS POSTQUIRÚRGICOS"
    },
    {
        "id": "2efac19b-fa54-1359-c5e6-22578ae9f0a9",
        "NOMBRE": "OTROS ESTADOS POSTQUIRÚRGICOS ESPECIFICADOS"
    },
    {
        "id": "e0db93d9-7971-e7a0-2d0c-2ea962f08864",
        "NOMBRE": "OTROS ESTAFILOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "c3204aed-6d7a-319e-9bd7-492a94da4c75",
        "NOMBRE": "OTROS ESTRABISMOS"
    },
    {
        "id": "e4fc8e18-b81d-c68b-5eee-a646c99db0bb",
        "NOMBRE": "OTROS ESTRABISMOS ESPECIFICADOS"
    },
    {
        "id": "484dab21-f5fa-de6b-309d-fff36e8c84d5",
        "NOMBRE": "OTROS ESTRABISMOS PARALÍTICOS"
    },
    {
        "id": "76fc512a-ec4c-0aff-ae02-cf6ec5bf3e6c",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES"
    },
    {
        "id": "8b89606c-ad85-ffa4-dede-e4431624c8ff",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "0a325c4c-d5db-bf01-35c8-b1113bde1ce0",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "856ee334-0a8f-e8c3-5d58-5297f3dc002c",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, CALLES Y CARRETERAS"
    },
    {
        "id": "804338b2-ae6d-5a51-d7a3-f23bd407580a",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "83886bc3-85fa-16d8-46d0-9886bc99a085",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "dfac07f5-79bd-f4d0-7307-727d13b87730",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, GRANJA"
    },
    {
        "id": "02eca243-0129-55aa-827c-34386f297cff",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "7b8858c8-080b-a082-ecc8-98b0da408da0",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "b7b9e7ee-9ca7-b48f-f7c7-01e56dee4072",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6ea0a426-4cdf-4890-7f49-1203a5a9c242",
        "NOMBRE": "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, VIVIENDA"
    },
    {
        "id": "a8aa29df-28da-30bf-299d-39fef853c9af",
        "NOMBRE": "OTROS ESTREPTOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "f07d93eb-ab9b-9c06-4ab8-b6559964691e",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "9c81437b-9b12-9aa8-3f63-8e2b5edec5ad",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "fe5ea53d-1b57-9ec5-0ba9-83ecacce21be",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "64f4ae51-e052-8a3f-574e-bfff7b3488af",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, CALLES Y CARRETERAS"
    },
    {
        "id": "47b146ba-017b-f1e9-7f50-12ede4556f03",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "05a347ee-c4cd-a8b0-041b-06493f60fda6",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ac5234be-d787-7701-ec52-bfb9d69db968",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, GRANJA"
    },
    {
        "id": "1276d375-a9bf-3dfc-fd27-6055ce6caa3b",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "6fc2fcbf-daf3-4d5e-b4d5-e5842756924a",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "d09f1c4d-02a7-5fae-08b2-f40311bb7653",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "11387264-819e-9da4-dd9a-b26d677b6c27",
        "NOMBRE": "OTROS EVENTOS ESPECIFICADOS, DE INTENCIÓN NO DETERMINADA, VIVIENDA"
    },
    {
        "id": "63e0b767-ae64-a5ae-f201-e68f133005dc",
        "NOMBRE": "OTROS EXÁMENES ESPECIALES E INVESTIGACIONES EN PERSONAS SIN QUEJAS O SIN DIAGNÓSTICO INFORMADO"
    },
    {
        "id": "6e3602c7-ed54-4610-3ffe-9176fcc04b8e",
        "NOMBRE": "OTROS EXÁMENES ESPECIALES ESPECIFICADOS"
    },
    {
        "id": "577f6b95-ed2b-2080-3ec1-8d922ae1849f",
        "NOMBRE": "OTROS EXÁMENES GENERALES"
    },
    {
        "id": "49864eb0-02b9-53fe-a82d-c3509a433398",
        "NOMBRE": "OTROS EXÁMENES PARA FINES ADMINISTRATIVOS"
    },
    {
        "id": "22bed929-6b97-7801-98b3-3164a105cac7",
        "NOMBRE": "OTROS FRACASOS DE LA INDUCCIÓN DEL TRABAJO DE PARTO"
    },
    {
        "id": "88fb7e2e-8a7e-dce7-99e7-a5ce4d73b444",
        "NOMBRE": "OTROS GLAUCOMAS"
    },
    {
        "id": "c73856e9-21e4-4696-45a7-9df2e541c01e",
        "NOMBRE": "OTROS HALLAZGOS ANORMALES EN DIAGNÓSTICO POR IMAGEN DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "8f2c9df9-1b11-673d-e206-042c7888bec6",
        "NOMBRE": "OTROS HALLAZGOS ANORMALES EN EL EXAMEN PRENATAL DE LA MADRE"
    },
    {
        "id": "c4a13427-f641-e4c1-f647-8cd9a9ca7c73",
        "NOMBRE": "OTROS HALLAZGOS ANORMALES EN LA ORINA"
    },
    {
        "id": "3882bebc-06ac-4b7d-891c-214535f72336",
        "NOMBRE": "OTROS HALLAZGOS ANORMALES EN LA ORINA Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "1addcb6f-2a5c-dd44-c56a-75174975cb81",
        "NOMBRE": "OTROS HALLAZGOS ANORMALES EN LA QUÍMICA SANGUÍNEA"
    },
    {
        "id": "34152a03-dd36-0ef4-b00e-df301c878f45",
        "NOMBRE": "OTROS HALLAZGOS ANORMALES ESPECIFICADOS EN LA QUÍMICA SANGUÍNEA"
    },
    {
        "id": "85b03b0d-3b6c-116d-5967-cb45061e9a55",
        "NOMBRE": "OTROS HALLAZGOS INMUNOLÓGICOS ANORMALES EN SUERO"
    },
    {
        "id": "eb7dda43-4ad6-e5af-1d16-6a249dd6e533",
        "NOMBRE": "OTROS HALLAZGOS INMUNOLÓGICOS ANORMALES ESPECIFICADOS EN SUERO"
    },
    {
        "id": "2c79a25b-a67c-f60a-c6e9-a221fb34029c",
        "NOMBRE": "OTROS HELMINTIASIS"
    },
    {
        "id": "06f0b108-bc1e-8042-5cb9-6e970264f509",
        "NOMBRE": "OTROS HIDROCÉFALOS CONGÉNITOS"
    },
    {
        "id": "2c6a81ab-0300-8919-bb70-230f79771df2",
        "NOMBRE": "OTROS HIDROCELES"
    },
    {
        "id": "d6024787-26e7-3e88-166f-be450e5f4bae",
        "NOMBRE": "OTROS HIPOTIROIDISMOS"
    },
    {
        "id": "931fa0f6-12dc-f13e-e357-56b2094bbd07",
        "NOMBRE": "OTROS HIPOTIROIDISMOS ESPECIFICADOS"
    },
    {
        "id": "9a0a9d88-d8d6-0e12-a854-18071f48d5e2",
        "NOMBRE": "OTROS INCIDENTES DURANTE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "1fddc928-da24-34b2-9233-67c4f1f481ed",
        "NOMBRE": "OTROS INCIDENTES ESPECIFICADOS DURANTE LA ATENCIÓN MÉDICA Y QUIRÚRGICA"
    },
    {
        "id": "10df322a-d892-06ec-887c-3510a7e5f859",
        "NOMBRE": "OTROS INFARTOS CEREBRALES"
    },
    {
        "id": "e4426adc-6870-50dc-fc8e-aead911e71e0",
        "NOMBRE": "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, COMPLICADOS POR EMBOLIA"
    },
    {
        "id": "8c07c7cb-14d8-75d1-b43c-86e55fce52dd",
        "NOMBRE": "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, COMPLICADOS POR HEMORRAGIA EXCESIVA O TARDÍA"
    },
    {
        "id": "f16b20bb-7d67-efbd-ef51-1095a54ab92c",
        "NOMBRE": "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, COMPLICADOS POR INFECCIÓN GENITAL Y PELVIANA"
    },
    {
        "id": "863f3c47-af61-7b48-e0aa-2cfcffebabab",
        "NOMBRE": "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, CON OTRAS COMPLICACIONES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "c80750f7-96dd-5848-6ad8-515d47702fe7",
        "NOMBRE": "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, SIN COMPLICACIÓN"
    },
    {
        "id": "3a778eed-d726-b60c-9a36-f80ce0adc84c",
        "NOMBRE": "OTROS LINFOMAS DE CÉLULAS T/NK MADURAS"
    },
    {
        "id": "53900f0a-9eae-8159-ab5f-64f6cc0e4dcf",
        "NOMBRE": "OTROS LINFOMAS DE HODGKIN CLÁSICOS"
    },
    {
        "id": "51a51163-c874-0487-ec0e-bb490f6dd3d7",
        "NOMBRE": "OTROS LÍQUENES PLANOS"
    },
    {
        "id": "ee127410-117d-bbeb-e533-a3898383aa0f",
        "NOMBRE": "OTROS LUPUS ERITEMATOSOS LOCALIZADOS"
    },
    {
        "id": "13afdc0c-2b27-7871-7ea6-cf0457a26755",
        "NOMBRE": "OTROS MALTRATOS"
    },
    {
        "id": "e5503aaa-7636-8af5-c08b-3a4e4d2b5f4e",
        "NOMBRE": "OTROS MALTRATOS POR AUTORIDADES OFICIALES"
    },
    {
        "id": "2cab2905-c678-2959-8731-ddbe4b9db4b4",
        "NOMBRE": "OTROS MALTRATOS POR CONOCIDO O AMIGO"
    },
    {
        "id": "35e2797a-2e56-3e09-5c1a-5770d6032aea",
        "NOMBRE": "OTROS MALTRATOS POR ESPOSO O PAREJA"
    },
    {
        "id": "bcfd1db2-c140-c8e5-dbc8-8d91b3a78182",
        "NOMBRE": "OTROS MALTRATOS POR OTRA PERSONA ESPECIFICADA"
    },
    {
        "id": "9997512c-8804-f784-1e66-246ae5c7d833",
        "NOMBRE": "OTROS MALTRATOS POR PADRE O MADRE"
    },
    {
        "id": "cea34474-1ec6-efcb-6963-090df520908d",
        "NOMBRE": "OTROS MALTRATOS POR PERSONA NO ESPECIFICADA"
    },
    {
        "id": "1e48b6b7-3e7e-e64d-caac-ea4e36e6ecfe",
        "NOMBRE": "OTROS MÉTODOS DE ATENCIÓN PARA LA FECUNDACIÓN"
    },
    {
        "id": "6eee57de-214e-b950-1c4b-a09b73c7af96",
        "NOMBRE": "OTROS MOVIMIENTOS ANORMALES INVOLUNTARIOS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "3e0a83fc-39eb-2b94-a568-2fb5188eb51a",
        "NOMBRE": "OTROS NACIMIENTOS MÚLTIPLES, ALGUNOS NACIDOS VIVOS"
    },
    {
        "id": "1f96bc14-087e-01ef-7c1c-080cfbdf50cb",
        "NOMBRE": "OTROS NACIMIENTOS MÚLTIPLES, EN HOSPITAL"
    },
    {
        "id": "e401a202-db29-d91e-c266-a35c40a4e1ef",
        "NOMBRE": "OTROS NACIMIENTOS MÚLTIPLES, FUERA DEL HOSPITAL"
    },
    {
        "id": "66672277-96b2-e18e-87fd-6740412d67c4",
        "NOMBRE": "OTROS NACIMIENTOS MÚLTIPLES, LUGAR DE NACIMIENTO NO ESPECIFICADO"
    },
    {
        "id": "5081a625-a4b7-df55-a5f7-dd245c701d07",
        "NOMBRE": "OTROS NACIMIENTOS MÚLTIPLES, TODOS NACIDOS MUERTOS"
    },
    {
        "id": "30ce2695-020c-66a8-7cf7-6fc3e82989bd",
        "NOMBRE": "OTROS NACIMIENTOS MÚLTIPLES, TODOS NACIDOS VIVOS"
    },
    {
        "id": "d9a67b63-67bf-9e9d-872b-f566a741ac99",
        "NOMBRE": "OTROS NEUMOTÓRAX"
    },
    {
        "id": "84c6c748-6504-a978-e01a-9b0635180ca7",
        "NOMBRE": "OTROS ÓRGANOS Y TEJIDOS TRASPLANTADOS"
    },
    {
        "id": "3ae78214-d9f0-b69a-cfe4-9f50834b09c0",
        "NOMBRE": "OTROS ORIFICIOS ARTIFICIALES DE LAS VÍAS URINARIAS"
    },
    {
        "id": "195b4e18-136b-aad8-d302-4c17dcdc7a37",
        "NOMBRE": "OTROS ORIFICIOS ARTIFICIALES DEL TUBO GASTROINTESTINAL"
    },
    {
        "id": "7bf27ee0-a705-f4d8-9aff-11d199ca09d6",
        "NOMBRE": "OTROS PALUDISMOS CONGÉNITOS"
    },
    {
        "id": "5c99aefd-a7a2-51c3-3c99-1ad96c098733",
        "NOMBRE": "OTROS PARTOS MÚLTIPLES"
    },
    {
        "id": "b3e60dcf-38a1-a39b-cc5b-5b1c4a463d64",
        "NOMBRE": "OTROS PARTOS ÚNICOS ASISTIDOS"
    },
    {
        "id": "ccc5154f-3c86-2f7f-b82f-3ea66998c665",
        "NOMBRE": "OTROS PARTOS ÚNICOS ASISTIDOS ESPECIFICADOS"
    },
    {
        "id": "649b52c0-42c1-2d6c-7564-a4e5e6a184ce",
        "NOMBRE": "OTROS PARTOS ÚNICOS ASISTIDOS, DE NALGAS"
    },
    {
        "id": "253632b2-fe02-12e8-0d5c-7448bbc74bb7",
        "NOMBRE": "OTROS PARTOS ÚNICOS CON AYUDA DE MANIPULACIÓN OBSTÉTRICA"
    },
    {
        "id": "b38d881a-0361-5cfc-2ee9-26b19611fd8d",
        "NOMBRE": "OTROS PARTOS ÚNICOS POR CESÁREA"
    },
    {
        "id": "0832d367-8141-b391-442b-8e692128648c",
        "NOMBRE": "OTROS PENFIGOIDES"
    },
    {
        "id": "562db946-640e-1680-bf82-f86eb8f018c9",
        "NOMBRE": "OTROS PÉNFIGOS"
    },
    {
        "id": "d5a3a4d7-ab29-05a3-c7ff-63ecaab9f929",
        "NOMBRE": "OTROS PÓLIPOS DE LOS SENOS PARANASALES"
    },
    {
        "id": "5c31c573-4b1e-8618-7bce-e4ec00ed61a4",
        "NOMBRE": "OTROS PROBLEMAS DE ALIMENTACIÓN DEL RECIÉN NACIDO"
    },
    {
        "id": "fdda29e3-c566-90b6-1fb4-008ed2910d50",
        "NOMBRE": "OTROS PROBLEMAS DE TENSIÓN FÍSICA O MENTAL RELACIONADAS CON EL TRABAJO"
    },
    {
        "id": "cd0f1f52-cb11-4286-6a42-7c2e00be5498",
        "NOMBRE": "OTROS PROBLEMAS ESPECIFICADOS RELACIONADOS CON CIRCUNSTANCIANCIAS PSICOSOCIALES"
    },
    {
        "id": "40aa4a2a-8a4f-50b8-5a9d-6c79cd546d96",
        "NOMBRE": "OTROS PROBLEMAS ESPECIFICADOS RELACIONADOS CON EL GRUPO PRIMARIO DE APOYO"
    },
    {
        "id": "7917c52b-94ae-3dfe-79fa-f6d3647049da",
        "NOMBRE": "OTROS PROBLEMAS ESPECIFICADOS Y RELACIONADOS CON LA CRIANZA DEL NIÑO"
    },
    {
        "id": "8b7f0bf7-1f4c-3a19-6966-af0f1fb76fde",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS"
    },
    {
        "id": "70a8ab38-8a40-9d22-fd6f-f5889ce1c8ca",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA"
    },
    {
        "id": "8f722110-e1b8-f0eb-24c9-ca4649e79df6",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON EL AMBIENTE FÍSICO"
    },
    {
        "id": "b317dabd-1e6c-a5a2-ad68-5bbc916f1f95",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON EL AMBIENTE SOCIAL"
    },
    {
        "id": "4dd28cd5-d34d-75b8-58a5-2bcdb8ce503c",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON EL ESTILO DE VIDA"
    },
    {
        "id": "32f1f68c-b1ed-dbdf-cf1d-3b98ee92d3dc",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON EL GRUPO PRIMARIO DE APOYO, INCLUSIVE CIRCUNSTANCIAS FAMILIARES"
    },
    {
        "id": "cc0091db-f72e-58ed-6066-1426c77342c6",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON LA CRIANZA DEL NIÑO"
    },
    {
        "id": "d7f6fd93-e05e-31f4-ad25-1b0dc7e560f9",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON LA EDUCACIÓN Y LA ALFABETIZACIÓN"
    },
    {
        "id": "ec96c87a-1a27-eaa2-04e7-a5fd366236ff",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONÓMICAS"
    },
    {
        "id": "42422354-795e-3503-8dcc-2d20f9278c4b",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON NEGLIGENCIA EN LA CRIANZA DEL NIÑO"
    },
    {
        "id": "a7d12ee2-c494-6f99-117a-e3a7753b2337",
        "NOMBRE": "OTROS PROBLEMAS RELACIONADOS CON SERVICIOS MÉDICOS Y DE SALUD"
    },
    {
        "id": "1b94b238-78a6-94c2-e924-fbbd3f9ff0e6",
        "NOMBRE": "OTROS PROBLEMAS RESPIRATORIOS DEL RECIÉN NACIDO, ORIGINADOS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "7412c94c-dd54-ff4b-9926-0c9667eddc9e",
        "NOMBRE": "OTROS PROBLEMAS RESPIRATORIOS ESPECIFICADOS DEL RECIÉN NACIDO"
    },
    {
        "id": "ff5398eb-5146-9828-eddc-faa602fab3af",
        "NOMBRE": "OTROS PROBLEMAS Y LOS NO ESPECIFICADOS RELACIONADOS CON EL EMPLEO"
    },
    {
        "id": "603df32e-b85b-be0b-0515-2a60df16ba8b",
        "NOMBRE": "OTROS PROCEDIMIENTOS MÉDICOS"
    },
    {
        "id": "9475458f-d5ca-c5a0-c680-e5593d43cd99",
        "NOMBRE": "OTROS PROCEDIMIENTOS MÉDICOS COMO LA CAUSA DE REACCIÓN ANORMAL DEL PACIENTE O DE COMPLICACIÓN POSTERIOR, SIN MENCIÓN DE INCIDENTE EN EL MOMENTO DE EFECTUAR EL PROCEDIMIENTO"
    },
    {
        "id": "33187aa2-42b7-2ed4-e9b8-17629afb4794",
        "NOMBRE": "OTROS PROCEDIMIENTOS PARA OTROS PROPÓSITOS QUE NO SEAN LOS DE MEJORAR EL ESTADO DE SALUD"
    },
    {
        "id": "79733d96-2439-3af4-8964-41933eb690ff",
        "NOMBRE": "OTROS PROCEDIMIENTOS QUIRÚRGICOS"
    },
    {
        "id": "eb951094-5e74-511b-2c90-35001f679fde",
        "NOMBRE": "OTROS PRODUCTOS ANORMALES DE LA CONCEPCIÓN"
    },
    {
        "id": "aa909a87-d479-d85d-cfef-c2b75753f47a",
        "NOMBRE": "OTROS PRODUCTOS ANORMALES ESPECIFICADOS DE LA CONCEPCIÓN"
    },
    {
        "id": "6756cf40-cbf8-b291-2be6-9cd421e9c2db",
        "NOMBRE": "OTROS PROLAPSOS GENITALES FEMENINOS"
    },
    {
        "id": "087bcfb9-c8df-d97c-29df-6ae7b693f61c",
        "NOMBRE": "OTROS PRURIGOS"
    },
    {
        "id": "d36611ee-ea0b-eec9-5753-635a7a530b46",
        "NOMBRE": "OTROS PRURITOS"
    },
    {
        "id": "94a5de17-864b-40b9-7514-0832f613eb36",
        "NOMBRE": "OTROS QUISTES DE LA BOLSA SEROSA"
    },
    {
        "id": "b8a0617f-b346-1d37-8c58-ba353e28f130",
        "NOMBRE": "OTROS QUISTES DE LA REGIÓN BUCAL, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "86e83025-ee51-2c9b-a68d-3631f3244c42",
        "NOMBRE": "OTROS QUISTES DE LOS MAXILARES"
    },
    {
        "id": "b68e31aa-9833-e6b1-a697-d51e2e519d31",
        "NOMBRE": "OTROS QUISTES FOLICULARES DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "58dc57df-971e-d879-153e-2d7a72d4a383",
        "NOMBRE": "OTROS QUISTES ÓSEOS"
    },
    {
        "id": "b0d9c945-03a3-6a33-b9ac-bda61349ff3a",
        "NOMBRE": "OTROS QUISTES OVÁRICOS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "195f04e6-6ba4-d537-c020-656463ab49c8",
        "NOMBRE": "OTROS RECIÉN NACIDOS CON SOBREPESO PARA LA EDAD GESTACIONAL"
    },
    {
        "id": "29b262f7-5d1a-25a1-2464-c5138b224bb8",
        "NOMBRE": "OTROS RECIÉN NACIDOS PRETÉRMINO"
    },
    {
        "id": "3bac68a3-4379-4101-21d2-8c907116f68f",
        "NOMBRE": "OTROS REORDENAMIENTOS EQUILIBRADOS Y MARCADORES ESTRUCTURALES"
    },
    {
        "id": "1dbf0a1b-f3dd-ac59-282e-f4c00de9bc29",
        "NOMBRE": "OTROS SARCOMAS DEL HÍGADO"
    },
    {
        "id": "d11f5e09-754a-56ca-9625-6e114218c418",
        "NOMBRE": "OTROS SIGNOS Y SÍNTOMAS RELATIVOS A LA MAMA"
    },
    {
        "id": "ea105b4d-56e4-5b65-f90d-5f04e583f0ba",
        "NOMBRE": "OTROS SÍNDROMES DE ASPIRACIÓN NEONATAL"
    },
    {
        "id": "0d4f158e-7175-c547-259d-77903e6d3ef7",
        "NOMBRE": "OTROS SÍNDROMES DE CEFALEA"
    },
    {
        "id": "f4a8ba25-5732-59f9-b202-46316fb15186",
        "NOMBRE": "OTROS SÍNDROMES DE CEFALEA ESPECIFICADOS"
    },
    {
        "id": "bd3491de-bb2d-135f-fd44-8bb25983818d",
        "NOMBRE": "OTROS SÍNDROMES DE MALFORMACIONES CONGÉNITAS CON OTROS CAMBIOS ESQUELÉTICOS"
    },
    {
        "id": "9293551d-c276-fbeb-e50d-a91ce3093b91",
        "NOMBRE": "OTROS SÍNDROMES DE MALFORMACIONES CONGÉNITAS DEBIDOS A CAUSAS EXÓGENAS CONOCIDAS"
    },
    {
        "id": "835593e0-f3f6-97e5-85b4-9d25110e52f9",
        "NOMBRE": "OTROS SÍNDROMES DE MALFORMACIONES CONGÉNITAS ESPECIFICADOS QUE AFECTAN MÚLTIPLES SISTEMAS"
    },
    {
        "id": "23fb5c5a-ea80-b77f-6daa-a914ac3664a6",
        "NOMBRE": "OTROS SÍNDROMES DE MALFORMACIONES CONGÉNITAS ESPECIFICADOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "98b66d76-2a38-b0e9-500d-b67c70b4b486",
        "NOMBRE": "OTROS SÍNDROMES DEL MALTRATO"
    },
    {
        "id": "97fcc1b0-ad77-3afd-cbf6-5d6dfc5c2175",
        "NOMBRE": "OTROS SÍNDROMES HISTIOCÍTICOS"
    },
    {
        "id": "171fbe3f-6444-08f7-7b16-39f9dcaf475d",
        "NOMBRE": "OTROS SÍNDROMES LACUNARES"
    },
    {
        "id": "f14b480e-9ddb-b637-2cf0-35f56dd299dd",
        "NOMBRE": "OTROS SÍNDROMES MIASTÉNICOS EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "81c5726a-5bcf-429e-c85d-9838a95b0363",
        "NOMBRE": "OTROS SÍNDROMES MIELODISPLÁSICOS"
    },
    {
        "id": "0f9b1280-fb98-b3a1-a20d-ab397b0e1a9d",
        "NOMBRE": "OTROS SÍNDROMES PARALÍTICOS"
    },
    {
        "id": "bf17ac5c-3c07-1b5d-df64-38fe8e8d5b6a",
        "NOMBRE": "OTROS SÍNDROMES PARALÍTICOS ESPECIFICADOS"
    },
    {
        "id": "090ec2c0-726b-2333-8185-dd89cf23b67b",
        "NOMBRE": "OTROS SÍNDROMES SUPERPUESTOS"
    },
    {
        "id": "498430a5-3538-d4c1-c25b-b84051a5533b",
        "NOMBRE": "OTROS SÍNDROMES VASCULARES ENCEFÁLICOS EN ENFERMEDADES CEREBROVASCULARES"
    },
    {
        "id": "7a771292-c470-565c-5f60-be5ca5c100a0",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS CONCERNIENTES A LA ALIMENTACIÓN Y A LA INGESTIÓN DE LÍQUIDOS"
    },
    {
        "id": "b014d08e-eaca-8712-b421-5981f00e4cb5",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS ESPECIFICADOS QUE INVOLUCRAN EL SISTEMA DIGESTIVO Y EL ABDOMEN"
    },
    {
        "id": "cb033d5a-a5c0-3d55-b13d-c78f9fedded8",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS ESPECIFICADOS QUE INVOLUCRAN LOS SISTEMAS CIRCULATORIO Y RESPIRATORIO"
    },
    {
        "id": "e97629ea-29bc-f184-d5f1-f31ac5c6cca4",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS GENERALES"
    },
    {
        "id": "0edfabe0-2bba-efb9-23e4-a07d23ab4d2d",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS GENERALES ESPECIFICADOS"
    },
    {
        "id": "d63c311f-23e0-29a5-1268-1262352a9ada",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN EL ESTADO EMOCIONAL"
    },
    {
        "id": "f689f6d4-475c-0097-4266-adb9e73bc701",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN EL SISTEMA DIGESTIVO Y EL ABDOMEN"
    },
    {
        "id": "5c41b8ea-d4aa-20ee-fc6b-460960e79e3d",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN EL SISTEMA URINARIO"
    },
    {
        "id": "99fc4a6d-b372-7634-71d1-f7dbd32b6654",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN EL SISTEMA URINARIO Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "8f392f1a-91f6-10fb-36a8-c1fd6b6ac3aa",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LA APARIENCIA Y EL COMPORTAMIENTO"
    },
    {
        "id": "6e256ce7-f761-dbf8-4d61-b8af50e23572",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LA FUNCIÓN COGNOSCITIVA Y LA CONCIENCIA"
    },
    {
        "id": "85ba6a73-c991-b623-88f1-0937188ab77d",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LA FUNCIÓN COGNOSCITIVA Y LA CONCIENCIA Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "db79ab71-7c6d-5b52-e86e-663fc0e70433",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LAS SENSACIONES Y PERCEPCIONES GENERALES"
    },
    {
        "id": "8b4abb49-8895-0759-1c4b-53e93c7f73c9",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LAS SENSACIONES Y PERCEPCIONES GENERALES Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "3a1760a4-a4aa-1a47-ebc7-ed2402357c57",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LOS SISTEMAS CIRCULATORIO Y RESPIRATORIO"
    },
    {
        "id": "19bdcf0f-527a-1898-b65d-e90b89068d39",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LOS SISTEMAS NERVIOSO Y OSTEOMUSCULAR"
    },
    {
        "id": "c8b639d0-d91c-b4bd-c005-d2d26bfb09c2",
        "NOMBRE": "OTROS SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LOS SISTEMAS NERVIOSO Y OSTEOMUSCULAR Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "3bcef493-b055-504f-734c-9fd74068d284",
        "NOMBRE": "OTROS SONIDOS CARDÍACOS"
    },
    {
        "id": "3cb5f317-80fe-29a4-88b2-bbfa4fdff4f4",
        "NOMBRE": "OTROS TÉTANOS"
    },
    {
        "id": "d84584b9-390d-236d-013c-944b14f0dee2",
        "NOMBRE": "OTROS TIPOS DE APENDICITIS"
    },
    {
        "id": "68a5a0ea-f28e-c9ae-901a-2a36c77cbcb9",
        "NOMBRE": "OTROS TIPOS DE BLOQUEO AURICULOVENTRICULAR Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "aa6c9703-14ab-9d23-580f-29a1176725b3",
        "NOMBRE": "OTROS TIPOS DE BLOQUEO DE RAMA DERECHA DEL HAZ Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "abfc90ad-3366-3282-d8dc-253ece9a27e7",
        "NOMBRE": "OTROS TIPOS DE BLOQUEO FASCICULAR Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "e6554eca-b725-0a07-fd29-7bc217f43b97",
        "NOMBRE": "OTROS TIPOS DE DESPOLARIZACIÓN PREMATURA Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "260b1400-3133-a6ce-1ae8-8e64203bc5fb",
        "NOMBRE": "OTROS TIPOS DE ENFERMEDAD DE ALZHEIMER"
    },
    {
        "id": "b19d0317-52bb-e60b-9d5d-00092f6c6ea7",
        "NOMBRE": "OTROS TIPOS DE ENFERMEDAD DE CROHN"
    },
    {
        "id": "624e1c2b-db06-c178-b60b-c2809c3f9fa3",
        "NOMBRE": "OTROS TIPOS DE ENFISEMA"
    },
    {
        "id": "7adbe210-43d6-e958-badd-a794c4ebe1e7",
        "NOMBRE": "OTROS TIPOS DE ENGROSAMIENTO EPIDÉRMICO"
    },
    {
        "id": "3c6733f5-05b6-d1ab-9035-d929c3b8b0c0",
        "NOMBRE": "OTROS TIPOS DE HIDROCÉFALO"
    },
    {
        "id": "52746d82-e12e-3656-d05f-1a456ed7eada",
        "NOMBRE": "OTROS TIPOS DE HIPERALDOSTERONISMO"
    },
    {
        "id": "76965741-3fb3-f88d-8ecf-6fe6456d139e",
        "NOMBRE": "OTROS TIPOS DE HIPERALIMENTACIÓN"
    },
    {
        "id": "2ba424ef-265b-45b3-475b-045d75d5ff9e",
        "NOMBRE": "OTROS TIPOS DE HIPERALIMENTACIÓN ESPECIFICADOS"
    },
    {
        "id": "3be24c3b-b611-166f-f607-9743abd6c2a8",
        "NOMBRE": "OTROS TIPOS DE HIPERPARATIROIDISMO"
    },
    {
        "id": "9271d81f-e7b6-d1e8-b78d-1150c26f40f8",
        "NOMBRE": "OTROS TIPOS DE HIPERPIGMENTACIÓN MELANODÉRMICA"
    },
    {
        "id": "ccc6ccb1-e5d7-f868-13fe-95eccfad50e1",
        "NOMBRE": "OTROS TIPOS DE HIPERTENSIÓN SECUNDARIA"
    },
    {
        "id": "f4ee3b1a-6163-3c45-3b3b-26e72b5306a4",
        "NOMBRE": "OTROS TIPOS DE HIPOPARATIROIDISMO"
    },
    {
        "id": "5bb11c66-1b93-8dda-a84f-c01202153201",
        "NOMBRE": "OTROS TIPOS DE HIPOTENSIÓN"
    },
    {
        "id": "6aa6718f-01f1-f253-8000-2ee2243ba4b6",
        "NOMBRE": "OTROS TIPOS DE INTOLERANCIA A LA LACTOSA"
    },
    {
        "id": "a415c7ce-62ea-d4eb-9326-c7b08f818e38",
        "NOMBRE": "OTROS TIPOS DE MALABSORCIÓN INTESTINAL"
    },
    {
        "id": "88adf82d-57bd-8da1-37cd-4ddd31f28dc7",
        "NOMBRE": "OTROS TIPOS DE NEUMOTÓRAX ESPONTÁNEO"
    },
    {
        "id": "38ec787a-7174-b8ac-63e0-bc068829e8f6",
        "NOMBRE": "OTROS TIPOS DE OBESIDAD"
    },
    {
        "id": "13dbef50-10c7-7137-26c0-ecfbdf6417c7",
        "NOMBRE": "OTROS TIPOS DE PARÁLISIS CEREBRAL"
    },
    {
        "id": "31f61af3-c76b-d877-e3b1-b30199e6b0fd",
        "NOMBRE": "OTROS TIPOS DE PARKINSONISMO SECUNDARIO"
    },
    {
        "id": "0797be7f-8b6c-6cc3-6dc2-57ae8d97558f",
        "NOMBRE": "OTROS TIPOS DE RETRASO MENTAL"
    },
    {
        "id": "59005527-9017-c184-c695-23bfa4457d99",
        "NOMBRE": "OTROS TIPOS DE RETRASO MENTAL, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"
    },
    {
        "id": "29655526-232d-e350-c083-59642dd65432",
        "NOMBRE": "OTROS TIPOS DE RETRASO MENTAL, DETERIORO DEL COMPORTAMIENTO NULO O MÍNIMO"
    },
    {
        "id": "a0c7d9f1-52f7-cadb-7b6e-8dde90248277",
        "NOMBRE": "OTROS TIPOS DE RETRASO MENTAL, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCIÓN O TRATAMIENTO"
    },
    {
        "id": "4ac670c9-3a35-e984-3dae-a172238cb22d",
        "NOMBRE": "OTROS TIPOS DE RETRASO MENTAL, OTROS DETERIOROS DEL COMPORTAMIENTO"
    },
    {
        "id": "9b2c7276-e211-1b92-a0a6-2175c9eed6e8",
        "NOMBRE": "OTROS TIPOS DE SÍNDROME DE CUSHING"
    },
    {
        "id": "78f65998-211a-df15-4f32-b535d70491ce",
        "NOMBRE": "OTROS TIPOS ESPECIFICADOS DE BLOQUEO DEL CORAZÓN"
    },
    {
        "id": "a7a694ce-4768-e1ad-5462-2a0585f1555d",
        "NOMBRE": "OTROS TIPOS ESPECIFICADOS DE LINFOMA DE CÉLULAS T/NK"
    },
    {
        "id": "93526912-d1ab-71bd-9f13-3e620332a279",
        "NOMBRE": "OTROS TIPOS ESPECIFICADOS DE LINFOMA FOLICULAR"
    },
    {
        "id": "e5f69899-8f1b-f027-6255-dc00c9f559b2",
        "NOMBRE": "OTROS TIPOS ESPECIFICADOS DE LINFOMA NO FOLICULAR"
    },
    {
        "id": "5f8be81f-9898-989c-94b3-5393d6b48688",
        "NOMBRE": "OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN"
    },
    {
        "id": "b9cad8bc-81fd-61fb-e67f-8d79d9766cb3",
        "NOMBRE": "OTROS TRASTORNOS ACANTOLÍTICOS"
    },
    {
        "id": "e80fa882-528f-9ab9-b87b-677d559617b2",
        "NOMBRE": "OTROS TRASTORNOS ACANTOLÍTICOS ESPECIFICADOS"
    },
    {
        "id": "261024e0-0bc8-605f-995d-1d7ef1518c0f",
        "NOMBRE": "OTROS TRASTORNOS ADRENOGENITALES"
    },
    {
        "id": "e50881f9-db84-4bb8-359a-89f8fc09a5f2",
        "NOMBRE": "OTROS TRASTORNOS AFECTIVOS BIPOLARES"
    },
    {
        "id": "edc4258b-feb1-7acd-17f4-5d272c62631e",
        "NOMBRE": "OTROS TRASTORNOS ARTERIALES O ARTERIOLARES"
    },
    {
        "id": "eb670e83-d4fd-1c2b-7eae-e3b0dbe6110c",
        "NOMBRE": "OTROS TRASTORNOS ARTICULARES ESPECIFICADOS"
    },
    {
        "id": "f69be499-fadc-0d89-22dc-b80dc155ef68",
        "NOMBRE": "OTROS TRASTORNOS ARTICULARES ESPECÍFICOS"
    },
    {
        "id": "04ed0c0d-e8e8-472a-0085-67749dcdfe34",
        "NOMBRE": "OTROS TRASTORNOS ARTICULARES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "9efdda57-bd43-e1cf-eaf5-3eecbda804b4",
        "NOMBRE": "OTROS TRASTORNOS ATRÓFICOS DE LA PIEL"
    },
    {
        "id": "665c9864-1d53-533e-475c-1ed0cc78a1b7",
        "NOMBRE": "OTROS TRASTORNOS CARDÍACOS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6e406ce1-092f-54f0-d090-f7339ec46fc4",
        "NOMBRE": "OTROS TRASTORNOS CARDÍACOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8f60d9fb-3b67-dad6-477f-5fe45128c8cc",
        "NOMBRE": "OTROS TRASTORNOS CARDÍACOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e7b7edaa-f40e-4e5e-e8f6-ac2b5a55c19e",
        "NOMBRE": "OTROS TRASTORNOS CARDÍACOS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ce51d254-df69-5396-4403-f1a1a69bfb0d",
        "NOMBRE": "OTROS TRASTORNOS CARDIOVASCULARES ORIGINADOS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "4ef1ded0-668b-71d9-7821-082a0029674d",
        "NOMBRE": "OTROS TRASTORNOS CEREBROVASCULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "42d6c805-b72d-8fcc-4185-9bce41116a5a",
        "NOMBRE": "OTROS TRASTORNOS CORIORRETINIANOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "59166349-a2e7-2637-28e1-06c2456dc43f",
        "NOMBRE": "OTROS TRASTORNOS DE ANSIEDAD"
    },
    {
        "id": "3f550139-ad4b-0826-4697-a865b3e1a0f2",
        "NOMBRE": "OTROS TRASTORNOS DE ANSIEDAD ESPECIFICADOS"
    },
    {
        "id": "68ba6002-c88a-5cb4-4308-ee0c9d80252b",
        "NOMBRE": "OTROS TRASTORNOS DE ANSIEDAD MIXTOS"
    },
    {
        "id": "147582e3-1873-66ba-74e0-96ba318c37d7",
        "NOMBRE": "OTROS TRASTORNOS DE ARTERIAS, ARTERIOLAS Y VASOS CAPILARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6ccd1ea8-7469-34fd-d7fb-7d3dc83b6f4f",
        "NOMBRE": "OTROS TRASTORNOS DE DISCO CERVICAL"
    },
    {
        "id": "15dea5d3-6f92-d068-bf2b-4534442b734b",
        "NOMBRE": "OTROS TRASTORNOS DE DISMINUCIÓN DE LA FORMACIÓN DE LA MELANINA"
    },
    {
        "id": "ea15a8aa-290a-18a4-00ac-574fb7c9a0cd",
        "NOMBRE": "OTROS TRASTORNOS DE LA ABSORCIÓN INTESTINAL DE CARBOHIDRATOS"
    },
    {
        "id": "9e6a552b-4d16-64ec-4720-f1767b4e3753",
        "NOMBRE": "OTROS TRASTORNOS DE LA CONDUCCIÓN"
    },
    {
        "id": "f752af78-030e-f270-c087-5ea2b6f05e36",
        "NOMBRE": "OTROS TRASTORNOS DE LA CONDUCTA"
    },
    {
        "id": "edaaaf46-74b0-2eef-a58c-37585c86ccde",
        "NOMBRE": "OTROS TRASTORNOS DE LA CONJUNTIVA"
    },
    {
        "id": "a48b8269-6de9-b249-1deb-1ef3119c4a5b",
        "NOMBRE": "OTROS TRASTORNOS DE LA CONJUNTIVA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3ff6c66c-e30a-799f-f154-a86c1db52b07",
        "NOMBRE": "OTROS TRASTORNOS DE LA CONTINUIDAD DEL HUESO"
    },
    {
        "id": "d6a4e47a-e192-0174-5f55-606f25e65314",
        "NOMBRE": "OTROS TRASTORNOS DE LA CÓRNEA"
    },
    {
        "id": "f5357bfe-88e0-07e3-dc4c-e3ec1650406c",
        "NOMBRE": "OTROS TRASTORNOS DE LA COROIDES"
    },
    {
        "id": "fdeb3acc-47e0-2438-5130-64645d7378bb",
        "NOMBRE": "OTROS TRASTORNOS DE LA DENSIDAD Y DE LA ESTRUCTURA ÓSEAS"
    },
    {
        "id": "87993fef-840d-78af-e10f-db07a0fd1d7f",
        "NOMBRE": "OTROS TRASTORNOS DE LA ELIMINACIÓN TRANSEPIDÉRMICA"
    },
    {
        "id": "04c804d4-0bd9-c5fb-99a1-46ea70121630",
        "NOMBRE": "OTROS TRASTORNOS DE LA ENCÍA Y DE LA ZONA EDÉNTULA"
    },
    {
        "id": "d720d2a8-3f83-4a23-8b20-ff43bddfb7e1",
        "NOMBRE": "OTROS TRASTORNOS DE LA ESCLERÓTICA"
    },
    {
        "id": "5e5e37fe-d1f7-437c-547a-95a4ad33171d",
        "NOMBRE": "OTROS TRASTORNOS DE LA ESCLERÓTICA Y DE LA CÓRNEA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "984f6bd5-1b66-8894-25b6-f18c36c8583d",
        "NOMBRE": "OTROS TRASTORNOS DE LA FUNCIÓN VESTIBULAR"
    },
    {
        "id": "485f90d7-5745-3dcd-63b9-ef4331065b41",
        "NOMBRE": "OTROS TRASTORNOS DE LA GLÁNDULA HIPÓFISIS"
    },
    {
        "id": "48c4adc1-4615-ec29-a3d0-171a33f4ec01",
        "NOMBRE": "OTROS TRASTORNOS DE LA GLÁNDULA LAGRIMAL"
    },
    {
        "id": "abd9313f-5860-4540-68e3-5519578b5fc2",
        "NOMBRE": "OTROS TRASTORNOS DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "54553505-b52c-d5a6-f0d0-4f633a145868",
        "NOMBRE": "OTROS TRASTORNOS DE LA IDENTIDAD DE GÉNERO"
    },
    {
        "id": "8b2bc894-9187-03fe-828e-8e7a3e681911",
        "NOMBRE": "OTROS TRASTORNOS DE LA INGESTIÓN DE ALIMENTOS"
    },
    {
        "id": "542465ea-d515-b437-fcd2-a682a4bd4f22",
        "NOMBRE": "OTROS TRASTORNOS DE LA MAMA"
    },
    {
        "id": "ec910078-53f5-d1e3-366c-519fbf1b7b14",
        "NOMBRE": "OTROS TRASTORNOS DE LA MAMA Y DE LA LACTANCIA ASOCIADOS CON EL PARTO"
    },
    {
        "id": "ed3b2f2d-8716-9db7-21e5-32c55692656e",
        "NOMBRE": "OTROS TRASTORNOS DE LA MAMA Y LOS NO ESPECIFICADOS ASOCIADOS CON EL PARTO"
    },
    {
        "id": "2552a044-93c5-e6a9-9e9b-d016127e8a0f",
        "NOMBRE": "OTROS TRASTORNOS DE LA MEMBRANA TIMPÁNICA"
    },
    {
        "id": "a620c434-c18b-124a-c868-37831cd494ac",
        "NOMBRE": "OTROS TRASTORNOS DE LA NARIZ Y DE LOS SENOS PARANASALES"
    },
    {
        "id": "ed0998be-f7a1-93ac-7838-2e4347b1d75d",
        "NOMBRE": "OTROS TRASTORNOS DE LA ÓRBITA"
    },
    {
        "id": "542cfd91-44cb-5150-fda5-0af27c4d4bbc",
        "NOMBRE": "OTROS TRASTORNOS DE LA ÓRBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f27ae536-7a77-163f-fd54-db5496e0e2bd",
        "NOMBRE": "OTROS TRASTORNOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS"
    },
    {
        "id": "8c57aed3-3a67-2584-8abe-716317df02d0",
        "NOMBRE": "OTROS TRASTORNOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a09c2f51-256e-8bd3-492f-53c45911279e",
        "NOMBRE": "OTROS TRASTORNOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "69708444-1ddc-d6d3-91e9-adafec909288",
        "NOMBRE": "OTROS TRASTORNOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "1d7e3320-cfb5-bb70-415f-36bd8b5c1e36",
        "NOMBRE": "OTROS TRASTORNOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO RELACIONADOS CON RADIACIÓN"
    },
    {
        "id": "ade4a5a8-aa8f-74af-5002-5ecff2a85153",
        "NOMBRE": "OTROS TRASTORNOS DE LA PIGMENTACIÓN"
    },
    {
        "id": "84e59581-1e08-b722-f7b6-4ebd9a653a61",
        "NOMBRE": "OTROS TRASTORNOS DE LA PREFERENCIA SEXUAL"
    },
    {
        "id": "273c11ef-6121-350a-6cd5-e2d629177b4a",
        "NOMBRE": "OTROS TRASTORNOS DE LA PRÓSTATA"
    },
    {
        "id": "658713f4-94b8-629e-1adc-e0c617e72b04",
        "NOMBRE": "OTROS TRASTORNOS DE LA PUBERTAD"
    },
    {
        "id": "29126b06-ecab-bcba-bc3a-9a9aad0b1aa2",
        "NOMBRE": "OTROS TRASTORNOS DE LA REFRACCIÓN"
    },
    {
        "id": "73f4f775-41c6-28fb-6c66-42f1dfd78490",
        "NOMBRE": "OTROS TRASTORNOS DE LA RETINA"
    },
    {
        "id": "e9320ae1-f5f7-e733-42de-0f4a440103be",
        "NOMBRE": "OTROS TRASTORNOS DE LA RETINA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e7a0e5e2-b0df-5e9c-e441-ef8139c3ff0c",
        "NOMBRE": "OTROS TRASTORNOS DE LA RÓTULA"
    },
    {
        "id": "f2690a4f-c758-5660-5688-1c23b8961d7f",
        "NOMBRE": "OTROS TRASTORNOS DE LA SANGRE Y DE LOS ÓRGANOS HEMATOPOYÉTICOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "312c6685-9a62-3e16-2bdd-43a60fc756f5",
        "NOMBRE": "OTROS TRASTORNOS DE LA SECRECIÓN INTERNA DEL PÁNCREAS"
    },
    {
        "id": "6479fdfa-0551-8b77-e302-0e2ab47fdc1b",
        "NOMBRE": "OTROS TRASTORNOS DE LA SINOVIA Y DEL TENDÓN"
    },
    {
        "id": "cbb24dfe-ddf7-dd27-c5ff-ceb6015af79f",
        "NOMBRE": "OTROS TRASTORNOS DE LA TIROIDES RELACIONADOS CON DEFICIENCIA DE YODO Y AFECCIONES SIMILARES"
    },
    {
        "id": "b47cb3b4-f79c-4d24-a84c-487e2626ab7f",
        "NOMBRE": "OTROS TRASTORNOS DE LA TROMPA DE EUSTAQUIO"
    },
    {
        "id": "71000b4d-38d7-49ff-8453-081dde0d327d",
        "NOMBRE": "OTROS TRASTORNOS DE LA URETRA"
    },
    {
        "id": "d1ed24c8-7650-3120-7755-bacabcc022c7",
        "NOMBRE": "OTROS TRASTORNOS DE LA VÁLVULA AÓRTICA"
    },
    {
        "id": "0c7211fa-d44d-1b47-637b-b2daaddad759",
        "NOMBRE": "OTROS TRASTORNOS DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "b6b059b2-ce93-119e-b086-96393aaee4d4",
        "NOMBRE": "OTROS TRASTORNOS DE LA VEJIGA"
    },
    {
        "id": "81533ca0-1e6b-d5f0-1e6e-ab914a69bdb2",
        "NOMBRE": "OTROS TRASTORNOS DE LA VISIÓN BINOCULAR"
    },
    {
        "id": "2d638a09-781d-c424-0a60-ce6ec06d9725",
        "NOMBRE": "OTROS TRASTORNOS DE LAS RAÍCES Y PLEXOS NERVIOSOS"
    },
    {
        "id": "6fd286e8-db4d-2edf-135f-4513b01c4e60",
        "NOMBRE": "OTROS TRASTORNOS DE LAS UÑAS"
    },
    {
        "id": "9875f070-d09f-40f0-c4ac-aca44c5d9d43",
        "NOMBRE": "OTROS TRASTORNOS DE LAS VENAS"
    },
    {
        "id": "cc1323d5-0722-8105-7bd9-d4fc07954c64",
        "NOMBRE": "OTROS TRASTORNOS DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTÉN"
    },
    {
        "id": "8ad1c971-b26b-db22-c4eb-5a2db7c39249",
        "NOMBRE": "OTROS TRASTORNOS DE LOS DISCOS INTERVERTEBRALES"
    },
    {
        "id": "6a414aed-3158-fcf4-4099-42db1fd3e07c",
        "NOMBRE": "OTROS TRASTORNOS DE LOS HÁBITOS Y DE LOS IMPULSOS"
    },
    {
        "id": "c8572c6b-c146-2d3e-2550-79319aa06ef7",
        "NOMBRE": "OTROS TRASTORNOS DE LOS LEUCOCITOS"
    },
    {
        "id": "18a49615-9f1c-e159-820c-d7d2f71b67ec",
        "NOMBRE": "OTROS TRASTORNOS DE LOS LÍQUIDOS, DE LOS ELECTRÓLITOS Y DEL EQUILIBRIO ÁCIDO-BÁSICO"
    },
    {
        "id": "f91d9f91-f9ce-72cc-b247-8adda134f936",
        "NOMBRE": "OTROS TRASTORNOS DE LOS MENISCOS"
    },
    {
        "id": "f0b1acaf-2d8b-27c3-efe5-58d138e68e7b",
        "NOMBRE": "OTROS TRASTORNOS DE LOS MOVIMIENTOS BINOCULARES"
    },
    {
        "id": "105eeca4-b1a4-4d26-9a05-811f7e9545d4",
        "NOMBRE": "OTROS TRASTORNOS DE LOS MÚSCULOS"
    },
    {
        "id": "e2f51210-32e3-2264-39a4-e2c5541e44ca",
        "NOMBRE": "OTROS TRASTORNOS DE LOS MÚSCULOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f0e07377-576d-eb92-eae4-081d2fb234d9",
        "NOMBRE": "OTROS TRASTORNOS DE LOS NERVIOS CRANEALES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0483e3de-ac24-b257-6407-78a42acdfdb6",
        "NOMBRE": "OTROS TRASTORNOS DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "a8c4ff46-fc0a-bd81-fed2-0f111eac73bb",
        "NOMBRE": "OTROS TRASTORNOS DE LOS ÓRGANOS GENITALES MASCULINOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "239cd1a4-92dd-49bf-b68d-713e754f8030",
        "NOMBRE": "OTROS TRASTORNOS DE LOS PÁRPADOS"
    },
    {
        "id": "76018c30-3652-eda1-c185-c6a2e9277325",
        "NOMBRE": "OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4b070e28-01fc-16da-e4e5-ae34c96a46bc",
        "NOMBRE": "OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS RELACIONADOS CON EL USO, EL USO EXCESIVO Y LA PRESIÓN"
    },
    {
        "id": "0b604b30-b5d1-9d1b-5712-5884c25e8c9d",
        "NOMBRE": "OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "bb9a4f8e-21ef-4bf5-c678-39dba5154af7",
        "NOMBRE": "OTROS TRASTORNOS DEGENERATIVOS DEL GLOBO OCULAR"
    },
    {
        "id": "5a508c9f-de05-0c90-b9c7-6b8106db3781",
        "NOMBRE": "OTROS TRASTORNOS DEGENERATIVOS DEL PÁRPADO Y DEL ÁREA PERIOCULAR"
    },
    {
        "id": "78c3094e-0f50-07f2-a58c-20287f29cebc",
        "NOMBRE": "OTROS TRASTORNOS DEGENERATIVOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0f79c200-aa3a-33f5-3b1c-8bd0bc9cd8a4",
        "NOMBRE": "OTROS TRASTORNOS DEGENERATIVOS ESPECIFICADOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "46574450-b1ab-a643-0c95-3d01c87712a4",
        "NOMBRE": "OTROS TRASTORNOS DEL ALMACENAMIENTO DE LÍPIDOS"
    },
    {
        "id": "cf2520e9-b44b-6ffb-52b0-6ecb5374ecff",
        "NOMBRE": "OTROS TRASTORNOS DEL CARTÍLAGO"
    },
    {
        "id": "698c0019-1f85-edda-4d06-63922a73c7ab",
        "NOMBRE": "OTROS TRASTORNOS DEL CARTÍLAGO ARTICULAR"
    },
    {
        "id": "e4d1db0a-6f1f-b67a-c9c7-e9023e84c062",
        "NOMBRE": "OTROS TRASTORNOS DEL COMPORTAMIENTO SOCIAL EN LA NIÑEZ"
    },
    {
        "id": "ade0b883-c8a0-cefe-82a1-2b260f0d9bc2",
        "NOMBRE": "OTROS TRASTORNOS DEL CRISTALINO"
    },
    {
        "id": "9e85d473-117d-c225-0b4b-5b2fcb1b2063",
        "NOMBRE": "OTROS TRASTORNOS DEL CRISTALINO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6a4a4758-64db-248c-5105-887462511abf",
        "NOMBRE": "OTROS TRASTORNOS DEL CUERPO VÍTREO"
    },
    {
        "id": "8e708abf-85b1-2812-4a1d-5784f333f267",
        "NOMBRE": "OTROS TRASTORNOS DEL CUERPO VÍTREO Y DEL GLOBO OCULAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7836d975-4784-904b-7c22-61cc36bc98e0",
        "NOMBRE": "OTROS TRASTORNOS DEL DESARROLLO DE LAS HABILIDADES ESCOLARES"
    },
    {
        "id": "0c57489f-3b95-cfed-312e-78f7ac665e1a",
        "NOMBRE": "OTROS TRASTORNOS DEL DESARROLLO DE LOS DIENTES"
    },
    {
        "id": "975e8898-d634-4a1c-eba3-c4b513be4156",
        "NOMBRE": "OTROS TRASTORNOS DEL DESARROLLO DEL HABLA Y DEL LENGUAJE"
    },
    {
        "id": "7d0b2739-546c-93e2-992e-96d523e26ca0",
        "NOMBRE": "OTROS TRASTORNOS DEL DESARROLLO PSICOLÓGICO"
    },
    {
        "id": "21952dee-e07d-1992-40b0-0c84f011d4d4",
        "NOMBRE": "OTROS TRASTORNOS DEL DESARROLLO PSICOSEXUAL"
    },
    {
        "id": "aa932419-bbba-6764-3f74-a28bc8e0158a",
        "NOMBRE": "OTROS TRASTORNOS DEL DESARROLLO Y CRECIMIENTO ÓSEO"
    },
    {
        "id": "f3b9c3d9-16ff-01c0-323e-6b7c712aa863",
        "NOMBRE": "OTROS TRASTORNOS DEL DISCO ÓPTICO"
    },
    {
        "id": "0af274b5-d54a-4cff-c6ce-5bf9188162a5",
        "NOMBRE": "OTROS TRASTORNOS DEL ENCÉFALO"
    },
    {
        "id": "3e56527e-2b59-e6a2-534c-b3b260b75920",
        "NOMBRE": "OTROS TRASTORNOS DEL ENCÉFALO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9a8cac4e-90e8-c69a-97e2-347166ae7334",
        "NOMBRE": "OTROS TRASTORNOS DEL EQUILIBRIO DE LOS ELECTRÓLITOS Y DE LOS LÍQUIDOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "c02fa6bb-1898-a4ea-5f9c-09de3a05faa2",
        "NOMBRE": "OTROS TRASTORNOS DEL GLOBO OCULAR"
    },
    {
        "id": "ba01d736-b5a4-ae64-d62a-052c1b3d5c08",
        "NOMBRE": "OTROS TRASTORNOS DEL HUESO"
    },
    {
        "id": "3eb0151c-cee8-ba55-2268-9181018a3a16",
        "NOMBRE": "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS]"
    },
    {
        "id": "8d711e25-5f50-0a0d-0775-034aa549e0b2",
        "NOMBRE": "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS] PERSISTENTES"
    },
    {
        "id": "6864b314-5146-0592-ce5f-ebaa99f3106c",
        "NOMBRE": "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS], AISLADOS"
    },
    {
        "id": "d22c7b8f-c561-f1cc-6389-3ad43d2f71b0",
        "NOMBRE": "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS], ESPECIFICADOS"
    },
    {
        "id": "983b1237-1d98-03d2-71a7-0761c452871e",
        "NOMBRE": "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS], RECURRENTES"
    },
    {
        "id": "6ccc4293-2d97-569c-b75a-7338994efd6d",
        "NOMBRE": "OTROS TRASTORNOS DEL IRIS Y DEL CUERPO CILIAR"
    },
    {
        "id": "3030456b-4730-1b13-744b-c43620c1fdbd",
        "NOMBRE": "OTROS TRASTORNOS DEL IRIS Y DEL CUERPO CILIAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5a4775d7-eb8e-bea0-c27c-8fe41c46764b",
        "NOMBRE": "OTROS TRASTORNOS DEL LÍQUIDO AMNIÓTICO Y DE LAS MEMBRANAS"
    },
    {
        "id": "1ba28027-d2b9-d24d-5a1c-79ddf57e1bdb",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LA BILIRRUBINA"
    },
    {
        "id": "d3a43d66-10bd-7415-48c3-22b597e0ae7d",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LAS GLUCOPROTEÍNAS"
    },
    {
        "id": "41f20994-1785-39e8-9063-f24b7e5c3a33",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LAS LIPOPROTEÍNAS"
    },
    {
        "id": "d4519463-16b2-205a-56d1-8410552129ee",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS"
    },
    {
        "id": "d9615b9c-c249-1e76-91b4-af1205866a8e",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOÁCIDOS"
    },
    {
        "id": "65ee2d37-ca0b-0bfb-8051-ec31fa27c12f",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOÁCIDOS AROMÁTICOS"
    },
    {
        "id": "805cd86f-dc93-a327-119d-a615a3668926",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOÁCIDOS DE CADENA RAMIFICADA"
    },
    {
        "id": "fd5e3b7f-7f12-d803-44f0-22f3bf6c9673",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOÁCIDOS DE CADENA RAMIFICADA, NO ESPECIFICADOS"
    },
    {
        "id": "f3236298-3f9e-bec0-49bc-4c17d2b14fc2",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LOS CARBOHIDRATOS"
    },
    {
        "id": "75f13d0c-199f-c0f2-bc9d-c55a12f92cd0",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS"
    },
    {
        "id": "5b40a12c-b3c1-55f6-eb1b-db661d70bb1c",
        "NOMBRE": "OTROS TRASTORNOS DEL METABOLISMO DE LOS MINERALES"
    },
    {
        "id": "98c783b4-bfb7-9746-165b-f6bde0371fcb",
        "NOMBRE": "OTROS TRASTORNOS DEL NERVIO FACIAL"
    },
    {
        "id": "2a3627a8-81e2-d49c-33df-0f5640ed7c40",
        "NOMBRE": "OTROS TRASTORNOS DEL NERVIO ÓPTICO [II PAR] Y DE LAS VÍAS ÓPTICAS"
    },
    {
        "id": "d92ab562-4db5-0379-a74d-d94fb324c93b",
        "NOMBRE": "OTROS TRASTORNOS DEL NERVIO ÓPTICO Y DE LAS VÍAS ÓPTICAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4f82333e-4a8c-324c-f0db-f27fcdaeb3d8",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "486cb050-474d-3219-99a6-f2f951933ae1",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO EXTERNO"
    },
    {
        "id": "e0de1adf-75c9-e5ae-0a76-6a487ef1898a",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO EXTERNO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5bd48301-485d-41d2-ae26-51b8a59a21a7",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO INTERNO"
    },
    {
        "id": "5e58801b-bd11-2599-ac1a-7bee1f7e8703",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO MEDIO Y DE LA APÓFISIS MASTOIDES"
    },
    {
        "id": "582320dd-4eb9-f122-2aaf-3fd5cece38a7",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO MEDIO Y DE LA APÓFISIS MASTOIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c19464fc-2c1c-737b-1809-3fefb5313e1e",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO Y DE LA APÓFISIS MASTOIDES, CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "bc08206b-39be-cf0d-5839-6b4aa35d57e2",
        "NOMBRE": "OTROS TRASTORNOS DEL OÍDO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "54b366b6-57a1-fe6a-2ab1-a3781a244e89",
        "NOMBRE": "OTROS TRASTORNOS DEL OJO Y SUS ANEXOS"
    },
    {
        "id": "406a41d4-49ea-2e1c-ef85-075cfb82eb28",
        "NOMBRE": "OTROS TRASTORNOS DEL OJO Y SUS ANEXOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "debc74f7-df49-e06e-61ac-1791bd3ad6f4",
        "NOMBRE": "OTROS TRASTORNOS DEL OJO Y SUS ANEXOS, CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "239f43d1-b15e-0121-f100-b22aead6ff0e",
        "NOMBRE": "OTROS TRASTORNOS DEL PENE"
    },
    {
        "id": "29ba8b6d-23b1-d52c-6755-460abc00431b",
        "NOMBRE": "OTROS TRASTORNOS DEL PERITONEO"
    },
    {
        "id": "c8da7981-16c1-e847-3993-516bec216356",
        "NOMBRE": "OTROS TRASTORNOS DEL PERITONEO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "3de4abf1-a7f0-b4e1-f610-0ae7ea4c104d",
        "NOMBRE": "OTROS TRASTORNOS DEL PULMÓN"
    },
    {
        "id": "30e8a86f-cc77-8850-7150-e0fdd0366379",
        "NOMBRE": "OTROS TRASTORNOS DEL RIÑÓN Y DEL URÉTER EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "900e12d2-c1c0-79bf-1bde-b06bdfc791c4",
        "NOMBRE": "OTROS TRASTORNOS DEL RIÑÓN Y DEL URÉTER EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6f353cd0-752a-fb0e-126d-78db31841536",
        "NOMBRE": "OTROS TRASTORNOS DEL RIÑÓN Y DEL URÉTER EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "683fb23a-dc6e-1a17-88eb-062f90eefc25",
        "NOMBRE": "OTROS TRASTORNOS DEL RIÑÓN Y DEL URÉTER, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "c28cbddf-8b38-15a8-7255-4aedc2bd449a",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA CIRCULATORIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "b2db41cf-4874-5da1-bc0a-a2d855b70896",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA CIRCULATORIO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b7d712c4-41db-686f-7aae-9fb100769299",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA DIGESTIVO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "ae707f96-0a73-946d-c367-cb2995c6e734",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA GENITOURINARIO CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "16739828-d287-6944-6a7a-12c1b4e1d4d6",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "98db7f9c-bd0f-8c33-5a4e-7b70a779a1f3",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA NERVIOSO AUTÓNOMO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "53309ccb-6d12-cc9b-f729-056e858b272a",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "bc370cba-63e0-8823-0f57-cbde1beee805",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA NERVIOSO CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "72b0e7a2-1a0f-b55d-9a54-eafb70307cdf",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e13ecb2c-2d4e-4346-6471-1293fa17add0",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA NERVIOSO PERIFÉRICO"
    },
    {
        "id": "486265f2-660c-8dab-d647-ec3643904b9e",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA NERVIOSO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "6b400662-1bf1-4a73-6a94-7683e20e9d65",
        "NOMBRE": "OTROS TRASTORNOS DEL SISTEMA URINARIO"
    },
    {
        "id": "689ad324-4ec6-2506-e67c-9973951f3d28",
        "NOMBRE": "OTROS TRASTORNOS DEL SUEÑO"
    },
    {
        "id": "8ddfc180-26b4-29a8-799c-658a9b33ccca",
        "NOMBRE": "OTROS TRASTORNOS DEL TONO MUSCULAR EN EL RECIÉN NACIDO"
    },
    {
        "id": "f24b4cfb-5e5b-ac0d-3f75-457604317cd5",
        "NOMBRE": "OTROS TRASTORNOS DEL TRIGÉMINO"
    },
    {
        "id": "aff886d2-710b-b7fd-d0ac-e60aa69782b6",
        "NOMBRE": "OTROS TRASTORNOS DELIRANTES PERSISTENTES"
    },
    {
        "id": "25610836-a4fe-6c1d-57ee-6396b41fd5b1",
        "NOMBRE": "OTROS TRASTORNOS DEPRESIVOS RECURRENTES"
    },
    {
        "id": "aeac2e6c-b72b-4b83-1b65-e395ed32061e",
        "NOMBRE": "OTROS TRASTORNOS DISOCIATIVOS [DE CONVERSIÓN]"
    },
    {
        "id": "6c83c32b-622f-c748-68f8-d62d262f36a8",
        "NOMBRE": "OTROS TRASTORNOS EMOCIONALES EN LA NIÑEZ"
    },
    {
        "id": "24b4ea16-7fce-0610-8693-c382c2a2d575",
        "NOMBRE": "OTROS TRASTORNOS EMOCIONALES Y DEL COMPORTAMIENTO QUE APARECEN HABITUALMENTE EN LA NIÑEZ Y EN LA ADOLESCENCIA"
    },
    {
        "id": "66e051d8-963c-a884-1e51-df08d5d7b843",
        "NOMBRE": "OTROS TRASTORNOS EMOCIONALES Y DEL COMPORTAMIENTO QUE APARECEN HABITUALMENTE EN LA NIÑEZ Y EN LA ADOLESCENCIA"
    },
    {
        "id": "fdbe0afc-e144-8473-5de0-4d5237b77f35",
        "NOMBRE": "OTROS TRASTORNOS ENCEFÁLICOS ESPECIFICADOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a524197f-97ee-6af6-75ab-8447f9488c0d",
        "NOMBRE": "OTROS TRASTORNOS ENDOCRINOS"
    },
    {
        "id": "987c2d0e-0c5d-e5ca-22de-0d9b7a5b5573",
        "NOMBRE": "OTROS TRASTORNOS ENDOCRINOS ESPECIFICADOS"
    },
    {
        "id": "09e347bb-a3b3-bf0d-a6d1-94deeace30eb",
        "NOMBRE": "OTROS TRASTORNOS ENDOCRINOS NEONATALES TRANSITORIOS"
    },
    {
        "id": "96b008b5-0f59-32e1-451d-61ca84f6bc84",
        "NOMBRE": "OTROS TRASTORNOS ENDOCRINOS NEONATALES TRANSITORIOS ESPECIFICADOS"
    },
    {
        "id": "2d80d9b0-9c06-17cd-5fb9-a9851589951c",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE ARTERIAS Y ARTERIOLAS"
    },
    {
        "id": "3777ab16-2acb-848d-76a5-12e7a050df30",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA BOLSA SEROSA"
    },
    {
        "id": "4f123c4b-eadc-37f7-cbad-c7ac41457019",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA CONDUCCIÓN"
    },
    {
        "id": "7232d7c4-aa45-2c85-f656-bf7ec177e567",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA CONJUNTIVA"
    },
    {
        "id": "3964cc20-306a-4e76-fd3e-98b853964c5c",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA CÓRNEA"
    },
    {
        "id": "931f6abf-2170-894b-b529-0af2e7d6d716",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA COROIDES"
    },
    {
        "id": "343c0a0e-3c1c-4d7b-e3bd-c5ab3c6fc816",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA DENSIDAD Y DE LA ESTRUCTURA ÓSEAS"
    },
    {
        "id": "6d60ee61-4c89-8259-e386-4a992ea08e3c",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA ENCÍA Y DE LA ZONA EDÉNTULA"
    },
    {
        "id": "e710e621-2cd3-d7a5-df20-98a6074d97f6",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA GLÁNDULA PARATIROIDES"
    },
    {
        "id": "9b02a03b-f66e-7b14-7322-a958dd18ed28",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "4c60eef7-dc73-ffd8-f301-e6da0204c156",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA GLÁNDULA TIROIDES"
    },
    {
        "id": "0bc31e1f-319c-cfcd-8154-e2f8730f46ea",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA MAMA"
    },
    {
        "id": "062c72b7-29fc-c770-c05a-c434366ba0de",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA MEMBRANA TIMPÁNICA"
    },
    {
        "id": "3f9f7c95-2f84-9e26-d792-ac33dcc958bc",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA NARIZ Y DE LOS SENOS PARANASALES"
    },
    {
        "id": "94763514-4717-e178-669e-d3119b0c58a1",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS"
    },
    {
        "id": "0f7cc4a7-97c4-5841-e4ab-bee7ca1f499b",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "294a253f-d633-fd32-bd23-3c6694a4d829",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO RELACIONADOS CON RADIACIÓN"
    },
    {
        "id": "7853d1b9-af83-d98c-4240-3833a60a9abe",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA PIGMENTACIÓN"
    },
    {
        "id": "dee36ff2-486a-f9d2-22cd-2fe92800bb90",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA PRÓSTATA"
    },
    {
        "id": "d74c8207-9040-27e4-3e7f-c9ad3f9474c5",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA RETINA"
    },
    {
        "id": "5355b8b5-dcba-ee88-9a73-3d3c3991bf76",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA SECRECIÓN INTERNA DEL PÁNCREAS"
    },
    {
        "id": "8fb745d7-f8e4-5048-8c52-b928399e5c53",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA SINOVIA Y DEL TENDÓN"
    },
    {
        "id": "6211d3ce-6664-2499-a404-5f67606bb421",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA TROMPA DE EUSTAQUIO"
    },
    {
        "id": "3f34523c-e0c8-5829-66da-565e0a21e0b2",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA URETRA"
    },
    {
        "id": "2511469a-90d3-b0a2-6381-7cbe89957b76",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LA VEJIGA"
    },
    {
        "id": "6b6afca6-8263-42a0-cd0e-3b12117d7932",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LOS DISCOS INTERVERTEBRALES"
    },
    {
        "id": "99958d3e-d9e6-1570-fce9-c50c78064ff6",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LOS LEUCOCITOS"
    },
    {
        "id": "9471a3ba-8903-a20b-7ba7-ca3c0204ae13",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LOS MOVIMIENTOS BINOCULARES"
    },
    {
        "id": "62634de4-cda1-ccdd-b01b-7d5cc13f1bf1",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LOS MÚSCULOS"
    },
    {
        "id": "5470d495-ed2c-868f-34ec-157ce209fb5b",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "ffac766a-f759-1015-5cac-75e832709199",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DE LOS TEJIDOS BLANDOS"
    },
    {
        "id": "1cac98f0-9b16-c35d-ed94-469702c4a567",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL APARATO CIRCULATORIO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "11d4d7bf-a003-20e1-1348-24aa632fb4fb",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL APARATO LAGRIMAL"
    },
    {
        "id": "65c0e561-8d74-fcca-92b7-15092e215d95",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL CARTÍLAGO"
    },
    {
        "id": "ab8aa388-681f-2b77-15f5-71ee0fda3b05",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL CRISTALINO"
    },
    {
        "id": "f5ea6d63-93a1-da8d-471d-46b13a2d7b9b",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL ENCÉFALO"
    },
    {
        "id": "2cb9931b-dd0f-7566-1c55-9bb646ad8e96",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL HUESO"
    },
    {
        "id": "4a787d81-95a1-96f2-1d03-157c1c1c1272",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL IRIS Y DEL CUERPO CILIAR"
    },
    {
        "id": "74600aba-c8fd-cbb8-7fca-721bb4ab264f",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL LÍQUIDO AMNIÓTICO Y DE LAS MEMBRANAS"
    },
    {
        "id": "87630cc4-2855-c179-6ad0-2561aad413c9",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO"
    },
    {
        "id": "6d3833aa-389e-9182-5e8b-74bd1ab81d3d",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO DE LOS AMINOÁCIDOS"
    },
    {
        "id": "76cef912-2cd8-02c1-c93d-b120ebeaee29",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO DE LOS CARBOHIDRATOS"
    },
    {
        "id": "287a8de8-8ab6-6a7d-586a-19a700e0b14b",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OÍDO"
    },
    {
        "id": "00327d84-7e14-b168-a473-e7efdc50b3b2",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OÍDO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7520fa0e-ffe9-b894-a558-4502cc57e28f",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OÍDO EXTERNO"
    },
    {
        "id": "debe146c-b3a0-e9b5-022c-c3f6c2c43108",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OÍDO INTERNO"
    },
    {
        "id": "25c1e622-345c-23c7-b095-f3f151e68d75",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OÍDO MEDIO Y DE LA APÓFISIS MASTOIDES"
    },
    {
        "id": "8c51250f-262d-767d-b0aa-a198d8a1e9cb",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OÍDO MEDIO Y DE LA APOFISIS MASTOIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f170ead5-ce17-3beb-1a7e-e917b7ebbb63",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OJO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "012f65d4-50b0-fdd4-4c54-3b3103b15b5b",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL OJO Y SUS ANEXOS"
    },
    {
        "id": "516a66e4-77db-0038-0b7f-f3316531b9d5",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL PÁRPADO"
    },
    {
        "id": "f91686a0-e90a-8c13-7857-ccdb87464fb7",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL PENE"
    },
    {
        "id": "332f5797-ab79-b4d0-8460-f2d8e0303d14",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL PERITONEO"
    },
    {
        "id": "8999de8e-6835-0bca-7e10-42acac39f0cf",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL RIÑÓN Y DEL URÉTER"
    },
    {
        "id": "3716fe66-278d-6a93-207a-824da6dea443",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "830cd387-cc81-b6e8-1c18-37bf0dd2be20",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "80f48c95-f6bc-ee65-8934-25c205a344b0",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA URINARIO"
    },
    {
        "id": "7f4971c8-51b8-e8e1-2da3-e9326203f9fa",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS NO INFECCIOSOS DE LOS VASOS Y GANGLIOS LINFÁTICOS"
    },
    {
        "id": "b36d4cd3-c7fa-ee83-ff95-2ab32627c1a5",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS NO INFLAMATORIOS DE LA VAGINA"
    },
    {
        "id": "2a980588-8462-ac9a-1daf-62d8c26b92ef",
        "NOMBRE": "OTROS TRASTORNOS ESPECIFICADOS QUE AFECTAN EL MECANISMO DE LA INMUNIDAD, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "0ed806b9-500a-343a-d7d5-708d56302a27",
        "NOMBRE": "OTROS TRASTORNOS ESPECÍFICOS DE LA PERSONALIDAD"
    },
    {
        "id": "7f45ff67-d11d-c7cc-0dc7-efff4c97029c",
        "NOMBRE": "OTROS TRASTORNOS ESQUIZOAFECTIVOS"
    },
    {
        "id": "4a11d50e-451a-c1e0-8e9a-37cc9d372859",
        "NOMBRE": "OTROS TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO"
    },
    {
        "id": "50eba405-0131-9e8a-742f-b9a467e350c3",
        "NOMBRE": "OTROS TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO"
    },
    {
        "id": "55bd4c60-e25d-f681-4728-aaf1bebdccdf",
        "NOMBRE": "OTROS TRASTORNOS FALCIFORMES"
    },
    {
        "id": "f1703646-f273-d77d-41ff-3d3a0dde1a05",
        "NOMBRE": "OTROS TRASTORNOS FIBROBLÁSTICOS"
    },
    {
        "id": "75ee4ee2-ddce-95be-d9d5-941065797706",
        "NOMBRE": "OTROS TRASTORNOS FLICTENULARES"
    },
    {
        "id": "2bf72620-473f-18d7-e38c-216a4bdf42e7",
        "NOMBRE": "OTROS TRASTORNOS FLICTENULARES ESPECIFICADOS"
    },
    {
        "id": "08446667-2872-3169-def9-a15eec565ebc",
        "NOMBRE": "OTROS TRASTORNOS FÓBICOS DE ANSIEDAD"
    },
    {
        "id": "ac206b05-7b9e-4aa5-11c7-d8cf79e9cfc5",
        "NOMBRE": "OTROS TRASTORNOS FOLICULARES"
    },
    {
        "id": "db0d6690-ba4a-0346-ae25-54475e0386f1",
        "NOMBRE": "OTROS TRASTORNOS FOLICULARES ESPECIFICADOS"
    },
    {
        "id": "da0e5ba6-9235-9609-7467-ed47cbbd6919",
        "NOMBRE": "OTROS TRASTORNOS FUNCIONALES CONGÉNITOS DEL COLON"
    },
    {
        "id": "1b9de55e-350b-a909-2826-dd5378a22a8a",
        "NOMBRE": "OTROS TRASTORNOS FUNCIONALES DEL PÁRPADO"
    },
    {
        "id": "5a04e999-e2f8-0437-f022-38b0e2784dc1",
        "NOMBRE": "OTROS TRASTORNOS FUNCIONALES ESPECIFICADOS DEL INTESTINO"
    },
    {
        "id": "947f0b11-9bc9-99c5-6dde-7169e83a4efe",
        "NOMBRE": "OTROS TRASTORNOS FUNCIONLES DEL INTESTINO"
    },
    {
        "id": "f367b2e6-5360-65b4-1bd5-4f99d27296a5",
        "NOMBRE": "OTROS TRASTORNOS GENERALIZADOS DEL DESARROLLO"
    },
    {
        "id": "c40e4f4c-c3b3-727d-7f0f-164521dc9238",
        "NOMBRE": "OTROS TRASTORNOS GRANULOMATOSOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "97d069b4-2a27-c295-df5a-82f767e0d214",
        "NOMBRE": "OTROS TRASTORNOS HEMATOLÓGICOS PERINATALES"
    },
    {
        "id": "4bde449e-0a47-0827-12ce-da9619de6192",
        "NOMBRE": "OTROS TRASTORNOS HEMATOLÓGICOS PERINATALES ESPECIFICADOS"
    },
    {
        "id": "621c8bb3-8c6f-817b-8865-2475b0607270",
        "NOMBRE": "OTROS TRASTORNOS HIPERCINÉTICOS"
    },
    {
        "id": "d3ce4c51-d83c-acf7-454e-441940bcb20e",
        "NOMBRE": "OTROS TRASTORNOS HIPERTRÓFICOS DE LA PIEL"
    },
    {
        "id": "bdecdc15-51b5-df72-af2e-3d540b561ceb",
        "NOMBRE": "OTROS TRASTORNOS INFILTRATIVOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "7c001938-5dcd-d022-a4f7-ac94e18e7f25",
        "NOMBRE": "OTROS TRASTORNOS INFLAMATORIOS DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "0835cef0-5c5b-4496-098c-4e65ac68b671",
        "NOMBRE": "OTROS TRASTORNOS INFLAMATORIOS DEL PENE"
    },
    {
        "id": "55ed2e24-1456-aa21-5799-3b881efef7a6",
        "NOMBRE": "OTROS TRASTORNOS INTERNOS DE LA RODILLA"
    },
    {
        "id": "15efd052-daa6-26a8-ee31-8971533a718d",
        "NOMBRE": "OTROS TRASTORNOS LOCALIZADOS DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "aad3d3ce-b423-fea3-0655-887e4d821702",
        "NOMBRE": "OTROS TRASTORNOS LOCALIZADOS ESPECIFICADOS DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "6dc18991-40ed-68ab-772b-5d9269ad0138",
        "NOMBRE": "OTROS TRASTORNOS MENOPÁUSICOS Y PERIMENOPÁUSICOS"
    },
    {
        "id": "2c084513-6923-7416-e5c2-f21fb9ae200b",
        "NOMBRE": "OTROS TRASTORNOS MENOPÁUSICOS Y PERIMENOPÁUSICOS ESPECIFICADOS"
    },
    {
        "id": "0b1e25bd-97e8-746e-c436-5df9519bfcaa",
        "NOMBRE": "OTROS TRASTORNOS MENTALES DEBIDOS A LESIÓN Y DISFUNCIÓN CEREBRAL, Y A ENFERMEDAD FÍSICA"
    },
    {
        "id": "fc3793d7-6278-46c9-5c1d-3349323a7338",
        "NOMBRE": "OTROS TRASTORNOS MENTALES ESPECIFICADOS DEBIDOS A LESIÓN Y DISFUNCIÓN CEREBRAL Y A ENFERMEDAD FÍSICA"
    },
    {
        "id": "15055c61-ea80-5403-3860-093034c04b08",
        "NOMBRE": "OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "90f23b8d-29b7-e6e8-2f1b-43b61a046933",
        "NOMBRE": "OTROS TRASTORNOS METABÓLICOS"
    },
    {
        "id": "6d7a37fb-3045-177c-5e13-6777a889fe52",
        "NOMBRE": "OTROS TRASTORNOS METABÓLICOS Y ENDOCRINOS CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "627a58d1-4145-82e2-04db-c15d35f7a487",
        "NOMBRE": "OTROS TRASTORNOS MIXTOS DE LA CONDUCTA Y DE LAS EMOCIONES"
    },
    {
        "id": "b891ad73-cdbc-ba3b-92ee-cecee9e932ba",
        "NOMBRE": "OTROS TRASTORNOS NEONATALES TRANSITORIOS DE LA COAGULACIÓN"
    },
    {
        "id": "9d0f9dcc-9265-3d06-7c4d-7a04c1c8faf1",
        "NOMBRE": "OTROS TRASTORNOS NEONATALES TRANSITORIOS DE LA FUNCIÓN TIROIDEA, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "0e204386-7447-de77-6840-5ec1c6411c9d",
        "NOMBRE": "OTROS TRASTORNOS NEONATALES TRANSITORIOS DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO"
    },
    {
        "id": "698dfdcd-e75f-75cf-789c-cdd6499d53f1",
        "NOMBRE": "OTROS TRASTORNOS NEUROMUSCULARES ESPECIFICADOS"
    },
    {
        "id": "79e4da64-f862-5d40-9915-ae18b03fa4b4",
        "NOMBRE": "OTROS TRASTORNOS NEURÓTICOS"
    },
    {
        "id": "a82dc5ca-a22d-9d5f-b2ca-5195dc5af1de",
        "NOMBRE": "OTROS TRASTORNOS NEURÓTICOS ESPECIFICADOS"
    },
    {
        "id": "77843b47-098c-3526-a1c9-cf55254a353a",
        "NOMBRE": "OTROS TRASTORNOS NO INFECCIOSOS DE LOS VASOS Y GANGLIOS LINFÁTICOS"
    },
    {
        "id": "fbe0e2bd-07b7-9413-c0b5-1dbb17763a70",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS DE LA VAGINA"
    },
    {
        "id": "1b795e69-9c45-f10a-4a59-d566d404613a",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS DE LA VULVA Y DEL PERINEO"
    },
    {
        "id": "8fe113f4-424f-8744-b496-48944379dea7",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "5702bf80-8f78-a732-f227-33e1527c7bb6",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS DEL OVARIO, DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO"
    },
    {
        "id": "2298b6e8-951a-0078-c556-7512844c46b5",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS DEL ÚTERO, EXCEPTO DEL CUELLO"
    },
    {
        "id": "8a6568f8-d836-2b26-1aa1-5d17742d6e1a",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DE LA VULVA Y DEL PERINEO"
    },
    {
        "id": "1cf0b454-ba10-4284-354a-0c52a5b36206",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "dbed8d8b-b763-b342-eff0-3d1f385fab54",
        "NOMBRE": "OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DEL ÚTERO"
    },
    {
        "id": "21d91139-7fe9-cfc5-d86d-9984c8e78049",
        "NOMBRE": "OTROS TRASTORNOS NO ORGÁNICOS DEL SUEÑO"
    },
    {
        "id": "012a1a39-5ee2-bb3f-5805-3a9c218f463a",
        "NOMBRE": "OTROS TRASTORNOS NO REUMÁTICOS DE LA VÁLVULA MITRAL"
    },
    {
        "id": "4ea08849-7175-a5f4-e51a-19ae9058675e",
        "NOMBRE": "OTROS TRASTORNOS NO REUMÁTICOS DE LA VÁLVULA TRICÚSPIDE"
    },
    {
        "id": "57b0f896-cfc2-26f5-809b-2e58838afeba",
        "NOMBRE": "OTROS TRASTORNOS OBSESIVO-COMPULSIVOS"
    },
    {
        "id": "6e05606c-db6a-39a3-0f3b-1be5aec24a78",
        "NOMBRE": "OTROS TRASTORNOS ORGÁNICOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO DEBIDOS A ENFERMEDAD, LESIÓN Y DISFUNCIÓN CEREBRALES"
    },
    {
        "id": "736a80c9-27b8-de6f-f73d-a0e532cfa1d6",
        "NOMBRE": "OTROS TRASTORNOS OSTEOMUSCULARES CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "c8c76de3-4c6f-d5d6-bb7f-aad8d9e1a1b8",
        "NOMBRE": "OTROS TRASTORNOS PAPULOESCAMOSOS"
    },
    {
        "id": "167fb09a-bdd4-656e-b3bc-43cd6ff156ab",
        "NOMBRE": "OTROS TRASTORNOS PAPULOESCAMOSOS ESPECIFICADOS"
    },
    {
        "id": "1aedbd68-f217-c750-5f7d-1d8d0049b3b1",
        "NOMBRE": "OTROS TRASTORNOS PERINATALES DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "2d28b837-28ec-efd8-a11a-88b2cfc105b3",
        "NOMBRE": "OTROS TRASTORNOS PERINATALES ESPECÍFICOS DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "8f908cde-4f5c-f249-76cf-50235e7e7aa9",
        "NOMBRE": "OTROS TRASTORNOS PLACENTARIOS"
    },
    {
        "id": "f2028796-7534-d22d-1ed0-955d6ccbe9a9",
        "NOMBRE": "OTROS TRASTORNOS POR TICS"
    },
    {
        "id": "f9ad9635-d089-5404-dd9c-8e3e2235bd9a",
        "NOMBRE": "OTROS TRASTORNOS POSTERIORES A LA MASTOIDECTOMÍA"
    },
    {
        "id": "34992334-02d8-e269-b182-f2835f37f1d8",
        "NOMBRE": "OTROS TRASTORNOS PRIMARIOS DE LOS MÚSCULOS"
    },
    {
        "id": "6720f8cd-4998-a2d8-2947-ee32184ee5e5",
        "NOMBRE": "OTROS TRASTORNOS PSICÓTICOS AGUDOS Y TRANSITORIOS"
    },
    {
        "id": "6ac10cdf-7ecd-9def-1e64-d57381e4d897",
        "NOMBRE": "OTROS TRASTORNOS PSICÓTICOS DE ORIGEN NO ORGÁNICO"
    },
    {
        "id": "3b6cae5b-74a3-f492-63fd-8c59bc4a884e",
        "NOMBRE": "OTROS TRASTORNOS QUE AFECTAN EL MECANISMO DE LA INMUNIDAD, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "22ef1cff-1b57-ee1f-3f2b-ad9a7b5c53da",
        "NOMBRE": "OTROS TRASTORNOS RESPIRATORIOS"
    },
    {
        "id": "97f3f040-367c-84e6-a2bb-b64aaa2c25c5",
        "NOMBRE": "OTROS TRASTORNOS RESPIRATORIOS CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "0ff6751f-5dec-73fe-372d-9ed472f1fc01",
        "NOMBRE": "OTROS TRASTORNOS RESPIRATORIOS ESPECIFICADOS"
    },
    {
        "id": "a73dd2a3-0271-fdb1-b43b-aace8914b0cd",
        "NOMBRE": "OTROS TRASTORNOS RESULTANTES DE LA FUNCIÓN TUBULAR RENAL ALTERADA"
    },
    {
        "id": "4be4498d-e9c0-6fc1-2ce4-f6465a31edec",
        "NOMBRE": "OTROS TRASTORNOS SINOVIALES Y TENDINOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "dc105317-0830-2330-607b-b9fbd7e68010",
        "NOMBRE": "OTROS TRASTORNOS SOMATOMORFOS"
    },
    {
        "id": "7df64b04-fe05-6e42-01b2-dfeab75e9317",
        "NOMBRE": "OTROS TRASTORNOS SUDORÍPAROS APOCRINOS"
    },
    {
        "id": "9fa05d6f-118b-6af8-0c48-29f68b36cd8b",
        "NOMBRE": "OTROS TRASTORNOS SUDORÍPAROS ECRINOS"
    },
    {
        "id": "623840b2-a256-d3f1-55db-3031556205ba",
        "NOMBRE": "OTROS TRASTORNOS TIROIDEOS"
    },
    {
        "id": "e2a9e009-e061-9060-801d-3571df75d37d",
        "NOMBRE": "OTROS TRASTORNOS TRANSITORIOS DEL METABOLISMO DE LOS CARBOHIDRATOS EN EL FETO Y EL RECIÉN NACIDO"
    },
    {
        "id": "917fb08e-21fe-b0e2-6e00-c1fecbe528e3",
        "NOMBRE": "OTROS TRASTORNOS URETRALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "09224ba3-fe28-4ea4-2a6a-bb018b2d235c",
        "NOMBRE": "OTROS TRASTORNOS VASCULARES DEL INTESTINO"
    },
    {
        "id": "59f70bfa-8a55-fa65-a6fd-80102b4782f6",
        "NOMBRE": "OTROS TRASTORNOS VASCULARES DEL IRIS Y DEL CUERPO CILIAR"
    },
    {
        "id": "b93ef848-44e5-8da7-f980-4f6b7b9665e3",
        "NOMBRE": "OTROS TRASTORNOS VASCULARES Y QUISTES CONJUNTIVALES"
    },
    {
        "id": "1f4b3afb-594f-0f5a-afb6-78558e1d8529",
        "NOMBRE": "OTROS TRASTORNOS VENOSOS ESPECIFICADOS"
    },
    {
        "id": "e3caf245-fda1-2a57-2607-9dca35e646f6",
        "NOMBRE": "OTROS TRASTORNOS Y LOS NO ESPECIFICADOS DE LA LACTANCIA"
    },
    {
        "id": "1e0f8c47-4de5-5c7d-2815-b3df1a0fdcbf",
        "NOMBRE": "OTROS TRASTORNOS Y LOS NO ESPECIFICADOS DEL SISTEMA CIRCULATORIO"
    },
    {
        "id": "6625a824-50dc-eb49-f3ac-cd0d9d8b1e47",
        "NOMBRE": "OTROS TRAUMAS OBSTÉTRICOS ESPECIFICADOS"
    },
    {
        "id": "1c57bd0c-7e35-fb99-0e26-50b47ba6a62a",
        "NOMBRE": "OTROS TRAUMATISMOS DE LA CABEZA, ESPECIFICADOS"
    },
    {
        "id": "f123c8a5-72b5-d8e6-4825-4964caa38bdf",
        "NOMBRE": "OTROS TRAUMATISMOS DE LA COLUMNA VERTEBRAL Y DEL TRONCO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "4a381b24-a468-29b2-120d-482047edf015",
        "NOMBRE": "OTROS TRAUMATISMOS DE LA MÉDULA ESPINAL CERVICAL Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "c0c49aff-e7ec-20fe-9b74-da7b7ddea8eb",
        "NOMBRE": "OTROS TRAUMATISMOS DE LA PIERNA, ESPECIFICADOS"
    },
    {
        "id": "f0c717f6-3900-da6b-5a3f-42f7f352c80f",
        "NOMBRE": "OTROS TRAUMATISMOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "0099d262-7f87-6d4e-53bf-dcc91d278edb",
        "NOMBRE": "OTROS TRAUMATISMOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "3485724b-094f-5c70-7918-9d6355a6e073",
        "NOMBRE": "OTROS TRAUMATISMOS DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "bdcebcd9-17ce-4c22-278b-8c33c80676c6",
        "NOMBRE": "OTROS TRAUMATISMOS DEL CORAZÓN"
    },
    {
        "id": "a56f2dd2-e6f2-d1fb-40ca-7ba207cd9713",
        "NOMBRE": "OTROS TRAUMATISMOS DEL CRÁNEO DURANTE EL NACIMIENTO"
    },
    {
        "id": "813eb15f-2ea0-f6a7-9646-c9acef78e2c2",
        "NOMBRE": "OTROS TRAUMATISMOS DEL CUELLO, ESPECIFICADOS"
    },
    {
        "id": "d9a179f6-661e-06a9-d1f4-dfb0f9c15f3a",
        "NOMBRE": "OTROS TRAUMATISMOS DEL NACIMIENTO"
    },
    {
        "id": "f16756cb-bb40-e287-9beb-df40fd2fcb14",
        "NOMBRE": "OTROS TRAUMATISMOS DEL NACIMIENTO EN EL CUERO CABELLUDO"
    },
    {
        "id": "bea1a61d-a0cb-c605-5ef2-15b242b203d0",
        "NOMBRE": "OTROS TRAUMATISMOS DEL NACIMIENTO EN EL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "f7b33247-b81f-3540-7a90-1567c8543a33",
        "NOMBRE": "OTROS TRAUMATISMOS DEL OJO Y DE LA ÓRBITA"
    },
    {
        "id": "b9257968-0f6f-8ec1-e3f8-42ca778a690f",
        "NOMBRE": "OTROS TRAUMATISMOS DEL PIE Y DEL TOBILLO, ESPECIFICADOS"
    },
    {
        "id": "0000d359-3fa7-4eb7-bf1e-74de644f97ac",
        "NOMBRE": "OTROS TRAUMATISMOS DEL PULMÓN"
    },
    {
        "id": "a52563b1-879d-9173-7291-4e48071f97ba",
        "NOMBRE": "OTROS TRAUMATISMOS DEL TÓRAX, ESPECIFICADOS"
    },
    {
        "id": "2e787ebd-4f73-1760-8498-13a513e4d286",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "b3997d9c-ec04-c92d-1974-8f269aac0807",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "6b92f275-ea24-c5d5-0d9c-0508a2cd3f40",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "65b819a3-a50b-57dc-42c9-ed7a4c6a167b",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "68c1678b-ce99-0fcf-d439-f590438e27dc",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "fa5dc44c-4a81-b9cb-74c7-17240163fd29",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DEL ANTEBRAZO"
    },
    {
        "id": "d5013b9b-2f43-7460-1d7c-004e1f3cd2cb",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "42a19e68-5230-acf8-40d3-286a69702142",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS DEL TRONCO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "cef79568-f2e8-490c-068c-f1fbf72bdee4",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "2bd1e5c0-469b-01e2-e341-6ef7c0b53f8b",
        "NOMBRE": "OTROS TRAUMATISMOS ESPECIFICADOS, DURANTE EL NACIMIENTO"
    },
    {
        "id": "93f54838-9e5f-1bd9-7b76-a53d4ccbc207",
        "NOMBRE": "OTROS TRAUMATISMOS INTRACRANEALES"
    },
    {
        "id": "92bf6e35-17c5-f4d8-f0f5-cb4d555557dc",
        "NOMBRE": "OTROS TRAUMATISMOS MÚLTIPLES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "593c7288-6c41-66fa-5ad5-c766d5996c3b",
        "NOMBRE": "OTROS TRAUMATISMOS MÚLTIPLES DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "9f65378e-0da8-3748-4276-4c9dc012af29",
        "NOMBRE": "OTROS TRAUMATISMOS MÚLTIPLES DEL ANTEBRAZO"
    },
    {
        "id": "312afa2d-918a-54cf-4df6-a1a76aabceda",
        "NOMBRE": "OTROS TRAUMATISMOS MÚLTIPLES DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "8be30345-fe39-c928-fc38-5dcbdb2d59b7",
        "NOMBRE": "OTROS TRAUMATISMOS OBSTÉTRICOS DE LOS ÓRGANOS PELVIANOS"
    },
    {
        "id": "935474da-faeb-c9b5-f9be-81dca057bbb4",
        "NOMBRE": "OTROS TRAUMATISMOS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "1c40ddfa-63b7-a051-7bf1-685da6089e21",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "3f6508ba-f2ab-c000-310b-2cda13aa4024",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "ca30978e-7e04-4d01-f0fd-2f3d51794a65",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DE LA PARED ANTERIOR DEL TÓRAX"
    },
    {
        "id": "97f24fd1-9adc-d1fb-4aa3-07cf3fbd8341",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DE LA PARED POSTERIOR DEL TÓRAX"
    },
    {
        "id": "1507157b-2382-ddf3-9ece-b60c62a71e2e",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DE LA PIERNA"
    },
    {
        "id": "61a879a9-0510-d183-c8e0-6a19ef2d057c",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "5ac0438b-b99a-5dc7-58c6-e69cfae06c6c",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DEL ANTEBRAZO"
    },
    {
        "id": "56948419-4e3f-b367-e145-c428e379dfb2",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "c5007f10-1abe-a1b6-45ee-c1c8b163f280",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DEL PÁRPADO Y DE LA REGIÓN PERIOCULAR"
    },
    {
        "id": "55ae2e96-d388-4afa-6211-7e6221255037",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "eafdb0d9-7511-1819-33bd-8e9adcc9a1dc",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES Y LOS NO ESPECIFICADOS DE LA GARGANTA"
    },
    {
        "id": "17465662-e590-af28-0fcd-b900f10a3fec",
        "NOMBRE": "OTROS TRAUMATISMOS SUPERFICIALES Y LOS NO ESPECIFICADOS DE LA MAMA"
    },
    {
        "id": "8774b4b9-feb3-a8bd-c9c1-62e553be08f1",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA CABEZA"
    },
    {
        "id": "515b1a9f-999b-56ba-8fc1-77affad808fe",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "467f9107-d2d8-be99-c2c8-fe5d521b6ceb",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA MÉDULA ESPINAL TORÁCICA"
    },
    {
        "id": "bcb7faf8-8557-965f-1d67-63f515cdcc49",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "bf6b2147-33f9-7fef-12a7-f9520d41faf7",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA PIERNA"
    },
    {
        "id": "a6282caa-e9e3-e4a0-49e3-3d311a2f155a",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "75b81a7e-46e2-a7d6-f15b-e0e51a55d76f",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL ANTEBRAZO"
    },
    {
        "id": "32158977-5c61-8a99-3639-8d6a8cd5cedf",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL CUELLO"
    },
    {
        "id": "0a9f8711-a2a4-f039-c0df-3793dfac97a3",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "9c2ad60c-3957-61b7-5d04-2e03d9b92eea",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "824236ba-1bee-599c-ab64-48857f6302ca",
        "NOMBRE": "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL TÓRAX"
    },
    {
        "id": "d0fe47f1-32e9-7342-fb15-d7cac8817ba6",
        "NOMBRE": "OTROS TUMORES BENIGNOS DE LA PIEL"
    },
    {
        "id": "e8a74378-0c87-cb71-47a8-06a035384894",
        "NOMBRE": "OTROS TUMORES BENIGNOS DEL TEJIDO CONJUNTIVO Y DE LOS TEJIDOS BLANDOS"
    },
    {
        "id": "a0a8611e-26f9-cccf-6317-b82ea187b583",
        "NOMBRE": "OTROS TUMORES BENIGNOS DEL ÚTERO"
    },
    {
        "id": "439d79e5-aca8-32e4-423a-a4200dec8e89",
        "NOMBRE": "OTROS TUMORES DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFÁTICO, DE LOS ÓRGANOS HEMATOPOYÉTICOS Y DE TEJIDOS AFINES"
    },
    {
        "id": "c24a06c1-b495-2942-cdc5-1efb6621ec56",
        "NOMBRE": "OTROS TUMORES ESPECIFICADOS DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFÁTICO, DE LOS ÓRGANOS HEMATOPOYÉTICOS Y DE TEJIDOS AFINES"
    },
    {
        "id": "13824556-2678-e1ca-3faa-4176246b9833",
        "NOMBRE": "OTROS TUMORES MALIGNOS DE LA PIEL"
    },
    {
        "id": "fe1d052a-e3fe-73ec-3c42-d6b1b0520d6d",
        "NOMBRE": "OTROS TUMORES MALIGNOS ESPECIFICADOS DEL TEJIDO LINFÁTICO, HEMATOPOYÉTICO Y TEJIDOS AFINES"
    },
    {
        "id": "8b39092b-8c8e-ac34-c614-c7a3a6ebc900",
        "NOMBRE": "OTROS TUMORES MALIGNOS Y LOS NO ESPECIFICADOS DEL TEJIDO LINFÁTICO, DE LOS ÓRGANOS HEMATOPOYÉTICOS Y DE TEJIDOS AFINES"
    },
    {
        "id": "2e3f0ccb-274c-4fab-3436-b9a66cdf09ba",
        "NOMBRE": "OTROS VÉRTIGOS PERIFÉRICOS"
    },
    {
        "id": "4c57ab97-22a0-5500-c809-abb0cba38b42",
        "NOMBRE": "OTROS VÓMITOS QUE COMPLICAN EL EMBARAZO"
    },
    {
        "id": "8357d5a5-52ad-a707-48d5-150604f35f8a",
        "NOMBRE": "PALIDEZ"
    },
    {
        "id": "85046fc3-7372-f4f5-d1c9-c826b15dca96",
        "NOMBRE": "PALPITACIONES"
    },
    {
        "id": "5c6504d1-a0fc-61db-6ed0-aed68d1fd5b0",
        "NOMBRE": "PALUDISMO [MALARIA] DEBIDO A PLASMODIUM FALCIPARUM"
    },
    {
        "id": "8009c321-3e47-ea85-ee99-65f1fa099d98",
        "NOMBRE": "PALUDISMO [MALARIA] DEBIDO A PLASMODIUM MALARIAE"
    },
    {
        "id": "f1be55f4-393d-1fec-6656-d779b50856ab",
        "NOMBRE": "PALUDISMO [MALARIA] DEBIDO A PLASMODIUM VIVAX"
    },
    {
        "id": "e2602fbc-6cd6-6658-b10b-5452d60d1a99",
        "NOMBRE": "PALUDISMO [MALARIA] NO ESPECIFICADO"
    },
    {
        "id": "5e091164-a87f-53da-d7e8-73e384211e4a",
        "NOMBRE": "PALUDISMO CONGÉNITO POR PLASMODIUM FALCIPARUM"
    },
    {
        "id": "de51de48-c59f-8337-d5b5-20522d651318",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIOS DE LOS SIMIOS"
    },
    {
        "id": "4be5bc0c-adbd-7b7c-ea42-9ff977e381c4",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM FALCIPARUM CON COMPLICACIONES CEREBRALES"
    },
    {
        "id": "c321c441-96ea-2866-f2c6-a4ad3c05ab9c",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM FALCIPARUM, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "a74f2b74-8817-951b-8276-696831069562",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM MALARIAE CON NEFROPATÍA"
    },
    {
        "id": "b32f5c26-91fd-fdbf-5025-f953d915a2f7",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM MALARIAE CON OTRAS COMPLICACIONES"
    },
    {
        "id": "088ccf74-ff62-19ad-a772-07da6fcc6bb4",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM MALARIAE, SIN COMPLICACIONES"
    },
    {
        "id": "6cc64088-856e-91b5-17ce-c74ba5f76eb1",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM OVALE"
    },
    {
        "id": "fd5ae465-24c1-f222-930f-69dffa40c04e",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM VIVAX CON OTRAS COMPLICACIONES"
    },
    {
        "id": "1cf2091d-6b01-f5a9-8650-2cf7127a9cea",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM VIVAX CON RUPTURA ESPLÉNICA"
    },
    {
        "id": "6e8765c2-771b-f176-0655-686580b47924",
        "NOMBRE": "PALUDISMO DEBIDO A PLASMODIUM VIVAX, SIN COMPLICACIONES"
    },
    {
        "id": "f8984898-1185-6b3b-b10c-4b6727c7e154",
        "NOMBRE": "PANCOLITIS ULCERATIVA (CRÓNICA)"
    },
    {
        "id": "828d320e-2e86-a4ca-74b7-42121e59de02",
        "NOMBRE": "PÁNCREAS ANULAR"
    },
    {
        "id": "9bca1988-5dd2-c72d-3f56-f4b8ef0e50bd",
        "NOMBRE": "PANCREATITIS AGUDA"
    },
    {
        "id": "f3263b73-8ccd-22fe-5f1c-3d0a7d4d5bde",
        "NOMBRE": "PANCREATITIS AGUDA"
    },
    {
        "id": "784c781c-6b18-8d45-7309-4d600a8a6220",
        "NOMBRE": "PANCREATITIS AGUDA INDUCIDA POR ALCOHOL"
    },
    {
        "id": "0f31093a-e3d0-05e7-6756-fdb024ca63fa",
        "NOMBRE": "PANCREATITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "260a8cc6-becd-cd9d-6b59-4a8d62c7c843",
        "NOMBRE": "PANCREATITIS BILIAR AGUDA"
    },
    {
        "id": "f03dcf3b-f7f8-60ae-c92b-6b1e2fdd72a1",
        "NOMBRE": "PANCREATITIS CRÓNICA INDUCIDA POR EL ALCOHOL"
    },
    {
        "id": "ddf8a4f5-8e6a-37fb-3e1a-75368d1edda5",
        "NOMBRE": "PANCREATITIS DEBIDA A VIRUS CITOMEGÁLICO"
    },
    {
        "id": "ca65cab3-1f32-903d-fa38-fd04901c985f",
        "NOMBRE": "PANCREATITIS IDIOPÁTICA AGUDA"
    },
    {
        "id": "9b8d3d39-f55c-a348-96ef-1d674b95d4f5",
        "NOMBRE": "PANCREATITIS POR PAROTIDITIS"
    },
    {
        "id": "f59c2caf-c39e-c33e-b641-d07d8d665c5e",
        "NOMBRE": "PANCREATITITS AGUDA INDUCIDA POR DROGAS"
    },
    {
        "id": "bfd9f401-35b2-c56b-7d47-9c35f821e972",
        "NOMBRE": "PANENCEFALITIS ESCLEROSANTE SUBAGUDA"
    },
    {
        "id": "c4450587-fdf2-b1b2-2a78-beff65882753",
        "NOMBRE": "PANICULITIS QUE AFECTA REGIONES DEL CUELLO Y DE LA ESPALDA"
    },
    {
        "id": "696d6de9-c83c-daa6-2d30-02d380efc5f5",
        "NOMBRE": "PANICULITIS RECIDIVANTE [WEBER-CHRISTIAN]"
    },
    {
        "id": "051c96fd-3bb8-0c11-02f2-2a0fb7899cec",
        "NOMBRE": "PANICULITIS, NO ESPECIFICADA"
    },
    {
        "id": "9d4de33d-8da7-ec3e-ed85-bdb45c7943ff",
        "NOMBRE": "PANMIELOSIS AGUDA CON MIELOFIBROSIS"
    },
    {
        "id": "5a774f7f-c3ba-0357-9ded-0fa08383d524",
        "NOMBRE": "PANSINUSITIS AGUDA"
    },
    {
        "id": "e38ff5bf-462e-0d0c-27d2-222bf2dfd609",
        "NOMBRE": "PANSINUSITIS CRÓNICA"
    },
    {
        "id": "db5e0f9f-039c-0ce3-8cc0-a36a7c918c3e",
        "NOMBRE": "PAPILEDEMA, NO ESPECIFICADO"
    },
    {
        "id": "8e282354-3944-e75f-a1a0-74defcf954b2",
        "NOMBRE": "PAPILOMAVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "7a619c66-9b85-914d-eb73-50f329553cfc",
        "NOMBRE": "PÁPULAS DE GOTTRON"
    },
    {
        "id": "edbd521d-c1e4-d600-1db2-79f94af3e4b7",
        "NOMBRE": "PAPULOSIS LINFOMATOIDE"
    },
    {
        "id": "979583ce-e581-ec85-f8bc-858813ab1b7b",
        "NOMBRE": "PAQUIPLEURITIS"
    },
    {
        "id": "1046fa8a-8751-b28d-ac4f-1633d9cbf41a",
        "NOMBRE": "PAQUIPLEURITIS CON ASBESTOSIS"
    },
    {
        "id": "b6729148-b784-02aa-b61b-4015d52c0266",
        "NOMBRE": "PAQUIPLEURITIS SIN ASBESTOSIS"
    },
    {
        "id": "fe150ff7-4269-d2c8-056f-de8ffbcc3c69",
        "NOMBRE": "PARACAIDISTA LESIONADO EN ACCIDENTE DE TRANSPORTE AÉREO"
    },
    {
        "id": "b933a304-9a14-24b0-86a5-ef013e86d931",
        "NOMBRE": "PARACOCCIDIOIDOMICOSIS"
    },
    {
        "id": "7955e9ae-766b-6ac1-58c6-24645ad2fa2b",
        "NOMBRE": "PARACOCCIDIOIDOMICOSIS DISEMINADA"
    },
    {
        "id": "bb9a7bc4-e5eb-b4a3-c2d1-d926d19c22e1",
        "NOMBRE": "PARACOCCIDIOIDOMICOSIS PULMONAR"
    },
    {
        "id": "5bb2752a-313d-3c62-bca8-fd96a9b9be54",
        "NOMBRE": "PARACOCCIDIOIDOMICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "b60b6301-3240-8e7c-9a9e-c2acc3935e63",
        "NOMBRE": "PARAGEUSIA"
    },
    {
        "id": "ff2c2520-96a4-6886-f2fe-b64e02a644ed",
        "NOMBRE": "PARAGONIMIASIS"
    },
    {
        "id": "9181b716-ba61-8483-1d77-d8aef591cfe2",
        "NOMBRE": "PARÁLISIS CEREBRAL"
    },
    {
        "id": "cf5e554a-41f4-44b1-f6b9-d1c9f831dadf",
        "NOMBRE": "PARÁLISIS CEREBRAL ATÁXICA"
    },
    {
        "id": "bf699e10-0361-94fb-7f3e-0a40992398fb",
        "NOMBRE": "PARÁLISIS CEREBRAL DISCINÉTICA"
    },
    {
        "id": "c74e3e72-a880-1cb5-51ec-c7d547a685c8",
        "NOMBRE": "PARÁLISIS CEREBRAL ESPÁSTICA CUADRIPLÉJICA"
    },
    {
        "id": "f605e343-d202-30a9-ecdc-56006b3497d2",
        "NOMBRE": "PARÁLISIS CEREBRAL ESPÁSTICA DIPLÉJICA"
    },
    {
        "id": "2835685f-83ef-7828-6b8a-e6437d9028c2",
        "NOMBRE": "PARÁLISIS CEREBRAL ESPÁSTICA HEMIPLÉJICA"
    },
    {
        "id": "6d3b0be7-5582-9930-5ae5-3820bb80881f",
        "NOMBRE": "PARÁLISIS CEREBRAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "d0c742db-57e8-7931-4b0a-aba6b2d75474",
        "NOMBRE": "PARÁLISIS DE BELL"
    },
    {
        "id": "5ee1cde1-6f37-970a-6d1e-2b48a9940dfc",
        "NOMBRE": "PARÁLISIS DE ERB DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "b8c607ae-4047-9b67-70b1-c5413443e0bf",
        "NOMBRE": "PARÁLISIS DE KLUMPKE DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "bb213f36-cde2-6f18-57b3-e3e6356b1429",
        "NOMBRE": "PARÁLISIS DE LA CONJUGACIÓN DE LA MIRADA"
    },
    {
        "id": "eea7bbfe-1cbd-cd29-b909-73d9315fea23",
        "NOMBRE": "PARÁLISIS DE LAS CUERDAS VOCALES Y DE LA LARINGE"
    },
    {
        "id": "e0ff0646-8a4c-1b03-1d0d-9e3c741f84c0",
        "NOMBRE": "PARÁLISIS DEL NERVIO FRÉNICO DEBIDA A TRAUMATISMO DEL NACIMIENTO"
    },
    {
        "id": "f88db49f-1c68-0d66-16d5-309ef1d72e00",
        "NOMBRE": "PARÁLISIS DEL NERVIO MOTOR OCULAR COMÚN [III PAR]"
    },
    {
        "id": "4747c72b-114e-6477-5472-fbfbeac0ed96",
        "NOMBRE": "PARÁLISIS DEL NERVIO MOTOR OCULAR EXTERNO [VI PAR]"
    },
    {
        "id": "df8996f5-892a-b0bf-8bd6-213701efa208",
        "NOMBRE": "PARÁLISIS DEL NERVIO PATÉTICO [IV PAR]"
    },
    {
        "id": "af330bd9-af15-cb5d-373d-355493414cb1",
        "NOMBRE": "PARÁLISIS FACIAL DE NEURONA MOTORA SUPERIOR"
    },
    {
        "id": "d755aa39-6545-fb90-ad48-f17411161d71",
        "NOMBRE": "PARÁLISIS FLÁCIDA AGUDA"
    },
    {
        "id": "bb9afa88-b1e4-e35d-bcc7-d88b54398bce",
        "NOMBRE": "PARÁLISIS MÚLTIPLE DE LOS NERVIOS CRANEALES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b66ce678-cf9c-ff30-76e0-1e61c3943b73",
        "NOMBRE": "PARÁLISIS MÚLTIPLE DE LOS NERVIOS CRANEALES, EN ENFERMEDADES NEOPLÁSICAS"
    },
    {
        "id": "5480eaa8-24d7-2ea8-0365-de68aeefec6c",
        "NOMBRE": "PARÁLISIS MÚLTIPLE DE LOS NERVIOS CRANEALES, EN LA SARCOIDOSIS"
    },
    {
        "id": "2ee9a93e-8e8f-1eef-2dd5-fa81f1178c7f",
        "NOMBRE": "PARÁLISIS PERIÓDICA"
    },
    {
        "id": "0bd57515-9f68-bcd6-7251-1453b251ff55",
        "NOMBRE": "PARAMETRITIS Y CELULITIS PÉLVICA AGUDA"
    },
    {
        "id": "01745603-296a-01fb-7067-00870d7c5969",
        "NOMBRE": "PARAMETRITIS Y CELULITIS PÉLVICA CRÓNICA"
    },
    {
        "id": "a904bed0-4832-61cf-4887-3327b5d34451",
        "NOMBRE": "PARAMETRITIS Y CELULITIS PÉLVICA NO ESPECIFICADA"
    },
    {
        "id": "51e67fbc-43c9-55c7-dad0-f779c8dd8a90",
        "NOMBRE": "PARAPLEJÍA ESPÁSTICA"
    },
    {
        "id": "cd365cf9-ad69-34cf-71b9-0b1435c7bae8",
        "NOMBRE": "PARAPLEJÍA ESPÁSTICA HEREDITARIA"
    },
    {
        "id": "4946991a-3732-8998-ecae-23272c93bd8f",
        "NOMBRE": "PARAPLEJÍA FLÁCIDA"
    },
    {
        "id": "97454c75-ab7a-35e3-dc5e-0c6537821a73",
        "NOMBRE": "PARAPLEJÍA Y CUADRIPLEJÍA"
    },
    {
        "id": "95ca7e3f-ba56-58fe-4c9d-7b0f98101d4c",
        "NOMBRE": "PARAPLEJÍA, NO ESPECIFICADA"
    },
    {
        "id": "4ad1f9d5-75ef-facc-be61-d44ce35ac95f",
        "NOMBRE": "PARAPSORIASIS"
    },
    {
        "id": "08fef561-9cfb-de84-52b3-0821b41a3008",
        "NOMBRE": "PARAPSORIASIS EN PLACAS GRANDES"
    },
    {
        "id": "5bff5088-7edb-970d-2b3f-6fd716552655",
        "NOMBRE": "PARAPSORIASIS EN PLACAS PEQUEÑAS"
    },
    {
        "id": "978701c4-2e75-5c07-b66c-05c30e017787",
        "NOMBRE": "PARAPSORIASIS RETIFORME"
    },
    {
        "id": "6d30161c-7d5e-97fe-ec7c-4f005c5e7624",
        "NOMBRE": "PARAPSORIASIS, NO ESPECIFICADA"
    },
    {
        "id": "1dcea339-5498-5435-5c4d-ae232245f4a9",
        "NOMBRE": "PARASITOSIS INTESTINAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "51e1a6d7-1c7b-5b8a-740d-ea61d9075485",
        "NOMBRE": "PARASITOSIS INTESTINAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "15c3d473-a67c-dd51-a986-a0b5e34bdedf",
        "NOMBRE": "PARESTESIA DE LA PIEL"
    },
    {
        "id": "f87d447e-37e4-fbdf-1775-373bc28163e0",
        "NOMBRE": "PARKINSONISMO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d08729b6-6dce-8d2f-3911-c508177d1f5e",
        "NOMBRE": "PARKINSONISMO POSTENCEFALÍTICO"
    },
    {
        "id": "329a294d-c07e-ff06-3e5c-8c0520a5d84e",
        "NOMBRE": "PARKINSONISMO SECUNDARIO"
    },
    {
        "id": "8d247e8a-c630-80d6-680b-20d63d7cde60",
        "NOMBRE": "PARKINSONISMO SECUNDARIO DEBIDO A OTROS AGENTES EXTERNOS"
    },
    {
        "id": "465ac3c6-6656-0b8c-11e9-980392964154",
        "NOMBRE": "PARKINSONISMO SECUNDARIO, NO ESPECIFICADO"
    },
    {
        "id": "1bfb6e5d-c72c-9224-3d10-968b84594280",
        "NOMBRE": "PARKINSONISMO VASCULAR"
    },
    {
        "id": "913d3c04-fc1f-0ebb-657e-f3f8a6847a4b",
        "NOMBRE": "PARO CARDÍACO"
    },
    {
        "id": "b1f89e43-5efd-31ab-2929-ddcd7bfa40d7",
        "NOMBRE": "PARO CARDÍACO CON RESUCITACIÓN EXITOSA"
    },
    {
        "id": "ac0c6c5c-0ad6-3063-5566-bf7fd50ac0f4",
        "NOMBRE": "PARO CARDÍACO, NO ESPECIFICADO"
    },
    {
        "id": "c6e7c6a6-30ee-ee7e-a4fa-94f1570f3cdb",
        "NOMBRE": "PARO RESPIRATORIO"
    },
    {
        "id": "b8175015-5454-7658-136e-c726a50c4cda",
        "NOMBRE": "PAROSMIA"
    },
    {
        "id": "ffe4f057-69de-ad4e-24e9-82b3cdca9772",
        "NOMBRE": "PAROTIDITIS INFECCIOSA"
    },
    {
        "id": "3a387b85-83ee-604a-7f7a-93f9c574343d",
        "NOMBRE": "PAROTIDITIS INFECCIOSA CON OTRAS COMPLICACIONES"
    },
    {
        "id": "1a6a180f-3d67-961d-6a31-202b8e00362b",
        "NOMBRE": "PAROTIDITIS, SIN COMPLICACIONES"
    },
    {
        "id": "319eae3f-ee44-bb7f-0672-82f527d09f77",
        "NOMBRE": "PARTO CON COMBINACIÓN DE FÓRCEPS Y VENTOSA EXTRACTORA"
    },
    {
        "id": "7805592a-2f4f-2774-a5eb-13acfe22cf07",
        "NOMBRE": "PARTO CON FÓRCEPS BAJO"
    },
    {
        "id": "f5835896-c97e-1b73-74d9-603664d59c47",
        "NOMBRE": "PARTO CON FÓRCEPS DE OTROS TIPOS Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "b09e974a-b414-a9fc-95c1-2a1ae81e4b4a",
        "NOMBRE": "PARTO CON FÓRCEPS MEDIO"
    },
    {
        "id": "ef073008-4f0c-6c0b-bc03-774d51d8d9de",
        "NOMBRE": "PARTO CON FÓRCEPS MEDIO CON ROTACIÓN"
    },
    {
        "id": "bfc25fb9-8ebc-e83c-e506-eccaa9700228",
        "NOMBRE": "PARTO CON VENTOSA EXTRACTORA"
    },
    {
        "id": "c8384ba6-1aff-5a88-cdde-19c2c9e36652",
        "NOMBRE": "PARTO DE FETO VIABLE EN EMBARAZO ABDOMINAL"
    },
    {
        "id": "849dccf6-fe16-1fd0-7677-f54f1ccf7ec3",
        "NOMBRE": "PARTO MÚLTIPLE"
    },
    {
        "id": "8700ffb9-efdf-13f5-b681-f9b5ae60866c",
        "NOMBRE": "PARTO MÚLTIPLE, NO ESPECIFICADO"
    },
    {
        "id": "b587239c-b856-67cc-f3d2-db6bb39a1473",
        "NOMBRE": "PARTO MÚLTIPLE, TODOS ESPONTÁNEOS"
    },
    {
        "id": "8d5d2eb2-9ce3-f27c-92a5-62397dd33f3b",
        "NOMBRE": "PARTO MÚLTIPLE, TODOS POR CESÁREA"
    },
    {
        "id": "e4694e83-995f-5d24-7f36-640dab011b47",
        "NOMBRE": "PARTO MÚLTIPLE, TODOS POR FÓRCEPS Y VENTOSA EXTRACTORA"
    },
    {
        "id": "7150d2d0-1282-aac6-694a-c0a6f158d538",
        "NOMBRE": "PARTO POR CESÁREA CON HISTERECTOMÍA"
    },
    {
        "id": "2bb7112f-7ce5-98d3-6ee9-bd537553f230",
        "NOMBRE": "PARTO POR CESÁREA DE EMERGENCIA"
    },
    {
        "id": "b6ea8440-293d-8ddd-672c-9c7ea64239c6",
        "NOMBRE": "PARTO POR CESÁREA ELECTIVA"
    },
    {
        "id": "2bc2ee72-2260-0baf-85e0-aea889fc2c19",
        "NOMBRE": "PARTO POR CESÁREA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "7f6a7b7d-a0ca-c088-0962-b06b7d4b5f85",
        "NOMBRE": "PARTO PREMATURO"
    },
    {
        "id": "1a8e7009-f79f-7ef9-51e4-3ba0f98debaa",
        "NOMBRE": "PARTO PREMATURO SIN TRABAJO DE PARTO ESPONTÁNEO"
    },
    {
        "id": "76ab0302-4dfd-9f24-0488-a0b3849f8746",
        "NOMBRE": "PARTO ÚNICO ASISTIDO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "5172ddf5-1158-4473-ff73-000826265dd9",
        "NOMBRE": "PARTO ÚNICO CON FÓRCEPS Y VENTOSA EXTRACTORA"
    },
    {
        "id": "253865bd-f0f3-98ef-636e-763afb966bca",
        "NOMBRE": "PARTO ÚNICO ESPONTÁNEO"
    },
    {
        "id": "6b256832-7b54-9e1b-e1d8-42975ad726d2",
        "NOMBRE": "PARTO ÚNICO ESPONTÁNEO, OTRAS PRESENTACIONES"
    },
    {
        "id": "80a121a3-5e38-55ba-7f75-530be82ac090",
        "NOMBRE": "PARTO ÚNICO ESPONTÁNEO, PRESENTACIÓN CEFÁLICA DE VÉRTICE"
    },
    {
        "id": "789c44ae-b835-d175-d1b7-a1436d1cabc4",
        "NOMBRE": "PARTO ÚNICO ESPONTÁNEO, PRESENTACIÓN DE NALGAS O PODÁLICA"
    },
    {
        "id": "26e2b174-3e90-a8e4-b273-3c9d78e5a6c4",
        "NOMBRE": "PARTO ÚNICO ESPONTÁNEO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "dd4b84a1-90d5-b044-7f90-5c29e6163763",
        "NOMBRE": "PARTO ÚNICO POR CESÁREA"
    },
    {
        "id": "8285624f-62b5-b7fc-7634-21881b967712",
        "NOMBRE": "PARTO VAGINAL POSTERIOR A UNA CESÁREA PREVIA"
    },
    {
        "id": "90ae2844-181e-83a7-e472-1fcd10801384",
        "NOMBRE": "PARVOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "79b4c429-27b8-0274-6565-9e8452de8abb",
        "NOMBRE": "PASAJERO DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6b6fef0b-245e-56a5-b322-22baaff332da",
        "NOMBRE": "PASAJERO DE AUTOBÚS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "f8aa8c9f-dcb8-2634-8da4-dafdc3003d25",
        "NOMBRE": "PASAJERO DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "0720ee54-ad79-3c14-088e-10a76bd757d0",
        "NOMBRE": "PASAJERO DE AUTOMÓVIL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0fdbfc1d-f2a1-902c-53bc-e95e92755183",
        "NOMBRE": "PASAJERO DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "9932ab50-0017-d658-7043-34d4c76c3746",
        "NOMBRE": "PASAJERO DE CAMIONETA O FURGONETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a5cee0ab-b551-b7ef-1515-9c1698792a5f",
        "NOMBRE": "PASAJERO DE MOTOCICLETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "3aad24ff-6417-235b-1693-91c217be55a5",
        "NOMBRE": "PASAJERO DE MOTOCICLETA LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "b0b8b329-dba7-5bbe-d0f1-ac44e0ec5a94",
        "NOMBRE": "PASAJERO DE VEHÍCULO AGRÍCOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "9cbdf5be-0158-34e3-9a81-afb1b6e2c9ad",
        "NOMBRE": "PASAJERO DE VEHÍCULO AGRÍCOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "e55c7578-f3be-591d-6ae4-daf4f46001ac",
        "NOMBRE": "PASAJERO DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a6f8a475-ad6d-4d73-136a-a1fe07271575",
        "NOMBRE": "PASAJERO DE VEHÍCULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f77fedf0-81d9-ae63-891d-45cd5b7ba103",
        "NOMBRE": "PASAJERO DE VEHÍCULO DE PEDAL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "1c293774-d9a3-4b84-bcea-2e2153663fed",
        "NOMBRE": "PASAJERO DE VEHÍCULO DE PEDAL LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "abcc48df-f13e-ae21-2b0e-3ac35578532e",
        "NOMBRE": "PASAJERO DE VEHÍCULO DE TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c224cc8f-1237-d615-6aa2-11665014d561",
        "NOMBRE": "PASAJERO DE VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "88e46016-b222-207b-771b-4d0faee8884e",
        "NOMBRE": "PASAJERO DE VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "03114f11-748e-ebcb-fd99-f6cee020a91c",
        "NOMBRE": "PASAJERO DE VEHÍCULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "ba4caee3-996d-c0e5-0954-79ea034a9b78",
        "NOMBRE": "PASAJERO DE VEHÍCULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "a7ca904d-a333-73a4-2354-18bf639535b3",
        "NOMBRE": "PASAJERO DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c5cb76f4-d25c-6761-6825-aa1b797a154b",
        "NOMBRE": "PASAJERO DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "0916a29e-a54f-828f-9a71-aa2c32cb8554",
        "NOMBRE": "PASAJERO DE VEHÍCULO TRANSPORTE PESADO LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "33559347-a1e2-54c4-2bcf-214b65426c98",
        "NOMBRE": "PASTEURELOSIS"
    },
    {
        "id": "8cdb3669-a43b-319a-1418-e8afc35b1e61",
        "NOMBRE": "PEATÓN LESIONADO EN ACCIDENTE DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "bf05d9cb-e596-437c-08a9-71452f6b315d",
        "NOMBRE": "PEATÓN LESIONADO EN ACCIDENTE DE TRÁNSITO QUE INVOLUCRA OTROS VEHÍCULOS DE MOTOR, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "0e70e6f4-6707-4d99-9e17-e606f50708d1",
        "NOMBRE": "PEATÓN LESIONADO EN ACCIDENTE DE TRANSPORTE NO ESPECIFICADO"
    },
    {
        "id": "2385abbb-2932-e8ca-8b4c-ea0d2ea9cacf",
        "NOMBRE": "PEATÓN LESIONADO EN ACCIDENTE NO DE TRÁNSITO NO ESPECIFICADO"
    },
    {
        "id": "09f6cb79-6404-f63f-7c79-00fb96858e53",
        "NOMBRE": "PEATÓN LESIONADO EN ACCIDENTE NO DE TRÁNSITO QUE INVOLUCRA OTROS VEHÍCULOS DE MOTOR, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "9cd60d7f-611c-950a-f3ed-79fb74453f9a",
        "NOMBRE": "PEATÓN LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"
    },
    {
        "id": "d0be56ab-1aa4-faf3-c08c-669899fe87e7",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA"
    },
    {
        "id": "ef76726c-80aa-d486-1d2c-2125e048cd19",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "64ca5ab6-aae5-b8f5-5784-3e1c97bcf3ed",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "9fa2d3d5-a796-7fc3-1f3c-dedfaaacf6d4",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON AUTOMÓVIL, CAMIONETA O FURGONETA, ACCIDENTE NO ESPECIFICADO COMO DE TRÁNSITO O NO DE TRÁNSITO"
    },
    {
        "id": "08a404f6-e0ea-3e1a-f431-5d5a1a1563af",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR"
    },
    {
        "id": "9881e7ea-57b3-004a-4037-3bef977172e4",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6024d080-1eb3-6fe1-ff5c-d3f036487ddf",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "c81a681e-294c-2d6c-aee8-4a998484bd37",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON OTROS VEHÍCULOS SIN MOTOR, ACCIDENTE NO ESPECIFICADO COMO DE TRÁNSITO O NO DE TRÁNSITO"
    },
    {
        "id": "0d8c8960-3dae-0575-9f6c-11147569cb3a",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "1b99406a-8728-5135-c09a-bb667a11296a",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "a8b49836-c562-3032-8eb7-67a515e0205e",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "f17753f6-37f5-156f-8ec4-198bc59e3568",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON TREN O VEHÍCULO DE RIELES, ACCIDENTE NO ESPECIFICADO COMO DE TRÁNSITO O NO DE TRÁNSITO"
    },
    {
        "id": "8cc7e6ac-4b9c-ab69-73d6-68acd182ec7a",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS"
    },
    {
        "id": "5bdb1a7e-1f1c-b827-d117-a37bc3ef7790",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "436a1b3b-d6f6-2132-b6f4-fa01fad8f635",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "20dfbebc-15ce-58fa-b341-d934fa0acb0f",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, ACCIDENTE NO ESPECIFICADO COMO DE TRÁNSITO O NO DE TRÁNSITO"
    },
    {
        "id": "bbceb161-69fb-1f11-0a74-d7d6a61ccafd",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL"
    },
    {
        "id": "b03606cc-897e-df8f-62f4-0e9bc4acb521",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "5e7daefb-faa6-a5de-f540-e9dac758fe8d",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "5ab2e212-6f09-3203-db2f-370c42a708de",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE PEDAL, ACCIDENTE NO ESPECIFICADO COMO DE TRÁNSITO O NO DE TRÁNSITO"
    },
    {
        "id": "9eda8c7c-c6d7-70bc-bb3d-fd743e019fab",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS"
    },
    {
        "id": "d318536b-7b59-accf-7d04-dcb16ebfde06",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "6215928c-20c0-1ff1-b271-268b8ab42c9c",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "497b01c4-10c1-41c1-6514-4ac554f8959c",
        "NOMBRE": "PEATÓN LESIONADO POR COLISIÓN CON VEHÍCULO DE TRANSPORTE PESADO O AUTOBÚS, ACCIDENTE NO ESPECIFICADO COMO DE TRÁNSITO O NO DE TRÁNSITO"
    },
    {
        "id": "01dba70e-2a83-911a-7975-263bbe2d8af4",
        "NOMBRE": "PEDICULOSIS DEBIDA A PEDICULUS HUMANUS CAPITIS"
    },
    {
        "id": "44e30aab-6235-50c0-fdac-03b2bdf31339",
        "NOMBRE": "PEDICULOSIS DEBIDA A PEDICULUS HUMANUS CORPORIS"
    },
    {
        "id": "d939fdc9-9d3d-9525-8b4f-0689eb61cef5",
        "NOMBRE": "PEDICULOSIS Y PHTHIRIASIS"
    },
    {
        "id": "63d1b411-4fd1-9fb7-6881-a549a26e4c03",
        "NOMBRE": "PEDICULOSIS Y PHTHIRIASIS MIXTAS"
    },
    {
        "id": "3e5a97b0-e2f5-f29a-e32f-67bd68287fbd",
        "NOMBRE": "PEDICULOSIS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "902d73f6-fc85-05d6-047f-48b44295b3df",
        "NOMBRE": "PEDOFILIA"
    },
    {
        "id": "c43073fc-3330-944b-2f09-1d9bde33ca6c",
        "NOMBRE": "PELIOSIS HEPÁTICA"
    },
    {
        "id": "d139d469-ea53-b7b8-f33e-227166f67fd9",
        "NOMBRE": "PELVIPERITONITIS GONOCÓCICA Y OTRAS INFECCIONES GONOCÓCICAS GENITOURINARIAS"
    },
    {
        "id": "93d6ab14-4458-7f3d-5a2a-c796106230a2",
        "NOMBRE": "PÉNFIGO"
    },
    {
        "id": "96f30424-2621-11bc-dd94-44b201980c96",
        "NOMBRE": "PÉNFIGO BRASILEÑO [FOGO SELVAGEM]"
    },
    {
        "id": "c0f3d5b9-2ef4-0f3e-db30-31a010bda8c8",
        "NOMBRE": "PÉNFIGO ERITEMATOSO"
    },
    {
        "id": "b1a27b04-3959-8633-3708-d803c25f7793",
        "NOMBRE": "PÉNFIGO FOLIÁCEO"
    },
    {
        "id": "fbde75fc-dcc5-3d74-a23c-9831ceb7b741",
        "NOMBRE": "PÉNFIGO INDUCIDO POR DROGAS"
    },
    {
        "id": "9bdb1528-d7bc-0ef5-e5b1-9d3376b0a913",
        "NOMBRE": "PÉNFIGO VEGETANTE"
    },
    {
        "id": "0b933425-2a97-cbcb-06c9-1aa591a2e2c8",
        "NOMBRE": "PÉNFIGO VULGAR"
    },
    {
        "id": "690b8b93-b90e-86e3-a8af-e4633f25bd92",
        "NOMBRE": "PÉNFIGO, NO ESPECIFICADO"
    },
    {
        "id": "e0ba2208-9c13-566a-8e64-fba8dbfbdb07",
        "NOMBRE": "PENFIGOIDE"
    },
    {
        "id": "85d4c845-7e36-787e-c455-3eb3b67d151b",
        "NOMBRE": "PENFIGOIDE CICATRICIAL"
    },
    {
        "id": "0fdaa17a-e767-4ba1-20f5-eb73adc69037",
        "NOMBRE": "PENFIGOIDE FLICTENULAR"
    },
    {
        "id": "81dd0491-03af-ca05-27cb-492cb555a0bf",
        "NOMBRE": "PENFIGOIDE OCULAR"
    },
    {
        "id": "0bd2b288-1ab7-c9eb-8dea-b8aa229bb6a3",
        "NOMBRE": "PENFIGOIDE, NO ESPECIFICADO"
    },
    {
        "id": "090e1df9-a790-ce0d-d7d3-ea5e6f55353a",
        "NOMBRE": "PENICILOSIS"
    },
    {
        "id": "e0675097-beb4-fb52-4606-56bc975bdb14",
        "NOMBRE": "PEQUEÑO MAL, NO ESPECIFICADO (SIN ATAQUE DE GRAN MAL)"
    },
    {
        "id": "447e2655-be03-4416-36e4-8768f47814f6",
        "NOMBRE": "PEQUEÑO PARA LA EDAD GESTACIONAL"
    },
    {
        "id": "a70cd420-6045-5d75-280a-a5c134e229b8",
        "NOMBRE": "PÉRDIDA ANORMAL DE PESO"
    },
    {
        "id": "3d2f77c3-a9bc-9595-f0b1-53c224170ff8",
        "NOMBRE": "PÉRDIDA CAPILAR ANÁGENA"
    },
    {
        "id": "64b62800-8140-4cd7-a6eb-3eadaaf71849",
        "NOMBRE": "PÉRDIDA CAPILAR TELÓGENA"
    },
    {
        "id": "c4b87181-71cc-8dee-ceeb-2ce3a3159d52",
        "NOMBRE": "PERDIDA DE DIENTES DEBIDA A ACCIDENTE, EXTRACCIÓN O ENFERMEDAD PERIODONTAL LOCAL"
    },
    {
        "id": "552d78f8-80c3-cc96-9bbf-cefdaa4dd1a7",
        "NOMBRE": "PÉRDIDA DE LÍQUIDO CEFALORRAQUÍDEO"
    },
    {
        "id": "2b71d0c4-d1d8-baf4-f392-3cc76a88621c",
        "NOMBRE": "PÉRDIDA DE LÍQUIDO CEFALORRAQUÍDEO POR PUNCIÓN ESPINAL"
    },
    {
        "id": "f285fb51-75ee-2d38-b28e-a3ee5f432183",
        "NOMBRE": "PÉRDIDA DE SANGRE FETAL"
    },
    {
        "id": "74e21115-1e08-5729-1f24-2f93e026a931",
        "NOMBRE": "PÉRDIDA DE SANGRE FETAL POR EL CORTE DEL CORDÓN UMBILICAL EN EL OTRO GEMELO"
    },
    {
        "id": "a87ef560-fbf3-a718-5a68-fb7103410c4e",
        "NOMBRE": "PÉRDIDA DE SANGRE FETAL POR LA PLACENTA"
    },
    {
        "id": "539709c4-1331-57c7-1941-6769f6357ece",
        "NOMBRE": "PÉRDIDA DE SANGRE FETAL POR RUPTURA DEL CORDÓN UMBILICAL"
    },
    {
        "id": "d39a4562-0fa9-2f38-b449-7e6f23e860a5",
        "NOMBRE": "PÉRDIDA DE SANGRE FETAL POR VASA PREVIA"
    },
    {
        "id": "b50d5ea7-ae80-bbe8-a299-ff4a731a8b04",
        "NOMBRE": "PÉRDIDA DE SANGRE FETAL, NO ESPECIFICADA"
    },
    {
        "id": "e0ac0ebb-b142-589a-d887-c94d3934904c",
        "NOMBRE": "PERDIDA NO CICATRICIAL DEL PELO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "59e01b52-6693-941d-8f72-923ffdddc756",
        "NOMBRE": "PERFORACIÓN ÁTICA DE LA MEMBRANA TIMPÁNICA"
    },
    {
        "id": "f326f522-5463-9764-27a9-7a3995cab84f",
        "NOMBRE": "PERFORACIÓN CENTRAL DE LA MEMBRANA TIMPÁNICA"
    },
    {
        "id": "983a0507-b508-4650-88e6-4e61a197d4de",
        "NOMBRE": "PERFORACIÓN CONGÉNITA DEL TABIQUE NASAL"
    },
    {
        "id": "e2e21e13-6ca3-2207-4c6e-06226df5bf11",
        "NOMBRE": "PERFORACIÓN DE LA MEMBRANA TIMPÁNICA"
    },
    {
        "id": "c391e29f-477c-aa8a-d99d-de3deaab8bda",
        "NOMBRE": "PERFORACIÓN DE LA MEMBRANA TIMPÁNICA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "ee2f4374-5461-65d3-aa84-82073fdc7b96",
        "NOMBRE": "PERFORACIÓN DE LA OREJA"
    },
    {
        "id": "244a1965-ac5c-d80f-3d4c-6de20667412d",
        "NOMBRE": "PERFORACIÓN DE LA VESÍCULA BILIAR"
    },
    {
        "id": "bbc100ca-88ed-605c-3687-640d85aa298c",
        "NOMBRE": "PERFORACIÓN DEL CONDUCTO BILIAR"
    },
    {
        "id": "79485231-64ec-cfe8-c1fe-797537fa770d",
        "NOMBRE": "PERFORACIÓN DEL ESÓFAGO"
    },
    {
        "id": "21ae1d04-01dd-2a65-1679-579f8d7ab7e4",
        "NOMBRE": "PERFORACIÓN DEL INTESTINO (NO TRAUMÁTICA)"
    },
    {
        "id": "de7514e7-8304-9c7b-c0f3-a4558db2aa41",
        "NOMBRE": "PERFORACIÓN INTESTINAL PERINATAL"
    },
    {
        "id": "78efddb4-c4cb-0ea2-2a1a-4fde25dad6a6",
        "NOMBRE": "PERIARTRITIS DE LA MUÑECA"
    },
    {
        "id": "466d6413-be05-0890-879c-298b88f8a688",
        "NOMBRE": "PERICARDITIS AGUDA"
    },
    {
        "id": "c2a75699-88fe-de27-2e04-dff56d0e539b",
        "NOMBRE": "PERICARDITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "c1daf403-d0c1-f9a3-a62c-7ed1dcff3ec3",
        "NOMBRE": "PERICARDITIS CONSTRICTIVA CRÓNICA"
    },
    {
        "id": "aa8a6d3b-6ad0-4f8d-c7c5-18a97058daea",
        "NOMBRE": "PERICARDITIS CRÓNICA ADHESIVA"
    },
    {
        "id": "8fce8b3a-dc0b-921a-349b-da1d61defc59",
        "NOMBRE": "PERICARDITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a04e633d-fd26-f148-3abb-795b06d3bba8",
        "NOMBRE": "PERICARDITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6e6a4983-2d15-c1cb-7d77-8d5df40dabc0",
        "NOMBRE": "PERICARDITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRAS PARTE"
    },
    {
        "id": "66b4572c-8cb1-2ca3-f620-b676db261ac5",
        "NOMBRE": "PERICARDITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "32d50c59-2ecf-2383-a102-8369f09b90e4",
        "NOMBRE": "PERICARDITIS IDIOPÁTICA AGUDA INESPECÍFICA"
    },
    {
        "id": "d2c71695-a290-fc07-fdc4-54f36ee0be35",
        "NOMBRE": "PERICARDITIS INFECCIOSA"
    },
    {
        "id": "75e87b40-5bcc-b4f5-b246-bff63eeff6f5",
        "NOMBRE": "PERICARDITIS REUMÁTICA AGUDA"
    },
    {
        "id": "425fc1d5-cfc6-2ce9-8b0e-6744a4ea5672",
        "NOMBRE": "PERICARDITIS REUMÁTICA CRÓNICA"
    },
    {
        "id": "14bd1f53-1645-72eb-8f45-a9bd32d7b286",
        "NOMBRE": "PERICONDRITIS DEL OÍDO EXTERNO"
    },
    {
        "id": "fc22c58f-26c0-a5ee-500e-f85e7d315b48",
        "NOMBRE": "PERIFOLICULITIS CAPITIS ABSCEDENS"
    },
    {
        "id": "a7108317-a5aa-f2dc-a283-506bcfb94df4",
        "NOMBRE": "PERIODONTITIS AGUDA"
    },
    {
        "id": "7be1e371-53bc-d24a-efcf-42f343a2b69f",
        "NOMBRE": "PERIODONTITIS APICAL AGUDA ORIGINADA EN LA PULPA"
    },
    {
        "id": "f509fe7f-51be-0871-ffe7-e5a94aebf2a9",
        "NOMBRE": "PERIODONTITIS APICAL CRÓNICA"
    },
    {
        "id": "13d9d71c-ee7c-6927-34d1-b007e382af60",
        "NOMBRE": "PERIODONTITIS CRÓNICA"
    },
    {
        "id": "5692234c-e231-f2a7-db0c-73e93435e11d",
        "NOMBRE": "PERIODONTOSIS"
    },
    {
        "id": "1e2066ae-4d36-107b-2c4b-7579a142ec87",
        "NOMBRE": "PERIOSTITIS EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "39214114-df86-d481-b140-fcb3c5d67d76",
        "NOMBRE": "PERISTALSIS VISIBLE"
    },
    {
        "id": "c990e9bd-528d-df52-9ec5-a4f16d40369c",
        "NOMBRE": "PERITONITIS"
    },
    {
        "id": "c1b21cf7-562e-9779-c3fe-a38c418e180a",
        "NOMBRE": "PERITONITIS AGUDA"
    },
    {
        "id": "4036bfe6-ec1d-8c9e-0bc1-3d371ec92bd1",
        "NOMBRE": "PERITONITIS GONOCÓCICA"
    },
    {
        "id": "1ca843a7-494d-cba4-87a4-15e10ec4defe",
        "NOMBRE": "PERITONITIS PÉLVICA AGUDA, FEMENINA"
    },
    {
        "id": "db7d5057-b87f-6205-12fa-8a79f29242fd",
        "NOMBRE": "PERITONITIS PÉLVICA CRÓNICA, FEMENINA"
    },
    {
        "id": "35a202ff-1be6-0103-9061-22fe0191fa48",
        "NOMBRE": "PERITONITIS PÉLVICA FEMENINA, NO ESPECIFICADA"
    },
    {
        "id": "0f8efae0-718e-baa8-deba-377b11fb0c7d",
        "NOMBRE": "PERITONITIS POR CLAMIDIAS"
    },
    {
        "id": "3c343d87-e4e6-183b-7545-5e7ad2532f68",
        "NOMBRE": "PERITONITIS SIFILÍTICA"
    },
    {
        "id": "d0fe465d-c68f-9a76-9297-fe9113662721",
        "NOMBRE": "PERITONITIS TUBERCULOSA"
    },
    {
        "id": "1429857f-9de7-d22f-e2cf-b547c2a29dd5",
        "NOMBRE": "PERITONITIS, NO ESPECIFICADA"
    },
    {
        "id": "ef758881-4107-a6d4-8284-781ed6b54aee",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD"
    },
    {
        "id": "c4185e66-5690-b2ad-8501-d411b4a8d394",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "7ddb035a-ae37-8649-2c38-31e06dc1c82e",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "cbbb30c4-4f4d-489f-3719-47299f322f60",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, CALLES Y CARRETERAS"
    },
    {
        "id": "e8fc8123-8164-7912-d0a9-874cb2d36088",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "a798f699-9da7-93fa-9f93-b784707131ea",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "d34f46bb-1179-c864-a275-a397d8307956",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, GRANJA"
    },
    {
        "id": "cf2e2ea3-ed3c-a10b-d93e-2fc174f49ef2",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "13f59243-1af2-c22d-838a-f1471eebcf26",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "8fca6642-767d-f9f0-e815-8e864daec0c2",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "a871dd1f-55e2-3886-b982-da57f27b57b9",
        "NOMBRE": "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, VIVIENDA"
    },
    {
        "id": "ba1f3a3f-a006-f9f3-5038-cc0a396e71e5",
        "NOMBRE": "PERSISTENCIA DE LA CIRCULACIÓN FETAL"
    },
    {
        "id": "e295a153-5936-7a86-8152-71e53dc0c523",
        "NOMBRE": "PERSISTENCIA DE LA CLOACA"
    },
    {
        "id": "8aa6265f-5fbb-06ed-8ffa-63e056d33d39",
        "NOMBRE": "PERSISTENCIA DE LA VENA CAVA SUPERIOR IZQUIERDA"
    },
    {
        "id": "264e6603-6521-3ee8-b8a3-1a6ac2132206",
        "NOMBRE": "PERSISTENCIA HEREDITARIA DE LA HEMOGLOBINA FETAL [PHHF]"
    },
    {
        "id": "b8079466-db32-8837-53a5-5062e6f63b69",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA"
    },
    {
        "id": "a75487f7-cdfe-b555-ae6b-c01d41022363",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "71bc4cbc-be9b-03f4-28a9-f665cff32fd1",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "b87bde3f-245b-738a-52d6-163e062f1a4e",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, CALLES Y CARRETERAS"
    },
    {
        "id": "22796080-fea7-5832-32a7-1269ba62777b",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "5f50403c-b354-cf78-e15c-2e5bc855d71e",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "f0692396-202b-3255-3dc3-13fd7a7a87e2",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, GRANJA"
    },
    {
        "id": "d0fc4b78-e9c4-59de-2959-2df4b809b595",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "523c02d8-6b9b-8a2e-671d-8c8148d7baa6",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "9b4cd86f-fac4-ced9-94f2-04d47dfc257b",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "8375f846-2f78-e0a1-6675-6a7d3785024c",
        "NOMBRE": "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, VIVIENDA"
    },
    {
        "id": "a6e2d589-6a51-280e-2d8e-53093e7fbaf8",
        "NOMBRE": "PERSONA EN CONTACTO CON LOS SERVICIOS DE SALUD EN CIRCUNSTANCIAS NO ESPECIFICADAS"
    },
    {
        "id": "0fcda3c8-6c61-b80a-cb29-bc39beed7ca1",
        "NOMBRE": "PERSONA EN CONTACTO CON LOS SERVICIOS DE SALUD EN OTRAS CIRCUNSTANCIAS ESPECIFICADAS"
    },
    {
        "id": "8319e7f4-6c38-63ae-be73-fe599f4ce763",
        "NOMBRE": "PERSONA EN CONTACTO CON LOS SERVICIOS DE SALUD PARA PROCEDIMIENTOS ESPECÍFICOS NO REALIZADOS"
    },
    {
        "id": "b4ff42a8-74a9-2c99-0b09-8b2d5bff5cc7",
        "NOMBRE": "PERSONA EN TIERRA LESIONADA POR ACCIDENTE DE TRANSPORTE AÉREO"
    },
    {
        "id": "48cdd8cd-9d8a-5dca-2a9e-a06006d6a16f",
        "NOMBRE": "PERSONA LESIONADA AL SUBIR O BAJAR DE UNA AERONAVE"
    },
    {
        "id": "c0d14383-2d4b-b7a4-1345-c15755ebeaeb",
        "NOMBRE": "PERSONA LESIONADA AL SUBIR O BAJAR DEL TRANVÍA"
    },
    {
        "id": "d996cedf-2d5c-2cf0-e709-38ac1388d524",
        "NOMBRE": "PERSONA LESIONADA AL SUBIR O BAJAR DEL TREN O VEHÍCULO DE RIELES"
    },
    {
        "id": "34d2965c-093e-1ff5-c7e9-e5047dd0cbb1",
        "NOMBRE": "PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO AGRÍCOLA ESPECIAL"
    },
    {
        "id": "fbfabe0f-e841-2e0f-ab2b-8509cbde30ca",
        "NOMBRE": "PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN"
    },
    {
        "id": "4a37d159-6ee0-fbdd-f298-82ce70a29080",
        "NOMBRE": "PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHÍCULO INDUSTRIAL ESPECIAL"
    },
    {
        "id": "31fdac67-6531-9fe2-55a1-163d18312a76",
        "NOMBRE": "PERSONA LESIONADA EN ACCIDENTE DE TRÁNSITO AL SUBIR O BAJAR DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE CARRETERA"
    },
    {
        "id": "b7495c0c-f93c-8c1c-53fc-f4b5e04f8d9a",
        "NOMBRE": "PERSONA LESIONADA EN ACCIDENTE DE TRÁNSITO, DE VEHÍCULO DE MOTOR NO ESPECIFICADO"
    },
    {
        "id": "63dd0046-c462-7402-c374-89e152854e5b",
        "NOMBRE": "PERSONA LESIONADA EN ACCIDENTE DE TRÁNSITO, DE VEHÍCULO SIN MOTOR NO ESPECIFICADO"
    },
    {
        "id": "7f795c7e-39aa-dd1a-e523-a5d73f735155",
        "NOMBRE": "PERSONA LESIONADA EN ACCIDENTE DE VEHÍCULO NO ESPECIFICADO"
    },
    {
        "id": "435ba9c2-47c2-a63b-b491-f95ba219e8a9",
        "NOMBRE": "PERSONA LESIONADA EN ACCIDENTE NO DE TRÁNSITO, DE VEHÍCULO DE MOTOR NO ESPECIFICADO"
    },
    {
        "id": "ba9ecd64-df8f-2725-8810-822426a75504",
        "NOMBRE": "PERSONA LESIONADA EN ACCIDENTE NO DE TRÁNSITO, DE VEHÍCULO SIN MOTOR NO ESPECIFICADO"
    },
    {
        "id": "a51fdd66-8db1-b8d2-8139-f81728f7ff99",
        "NOMBRE": "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHÍCULO DE MOTOR SIN COLISIÓN (TRÁNSITO)"
    },
    {
        "id": "ca785b00-5381-f3eb-a53d-e092e07332a0",
        "NOMBRE": "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHÍCULO DE MOTOR SIN COLISIÓN, NO DE TRÁNSITO"
    },
    {
        "id": "a57ab138-4c6f-d37b-adbf-c270cb47ca7c",
        "NOMBRE": "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHÍCULO SIN MOTOR (CON COLISIÓN)(SIN COLISIÓN), NO DE TRÁNSITO"
    },
    {
        "id": "b2aabba9-48f4-f58a-1259-7ac1d03dd230",
        "NOMBRE": "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHÍCULO SIN MOTOR (CON COLISIÓN)(SIN COLISIÓN)(TRÁNSITO)"
    },
    {
        "id": "15f3c024-d930-d1c5-6790-19f87ba78005",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y AUTOBÚS (TRÁNSITO)"
    },
    {
        "id": "c7a4c3bb-101e-07e6-d651-9217886f329a",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y AUTOBÚS, NO DE TRÁNSITO"
    },
    {
        "id": "caeef97d-844a-343b-c6eb-ebbb35a9314b",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y CAMIONETA O FURGONETA (TRÁNSITO)"
    },
    {
        "id": "24717e99-752e-9887-007d-64c4cf3cb802",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y CAMIONETA O FURGONETA, NO DE TRÁNSITO"
    },
    {
        "id": "58d3df56-6a12-ee46-f09d-a45ec62dd01d",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS (TRÁNSITO)"
    },
    {
        "id": "221076e7-0213-09b2-7d65-7ea2ce1d1f94",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, NO DE TRÁNSITO"
    },
    {
        "id": "831b9e59-baf1-e599-9260-68227b403883",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y VEHÍCULO DE TRANSPORTE PESADO (TRÁNSITO)"
    },
    {
        "id": "21c8b6af-2a09-b18d-cfad-498b510bbd0c",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE AUTOMÓVIL Y VEHÍCULO DE TRANSPORTE PESADO, NO DE TRÁNSITO"
    },
    {
        "id": "2a019911-79ac-371a-22f6-88231fe0fb46",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE OTROS VEHÍCULOS DE MOTOR ESPECIFICADOS (TRÁNSITO)"
    },
    {
        "id": "5c2c97b1-5461-29d7-24c7-f1d0871e2c41",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE OTROS VEHÍCULOS DE MOTOR ESPECIFICADOS, NO DE TRÁNSITO"
    },
    {
        "id": "5e3abce4-4f8d-8aab-b210-d5b459cd6bc0",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE OTROS VEHÍCULOS DE MOTOR Y UN VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS (TRÁNSITO)"
    },
    {
        "id": "b6e90602-0b36-ac15-8f1d-f3e29511ba2a",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE OTROS VEHÍCULOS DE MOTOR Y UN VEHÍCULO DE MOTOR DE DOS O TRES RUEDAS, NO DE TRÁNSITO"
    },
    {
        "id": "bf698e11-28e8-8efb-7de3-cdb5615c5258",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE TREN O VEHÍCULO DE RIELES Y AUTOMÓVIL (TRÁNSITO)"
    },
    {
        "id": "688e4396-0c0c-a686-0019-15972a73743f",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE TREN O VEHÍCULO DE RIELES Y AUTOMÓVIL, NO DE TRÁNSITO"
    },
    {
        "id": "66fc52e9-4409-2519-4543-88a9d3a78c31",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE VEHÍCULO DE TRANSPORTE PESADO Y AUTOBÚS (TRÁNSITO)"
    },
    {
        "id": "dbd02d2d-bc7d-aeeb-a062-1c4f49afa954",
        "NOMBRE": "PERSONA LESIONADA POR COLISIÓN ENTRE VEHÍCULO DE TRANSPORTE PESADO Y AUTOBÚS, NO DE TRÁNSITO"
    },
    {
        "id": "e4969347-e119-63e1-7c81-74a3893812ce",
        "NOMBRE": "PERSONA QUE CONSULTA CON SIMULACIÓN CONSCIENTE [SIMULADOR]"
    },
    {
        "id": "388c280a-95f0-93cc-fe8a-3847039764de",
        "NOMBRE": "PERSONA QUE CONSULTA EN NOMBRE DE OTRA PERSONA"
    },
    {
        "id": "bd4e3592-85fd-4972-bc29-4bda8e28fa15",
        "NOMBRE": "PERSONA QUE CONSULTA PARA LA EXPLICACIÓN DE HALLAZGOS DE INVESTIGACIÓN"
    },
    {
        "id": "54e91b47-fa51-f980-ffc6-317b86ec0c2c",
        "NOMBRE": "PERSONA QUE TEME ESTAR ENFERMA, A QUIEN NO SE HACE DIAGNÓSTICO"
    },
    {
        "id": "ed4a05ef-12e6-905e-fddd-9d3fa33e3d7d",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DE VEHÍCULO INDUSTRIAL ESPECIAL LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "771ff3e8-a94d-06ee-2ca2-278d8b59f573",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DE VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO MOTOR PARA USO FUERA DE CARRETERA LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "ba92f742-66a3-f40a-8c1b-3e29b51d4eb7",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DEL VEHÍCULO AGRÍCOLA ESPECIAL LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "c6bd604a-fbed-eca8-7985-4fdbe7e11653",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DEL VEHÍCULO AGRÍCOLA ESPECIAL LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "6e76798a-4eac-fc9d-7a6e-8d992a1a4cc0",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DEL VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "de020db4-132f-f764-6a85-f087b52d897a",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DEL VEHÍCULO ESPECIAL PARA CONSTRUCCIÓN LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "30f80f2a-9499-00cf-2e54-2c1f7475c28f",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DEL VEHÍCULO INDUSTRIAL ESPECIAL LESIONADA EN ACCIDENTE NO DE TRÁNSITO"
    },
    {
        "id": "72c16e86-2d54-3125-e7c0-a5f149c4f520",
        "NOMBRE": "PERSONA QUE VIAJA FUERA DEL VEHÍCULO PARA TODO TERRENO O DE OTRO VEHÍCULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADA EN ACCIDENTE DE TRÁNSITO"
    },
    {
        "id": "981d3db8-a014-1d75-5a07-aa9e6baa04a4",
        "NOMBRE": "PERSONA SANA QUE ACOMPAÑA AL ENFERMO"
    },
    {
        "id": "b550a9c6-f793-147a-c8c5-d601532cfa94",
        "NOMBRE": "PERSONAS EN CONTACTO CON LOS SERVICIOS DE SALUD POR OTRAS CIRCUNSTANCIAS"
    },
    {
        "id": "fc532357-c256-33e0-7690-53841dfa9f58",
        "NOMBRE": "PERSONAS EN CONTACTO CON LOS SERVICIOS DE SALUD POR OTRAS CONSULTAS Y CONSEJOS MÉDICOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "36f1c753-fc97-15dd-5104-5c56b24c8d35",
        "NOMBRE": "PERTURBACIÓN DE LA ACTIVIDAD Y DE LA ATENCIÓN"
    },
    {
        "id": "38dc24a6-92ba-8a5c-5f64-f9a22dc7c9ab",
        "NOMBRE": "PESADILLAS"
    },
    {
        "id": "11b52246-f4a7-366a-4172-a0b4ca74e6d1",
        "NOMBRE": "PESO EXTREMADAMENTE BAJO AL NACER"
    },
    {
        "id": "bd8a0cdb-e68b-901c-f359-4833c20b6200",
        "NOMBRE": "PESQUISA PRENATAL DE MALFORMACIONES USANDO ULTRASONIDO Y OTROS MÉTODOS FÍSICOS"
    },
    {
        "id": "2ed79d92-22ae-c2d6-5b40-74e6c0f7935c",
        "NOMBRE": "PESQUISA PRENATAL DEL RETARDO DEL CRECIMIENTO FETAL USANDO ULTRASONIDO Y OTROS MÉTODOS FÍSICOS"
    },
    {
        "id": "f75f4b97-0cac-28aa-0432-4d10f11a67cb",
        "NOMBRE": "PESQUISA PRENATAL PARA ANOMALÍAS CROMOSÓMICAS"
    },
    {
        "id": "f4b83209-4479-f288-0982-665c81cc3fc5",
        "NOMBRE": "PESQUISA PRENATAL PARA ISOINMUNIZACIÓN"
    },
    {
        "id": "ca92cdeb-4c7c-0290-4a5b-d300afa9e92c",
        "NOMBRE": "PESQUISA PRENATAL PARA MEDIR NIVELES ELEVADOS DE ALFAFETOPROTEÍNAS"
    },
    {
        "id": "16545973-c8fd-829e-d905-c2f917a578f5",
        "NOMBRE": "PESQUISA PRENATAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "0877f4bf-7cb3-2984-327b-815b47e94e96",
        "NOMBRE": "PESQUISAS PRENATALES"
    },
    {
        "id": "a0d259dc-960c-53e1-d548-e3382a89f14e",
        "NOMBRE": "PESTE"
    },
    {
        "id": "8f809d22-dfb1-c0a7-82a9-1e55f17a7830",
        "NOMBRE": "PESTE BUBÓNICA"
    },
    {
        "id": "4c86ba10-508f-6fda-047c-53874957d3e3",
        "NOMBRE": "PESTE CELULOCUTÁNEA"
    },
    {
        "id": "dd39433a-1282-e5d1-e91e-fc4dda24cabc",
        "NOMBRE": "PESTE NEUMÓNICA"
    },
    {
        "id": "e7bc7c58-8125-c55d-4034-af2f08f0f227",
        "NOMBRE": "PESTE SEPTICÉMICA"
    },
    {
        "id": "cdc40a92-f55a-7995-d338-f5dbb1a7bc9d",
        "NOMBRE": "PESTE, NO ESPECIFICADA"
    },
    {
        "id": "69ea8fac-8d58-be6b-cde1-9f1d26d62863",
        "NOMBRE": "PETROSITIS"
    },
    {
        "id": "54c38aff-2428-10e6-d302-bd16b76de61d",
        "NOMBRE": "PEZÓN SUPERNUMERARIO"
    },
    {
        "id": "b445a71e-3489-b3d1-bb8a-421e65cf4783",
        "NOMBRE": "PHTHIRIASIS"
    },
    {
        "id": "c5dd6907-f9a3-ddd2-de05-01ebd1780bdf",
        "NOMBRE": "PICA EN LA INFANCIA Y LA NIÑEZ"
    },
    {
        "id": "bdee3199-dd7b-c4a4-e69c-bcffa14e084f",
        "NOMBRE": "PIE CAVUS"
    },
    {
        "id": "fd8f1b91-293f-65e2-8b95-eeb01fd6e85d",
        "NOMBRE": "PIE HENDIDO"
    },
    {
        "id": "e2156fc9-a7b2-5df8-f922-92bcbb089fea",
        "NOMBRE": "PIE PLANO [PES PLANUS] (ADQUIRIDO)"
    },
    {
        "id": "7e6d9627-c109-b50e-e09e-f782c3060b82",
        "NOMBRE": "PIE PLANO CONGÉNITO"
    },
    {
        "id": "28a260cd-d761-6bfd-5a72-ccf0a445b0f2",
        "NOMBRE": "PIEDRA BLANCA"
    },
    {
        "id": "b6132892-2e1b-c5f0-edf2-d078215e8339",
        "NOMBRE": "PIEDRA NEGRA"
    },
    {
        "id": "ba83da5f-92a5-3014-390b-a76b4f309a6f",
        "NOMBRE": "PIEL LAXA SENIL"
    },
    {
        "id": "455be53c-e953-2849-e645-97c901b7fc14",
        "NOMBRE": "PIEL ROMBOIDAL DE LA NUCA"
    },
    {
        "id": "b42639d6-3818-c409-3c1f-201b5fd342b1",
        "NOMBRE": "PIEL Y TEJIDO SUBCUTÁNEO EXCESIVA Y REDUNDANTE"
    },
    {
        "id": "83fc5c16-336a-2436-ba2c-b9214a6ad202",
        "NOMBRE": "PIELONEFRITIS CRÓNICA NO OBSTRUCTIVA ASOCIADA CON REFLUJO"
    },
    {
        "id": "7ca34a1a-5405-4585-bfd6-f662c5769f17",
        "NOMBRE": "PIELONEFRITIS CRÓNICA OBSTRUCTIVA"
    },
    {
        "id": "6578945d-3a19-371d-9cc0-6f0467e3e8b8",
        "NOMBRE": "PIGMENTACIONES Y DEPÓSITOS EN LA CÓRNEA"
    },
    {
        "id": "8495e6df-dea8-c300-b55e-6819a7f891e8",
        "NOMBRE": "PINTA [CARATE]"
    },
    {
        "id": "aadec189-d052-e745-ad83-4f80855efbc2",
        "NOMBRE": "PINTA, NO ESPECIFICADA"
    },
    {
        "id": "6d40a94d-4e02-c77f-c8b4-3cbb92b26903",
        "NOMBRE": "PIODERMA"
    },
    {
        "id": "43edf579-7873-ffa0-a323-d54723c81395",
        "NOMBRE": "PIODERMA GANGRENOSO"
    },
    {
        "id": "41af9274-306b-a14b-9f6f-5f2c87ebe46f",
        "NOMBRE": "PIONEFROSIS"
    },
    {
        "id": "7c9e5ed2-eeb3-b726-1ba7-5746b41bbb10",
        "NOMBRE": "PIOTÓRAX"
    },
    {
        "id": "295c91f6-c783-2c34-9956-0987e5a04237",
        "NOMBRE": "PIOTÓRAX CON FÍSTULA"
    },
    {
        "id": "00e986df-e877-6ec4-dcc2-df8e4e8d735a",
        "NOMBRE": "PIOTÓRAX SIN FÍSTULA"
    },
    {
        "id": "014722c5-0421-96af-fff1-99c2e0edd05c",
        "NOMBRE": "PIREXIA DE ORIGEN DESCONOCIDO CONSECUTIVA AL PARTO"
    },
    {
        "id": "d3aea6da-0e13-94f6-cb52-be5bbd00d09c",
        "NOMBRE": "PIREXIA DURANTE EL TRABAJO DE PARTO, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "600c87c2-0923-e600-437b-cca7c3e643c9",
        "NOMBRE": "PIROMANÍA"
    },
    {
        "id": "924b0227-8dba-2dd6-87a5-250f8ed97f4a",
        "NOMBRE": "PITIRIASIS ALBA"
    },
    {
        "id": "c9d1acea-b9f5-ffea-3979-962e507cab03",
        "NOMBRE": "PITIRIASIS LIQUENOIDE CRÓNICA"
    },
    {
        "id": "318bd31a-ddaa-2191-68de-2296fb0841f4",
        "NOMBRE": "PITIRIASIS LIQUENOIDE Y VARIOLIFORME AGUDA"
    },
    {
        "id": "56460c76-0339-7bf0-415a-e0d796523fdf",
        "NOMBRE": "PITIRIASIS ROSADA"
    },
    {
        "id": "818558c1-f7b2-791b-92e5-a591cd8afadb",
        "NOMBRE": "PITIRIASIS RUBRA PILARIS"
    },
    {
        "id": "91845f49-a507-4ad4-48fa-aeacb611bd9f",
        "NOMBRE": "PITIRIASIS VERSICOLOR"
    },
    {
        "id": "7cb04e10-009e-7d37-a618-f1179040fcee",
        "NOMBRE": "PLACENTA ANORMALMENTE ADHERIDA"
    },
    {
        "id": "1fb23471-339c-3120-afd3-52eb0184ebb8",
        "NOMBRE": "PLACENTA PREVIA"
    },
    {
        "id": "9a69e050-8f4d-f6fa-b77f-9c002158003a",
        "NOMBRE": "PLACENTA PREVIA CON ESPECIFICACIÓN DE QUE NO HUBO HEMORRAGIA"
    },
    {
        "id": "6592e634-d00a-2814-6bac-47fe5d29f810",
        "NOMBRE": "PLACENTA PREVIA CON HEMORRAGIA"
    },
    {
        "id": "4022b147-6d12-462d-e4d1-94088bde74a4",
        "NOMBRE": "PLAGIOCEFALIA"
    },
    {
        "id": "998c59b4-66a2-fe17-0563-3c631ca3869e",
        "NOMBRE": "PLASMOCITOMA EXTRAMEDULAR"
    },
    {
        "id": "6eb802df-d45a-175a-bb35-0eac84bf4003",
        "NOMBRE": "PLASMOCITOMA SOLITARIO"
    },
    {
        "id": "430d0a13-9287-9aaf-c438-155c83543676",
        "NOMBRE": "PLEURESÍA"
    },
    {
        "id": "cc82849f-8443-d805-c8e1-99eb00b808e9",
        "NOMBRE": "PLEURESÍA TUBERCULOSA, CONFIRMADA BACTERIOLÓGICA E HISTOLÓGICAMENTE"
    },
    {
        "id": "9f72afb9-9182-f375-c9a9-665e5b93417f",
        "NOMBRE": "PLEURESÍA TUBERCULOSA, SIN MENCIÓN DE CONFIRMACIÓN BACTERIOLÓGICA O HISTOLÓGICA"
    },
    {
        "id": "111d5dfa-c633-fcdf-7a59-8849fb03e4b1",
        "NOMBRE": "POIQUILODERMIA DE CIVATTE"
    },
    {
        "id": "1e8fd78b-9340-74cd-ca76-e52616ecf584",
        "NOMBRE": "POIQUILODERMIA VASCULAR ATRÓFICA"
    },
    {
        "id": "855863e3-f4fb-58b5-0a94-9df6c8d49ae5",
        "NOMBRE": "POLIANGIÍTIS MICROSCÓPICA"
    },
    {
        "id": "de234e05-31be-df9d-501e-90b0669c1d70",
        "NOMBRE": "POLIARTERITIS CON COMPROMISO PULMONAR [CHURG-STRAUSS]"
    },
    {
        "id": "8d94895b-8369-6ad1-0e90-7a96f8543d36",
        "NOMBRE": "POLIARTERITIS JUVENIL"
    },
    {
        "id": "c115484d-645f-ea03-27f0-b69551725803",
        "NOMBRE": "POLIARTERITIS NUDOSA"
    },
    {
        "id": "c9ec80b3-9e46-f975-4d59-0820513a9a0f",
        "NOMBRE": "POLIARTERITIS NUDOSA Y AFECCIONES RELACIONADAS"
    },
    {
        "id": "09942510-6e09-263c-7151-91296925c780",
        "NOMBRE": "POLIARTRITIS JUVENIL (SERONEGATIVA)"
    },
    {
        "id": "e4a3e9b6-2b7e-de49-4d62-7ca3b8a328ca",
        "NOMBRE": "POLIARTRITIS, NO ESPECIFICADA"
    },
    {
        "id": "6cb3f629-644f-a017-7e30-5ec8b935c0f4",
        "NOMBRE": "POLIARTROPATÍA INFLAMATORIA"
    },
    {
        "id": "df785946-bc79-0340-670a-8675c217a4d4",
        "NOMBRE": "POLIARTROSIS"
    },
    {
        "id": "8f1afb3f-5798-e9b8-6f39-d9678ecbbcd6",
        "NOMBRE": "POLIARTROSIS, NO ESPECIFICADA"
    },
    {
        "id": "f27cb317-d50e-c53d-195c-b63eaca27a3f",
        "NOMBRE": "POLICITEMIA NEONATAL"
    },
    {
        "id": "126e4b21-d751-5537-6aef-11459df4c545",
        "NOMBRE": "POLICITEMIA SECUNDARIA"
    },
    {
        "id": "079899d4-34a5-dc62-b297-9bb588d1b08f",
        "NOMBRE": "POLICITEMIA VERA"
    },
    {
        "id": "be797695-b953-f913-c3c9-8a32d6de6a34",
        "NOMBRE": "POLICONDRITIS RECIDIVANTE"
    },
    {
        "id": "470612d6-ba92-c95e-bf3a-e28f2b11d4f5",
        "NOMBRE": "POLIDACTILIA"
    },
    {
        "id": "352083b5-606f-488f-4348-3c6523300c46",
        "NOMBRE": "POLIDACTILIA, NO ESPECIFICADA"
    },
    {
        "id": "2b471190-f140-f0ac-9432-c9a138cb8521",
        "NOMBRE": "POLIDIPSIA"
    },
    {
        "id": "048956bb-cdc8-a1bf-577f-9f4af2d79cc8",
        "NOMBRE": "POLIFAGIA"
    },
    {
        "id": "b3fbbec7-fcd5-73ed-82ea-388f7147b05e",
        "NOMBRE": "POLIHIDRAMNIOS"
    },
    {
        "id": "9ac13ec8-acc8-1c9a-9d21-fea8ad19f8a2",
        "NOMBRE": "POLIMIALGIA REUMÁTICA"
    },
    {
        "id": "5075920e-5f48-1657-1a7e-3b80fdf09397",
        "NOMBRE": "POLIMIOSITIS"
    },
    {
        "id": "d4cd00a6-d653-b4fe-8b3b-abfde64f9048",
        "NOMBRE": "POLINEUOPATÍA INFLAMATORIA"
    },
    {
        "id": "11421290-472b-df55-b7d6-0ae1d2af98a1",
        "NOMBRE": "POLINEUROPATÍA ALCOHÓLICA"
    },
    {
        "id": "368c4bd4-07b1-4792-7c8f-cc29cb5ec6e7",
        "NOMBRE": "POLINEUROPATÍA DEBIDA A OTRO AGENTE TÓXICO"
    },
    {
        "id": "513aab18-219a-2963-a3b4-e6ce4cbeaa8a",
        "NOMBRE": "POLINEUROPATÍA DIABÉTICA"
    },
    {
        "id": "e618aa4e-2502-b783-5b5e-dc4fa42c8d1e",
        "NOMBRE": "POLINEUROPATÍA EN DEFICIENCIA NUTRICIONAL"
    },
    {
        "id": "64617d17-fec7-c352-62ba-39c251c28190",
        "NOMBRE": "POLINEUROPATÍA EN ENFERMEDAD NEOPLÁSICA"
    },
    {
        "id": "8dd575af-79b0-f4e0-5408-b57bfde76f75",
        "NOMBRE": "POLINEUROPATÍA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "aa396679-23bf-8f3e-57fd-927d89fdb0e3",
        "NOMBRE": "POLINEUROPATÍA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9400904f-7e7b-d134-8859-3664bdb0e979",
        "NOMBRE": "POLINEUROPATÍA EN OTRAS ENFERMEDADES ENDOCRINAS Y METABÓLICAS"
    },
    {
        "id": "584f43d5-25c2-a210-85e4-b6bc7a7f89ee",
        "NOMBRE": "POLINEUROPATÍA EN OTROS TRASTORNOS OSTEOMUSCULARES"
    },
    {
        "id": "35341afa-9b6e-85cf-51c1-2426cb5efd89",
        "NOMBRE": "POLINEUROPATÍA EN TRASTORNOS DEL TEJIDO CONECTIVO SISTÉMICO"
    },
    {
        "id": "a2e4a9d5-62fa-8c00-2b10-18993904a2b0",
        "NOMBRE": "POLINEUROPATÍA INDUCIDA POR DROGAS"
    },
    {
        "id": "60207c81-902e-1681-9163-b68ec0736c93",
        "NOMBRE": "POLINEUROPATÍA INFLAMATORIA, NO ESPECIFICADA"
    },
    {
        "id": "23eb9acd-5a02-21cc-2423-80d55a9691b5",
        "NOMBRE": "POLINEUROPATÍA, NO ESPECIFICADA"
    },
    {
        "id": "e3120049-51b9-b3de-d35e-9b66fdbae19d",
        "NOMBRE": "POLINEUROPATÍAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4e299d86-ecc7-bf04-0dd3-9f71ff30f501",
        "NOMBRE": "POLIOMIELITIS AGUDA"
    },
    {
        "id": "4a2c1fbe-3e90-a072-7d6b-a1e4d1fd01ab",
        "NOMBRE": "POLIOMIELITIS AGUDA NO PARALÍTICA"
    },
    {
        "id": "f119a2ae-d2ea-e8b2-63bd-9a1ec0bd0f81",
        "NOMBRE": "POLIOMIELITIS AGUDA PARALÍTICA DEBIDA A VIRUS SALVAJE AUTÓCTONO"
    },
    {
        "id": "8f4e6370-9220-5481-2c4b-94e066cd2ace",
        "NOMBRE": "POLIOMIELITIS AGUDA PARALÍTICA DEBIDA A VIRUS SALVAJE IMPORTADO"
    },
    {
        "id": "f443c8d3-6c80-82b5-5ac2-c716bc65de07",
        "NOMBRE": "POLIOMIELITIS AGUDA PARALÍTICA, ASOCIADA A VACUNA"
    },
    {
        "id": "0d421afe-7559-b158-ea01-f0d434b8b936",
        "NOMBRE": "POLIOMIELITIS AGUDA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "9a26b164-2dd8-2b2b-458e-86b19fa9ee8c",
        "NOMBRE": "PÓLIPO ANAL"
    },
    {
        "id": "3af4452d-4228-ffb9-37a5-0d06fa571227",
        "NOMBRE": "PÓLIPO DE LA CAVIDAD NASAL"
    },
    {
        "id": "d3dbbf0e-4366-141b-24ae-07fe6054eb1e",
        "NOMBRE": "PÓLIPO DE LA VAGINA"
    },
    {
        "id": "80adbb6a-e05f-fd86-d577-5694b9a76e1f",
        "NOMBRE": "PÓLIPO DE LA VULVA"
    },
    {
        "id": "225e0516-2830-4a4c-aa74-07cb656f69a5",
        "NOMBRE": "PÓLIPO DE LAS CUERDAS VOCALES Y DE LA LARINGE"
    },
    {
        "id": "c7cda440-bfd0-4984-ba38-cd88bb0346be",
        "NOMBRE": "PÓLIPO DEL COLON"
    },
    {
        "id": "4a04c00c-a207-11fc-cb03-0eedb5e3d8b3",
        "NOMBRE": "PÓLIPO DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "2e7799d6-76a1-9f6b-d667-6bbb9041ce76",
        "NOMBRE": "PÓLIPO DEL CUERPO DEL ÚTERO"
    },
    {
        "id": "e63987ac-8d85-9f31-86bd-a68efcf23f2d",
        "NOMBRE": "PÓLIPO DEL ESTÓMAGO Y DEL DUODENO"
    },
    {
        "id": "68a520ce-ae34-258d-576d-6a72bcb1de8d",
        "NOMBRE": "PÓLIPO DEL OÍDO MEDIO"
    },
    {
        "id": "022b956d-de9b-4d6b-6ed6-9e231f3faa04",
        "NOMBRE": "PÓLIPO DEL TRACTO GENITAL FEMENINO"
    },
    {
        "id": "0509805d-feca-cef8-4be2-b1baae1b5cda",
        "NOMBRE": "PÓLIPO DEL TRACTO GENITAL FEMENINO, NO ESPECIFICADO"
    },
    {
        "id": "56112bd9-4134-6f0c-41cd-35eef3f8fb88",
        "NOMBRE": "PÓLIPO NASAL"
    },
    {
        "id": "03b636aa-5f17-1ca8-4565-4cb40216acb5",
        "NOMBRE": "PÓLIPO NASAL, NO ESPECIFICADO"
    },
    {
        "id": "fa16e331-7ac1-7595-9572-6b80647e443b",
        "NOMBRE": "PÓLIPO RECTAL"
    },
    {
        "id": "6eb45fe7-53f4-f808-30ff-2e5eaa3cda5f",
        "NOMBRE": "PÓLIPO UMBILICAL DEL RECIÉN NACIDO"
    },
    {
        "id": "05e5fc75-a8a6-e143-705a-379e14291772",
        "NOMBRE": "PÓLIPOS DE OTRAS PARTES DEL TRACTO GENITAL FEMENINO"
    },
    {
        "id": "ab95e9c1-7c61-2f83-728a-d6ae2f7541a7",
        "NOMBRE": "PÓLIPOS INFLAMATORIOS"
    },
    {
        "id": "8ab79c1d-72d7-08d7-cab8-f24b11772466",
        "NOMBRE": "POLISINDACTILIA"
    },
    {
        "id": "11bf9484-9393-f189-c06b-cd96b28d9564",
        "NOMBRE": "POLITRIQUIA"
    },
    {
        "id": "4201736b-587c-4469-6efa-2c9d885d29d7",
        "NOMBRE": "POLIURIA"
    },
    {
        "id": "0989c28d-80d4-a0e1-538a-2adb86172a40",
        "NOMBRE": "PORFIRIA CUTÁNEA TARDÍA"
    },
    {
        "id": "54c537e5-a54c-e5dd-4df3-a60b47bee3f7",
        "NOMBRE": "PORFIRIA ERITROPOYÉTICA HEREDITARIA"
    },
    {
        "id": "a2741442-6e70-712b-7bf2-f5d943339e0c",
        "NOMBRE": "PORTADOR DE AGENTE DE ENFERMEDAD INFECCIOSA"
    },
    {
        "id": "f0886bff-1a60-bd2f-1fdd-c574688f9eb5",
        "NOMBRE": "PORTADOR DE AGENTE DE ENFERMEDAD INFECCIOSA NO ESPECIFICADA"
    },
    {
        "id": "bb6f5bcb-7d00-0e47-bcc5-2d32559e91f1",
        "NOMBRE": "PORTADOR DE AGENTES DE ENFERMEDADES INFECCIOSAS CON UN MODO DE TRANSMISIÓN PREDOMINANTEMENTE SEXUAL"
    },
    {
        "id": "7c21b24e-32e5-84ba-0120-65f19d6745f6",
        "NOMBRE": "PORTADOR DE AGENTES DE HEPATITIS VIRAL"
    },
    {
        "id": "f418a05c-266f-5249-9c9b-d0ee280121b1",
        "NOMBRE": "PORTADOR DE AGENTES DE OTRAS ENFERMEDADES BACTERIANAS ESPECIFICADAS"
    },
    {
        "id": "fc8353a2-64ab-a0c0-a9a7-d792cfe9f690",
        "NOMBRE": "PORTADOR DE AGENTES DE OTRAS ENFERMEDADES INFECCIOSAS"
    },
    {
        "id": "a4cf5653-e6ba-890d-468f-9321e1a42dd9",
        "NOMBRE": "PORTADOR DE AGENTES DE OTRAS ENFERMEDADES INFECCIOSAS INTESTINALES"
    },
    {
        "id": "6fea6855-b723-0838-3d99-7f8d1af35037",
        "NOMBRE": "PORTADOR DEL AGENTE DE LA DIFTERIA"
    },
    {
        "id": "56fe82ae-2f3e-25ad-691e-a86a864bccd0",
        "NOMBRE": "PORTADOR DEL AGENTE DE LA FIEBRE TIFOIDEA"
    },
    {
        "id": "b9f1d45d-c880-fbb3-2d58-7594cb7018ed",
        "NOMBRE": "PORTADOR DEL VIRUS HUMANO T-LINFOTRÓPICO TIPO 1 [VHTL-1]"
    },
    {
        "id": "62d773f9-999b-385b-582d-6d90e6d63402",
        "NOMBRE": "POSTURA ANORMAL"
    },
    {
        "id": "d3b73982-da00-1291-b989-b7fd94da3645",
        "NOMBRE": "PREDOMINIO DE ACTOS COMPULSIVOS [RITUALES OBSESIVOS]"
    },
    {
        "id": "8cfe9e8f-7794-93db-9529-d75e668076a2",
        "NOMBRE": "PREDOMINIO DE PENSAMIENTOS O RUMIACIONES OBSESIVAS"
    },
    {
        "id": "9c4a3599-0dda-4762-9ee5-e1bf901e0be2",
        "NOMBRE": "PREECLAMPSIA"
    },
    {
        "id": "3a495697-ff2d-5048-e6e8-8246d19aba57",
        "NOMBRE": "PREECLAMPSIA LEVE A MODERADA"
    },
    {
        "id": "90532165-daff-260e-d461-0585058aad6a",
        "NOMBRE": "PREECLAMPSIA SEVERA"
    },
    {
        "id": "8b1a6a26-cb1f-b6db-920a-5c4f105d1daa",
        "NOMBRE": "PREECLAMPSIA SUPERPUESTA EN HIPERTENSIÓN CRÓNICA"
    },
    {
        "id": "7348b510-37e7-138c-1efe-487608a37d64",
        "NOMBRE": "PREECLAMPSIA, NO ESPECIFICADA"
    },
    {
        "id": "f5e7e113-d8c0-7fc1-22b2-75b0a8bc188f",
        "NOMBRE": "PREOCUPACIÓN INDEBIDA POR SUCESOS QUE CAUSAN TENSIÓN"
    },
    {
        "id": "7b113180-99b1-47c1-88ef-4702ccb30bd4",
        "NOMBRE": "PREPUCIO REDUNDANTE, FIMOSIS Y PARAFIMOSIS"
    },
    {
        "id": "3515d1e5-7a58-007b-ea69-d045527dc4c1",
        "NOMBRE": "PRESBIACUSIA"
    },
    {
        "id": "4c7da957-0509-c155-c097-74ea07eb5680",
        "NOMBRE": "PRESBICIA"
    },
    {
        "id": "d56e9d5a-45b7-e787-7787-9aedbfe1ba0a",
        "NOMBRE": "PRESENCIA DE ALCOHOL EN LA SANGRE, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "f9e780f0-ea0d-8d6e-b0d9-95180a2f4bea",
        "NOMBRE": "PRESENCIA DE ANGIOPLASTIA, INJERTOS Y PRÓTESIS CORONARIAS"
    },
    {
        "id": "8167eee2-31b9-62d7-5116-ad584228d455",
        "NOMBRE": "PRESENCIA DE ANTEOJOS Y LENTES DE CONTACTO"
    },
    {
        "id": "ffd261ee-bdfb-1d32-0959-386fb52caf86",
        "NOMBRE": "PRESENCIA DE AUDÍFONO EXTERNO"
    },
    {
        "id": "e2802279-122f-a14a-7424-e3d0fdb60b39",
        "NOMBRE": "PRESENCIA DE DERIVACIÓN AORTOCORONARIA"
    },
    {
        "id": "d4e1145f-f534-f865-feec-a7f86de94d3f",
        "NOMBRE": "PRESENCIA DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)"
    },
    {
        "id": "d73d4346-c13f-2c53-80f1-5dd967d52cef",
        "NOMBRE": "PRESENCIA DE DISPOSITIVO PARA DRENAJE DE LÍQUIDO CEFALORRAQUÍDEO"
    },
    {
        "id": "daaa178e-c423-c626-bb8a-da7bc75155de",
        "NOMBRE": "PRESENCIA DE DISPOSITIVO PROTÉSICO DENTAL (COMPLETO) (PARCIAL)"
    },
    {
        "id": "88d226a8-63a2-190a-fd4c-c3fd69cab926",
        "NOMBRE": "PRESENCIA DE DISPOSITIVOS CARDÍACOS ELECTRÓNICOS"
    },
    {
        "id": "1bd648f1-470e-7afe-ef26-32ebba9a6e6d",
        "NOMBRE": "PRESENCIA DE IMPLANTE ORTOPÉDICO ARTICULAR"
    },
    {
        "id": "f4624f11-656f-8478-54a6-9cc8677851a9",
        "NOMBRE": "PRESENCIA DE IMPLANTE UROGENITAL"
    },
    {
        "id": "1fb82fdb-1f3b-ea04-0506-0ed387aa5f78",
        "NOMBRE": "PRESENCIA DE IMPLANTES DE RAÍZ DE DIENTE Y DE MANDÍBULA"
    },
    {
        "id": "ef2fa7f9-9dfc-a62d-a2da-d4bd76628e1b",
        "NOMBRE": "PRESENCIA DE IMPLANTES E INJERTOS CARDIOVASCULARES"
    },
    {
        "id": "31d5530d-7311-2ec6-8cbe-c79a0c88e786",
        "NOMBRE": "PRESENCIA DE IMPLANTES ENDOCRINOS"
    },
    {
        "id": "22cec17d-3e3b-7e53-2f26-f49557a762c9",
        "NOMBRE": "PRESENCIA DE IMPLANTES FUNCIONALES NO ESPECIFICADOS"
    },
    {
        "id": "95a31364-2ffb-baf6-9c59-86c014bea0d9",
        "NOMBRE": "PRESENCIA DE IMPLANTES ÓTICOS Y AUDITIVOS"
    },
    {
        "id": "c95ed2f8-75db-8c2b-1fb3-84a9f17cb68d",
        "NOMBRE": "PRESENCIA DE INJERTOS E IMPLANTES CARDIOVASCULARES NO ESPECIFICADOS"
    },
    {
        "id": "9e8605ab-3d2c-33d8-035c-9bbe7c25be53",
        "NOMBRE": "PRESENCIA DE LARINGE ARTIFICIAL"
    },
    {
        "id": "0656b688-a0dc-9860-8149-0c1973a99fc7",
        "NOMBRE": "PRESENCIA DE LENTES INTRAOCULARES"
    },
    {
        "id": "2b447db1-cad6-759f-706a-abc8938c65bd",
        "NOMBRE": "PRESENCIA DE MIEMBRO ARTIFICIAL (COMPLETO) (PARCIAL)"
    },
    {
        "id": "e8a36db8-8767-aed6-5818-6684b7fd7cc8",
        "NOMBRE": "PRESENCIA DE OJO ARTIFICIAL"
    },
    {
        "id": "3e0aefb1-fc4d-581f-5d26-f9687cf02125",
        "NOMBRE": "PRESENCIA DE OTROS DISPOSITIVOS"
    },
    {
        "id": "adbad431-ce34-05e2-4746-1e7012e6b578",
        "NOMBRE": "PRESENCIA DE OTROS DISPOSITIVOS ESPECIFICADOS"
    },
    {
        "id": "01b9a82e-61b8-5a5c-03a1-46b3addb6347",
        "NOMBRE": "PRESENCIA DE OTROS IMPLANTES DE TENDONES Y HUESOS"
    },
    {
        "id": "9f2ec7c6-df73-51c3-2bc9-35412e53a934",
        "NOMBRE": "PRESENCIA DE OTROS IMPLANTES FUNCIONALES"
    },
    {
        "id": "d03515dc-4431-32c9-3aac-5fa4ec3f7d1e",
        "NOMBRE": "PRESENCIA DE OTROS IMPLANTES FUNCIONALES ESPECIFICADOS"
    },
    {
        "id": "474994ba-a7fa-fb16-862b-cff260bccc1a",
        "NOMBRE": "PRESENCIA DE OTROS INJERTOS Y PRÓTESIS CARDIOVASCULARES"
    },
    {
        "id": "146d8ddf-d4ba-0c6c-291b-49825858c693",
        "NOMBRE": "PRESENCIA DE OTROS REEMPLAZOS DE VÁLVULA CARDÍACA"
    },
    {
        "id": "a0639708-c101-abb6-d95b-d2b042e01f09",
        "NOMBRE": "PRESENCIA DE VÁLVULA CARDÍACA PROTÉSICA"
    },
    {
        "id": "9bac8f87-1b45-1568-9e14-d353e6bc5f88",
        "NOMBRE": "PRESENCIA DE VÁLVULA CARDÍACA XENOGÉNICA"
    },
    {
        "id": "d3c52da1-266c-c20d-0573-bf4ae164c209",
        "NOMBRE": "PRIAPISMO"
    },
    {
        "id": "bdf8294a-6e7a-0da0-e90d-9051d09d7df2",
        "NOMBRE": "PRIVACIÓN DE AGUA"
    },
    {
        "id": "0fe3ef27-5cf5-5436-de19-d597f591fef8",
        "NOMBRE": "PRIVACIÓN DE AGUA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "4430be28-5847-bbcd-b01c-12b4b353ce30",
        "NOMBRE": "PRIVACIÓN DE AGUA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4a741a99-b1d8-61a9-c3aa-4d7c010cba90",
        "NOMBRE": "PRIVACIÓN DE AGUA, CALLES Y CARRETERAS"
    },
    {
        "id": "e5079d38-06af-e3b1-d071-73618da4afc2",
        "NOMBRE": "PRIVACIÓN DE AGUA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "ee2e2b72-4fd0-6c29-4ada-a751013b530d",
        "NOMBRE": "PRIVACIÓN DE AGUA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "a0a58ba5-7bd9-f6db-edb7-50bdc4b0b3df",
        "NOMBRE": "PRIVACIÓN DE AGUA, GRANJA"
    },
    {
        "id": "54806020-391c-df6c-ff22-0575fe7047c1",
        "NOMBRE": "PRIVACIÓN DE AGUA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "f75e797e-91ee-7ece-257b-7c8e2699a3db",
        "NOMBRE": "PRIVACIÓN DE AGUA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "44c6bf61-30d9-89fb-a679-0a287c6d2b81",
        "NOMBRE": "PRIVACIÓN DE AGUA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "6e76e8a0-ab87-80a3-0323-d113de4492a2",
        "NOMBRE": "PRIVACIÓN DE AGUA, VIVIENDA"
    },
    {
        "id": "960db0c0-5fb9-b502-4cbf-211ea951f4d5",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS"
    },
    {
        "id": "d627661f-bcea-d4c5-52b8-750318494226",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "553c4c10-1955-7e81-ff89-5dda3e48d26e",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "f33d57ef-d670-5031-350a-b6ed7f37bd98",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, CALLES Y CARRETERAS"
    },
    {
        "id": "55d048d6-fa8a-a66e-f3c7-0e683f3348fd",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "73be82ff-016a-08c2-a7aa-7960f261649e",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "cee83672-6845-ac2c-9eba-b1712f64b7f7",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, GRANJA"
    },
    {
        "id": "1c6ee92b-e3c3-c4f9-de38-39ba9ebde0b2",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "39103fd4-fb88-b191-0a11-581c41c3d533",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "44e86a00-0abd-1001-2251-f3c802e61096",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "41e478fc-3f45-9a95-474f-aee69a9a9e59",
        "NOMBRE": "PRIVACIÓN DE ALIMENTOS, VIVIENDA"
    },
    {
        "id": "c938b78b-8b07-6502-18c6-a75dd1772fbc",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA"
    },
    {
        "id": "4e503b04-d48d-0712-4d3a-c456133c69ed",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "3b272ad5-1337-c97d-2c45-c1c0e9b5975c",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "d7f614ad-5e54-85b1-c49f-51d801a4436f",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, CALLES Y CARRETERAS"
    },
    {
        "id": "1ee41391-0621-4c17-290f-887189c46775",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "81f0317f-d440-8420-7293-50711f1cae83",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "2b2a169f-4ba2-39b8-a90a-b32d4d67d17c",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, GRANJA"
    },
    {
        "id": "a7838c45-f025-04bb-ef5c-6ef657315b69",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "e4cf83c6-cdb1-59c3-f3c1-3a29036f5f21",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "fcb7035c-23ad-831f-128b-e3c5ca17dc40",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "b1229859-c767-3db6-82d3-4a274a7cfcc9",
        "NOMBRE": "PRIVACIÓN NO ESPECIFICADA, VIVIENDA"
    },
    {
        "id": "4583156c-546e-7f95-2db9-af437076aa61",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO DE LA ALIMENTACIÓN DEL RECIÉN NACIDO"
    },
    {
        "id": "bf823ca3-d278-b1af-250f-8a610f736e19",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS"
    },
    {
        "id": "4e620791-31b2-e25d-993b-002909066ded",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL AMBIENTE FÍSICO"
    },
    {
        "id": "7d64452e-94c1-1a52-aed9-cd51ac7bce15",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL AMBIENTE SOCIAL"
    },
    {
        "id": "e74991ff-5d85-7bbd-6ba8-1f0044ef0f67",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL ESTILO DE VIDA"
    },
    {
        "id": "8bfa17e3-37ca-60e6-76c3-eae72d403de2",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL GRUPO PRIMARIO DE APOYO"
    },
    {
        "id": "fa18387b-7988-5cfb-9a9d-860a676e2d88",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON LA CRIANZA DEL NIÑO"
    },
    {
        "id": "cd2138bf-7619-eea6-9eae-ea3a0a22da46",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON LA EDUCACIÓN Y LA ALFABETIZACIÓN"
    },
    {
        "id": "8f335ce3-f9e3-397a-a567-93075345e801",
        "NOMBRE": "PROBLEMA NO ESPECIFICADO RELACIONADO CON SERVICIOS MÉDICOS Y DE SALUD"
    },
    {
        "id": "6e6085bf-881d-68bc-3366-5b2632900611",
        "NOMBRE": "PROBLEMAS CASEROS Y CON VECINOS E INQUILINOS"
    },
    {
        "id": "12caefcd-20dd-612d-2c9d-9434a8316960",
        "NOMBRE": "PROBLEMAS DE LA INGESTIÓN DE ALIMENTOS DEL RECIÉN NACIDO"
    },
    {
        "id": "b81d9ef0-3709-ede0-fdb5-9872e538cd7e",
        "NOMBRE": "PROBLEMAS EN LA RELACIÓN CON LOS PADRES Y LOS FAMILIARES POLÍTICOS"
    },
    {
        "id": "6ccce3c4-7d29-fc2e-cea9-41fb0d3f7aa2",
        "NOMBRE": "PROBLEMAS EN LA RELACIÓN ENTRE ESPOSOS O PAREJA"
    },
    {
        "id": "3183d83e-923e-1311-2408-86ab89972894",
        "NOMBRE": "PROBLEMAS NO ESPECIFICADOS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA"
    },
    {
        "id": "5c52f31c-d3e4-f828-36db-474490336f9c",
        "NOMBRE": "PROBLEMAS NO ESPECIFICADOS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONÓMICAS"
    },
    {
        "id": "6af8d4ce-b3a1-d445-b966-9c04ad607009",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON ABUSO FÍSICO DEL NIÑO"
    },
    {
        "id": "7fec3441-2c37-9f8e-28db-78235ae4ed66",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON ALTERACIÓN EN EL PATRÓN DE LA RELACIÓN FAMILIAR EN LA INFANCIA"
    },
    {
        "id": "ae358320-7dab-fc25-9bfa-10c02245cf52",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON AMENAZA DE PÉRDIDA DEL EMPLEO"
    },
    {
        "id": "8db44cb7-a1eb-1969-14f3-21e4462a4464",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON ATENCIÓN DE SALUD NO DISPONIBLE O INACCESIBLE"
    },
    {
        "id": "2bcb8df1-fc61-349b-c1f3-de8a03c8e642",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON BAJOS INGRESOS"
    },
    {
        "id": "05880d9a-1a75-58ae-a853-a59407bb0396",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON CIERTAS CIRCUNSTANCIAS PSICOSOCIALES"
    },
    {
        "id": "52a3c74c-02f0-4448-adac-52e1e8725ea1",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON CIRCUNSTANCIAS PSICOSOCIALES NO ESPECIFICADAS"
    },
    {
        "id": "08708125-b5a0-6c09-dc03-756f25244646",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON CULPABILIDAD EN PROCEDIMIENTOS CIVILES O CRIMINALES SIN PRISIÓN"
    },
    {
        "id": "d3a72c8b-9c68-0b39-4d15-b4527de01d08",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS"
    },
    {
        "id": "f2ac8e06-f1b5-7117-a91a-72faefb34e2b",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON DESAVENENCIAS CON EL JEFE Y LOS COMPAÑEROS DE TRABAJO"
    },
    {
        "id": "32571550-76ba-a042-884c-27ac0d956265",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA"
    },
    {
        "id": "f32831c3-3279-d567-bc26-997f2ff77376",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL ABANDONO EMOCIONAL DEL NIÑO"
    },
    {
        "id": "9459c609-1062-5c0a-1256-fa56d35ce353",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL ABUSO SEXUAL DEL NIÑO POR PERSONA AJENA AL GRUPO DE APOYO PRIMARIO"
    },
    {
        "id": "917126f9-e55e-487d-07bf-423230482595",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL ABUSO SEXUAL DEL NIÑO POR PERSONA DENTRO DEL GRUPO DE APOYO PRIMARIO"
    },
    {
        "id": "a958bae7-7054-9ed3-1fa5-6c3d280e3a90",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL AJUSTE A LAS TRANSICIONES DEL CICLO VITAL"
    },
    {
        "id": "25441709-4ed4-d073-ade8-eb6a39314da2",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL ALEJAMIENTO DEL HOGAR EN LA INFANCIA"
    },
    {
        "id": "691fb764-e146-ece7-495a-40b0101abe77",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL AMBIENTE FÍSICO"
    },
    {
        "id": "f0b44aab-a0db-3051-5c25-ff393ffbb2b9",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL AMBIENTE SOCIAL"
    },
    {
        "id": "239bd70b-64a0-0c44-1ea8-c522ce4758bf",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL ANALFABETISMO O BAJO NIVEL DE INSTRUCCIÓN"
    },
    {
        "id": "d9b5fb33-3b6d-3c7d-ff38-94a0005e0d6c",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL APOYO FAMILIAR INADECUADO"
    },
    {
        "id": "8ca1a6ed-91d2-57f3-56bd-1626b2a9acee",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL BAJO RENDIMIENTO ESCOLAR"
    },
    {
        "id": "43ef6590-3d60-a2aa-8618-1fb9af60c6b7",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL CAMBIO DE EMPLEO"
    },
    {
        "id": "0f7c87b5-4b5c-90d7-96eb-c233f6c6a45e",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL CONFLICTO DEL ROL SOCIAL, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "7aae3b97-79c1-03db-1598-e0082b2908b0",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL DESACUERDO CON CONSEJEROS"
    },
    {
        "id": "16c3dd24-b93f-9116-c5f3-077fd9b6bc75",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL DESEMPLEO, NO ESPECIFICADOS"
    },
    {
        "id": "aacd999d-eaa1-4371-8e72-b8f9110a1ad5",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL EMPLEO Y EL DESEMPLEO"
    },
    {
        "id": "4de993e9-cf02-8980-24c4-2b0e526db1ad",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL ESTILO DE VIDA"
    },
    {
        "id": "6c9157b0-6572-3599-c46b-45c9670adbd4",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL ESTRÉS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "983a1814-d8d9-8583-4ca9-7a7b09e6348a",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL JUEGO Y LAS APUESTAS"
    },
    {
        "id": "16708515-fa6f-98eb-5c29-c83aeac79ad6",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL TRABAJO INCOMPATIBLE"
    },
    {
        "id": "883045e9-b476-d025-638c-ac4d66b3eed6",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL USO DE DROGAS"
    },
    {
        "id": "96cbade3-90c8-ac1e-4d2a-307b75542694",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL USO DEL ALCOHOL"
    },
    {
        "id": "38b3be65-730a-92ea-ae1e-88283f1f22aa",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EL USO DEL TABACO"
    },
    {
        "id": "998d89b9-33f8-1f88-67cc-5b4885b31b02",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EMBARAZO NO DESEADO"
    },
    {
        "id": "c0115cc8-68f8-7a3a-8d11-2d91e47c55d2",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EVENTOS QUE LLEVARON A LA PÉRDIDA DE LA AUTOESTIMA EN LA INFANCIA"
    },
    {
        "id": "96adca25-0368-f0b6-9c31-8c7a080204a7",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EXCLUSIÓN Y RECHAZO SOCIAL"
    },
    {
        "id": "61b3906b-4947-c48e-a8a7-8eed772e35e4",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EXPERIENCIA NEGATIVA NO ESPECIFICADA EN LA INFANCIA"
    },
    {
        "id": "63228724-d737-23c9-01f3-348b5ae63ae2",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON EXPERIENCIAS PERSONALES ATEMORIZANTES EN LA INFANCIA"
    },
    {
        "id": "7a6d3d12-d3f4-2d9a-a813-013008f60793",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON FACILIDADES DE ATENCIÓN MÉDICA U OTROS SERVICIOS DE SALUD"
    },
    {
        "id": "3e6c2805-3366-e0b8-547f-93a87f00cecc",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON FALTA DE EJERCICIO FÍSICO"
    },
    {
        "id": "7a9490ce-3403-1954-7f5f-d58180351611",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON FAMILIAR DEPENDIENTE, NECESITADO DE CUIDADO EN LA CASA"
    },
    {
        "id": "ca026039-2e77-f7d7-4cb5-9b7192f109b7",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON HABILIDADES SOCIALES INADECUADAS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "a51b9131-f782-9d77-51ef-a3aba8c95392",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON HECHOS NEGATIVOS EN LA NIÑEZ"
    },
    {
        "id": "b13a546c-b45a-92d7-7de5-84f2a1c9b0c4",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON HORARIO ESTRESANTE DE TRABAJO"
    },
    {
        "id": "0264b45b-1e4f-97a3-cae4-cf86e21b9f87",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON HOSTILIDAD Y REPROBACIÓN AL NIÑO"
    },
    {
        "id": "f65d5edd-00e3-71bf-c197-66803f45e6b2",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA ACENTUACIÓN DE RASGOS DE LA PERSONALIDAD"
    },
    {
        "id": "6ebdbee2-8bb8-6e3a-9f78-1ca74250b272",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA ADAPTACIÓN CULTURAL"
    },
    {
        "id": "f0a6ef02-4ddc-e856-60c0-4e3725c5dfd0",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA ATENCIÓN DURANTE VACACIONES DE LA FAMILIA"
    },
    {
        "id": "2c30b692-56f2-4af6-f9e5-3fe76f2ff028",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA AUSENCIA DE UN MIEMBRO DE LA FAMILIA"
    },
    {
        "id": "a43e57ca-905b-f281-7f44-16dc532ef2c5",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA CONDUCTA SEXUAL DE ALTO RIESGO"
    },
    {
        "id": "cfbca447-aeb9-602b-679e-060a6fbe174b",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA CRIANZA EN INSTITUCIÓN"
    },
    {
        "id": "ebc8ecf7-47f7-b735-8c54-efdd2c4c7988",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA DESAPARICIÓN O MUERTE DE UN MIEMBRO DE LA FAMILIA"
    },
    {
        "id": "d9c6495f-8de1-09a5-3498-2e29276c22ff",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA DIETA Y HÁBITOS ALIMENTARIOS INAPROPIADOS"
    },
    {
        "id": "ed70bb56-fa9a-5b81-9d89-a0e795cb7154",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA DISCRIMINACIÓN Y PERSECUCIÓN PERCIBIDAS"
    },
    {
        "id": "5f2fe323-93e9-e83f-1718-e27034a6047f",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA EDUCACIÓN NO DISPONIBLE O INACCESIBLE"
    },
    {
        "id": "5b9610dd-e2fb-e188-60e9-858605bbbdd8",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA EDUCACIÓN Y LA ALFABETIZACIÓN"
    },
    {
        "id": "8aa66ca5-3a6f-5691-d715-3e00b215a59e",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA ENFERMEDAD CONSUNTIVA"
    },
    {
        "id": "c159b924-0e6d-ed6b-e125-64b42d52dabc",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA EXPOSICIÓN A DESASTRE, GUERRA U OTRAS HOSTILIDADES"
    },
    {
        "id": "eaf5746c-90e4-85d4-fc39-572a5d2c9069",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA FALLA EN LOS EXÁMENES"
    },
    {
        "id": "23b1db88-d693-639c-3cbc-db9fc5641b0b",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA FALTA DE ALIMENTOS ADECUADOS"
    },
    {
        "id": "09ea9c80-5eaf-5787-b0db-395447258210",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA FALTA DE RELAJACIÓN Y DESCANSO"
    },
    {
        "id": "d56d05c4-6da8-4abd-59ab-faf4e6d0595b",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA FALTA DE VIVIENDA"
    },
    {
        "id": "23f3eac7-3d2a-3519-efb0-0879c3e5c5b9",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA INADAPTACIÓN EDUCACIONAL Y DESAVENENCIAS CON MAESTROS Y COMPAÑEROS"
    },
    {
        "id": "6409d211-550c-f8f5-0eab-906536aea090",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA LIBERACIÓN DE LA PRISIÓN"
    },
    {
        "id": "fc3dd504-e00b-e1ca-2ed0-f6131cfa5627",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA LIMITACIÓN DE LAS ACTIVIDADES DEBIDO A DISCAPACIDAD"
    },
    {
        "id": "97bc2145-0915-b1d6-80b4-daaf6ad24e43",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA MULTIPARIDAD"
    },
    {
        "id": "c26ebbf2-2551-7b14-e93b-8b28abfc45da",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA NECESIDAD DE ASISTENCIA DOMICILIARIA Y QUE NINGÚN OTRO MIEMBRO DEL HOGAR PUEDE PROPORCIONAR"
    },
    {
        "id": "51d5e1ed-c5bc-a52f-b13a-08de016fd8c0",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA NECESIDAD DE AYUDA PARA EL CUIDADO PERSONAL"
    },
    {
        "id": "6f1fdd18-07cf-e437-175d-6071455ac686",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA NECESIDAD DE SUPERVISIÓN CONTINUA"
    },
    {
        "id": "3efe3616-ee54-ffb1-a585-500d954d9099",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA PÉRDIDA DE RELACIÓN AFECTIVA EN LA INFANCIA"
    },
    {
        "id": "a594815c-41a7-bdf1-6343-48ee6e090f3a",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA RUPTURA FAMILIAR POR SEPARACIÓN O DIVORCIO"
    },
    {
        "id": "51083126-2afd-6c66-bb9a-db06b6f369ea",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA SOBREPROTECCIÓN DE LOS PADRES"
    },
    {
        "id": "69e2f72e-4925-f40b-9738-50771cfeb556",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA SOLICITUD O ACEPTACIÓN DE INTERVENCIONES FÍSICAS, NUTRICIONALES Y QUÍMICAS, CONOCIENDO SU RIESGO Y PELIGRO"
    },
    {
        "id": "250911b8-6fb7-0161-7ecc-6d9414a2dd1b",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA SOLICITUD O ACEPTACIÓN DE INTERVENCIONES PSICOLÓGICAS O DE LA CONDUCTA, CONOCIENDO SU RIESGO Y PELIGRO"
    },
    {
        "id": "434922f5-1d16-2997-a007-15fb55d0e106",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA SUPERVISIÓN O EL CONTROL INADECUADOS DE LOS PADRES"
    },
    {
        "id": "2b0adf0f-183e-6421-0b54-8847b9df121c",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONÓMICAS"
    },
    {
        "id": "3122e963-d1b4-b127-3866-84b877a16d66",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON OTRAS CIRCUNSTANCIAS LEGALES"
    },
    {
        "id": "04668acc-5b2b-d52e-ba34-54f2af61e4c0",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON OTRAS CIRCUNSTANCIAS PSICOSOCIALES"
    },
    {
        "id": "1c2e8345-3a2e-e5f4-1d08-0b441620a4c5",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON OTRAS EXPERIENCIAS NEGATIVAS EN LA INFANCIA"
    },
    {
        "id": "6cad42d7-d1e0-84cb-f386-7c95d7f99ce3",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON OTROS HECHOS ESTRESANTES QUE AFECTAN A LA FAMILIA Y AL HOGAR"
    },
    {
        "id": "fd8839e8-3451-fc17-d4cc-a2b0a9e5c8ab",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON OTROS SERVICIOS ASISTENCIALES NO DISPONIBLES O INACCESIBLES"
    },
    {
        "id": "05821f3d-24da-6a14-9285-f780b95c25ab",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON PERSONA EN OTRO PERÍODO DE ESPERA PARA INVESTIGACIÓN Y TRATAMIENTO"
    },
    {
        "id": "6e53114f-7af3-e814-f8d6-2c9907345213",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON PERSONA ESPERANDO ADMISIÓN EN UNA INSTITUCIÓN APROPIADA EN OTRO LUGAR"
    },
    {
        "id": "7c4e98d2-75b1-b3d7-fe7e-4a1392f4502d",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON PERSONA QUE RESIDE EN UNA INSTITUCIÓN"
    },
    {
        "id": "a4c265b5-6e8c-4b71-3823-d04760bc59b7",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON PERSONA QUE VIVE SOLA"
    },
    {
        "id": "9b4d4e2a-41ee-28ee-0539-1391eca31616",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON POBREZA EXTREMA"
    },
    {
        "id": "a9849a34-cb67-0d54-7a4e-3dfa77209e4b",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON PRESIONES INAPROPIADAS DE LOS PADRES Y OTRAS ANORMALIDADES EN LA CALIDAD DE LA CRIANZA"
    },
    {
        "id": "4ee5351c-589e-01a8-1c9a-1cd22516fd1c",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON PRISIÓN Y OTRO ENCARCELAMIENTO"
    },
    {
        "id": "c08065dd-d091-d1c0-2482-648fb01f8401",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON SEGURIDAD SOCIAL Y SOSTENIMIENTO INSUFICIENTES PARA EL BIENESTAR"
    },
    {
        "id": "f4370272-2467-8ebb-a513-6e04763058ac",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON SERVICIO MÉDICO NO DISPONIBLE EN EL DOMICILIO"
    },
    {
        "id": "04375f32-af8c-0285-f054-bad6a2cf5270",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON SITUACIÓN FAMILIAR ATÍPICA"
    },
    {
        "id": "39c98c23-1b2a-245b-9f5a-7ff308e41b3c",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON VÍCTIMA DE CRIMEN O TERRORISMO"
    },
    {
        "id": "1a9ac554-6396-5fd0-87de-899575bd47f7",
        "NOMBRE": "PROBLEMAS RELACIONADOS CON VIVIENDA INADECUADA"
    },
    {
        "id": "2b7cef37-5cd7-a1f6-2ab8-513778f37140",
        "NOMBRE": "PROCEDIMIENTO MÉDICO NO ESPECIFICADO"
    },
    {
        "id": "fa68ea52-fdbc-b6f2-569e-b06b0bfe0cfc",
        "NOMBRE": "PROCEDIMIENTO NO ESPECIFICADO PARA OTROS PROPÓSITOS QUE NO SEAN LOS DE MEJORAR EL ESTADO DE SALUD"
    },
    {
        "id": "43ca4580-5435-ffbf-39aa-535d6f2e5598",
        "NOMBRE": "PROCEDIMIENTO NO REALIZADO POR CONTRAINDICACIÓN"
    },
    {
        "id": "fa9c4178-16fa-fa12-6ab1-e3a585c43609",
        "NOMBRE": "PROCEDIMIENTO NO REALIZADO POR DECISIÓN DEL PACIENTE, POR OTRAS RAZONES Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "09853b32-ada6-03e9-feed-85d5274475a2",
        "NOMBRE": "PROCEDIMIENTO NO REALIZADO POR DECISIÓN DEL PACIENTE, POR RAZONES DE CREENCIA O PRESIÓN DE GRUPO"
    },
    {
        "id": "f084eeba-a4f0-2d9d-fa89-b3c696875ec3",
        "NOMBRE": "PROCEDIMIENTO NO REALIZADO POR OTRAS RAZONES"
    },
    {
        "id": "dca0cbb4-0af7-a22e-7809-5a8fe679c25d",
        "NOMBRE": "PROCEDIMIENTO NO REALIZADO POR RAZÓN NO ESPECIFICADA"
    },
    {
        "id": "21a52d13-673b-02d4-5e22-99d7413b292f",
        "NOMBRE": "PROCEDIMIENTO QUIRÚRGICO NO ESPECIFICADO"
    },
    {
        "id": "aa801fd3-d1be-ae59-2139-9d97b5b2bb44",
        "NOMBRE": "PROCEDIMIENTO RADIOLÓGICO Y RADIOTERAPIA"
    },
    {
        "id": "c8e28f25-5871-b807-a1f3-3deddfc100c3",
        "NOMBRE": "PROCEDIMIENTOS PARA OTROS PROPÓSITOS QUE NO SEAN LOS DE MEJORAR EL ESTADO DE SALUD"
    },
    {
        "id": "f417af03-de90-2c08-ddcd-bdba44215a09",
        "NOMBRE": "PROCTITIS (CRÓNICA) ULCERATIVA"
    },
    {
        "id": "678fae7d-5e9e-1b5c-7896-92cc54b4887d",
        "NOMBRE": "PROCTITIS POR RADIACIÓN"
    },
    {
        "id": "d2b07234-3bae-4f4f-df1a-fe80c16402e8",
        "NOMBRE": "PRODUCCIÓN INTENCIONAL O SIMULACIÓN DE SÍNTOMAS O DE INCAPACIDADES, TANTO FÍSICAS COMO PSICOLÓGICAS [TRASTORNO FACTICIO]"
    },
    {
        "id": "037d5bae-7899-58c8-3d48-8371d064b75b",
        "NOMBRE": "PRODUCTO ANORMAL DE LA CONCEPCIÓN, NO ESPECIFICADO"
    },
    {
        "id": "89f9d7ff-b8f8-b677-31e0-4f2a1a8ed074",
        "NOMBRE": "PRODUCTO DEL PARTO"
    },
    {
        "id": "bfc30cf5-415e-5067-e2a0-36ab894cbb9a",
        "NOMBRE": "PRODUCTO DEL PARTO NO ESPECIFICADO"
    },
    {
        "id": "3490f523-529e-99f4-9e30-6dcf5457b43b",
        "NOMBRE": "PRODUCTO ÚNICO, LUGAR DE NACIMIENTO NO ESPECIFICADO"
    },
    {
        "id": "f932e5c2-3b89-cf1b-e839-3b190c4caf1b",
        "NOMBRE": "PRODUCTO ÚNICO, NACIDO EN HOSPITAL"
    },
    {
        "id": "26e4f02b-5fdf-b81f-71a1-ca18fe5c54b0",
        "NOMBRE": "PRODUCTO ÚNICO, NACIDO FUERA DE HOSPITAL"
    },
    {
        "id": "425fb8f5-929d-8a4f-4a11-d80db972c4b7",
        "NOMBRE": "PROLAPSO (DE LA VÁLVULA) MITRAL"
    },
    {
        "id": "49e27c20-7370-18e1-55fa-e176cf3efffe",
        "NOMBRE": "PROLAPSO ANAL"
    },
    {
        "id": "be81bbbd-d69d-8d82-3fdc-e073ce00b808",
        "NOMBRE": "PROLAPSO DE LA CÚPULA VAGINAL DESPUÉS DE HISTERECTOMÍA"
    },
    {
        "id": "4490d427-3c17-7e7a-0a1b-9a0506066180",
        "NOMBRE": "PROLAPSO DE LA MUCOSA URETRAL"
    },
    {
        "id": "6a5b1658-075a-c602-8458-96adbe6e303c",
        "NOMBRE": "PROLAPSO DEL VÍTREO"
    },
    {
        "id": "aa004a27-8828-b8fb-b36e-3075d86dce49",
        "NOMBRE": "PROLAPSO GENITAL FEMENINO"
    },
    {
        "id": "f177808d-b7b6-0e4d-ec3f-dad22243d1eb",
        "NOMBRE": "PROLAPSO GENITAL FEMENINO, NO ESPECIFICADO"
    },
    {
        "id": "49bd204d-f044-6721-4551-40896ecd9238",
        "NOMBRE": "PROLAPSO RECTAL"
    },
    {
        "id": "70231a2e-4665-e6d7-6b34-0c7c429912ab",
        "NOMBRE": "PROLAPSO UTEROVAGINAL COMPLETO"
    },
    {
        "id": "06024325-83f5-229e-a84f-d2e984ed8fd9",
        "NOMBRE": "PROLAPSO UTEROVAGINAL INCOMPLETO"
    },
    {
        "id": "5ff7914b-32fc-7486-ad38-84f90394a39e",
        "NOMBRE": "PROLAPSO UTEROVAGINAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "24346c6c-7b7f-2f6e-f365-822ba9f99fd2",
        "NOMBRE": "PROLAPSO Y HERNIA DEL OVARIO Y DE LA TROMPA DE FALOPIO"
    },
    {
        "id": "02e2d8d1-2df4-67a3-e393-21074b196f43",
        "NOMBRE": "PROLONGACIÓN DEL PRIMER PERÍODO (DEL TRABAJO DE PARTO)"
    },
    {
        "id": "3df26bd0-0731-f3e9-8191-f37c48fbbdc4",
        "NOMBRE": "PROLONGACIÓN DEL SEGUNDO PERÍODO (DEL TRABAJO DE PARTO)"
    },
    {
        "id": "425969cf-9c39-7534-6d89-f32fc099e78c",
        "NOMBRE": "PROMINENCIAS CUTÁNEAS, RESIDUO DE HEMORROIDES"
    },
    {
        "id": "95a39b73-a95d-9484-d9ec-7faea6b82c70",
        "NOMBRE": "PROSTATITIS AGUDA"
    },
    {
        "id": "71777f94-ad0b-fdc4-3489-0e6ac097f0c3",
        "NOMBRE": "PROSTATITIS CRÓNICA"
    },
    {
        "id": "761eaf6a-cc9c-1312-a1fa-4a8d06528952",
        "NOMBRE": "PROSTATOCISTITIS"
    },
    {
        "id": "87487724-e97b-7674-57cc-b8d904c0271f",
        "NOMBRE": "PROTEINURIA AISLADA"
    },
    {
        "id": "02201f34-f97c-593e-8e7b-6200415dee97",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA"
    },
    {
        "id": "f3383fce-d556-a33a-684c-9a608ea9c15a",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "87b3d9ae-386e-294e-ffc0-2116e1a7879a",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "0aaf3234-cb5d-4327-ad12-0650085c3ba7",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "4289afd8-1096-86e4-1f9a-f1e465f05157",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "026ca480-bcf7-2a05-9269-4a7668410692",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "509d7f1b-e51d-2bdd-9ce1-1255fd5cd17e",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "1ea28c99-1aee-6c9f-2e29-065733c92071",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "bbf87818-7e27-f8c8-4ab7-6898ad7d4403",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "a9b7a139-5a6a-125f-913d-9433d0ddf574",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, NO ESPECIFICADA"
    },
    {
        "id": "2d3c629c-6896-2d2e-a328-1bfdf0e9247a",
        "NOMBRE": "PROTEINURIA AISLADA CON LESIÓN MORFOLÓGICA ESPECIFICADA, OTRAS"
    },
    {
        "id": "6d409f8e-5c54-8191-1c78-84b2f44c51f0",
        "NOMBRE": "PROTEINURIA GESTACIONAL"
    },
    {
        "id": "62515419-ef96-896c-09ff-26991201cf73",
        "NOMBRE": "PROTEINURIA ORTOSTÁTICA, NO ESPECIFICADA"
    },
    {
        "id": "fba08f38-3c36-8b3a-8570-90bd254668a4",
        "NOMBRE": "PROTEINURIA PERSISTENTE, NO ESPECIFICADA"
    },
    {
        "id": "f43c16f9-547d-688c-c71a-5a8744782f81",
        "NOMBRE": "PROTEUS (MIRABILIS) (MORGANII) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "2e2a2dbf-3f81-f622-0e8c-5574f06a46c5",
        "NOMBRE": "PROTRUSIÓN DE ACETÁBULO"
    },
    {
        "id": "0478d9ce-9b7b-1805-9927-12a9ad3a09cc",
        "NOMBRE": "PRUEBA DE ALCOHOL O DROGAS EN LA SANGRE"
    },
    {
        "id": "9b085e90-eca6-8089-47ab-7e800a283318",
        "NOMBRE": "PRUEBA Y AJUSTE DE ANTEOJOS Y LENTES DE CONTACTO"
    },
    {
        "id": "53426bd2-53de-a99b-3212-e55dd1413647",
        "NOMBRE": "PRUEBA Y AJUSTE DE AUDÍFONOS"
    },
    {
        "id": "4e8854d1-0349-8af0-1c16-36406e239314",
        "NOMBRE": "PRUEBA Y AJUSTE DE BRAZO ARTIFICIAL (COMPLETO) (PARCIAL)"
    },
    {
        "id": "e57feb5a-b579-b6f1-7e75-e488262a4bc5",
        "NOMBRE": "PRUEBA Y AJUSTE DE DISPOSITIVO NO ESPECIFICADO"
    },
    {
        "id": "fdb1a009-f312-121a-b053-d0343f9699fe",
        "NOMBRE": "PRUEBA Y AJUSTE DE DISPOSITIVO ORTODÓNCICO"
    },
    {
        "id": "0aad9d87-3974-1eaf-1b9b-7a7e047658c3",
        "NOMBRE": "PRUEBA Y AJUSTE DE DISPOSITIVO ORTOPÉDICO"
    },
    {
        "id": "076ed0bc-5dc9-7280-b85a-f089081528bc",
        "NOMBRE": "PRUEBA Y AJUSTE DE DISPOSITIVO PROTÉSICO EXTERNO NO ESPECIFICADO"
    },
    {
        "id": "978eee0e-92a9-4728-0a06-49b1bf6377d3",
        "NOMBRE": "PRUEBA Y AJUSTE DE DISPOSITIVO URINARIO"
    },
    {
        "id": "edf204e1-2d7d-7d16-5324-2f89a06a07de",
        "NOMBRE": "PRUEBA Y AJUSTE DE DISPOSITIVOS PROTÉSICOS EXTERNOS"
    },
    {
        "id": "6dd993df-7c12-a6b9-6088-b1dfd2456129",
        "NOMBRE": "PRUEBA Y AJUSTE DE ILEOSTOMÍA U OTRO DISPOSITIVO INTESTINAL"
    },
    {
        "id": "66a276ca-0eb0-749c-da9a-64b0055f991a",
        "NOMBRE": "PRUEBA Y AJUSTE DE OJO ARTIFICIAL"
    },
    {
        "id": "2f4c7086-9ff9-9886-8b36-d47ea0aa0f17",
        "NOMBRE": "PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS"
    },
    {
        "id": "fd092f10-647c-e14a-cd91-7bbba5cccec1",
        "NOMBRE": "PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS ESPECIFICADOS"
    },
    {
        "id": "5f6a65b4-f656-4b3a-4595-8b83019edbb3",
        "NOMBRE": "PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS PROTÉSICOS EXTERNOS"
    },
    {
        "id": "85c7ec88-a953-81c8-67ad-85d37cf500d0",
        "NOMBRE": "PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS RELACIONADOS CON EL SISTEMA NERVIOSO Y LOS SENTIDOS ESPECIALES"
    },
    {
        "id": "0b227cb9-049e-bc58-dfb1-1d5af069dd9c",
        "NOMBRE": "PRUEBA Y AJUSTE DE PIERNA ARTIFICIAL (COMPLETA) (PARCIAL)"
    },
    {
        "id": "3985a513-464d-6c95-664b-2ce8d5bd1820",
        "NOMBRE": "PRUEBA Y AJUSTE DE PRÓTESIS DENTAL"
    },
    {
        "id": "ffdaa128-31a5-95b9-6724-b41b96daaa79",
        "NOMBRE": "PRUEBA Y AJUSTE DE PRÓTESIS MAMARIA EXTERNA"
    },
    {
        "id": "283e405d-e846-64da-5157-ef77515d9c52",
        "NOMBRE": "PRUEBAS DE SENSIBILIZACIÓN Y DIAGNÓSTICO CUTÁNEO"
    },
    {
        "id": "83a44790-b0d9-a4d9-cf6d-d4709b4ab541",
        "NOMBRE": "PRURIGO DE BESNIER"
    },
    {
        "id": "8e3f782d-0c87-abb6-a2f2-bdacc5e16e16",
        "NOMBRE": "PRURIGO NODULAR"
    },
    {
        "id": "f3b0cd3a-785b-a37f-a160-bc74f794b45e",
        "NOMBRE": "PRURITO"
    },
    {
        "id": "63e277f7-d415-8be9-4b79-081467a344ff",
        "NOMBRE": "PRURITO ANAL"
    },
    {
        "id": "9d68ab9d-bd88-f71c-1617-ca7fea026b72",
        "NOMBRE": "PRURITO ANOGENITAL, NO ESPECIFICADO"
    },
    {
        "id": "437fe6a5-428f-a6e5-5d9f-4510a2b44726",
        "NOMBRE": "PRURITO ESCROTAL"
    },
    {
        "id": "859787e8-da5e-e298-7014-dbd23cd31e1c",
        "NOMBRE": "PRURITO VULVAR"
    },
    {
        "id": "a34e48f1-902f-2e8b-3e0a-668317fc0bcc",
        "NOMBRE": "PRURITO, NO ESPECIFICADO"
    },
    {
        "id": "873dea9c-e57d-f893-297c-23e50c0be289",
        "NOMBRE": "PSEUDOCOXALGIA"
    },
    {
        "id": "ba463264-8e9e-10b3-2a89-d92ed10e95b2",
        "NOMBRE": "PSEUDOHIPOPARATIROIDISMO"
    },
    {
        "id": "cd4ffac0-66b9-eae1-0cdf-22edb9decc67",
        "NOMBRE": "PSEUDOMONAS (AERUGINOSA) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "9c136829-167c-8599-fc5b-626722d530f5",
        "NOMBRE": "PSICOSIS DE ORIGEN NO ORGÁNICO, NO ESPECIFICADA"
    },
    {
        "id": "fb12f6e3-950f-cd11-c3e1-c160a8805abe",
        "NOMBRE": "PSICOTERAPIA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "c98fe325-6b28-acf7-9ebe-c9dfec5916a8",
        "NOMBRE": "PSORIASIS"
    },
    {
        "id": "214b812d-c907-e0ed-7709-1a536df4a3fc",
        "NOMBRE": "PSORIASIS GUTTATA"
    },
    {
        "id": "34f4de15-5732-1b38-90d1-ef5063fdaecd",
        "NOMBRE": "PSORIASIS PUSTULOSA GENERALIZADA"
    },
    {
        "id": "716c264c-5d45-e933-d6bd-3fc8fdad9ab3",
        "NOMBRE": "PSORIASIS VULGAR"
    },
    {
        "id": "ee4e083a-72d2-b4f8-03e1-26ccb7d480c6",
        "NOMBRE": "PSORIASIS, NO ESPECIFICADA"
    },
    {
        "id": "91b07f2c-36c5-9967-7e9a-f9b2916c7c69",
        "NOMBRE": "PTERIGIÓN"
    },
    {
        "id": "fcae013f-ab2b-42c9-61f6-5b6d995854fb",
        "NOMBRE": "PTERIGIÓN DE LA LARINGE"
    },
    {
        "id": "12904437-5dac-c8d8-1c8f-b43c74717ef5",
        "NOMBRE": "PTERIGIÓN DEL CUELLO"
    },
    {
        "id": "7aa7da0c-d574-c0e0-1112-8d61a56e8f67",
        "NOMBRE": "PTERIGIÓN DEL ESÓFAGO"
    },
    {
        "id": "7c8b3619-8ba2-ba22-5508-9347e3b44df9",
        "NOMBRE": "PUBERTAD PRECOZ"
    },
    {
        "id": "d8c38e9e-8441-8dbe-ed8d-af4362edf6b7",
        "NOMBRE": "PUBERTAD RETARDADA"
    },
    {
        "id": "225a82c9-aa9c-999d-eb57-c0a8b50bd585",
        "NOMBRE": "PULGAR(ES) SUPERNUMERARIO(S)"
    },
    {
        "id": "08aef16b-047f-693b-fb98-1375356d6b8f",
        "NOMBRE": "PULMÓN DEL DESCORTEZADOR DEL ARCE"
    },
    {
        "id": "52437a00-e849-594e-05e6-997d4605eb32",
        "NOMBRE": "PULMÓN DEL GRANJERO"
    },
    {
        "id": "ce4d5928-f38c-f8eb-94f2-f997a5a91db7",
        "NOMBRE": "PULMÓN DEL MANIPULADOR DE HONGOS"
    },
    {
        "id": "240816e8-2948-5860-3873-32d50a3d6fe2",
        "NOMBRE": "PULMÓN DEL MANIPULADOR DE MALTA"
    },
    {
        "id": "69487273-88e6-be12-205a-e475166c26dd",
        "NOMBRE": "PULMÓN DEL ORNITÓFILO"
    },
    {
        "id": "877df8a2-c8eb-b3cb-0f8a-390d4648ff90",
        "NOMBRE": "PULPITIS"
    },
    {
        "id": "f668655a-2570-ea38-5425-38b48ca980b5",
        "NOMBRE": "PUNCIÓN O LACERACIÓN ACCIDENTAL DURANTE UN PROCEDIMIENTO, NO CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1e25f7bc-9b1c-7dce-52b8-ebd835883a6e",
        "NOMBRE": "PÚRPURA ALÉRGICA"
    },
    {
        "id": "2154bb13-1996-e7ad-ed97-6706fc59771a",
        "NOMBRE": "PÚRPURA TROMBOCITOPÉNICA IDIOPÁTICA"
    },
    {
        "id": "6e4f3d7a-e3ab-684b-9487-d30388a76be7",
        "NOMBRE": "PÚRPURA Y OTRAS AFECCIONES HEMORRÁGICAS"
    },
    {
        "id": "f2f4904a-9574-c578-e63b-226dcb62bd6a",
        "NOMBRE": "PUSTULOSIS PALMAR Y PLANTAR"
    },
    {
        "id": "ab5fa064-9c7f-cf9b-21d4-db66ca3d42ff",
        "NOMBRE": "QUEMADURA CON RUPTURA Y DESTRUCCIÓN RESULTANTES DEL GLOBO OCULAR"
    },
    {
        "id": "400c5ccc-da30-cdbf-1851-3cee95e1e5b6",
        "NOMBRE": "QUEMADURA DE LA BOCA Y DE LA FARINGE"
    },
    {
        "id": "0d57dc48-193e-ef94-911f-53dfd41f01ce",
        "NOMBRE": "QUEMADURA DE LA CABEZA Y DEL CUELLO, DE PRIMER GRADO"
    },
    {
        "id": "26f9e4c9-ea6a-4b9d-9369-7198a1411fa9",
        "NOMBRE": "QUEMADURA DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO"
    },
    {
        "id": "d1fe830e-e7ab-3722-7f1d-f5966d3e8b70",
        "NOMBRE": "QUEMADURA DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO"
    },
    {
        "id": "d80810d1-1995-1a7c-3729-ab2e4341c55b",
        "NOMBRE": "QUEMADURA DE LA CABEZA Y DEL CUELLO, GRADO NO ESPECIFICADO"
    },
    {
        "id": "dba66ce2-0c2f-338f-11cc-53685c595fdb",
        "NOMBRE": "QUEMADURA DE LA CADERA Y MIEMBRO INFERIOR, DE PRIMER GRADO, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "e6b8d382-ee44-0cb7-b87d-2a56918a65e2",
        "NOMBRE": "QUEMADURA DE LA CADERA Y MIEMBRO INFERIOR, DE SEGUNDO GRADO , EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "88b493e4-aaf0-1e58-2885-695416a72baa",
        "NOMBRE": "QUEMADURA DE LA CADERA Y MIEMBRO INFERIOR, DE TERCER GRADO, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "65c720c7-c86b-17ff-7714-c91ed29115a7",
        "NOMBRE": "QUEMADURA DE LA CADERA Y MIEMBRO INFERIOR, GRADO NO ESPECIFICADO, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "101df7ee-30bc-6cd6-eb0b-005f4e96b093",
        "NOMBRE": "QUEMADURA DE LA CÓRNEA Y SACO CONJUNTIVAL"
    },
    {
        "id": "17b0d898-3d81-f7b0-1562-e1e267bc95a2",
        "NOMBRE": "QUEMADURA DE LA LARINGE Y DE LA TRÁQUEA"
    },
    {
        "id": "0d375c8b-617c-8d54-43fb-ed6b20f2de1c",
        "NOMBRE": "QUEMADURA DE LA MUÑECA Y DE LA MANO, DE PRIMER GRADO"
    },
    {
        "id": "27114ed4-da10-3120-0a7c-ab18f7d13fcf",
        "NOMBRE": "QUEMADURA DE LA MUÑECA Y DE LA MANO, DE SEGUNDO GRADO"
    },
    {
        "id": "fb2089a7-b4cb-3ec1-c5b3-8716d41a4bbd",
        "NOMBRE": "QUEMADURA DE LA MUÑECA Y DE LA MANO, DE TERCER GRADO"
    },
    {
        "id": "a0bc966b-3537-e75f-97dc-a0f1cce50839",
        "NOMBRE": "QUEMADURA DE LA MUÑECA Y DE LA MANO, GRADO NO ESPECIFICADO"
    },
    {
        "id": "3526dade-9b98-d135-cac8-0c6ed299355a",
        "NOMBRE": "QUEMADURA DE LAS VÍAS RESPIRATORIAS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "3ab3d238-f4ae-90c1-e489-30133bec3b36",
        "NOMBRE": "QUEMADURA DE ÓRGANOS GENITOURINARIOS INTERNOS"
    },
    {
        "id": "96d86615-296d-0861-8124-5f5eec8cee8a",
        "NOMBRE": "QUEMADURA DE OTRAS PARTES DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "0f911dd2-75e4-0591-52ec-208cd1d64d7e",
        "NOMBRE": "QUEMADURA DE OTRAS PARTES DEL OJO Y SUS ANEXOS"
    },
    {
        "id": "1ebc6a26-42e8-f3d1-70b3-30ad95a59514",
        "NOMBRE": "QUEMADURA DE OTRAS PARTES DEL TUBO DIGESTIVO"
    },
    {
        "id": "b053b39b-f0da-2718-dfc8-a3cf57694d3a",
        "NOMBRE": "QUEMADURA DE OTROS ÓRGANOS INTERNOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "958df779-de35-c5ea-6fee-5dc0edd325a9",
        "NOMBRE": "QUEMADURA DE PRIMER GRADO, REGIÓN DEL CUERPO NO ESPECIFICADA"
    },
    {
        "id": "ef5eb3ef-6b3c-583b-2517-845ec5460d6c",
        "NOMBRE": "QUEMADURA DE REGIÓN DEL CUERPO Y GRADO NO ESPECIFICADOS"
    },
    {
        "id": "161f4af0-73c4-b834-c133-f55d298c7791",
        "NOMBRE": "QUEMADURA DE SEGUNDO GRADO, REGIÓN DEL CUERPO NO ESPECIFICADA"
    },
    {
        "id": "c18aa4d6-472a-fd6b-70fc-303367238e77",
        "NOMBRE": "QUEMADURA DE TERCER GRADO, REGIÓN DEL CUERPO NO ESPECIFICADA"
    },
    {
        "id": "0ec9a64d-6947-95cd-dc24-58fb95ed6473",
        "NOMBRE": "QUEMADURA DEL ESÓFAGO"
    },
    {
        "id": "c0a48c2b-28d3-5f34-888c-58042fdd064a",
        "NOMBRE": "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE PRIMER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "810e2b52-9885-c9bb-cb4c-f6bbb050d060",
        "NOMBRE": "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE SEGUNDO GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "12adfd32-7a07-ee96-c7fc-d873971e3c91",
        "NOMBRE": "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "3a046e27-ba12-535b-0d97-896eab3ee8f5",
        "NOMBRE": "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, GRADO NO ESPECIFICADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "2c262790-174c-0c2f-eef8-152df636fcb3",
        "NOMBRE": "QUEMADURA DEL OJO Y ANEXOS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "aecabfc8-7053-bfe6-5000-aa4fb66524a8",
        "NOMBRE": "QUEMADURA DEL PÁRPADO Y ÁREA PERIOCULAR"
    },
    {
        "id": "287925f7-ba29-8a16-efc3-94fee81f2fd4",
        "NOMBRE": "QUEMADURA DEL TOBILLO Y DEL PIE, DE PRIMER GRADO"
    },
    {
        "id": "240bfdb3-c567-c559-93da-73713c6d5fb3",
        "NOMBRE": "QUEMADURA DEL TOBILLO Y DEL PIE, DE SEGUNDO GRADO"
    },
    {
        "id": "b688d3a7-ce0f-512b-e649-1630b2140575",
        "NOMBRE": "QUEMADURA DEL TOBILLO Y DEL PIE, DE TERCER GRADO"
    },
    {
        "id": "d2267798-a560-aa3f-d32c-92a2585d1c67",
        "NOMBRE": "QUEMADURA DEL TOBILLO Y DEL PIE, GRADO NO ESPECIFICADO"
    },
    {
        "id": "d27fe1a6-651b-c806-17b1-15f6d9404a71",
        "NOMBRE": "QUEMADURA DEL TRONCO, DE PRIMER GRADO"
    },
    {
        "id": "a055c06c-8be5-2373-a9f9-209e06e60786",
        "NOMBRE": "QUEMADURA DEL TRONCO, DE SEGUNDO GRADO"
    },
    {
        "id": "3b8d8249-9b26-7ac6-7f44-adb62c05ddcd",
        "NOMBRE": "QUEMADURA DEL TRONCO, DE TERCER GRADO"
    },
    {
        "id": "45a9a8a0-3a79-808a-e9cf-79cce1aa7550",
        "NOMBRE": "QUEMADURA DEL TRONCO, GRADO NO ESPECIFICADO"
    },
    {
        "id": "55f02844-95e0-3a72-767c-4b2f2cc91153",
        "NOMBRE": "QUEMADURA QUE AFECTA LA LARINGE Y LA TRÁQUEA CON EL PULMÓN"
    },
    {
        "id": "f5afca30-ede6-05d7-47a4-7361192ebcd4",
        "NOMBRE": "QUEMADURA SOLAR"
    },
    {
        "id": "bc803349-dd2b-095d-53c9-a5748f9e038a",
        "NOMBRE": "QUEMADURA SOLAR DE PRIMER GRADO"
    },
    {
        "id": "18ff563b-b62a-9701-e85e-e95ebd7dce43",
        "NOMBRE": "QUEMADURA SOLAR DE SEGUNDO GRADO"
    },
    {
        "id": "b5518a1c-f450-b4fc-01b3-f8042c5707ac",
        "NOMBRE": "QUEMADURA SOLAR DE TERCER GRADO"
    },
    {
        "id": "3c0c3a59-19e3-5bef-92d4-0bbc1a960e18",
        "NOMBRE": "QUEMADURA SOLAR, NO ESPECIFICADA"
    },
    {
        "id": "0e009926-a721-b792-f67c-98aaff135583",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DE LA CABEZA Y DEL CUELLO"
    },
    {
        "id": "b4b95186-3401-1f5b-6f9f-2211ab63da9f",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DE LA CADERA Y MIEMBRO INFERIOR, EXCEPTO TOBILLO Y PIE"
    },
    {
        "id": "a5e487b4-90da-6f31-c16a-96ab20b8047e",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "1970ce26-edbf-ee54-3c8e-3270fd25ff49",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DE LAS VÍAS RESPIRATORIAS"
    },
    {
        "id": "5da2bffa-c320-032a-18db-1068484c2567",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DE OTROS ÓRGANOS INTERNOS"
    },
    {
        "id": "caa5f96b-3fdf-3501-afaf-55db3a9001f2",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DEL HOMBRO Y MIEMBRO SUPERIOR, EXCEPTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "09b35026-c2cd-1ef5-5d9b-440704ed49d7",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "e63f0d82-0222-5637-8460-388fbdb5ef02",
        "NOMBRE": "QUEMADURA Y CORROSIÓN DEL TRONCO"
    },
    {
        "id": "560dba88-bd4b-a0f8-34f2-46bf520edc76",
        "NOMBRE": "QUEMADURA Y CORROSIÓN LIMITADA AL OJO Y SUS ANEXOS"
    },
    {
        "id": "fdd1960d-e908-2acd-fad4-f8b08d49f868",
        "NOMBRE": "QUEMADURA Y CORROSIÓN, REGIÓN DEL CUERPO NO ESPECIFICADA"
    },
    {
        "id": "3c449512-a5ef-1d03-c1e8-21d9dc4b1914",
        "NOMBRE": "QUEMADURAS CLASIFICADAS SEGÚN LA EXTENSIÓN DE LA SUPERFICIE DEL CUERPO AFECTADA"
    },
    {
        "id": "7615d541-6794-ba00-680f-780630da8615",
        "NOMBRE": "QUEMADURAS DE MÚLTIPLES REGIONES, GRADO NO ESPECIFICADO"
    },
    {
        "id": "258ba725-03d4-978a-b6ce-a134a30081c6",
        "NOMBRE": "QUEMADURAS DE MÚLTIPLES REGIONES, MENCIONADAS COMO DE NO MÁS DE PRIMER GRADO"
    },
    {
        "id": "871c33f5-23be-fe25-9f91-3f1f497fc728",
        "NOMBRE": "QUEMADURAS DE MÚLTIPLES REGIONES, MENCIONADAS COMO DE NO MÁS DE SEGUNDO GRADO"
    },
    {
        "id": "7d9621b6-ff58-c962-eea0-4c5dbb0029f6",
        "NOMBRE": "QUEMADURAS MÚLTIPLES, CON MENCIÓN AL MENOS DE UNA QUEMADURA DE TERCER GRADO"
    },
    {
        "id": "897a7ad7-2df0-f4d0-4b96-ef90040a5a45",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 10 AL 19% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "b72827ff-b528-99e7-d39b-a8fd1152afee",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 20 AL 29% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "04fe7f82-f4ae-a109-b571-0c864597ee83",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 30 AL 39% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "01208d73-ad28-fb97-4b2a-49e9a1544c4d",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 40 AL 49% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "0519f7ae-97fa-cc40-6983-2ffc10d33962",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 50 AL 59% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "56cce636-8ec5-7b60-0e98-ea024148366e",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 60 AL 69% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "d548c767-c6d1-0e8f-c0cc-46213cc26e98",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 70 AL 79% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "5552c615-dc71-91ca-8ac7-be42e7bc9f71",
        "NOMBRE": "QUEMADURAS QUE AFECTAN DEL 80 AL 89% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "12ccd5cb-f404-219c-640d-2bc452eaaa6f",
        "NOMBRE": "QUEMADURAS QUE AFECTAN EL 90% O MÁS DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "db8e50d1-dd7f-7474-3b33-ce893cafc92c",
        "NOMBRE": "QUEMADURAS QUE AFECTAN MENOS DEL 10% DE LA SUPERFICIE DEL CUERPO"
    },
    {
        "id": "91aa27e9-914f-588b-e297-7dd07bd54231",
        "NOMBRE": "QUEMADURAS Y CORROSIONES DE MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "be031a5c-1bd4-0621-f3ec-50f89ae627c1",
        "NOMBRE": "QUERATITIS"
    },
    {
        "id": "f98cb3d4-9ec4-aa4a-9e61-9ef750abd9d5",
        "NOMBRE": "QUERATITIS INTERSTICIAL Y PROFUNDA"
    },
    {
        "id": "099e7b8e-6967-d38b-4bac-79a24b078bba",
        "NOMBRE": "QUERATITIS Y QUERATOCONJUNTIVITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS, CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "64bfcfce-0611-580e-c948-0ef9361a9993",
        "NOMBRE": "QUERATITIS Y QUERATOCONJUNTIVITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "432393ce-95e7-c72f-d519-e074ba0fe7f5",
        "NOMBRE": "QUERATITIS Y QUERATOCONJUNTIVITIS POR HERPES SIMPLE"
    },
    {
        "id": "80c041fb-0f57-a7e4-4dca-c2d29180da8b",
        "NOMBRE": "QUERATITIS,NO ESPECIFICADA"
    },
    {
        "id": "c49ae119-226e-6cfd-0e67-ef21996dde0a",
        "NOMBRE": "QUERATOCONJUNTIVITIS"
    },
    {
        "id": "12f6a1c4-6fe3-4a9c-263a-d04a868bb939",
        "NOMBRE": "QUERATOCONJUNTIVITIS DEBIDA A ADENOVIRUS"
    },
    {
        "id": "8b4ceace-40d1-1bf7-481f-7961419481ea",
        "NOMBRE": "QUERATOCONO"
    },
    {
        "id": "f2b353eb-033c-d916-6bd4-eb479f8a219e",
        "NOMBRE": "QUERATODERMA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c8ea0f77-6cdd-2339-cf5a-214503652835",
        "NOMBRE": "QUERATOPATÍA (BULLOSA AFÁQUICA) CONSECUTIVA A CIRUGÍA DE CATARATA"
    },
    {
        "id": "aabc9461-0f59-a775-1f5f-c01db59342b3",
        "NOMBRE": "QUERATOPATÍA VESICULAR"
    },
    {
        "id": "310e1a1a-8d34-fac3-6253-436bfd39058d",
        "NOMBRE": "QUERATOSIS [QUERATODERMIA] PALMAR Y PLANTAR ADQUIRIDA"
    },
    {
        "id": "91007de2-aaee-cda8-4538-208476897874",
        "NOMBRE": "QUERATOSIS ACTÍNICA"
    },
    {
        "id": "ea57ea55-b6e4-5b08-ed80-859979718634",
        "NOMBRE": "QUERATOSIS FOLICULAR ADQUIRIDA"
    },
    {
        "id": "9dd326eb-13b8-0baa-6e52-be69d4677b30",
        "NOMBRE": "QUERATOSIS FOLICULAR Y PARAFOLICULAR PENETRANTE DEL CUTIS [KYRLE]"
    },
    {
        "id": "b0ec191d-7459-643e-1e76-58886c21c4f8",
        "NOMBRE": "QUERATOSIS PUNCTATA (PALMAR Y PLANTAR)"
    },
    {
        "id": "2b21eff3-ac3b-0589-fbdc-d56f7d3a1d08",
        "NOMBRE": "QUERATOSIS SEBORREICA"
    },
    {
        "id": "bbad0ebd-3390-b204-a31f-409381aec66c",
        "NOMBRE": "QUILOTÓRAX"
    },
    {
        "id": "8c3b37e1-ef34-f578-89dd-8a7afde41b4e",
        "NOMBRE": "QUILURIA"
    },
    {
        "id": "2e52ce0e-7b9e-d40c-3ea6-15633beff6cf",
        "NOMBRE": "QUIMERA 46, XX/46, XY"
    },
    {
        "id": "a9cc16fa-d06c-f048-f318-fed026da1974",
        "NOMBRE": "QUISTE BILIAR"
    },
    {
        "id": "bcae8a32-e515-fc83-8764-c6ecf69a9e78",
        "NOMBRE": "QUISTE CEREBRAL"
    },
    {
        "id": "fb2cc711-4ecb-61ce-9603-659c2e398d86",
        "NOMBRE": "QUISTE CONGÉNITO DEL MEDIASTINO"
    },
    {
        "id": "664756ff-ea97-db7b-d4cf-4ab39e80396f",
        "NOMBRE": "QUISTE CONGÉNITO DEL PÁNCREAS"
    },
    {
        "id": "db56c94c-c89d-e135-6f1a-0314f0f7d501",
        "NOMBRE": "QUISTE DE LA GLÁNDULA DE BARTHOLIN"
    },
    {
        "id": "4d82c34e-dd91-e330-3d9a-d33e96875d64",
        "NOMBRE": "QUISTE DE LA REGIÓN BUCAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "1d61b218-40e4-bc70-08f6-b9d1306bce11",
        "NOMBRE": "QUISTE DE LA VULVA"
    },
    {
        "id": "82c8abe5-b0df-369f-810d-6735a90d4f21",
        "NOMBRE": "QUISTE DE RIÑÓN, ADQUIRIDO"
    },
    {
        "id": "d44b95aa-e391-4c7b-0e42-e64592dacf83",
        "NOMBRE": "QUISTE DEL BAZO"
    },
    {
        "id": "f2fa4734-fce0-395b-9d00-8c14294be629",
        "NOMBRE": "QUISTE DEL COLÉDOCO"
    },
    {
        "id": "43635c8d-c552-f96e-fe8a-a6cede1b341e",
        "NOMBRE": "QUISTE DEL CUERPO AMARILLO"
    },
    {
        "id": "26bbdd12-81ce-a54a-b0d8-ad1c278f4197",
        "NOMBRE": "QUISTE DEL IRIS, DEL CUERPO CILIAR Y DE LA CÁMARA ANTERIOR"
    },
    {
        "id": "d23d2893-ac0c-98b4-f7f9-fd8a3879d1bc",
        "NOMBRE": "QUISTE DEL PÁNCREAS"
    },
    {
        "id": "b562f909-19ed-33a9-c4c4-a04608fcfbca",
        "NOMBRE": "QUISTE EMBRIONARIO DE LA TROMPA DE FALOPIO"
    },
    {
        "id": "1d9b5bc6-2894-926a-c986-e5d642cdf232",
        "NOMBRE": "QUISTE EMBRIONARIO DEL CUELLO UTERINO"
    },
    {
        "id": "017e0657-68fe-ec71-cc7e-ff450c13ce43",
        "NOMBRE": "QUISTE EMBRIONARIO DEL LIGAMENTO ANCHO"
    },
    {
        "id": "fb55b63c-3354-3a45-38de-23ecced33af3",
        "NOMBRE": "QUISTE EN DESARROLLO DEL OVARIO"
    },
    {
        "id": "42f2c628-1739-f70c-2090-a095e95e05fa",
        "NOMBRE": "QUISTE EPIDÉRMICO"
    },
    {
        "id": "353fcd0d-92b2-19ac-aab4-79b222931f85",
        "NOMBRE": "QUISTE FOLICULAR DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "0c6bf277-5bf7-5b58-9558-29fc5dc32c68",
        "NOMBRE": "QUISTE FOLICULAR DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "f6d8326a-f378-72db-4062-0a0348ba5876",
        "NOMBRE": "QUISTE FOLICULAR DEL OVARIO"
    },
    {
        "id": "730368e1-d9e7-5d4e-f283-81d6f0ce1a9a",
        "NOMBRE": "QUISTE ÓSEO ANEURISMÁTICO"
    },
    {
        "id": "4a27af2a-1219-4293-d283-4714886e6f73",
        "NOMBRE": "QUISTE ÓSEO SOLITARIO"
    },
    {
        "id": "c037610b-504f-d17d-a6b9-a65df98c810b",
        "NOMBRE": "QUISTE PILONIDAL"
    },
    {
        "id": "e41ce9be-92db-e345-2ab7-89f63b49bf57",
        "NOMBRE": "QUISTE PILONIDAL CON ABSCESO"
    },
    {
        "id": "9afb9cd5-5702-bf48-ee08-a4cc06d29d74",
        "NOMBRE": "QUISTE PILONIDAL SIN ABSCESO"
    },
    {
        "id": "bcff63ba-d956-f10b-5112-a94119d7d219",
        "NOMBRE": "QUISTE PULMONAR CONGÉNITO"
    },
    {
        "id": "b37f6bfc-4584-45e1-3f9c-20ee301f1347",
        "NOMBRE": "QUISTE RADICULAR"
    },
    {
        "id": "113e922f-f232-2038-34bf-6b49acbcdb39",
        "NOMBRE": "QUISTE RENAL SOLITARIO CONGÉNITO"
    },
    {
        "id": "cb3728e1-884a-caaa-b098-e1c37d34b994",
        "NOMBRE": "QUISTE SINOVIAL DEL HUECO POPLÍTEO [DE BAKER]"
    },
    {
        "id": "77dca53f-a98d-9503-143d-0da9f85b325c",
        "NOMBRE": "QUISTE SOLITARIO DE LA MAMA"
    },
    {
        "id": "b922a2a9-499f-d4a7-e9d4-7a85d19a64d1",
        "NOMBRE": "QUISTE TRICODÉRMICO"
    },
    {
        "id": "e191a64f-99d4-6b05-3d0b-afdf44fa5679",
        "NOMBRE": "QUISTE Y MUCOCELE DE LA NARIZ Y DEL SENO PARANASAL"
    },
    {
        "id": "24c44f96-d561-d1df-1678-414fc55568ac",
        "NOMBRE": "QUISTES CEREBRALES CONGÉNITOS"
    },
    {
        "id": "d5958127-ca47-2509-1cbc-843c12f5814b",
        "NOMBRE": "QUISTES DE LAS FISURAS DE LA REGIÓN ORAL (NO ODONTOGÉNICOS)"
    },
    {
        "id": "b7e8c74c-5018-a599-1775-dcb66b175bce",
        "NOMBRE": "QUISTES ORIGINADOS POR EL DESARROLLO DE LOS DIENTES"
    },
    {
        "id": "b7cd008d-277f-91b9-6074-3e76e7478af3",
        "NOMBRE": "QUISTES PERIVENTRICULARES ADQUIRIDOS DEL RECIÉN NACIDO"
    },
    {
        "id": "1f4b7cb6-7d54-9cec-0587-b5056e29cc85",
        "NOMBRE": "QUITES DE LA REGIÓN BUCAL, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "87f28d41-911d-5df0-c59f-ba8c1c345fbc",
        "NOMBRE": "RABIA"
    },
    {
        "id": "696cba22-b534-7081-4523-9b9e8d1a4247",
        "NOMBRE": "RABIA SELVÁTICA"
    },
    {
        "id": "b2d3fead-b957-f6d0-aaac-0d3c2f8976d5",
        "NOMBRE": "RABIA URBANA"
    },
    {
        "id": "11b11901-875f-108f-92cd-239387a399b1",
        "NOMBRE": "RABIA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "08dd2ff5-df11-f4bf-7b57-3325c46870a7",
        "NOMBRE": "RADICULOPATÍA"
    },
    {
        "id": "b08b35e5-1020-c449-77e3-a47a5f5868da",
        "NOMBRE": "RADIODERMATITIS"
    },
    {
        "id": "8e524c4a-66fe-543b-a0d7-e63b5767f2ec",
        "NOMBRE": "RADIODERMATITIS AGUDA"
    },
    {
        "id": "67c95b7f-ce2a-5444-9bc7-2a467f9193d4",
        "NOMBRE": "RADIODERMATITIS CRÓNICA"
    },
    {
        "id": "ffe05c97-dc71-7518-2f60-3529709e8c8c",
        "NOMBRE": "RADIODERMATITIS, NO ESPECIFICADA"
    },
    {
        "id": "68db9772-42ec-f03d-7400-e956fa23eb24",
        "NOMBRE": "RAÍZ DENTAL RETENIDA"
    },
    {
        "id": "64b8a649-4007-0367-2e4b-30d7e1a623d3",
        "NOMBRE": "RAQUITISMO ACTIVO"
    },
    {
        "id": "e411dee1-c9f8-e9ef-d295-10240f27cee3",
        "NOMBRE": "RASGO DREPANOCÍTICO"
    },
    {
        "id": "b44a8025-f036-d770-632b-5dffa930d40d",
        "NOMBRE": "RASGO TALASÉMICO"
    },
    {
        "id": "02d39f31-a61b-db22-211e-b1301859b68d",
        "NOMBRE": "REABSORCIÓN PATOLÓGICA DE LOS DIENTES"
    },
    {
        "id": "c4a8f9b7-f509-8f71-4bce-fdcdf0a6915e",
        "NOMBRE": "REACCIÓN AGUDA A SUSTANCIA EXTRAÑA DEJADA ACCIDENTALMENTE DURANTE UN PROCEDIMIENTO"
    },
    {
        "id": "e0dc8848-1c80-fdcd-f7ed-3f9e994f148a",
        "NOMBRE": "REACCIÓN AL ESTRÉS AGUDO"
    },
    {
        "id": "ec18cf10-a46b-4a6c-eb1c-c12205c3819e",
        "NOMBRE": "REACCIÓN AL ESTRÉS GRAVE Y TRASTORNOS DE ADAPTACIÓN"
    },
    {
        "id": "846f82d6-f2a6-fe0e-a93d-7a48be55bd7f",
        "NOMBRE": "REACCIÓN AL ESTRÉS GRAVE, NO ESPECIFICADA"
    },
    {
        "id": "f8ca370a-5dd5-0505-49c1-df94f0526424",
        "NOMBRE": "REACCIÓN ANORMAL A LA PRUEBA CON TUBERCULINA"
    },
    {
        "id": "1dcc4256-d032-4ca2-743c-2e38b933c06c",
        "NOMBRE": "REACCIÓN DE HIPERSENSIBILIDAD DE LAS VÍAS RESPIRATORIAS SUPERIORES, SITIO NO ESPECIFICADO"
    },
    {
        "id": "6e98634e-2608-92f9-534d-d7a4e13e3d45",
        "NOMBRE": "REACCIÓN DE INCOMPATIBILIDAD A RH"
    },
    {
        "id": "7a34cba2-cd3b-55fd-3913-005ca777df93",
        "NOMBRE": "REACCIÓN DE INCOMPATIBILIDAD AL GRUPO ABO"
    },
    {
        "id": "e37f782b-35eb-1fea-5899-8118de04f9ce",
        "NOMBRE": "REACCIÓN LIQUENOIDE DEBIDA A DROGAS"
    },
    {
        "id": "efb9f532-d8f5-5d0b-e647-4dfa31883f78",
        "NOMBRE": "REACCIÓN TÓXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL EMBARAZO"
    },
    {
        "id": "7133a210-d1f9-cfa5-8d19-9532bd7d4314",
        "NOMBRE": "REACCIÓN TÓXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL PUERPERIO"
    },
    {
        "id": "27bd7b3a-0c29-8cbe-2340-fc876c7f2345",
        "NOMBRE": "REACCIÓN TÓXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "95b390ac-5bc5-99c4-2d81-0e80fb417fb5",
        "NOMBRE": "REACCIONES E INTOXICACIONES DEBIDAS A DROGAS ADMINISTRADAS AL FETO Y AL RECIÉN NACIDO"
    },
    {
        "id": "820d53db-a0b0-7da2-b3f7-a4eb2b554f2c",
        "NOMBRE": "REALIZACIÓN DE UNA OPERACIÓN INADECUADA"
    },
    {
        "id": "bd95c69a-8ccb-ebe1-7f99-1f13877fd42d",
        "NOMBRE": "RECHAZO DE TRASPLANTE DE MÉDULA ÓSEA"
    },
    {
        "id": "3c217d58-ec20-066c-7813-5b701318c1f5",
        "NOMBRE": "RECIÉN NACIDO EXCEPCIONALMENTE GRANDE"
    },
    {
        "id": "122eea13-27ae-d086-db6d-8e3eecb9b96b",
        "NOMBRE": "RECIÉN NACIDO POSTÉRMINO SIN SOBREPESO PARA SU EDAD GESTACIONAL"
    },
    {
        "id": "bde8ee44-0b10-c874-c62b-d4aa080c7ca3",
        "NOMBRE": "RECTOCELE"
    },
    {
        "id": "d42f3af7-7701-2495-bc6e-2a7403090836",
        "NOMBRE": "RECTOSIGMOIDITIS (CRÓNICA) ULCERATIVA"
    },
    {
        "id": "63f946be-a207-f2d3-ce94-fb92f9a767bf",
        "NOMBRE": "REFLEJOS ANORMALES"
    },
    {
        "id": "9936e5b0-3c58-b530-4601-6e128dc81ff6",
        "NOMBRE": "REFLUJO VÉSICO-URÉTERO-RENAL CONGÉNITO"
    },
    {
        "id": "bcec3ff2-5293-4850-0336-e97413a919fc",
        "NOMBRE": "REGURGITACIÓN Y RUMIACIÓN DEL RECIÉN NACIDO"
    },
    {
        "id": "02428beb-2fe3-0a9c-caa8-894cea7eb6b7",
        "NOMBRE": "REHABILITACIÓN CARDÍACA"
    },
    {
        "id": "6498c973-1cc6-3c53-eca3-deecfdc13c88",
        "NOMBRE": "REHABILITACIÓN DEL ALCOHÓLICO"
    },
    {
        "id": "cbb37e9d-ced3-e188-31bb-492bd1945239",
        "NOMBRE": "REHABILITACIÓN DEL DROGADICTO"
    },
    {
        "id": "ca5e5ed7-ce37-016b-0bde-f9bfcc204c8a",
        "NOMBRE": "REINCIDENCIA DE COVID-19"
    },
    {
        "id": "dedbbbd9-557d-c4bd-08bf-7ad75870de2c",
        "NOMBRE": "REINCIDENCIA DE COVID-19"
    },
    {
        "id": "21d969f9-22ff-3b0e-0d21-ef178a0526dd",
        "NOMBRE": "REMOCIÓN DE OTRO ÓRGANO (PARCIAL) (TOTAL)"
    },
    {
        "id": "749440a4-32d4-e7a3-6e56-57bfd46622c1",
        "NOMBRE": "REORDENAMIENTO AUTOSÓMICO EQUILIBRADO EN INDIVIDUO ANORMAL"
    },
    {
        "id": "8035400e-96dd-14eb-611e-265055514489",
        "NOMBRE": "REORDENAMIENTO AUTOSÓMICO/SEXUAL EQUILIBRADO EN INDIVIDUO ANORMAL"
    },
    {
        "id": "ce0b976f-f7d8-9c8f-11b6-5165c63ecd3d",
        "NOMBRE": "REORDENAMIENTO EQUILIBRADO Y MARCADOR ESTRUCTURAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "e7141768-bc13-76d3-162e-5c68a59c0e55",
        "NOMBRE": "REORDENAMIENTOS EQUILIBRADOS Y MARCADORES ESTRUCTURALES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "9a731728-c9e7-3c99-5efa-42203dfdcb53",
        "NOMBRE": "REOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "671d22f9-e557-56e5-e4fa-983c95164703",
        "NOMBRE": "RESISTENCIA A ANTIBIÓTICOS BETALACTÁMICOS"
    },
    {
        "id": "6f1df4ec-f642-bb81-5ce1-f236dfa192bf",
        "NOMBRE": "RESISTENCIA A ANTIBIÓTICOS BETALÁCTAMICOS, NO ESPECIFICADOS"
    },
    {
        "id": "1b01e4b9-7670-f9b4-bf28-6152f55e47b7",
        "NOMBRE": "RESISTENCIA A ANTIBIÓTICOS NO ESPECIFICADOS"
    },
    {
        "id": "0075c1ba-0813-433b-4195-e4cf0bd331d2",
        "NOMBRE": "RESISTENCIA A BETALACTAMASA DE ESPECTRO EXTENDIDO (BLEE)"
    },
    {
        "id": "724343c8-7f12-b3f5-421a-48dbfb33029a",
        "NOMBRE": "RESISTENCIA A DROGAS ANTIMICÓTICAS"
    },
    {
        "id": "c55be4f4-8816-888c-17d3-2ebc23204fa0",
        "NOMBRE": "RESISTENCIA A DROGAS ANTIMICROBIANAS NO ESPECIFICADAS"
    },
    {
        "id": "ba52d633-5c28-fac3-44aa-953a165801fe",
        "NOMBRE": "RESISTENCIA A DROGAS ANTINEOPLÁSICAS"
    },
    {
        "id": "03d6c214-bb62-01ee-4cd7-dd2cc7175db3",
        "NOMBRE": "RESISTENCIA A DROGAS ANTIPARASITARIAS"
    },
    {
        "id": "c43cd2b8-65da-2717-5df8-d68fa832eb20",
        "NOMBRE": "RESISTENCIA A DROGAS ANTITUBERCULOSAS"
    },
    {
        "id": "b2392443-8336-83e7-0990-5219c693d7dd",
        "NOMBRE": "RESISTENCIA A DROGAS ANTIVIRALES"
    },
    {
        "id": "d0ffa37c-f56a-d0e5-bca5-4f80220c7658",
        "NOMBRE": "RESISTENCIA A METICILINA"
    },
    {
        "id": "37b22e02-1d11-d965-514b-045e69d1058a",
        "NOMBRE": "RESISTENCIA A MÚLTIPLES ANTIBIÓTICOS"
    },
    {
        "id": "c327cdee-fce3-3e28-23f0-4fa7d4f80977",
        "NOMBRE": "RESISTENCIA A MÚLTIPLES DROGAS ANTIMICROBIANAS"
    },
    {
        "id": "34b04307-e96c-fde4-b6b4-7dca7b2d04b0",
        "NOMBRE": "RESISTENCIA A OTRAS DROGAS ANTIMICROBIANAS"
    },
    {
        "id": "f8389d97-d260-679c-e85b-207d13563f47",
        "NOMBRE": "RESISTENCIA A OTRAS DROGAS ANTIMICROBIANAS ESPECIFICADAS"
    },
    {
        "id": "1a90100f-059a-f745-5529-be8bd92b01c3",
        "NOMBRE": "RESISTENCIA A OTROS ANTIBIÓTICOS"
    },
    {
        "id": "9781db12-ab44-9c4f-6a22-b3cdfd60a207",
        "NOMBRE": "RESISTENCIA A OTROS ANTIBIÓTICOS BETALÁCTAMICOS"
    },
    {
        "id": "947c0e2a-267d-ac1b-b8fb-ad15aac88eff",
        "NOMBRE": "RESISTENCIA A OTROS ANTIBIÓTICOS SIMILARES A VANCOMICINA"
    },
    {
        "id": "343a1bf7-d43b-fb51-f254-a2a8f09901cc",
        "NOMBRE": "RESISTENCIA A OTROS ANTIBIÓTICOS ÚNICOS ESPECIFICADOS"
    },
    {
        "id": "5241b56d-7194-bd4e-309d-1d81847ca5a1",
        "NOMBRE": "RESISTENCIA A PENICILINA"
    },
    {
        "id": "21587406-482c-04ad-cc69-22d34104157c",
        "NOMBRE": "RESISTENCIA A QUINOLONAS"
    },
    {
        "id": "daeec90c-2776-d8d1-34ca-7c5e8e30aa50",
        "NOMBRE": "RESISTENCIA A VANCOMICINA"
    },
    {
        "id": "ddb1da5d-aa1d-4b2b-1746-667911b1995d",
        "NOMBRE": "RESPIRACIÓN CON LA BOCA"
    },
    {
        "id": "8b2a7b23-7aa3-22b0-19ed-8612892eb8e7",
        "NOMBRE": "RESPIRACIÓN PERIÓDICA"
    },
    {
        "id": "3ebbd100-39fa-69d0-5b41-233e3ef042dc",
        "NOMBRE": "RESPUESTA FOTOALÉRGICA A DROGAS"
    },
    {
        "id": "d7aa3f9c-3d69-30dc-2851-c299b2c401be",
        "NOMBRE": "RESPUESTA FOTOTÓXICA A DROGAS"
    },
    {
        "id": "026c2d1b-37e3-39bd-b061-a0f27933b409",
        "NOMBRE": "RESULTADOS ANORMALES DE ESTUDIOS FUNCIONALES"
    },
    {
        "id": "6e357cd7-96aa-ab3b-83d1-c08eeba0fd4a",
        "NOMBRE": "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES CARDIOVASCULARES"
    },
    {
        "id": "dd7401c1-8062-7150-76d6-b9e7ecb5abe6",
        "NOMBRE": "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DE LA TIROIDES"
    },
    {
        "id": "77cd231d-7959-2ee0-3d56-e31d0b008bbc",
        "NOMBRE": "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL HÍGADO"
    },
    {
        "id": "9d56c8f0-78ba-2b2c-5374-59895aa0ec0b",
        "NOMBRE": "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL PULMÓN"
    },
    {
        "id": "531a0006-7c58-8935-e55f-bfcdec1714c6",
        "NOMBRE": "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL RIÑÓN"
    },
    {
        "id": "48e18932-2283-94ad-a99d-c4dc03a416d7",
        "NOMBRE": "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "84297ff2-c818-8b4e-88ac-ced996e283d6",
        "NOMBRE": "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL SISTEMA NERVIOSO PERIFÉRICO Y SENTIDOS ESPECIALES"
    },
    {
        "id": "69007b49-3f5c-f72f-718f-b85a85d2801f",
        "NOMBRE": "RESULTADOS ANORMALES EN LOS ESTUDIOS FUNCIONALES DE OTROS ÓRGANOS Y SISTEMAS"
    },
    {
        "id": "b7b974dd-adf9-07ee-4d8f-52d2f02ec433",
        "NOMBRE": "RESULTADOS ANORMALES EN OTROS ESTUDIOS FUNCIONALES ENDOCRINOS"
    },
    {
        "id": "96aad1de-1b19-b623-ecfa-9cbba9e305fc",
        "NOMBRE": "RETARDO DEL CRECIMIENTO FETAL Y DESNUTRICIÓN FETAL"
    },
    {
        "id": "0d433cee-b8c0-5d1c-eaf3-dd4a705ee41b",
        "NOMBRE": "RETARDO DEL CRECIMIENTO FETAL, NO ESPECIFICADO"
    },
    {
        "id": "3c9ceee4-7b46-d564-2a81-0a675a347586",
        "NOMBRE": "RETARDO DEL DESARROLLO"
    },
    {
        "id": "4a66e906-4ec1-3b84-c807-90b8889f85d7",
        "NOMBRE": "RETARDO DEL DESARROLLO DEBIDO A DESNUTRICIÓN PROTEICOCALÓRICA"
    },
    {
        "id": "68389e52-9b72-280c-b7d3-fe77dc22ac83",
        "NOMBRE": "RETENCIÓN DE CUERPO EXTRAÑO (ANTIGUO), CONSECUTIVA A HERIDA PENETRANTE DE LA ÓRBITA"
    },
    {
        "id": "09042a33-e4ef-85b8-8cb3-1ab75f070ac2",
        "NOMBRE": "RETENCIÓN DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO EN EL EMBARAZO"
    },
    {
        "id": "a729270a-27dd-64bb-30d0-a0bdcfb92bd4",
        "NOMBRE": "RETENCIÓN DE FRAGMENTOS DE LA PLACENTA O DE LAS MEMBRANAS, SIN HEMORRAGIA"
    },
    {
        "id": "8ab5c61c-d415-f35d-6fe8-2b1ba0de8167",
        "NOMBRE": "RETENCIÓN DE LA PLACENTA O DE LAS MEMBRANAS, SIN HEMORRAGIA"
    },
    {
        "id": "f1a19512-c61d-5518-61c5-377f8fd0ff3d",
        "NOMBRE": "RETENCIÓN DE LA PLACENTA SIN HEMORRAGIA"
    },
    {
        "id": "a37d0f96-ab59-1e39-a7cd-4899cc227a1e",
        "NOMBRE": "RETENCIÓN DE ORINA"
    },
    {
        "id": "2929fa50-e6c8-c42d-5e45-a82c69717987",
        "NOMBRE": "RETENCIÓN INTRAOCULAR DE CUERPO EXTRAÑO MAGNÉTICO (ANTIGUO)"
    },
    {
        "id": "a212777c-5bb9-8660-eee4-ca6a2c9ccd0c",
        "NOMBRE": "RETENCIÓN INTRAOCULAR DE CUERPO EXTRAÑO NO MAGNÉTICO (ANTIGUO)"
    },
    {
        "id": "dc958e6d-fee2-10ba-bb47-e92cad8923e1",
        "NOMBRE": "RETICULOIDE ACTÍNICO"
    },
    {
        "id": "db50448c-0e76-4e60-d805-3814e0807f4d",
        "NOMBRE": "RETINOPATÍA DE LA PREMATURIDAD"
    },
    {
        "id": "07217884-47d9-c0da-e1f6-c716b22340a7",
        "NOMBRE": "RETINOPATÍA DIABÉTICA"
    },
    {
        "id": "dd0b9a35-585d-f2b7-b0be-77f5e58233e2",
        "NOMBRE": "RETINOPATÍAS DEL FONDO Y CAMBIOS VASCULARES RETINIANOS"
    },
    {
        "id": "8884705c-18e2-9324-6dd5-754bb6a86f81",
        "NOMBRE": "RETINOSQUISIS Y QUISTES DE LA RETINA"
    },
    {
        "id": "85131f8e-883b-ca92-c57e-1c3032522b05",
        "NOMBRE": "RETRACCIÓN DEL PEZÓN ASOCIADA CON EL PARTO"
    },
    {
        "id": "62f46d98-f5a3-fc8e-6061-0690ea5148b0",
        "NOMBRE": "RETRACCIÓN GINGIVAL"
    },
    {
        "id": "4393ded2-63d9-d462-6a07-ee344c799ef7",
        "NOMBRE": "RETRASO DE LA EXPULSIÓN DEL SEGUNDO GEMELO, DEL TERCERO, ETC."
    },
    {
        "id": "daa238bd-f2b5-cb26-eb9b-c2e33d96335e",
        "NOMBRE": "RETRASO DEL PARTO DESPUÉS DE LA RUPTURA ARTIFICIAL DE LAS MEMBRANAS"
    },
    {
        "id": "7b7c4ca2-1ad3-7c49-8bd7-f0777b7fc141",
        "NOMBRE": "RETRASO DEL PARTO DESPUÉS DE LA RUPTURA ESPONTÁNEA O NO ESPECIFICADA DE LAS MEMBRANAS"
    },
    {
        "id": "fa1dfea6-3263-9574-d7bf-b124871a7365",
        "NOMBRE": "RETRASO MENTAL GRAVE"
    },
    {
        "id": "ce412d1b-ad5e-b6ea-4d70-15c3d684a701",
        "NOMBRE": "RETRASO MENTAL GRAVE, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"
    },
    {
        "id": "343c4eee-a4e4-5882-469d-ca671e03ecdd",
        "NOMBRE": "RETRASO MENTAL GRAVE, DETERIORO DEL COMPORTAMIENTO NULO O MÍNIMO"
    },
    {
        "id": "fb68cfa3-95c6-33b9-00e1-fbc046ea4ae8",
        "NOMBRE": "RETRASO MENTAL GRAVE, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCIÓN O TRATAMIENTO"
    },
    {
        "id": "3efb4234-19bd-a364-75fe-e7b689bda7a7",
        "NOMBRE": "RETRASO MENTAL GRAVE, OTROS DETERIOROS DEL COMPORTAMIENTO"
    },
    {
        "id": "8e484c49-8243-5e64-1cc8-7141852640cf",
        "NOMBRE": "RETRASO MENTAL LEVE"
    },
    {
        "id": "5c347b19-1c13-6a2a-43de-1fa62d283c33",
        "NOMBRE": "RETRASO MENTAL LEVE, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"
    },
    {
        "id": "cf3c8a88-d8e0-378d-89a6-a5fe4ea30a64",
        "NOMBRE": "RETRASO MENTAL LEVE, DETERIORO DEL COMPORTAMIENTO NULO O MÍNIMO"
    },
    {
        "id": "3608d3f7-7b26-157b-b240-750fd497d45d",
        "NOMBRE": "RETRASO MENTAL LEVE, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCIÓN O TRATAMIENTO"
    },
    {
        "id": "e0f8f0ec-66f4-fc6d-a0ee-5d589ac03521",
        "NOMBRE": "RETRASO MENTAL LEVE, OTROS DETERIOROS DEL COMPORTAMIENTO"
    },
    {
        "id": "335473c2-3dd2-36e1-7b3e-f3b9c7c48e76",
        "NOMBRE": "RETRASO MENTAL MODERADO"
    },
    {
        "id": "8ed8fe37-d7d9-cf0d-b7b0-bb2d8bb82e4b",
        "NOMBRE": "RETRASO MENTAL MODERADO, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"
    },
    {
        "id": "c61fd1b2-6831-b95a-d20f-c0304869d0e8",
        "NOMBRE": "RETRASO MENTAL MODERADO, DETERIORO DEL COMPORTAMIENTO NULO O MÍNIMO"
    },
    {
        "id": "9fd6744b-c542-eb20-bfe9-3c11598cc92f",
        "NOMBRE": "RETRASO MENTAL MODERADO, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCIÓN O TRATAMIENTO"
    },
    {
        "id": "9be49249-af19-2a6b-a8f8-df66b9d85be9",
        "NOMBRE": "RETRASO MENTAL MODERADO, OTROS DETERIOROS DEL COMPORTAMIENTO"
    },
    {
        "id": "8e5fb117-273b-6e89-baad-e94432634736",
        "NOMBRE": "RETRASO MENTAL PROFUNDO"
    },
    {
        "id": "7e7a2c71-76ed-031d-f75c-11dba5458418",
        "NOMBRE": "RETRASO MENTAL PROFUNDO, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"
    },
    {
        "id": "fda4fc4d-a7fb-3a22-c03a-314e06889836",
        "NOMBRE": "RETRASO MENTAL PROFUNDO, DETERIORO DEL COMPORTAMIENTO NULO O MÍNIMO"
    },
    {
        "id": "4e24bdce-e01f-9a0a-a913-424cd7085c8d",
        "NOMBRE": "RETRASO MENTAL PROFUNDO, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCIÓN O TRATAMIENTO"
    },
    {
        "id": "401f5d31-aaa0-159c-596b-df7e316dab53",
        "NOMBRE": "RETRASO MENTAL PROFUNDO, OTROS DETERIOROS DEL COMPORTAMIENTO"
    },
    {
        "id": "4c3cfc4b-0950-a080-9772-711092a42420",
        "NOMBRE": "RETRASO MENTAL, NO ESPECIFICADO"
    },
    {
        "id": "7c8867a1-0bde-e25e-eba6-8401aab455b6",
        "NOMBRE": "RETRASO MENTAL, NO ESPECIFICADO, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"
    },
    {
        "id": "7f9dcbc8-9e49-d286-5e15-5944f0c9591f",
        "NOMBRE": "RETRASO MENTAL, NO ESPECIFICADO, DETERIORO DEL COMPORTAMIENTO NULO O MÍNIMO"
    },
    {
        "id": "81b173b8-84c3-4c1c-5fa2-e0569e69a6b7",
        "NOMBRE": "RETRASO MENTAL, NO ESPECIFICADO, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCIÓN O TRATAMIENTO"
    },
    {
        "id": "dd77b7fe-790a-ebae-b58f-c43fa31a26f7",
        "NOMBRE": "RETRASO MENTAL, NO ESPECIFICADO, OTROS DETERIOROS DEL COMPORTAMIENTO"
    },
    {
        "id": "04e606f4-fa5b-c56b-7b82-cdcaf149825e",
        "NOMBRE": "RETROVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "3b44ab9a-3de8-9daa-3843-154646cadf50",
        "NOMBRE": "REUMATISMO PALINDRÓMICO"
    },
    {
        "id": "a1bbf0d1-fca5-71e8-1641-b192bd4130e1",
        "NOMBRE": "REUMATISMO, NO ESPECIFICADO"
    },
    {
        "id": "21f16e1b-ea61-91bf-3f35-686f3fcd2da2",
        "NOMBRE": "RICKETTSIOSIS PUSTULOSA DEBIDA A RICKETTSIA AKARI"
    },
    {
        "id": "0aa7dc03-d8e0-e4bd-0796-9522ab31751f",
        "NOMBRE": "RICKETTSIOSIS, NO ESPECIFICADA"
    },
    {
        "id": "f180bd24-11c5-c085-4e9f-c6590e7aac78",
        "NOMBRE": "RIGIDEZ ABDOMINAL"
    },
    {
        "id": "99e7b231-2724-aa8f-2d9b-674a488fdd59",
        "NOMBRE": "RIGIDEZ ARTICULAR, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "270de51b-7be9-1972-b5db-5ca77f56561a",
        "NOMBRE": "RINITIS ALÉRGICA DEBIDA AL POLEN"
    },
    {
        "id": "d04a3d42-f5f0-e7af-af98-d8d883daae94",
        "NOMBRE": "RINITIS ALÉRGICA Y VASOMOTORA"
    },
    {
        "id": "7aba4172-49c4-3510-af7d-c86dcefeb858",
        "NOMBRE": "RINITIS ALÉRGICA, NO ESPECIFICADA"
    },
    {
        "id": "0ebc6f04-d652-7569-0157-1de3a6c98d84",
        "NOMBRE": "RINITIS CRÓNICA"
    },
    {
        "id": "ddace528-f262-8dd5-9081-863c47e5f77e",
        "NOMBRE": "RINITIS VASOMOTORA"
    },
    {
        "id": "2a39091e-08e1-299f-a759-9fa3f84e973e",
        "NOMBRE": "RINITIS, RINOFARINGITIS Y FARINGITIS CRÓNICAS"
    },
    {
        "id": "15efb590-fafb-a24d-df8c-da1990868f8a",
        "NOMBRE": "RINOFARINGITIS AGUDA [RESFRIADO COMÚN]"
    },
    {
        "id": "26164d52-3218-80e2-d559-8d367f815b57",
        "NOMBRE": "RINOFARINGITIS CRÓNICA"
    },
    {
        "id": "677be2a5-8380-4361-c790-8d79c7dcaa71",
        "NOMBRE": "RINOFIMA"
    },
    {
        "id": "69b91278-505a-72d4-4827-4424fddfba2f",
        "NOMBRE": "RINOSPORIDIOSIS"
    },
    {
        "id": "014b10da-46da-f292-7c9a-7036002471e3",
        "NOMBRE": "RIÑÓN CONTRAÍDO, NO ESPECIFICADO"
    },
    {
        "id": "40a0eb7c-a4dd-340f-a9f3-2a9dfe74dabe",
        "NOMBRE": "RIÑÓN ECTÓPICO"
    },
    {
        "id": "208083d6-d3d6-0d16-d2a4-1ca29abd9dd3",
        "NOMBRE": "RIÑÓN LOBULADO, FUSIONADO Y EN HERRADURA"
    },
    {
        "id": "de158b0e-7149-1b18-b3ab-7212848ca520",
        "NOMBRE": "RIÑÓN PEQUEÑO DE CAUSA DESCONOCIDA"
    },
    {
        "id": "c70472ed-7a75-a43a-34e8-3416d0c43b8b",
        "NOMBRE": "RIÑÓN PEQUEÑO, BILATERAL"
    },
    {
        "id": "709b724f-1d81-41a8-62b7-715e69cae984",
        "NOMBRE": "RIÑÓN PEQUEÑO, NO ESPECIFICADO"
    },
    {
        "id": "b5373242-ac59-c436-0383-4a7ee4185f28",
        "NOMBRE": "RIÑÓN PEQUEÑO, UNILATERAL"
    },
    {
        "id": "e4187704-6429-e2df-4fe4-4dc55445307a",
        "NOMBRE": "RIÑÓN POLIQUÍSTICO, AUTOSÓMICO DOMINANTE"
    },
    {
        "id": "8ef36601-2e45-74c3-26bd-0b2832123be0",
        "NOMBRE": "RIÑÓN POLIQUÍSTICO, AUTOSÓMICO RECESIVO"
    },
    {
        "id": "fb077517-e933-713d-56cf-462cfb714ab1",
        "NOMBRE": "RIÑÓN POLIQUÍSTICO, TIPO NO ESPECIFICADO"
    },
    {
        "id": "3418c4c4-0585-be7f-2c91-2008bf93398e",
        "NOMBRE": "RIÑÓN QUÍSTICO MEDULAR"
    },
    {
        "id": "ae832b70-17e8-a0cf-cc49-070f66d0e643",
        "NOMBRE": "RIÑÓN SUPERNUMERARIO"
    },
    {
        "id": "c25c29e5-ab00-110d-304c-cefe547684a7",
        "NOMBRE": "ROSÁCEA, NO ESPECIFICADA"
    },
    {
        "id": "dc5384dd-eb6b-f960-1c85-d68ee1539105",
        "NOMBRE": "ROSÁSEA"
    },
    {
        "id": "a433f1dd-5c47-40d9-f03e-7f31e15cf39a",
        "NOMBRE": "RUBÉOLA [SARAMPIÓN ALEMÁN]"
    },
    {
        "id": "a96bd4c7-1666-9594-c25e-fdebe5527ac2",
        "NOMBRE": "RUBÉOLA CON COMPLICACIONES NEUROLÓGICAS"
    },
    {
        "id": "ebe9360d-c283-f764-59e2-dcf44731155e",
        "NOMBRE": "RUBÉOLA CON OTRAS COMPLICACIONES"
    },
    {
        "id": "9f3a6c45-a5c9-aab6-ebcc-864bc1b9be38",
        "NOMBRE": "RUBÉOLA SIN COMPLICACIONES"
    },
    {
        "id": "24872867-fde6-638d-c877-62d44741062d",
        "NOMBRE": "RUBOR"
    },
    {
        "id": "e0e8569e-fa42-c0dc-5d72-59ee593c4ec2",
        "NOMBRE": "RUPTURA ARTERIAL"
    },
    {
        "id": "d07f1ccd-7182-5b54-6020-7e6e51f0ff64",
        "NOMBRE": "RUPTURA DE ANEURISMA AÓRTICO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "6a658170-b449-2ab1-49da-80bda110d4b0",
        "NOMBRE": "RUPTURA DE ANEURISMA DE LA AORTA ABDOMINAL"
    },
    {
        "id": "26ed419e-1e95-b0a6-26bb-06f07a59c930",
        "NOMBRE": "RUPTURA DE ANEURISMA DE LA AORTA TORÁCICA"
    },
    {
        "id": "a7658949-de0d-0632-7685-d829fb8befc4",
        "NOMBRE": "RUPTURA DE ANEURISMA DE LA AORTA TORACOABDOMINAL"
    },
    {
        "id": "592ede20-b4c8-376e-7912-01d6015eaeb3",
        "NOMBRE": "RUPTURA DE CUERDA TENDINOSA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "65d19b6b-6b41-1f63-1e75-10406a2d83c2",
        "NOMBRE": "RUPTURA DE LA PARED CARDÍACA SIN HEMOPERICARDIO COMO COMPLICACIÓN PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "21364d74-3ebb-3028-c7aa-f8703009d263",
        "NOMBRE": "RUPTURA DE LA SINOVIA"
    },
    {
        "id": "a8d067be-dfd2-cc0d-3a5e-02acfd2fe7a1",
        "NOMBRE": "RUPTURA DE LA VEJIGA, NO TRAUMÁTICA"
    },
    {
        "id": "d6ef3ea4-2f65-41aa-f4ee-441672998253",
        "NOMBRE": "RUPTURA DE LAS CUERDAS TENDINOSAS COMO COMPLICACIÓN PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "253d316c-044e-8050-e8ce-490c189838af",
        "NOMBRE": "RUPTURA DE LIGAMENTOS A NIVEL DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "c841af40-f4ba-3e38-923e-38a320b518d8",
        "NOMBRE": "RUPTURA DE MÚSCULO PAPILAR COMO COMPLICACIÓN PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "e6cc7e5c-f36c-fe7c-6a04-53fa79051050",
        "NOMBRE": "RUPTURA DE MÚSCULO PAPILAR, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "7a233460-eff0-9f97-3497-c064f531b439",
        "NOMBRE": "RUPTURA DE QUISTE SINOVIAL POPLÍTEO"
    },
    {
        "id": "32b82c89-e2b6-3675-eb52-d7ee2a7c178c",
        "NOMBRE": "RUPTURA DEL ÚTERO ANTES DEL INICIO DEL TRABAJO DE PARTO"
    },
    {
        "id": "7113a2f0-d854-9bff-27f9-62b834902dd2",
        "NOMBRE": "RUPTURA DEL ÚTERO DURANTE EL TRABAJO DE PARTO"
    },
    {
        "id": "becd9110-4dc2-ecb0-38b3-f081ca986c42",
        "NOMBRE": "RUPTURA ESPONTÁNEA DE LA SINOVIA Y DEL TENDÓN"
    },
    {
        "id": "40d1079b-9842-338d-c8eb-be51f4ea6936",
        "NOMBRE": "RUPTURA ESPONTÁNEA DE OTROS TENDONES"
    },
    {
        "id": "498b4124-b6cf-d053-de8c-e0f70bba6c1e",
        "NOMBRE": "RUPTURA ESPONTÁNEA DE TENDÓN NO ESPECIFICADO"
    },
    {
        "id": "cf628a14-8f3b-0624-7124-6d750c86e242",
        "NOMBRE": "RUPTURA ESPONTÁNEA DE TENDONES EXTENSORES"
    },
    {
        "id": "2a778861-1e32-5bcf-e443-dd02fb6376bb",
        "NOMBRE": "RUPTURA ESPONTÁNEA DE TENDONES FLEXORES"
    },
    {
        "id": "e4bc73b0-b71f-37a2-daa1-3eb5a2482673",
        "NOMBRE": "RUPTURA PREMATURA DE LAS MEMBRANAS"
    },
    {
        "id": "5a6442bc-775f-1485-bb5d-4508bf19ddeb",
        "NOMBRE": "RUPTURA PREMATURA DE LAS MEMBRANAS, E INICIO DEL TRABAJO DE PARTO DENTRO DE LAS 24 HORAS"
    },
    {
        "id": "e2e91be6-174e-f310-54f4-550e5b2d5ef6",
        "NOMBRE": "RUPTURA PREMATURA DE LAS MEMBRANAS, E INICIO DEL TRABAJO DE PARTO DESPUÉS DE LAS 24 HORAS"
    },
    {
        "id": "684fc95c-94ec-3721-1abe-b45f841c9f27",
        "NOMBRE": "RUPTURA PREMATURA DE LAS MEMBRANAS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "d9fde5ab-8a2e-7511-f930-7731a323294b",
        "NOMBRE": "RUPTURA PREMATURA DE LAS MEMBRANAS, TRABAJO DE PARTO RETRASADO POR LA TERAPÉUTICA"
    },
    {
        "id": "e7c342b9-0255-6ace-b557-22ed1ba7abb6",
        "NOMBRE": "RUPTURA TRAUMÁTICA DE DISCO CERVICAL INTERVERTEBRAL"
    },
    {
        "id": "8c252b91-237b-fd48-3072-d664ecd1e143",
        "NOMBRE": "RUPTURA TRAUMÁTICA DE DISCO INTERVERTEBRAL LUMBAR"
    },
    {
        "id": "5d217405-c5df-e60f-3692-afd51aeee0eb",
        "NOMBRE": "RUPTURA TRAUMÁTICA DE DISCO INTERVERTEBRAL TORÁCICO"
    },
    {
        "id": "9d97be6d-fa91-510d-b221-084b273bfc05",
        "NOMBRE": "RUPTURA TRAUMÁTICA DE LA SÍNFISIS DEL PUBIS"
    },
    {
        "id": "1ba28fdf-7b0f-96d9-240d-612ca0bc9bc8",
        "NOMBRE": "RUPTURA TRAUMÁTICA DE LIGAMENTOS DE LA MUÑECA Y DEL CARPO"
    },
    {
        "id": "d4d51de6-7502-4b27-7e22-02398693c7c2",
        "NOMBRE": "RUPTURA TRAUMÁTICA DE LIGAMENTOS DEL DEDO DE LA MANO EN LA(S) ARTICULACIÓN(ES) METACARPOFALÁNGICA E INTERFALÁNGICA"
    },
    {
        "id": "5396b698-81a8-0a5c-84d4-b62aa8da70d4",
        "NOMBRE": "RUPTURA TRAUMÁTICA DEL LIGAMENTO LATERAL DEL CÚBITO"
    },
    {
        "id": "20d9d746-9999-1732-7af5-d0a33a31e010",
        "NOMBRE": "RUPTURA TRAUMÁTICA DEL LIGAMENTO LATERAL DEL RADIO"
    },
    {
        "id": "ee8295b0-35d6-3722-7145-4eb44978a8bc",
        "NOMBRE": "RUPTURA TRAUMÁTICA DEL TÍMPANO DEL OÍDO"
    },
    {
        "id": "eef229d3-5763-c392-9598-10b52e74bd63",
        "NOMBRE": "SABAÑÓN(ES)"
    },
    {
        "id": "88553d5e-6882-8dc4-7149-5336af646a50",
        "NOMBRE": "SACROILIÍTIS, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "03ed9bac-cc03-ccc1-c28b-4b94ae65ec74",
        "NOMBRE": "SADOMASOQUISMO"
    },
    {
        "id": "17391bae-8088-b009-cc6a-da71d4e48dfd",
        "NOMBRE": "SALPINGITIS EUSTAQUIANA"
    },
    {
        "id": "18111ab4-11c7-8096-9628-b0ebf7b5aa63",
        "NOMBRE": "SALPINGITIS Y OOFORITIS"
    },
    {
        "id": "70bc087e-1c6e-3d3d-d6d0-a233b6b70562",
        "NOMBRE": "SALPINGITIS Y OOFORITIS AGUDA"
    },
    {
        "id": "cd82fbd4-166a-2501-c16d-6475aa56e30c",
        "NOMBRE": "SALPINGITIS Y OOFORITIS CRÓNICA"
    },
    {
        "id": "8e4bdab2-472b-ccdd-97db-f8d0b222dd6e",
        "NOMBRE": "SALPINGITIS Y OOFORITIS, NO ESPECIFICADAS"
    },
    {
        "id": "90595e93-7ec1-475c-7ea3-84a6bb583000",
        "NOMBRE": "SALPULLIDO Y OTRAS ERUPCIONES CUTÁNEAS NO ESPECIFICADAS"
    },
    {
        "id": "0813facf-c946-54f6-75d0-b8e25dea86b2",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO"
    },
    {
        "id": "57b94baf-f548-db33-30a6-2465715020df",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "96bb65f5-9ffd-2c91-0f45-8f2dbbbb4e9a",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "c804061c-d2cf-aaf6-dbbf-45b06c34cad8",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, CALLES Y CARRETERAS"
    },
    {
        "id": "ce4df3b6-eba1-279c-6191-f4214bf9f66f",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "34deea4e-d153-552b-91fe-7279b1727fb3",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "e142fa7a-d45e-e41b-0710-769ebd9b9e2b",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, GRANJA"
    },
    {
        "id": "ff3684d3-a86f-0a58-823a-d5ca5ca3eb9a",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "ffbfd7ae-6ee8-a5ab-9107-1617ae4643c1",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "5317cf4d-08be-2d09-f344-d448fe1e89e7",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "7123523a-220e-e2db-39c3-5cdeeb707305",
        "NOMBRE": "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSIÓN O AHOGAMIENTO, VIVIENDA"
    },
    {
        "id": "07c9d061-84f1-5b50-ce8a-6ed169af0837",
        "NOMBRE": "SANGRE INCOMPATIBLE USADA EN TRANSFUSIÓN"
    },
    {
        "id": "ff1775b3-af22-51ab-72f8-e57363ed8f3e",
        "NOMBRE": "SARAMPIÓN"
    },
    {
        "id": "9902a7d5-8e41-d83f-657c-99180b00bb42",
        "NOMBRE": "SARAMPIÓN COMPLICADO CON ENCEFALITIS"
    },
    {
        "id": "6bfacee2-4d91-0287-a2c8-9258495865e0",
        "NOMBRE": "SARAMPIÓN COMPLICADO CON MENINGITIS"
    },
    {
        "id": "3ff26e38-f03e-7f35-2b11-1d61308ed87d",
        "NOMBRE": "SARAMPIÓN COMPLICADO CON NEUMONÍA"
    },
    {
        "id": "f1b16bb3-27e5-24e0-6358-e962312af8e9",
        "NOMBRE": "SARAMPIÓN COMPLICADO CON OTITIS MEDIA"
    },
    {
        "id": "6dbe8ec9-1ea7-b666-8df8-a555ef73e92f",
        "NOMBRE": "SARAMPIÓN CON COMPLICACIONES INTESTINALES"
    },
    {
        "id": "f624d384-ffa5-4162-13a3-16c8cf7aaf09",
        "NOMBRE": "SARAMPIÓN CON OTRAS COMPLICACIONES"
    },
    {
        "id": "23ed31a0-503f-1f5c-e1c2-2954b4af5a95",
        "NOMBRE": "SARAMPIÓN SIN COMPLICACIONES"
    },
    {
        "id": "d6fb1eb9-1298-564e-b9d5-b26e8f3b5fc4",
        "NOMBRE": "SARCOIDOSIS"
    },
    {
        "id": "c5da6369-bd1d-f7fd-0933-7c52be1def87",
        "NOMBRE": "SARCOIDOSIS DE LA PIEL"
    },
    {
        "id": "c722d063-ce92-0ada-fd7b-3910b7708da0",
        "NOMBRE": "SARCOIDOSIS DE LOS GANGLIOS LINFÁTICOS"
    },
    {
        "id": "a7e82545-1681-5e8c-559c-52910905eaab",
        "NOMBRE": "SARCOIDOSIS DE OTROS SITIOS ESPECIFICADOS O DE SITIOS COMBINADOS"
    },
    {
        "id": "700cb26e-7971-435f-27a7-00b50090609b",
        "NOMBRE": "SARCOIDOSIS DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "14c1fe2b-bf80-e41f-cd2d-8064379d6590",
        "NOMBRE": "SARCOIDOSIS DEL PULMÓN"
    },
    {
        "id": "29fd9461-9260-66eb-2256-da7f4271030f",
        "NOMBRE": "SARCOIDOSIS DEL PULMÓN Y DE LOS GANGLIOS LINFÁTICOS"
    },
    {
        "id": "1bfe1b6d-5267-38bd-fc99-78a5892cc3d7",
        "NOMBRE": "SARCOMA DE CÉLULAS DENDRÍTICAS (CÉLULAS ACCESORIAS)"
    },
    {
        "id": "af781a70-b610-553c-4c6f-25af5d96c155",
        "NOMBRE": "SARCOMA DE KAPOSI"
    },
    {
        "id": "608ea775-5892-63dc-a85d-f6b76e8e4654",
        "NOMBRE": "SARCOMA DE KAPOSI DE LA PIEL"
    },
    {
        "id": "7aab2dbf-cb3c-de7e-b152-6f7ed4c96abe",
        "NOMBRE": "SARCOMA DE KAPOSI DE LOS GANGLIOS LINFÁTICOS"
    },
    {
        "id": "9e9ddf05-e9fb-6a5d-ed93-ab137dcd9f99",
        "NOMBRE": "SARCOMA DE KAPOSI DE MÚLTIPLES ÓRGANOS"
    },
    {
        "id": "26d7993f-4589-aeaf-0b58-74a95e535765",
        "NOMBRE": "SARCOMA DE KAPOSI DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "4e055b3d-b15a-ccf8-fd5b-14813f2c730b",
        "NOMBRE": "SARCOMA DE KAPOSI DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "5053ed0b-872c-9f56-b91b-a05e3589430d",
        "NOMBRE": "SARCOMA DE KAPOSI DEL PALADAR"
    },
    {
        "id": "46861c07-2f3d-8860-2529-aff825062a47",
        "NOMBRE": "SARCOMA DE KAPOSI DEL TEJIDO BLANDO"
    },
    {
        "id": "d73934b2-fa97-3278-1ef4-0f4a9a6ab9b5",
        "NOMBRE": "SARCOMA HISTIOCÍTICO"
    },
    {
        "id": "0bc02d20-b171-4e10-af73-ca5b1d8f456c",
        "NOMBRE": "SARCOMA MIELOIDE"
    },
    {
        "id": "7dedbdac-2e65-8c46-abc6-fd2226060c4d",
        "NOMBRE": "SEBORREA CAPITIS"
    },
    {
        "id": "d26f4dda-243b-aeb2-d880-7b2c8d443a3b",
        "NOMBRE": "SECRECIÓN ANORMAL DE GASTRINA"
    },
    {
        "id": "985d4cc5-9b9b-4c4d-abdb-2c0d72810164",
        "NOMBRE": "SECRECIÓN EXAGERADA DEL GLUCAGÓN"
    },
    {
        "id": "994e6a36-da9f-5ef1-b39c-5093360fddee",
        "NOMBRE": "SECRECIÓN HORMONAL ECTÓPICA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "9d8e192a-1dc4-9c31-bdb1-82205a7f708c",
        "NOMBRE": "SECUELAS CON ATENCIÓN MÉDICA Y QUIRÚRGICA COMO CAUSA EXTERNA"
    },
    {
        "id": "e1a1f81b-5b92-22b4-7a77-c542b0e1abe4",
        "NOMBRE": "SECUELAS DE ACCIDENTE DE VEHÍCULO DE MOTOR"
    },
    {
        "id": "678788bb-5ae2-3184-175c-7d7354e04eb9",
        "NOMBRE": "SECUELAS DE ACCIDENTE VASCULAR ENCÉFALICO, NO ESPECIFICADO COMO HEMORRÁGICO O ISQUÉMICO"
    },
    {
        "id": "37a309c2-b78d-3aef-76fa-583ef13254d7",
        "NOMBRE": "SECUELAS DE ACCIDENTES DE TRANSPORTE"
    },
    {
        "id": "594fa111-46ca-ef5b-7695-c87726bb6f95",
        "NOMBRE": "SECUELAS DE AGRESIONES"
    },
    {
        "id": "1cab05d5-ac5c-62f0-c50b-088f507f7658",
        "NOMBRE": "SECUELAS DE APLASTAMIENTO Y AMPUTACIÓN TRAUMÁTICAS DE MIEMBRO INFERIOR"
    },
    {
        "id": "2151dd32-9e7d-0629-5d50-f520176b6fcd",
        "NOMBRE": "SECUELAS DE APLASTAMIENTO Y AMPUTACIÓN TRAUMÁTICAS DE MIEMBRO SUPERIOR"
    },
    {
        "id": "9d306063-246e-fdb8-9f3e-ba342d4deedb",
        "NOMBRE": "SECUELAS DE CAUSA EXTERNA NO ESPECIFICADA"
    },
    {
        "id": "3a5c483b-34bb-ff2a-527e-23fe0a68c594",
        "NOMBRE": "SECUELAS DE CIERTAS COMPLICACIONES PRECOCES DE LOS TRAUMATISMOS"
    },
    {
        "id": "c9beb94b-e6eb-c959-9946-6e332e49d31e",
        "NOMBRE": "SECUELAS DE COMPLICACIONES DE LA ATENCIÓN MÉDICA Y QUIRÚRGICA, NO CLASIFICADAS EN OTRAS PARTE"
    },
    {
        "id": "312e3f3a-cb5a-5a2d-0dbd-4d6b86fa5069",
        "NOMBRE": "SECUELAS DE COMPLICACIONES DEL EMBARAZO, DEL PARTO Y DEL PUERPERIO"
    },
    {
        "id": "169f195c-adfc-f709-d1f2-2589b001d69c",
        "NOMBRE": "SECUELAS DE EFECTOS ADVERSOS CAUSADOS POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS EN SU USO TERAPÉUTICO"
    },
    {
        "id": "5db7285d-8933-a6de-847d-51ea07502a89",
        "NOMBRE": "SECUELAS DE EFECTOS DE CUERPOS EXTRAÑOS QUE PENETRAN EN ORIFICIOS NATURALES"
    },
    {
        "id": "8080e8f4-679f-b021-f142-8bb2d507cacd",
        "NOMBRE": "SECUELAS DE EFECTOS TÓXICOS DE SUSTANCIAS DE PROCEDENCIA PRINCIPALMENTE NO MEDICINAL"
    },
    {
        "id": "4f16cedb-662e-01cf-bcc4-7978419ecbe3",
        "NOMBRE": "SECUELAS DE ENCEFALITIS VIRAL"
    },
    {
        "id": "9f654b7b-df07-40ad-19dc-9463731b8070",
        "NOMBRE": "SECUELAS DE ENFERMEDAD CEREBROVASCULAR"
    },
    {
        "id": "7b1e7b85-9ef3-233c-c8f2-67ef3645d0f8",
        "NOMBRE": "SECUELAS DE ENFERMEDADES INFECCIOSAS Y PARASITARIAS NO ESPECIFICADAS"
    },
    {
        "id": "4311f6a0-b550-ced3-6d9e-93562fdb3e44",
        "NOMBRE": "SECUELAS DE ENFERMEDADES INFLAMATORIAS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "10b76ecc-1876-4ad0-3a45-0f48919e7e05",
        "NOMBRE": "SECUELAS DE ENVENENAMIENTOS POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLÓGICAS"
    },
    {
        "id": "0f13586a-54d5-8788-bd68-fc36301fac34",
        "NOMBRE": "SECUELAS DE EVENTOS DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "3753dbb1-e15a-0c7e-a50e-41b3896b3a18",
        "NOMBRE": "SECUELAS DE FRACTURA DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "8fece488-009d-b922-8579-15781b38e524",
        "NOMBRE": "SECUELAS DE FRACTURA DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "a562b152-2904-71f0-fab6-7f87f93d9774",
        "NOMBRE": "SECUELAS DE FRACTURA DEL BRAZO"
    },
    {
        "id": "ed568868-9dcb-137a-9e4d-027f7e0f0163",
        "NOMBRE": "SECUELAS DE FRACTURA DEL CRÁNEO Y DE HUESOS FACIALES"
    },
    {
        "id": "08d21e7c-f02a-373e-26fc-a20258ec6668",
        "NOMBRE": "SECUELAS DE FRACTURA DEL FÉMUR"
    },
    {
        "id": "5f1a0b59-8e61-feea-3e6f-2a43bde95926",
        "NOMBRE": "SECUELAS DE HEMORRAGIA INTRAENCEFÁLICA"
    },
    {
        "id": "598d39b8-7e3e-ec4d-5126-46bcec610cf0",
        "NOMBRE": "SECUELAS DE HEMORRAGIA SUBARACNOIDEA"
    },
    {
        "id": "f2d672a0-a186-1e8a-013f-fb625a36bd75",
        "NOMBRE": "SECUELAS DE HEPATITIS VIRAL"
    },
    {
        "id": "a24cd46e-d9c8-d188-754d-1b74b46195e6",
        "NOMBRE": "SECUELAS DE HERIDA DE LA CABEZA"
    },
    {
        "id": "8acb1fc4-aadd-bbb0-17d1-9768c985f599",
        "NOMBRE": "SECUELAS DE HERIDA DE MIEMBRO INFERIOR"
    },
    {
        "id": "3669813f-94ff-b14e-d159-721e9a223750",
        "NOMBRE": "SECUELAS DE HERIDA DE MIEMBRO SUPERIOR"
    },
    {
        "id": "74bd5622-4799-bf63-0867-5fb06fdae359",
        "NOMBRE": "SECUELAS DE HIPERALIMENTACIÓN"
    },
    {
        "id": "8cb1fc6d-5eb5-7bda-7b77-42e7b5e21e5f",
        "NOMBRE": "SECUELAS DE INCIDENTES ADVERSOS ASOCIADOS CON DISPOSITIVOS MÉDICOS EN USO DIAGNÓSTICO Y TERAPÉUTICO"
    },
    {
        "id": "1de7adfc-85c3-20be-a0db-034542b7724b",
        "NOMBRE": "SECUELAS DE INCIDENTES OCURRIDOS AL PACIENTE DURANTE PROCEDIMIENTOS MÉDICOS Y QUIRÚRGICOS"
    },
    {
        "id": "376e0fb9-a5d6-c6da-1891-9894900c7bd5",
        "NOMBRE": "SECUELAS DE INFARTO CEREBRAL"
    },
    {
        "id": "a836db93-52ed-f6c2-ca79-86d9dc0d8609",
        "NOMBRE": "SECUELAS DE INTERVENCIÓN LEGAL"
    },
    {
        "id": "9ba38ccb-199f-1126-f817-2ad22c10d573",
        "NOMBRE": "SECUELAS DE LA DEFICIENCIA DE VITAMINA A"
    },
    {
        "id": "492cf259-fb97-5ccf-5011-1652baeed800",
        "NOMBRE": "SECUELAS DE LA DEFICIENCIA DE VITAMINA C"
    },
    {
        "id": "6dec36c0-f0c7-744f-b9e1-a43c971092f8",
        "NOMBRE": "SECUELAS DE LA DEFICIENCIA NUTRICIONAL NO ESPECIFICADA"
    },
    {
        "id": "4de430c1-2be4-7cd5-a7de-91c58449bc96",
        "NOMBRE": "SECUELAS DE LA DESNUTRICIÓN PROTEICOCALÓRICA"
    },
    {
        "id": "83d16b3a-eba6-922e-6b65-60e4434178ab",
        "NOMBRE": "SECUELAS DE LA DESNUTRICIÓN Y DE OTRAS DEFICIENCIAS NUTRICIONALES"
    },
    {
        "id": "64c12da8-f201-0213-ef47-a6dae416e42b",
        "NOMBRE": "SECUELAS DE LEPRA"
    },
    {
        "id": "4f675398-0afb-d24e-dbfb-2407e647b768",
        "NOMBRE": "SECUELAS DE LESIONES AUTOINFLIGIDAS"
    },
    {
        "id": "5edf7c06-40fe-3776-890c-c351d86bedb9",
        "NOMBRE": "SECUELAS DE LESIONES AUTOINFLIGIDAS INTENCIONALMENTE, AGRESIONES Y EVENTOS DE INTENCIÓN NO DETERMINADA"
    },
    {
        "id": "08d63d8a-1514-a18d-f062-0c27c2ec622a",
        "NOMBRE": "SECUELAS DE LUXACIÓN, TORCEDURA Y ESGUINCE DE MIEMBRO INFERIOR"
    },
    {
        "id": "ceb526ab-67df-c98c-d457-2372e1d76b16",
        "NOMBRE": "SECUELAS DE LUXACIÓN, TORCEDURA Y ESQUINCE DE MIEMBRO SUPERIOR"
    },
    {
        "id": "3643c0a4-8c46-b915-1495-e390d1adb861",
        "NOMBRE": "SECUELAS DE OPERACIONES DE GUERRA"
    },
    {
        "id": "77abb2b7-9e01-f031-d5b6-ab6f9321ee8c",
        "NOMBRE": "SECUELAS DE OTRA FRACTURA DEL TÓRAX Y DE LA PELVIS"
    },
    {
        "id": "7d60c6d6-a17f-067c-071d-202901d769d1",
        "NOMBRE": "SECUELAS DE OTRAS CAUSAS EXTERNAS"
    },
    {
        "id": "0b6f7147-015a-8b37-c63b-b36103c0f94b",
        "NOMBRE": "SECUELAS DE OTRAS DEFICIENCIAS NUTRICIONALES"
    },
    {
        "id": "cf0a10a5-22cd-64c7-ce60-3f8fd813b0a8",
        "NOMBRE": "SECUELAS DE OTRAS ENFERMEDADES CEREBROVASCULARES Y DE LAS NO ESPECIFICADAS"
    },
    {
        "id": "694e92a6-a8c6-5d0f-a269-702ab5731a06",
        "NOMBRE": "SECUELAS DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS DE LOS NO ESPECIFICADAS"
    },
    {
        "id": "fea3dc59-8871-95df-d09b-fb95993f9886",
        "NOMBRE": "SECUELAS DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS"
    },
    {
        "id": "14b80221-ddb5-19f2-b432-25d65ae1def0",
        "NOMBRE": "SECUELAS DE OTRAS FRACTURAS DE MIEMBRO INFERIOR"
    },
    {
        "id": "bf5053b5-0fdd-c441-2e31-95cddac0f533",
        "NOMBRE": "SECUELAS DE OTRAS HEMORRAGIAS INTRACRANEALES NO TRAUMÁTICAS"
    },
    {
        "id": "74c7f08a-c2f3-bc72-dafc-09238b9702aa",
        "NOMBRE": "SECUELAS DE OTRAS QUEMADURAS, CORROSIONES Y CONGELAMIENTOS ESPECIFICADOS"
    },
    {
        "id": "fc471503-7d4d-4194-d44b-d642b15393a6",
        "NOMBRE": "SECUELAS DE OTROS ACCIDENTES"
    },
    {
        "id": "9566d141-0c5f-8470-e8fe-6e3702f1630d",
        "NOMBRE": "SECUELAS DE OTROS ACCIDENTES DE TRANSPORTE, Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "2eaa58f2-79cf-fe38-dd0a-e553b0c06960",
        "NOMBRE": "SECUELAS DE OTROS EFECTOS Y LOS NO ESPECIFICADOS DE CAUSAS EXTERNAS"
    },
    {
        "id": "b89ffc83-7369-4696-e94f-fed4c9e41b06",
        "NOMBRE": "SECUELAS DE OTROS EFECTOS Y LOS NO ESPECIFICADOS DE CAUSAS EXTERNAS"
    },
    {
        "id": "22ce2079-554b-0a57-979c-00f3577633d9",
        "NOMBRE": "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE LA CABEZA"
    },
    {
        "id": "33bdb65d-b605-549b-5383-7fd6447027f9",
        "NOMBRE": "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO INFERIOR"
    },
    {
        "id": "687a1c8e-5f71-985f-fc68-9952885201ee",
        "NOMBRE": "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO SUPERIOR"
    },
    {
        "id": "0c9befc9-456e-5a44-d638-b17905a5d70f",
        "NOMBRE": "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DEL CUELLO Y DEL TRONCO"
    },
    {
        "id": "e7948708-1d03-a744-4cf3-7590371ae5b2",
        "NOMBRE": "SECUELAS DE POLIOMIELITIS"
    },
    {
        "id": "033f31b3-a8f3-236f-db54-84fa5497ab18",
        "NOMBRE": "SECUELAS DE PROCEDIMIENTOS MÉDICOS Y QUIRÚRGICOS COMO LA CAUSA DE REACCIÓN ANORMAL DEL PACIENTE O DE COMPLICACIÓN POSTERIOR, SIN MENCIÓN DE INCIDENTE EN EL MOMENTO DE EFECTUAR EL PROCEDIMIENTO"
    },
    {
        "id": "f53a18b6-47af-cb15-e464-bf6b00ec6f23",
        "NOMBRE": "SECUELAS DE QUEMADURA Y CORROSIÓN CLASIFICABLES SÓLO DE ACUERDO CON LA EXTENSIÓN DE LA SUPERFICIE DEL CUERPO AFECTADA"
    },
    {
        "id": "ed389caf-62e3-5538-79d6-96fb84c4ac64",
        "NOMBRE": "SECUELAS DE QUEMADURA, CORROSIÓN Y CONGELAMIENTO DE LA CABEZA Y DEL CUELLO"
    },
    {
        "id": "6003c3ea-58e4-38bb-3712-001748369272",
        "NOMBRE": "SECUELAS DE QUEMADURA, CORROSIÓN Y CONGELAMIENTO DE MIEMBRO INFERIOR"
    },
    {
        "id": "f3d6c4d5-881a-4777-8139-2b3e64dcd21b",
        "NOMBRE": "SECUELAS DE QUEMADURA, CORROSIÓN Y CONGELAMIENTO DE MIEMBRO SUPERIOR"
    },
    {
        "id": "7c39f235-d76f-98de-ea2a-4d9f391cc0c8",
        "NOMBRE": "SECUELAS DE QUEMADURA, CORROSIÓN Y CONGELAMIENTO DEL TRONCO"
    },
    {
        "id": "2357dbf1-6612-198d-6130-d7bbdb36995e",
        "NOMBRE": "SECUELAS DE QUEMADURA, CORROSIÓN Y CONGELAMIENTO NO ESPECIFICADOS"
    },
    {
        "id": "d0e8e8dd-56d3-e09e-c189-3d1bd592f7da",
        "NOMBRE": "SECUELAS DE QUEMADURAS, CORROSIONES Y CONGELAMIENTOS"
    },
    {
        "id": "51749fab-73f3-a22d-a367-77401665326a",
        "NOMBRE": "SECUELAS DE TRACOMA"
    },
    {
        "id": "82d004bd-8ff3-6527-7830-0c6c9676fe86",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE LA MÉDULA ESPINAL"
    },
    {
        "id": "7eff7cc4-7ca8-e0ee-6b05-b90509491585",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE NERVIO DE MIEMBRO INFERIOR"
    },
    {
        "id": "f8be10de-2aa2-f242-200e-89ceab5fc5f0",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE NERVIO DE MIEMBRO SUPERIOR"
    },
    {
        "id": "695eaf8d-6eb7-c70d-233a-519f11f60f82",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE NERVIOS CRANEALES"
    },
    {
        "id": "8343a06f-6edb-5c5a-9274-e8cc66dcdd22",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE ÓRGANOS INTRAABDOMINALES Y PÉLVICOS"
    },
    {
        "id": "2e43982e-c410-fcd0-d128-c547425b99db",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE ÓRGANOS INTRATORÁCICOS"
    },
    {
        "id": "991d19e1-72a5-0af7-2cd0-f124746c226a",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE TENDÓN Y MÚSCULO DE MIEMBRO INFERIOR"
    },
    {
        "id": "c52196ee-795e-5cfc-d5ad-a528db6ab6d1",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DE TENDÓN Y MÚSCULO DE MIEMBRO SUPERIOR"
    },
    {
        "id": "34dc3c5e-d6d6-2daa-2864-36b049ff1bf4",
        "NOMBRE": "SECUELAS DE TRAUMATISMO DEL OJO Y DE LA ÓRBITA"
    },
    {
        "id": "2554343d-f804-8ee1-d7c3-b5afcc6e73c2",
        "NOMBRE": "SECUELAS DE TRAUMATISMO INTRACRANEAL"
    },
    {
        "id": "2ad81de2-5706-1cc2-7906-30b3bc14964a",
        "NOMBRE": "SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE LA CABEZA"
    },
    {
        "id": "2cbdb86b-e6f8-e241-0837-f73fa5c8e9f1",
        "NOMBRE": "SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE MIEMBRO INFERIOR"
    },
    {
        "id": "047e53b4-c01c-f74a-3783-537b59ac19f3",
        "NOMBRE": "SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE MIEMBRO SUPERIOR"
    },
    {
        "id": "71703d2c-28ec-57c0-a5bf-87673d72ef79",
        "NOMBRE": "SECUELAS DE TRAUMATISMO NO ESPECIFICADO DEL CUELLO Y DEL TRONCO"
    },
    {
        "id": "f4da7668-2523-2cdd-6c52-c3184d0111d7",
        "NOMBRE": "SECUELAS DE TRAUMATISMO SUPERFICIAL DE LA CABEZA"
    },
    {
        "id": "ed64c46d-8db0-c821-bdda-0a96389e7882",
        "NOMBRE": "SECUELAS DE TRAUMATISMO SUPERFICIAL Y DE HERIDAS DEL CUELLO Y DEL TRONCO"
    },
    {
        "id": "9d9deab6-5d4f-3aa1-d44e-4227fb0b53e8",
        "NOMBRE": "SECUELAS DE TRAUMATISMOS DE LA CABEZA"
    },
    {
        "id": "9d0a95ed-0185-456b-bf84-eed69e924728",
        "NOMBRE": "SECUELAS DE TRAUMATISMOS DE MIEMBRO INFERIOR"
    },
    {
        "id": "a9d2e67f-da30-c26e-6732-31c3c5542dbb",
        "NOMBRE": "SECUELAS DE TRAUMATISMOS DE MIEMBRO SUPERIOR"
    },
    {
        "id": "ed32ffd3-ac95-c36f-3d0d-eeedd27ea974",
        "NOMBRE": "SECUELAS DE TRAUMATISMOS DE REGIONES NO ESPECIFICADAS DEL CUERPO"
    },
    {
        "id": "b59d4db2-2cb5-f683-a045-cdd0f84f6de0",
        "NOMBRE": "SECUELAS DE TRAUMATISMOS DEL CUELLO Y DEL TRONCO"
    },
    {
        "id": "97bf47cb-cadb-82b9-65c9-7d38d13266b5",
        "NOMBRE": "SECUELAS DE TRAUMATISMOS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "564be162-e9f2-8b73-d862-8cd033563571",
        "NOMBRE": "SECUELAS DE TRAUMATISMOS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO Y LAS NO ESPECIFICADAS"
    },
    {
        "id": "a0e41522-5470-e799-49eb-d4c26b654181",
        "NOMBRE": "SECUELAS DE TUBERCULOSIS"
    },
    {
        "id": "e558a082-9c34-4b83-37dd-e219dd879c45",
        "NOMBRE": "SECUELAS DE TUBERCULOSIS DE HUESOS Y ARTICULACIONES"
    },
    {
        "id": "d41bad86-d4f2-a9ac-7611-c799550d6bd8",
        "NOMBRE": "SECUELAS DE TUBERCULOSIS DE OTROS ÓRGANOS ESPECIFICADOS"
    },
    {
        "id": "1db3647d-6f22-da12-cfcb-e468614fb1d4",
        "NOMBRE": "SECUELAS DE TUBERCULOSIS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "0550415c-dea2-669d-d7e3-7d15c5f9b695",
        "NOMBRE": "SECUELAS DE TUBERCULOSIS GENITOURINARIA"
    },
    {
        "id": "5a65a7aa-5b42-d6b0-0a15-c9006c53297d",
        "NOMBRE": "SECUELAS DE TUBERCULOSIS RESPIRATORIA Y DE TUBERCULOSIS NO ESPECIFICADA"
    },
    {
        "id": "8b77dfe8-418b-bbde-37d9-c95029c0d686",
        "NOMBRE": "SECUELAS DEL RAQUITISMO"
    },
    {
        "id": "0b4d37fb-c497-faf8-065a-000282aeed31",
        "NOMBRE": "SECUESTRO DEL PULMÓN"
    },
    {
        "id": "52a82c4f-6011-2de4-bb11-06cf7ab8a223",
        "NOMBRE": "SEGUIMIENTO POSTPARTO, DE RUTINA"
    },
    {
        "id": "4edda5fd-6d25-d8ec-bb8d-d67d021f2aac",
        "NOMBRE": "SENILIDAD"
    },
    {
        "id": "5d7bace2-aa5c-ef4c-55c3-f5c56edcb8f8",
        "NOMBRE": "SENO Y QUISTE PREAURICULAR"
    },
    {
        "id": "55ec509d-829f-271c-8e1b-d31081f3e196",
        "NOMBRE": "SENO, FÍSTULA O QUISTE DE LA HENDIDURA BRANQUIAL"
    },
    {
        "id": "071e6361-9f05-4d73-7e71-d58444f74f8c",
        "NOMBRE": "SEPARACIÓN DE LAS CAPAS DE LA RETINA"
    },
    {
        "id": "4c26dd03-d878-7ff5-9f10-763f40b47138",
        "NOMBRE": "SEPSIS ACTINOMICÓTICA"
    },
    {
        "id": "ffacd2af-5e9e-a5f3-90b6-791ff1427491",
        "NOMBRE": "SEPSIS BACTERIANA DEL RECIÉN NACIDO"
    },
    {
        "id": "f98875c0-27f8-3719-6382-6b23c724ac49",
        "NOMBRE": "SEPSIS BACTERIANA DEL RECIÉN NACIDO, NO ESPECIFICADA"
    },
    {
        "id": "967658b4-5ee3-25e9-34d2-9acabe9d6194",
        "NOMBRE": "SEPSIS DEBIDA A ANAEROBIOS"
    },
    {
        "id": "a0ac4189-0166-1272-3314-5f84db110e3a",
        "NOMBRE": "SEPSIS DEBIDA A CANDIDA"
    },
    {
        "id": "9528ca41-5088-27f9-fa6e-5d423d67300c",
        "NOMBRE": "SEPSIS DEBIDA A ESTAFILOCOCO NO ESPECIFICADO"
    },
    {
        "id": "6d04fddd-348e-8fd8-ce28-5d20ecff9370",
        "NOMBRE": "SEPSIS DEBIDA A ESTREPTOCOCO, GRUPO A"
    },
    {
        "id": "b51b9e61-f0fc-1d0d-83ed-c62941fe9507",
        "NOMBRE": "SEPSIS DEBIDA A ESTREPTOCOCO, GRUPO B"
    },
    {
        "id": "1318b8ca-f1b4-ac26-3f27-f0727e656986",
        "NOMBRE": "SEPSIS DEBIDA A ESTREPTOCOCO, GRUPO D Y ENTEROCOCO"
    },
    {
        "id": "ff0027db-36c1-26aa-b84a-9b3642e64764",
        "NOMBRE": "SEPSIS DEBIDA A HAEMOPHILUS INFLUENZAE"
    },
    {
        "id": "a94360af-d41c-1f7f-2a91-0e23d43f3d5b",
        "NOMBRE": "SEPSIS DEBIDA A OTRO ESTAFILOCOCO ESPECIFICADO"
    },
    {
        "id": "ae2f7797-462f-157d-d6f5-a5d9a7e5d8de",
        "NOMBRE": "SEPSIS DEBIDA A OTROS ORGANISMOS GRAMNEGATIVOS"
    },
    {
        "id": "0ae65979-8802-bf0f-1415-a092c2861f2b",
        "NOMBRE": "SEPSIS DEBIDA A SALMONELLA"
    },
    {
        "id": "4b35cf04-32e4-5499-fd56-5768ee457ebc",
        "NOMBRE": "SEPSIS DEBIDA A STAPHYLOCOCCUS AUREUS"
    },
    {
        "id": "301ff445-1778-202d-6914-b8d5d3d83a4e",
        "NOMBRE": "SEPSIS DEBIDA A STREPTOCOCCUS PNEUMONIAE"
    },
    {
        "id": "28fa25b7-85ca-09cc-ba20-999bdc465aec",
        "NOMBRE": "SEPSIS DEL RECIÉN NACIDO DEBIDA A ANAEROBIOS"
    },
    {
        "id": "8323b3df-3e73-b50e-beed-b2bf99c5a88c",
        "NOMBRE": "SEPSIS DEL RECIÉN NACIDO DEBIDA A ESCHERICHIA COLI"
    },
    {
        "id": "e8d69414-14bf-310a-5086-b7c81aaa1203",
        "NOMBRE": "SEPSIS DEL RECIÉN NACIDO DEBIDA A ESTREPTOCOCO DEL GRUPO B"
    },
    {
        "id": "2df7e6d8-81e5-75a8-e81c-c99e2f51b4ba",
        "NOMBRE": "SEPSIS DEL RECIÉN NACIDO DEBIDA A OTRAS BACTERIAS"
    },
    {
        "id": "f2682506-3ea0-9e43-186c-6362f8291139",
        "NOMBRE": "SEPSIS DEL RECIÉN NACIDO DEBIDA A OTROS ESTAFILOCOCOS Y A LOS NO ESPECIFICADOS"
    },
    {
        "id": "e0107b79-1323-3878-7540-a443e6bc3e59",
        "NOMBRE": "SEPSIS DEL RECIÉN NACIDO DEBIDA A OTROS ESTREPTOCOCOS Y A LOS NO ESPECIFICADOS"
    },
    {
        "id": "3c406f95-82d2-b195-78dc-ec4b3569cf41",
        "NOMBRE": "SEPSIS DEL RECIÉN NACIDO DEBIDA A STAPHYLOCOCCUS AUREUS"
    },
    {
        "id": "a57a9463-3533-0e44-5c72-c4f7e543ffd0",
        "NOMBRE": "SEPSIS ESTREPTOCÓCICA"
    },
    {
        "id": "bf784290-ed1f-30ac-36b9-9b0a4cf93578",
        "NOMBRE": "SEPSIS ESTREPTOCÓCICA, NO ESPECIFICADA"
    },
    {
        "id": "1bbda2c7-beab-8e18-d14f-645ed774c498",
        "NOMBRE": "SEPSIS LISTERIANA"
    },
    {
        "id": "7dbc21bf-c20c-79db-bd24-1db9e16cba9b",
        "NOMBRE": "SEPSIS POR ERYSIPELOTHRIX"
    },
    {
        "id": "c8ab92fa-2c5e-a869-88cc-332f8a98b2c6",
        "NOMBRE": "SEPSIS PUERPERAL"
    },
    {
        "id": "a8b79524-84b8-3487-5682-2b3d0af8ce93",
        "NOMBRE": "SEPSIS, NO ESPECIFICADA"
    },
    {
        "id": "477987a0-f4e5-921e-3c5d-2c1e1cb3945a",
        "NOMBRE": "SESIÓN DE QUIMIOTERAPIA POR TUMOR"
    },
    {
        "id": "1711ecb3-4a86-5c77-d5f1-8579a0ab468e",
        "NOMBRE": "SESIÓN DE RADIOTERAPIA"
    },
    {
        "id": "2a8d567d-fbf6-cf68-08fe-8a2c8b31eff6",
        "NOMBRE": "SEUDOARTROSIS CONSECUTIVA A FUSIÓN O ARTRODESIS"
    },
    {
        "id": "1d707c82-2698-cb45-f6c5-f3e9acef5622",
        "NOMBRE": "SEUDOFOLICULITIS DE LA BARBA"
    },
    {
        "id": "f7bb907e-60d3-b30b-7498-27589e788da1",
        "NOMBRE": "SEUDOHERMAFRODITISMO FEMENINO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "22832560-a82c-eb89-dd93-df0980fae633",
        "NOMBRE": "SEUDOHERMAFRODITISMO MASCULINO, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "6256df85-02a5-a617-04dd-3bb79da079a9",
        "NOMBRE": "SEUDOHERMAFRODITISMO, NO ESPECIFICADO"
    },
    {
        "id": "d16f8f3c-b2e2-f269-3378-c1e01848748d",
        "NOMBRE": "SEUDOPELADA"
    },
    {
        "id": "b2f7903e-ac47-77ef-aaff-99ffa05f7918",
        "NOMBRE": "SEUDOQUISTE DEL PÁNCREAS"
    },
    {
        "id": "41e3ff7e-fac3-4c07-cdb1-c2c9e9445d5b",
        "NOMBRE": "SEXO INDETERMINADO Y SEUDOHERMAFRODITISMO"
    },
    {
        "id": "169e2e02-45db-c3e2-d3bb-c6a8d10ff06d",
        "NOMBRE": "SEXO INDETERMINADO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "1be7be4e-1d9f-cc15-5721-6bc82db8620a",
        "NOMBRE": "SHIGELOSIS"
    },
    {
        "id": "8b989bc7-db1a-4946-45fb-ac06ffdd8d1a",
        "NOMBRE": "SHIGELOSIS DE TIPO NO ESPECIFICADO"
    },
    {
        "id": "24104ddd-6486-4d4d-ad2c-e386fa65857a",
        "NOMBRE": "SHIGELOSIS DEBIDA A SHIGELLA BOYDII"
    },
    {
        "id": "3b6001c8-8eec-2ea2-cec9-8a4c397a9805",
        "NOMBRE": "SHIGELOSIS DEBIDA A SHIGELLA DYSENTERIAE"
    },
    {
        "id": "c29f5fd5-e3e5-5eb3-11bf-96f1343f95a9",
        "NOMBRE": "SHIGELOSIS DEBIDA A SHIGELLA FLEXNERI"
    },
    {
        "id": "e3f9e2d9-e01b-6949-0bd2-ae620e94a3fd",
        "NOMBRE": "SHIGELOSIS DEBIDA A SHIGELLA SONNEI"
    },
    {
        "id": "6c91ce7f-bea6-1af6-14af-2d90b25612c7",
        "NOMBRE": "SIALADENITIS"
    },
    {
        "id": "8ea6a773-8e02-a540-6d70-4c95695b89e6",
        "NOMBRE": "SIALOLITIASIS"
    },
    {
        "id": "481aae24-a731-c8c6-9a6d-d8bd153fc348",
        "NOMBRE": "SIDEROSIS"
    },
    {
        "id": "6a7f6401-ef00-57eb-ddb9-32d1c67b9a2b",
        "NOMBRE": "SÍFILIS CARDIOVASCULAR"
    },
    {
        "id": "a63b9c0b-2ab3-622f-4925-a82397628406",
        "NOMBRE": "SÍFILIS CARDIOVASCULAR"
    },
    {
        "id": "fd737541-07ae-86c9-b472-bf5b5e881ce4",
        "NOMBRE": "SÍFILIS CONGÉNITA"
    },
    {
        "id": "41c45dff-f612-fa4e-4c9d-b0c0fa5ac7f6",
        "NOMBRE": "SÍFILIS CONGÉNITA PRECOZ, LATENTE"
    },
    {
        "id": "251f82de-155e-170f-e744-61736c7bf911",
        "NOMBRE": "SÍFILIS CONGÉNITA PRECOZ, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "a26ab7ee-24f0-363f-be79-bf93e9bc79b3",
        "NOMBRE": "SÍFILIS CONGÉNITA PRECOZ, SINTOMÁTICA"
    },
    {
        "id": "a81fdaa8-861e-e1bb-c0ab-be8a1b85f2db",
        "NOMBRE": "SÍFILIS CONGÉNITA TARDÍA, LATENTE"
    },
    {
        "id": "0131977a-b854-bc80-000b-6ef94203f98e",
        "NOMBRE": "SÍFILIS CONGÉNITA TARDÍA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "fefd453f-2cb1-351e-555c-c327844e69aa",
        "NOMBRE": "SÍFILIS CONGÉNITA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "24ee8da6-a388-8c95-9338-3500ee02e2fd",
        "NOMBRE": "SÍFILIS GENITAL PRIMARIA"
    },
    {
        "id": "d5aecabe-1085-6fa4-c1d5-ed67468dd111",
        "NOMBRE": "SÍFILIS LATENTE, NO ESPECIFICADA COMO PRECOZ O TARDÍA"
    },
    {
        "id": "c05e7f8d-0c73-eaea-d61b-b6a05d253ba3",
        "NOMBRE": "SÍFILIS NO VENÉREA"
    },
    {
        "id": "a83d933a-d0ce-0a7a-0115-d9460c3fc358",
        "NOMBRE": "SÍFILIS PRECOZ"
    },
    {
        "id": "69fc665e-8fa0-e0d7-fc74-5ffcc7c98f47",
        "NOMBRE": "SÍFILIS PRECOZ, LATENTE"
    },
    {
        "id": "5ca7a657-757d-3da8-a735-d6426daf6a29",
        "NOMBRE": "SÍFILIS PRECOZ, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "ffb474bd-a403-c88c-363e-61d5015f4e74",
        "NOMBRE": "SÍFILIS PRIMARIA ANAL"
    },
    {
        "id": "e802e1d0-d4b2-f920-bb20-c55442b61562",
        "NOMBRE": "SÍFILIS PRIMARIA EN OTROS SITIOS"
    },
    {
        "id": "290c1fdc-bb75-5647-3b9d-b967594921e6",
        "NOMBRE": "SÍFILIS QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "b4d16811-256f-6df1-93d9-2087f011f668",
        "NOMBRE": "SÍFILIS RENAL TARDÍA"
    },
    {
        "id": "04862bf3-ebfe-6c28-8b65-7266afa8adf1",
        "NOMBRE": "SÍFILIS SECUNDARIA DE PIEL Y MEMBRANAS MUCOSAS"
    },
    {
        "id": "602d0af0-ce26-1640-9162-fea5e071a1f6",
        "NOMBRE": "SÍFILIS TARDÍA"
    },
    {
        "id": "f67a30fc-588a-ea7f-c4f7-f2bc3ec05885",
        "NOMBRE": "SÍFILIS TARDÍA, LATENTE"
    },
    {
        "id": "055c449d-24d4-559e-b39e-5a0350adafee",
        "NOMBRE": "SÍFILIS TARDÍA, NO ESPECIFICADA"
    },
    {
        "id": "4854bee9-cef9-2cf5-d987-0e91e5e4d70b",
        "NOMBRE": "SÍFILIS, NO ESPECIFICADA"
    },
    {
        "id": "c2532abf-71ac-2454-3885-cbfa94091727",
        "NOMBRE": "SILBIDO"
    },
    {
        "id": "b0789b0c-130b-ed98-eb2f-f168a4934388",
        "NOMBRE": "SÍNCOPE POR CALOR"
    },
    {
        "id": "81cfc96b-a2fe-e5d9-d9c0-f2eb9326b1eb",
        "NOMBRE": "SÍNCOPE Y COLAPSO"
    },
    {
        "id": "7238b9f4-b7e4-ab31-b6c7-b3dd081f53b1",
        "NOMBRE": "SINDACTILIA"
    },
    {
        "id": "4fa4a771-547d-62f0-bdc3-ca1df36275de",
        "NOMBRE": "SINDACTILIA, NO ESPECIFICADA"
    },
    {
        "id": "747ac3de-7a19-4c69-9a2a-86ed1146ca8d",
        "NOMBRE": "SÍNDROME (CARDIO) PULMONAR POR HANTAVIRUS [SPH] [SCPH] (J17.1*)"
    },
    {
        "id": "12b69832-54a7-41fc-e4e7-df7ccd813817",
        "NOMBRE": "SÍNDROME AMNÉSICO ORGÁNICO, NO INDUCIDO POR ALCOHOL O POR OTRAS SUSTANCIAS PSICOACTIVAS"
    },
    {
        "id": "215e8abb-3073-2377-4438-c3aabece8df8",
        "NOMBRE": "SÍNDROME ARTERIAL VÉRTEBRO-BASILAR"
    },
    {
        "id": "c77b51d0-8790-5808-eadc-63c5282ed09f",
        "NOMBRE": "SÍNDROME CARCINOIDE"
    },
    {
        "id": "06c947fc-0652-9944-11a4-3c1aed1fa30f",
        "NOMBRE": "SÍNDROME CERVICOBRAQUIAL"
    },
    {
        "id": "ecbdba1c-6a27-b635-1fc6-dc40a2a731a1",
        "NOMBRE": "SÍNDROME CERVICOCRANEAL"
    },
    {
        "id": "d14cb4e2-0939-89f5-1ec2-f07d4a771495",
        "NOMBRE": "SÍNDROME CONGÉNITO DE DEFICIENCIA DE YODO"
    },
    {
        "id": "910e196f-ff48-6cd7-1a0b-88af8b2f3744",
        "NOMBRE": "SÍNDROME CONGÉNITO DE DEFICIENCIA DE YODO, NO ESPECIFICADO"
    },
    {
        "id": "e83ea036-0ad5-a778-6dd8-167e973acba4",
        "NOMBRE": "SÍNDROME CONGÉNITO DE DEFICIENCIA DE YODO, TIPO MIXTO"
    },
    {
        "id": "90a0c759-d3ec-1f99-11da-c145f55f806c",
        "NOMBRE": "SÍNDROME CONGÉNITO DE DEFICIENCIA DE YODO, TIPO NEUROLÓGICO"
    },
    {
        "id": "6db1a96f-d12d-646b-b86d-a879826039d9",
        "NOMBRE": "SÍNDROME COQUELUCHOIDE"
    },
    {
        "id": "2b6d6084-35bd-88ff-ac2b-b7194af42c5c",
        "NOMBRE": "SÍNDROME CR(E)ST"
    },
    {
        "id": "420673d9-40a3-91b9-f26d-8ce9feadac7d",
        "NOMBRE": "SÍNDROME DE ABDUCCIÓN DOLOROSA DEL HOMBRO"
    },
    {
        "id": "b17afc00-a78f-eef6-246e-2cd96b5300e0",
        "NOMBRE": "SÍNDROME DE ACTH ECTÓPICO"
    },
    {
        "id": "c2d51fb7-c5cf-0d6c-dfec-a8bfd2885d16",
        "NOMBRE": "SÍNDROME DE ARNOLD-CHIARI"
    },
    {
        "id": "01a83531-3bfc-acd5-b7d4-fecf8362e901",
        "NOMBRE": "SÍNDROME DE ASPERGER"
    },
    {
        "id": "ad6edf37-7640-ba39-5f67-8e86556e5c2c",
        "NOMBRE": "SÍNDROME DE ASPIRACIÓN NEONATAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c8d9b73a-8b82-2faa-2f14-0bd59a1abc9a",
        "NOMBRE": "SÍNDROME DE BUDD-CHIARI"
    },
    {
        "id": "3d9e095d-3be5-4bd4-49d8-8d5034ad976a",
        "NOMBRE": "SÍNDROME DE CEFALEA EN RACIMOS"
    },
    {
        "id": "a88aa0f9-5d75-643b-1171-2b29eaa678c8",
        "NOMBRE": "SÍNDROME DE COMPRESIÓN DEL TRONCO CELÍACO"
    },
    {
        "id": "a57c312a-1037-e284-1509-30ca73a631b1",
        "NOMBRE": "SÍNDROME DE COSTILLA CORTA"
    },
    {
        "id": "cb922435-4cfd-30db-4d95-acba2a45972d",
        "NOMBRE": "SÍNDROME DE CRIGLER-NAJJAR"
    },
    {
        "id": "fa8aa284-74a9-6785-c473-26e0f3a26f9d",
        "NOMBRE": "SÍNDROME DE CUSHING"
    },
    {
        "id": "ac7f11cf-3eb8-8e9b-9949-a3937cf640e5",
        "NOMBRE": "SÍNDROME DE CUSHING INDUCIDO POR DROGAS"
    },
    {
        "id": "59f641a0-c6e9-4373-87cc-748f913459e3",
        "NOMBRE": "SÍNDROME DE CUSHING, NO ESPECIFICADO"
    },
    {
        "id": "7e226335-62f8-39f5-5489-e6b27f68418f",
        "NOMBRE": "SÍNDROME DE DEFICIENCIA CONGÉNITA DE YODO, TIPO MIXEDEMATOSO"
    },
    {
        "id": "1a43d7fa-6606-6435-591c-643cc7e26725",
        "NOMBRE": "SÍNDROME DE DESPERSONALIZACIÓN Y DESVINCULACIÓN DE LA REALIDAD"
    },
    {
        "id": "6a205201-d452-0568-3b97-e15786454ade",
        "NOMBRE": "SÍNDROME DE DI GEORGE"
    },
    {
        "id": "af99ee0f-4cc1-a48f-5b5a-a40a0b3d0f63",
        "NOMBRE": "SÍNDROME DE DIFICULTAD RESPIRATORIA DEL ADULTO"
    },
    {
        "id": "cd4be0d0-a328-785b-3ca6-1dede1ac9d3c",
        "NOMBRE": "SÍNDROME DE DIFICULTAD RESPIRATORIA DEL RECIÉN NACIDO"
    },
    {
        "id": "f6735a35-ec29-c5ae-3db8-e46241a477c0",
        "NOMBRE": "SÍNDROME DE DOLOR REGIONAL COMPLEJO TIPO  II"
    },
    {
        "id": "c38d4307-ac8c-cb90-5115-4e7f31d263dc",
        "NOMBRE": "SÍNDROME DE DOLOR REGIONAL COMPLEJO TIPO I"
    },
    {
        "id": "ca88712a-3a76-8945-f6e8-989264fd3844",
        "NOMBRE": "SÍNDROME DE DOLOR REGIONAL COMPLEJO, OTRO TIPO Y EL NO ESPECIFICADO"
    },
    {
        "id": "fb96262c-78a7-32b7-0a0a-c43a32caf21d",
        "NOMBRE": "SÍNDROME DE DOWN"
    },
    {
        "id": "2a34c8f9-9419-33a6-9bb5-03033814e7e3",
        "NOMBRE": "SÍNDROME DE DOWN, NO ESPECIFICADO"
    },
    {
        "id": "f7d38744-fcd3-a771-d5bf-241c60b695fe",
        "NOMBRE": "SÍNDROME DE DRESSLER"
    },
    {
        "id": "93500a66-f30a-50c5-fdeb-3bb718e208e4",
        "NOMBRE": "SÍNDROME DE EATON-LAMBERT (C00-D48†)"
    },
    {
        "id": "1a07baf5-33da-8d7f-ab0e-ecdcd1bf3838",
        "NOMBRE": "SÍNDROME DE EDWARDS Y SÍNDROME DE PATAU"
    },
    {
        "id": "f94ef258-95bb-2ed3-6721-c97d847d5d53",
        "NOMBRE": "SÍNDROME DE EDWARDS, NO ESPECIFICADO"
    },
    {
        "id": "4705370c-963e-1927-4354-6f3bc9dede1f",
        "NOMBRE": "SÍNDROME DE EHLERS-DANLOS"
    },
    {
        "id": "560e38ac-49ef-36e5-3b57-2c9c8398b52e",
        "NOMBRE": "SÍNDROME DE ENCLAUSTRAMIENTO"
    },
    {
        "id": "ff5661d6-3953-f631-05f1-22f6863dea06",
        "NOMBRE": "SÍNDROME DE ESPALDA PLANA"
    },
    {
        "id": "12207052-d040-0d7e-74d0-2633a9f61433",
        "NOMBRE": "SÍNDROME DE FATIGA POSTVIRAL"
    },
    {
        "id": "96008eab-8c7a-2da0-8ee5-116fef22fc27",
        "NOMBRE": "SÍNDROME DE FELTY"
    },
    {
        "id": "29ee62f9-bdbb-6b7d-6406-a38e045cf72f",
        "NOMBRE": "SÍNDROME DE GILBERT"
    },
    {
        "id": "3fc23aa8-58b8-418a-1f03-16dff5648890",
        "NOMBRE": "SÍNDROME DE GUILLAIN-BARRÉ"
    },
    {
        "id": "b7b77a95-e879-852e-3387-1664e78634c0",
        "NOMBRE": "SÍNDROME DE HIDANTOÍNA FETAL"
    },
    {
        "id": "259e42ca-fdc0-ba62-720c-d853c2ca199b",
        "NOMBRE": "SÍNDROME DE HIPERINMUNOGLOBULINA E [IGE]"
    },
    {
        "id": "bb856710-5a6b-8d90-202d-94278dc0ba20",
        "NOMBRE": "SÍNDROME DE HIPERMOVILIDAD"
    },
    {
        "id": "f8d25167-1b6a-7819-2a04-14eb070c04e5",
        "NOMBRE": "SÍNDROME DE HIPOPLASIA DEL CORAZÓN DERECHO"
    },
    {
        "id": "c2c17c88-844d-8b13-7c83-5859d0042af6",
        "NOMBRE": "SÍNDROME DE HIPOPLASIA DEL CORAZÓN IZQUIERDO"
    },
    {
        "id": "9b283eea-2d00-cc8f-4f04-84c829ffaa31",
        "NOMBRE": "SÍNDROME DE HIPOTENSIÓN MATERNA"
    },
    {
        "id": "8371535e-c676-2272-a6d9-ae3f835c9ad7",
        "NOMBRE": "SÍNDROME DE HORNER"
    },
    {
        "id": "97d864d4-50a5-6822-133d-97e9b81d6395",
        "NOMBRE": "SÍNDROME DE INFARTO CEREBELOSO"
    },
    {
        "id": "93621faa-088a-b951-ce48-65acf5b85523",
        "NOMBRE": "SÍNDROME DE INFECCIÓN AGUDA DEBIDA A VIH"
    },
    {
        "id": "91a0369b-7b38-ae07-ad86-320a6683c45f",
        "NOMBRE": "SÍNDROME DE INMOVILIDAD (PARAPLÉJICO)"
    },
    {
        "id": "873fcfbe-0195-8aff-a3df-69849835a74a",
        "NOMBRE": "SÍNDROME DE INTESTINO IRRITABLE CON CONSTIPACIÓN PREDOMINANTE [IBS-C]"
    },
    {
        "id": "bd059689-c371-b616-3a91-01af49d4edf1",
        "NOMBRE": "SÍNDROME DE INTESTINO IRRITABLE CON DIARREA PREDOMINANTE [IBS-D]"
    },
    {
        "id": "781f382e-4d8c-0f09-4204-4370909754a2",
        "NOMBRE": "SÍNDROME DE INTESTINO IRRITABLE CON HÁBITOS INTESTINALES MIXTOS [IBS-M]"
    },
    {
        "id": "338bc3cd-75c4-d78c-a904-e48c8c161de3",
        "NOMBRE": "SÍNDROME DE KLINEFELTER, CARIOTIPO 47, XXY"
    },
    {
        "id": "3ade33b8-a4fb-e7a6-47eb-9db8b31cab23",
        "NOMBRE": "SÍNDROME DE KLINEFELTER, HOMBRE CON CARIOTIPO 46, XX"
    },
    {
        "id": "9cc73456-2256-f0ef-2021-198780f07d20",
        "NOMBRE": "SÍNDROME DE KLINEFELTER, HOMBRE CON MÁS DE DOS CROMOSOMAS X"
    },
    {
        "id": "7caad1b8-d17c-ad88-185a-1713b850a076",
        "NOMBRE": "SÍNDROME DE KLINEFELTER, NO ESPECIFICADO"
    },
    {
        "id": "2fb51897-f9ae-b6b4-0034-75cbee8aa6ca",
        "NOMBRE": "SÍNDROME DE KLIPPEL-FEIL"
    },
    {
        "id": "e8bb9e42-b3c3-51bd-107f-c050e5bd6fa8",
        "NOMBRE": "SÍNDROME DE LA ARTERIA CARÓTIDA (HEMISFÉRICO)"
    },
    {
        "id": "a1b44e84-be3e-be79-553e-d5a1b4832580",
        "NOMBRE": "SÍNDROME DE LA ARTERIA CEREBRAL ANTERIOR"
    },
    {
        "id": "c4c52bc2-3216-a0e4-7d6e-b2f7a30c3320",
        "NOMBRE": "SÍNDROME DE LA ARTERIA CEREBRAL MEDIA"
    },
    {
        "id": "d93214f9-538a-b8c3-ea40-540104b2887f",
        "NOMBRE": "SÍNDROME DE LA ARTERIA CEREBRAL POSTERIOR"
    },
    {
        "id": "a807c7ce-d070-6299-4884-7b359044e0e5",
        "NOMBRE": "SÍNDROME DE LA ARTICULACIÓN CONDROCOSTAL [TIETZE]"
    },
    {
        "id": "7d13b1dc-4979-34ab-aa00-69722dc59fa5",
        "NOMBRE": "SÍNDROME DE LA BILIS ESPESA"
    },
    {
        "id": "5fa4b211-9334-ff3c-f5ae-8192e534c611",
        "NOMBRE": "SÍNDROME DE LA COLA DE CABALLO"
    },
    {
        "id": "b3bb22ea-7678-b66c-7a45-50db9861ef47",
        "NOMBRE": "SÍNDROME DE LA ERUPCIÓN DENTARIA"
    },
    {
        "id": "96cb6c6f-006f-9400-a002-43ac15defa49",
        "NOMBRE": "SÍNDROME DE LA MUERTE SÚBITA INFANTIL"
    },
    {
        "id": "9e26df2a-9caf-05b0-43cb-dcd34e82d364",
        "NOMBRE": "SÍNDROME DE LA MUERTE SÚBITA INFANTIL"
    },
    {
        "id": "d766ffbc-65be-77ba-b374-cea505f79f02",
        "NOMBRE": "SÍNDROME DE LA MUERTE SÚBITA INFANTIL, CON MENCIÓN DE AUTOPSIA"
    },
    {
        "id": "b3bb024c-b225-253b-5c55-4ffe39a1ed44",
        "NOMBRE": "SÍNDROME DE LA MUERTE SÚBITA INFANTIL, SIN MENCIÓN DE AUTOPSIA"
    },
    {
        "id": "4f5aaea0-485f-f9a1-b866-ad57ea6e6994",
        "NOMBRE": "SÍNDROME DE LA UÑA AMARILLA"
    },
    {
        "id": "2e4b4044-f90a-76f7-1667-41f576452e4d",
        "NOMBRE": "SÍNDROME DE LACERACIÓN Y HEMORRAGIA GASTROESOFÁGICAS"
    },
    {
        "id": "37ab15d0-5b7d-05ea-e012-bb46e92eea38",
        "NOMBRE": "SÍNDROME DE LESCH-NYHAN"
    },
    {
        "id": "3a748408-c615-140c-e67c-8136b3c5e6d1",
        "NOMBRE": "SÍNDROME DE LINFEDEMA POSTMASTECTOMÍA"
    },
    {
        "id": "0d428595-70ae-4877-6511-9233c985d5ba",
        "NOMBRE": "SÍNDROME DE LISIS TUMORAL"
    },
    {
        "id": "d3f57d94-50d6-7fce-95e5-cec56701004e",
        "NOMBRE": "SÍNDROME DE MACLEOD"
    },
    {
        "id": "134f76a4-b44e-3d06-b643-49d3b3c38636",
        "NOMBRE": "SÍNDROME DE MARFAN"
    },
    {
        "id": "98e9fcc3-8c96-205e-a81b-64df54ff80bc",
        "NOMBRE": "SÍNDROME DE MEGAVITAMINA B6"
    },
    {
        "id": "de4daef2-d0ec-aa41-4ea3-597b6aadfb30",
        "NOMBRE": "SÍNDROME DE MELKERSSON"
    },
    {
        "id": "98182c21-6090-e124-3206-a90f8179de95",
        "NOMBRE": "SÍNDROME DE MENDELSON"
    },
    {
        "id": "1dd5cfe7-403b-bdb4-b03f-c1f99c3eebe4",
        "NOMBRE": "SÍNDROME DE NELSON"
    },
    {
        "id": "98b8dd21-6b3c-a442-be80-2fc0a6f1a6f8",
        "NOMBRE": "SÍNDROME DE NEZELOF"
    },
    {
        "id": "0180aea8-a5d9-45bc-e8c8-786bd7220371",
        "NOMBRE": "SÍNDROME DE OVARIO POLIQUÍSTICO"
    },
    {
        "id": "de441b45-2734-cd15-65ec-1b9687a78954",
        "NOMBRE": "SÍNDROME DE PATAU, NO ESPECIFICADO"
    },
    {
        "id": "54a9837d-8724-0286-2e38-2f3e488a5c46",
        "NOMBRE": "SÍNDROME DE POSTCARDIOTOMÍA"
    },
    {
        "id": "5169d102-487f-f492-669a-0d749d89744b",
        "NOMBRE": "SÍNDROME DE POSTCOLECISTECTOMÍA"
    },
    {
        "id": "4ae6eb68-b91e-3434-0655-27eb99d9b94e",
        "NOMBRE": "SÍNDROME DE POTTER"
    },
    {
        "id": "b6eff207-4394-c018-6a58-d33126de8b3c",
        "NOMBRE": "SÍNDROME DE PREEXCITACIÓN"
    },
    {
        "id": "0242989b-1450-0af8-2afd-7132a7c2fc1d",
        "NOMBRE": "SÍNDROME DE RAYNAUD"
    },
    {
        "id": "67423666-c055-0101-d095-ae69608b62d8",
        "NOMBRE": "SÍNDROME DE RECONSTITUCIÓN INMUNE"
    },
    {
        "id": "21e24ac9-9d59-a915-42b9-e84fc0959626",
        "NOMBRE": "SÍNDROME DE RESISTENCIA ANDROGÉNICA"
    },
    {
        "id": "bd361f25-e722-f789-bded-a604abafcfd4",
        "NOMBRE": "SÍNDROME DE RESPUESTA INFLAMATORIA SISTÉMICA"
    },
    {
        "id": "5602e10c-a0f4-3ebd-d652-a6b31dd151aa",
        "NOMBRE": "SÍNDROME DE RESPUESTA INFLAMATORIA SISTÉMICA DE ORIGEN INFECCIOSO, CON FALLA ORGÁNICA"
    },
    {
        "id": "efcd1f65-fe62-d1d3-02a6-2817831aa75f",
        "NOMBRE": "SÍNDROME DE RESPUESTA INFLAMATORIA SISTÉMICA DE ORIGEN INFECCIOSO, SIN FALLA ORGÁNICA"
    },
    {
        "id": "2270deb4-c84b-d82c-5bab-d82eb1522c36",
        "NOMBRE": "SÍNDROME DE RESPUESTA INFLAMATORIA SISTÉMICA DE ORIGEN NO INFECCIOSO, CON FALLA ORGÁNICA"
    },
    {
        "id": "fc10db39-c587-42d4-060a-4cd9a0fe514d",
        "NOMBRE": "SÍNDROME DE RESPUESTA INFLAMATORIA SISTÉMICA DE ORIGEN NO INFECCIOSO, SIN FALLA ORGÁNICA"
    },
    {
        "id": "f171cbf9-bce2-909d-882a-f8260c81c00a",
        "NOMBRE": "SÍNDROME DE RESPUESTA INFLAMATORIA SISTÉMICA, NO ESPECIFICADO"
    },
    {
        "id": "81386353-e73a-5f10-f354-bb33bbc1d3f2",
        "NOMBRE": "SÍNDROME DE RETT"
    },
    {
        "id": "91af765b-669d-49e2-2e47-45c76514ec2b",
        "NOMBRE": "SÍNDROME DE REYE"
    },
    {
        "id": "4e0d7f5c-17ad-885d-6f3f-fde8d9f3e5f8",
        "NOMBRE": "SÍNDROME DE RUBÉOLA CONGÉNITA"
    },
    {
        "id": "76d5a89e-ef4d-4701-b7b3-cfa51390e9b9",
        "NOMBRE": "SÍNDROME DE SECRECIÓN INAPROPIADA DE HORMONA ANITIDIURÉTICA"
    },
    {
        "id": "7cd21674-55d3-e0d4-81a0-0c999bae42db",
        "NOMBRE": "SÍNDROME DE SEUDO-CUSHING INDUCIDO POR ALCOHOL"
    },
    {
        "id": "88caba89-54d4-4969-b5c3-518144042548",
        "NOMBRE": "SÍNDROME DE TENSIÓN PREMENSTRUAL"
    },
    {
        "id": "592d61d5-0e3f-f9e5-2676-177fac27e66a",
        "NOMBRE": "SÍNDROME DE TRANSFUSIÓN PLACENTARIA"
    },
    {
        "id": "09db43f3-e8a6-4043-755d-75654351904f",
        "NOMBRE": "SÍNDROME DE TURNER"
    },
    {
        "id": "f0f4351f-2f62-79d6-12c8-4de1e724ae3f",
        "NOMBRE": "SÍNDROME DE TURNER, NO ESPECIFICADO"
    },
    {
        "id": "a793a43b-2ab7-8084-2310-12e11a580858",
        "NOMBRE": "SÍNDROME DE WATERHOUSE-FRIDERICHSEN"
    },
    {
        "id": "8daae225-b6f6-bc4c-96c2-53d32ad283f8",
        "NOMBRE": "SÍNDROME DE WILSON-MIKITY"
    },
    {
        "id": "db763911-752f-17f3-e2a6-86f21a6722f5",
        "NOMBRE": "SÍNDROME DE WISKOTT-ALDRICH"
    },
    {
        "id": "09c6579b-e8aa-1d12-be90-8d5bb9c6e6e5",
        "NOMBRE": "SÍNDROME DEL ABDOMEN EN CIRUELA PASA"
    },
    {
        "id": "51635211-3e07-4000-bc3e-ce1c19c05b5d",
        "NOMBRE": "SÍNDROME DEL ASA CIEGA, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "7029a575-9ea5-4a40-8e45-753bb4ca3fa3",
        "NOMBRE": "SÍNDROME DEL CAYADO DE LA AORTA [TAKAYASU]"
    },
    {
        "id": "baa92ca2-f407-3599-6294-13096bf2354e",
        "NOMBRE": "SÍNDROME DEL CHOQUE TÓXICO"
    },
    {
        "id": "e1d555cd-4750-bac5-ce2a-9adcd59b38cb",
        "NOMBRE": "SÍNDROME DEL COLON IRRITABLE"
    },
    {
        "id": "d3dde1df-1aaf-c0cb-9850-0957a24bb6b6",
        "NOMBRE": "SÍNDROME DEL COLON IRRITABLE CON DIARREA"
    },
    {
        "id": "b60149ab-743e-dd80-1c07-84e4faf41b3c",
        "NOMBRE": "SÍNDROME DEL COLON IRRITABLE SIN DIARREA"
    },
    {
        "id": "6bef9a4a-7b50-8316-5ce2-341f6dfe2524",
        "NOMBRE": "SÍNDROME DEL ENFRIAMIENTO"
    },
    {
        "id": "9427be0c-d0bf-e55f-9519-eff68f7f7a26",
        "NOMBRE": "SÍNDROME DEL MALTRATO"
    },
    {
        "id": "21e809c3-2fb9-6f7f-c3d8-75f6b37d449e",
        "NOMBRE": "SÍNDROME DEL MALTRATO, NO ESPECIFICADO"
    },
    {
        "id": "241a5c6d-9bb4-b29c-27ac-3764409412ef",
        "NOMBRE": "SÍNDROME DEL MANGUITO ROTATORIO"
    },
    {
        "id": "520d0862-40c0-93e1-d349-165e34fe4f0f",
        "NOMBRE": "SÍNDROME DEL MIEMBRO FANTASMA CON DOLOR"
    },
    {
        "id": "ca286b0b-db0c-900d-024f-06942112ec05",
        "NOMBRE": "SÍNDROME DEL MIEMBRO FANTASMA SIN DOLOR"
    },
    {
        "id": "d344e3be-a5b5-1464-5032-0b1e2bc9be77",
        "NOMBRE": "SÍNDROME DEL RECIÉN NACIDO DE MADRE CON DIABETES GESTACIONAL"
    },
    {
        "id": "9acc13cd-60cf-54ec-6982-6ad85b8b45a5",
        "NOMBRE": "SÍNDROME DEL RECIÉN NACIDO DE MADRE DIABÉTICA"
    },
    {
        "id": "0fc90fb6-3f56-49ae-1b7d-c7829dd70d21",
        "NOMBRE": "SÍNDROME DEL SENO ENFERMO"
    },
    {
        "id": "db736578-04ed-f47c-8aaa-38b3bd6e716b",
        "NOMBRE": "SÍNDROME DEL TAPÓN DE MECONIO"
    },
    {
        "id": "4496163b-54dd-eebd-1c78-8fc7c0b94b1b",
        "NOMBRE": "SÍNDROME DEL TENDÓN DEL TENSOR DE LA FASCIA LATA"
    },
    {
        "id": "2464fc8e-345b-b0e8-98ec-1117513adf73",
        "NOMBRE": "SÍNDROME DEL TÚNEL CALCÁNEO"
    },
    {
        "id": "e56b18ad-3de2-e8e0-c568-817a55ab7977",
        "NOMBRE": "SÍNDROME DEL TÚNEL CARPIANO"
    },
    {
        "id": "445b7423-1560-e1e0-456f-d5007abd8b9a",
        "NOMBRE": "SÍNDROME ESTAFILOCÓCICO DE LA PIEL ESCALDADA"
    },
    {
        "id": "0052f834-e7d9-341d-37f1-81a66e619a58",
        "NOMBRE": "SÍNDROME FETAL (DISMÓRFICO) DEBIDO AL ALCOHOL"
    },
    {
        "id": "c55ca614-f832-fdb6-8204-c3fb7396da10",
        "NOMBRE": "SÍNDROME HELLP"
    },
    {
        "id": "9b9ca04d-9282-eaa8-de2e-8f7aeaa566aa",
        "NOMBRE": "SÍNDROME HEMOFAGOCÍTICO ASOCIADO A INFECCIÓN"
    },
    {
        "id": "3b0dfc1d-4889-bab4-acf9-ee86f99a581e",
        "NOMBRE": "SÍNDROME HEMOLÍTICO-URÉMICO"
    },
    {
        "id": "6b1200ff-49f1-346a-6797-b7381376090d",
        "NOMBRE": "SÍNDROME HEPATORRENAL"
    },
    {
        "id": "c9695dfc-5c76-f585-bfcb-b721a765190d",
        "NOMBRE": "SÍNDROME INFLAMATORIO MULTISISTÉMICO ASOCIADO CON LA COVID-19"
    },
    {
        "id": "3a42eae4-8abd-b406-719b-b683cadc952c",
        "NOMBRE": "SÍNDROME INFLAMATORIO MULTISISTÉMICO ASOCIADO CON LA COVID-19, NO ESPECIFICADO"
    },
    {
        "id": "832a3096-f781-58b9-5a7d-a87289e4b6cf",
        "NOMBRE": "SÍNDROME LACUNAR MOTOR PURO"
    },
    {
        "id": "3a4ffb42-3319-f8bd-08fe-0a67a2c6c673",
        "NOMBRE": "SÍNDROME LACUNAR SENSORIAL PURO"
    },
    {
        "id": "25924d2d-0dd1-b394-e4bd-a7eb4bf010ca",
        "NOMBRE": "SÍNDROME MIELODISPLÁSICO CON ANORMALIDAD CROMOSÓMICA AISLADA DEL (5Q)"
    },
    {
        "id": "00a2868f-53f7-2876-c4e1-c72fc65e60d1",
        "NOMBRE": "SÍNDROME MIELODISPLÁSICO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "acdbc749-43d0-8a0d-a320-ba5614d54171",
        "NOMBRE": "SÍNDROME MUCOCUTÁNEO LINFONODULAR [KAWASAKI]"
    },
    {
        "id": "f3e6ad34-3b39-f46e-8ff4-e9c2df661d31",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO"
    },
    {
        "id": "26052bc3-a66f-9b65-73d8-74c764a654c4",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "e75f7ef1-ae96-3e0a-0838-a50f631dd2d8",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "5e44eae9-d323-8785-1dc1-1f46b87a1084",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "7a4310d7-2279-ac88-b0e6-80ebbbb99f16",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "4dbad239-6abd-c886-b4fe-1f5bc0e64e26",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "ec434bd0-b39c-be6c-f318-36cb94187c9c",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "bfa111cd-2c91-545e-12f8-00bf53d36661",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "bc516fe3-a88a-dcf8-79c5-13203419b9d4",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "6d9899ad-ed7d-f338-af23-0cccec4ca02a",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, NO ESPECIFICADA"
    },
    {
        "id": "000e5acb-549b-d3d3-3bfb-8b48dfbf7e06",
        "NOMBRE": "SÍNDROME NEFRÍTICO AGUDO, OTRAS"
    },
    {
        "id": "06e0916d-2195-90d4-cae3-14a4aa4c56ab",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO"
    },
    {
        "id": "15bd2e19-c4c0-9c57-64ee-1a73aef19855",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "16fc6dfe-e005-564b-4800-df7eefb03ec8",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "28e13360-5099-1412-3c59-35adb2408d1c",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "93e2cfa0-431f-a81f-3828-fc0d0733969f",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "5cdac948-70cb-a395-27f4-3c4427d69ea9",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "55d301b3-5df4-61d1-f41e-4f7c1845213a",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "11dc0fed-2848-ce32-23de-a4fe20c5cf08",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "4e1a7ec4-2cbb-0802-8895-dea03c95ebfb",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "fbae2dee-f662-781b-b1ed-13bf0b8430da",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, NO ESPECIFICADA"
    },
    {
        "id": "9dcca3ad-e66a-3b10-8ceb-dd3e967b8151",
        "NOMBRE": "SÍNDROME NEFRÍTICO CRÓNICO, OTRAS"
    },
    {
        "id": "f2e498b8-5049-9023-5e0f-c005dcd98293",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADA"
    },
    {
        "id": "5399dc49-b56d-a2ff-1178-745a04e589d9",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO"
    },
    {
        "id": "0f41aa8c-cd82-0f03-2985-d5b2871378c3",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "bcd8a71b-ee7f-b798-b0de-e0e912d6a826",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "8bb1a5ab-2933-47a7-a383-ac7bd4c39590",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "7a9301a2-5bd5-972d-0e4f-42b43ff9337d",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "b43b5899-ca31-8141-82e3-ee637a10ea00",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "2d957dd3-24dd-da8b-66e1-7dbb02114f06",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "4db81fd4-e874-cbc4-0452-6f610e503a7e",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "a5b057a7-7b6a-3267-212b-59bd488ae3bf",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "32613542-3dda-3c77-3b1e-99127b5ba9c8",
        "NOMBRE": "SÍNDROME NEFRÍTICO NO ESPECIFICADO, OTRAS"
    },
    {
        "id": "64fd6365-a5e2-6a48-6cfb-a92e2ac6a1b2",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO"
    },
    {
        "id": "69fcf6b3-604d-885f-0b64-be5db456092a",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "7f61d612-d3f5-938b-fdd8-25c8d20d4716",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "e384f0eb-031d-80bc-97b4-c0fcd4e80570",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "481716c8-4b3a-ee93-a330-e843a2c13676",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "1cf1e5a3-a7fe-1b81-9dbc-78efa25f4c89",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "a9b57be1-703e-d3a3-1506-0d97f79bb591",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "2b36ac01-0cc7-a90a-89e7-98b627c86aab",
        "NOMBRE": "SÍNDROME NEFRITICO RÁPIDAMENTE PROGRESIVO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "0d6e7531-3b66-3f0f-e5e5-337be3327a54",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "b70a0514-7d89-0cd2-d682-7d0aeecb6055",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, NO ESPECIFICADA"
    },
    {
        "id": "888ce0e3-8f72-efce-6053-c08d5c728403",
        "NOMBRE": "SÍNDROME NEFRÍTICO RÁPIDAMENTE PROGRESIVO, OTRAS"
    },
    {
        "id": "1540cc5b-fb11-197e-81b3-0d578e22622c",
        "NOMBRE": "SÍNDROME NEFRÓTICO"
    },
    {
        "id": "5e04f9f1-5477-5161-19fd-d69140ce98ae",
        "NOMBRE": "SÍNDROME NEFRÓTICO, ANOMALÍA GLOMERULAR MÍNIMA"
    },
    {
        "id": "f66a76b8-4a10-dd3f-4587-5d75db5e4816",
        "NOMBRE": "SÍNDROME NEFRÓTICO, ENFERMEDAD POR DEPÓSITOS DENSOS"
    },
    {
        "id": "e2847bcc-522c-ee50-be7d-5438e5a31919",
        "NOMBRE": "SÍNDROME NEFRÓTICO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"
    },
    {
        "id": "a54b6746-1845-1961-d39c-a1e072ad72cd",
        "NOMBRE": "SÍNDROME NEFRÓTICO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA"
    },
    {
        "id": "36523afa-a9b5-362d-00d1-ced2b028d625",
        "NOMBRE": "SÍNDROME NEFRÓTICO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"
    },
    {
        "id": "402a7e1d-3533-03ca-726b-55d96e66cfa8",
        "NOMBRE": "SÍNDROME NEFRÓTICO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"
    },
    {
        "id": "9a8f2047-952d-0e16-3154-c0775b24d8e3",
        "NOMBRE": "SÍNDROME NEFRÓTICO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"
    },
    {
        "id": "64834d2d-54d1-6421-0286-538f6e62995c",
        "NOMBRE": "SÍNDROME NEFRÓTICO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"
    },
    {
        "id": "b9410934-511b-a273-28e0-299b6cb5bac4",
        "NOMBRE": "SÍNDROME NEFRÓTICO, NO ESPECIFICADA"
    },
    {
        "id": "b8364f55-544d-ad51-4712-bf854434c5bd",
        "NOMBRE": "SÍNDROME NEFRÓTICO, OTRAS"
    },
    {
        "id": "4f51d546-3dbe-c443-25f4-590ff5a5855f",
        "NOMBRE": "SÍNDROME NEUROLÉPTICO MALIGNO"
    },
    {
        "id": "d235139f-683f-bb8b-f1ab-5b728efcd42d",
        "NOMBRE": "SÍNDROME PARALÍTICO, NO ESPECIFICADO"
    },
    {
        "id": "7b6d67b8-da46-b9c7-394f-b38fa915ff1f",
        "NOMBRE": "SÍNDROME POSTCONCUSIONAL"
    },
    {
        "id": "b9eb05e6-27a4-9d4c-4e00-c7b4f736f2e4",
        "NOMBRE": "SÍNDROME POSTENCEFALÍTICO"
    },
    {
        "id": "6cbe26b8-06da-e05a-97f8-db82d969f827",
        "NOMBRE": "SÍNDROME POSTLAMINECTOMÍA, NO CLASIFICADO EN OTRA PARTE"
    },
    {
        "id": "0a90787b-2870-f778-19c2-3af5cc548cfb",
        "NOMBRE": "SÍNDROME POSTPOLIO"
    },
    {
        "id": "c1fb26ea-7839-9350-d64c-8cb05dd3c4f6",
        "NOMBRE": "SÍNDROME POSTROMBÓTICO"
    },
    {
        "id": "4fcc09fb-abdd-67da-c1f2-d63c83cf9870",
        "NOMBRE": "SÍNDROME RESPIRATORIO AGUDO GRAVE [SRAG]"
    },
    {
        "id": "5070944c-8eba-8753-a6a7-59cc0bf0f2ad",
        "NOMBRE": "SÍNDROME RESPIRATORIO AGUDO GRAVE [SRAG], NO ESPECIFICADO"
    },
    {
        "id": "d187e3f2-b528-20ad-ccb3-57e3c684e3e7",
        "NOMBRE": "SÍNDROME SECO [SJÖGREN]"
    },
    {
        "id": "c576c512-f3bc-371c-c3d2-0ea59375a4c3",
        "NOMBRE": "SÍNDROME URETRAL, NO ESPECIFICADO"
    },
    {
        "id": "a06a5d53-c5b1-ef93-0cf5-d0a5ffe58232",
        "NOMBRE": "SÍNDROMES APOPLÉTICOS DEL TALLO ENCEFÁLICO"
    },
    {
        "id": "72a1e39e-c924-9a3b-2e13-3ba7fe3864ac",
        "NOMBRE": "SÍNDROMES ARTERIALES PRECEREBRALES BILATERALES Y MÚLTIPLES"
    },
    {
        "id": "2f141b58-4c71-6d5d-9205-00cde6385dbf",
        "NOMBRE": "SÍNDROMES CONSECUTIVOS A LA CIRUGÍA GÁSTRICA"
    },
    {
        "id": "355c4552-ae9e-649b-0ba0-66719127169e",
        "NOMBRE": "SÍNDROMES DE ASPIRACIÓN NEONATAL"
    },
    {
        "id": "b4ace5d5-befa-417f-4f76-e37acc7a33d5",
        "NOMBRE": "SÍNDROMES DE COMPRESIÓN DE LA ARTERIA ESPINAL O VERTEBRAL ANTERIOR"
    },
    {
        "id": "4152db58-b0b0-59b6-984d-ebfd8438d91c",
        "NOMBRE": "SÍNDROMES DE MALFORMACIONES CONGÉNITAS ASOCIADAS PRINCIPALMENTE CON ESTATURA BAJA"
    },
    {
        "id": "311f96b5-042d-646d-c169-8de26a8af702",
        "NOMBRE": "SÍNDROMES DE MALFORMACIONES CONGÉNITAS CON EXCESO DE CRECIMIENTO PRECOZ"
    },
    {
        "id": "08ff8ef9-7934-581f-5234-42b936d8fb85",
        "NOMBRE": "SÍNDROMES DE MALFORMACIONES CONGÉNITAS DEBIDOS A CAUSAS EXÓGENAS CONOCIDAS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "41a35c9f-60e8-5216-a3bd-e017667f1ac1",
        "NOMBRE": "SÍNDROMES DE MALFORMACIONES CONGÉNITAS QUE AFECTAN PRINCIPALMENTE LA APARIENCIA FACIAL"
    },
    {
        "id": "d158e700-7009-8a9f-b36f-fd2b185fce76",
        "NOMBRE": "SÍNDROMES DE MALFORMACIONES CONGÉNITAS QUE AFECTAN PRINCIPALMENTE LOS MIEMBROS"
    },
    {
        "id": "9ff49ed8-3786-5d4d-acd6-590f49f9be7a",
        "NOMBRE": "SÍNDROMES DEL COMPORTAMIENTO ASOCIADOS CON ALTERACIONES FISIOLÓGICAS Y FACTORES FÍSICOS, NO ESPECIFICADOS"
    },
    {
        "id": "dc19a29b-2d43-5f9b-dd1f-ff268517c737",
        "NOMBRE": "SÍNDROMES EPILÉPTICOS ESPECIALES"
    },
    {
        "id": "1b651e22-dbab-8a13-d600-6546d03678e3",
        "NOMBRE": "SÍNDROMES MIASTÉNICOS EN ENFERMEDADES ENDOCRINAS"
    },
    {
        "id": "ac9b5f28-7db9-ea1d-db19-2e65f11c36ca",
        "NOMBRE": "SÍNDROMES MIASTÉNICOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "766d2dd3-004e-dac7-b3ea-25bff724279c",
        "NOMBRE": "SÍNDROMES MIELODISPLÁSICOS"
    },
    {
        "id": "c8101e08-0718-ad7d-e635-cb57406e216b",
        "NOMBRE": "SÍNDROMES VASCULARES ENCEFÁLICOS EN ENFERMEDADES CEREBROVASCULARES"
    },
    {
        "id": "3c881b33-3573-2c4f-63be-3b49a24bbab7",
        "NOMBRE": "SÍNDROMES VERTIGINOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9a3b2658-a3f9-f18d-80e3-71a036f90f16",
        "NOMBRE": "SINEQUIAS INTRAUTERINAS"
    },
    {
        "id": "7a32d8c6-2b56-da56-b410-b7f6927d05be",
        "NOMBRE": "SINGAMIASIS"
    },
    {
        "id": "94b3a7df-e997-c4ca-817c-9e33f37ec369",
        "NOMBRE": "SINOVITIS CREPITANTE CRÓNICA DE LA MANO Y DE LA MUÑECA"
    },
    {
        "id": "437de9c5-5aca-cbb1-e320-ca3ea88beaad",
        "NOMBRE": "SINOVITIS TRANSITORIA"
    },
    {
        "id": "c91880a9-3a3e-894f-def3-7a44bd102269",
        "NOMBRE": "SINOVITIS VELLONODULAR (PIGMENTADA)"
    },
    {
        "id": "effe1ff8-899a-3505-681f-d0bfa950ff43",
        "NOMBRE": "SINOVITIS Y TENOSINOVITIS"
    },
    {
        "id": "26d190a5-f92e-dae6-e24a-f53f05d86887",
        "NOMBRE": "SINOVITIS Y TENOSINOVITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "52f34520-7f32-9f17-ed39-099267c60977",
        "NOMBRE": "SINOVITIS Y TENOSINOVITIS, NO ESPECIFICADA"
    },
    {
        "id": "da08958c-4f82-9c5d-f378-799a01d51105",
        "NOMBRE": "SÍNTOMAS DE ABSTINENCIA POR EL USO TERAPÉUTICO DE DROGAS EN EL RECIÉN NACIDO"
    },
    {
        "id": "1acbb714-dc59-afef-8fb8-060b5d9f6761",
        "NOMBRE": "SÍNTOMAS NEONATALES DE ABSTINENCIA POR DROGADICCIÓN MATERNA"
    },
    {
        "id": "9714a07a-cc2f-2fdb-57aa-0dc0c9569e72",
        "NOMBRE": "SÍNTOMAS NO ESPECÍFICOS PROPIOS DE LA INFANCIA"
    },
    {
        "id": "428baa8c-d477-aea3-0fdc-56dd68893b68",
        "NOMBRE": "SÍNTOMAS Y SIGNOS CONCERNIENTES A LA ALIMENTACIÓN Y A LA INGESTIÓN DE LÍQUIDOS"
    },
    {
        "id": "8b41d4c7-50ae-bdfe-3a83-1aec3a1405cd",
        "NOMBRE": "SÍNTOMAS Y SIGNOS QUE INVOLUCRAN EL ESTADO EMOCIONAL"
    },
    {
        "id": "f03382cf-5a03-f555-3c13-45f98dfa2dc2",
        "NOMBRE": "SÍNTOMAS Y SIGNOS QUE INVOLUCRAN LA APARIENCIA Y EL COMPORTAMIENTO"
    },
    {
        "id": "11c910b5-e172-fc67-597f-98d13971f3a7",
        "NOMBRE": "SINUSITIS AGUDA"
    },
    {
        "id": "57dfc3e8-08fa-3604-4ad3-917bfd465ad9",
        "NOMBRE": "SINUSITIS AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "05b57dfc-48af-3837-793b-c51d0034bdcd",
        "NOMBRE": "SINUSITIS CRÓNICA"
    },
    {
        "id": "8664caa4-79b2-5acd-1170-936aba6247f6",
        "NOMBRE": "SINUSITIS CRÓNICA, NO ESPECIFICADA"
    },
    {
        "id": "69c710bb-479d-8bc1-9864-a8d28546d357",
        "NOMBRE": "SINUSITIS ESFENOIDAL AGUDA"
    },
    {
        "id": "0d2c1516-cf67-ca49-1050-697c44428484",
        "NOMBRE": "SINUSITIS ESFENOIDAL CRÓNICA"
    },
    {
        "id": "3178cdd4-94c9-6d97-7c66-c9785802d5b5",
        "NOMBRE": "SINUSITIS ETMOIDAL AGUDA"
    },
    {
        "id": "64d72e35-7384-1b53-55ed-9cc3cb03c7db",
        "NOMBRE": "SINUSITIS ETMOIDAL CRÓNICA"
    },
    {
        "id": "172b96c3-f25a-df8e-83d9-a3e9578cd65e",
        "NOMBRE": "SINUSITIS FRONTAL AGUDA"
    },
    {
        "id": "4755d066-0049-bd68-0d82-27b3ccf051b0",
        "NOMBRE": "SINUSITIS FRONTAL CRÓNICA"
    },
    {
        "id": "a91f0d40-6374-8391-f92d-39569456807a",
        "NOMBRE": "SINUSITIS MAXILAR AGUDA"
    },
    {
        "id": "0ed1c583-0468-5019-aec9-8f12a079de57",
        "NOMBRE": "SINUSITIS MAXILAR CRÓNICA"
    },
    {
        "id": "917edf22-333a-186a-588e-d2a4c05b9f56",
        "NOMBRE": "SIRINGOMIELIA Y SIRINGOBULBIA"
    },
    {
        "id": "22e6f3c2-8e6e-7e53-f4b1-43423d779015",
        "NOMBRE": "SITUS INVERSUS"
    },
    {
        "id": "5bfdc826-9b90-28a4-cbcf-802d47c40467",
        "NOMBRE": "SOBRECARGA DE LÍQUIDOS"
    },
    {
        "id": "a1630a20-293e-9954-6480-d9a447afc9a1",
        "NOMBRE": "SOBREDOSIS DE RADIACIÓN ADMINISTRADA DURANTE TERAPIA"
    },
    {
        "id": "292269e3-8211-81df-af75-7382bd0934c7",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA"
    },
    {
        "id": "be382e02-113d-708c-a4f2-3b6e3e1b3973",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "08612d29-75a7-f798-b818-b3eb3039160d",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "7b104b17-f2d3-3ee2-8c9d-6bdcd39530bf",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, CALLES Y CARRETERAS"
    },
    {
        "id": "c0ca216c-78cb-3edd-9b4a-a3e4aa76966a",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "69718679-3918-59ee-3ad9-5aa984315781",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "ab0537b3-38a3-8179-e11c-e36e3becb888",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, GRANJA"
    },
    {
        "id": "4361d027-d701-2ef3-6d78-28dbb33e8a18",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "1b535a49-15cc-5496-84f6-eb3f3e4bac51",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "2a903727-640d-8092-f827-0c5fa6f306db",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "4d009d9b-148d-098f-b1e5-252705f3d29d",
        "NOMBRE": "SOFOCACIÓN Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, VIVIENDA"
    },
    {
        "id": "fae8f53c-6058-bcc5-8711-c8539446261e",
        "NOMBRE": "SOMNOLENCIA"
    },
    {
        "id": "2b96f552-34ad-9b8b-3d40-633df11a5cde",
        "NOMBRE": "SOMNOLENCIA, ESTUPOR Y COMA"
    },
    {
        "id": "8c32cfbd-abbb-74ed-4133-1ff6fb88ca3e",
        "NOMBRE": "SONAMBULISMO"
    },
    {
        "id": "231828db-ac5b-b65e-1c2a-9c444701c51c",
        "NOMBRE": "SONIDOS INTESTINALES ANORMALES"
    },
    {
        "id": "2b2c0391-1865-dc1c-3639-acee9e0aded1",
        "NOMBRE": "SOPLO CARDÍACO, NO ESPECIFICADO"
    },
    {
        "id": "1bcad4e6-335b-49a0-2115-f5f055eade7a",
        "NOMBRE": "SOPLOS CARDÍACOS BENIGNOS O INOCENTES"
    },
    {
        "id": "bc4481e8-1a95-d99d-7c7a-6cc5cdb6e131",
        "NOMBRE": "SOPLOS Y OTROS SONIDOS CARDÍACOS"
    },
    {
        "id": "f71aad40-a518-0fb1-1426-254e310daf1b",
        "NOMBRE": "SORDOMUDEZ, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "ad9de211-4d4e-b966-8c9c-bf7ee450f0a9",
        "NOMBRE": "SOSPECHA DE GLAUCOMA"
    },
    {
        "id": "6df522d6-b724-f9b1-e5a0-38e0843d4faa",
        "NOMBRE": "STAPHYLOCOCCUS AUREUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "7bcffaa7-7383-2f09-54bc-7c2ad2ec982e",
        "NOMBRE": "STREPTOCOCCUS PNEUMONIAE COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "0b815360-2869-eed4-868a-113f06efaf8d",
        "NOMBRE": "SUBEROSIS"
    },
    {
        "id": "924582a2-8ca2-860a-a82a-69b365b7a9f4",
        "NOMBRE": "SUBINVOLUCIÓN DEL ÚTERO"
    },
    {
        "id": "a4e2b6af-aea7-82f2-9833-dac3644f941f",
        "NOMBRE": "SUBLUXACIÓN ATLANTO-AXOIDEA RECURRENTE, CON MIELOPATÍA"
    },
    {
        "id": "608931a1-622e-de86-9c6a-3bf24358b577",
        "NOMBRE": "SUBLUXACIÓN CON ESTENOSIS DEL CANAL NEURAL"
    },
    {
        "id": "436421ee-8be9-3ae9-db73-0870e730063d",
        "NOMBRE": "SUBLUXACIÓN CONGÉNITA DE LA CADERA, BILATERAL"
    },
    {
        "id": "b9bf45c3-b354-13de-cf57-591635e76b10",
        "NOMBRE": "SUBLUXACIÓN CONGÉNITA DE LA CADERA, NO ESPECIFICADA"
    },
    {
        "id": "94263257-7695-0eee-fa95-a1e8b57d7611",
        "NOMBRE": "SUBLUXACIÓN CONGÉNITA DE LA CADERA, UNILATERAL"
    },
    {
        "id": "1d30bdf1-dae1-0bd3-2af4-f549703c22af",
        "NOMBRE": "SUBLUXACIÓN DE LA SÍNFISIS (DEL PUBIS) EN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "0e5fcc55-648b-bc0d-fb90-01d403bcb2fa",
        "NOMBRE": "SUBLUXACIÓN RECIDIVANTE DE LA RÓTULA"
    },
    {
        "id": "d8e98522-c71c-9b70-7d95-d7f89e0c8f3e",
        "NOMBRE": "SUFRIMIENTO MATERNO DURANTE EL TRABAJO DE PARTO Y EL PARTO"
    },
    {
        "id": "9ce71220-bbfc-f698-4af7-72abe029fe9a",
        "NOMBRE": "SUMINISTRO INADECUADO DE AGUA POTABLE"
    },
    {
        "id": "e7a5c986-02f0-049f-8798-c033de6ca026",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO CON GRAN MULTIPARIDAD"
    },
    {
        "id": "81500c70-d78d-1b69-df65-4552bb543e26",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO CON HISTORIA DE ABORTO"
    },
    {
        "id": "bc7bcea7-c14b-b9d2-9f25-7ac9847cacf4",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO CON HISTORIA DE ESTERILIDAD"
    },
    {
        "id": "91796b6f-1c67-8916-a1ba-d69f9474f32a",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO CON HISTORIA DE INSUFICIENTE ATENCIÓN PRENATAL"
    },
    {
        "id": "2e6a3b26-db5f-1f07-18e5-9de0b51a4f53",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO CON OTRO RIESGO EN LA HISTORIA OBSTÉTRICA O REPRODUCTIVA"
    },
    {
        "id": "a9489e74-41a6-05d2-6078-45f588418662",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO DE ALTO RIESGO"
    },
    {
        "id": "6949578e-97c3-a111-4a66-f495b52966f6",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO DE ALTO RIESGO DEBIDO A PROBLEMAS SOCIALES"
    },
    {
        "id": "8c61109f-8cff-d58e-91c8-4f5506a1b8db",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO DE ALTO RIESGO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "5da4b629-7925-511e-8239-5e813247f5c2",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO NORMAL"
    },
    {
        "id": "07a03118-7410-8a1f-9163-a079cf5110fb",
        "NOMBRE": "SUPERVISIÓN DE EMBARAZO NORMAL NO ESPECIFICADO"
    },
    {
        "id": "0d6e6630-6f8f-873d-d48e-16aa7f604c22",
        "NOMBRE": "SUPERVISIÓN DE OTROS EMBARAZOS DE ALTO RIESGO"
    },
    {
        "id": "3c6ac6ac-0a82-ab5b-0fca-5125dcb9e65c",
        "NOMBRE": "SUPERVISIÓN DE OTROS EMBARAZOS NORMALES"
    },
    {
        "id": "4c717280-313a-1f47-0088-e0cb990baeef",
        "NOMBRE": "SUPERVISIÓN DE PRIMER EMBARAZO NORMAL"
    },
    {
        "id": "bfc8b435-e166-1c86-35ce-4fa674ecb386",
        "NOMBRE": "SUPERVISIÓN DE PRIMIGESTA AÑOSA"
    },
    {
        "id": "5557dc13-53df-440c-b56e-3cac3b7a68fa",
        "NOMBRE": "SUPERVISIÓN DE PRIMIGESTA MUY JOVEN"
    },
    {
        "id": "8e2b6191-0a0e-775d-f24e-09ee7f738162",
        "NOMBRE": "SUPERVISIÓN DEL USO DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)"
    },
    {
        "id": "32efaf93-3c15-f86f-ae1c-d5d4b3cd26e2",
        "NOMBRE": "SUPERVISIÓN DEL USO DE DROGAS ANTICONCEPTIVAS"
    },
    {
        "id": "bdacf9e6-fca4-9b3f-5571-63adba7a83dd",
        "NOMBRE": "SUPRESIÓN DE LA LACTANCIA"
    },
    {
        "id": "e167dd5a-045e-9c94-b79c-7875049eb747",
        "NOMBRE": "SUPRESIÓN DE LOS AUTOSOMAS, NO ESPECIFICADA"
    },
    {
        "id": "58f6d2f6-2221-d39a-2cd3-fb1a0e945477",
        "NOMBRE": "SUPRESIÓN DEL BRAZO CORTO DEL CROMOSOMA 4"
    },
    {
        "id": "6f86636c-0bc0-dd50-9504-d03f28cedd9c",
        "NOMBRE": "SUPRESIÓN DEL BRAZO CORTO DEL CROMOSOMA 5"
    },
    {
        "id": "7a215702-2092-f000-4e91-fc52602919a9",
        "NOMBRE": "SUPRESIONES CON OTROS REORDENAMIENTOS COMPLEJOS"
    },
    {
        "id": "5f38e52a-63fd-b7c1-9d19-5eb33224a2ba",
        "NOMBRE": "SUPRESIONES VISIBLES SÓLO EN LA PROMETAFASE"
    },
    {
        "id": "54481153-0601-0995-7ac1-6dc538f31255",
        "NOMBRE": "SUSPICACIA Y EVASIVIDAD MARCADAS"
    },
    {
        "id": "9663f08f-6264-cae2-3068-f02f59f0d57d",
        "NOMBRE": "TALASEMIA"
    },
    {
        "id": "c56313f2-3705-79cd-496c-eb5c1fa3e98e",
        "NOMBRE": "TALASEMIA, NO ESPECIFICADA"
    },
    {
        "id": "0f6564da-a285-e60b-8141-283a71e86403",
        "NOMBRE": "TALIPES CALCANEOVALGUS"
    },
    {
        "id": "afbb7762-2345-d300-8d7c-904ba0b01d5e",
        "NOMBRE": "TALIPES CALCANEOVARUS"
    },
    {
        "id": "675a4db7-4d39-61cb-2c22-c72abc412df3",
        "NOMBRE": "TALIPES EQUINOVARUS"
    },
    {
        "id": "5d50c5e1-7e9e-5a11-b495-2958074b853a",
        "NOMBRE": "TAQUICARDIA PAROXÍSTICA"
    },
    {
        "id": "6d88fc40-8b71-146b-a7dc-d83f60f44714",
        "NOMBRE": "TAQUICARDIA PAROXÍSTICA, NO ESPECIFICADA"
    },
    {
        "id": "115aade2-fe79-07cd-38f5-f90323b7a17e",
        "NOMBRE": "TAQUICARDIA SUPRAVENTRICULAR"
    },
    {
        "id": "d5bc8402-afc8-d541-7355-5492d7a4c1cc",
        "NOMBRE": "TAQUICARDIA VENTRICULAR"
    },
    {
        "id": "2e16fd9e-0af6-09f0-cf5c-5b16c223c4b4",
        "NOMBRE": "TAQUICARDIA, NO ESPECIFICADA"
    },
    {
        "id": "d5c3f684-567e-a8d3-85ab-2e22940f241b",
        "NOMBRE": "TAQUIPNEA TRANSITORIA DEL RECIÉN NACIDO"
    },
    {
        "id": "9c8947d7-1dff-d2b0-d7f2-6fc73109d98e",
        "NOMBRE": "TARTAMUDEZ [ESPASMOFEMIA]"
    },
    {
        "id": "cacaaa77-85ff-17d8-2904-fc0a60162b14",
        "NOMBRE": "TEJIDO ECTÓPICO EN EL PULMÓN"
    },
    {
        "id": "8e224778-c4a3-34c6-1f34-058166ba0f77",
        "NOMBRE": "TELANGIECTASIA HEMORRÁGICA HEREDITARIA"
    },
    {
        "id": "3ee6a3b1-6983-6ebc-29da-35ce72bb531a",
        "NOMBRE": "TEMBLOR ESENCIAL"
    },
    {
        "id": "7594fb23-0124-aa46-4ac7-4f6ea6a66def",
        "NOMBRE": "TEMBLOR INDUCIDO POR DROGAS"
    },
    {
        "id": "e23f7258-917e-c886-5c3c-c62be24d244b",
        "NOMBRE": "TEMBLOR NO ESPECIFICADO"
    },
    {
        "id": "0c64c8d7-7b49-9617-9daf-c2bc47b3cd18",
        "NOMBRE": "TENDENCIA A CAER, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "76854f12-91aa-99eb-536c-e0f307d2541e",
        "NOMBRE": "TENDINITIS AQUILIANA"
    },
    {
        "id": "949afaf9-6a8e-a54b-1779-eb679e1d846d",
        "NOMBRE": "TENDINITIS CALCIFICADA"
    },
    {
        "id": "dbfed9d7-9791-54c1-0a06-2292e31ca51d",
        "NOMBRE": "TENDINITIS CALCIFICANTE DEL HOMBRO"
    },
    {
        "id": "8637c41e-3c7e-896a-233a-434fc26f3ca4",
        "NOMBRE": "TENDINITIS DEL BÍCEPS"
    },
    {
        "id": "6c2b0a14-f4a8-d192-e6f8-6b96534f421f",
        "NOMBRE": "TENDINITIS DEL GLÚTEO"
    },
    {
        "id": "629dcb2b-9c59-3980-7f5d-3660040a6423",
        "NOMBRE": "TENDINITIS DEL PSOAS"
    },
    {
        "id": "3ceeecdf-5a5e-5cbf-b815-f104fd46b8c5",
        "NOMBRE": "TENDINITIS PERONEAL"
    },
    {
        "id": "1803e774-33bd-ffec-dee5-2920608e06d7",
        "NOMBRE": "TENDINITIS ROTULIANA"
    },
    {
        "id": "8d17894b-f078-2ee6-4d8b-8fa8d2d91cbb",
        "NOMBRE": "TENESMO VESICAL"
    },
    {
        "id": "497e0979-12c8-b4f8-fcbc-2224d5fed260",
        "NOMBRE": "TENIASIS"
    },
    {
        "id": "ea6a6b2c-0f63-427d-7ec4-f58453d1baf8",
        "NOMBRE": "TENIASIS DEBIDA A TAENIA SOLIUM"
    },
    {
        "id": "bcdcba42-451e-5643-7344-1a6fdc3ae57c",
        "NOMBRE": "TENIASIS, NO ESPECIFICADA"
    },
    {
        "id": "7a3f45a8-877d-f16c-af22-4cd067652d4f",
        "NOMBRE": "TENOSINOVITIS DE ESTILOIDES RADIAL [DE QUERVAIN]"
    },
    {
        "id": "92b87c80-c1c5-b824-4c95-171a20eb07fa",
        "NOMBRE": "TENSIÓN Y ESTADO DE CHOQUE EMOCIONAL, NO ESPECIFICADO"
    },
    {
        "id": "f2f11800-4146-0a3d-2ce5-3c65d94522bb",
        "NOMBRE": "TERAPIA DEL LENGUAJE"
    },
    {
        "id": "b04fbe26-cf40-7bc5-d477-2dbd112a1e02",
        "NOMBRE": "TERAPIA OCUPACIONAL Y REHABILITACIÓN VOCACIONAL, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "d3d92ed5-cc64-6963-be27-4d40407e77fd",
        "NOMBRE": "TERAPIA POR CHOQUE"
    },
    {
        "id": "aa1bc73e-3bc3-7562-4f72-843297a08543",
        "NOMBRE": "TERMINACIÓN DEL EMBARAZO, FETO Y RECIÉN NACIDO"
    },
    {
        "id": "34190624-7f35-d1c4-c9be-ec7c53706bb3",
        "NOMBRE": "TERRORES DEL SUEÑO [TERRORES NOCTURNOS]"
    },
    {
        "id": "eac231c6-df22-eee6-3c70-9408b84f2db8",
        "NOMBRE": "TESTÍCULO NO DESCENDIDO"
    },
    {
        "id": "4a77d6a3-1cee-eb3b-49be-2ccf108b07a2",
        "NOMBRE": "TESTÍCULO NO DESCENDIDO, BILATERAL"
    },
    {
        "id": "5d30c27f-79a1-8a65-f349-38e40444a960",
        "NOMBRE": "TESTÍCULO NO DESCENDIDO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "47ea2f3f-f185-af49-b27c-1487eeea5143",
        "NOMBRE": "TESTÍCULO NO DESCENDIDO, UNILATERAL"
    },
    {
        "id": "b22bc053-e98e-aaa1-de46-aaafd9897b91",
        "NOMBRE": "TETANIA"
    },
    {
        "id": "585a2fcb-85c4-bcc5-97ca-57434b6366fd",
        "NOMBRE": "TETANIA NEONATAL SIN MENCIÓN DE DEFICIENCIA DE CALCIO O DE MAGNESIO"
    },
    {
        "id": "43669463-2815-4e1e-bb47-a18f920763ea",
        "NOMBRE": "TÉTANOS NEONATAL"
    },
    {
        "id": "0de8f825-6679-9d15-8ddd-2280ab111fa7",
        "NOMBRE": "TÉTANOS OBSTÉTRICO"
    },
    {
        "id": "147bcf8b-5571-9a9b-5fa7-8820a042863c",
        "NOMBRE": "TETRALOGÍA DE FALLOT"
    },
    {
        "id": "f8a3cac5-bd34-a40d-9cc9-7ed098c82883",
        "NOMBRE": "TICS INDUCIDOS POR DROGAS Y OTROS TICS DE ORIGEN ORGÁNICO"
    },
    {
        "id": "307a271b-6343-fa49-9105-72ac063b5b36",
        "NOMBRE": "TIFUS"
    },
    {
        "id": "b84f911d-4079-2c3e-7dd2-6db74b892635",
        "NOMBRE": "TIFUS DEBIDO A RICKETTSIA TSUTSUGAMUSHI"
    },
    {
        "id": "0213db7d-c50e-95fb-b8b7-5c654d9f0e3c",
        "NOMBRE": "TIFUS DEBIDO A RICKETTSIA TYPHI"
    },
    {
        "id": "846f89b0-b210-1019-5199-4f251ab2e231",
        "NOMBRE": "TIFUS EPIDÉMICO DEBIDO A RICKETTSIA PROWAZEKII TRANSMITIDO POR PIOJOS"
    },
    {
        "id": "30abff2e-e6bf-3daa-43d8-c437d48ef1e8",
        "NOMBRE": "TIFUS RECRUDESCENTE [ENFERMEDAD DE BRILL]"
    },
    {
        "id": "451cfdb0-3b43-9efe-bbd7-53ed9461b74a",
        "NOMBRE": "TIFUS, NO ESPECIFICADO"
    },
    {
        "id": "300a6cc8-8357-5aee-4e6d-ce2445ead5e1",
        "NOMBRE": "TIMPANOSCLEROSIS"
    },
    {
        "id": "4f82c5fb-c515-67f5-d3a5-bfd9d8de138f",
        "NOMBRE": "TINNITUS"
    },
    {
        "id": "96c73446-fb32-36a7-567f-760802f6cddd",
        "NOMBRE": "TIÑA DE LA BARBA Y DEL CUERO CABELLUDO"
    },
    {
        "id": "07c65bc6-8dad-0306-7f10-7e7d71e29f4d",
        "NOMBRE": "TIÑA DE LA MANO"
    },
    {
        "id": "f885aee7-c05d-1c30-0c60-d83fb078fae8",
        "NOMBRE": "TIÑA DE LAS UÑAS"
    },
    {
        "id": "74e4461e-ff1c-c9b7-12f5-2f1940c1448f",
        "NOMBRE": "TIÑA DEL CUERPO [TINEA CORPORIS]"
    },
    {
        "id": "15aae742-c89c-a425-9fad-2dc29aff8a38",
        "NOMBRE": "TIÑA DEL PIE [TINEA PEDIS]"
    },
    {
        "id": "d339e0bc-7007-8723-6b27-4abb1093f45b",
        "NOMBRE": "TIÑA IMBRICADA [TINEA IMBRICATA]"
    },
    {
        "id": "508f9ad8-c9d4-7114-5901-39f5aa192bf7",
        "NOMBRE": "TIÑA INGUINAL [TINEA CRURIS]"
    },
    {
        "id": "92aafe3d-b3c4-acb6-2ad7-282555f8fb3b",
        "NOMBRE": "TIÑA NEGRA"
    },
    {
        "id": "5f89dbcd-f989-4fc2-d8f2-5beb8af190a8",
        "NOMBRE": "TIROIDITIS"
    },
    {
        "id": "796485a9-4fdd-1ec1-d5e4-0c7d3aab2e93",
        "NOMBRE": "TIROIDITIS AGUDA"
    },
    {
        "id": "0d7c89cf-1074-82e4-8da8-28b1cc5c71de",
        "NOMBRE": "TIROIDITIS AUTOINMUNE"
    },
    {
        "id": "e5813c73-7538-01f8-bea4-7dd8730769ff",
        "NOMBRE": "TIROIDITIS CRÓNICA CON TIROTOXICOSIS TRANSITORIA"
    },
    {
        "id": "2e2b5097-77d3-c1ae-1cdb-f63ed8a4dc57",
        "NOMBRE": "TIROIDITIS INDUCIDA POR DROGAS"
    },
    {
        "id": "6ba4828f-79d5-946c-059f-b1388d6eb005",
        "NOMBRE": "TIROIDITIS POSTPARTO"
    },
    {
        "id": "a9dcf874-15ed-3225-c998-98ccd6c4e89a",
        "NOMBRE": "TIROIDITIS SUBAGUDA"
    },
    {
        "id": "9cd55620-a55c-f525-c16f-75e8b7d02b72",
        "NOMBRE": "TIROIDITIS, NO ESPECIFICADA"
    },
    {
        "id": "5f71e9d6-6085-4fcd-a36a-33acd337b139",
        "NOMBRE": "TIROSINEMIA TRANSITORIA DEL RECIÉN NACIDO"
    },
    {
        "id": "dbc441a1-9204-346a-c16f-9b20e485f3a2",
        "NOMBRE": "TIROTOXICOSIS [HIPERTIROIDISMO]"
    },
    {
        "id": "1de56d8a-ff0e-62c5-6aeb-5feac9beb243",
        "NOMBRE": "TIROTOXICOSIS CON BOCIO DIFUSO"
    },
    {
        "id": "6524ba06-a863-c48b-158c-45314626decf",
        "NOMBRE": "TIROTOXICOSIS CON BOCIO MULTINODULAR TÓXICO"
    },
    {
        "id": "ca9791c6-d8df-e22d-2caf-6f19c737f2d0",
        "NOMBRE": "TIROTOXICOSIS CON NÓDULO SOLITARIO TIROIDEO TÓXICO"
    },
    {
        "id": "ea54118e-a5dc-94f1-8ef6-05369f097e25",
        "NOMBRE": "TIROTOXICOSIS FACTICIA"
    },
    {
        "id": "d36fe3a5-dd36-81cd-c4ee-17e0233d93ec",
        "NOMBRE": "TIROTOXICOSIS POR TEJIDO TIROIDEO ECTÓPICO"
    },
    {
        "id": "317f5c76-f7be-9348-6582-6aa6e0a5f83b",
        "NOMBRE": "TIROTOXICOSIS, NO ESPECIFICADA"
    },
    {
        "id": "d3c22a7a-34b5-35f7-5294-9b788cc795d3",
        "NOMBRE": "TITULACIÓN ELEVADA DE ANTICUERPOS"
    },
    {
        "id": "17e6f674-8920-cbc5-1331-f2c409cac5f0",
        "NOMBRE": "TÓRAX AZOTADO"
    },
    {
        "id": "ac0b0beb-979d-1cdc-3148-2489189d52c3",
        "NOMBRE": "TÓRAX EN QUILLA"
    },
    {
        "id": "cc06c973-9acc-b772-781d-cc4955ccc8fe",
        "NOMBRE": "TÓRAX EXCAVADO"
    },
    {
        "id": "ecb9c2f6-5a6e-072d-1f55-22454056481b",
        "NOMBRE": "TORSIÓN CONGÉNITA DEL OVARIO"
    },
    {
        "id": "46e7ff12-3ec4-7f93-a424-4fe2a1564a27",
        "NOMBRE": "TORSIÓN DE OVARIO, PEDÍCULO DE OVARIO Y TROMPA DE FALOPIO"
    },
    {
        "id": "60fc452b-8fac-aa60-1c62-20e88d2b8b43",
        "NOMBRE": "TORSIÓN DEL TESTÍCULO"
    },
    {
        "id": "01ed3d28-b601-cf9b-6a37-b5c2b5fa58c7",
        "NOMBRE": "TORSIÓN Y ESTRECHEZ DEL URÉTER SIN HIDRONEFROSIS"
    },
    {
        "id": "26bea925-1ed3-5a34-49c1-ccfd41afe35a",
        "NOMBRE": "TORTÍCOLIS"
    },
    {
        "id": "dc9f4c4c-f8ba-58ef-1977-aaee73915b1c",
        "NOMBRE": "TORTÍCOLIS ESPASMÓDICA"
    },
    {
        "id": "4b095781-ead9-bb3a-4341-d8e18c07ab66",
        "NOMBRE": "TOS"
    },
    {
        "id": "0fe2ce99-5305-5907-9a60-cd706a836d7b",
        "NOMBRE": "TOS FERINA [TOS CONVULSIVA]"
    },
    {
        "id": "37401fc3-4750-bfe8-5dc6-09a4dfab3601",
        "NOMBRE": "TOS FERINA DEBIDA A BORDETELLA PARAPERTUSSIS"
    },
    {
        "id": "d8fde31f-b817-11a5-5b4c-c362fce81e6a",
        "NOMBRE": "TOS FERINA DEBIDA A BORDETELLA PERTUSSIS"
    },
    {
        "id": "1714390e-617c-39a2-1aea-97fe5827d2a2",
        "NOMBRE": "TOS FERINA DEBIDA A OTRAS ESPECIES DE BORDETELLA"
    },
    {
        "id": "d7410614-d33c-5cfa-3e19-65ad4badd2fc",
        "NOMBRE": "TOS FERINA, NO ESPECIFICADA"
    },
    {
        "id": "b0d2fd3a-0ef9-4baf-c71e-b510a578c969",
        "NOMBRE": "TOXOPLASMOSIS"
    },
    {
        "id": "2171e2d8-8c5b-b479-60f6-167f956e65aa",
        "NOMBRE": "TOXOPLASMOSIS CON OTRO ÓRGANO AFECTADO"
    },
    {
        "id": "441e778e-b1a9-274e-daf8-2788296be681",
        "NOMBRE": "TOXOPLASMOSIS CONGÉNITA"
    },
    {
        "id": "176c0450-880b-a9ff-c638-fce354a6dcae",
        "NOMBRE": "TOXOPLASMOSIS PULMONAR"
    },
    {
        "id": "ccfc7380-434f-0518-8be1-40c79596e369",
        "NOMBRE": "TOXOPLASMOSIS, NO ESPECIFICADA"
    },
    {
        "id": "78ddc174-bf9c-d301-633b-71dcc892ef80",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANOMALÍA PELVIANA NO ESPECIFICADA"
    },
    {
        "id": "de1df91b-1fa9-f93f-6020-aa1575be1d79",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANOMALÍAS DE LOS ÓRGANOS PELVIANOS MATERNOS"
    },
    {
        "id": "cb2c6081-df3a-2371-9104-91e0c8cddcb2",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANORMALIDAD DE LA PELVIS MATERNA"
    },
    {
        "id": "97f915f9-8fac-e278-2697-2cfbc0e37738",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DEFORMIDAD DE LA PELVIS"
    },
    {
        "id": "25f46b0f-dc86-ecae-afb3-4673e7203955",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DESPROPORCIÓN FETOPELVIANA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c9177529-daf6-5a06-d091-1403cc83cc28",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISMINUCIÓN DEL ESTRECHO INFERIOR DE LA PELVIS"
    },
    {
        "id": "9f8338e7-c7bb-74f6-1017-16e385db42d8",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISMINUCIÓN DEL ESTRECHO SUPERIOR DE LA PELVIS"
    },
    {
        "id": "a4b0fd79-0266-3f6d-66f7-f17476e8f6b3",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA DE HOMBROS"
    },
    {
        "id": "7e53bdf0-7802-0aca-78b4-dae858665bc3",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA GEMELAR"
    },
    {
        "id": "60f307d0-30d1-5526-da68-dd620d7d5c21",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA POR FETO INUSUALMENTE GRANDE"
    },
    {
        "id": "710c5c9f-d645-3228-2dcf-8d7f1ac73b6a",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ESTRECHEZ GENERAL DE LA PELVIS"
    },
    {
        "id": "39011f5e-6d82-ed03-f0d4-ff61a1132d94",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A MALA POSICIÓN Y PRESENTACIÓN ANORMAL DEL FETO"
    },
    {
        "id": "61b50f54-e5e3-09f5-8627-58828e452290",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS ANOMALÍAS PELVIANAS MATERNAS"
    },
    {
        "id": "d179a033-7b85-7459-66fd-5c8c2b8a9abf",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS ANORMALIDADES DEL FETO"
    },
    {
        "id": "ff806329-33b3-2b8a-5009-2e2ebe41f09b",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS PRESENTACIONES ANORMALES DEL FETO"
    },
    {
        "id": "54205ec7-b014-deef-63e2-d251677bbe77",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACIÓN ANORMAL DEL FETO NO ESPECIFICADA"
    },
    {
        "id": "4184427c-ebfa-7202-86dc-82ba28cd30bc",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACIÓN COMPUESTA"
    },
    {
        "id": "d0c61614-9c04-602b-9b19-1e67ac612d88",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACIÓN DE CARA"
    },
    {
        "id": "a945dac0-53e1-9fae-6427-3b736d01185a",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACIÓN DE FRENTE"
    },
    {
        "id": "b31a68bc-5fb5-090f-93d6-597c4f36890d",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACIÓN DE HOMBRO"
    },
    {
        "id": "23a1d5aa-1474-b214-848f-a7b23c9bd967",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACIÓN DE NALGAS"
    },
    {
        "id": "2dfa774b-3835-e2d1-6f3f-f72eec897100",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ROTACIÓN INCOMPLETA DE LA CABEZA FETAL"
    },
    {
        "id": "e0d06877-51e6-c540-b126-a0e3c785f76b",
        "NOMBRE": "TRABAJO DE PARTO OBSTRUIDO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c966dc5f-4daa-c4de-17a0-aec02d29f622",
        "NOMBRE": "TRABAJO DE PARTO PRECIPITADO"
    },
    {
        "id": "c9fb7e02-0114-d32c-649e-37cf676a2e51",
        "NOMBRE": "TRABAJO DE PARTO PREMATURO ESPONTÁNEO CON PARTO A TÉRMINO"
    },
    {
        "id": "5240503a-b083-ba76-5329-79aca35e0558",
        "NOMBRE": "TRABAJO DE PARTO PREMATURO ESPONTÁNEO CON PARTO PREMATURO"
    },
    {
        "id": "72b4849d-7225-77a9-5cd5-177ba23637ce",
        "NOMBRE": "TRABAJO DE PARTO PREMATURO SIN PARTO"
    },
    {
        "id": "ff1c6517-a7eb-b14b-4dc9-87cfbdcfbd90",
        "NOMBRE": "TRABAJO DE PARTO PREMATURO Y PARTO"
    },
    {
        "id": "2efdc51f-09e7-5cf6-8a54-287b4b7278d7",
        "NOMBRE": "TRABAJO DE PARTO PROLONGADO"
    },
    {
        "id": "67ec6d62-7a81-80da-94f4-87b9b008bfd1",
        "NOMBRE": "TRABAJO DE PARTO PROLONGADO, NO ESPECIFICADO"
    },
    {
        "id": "0f7cef1b-7f2a-70f6-564b-90d5cd4e49d2",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR ANOMALÍA DE LA FRECUENCIA CARDÍACA FETAL"
    },
    {
        "id": "ab784044-47b3-4524-44a8-f1329438b291",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR ANOMALÍA DE LA FRECUENCIA CARDÍACA FETAL ASOCIADA CON PRESENCIA DE MECONIO EN EL LÍQUIDO AMNIÓTICO"
    },
    {
        "id": "7f420bbc-cc8e-d1e7-90a6-fdbaa7b21428",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR CIRCULAR PERICERVICAL DEL CORDÓN, CON COMPRESIÓN"
    },
    {
        "id": "98b02f09-97f0-f373-7c53-fde8641bef3c",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR CORDÓN UMBILICAL CORTO"
    },
    {
        "id": "345b9e88-a1ed-6c66-3db4-58ec29f044a9",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR EVIDENCIA BIOQUÍMICA DE SUFRIMIENTO FETAL"
    },
    {
        "id": "d0f9ed62-b1f6-ab23-f0bd-123ab549f43e",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR HEMORRAGIA INTRAPARTO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "79bda3ba-970d-1c42-c728-c0c0b4d48d36",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR LA PRESENCIA DE MECONIO EN EL LÍQUIDO AMNIÓTICO"
    },
    {
        "id": "92b74de6-5c15-3329-384f-96e629c8eaba",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR LESIÓN VASCULAR DEL CORDÓN"
    },
    {
        "id": "2a18585e-92f2-b2c0-ae7b-df24fbe0b580",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTRAS EVIDENCIAS DE SUFRIMIENTO FETAL"
    },
    {
        "id": "abd883cd-0a4c-dd51-3c27-394cc198a2cd",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTROS ENREDOS DEL CORDÓN UMBLICAL CON COMPRESIÓN"
    },
    {
        "id": "5d8a0edf-3c4d-a5fe-e03f-1d070d0fd10d",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTROS PROBLEMAS DEL CORDÓN UMBILICAL"
    },
    {
        "id": "cdaa0b69-60d3-8551-5c2c-d5930ae884fd",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROBLEMAS DEL CORDÓN UMBILICAL"
    },
    {
        "id": "0a68ab7b-fc72-6941-12dd-9e4a794b04a5",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROBLEMAS NO ESPECIFICADOS DEL CORDÓN UMBILICAL"
    },
    {
        "id": "5f4e563a-18bc-3644-e971-be3f8041fb01",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROLAPSO DEL CORDÓN UMBILICAL"
    },
    {
        "id": "c6de07dd-9983-5300-39f5-d6f41e877fa7",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR SUFRIMIENTO FETAL"
    },
    {
        "id": "71e7212f-eed6-d3db-b8bf-2d14c5d013af",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR SUFRIMIENTO FETAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "2c070040-e9be-9682-4b49-0fccca26ebfe",
        "NOMBRE": "TRABAJO DE PARTO Y PARTO COMPLICADOS POR VASA PREVIA"
    },
    {
        "id": "2ec5cae2-154a-4ce0-974e-379b65212218",
        "NOMBRE": "TRACOMA"
    },
    {
        "id": "55ec41df-3678-ef23-5b68-27b615b5d15b",
        "NOMBRE": "TRACOMA, NO ESPECIFICADO"
    },
    {
        "id": "539987c0-55fd-7633-d083-6af4eba40664",
        "NOMBRE": "TRANSEXUALISMO"
    },
    {
        "id": "8e8f9a61-a9ef-8513-34bb-8bff85ca02c8",
        "NOMBRE": "TRANSFUSIÓN DE SANGRE SIN DIAGNÓSTICO INFORMADO"
    },
    {
        "id": "da539b55-6983-a0be-b76a-f0e612e5d7f6",
        "NOMBRE": "TRANSLOCACIÓN EQUILIBRADA E INSERCIÓN EN INDIVIDUO NORMAL"
    },
    {
        "id": "ae8d8d52-8321-afb0-da48-387fb181aa00",
        "NOMBRE": "TRANSPOSICIÓN DE LOS GRANDES VASOS EN VENTRÍCULO DERECHO"
    },
    {
        "id": "e56fc6c7-07a2-3a42-7182-738d290881ee",
        "NOMBRE": "TRANSPOSICIÓN DE LOS GRANDES VASOS EN VENTRÍCULO IZQUIERDO"
    },
    {
        "id": "a375783f-3359-908c-ada7-453f156dfe52",
        "NOMBRE": "TRANSVESTISMO DE ROL DUAL"
    },
    {
        "id": "1e0024f6-17c6-0556-794c-a6785675c135",
        "NOMBRE": "TRANSVESTISMO FETICHISTA"
    },
    {
        "id": "26364bac-197b-d0e1-0f92-a9f9b18be72a",
        "NOMBRE": "TRAQUEÍTIS AGUDA"
    },
    {
        "id": "5918d1a5-e1f6-36c1-316a-d509d50d48b0",
        "NOMBRE": "TRAQUEOMALACIA CONGÉNITA"
    },
    {
        "id": "ec17d44b-1093-3d62-fa64-f28e720bf65c",
        "NOMBRE": "TRAQUEOSTOMÍA"
    },
    {
        "id": "f5fe3c67-44f3-62c1-e281-ea850e9502fb",
        "NOMBRE": "TRASPLANTE DE CORAZÓN"
    },
    {
        "id": "5b79012f-3e91-413f-68c7-27116cf57525",
        "NOMBRE": "TRASPLANTE DE CORAZÓN Y PULMONES"
    },
    {
        "id": "b3dae420-ac4d-ab5b-a598-277ebbe89663",
        "NOMBRE": "TRASPLANTE DE CÓRNEA"
    },
    {
        "id": "d6961ac6-5dbc-15c7-42f9-501d902f5c27",
        "NOMBRE": "TRASPLANTE DE HÍGADO"
    },
    {
        "id": "168b0da2-9895-fa48-9024-3f2b07bbf155",
        "NOMBRE": "TRASPLANTE DE HUESO"
    },
    {
        "id": "737f237f-d5a7-8e02-20eb-cac5fe6d3c86",
        "NOMBRE": "TRASPLANTE DE PELO"
    },
    {
        "id": "ed049964-27b7-50b7-0718-099a430043c5",
        "NOMBRE": "TRASPLANTE DE PIEL"
    },
    {
        "id": "5008973b-2b38-b6fc-d8ad-05b18ba45e71",
        "NOMBRE": "TRASPLANTE DE PULMÓN"
    },
    {
        "id": "693d93bc-b34b-2106-61f3-3da60d80a748",
        "NOMBRE": "TRASPLANTE DE RIÑÓN"
    },
    {
        "id": "f450d5d8-5a01-df81-91dc-023381ae4eac",
        "NOMBRE": "TRASTORNO ACANTOLÍTICO, NO ESPECIFICADO"
    },
    {
        "id": "8eeaf17c-7a9b-0b59-9354-74b7117016b3",
        "NOMBRE": "TRASTORNO ADRENOGENITAL, NO ESPECIFICADO"
    },
    {
        "id": "d38133b4-1eb4-6aaf-8728-b1ce60fd8951",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR"
    },
    {
        "id": "13b8c257-7e92-ddce-edef-b93d30c42735",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, ACTUALMENTE EN REMISIÓN"
    },
    {
        "id": "7523842a-864e-ea2a-0229-300a7846e63c",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE CON SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "50b23bad-5d1b-38ef-0c49-8e2642b7f328",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "f3ba97e5-4c01-dbb1-2a93-0812da5d2c60",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO PRESENTE LEVE O MODERADO"
    },
    {
        "id": "cba692a4-d314-66af-96af-f7a6d2a0e69f",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, EPISODIO HIPOMANÍACO PRESENTE"
    },
    {
        "id": "c5f69611-59b3-6582-616e-8489d420c22a",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANÍACO PRESENTE CON SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "ba3f2e9b-b197-d573-96e0-bd60c884b36c",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANÍACO PRESENTE SIN SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "a5b8f88b-0d55-655e-ada5-4c488ec30f90",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, EPISODIO MIXTO PRESENTE"
    },
    {
        "id": "fd5dc94b-61c6-3c49-686a-1f00eabe8d1f",
        "NOMBRE": "TRASTORNO AFECTIVO BIPOLAR, NO ESPECIFICADO"
    },
    {
        "id": "0e63c260-34bf-d615-b2f5-0db0b2860eab",
        "NOMBRE": "TRASTORNO ANANCÁSTICO DE LA PERSONALIDAD"
    },
    {
        "id": "4539137d-469d-26a8-0f20-86c3f90fa1ba",
        "NOMBRE": "TRASTORNO ARTICULAR, NO ESPECIFICADO"
    },
    {
        "id": "c0c54dce-d575-c212-5a55-7c37d3b3925c",
        "NOMBRE": "TRASTORNO ASOCIAL DE LA PERSONALIDAD"
    },
    {
        "id": "cb454346-3d60-6d1f-7eba-ebc17f5e9011",
        "NOMBRE": "TRASTORNO ATRÓFICO DE LA PIEL, NO ESPECIFICADO"
    },
    {
        "id": "408040e5-426d-9a1e-dd00-c6f6f62c8e3a",
        "NOMBRE": "TRASTORNO CARDIOVASCULAR NO ESPECIFICADO, ORIGINADO EN EL PERÍODO PERINATAL"
    },
    {
        "id": "e1b34796-52e4-b93c-2f6d-4d1cefc0cbdd",
        "NOMBRE": "TRASTORNO CATATÓNICO, ORGÁNICO"
    },
    {
        "id": "0ca0b1d3-905d-54b2-a5da-f805fb938e78",
        "NOMBRE": "TRASTORNO COGNOSCITIVO LEVE"
    },
    {
        "id": "a0a2e243-f9ee-2007-9424-384516e2374f",
        "NOMBRE": "TRASTORNO DE ANSIEDAD DE SEPARACIÓN EN LA NIÑEZ"
    },
    {
        "id": "0d055ef2-edb9-1125-f741-2e61e923122a",
        "NOMBRE": "TRASTORNO DE ANSIEDAD FÓBICA EN LA NIÑEZ"
    },
    {
        "id": "58e902c6-f553-2e1d-d635-bfb88f42ef77",
        "NOMBRE": "TRASTORNO DE ANSIEDAD GENERALIZADA"
    },
    {
        "id": "57dae04e-2d50-4ecd-dff0-5a920ae1aaeb",
        "NOMBRE": "TRASTORNO DE ANSIEDAD SOCIAL EN LA NIÑEZ"
    },
    {
        "id": "7959e30a-816b-dbca-7fb0-d1ac96e06355",
        "NOMBRE": "TRASTORNO DE ANSIEDAD, NO ESPECIFICADO"
    },
    {
        "id": "9b877b0e-53bb-8712-7c8b-140753ffbbb7",
        "NOMBRE": "TRASTORNO DE ANSIEDAD, ORGÁNICO"
    },
    {
        "id": "5a650590-cb6f-080b-a1bb-77f80c21d207",
        "NOMBRE": "TRASTORNO DE ARTERIAS Y ARTERIOLAS, NO ESPECIFICADO"
    },
    {
        "id": "ca0248ce-4b1f-19b4-2ca2-d58959514797",
        "NOMBRE": "TRASTORNO DE DISCO CERVICAL CON MIELOPATÍA"
    },
    {
        "id": "9d3477bc-b181-53f3-30f3-c6d41ee92478",
        "NOMBRE": "TRASTORNO DE DISCO CERVICAL CON RADICULOPATÍA"
    },
    {
        "id": "58e76adf-46f7-1394-2325-52f1f732af04",
        "NOMBRE": "TRASTORNO DE DISCO CERVICAL, NO ESPECIFICADO"
    },
    {
        "id": "aa682e18-8b3b-e1ba-9a03-b54f34c1a6c5",
        "NOMBRE": "TRASTORNO DE DOLOR PERSISTENTE SOMATOMORFO"
    },
    {
        "id": "8cfb0be0-66d8-392e-f4f6-96278610d3a8",
        "NOMBRE": "TRASTORNO DE ESTRÉS POSTRAUMÁTICO"
    },
    {
        "id": "750ffc43-f147-24c1-f4de-84dc457fcf79",
        "NOMBRE": "TRASTORNO DE LA CONDUCCIÓN, NO ESPECIFICADO"
    },
    {
        "id": "72d1f130-14ce-e722-c742-56a8952fdd91",
        "NOMBRE": "TRASTORNO DE LA CONDUCTA INSOCIABLE"
    },
    {
        "id": "ebc871b6-c191-7308-ac8e-31b3f7fa601d",
        "NOMBRE": "TRASTORNO DE LA CONDUCTA LIMITADO AL CONTEXTO FAMILIAR"
    },
    {
        "id": "0d0ee6a4-abf3-68aa-2ef8-7c0f0c11374a",
        "NOMBRE": "TRASTORNO DE LA CONDUCTA SOCIABLE"
    },
    {
        "id": "ab05fc64-088d-d075-e5d0-efd0f70a334c",
        "NOMBRE": "TRASTORNO DE LA CONDUCTA, NO ESPECIFICADO"
    },
    {
        "id": "f55df1f5-2873-8f06-beb6-455cc2e8ed17",
        "NOMBRE": "TRASTORNO DE LA CONJUNTIVA, NO ESPECIFICADO"
    },
    {
        "id": "e790fc45-eb79-ba5d-e8bc-ab52ef25bcdd",
        "NOMBRE": "TRASTORNO DE LA CONTINUIDAD DEL HUESO, NO ESPECIFICADO"
    },
    {
        "id": "9f8c6332-0dc7-ca44-4696-aaf9cc24471f",
        "NOMBRE": "TRASTORNO DE LA CÓRNEA, NO ESPECIFICADO"
    },
    {
        "id": "80fb20b8-99f6-ee81-b0ca-5a03e190751d",
        "NOMBRE": "TRASTORNO DE LA COROIDES, NO ESPECIFICADO"
    },
    {
        "id": "dff224c6-8f40-3703-444e-9f7242629cc7",
        "NOMBRE": "TRASTORNO DE LA DENSIDAD Y DE LA ESTRUCTURA ÓSEAS, NO ESPECIFICADO"
    },
    {
        "id": "91a9a084-4b27-7c82-dfda-b102a83b40a5",
        "NOMBRE": "TRASTORNO DE LA ELIMINACIÓN TRANSEPIDÉRMICA, NO ESPECIFICADO"
    },
    {
        "id": "d1fe3d3b-f4db-9a7f-ede7-0512a1cb5cd1",
        "NOMBRE": "TRASTORNO DE LA ESCLERÓTICA, NO ESPECIFICADO"
    },
    {
        "id": "3b3dbbd8-8fb3-0ffe-5919-1f1e5080923d",
        "NOMBRE": "TRASTORNO DE LA FUNCIÓN VESTIBULAR, NO ESPECIFICADO"
    },
    {
        "id": "0f1c1f0c-d072-db4f-270d-15d8fc7b0f7e",
        "NOMBRE": "TRASTORNO DE LA GLÁNDULA HIPÓFISIS, NO ESPECIFICADO"
    },
    {
        "id": "169e8822-c1bd-6b69-ad91-072f9dde509c",
        "NOMBRE": "TRASTORNO DE LA GLÁNDULA PARATIROIDES, NO ESPECIFICADO"
    },
    {
        "id": "0ad334f9-9aaf-48b3-1fb7-854cbcf9f65b",
        "NOMBRE": "TRASTORNO DE LA GLÁNDULA SUPRARRENAL, NO ESPECIFICADO"
    },
    {
        "id": "205ac583-2fc3-2b0b-6660-3e5a24e92155",
        "NOMBRE": "TRASTORNO DE LA GLÁNDULA TIROIDES, NO ESPECIFICADO"
    },
    {
        "id": "65027f4b-9488-68da-3bb0-1680f6466072",
        "NOMBRE": "TRASTORNO DE LA IDENTIDAD DE GÉNERO EN LA NIÑEZ"
    },
    {
        "id": "29837b30-a45d-c3b6-c90b-a4c262b13f29",
        "NOMBRE": "TRASTORNO DE LA IDENTIDAD DE GÉNERO, NO ESPECIFICADO"
    },
    {
        "id": "593ae7cf-1c46-ca35-6f25-34721ab23a80",
        "NOMBRE": "TRASTORNO DE LA INGESTIÓN ALIMENTARIA EN LA INFANCIA Y LA NIÑEZ"
    },
    {
        "id": "61a19f78-8ba5-9435-5fdb-6f209cf090cd",
        "NOMBRE": "TRASTORNO DE LA INGESTIÓN DE ALIMENTOS, NO ESPECIFICADO"
    },
    {
        "id": "9ac59057-569b-2f13-c4a8-f523bb9164f2",
        "NOMBRE": "TRASTORNO DE LA MADURACIÓN SEXUAL"
    },
    {
        "id": "73d68ab5-3ae5-3feb-4cd2-aef8bdb93655",
        "NOMBRE": "TRASTORNO DE LA MAMA, NO ESPECIFICADO"
    },
    {
        "id": "112843c3-2a01-c259-2b78-e4edbbe0fb3d",
        "NOMBRE": "TRASTORNO DE LA MEMBRANA TIMPÁNICA, NO ESPECIFICADO"
    },
    {
        "id": "e52a1493-6f53-9600-6e97-e2376fd4daee",
        "NOMBRE": "TRASTORNO DE LA ÓRBITA, NO ESPECIFICADO"
    },
    {
        "id": "72a785ef-e249-58d7-fb62-269336f30e13",
        "NOMBRE": "TRASTORNO DE LA PERSONALIDAD ANSIOSA (EVASIVA, ELUSIVA)"
    },
    {
        "id": "a4898de3-70eb-be52-1d0b-8c425276a61a",
        "NOMBRE": "TRASTORNO DE LA PERSONALIDAD DEPENDIENTE"
    },
    {
        "id": "49f217f7-ae46-f776-00b9-5621ebfcf018",
        "NOMBRE": "TRASTORNO DE LA PERSONALIDAD EMOCIONALMENTE INESTABLE"
    },
    {
        "id": "ec767765-5a74-d7a0-a80c-f52f46c81951",
        "NOMBRE": "TRASTORNO DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS, NO ESPECIFICADO"
    },
    {
        "id": "ebf10e83-51b5-b30a-192c-55f0cc7b98c8",
        "NOMBRE": "TRASTORNO DE LA PERSONALIDAD, NO ESPECIFICADO"
    },
    {
        "id": "6f013a56-140f-ae6e-44a7-bfb49d5cc034",
        "NOMBRE": "TRASTORNO DE LA PERSONALIDAD, ORGÁNICO"
    },
    {
        "id": "918372a0-c27f-1aeb-3ae6-e56e58990d81",
        "NOMBRE": "TRASTORNO DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO, NO ESPECIFICADO"
    },
    {
        "id": "fb05dfc5-12bd-59fe-3202-2651b55c8fdb",
        "NOMBRE": "TRASTORNO DE LA PIGMENTACIÓN, NO ESPECIFICADO"
    },
    {
        "id": "f98da424-1872-2e1f-64b0-9ea660dc4964",
        "NOMBRE": "TRASTORNO DE LA PLACENTA, NO ESPECIFICADO"
    },
    {
        "id": "9cfb8bf6-65b4-8dcf-dc1a-4ca03f22bdfa",
        "NOMBRE": "TRASTORNO DE LA PREFERENCIA SEXUAL, NO ESPECIFICADO"
    },
    {
        "id": "bf685a20-14f5-2bca-cbb2-94ff7f5d825d",
        "NOMBRE": "TRASTORNO DE LA PRÓSTATA, NO ESPECIFICADO"
    },
    {
        "id": "8daf4bdf-7948-6440-bdf4-d0a4fb84d05e",
        "NOMBRE": "TRASTORNO DE LA PUBERTAD, NO ESPECIFICADO"
    },
    {
        "id": "d000c613-de3a-42de-c799-366a75980a0c",
        "NOMBRE": "TRASTORNO DE LA RAÍZ Y PLEXOS NERVIOSOS, NO ESPECIFICADO"
    },
    {
        "id": "0df422c7-b483-6181-c6ef-7a4c44b397d9",
        "NOMBRE": "TRASTORNO DE LA RECEPCIÓN DEL LENGUAJE"
    },
    {
        "id": "70f2f502-611c-3585-874d-3d1dc8ffb4d2",
        "NOMBRE": "TRASTORNO DE LA REFRACCIÓN, NO ESPECIFICADO"
    },
    {
        "id": "b26024c2-8eb2-96e4-4c09-49487bb53663",
        "NOMBRE": "TRASTORNO DE LA RELACIÓN SEXUAL"
    },
    {
        "id": "4b275e25-0b41-f52b-5118-8fa79788f526",
        "NOMBRE": "TRASTORNO DE LA RETINA, NO ESPECIFICADO"
    },
    {
        "id": "e47fbbdc-43bc-6a1a-4a2d-9ac66eef801f",
        "NOMBRE": "TRASTORNO DE LA RÓTULA, NO ESPECIFICADO"
    },
    {
        "id": "0064119a-899c-7e7b-c848-62c8c2833d4a",
        "NOMBRE": "TRASTORNO DE LA TROMPA DE EUSTAQUIO, NO ESPECIFICADO"
    },
    {
        "id": "f36c6de1-bdf9-ab28-9f34-78f23c5927f2",
        "NOMBRE": "TRASTORNO DE LA UÑA, NO ESPECIFICADO"
    },
    {
        "id": "39efe7c1-f319-c1de-1ede-eb3a688d48de",
        "NOMBRE": "TRASTORNO DE LA URETRA, NO ESPECIFICADO"
    },
    {
        "id": "8fb09308-a158-8605-3ed4-23da82db0bae",
        "NOMBRE": "TRASTORNO DE LA VÁLVULA AÓRTICA, NO ESPECIFICADO"
    },
    {
        "id": "6ca1e776-0ce1-88fd-0ae5-404fb62f326e",
        "NOMBRE": "TRASTORNO DE LA VÁLVULA PULMONAR, NO ESPECIFICADO"
    },
    {
        "id": "146bec1c-bdc9-7de4-62d1-8c3a07d2afbe",
        "NOMBRE": "TRASTORNO DE LA VEJIGA, NO ESPECIFICADO"
    },
    {
        "id": "2fa6147b-cae6-cc06-1030-2717d54c5331",
        "NOMBRE": "TRASTORNO DE LABILIDAD EMOCIONAL [ASTÉNICO], ORGÁNICO"
    },
    {
        "id": "6a435d83-05d0-4ea2-610a-10884e0244cf",
        "NOMBRE": "TRASTORNO DE LAS VÍAS ÓPTICAS, NO ESPECIFICADO"
    },
    {
        "id": "8dbc2491-38a1-ea79-2db3-8230db92cc46",
        "NOMBRE": "TRASTORNO DE LOS DIENTES Y DE SUS ESTRUTURAS DE SOSTÉN, NO ESPECIFICADO"
    },
    {
        "id": "dbbe5eed-88aa-ecfe-7332-ba361037a3c6",
        "NOMBRE": "TRASTORNO DE LOS DISCOS INTERVERTEBRALES, NO ESPECIFICADO"
    },
    {
        "id": "c7196de4-bd78-2b0e-60d4-5619fa93305c",
        "NOMBRE": "TRASTORNO DE LOS HÁBITOS Y DE LOS IMPULSOS, NO ESPECIFICADO"
    },
    {
        "id": "77f5ce44-0aa3-ecbf-656f-97ebe774e2c5",
        "NOMBRE": "TRASTORNO DE LOS LEUCOCITOS, NO ESPECIFICADO"
    },
    {
        "id": "52b918f4-e233-f84d-13e5-91a13ddcdaac",
        "NOMBRE": "TRASTORNO DE LOS TEJIDOS BLANDOS, NO ESPECIFICADO"
    },
    {
        "id": "557eae53-488f-2be7-7fe4-5bed5f7dbcea",
        "NOMBRE": "TRASTORNO DE MENISCO DEBIDO A DESGARRO O LESIÓN ANTIGUA"
    },
    {
        "id": "a67760b9-7c63-e996-ad14-0c235205ed78",
        "NOMBRE": "TRASTORNO DE NERVIO CRANEAL, NO ESPECIFICADO"
    },
    {
        "id": "106bf144-b917-8acd-8454-4c11ccc697be",
        "NOMBRE": "TRASTORNO DE PÁNICO [ANSIEDAD PAROXÍSTICA EPISÓDICA]"
    },
    {
        "id": "6fc8a230-664e-a6b1-01fc-3bf7a5b0d54b",
        "NOMBRE": "TRASTORNO DE RIVALIDAD ENTRE HERMANOS"
    },
    {
        "id": "b4c4cfb6-baf9-9f58-644d-cb2cc59f5d4e",
        "NOMBRE": "TRASTORNO DE SOMATIZACIÓN"
    },
    {
        "id": "4201b9b4-5398-f12c-4ca6-e08aa1f3b5b8",
        "NOMBRE": "TRASTORNO DE VINCULACIÓN DESINHIBIDA EN LA NIÑEZ"
    },
    {
        "id": "92d36720-b9c7-36e8-8fe2-0dd86e895ee2",
        "NOMBRE": "TRASTORNO DE VINCULACIÓN REACTIVA EN LA NIÑEZ"
    },
    {
        "id": "d85d7255-588d-caa0-2d55-8ad24d60e2aa",
        "NOMBRE": "TRASTORNO DEL ALMACENAMIENTO DE LÍPIDOS, NO ESPECIFICADO"
    },
    {
        "id": "1cef3c73-df9c-a758-f9a3-14f9334c529f",
        "NOMBRE": "TRASTORNO DEL APARATO LAGRIMAL, NO ESPECIFICADO"
    },
    {
        "id": "b27750e2-329d-de45-43e7-b5c78265afdb",
        "NOMBRE": "TRASTORNO DEL CARTÍLAGO, NO ESPECIFICADO"
    },
    {
        "id": "3c003703-452d-3740-1374-785097c9fe53",
        "NOMBRE": "TRASTORNO DEL COMPORTAMIENTO SOCIAL EN LA NIÑEZ, NO ESPECIFICADO"
    },
    {
        "id": "77add830-cdba-6723-d78a-0d1fcd8882f4",
        "NOMBRE": "TRASTORNO DEL CRISTALINO, NO ESPECIFICADO"
    },
    {
        "id": "82f6b0a0-ea99-0038-08b4-3d760cd94559",
        "NOMBRE": "TRASTORNO DEL CUERPO VÍTREO, NO ESPECIFICADO"
    },
    {
        "id": "cf434b3c-f38f-3359-114d-4d49309d333f",
        "NOMBRE": "TRASTORNO DEL DESARROLLO DE LAS HABILIDADES ESCOLARES, NO ESPECIFICADO"
    },
    {
        "id": "d8e27ffe-04d9-2cd2-7995-46234b8f9f8a",
        "NOMBRE": "TRASTORNO DEL DESARROLLO DE LOS DIENTES, NO ESPECIFICADO"
    },
    {
        "id": "eba9c80a-1694-4202-cd81-7e0c2a18021f",
        "NOMBRE": "TRASTORNO DEL DESARROLLO DEL HABLA Y DEL LENGUAJE NO ESPECIFICADO"
    },
    {
        "id": "7ecd0870-1e58-3360-a168-09c06338644f",
        "NOMBRE": "TRASTORNO DEL DESARROLLO PSICOLÓGICO, NO ESPECIFICADO"
    },
    {
        "id": "b84891fa-cec3-fc97-bfbf-ba239d78d840",
        "NOMBRE": "TRASTORNO DEL DESARROLLO PSICOSEXUAL, NO ESPECIFICADO"
    },
    {
        "id": "e2585fc8-a820-9a49-f750-72f67c61d072",
        "NOMBRE": "TRASTORNO DEL ENCÉFALO, NO ESPECIFICADO"
    },
    {
        "id": "b60168dc-f071-9dbc-43c8-6a711d394870",
        "NOMBRE": "TRASTORNO DEL GLOBO OCULAR, NO ESPECIFICADO"
    },
    {
        "id": "1ea14340-00e9-6053-2073-78a20c744d34",
        "NOMBRE": "TRASTORNO DEL HUESO, NO ESPECIFICADO"
    },
    {
        "id": "88c2b1a0-e993-4f48-f84f-312299905603",
        "NOMBRE": "TRASTORNO DEL HUMOR [AFECTIVO], NO ESPECIFICADO"
    },
    {
        "id": "16ce1869-a0e1-c6ba-5208-6712fe521f8e",
        "NOMBRE": "TRASTORNO DEL HUMOR [AFECTIVOS] PERSISTENTES"
    },
    {
        "id": "8d77e5f7-b8c5-1f22-2a58-714985b6ae01",
        "NOMBRE": "TRASTORNO DEL IRIS Y DEL CUERPO CILIAR, NO ESPECIFICADO"
    },
    {
        "id": "480bbcd2-dbe8-2dc5-f9fe-1b7792e5ae46",
        "NOMBRE": "TRASTORNO DEL LENGUAJE EXPRESIVO"
    },
    {
        "id": "fee11063-2c89-243b-0ddb-d11821353b81",
        "NOMBRE": "TRASTORNO DEL LIGAMENTO"
    },
    {
        "id": "58c9a678-83d2-dd03-cc91-ccd82786ea54",
        "NOMBRE": "TRASTORNO DEL LÍQUIDO AMNIÓTICO Y DE LAS MEMBRANAS, NO ESPECIFICADO"
    },
    {
        "id": "7d6e9d82-0382-4808-91b0-2866bb4bb0a8",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LA BILIRRUBINA, NO ESPECIFICADO"
    },
    {
        "id": "d8c5ee68-2fab-43aa-419b-bd8f98f6b721",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LA GALACTOSA"
    },
    {
        "id": "e5f303de-1790-d2e3-1de4-eb4778700ac0",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LAS LIPOPROTEÍNAS, NO ESPECIFICADO"
    },
    {
        "id": "6740cf99-f78d-9a31-c7af-c39f52a66dff",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS, NO ESPECIFICADO"
    },
    {
        "id": "935d3117-606b-2b04-bf25-705d2c46b5e1",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LOS AMINOÁCIDOS AROMÁTICOS, NO ESPECIFICADO"
    },
    {
        "id": "ee1254b1-9975-a76b-0323-9debc6d6832f",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LOS AMINOÁCIDOS, NO ESPECIFICADO"
    },
    {
        "id": "1b2120e5-2381-073b-77d9-34d04dd14b18",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LOS CARBOHIDRATOS, NO ESPECIFICADO"
    },
    {
        "id": "b02cfbe5-d514-fe15-f9be-06fd28cb4ab1",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS, NO ESPECIFICADO"
    },
    {
        "id": "6c1fa662-7f27-d0d7-5b55-9c5c6e263cd7",
        "NOMBRE": "TRASTORNO DEL METABOLISMO DE LOS MINERALES, NO ESPECIFICADO"
    },
    {
        "id": "92da400d-c02f-e67e-cae7-52bf21cb2059",
        "NOMBRE": "TRASTORNO DEL MOVIMIENTO BINOCULAR, NO ESPECIFICADO"
    },
    {
        "id": "2e1f6914-f8bc-6d92-68f0-bd786be85714",
        "NOMBRE": "TRASTORNO DEL NERVIO FACIAL, NO ESPECIFICADO"
    },
    {
        "id": "e40d8b83-7b95-7e5b-79d6-9319a693209c",
        "NOMBRE": "TRASTORNO DEL OÍDO EXTERNO, NO ESPECIFICADO"
    },
    {
        "id": "25ce0420-bde9-872d-ab66-b600f84a1c1a",
        "NOMBRE": "TRASTORNO DEL OÍDO INTERNO, NO ESPECIFICADO"
    },
    {
        "id": "3d5f37b7-4df6-c99f-cc6d-7e6219e1af5f",
        "NOMBRE": "TRASTORNO DEL OÍDO MEDIO Y DE LA APÓFISIS MASTOIDES, NO ESPECIFICADO"
    },
    {
        "id": "db582501-ece9-c289-8fe8-dad40a7fa377",
        "NOMBRE": "TRASTORNO DEL OÍDO, NO ESPECIFICADO"
    },
    {
        "id": "5a812279-452c-a230-3d22-7e15ae13f7e6",
        "NOMBRE": "TRASTORNO DEL OJO Y SUS ANEXOS, NO ESPECIFICADO"
    },
    {
        "id": "d9198165-f8c7-3274-7c8f-2b33d801d181",
        "NOMBRE": "TRASTORNO DEL PÁRPADO, NO ESPECIFICADO"
    },
    {
        "id": "2b5ef8a3-d560-19da-171b-646bc4710cea",
        "NOMBRE": "TRASTORNO DEL PENE, NO ESPECIFICADO"
    },
    {
        "id": "a896af53-3d4e-09de-c93b-ec9022901b09",
        "NOMBRE": "TRASTORNO DEL PERITONEO, NO ESPECIFICADO"
    },
    {
        "id": "9a30c85a-a2a4-8a5f-44cf-87b56460a8ab",
        "NOMBRE": "TRASTORNO DEL RIÑÓN Y DEL URÉTER, NO ESPECIFICADO"
    },
    {
        "id": "9bde1711-b936-bb43-d4b6-87c31eeb8911",
        "NOMBRE": "TRASTORNO DEL SISTEMA NERVIOSO AUTÓNOMO, NO ESPECIFICADO"
    },
    {
        "id": "b7853a28-798e-93d3-1fbf-3e7c4e5e9321",
        "NOMBRE": "TRASTORNO DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADO"
    },
    {
        "id": "8ab06ec0-4c23-b583-f0b0-d29ef02f4edb",
        "NOMBRE": "TRASTORNO DEL SISTEMA URINARIO, NO ESPECIFICADO"
    },
    {
        "id": "32cd568f-993a-64bb-acdb-aebde3da5d0c",
        "NOMBRE": "TRASTORNO DEL SUEÑO, NO ESPECIFICADO"
    },
    {
        "id": "76dcabba-185c-e22c-0206-2e147b90fd36",
        "NOMBRE": "TRASTORNO DEL TRIGÉMINO, NO ESPECIFICADO"
    },
    {
        "id": "bebceb1f-e9a7-a9bb-662f-67b6d9d72bed",
        "NOMBRE": "TRASTORNO DELIRANTE"
    },
    {
        "id": "3c46d9c8-9b22-9823-f3b7-109cd709c1b1",
        "NOMBRE": "TRASTORNO DELIRANTE [ESQUIZOFRENIFORME], ORGÁNICO"
    },
    {
        "id": "f9489a45-b502-5551-e4a7-3bf75e4947ed",
        "NOMBRE": "TRASTORNO DELIRANTE INDUCIDO"
    },
    {
        "id": "f7f59ed0-d0b3-40c4-69e8-670f7eb971ef",
        "NOMBRE": "TRASTORNO DEPRESIVO DE LA CONDUCTA"
    },
    {
        "id": "cf4a06e9-6efe-1b0d-1287-8e9885a6f42f",
        "NOMBRE": "TRASTORNO DEPRESIVO RECURRENTE"
    },
    {
        "id": "d45069e5-3281-c448-826d-d4dbff0135d1",
        "NOMBRE": "TRASTORNO DEPRESIVO RECURRENTE ACTUALMENTE EN REMISIÓN"
    },
    {
        "id": "9508fb2a-12de-2200-8eff-10943ae420eb",
        "NOMBRE": "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "2dde5ff3-bdaa-c54b-0ffd-1c6cf12cfa11",
        "NOMBRE": "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE, CON SÍNTOMAS PSICÓTICOS"
    },
    {
        "id": "e7d94db2-5e3b-cfa9-ed39-5fdd2eb0503a",
        "NOMBRE": "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO LEVE PRESENTE"
    },
    {
        "id": "779fbd62-e4a4-c892-0480-ed341d8160d3",
        "NOMBRE": "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO MODERADO PRESENTE"
    },
    {
        "id": "d07c010f-195e-1d22-8b4c-2009fc1b22a1",
        "NOMBRE": "TRASTORNO DEPRESIVO RECURRENTE, NO ESPECIFICADO"
    },
    {
        "id": "34eed7af-0952-ca47-9434-4ec119871cdf",
        "NOMBRE": "TRASTORNO DISOCIATIVO [DE CONVERSIÓN], NO ESPECIFICADO"
    },
    {
        "id": "6655495e-52ff-4d8b-018c-def8f34b3798",
        "NOMBRE": "TRASTORNO DISOCIATIVO, ORGÁNICO"
    },
    {
        "id": "82b1d7be-585b-9de3-0ad8-56f161e94047",
        "NOMBRE": "TRASTORNO EMOCIONAL EN LA NIÑEZ, NO ESPECIFICADO"
    },
    {
        "id": "3fcaf890-8ae2-8d0d-e02e-ed91ee59ee67",
        "NOMBRE": "TRASTORNO ENDOCRINO NEONATAL TRANSITORIO, NO ESPECIFICADO"
    },
    {
        "id": "2399c5d5-b3f1-291e-d03e-480271a81108",
        "NOMBRE": "TRASTORNO ENDOCRINO Y METABÓLICO CONSECUTIVO A PROCEDIMIENTOS, NO ESPECIFICADO"
    },
    {
        "id": "81d69b62-8a1e-af23-9459-fbb2b5a1b7bf",
        "NOMBRE": "TRASTORNO ENDOCRINO, NO ESPECIFICADO"
    },
    {
        "id": "641be36c-fb25-59f1-a268-261f0c91528e",
        "NOMBRE": "TRASTORNO ESPECÍFICO DE LA LECTURA"
    },
    {
        "id": "a198bb1d-8e75-a23e-f067-253973529925",
        "NOMBRE": "TRASTORNO ESPECÍFICO DE LA PRONUNCIACIÓN"
    },
    {
        "id": "7aa24a36-324b-9a92-72b7-661e4fd534c5",
        "NOMBRE": "TRASTORNO ESPECÍFICO DE LAS HABILIDADES ARITMÉTICAS"
    },
    {
        "id": "9ae3472d-01ec-2246-5b97-cdecf2a05675",
        "NOMBRE": "TRASTORNO ESPECÍFICO DEL DELETREO [ORTOGRAFÍA]"
    },
    {
        "id": "ba59b8b3-7e0e-2cc4-1b9b-8d4805b3be59",
        "NOMBRE": "TRASTORNO ESPECÍFICO DEL DESARROLLO DE LA FUNCIÓN MOTRIZ"
    },
    {
        "id": "9ddb1900-33c6-4622-c915-8b95d8a9585e",
        "NOMBRE": "TRASTORNO ESQUIZOAFECTIVO DE TIPO DEPRESIVO"
    },
    {
        "id": "ae6f078f-4c0c-cf3c-3838-6be72f280ccf",
        "NOMBRE": "TRASTORNO ESQUIZOAFECTIVO DE TIPO MANÍACO"
    },
    {
        "id": "84c69809-98ae-d75d-573e-d51580b1346e",
        "NOMBRE": "TRASTORNO ESQUIZOAFECTIVO DE TIPO MIXTO"
    },
    {
        "id": "d19c15dc-53c1-66ba-8419-f89f42486a56",
        "NOMBRE": "TRASTORNO ESQUIZOAFECTIVO, NO ESPECIFICADO"
    },
    {
        "id": "2c698ec6-bf03-3618-f07e-c19923a4fc8e",
        "NOMBRE": "TRASTORNO ESQUIZOIDE DE LA PERSONALIDAD"
    },
    {
        "id": "6e8b3aa3-847b-7ed8-f68a-c573950e2660",
        "NOMBRE": "TRASTORNO ESQUIZOTÍPICO"
    },
    {
        "id": "1a14f424-b1c5-8480-8356-55d9a86ffba4",
        "NOMBRE": "TRASTORNO EXTRAPIRAMIDAL Y DEL MOVIMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "97e0dee5-b8e4-74de-7055-9d97156eed0c",
        "NOMBRE": "TRASTORNO FIBROBLÁSTICO, NO ESPECIFICADO"
    },
    {
        "id": "800555ca-e470-e1b5-d26d-a76aeca8752d",
        "NOMBRE": "TRASTORNO FLICTENULAR, NO ESPECIFICADO"
    },
    {
        "id": "c5f04839-fcc4-0d6e-2320-ee51a97067f6",
        "NOMBRE": "TRASTORNO FÓBICO DE ANSIEDAD, NO ESPECIFICADO"
    },
    {
        "id": "edf7e24d-98df-cbe2-55ae-dea44e31528d",
        "NOMBRE": "TRASTORNO FOLICULAR, NO ESPECIFICADO"
    },
    {
        "id": "67102d3f-e442-fa38-8462-d5b18c9258fc",
        "NOMBRE": "TRASTORNO FUNCIONAL INTESTINAL, NO ESPECIFICADO"
    },
    {
        "id": "de898142-1f9e-9307-d2b4-84d9eb153f77",
        "NOMBRE": "TRASTORNO GENERALIZADO DEL DESARROLLO NO ESPECIFICADO"
    },
    {
        "id": "47c67ad7-b98f-fe9a-0b8e-f4c9e4c2cada",
        "NOMBRE": "TRASTORNO GRANULOMATOSO DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO, NO ESPECIFICADO"
    },
    {
        "id": "58522d79-94de-b511-3cd1-a48b8e6df6d9",
        "NOMBRE": "TRASTORNO HEMATÓLOGICO PERINATAL, NO ESPECIFICADO"
    },
    {
        "id": "22c2ade7-7bc9-5715-7a8e-75ecf0d9cfb3",
        "NOMBRE": "TRASTORNO HEMORRÁGICO DEBIDO A ANTICOAGULANTES CIRCULANTES"
    },
    {
        "id": "d1f06de3-a324-9a5c-36ba-89d3dd6bcc27",
        "NOMBRE": "TRASTORNO HIPERACTIVO ASOCIADO CON RETRASO MENTAL Y MOVIMIENTOS ESTEREOTIPADOS"
    },
    {
        "id": "de3ea64a-e651-aad1-a6cb-5733438368db",
        "NOMBRE": "TRASTORNO HIPERCINÉTICO DE LA CONDUCTA"
    },
    {
        "id": "de929724-3c99-3970-11c5-c6d46a8184b4",
        "NOMBRE": "TRASTORNO HIPERCINÉTICO, NO ESPECIFICADO"
    },
    {
        "id": "f1b15ae2-d05d-7159-0434-94870a379a88",
        "NOMBRE": "TRASTORNO HIPERTRÓFICO DE LA PIEL, NO ESPECIFICADO"
    },
    {
        "id": "872c8241-3db5-7599-f8f7-8da5cb4fa309",
        "NOMBRE": "TRASTORNO HIPOCONDRÍACO"
    },
    {
        "id": "3b44f9a4-fb27-1fa5-2f71-64188869cf2f",
        "NOMBRE": "TRASTORNO HISTRIÓNICO DE LA PERSONALIDAD"
    },
    {
        "id": "4072c781-a07d-ab5c-71fb-1812f738e498",
        "NOMBRE": "TRASTORNO INFLAMATORIO DE ÓRGANO GENITAL MASCULINO, NO ESPECIFICADO"
    },
    {
        "id": "c7e357fe-3469-dfc4-c341-154cf16c5c2a",
        "NOMBRE": "TRASTORNO INTERNO DE LA RODILLA"
    },
    {
        "id": "cd38c3ce-e86d-6f17-5ade-62d47e69a948",
        "NOMBRE": "TRASTORNO INTERNO DE LA RODILLA, NO ESPECIFICADO"
    },
    {
        "id": "af72aa56-12c5-4849-31ea-0d22ff12d235",
        "NOMBRE": "TRASTORNO LOCALIZADO DEL TEJIDO CONJUNTIVO, NO ESPECIFICADO"
    },
    {
        "id": "41211dc9-5325-b5f5-a7b3-9f9f08eb2e38",
        "NOMBRE": "TRASTORNO MENOPÁUSICO Y PERIMENOPÁUSICO, NO ESPECIFICADO"
    },
    {
        "id": "2a3500f6-e1fb-f1b2-5371-b25cab297296",
        "NOMBRE": "TRASTORNO MENTAL NO ESPECIFICADO DEBIDO A LESIÓN Y DISFUNCIÓN CEREBRAL Y A ENFERMEDAD FÍSICA"
    },
    {
        "id": "71acd200-cec0-2945-9520-ff575fa5c8eb",
        "NOMBRE": "TRASTORNO MENTAL ORGÁNICO O SINTOMÁTICO, NO ESPECIFICADO"
    },
    {
        "id": "744e7c82-ab2a-ef72-29c4-491f60ea4817",
        "NOMBRE": "TRASTORNO MENTAL PUERPERAL, NO ESPECIFICADO"
    },
    {
        "id": "c9bba9e1-d2ca-e4d0-7382-3f487ddc21eb",
        "NOMBRE": "TRASTORNO MENTAL, NO ESPECIFICADO"
    },
    {
        "id": "5f99beee-c9e9-50e3-2bff-cee3b48aee0c",
        "NOMBRE": "TRASTORNO METABÓLICO CONSECUTIVO AL ABORTO, AL EMBARAZO ECTÓPICO Y AL EMBARAZO MOLAR"
    },
    {
        "id": "9c5d5b4c-8408-d8f2-af53-21d008c01189",
        "NOMBRE": "TRASTORNO METABÓLICO TRANSITORIO DEL RECIÉN NACIDO, NO ESPECIFICADO"
    },
    {
        "id": "6121a3c2-b40f-caaf-61db-fdd6a050aac4",
        "NOMBRE": "TRASTORNO METABÓLICO, NO ESPECIFICADO"
    },
    {
        "id": "8c267bfb-9423-6197-d096-cf0e2aa42782",
        "NOMBRE": "TRASTORNO MITRAL NO REUMÁTICO, NO ESPECIFICADO"
    },
    {
        "id": "44252457-3a82-ba48-6969-133a2a751ec5",
        "NOMBRE": "TRASTORNO MIXTO DE ANSIEDAD Y DEPRESIÓN"
    },
    {
        "id": "2c251c17-506a-3d8f-7333-145370f27b80",
        "NOMBRE": "TRASTORNO MIXTO DE LA CONDUCTA Y DE LAS EMOCIONES, NO ESPECIFICADO"
    },
    {
        "id": "655d4706-7159-47b7-3100-672e51227e83",
        "NOMBRE": "TRASTORNO MIXTO DE LAS HABILIDADES ESCOLARES"
    },
    {
        "id": "258b7889-523c-cd61-0c88-5d5768517edb",
        "NOMBRE": "TRASTORNO MUSCULAR, NO ESPECIFICADO"
    },
    {
        "id": "32268007-9819-ff04-8115-357d2cd7bf10",
        "NOMBRE": "TRASTORNO NEONATAL TRANSITORIO NO ESPECIFICADO DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO"
    },
    {
        "id": "c555daf4-0a37-fc53-14d6-ba0841e68222",
        "NOMBRE": "TRASTORNO NEUROMUSCULAR, NO ESPECIFICADO"
    },
    {
        "id": "aef8e863-04d5-3583-b0eb-b0abae354cd8",
        "NOMBRE": "TRASTORNO NEURÓTICO, NO ESPECIFICADO"
    },
    {
        "id": "a8d3ac66-2cfd-de03-cafe-75c516bf7e56",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DE LA ENCÍA Y DE LA ZONA EDÉNTULA"
    },
    {
        "id": "ea4f7dc1-af40-8b4d-7f99-7f55e94103d6",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "0c2817f2-fa1e-7a8a-45e3-897dde9f8a3d",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DE LOS TEJIDOS BLANDOS RELACIONADO CON EL USO, EL USO EXCESIVO Y LA PRESIÓN"
    },
    {
        "id": "7e0d7bb2-e093-087e-9e97-ac3dc8cd7045",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DEL OJO Y SUS ANEXOS, CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "54c1b4f0-c725-e90d-57ac-45321078110c",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DEL SISTEMA CIRCULATORIO CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "bfd575a3-ae86-5776-c906-5e86de0f0970",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DEL SISTEMA DIGESTIVO CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "4c13c5f9-1fac-fcbf-c886-f747b838e8dd",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DEL SISTEMA GENITOURINARIO CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "a86e9a6a-ada8-1812-d292-68830c40286d",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DEL SISTEMA RESPIRATORIO, CONSECUTIVO A PROCEDIMIENTOS"
    },
    {
        "id": "a9f10633-e3e9-136d-2d14-c3ca1889274e",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO DEL TONO MUSCULAR EN EL RECIÉN NACIDO"
    },
    {
        "id": "5b182a2a-a498-0cba-317f-ea759bcab811",
        "NOMBRE": "TRASTORNO NO ESPECIFICADO, RESULTANTE DE LA FUNCIÓN TUBULAR RENAL ALTERADA"
    },
    {
        "id": "afa1f48d-5acf-8480-7b6b-71262c4a0bdf",
        "NOMBRE": "TRASTORNO NO INFECCIOSO DE VASOS Y GANGLIOS LINFÁTICOS, NO ESPECIFICADO"
    },
    {
        "id": "2c6a615c-e753-f67b-79f2-f4e2614b6199",
        "NOMBRE": "TRASTORNO NO INFLAMATORIO DE LA VAGINA, NO ESPECIFICADO"
    },
    {
        "id": "a3f7784d-65f5-6fc4-0bba-1c7c1f45034a",
        "NOMBRE": "TRASTORNO NO INFLAMATORIO DE LA VULVA Y DEL PERINEO, NO ESPECIFICADO"
    },
    {
        "id": "fb77a8eb-0589-a285-8897-a74a5ac8fe56",
        "NOMBRE": "TRASTORNO NO INFLAMATORIO DEL CUELLO DEL ÚTERO, NO ESPECIFICADO"
    },
    {
        "id": "12036423-e45e-d3d1-9d60-306d88cf6df4",
        "NOMBRE": "TRASTORNO NO INFLAMATORIO DEL ÚTERO, NO ESPECIFICADO"
    },
    {
        "id": "3995b356-5942-48df-9c47-903f4ac531d8",
        "NOMBRE": "TRASTORNO NO ORGÁNICO DEL CICLO SUEÑO-VIGILIA"
    },
    {
        "id": "433f2d4b-4842-535e-ed79-19bb7d4637a5",
        "NOMBRE": "TRASTORNO NO ORGÁNICO DEL SUEÑO, NO ESPECIFICADO"
    },
    {
        "id": "2c67e48a-c35c-1ff1-7696-e5d549dbde0f",
        "NOMBRE": "TRASTORNO NO REUMÁTICO DE LA VÁLVULA TRICÚSPIDE, NO ESPECIFICADO"
    },
    {
        "id": "aefd1050-b3e9-6bb6-73f9-c2346bedb6e1",
        "NOMBRE": "TRASTORNO OBSESIVO-COMPULSIVO"
    },
    {
        "id": "ad0ee612-bfb7-5f9b-aa5e-7da8987d5113",
        "NOMBRE": "TRASTORNO OBSESIVO-COMPULSIVO, NO ESPECIFICADO"
    },
    {
        "id": "e38f9cac-4666-2d8f-7b32-fd5049f9fcdf",
        "NOMBRE": "TRASTORNO OPOSITOR DESAFIANTE"
    },
    {
        "id": "732c6c3f-c404-64b4-024a-5c215db0023e",
        "NOMBRE": "TRASTORNO ORGÁNICO DE LA PERSONALIDAD Y DEL COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO A ENFERMEDAD, LESIÓN Y DISFUNCIÓN CEREBRAL"
    },
    {
        "id": "765c5790-dac4-30c8-1e5a-365c49e5cbc4",
        "NOMBRE": "TRASTORNO PAPULOESCAMOSO, NO ESPECIFICADO"
    },
    {
        "id": "d670cbdc-0fb6-4a5e-3e06-5a4b0dc25a31",
        "NOMBRE": "TRASTORNO PARANOIDE DE LA PERSONALIDAD"
    },
    {
        "id": "4cdecf21-c633-9552-fcea-64cde2e5c370",
        "NOMBRE": "TRASTORNO PERINATAL DEL SISTEMA DIGESTIVO, NO ESPECIFICADO"
    },
    {
        "id": "20324838-c704-11ba-9ecf-917574594cbd",
        "NOMBRE": "TRASTORNO PERSISTENTE DEL HUMOR [AFECTIVO], NO ESPECIFICADO"
    },
    {
        "id": "0e42e10e-5365-6b7e-ce86-1078f5909ac2",
        "NOMBRE": "TRASTORNO POR TIC MOTOR O VOCAL CRÓNICO"
    },
    {
        "id": "c18f0fbf-75e6-50f5-ae07-af4137ba6153",
        "NOMBRE": "TRASTORNO POR TIC TRANSITORIO"
    },
    {
        "id": "5b6f2b55-334c-bda7-6347-e11856020bb4",
        "NOMBRE": "TRASTORNO POR TIC, NO ESPECIFICADO"
    },
    {
        "id": "87f90ea9-d50d-b974-4ab3-0e71305fd0bd",
        "NOMBRE": "TRASTORNO POR TICS MOTORES Y VOCALES MÚLTIPLES COMBINADOS [DE LA TOURETTE]"
    },
    {
        "id": "9758db03-5917-ad3a-7413-1d6ac70194d1",
        "NOMBRE": "TRASTORNO PRIMARIO DEL MÚSCULO, TIPO NO ESPECIFICADO"
    },
    {
        "id": "25f00d3b-1356-83ba-15ea-58a200c14f1f",
        "NOMBRE": "TRASTORNO PSICÓTICO AGUDO DE TIPO ESQUIZOFRÉNICO"
    },
    {
        "id": "929cebd7-96ed-b9cd-278f-64d87a07b7a1",
        "NOMBRE": "TRASTORNO PSICÓTICO AGUDO POLIMORFO, CON SÍNTOMAS DE ESQUIZOFRENIA"
    },
    {
        "id": "d4fd4bc2-e038-355a-633f-5dd00fa8fafb",
        "NOMBRE": "TRASTORNO PSICÓTICO AGUDO POLIMORFO, SIN SÍNTOMAS DE ESQUIZOFRENIA"
    },
    {
        "id": "a051ce3f-be69-8995-740c-e8a01f9b34d2",
        "NOMBRE": "TRASTORNO PSICÓTICO AGUDO Y TRANSITORIO, NO ESPECIFICADO"
    },
    {
        "id": "5323329a-f886-e30a-00b4-43a8d247e920",
        "NOMBRE": "TRASTORNO QUE AFECTA AL MECANISMO DE LA INMUNIDAD, NO ESPECIFICADO"
    },
    {
        "id": "45888a39-90ca-cc54-f70b-7e9c80b780d8",
        "NOMBRE": "TRASTORNO RELACIONADO CON EL VAPEO (VAPING)"
    },
    {
        "id": "c816da8e-ca61-b7a7-64c4-86419dce88df",
        "NOMBRE": "TRASTORNO RESPIRATORIO, NO ESPECIFICADO"
    },
    {
        "id": "e51d0d83-7cb6-edc6-1783-27d3452800a4",
        "NOMBRE": "TRASTORNO SINOVIAL Y TENDINOSO, NO ESPECIFICADO"
    },
    {
        "id": "c4dcaef5-7637-661d-878a-2de581aa70a5",
        "NOMBRE": "TRASTORNO SOMATOMORFO INDIFERENCIADO"
    },
    {
        "id": "7f780f10-d2b1-0453-123e-f4d1c7adb794",
        "NOMBRE": "TRASTORNO SOMATOMORFO, NO ESPECIFICADO"
    },
    {
        "id": "cd59c7ee-ddbc-a638-adc5-6b3ecb22f685",
        "NOMBRE": "TRASTORNO SUDORÍPARO APOCRINO, NO ESPECIFICADO"
    },
    {
        "id": "2256dce9-a3b6-13c6-dc7a-252603212a5f",
        "NOMBRE": "TRASTORNO SUDORÍPARO ECRINO, NO ESPECIFICADO"
    },
    {
        "id": "17cf3000-3285-2f56-ee17-c4b7a14a911b",
        "NOMBRE": "TRASTORNO TRANSITORIO NO ESPECIFICADO DEL METABOLISMO DE LOS CARBOHIDRATOS EN EL FETO Y EL RECIÉN NACIDO"
    },
    {
        "id": "6c65df92-e7b3-94b3-3a80-45af554fee57",
        "NOMBRE": "TRASTORNO VASCULAR AGUDO DE LOS INTESTINOS"
    },
    {
        "id": "a31054ee-8d98-a5b2-572a-bfc3b7538229",
        "NOMBRE": "TRASTORNO VASCULAR CRÓNICO DEL INTESTINO"
    },
    {
        "id": "e7e14005-9428-8439-21a6-5db5453dac11",
        "NOMBRE": "TRASTORNO VASCULAR DEL INTESTINO, NO ESPECIFICADO"
    },
    {
        "id": "18486f41-d006-58d0-3dbd-891066a7a974",
        "NOMBRE": "TRASTORNO VENOSO, NO ESPECIFICADO"
    },
    {
        "id": "040dd33e-6e89-981d-54ab-1e0de6c221be",
        "NOMBRE": "TRASTORNOS ADRENOGENITALES"
    },
    {
        "id": "4b32e1fa-6033-723d-e755-d4c8967b86d7",
        "NOMBRE": "TRASTORNOS ADRENOGENITALES CONGÉNITOS CON DEFICIENCIA ENZIMÁTICA"
    },
    {
        "id": "bdb9a851-6717-d177-a32c-3575bad1b184",
        "NOMBRE": "TRASTORNOS ATRÓFICOS DE LA PIEL"
    },
    {
        "id": "9eb48cb0-a6f0-5653-9395-147c6514a321",
        "NOMBRE": "TRASTORNOS CARDIOVASCULARES EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7e1cb544-b79e-4068-f533-aef4a12baf3e",
        "NOMBRE": "TRASTORNOS CARDIOVASCULARES ORIGINADOS EN EL PERÍODO PERINATAL"
    },
    {
        "id": "b709cc4f-aab6-d9b2-89f8-b7bf3c18c49e",
        "NOMBRE": "TRASTORNOS CEREBROVASCULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "947047d0-3538-925d-cbf4-0b5c3bcfeb84",
        "NOMBRE": "TRASTORNOS COMBINADOS DE LAS VÁLVULAS MITRAL, TRICÚSPIDE Y AÓRTICA"
    },
    {
        "id": "40b29c2e-aa2f-0a24-8adf-744f68ae9a63",
        "NOMBRE": "TRASTORNOS CORIORRETINIANOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f39bf0fb-d334-dfca-0311-c787b580e655",
        "NOMBRE": "TRASTORNOS DE ADAPTACIÓN"
    },
    {
        "id": "b825b3b7-f929-025b-db17-7325f52d6076",
        "NOMBRE": "TRASTORNOS DE DISCO CERVICAL"
    },
    {
        "id": "bc002adb-988b-f5fc-0db4-90fefbd57d92",
        "NOMBRE": "TRASTORNOS DE DISCO LUMBAR Y OTROS, CON RADICULOPATÍA"
    },
    {
        "id": "6e31cb11-abae-d106-24e5-5a489ea33377",
        "NOMBRE": "TRASTORNOS DE DISCOS INTERVERTEBRALES LUMBARES Y OTROS, CON MIELOPATÍA"
    },
    {
        "id": "2afacdd4-75de-b002-e779-aa067b0ffecc",
        "NOMBRE": "TRASTORNOS DE LA ACOMODACIÓN"
    },
    {
        "id": "62f3944d-d6f8-c360-bc58-af8b0a0c1912",
        "NOMBRE": "TRASTORNOS DE LA ACOMODACIÓN Y DE LA REFRACCIÓN"
    },
    {
        "id": "d1deba18-908f-10bc-0835-43b77eda9934",
        "NOMBRE": "TRASTORNOS DE LA ARTICULACIÓN TEMPOROMAXILAR"
    },
    {
        "id": "1f7e79b6-046c-5430-9d68-3798fc89e40b",
        "NOMBRE": "TRASTORNOS DE LA CONDUCTA"
    },
    {
        "id": "fabc5b8e-313f-7b5e-c5cb-f41bd45e499e",
        "NOMBRE": "TRASTORNOS DE LA CONJUNTIVA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9609b1a6-1bdb-6d9d-bd54-6e8e999ee344",
        "NOMBRE": "TRASTORNOS DE LA CONTINUIDAD DEL HUESO"
    },
    {
        "id": "8c0349e8-77ac-47b8-02d2-e4d8dc3c98ca",
        "NOMBRE": "TRASTORNOS DE LA CORTEZA VISUAL"
    },
    {
        "id": "3ffbfcbe-dcc5-3880-5c89-f44a6cecf256",
        "NOMBRE": "TRASTORNOS DE LA ELIMINACIÓN TRANSEPIDÉRMICA"
    },
    {
        "id": "bd161b7d-d23a-70f2-e145-79eb197e453f",
        "NOMBRE": "TRASTORNOS DE LA ESCLERÓTICA"
    },
    {
        "id": "ea4396ec-712b-452d-a1c1-39bfdf51c591",
        "NOMBRE": "TRASTORNOS DE LA ESCLERÓTICA Y DE LA CÓRNEA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "6917bc13-f1f7-bb34-65de-2c227f0466cd",
        "NOMBRE": "TRASTORNOS DE LA FUNCIÓN VESTIBULAR"
    },
    {
        "id": "038da837-3c99-7b47-9ad6-26163a9341ce",
        "NOMBRE": "TRASTORNOS DE LA GLÁNDULA TIROIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4bc00624-51ca-c3f7-be56-e9e32a25332e",
        "NOMBRE": "TRASTORNOS DE LA IDENTIDAD DE GÉNERO"
    },
    {
        "id": "ba7f9953-9e36-86eb-a56d-5877cfc9808e",
        "NOMBRE": "TRASTORNOS DE LA INGESTIÓN DE ALIMENTOS"
    },
    {
        "id": "18467c0d-3997-31bd-69d5-d4b3390c0ab2",
        "NOMBRE": "TRASTORNOS DE LA ÓRBITA"
    },
    {
        "id": "8db72e88-241d-18f7-a691-199bdd3a82e5",
        "NOMBRE": "TRASTORNOS DE LA PREFERENCIA SEXUAL"
    },
    {
        "id": "4aef9bc5-3d42-c002-63eb-8bf8cac7d568",
        "NOMBRE": "TRASTORNOS DE LA PUBERTAD, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "8ec8e992-2af2-b2e3-84bd-82bc1dc2f177",
        "NOMBRE": "TRASTORNOS DE LA RAÍZ CERVICAL, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "372430e9-416e-702c-75aa-380b33e0f843",
        "NOMBRE": "TRASTORNOS DE LA RAÍZ LUMBOSACRA, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "aa115933-47a9-c680-c7b2-f140b6b02873",
        "NOMBRE": "TRASTORNOS DE LA RAÍZ TORÁCICA, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "0f50fac1-933b-b183-f11c-a0d161eaf41c",
        "NOMBRE": "TRASTORNOS DE LA RETINA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ea3e6a7b-3b77-9cf8-66dd-f2ae5e98647b",
        "NOMBRE": "TRASTORNOS DE LA RÓTULA"
    },
    {
        "id": "86121f4a-ac33-ba4f-09d3-5c95dc6c3b34",
        "NOMBRE": "TRASTORNOS DE LA SECRECIÓN INTERNA DEL PÁNCREAS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "f6837601-9e96-98d0-bc0d-db1f9d0f4233",
        "NOMBRE": "TRASTORNOS DE LA URETRA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8a21356a-9a79-b494-7990-b73b0d7e5942",
        "NOMBRE": "TRASTORNOS DE LA VÁLVULA AÓRTICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d923722e-f7c6-32b5-a0b5-02a0f9df6b99",
        "NOMBRE": "TRASTORNOS DE LA VÁLVULA MITRAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d373b0ce-aa54-93cc-6ecb-b2ce01b55e13",
        "NOMBRE": "TRASTORNOS DE LA VÁLVULA PULMONAR"
    },
    {
        "id": "acdb01a3-1a49-ba53-9ae5-916e5df58bdd",
        "NOMBRE": "TRASTORNOS DE LA VÁLVULA PULMONAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "099e13f1-0f3a-f57f-7159-854358e85340",
        "NOMBRE": "TRASTORNOS DE LA VÁLVULA TRICÚSPIDE EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e45859ae-2e5d-7f5c-b9a7-8808cc7448de",
        "NOMBRE": "TRASTORNOS DE LA VEJIGA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f1a98dea-5e15-ddfd-2888-e0ae0e30d382",
        "NOMBRE": "TRASTORNOS DE LA VEJIGA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5d8e47b7-61ef-fab2-197f-1b923a12208d",
        "NOMBRE": "TRASTORNOS DE LA VESÍCULA BILIAR Y DE LAS VÍAS BILIARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "cc535be4-7054-d7be-a66a-1af9c7cca7b2",
        "NOMBRE": "TRASTORNOS DE LA VESÍCULA BILIAR, DE LAS VÍAS BILIARES Y DEL PÁNCREAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c7497d54-d5d5-6996-65a7-276dd62518f1",
        "NOMBRE": "TRASTORNOS DE LAS ARTERIAS, DE LAS ARTERIOLAS Y DE LOS VASOS CAPILARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "f926c716-af4c-0948-68f3-20386450c7fd",
        "NOMBRE": "TRASTORNOS DE LAS GLÁNDULAS SUPRARRENALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "531136ac-5a2d-4393-6400-302b1d627f04",
        "NOMBRE": "TRASTORNOS DE LAS MENINGES, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "edef8e0f-b454-0f3e-a852-417f772d7f7a",
        "NOMBRE": "TRASTORNOS DE LAS RAÍCES Y DE LOS PLEXOS NERVIOSOS"
    },
    {
        "id": "28349b19-bb39-b65b-2a32-074f6671c7ff",
        "NOMBRE": "TRASTORNOS DE LAS UÑAS"
    },
    {
        "id": "a3a598e3-9ae6-f01e-f2db-25160e3fa21a",
        "NOMBRE": "TRASTORNOS DE LAS UÑAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ad02d103-82af-f4b9-f94c-ef9e7a39b68e",
        "NOMBRE": "TRASTORNOS DE LAS UÑAS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9cbe978a-60ce-b8fc-11bb-e018b3291ad4",
        "NOMBRE": "TRASTORNOS DE LAS VÁLVULAS AÓRTICA Y TRICÚSPIDE"
    },
    {
        "id": "71b9dc05-6197-e482-e8b2-39c1b7bb907d",
        "NOMBRE": "TRASTORNOS DE LAS VÁLVULAS MITRAL Y AÓRTICA"
    },
    {
        "id": "98522163-d318-d503-810a-39e84e45ef6e",
        "NOMBRE": "TRASTORNOS DE LAS VÁLVULAS MITRAL Y TRICÚSPIDE"
    },
    {
        "id": "e3cba122-6a02-7e1e-9d7a-337ae9cf5155",
        "NOMBRE": "TRASTORNOS DE LOS HÁBITOS Y DE LOS IMPULSOS"
    },
    {
        "id": "ff5e9727-29b3-3b9d-4817-0ed3a95d5fc0",
        "NOMBRE": "TRASTORNOS DE LOS MOVIMIENTOS ESTEREOTIPADOS"
    },
    {
        "id": "59a331a2-9f80-a27c-6980-bff83e58c393",
        "NOMBRE": "TRASTORNOS DE LOS MÚSCULOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8e6ec5ff-c9a5-0d48-f8e7-adce3d702216",
        "NOMBRE": "TRASTORNOS DE LOS NERVIOS CRANEALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "dc6bcbcb-01a3-9faf-a6b7-558dcb14facf",
        "NOMBRE": "TRASTORNOS DE LOS ÓRGANOS GENITALES MASCULINOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "bf5c1f23-23cf-f916-ce8a-4fae151b6265",
        "NOMBRE": "TRASTORNOS DE LOS TEJIDOS BLANDOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "2b9fce52-a009-8065-91f3-eb42e4a657ac",
        "NOMBRE": "TRASTORNOS DE LOS TEJIDOS BLANDOS RELACIONADOS CON EL USO, EL USO EXCESIVO Y LA PRESIÓN"
    },
    {
        "id": "763efe30-206b-5f3b-77a8-4696215b3ae7",
        "NOMBRE": "TRASTORNOS DE LOS TENDONES Y DE LA SINOVIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "cf838d79-e7f8-b863-100e-dbec0a0d937d",
        "NOMBRE": "TRASTORNOS DE MÚLTIPLES NERVIOS CRANEALES"
    },
    {
        "id": "9d28d5d3-58eb-4d45-dd94-7f242bd22a8e",
        "NOMBRE": "TRASTORNOS DE OTRAS GLÁNDULAS ENDOCRINAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "13747f14-dd17-708f-3dff-346bf3c2f559",
        "NOMBRE": "TRASTORNOS DE OTRAS VÍAS ÓPTICAS"
    },
    {
        "id": "06af9bc2-8d77-e9f3-701f-ff76b1dd56dc",
        "NOMBRE": "TRASTORNOS DE OTROS NERVIOS CRANEALES"
    },
    {
        "id": "464ee966-ab7d-16ed-fda5-d21153bbdbe5",
        "NOMBRE": "TRASTORNOS DE OTROS NERVIOS CRANEALES ESPECIFICADOS"
    },
    {
        "id": "de18d3c0-6b85-9e02-40bf-5adaee129f90",
        "NOMBRE": "TRASTORNOS DE OTROS ÓRGANOS DIGESTIVOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "5d2edec5-ede3-054e-ab36-a063fc2178c2",
        "NOMBRE": "TRASTORNOS DE OTROS ÓRGANOS DIGESTIVOS ESPECIFICADOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "06d23cfb-3372-2b53-59b4-366ca10640f7",
        "NOMBRE": "TRASTORNOS DE PRÓSTATA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "81d8ebbf-2cda-172c-9ef1-c8890693adec",
        "NOMBRE": "TRASTORNOS DE SOMNOLENCIA EXCESIVA (HIPERSOMNIOS)"
    },
    {
        "id": "dff18a14-4f17-43f0-a3ce-0c6b30b2bc30",
        "NOMBRE": "TRASTORNOS DE TRANCE Y DE POSESIÓN"
    },
    {
        "id": "7a2135d8-8788-2ec5-e2e3-2891b6f35909",
        "NOMBRE": "TRASTORNOS DEGENERATIVOS Y VASCULARES DEL OÍDO"
    },
    {
        "id": "b743d909-61b8-1fe6-224a-a392b03599c0",
        "NOMBRE": "TRASTORNOS DEL APARATO LAGRIMAL"
    },
    {
        "id": "96d7a6c3-05e2-1e10-0634-ea7f90c14ffa",
        "NOMBRE": "TRASTORNOS DEL APARATO LAGRIMAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "624485db-ed04-6720-3d74-ca3c8bb42f99",
        "NOMBRE": "TRASTORNOS DEL APARATO LAGRIMAL Y DE LA ÓRBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "25cf4bed-8d36-faa1-0c01-d0b515f337f8",
        "NOMBRE": "TRASTORNOS DEL COMPORTAMIENTO SOCIAL DE COMIENZO ESPECÍFICO EN LA NIÑEZ Y EN LA ADOLESCENCIA"
    },
    {
        "id": "3348a30b-2a58-33b3-370b-4dd70bf5b690",
        "NOMBRE": "TRASTORNOS DEL CUERPO VÍTREO"
    },
    {
        "id": "980205ce-8fe8-cbc0-6149-d08c35906d0e",
        "NOMBRE": "TRASTORNOS DEL CUERPO VÍTREO Y DEL GLOBO OCULAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7a4cbc98-7dc4-40a2-a7f9-02b4fe6d078c",
        "NOMBRE": "TRASTORNOS DEL DESARROLLO DE LOS MAXILARES"
    },
    {
        "id": "e5bf109e-fa65-90bd-66a6-e34e414ad3c4",
        "NOMBRE": "TRASTORNOS DEL DESARROLLO Y DE LA ERUPCIÓN DE LOS DIENTES"
    },
    {
        "id": "93d4c4af-8cc6-a733-d4a3-281c109a7792",
        "NOMBRE": "TRASTORNOS DEL DIAFRAGMA"
    },
    {
        "id": "58ce6754-fc45-5c53-7fb8-8bb3662866f7",
        "NOMBRE": "TRASTORNOS DEL ESÓFAGO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "648e08b3-e44c-5d42-a4ad-88a612e513a4",
        "NOMBRE": "TRASTORNOS DEL ESÓFAGO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0a28d321-5b9c-67ae-3cbf-dcb980d0be42",
        "NOMBRE": "TRASTORNOS DEL GLOBO OCULAR"
    },
    {
        "id": "20c945ba-e5f1-a1e8-d580-e632e3f9dff8",
        "NOMBRE": "TRASTORNOS DEL HÍGADO EN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "1a5ac499-19ee-8b29-097a-9edcc4889579",
        "NOMBRE": "TRASTORNOS DEL HÍGADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "96105b2e-c9d0-91c4-4c15-e29c94d99efb",
        "NOMBRE": "TRASTORNOS DEL HÍGADO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "e6b50d4d-6554-8ae1-73c5-ca4c20fb100c",
        "NOMBRE": "TRASTORNOS DEL HÍGADO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a1cb2d7e-045f-5db2-30e3-0fac062bb72e",
        "NOMBRE": "TRASTORNOS DEL HUMOR [AFECTIVOS], ORGÁNICOS"
    },
    {
        "id": "b2086574-6de9-81e6-f2c2-0e4925358ce6",
        "NOMBRE": "TRASTORNOS DEL INICIO Y DEL MANTENIMIENTO DEL SUEÑO [INSOMNIOS]"
    },
    {
        "id": "42d46b7d-3119-ec89-1dae-07f58044383b",
        "NOMBRE": "TRASTORNOS DEL IRIS Y DEL CUERPO CILIAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "1aaecc21-5633-aa27-d592-7903ad5f02f5",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LA FRUCTOSA"
    },
    {
        "id": "e4fcc777-c8f2-3768-6c0d-5610909fe172",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LA GLICINA"
    },
    {
        "id": "418096b2-eb3b-4516-b9d4-b6151a0ff7de",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LA LISINA Y LA HIDROXILISINA"
    },
    {
        "id": "78982804-6e56-9cee-72c1-6d383107524a",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LA ORNITINA"
    },
    {
        "id": "8afd48f3-dc53-5a25-4c2e-7cf2265a89e8",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LA TIROSINA"
    },
    {
        "id": "9ae56931-ee9f-6a6a-e74f-76e37ba7b856",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LAS GLUCOPROTEÍNAS"
    },
    {
        "id": "287b1ae7-de2e-e114-85c6-112efad48b8f",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LAS GLUCOPROTEÍNAS, NO ESPECIFICADO"
    },
    {
        "id": "89ef367c-0ccc-47cf-fda5-1a5ecda84b87",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LAS LIPOPROTEÍNAS Y OTRAS LIPIDEMIAS"
    },
    {
        "id": "5ea27081-5a9f-12ab-badd-404eaaf7ad31",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LAS PORFIRINAS Y DE LA BILIRRUBINA"
    },
    {
        "id": "fada7320-d263-31bd-0cc9-60bbe8851eb5",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LAS PROTEÍNAS PLASMÁTICAS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "c301143b-423c-453d-cd37-d7ac92a39181",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS"
    },
    {
        "id": "7aac2193-1996-b7c9-b9d8-35986dfb0a7e",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LOS ÁCIDOS GRASOS"
    },
    {
        "id": "c3c9b1bb-5cb0-3749-d3a4-1fd816d6d932",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LOS AMINOÁCIDOS AROMÁTICOS"
    },
    {
        "id": "9d62f2cf-aa64-30b2-948b-4c2d91c0bda7",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LOS AMINOÁCIDOS AZUFRADOS"
    },
    {
        "id": "778d974b-9891-db34-5e75-9e4bd49e8749",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LOS AMINOÁCIDOS DE CADENA RAMIFICADA Y DE LOS ÁCIDOS GRASOS"
    },
    {
        "id": "9e5258e3-6b98-4be2-c24f-bce1929f9d60",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LOS ESFINGOLÍPIDOS Y OTROS TRASTORNOS POR ALMACENAMIENTO DE LÍPIDOS"
    },
    {
        "id": "42528683-38db-d478-cadf-2b30dd9a3649",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS"
    },
    {
        "id": "2d2699a6-d55c-2224-995a-3e168ddd4ff9",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DE LOS MINERALES"
    },
    {
        "id": "99ea0ec0-5ee0-11eb-f8b5-86f2bfa0b4af",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL CALCIO"
    },
    {
        "id": "381d806e-53e9-311c-3658-c187ffd8e390",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL CICLO DE LA UREA"
    },
    {
        "id": "8a28a308-9dc7-8e98-d105-d38f50f90f0e",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL COBRE"
    },
    {
        "id": "d1dd640c-cf99-5eb9-9fa6-94b45d914b59",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL FÓSFORO Y FOSFATASA"
    },
    {
        "id": "c34ce30e-b538-7079-0c99-0f25bc3dbfe1",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL HIERRO"
    },
    {
        "id": "1bf1d6a3-8782-d7c5-b041-7ce50708e79c",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL MAGNESIO"
    },
    {
        "id": "89669625-58fd-fe23-8ff7-7b60b137102f",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL PIRUVATO Y DE LA GLUCONEOGÉNESIS"
    },
    {
        "id": "0ead919f-a0fd-e615-f08e-41f9e9606456",
        "NOMBRE": "TRASTORNOS DEL METABOLISMO DEL ZINC"
    },
    {
        "id": "6774a737-b7ff-7728-f065-429d7fd363db",
        "NOMBRE": "TRASTORNOS DEL MÚSCULO Y DE LA UNIÓN NEUROMUSCULAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "27432e0b-cc0a-b6e7-d0ab-12da232d734c",
        "NOMBRE": "TRASTORNOS DEL NERVIO AUDITIVO"
    },
    {
        "id": "8b97dac6-237d-7064-bba2-13839838b6f4",
        "NOMBRE": "TRASTORNOS DEL NERVIO FACIAL"
    },
    {
        "id": "faf06a1a-d029-e1c0-a3c9-0f6b968135f5",
        "NOMBRE": "TRASTORNOS DEL NERVIO GLOSOFARÍNGEO"
    },
    {
        "id": "3dc68a9f-b765-bc18-482a-3c5c081f2c90",
        "NOMBRE": "TRASTORNOS DEL NERVIO HIPOGLOSO"
    },
    {
        "id": "cdc2b911-6093-f2da-4e80-f3e791d09f69",
        "NOMBRE": "TRASTORNOS DEL NERVIO OLFATORIO"
    },
    {
        "id": "e62e813b-dc8c-345c-b94a-08a65c5cfe12",
        "NOMBRE": "TRASTORNOS DEL NERVIO ÓPTICO [II PAR] Y DE LAS VÍAS ÓPTICAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "175ef526-1e5d-a8b5-9be8-2545cc66f678",
        "NOMBRE": "TRASTORNOS DEL NERVIO ÓPTICO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "621ce820-9f49-1dbf-668b-d37846fdfe46",
        "NOMBRE": "TRASTORNOS DEL NERVIO TRIGÉMINO"
    },
    {
        "id": "40d202f6-6ec5-7522-3761-a7e462ff85f9",
        "NOMBRE": "TRASTORNOS DEL NERVIO VAGO"
    },
    {
        "id": "9b5d09db-4a76-145f-8dbc-c5702baf542c",
        "NOMBRE": "TRASTORNOS DEL OÍDO EXTERNO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0e464970-d6cc-7fd8-23c6-f5f8600740ff",
        "NOMBRE": "TRASTORNOS DEL OÍDO Y DE LA APÓFISIS MASTOIDES CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "85e85072-34f0-b9ec-5313-3fb475e05b52",
        "NOMBRE": "TRASTORNOS DEL OJO Y SUS ANEXOS CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "3804e72b-02b7-c18d-9e3a-6c43a95b812c",
        "NOMBRE": "TRASTORNOS DEL OLFATO Y DEL GUSTO"
    },
    {
        "id": "d7ab854b-9b42-9440-73a4-ca8bed5fa4c2",
        "NOMBRE": "TRASTORNOS DEL PÁNCREAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "b0a65e4e-4408-bbd3-d2bb-6e1f7b31d9c2",
        "NOMBRE": "TRASTORNOS DEL PÁRPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "2867a669-755f-b3b1-d09e-07bcd0e6799f",
        "NOMBRE": "TRASTORNOS DEL PERITONEO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ea3637ac-ef81-fca2-83c0-577d94764b8d",
        "NOMBRE": "TRASTORNOS DEL PLEXO BRAQUIAL"
    },
    {
        "id": "79dad50c-b9f0-5055-f433-ec704e0b1985",
        "NOMBRE": "TRASTORNOS DEL PLEXO LUMBOSACRO"
    },
    {
        "id": "69f5fd03-ad8d-d529-4dbd-4fc56f384e72",
        "NOMBRE": "TRASTORNOS DEL QUIASMA ÓPTICO"
    },
    {
        "id": "b24d55a3-c0c9-6daa-57fb-4654d6c90bf5",
        "NOMBRE": "TRASTORNOS DEL RITMO NICTAMERAL"
    },
    {
        "id": "7f89226b-e8cc-fb6a-a443-7260de05f08e",
        "NOMBRE": "TRASTORNOS DEL SISTEMA CIRCULATORIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "1092b0ef-18d0-dd52-b256-eb86102637aa",
        "NOMBRE": "TRASTORNOS DEL SISTEMA DIGESTIVO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "22f94f98-b4d4-6714-7840-5a1647cd633e",
        "NOMBRE": "TRASTORNOS DEL SISTEMA GENITOURINARIO CONSECUTIVOS A PROCEDIMIENTOS NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "2ae2bf19-84f9-b0f8-df5b-8c9678fd6458",
        "NOMBRE": "TRASTORNOS DEL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "118e7b92-cc5c-2a13-059c-e6acabc744dc",
        "NOMBRE": "TRASTORNOS DEL SISTEMA NERVIOSO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "b2d7c14f-c171-190f-511e-3c55324938b5",
        "NOMBRE": "TRASTORNOS DEL SISTEMA RESPIRATORIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "020ce46f-1777-e5c4-6e27-12fd44e780d8",
        "NOMBRE": "TRASTORNOS DEL SUEÑO"
    },
    {
        "id": "f6471af7-b08a-ccc4-d4d2-e8248a8d22e8",
        "NOMBRE": "TRASTORNOS DEL TESTÍCULO Y DEL EPIDÍDIMO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "dd4f1907-6cef-ae3a-e4ae-486a4519e461",
        "NOMBRE": "TRASTORNOS DEL TONO MUSCULAR DEL RECIÉN NACIDO"
    },
    {
        "id": "c4fd70e4-bf03-6e6d-9227-1da9edff12f4",
        "NOMBRE": "TRASTORNOS DEL TRANSPORTE DE LOS AMINOÁCIDOS"
    },
    {
        "id": "062f6304-58d2-15b2-1347-434e456b90f4",
        "NOMBRE": "TRASTORNOS DELIRANTE PERSISTENTE, NO ESPECIFICADO"
    },
    {
        "id": "941b5de8-2254-e292-cf4a-5ccb44c58bcf",
        "NOMBRE": "TRASTORNOS DELIRANTES PERSISTENTES"
    },
    {
        "id": "5ed3a252-eece-29aa-ae4c-8c76f7197197",
        "NOMBRE": "TRASTORNOS DISOCIATIVOS [DE CONVERSIÓN]"
    },
    {
        "id": "273d40e8-33ed-4da3-751d-edca05667ac7",
        "NOMBRE": "TRASTORNOS DISOCIATIVOS DEL MOVIMIENTO"
    },
    {
        "id": "b41bbaac-31c8-b758-8b2b-dd69fe29adc8",
        "NOMBRE": "TRASTORNOS DISOCIATIVOS MIXTOS [Y DE CONVERSIÓN]"
    },
    {
        "id": "9fa05ac4-ef4a-7b97-9461-c64b2811ddd4",
        "NOMBRE": "TRASTORNOS EMOCIONALES DE COMIENZO ESPECÍFICO EN LA NIÑEZ"
    },
    {
        "id": "557567ce-6cb7-ead3-bad0-9fb0a1d5a88e",
        "NOMBRE": "TRASTORNOS ENDOCRINOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "dc62e425-4a61-3504-54cf-259cad144d5c",
        "NOMBRE": "TRASTORNOS ENDOCRINOS Y METABÓLICOS CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "40ee9b67-5a00-635f-f9c0-1623024f7a45",
        "NOMBRE": "TRASTORNOS ESPECÍFICOS DE LA PERSONALIDAD"
    },
    {
        "id": "26d6cd5d-f6c7-56b7-a966-67d66dbb72fc",
        "NOMBRE": "TRASTORNOS ESPECÍFICOS DEL DESARROLLO DE LAS HABILIDADES ESCOLARES"
    },
    {
        "id": "284953c6-66d6-47df-4cbc-93113a99de1d",
        "NOMBRE": "TRASTORNOS ESPECÍFICOS DEL DESARROLLO DEL HABLA Y DEL LENGUAJE"
    },
    {
        "id": "9fc7a16c-6612-8ed3-f56c-4e8580f652cd",
        "NOMBRE": "TRASTORNOS ESPECÍFICOS MIXTOS DEL DESARROLLO"
    },
    {
        "id": "ab4882b5-5883-15b0-756f-3bcb9fba4e2a",
        "NOMBRE": "TRASTORNOS ESQUIZOAFECTIVOS"
    },
    {
        "id": "45b764b0-b9f7-65b3-fdb2-de6f3e082dc2",
        "NOMBRE": "TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "ad7ac1e7-d6ce-a05e-d9b7-c489bf06ce26",
        "NOMBRE": "TRASTORNOS FALCIFORMES"
    },
    {
        "id": "26410285-0fc3-d637-a31e-903b1b372d0c",
        "NOMBRE": "TRASTORNOS FALCIFORMES HETEROCIGOTOS DOBLES"
    },
    {
        "id": "4f79c807-77de-d81c-88cc-3ce6c2da7308",
        "NOMBRE": "TRASTORNOS FIBROBLÁSTICOS"
    },
    {
        "id": "e03d2327-5451-1775-09cc-43fccfd481f0",
        "NOMBRE": "TRASTORNOS FLICTENULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c8f3fd62-9561-9fb4-4a26-c31b9715761a",
        "NOMBRE": "TRASTORNOS FÓBICOS DE ANSIEDAD"
    },
    {
        "id": "0fb3c77c-2b32-7410-acb8-872aa52761a8",
        "NOMBRE": "TRASTORNOS FUNCIONALES DE LOS POLIMORFONUCLEARES NEUTRÓFILOS"
    },
    {
        "id": "9f6e898d-892e-1297-dab1-38ec20cf7dab",
        "NOMBRE": "TRASTORNOS GENERALIZADOS DEL DESARROLLO"
    },
    {
        "id": "92c84bd0-bb77-2772-72e1-d79cad8e6d7d",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN DIABETES MELLITUS"
    },
    {
        "id": "87b8e73a-9eb2-0b74-bc6b-202bfc71e502",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d71e724e-3640-e2cb-5a6a-f70828f7f67f",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN ENFERMEDADES DE LA SANGRE Y OTROS TRASTORNOS QUE AFECTAN EL MECANISMO INMUNITARIO"
    },
    {
        "id": "af9c26b6-aa7f-a47b-45a8-1445030a11bb",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "c35860a4-44c2-4ac7-1f27-34809db21717",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN ENFERMEDADES NEOPLÁSICAS"
    },
    {
        "id": "b3592ce8-b186-24ed-0eee-0114f8218afa",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "23a327f7-773d-2d2f-2686-ae3d4a5887cf",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABÓLICAS"
    },
    {
        "id": "44bc619e-5cd9-5414-2191-c4d525e37949",
        "NOMBRE": "TRASTORNOS GLOMERULARES EN TRASTORNOS SISTÉMICOS DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "fc6954f3-5e42-4b4d-26a5-0c04667ce0c8",
        "NOMBRE": "TRASTORNOS GRANULOMATOSOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "69ffd26e-353a-0f97-7691-d4b3bf739d50",
        "NOMBRE": "TRASTORNOS HIPERCINÉTICOS"
    },
    {
        "id": "9abae129-91f0-b1f3-e2b9-a58f9b747fc1",
        "NOMBRE": "TRASTORNOS HIPERTRÓFICOS DE LA PIEL"
    },
    {
        "id": "9bfc9a04-7fd3-6199-2358-9dec6a9e072c",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS CRÓNICOS DE LA ÓRBITA"
    },
    {
        "id": "d991afa9-eaff-fa82-bfb5-4d7001486d27",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS DE LA MAMA"
    },
    {
        "id": "65c8a093-d003-636c-931f-0424dfe47fd3",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS DE LA PELVIS FEMENINA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "86fb3b00-66a8-873f-2e77-960da475a6d7",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS DE ÓRGANOS GENITALES MASCULINOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "86216a9e-105d-b188-f7c7-a72c81e850fe",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS DE VESÍCULA SEMINAL"
    },
    {
        "id": "fa116ab1-a8df-329a-3b00-2ed9253bbe34",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS DEL CORDÓN ESPERMÁTICO, TÚNICA VAGINAL Y CONDUCTO DEFERENTE"
    },
    {
        "id": "1c491089-2008-e325-9e02-4284170e0793",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS DEL ESCROTO"
    },
    {
        "id": "daadddbb-a2b0-4f6f-14d8-5dc7e020b520",
        "NOMBRE": "TRASTORNOS INFLAMATORIOS PÉLVICOS FEMENINOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "65cf7967-4462-0d6a-3952-02e75c5a3a05",
        "NOMBRE": "TRASTORNOS LINFOPROLIFERATIVOS PRIMARIO CUTÁNEOS DE CÉLULAS T CD30-POSITIVO"
    },
    {
        "id": "8706e156-116e-82cc-afee-c700c3cc73b4",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "89728043-07b5-fc01-1889-e25a8e7f35a7",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL"
    },
    {
        "id": "15eb8426-7f1c-d5d2-332f-2bc60470a25a",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "61f6cfd3-e2f6-dd51-cc0f-c7253ea1873e",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "810d71a7-8cfd-f2dd-77fe-4fe41cb22442",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, INTOXICACIÓN AGUDA"
    },
    {
        "id": "55ee7dcf-2f17-ca47-272b-a5f4649fb70b",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "c8b2a9fa-5578-1904-81d3-d783a4bfa899",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, SÍNDROME AMNÉSICO"
    },
    {
        "id": "4a701111-8e80-396d-3d53-2dc786cdf547",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "8e81c69c-78f0-f149-a1ce-6d657e681450",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "4c56ce5d-6b15-81da-2cf6-5f69dc7f0182",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, TRASTORNO PSICÓTICO"
    },
    {
        "id": "1f85084e-8e55-d381-cc6b-8bf73a32d9a4",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "8d3d8f6b-ce32-81ab-8f27-00a93f49c0ab",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALCOHOL, USO NOCIVO"
    },
    {
        "id": "27f7aba1-8158-ba6a-faef-1daaa588a02e",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS"
    },
    {
        "id": "03796967-26ec-0447-9402-a67f7d3922c4",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "a891eba8-469d-c91d-f7ef-1fe90fab7720",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "45efc889-817a-a2db-5c5e-da0c594d8918",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, INTOXICACIÓN AGUDA"
    },
    {
        "id": "296094cc-fb8c-ea0c-0042-0eaaf5c3b8b2",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, OTROS TRASTORNO MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "2ac7ba33-9fcc-ce56-6607-b5755b84b99f",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, SÍNDROME AMNÉSICO"
    },
    {
        "id": "8863a981-3948-12ae-77f2-2cec19aff4c4",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "30056390-4744-500a-94be-6c322e183376",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "abd6d9fd-21b9-efb6-12fe-3f1e926b7662",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, TRASTORNO PSICÓTICO"
    },
    {
        "id": "88e97d6e-c8d1-8d6c-13c3-6d0b097fdf1f",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "f71379cb-3544-b18a-3287-b3a2a2b8a50c",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINÓGENOS, USO NOCIVO"
    },
    {
        "id": "86f89fa3-5a41-d7cd-f692-2883237f7993",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES"
    },
    {
        "id": "b294c237-cab0-5a04-9947-5f28bad3df8e",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "9cf8aa05-da2d-8b8b-6d48-b812c80ca5d7",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "217b05f5-01b7-b157-72a1-05a4f077af29",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, INTOXICACIÓN AGUDA"
    },
    {
        "id": "3ce0fda5-5a29-0756-6b19-ee372f292226",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "5114e893-3f7b-67ce-22e2-0d9911205c17",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, SÍNDROME AMNÉSICO"
    },
    {
        "id": "ded795c6-188c-988d-c2cd-b13ba920bed0",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "df2fa35b-feb0-32b7-9d37-063cd62426e7",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "69d2587e-2058-da78-9e71-ed2f27fed579",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, TRASTORNO PSICÓTICO"
    },
    {
        "id": "cd034fd7-fdfa-d52e-0a8a-8d7be5aaa7f2",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "0beea4e0-5afa-915f-00d5-0cbabab20cb9",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES, USO NOCIVO"
    },
    {
        "id": "018f2d7f-5115-67b9-6c20-e796a20a8d23",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA"
    },
    {
        "id": "a40bc9e2-72f5-3ad8-6a42-c0a7f64b3aa4",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "5b4429da-6346-7d97-78c6-8f10891493e1",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "93dabd49-c389-4f5a-4a73-7d872827d9fb",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, INTOXICACIÓN AGUDA"
    },
    {
        "id": "1788f4c6-0288-f877-8895-a1831829273a",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "08b18295-e342-2fd4-f151-de29ef4043fe",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, SÍNDROME AMNÉSICO"
    },
    {
        "id": "75be21b2-8cc2-b7ff-3f93-81df4f09e285",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "8348c96d-e2e7-718e-6313-95b298b717dc",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "5fabdb3b-7546-526e-ec34-036de9c37a8e",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, TRASTORNO PSICÓTICO"
    },
    {
        "id": "be65aa10-f033-e5b6-d035-8cacd101dfc1",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "bd87ae15-2d37-63c3-62fb-21a7d3a1d0ac",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAÍNA, USO NOCIVO"
    },
    {
        "id": "5ee5cb21-e1b9-6b90-a5d1-341233823b16",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES"
    },
    {
        "id": "d09e4420-7157-bad2-9c5f-d99a49477008",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "10f7048c-9f1c-c893-6a02-db5ed421956d",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "4dcc2b3a-dd3d-0487-8ef8-fbfeb02cb246",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, INTOXICACIÓN AGUDA"
    },
    {
        "id": "b343ee82-a247-e1e1-444f-b28b9967a4ee",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "14f98811-dd3b-c821-2122-e5a2aec23a4a",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, SÍNDROME AMNÉSICO"
    },
    {
        "id": "03218f81-d0f8-3c6f-a72b-ca84cb421c31",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "6c089ee5-1ec0-1e57-7643-e9aee452ca65",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "db8739d2-aeae-a048-b37d-a50a34edffef",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, TRASTORNO PSICÓTICO"
    },
    {
        "id": "9ade12d9-8f93-0bdb-fb21-ec73e5d7e71c",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "d9cfb5da-6699-122e-038b-bd54cfd726ef",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLÁTILES, USO NOCIVO"
    },
    {
        "id": "8a50b709-4f55-45da-e96b-753b2d034252",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS"
    },
    {
        "id": "4a8b0591-6e3f-0399-f13a-15f2ddb0b172",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "ed5cf7f4-eb85-f586-6deb-ecd4f81bab1a",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "7be69062-4ba1-afa6-9e0b-51c3348b6f10",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, INTOXICACIÓN AGUDA"
    },
    {
        "id": "35d0422f-3d2a-ace1-6cf8-d64acef55697",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "0ed22baf-0e6d-b1e0-c0f9-2b439d6220f4",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, SÍNDROME AMNÉSICO"
    },
    {
        "id": "7b0c5dc1-0375-c850-31af-1dba03c56efd",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, SÍNDROME DE DEPENDENDENCIA"
    },
    {
        "id": "afe15703-e3bd-91e5-4ffd-b6dbf7144b52",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "53e3c92b-e1b4-f941-fafb-987db0a9e5c5",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, TRASTORNO PSICÓTICO"
    },
    {
        "id": "8f551368-5773-72ef-25cf-5c7d0ee22f95",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "3fc79b2b-16af-0e38-6dbe-e2c0a783713d",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MÚLTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS, USO NOCIVO"
    },
    {
        "id": "0161d8c5-d038-8300-b2fa-4c954981a0fc",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS"
    },
    {
        "id": "188dee64-9306-c49c-9f6d-0166de6031ac",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "1cdcb141-7145-c669-f40a-a7305813d375",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "00edaf29-21c4-7ba0-38af-dcdd6af21b5b",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, INTOXICACIÓN AGUDA"
    },
    {
        "id": "93c9bc9b-cd38-dfa6-0cb0-b3c84dec16a0",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "d59a8701-77be-dbe1-e0f9-360e5697930d",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, SÍNDROME AMNÉSICO"
    },
    {
        "id": "548430cc-555f-eaef-eb11-1911b733804e",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "40ce8596-fadb-ebfc-377a-1a1bc555da84",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "ff1e960e-62fb-5990-7e09-0498598af9ea",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, TRASTORNO PSICÓTICO"
    },
    {
        "id": "2c49f45d-f7f5-aa1b-17d3-0af7e762570b",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "d9002ff3-672a-a21b-cac3-59b4e653ca3b",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIÁCEOS, USO NOCIVO"
    },
    {
        "id": "c86e04f1-430c-b70d-e505-fb09ce3f4881",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMILANTES, INCLUIDA LA CAFEÍNA"
    },
    {
        "id": "33fcb9af-5364-dc38-03be-c767b302186c",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "99ecb13d-5440-432d-f427-d9bc6b0a279c",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "7cbc0d88-c29c-ec98-7958-ce7fb31d3cc7",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, INTOXICACIÓN AGUDA"
    },
    {
        "id": "be85d4f2-49a5-23ac-6445-6b6ffd5d6972",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "5f812826-d131-8a1b-c425-a60273463093",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, SÍNDROME AMNÉSICO"
    },
    {
        "id": "2f7b9f37-67c4-5a91-32e3-cbb038ea9c36",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "0b17edf5-f256-f29b-3332-51576e51dd7e",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "3b9119c6-e38e-5bc1-fce0-5c1d0cbe6686",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, TRASTORNO PSICÓTICO"
    },
    {
        "id": "2bed4d9a-82b7-b768-d83e-bc89593f1503",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "f6e079e5-7e82-4c88-d734-4e017851dfa7",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEÍNA, USO NOCIVO"
    },
    {
        "id": "ec0b093a-2d7f-6035-8bf4-e9f7bf9c1976",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS"
    },
    {
        "id": "bb6abfd8-c2a8-1151-98d8-1c44497e1e04",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "e54f1f26-821b-af60-7ba1-fb4027070279",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "e6f0e779-9a00-57c6-a3af-13265db452a4",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, INTOXICACIÓN AGUDA"
    },
    {
        "id": "337371b6-9713-0418-825f-f19d61b214a2",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "5982b76a-aada-cbfe-8186-696e793293a1",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, SÍNDROME AMNÉSICO"
    },
    {
        "id": "a3e15b3c-5eda-9381-a1d5-d36827383ae5",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "c3a609c4-b11d-31e1-1dc8-5d182fe15063",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, TRASTORNO PSICÓTICO"
    },
    {
        "id": "aef41ae4-d940-3778-f14a-1bfaa9162fdb",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDÍO"
    },
    {
        "id": "f0666e84-3e60-5ac6-2ec8-6dce082dc1d0",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, TRASTORNOS MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADOS"
    },
    {
        "id": "a5cb0535-52fe-6feb-3e89-a09a00cd4226",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNÓTICOS, USO NOCIVO"
    },
    {
        "id": "dd546232-2f01-a84a-8859-c0c589d8d54c",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO"
    },
    {
        "id": "7f35ecdf-2f1f-9e2c-aa7c-5a237bdd6a9f",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, ESTADO DE ABSTINENCIA"
    },
    {
        "id": "eb6e34c9-5269-4fb8-3a78-92bd0a3f47be",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, ESTADO DE ABSTINENCIA CON DELIRIO"
    },
    {
        "id": "07d479a1-d13f-fd74-7f03-ab11a80ed6d8",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, INTOXICACIÓN AGUDA"
    },
    {
        "id": "a7530a5b-aaa4-2cc9-a747-0f2e0fdd6d61",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"
    },
    {
        "id": "67534c1e-65a0-e2f3-5c03-6cbf3744bf2f",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, SÍNDROME AMNÉSICO"
    },
    {
        "id": "c42882ad-aee1-798b-1999-1100222158ad",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, SÍNDROME DE DEPENDENCIA"
    },
    {
        "id": "607b3fd6-1487-39b0-88e5-4c3de23eed56",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"
    },
    {
        "id": "ff60badf-118f-4718-42a6-ce0f56629759",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, TRASTORNO PSICÓTICO"
    },
    {
        "id": "e8f95865-3449-dd05-1a8e-a3520c9d282e",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, TRASTORNO PSICÓTICO RESIDUAL Y DE COMIENZO TARDIO"
    },
    {
        "id": "9d95e85e-c323-2a59-3442-553540570dbb",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO, USO NOCIVO"
    },
    {
        "id": "f2b223a5-98f0-f676-ada9-1906cd717729",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO GRAVES, ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "ecfd1fbc-46c8-ca8e-4ba4-e6b8abcc6cb2",
        "NOMBRE": "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO LEVES, ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "4cc04449-17f5-a103-063a-9ce293e9519d",
        "NOMBRE": "TRASTORNOS MENTALES Y ENFERMEDADES DEL SISTEMA NERVIOSO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "1ec5d70b-084b-388c-1541-415952c34c66",
        "NOMBRE": "TRASTORNOS MIOTÓNICOS"
    },
    {
        "id": "a21120c9-03e9-3714-7df5-5c217ba34862",
        "NOMBRE": "TRASTORNOS MIXTOS DE LA CONDUCTA Y DE LAS EMOCIONES"
    },
    {
        "id": "f1549817-4e04-c9e2-ca5f-c6209cfac3d6",
        "NOMBRE": "TRASTORNOS MIXTOS DEL BALANCE ÁCIDO-BÁSICO"
    },
    {
        "id": "455eda9f-aa3e-b2fd-0a8a-dc871ebf59ab",
        "NOMBRE": "TRASTORNOS MIXTOS Y OTROS TRASTORNOS DE LA PERSONALIDAD"
    },
    {
        "id": "127971a3-7911-7fbf-5a7e-846ae8a77cc6",
        "NOMBRE": "TRASTORNOS MÚLTIPLES DE LA PREFERENCIA SEXUAL"
    },
    {
        "id": "9ce73d2d-addf-f489-1c97-3a8b23cbea85",
        "NOMBRE": "TRASTORNOS MUSCULARES PRIMARIOS"
    },
    {
        "id": "52be3f79-5687-dff6-e2d9-d6a05f2ae9c1",
        "NOMBRE": "TRASTORNOS NEONATALES TRANSITORIOS DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO"
    },
    {
        "id": "4fd2941c-6932-0f4b-c6ca-e42cfb4174c3",
        "NOMBRE": "TRASTORNOS NO ESPECIFICADOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO RELACIONADOS CON RADIACIÓN"
    },
    {
        "id": "1701080c-01f4-8562-47f5-de19c06c701b",
        "NOMBRE": "TRASTORNOS NO ESPECIFICADOS DEL OÍDO Y DE LA APÓFISIS MASTOIDES, CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "de98b5c3-4784-fa97-cb4f-2dbc4589d4da",
        "NOMBRE": "TRASTORNOS NO ESPECIFICADOS DEL SISTEMA NERVIOSO, CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "b450b386-206c-0fe5-06af-d71bc9b6f414",
        "NOMBRE": "TRASTORNOS NO ESPECIFICADOS, EMOCIONALES Y DEL COMPORTAMIENTO, QUE APARECEN HABITUALMENTE EN LA NIÑEZ Y EN LA ADOLESCENCIA"
    },
    {
        "id": "e7ec770f-738f-58e7-3dcc-266b7b78180e",
        "NOMBRE": "TRASTORNOS NO ORGÁNICOS DEL SUEÑO"
    },
    {
        "id": "c70b1277-7609-c262-1c05-6a9d14a07f66",
        "NOMBRE": "TRASTORNOS NO REUMÁTICOS DE LA VÁLVULA AÓRTICA"
    },
    {
        "id": "02232203-7155-a3c9-eb44-ff23a79a4f34",
        "NOMBRE": "TRASTORNOS NO REUMÁTICOS DE LA VÁLVULA MITRAL"
    },
    {
        "id": "9db92ca2-a426-b689-f8c5-a40912fac8fc",
        "NOMBRE": "TRASTORNOS NO REUMÁTICOS DE LA VÁLVULA TRICÚSPIDE"
    },
    {
        "id": "0bb91a73-a9ce-bff7-aceb-cc3306d01b69",
        "NOMBRE": "TRASTORNOS NUTRICIONALES Y METABÓLICOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "8e079656-78d7-e795-5658-3e4fefb244e0",
        "NOMBRE": "TRASTORNOS OSTEOMUSCULARES CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "bb7c3c21-d4f6-96f7-3dcf-d0c46922a835",
        "NOMBRE": "TRASTORNOS OSTEOMUSCULARES NO ESPECIFICADOS CONSECUTIVOS A PROCEDIMIENTOS"
    },
    {
        "id": "a167815f-8f21-8c3b-125f-41452bb2d709",
        "NOMBRE": "TRASTORNOS PAPULOESCAMOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "78729c13-7846-91f5-aa19-af7dffaf975f",
        "NOMBRE": "TRASTORNOS PLACENTARIOS"
    },
    {
        "id": "1e2e99de-90bc-4d64-975b-0bf15d1a477b",
        "NOMBRE": "TRASTORNOS POR TICS"
    },
    {
        "id": "97e32059-7ef5-d427-ca3f-5d5072c31773",
        "NOMBRE": "TRASTORNOS PSICOLÓGICOS Y DEL COMPORTAMIENTO ASOCIADOS CON EL DESARROLLO Y CON LA ORIENTACIÓN SEXUALES"
    },
    {
        "id": "5bd2d741-96f4-1f67-df98-d4fe2c70aff3",
        "NOMBRE": "TRASTORNOS PSICÓTICOS AGUDOS Y TRANSITORIOS"
    },
    {
        "id": "72a91ddc-391f-162d-c343-d724f16a8235",
        "NOMBRE": "TRASTORNOS PULMONARES INTERSTICIALES AGUDOS INDUCIDOS POR DROGAS"
    },
    {
        "id": "ce368dbc-848f-a27d-b382-19665af23739",
        "NOMBRE": "TRASTORNOS PULMONARES INTERSTICIALES CRÓNICOS INDUCIDOS POR DROGAS"
    },
    {
        "id": "5fb8099a-2378-5684-0255-107fcabbddaf",
        "NOMBRE": "TRASTORNOS PULMONARES INTERSTICIALES NO ESPECIFICADOS INDUCIDOS POR DROGAS"
    },
    {
        "id": "dc980476-525d-67b4-19a5-2ad40e378a64",
        "NOMBRE": "TRASTORNOS RELACIONADOS CON DURACIÓN CORTA DE LA GESTACIÓN Y CON BAJO PESO AL NACER, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "e3ac734c-faf8-35d9-9d5c-c8ea64a9edea",
        "NOMBRE": "TRASTORNOS RELACIONADOS CON EL EMBARAZO PROLONGADO Y CON SOBREPESO AL NACER"
    },
    {
        "id": "93b1831c-1ce5-a39d-fe8f-1446b1538531",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7844728d-3cab-87e6-0c9b-ac3945c7c706",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES DE LA SANGRE Y EN TRASTORNOS QUE AFECTAN EL MECANISMO INMUNITARIO"
    },
    {
        "id": "4de35c69-9f54-1696-1f17-e950ec372b3e",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "419e4df8-6083-5516-5c6c-91b2f573c4d3",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "9125f5e8-85aa-1165-32b1-e78a53ac3782",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES METABÓLICAS"
    },
    {
        "id": "edecacce-3317-89ca-8eb2-00b3dd76cc92",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES NEOPLÁSICAS"
    },
    {
        "id": "261a55f9-b596-74e6-3eed-88c0c534d231",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "7ac49a4a-16ac-a13a-f54b-92ea05ae51f5",
        "NOMBRE": "TRASTORNOS RENALES TUBULOINTERSTICIALES EN RECHAZO DE TRASPLANTE"
    },
    {
        "id": "b03c3325-4eea-31c4-1f9e-080af1acc2ec",
        "NOMBRE": "TRASTORNOS RESPIRATORIOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0c09c652-a13a-1056-bde9-dbf89ff6e153",
        "NOMBRE": "TRASTORNOS RESPIRATORIOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "a32692e3-aa5a-0ba8-95f7-158352a4e117",
        "NOMBRE": "TRASTORNOS RESPIRATORIOS EN OTROS TRASTORNOS DIFUSOS DEL TEJIDO CONJUNTIVO"
    },
    {
        "id": "317aafa0-36b5-ff09-cac1-9b56160619be",
        "NOMBRE": "TRASTORNOS RESULTANTES DE LA FUNCIÓN TUBULAR RENAL ALTERADA"
    },
    {
        "id": "fda5b3ce-82d2-f1da-e290-008d43c1eb5b",
        "NOMBRE": "TRASTORNOS ROTULOFEMORALES"
    },
    {
        "id": "d78d9738-6f46-ac9e-19ab-3a4a373184d4",
        "NOMBRE": "TRASTORNOS SACROCOCCÍGEOS, NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "e1abbf7a-cb0c-ac1f-89d1-0bb318a0b0c8",
        "NOMBRE": "TRASTORNOS SISTÉMICOS DEL TEJIDO CONJUNTIVO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "d741e556-d0bd-a2b3-7ddc-8495ef8a8879",
        "NOMBRE": "TRASTORNOS SISTÉMICOS DEL TEJIDO CONJUNTIVO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "478bcbc4-ad82-1fbe-c5cb-0e2d900c3a93",
        "NOMBRE": "TRASTORNOS SOMATOMORFOS"
    },
    {
        "id": "babae31b-498f-9bb4-ce96-81a7df3d0f46",
        "NOMBRE": "TRASTORNOS SUDORÍPAROS APOCRINOS"
    },
    {
        "id": "25021882-44c7-ed4e-f618-7424aafb0ba9",
        "NOMBRE": "TRASTORNOS SUDORÍPAROS ECRINOS"
    },
    {
        "id": "9a505bd7-99a2-836f-a5bb-eff62f0c348d",
        "NOMBRE": "TRASTORNOS TIROIDEOS VINCULADOS A DEFICIENCIA DE YODO Y AFECCIONES RELACIONADAS"
    },
    {
        "id": "81fa5b9b-1739-8c98-0f1e-fb65eee8fde3",
        "NOMBRE": "TRASTORNOS TÓXICOS NEUROMUSCULARES"
    },
    {
        "id": "98a80bd4-c01e-cc6e-3b94-c905b8dd810f",
        "NOMBRE": "TRASTORNOS TRANSITORIOS DEL METABOLISMO DE LOS CARBOHIDRATOS ESPECÍFICOS DEL FETO Y DEL RECIÉN NACIDO"
    },
    {
        "id": "ff9db64c-9c3f-71d5-fa72-e11c8074f7bd",
        "NOMBRE": "TRASTORNOS TUBERCULOSOS DEL INTESTINO, PERITONEO Y GANGLIOS MESENTÉRICOS"
    },
    {
        "id": "a78418fd-95d6-c781-9c5c-76a33430d535",
        "NOMBRE": "TRASTORNOS VALVULARES MÚLTIPLES EN ENFERMEDADES CLASIFICADAS EN OTRAS PARTE"
    },
    {
        "id": "f2273fc8-5941-6a0b-2657-de98169abce4",
        "NOMBRE": "TRASTORNOS VASCULARES DE LOS INTESTINOS"
    },
    {
        "id": "0e66d117-326b-55a8-7109-77a273f79bdb",
        "NOMBRE": "TRASTORNOS VASCULARES DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "53c8c4dc-db2b-afaa-4a39-7c0f246681fd",
        "NOMBRE": "TRASTRONOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO DEBIDOS A ENFERMEDAD, LESIÓN O DISFUNCIÓN CEREBRAL"
    },
    {
        "id": "d44dab09-ca4b-a414-5ad8-b7585afc7aff",
        "NOMBRE": "TRATORNOS NO INFLAMATORIOS DEL OVARIO, DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO"
    },
    {
        "id": "dcaeaa2d-81fc-caf4-5847-28d71fb78180",
        "NOMBRE": "TRAUMA OBSTÉTRICO, NO ESPECIFICADO"
    },
    {
        "id": "65d582ed-5a58-31fc-a7f5-bcf3d7cbd8d8",
        "NOMBRE": "TRAUMATISMO CEREBRAL DIFUSO"
    },
    {
        "id": "1a8393b9-48e3-804e-50dc-7aadd57c1e3d",
        "NOMBRE": "TRAUMATISMO CEREBRAL FOCAL"
    },
    {
        "id": "75d172d9-7c88-fea5-e261-5bb3da030ebd",
        "NOMBRE": "TRAUMATISMO DE ARTERIAS CELÍACAS Y MESENTÉRICAS"
    },
    {
        "id": "0c79bacc-0aad-5924-163a-d5b54ef2cc40",
        "NOMBRE": "TRAUMATISMO DE ESTRUCTURAS MÚLTIPLES DE LA RODILLA"
    },
    {
        "id": "2da264cc-0776-2486-e184-d5f3aa096e11",
        "NOMBRE": "TRAUMATISMO DE LA AORTA ABDOMINAL"
    },
    {
        "id": "7c09b42e-59db-a2cb-5b00-1829f7110279",
        "NOMBRE": "TRAUMATISMO DE LA AORTA TORÁCICA"
    },
    {
        "id": "7eef8648-d609-24b0-cca4-9aa6aa69efd9",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA AXILAR"
    },
    {
        "id": "64c0a291-5b03-bd3f-1ad9-d82cef5a5558",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA BRAQUIAL"
    },
    {
        "id": "29cd1730-6a25-5bbb-3b31-d3f95eba6057",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA CARÓTIDA"
    },
    {
        "id": "6f05a4dd-6ce6-a62f-7797-d9df1b7b9ec2",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA CUBITAL A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "7857ddc7-6079-1879-5db7-d5e3ec49a21a",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA CUBITAL A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "b23c3527-1e70-9857-4330-af19bda30320",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA DORSAL DEL PIE"
    },
    {
        "id": "d526ad44-099d-be47-be47-b146ac9ffe8a",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA FEMORAL"
    },
    {
        "id": "1ec2bb67-9d66-dd8b-61bc-681fdcbf419a",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA INNOMINADA O SUBCLAVIA"
    },
    {
        "id": "66275f9c-74bc-82cf-d3b0-e8c56fd0fb58",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA PERONEA"
    },
    {
        "id": "b76eb3d1-bfa8-3db8-4fa8-cdd56a0ca20d",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA PLANTAR DEL PIE"
    },
    {
        "id": "78ffc821-e112-6f67-9300-b960308e5ca5",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA POPLÍTEA"
    },
    {
        "id": "cab2f592-6766-37f2-2486-f1fd2535a586",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA RADIAL A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "fc651307-2864-aad5-5a80-fc0e1e173113",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA RADIAL A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "bd4f8e15-e32d-abd0-264d-5af1a4710db9",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA TIBIAL (ANTERIOR) (POSTERIOR)"
    },
    {
        "id": "2be18782-5b78-d9d6-f503-b0f644e27554",
        "NOMBRE": "TRAUMATISMO DE LA ARTERIA VERTEBRAL"
    },
    {
        "id": "6b7c5f89-7b92-7879-3471-7c91a93a0730",
        "NOMBRE": "TRAUMATISMO DE LA CABEZA, NO ESPECIFICADO"
    },
    {
        "id": "b64e7517-bfee-198e-5cc9-953f54fa02b6",
        "NOMBRE": "TRAUMATISMO DE LA COLA DE CABALLO"
    },
    {
        "id": "c01f1c6f-b4a8-ddad-b886-c47ca1fe56f9",
        "NOMBRE": "TRAUMATISMO DE LA CONJUNTIVA Y ABRASIÓN CORNEAL SIN MENCIÓN DE CUERPO EXTRAÑO"
    },
    {
        "id": "e47e0f96-9b41-b58c-64a1-43333441a9ab",
        "NOMBRE": "TRAUMATISMO DE LA GRAN VENA SAFENA A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "1ca532dd-49f2-867d-d8c5-9e9dd3c91c8f",
        "NOMBRE": "TRAUMATISMO DE LA GRAN VENA SAFENA A NIVEL DE LA PIERNA"
    },
    {
        "id": "a2785ae8-ee59-1577-2166-d570620964be",
        "NOMBRE": "TRAUMATISMO DE LA MÉDULA ESPINAL Y DE NERVIOS A NIVEL DEL CUELLO"
    },
    {
        "id": "3c8bb023-492f-0a8b-6f30-23db738b164a",
        "NOMBRE": "TRAUMATISMO DE LA MÉDULA ESPINAL, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "59355902-d142-f115-981c-2be3183d731f",
        "NOMBRE": "TRAUMATISMO DE LA PIERNA, NO ESPECIFICADO"
    },
    {
        "id": "92fe45d9-97cd-c057-a601-b15c84d0785f",
        "NOMBRE": "TRAUMATISMO DE LA PLEURA"
    },
    {
        "id": "25d431e3-ab75-d5c8-c3c5-cb9fb9bea90e",
        "NOMBRE": "TRAUMATISMO DE LA TRÁQUEA TORÁCICA"
    },
    {
        "id": "e3b09a0c-9cf9-2176-b8ef-e17f6fb64d2b",
        "NOMBRE": "TRAUMATISMO DE LA TROMPA DE FALOPIO"
    },
    {
        "id": "d368cd77-c4fe-03c8-1ada-d5098e92b85e",
        "NOMBRE": "TRAUMATISMO DE LA URETRA"
    },
    {
        "id": "981949a8-06a5-4170-5754-6a7bd952fd5c",
        "NOMBRE": "TRAUMATISMO DE LA VEJIGA"
    },
    {
        "id": "363f23cf-2ab9-42cb-f328-b42c0571daa0",
        "NOMBRE": "TRAUMATISMO DE LA VENA AXILAR O BRAQUIAL"
    },
    {
        "id": "9afec58b-e6b4-be7a-e2fc-8e41237c7061",
        "NOMBRE": "TRAUMATISMO DE LA VENA CAVA INFERIOR"
    },
    {
        "id": "645584c6-3b66-876d-417a-71ae02ef8b9f",
        "NOMBRE": "TRAUMATISMO DE LA VENA DORSAL DEL PIE"
    },
    {
        "id": "cc6a0455-f4a0-22c3-2f18-dad14890de73",
        "NOMBRE": "TRAUMATISMO DE LA VENA FEMORAL A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "3353d84e-a2d7-3a73-647a-767e05e47870",
        "NOMBRE": "TRAUMATISMO DE LA VENA INNOMINADA O SUBCLAVIA"
    },
    {
        "id": "e7fd10e9-29f1-b746-4e1e-1ceaf5d906f7",
        "NOMBRE": "TRAUMATISMO DE LA VENA POPLÍTEA"
    },
    {
        "id": "45d423e3-601c-7c91-7eed-2ddc52fc29dd",
        "NOMBRE": "TRAUMATISMO DE LA VENA SAFENA EXTERNA A NIVEL DE LA PIERNA"
    },
    {
        "id": "53eea128-1012-d2ad-99f7-e65ca056e084",
        "NOMBRE": "TRAUMATISMO DE LA VENA YUGULAR EXTERNA"
    },
    {
        "id": "a3d7ee7d-82f7-d647-90a4-8c78899bd5be",
        "NOMBRE": "TRAUMATISMO DE LA VENA YUGULAR INTERNA"
    },
    {
        "id": "9f2e2922-6fcf-9718-3fd9-78e2635ed106",
        "NOMBRE": "TRAUMATISMO DE LOS BRONQUIOS"
    },
    {
        "id": "4fcde18e-ebb4-f5dc-9611-d44388cd34ac",
        "NOMBRE": "TRAUMATISMO DE LOS GENITALES EXTERNOS DURANTE EL NACIMIENTO"
    },
    {
        "id": "7d4f04e3-4182-5682-292c-00777158c1d2",
        "NOMBRE": "TRAUMATISMO DE LOS NERVIOS Y DE LA MÉDULA ESPINAL LUMBAR, A NIVEL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "8f2bd5de-9406-3c19-9057-4aa9d6c8ab0d",
        "NOMBRE": "TRAUMATISMO DE LOS VASOS SANGUÍNEOS A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "4dc579b7-123d-4564-aa27-01ac65c91ded",
        "NOMBRE": "TRAUMATISMO DE LOS VASOS SANGUÍNEOS DE LA CABEZA NO CLASIFICADOS EN OTRA PARTE"
    },
    {
        "id": "42fe1f4f-8969-3c50-8834-193999ac3be8",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES NERVIOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "79a4b813-cdf4-4020-50ca-d4ebd35f5b2b",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES NERVIOS A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "bdce703b-9e23-bc72-1503-f45156138e0c",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "1315f13e-188c-1f37-9d83-0279616a3a23",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES NERVIOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "69364178-6151-9f6a-d03b-c310544e901f",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES ÓRGANOS INTRAABDOMINALES"
    },
    {
        "id": "6a102312-8190-b541-e2db-6f8a6a2305d6",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES ÓRGANOS PÉLVICOS"
    },
    {
        "id": "75d8b0e4-a1b1-e4f9-04ab-52f719e4f567",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES TENDONES Y MÚSCULOS A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "7421bed0-a9ae-c35a-ffc4-bba6ed3d77b9",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES TENDONES Y MÚSCULOS A NIVEL DE LA PIERNA"
    },
    {
        "id": "8790deee-4d64-9c58-ca88-29a16edf61f6",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES TENDONES Y MÚSCULOS A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "511f5ea1-cb7a-cc15-4cef-33d1bf759db8",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES TENDONES Y MÚSCULOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "a69c24ba-c7ce-ad4e-79ed-b9a80bf5a798",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES TENDONES Y MÚSCULOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "3071318e-f354-56c5-b128-f8ede05c16c0",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES TENDONES Y MÚSCULOS EXTENSORES A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "c28b3f3c-fb91-9dec-9c30-5e8ff44e2c4f",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES TENDONES Y MÚSCULOS FLEXORES A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "2083e445-f83e-e805-40f1-2b6fc646d147",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "40dec365-2e7f-9f00-4829-4118e69a51c8",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "215feb00-9873-0dab-e21f-83df7a086be9",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS A NIVEL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "c050bd3f-d691-5b20-cf7a-6084efac3aa2",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "a8ba6f79-d430-ab7d-1d06-b1dc68aa44d1",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS A NIVEL DEL CUELLO"
    },
    {
        "id": "4a44ae80-b2e4-be9f-2274-e4c0bdecccfc",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "0e9f3687-0fe1-5b1c-1960-232be67e3fd1",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "0c17de1b-9f47-d45b-ccdc-315f664c1c58",
        "NOMBRE": "TRAUMATISMO DE MÚLTIPLES VASOS SANGUÍNEOS DEL TÓRAX"
    },
    {
        "id": "424f220e-48a8-4271-d729-550ef4029ad1",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "4556453b-87b9-870e-dbee-d29c2036df2d",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "bcdf02f7-7c69-6b19-ee27-22c78e37835d",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA PIERNA"
    },
    {
        "id": "88401543-49ee-f900-6a10-5d3e622f2c59",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "d8ffd2f7-9830-86fb-9adf-74f28f8511a2",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "5d2acfe2-7196-57ac-fcb4-625403b48666",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "6ad02144-45e3-3142-1085-f7c1b7f19ed3",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "007540ef-38c5-8308-6ae6-d4915c267788",
        "NOMBRE": "TRAUMATISMO DE NERVIO NO ESPECIFICADO DEL TÓRAX"
    },
    {
        "id": "5004e8cc-f6f0-43cd-aba2-8893d52a589e",
        "NOMBRE": "TRAUMATISMO DE NERVIO SENSORIAL CUTÁNEO A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "8e80360d-3aa3-7467-dff2-2b07ce923cd6",
        "NOMBRE": "TRAUMATISMO DE NERVIO(S) DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "bbed34f2-8cf1-ba78-3783-efcc0a1777ee",
        "NOMBRE": "TRAUMATISMO DE NERVIO(S) PERIFÉRICO(S) DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "6b3994f4-822f-beb1-0c1b-338bcc76b1d9",
        "NOMBRE": "TRAUMATISMO DE NERVIOS A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "70abe9f6-c2cc-cdec-6c2c-fa80d663dfbf",
        "NOMBRE": "TRAUMATISMO DE NERVIOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "bbd8c8a2-b9fb-356c-af65-88e4514b4f87",
        "NOMBRE": "TRAUMATISMO DE NERVIOS A NIVEL DE LA PIERNA"
    },
    {
        "id": "750e0fad-f255-9e95-2f37-837860099e73",
        "NOMBRE": "TRAUMATISMO DE NERVIOS A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "f7027f14-df22-db7b-b27d-f59cdfe6cb5f",
        "NOMBRE": "TRAUMATISMO DE NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "e50aa2c9-2bde-b23f-02dc-1e16506578be",
        "NOMBRE": "TRAUMATISMO DE NERVIOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "d6a02270-fe5c-ba55-f443-98cc44d689d0",
        "NOMBRE": "TRAUMATISMO DE NERVIOS CERVICALES SIMPÁTICOS"
    },
    {
        "id": "f7e71bf6-f774-18a8-439e-b9f991b9da22",
        "NOMBRE": "TRAUMATISMO DE NERVIOS CRANEALES"
    },
    {
        "id": "2874ac8b-0ee3-0f8e-80aa-c17e53d2aadf",
        "NOMBRE": "TRAUMATISMO DE NERVIOS CRANEALES, NO ESPECIFICADO"
    },
    {
        "id": "2e94a80c-38c3-6aa7-5db2-f38da83ffccc",
        "NOMBRE": "TRAUMATISMO DE NERVIOS MÚLTIPLES A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "e545f877-9a57-872b-1eb7-2bc4356d8de7",
        "NOMBRE": "TRAUMATISMO DE NERVIOS MÚLTIPLES A NIVEL DE LA PIERNA"
    },
    {
        "id": "da5a0c56-e948-be85-5e09-9747a9e656cb",
        "NOMBRE": "TRAUMATISMO DE NERVIOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "e416e607-b186-7396-32c3-c797e53c72b8",
        "NOMBRE": "TRAUMATISMO DE NERVIOS PERIFÉRICOS DEL CUELLO"
    },
    {
        "id": "93f2c238-3791-47ec-f113-1e94e812c01f",
        "NOMBRE": "TRAUMATISMO DE NERVIOS PERIFÉRICOS DEL TÓRAX"
    },
    {
        "id": "bae970fd-aa12-b91b-8c17-88cfaad88454",
        "NOMBRE": "TRAUMATISMO DE NERVIOS SIMPÁTICOS TORÁCICOS"
    },
    {
        "id": "9f16a683-07ee-e7dd-addb-8354d574989d",
        "NOMBRE": "TRAUMATISMO DE NERVIOS Y DE LA MÉDULA ESPINAL A NIVEL DEL TÓRAX"
    },
    {
        "id": "239d03c4-c9b4-c29b-763d-1d8b4bddd8e6",
        "NOMBRE": "TRAUMATISMO DE NERVIOS, RAÍZ DE NERVIO ESPINAL Y PLEXOS DEL TRONCO NO ESPECIFICADOS"
    },
    {
        "id": "2c436cb7-e3cc-5e03-371b-bb4d2b203ce3",
        "NOMBRE": "TRAUMATISMO DE NERVIOS(S) SIMPÁTICO(S) LUMBAR(ES),SACRO(S) Y PÉLVICO(S)"
    },
    {
        "id": "64a9f8fd-e4b2-afa0-ee94-5a083fc9b0dc",
        "NOMBRE": "TRAUMATISMO DE ÓRGANO INTRAABDOMINAL NO ESPECIFICADO"
    },
    {
        "id": "da259d7b-081b-5d46-3ebf-3590c579db1e",
        "NOMBRE": "TRAUMATISMO DE ÓRGANO INTRATORÁCICO, NO ESPECIFICADO"
    },
    {
        "id": "17903edb-8706-c1fc-7cd7-7ea008eaa0bc",
        "NOMBRE": "TRAUMATISMO DE ÓRGANO PÉLVICO NO ESPECIFICADO"
    },
    {
        "id": "c7e5aa69-b6ad-7fe3-3f82-8e6e1ebd868f",
        "NOMBRE": "TRAUMATISMO DE ÓRGANO(S) INTRAABDOMINAL(ES) CON ÓRGANO(S) PÉLVICO(S)"
    },
    {
        "id": "f22c7388-2358-9acd-1a29-12a6d67f136c",
        "NOMBRE": "TRAUMATISMO DE ÓRGANOS INTRAABDOMINALES"
    },
    {
        "id": "3337ec7c-e79e-f2de-712b-92be288c7922",
        "NOMBRE": "TRAUMATISMO DE OTRO TENDÓN Y MÚSCULO EXTENSOR A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "9dbb361a-627c-ab1e-d4a1-c85d0639559e",
        "NOMBRE": "TRAUMATISMO DE OTRO TENDÓN Y MÚSCULO FLEXOR A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "cd095a1a-ecea-490d-3739-da3703cda69a",
        "NOMBRE": "TRAUMATISMO DE OTRO(S) TENDÓN(ES) Y MÚSCULO(S) DEL GRUPO MUSCULAR POSTERIOR A NIVEL DE LA PIERNA"
    },
    {
        "id": "a6bf08d7-f08e-9f4b-1942-c8a5d13a70c0",
        "NOMBRE": "TRAUMATISMO DE OTROS HUESOS LARGOS DURANTE EL NACIMIENTO"
    },
    {
        "id": "0ee0fe64-d4ee-16ce-256d-627b5ba45641",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "61ab3b6f-2752-b227-6f2b-23d43155059c",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "54cfeffd-8d2b-73f1-be0c-d6ae5516e19d",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA PIERNA"
    },
    {
        "id": "f4cb411d-d453-4b10-071f-5606512b10b4",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "e8170bb1-f7c1-775c-44f2-bcff95dc23d5",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "32aba189-8097-1ceb-4b20-c7b136d71d65",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "d26e6cb1-8fe3-a7ea-ef91-7e2e83a642a2",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "7c0b864d-ceae-7da6-0408-292a62630b74",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS CRANEALES"
    },
    {
        "id": "24db6a28-c502-78b6-d87d-c85a45c54abd",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS DEL TÓRAX"
    },
    {
        "id": "29488c70-de2d-6db3-09b8-aac1d9dc7d06",
        "NOMBRE": "TRAUMATISMO DE OTROS NERVIOS Y DE LOS NO ESPECIFICADOS DEL CUELLO"
    },
    {
        "id": "1bc3cba9-65a8-7ca4-0248-be40a52e5e15",
        "NOMBRE": "TRAUMATISMO DE OTROS ÓRGANOS INTRAABDOMINALES"
    },
    {
        "id": "8e451870-29f7-95a3-c3dd-a1b8ac47a5ef",
        "NOMBRE": "TRAUMATISMO DE OTROS ÓRGANOS INTRATORÁCICOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "6de4a35d-e231-5ebe-7e79-dc3a305c7252",
        "NOMBRE": "TRAUMATISMO DE OTROS ÓRGANOS INTRATORÁCICOS, ESPECIFICADOS"
    },
    {
        "id": "e32c1720-377a-a622-00a0-5479896bea75",
        "NOMBRE": "TRAUMATISMO DE OTROS ÓRGANOS PÉLVICOS"
    },
    {
        "id": "38beaaed-b8f1-d39f-bf51-a819c1659766",
        "NOMBRE": "TRAUMATISMO DE OTROS TENDONES Y MÚSCULOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "e6473dc4-09f8-518a-76d0-1edf6e5b1161",
        "NOMBRE": "TRAUMATISMO DE OTROS TENDONES Y MÚSCULOS A NIVEL DE LA PIERNA"
    },
    {
        "id": "1573da22-5e4c-8169-39be-b18225a344d1",
        "NOMBRE": "TRAUMATISMO DE OTROS TENDONES Y MÚSCULOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "6da08ca2-c569-4e7a-5b03-ad75397b0789",
        "NOMBRE": "TRAUMATISMO DE OTROS TENDONES Y MÚSCULOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "297c8661-faa8-f582-72fd-347e21ae9c20",
        "NOMBRE": "TRAUMATISMO DE OTROS TENDONES Y MÚSCULOS Y DE LOS NO ESPECIFICADOS, A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "50b6b825-782e-ed58-aedb-d266f0908cb8",
        "NOMBRE": "TRAUMATISMO DE OTROS TENDONES Y MÚSCULOS Y LOS NO ESPECIFICADOS A NIVEL DEL MUSLO"
    },
    {
        "id": "15081d26-a114-a9ad-1a33-fc4f2e8da370",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "0fae0500-c031-e2e0-d455-acb4d1e283c0",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "3bba524e-a97d-a116-989b-01b2101ac64d",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DE LA PIERNA"
    },
    {
        "id": "6ca93c26-67e4-7b3a-1be8-60fbe5a74d39",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "7e56587a-ec4f-4fa8-6659-69d237e30b49",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "be22b685-295c-cf66-819e-0783a0fe6b1f",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DEL CUELLO"
    },
    {
        "id": "739b0154-01be-72ea-c969-8c52f86026f6",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "ef21d88b-f327-7eef-4f3a-e7d2ed524a40",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "b36d879a-c10c-9c0d-d6cc-7eacafad03ac",
        "NOMBRE": "TRAUMATISMO DE OTROS VASOS SANGUÍNEOS DEL TÓRAX"
    },
    {
        "id": "6da6860b-fe57-05ab-e66d-53a25c3f4dd7",
        "NOMBRE": "TRAUMATISMO DE RAÍCES NERVIOSAS DE LA COLUMNA TORÁCICA"
    },
    {
        "id": "424ed01e-6eea-0416-a26b-1d26908a88b4",
        "NOMBRE": "TRAUMATISMO DE RAÍZ NERVIOSA DE COLUMNA CERVICAL"
    },
    {
        "id": "1b4ac931-bab5-46cb-84cd-632c7c0e423d",
        "NOMBRE": "TRAUMATISMO DE RAÍZ NERVIOSA DE LA COLUMNA LUMBAR Y SACRA"
    },
    {
        "id": "8e206c4c-d074-cc0b-aa95-2fae3b3decda",
        "NOMBRE": "TRAUMATISMO DE REGIONES NO ESPECIFICADAS DEL CUERPO"
    },
    {
        "id": "2e23d192-cd66-8e70-e42b-888db0283e11",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y DE MÚSCULOS DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "0e92ae15-cdd1-ff88-0384-fde52aa308a2",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "22986d48-3d25-d214-17a5-b56d15c47518",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "089f4ea7-aeed-0842-cc21-167c0111cc1e",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO A NIVEL DE LA PIERNA"
    },
    {
        "id": "380bce19-14d2-b0e9-635d-c88465ddfd75",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "13b6f136-fcb7-bf2d-7c59-ed075b974360",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "69453c46-4ff0-80af-2015-b0752f5435ad",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "0d19d586-ff50-f987-41ed-e5f63dd2e095",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO DEL GRUPO MUSCULAR POSTERIOR A NIVEL DEL MUSLO"
    },
    {
        "id": "a3698b8d-8622-4e79-36c9-407db52ca571",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO NO ESPECIFICADO A NIVEL DE LA PIERNA"
    },
    {
        "id": "18d3fed8-43bb-0a60-7356-449710646173",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO NO ESPECIFICADO, A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "9e47492a-6a87-33b0-a0a5-cff82d957c58",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO NO ESPECIFICADO, A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "67deba4c-8b39-3c45-6daf-8a7e799817de",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULO NO ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "429a8cd2-141f-3fbe-8927-5bd3ba916614",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULOS A NIVEL DEL CUELLO"
    },
    {
        "id": "242d8d49-2161-7f4c-a7a1-122fd3bb5a56",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULOS A NIVEL DEL TÓRAX"
    },
    {
        "id": "f49ac9e6-d129-4c8e-0645-d2051289987f",
        "NOMBRE": "TRAUMATISMO DE TENDÓN Y MÚSCULOS DE LA CABEZA"
    },
    {
        "id": "5ce4ddd2-9da5-3041-2280-fda2268cfc2c",
        "NOMBRE": "TRAUMATISMO DE TENDÓN(ES) Y MÚSCULO(S) DEL GRUPO MUSCULAR ANTERIOR A NIVEL DE LA PIERNA"
    },
    {
        "id": "d481c572-c31c-aba9-caf7-49f8556cf408",
        "NOMBRE": "TRAUMATISMO DE TENDÓN(ES) Y MÚSCULO(S) DEL GRUPO MUSCULAR PERONEO A NIVEL DE LA PIERNA"
    },
    {
        "id": "a7c02b3b-1e96-0e82-4182-bf9bb5e5548d",
        "NOMBRE": "TRAUMATISMO DE TENDONES Y MÚSCULOS ABDUCTORES Y EXTENSORES DEL PULGAR A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "b517473e-3631-6029-2570-e53821c3c3c3",
        "NOMBRE": "TRAUMATISMO DE TENDONES Y MÚSCULOS DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "e9d468ef-9c15-b64b-34d2-c9c615f12d4f",
        "NOMBRE": "TRAUMATISMO DE TENDONES Y MÚSCULOS DEL TRONCO NO ESPECIFICADOS"
    },
    {
        "id": "15bb6cdb-729a-95c6-f4b2-5266c4cd6749",
        "NOMBRE": "TRAUMATISMO DE TENDONES Y MÚSCULOS INTRÍNSECOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "107eeabc-bd02-1a8a-4869-9c10b72d06dd",
        "NOMBRE": "TRAUMATISMO DE TENDONES Y MÚSCULOS NO ESPECIFICADOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "c438bae8-d680-c071-3a76-5cdea02f8279",
        "NOMBRE": "TRAUMATISMO DE TENDONES Y MÚSCULOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "d64066e3-262b-490d-e15b-c056253b435e",
        "NOMBRE": "TRAUMATISMO DE VASO SANGUÍNEO NO ESPECIFICADO A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "2d623674-8ece-2d26-b127-114a06b3b026",
        "NOMBRE": "TRAUMATISMO DE VASO SANGUÍNEO NO ESPECIFICADO A NIVEL DE LA PIERNA"
    },
    {
        "id": "681ec8b4-239e-0d43-8a5b-a1b59a9390b3",
        "NOMBRE": "TRAUMATISMO DE VASO SANGUÍNEO NO ESPECIFICADO A NIVEL DE PIE Y DEL TOBILLO"
    },
    {
        "id": "38b3d966-035c-6dce-bd26-a0d3a8582267",
        "NOMBRE": "TRAUMATISMO DE VASO SANGUÍNEO NO ESPECIFICADO A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "36476f55-1773-fd3c-0668-09895c8caaf1",
        "NOMBRE": "TRAUMATISMO DE VASO SANGUÍNEO NO ESPECIFICADO A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "c5453228-12db-150a-6a40-ee021fe2b0d2",
        "NOMBRE": "TRAUMATISMO DE VASO SANGUÍNEO NO ESPECIFICADO, A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "5b954db5-1b9b-b2c9-6d0c-c573ba4ba363",
        "NOMBRE": "TRAUMATISMO DE VASO(S) SANGUÍNEO(S) DE OTRO DEDO"
    },
    {
        "id": "1b5359ad-dad4-2d39-8fb9-879875b37379",
        "NOMBRE": "TRAUMATISMO DE VASO(S) SANGUÍNEO(S) DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "55d47348-6aef-2f18-9513-09d0d617d654",
        "NOMBRE": "TRAUMATISMO DE VASO(S) SANGUÍNEO(S) DEL PULGAR"
    },
    {
        "id": "7e16e5f7-004d-681d-f605-9a89e56cba91",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "70e6fee5-db79-50c6-597a-13dbed247b39",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "db170b46-7a3c-4412-984b-fe5bf0935c87",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS A NIVEL DE LA PIERNA"
    },
    {
        "id": "f67e50b5-33bb-a196-a7e5-d6b74879d9f3",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS A NIVEL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "6590e24d-83ab-652d-d86a-c2dc3b81b2a7",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS A NIVEL DEL CUELLO"
    },
    {
        "id": "8144af8b-9748-b689-1bb8-8c2e107aeadf",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "c169ce25-8188-e3b5-237b-5a18faf7b257",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "947fecc1-70eb-c85d-380c-19173307723a",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS DEL TÓRAX"
    },
    {
        "id": "1b15a7c9-0aee-e9a0-b783-1bdae8bd4b01",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS ILIACOS"
    },
    {
        "id": "845f0317-d1ae-e39b-19d1-a773781600c3",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS INTERCOSTALES"
    },
    {
        "id": "c56c4758-d20c-9936-4cba-e7ca32e02dec",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS MÚLTIPLES A NIVEL DE LA PIERNA"
    },
    {
        "id": "25969933-dace-93f5-1ed4-1f225e9221fa",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS NO ESPECIFICADOS A NIVEL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "e73f9c7c-970b-5436-b039-3af1d7fd3102",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS NO ESPECIFICADOS A NIVEL DEL CUELLO"
    },
    {
        "id": "dc035d71-45cd-6cbb-40b9-2051a5c032cc",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "7a4ed777-eb8d-5a05-9468-5f3315429d4d",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS NO ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "fe5a050a-8da5-7efb-922b-66b79521ab1d",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS NO ESPECIFICADOS DEL TÓRAX"
    },
    {
        "id": "95fd6ac6-d30d-4837-f01d-19ad3c0cc212",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS PULMONARES"
    },
    {
        "id": "ee8db5c9-315a-354a-b7b9-9cf058c377c7",
        "NOMBRE": "TRAUMATISMO DE VASOS SANGUÍNEOS RENALES"
    },
    {
        "id": "02468633-d303-f7d3-a1c0-faaeae3b3dcf",
        "NOMBRE": "TRAUMATISMO DE VENA A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "7482e4f0-37be-9ca1-e755-432b473544f8",
        "NOMBRE": "TRAUMATISMO DE VENA CAVA SUPERIOR"
    },
    {
        "id": "92e2d929-cead-2a28-81b3-3ce0edaf2678",
        "NOMBRE": "TRAUMATISMO DE VENA SUPERFICIAL A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "e02e6457-1ca4-e6c7-547f-4592728c2622",
        "NOMBRE": "TRAUMATISMO DE VENAS PORTA Y ESPLÉNICA"
    },
    {
        "id": "3a06b6f9-5ecc-d3a5-0c00-68e7cf0654ff",
        "NOMBRE": "TRAUMATISMO DEL APARATO URINARIO Y DE LOS ÓRGANOS PÉLVICOS"
    },
    {
        "id": "4b683129-b59b-d7ec-0437-764e2904a79c",
        "NOMBRE": "TRAUMATISMO DEL ARCO PALMAR PROFUNDO"
    },
    {
        "id": "c925dc9e-0e0c-0898-6aa5-2049954308f3",
        "NOMBRE": "TRAUMATISMO DEL ARCO PALMAR SUPERFICIAL"
    },
    {
        "id": "be4c337c-d83c-f8ea-e8f6-52af84b8ee3c",
        "NOMBRE": "TRAUMATISMO DEL BAZO"
    },
    {
        "id": "56bd63db-74c9-571c-e0de-9f16fff2736e",
        "NOMBRE": "TRAUMATISMO DEL COLON"
    },
    {
        "id": "aadfaa2b-1865-0314-f1cd-433d8ca6fa48",
        "NOMBRE": "TRAUMATISMO DEL CORAZÓN"
    },
    {
        "id": "6239ed53-d3cb-92e9-e341-7810611449e3",
        "NOMBRE": "TRAUMATISMO DEL CORAZÓN CON HEMOPERICARDIO"
    },
    {
        "id": "7d50a3b5-16d4-0b97-bb28-b795bde3d9ef",
        "NOMBRE": "TRAUMATISMO DEL CORAZÓN, NO ESPECIFICADO"
    },
    {
        "id": "e95f13a9-9b64-afe0-af20-a67e3219a265",
        "NOMBRE": "TRAUMATISMO DEL CUELLO, NO ESPECIFICADO"
    },
    {
        "id": "11158d9e-ee21-e514-960c-00ffed8bee95",
        "NOMBRE": "TRAUMATISMO DEL ESQUELETO DURANTE EL NACIMIENTO"
    },
    {
        "id": "1e57eee5-79f2-8306-712e-a09511be9a64",
        "NOMBRE": "TRAUMATISMO DEL ESTÓMAGO"
    },
    {
        "id": "70518230-976e-90f3-44f0-31c8e2188d6a",
        "NOMBRE": "TRAUMATISMO DEL FÉMUR DURANTE EL NACIMIENTO"
    },
    {
        "id": "a2589d95-209f-463b-b9ae-0dc8262e26f3",
        "NOMBRE": "TRAUMATISMO DEL HÍGADO Y DE LA VESÍCULA BILIAR"
    },
    {
        "id": "c910f03c-e8d2-286c-3157-004af8f05600",
        "NOMBRE": "TRAUMATISMO DEL INTESTINO DELGADO"
    },
    {
        "id": "a3d59383-ca19-4b4b-7433-66b982e89129",
        "NOMBRE": "TRAUMATISMO DEL MÚSCULO ESTERNOCLEIDOMASTOIDEO DURANTE EL NACIMIENTO"
    },
    {
        "id": "fb044f99-3e5d-9cf0-f3a1-a9ee443b9d54",
        "NOMBRE": "TRAUMATISMO DEL MÚSCULO Y TENDÓN INTRÍNSECO DE OTRO(S) DEDO(S) A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "52dfb1fa-3876-cfc9-dbd8-53c834b8d929",
        "NOMBRE": "TRAUMATISMO DEL MÚSCULO Y TENDÓN INTRÍNSECO DEL PULGAR A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "25617410-7264-47d6-3cf0-fce987f6f7f6",
        "NOMBRE": "TRAUMATISMO DEL MÚSCULO(S) Y TENDÓN(ES) DEL MANGUITO ROTATORIO DEL HOMBRO"
    },
    {
        "id": "6b8c99a5-d471-2e4d-9014-370629c13e8d",
        "NOMBRE": "TRAUMATISMO DEL NACIMIENTO EN EL CUERO CABELLUDO"
    },
    {
        "id": "d5f1f6d6-6bca-8fd3-3fb6-6e2a58cd8d8f",
        "NOMBRE": "TRAUMATISMO DEL NACIMIENTO EN EL CUERO CABELLUDO, NO ESPECIFICADO"
    },
    {
        "id": "9b336f77-9856-4ff1-0e87-0c56d6ceb63f",
        "NOMBRE": "TRAUMATISMO DEL NACIMIENTO EN EL NERVIO FACIAL"
    },
    {
        "id": "a0a7fe85-5782-f859-617c-2480aae1c81b",
        "NOMBRE": "TRAUMATISMO DEL NACIMIENTO EN EL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADO"
    },
    {
        "id": "3754989f-6c60-5b62-2063-d0e09fbeeeea",
        "NOMBRE": "TRAUMATISMO DEL NACIMIENTO EN LA COLUMNA VERTEBRAL Y EN LA MÉDULA ESPINAL"
    },
    {
        "id": "59dc5789-ee46-51f1-9cb9-24757369f519",
        "NOMBRE": "TRAUMATISMO DEL NACIMIENTO EN OTRAS PARTES DEL ESQUELETO"
    },
    {
        "id": "80b05770-fa30-88c2-5495-b38d4d6769f7",
        "NOMBRE": "TRAUMATISMO DEL NACIMIENTO EN OTROS NERVIOS CRANEALES"
    },
    {
        "id": "7bba43e0-2175-436d-5d05-667be98bf48e",
        "NOMBRE": "TRAUMATISMO DEL NERVIO ACÚSTICO [VIII PAR]"
    },
    {
        "id": "d511fa94-bcf5-2df0-7b33-1e057fb00d7a",
        "NOMBRE": "TRAUMATISMO DEL NERVIO AXILAR"
    },
    {
        "id": "9731ea64-930d-fef3-1bd3-927bbb4103e9",
        "NOMBRE": "TRAUMATISMO DEL NERVIO CIÁTICO A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "19608ed4-a51a-c246-26fe-bf6584bff2a3",
        "NOMBRE": "TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "046ff3e3-8752-3ded-6b05-56c6978d194b",
        "NOMBRE": "TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "63d8e593-f410-4509-8a60-d30ae7401417",
        "NOMBRE": "TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DEL BRAZO"
    },
    {
        "id": "6f7b09bd-5a9b-ed45-80ec-dc0aff0693df",
        "NOMBRE": "TRAUMATISMO DEL NERVIO DIGITAL DE OTRO DEDO"
    },
    {
        "id": "fd3569b7-40c1-e802-29d5-c349e76e383f",
        "NOMBRE": "TRAUMATISMO DEL NERVIO DIGITAL DEL PULGAR"
    },
    {
        "id": "ba359077-b0f3-6755-4fbb-50bbb8958e59",
        "NOMBRE": "TRAUMATISMO DEL NERVIO ESPINAL [XI PAR]"
    },
    {
        "id": "54fda7a1-ff42-2c69-9b35-15e915ee2dc3",
        "NOMBRE": "TRAUMATISMO DEL NERVIO FACIAL [VII PAR]"
    },
    {
        "id": "e971edfd-d69b-784d-6439-b835645a81aa",
        "NOMBRE": "TRAUMATISMO DEL NERVIO FEMOROCUTÁNEO A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "92d8b695-73a7-10ef-20eb-1a00e03865f3",
        "NOMBRE": "TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "f1b4e91b-2b44-a5a5-d31d-f3b10506fe34",
        "NOMBRE": "TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "5d3cba14-ce6a-94cb-1d45-8d54324a1c91",
        "NOMBRE": "TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DEL BRAZO"
    },
    {
        "id": "a4d76090-44e6-6978-9f58-b09d92ba44eb",
        "NOMBRE": "TRAUMATISMO DEL NERVIO MOTOR OCULAR COMÚN [III PAR]"
    },
    {
        "id": "d1da555e-05c8-e03f-d3cd-bb4ccd41a1dd",
        "NOMBRE": "TRAUMATISMO DEL NERVIO MOTOR OCULAR EXTERNO [VI PAR]"
    },
    {
        "id": "a99283ae-f774-688c-fe5c-504c195e6261",
        "NOMBRE": "TRAUMATISMO DEL NERVIO MUSCULOCUTÁNEO"
    },
    {
        "id": "85c4d68c-7df6-9433-1b08-c66245b4c217",
        "NOMBRE": "TRAUMATISMO DEL NERVIO ÓPTICO [II PAR] Y DE LAS VÍAS ÓPTICAS"
    },
    {
        "id": "4053bf3f-e56b-9def-3560-878790db2b38",
        "NOMBRE": "TRAUMATISMO DEL NERVIO PATÉTICO [IV PAR]"
    },
    {
        "id": "1b6e260f-4d0c-82b9-1444-52a7b9bf81fa",
        "NOMBRE": "TRAUMATISMO DEL NERVIO PERONEAL PROFUNDO A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "f966e013-2906-17e3-eb55-2b3ef30bf50b",
        "NOMBRE": "TRAUMATISMO DEL NERVIO PERONEO A NIVEL DE LA PIERNA"
    },
    {
        "id": "d745e624-95a2-83ab-4135-22ac278a2dbb",
        "NOMBRE": "TRAUMATISMO DEL NERVIO PLANTAR EXTERNO"
    },
    {
        "id": "441376ad-ec9a-91fc-5a00-fb0465b2affa",
        "NOMBRE": "TRAUMATISMO DEL NERVIO PLANTAR INTERNO"
    },
    {
        "id": "dd13d681-a62d-b09c-89aa-46b7cf6c0c8b",
        "NOMBRE": "TRAUMATISMO DEL NERVIO RADIAL A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "c00c4486-40f6-a3bf-70ec-2897218af700",
        "NOMBRE": "TRAUMATISMO DEL NERVIO RADIAL A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "11aadd9d-3bc4-6fe9-4eb7-eb3f9d332a5f",
        "NOMBRE": "TRAUMATISMO DEL NERVIO RADIAL A NIVEL DEL BRAZO"
    },
    {
        "id": "567960a1-5491-e690-7a7c-f1f2dc67c67b",
        "NOMBRE": "TRAUMATISMO DEL NERVIO SENSITIVO CUTÁNEO A NIVEL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "9ffee1d0-fb08-bd82-a9af-e021e08e27bc",
        "NOMBRE": "TRAUMATISMO DEL NERVIO SENSORIAL CUTÁNEO A NIVEL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "4f758335-df76-ae19-0b3f-8e5220362f9f",
        "NOMBRE": "TRAUMATISMO DEL NERVIO SENSORIAL CUTÁNEO A NIVEL DE LA PIERNA"
    },
    {
        "id": "40b757cd-3e8e-fdf3-54d0-b1b5f9f90175",
        "NOMBRE": "TRAUMATISMO DEL NERVIO SENSORIAL CUTÁNEO A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "107f537b-cbd1-2701-2903-2dda9332b020",
        "NOMBRE": "TRAUMATISMO DEL NERVIO TIBIAL A NIVEL DE LA PIERNA"
    },
    {
        "id": "ee2ec57b-bb84-e6c3-96a4-3a3addf68db4",
        "NOMBRE": "TRAUMATISMO DEL NERVIO TRIGÉMINO [V PAR]"
    },
    {
        "id": "d0759161-b8e5-7a23-ef86-4b678ccfe044",
        "NOMBRE": "TRAUMATISMO DEL OJO Y DE LA ÓRBITA"
    },
    {
        "id": "dad4e29f-4090-b3ec-8c47-ac4b6d5cd3c3",
        "NOMBRE": "TRAUMATISMO DEL OJO Y DE LA ÓRBITA, NO ESPECIFICADO"
    },
    {
        "id": "8437da8f-e42f-ea0d-f22f-ee42b4804306",
        "NOMBRE": "TRAUMATISMO DEL OVARIO"
    },
    {
        "id": "3211a8a3-5f56-7d01-131c-3026ff48d214",
        "NOMBRE": "TRAUMATISMO DEL PÁNCREAS"
    },
    {
        "id": "e9ada502-047d-f305-5572-379982c27a63",
        "NOMBRE": "TRAUMATISMO DEL PIE Y DEL TOBILLO, NO ESPECIFICADO"
    },
    {
        "id": "922a8643-7fca-fc01-b09f-8c757d6dcf41",
        "NOMBRE": "TRAUMATISMO DEL PLEXO BRAQUIAL"
    },
    {
        "id": "7c4f40df-3443-7316-55a4-b6a2bcce4857",
        "NOMBRE": "TRAUMATISMO DEL PLEXO LUMBOSACRO"
    },
    {
        "id": "0105e015-9b4d-1fbb-db0b-7cb540c3002e",
        "NOMBRE": "TRAUMATISMO DEL RECTO"
    },
    {
        "id": "f16afa0e-a202-fc57-a201-f6445be1687f",
        "NOMBRE": "TRAUMATISMO DEL RIÑÓN"
    },
    {
        "id": "df341329-a7d7-cf99-6b3b-302c8cd96583",
        "NOMBRE": "TRAUMATISMO DEL SISTEMA NERVIOSO PERIFÉRICO DURANTE EL NACIMIENTO"
    },
    {
        "id": "6fac8014-14e0-a246-d061-7c686557b63f",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN DE AQUILES"
    },
    {
        "id": "3a45add7-b294-492a-1240-04beb5b5633c",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO ADUCTOR MAYOR DEL MUSLO"
    },
    {
        "id": "35205545-b693-e6af-b10f-19191710f7c5",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO CUÁDRICEPS"
    },
    {
        "id": "9a92f62f-5e85-78a3-b600-e55d3e38a751",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO DE LA CABEZA LARGA DEL BÍCEPS"
    },
    {
        "id": "56f9b6bf-e409-3171-4972-47575ca58d4b",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO DE LA CADERA"
    },
    {
        "id": "7a0cef8e-96ee-6368-04d5-80362aa2a1ae",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO DE OTRAS PARTES DEL BÍCEPS"
    },
    {
        "id": "42e5460d-78ab-7671-df3f-01cfa66305ea",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO DEL EXTENSOR LARGO DEL (DE LOS) DEDO(S) A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "b81456d4-30fb-e6ae-4284-45dad5bebead",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO DEL FLEXOR LARGO DEL DEDO A NIVEL DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "042ad615-b2e6-534b-92b9-b2c238415c15",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO DEL TRÍCEPS"
    },
    {
        "id": "1c637153-e818-bd95-aac9-30f86884c4e6",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO EXTENSOR DE OTRO(S) DEDO(S) A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "b686f932-4744-8ca4-7361-6e0827333df5",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO EXTENSOR DE OTRO(S) DEDO(S) A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "c6247de7-6855-4152-73a5-d84ef8934799",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO EXTENSOR DEL PULGAR A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "bcf44ac0-0ddf-9d9a-0142-b65d79967832",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO FLEXOR DE OTRO DEDO A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "0843803c-eee7-6117-bba9-dc64bbbb6c11",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO FLEXOR DE OTRO(S) DEDO(S) A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "1a3126b5-0f14-77c0-17de-2cb8c329f4a3",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO FLEXOR DEL PULGAR A NIVEL DEL ANTEBRAZO"
    },
    {
        "id": "7cfd1681-a010-76e4-9b95-5c2258d3e3fd",
        "NOMBRE": "TRAUMATISMO DEL TENDÓN Y MÚSCULO FLEXOR LARGO DEL PULGAR A NIVEL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "c9cd9706-0962-2e83-a5a2-4322175ca7f3",
        "NOMBRE": "TRAUMATISMO DEL TÓRAX, NO ESPECIFICADO"
    },
    {
        "id": "70265d1e-f1ff-9b49-4eb4-8382b63c1786",
        "NOMBRE": "TRAUMATISMO DEL URÉTER"
    },
    {
        "id": "56ef7cdd-bc59-2da3-4789-58877749bfce",
        "NOMBRE": "TRAUMATISMO DEL ÚTERO"
    },
    {
        "id": "927bce1d-b640-8792-08b3-b6c3e9fcc34b",
        "NOMBRE": "TRAUMATISMO DURANTE EL NACIMIENTO EN OTRAS PARTES DEL SISTEMA NERVIOSO PERIFÉRICO"
    },
    {
        "id": "d9f90bf4-ed9b-522e-1e91-df01d285517b",
        "NOMBRE": "TRAUMATISMO EN EL CUERO CABELLUDO DEL RECIÉN NACIDO POR MONITOREO FETAL"
    },
    {
        "id": "5d520f95-08cb-862b-05a3-1667cd1bc78a",
        "NOMBRE": "TRAUMATISMO FACIAL DURANTE EL NACIMIENTO"
    },
    {
        "id": "e8297299-c8cd-ff63-71f1-092403e159c3",
        "NOMBRE": "TRAUMATISMO INTRACRANEAL"
    },
    {
        "id": "f0e29972-850c-a3fc-1da3-b948cbc16d6a",
        "NOMBRE": "TRAUMATISMO INTRACRANEAL CON COMA PROLONGADO"
    },
    {
        "id": "d7814bad-74b2-f773-bdd2-0c602c1455ae",
        "NOMBRE": "TRAUMATISMO INTRACRANEAL, NO ESPECIFICADO"
    },
    {
        "id": "293f281d-7ed3-663c-a123-5e79c0d1a7d1",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "cb42f311-8196-6f19-0614-6ec5df1e3462",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "a498051a-d517-309f-6ccf-52960289aa10",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "0f196ab7-bbbf-37fc-8356-14ca0920d85e",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "8d1666b6-5f2d-3b0b-6c75-780bff5cef03",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "dbfad20a-e788-2d49-33d7-425bff3def4c",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DEL ANTEBRAZO"
    },
    {
        "id": "c3e1b879-cc5c-4da9-424f-f2144ce0bdb1",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DEL ESQUELETO DURANTE EL NACIMIENTO"
    },
    {
        "id": "d94c8c84-f964-794b-2a25-1738878a37a5",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DEL SISTEMA NERVIOSO PERIFÉRICO DURANTE EL NACIMIENTO"
    },
    {
        "id": "44c25e7b-28c0-8c11-6e04-29ef6b6c98f8",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO DEL TRONCO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "13bab476-2527-c38c-f6b0-75dff037cf07",
        "NOMBRE": "TRAUMATISMO NO ESPECIFICADO, DURANTE EL NACIMIENTO"
    },
    {
        "id": "91fa6467-6dde-af57-c548-8dddd7809bbd",
        "NOMBRE": "TRAUMATISMO OBSTÉTRICO DE LOS LIGAMENTOS Y ARTICULACIONES DE LA PELVIS"
    },
    {
        "id": "dc6f90b0-fa43-a91c-8b05-882bb0d86edc",
        "NOMBRE": "TRAUMATISMO OCULAR DURANTE EL NACIMIENTO"
    },
    {
        "id": "2c79f0a3-22c4-5b09-6c06-92922040b7b1",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE DEDO(S) DEL PIE"
    },
    {
        "id": "bbb9a6a2-2e27-c75c-2fef-2c4d55119490",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA"
    },
    {
        "id": "89ab98bd-2042-baf6-931f-c949152d7507",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "28827901-38e1-ca9a-f7b4-b4ea4f0b05a5",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA CADERA"
    },
    {
        "id": "b916cc33-740f-3c92-42c2-a7fa2101c986",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA CADERA CON EL MUSLO"
    },
    {
        "id": "b7e8c628-3446-3974-b680-bbb278941936",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "49bf4d88-a643-e337-26fc-4da6dd2b0457",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA CARA"
    },
    {
        "id": "88eb2ef5-c465-76b6-046b-846725c4373f",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA LARINGE Y DE LA TRÁQUEA"
    },
    {
        "id": "247dc305-cc42-49e8-ab2d-a8129cc642d0",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "bea50edf-2207-45c8-2872-bea1424f7fd4",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA PIERNA"
    },
    {
        "id": "fb4fbd62-9dfe-3313-8a1a-ca6fa2d994ac",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE LA RODILLA"
    },
    {
        "id": "91ab2147-5074-046c-b56f-94146e02412a",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE ÓRGANOS GENITALES EXTERNOS"
    },
    {
        "id": "dd5462b4-6a36-1ccd-3196-01a88fc5a890",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DE LA CABEZA"
    },
    {
        "id": "1daf9f82-a57f-de22-38fa-748dc42d4e82",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL ANTEBRAZO"
    },
    {
        "id": "bc1c09aa-ed72-a279-12f6-38531f100279",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL CUELLO"
    },
    {
        "id": "8fc90f9b-ff3d-a349-dbf9-110e687c8212",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "5d8a2a00-961b-9500-5078-44ee5a071bac",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "8d1dca42-d60a-c885-b0cc-df020f78cc0e",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA PIERNA"
    },
    {
        "id": "7dfadde5-c779-bc78-2d63-53e40e7b06ed",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "90c16868-0d88-bb0c-2dd9-a3770d7feaab",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL ANTEBRAZO"
    },
    {
        "id": "afd3be92-9bfd-d4d7-53ca-1912edf6dda9",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL ANTEBRAZO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "f9d1f882-90ee-896c-2976-3779c04a9e29",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL CODO"
    },
    {
        "id": "0bb59f2a-697a-e5ef-809b-47a28a199d60",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL CRÁNEO"
    },
    {
        "id": "64ab4b4c-8e01-0d72-7ca7-41fe0936c139",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL CUELLO"
    },
    {
        "id": "47dabddb-1756-ec4e-329c-2241a1ebfd0e",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL CUELLO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "478b1552-2ba0-0059-5b01-e3688e55df36",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "a98f586e-62f4-0568-9d87-36fb72327759",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL MUSLO"
    },
    {
        "id": "442419ab-3fb7-52ff-a914-99ad986fdeee",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "244aec86-550f-471a-ee50-3108cbb1d7da",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL PULGAR Y OTRO(S) DEDO(S)"
    },
    {
        "id": "3d4f406f-ea30-4e92-d960-404fba0a3025",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL TOBILLO"
    },
    {
        "id": "b8c04347-a172-869e-0d5f-9e7a5216e721",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO DEL TÓRAX Y AMPUTACIÓN TRAUMÁTICA DE PARTE DEL TÓRAX"
    },
    {
        "id": "28166f7d-0910-f3f5-bf3c-f87bea7e0560",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO Y AMPUTACIÓN TRAUMÁTICA DE PARTE DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "cdec9061-d48a-6cab-64ba-bc7465d836fb",
        "NOMBRE": "TRAUMATISMO POR APLASTAMIENTO Y AMPUTACIÓN TRAUMÁTICA DE REGIONES NO ESPECIFICADAS DEL CUERPO"
    },
    {
        "id": "573c993d-8c34-c40e-0c5f-b85ed240a80b",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA CABEZA"
    },
    {
        "id": "ea744b3c-d826-bc80-a0bb-d1d6f1586049",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA CABEZA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "dcfe14f7-e526-934e-e893-e0293cf36c68",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "540ec12e-15b0-b0d4-5599-3fd853fce657",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA CADERA Y DEL MUSLO, NO ESPECIFICADO"
    },
    {
        "id": "2e2c84fb-1da2-bab6-ab8e-0af3defa02c2",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "3b37f3c0-da45-693e-1cc0-01bf7816f402",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA MUÑECA Y DE LA MANO, NO ESPECIFICADO"
    },
    {
        "id": "e2cf7c8e-4f63-d67f-2c54-354cab7cbd7d",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA NARIZ"
    },
    {
        "id": "ebe5ad13-6019-ca81-d473-de4bf3bde33e",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA PIERNA"
    },
    {
        "id": "20d1ceeb-037e-7a94-4c0f-0fdc274c1ea6",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE LA PIERNA, NO ESPECIFICADO"
    },
    {
        "id": "5a26d504-1f23-b277-0fda-4d6ac79445bb",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO."
    },
    {
        "id": "ae9414be-3d73-7fb1-ade7-436cec4873ed",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "2b2a1229-e683-d6a3-62e8-46db89ffd6ac",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE OTRAS PARTES DE LA CABEZA"
    },
    {
        "id": "5710c7f5-a179-eab9-8981-41d5878b5f04",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE OTRAS PARTES DEL CUELLO"
    },
    {
        "id": "03a6ba79-709f-886a-901d-ad418056129e",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TÓRAX"
    },
    {
        "id": "6d5d4913-9d4b-1a72-c0be-d897062ba975",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DE REGIÓN NO ESPECIFICADA DEL CUERPO"
    },
    {
        "id": "0361ab5c-9dcc-3b5a-7c91-43f066469f85",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "83a0451d-e1fd-12d9-bb25-a2de7f52680f",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "72773dfb-aa67-cc97-61fd-20e78d183409",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL ANTEBRAZO Y DEL CODO"
    },
    {
        "id": "546f5bc6-3f04-2801-6427-f50c3b9f227d",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL ANTEBRAZO, NO ESPECIFICADO"
    },
    {
        "id": "04f0daf0-282c-fc9f-d441-0279a0b4cdbb",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL CUELLO"
    },
    {
        "id": "beb9e0a8-ecc6-c5da-394a-76420dfa7c05",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL CUELLO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "10d9a26f-5968-250d-88a1-b2a127d82c45",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL CUERO CABELLUDO"
    },
    {
        "id": "433179f9-4569-d464-8cab-8398cf42dcaf",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL LABIO Y DE LA CAVIDAD BUCAL"
    },
    {
        "id": "8f40a52d-c2b7-198a-64e0-ab942bea3f24",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL OÍDO"
    },
    {
        "id": "1c5a023a-c38f-5f22-6402-4785f4784a9f",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL PIE Y DEL TOBILLO, NO ESPECIFICADO"
    },
    {
        "id": "4182f062-4fda-52e4-35b3-59f351f69135",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL TOBILLO Y DEL PIE"
    },
    {
        "id": "077a92c2-2971-cd6b-75e2-9949368ebcab",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL TÓRAX"
    },
    {
        "id": "01e01abc-6716-2100-2a1a-fb3474bf49b3",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL DEL TRONCO, NIVEL NO ESPECIFICADO"
    },
    {
        "id": "99252ae7-b135-565c-24ae-b2b1babbb15f",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL MÚLTIPLE DEL CUELLO"
    },
    {
        "id": "9b0f1edb-46c2-e9cb-d820-fec4b5b1f8ac",
        "NOMBRE": "TRAUMATISMO SUPERFICIAL NO ESPECIFICADO DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "3dd7aa24-7e1c-c10d-91fc-6fab0d9699e0",
        "NOMBRE": "TRAUMATISMO SUPERIFICIAL DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "24e38a1c-322f-113c-5262-aa19287c5411",
        "NOMBRE": "TRAUMATISMO, NO ESPECIFICADO"
    },
    {
        "id": "34067301-9a83-758a-5c42-99d674782c73",
        "NOMBRE": "TRAUMATISMOS DE NERVIOS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "b6839b31-54c9-e1e2-5f0d-1d68159ac532",
        "NOMBRE": "TRAUMATISMOS DE NERVIOS Y MÉDULA ESPINAL QUE AFECTAN OTRAS MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "728d013a-0a5d-7dfd-20a8-58f7de041208",
        "NOMBRE": "TRAUMATISMOS DE ÓRGANOS INTRATORÁCICOS CON ÓRGANOS INTRAABDOMINALES Y PÉLVICOS"
    },
    {
        "id": "ae2eacc0-161c-435b-13bb-dbf94bd3d0ff",
        "NOMBRE": "TRAUMATISMOS DE TENDONES Y MÚSCULOS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "b97af7cc-66b7-f387-70eb-40174120a239",
        "NOMBRE": "TRAUMATISMOS DE VASOS SANGUÍNEOS QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "dd39752a-c6d8-9b6d-beb6-ae2d082616ba",
        "NOMBRE": "TRAUMATISMOS DEL ENCÉFALO Y DE NERVIOS CRANEALES CON TRAUMATISMO DE NERVIOS Y MÉDULA ESPINAL A NIVEL DEL CUELLO"
    },
    {
        "id": "9f18b73f-c71d-a961-9668-41b2f3f5dbdb",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES DE LA CABEZA"
    },
    {
        "id": "bb785ad9-45ca-4a05-65bd-89d1c8fbb3a1",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "84825282-466b-a724-9ecd-544ee3f8c97e",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES DE LA PIERNA"
    },
    {
        "id": "3fda4a3e-3f1c-106d-0af6-f6f8096c6e4d",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES DE ÓRGANOS INTRATORÁCICOS"
    },
    {
        "id": "bf8f7099-c058-0e08-b197-e257bbf2e50d",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES DEL CUELLO"
    },
    {
        "id": "6336d087-9e42-ad49-b4dd-5f0e11c3925f",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "5cf812a5-2521-0ca4-cd40-cf7caebc1309",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES DEL TÓRAX"
    },
    {
        "id": "849942e5-86dc-4915-c5e1-3ccc20ab5db2",
        "NOMBRE": "TRAUMATISMOS MÚLTIPLES, NO ESPECIFICADOS"
    },
    {
        "id": "bf603be1-6869-eebb-e602-9f28f8d16d27",
        "NOMBRE": "TRAUMATISMOS NO ESPECIFICADOS DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "2a0acea6-8d8d-6807-8049-74c2ee08b935",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO DEL TÓRAX, DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS CON MIEMBRO(S)"
    },
    {
        "id": "61f11c34-1961-f0ec-565b-a2860d6c0093",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO MÚLTIPLE, NO ESPECIFICADOS"
    },
    {
        "id": "f3702a9b-029f-608c-c010-7334fc52d79a",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTA MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "d870e5da-bc9a-b98a-b327-58dd85b8125e",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN EL TÓRAX CON EL ABDOMEN, LA REGIÓN LUMBOSACRA Y LA PELVIS"
    },
    {
        "id": "e8a791db-a02b-5cc0-0269-5cf2dba405b9",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN LA CABEZA CON EL CUELLO"
    },
    {
        "id": "12c3d15f-884f-2de9-4178-eb0ecfd2dc1f",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "041c93b0-0828-9797-f731-14fe89d24af3",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)"
    },
    {
        "id": "3c33058e-37ef-440b-382a-79673e533cb0",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "58fed875-7bee-5a70-bdba-38b8bb52d1fe",
        "NOMBRE": "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"
    },
    {
        "id": "e262cb95-b075-b0b3-b831-c2a751e392fe",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DE LA CABEZA"
    },
    {
        "id": "1af01ece-6be8-9c36-9192-a4d7238ff972",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DE LA CADERA Y DEL MUSLO"
    },
    {
        "id": "a0eb7955-9608-3184-a1f6-0a6e085eb76c",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DE LA MUÑECA Y DE LA MANO"
    },
    {
        "id": "0f560fa5-7058-62a1-c2b3-2dfcc658624b",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DE LA PIERNA"
    },
    {
        "id": "966aa98c-75f5-393b-979b-1d404fbf18f1",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DEL ABDOMEN, DE LA REGIÓN LUMBOSACRA Y DE LA PELVIS"
    },
    {
        "id": "b913e55a-258e-0c07-f69c-4a7b0b8a78ce",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DEL ANTEBRAZO"
    },
    {
        "id": "fceddd63-1724-5b48-51ae-3a589e03f87b",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DEL HOMBRO Y DEL BRAZO"
    },
    {
        "id": "61e95d59-4746-4949-a583-09b8f9e3637d",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DEL PIE Y DEL TOBILLO"
    },
    {
        "id": "fba883c0-8df7-7f70-305a-b04019d9cd30",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES DEL TÓRAX"
    },
    {
        "id": "cfdefc17-0be1-2e3f-4f00-80edc9647921",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES MÚLTIPLES, NO ESPECIFICADOS"
    },
    {
        "id": "16c4ed77-23b9-6006-2bb2-c987b6950944",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES QUE AFECTAN EL TÓRAX CON EL ABDOMEN, LA REGIÓN LUMBOSACRA Y LA PELVIS"
    },
    {
        "id": "75b5a27e-a478-8196-d715-cd5ef5254df1",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES QUE AFECTAN LA CABEZA CON EL CUELLO"
    },
    {
        "id": "63659110-b281-d8bf-c689-d1a1c3de2119",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES QUE AFECTAN MÚLTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "338c9972-155c-699f-cedd-1e1dc8aca4e0",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES QUE AFECTAN MÚLTIPLES REGIONES DEL CUERPO"
    },
    {
        "id": "14093dd0-c751-1e12-9caf-3bd495cf8725",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES QUE AFECTAN MÚLTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) INFERIOR(ES)"
    },
    {
        "id": "5975062f-1377-1036-ea2c-96a2fa2ac818",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES QUE AFECTAN MÚLTIPLES REGIONES DEL(OS) MIEMBRO(S) SUPERIOR(ES)"
    },
    {
        "id": "9c6d3deb-fc8e-a9b7-4e22-21a2c2bdf119",
        "NOMBRE": "TRAUMATISMOS SUPERFICIALES QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"
    },
    {
        "id": "071ed03d-79f5-b3d7-46b5-9262254e5bf7",
        "NOMBRE": "TRICOESTRONGILIASIS"
    },
    {
        "id": "f0607a3a-763f-c83c-037f-688abc31e1c6",
        "NOMBRE": "TRICOMONIASIS"
    },
    {
        "id": "a837692a-68a1-ea3f-9646-f85fb09744cc",
        "NOMBRE": "TRICOMONIASIS DE OTROS SITOS"
    },
    {
        "id": "05027421-5afa-23ef-fc7e-421cc6c82961",
        "NOMBRE": "TRICOMONIASIS UROGENITAL"
    },
    {
        "id": "afbd3e6b-dee3-f755-d257-84a10d6e83e3",
        "NOMBRE": "TRICOMONIASIS, NO ESPECIFICADA"
    },
    {
        "id": "b70006b0-9bb7-6205-c5de-2e16d72605f0",
        "NOMBRE": "TRICORREXIS NUDOSA"
    },
    {
        "id": "ab2ba133-dfe1-ea0c-ca6e-730e4de500ff",
        "NOMBRE": "TRICOTILOMANÍA"
    },
    {
        "id": "ddbb902e-495e-1a9f-2ba9-009952aa45d7",
        "NOMBRE": "TRICURIASIS"
    },
    {
        "id": "348d6b9a-44b9-436d-be4f-952c5bcd6ca1",
        "NOMBRE": "TRIGONITIS"
    },
    {
        "id": "c84e5fc4-79b9-8a06-de15-03e8562d623e",
        "NOMBRE": "TRIPANOSOMIASIS AFRICANA"
    },
    {
        "id": "732b6cab-148b-3eee-2fb8-47c401250107",
        "NOMBRE": "TRIPANOSOMIASIS AFRICANA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "587e363e-ba32-0c6b-fa84-fa825f8f5808",
        "NOMBRE": "TRIPANOSOMIASIS GAMBIENSE"
    },
    {
        "id": "e1ca3428-1bfb-f4a1-ca98-ad32347cab01",
        "NOMBRE": "TRIPANOSOMIASIS RHODESIENSE"
    },
    {
        "id": "899a2fd7-d9d7-1568-66dd-8e216bdc4170",
        "NOMBRE": "TRIPLOIDÍA Y POLIPLOIDÍA"
    },
    {
        "id": "697f88c2-114c-5ef9-a2fb-e9ece00cf8ed",
        "NOMBRE": "TRIQUINOSIS"
    },
    {
        "id": "c9738f71-0309-bd15-1d39-48c96545c572",
        "NOMBRE": "TRISOMÍA 13, MOSAICO (POR FALTA DE DISYUNCIÓN MITÓTICA)"
    },
    {
        "id": "89c88119-3388-ceca-66c8-4bfc9cfa4d6c",
        "NOMBRE": "TRISOMÍA 13, POR FALTA DE DISYUNCIÓN MEIÓTICA"
    },
    {
        "id": "da253530-09d1-873b-0014-3d23ee0f0076",
        "NOMBRE": "TRISOMÍA 13, POR TRANSLOCACIÓN"
    },
    {
        "id": "d62c3d51-9a74-a8ba-5ced-5563e3f76716",
        "NOMBRE": "TRISOMÍA 18, MOSAICO (POR FALTA DE DISYUNCIÓN MITÓTICA)"
    },
    {
        "id": "b47c0513-1b6b-5510-29c4-0d3a0ce57d7e",
        "NOMBRE": "TRISOMIA 18, POR FALTA DE DISYUNCIÓN MEIÓTICA"
    },
    {
        "id": "03b351fb-18f3-5c63-8f7d-cf03dfe7fe97",
        "NOMBRE": "TRISOMÍA 18, POR TRANSLOCACIÓN"
    },
    {
        "id": "dc9da5cf-1ce5-f34f-4a1d-c6173e2dad47",
        "NOMBRE": "TRISOMÍA 21, MOSAICO (POR FALTA DE DISYUNCIÓN MITÓTICA)"
    },
    {
        "id": "9da5dd4f-46f8-6f47-a9f6-86be76dbc8e4",
        "NOMBRE": "TRISOMÍA 21, POR FALTA DE DISYUNCIÓN MEIÓTICA"
    },
    {
        "id": "9c7eb453-284b-6f02-f47d-235fe8fe2422",
        "NOMBRE": "TRISOMIA 21, POR TRANSLOCACIÓN"
    },
    {
        "id": "191da51a-d0b8-8169-6a37-f25341521c7c",
        "NOMBRE": "TRISOMÍA DE UN CROMOSOMA COMPLETO, MOSAICO (POR FALTA DE DISYUNCIÓN MITÓTICA)"
    },
    {
        "id": "5ce7d4e7-d8de-fb96-92ae-9024baaf1809",
        "NOMBRE": "TRISOMÍA DE UN CROMOSOMA COMPLETO, POR FALTA DE DISYUNCIÓN MEIÓTICA"
    },
    {
        "id": "418827a9-6046-36be-47bc-de010a378d7a",
        "NOMBRE": "TRISOMÍA PARCIAL MAYOR"
    },
    {
        "id": "bc893aca-4b48-9156-7879-593c98893744",
        "NOMBRE": "TRISOMÍA PARCIAL MENOR"
    },
    {
        "id": "c90115a5-d16c-6330-c26e-49a7fd2a4f5e",
        "NOMBRE": "TRISOMÍA Y TRISOMÍA PARCIAL DE LOS AUTOSOMAS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "894102d3-99d8-88e3-496a-1b6cd176e8f1",
        "NOMBRE": "TROMBOANGEÍTIS OBLITERANTE [BUERGER]"
    },
    {
        "id": "c28516bc-bf00-b919-d9e3-981e6d09dc20",
        "NOMBRE": "TROMBOCITOPENIA (HEMORRÁGICA) ESENCIAL"
    },
    {
        "id": "a1956b8c-72dc-6140-f341-fedc327391f1",
        "NOMBRE": "TROMBOCITOPENIA NEONATAL TRANSITORIA"
    },
    {
        "id": "e401c59c-187e-32db-1b36-415769059c42",
        "NOMBRE": "TROMBOCITOPENIA NO ESPECIFICADA"
    },
    {
        "id": "5e485df1-20ce-6806-3546-0c9022983592",
        "NOMBRE": "TROMBOCITOPENIA SECUNDARIA"
    },
    {
        "id": "150fc53d-8464-d928-3ce8-aec24356223f",
        "NOMBRE": "TROMBOCITOSIS ESENCIAL"
    },
    {
        "id": "cab6a108-078d-2e1a-ed39-b8dc47083272",
        "NOMBRE": "TROMBOFILIA PRIMARIA"
    },
    {
        "id": "c42dd4c6-e847-25e4-5eaa-db492bc37767",
        "NOMBRE": "TROMBOFLEBITIS MIGRATORIA"
    },
    {
        "id": "3ca8600c-a92f-4c22-bafa-b4c9834ef0c9",
        "NOMBRE": "TROMBOFLEBITIS SUPERFICIAL EN EL EMBARAZO"
    },
    {
        "id": "683445ce-b27c-cc22-b240-bd9015149350",
        "NOMBRE": "TROMBOFLEBITIS SUPERFICIAL EN EL PUERPERIO"
    },
    {
        "id": "edb8f064-7ae5-b84d-d33a-95f9a7b2ce3c",
        "NOMBRE": "TROMBOSIS APIÓGENA DEL SISTEMA VENOSO INTRACRANEAL"
    },
    {
        "id": "e5419dfa-b49e-ea20-383c-2955730553ff",
        "NOMBRE": "TROMBOSIS CORONARIA QUE NO RESULTA EN INFARTO DEL MIOCARDIO"
    },
    {
        "id": "b5b71a30-a505-dcd7-54d9-17fd5841b7a9",
        "NOMBRE": "TROMBOSIS DE LA AURÍCULA, APÉNDICE AURICULAR Y VENTRÍCULO COMO COMPLICACIÓN PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"
    },
    {
        "id": "110968d0-393c-0f30-2518-5823dabc4bd9",
        "NOMBRE": "TROMBOSIS DE LA VENA PORTA"
    },
    {
        "id": "8d85b302-635b-18b3-9f02-24d033c5783c",
        "NOMBRE": "TROMBOSIS INTRACARDÍACA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "06a4b8a9-06cc-ef84-d486-44a8103f024a",
        "NOMBRE": "TROMBOSIS VENOSA CEREBRAL EN EL EMBARAZO"
    },
    {
        "id": "40ae469c-bc1e-dea1-c91b-316abb10ed53",
        "NOMBRE": "TROMBOSIS VENOSA CEREBRAL EN EL PUERPERIO"
    },
    {
        "id": "c158f3d1-04d4-5e60-3eca-24ad5974f1ad",
        "NOMBRE": "TROMBOSIS VENOSA PERIANAL"
    },
    {
        "id": "7ad61119-df22-3501-989e-cbb29f4c3543",
        "NOMBRE": "TRONCO ARTERIOSO COMÚN"
    },
    {
        "id": "31b5b00e-af7a-57ed-b001-8976db08b811",
        "NOMBRE": "TUBERCULOMA MENÍNGEO"
    },
    {
        "id": "a986ba8e-fa50-10e3-2262-f478d82ad63d",
        "NOMBRE": "TUBERCULOSIS CONGÉNITA"
    },
    {
        "id": "855e3155-d9ed-d285-b576-6ec8231e72ad",
        "NOMBRE": "TUBERCULOSIS DE GANGLIOS LINFÁTICOS INTRATORÁCICOS, CONFIRMADA BACTERIOLÓGICA E HISTOLÓGICAMENTE"
    },
    {
        "id": "68bc8e6c-c5b2-5447-686e-aec426a1725f",
        "NOMBRE": "TUBERCULOSIS DE GANGLIOS LINFÁTICOS INTRATORÁCICOS, SIN MENCIÓN DE CONFIRMACIÓN BACTERIOLÓGICA O HISTOLÓGICA"
    },
    {
        "id": "582d477a-e89f-b8d4-dc09-314fc67cebe2",
        "NOMBRE": "TUBERCULOSIS DE GLÁNDULAS SUPRARRENALES"
    },
    {
        "id": "49c59177-37f6-817e-a74d-8679e4b088e8",
        "NOMBRE": "TUBERCULOSIS DE HUESOS Y ARTICULACIONES"
    },
    {
        "id": "219e7ab3-9877-e10a-08c8-84eccedb8380",
        "NOMBRE": "TUBERCULOSIS DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "7c5e34dc-6192-871c-c163-3447733b9a2d",
        "NOMBRE": "TUBERCULOSIS DE LA PIEL Y EL TEJIDO SUBCUTÁNEO"
    },
    {
        "id": "09a79bc5-dfd9-2c33-3513-57a6ae23e97a",
        "NOMBRE": "TUBERCULOSIS DE LARINGE, TRAQUEA Y BRONQUIOS, CONFIRMADA BACTERIOLÓGICA E HISTOLÓGICAMENTE"
    },
    {
        "id": "e8c01aa3-a6f1-f670-5fa7-8cf6fa087048",
        "NOMBRE": "TUBERCULOSIS DE LARINGE, TRAQUEA Y BRONQUIOS, SIN MENCIÓN DE CONFIRMACIÓN BACTERIOLÓGICA O HISTOLÓGICA"
    },
    {
        "id": "74925b60-51a7-8661-db5c-f4d0b80e688f",
        "NOMBRE": "TUBERCULOSIS DE LOS INTESTINOS, EL PERITONEO Y LOS GANGLIOS MESENTÉRICOS"
    },
    {
        "id": "02692765-9ebe-5313-d7a8-1bc8a45e4b21",
        "NOMBRE": "TUBERCULOSIS DE OTROS ÓRGANOS"
    },
    {
        "id": "c15b3bc8-7ac2-d41c-9c02-928526ba94cd",
        "NOMBRE": "TUBERCULOSIS DE OTROS ÓRGANOS ESPECIFICADOS"
    },
    {
        "id": "da094d96-545d-b698-fe2f-b4299aff7619",
        "NOMBRE": "TUBERCULOSIS DE PULMÓN, SIN EXAMEN BACTERIOLÓGICO E HISTOLÓGICO"
    },
    {
        "id": "f99e8dd1-0323-8d75-657e-6c3df20156b7",
        "NOMBRE": "TUBERCULOSIS DE PULMÓN, SIN MENCIÓN DE CONFIRMACIÓN BACTERIOLÓGICA O HISTOLÓGICA"
    },
    {
        "id": "d1672137-164f-3724-a0af-6ac4189fb233",
        "NOMBRE": "TUBERCULOSIS DEL APARATO GENITOURINARIO"
    },
    {
        "id": "87f42b1d-80d2-fae8-bcc1-3ce5d2a0563d",
        "NOMBRE": "TUBERCULOSIS DEL OÍDO"
    },
    {
        "id": "b7eba6ac-ef89-7d84-91fe-2ea028729585",
        "NOMBRE": "TUBERCULOSIS DEL OJO"
    },
    {
        "id": "5917f3c4-daa8-0f97-eaf8-506a2b3777eb",
        "NOMBRE": "TUBERCULOSIS DEL PULMÓN, CON EXAMEN BACTERIOLÓGICO E HISTOLÓGICO NEGATIVOS"
    },
    {
        "id": "6ebe7c09-a8c8-3722-0a39-23268676efea",
        "NOMBRE": "TUBERCULOSIS DEL PULMÓN, CONFIRMADA HISTOLÓGICAMENTE"
    },
    {
        "id": "8b8e5345-b3ba-c99d-380c-6d34ee80e513",
        "NOMBRE": "TUBERCULOSIS DEL PULMÓN, CONFIRMADA POR HALLAZGO MICROSCÓPICO DEL BACILO TUBERCULOSO EN ESPUTO, CON O SIN CULTIVO"
    },
    {
        "id": "41f4633e-2d71-14f7-4af6-8dd056fea9b4",
        "NOMBRE": "TUBERCULOSIS DEL PULMÓN, CONFIRMADA POR MEDIOS NO ESPECIFICADOS"
    },
    {
        "id": "44e0f414-cf6f-e70b-01e0-52ed16389591",
        "NOMBRE": "TUBERCULOSIS DEL PULMÓN, CONFIRMADA ÚNICAMENTE POR CULTIVO"
    },
    {
        "id": "98a0d518-e8ba-ad54-71e8-ebe2df5f8693",
        "NOMBRE": "TUBERCULOSIS DEL SISTEMA NERVIOSO"
    },
    {
        "id": "08858381-e5c4-8253-5e0f-465d06c50d65",
        "NOMBRE": "TUBERCULOSIS DEL SISTEMA NERVIOSO, NO ESPECIFICADA"
    },
    {
        "id": "b04ca90c-d326-7a5c-108a-17929193473c",
        "NOMBRE": "TUBERCULOSIS MILIAR"
    },
    {
        "id": "3cdcfa6a-e724-ad54-3d3e-354e330be37e",
        "NOMBRE": "TUBERCULOSIS MILIAR AGUDA DE SITIOS MÚLTIPLES"
    },
    {
        "id": "b3b765ba-b14c-4e02-b8ea-6caf6ecc8eb6",
        "NOMBRE": "TUBERCULOSIS MILIAR AGUDA DE UN SOLO SITIO ESPECIFICADO"
    },
    {
        "id": "b3ea441d-9441-f5cc-080c-0f099cc4e412",
        "NOMBRE": "TUBERCULOSIS MILIAR AGUDA, NO ESPECIFICADA"
    },
    {
        "id": "0cd1e817-6094-46e9-3e1b-7879ca2d6972",
        "NOMBRE": "TUBERCULOSIS MILIAR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "6494d3de-3c4e-3ca4-5ac2-6fd4600af940",
        "NOMBRE": "TUBERCULOSIS ÓSEA"
    },
    {
        "id": "23535b0f-e16f-680a-7f79-9b5df9804336",
        "NOMBRE": "TUBERCULOSIS QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"
    },
    {
        "id": "fe234c8f-bbc0-5eb0-fa57-105cf06f5c6c",
        "NOMBRE": "TUBERCULOSIS RESPIRATORIA NO ESPECIFICADA, CONFIRMADA BACTERIOLÓGICA E HISTOLÓGICAMENTE"
    },
    {
        "id": "5881fec8-4beb-518d-a69c-701c9cac0b0a",
        "NOMBRE": "TUBERCULOSIS RESPIRATORIA NO ESPECIFICADA, SIN MENCIÓN DE CONFIRMACIÓN BACTERIOLÓGICA O HISTOLÓGICA"
    },
    {
        "id": "3c027586-17fb-a907-9abb-103b304f59ba",
        "NOMBRE": "TUBERCULOSIS RESPIRATORIA PRIMARIA, CONFIRMADA BACTERIOLÓGICA E HISTOLÓGICAMENTE"
    },
    {
        "id": "23d1fed1-d3c9-2fae-7f31-d013a9dc9306",
        "NOMBRE": "TUBERCULOSIS RESPIRATORIA PRIMARIA, SIN MENCIÓN DE CONFIRMACIÓN BACTERIOLÓGICA O HISTOLÓGICA"
    },
    {
        "id": "537f4385-bd58-354e-28a9-614b2ca770bc",
        "NOMBRE": "TUBERCULOSIS RESPIRATORIA, CONFIRMADA BACTERIOLÓGICA E HISTOLÓGICAMENTE"
    },
    {
        "id": "91fbd35f-672a-b4c8-f352-52a9ccfa03fe",
        "NOMBRE": "TUBERCULOSIS RESPIRATORIA, NO CONFIRMADA BACTERIOLÓGICA O HISTOLÓGICAMENTE"
    },
    {
        "id": "94b710a1-b6e2-02f1-349d-1b3442cec35b",
        "NOMBRE": "TUBO ENDOTRAQUEAL COLOCADO ERRÓNEAMENTE DURANTE PROCEDIMIENTO ANESTÉSICO"
    },
    {
        "id": "9d0b3203-8e25-0987-1f52-c8148add02f8",
        "NOMBRE": "TUBOPLASTIA O VASOPLASTIA POSTERIOR A ESTERILIZACIÓN"
    },
    {
        "id": "3bedb262-f9c7-4d77-7e43-e08de249260a",
        "NOMBRE": "TULAREMIA"
    },
    {
        "id": "ea114ecf-ff58-34ee-845b-f4d6430d1834",
        "NOMBRE": "TULAREMIA GASTROINTESTINAL"
    },
    {
        "id": "7774eee3-23fa-71c0-7952-7b2294b5f582",
        "NOMBRE": "TULAREMIA GENERALIZADA"
    },
    {
        "id": "ac8aa47a-7037-45bd-a5bb-b1fb39126fa8",
        "NOMBRE": "TULAREMIA OCULOGLANDULAR"
    },
    {
        "id": "25735e1d-5850-3eeb-986b-9cb7b1d701ae",
        "NOMBRE": "TULAREMIA PULMONAR"
    },
    {
        "id": "112aaba7-9f36-0b0f-60fe-155110e0adb9",
        "NOMBRE": "TULAREMIA ULCEROGLANDULAR"
    },
    {
        "id": "564f71e6-d9cf-05bf-2f54-7e1ff11ef833",
        "NOMBRE": "TULAREMIA, NO ESPECIFICADA"
    },
    {
        "id": "cc2381cc-348a-5ada-2836-6a8714592c10",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO LOCALIZADOS"
    },
    {
        "id": "51447e4e-d767-2399-d111-fbdda4639b21",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA INTRAABDOMINAL Y PÉLVICA"
    },
    {
        "id": "37ed4eb1-5ba7-2df7-a1f6-bb8e1942799e",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA LOCALIZADA EN EL CUELLO"
    },
    {
        "id": "b4d44a2d-1e67-17ac-f074-e64e6791eedc",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA LOCALIZADA EN EL MIEMBRO INFERIOR"
    },
    {
        "id": "5958b261-00e1-6339-e80b-647bd4077f04",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA LOCALIZADA EN EL MIEMBRO SUPERIOR"
    },
    {
        "id": "5c3e739d-fd59-7f66-6321-48a69cb022e6",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA LOCALIZADA EN EL TRONCO"
    },
    {
        "id": "d64610b5-9cf1-6bfa-7be5-e4b90470a357",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA LOCALIZADA EN LA CABEZA"
    },
    {
        "id": "149592e8-d9a3-ce49-d547-c882aa3b15a2",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA LOCALIZADA EN PARTE NO ESPECIFICADA"
    },
    {
        "id": "d2109e89-5b55-ecff-ea79-0803ed44b1e0",
        "NOMBRE": "TUMEFACCIÓN, MASA O PROMINENCIA LOCALIZADA EN SITIOS MÚLTIPLES"
    },
    {
        "id": "07f9da0a-648e-55b0-4c8b-c48d7aeb61cc",
        "NOMBRE": "TUMOR BENIGNO DE GLÁNDULA ENDOCRINA NO ESPECIFICADA"
    },
    {
        "id": "bb716f65-0285-6aa1-ab6b-39bb499eb0cf",
        "NOMBRE": "TUMOR BENIGNO DE LA AMÍGDALA"
    },
    {
        "id": "7ba462e1-e4da-1287-36ce-46c7c8f232d1",
        "NOMBRE": "TUMOR BENIGNO DE LA BOCA Y DE LA FARINGE"
    },
    {
        "id": "b560f4fe-4f87-40ef-512d-e905a3919aca",
        "NOMBRE": "TUMOR BENIGNO DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "65a140ba-fc19-aa12-95a5-8c7c4aaee217",
        "NOMBRE": "TUMOR BENIGNO DE LA CONJUNTIVA"
    },
    {
        "id": "c0c43e13-914c-5e95-2050-f757e1b4ed6b",
        "NOMBRE": "TUMOR BENIGNO DE LA CÓRNEA"
    },
    {
        "id": "b8ace978-36f7-4007-ca96-dcb9c2474d06",
        "NOMBRE": "TUMOR BENIGNO DE LA COROIDES"
    },
    {
        "id": "e7ac5520-b6fe-d7b1-5d6a-281a516d29d8",
        "NOMBRE": "TUMOR BENIGNO DE LA FARINGE, PARTE NO ESPECIFICADA"
    },
    {
        "id": "bced181c-afb2-dfd3-e4af-a34ca3e62d4d",
        "NOMBRE": "TUMOR BENIGNO DE LA GLÁNDULA PARATIROIDES"
    },
    {
        "id": "ee52c5ae-b089-cc1c-9e65-fbd2ffd37d58",
        "NOMBRE": "TUMOR BENIGNO DE LA GLÁNDULA PARÓTIDA"
    },
    {
        "id": "a635e519-65bb-9d40-e7f3-fb3555579146",
        "NOMBRE": "TUMOR BENIGNO DE LA GLÁNDULA PINEAL"
    },
    {
        "id": "7c32b5fe-f025-abc9-e377-3a67f603ce1a",
        "NOMBRE": "TUMOR BENIGNO DE LA GLÁNDULA SALIVAL MAYOR, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "8c36ceea-0acb-7fba-3535-b668c8429669",
        "NOMBRE": "TUMOR BENIGNO DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "d46a48e8-228e-18d2-f9bf-569ca247c9e0",
        "NOMBRE": "TUMOR BENIGNO DE LA GLÁNDULA TIROIDES"
    },
    {
        "id": "6f290823-cffb-a214-d030-1eafa2779219",
        "NOMBRE": "TUMOR BENIGNO DE LA HIPOFARINGE"
    },
    {
        "id": "b0a93d15-28aa-1fe6-a218-08c6edfe8686",
        "NOMBRE": "TUMOR BENIGNO DE LA HIPÓFISIS"
    },
    {
        "id": "ec696e08-0449-b981-f2ed-953acf9cba44",
        "NOMBRE": "TUMOR BENIGNO DE LA LARINGE"
    },
    {
        "id": "51d0937e-3f68-d09a-fecb-70a63bf6bcb5",
        "NOMBRE": "TUMOR BENIGNO DE LA LENGUA"
    },
    {
        "id": "6e26386a-bfcf-1fac-9b7c-37f4ef4fc6c5",
        "NOMBRE": "TUMOR BENIGNO DE LA MAMA"
    },
    {
        "id": "8252b8be-c7c7-8b87-834b-77d82666839d",
        "NOMBRE": "TUMOR BENIGNO DE LA MÉDULA ESPINAL"
    },
    {
        "id": "0ff89a7e-c622-6833-47b6-4e695f0885aa",
        "NOMBRE": "TUMOR BENIGNO DE LA NASOFARINGE"
    },
    {
        "id": "c56a641c-fc60-205f-3498-773ca4188925",
        "NOMBRE": "TUMOR BENIGNO DE LA ÓRBITA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "6b9d5957-3729-8f2a-3c88-ab8e5dc15b46",
        "NOMBRE": "TUMOR BENIGNO DE LA PELVIS RENAL"
    },
    {
        "id": "de8872ce-36c4-e7de-0aa4-6ae513fa8382",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"
    },
    {
        "id": "288a4bb4-e677-0cfe-0675-d91c8c2825a0",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"
    },
    {
        "id": "27861818-5565-8cb9-6933-ef2f3e731d59",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DEL CUERO CABELLUDO Y DEL CUELLO"
    },
    {
        "id": "39c532ed-1fb7-a21b-af44-bf1eaadf120d",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DEL LABIO"
    },
    {
        "id": "33a906da-19b5-483e-6b8b-aff2338690db",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "fa804c0e-5748-6543-c9fa-1edab852605a",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "1e7d1ed6-92b4-2663-a5c9-c5b7a8156f66",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DEL PÁRPADO, INCLUIDA LA COMISURA PALPEBRAL"
    },
    {
        "id": "d2c3e7ef-e133-47b2-1ea7-b481a1f08ae1",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL DEL TRONCO"
    },
    {
        "id": "59c39df0-3476-124d-23c0-5b75fbf1806a",
        "NOMBRE": "TUMOR BENIGNO DE LA PIEL, SITIO NO ESPECIFICADO"
    },
    {
        "id": "72a69c8f-487c-f235-f0dd-38aa72248396",
        "NOMBRE": "TUMOR BENIGNO DE LA PRÓSTATA"
    },
    {
        "id": "4f40749c-4fa6-ec6e-6a2d-8a35d0d7cda1",
        "NOMBRE": "TUMOR BENIGNO DE LA RETINA"
    },
    {
        "id": "45a9591b-96db-681d-8451-3e315a7cd2c1",
        "NOMBRE": "TUMOR BENIGNO DE LA TRÁQUEA"
    },
    {
        "id": "75899410-b348-d85c-6616-5ad483d2edae",
        "NOMBRE": "TUMOR BENIGNO DE LA TROMPA DE FALOPIO Y DE LOS LIGAMENTOS UTERINOS"
    },
    {
        "id": "f2ad8227-7a60-11c8-6f7f-2b07895241ad",
        "NOMBRE": "TUMOR BENIGNO DE LA UNIÓN RECTOSIGMOIDEA"
    },
    {
        "id": "daaae06b-b536-da5b-490a-4b8c9ede27d9",
        "NOMBRE": "TUMOR BENIGNO DE LA URETRA"
    },
    {
        "id": "21c4df27-66aa-f3a4-f8ec-405913639f1e",
        "NOMBRE": "TUMOR BENIGNO DE LA VAGINA"
    },
    {
        "id": "fa08bd22-7c57-9c3a-b424-7bcea7714adb",
        "NOMBRE": "TUMOR BENIGNO DE LA VEJIGA"
    },
    {
        "id": "28099bf1-3a72-3e22-4037-65468ad8b3d3",
        "NOMBRE": "TUMOR BENIGNO DE LA VULVA"
    },
    {
        "id": "c598600c-5680-8d62-5647-d0e85cacdfce",
        "NOMBRE": "TUMOR BENIGNO DE LAS COSTILLAS, ESTERNÓN Y CLAVÍCULA"
    },
    {
        "id": "0ba11ac4-ae26-9c84-da65-10e92a5284b1",
        "NOMBRE": "TUMOR BENIGNO DE LAS GLÁNDULAS SALIVALES MAYORES"
    },
    {
        "id": "bff18a79-e1e5-f652-1c74-193ac168f0ce",
        "NOMBRE": "TUMOR BENIGNO DE LAS GLÁNDULAS Y DE LOS CONDUCTOS LAGRIMALES"
    },
    {
        "id": "54a3a903-4ba6-7f9e-123a-c22403fb4bb5",
        "NOMBRE": "TUMOR BENIGNO DE LAS MENINGES CEREBRALES"
    },
    {
        "id": "21080613-19b7-f592-3c67-b6d50ae36c3c",
        "NOMBRE": "TUMOR BENIGNO DE LAS MENINGES RAQUÍDEAS"
    },
    {
        "id": "a3a008e6-7834-a6b8-b228-0f61acb83706",
        "NOMBRE": "TUMOR BENIGNO DE LAS MENINGES, PARTE NO ESPECIFICADA"
    },
    {
        "id": "f4e76c41-9387-aa11-4f00-58cd88b36852",
        "NOMBRE": "TUMOR BENIGNO DE LAS VÍAS BILIARES EXTRAHEPÁTICAS"
    },
    {
        "id": "e542d969-b666-808c-e191-c107fa0d4cdf",
        "NOMBRE": "TUMOR BENIGNO DE LOS BRONQUIOS Y DEL PULMÓN"
    },
    {
        "id": "c0fee19b-49c4-18b4-3b96-9b5382bb3b3e",
        "NOMBRE": "TUMOR BENIGNO DE LOS GANGLIOS LINFÁTICOS"
    },
    {
        "id": "75c3348b-ad85-6354-286f-50fd92a141d9",
        "NOMBRE": "TUMOR BENIGNO DE LOS HUESOS CORTOS DEL MIEMBRO INFERIOR"
    },
    {
        "id": "0282eb00-b80a-7551-081a-7cecf3136206",
        "NOMBRE": "TUMOR BENIGNO DE LOS HUESOS CORTOS DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "f3f85184-99f0-6e92-65d5-68faf901cfdc",
        "NOMBRE": "TUMOR BENIGNO DE LOS HUESOS DEL CRÁNEO Y DE LA CARA"
    },
    {
        "id": "84bbc1e3-0960-766c-aea6-9dc638e93404",
        "NOMBRE": "TUMOR BENIGNO DE LOS HUESOS LARGOS DEL MIEMBRO INFERIOR"
    },
    {
        "id": "eabd0f6d-131e-af9e-69c8-532fe80fce09",
        "NOMBRE": "TUMOR BENIGNO DE LOS HUESOS PÉLVICOS, SACRO Y CÓCCIX"
    },
    {
        "id": "60e40806-2cc1-ef8c-dd47-148e6de464e0",
        "NOMBRE": "TUMOR BENIGNO DE LOS NERVIOS CRANEALES"
    },
    {
        "id": "b10af433-8772-86de-7a02-c903ab69374b",
        "NOMBRE": "TUMOR BENIGNO DE LOS NERVIOS PERIFÉRICOS Y DEL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "37f879c1-10a5-3648-57a4-17c42a310b90",
        "NOMBRE": "TUMOR BENIGNO DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "82caaefa-e950-b953-5fad-efd5a92e5f4d",
        "NOMBRE": "TUMOR BENIGNO DE LOS ÓRGANOS URINARIOS"
    },
    {
        "id": "1107b987-b3a5-7feb-1aa7-abfeac936fab",
        "NOMBRE": "TUMOR BENIGNO DE LOS TESTÍCULOS"
    },
    {
        "id": "346860a7-e546-9f9f-55df-cf9f601f92f7",
        "NOMBRE": "TUMOR BENIGNO DE ÓRGANO GENITAL FEMENINO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "00d4c254-4ddb-7f36-96a1-bac5c6212fa4",
        "NOMBRE": "TUMOR BENIGNO DE ÓRGANO GENITAL MASCULINO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "62f1648e-0890-f661-0801-7c1b8ad69e24",
        "NOMBRE": "TUMOR BENIGNO DE ÓRGANO INTRATORÁCICO NO ESPECIFICADO"
    },
    {
        "id": "e52444c6-5e3e-8cc3-f56c-65ac6e71de1f",
        "NOMBRE": "TUMOR BENIGNO DE ÓRGANO URINARIO NO ESPECIFICADO"
    },
    {
        "id": "62f6f880-a60a-f0b4-acf9-c2b6b70c1dd8",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS GLÁNDULAS ENDOCRINAS ESPECIFICADAS"
    },
    {
        "id": "9c965880-3913-4fde-b72e-40d137834a3e",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS GLÁNDULAS ENDOCRINAS Y DE LAS NO ESPECIFICADAS"
    },
    {
        "id": "c47e1862-68e2-346b-f5aa-8b1ba44c615d",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS GLÁNDULAS SALIVALES MAYORES ESPECIFICADAS"
    },
    {
        "id": "2b9f2f71-d329-2aae-8640-09b5b495794d",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS PARTES DE LA OROFARINGE"
    },
    {
        "id": "56ee89b7-3539-d8aa-a4d5-2fd81e6f91be",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS PARTES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "2d840535-111c-55ab-815d-038ae9f0cf88",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS PARTES ESPECIFICADAS DEL ÚTERO"
    },
    {
        "id": "80910378-e9f2-1aba-d712-0c8bc80eee5b",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS PARTES Y DE LAS MAL DEFINIDAS DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "a53abeb2-f7c8-4766-b3ef-60fd9bda69f1",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA"
    },
    {
        "id": "475d884d-e5bb-32eb-e5ea-2314c0caa385",
        "NOMBRE": "TUMOR BENIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL INTESTINO DELGADO"
    },
    {
        "id": "6237d825-dbad-f2ee-405c-7bd11bd16457",
        "NOMBRE": "TUMOR BENIGNO DE OTROS ÓRGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "f489886d-c8f9-9268-d92c-f62060c6c604",
        "NOMBRE": "TUMOR BENIGNO DE OTROS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "8a09e131-52e8-0707-8307-b0c7aba678c8",
        "NOMBRE": "TUMOR BENIGNO DE OTROS ÓRGANOS INTRATORÁCICOS ESPECIFICADOS"
    },
    {
        "id": "2f6491f9-19cc-6f19-82cc-13df81f99149",
        "NOMBRE": "TUMOR BENIGNO DE OTROS ÓRGANOS INTRATORÁCICOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "298ea85e-987d-e47e-2071-0ac53d225623",
        "NOMBRE": "TUMOR BENIGNO DE OTROS ÓRGANOS URINARIOS"
    },
    {
        "id": "87d57af4-3671-fc26-c0a2-454540a5c9dd",
        "NOMBRE": "TUMOR BENIGNO DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "b6d0d470-f20d-b427-2aaf-45b7537dfcb0",
        "NOMBRE": "TUMOR BENIGNO DE OTROS SITIOS ESPECIFICADOS DE LOS ÓRGANOS GENITALES FEMENINOS"
    },
    {
        "id": "067790cf-821d-8f52-df27-232f5d2214b1",
        "NOMBRE": "TUMOR BENIGNO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "77f019d2-2c87-4920-e45d-d1291ee2a218",
        "NOMBRE": "TUMOR BENIGNO DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "28d4f4ee-f38f-1401-7479-3d4d39044933",
        "NOMBRE": "TUMOR BENIGNO DE SITIOS MAL DEFINIDOS DEL SISTEMA DIGESTIVO"
    },
    {
        "id": "b8febf91-50c5-b134-748c-5cc0a3a47c5f",
        "NOMBRE": "TUMOR BENIGNO DEL APÉNDICE"
    },
    {
        "id": "3df09da5-d182-b800-710f-618486b65703",
        "NOMBRE": "TUMOR BENIGNO DEL CIEGO"
    },
    {
        "id": "e4f9d86d-6e0c-27d7-e8ba-b3052e171c78",
        "NOMBRE": "TUMOR BENIGNO DEL COLON ASCENDENTE"
    },
    {
        "id": "7a34e8f9-d35b-38ea-2823-75757875bdbb",
        "NOMBRE": "TUMOR BENIGNO DEL COLON DESCENDENTE"
    },
    {
        "id": "e2207469-3ff2-5882-3d04-0260979e2743",
        "NOMBRE": "TUMOR BENIGNO DEL COLON SIGMOIDE"
    },
    {
        "id": "9b891f37-83de-05e9-a06e-a04a631e444c",
        "NOMBRE": "TUMOR BENIGNO DEL COLON TRANSVERSO"
    },
    {
        "id": "f5059140-7227-1008-cfa9-a656949d4c0b",
        "NOMBRE": "TUMOR BENIGNO DEL COLON, DEL RECTO, DEL CONDUCTO ANAL Y DEL ANO"
    },
    {
        "id": "f46862a2-df06-9c04-c23a-6bfb2ae1cf3b",
        "NOMBRE": "TUMOR BENIGNO DEL COLON, PARTE NO ESPECIFICADA"
    },
    {
        "id": "80c24e90-0a71-405f-1ef4-72efdcbc4d5b",
        "NOMBRE": "TUMOR BENIGNO DEL CONDUCTO ANAL Y DEL ANO"
    },
    {
        "id": "462f34ef-5491-2844-e526-856353d39582",
        "NOMBRE": "TUMOR BENIGNO DEL CONDUCTO CRANEOFARINGEO"
    },
    {
        "id": "44e813a1-0574-22f0-bc0d-7d8725d3878b",
        "NOMBRE": "TUMOR BENIGNO DEL CORAZÓN"
    },
    {
        "id": "5d50fe16-131b-509e-1965-28f1682a560b",
        "NOMBRE": "TUMOR BENIGNO DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "d3419d97-ec76-db0a-a827-f025aae13944",
        "NOMBRE": "TUMOR BENIGNO DEL CUERPO AÓRTICO Y DE OTROS CUERPOS CROMAFINES"
    },
    {
        "id": "6ea04050-71e5-269a-b567-f40931ea2571",
        "NOMBRE": "TUMOR BENIGNO DEL CUERPO CAROTÍDEO"
    },
    {
        "id": "10a7764e-0a04-652a-9e29-4e08ac4328b0",
        "NOMBRE": "TUMOR BENIGNO DEL CUERPO CILIAR"
    },
    {
        "id": "b049e461-cab3-953f-3b41-ec1f432ec52d",
        "NOMBRE": "TUMOR BENIGNO DEL CUERPO DEL ÚTERO"
    },
    {
        "id": "2778ad41-1933-95b1-0c24-9a8ae0801114",
        "NOMBRE": "TUMOR BENIGNO DEL DUODENO"
    },
    {
        "id": "8f747e88-b0b8-af6d-46a1-75c85dfc63b5",
        "NOMBRE": "TUMOR BENIGNO DEL ENCÉFALO Y DE OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "3d97ed98-5d6c-89db-ae11-66ea8c5773d0",
        "NOMBRE": "TUMOR BENIGNO DEL ENCÉFALO, INFRATENTORIAL"
    },
    {
        "id": "cf3ca5bd-cd85-fe03-f092-061a9cad60e2",
        "NOMBRE": "TUMOR BENIGNO DEL ENCÉFALO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "085cabf7-3e72-c1d8-0401-9e45fbeaeac2",
        "NOMBRE": "TUMOR BENIGNO DEL ENCÉFALO, SUPRATENTORIAL"
    },
    {
        "id": "e12965ac-8d64-fb75-67a5-7094819f39e5",
        "NOMBRE": "TUMOR BENIGNO DEL EPIDÍDIMO"
    },
    {
        "id": "e2ad0190-f334-df02-1960-a65bdb0729ac",
        "NOMBRE": "TUMOR BENIGNO DEL ESCROTO"
    },
    {
        "id": "45217009-a765-1b46-45a3-94fa4ab46ad0",
        "NOMBRE": "TUMOR BENIGNO DEL ESÓFAGO"
    },
    {
        "id": "8caf59d3-a217-c7b2-9cbc-191db6b62a27",
        "NOMBRE": "TUMOR BENIGNO DEL ESTÓMAGO"
    },
    {
        "id": "2fd13781-4b1c-8bf8-8e28-8f46bf9e947e",
        "NOMBRE": "TUMOR BENIGNO DEL HÍGADO"
    },
    {
        "id": "eaf9f1b3-bb62-1966-0d88-a715371d4bf0",
        "NOMBRE": "TUMOR BENIGNO DEL HUESO Y DEL CARTÍLAGO ARTICULAR"
    },
    {
        "id": "c629d018-33fb-4d90-301e-3e3363f57de3",
        "NOMBRE": "TUMOR BENIGNO DEL HUESO Y DEL CARTÍLAGO ARTICULAR, SITIO NO ESPECIFICADO"
    },
    {
        "id": "c5ac3571-0c99-0bec-ade8-1b5619d6f194",
        "NOMBRE": "TUMOR BENIGNO DEL LABIO"
    },
    {
        "id": "e48abbc6-027c-2c2b-c589-67e4f23c3d31",
        "NOMBRE": "TUMOR BENIGNO DEL MAXILAR INFERIOR"
    },
    {
        "id": "504954b6-0f83-2fcb-9c95-c35759ed902c",
        "NOMBRE": "TUMOR BENIGNO DEL MEDIASTINO"
    },
    {
        "id": "5c3748d2-d999-f6c9-4dd2-05dcb8347b51",
        "NOMBRE": "TUMOR BENIGNO DEL OÍDO MEDIO Y DEL SISTEMA RESPIRATORIO"
    },
    {
        "id": "1a34c732-860b-0118-00bd-75bcc2fc56dc",
        "NOMBRE": "TUMOR BENIGNO DEL OÍDO MEDIO, DE LA CAVIDAD NASAL Y DE LOS SENOS PARANASALES"
    },
    {
        "id": "534ca0cf-dd90-157a-82a6-e0151c03c837",
        "NOMBRE": "TUMOR BENIGNO DEL OJO Y SUS ANEXOS"
    },
    {
        "id": "872cd99e-6c39-d476-a2c4-6f2db4fe2965",
        "NOMBRE": "TUMOR BENIGNO DEL OJO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "029b5659-8e48-b863-5cf6-c54fa5f41ccb",
        "NOMBRE": "TUMOR BENIGNO DEL OMÓPLATO Y HUESOS LARGOS DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "f0e9eb05-0251-f571-1e0c-194c18cceef4",
        "NOMBRE": "TUMOR BENIGNO DEL OVARIO"
    },
    {
        "id": "52c85de3-df16-23fe-5a45-2ad6d4e8a9c8",
        "NOMBRE": "TUMOR BENIGNO DEL PÁNCREAS"
    },
    {
        "id": "19562505-3211-b653-5eea-dec1c8acc40f",
        "NOMBRE": "TUMOR BENIGNO DEL PÁNCREAS ENDOCRINO"
    },
    {
        "id": "c399e5df-7d2c-75fd-2e90-3117d703ff77",
        "NOMBRE": "TUMOR BENIGNO DEL PENE"
    },
    {
        "id": "b015414f-1668-8f8f-1330-ee64963a9f13",
        "NOMBRE": "TUMOR BENIGNO DEL PERITONEO"
    },
    {
        "id": "0b84dc78-01f1-15c1-c7e1-ffa2ee49f2f7",
        "NOMBRE": "TUMOR BENIGNO DEL PISO DE LA BOCA"
    },
    {
        "id": "3c42e89d-c42a-d3c7-7a2f-8e9bc3c1f11e",
        "NOMBRE": "TUMOR BENIGNO DEL RECTO"
    },
    {
        "id": "3de3459b-57c6-a371-0f5c-812da24bfe72",
        "NOMBRE": "TUMOR BENIGNO DEL RETROPERITONEO"
    },
    {
        "id": "8223dbe4-e9c0-728f-1ff2-5e046caf240f",
        "NOMBRE": "TUMOR BENIGNO DEL RIÑÓN"
    },
    {
        "id": "bdc32ac6-1cb2-fe09-098b-b65ecc2afb36",
        "NOMBRE": "TUMOR BENIGNO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIFICADO"
    },
    {
        "id": "0cb57ab4-509a-5fc3-e1cb-522720d5c34b",
        "NOMBRE": "TUMOR BENIGNO DEL SISTEMA RESPIRATORIO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "6d1f919e-5309-8b7b-18bb-fd0bc386b9ed",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO BLANDO DEL PERITONEO Y DEL RETROPERITONEO"
    },
    {
        "id": "2e23da93-8f85-2b8f-e480-44b6b0b373bd",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y DE OTROS TEJIDOS BLANDOS DE CABEZA, CARA Y CUELLO"
    },
    {
        "id": "362fb23b-5d3b-982c-f544-39e25caec3d0",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "b71f49b6-b4e3-a570-b899-adb6ae153fad",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "10155d04-4206-c9c5-54ad-f972a6b6134c",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL TÓRAX"
    },
    {
        "id": "9a1bb0fb-201b-ec45-8b81-b839c0d40ab3",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DE LA PELVIS"
    },
    {
        "id": "8ef0bffe-ff73-24bb-bb93-5462db460c60",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DEL ABDOMEN"
    },
    {
        "id": "fcb18f61-e4fa-dd71-0567-47fc1517fee6",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DEL TRONCO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "fcea934d-56fd-73c2-0427-27f779449872",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "17353ce2-7527-5e80-65d5-c735ceec1c7a",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO MESOTELIAL DE LA PLEURA"
    },
    {
        "id": "e1a506bd-69d8-e786-874e-0feb8c05459c",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO MESOTELIAL DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "a2a7e04d-f0b6-984b-8598-f22d7acb815f",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO MESOTELIAL DEL PERITONEO"
    },
    {
        "id": "8b266436-422d-e1bc-2ae6-ee27ad3fbaaf",
        "NOMBRE": "TUMOR BENIGNO DEL TEJIDO MESOTELIAL, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "9f5c7bdc-a071-a2c8-36be-e0606da1c458",
        "NOMBRE": "TUMOR BENIGNO DEL TIMO"
    },
    {
        "id": "628bdfac-41e5-1b20-e875-63aabcb6dda1",
        "NOMBRE": "TUMOR BENIGNO DEL URÉTER"
    },
    {
        "id": "945a6d76-8179-e292-bd6b-c9a366f7d290",
        "NOMBRE": "TUMOR BENIGNO DEL ÚTERO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "dd09c0fb-86e6-9ace-3023-7a34ff63e8d3",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DE LOS ÓRGANOS INTRAABDOMINALES"
    },
    {
        "id": "4e21ed10-9e87-07cf-3be6-1f408f64930c",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DE LOS ÓRGANOS INTRATORÁCICOS"
    },
    {
        "id": "78d9fd0c-0076-15df-8f52-f7d063afcc67",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "3252c4a4-8622-2423-58fe-53ef79e019e6",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTÁNEO DE CABEZA, CARA Y CUELLO"
    },
    {
        "id": "e210ee2d-5359-8c27-8763-91b5a5204036",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTÁNEO DE MIEMBROS"
    },
    {
        "id": "fd3e9671-e0e2-54e7-0942-ec8ca070c900",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTÁNEO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "fe1e7b05-50b8-5af6-fd43-054b3f6ae52a",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTÁNEO DEL TRONCO"
    },
    {
        "id": "e38b6c5d-9ef1-4f53-2d65-af2c3757871a",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO DEL CORDÓN ESPERMÁTICO"
    },
    {
        "id": "e1205af3-057b-c40f-5f22-3dac4e047bdf",
        "NOMBRE": "TUMOR BENIGNO LIPOMATOSO, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "c5cdf3cb-a5a5-2111-23ef-446e7f52668f",
        "NOMBRE": "TUMOR BENIGNO PLURIGLANDULAR"
    },
    {
        "id": "45868cef-9985-0526-f9b5-569924dd010c",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO CON AFECTACIÓN PLURIGLANDULAR"
    },
    {
        "id": "1d52c106-aa10-b88f-8a20-810b611253e3",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE GLÁNDULA ENDOCRINA NO ESPECIFICADA"
    },
    {
        "id": "6e60a6c0-4dbe-38b5-2d8c-67a875636429",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA CAVIDAD BUCAL Y DE LOS ÓRGANOS DIGESTIVOS"
    },
    {
        "id": "e34241ef-f7bf-8624-b231-536b10769626",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLÁNDULA HIPÓFISIS"
    },
    {
        "id": "7e65b460-0b25-6c32-0a26-0aa20f349253",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLÁNDULA PARATIROIDES"
    },
    {
        "id": "0254c1e5-4552-23b1-8d4e-3e1862adfc00",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLÁNDULA PINEAL"
    },
    {
        "id": "ac45f4a1-4f5f-e647-9f63-fb1ebf6bc5af",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "a549bde5-ffbd-7365-0501-be93d712c74d",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLÁNDULA TIROIDES"
    },
    {
        "id": "79c96c5a-9234-8349-b45a-02e90830d1ef",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MAMA"
    },
    {
        "id": "d49f5791-8303-e81f-fbdf-306a3840d63d",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MÉDULA ESPINAL"
    },
    {
        "id": "de4b22a4-8d31-abbb-a3e2-ad649bd17293",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PELVIS RENAL"
    },
    {
        "id": "264e16c2-414e-83b7-d132-4c0ab8e1f642",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PIEL"
    },
    {
        "id": "432e629d-6453-c7b2-0039-c09d23d01029",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PLACENTA"
    },
    {
        "id": "55a176d7-21e5-1045-89a0-798f3e32b39b",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PLEURA"
    },
    {
        "id": "962631f7-277f-129d-d047-9d47308214ae",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PRÓSTATA"
    },
    {
        "id": "ee0c9012-45f1-b5af-cd9e-dea66c03bc72",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA TRÁQUEA, DE LOS BRONQUIOS Y DEL PULMÓN"
    },
    {
        "id": "57cd7891-0ab9-af70-1c2c-aaecc4b85c7c",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA URETRA"
    },
    {
        "id": "059982fb-23ff-706e-c3fc-3d0f4172fe4d",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA VEJIGA"
    },
    {
        "id": "74de8a66-3b99-d6c0-4332-70a9231a24b9",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LARINGE"
    },
    {
        "id": "ba21c80e-aac6-92b2-593f-61136f8e8777",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS GLÁNDULAS ENDOCRINAS"
    },
    {
        "id": "a5a9857c-fa59-3fb8-3f18-8bf7113b60b1",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES"
    },
    {
        "id": "2de03a86-c06b-9e03-2e68-cc3f78de809e",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES CEREBRALES"
    },
    {
        "id": "e6a6fb0c-73b6-f450-0631-f761952125d7",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES RAQUÍDEAS"
    },
    {
        "id": "3804672d-64a0-5277-2f21-49a84faa781a",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES, PARTE NO ESPECIFICADA"
    },
    {
        "id": "18294cf9-f6f8-9fb4-a79c-d513909caf96",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS MASTOCITOS E HISTIOCITOS"
    },
    {
        "id": "33a6183e-9660-7927-a9b4-30bf70847eb4",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS NERVIOS CRANEALES"
    },
    {
        "id": "cbc4c184-435d-bd10-4a81-cbe7dc3c71f5",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS NERVIOS PERIFÉRICOS Y DEL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "2d9f238b-bf88-d3fb-8d1a-0cb912e1cc16",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS ÓRGANOS GENITALES FEMENINOS"
    },
    {
        "id": "41f1e8ea-58c0-00ee-651a-2c0105ebbbf0",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "b06fa7fc-3d0e-d16f-1be2-e7cdc8d4bffb",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS ÓRGANOS URINARIOS"
    },
    {
        "id": "4124b585-f713-f257-8574-1a9ba80d42cd",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ÓRGANO GENITAL FEMENINO NO ESPECIFICADO"
    },
    {
        "id": "319482a7-dd49-4f41-b358-a8ff7c2db8b2",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ÓRGANO GENITAL MASCULINO NO ESPECIFICADO"
    },
    {
        "id": "81522215-2218-8b1a-f262-c2d83178ba4e",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ÓRGANO URINARIO NO ESPECIFICADO"
    },
    {
        "id": "e9d40229-097d-4b25-cb3c-c87385ea40e3",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ÓRGANOS DIGESTIVOS, SITIO NO ESPECIFICADO"
    },
    {
        "id": "e5795ed8-34c7-5988-1c5f-423c240ea5e3",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ÓRGANOS RESPIRATORIOS, SITIO NO ESPECIFICADO"
    },
    {
        "id": "3c66470c-389c-9a19-35b8-f1abc4ad8ab4",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTRAS PARTES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "06a851d3-12fb-79ec-b8d4-047bc78fb383",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ÓRGANOS DIGESTIVOS ESPECIFICADOS"
    },
    {
        "id": "7ef64521-e222-9c3c-9205-b69cf77e261a",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ÓRGANOS GENITALES FEMENINOS"
    },
    {
        "id": "8224c474-3d4f-0a90-60aa-8716f93751cb",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "d24fab02-6b60-474f-3a7c-bc441fc3e901",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ÓRGANOS RESPIRATORIOS Y DEL OÍDO MEDIO"
    },
    {
        "id": "83c070e7-fb1a-a93b-d656-f7bed9d7794a",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ÓRGANOS URINARIOS"
    },
    {
        "id": "5d81d6f7-a572-927b-9c48-33af604ffe29",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "17e582fc-6ca7-9c47-d7bf-4363f40d7272",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "abf1b72b-b147-3448-c04a-276857d6d7fa",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL APÉNDICE"
    },
    {
        "id": "fdc0ff64-9076-10f2-50c8-af2b9c1cacea",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL COLON"
    },
    {
        "id": "36423513-3931-27b4-488a-5a02626d1bbb",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CONDUCTO CRANEOFARÍNGEO"
    },
    {
        "id": "99c208d9-312b-794c-44f2-95c3ed9c8662",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CUERPO AÓRTICO Y OTROS CUERPOS CROMAFINES"
    },
    {
        "id": "efceaa70-cc97-d4b7-a4aa-7b3d2adc2376",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CUERPO CAROTÍDEO"
    },
    {
        "id": "2b29e9af-bc8a-3208-d19d-f341ec9378ff",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCÉFALO Y DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "1804a11b-b1c7-73d4-12c3-4d5fc450b6ca",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCÉFALO, INFRATENTORIAL"
    },
    {
        "id": "a38116b6-67fb-1645-43ad-41f8d97ce5ae",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCÉFALO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "c18ae711-28a0-696d-8299-99a414e09c23",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCÉFALO, SUPRATENTORIAL"
    },
    {
        "id": "0dad9f90-47d5-8957-f5e2-d659caa501ff",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ESTÓMAGO"
    },
    {
        "id": "21419a9f-c5d3-e331-05a8-5528c41e769a",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL HÍGADO, DE LA VESÍCULA BILIAR Y DEL CONDUCTO BILIAR"
    },
    {
        "id": "e13dbbff-b6ac-c33f-43e5-2110e7f69d94",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL HUESO Y CARTÍLAGO ARTICULAR"
    },
    {
        "id": "29173fbd-1381-0302-b850-66248f2f6723",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL INTESTINO DELGADO"
    },
    {
        "id": "41048088-acd3-b473-7370-d4d6bead2fb3",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE"
    },
    {
        "id": "ee76f5de-1c08-04ba-120f-ee15dd4512fc",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL MEDIASTINO"
    },
    {
        "id": "fa6e4225-45b0-2af8-0431-f646ce7446fd",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL OÍDO MEDIO Y DE LOS ÓRGANOS RESPIRATORIOS E INTRATORÁCICOS"
    },
    {
        "id": "3fd89eb0-75be-bef1-f292-95dc913ca20f",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL OVARIO"
    },
    {
        "id": "95aea704-974c-ae87-c80d-69683a947a04",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL PERITONEO"
    },
    {
        "id": "55171d45-4c67-8af1-4543-ed614d5644b5",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RECTO"
    },
    {
        "id": "e6be3e4f-de3f-a1a0-03bb-6651b96b2136",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RETROPERITONEO"
    },
    {
        "id": "5140158c-a569-dca6-7811-d23c13176c8e",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RIÑÓN"
    },
    {
        "id": "769879f0-2f6a-e4eb-f265-39f17edcba4a",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIFICADO"
    },
    {
        "id": "1c9d7970-330d-7f62-99a2-e350c1a7d098",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO CONJUNTIVO Y OTRO TEJIDO BLANDO"
    },
    {
        "id": "37205fc8-6e63-6ae0-33e2-0917195b6b78",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TESTÍCULO"
    },
    {
        "id": "6c85a40f-02ef-af82-59b6-af04d57b0492",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TIMO"
    },
    {
        "id": "98da8d29-5a3f-2d18-896d-166d1fe325c3",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL URÉTER"
    },
    {
        "id": "e6332808-214f-7838-1b75-28b59e79837a",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ÚTERO"
    },
    {
        "id": "25c0261b-4912-39de-fe9c-487d085cbba6",
        "NOMBRE": "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "a5ece118-e14b-6b32-2b0a-595a8030d702",
        "NOMBRE": "TUMOR MALIGNO DE GLÁNDULA ENDOCRINA NO ESPECIFICADA"
    },
    {
        "id": "61fa1220-eb8a-7ed2-1854-a504672290b6",
        "NOMBRE": "TUMOR MALIGNO DE GLÁNDULA SALIVAL MAYOR, NO ESPECIFICADA"
    },
    {
        "id": "7e5ee320-1960-3139-bdfe-d32e444e6777",
        "NOMBRE": "TUMOR MALIGNO DE LA AMÍGDALA"
    },
    {
        "id": "8089b0a8-cf50-6aa7-355d-6f8a1637d0f1",
        "NOMBRE": "TUMOR MALIGNO DE LA AMÍGDALA LINGUAL"
    },
    {
        "id": "8827ff0c-6428-38f9-5083-58069f5e7421",
        "NOMBRE": "TUMOR MALIGNO DE LA AMÍGDALA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "1a6c7189-0fe5-db54-29dd-4fc709ca9329",
        "NOMBRE": "TUMOR MALIGNO DE LA AMPOLLA DE VATER"
    },
    {
        "id": "8da2420b-e42d-6a9f-2f01-45a579e4e311",
        "NOMBRE": "TUMOR MALIGNO DE LA BASE DE LA LENGUA"
    },
    {
        "id": "5e05c1c5-ebcb-c649-2bc6-498bb26406e9",
        "NOMBRE": "TUMOR MALIGNO DE LA BOCA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "72446df1-57f0-db54-f123-0e7f0a3c70a8",
        "NOMBRE": "TUMOR MALIGNO DE LA CABEZA DEL PÁNCREAS"
    },
    {
        "id": "e239c4d0-75a3-ec1f-4945-577c56b8f79a",
        "NOMBRE": "TUMOR MALIGNO DE LA CABEZA, CARA Y CUELLO"
    },
    {
        "id": "32ae496f-0d0c-4365-2f18-fb7e3383c245",
        "NOMBRE": "TUMOR MALIGNO DE LA CARA ANTERIOR DE LA EPIGLOTIS"
    },
    {
        "id": "df821538-af45-2683-5e89-8cb67b027dff",
        "NOMBRE": "TUMOR MALIGNO DE LA CARA DORSAL DE LA LENGUA"
    },
    {
        "id": "2a84b62f-3cdf-00c4-5cdc-865c6b6967bd",
        "NOMBRE": "TUMOR MALIGNO DE LA CARA VENTRAL DE LA LENGUA"
    },
    {
        "id": "9569674e-52f0-18d4-cdf1-b8112082e09f",
        "NOMBRE": "TUMOR MALIGNO DE LA COLA DE CABALLO"
    },
    {
        "id": "8f7856e0-52ff-181b-4adf-00f0832af4e3",
        "NOMBRE": "TUMOR MALIGNO DE LA COLA DEL PÁNCREAS"
    },
    {
        "id": "4528b5bd-e7e2-a336-6352-fa68e8c232ba",
        "NOMBRE": "TUMOR MALIGNO DE LA COLUMNA VERTEBRAL"
    },
    {
        "id": "06a8921b-43a8-c000-40c5-6a7860a7978a",
        "NOMBRE": "TUMOR MALIGNO DE LA COMISURA LABIAL"
    },
    {
        "id": "a1eb8ba5-a823-dfe6-4549-29654517e834",
        "NOMBRE": "TUMOR MALIGNO DE LA CONJUNTIVA"
    },
    {
        "id": "d44d0285-45fc-7320-fa1a-14e86d5ab8cc",
        "NOMBRE": "TUMOR MALIGNO DE LA CÓRNEA"
    },
    {
        "id": "ca99e781-783f-0721-6b25-790ed5078dbd",
        "NOMBRE": "TUMOR MALIGNO DE LA COROIDES"
    },
    {
        "id": "4900622b-be1e-bb49-ddf0-323d0f024b22",
        "NOMBRE": "TUMOR MALIGNO DE LA CORTEZA DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "28927e74-637a-19cc-a017-a58c43fc2d75",
        "NOMBRE": "TUMOR MALIGNO DE LA COSTILLA, ESTERNÓN Y CLAVÍCULA"
    },
    {
        "id": "db327897-f04d-ff1b-7f4b-20b2fea63ad7",
        "NOMBRE": "TUMOR MALIGNO DE LA CÚPULA VESICAL"
    },
    {
        "id": "098e9260-fd9b-db85-f9ef-2c5a01e65ed1",
        "NOMBRE": "TUMOR MALIGNO DE LA CURVATURA MAYOR DEL ESTÓMAGO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "352c6551-cd4d-a941-9ec7-8c71604f7a73",
        "NOMBRE": "TUMOR MALIGNO DE LA CURVATURA MENOR DEL ESTÓMAGO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "262a4661-6871-92bb-ea3e-148de027b15e",
        "NOMBRE": "TUMOR MALIGNO DE LA ENCÍA"
    },
    {
        "id": "23996c52-0646-acf0-86f4-0b15890d137e",
        "NOMBRE": "TUMOR MALIGNO DE LA ENCÍA INFERIOR"
    },
    {
        "id": "0a9f6fc7-492d-41c2-6184-180981dc1897",
        "NOMBRE": "TUMOR MALIGNO DE LA ENCÍA SUPERIOR"
    },
    {
        "id": "ff8f3fa2-ec49-f662-618a-bfee51308ed9",
        "NOMBRE": "TUMOR MALIGNO DE LA ENCÍA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "2da7131d-6903-6ed1-202f-de36cc790e10",
        "NOMBRE": "TUMOR MALIGNO DE LA FARINGE, PARTE NO ESPECIFICADA"
    },
    {
        "id": "25b5f072-8706-43b8-337e-09f09b0bafbe",
        "NOMBRE": "TUMOR MALIGNO DE LA FOSA AMIGDALINA"
    },
    {
        "id": "ebb7d02b-7ebc-94fe-ae99-e22ea9c04bcc",
        "NOMBRE": "TUMOR MALIGNO DE LA FOSA NASAL"
    },
    {
        "id": "ace0d6a7-3228-3504-30ac-59f591569085",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA PARATIROIDES"
    },
    {
        "id": "b0a6c358-195c-02e5-14ed-4be817039b8f",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA PARÓTIDA"
    },
    {
        "id": "1747a858-4713-55cc-8b45-7a812e3712ef",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA PINEAL"
    },
    {
        "id": "9e33b62b-8d6b-a686-0b5c-a7b41bd41dc6",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA SUBLINGUAL"
    },
    {
        "id": "9cbbb5a0-74ab-344c-0c34-a302825deb93",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA SUBMAXILAR"
    },
    {
        "id": "3188fb0d-d8f4-1121-348f-f178470217af",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "ee71736d-1ff7-7baa-dbb6-b027b116de6b",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA SUPRARRENAL, PARTE NO ESPECIFICADA"
    },
    {
        "id": "5007e4ee-b2f5-f6bb-8215-3519afbd67b8",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA TIROIDES"
    },
    {
        "id": "f5cbaee0-93fe-d24a-07dc-cd715fc82aad",
        "NOMBRE": "TUMOR MALIGNO DE LA GLÁNDULA Y CONDUCTO LAGRIMALES"
    },
    {
        "id": "9663890b-3c95-93fa-6a0a-aa8038485b62",
        "NOMBRE": "TUMOR MALIGNO DE LA GLOTIS"
    },
    {
        "id": "49e398b3-71df-12da-4836-a019d6b7701b",
        "NOMBRE": "TUMOR MALIGNO DE LA HENDIDURA BRANQUIAL"
    },
    {
        "id": "2fe41d0e-7bed-1ffc-4caf-ed5bfab37d71",
        "NOMBRE": "TUMOR MALIGNO DE LA HIPOFARINGE"
    },
    {
        "id": "2c7f6b01-b37a-7455-9201-26d51d20103c",
        "NOMBRE": "TUMOR MALIGNO DE LA HIPOFARINGE, PARTE NO ESPECIFICADA"
    },
    {
        "id": "3488f011-2ed7-b3f2-6ea8-85b438011844",
        "NOMBRE": "TUMOR MALIGNO DE LA HIPÓFISIS"
    },
    {
        "id": "e2d5ac0d-4ea4-75cc-a625-4341d54a58df",
        "NOMBRE": "TUMOR MALIGNO DE LA LARINGE"
    },
    {
        "id": "3a85c132-50a1-3b85-6769-d6a0e4e5b390",
        "NOMBRE": "TUMOR MALIGNO DE LA LARINGE, PARTE NO ESPECIFICADA"
    },
    {
        "id": "a6867539-f6ad-6c5e-236c-0a2303f40532",
        "NOMBRE": "TUMOR MALIGNO DE LA LENGUA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "cab6271a-4d4d-2d12-4cce-daba2bb9fb58",
        "NOMBRE": "TUMOR MALIGNO DE LA MAMA"
    },
    {
        "id": "edd0e382-6143-d15e-3191-82d1b0185e0d",
        "NOMBRE": "TUMOR MALIGNO DE LA MAMA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "978a3f06-60c5-3ed7-232b-02427ec6891f",
        "NOMBRE": "TUMOR MALIGNO DE LA MÉDULA DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "8737b68b-0635-f86f-8606-9538708fffc8",
        "NOMBRE": "TUMOR MALIGNO DE LA MÉDULA ESPINAL"
    },
    {
        "id": "faa0cd89-b815-e237-1e45-5f166bf3306c",
        "NOMBRE": "TUMOR MALIGNO DE LA MÉDULA ESPINAL, DE LOS NERVIOS CRANEALES Y DE OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL"
    },
    {
        "id": "48398787-3143-7d99-728e-d724c9e93bf4",
        "NOMBRE": "TUMOR MALIGNO DE LA MUCOSA DE LA MEJILLA"
    },
    {
        "id": "a48a5ada-9abb-a561-d056-0597a2977f1c",
        "NOMBRE": "TUMOR MALIGNO DE LA NASOFARINGE"
    },
    {
        "id": "4b32a58f-e67c-eaf1-256e-13217d5f22aa",
        "NOMBRE": "TUMOR MALIGNO DE LA NASOFARINGE, PARTE NO ESPECIFICADA"
    },
    {
        "id": "e87f0515-bfb4-f36f-6ac8-f9af9eb62071",
        "NOMBRE": "TUMOR MALIGNO DE LA ÓRBITA"
    },
    {
        "id": "ed949ca0-7efc-8ae8-4123-764039530f7d",
        "NOMBRE": "TUMOR MALIGNO DE LA OROFARINGE"
    },
    {
        "id": "d8bb5688-89b7-99e9-60af-1677f5125dbd",
        "NOMBRE": "TUMOR MALIGNO DE LA OROFARINGE, PARTE NO ESPECIFICADA"
    },
    {
        "id": "13bf3b2a-fc1b-213d-2a67-e9a039fc3dee",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED ANTERIOR DE LA NASOFARINGE"
    },
    {
        "id": "78565de2-6e28-ecc4-4f6c-da6f7d16312b",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED ANTERIOR DE LA VEJIGA"
    },
    {
        "id": "6ac47f85-b992-7d81-02b2-2ff4e6376bc3",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED LATERAL DE LA NASOFARINGE"
    },
    {
        "id": "65d3db75-fddf-c538-1f3b-749d6082c5e6",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED LATERAL DE LA OROFARINGE"
    },
    {
        "id": "da196bd9-eb63-6d73-40d7-b9a0e9a9ec1f",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED LATERAL DE LA VEJIGA"
    },
    {
        "id": "11f74726-e5df-735c-0b2c-da6999162da8",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA HIPOFARINGE"
    },
    {
        "id": "338d432d-4c7e-8267-bdd8-eea906ced956",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA NASOFARINGE"
    },
    {
        "id": "e76ae47b-3dce-25b6-c291-b66233371aac",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA OROFARINGE"
    },
    {
        "id": "09bec4a2-ea28-6c8c-309b-3b6ece146d77",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA VEJIGA"
    },
    {
        "id": "be862d86-cab0-afc4-9316-a28f867d56d5",
        "NOMBRE": "TUMOR MALIGNO DE LA PARED SUPERIOR DE LA NASOFARINGE"
    },
    {
        "id": "5cdb551c-071f-f37a-e48c-ba179ded2105",
        "NOMBRE": "TUMOR MALIGNO DE LA PARTE ANTERIOR DEL PISO DE LA BOCA"
    },
    {
        "id": "e3d15c47-8bc8-177d-0c97-2d0bc73d6afd",
        "NOMBRE": "TUMOR MALIGNO DE LA PARTE LATERAL DEL PISO DE LA BOCA"
    },
    {
        "id": "57e574bf-1865-7dfd-4b7a-d4e44f81d4a9",
        "NOMBRE": "TUMOR MALIGNO DE LA PELVIS"
    },
    {
        "id": "1bafe3eb-2ed3-b9aa-fb23-582b80962706",
        "NOMBRE": "TUMOR MALIGNO DE LA PELVIS RENAL"
    },
    {
        "id": "78e32be6-28ee-5d5a-f0dd-61ca581dd6a3",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"
    },
    {
        "id": "ef092fb0-ce04-c35b-25c4-cce23e0f38ab",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"
    },
    {
        "id": "f110aeba-23d9-db6b-8dd6-5c13cf7ef68d",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL DEL CUERO CABELLUDO Y DEL CUELLO"
    },
    {
        "id": "37545824-c07d-6e2a-355e-202a2955358d",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL DEL LABIO"
    },
    {
        "id": "ec7c1a8f-aec1-94b9-7dc1-e54a30f4b028",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "e47cc2e6-af7d-b0a7-6c0f-a10b83cb1fcc",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL DEL PÁRPADO, INCLUIDA LA COMISURA PALPEBRAL"
    },
    {
        "id": "bad1d12e-ef40-2097-cc40-6cb978ab6891",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL DEL TRONCO"
    },
    {
        "id": "2e2645e6-269f-7ee7-13fe-02ed60798170",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "4f803d5a-e23c-ac6c-9c06-e1fab9769228",
        "NOMBRE": "TUMOR MALIGNO DE LA PIEL, SITIO NO ESPECIFICADO"
    },
    {
        "id": "60a0c99b-dfed-5944-0ead-4f120c58ab8e",
        "NOMBRE": "TUMOR MALIGNO DE LA PLACENTA"
    },
    {
        "id": "37b4e5a4-bfd8-b7c6-0627-ceb02bba19f6",
        "NOMBRE": "TUMOR MALIGNO DE LA PLEURA"
    },
    {
        "id": "65a2e5f0-4601-691c-c2a4-2e4ef35d700f",
        "NOMBRE": "TUMOR MALIGNO DE LA PORCIÓN CENTRAL DE LA MAMA"
    },
    {
        "id": "beffc573-dc40-8be3-883e-9fcfad0a04c9",
        "NOMBRE": "TUMOR MALIGNO DE LA PROLONGACIÓN AXILAR DE LA MAMA"
    },
    {
        "id": "924ef297-c623-8844-e9ed-4fca046dfe16",
        "NOMBRE": "TUMOR MALIGNO DE LA PRÓSTATA"
    },
    {
        "id": "bb5be4c0-2e91-5c8d-8efd-1b4158fe191a",
        "NOMBRE": "TUMOR MALIGNO DE LA REGIÓN POSTCRICOIDEA"
    },
    {
        "id": "f35ecfa9-c4b9-cbe4-b707-fff0f692395b",
        "NOMBRE": "TUMOR MALIGNO DE LA REGIÓN SUBGLÓTICA"
    },
    {
        "id": "98f45f0b-3b3b-7900-b87b-4bf42d0752a3",
        "NOMBRE": "TUMOR MALIGNO DE LA REGIÓN SUPRAGLÓTICA"
    },
    {
        "id": "10756f78-70cb-1050-4502-0182d1c731fc",
        "NOMBRE": "TUMOR MALIGNO DE LA RETINA"
    },
    {
        "id": "8552ea43-2a14-a157-8ec6-cdd13f8cb5e7",
        "NOMBRE": "TUMOR MALIGNO DE LA TRÁQUEA"
    },
    {
        "id": "975f9daa-0744-3270-bddc-44ef2ec7a116",
        "NOMBRE": "TUMOR MALIGNO DE LA TROMPA DE FALOPIO"
    },
    {
        "id": "885b38d5-9d6e-866f-a8f8-bb87dd233530",
        "NOMBRE": "TUMOR MALIGNO DE LA UNIÓN RECTOSIGMOIDEA"
    },
    {
        "id": "4abfa3b7-8096-cfd2-d109-dfc29d720826",
        "NOMBRE": "TUMOR MALIGNO DE LA URETRA"
    },
    {
        "id": "a9ecf339-3934-afdf-8248-c21559acbf9d",
        "NOMBRE": "TUMOR MALIGNO DE LA ÚVULA"
    },
    {
        "id": "5156d999-815c-009c-afc3-f588a1b15735",
        "NOMBRE": "TUMOR MALIGNO DE LA VAGINA"
    },
    {
        "id": "ebb4a6d7-3cc7-fbe6-186e-bc5b34fb4968",
        "NOMBRE": "TUMOR MALIGNO DE LA VALÉCULA"
    },
    {
        "id": "e6160ee4-931c-2eaf-b307-12fd20412d36",
        "NOMBRE": "TUMOR MALIGNO DE LA VEJIGA URINARIA"
    },
    {
        "id": "6e90017b-7eba-2d48-9398-77fdeac0ec2e",
        "NOMBRE": "TUMOR MALIGNO DE LA VEJIGA URINARIA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "6f0951c3-ebfd-544d-2f90-9be718a6c6fe",
        "NOMBRE": "TUMOR MALIGNO DE LA VESÍCULA BILIAR"
    },
    {
        "id": "0d27b441-ddc5-dc21-5fda-6facf0a8b065",
        "NOMBRE": "TUMOR MALIGNO DE LA VÍAS RESPIRATORIA SUPERIORES, PARTE NO ESPECIFICADA"
    },
    {
        "id": "eedb9478-91ce-b502-fc9f-cbb12cc20f2c",
        "NOMBRE": "TUMOR MALIGNO DE LA VULVA"
    },
    {
        "id": "d4281659-10db-2f3d-c58a-483fe5d2766c",
        "NOMBRE": "TUMOR MALIGNO DE LA VULVA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "bd7dcebc-de02-396d-89b4-b55dcb76645a",
        "NOMBRE": "TUMOR MALIGNO DE LA ZONA CLOACOGÉNICA"
    },
    {
        "id": "13a7becc-be1b-cf48-6fff-3f872f2517a5",
        "NOMBRE": "TUMOR MALIGNO DE LAS FOSAS NASALES Y DEL OÍDO MEDIO"
    },
    {
        "id": "d09bd986-d493-8498-1213-d068e96318a1",
        "NOMBRE": "TUMOR MALIGNO DE LAS GLÁNDULAS PARAURETRALES"
    },
    {
        "id": "9a397039-a3f0-56d1-6523-9a8af64f9213",
        "NOMBRE": "TUMOR MALIGNO DE LAS MENINGES"
    },
    {
        "id": "fe967d9e-b131-f115-3e47-407c3fa72b0d",
        "NOMBRE": "TUMOR MALIGNO DE LAS MENINGES CEREBRALES"
    },
    {
        "id": "8a4f2189-f79a-00d0-de38-72069704e83e",
        "NOMBRE": "TUMOR MALIGNO DE LAS MENINGES RAQUÍDEAS"
    },
    {
        "id": "95676f88-23ca-f4c0-e8a8-ebca02036590",
        "NOMBRE": "TUMOR MALIGNO DE LAS MENINGES, PARTE NO ESPECIFICADA"
    },
    {
        "id": "d7fed85d-7a59-4c3e-4c15-1b04ddcab8da",
        "NOMBRE": "TUMOR MALIGNO DE LAS VÍAS BILIARES EXTRAHEPÁTICAS"
    },
    {
        "id": "8e93fc70-943d-e234-f17e-56f04e64993b",
        "NOMBRE": "TUMOR MALIGNO DE LAS VÍAS BILIARES, PARTE NO ESPECIFICADA"
    },
    {
        "id": "f669bef4-93be-445c-de99-5219b41b4800",
        "NOMBRE": "TUMOR MALIGNO DE LOS ANEXOS UTERINOS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "f27758f7-8835-f832-4a25-dde915c99445",
        "NOMBRE": "TUMOR MALIGNO DE LOS BRONQUIOS O DEL PULMÓN, PARTE NO ESPECIFICADA"
    },
    {
        "id": "d8769b16-5be4-af36-fb54-f0917dba19c8",
        "NOMBRE": "TUMOR MALIGNO DE LOS BRONQUIOS Y DEL PULMÓN"
    },
    {
        "id": "05378cc4-0802-1b48-53ab-3bb0871c1f59",
        "NOMBRE": "TUMOR MALIGNO DE LOS DOS TERCIOS ANTERIORES DE LA LENGUA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "90e24463-1eca-1687-28e0-b0a223a73b07",
        "NOMBRE": "TUMOR MALIGNO DE LOS GANGLIOS LINFÁTICOS DE LA AXILA Y DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "e4199a2c-50bc-1678-fc4f-4b146e56763f",
        "NOMBRE": "TUMOR MALIGNO DE LOS GANGLIOS LINFÁTICOS DE LA CABEZA, CARA Y CUELLO"
    },
    {
        "id": "555f1611-b755-1f56-d3c6-db674088d25f",
        "NOMBRE": "TUMOR MALIGNO DE LOS GANGLIOS LINFÁTICOS DE LA PELVIS"
    },
    {
        "id": "d4276d53-b8bb-00b1-6f3a-224580480f26",
        "NOMBRE": "TUMOR MALIGNO DE LOS GANGLIOS LINFÁTICOS DE LA REGIÓN INGUINAL Y DEL MIEMBRO INFERIOR"
    },
    {
        "id": "cdc8aa9a-8459-5a20-0923-6ead9d50cd69",
        "NOMBRE": "TUMOR MALIGNO DE LOS GANGLIOS LINFÁTICOS DE REGIONES MÚLTIPLES"
    },
    {
        "id": "f32aece5-2aad-7d76-21e7-70a4b24e6827",
        "NOMBRE": "TUMOR MALIGNO DE LOS GANGLIOS LINFÁTICOS INTRAABDOMINALES"
    },
    {
        "id": "089f63f3-91f9-56b1-b18e-b71416eee809",
        "NOMBRE": "TUMOR MALIGNO DE LOS GANGLIOS LINFÁTICOS INTRATORÁCICOS"
    },
    {
        "id": "c076fb1d-a517-a1c3-eba4-da9070c0aa60",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO INFERIOR"
    },
    {
        "id": "2809bb70-e4b9-24dd-4350-190daf1ac5f1",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "19b0e4a6-03af-8b97-ba39-d87d193d7a2a",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS DE LA PELVIS, SACRO Y CÓCCIX"
    },
    {
        "id": "d15b1719-c2f4-6ce7-682f-ed12c67084bc",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS DEL CRÁNEO Y DE LA CARA"
    },
    {
        "id": "c262ce13-98bb-bcb7-2066-0c9165118145",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS LARGOS DEL MIEMBRO INFERIOR"
    },
    {
        "id": "b742527d-914d-2fe9-eb2e-684ccf4de014",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS Y DE LOS CARTÍLAGOS ARTICULARES DE LOS MIEMBROS"
    },
    {
        "id": "850dbc50-b081-e5a1-a423-daebe8af03c1",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS Y DE LOS CARTÍLAGOS ARTICULARES DE LOS MIEMBROS, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "ecaa6a1d-5b7a-621e-81b1-fbf4abc52fbe",
        "NOMBRE": "TUMOR MALIGNO DE LOS HUESOS Y DE LOS CARTÍLAGOS ARTICULARES, DE OTROS SITIOS Y DE SITIOS NO ESPECIFICADOS"
    },
    {
        "id": "e032661a-b833-86cf-7da1-cc9ae7a0c3a2",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS DE LA CABEZA, CARA Y CUELLO"
    },
    {
        "id": "450fbb72-0362-80dc-e173-b9b2d68de2e9",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS DE LA PELVIS"
    },
    {
        "id": "c1fbb2d8-9f64-92f6-2a7d-d03b46baafc9",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS DEL ABDOMEN"
    },
    {
        "id": "ce0235fb-e2de-9b89-7c82-76a8c905ffaa",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "74a9fdfa-0c65-0805-3cc0-1938e6274f0c",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "49d870a7-4029-6f70-f98c-3db02bdb3523",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS DEL TÓRAX"
    },
    {
        "id": "692e1b36-a31a-66f0-148e-c38935350087",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS DEL TRONCO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "b3b5c458-4a6e-678f-a442-00affec8e765",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS Y DEL SISTEMA NERVIOSO AUTÓNOMO"
    },
    {
        "id": "1439ce48-3fb9-c635-4717-841f42c301e1",
        "NOMBRE": "TUMOR MALIGNO DE LOS NERVIOS PERIFÉRICOS Y DEL SISTEMA NERVIOSO AUTÓNOMO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "088516b6-4f86-e925-c336-c87779dfdfcd",
        "NOMBRE": "TUMOR MALIGNO DE LOS SENOS PARANASALES"
    },
    {
        "id": "48a25cd0-9e3a-d939-d907-d574d0f1af8e",
        "NOMBRE": "TUMOR MALIGNO DE MASTOCITOS"
    },
    {
        "id": "bad574d2-4bd0-d8a9-c757-17828004fd6f",
        "NOMBRE": "TUMOR MALIGNO DE ÓRGANO GENITAL FEMENINO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "6ecf0f98-3ab5-8a73-9464-30b9da410259",
        "NOMBRE": "TUMOR MALIGNO DE ÓRGANO GENITAL MASCULINO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "9e3153a4-51d0-92fe-c25b-4e48dae67d13",
        "NOMBRE": "TUMOR MALIGNO DE ÓRGANO URINARIO NO ESPECIFICADO"
    },
    {
        "id": "bc6a855a-3dad-9164-215d-a950996d4b05",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS GLÁNDULAS ENDOCRINAS Y DE ESTRUCTURAS AFINES"
    },
    {
        "id": "e200988c-e426-63cf-2ae9-f8bea5e60c9d",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS GLÁNDULAS SALIVALES MAYORES Y DE LAS NO ESPECIFICADAS"
    },
    {
        "id": "1cc063d7-c8b6-fd0a-5b50-25d51207906d",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DE LOS ÓRGANOS GENITALES FEMENINOS"
    },
    {
        "id": "4ec076c9-fa0b-7023-1ef8-25b21b9ccc54",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DE LOS ÓRGANOS GENITALES MASCULINOS"
    },
    {
        "id": "e300d0d9-917f-8ab1-8153-a731522469c5",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DEL PÁNCREAS"
    },
    {
        "id": "522c40ef-2972-2d18-f8e3-71cdf90b9038",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA"
    },
    {
        "id": "d4923eca-a1b8-ae3d-e1ef-7523725d97c6",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA LENGUA"
    },
    {
        "id": "c20cca24-da3c-b31d-11bc-ad58b4f64291",
        "NOMBRE": "TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LAS VÍAS BILIARES"
    },
    {
        "id": "278858b1-c719-432f-2bfb-338d4298b689",
        "NOMBRE": "TUMOR MALIGNO DE OTROS NERVIOS CRANEALES Y LOS NO ESPECIFICADOS"
    },
    {
        "id": "c1e4fb8a-2ad6-ee1b-1b05-e952c2da298f",
        "NOMBRE": "TUMOR MALIGNO DE OTROS ÓGANOS URINARIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "7de31980-0200-5594-0138-4faa4e2a4d9b",
        "NOMBRE": "TUMOR MALIGNO DE OTROS ÓRGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "08ef0586-f341-277b-b512-caa86c04662b",
        "NOMBRE": "TUMOR MALIGNO DE OTROS ÓRGANOS GENITALES MACULINOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "e55e7e75-eab5-8da4-202b-5e7a23137f07",
        "NOMBRE": "TUMOR MALIGNO DE OTROS SITIOS MAL DEFINIDOS"
    },
    {
        "id": "e576a7c5-2e2f-fc5d-ecdc-0982764dd841",
        "NOMBRE": "TUMOR MALIGNO DE OTROS SITIOS Y DE LOS MAL DEFINIDOS DE LOS ÓRGANOS DIGESTIVOS"
    },
    {
        "id": "e5bd3b1a-9293-633c-cdb6-d506eef1a673",
        "NOMBRE": "TUMOR MALIGNO DE OTROS SITIOS Y DE LOS MAL DEFINIDOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE"
    },
    {
        "id": "d3dfbfbe-73ee-66ad-357f-3494044da1bf",
        "NOMBRE": "TUMOR MALIGNO DE OTROS SITIOS Y DE LOS MAL DEFINIDOS DEL SISTEMA RESPIRATORIO Y DE LOS ÓRGANOS INTRATORÁCICOS"
    },
    {
        "id": "9436c2c7-a057-fe74-0aa3-2d16b70f5e5b",
        "NOMBRE": "TUMOR MALIGNO DE OTROS SITIOS Y DE LOS SITIOS MAL DEFINIDOS"
    },
    {
        "id": "64fd3254-ce3e-a02a-ecc8-d133cc051f23",
        "NOMBRE": "TUMOR MALIGNO DE OTROS TEJIDOS CONJUNTIVOS Y DE TEJIDOS BLANDOS"
    },
    {
        "id": "5eb3e083-7625-7672-4f6f-ffb04dfecec2",
        "NOMBRE": "TUMOR MALIGNO DE PARTE ESPECIFICADA DEL PERITONEO"
    },
    {
        "id": "d02c1440-88ce-964f-ab3c-cd337dd6af8b",
        "NOMBRE": "TUMOR MALIGNO DE SENO PARANASAL NO ESPECIFICADO"
    },
    {
        "id": "3cfa0e8f-92ca-f25c-d38a-3436ff2d27ce",
        "NOMBRE": "TUMOR MALIGNO DE SITIOS MAL DEFINIDOS DE LOS ÓRGANOS DIGESTIVOS"
    },
    {
        "id": "99b065bf-f67f-1e50-ec95-b7712790b1e2",
        "NOMBRE": "TUMOR MALIGNO DE SITIOS MAL DEFINIDOS DEL SISTEMA RESPIRATORIO"
    },
    {
        "id": "453ad982-b476-9d70-1742-ac9c2c15299b",
        "NOMBRE": "TUMOR MALIGNO DE SITIOS NO ESPECIFICADOS"
    },
    {
        "id": "f9a6c319-ac87-28a8-e112-50b5eb1910a0",
        "NOMBRE": "TUMOR MALIGNO DE SITIOS NO ESPECIFICADOS"
    },
    {
        "id": "53f68658-6715-3fad-bb85-a9b297a6f6fb",
        "NOMBRE": "TUMOR MALIGNO DEL ABDOMEN"
    },
    {
        "id": "3556e43e-40f9-710f-539c-396c51b0ab7a",
        "NOMBRE": "TUMOR MALIGNO DEL ÁNGULO ESPLÉNICO"
    },
    {
        "id": "65b840a9-0806-616c-8b75-52b42aab90e1",
        "NOMBRE": "TUMOR MALIGNO DEL ÁNGULO HEPÁTICO"
    },
    {
        "id": "b65eee3d-4b51-a871-8fbb-9c92e505e52c",
        "NOMBRE": "TUMOR MALIGNO DEL ANILLO DE WALDEYER"
    },
    {
        "id": "b4b51335-3f3d-080c-c5d9-3f413e1875d9",
        "NOMBRE": "TUMOR MALIGNO DEL ANO Y DEL CONDUCTO ANAL"
    },
    {
        "id": "575c2960-4b38-548e-eec3-db44db139ae7",
        "NOMBRE": "TUMOR MALIGNO DEL ANO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "d8b7d3ce-a84c-fd9c-18e8-5b7377ad85a4",
        "NOMBRE": "TUMOR MALIGNO DEL ANTRO PILÓRICO"
    },
    {
        "id": "ad9e3a3b-6b38-445f-6db3-ea8e2f6e2d54",
        "NOMBRE": "TUMOR MALIGNO DEL APÉNDICE"
    },
    {
        "id": "ec58b9f6-53c4-c402-c6af-438ddb9bd64a",
        "NOMBRE": "TUMOR MALIGNO DEL ÁREA RETROMOLAR"
    },
    {
        "id": "981c753a-9914-ab64-3414-d80e442ee054",
        "NOMBRE": "TUMOR MALIGNO DEL BAZO"
    },
    {
        "id": "b148a061-addc-91a5-ebfa-0b19931f05b2",
        "NOMBRE": "TUMOR MALIGNO DEL BORDE DE LA LENGUA"
    },
    {
        "id": "7733cc6c-ea3b-86dd-ca70-2393b93827ae",
        "NOMBRE": "TUMOR MALIGNO DEL BRONQUIO PRINCIPAL"
    },
    {
        "id": "25d7b217-9c9a-b3cf-9bd7-43cbe38c0b4a",
        "NOMBRE": "TUMOR MALIGNO DEL CARDIAS"
    },
    {
        "id": "597da8a4-7c6e-68b6-7196-43042e6743c7",
        "NOMBRE": "TUMOR MALIGNO DEL CARTÍLAGO LARÍNGEO"
    },
    {
        "id": "f4910910-2b40-360b-a1d8-de39a2d2adb6",
        "NOMBRE": "TUMOR MALIGNO DEL CEREBELO"
    },
    {
        "id": "161fa0f0-f77b-3740-ab0c-871679f4b656",
        "NOMBRE": "TUMOR MALIGNO DEL CEREBRO, EXCEPTO LÓBULOS Y VENTRÍCULOS"
    },
    {
        "id": "891b29c3-0003-59f7-14df-22512deb24ea",
        "NOMBRE": "TUMOR MALIGNO DEL CIEGO"
    },
    {
        "id": "59179113-5507-399d-7d85-a09e6ee69b6e",
        "NOMBRE": "TUMOR MALIGNO DEL CLÍTORIS"
    },
    {
        "id": "50f7ca3b-bc24-ca97-dbab-164b3e127a35",
        "NOMBRE": "TUMOR MALIGNO DEL COLON"
    },
    {
        "id": "97979dc4-e5bc-8a4d-2fbe-d0afb85bb3f8",
        "NOMBRE": "TUMOR MALIGNO DEL COLON ASCENDENTE"
    },
    {
        "id": "ce115b62-6dd3-b8be-8f48-9f3dd1102f84",
        "NOMBRE": "TUMOR MALIGNO DEL COLON DESCENDENTE"
    },
    {
        "id": "67429aff-4097-f60e-4122-1f4ebadd0f69",
        "NOMBRE": "TUMOR MALIGNO DEL COLON SIGMOIDE"
    },
    {
        "id": "33017853-b962-453d-aaed-751412c6f4ae",
        "NOMBRE": "TUMOR MALIGNO DEL COLON TRANSVERSO"
    },
    {
        "id": "1eca64c9-544a-44b6-02e6-289c1fb0ddb8",
        "NOMBRE": "TUMOR MALIGNO DEL COLON, PARTE NO ESPECIFICADA"
    },
    {
        "id": "c68fa0b4-7419-285e-5c86-aa367b4270eb",
        "NOMBRE": "TUMOR MALIGNO DEL CONDUCTO ANAL"
    },
    {
        "id": "3d262a11-a41a-9f44-14c8-2cac5b34c999",
        "NOMBRE": "TUMOR MALIGNO DEL CONDUCTO CRANEOFARÍNGEO"
    },
    {
        "id": "90607140-42bb-095f-2fde-c2c612caa0cc",
        "NOMBRE": "TUMOR MALIGNO DEL CONDUCTO PANCREÁTICO"
    },
    {
        "id": "ab23abd7-8735-ee74-32ad-ba514ed0acca",
        "NOMBRE": "TUMOR MALIGNO DEL CORAZÓN"
    },
    {
        "id": "56a89fcc-ab56-478c-36d7-a4d1e8e194ba",
        "NOMBRE": "TUMOR MALIGNO DEL CORAZÓN, DEL MEDIASTINO Y DE LA PLEURA"
    },
    {
        "id": "b855f3fc-a51d-8f21-5d99-65bcc4d669b2",
        "NOMBRE": "TUMOR MALIGNO DEL CORDÓN ESPERMÁTICO"
    },
    {
        "id": "8fd68fe9-511d-8a60-7d63-086b63ef6f01",
        "NOMBRE": "TUMOR MALIGNO DEL CUADRANTE INFERIOR EXTERNO DE LA MAMA"
    },
    {
        "id": "35130dff-d498-da18-9cae-51f30e11c50b",
        "NOMBRE": "TUMOR MALIGNO DEL CUADRANTE INFERIOR INTERNO DE LA MAMA"
    },
    {
        "id": "e622f0ad-9910-ba05-f2e7-eadf7a1fe69d",
        "NOMBRE": "TUMOR MALIGNO DEL CUADRANTE SUPERIOR EXTERNO DE LA MAMA"
    },
    {
        "id": "aa054d7f-ea60-b165-8f43-e24419cd82c5",
        "NOMBRE": "TUMOR MALIGNO DEL CUADRANTE SUPERIOR INTERNO DE LA MAMA"
    },
    {
        "id": "be36744c-7489-d080-4ce2-3c7ff48badd0",
        "NOMBRE": "TUMOR MALIGNO DEL CUELLO DE LA VEJIGA"
    },
    {
        "id": "b1c2aa98-23ea-b507-03ad-69da96e112c4",
        "NOMBRE": "TUMOR MALIGNO DEL CUELLO DEL ÚTERO"
    },
    {
        "id": "0027d9ce-28f3-ccae-ca17-1b836e2a81c0",
        "NOMBRE": "TUMOR MALIGNO DEL CUELLO DEL ÚTERO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "c8b1c381-daf9-0982-2f54-714e50631345",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO AÓRTICO Y OTROS CUERPOS CROMAFINES"
    },
    {
        "id": "60f40101-80f5-43f7-0ca1-03c7c574cb20",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO CAROTÍDEO"
    },
    {
        "id": "e0cb725e-c620-cd56-123b-4b95a2f6a968",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO CILIAR"
    },
    {
        "id": "ec78f71b-53c6-0249-3e58-d049518c11b7",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO DEL ESTÓMAGO"
    },
    {
        "id": "50fdb251-6f4f-02d5-ddd0-a1f31124c546",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO DEL PÁNCREAS"
    },
    {
        "id": "2f333979-4bbe-503c-24c9-3de6363f6bfb",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO DEL PENE"
    },
    {
        "id": "a9445532-95c0-582d-29c2-cf0e1677dccf",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO DEL ÚTERO"
    },
    {
        "id": "afcba904-5ced-75ba-7b38-b91bfd537eb3",
        "NOMBRE": "TUMOR MALIGNO DEL CUERPO DEL ÚTERO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "17d5be4b-1d89-ef03-11ad-56df23d88051",
        "NOMBRE": "TUMOR MALIGNO DEL DIVERTÍCULO DE MECKEL"
    },
    {
        "id": "0d3cfac6-e205-471f-fca2-018e004b426a",
        "NOMBRE": "TUMOR MALIGNO DEL DUODENO"
    },
    {
        "id": "40b70294-160b-7829-b53a-cae28edcaf5e",
        "NOMBRE": "TUMOR MALIGNO DEL ENCÉFALO"
    },
    {
        "id": "8ad6d274-2553-186b-1ffc-152c0eafb586",
        "NOMBRE": "TUMOR MALIGNO DEL ENCÉFALO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "5b68557d-f3da-aa03-3232-40574f38a8a3",
        "NOMBRE": "TUMOR MALIGNO DEL ENDOCÉRVIX"
    },
    {
        "id": "dec06ea4-9404-ecb3-b9b8-31074d107a82",
        "NOMBRE": "TUMOR MALIGNO DEL ENDOMETRIO"
    },
    {
        "id": "eee14c68-d167-1ad1-e92e-c48456ce5790",
        "NOMBRE": "TUMOR MALIGNO DEL EPIDÍDIMO"
    },
    {
        "id": "5b600684-84e1-4ce5-8e87-6472f2ac6c83",
        "NOMBRE": "TUMOR MALIGNO DEL ESCROTO"
    },
    {
        "id": "867f1b4e-bd53-85c6-4e42-1be41a0bc78b",
        "NOMBRE": "TUMOR MALIGNO DEL ESÓFAGO"
    },
    {
        "id": "4efdf98b-eac7-a62c-b037-06f7569cbb73",
        "NOMBRE": "TUMOR MALIGNO DEL ESÓFAGO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "9304584f-dccf-34db-64ac-8fee9908a53f",
        "NOMBRE": "TUMOR MALIGNO DEL ESÓFAGO, PORCIÓN ABDOMINAL"
    },
    {
        "id": "f3573df0-c935-4c67-ae42-853b217d0993",
        "NOMBRE": "TUMOR MALIGNO DEL ESÓFAGO, PORCIÓN CERVICAL"
    },
    {
        "id": "d43eead8-d209-4766-1a8e-7e24cd807195",
        "NOMBRE": "TUMOR MALIGNO DEL ESÓFAGO, PORCIÓN TORÁCICA"
    },
    {
        "id": "97037e33-6d02-fb3e-20dd-8a5e32ff070b",
        "NOMBRE": "TUMOR MALIGNO DEL ESTÓMAGO"
    },
    {
        "id": "7a97eaff-c120-64c6-b1df-9de9e12b1d14",
        "NOMBRE": "TUMOR MALIGNO DEL ESTÓMAGO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "f3053e54-16e4-5236-1079-15570d79cc2b",
        "NOMBRE": "TUMOR MALIGNO DEL EXOCÉRVIX"
    },
    {
        "id": "f04ed498-b422-ebe3-e95a-5472957624c3",
        "NOMBRE": "TUMOR MALIGNO DEL FONDO DEL ÚTERO"
    },
    {
        "id": "514e6db0-e1ec-a8ed-6106-cc5375a69e39",
        "NOMBRE": "TUMOR MALIGNO DEL FUNDUS GÁSTRICO"
    },
    {
        "id": "6b0ee182-8621-7bef-6e95-4b270218267a",
        "NOMBRE": "TUMOR MALIGNO DEL GANGLIO LINFÁTICO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "9f959ff9-8158-aeb9-6a1b-3b8040f33738",
        "NOMBRE": "TUMOR MALIGNO DEL GLANDE"
    },
    {
        "id": "9726d3e6-5994-3212-d82d-2a3cbe8e46c1",
        "NOMBRE": "TUMOR MALIGNO DEL HÍGADO Y DE LAS VÍAS BILIARES INTRAHEPÁTICAS"
    },
    {
        "id": "df45de96-8b7e-4333-3372-b1249c95bca1",
        "NOMBRE": "TUMOR MALIGNO DEL HÍGADO, NO ESPECIFICADO"
    },
    {
        "id": "53d18ca3-8b8c-72b8-8b28-d4bf7964304c",
        "NOMBRE": "TUMOR MALIGNO DEL HUESO DEL MAXILAR INFERIOR"
    },
    {
        "id": "736f376e-e214-faa2-a70a-240e1e9c6dc2",
        "NOMBRE": "TUMOR MALIGNO DEL HUESO Y DEL CARTÍLAGO ARTICULAR, NO ESPECIFICADO"
    },
    {
        "id": "da01c727-8f33-abcd-fb4c-1cd310c823b1",
        "NOMBRE": "TUMOR MALIGNO DEL ÍLEON"
    },
    {
        "id": "fab0f6bd-aac6-c604-844d-32bc023606be",
        "NOMBRE": "TUMOR MALIGNO DEL INTESTINO DELGADO"
    },
    {
        "id": "250b70ed-1a37-3f03-2774-8f29e4aab6d9",
        "NOMBRE": "TUMOR MALIGNO DEL INTESTINO DELGADO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "c6e7dcad-3c64-dc81-43fd-45c89f931302",
        "NOMBRE": "TUMOR MALIGNO DEL INTESTINO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "35756b52-6fa6-c716-21ff-e6b1e40d975e",
        "NOMBRE": "TUMOR MALIGNO DEL ISTMO UTERINO"
    },
    {
        "id": "7174e048-973c-3ac7-2e27-20fd1a5b8b00",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO"
    },
    {
        "id": "5042691e-1208-14f8-4fb6-f1d3ab00b57e",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO INFERIOR, CARA EXTERNA"
    },
    {
        "id": "7ea83fbf-a4ef-1f3f-248c-083ad5f87562",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO INFERIOR, CARA INTERNA"
    },
    {
        "id": "dca535d2-aa48-1d42-651d-3c3d6c27ea4c",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO MAYOR"
    },
    {
        "id": "ae627d07-a5b9-9323-3e2c-3aab8673a245",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO MENOR"
    },
    {
        "id": "07bf78f5-fb5c-2533-2c76-bd2eef544fa4",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO SUPERIOR, CARA EXTERNA"
    },
    {
        "id": "21b4a5bc-0cf3-bb01-ed4b-0e94f2e4e81a",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO SUPERIOR, CARA INTERNA"
    },
    {
        "id": "77d3522c-8529-ac2c-9875-67f0f3443940",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO, CARA EXTERNA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "0cb34075-79f1-830b-8910-6293e12e32af",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO, CARA INTERNA, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "578c15ad-bb62-a11a-7151-f0b65873f295",
        "NOMBRE": "TUMOR MALIGNO DEL LABIO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "64a2b7aa-be93-e45d-2e64-fc65cb351e6f",
        "NOMBRE": "TUMOR MALIGNO DEL LIGAMENTO ANCHO"
    },
    {
        "id": "6b0ea23c-d1de-e7bc-104b-af7399130d97",
        "NOMBRE": "TUMOR MALIGNO DEL LIGAMENTO REDONDO"
    },
    {
        "id": "a257ae3b-f523-5e40-9ff3-deb805a90c35",
        "NOMBRE": "TUMOR MALIGNO DEL LÓBULO FRONTAL"
    },
    {
        "id": "e1ec7fb2-a366-f6f3-c2c6-e08c6f85f090",
        "NOMBRE": "TUMOR MALIGNO DEL LÓBULO INFERIOR, BRONQUIO O PULMÓN"
    },
    {
        "id": "22fae0d9-60ac-3ba4-1285-c3ce7f6077e3",
        "NOMBRE": "TUMOR MALIGNO DEL LÓBULO MEDIO, BRONQUIO O PULMÓN"
    },
    {
        "id": "839a091e-4a94-7652-d4c7-6f6c987e7891",
        "NOMBRE": "TUMOR MALIGNO DEL LÓBULO OCCIPITAL"
    },
    {
        "id": "991f3edc-f85a-abde-e62d-49259c76a0e6",
        "NOMBRE": "TUMOR MALIGNO DEL LÓBULO PARIETAL"
    },
    {
        "id": "d7bc9283-48de-a1db-5df7-4b1d1bbb76e0",
        "NOMBRE": "TUMOR MALIGNO DEL LÓBULO SUPERIOR, BRONQUIO O PULMÓN"
    },
    {
        "id": "7859d2b1-8904-bfef-cdc9-6c5d40ab4df1",
        "NOMBRE": "TUMOR MALIGNO DEL LÓBULO TEMPORAL"
    },
    {
        "id": "622642b1-6172-9ce2-0cc6-7296e998d1b1",
        "NOMBRE": "TUMOR MALIGNO DEL MEDIASTINO ANTERIOR"
    },
    {
        "id": "48591a58-99ef-919d-795e-99b17c431d64",
        "NOMBRE": "TUMOR MALIGNO DEL MEDIASTINO POSTERIOR"
    },
    {
        "id": "aaaed0ce-8ed3-4ed2-c99d-d420c377191d",
        "NOMBRE": "TUMOR MALIGNO DEL MEDIASTINO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "e372798b-4e91-b9eb-e1a9-24ade24e5bad",
        "NOMBRE": "TUMOR MALIGNO DEL MIEMBRO INFERIOR"
    },
    {
        "id": "a9e3f807-abbb-0ffb-234e-9b3e336bea3f",
        "NOMBRE": "TUMOR MALIGNO DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "69885c67-a6b1-da86-7b6f-78dc98d85310",
        "NOMBRE": "TUMOR MALIGNO DEL MIOMETRIO"
    },
    {
        "id": "9b024421-baf6-75fa-3ced-d352eef66527",
        "NOMBRE": "TUMOR MALIGNO DEL NERVIO ACÚSTICO"
    },
    {
        "id": "241c8e18-7ae0-39b0-3af6-091e305ffa53",
        "NOMBRE": "TUMOR MALIGNO DEL NERVIO OLFATORIO"
    },
    {
        "id": "a715bdf6-2163-d728-fcb2-9c7352bf0196",
        "NOMBRE": "TUMOR MALIGNO DEL NERVIO ÓPTICO"
    },
    {
        "id": "830b9f5a-0002-2873-658f-bec1748bc725",
        "NOMBRE": "TUMOR MALIGNO DEL OÍDO MEDIO"
    },
    {
        "id": "bca8f28e-7ef7-94ea-825e-5d09cc940a6b",
        "NOMBRE": "TUMOR MALIGNO DEL OJO Y SUS ANEXOS"
    },
    {
        "id": "6b13dca2-b885-1ebe-8e28-6859218bffa0",
        "NOMBRE": "TUMOR MALIGNO DEL OJO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "c3bbb059-5669-8419-b02c-fb24f59d2585",
        "NOMBRE": "TUMOR MALIGNO DEL OMÓPLATO Y DE LOS HUESOS LARGOS DEL MIEMBRO SUPERIOR"
    },
    {
        "id": "ca701c09-c5c1-13e3-44b6-4dea1fd224cf",
        "NOMBRE": "TUMOR MALIGNO DEL ORIFICIO URETERAL"
    },
    {
        "id": "93f19290-c416-506c-84ae-83493f4ac885",
        "NOMBRE": "TUMOR MALIGNO DEL OVARIO"
    },
    {
        "id": "ad35c7e8-137d-4e0d-352e-5e894767c33e",
        "NOMBRE": "TUMOR MALIGNO DEL PALADAR"
    },
    {
        "id": "7606c284-5e3f-a53b-476c-3555f7d14347",
        "NOMBRE": "TUMOR MALIGNO DEL PALADAR BLANDO"
    },
    {
        "id": "cc519332-1c76-cda1-68d6-dfac61e15e02",
        "NOMBRE": "TUMOR MALIGNO DEL PALADAR DURO"
    },
    {
        "id": "2cb268cc-d09a-228d-e0fa-ff5a31620dfb",
        "NOMBRE": "TUMOR MALIGNO DEL PALADAR, PARTE NO ESPECIFICADA"
    },
    {
        "id": "e3a6f6da-0c87-b55e-5d76-913cfe4b1e00",
        "NOMBRE": "TUMOR MALIGNO DEL PÁNCREAS"
    },
    {
        "id": "22fbd631-6277-5078-bffe-dfc67916f034",
        "NOMBRE": "TUMOR MALIGNO DEL PÁNCREAS ENDOCRINO"
    },
    {
        "id": "cb520b0a-e6c4-c12e-1f15-8bf5ab1e84b8",
        "NOMBRE": "TUMOR MALIGNO DEL PÁNCREAS, PARTE NO ESPECIFICADA"
    },
    {
        "id": "0de88f35-b98b-c611-6343-c4c498037634",
        "NOMBRE": "TUMOR MALIGNO DEL PARAMETRIO"
    },
    {
        "id": "b528742a-d351-f24a-f8e0-64a4065d3b23",
        "NOMBRE": "TUMOR MALIGNO DEL PEDÚNCULO CEREBRAL"
    },
    {
        "id": "b89090fe-c391-dea3-be54-6bde343b8e8c",
        "NOMBRE": "TUMOR MALIGNO DEL PENE"
    },
    {
        "id": "18ca21bf-31c8-f96f-8dc1-5eed7d2b8449",
        "NOMBRE": "TUMOR MALIGNO DEL PENE, PARTE NO ESPECIFICADA"
    },
    {
        "id": "049c10f2-121e-034d-5b1d-75ab5741bebb",
        "NOMBRE": "TUMOR MALIGNO DEL PERITONEO Y DEL RETROPERITONEO"
    },
    {
        "id": "15541682-0e8f-2ecf-c708-9b11ecc4e489",
        "NOMBRE": "TUMOR MALIGNO DEL PERITONEO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "0c61beb1-78f5-61d2-f455-704fd4aee703",
        "NOMBRE": "TUMOR MALIGNO DEL PEZÓN Y ARÉOLA MAMARIA"
    },
    {
        "id": "89d20b69-c6e2-ad9e-2da3-e499e0d9c08b",
        "NOMBRE": "TUMOR MALIGNO DEL PILAR AMIGDALINO (ANTERIOR) (POSTERIOR)"
    },
    {
        "id": "ad8de485-f035-7b91-4a89-b43f4f76a556",
        "NOMBRE": "TUMOR MALIGNO DEL PÍLORO"
    },
    {
        "id": "84a0927f-34e9-9e69-90ef-5907065c29d3",
        "NOMBRE": "TUMOR MALIGNO DEL PISO DE LA BOCA"
    },
    {
        "id": "77e08108-3c5d-5978-b660-5da374e6f0df",
        "NOMBRE": "TUMOR MALIGNO DEL PISO DE LA BOCA, PARTE NO ESPECIFICADA"
    },
    {
        "id": "3698dfc2-11bc-81e3-6c09-9f53a65e892a",
        "NOMBRE": "TUMOR MALIGNO DEL PLIEGUE ARITENOEPIGLÓTICO, CARA HIPOFARÍNGEA"
    },
    {
        "id": "dbce91bd-fe83-6233-56dc-5b8890d720f1",
        "NOMBRE": "TUMOR MALIGNO DEL PREPUCIO"
    },
    {
        "id": "ae741054-e6ca-b59e-1879-841b56d198b4",
        "NOMBRE": "TUMOR MALIGNO DEL RECTO"
    },
    {
        "id": "c3ca4d81-b99f-5a42-0e3d-49adb2dfe267",
        "NOMBRE": "TUMOR MALIGNO DEL RETROPERITONEO"
    },
    {
        "id": "3f7e6d0c-1ee0-16ba-eb2a-291f3243126f",
        "NOMBRE": "TUMOR MALIGNO DEL RIÑÓN, EXCEPTO DE LA PELVIS RENAL"
    },
    {
        "id": "16f21c1c-c562-e746-b5e8-152855f9b4d7",
        "NOMBRE": "TUMOR MALIGNO DEL SENO ESFENOIDAL"
    },
    {
        "id": "dc19ba8e-93bc-4bdc-81ee-311f321234e7",
        "NOMBRE": "TUMOR MALIGNO DEL SENO ETMOIDAL"
    },
    {
        "id": "3e8ec0ce-baee-69f0-4742-d5209847e9e5",
        "NOMBRE": "TUMOR MALIGNO DEL SENO FRONTAL"
    },
    {
        "id": "1e997428-8097-5fb8-07d2-a56da9555a83",
        "NOMBRE": "TUMOR MALIGNO DEL SENO MAXILAR"
    },
    {
        "id": "20ce34db-5e62-e6a9-8dc2-b46ff1f0ea6a",
        "NOMBRE": "TUMOR MALIGNO DEL SENO PIRIFORME"
    },
    {
        "id": "c5bea140-53e0-94c9-c61a-31ae2c3be910",
        "NOMBRE": "TUMOR MALIGNO DEL SISTEMA NERVIOSO CENTRAL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "57b1e10e-dc14-b3c1-b542-0632cd88c378",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DE LA CABEZA, CARA Y CUELLO"
    },
    {
        "id": "08fa8da2-cf39-ba77-97d9-d002548f5346",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DE LA PELVIS"
    },
    {
        "id": "1c37c0ff-a0ef-32a5-fda6-e233242385d5",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL ABDOMEN"
    },
    {
        "id": "c672d42c-e1fb-1b56-d1ff-aea07d620c99",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"
    },
    {
        "id": "9cf0817d-ea9e-32d3-fba5-d715739e6bfe",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"
    },
    {
        "id": "631ba765-8557-119b-7272-5bcb31269a8b",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL TÓRAX"
    },
    {
        "id": "d7345d99-6914-d773-738e-40d5a946f840",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL TRONCO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "640ae621-b4b8-6d46-7c6a-eea57762bc4b",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "eae146cb-1ff6-c723-1bd2-1b9c6203fd51",
        "NOMBRE": "TUMOR MALIGNO DEL TEJIDO LINFÁTICO, HEMATOPOYÉTICO Y TEJIDOS AFINES, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "4e2fd7db-e383-3c0c-9c86-d602f55f992f",
        "NOMBRE": "TUMOR MALIGNO DEL TERCIO INFERIOR DEL ESÓFAGO"
    },
    {
        "id": "5cd938a0-e8b9-2be0-02bb-c1b3bbd93bb7",
        "NOMBRE": "TUMOR MALIGNO DEL TERCIO MEDIO DEL ESÓFAGO"
    },
    {
        "id": "8f8dd5e5-12d8-d379-2183-5cc7bb351931",
        "NOMBRE": "TUMOR MALIGNO DEL TERCIO SUPERIOR DEL ESÓFAGO"
    },
    {
        "id": "7c167823-e135-cdea-5bf3-e9376812f954",
        "NOMBRE": "TUMOR MALIGNO DEL TESTÍCULO"
    },
    {
        "id": "4bb7c5ed-4242-40ab-54c8-2a12c69f4cf7",
        "NOMBRE": "TUMOR MALIGNO DEL TESTÍCULO DESCENDIDO"
    },
    {
        "id": "057120c7-e863-55b8-c4d2-ee1f451d6dab",
        "NOMBRE": "TUMOR MALIGNO DEL TESTÍCULO NO DESCENDIDO"
    },
    {
        "id": "26d005b5-1336-0894-6549-d2066cc396fc",
        "NOMBRE": "TUMOR MALIGNO DEL TESTÍCULO, NO ESPECIFICADO"
    },
    {
        "id": "ef09496e-6e09-2ece-6d5c-475663077ced",
        "NOMBRE": "TUMOR MALIGNO DEL TIMO"
    },
    {
        "id": "f5e74aa7-691d-a363-1f11-3a9c57e27ff1",
        "NOMBRE": "TUMOR MALIGNO DEL TÓRAX"
    },
    {
        "id": "919e505b-e776-c738-32d3-f6746a923bb7",
        "NOMBRE": "TUMOR MALIGNO DEL TRÍGONO VESICAL"
    },
    {
        "id": "b9da83f0-0607-3b86-16b5-d1314d5db988",
        "NOMBRE": "TUMOR MALIGNO DEL URACO"
    },
    {
        "id": "198ec183-787f-97ac-426d-34b1e367f9d5",
        "NOMBRE": "TUMOR MALIGNO DEL URÉTER"
    },
    {
        "id": "6520679c-29f4-250f-06d6-dc1c52035127",
        "NOMBRE": "TUMOR MALIGNO DEL ÚTERO, PARTE NO ESPECIFICADA"
    },
    {
        "id": "a61ef41c-1b55-c82f-c7b5-d7d6f882960f",
        "NOMBRE": "TUMOR MALIGNO DEL VENTRÍCULO CEREBRAL"
    },
    {
        "id": "fd4acf3e-8a28-e5aa-74c0-963643f4f831",
        "NOMBRE": "TUMOR MALIGNO DEL VESTÍBULO DE LA BOCA"
    },
    {
        "id": "5b62bc98-fa2b-f3ca-495d-dd72369ed170",
        "NOMBRE": "TUMOR MALIGNO DEL YEYUNO"
    },
    {
        "id": "db641366-54f5-7e23-69ad-46d6eeb6d069",
        "NOMBRE": "TUMOR MALIGNO PLURIGLANDULAR, NO ESPECIFICADO"
    },
    {
        "id": "811091b8-e0d0-4d97-2ed8-35633b24a225",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE LA GLÁNDULA SUPRARRENAL"
    },
    {
        "id": "f3bc3bdf-4b6a-b203-aaac-7d3be557b3a3",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE LA PIEL"
    },
    {
        "id": "8c66637b-dd3e-4441-2790-d4ba25dcc921",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE LA PLEURA"
    },
    {
        "id": "97c7f346-be06-72e0-11fa-0c676a6f93ee",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE LA VEJIGA, Y DE OTROS ÓRGANOS Y LOS NO ESPECIFICADOS DE LAS VÍAS URINARIAS"
    },
    {
        "id": "7290469e-1492-61b1-0398-65924411a96b",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE LOS HUESOS Y DE LA MÉDULA ÓSEA"
    },
    {
        "id": "2fa7f917-895f-e32e-0a99-d375b006b766",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE LOS ÓRGANOS RESPIRATORIOS Y DIGESTIVOS"
    },
    {
        "id": "71d2e82d-bcda-5ec4-9fc0-f30d3e8c50c8",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE OTRAS PARTES DEL SISTEMA NERVIOSO Y DE LAS NO ESPECIFICADAS"
    },
    {
        "id": "03dfffac-cc73-361d-911c-aac3303e300e",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE OTROS ÓRGANOS DIGESTIVOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "bf189b61-e11c-578f-8118-66766e3b26e4",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE OTROS ÓRGANOS RESPIRATORIOS Y DE LOS NO ESPECIFICADOS"
    },
    {
        "id": "cf6b8377-abe4-0b44-b0fc-5bfc601b1467",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "8eb5aa6d-519b-6750-276c-a1fe977cb16a",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DE OTROS SITIOS Y DE SITIOS NO ESPECIFICADOS"
    },
    {
        "id": "950e1731-9cdb-0973-f7be-5206a6f83947",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL ENCÉFALO Y DE LAS MENINGES CEREBRALES"
    },
    {
        "id": "e9ed4105-e527-8fe8-01d9-66b9242ac0ad",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL HÍGADO Y DE LOS CONDUCTOS BILIARES INTRAHEPÁTICOS"
    },
    {
        "id": "36b136fa-2158-70bf-033e-c33877f11ea7",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL INTESTINO DELGADO"
    },
    {
        "id": "cd61d7bd-044e-a4dd-ad6e-3c09f552eca4",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL INTESTINO GRUESO Y DEL RECTO"
    },
    {
        "id": "d5847c4d-a605-6724-103f-3542ccc6083f",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL MEDIASTINO"
    },
    {
        "id": "3fbd6e77-2b29-7078-89f2-b56bd9959152",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL OVARIO"
    },
    {
        "id": "1c54fb80-96c5-4dd6-2f9b-d964ff457464",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL PERITONEO Y DEL RETROPERITONEO"
    },
    {
        "id": "5193b218-cbc3-a9ac-56a0-ae25375a3242",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL PULMÓN"
    },
    {
        "id": "82e4765a-809f-c462-3689-fd6f1b004c1c",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO DEL RIÑÓN Y DE LA PELVIS RENAL"
    },
    {
        "id": "4f5064fc-f927-bb92-05e6-521a363ee906",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO Y EL NO ESPECIFICADO DE LOS GANGLIOS LINFÁTICOS"
    },
    {
        "id": "9e671a1a-3985-caa9-0b5c-c9fcfd8c7f1d",
        "NOMBRE": "TUMOR MALIGNO SECUNDARIO, SITIO NO ESPECIFICADO"
    },
    {
        "id": "d80b9b3a-9b8c-bff5-f401-ef1cd2d66e33",
        "NOMBRE": "TUMOR MALIGNO, DE SITIO PRIMARIO DESCONOCIDO, ASÍ DESCRITO"
    },
    {
        "id": "18623ed2-8cc6-2885-5026-cfe54f7e79a0",
        "NOMBRE": "TUMOR MALIGNO, SITIO PRIMARIO NO ESPECIFICADO"
    },
    {
        "id": "b5b3217d-49fa-2888-301b-5db5a1a033f5",
        "NOMBRE": "TUMORES BENIGNOS DE LAS MENINGES"
    },
    {
        "id": "deefa94f-a346-8f72-aed6-ff7c0bfd2b16",
        "NOMBRE": "TUMORES BENIGNOS DEL TEJIDO MESOTELIAL"
    },
    {
        "id": "6775c666-48cf-4146-8bf6-5800f8bc8ab8",
        "NOMBRE": "TUMORES BENIGNOS LIPOMATOSOS"
    },
    {
        "id": "832493c7-0904-dbb1-d8d8-b6b13bc9359f",
        "NOMBRE": "TUMORES DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFÁTICO, DE LOS ÓRGANOS HEMATOPOYÉTICOS Y DE TEJIDOS AFINES, NO ESPECIFICADOS"
    },
    {
        "id": "79d6d522-a8a9-b8cb-3fe6-84613458922f",
        "NOMBRE": "TUMORES MALIGNOS (PRIMARIOS) DE SITIOS MÚLTIPLES INDEPENDIENTES"
    },
    {
        "id": "b8d9d0e8-7e54-6fee-5eff-043159f35c5f",
        "NOMBRE": "TUNGIASIS [INFECCIÓN DEBIDA A PULGA DE ARENA]"
    },
    {
        "id": "4767f043-8a20-b5f5-ac51-5e3d6da8e45f",
        "NOMBRE": "ÚLCERA CRÓNICA DE LA PIEL, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "8d44a4b0-5bff-9d2d-adaa-2e1a64a46b49",
        "NOMBRE": "ÚLCERA DE DECÚBITO"
    },
    {
        "id": "867f2ea6-a283-fa9d-bb2a-8a913dc0b25a",
        "NOMBRE": "ÚLCERA DE DECÚBITO Y POR ÁREA DE PRESIÓN"
    },
    {
        "id": "e49b289c-a754-ae48-44cc-6bd5c603c72b",
        "NOMBRE": "ÚLCERA DE DECÚBITO Y POR ÁREA DE PRESIÓN, ETAPA I"
    },
    {
        "id": "ea7ecaf1-3c02-64f3-9212-a3bcd71f77e5",
        "NOMBRE": "ÚLCERA DE DECÚBITO Y POR ÁREA DE PRESIÓN, NO ESPECIFICADA"
    },
    {
        "id": "58b390d1-2b31-fef3-2292-4be735143fb5",
        "NOMBRE": "ÚLCERA DE DECÚBITO, ETAPA II"
    },
    {
        "id": "ee11bc20-d8ca-91df-caaa-b1c72dff2c08",
        "NOMBRE": "ÚLCERA DE DECÚBITO, ETAPA III"
    },
    {
        "id": "a94b7d0c-7acb-b347-8676-44c9c11e1e9c",
        "NOMBRE": "ÚLCERA DE DECÚBITO, ETAPA IV"
    },
    {
        "id": "4d6d4821-069a-1dad-81a2-0db95579d7b2",
        "NOMBRE": "ÚLCERA DE LA CÓRNEA"
    },
    {
        "id": "e4a01084-7894-5863-7626-ced9bbb88243",
        "NOMBRE": "ÚLCERA DE MIEMBRO INFERIOR, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "06a21399-bce2-5cba-471c-25794ba8e563",
        "NOMBRE": "ÚLCERA DEL ANO Y DEL RECTO"
    },
    {
        "id": "c802858c-3038-ff61-9a62-85f7fc651a1c",
        "NOMBRE": "ÚLCERA DEL ESÓFAGO"
    },
    {
        "id": "4e30e802-fea9-df10-7a40-8ed4454415bf",
        "NOMBRE": "ÚLCERA DEL INTESTINO"
    },
    {
        "id": "ed7fa369-d4e4-3426-c423-4b88bc19abbc",
        "NOMBRE": "ÚLCERA DEL PENE"
    },
    {
        "id": "1aa9a1d2-d454-2045-f2b4-26b6fa798a18",
        "NOMBRE": "ÚLCERA DUODENAL"
    },
    {
        "id": "cdd5a28a-9c82-32dc-42a0-a65f8e06d5c3",
        "NOMBRE": "ÚLCERA DUODENAL, AGUDA CON HEMORRAGIA"
    },
    {
        "id": "ba67d166-a23e-cf56-f293-52399c132c6a",
        "NOMBRE": "ÚLCERA DUODENAL, AGUDA CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "9c1c9665-2898-f4c5-6de0-b66ac259e97e",
        "NOMBRE": "ÚLCERA DUODENAL, AGUDA CON PERFORACIÓN"
    },
    {
        "id": "30d8ddc9-0211-9105-0547-3a5e4df71956",
        "NOMBRE": "ÚLCERA DUODENAL, AGUDA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "74651f1c-e135-1939-0115-72f988b6711c",
        "NOMBRE": "ÚLCERA DUODENAL, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA"
    },
    {
        "id": "f2c4416e-ce0e-edb1-ca32-ff5d53bde809",
        "NOMBRE": "ÚLCERA DUODENAL, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "5d0eaad6-3ae0-8707-5514-897b33844943",
        "NOMBRE": "ÚLCERA DUODENAL, CRÓNICA O NO ESPECIFICADA, CON PERFORACIÓN"
    },
    {
        "id": "e7509722-2002-0ca9-2dad-d445a31292ce",
        "NOMBRE": "ÚLCERA DUODENAL, CRÓNICA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "a4d3384f-daaa-63e4-7ff0-636a52d24a25",
        "NOMBRE": "ÚLCERA DUODENAL, NO ESPECIFICADA COMO AGUDA NI CRÓNICA, SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "f25f33f8-bd97-7fcb-2931-4b599605ba98",
        "NOMBRE": "ÚLCERA GÁSTRICA"
    },
    {
        "id": "6cd8df99-fc90-118b-8751-d4c672a46988",
        "NOMBRE": "ÚLCERA GÁSTRICA, AGUDA CON HEMORRAGIA"
    },
    {
        "id": "78791cfd-6977-ed36-b7a6-dc29a2f7deeb",
        "NOMBRE": "ÚLCERA GÁSTRICA, AGUDA CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "e7089541-c914-31e0-09b0-dcb0d126c788",
        "NOMBRE": "ÚLCERA GASTRICA, AGUDA CON PERFORACIÓN"
    },
    {
        "id": "c91e9668-7ec3-a20b-a088-4890f2740e14",
        "NOMBRE": "ÚLCERA GÁSTRICA, AGUDA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "e886fcc8-5312-db3a-9da7-07350ab49679",
        "NOMBRE": "ÚLCERA GÁSTRICA, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA"
    },
    {
        "id": "14931422-3088-428b-72d5-ad6e4e9c20f8",
        "NOMBRE": "ÚLCERA GÁSTRICA, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "fa58d686-679b-ee3c-3e58-404f247043ba",
        "NOMBRE": "ÚLCERA GÁSTRICA, CRÓNICA O NO ESPECIFICADA, CON PERFORACIÓN"
    },
    {
        "id": "517c2c7b-8420-89b3-8146-6ff804546ba7",
        "NOMBRE": "ÚLCERA GÁSTRICA, CRÓNICA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "beaaca40-eb29-04bc-3ab7-2a1763f5e8f1",
        "NOMBRE": "ÚLCERA GÁSTRICA, NO ESPECIFICADA COMO AGUDA NI CRÓNICA, SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "3e76a8e0-f220-3e2e-432a-0c225eff1715",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL"
    },
    {
        "id": "fbc9b04b-e6f6-e9bb-fc01-2fb3da4a050b",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, AGUDA CON HEMORRAGIA"
    },
    {
        "id": "74e55948-4236-be10-e1d6-b057593e32b6",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, AGUDA CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "a610fa92-3f98-1af1-e69e-f5eb496791ec",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, AGUDA CON PERFORACIÓN"
    },
    {
        "id": "5fa7efda-e9ff-86c9-9942-fcffde87d6c8",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, AGUDA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "4dbb8c49-3bc8-35ee-9224-c68f777aca00",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA"
    },
    {
        "id": "b170cd8b-5b2b-dc16-e285-07b33ac5db60",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "d3d14ed3-e716-0304-fc26-2110d4958721",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, CRÓNICA O NO ESPECIFICADA, CON PERFORACIÓN"
    },
    {
        "id": "caf95dba-6451-c038-0357-41bc9989f1e5",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, CRÓNICA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "3c30e274-66df-9d51-6d57-bc442edd55d9",
        "NOMBRE": "ÚLCERA GASTROYEYUNAL, NO ESPECIFICADA COMO AGUDA NI CRÓNICA, SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "ec2b457d-6c08-a2bb-77f5-8b0c1d92ca80",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO"
    },
    {
        "id": "052bff6e-b195-47ba-f656-c8462370edaa",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, AGUDA CON HEMORRAGIA"
    },
    {
        "id": "46a31b91-3d1e-df6c-00c8-f64f8f7337e6",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, AGUDA CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "ddcb02b9-fc79-c310-1301-07e2d8580a81",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, AGUDA CON PERFORACIÓN"
    },
    {
        "id": "81f110c9-a796-ffe4-3ffd-e5ed990d3e4f",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, AGUDA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "f1e0149b-6afa-78ca-b558-7147f1c22226",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA"
    },
    {
        "id": "81a3ba19-6ad9-b22b-ac39-9844e1e012d5",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, CRÓNICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACIÓN"
    },
    {
        "id": "2c513250-74f9-b114-2693-d2788c287c04",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, CRÓNICA O NO ESPECIFICADA, CON PERFORACIÓN"
    },
    {
        "id": "b29510c3-0672-6bf0-d17f-5fc6b4767560",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, CRÓNICA SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "c25f8cf1-53d3-be67-d24f-3ed9af4df9bb",
        "NOMBRE": "ÚLCERA PÉPTICA, DE SITIO NO ESPECIFICADO, NO ESPECIFICADA COMO AGUDA NI CRÓNICA, SIN HEMORRAGIA NI PERFORACIÓN"
    },
    {
        "id": "33630fb0-8636-854d-368f-ab99837258cf",
        "NOMBRE": "ULCERACIÓN DE LA VAGINA"
    },
    {
        "id": "116976ad-a025-0c24-eca0-04e2b7a0a8e0",
        "NOMBRE": "ULCERACIÓN DE LA VULVA"
    },
    {
        "id": "7077b26e-f1f8-8940-8d4c-cf54f6c75eed",
        "NOMBRE": "ULCERACIÓN DE LA VULVA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4095bcff-b1fd-7217-1cb8-bfaf55d4509f",
        "NOMBRE": "ULCERACIÓN E INFLAMACIÓN VULVOVAGINAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "23559e4b-86ba-9de5-2e88-91b0c762d089",
        "NOMBRE": "ULCERACIÓN E INFLAMACIÓN VULVOVAGINAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "917cc55b-f549-9305-e3af-d8f5dffbc78c",
        "NOMBRE": "UÑA DEFORME DE LA PAQUIDERMOPERIOSTOSIS"
    },
    {
        "id": "ea4d7595-2212-c2b7-b3c3-278d70972ab1",
        "NOMBRE": "UÑA ENCARNADA"
    },
    {
        "id": "7b93c645-db6e-49cd-987c-33733a956591",
        "NOMBRE": "UREMIA EXTRARRENAL"
    },
    {
        "id": "a73ac4f9-ed83-3e78-4375-197f86a7d171",
        "NOMBRE": "URETRITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "cc3c67fc-811e-a7c5-6a82-0d5a3176e38f",
        "NOMBRE": "URETRITIS NO ESPECÍFICA"
    },
    {
        "id": "ce064836-1da1-df6e-5627-26d7078693ff",
        "NOMBRE": "URETRITIS Y SÍNDROME URETRAL"
    },
    {
        "id": "2814c6ff-5da4-994f-1369-cc238c7eb140",
        "NOMBRE": "URETROCELE FEMENINO"
    },
    {
        "id": "da16b8db-21df-8cea-0956-26c60000db75",
        "NOMBRE": "UROPATÍA ASOCIADA CON REFLUJO VESICOURETERAL"
    },
    {
        "id": "7defde9c-c1c8-568e-110d-2e764e1305cd",
        "NOMBRE": "UROPATÍA OBSTRUCTIVA Y POR REFLUJO"
    },
    {
        "id": "ba1143b5-fa0e-1229-66b3-69d77b8bc3dd",
        "NOMBRE": "UROPATÍA OBSTRUCTIVA Y POR REFLUJO, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "5a7491b2-1234-ddd8-265b-cd6d915c3c24",
        "NOMBRE": "URTICARIA"
    },
    {
        "id": "f364d45d-736f-5d31-af08-4ace9d586183",
        "NOMBRE": "URTICARIA ALÉRGICA"
    },
    {
        "id": "d3ffbcb8-dd88-cff7-cbe5-1f6e49828526",
        "NOMBRE": "URTICARIA COLINÉRGICA"
    },
    {
        "id": "18a1ac5e-4275-b2d1-3af7-0931d4ed823b",
        "NOMBRE": "URTICARIA DEBIDA AL CALOR Y AL FRÍO"
    },
    {
        "id": "f2d07c16-328d-c739-aa7e-d93b5118413d",
        "NOMBRE": "URTICARIA DERMATOGRÁFICA"
    },
    {
        "id": "7f069707-d695-0ed4-7cae-a4c6bacf02fc",
        "NOMBRE": "URTICARIA IDIOPÁTICA"
    },
    {
        "id": "b1eea805-9297-945a-ed71-564d67df6f99",
        "NOMBRE": "URTICARIA POR CONTACTO"
    },
    {
        "id": "fb173782-5197-044b-3120-37ff29b232df",
        "NOMBRE": "URTICARIA SOLAR"
    },
    {
        "id": "46e07094-d56d-4f2b-47bd-3f601b7b4962",
        "NOMBRE": "URTICARIA VIBRATORIA"
    },
    {
        "id": "abdc5a4a-9fe6-4aeb-896a-b8000484bf9b",
        "NOMBRE": "URTICARIA, NO ESPECIFICADA"
    },
    {
        "id": "34eccf99-8c04-a536-ef00-039802cd4d67",
        "NOMBRE": "USO EMERGENTE DE U06"
    },
    {
        "id": "37ec7c98-9741-c9f9-8e23-14c17a32094c",
        "NOMBRE": "USO EMERGENTE DE U06.0"
    },
    {
        "id": "c6590362-e07d-fc26-ae07-7d81efacedff",
        "NOMBRE": "USO EMERGENTE DE U06.1"
    },
    {
        "id": "bc4dce34-064e-fcf4-8505-e6f14438f573",
        "NOMBRE": "USO EMERGENTE DE U06.2"
    },
    {
        "id": "cebad69d-a7f8-3a76-3b24-f7fc89a19003",
        "NOMBRE": "USO EMERGENTE DE U06.3"
    },
    {
        "id": "33a0c0ae-8a5d-4392-35c8-b22c68c983ff",
        "NOMBRE": "USO EMERGENTE DE U06.4"
    },
    {
        "id": "f91c4a08-da01-0e3e-8aad-7d0b736d1f45",
        "NOMBRE": "USO EMERGENTE DE U06.5"
    },
    {
        "id": "e5f8fe48-8c96-00b1-efdb-4baeb7a1da2d",
        "NOMBRE": "USO EMERGENTE DE U06.6"
    },
    {
        "id": "a97be7c2-f9e3-f758-5c9e-519b28e6b683",
        "NOMBRE": "USO EMERGENTE DE U06.7"
    },
    {
        "id": "aeed6bb1-a49c-043a-56bf-2ce1a02d93b4",
        "NOMBRE": "USO EMERGENTE DE U06.8"
    },
    {
        "id": "9edce775-178c-6178-b49d-437c39050c19",
        "NOMBRE": "USO EMERGENTE DE U06.9"
    },
    {
        "id": "9d0798fd-d378-4dd2-15df-a0381a425296",
        "NOMBRE": "USO EMERGENTE DE U07"
    },
    {
        "id": "26df2616-c9f3-696f-8899-90d2a46ff77a",
        "NOMBRE": "USO EMERGENTE DE U07.3"
    },
    {
        "id": "03525223-2537-12d0-37a2-2640815efcfe",
        "NOMBRE": "USO EMERGENTE DE U07.4"
    },
    {
        "id": "abcdad6f-a455-2604-ade2-52f6b4afc781",
        "NOMBRE": "USO EMERGENTE DE U07.5"
    },
    {
        "id": "d7188be4-8991-e7eb-e630-0b3c9314133a",
        "NOMBRE": "USO EMERGENTE DE U07.6"
    },
    {
        "id": "0b4b9b3a-bea1-b854-d8dd-3a21c7f81808",
        "NOMBRE": "USO EMERGENTE DE U07.7"
    },
    {
        "id": "17d9bfab-8f4a-aaf3-7a49-c3f55220c80a",
        "NOMBRE": "USO EMERGENTE DE U07.8"
    },
    {
        "id": "78dfac0a-a662-f932-747e-a6f8545fba66",
        "NOMBRE": "USO EMERGENTE DE U07.9"
    },
    {
        "id": "b5ab163d-5805-8e19-4d74-e19a5e155957",
        "NOMBRE": "ÚTERO BICORNE"
    },
    {
        "id": "ff267200-d583-eedc-dbe9-3a3df6827534",
        "NOMBRE": "ÚTERO UNICORNE"
    },
    {
        "id": "55e0be75-12fd-2cea-9b56-1e5313b7f136",
        "NOMBRE": "VACUNA CONTRA LA COVID-19"
    },
    {
        "id": "8482571c-942a-59fe-7526-ec7cc2e51f52",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 AZTRA ZENECA/OXFORD (CHAD)"
    },
    {
        "id": "67e4ce0f-032b-6fa1-5426-6baf42e5bc04",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 BIONTECH/PFIZER"
    },
    {
        "id": "981ef792-16e6-24f2-db28-3e8f09a4793c",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 CANSINO BIOLOGICS"
    },
    {
        "id": "540885f0-e5ce-1327-0f57-9650df947369",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 JOHNSON & JOHNSON (JANSSEN)"
    },
    {
        "id": "7d2b88d6-615c-d83c-08f0-3086ce5dc77b",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 MODERNA"
    },
    {
        "id": "5ab9c350-8a94-af7b-223a-4b4fe1eae33b",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 OTRA VACUNA ESPECIFICADA"
    },
    {
        "id": "ef30cacb-7451-5c7e-cb78-4a13eb46c445",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 SINOVAC"
    },
    {
        "id": "6cc6c04c-efda-1d46-182e-ccf20429c47e",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 SPUTNIK V"
    },
    {
        "id": "955d90c2-9ca6-087c-8726-1f55a7ab11a6",
        "NOMBRE": "VACUNA CONTRA LA COVID-19 VACUNA NO ESPECIFICADA"
    },
    {
        "id": "741650f2-4596-755b-74f6-8f33ef9fb22b",
        "NOMBRE": "VACUNAS COVID-19 QUE CAUSAN EFECTOS ADVERSOS EN SU USO TERAPÉUTICO"
    },
    {
        "id": "f1a9e1e8-2990-ee77-b786-ee0c0896491b",
        "NOMBRE": "VACUNAS COVID-19 QUE CAUSAN EFECTOS ADVERSOS EN SU USO TERAPÉUTICO, NO ESPECIFICADA"
    },
    {
        "id": "5230aa03-fbec-b98a-0e5a-cbd49d106637",
        "NOMBRE": "VAGINISMO"
    },
    {
        "id": "8ec56d1c-0dc8-031c-2a16-e827c695b60a",
        "NOMBRE": "VAGINISMO NO ORGÁNICO"
    },
    {
        "id": "e7dfc619-f125-6a39-c27a-28578e6db1a8",
        "NOMBRE": "VAGINITIS AGUDA"
    },
    {
        "id": "44a06def-2fce-dfba-d6f0-90c4604018af",
        "NOMBRE": "VAGINITIS ATRÓFICA POSTMENOPÁUSICA"
    },
    {
        "id": "1a5b86e9-83f4-2fe4-b952-211346b0a490",
        "NOMBRE": "VAGINITIS SUBAGUDA Y CRÓNICA"
    },
    {
        "id": "351f3956-a492-87ea-9ac9-30f8d627b6e3",
        "NOMBRE": "VAGINITIS, VULVITIS Y VULVOVAGINITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "4eb96e9b-60f6-ae6e-5ac4-6a51b884efaf",
        "NOMBRE": "VÁLVULAS URETRALES POSTERIORES CONGÉNITAS"
    },
    {
        "id": "bd894cc2-38f3-4674-f690-7e222f080f29",
        "NOMBRE": "VARIACIÓN DEL COLOR DEL PELO"
    },
    {
        "id": "640b83f4-53b8-d440-eda4-20e9663efafe",
        "NOMBRE": "VARICELA"
    },
    {
        "id": "ba4795c6-4706-0b69-8d7e-87d4fdfc4591",
        "NOMBRE": "VARICELA CON OTRAS COMPLICACIONES"
    },
    {
        "id": "ba396b8f-d6c5-75b5-6166-9506c242d4ad",
        "NOMBRE": "VARICELA SIN COMPLICACIONES"
    },
    {
        "id": "62c5a295-47ae-3537-0c50-6db19981aa7f",
        "NOMBRE": "VÁRICES DE LA VULVA"
    },
    {
        "id": "7ba1b3c3-4152-aa37-7aa5-c42d651943c9",
        "NOMBRE": "VÁRICES DE OTROS SITIOS"
    },
    {
        "id": "713a8228-dffa-2fab-0bfc-a5dbb13c3c5e",
        "NOMBRE": "VÁRICES EN OTROS SITIOS ESPECIFICADOS"
    },
    {
        "id": "43555a72-a0e9-3dfd-73a3-6c76d4dec0ed",
        "NOMBRE": "VÁRICES ESCROTALES"
    },
    {
        "id": "009078ed-1387-7bee-7e7e-bb405957249d",
        "NOMBRE": "VÁRICES ESOFÁGICAS"
    },
    {
        "id": "33b0585a-c148-13be-173f-8207511cb8db",
        "NOMBRE": "VÁRICES ESOFÁGICAS CON HEMORRAGIA"
    },
    {
        "id": "ead34020-4ab0-2e44-5759-e59c5f2acf68",
        "NOMBRE": "VÁRICES ESOFÁGICAS CON HEMORRAGIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "730af8fe-7fa4-304a-5e10-09feff594877",
        "NOMBRE": "VÁRICES ESOFÁGICAS SIN HEMORRAGIA"
    },
    {
        "id": "4b591d73-6e6d-8266-8499-0e1aaecc407d",
        "NOMBRE": "VÁRICES ESOFÁGICAS SIN HEMORRAGIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "0adbd264-be10-cc2e-35f7-28dd19140a4c",
        "NOMBRE": "VÁRICES GÁSTRICAS"
    },
    {
        "id": "30289bb1-8d0e-8b4f-5292-8a48d808f802",
        "NOMBRE": "VÁRICES GENITALES EN EL EMBARAZO"
    },
    {
        "id": "7ab694ea-87be-cde7-8bfb-39601eba925a",
        "NOMBRE": "VÁRICES PÉLVICAS"
    },
    {
        "id": "c89ad065-6bd0-5285-b554-e3a74ddf2bff",
        "NOMBRE": "VÁRICES SUBLINGUALES"
    },
    {
        "id": "d45ed6f7-8f6f-6594-2cef-d0e692326ab5",
        "NOMBRE": "VASCULITIS LIMITADA A LA PIEL, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "540bb829-b351-829e-13c6-6dded20b66db",
        "NOMBRE": "VASCULITIS LIMITADA A LA PIEL, SIN OTRA ESPECIFICACIÓN"
    },
    {
        "id": "fdd2adf0-8bd2-324b-9167-ecf1f2e06350",
        "NOMBRE": "VASCULITIS LIVEDOIDE"
    },
    {
        "id": "198f4b7f-3f30-78e0-e12d-fc5b178e7a64",
        "NOMBRE": "VASCULITIS REUMATOIDE"
    },
    {
        "id": "5057a80e-46e2-59ac-49d1-0076d79cc879",
        "NOMBRE": "VASCULOPATÍA NECROTIZANTE, NO ESPECIFICADA"
    },
    {
        "id": "9abb8d38-acee-d9cf-2e68-b269ba6f2a70",
        "NOMBRE": "VEJIGA NEUROPÁTICA FLÁCIDA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "881efd73-3477-6dda-1840-f63215cf1006",
        "NOMBRE": "VEJIGA NEUROPÁTICA NO INHIBIDA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "965d03a8-e31a-4847-3d9a-407429abb58d",
        "NOMBRE": "VEJIGA NEUROPÁTICA REFLEJA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "57dcab7b-c50a-6d83-227e-9c7db377e34e",
        "NOMBRE": "VELOCIDAD DE ERITROSEDIMENTACIÓN ELEVADA"
    },
    {
        "id": "ce9c6324-86cc-8bc8-c536-daefd3357dd1",
        "NOMBRE": "VELOCIDAD DE ERITROSEDIMENTACIÓN ELEVADA Y OTRAS ANORMALIDADES DE LA VISCOSIDAD DEL PLASMA"
    },
    {
        "id": "c545e5cb-fedf-0b46-5601-6b29215de3a3",
        "NOMBRE": "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES"
    },
    {
        "id": "686df574-6a3c-5554-9da0-14f7a292a2c2",
        "NOMBRE": "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON INFLAMACIÓN"
    },
    {
        "id": "c0518644-c20f-5228-b01f-0ea305380acd",
        "NOMBRE": "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON ÚLCERA"
    },
    {
        "id": "2e740020-0187-bef7-a3c1-8b53f15c7122",
        "NOMBRE": "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON ÚLCERA E INFLAMACIÓN"
    },
    {
        "id": "5199b9cf-8be3-665c-cadc-4cf23400ac6a",
        "NOMBRE": "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES EN EL EMBARAZO"
    },
    {
        "id": "448aa8e8-0d78-cfd7-54a0-33e368f12c5a",
        "NOMBRE": "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES SIN ÚLCERA NI INFLAMACIÓN"
    },
    {
        "id": "e889371a-94a8-eccf-acd8-317d197606d5",
        "NOMBRE": "VENTRÍCULO CON DOBLE ENTRADA"
    },
    {
        "id": "4e6436cd-0601-2b42-06f3-4860bbd65d3d",
        "NOMBRE": "VERBOSIDAD Y DETALLES CIRCUNSTANCIALES QUE OSCURECEN LA RAZÓN DE LA CONSULTA O EL CONTACTO"
    },
    {
        "id": "ad7094aa-b725-d15c-d159-eca57dc3f60b",
        "NOMBRE": "VERRUGAS (VENÉREAS) ANOGENITALES"
    },
    {
        "id": "ac9a50dd-701b-e919-902d-0c6b0958419f",
        "NOMBRE": "VERRUGAS VÍRICAS"
    },
    {
        "id": "25a322e2-39bd-eb41-fef0-39a9968da208",
        "NOMBRE": "VÉRTEBRA COLAPSADA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"
    },
    {
        "id": "caab9816-8172-fe22-f6c6-c78fb72c0823",
        "NOMBRE": "VÉRTEBRA COLAPSADA, NO CLASIFICADA EN OTRA PARTE"
    },
    {
        "id": "8e4f50a8-cae0-e33e-66c6-021a5db846c9",
        "NOMBRE": "VÉRTIGO DE ORIGEN CENTRAL"
    },
    {
        "id": "d58ba32a-df6d-9d05-cae4-39717a90e067",
        "NOMBRE": "VÉRTIGO EPIDÉMICO"
    },
    {
        "id": "4186ac33-74b6-5c9b-5d9c-ba15c55e1cbc",
        "NOMBRE": "VÉRTIGO PAROXÍSTICO BENIGNO"
    },
    {
        "id": "606f084b-aa0e-7924-d759-66c31f58bd72",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS"
    },
    {
        "id": "ee479eff-8016-3111-69dc-ed30e874f00c",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "48911da8-cb0b-ccc4-bee8-089f947c7258",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "e5ac8098-db70-e78e-230e-93f38a17ce51",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, CALLES Y CARRETERAS"
    },
    {
        "id": "fb37566d-a57b-e725-72ca-83f4fff83195",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "1a1701b7-72e8-c4da-f2ec-845c11ec251a",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6e323244-62ef-2006-4d4c-ce8718915c3a",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, GRANJA"
    },
    {
        "id": "a8548da6-1832-da82-b440-a54598737ac0",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "4fe0be8c-b60d-6504-b847-d818e6574ced",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "2616f47b-cd4b-bab5-aadd-cd0fbd1d7161",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "23364ca4-cb85-2826-5662-4fc39453e5c5",
        "NOMBRE": "VIAJES Y DESPLAZAMIENTOS, VIVIENDA"
    },
    {
        "id": "1c4b0e98-be03-8126-a998-ed02759d83ec",
        "NOMBRE": "VIBRIO VULNIFICUS COMO LA CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "b972ce83-e325-7f4b-e00f-8302d25ab26b",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA"
    },
    {
        "id": "8be6eaa1-6390-e720-55a9-374bc08784e1",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "5321c913-95af-4a11-7bae-1f5e850c9ba1",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "2f3f0532-a2d0-26f7-00b9-9c23968cb260",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, CALLES Y CARRETERAS"
    },
    {
        "id": "94e7b1aa-de05-e1a3-1a54-6f569cc2650f",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, COMERCIO Y ÁREAS DE SERVICIO"
    },
    {
        "id": "bdd6d85b-ce0d-37e9-01e1-6c17a95e1804",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "8ae30e10-e506-04ec-02c5-cb86359e192f",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, GRANJA"
    },
    {
        "id": "d5f9ba5f-8689-bc66-72ac-4d7374923c46",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "8786cfcd-1aba-183f-fa50-6a95e94d17e8",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "c54a72eb-2a91-4f68-ce37-efce743865fd",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "22cf6679-f78f-4fff-04f9-c54dfbbc2eba",
        "NOMBRE": "VÍCTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, VIVIENDA"
    },
    {
        "id": "845118d3-c0d9-714d-74a7-9e80ce1fb759",
        "NOMBRE": "VÍCTIMA DE EFECTOS DE TERREMOTO NO ESPECIFICADOS"
    },
    {
        "id": "7cacfcb2-e1d6-9f34-3380-5178c83d8fdd",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA"
    },
    {
        "id": "871ce90a-0f79-cd31-f35f-d34949387e37",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "57949df9-1a69-20c2-0e62-3568beaaa1c7",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "4b7947b5-7d91-9591-06dc-00ef344243ae",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, CALLES Y CARRETERAS"
    },
    {
        "id": "881fd978-099b-3d3f-f6b7-359020a43750",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "e83fdcc7-f358-76f2-b415-7a8f54c590f2",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "39399c8c-a004-d806-7fae-c98995e51f80",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, GRANJA"
    },
    {
        "id": "77ead26b-9b99-1b6a-dfdd-32fc38a864dc",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "8936ae9b-ed7c-d0e8-6918-a001a6120421",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "a1f94918-0691-e5ab-4111-0f13adf8420e",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "ffa57d3a-d142-08c5-297c-77bf7eeb7ab6",
        "NOMBRE": "VÍCTIMA DE ERUPCIÓN VOLCÁNICA, VIVIENDA"
    },
    {
        "id": "2cdc785b-4780-c11a-93ea-42ba180e5ac2",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN"
    },
    {
        "id": "d8e46e7e-069d-7fe1-e4ca-8d330531002a",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "2ded78b9-0272-cc9e-fbfd-da89846d6787",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "dcdf83a9-c066-288e-e47a-af590c0b2a16",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, CALLES Y CARRETERAS"
    },
    {
        "id": "d2bb70fb-aa38-09c1-b8d9-62249d1439e0",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "d2df5145-ad1d-6b33-d758-18cf25fa5e99",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "7049177e-9cc4-b6aa-d1ea-545d32abe149",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, GRANJA"
    },
    {
        "id": "c611379a-ca35-df05-5b2d-cb97f3bf3db6",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "8c19fbfb-367a-c479-bdb6-a9945d2593b4",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "f45bc969-b2e3-9c55-e00c-de6dbf7ff8b4",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "cf269f72-87c3-3427-2c9e-4b56668116a0",
        "NOMBRE": "VÍCTIMA DE INUNDACIÓN, VIVIENDA"
    },
    {
        "id": "8fce2621-7c2f-5975-490d-77c159c981d9",
        "NOMBRE": "VÍCTIMA DE MOVIMIENTOS CATACLÍSMICOS DE LA TIERRA CAUSADOS POR TERREMOTO"
    },
    {
        "id": "e5703962-ec6a-f2c4-d685-8bf1865ecad3",
        "NOMBRE": "VÍCTIMA DE OTROS EFECTOS DE TERREMOTO ESPECIFICADOS"
    },
    {
        "id": "09979b61-54e1-7dd5-a01d-25bc78e492af",
        "NOMBRE": "VÍCTIMA DE RAYO"
    },
    {
        "id": "b664045a-2c2e-8ac0-76ab-38fe7c46cde7",
        "NOMBRE": "VÍCTIMA DE RAYO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "f302e8ef-27ae-3e83-450b-b8dd69fae59e",
        "NOMBRE": "VÍCTIMA DE RAYO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "7208e452-43eb-c381-0ad0-94e1f7281649",
        "NOMBRE": "VÍCTIMA DE RAYO, CALLES Y CARRETERAS"
    },
    {
        "id": "31cd4937-ab35-8b49-a830-140e02726e4f",
        "NOMBRE": "VÍCTIMA DE RAYO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "96a2e707-1321-d19c-536b-a2fef490363e",
        "NOMBRE": "VÍCTIMA DE RAYO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "b55970b5-156f-0381-c9ff-fed286d5f8ae",
        "NOMBRE": "VÍCTIMA DE RAYO, GRANJA"
    },
    {
        "id": "c6794a0c-f403-6ff1-1d88-e944c96f5f72",
        "NOMBRE": "VÍCTIMA DE RAYO, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "37e36685-a79f-9749-3556-5a6a40b280e4",
        "NOMBRE": "VÍCTIMA DE RAYO, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "42ce1906-d8b5-6050-1eb0-1afa3401d06c",
        "NOMBRE": "VÍCTIMA DE RAYO, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "2fc3c154-95ee-7d60-8cb9-6d20105ccbf1",
        "NOMBRE": "VÍCTIMA DE RAYO, VIVIENDA"
    },
    {
        "id": "994e20e9-d560-bbaa-c336-29159199ba08",
        "NOMBRE": "VÍCTIMA DE TERREMOTO"
    },
    {
        "id": "303e5087-babe-debf-49a4-097b469baeb6",
        "NOMBRE": "VÍCTIMA DE TERREMOTO, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "4910251a-a5cf-6c58-0c06-9c050a30a61c",
        "NOMBRE": "VÍCTIMA DE TERREMOTO, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "fde52cde-5979-2cc7-246d-0bd646f00946",
        "NOMBRE": "VÍCTIMA DE TERREMOTO, CALLES Y CARRETERAS"
    },
    {
        "id": "4b2e33f7-1542-a2e9-a142-91470ab94cbc",
        "NOMBRE": "VÍCTIMA DE TERREMOTO, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "85b7c21c-e3f4-350a-830a-8dc2e0084523",
        "NOMBRE": "VÍCTIMA DE TERREMOTO, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "6991689f-554b-47cd-e4c1-15f025b36f1d",
        "NOMBRE": "VÍCTIMA DE TERREMOTO, GRANJA"
    },
    {
        "id": "447af2d1-2f8d-034f-e9dc-50d22b2e038c",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA"
    },
    {
        "id": "37de3dbf-d323-dfd8-7233-cc6df2501d43",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, ÁREA INDUSTRIAL Y DE LA CONSTRUCCIÓN"
    },
    {
        "id": "5ac55192-0960-9450-4972-555650b9f845",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, ÁREAS DE DEPORTE Y ATLETISMO"
    },
    {
        "id": "a1dd05d4-05f7-67aa-85a2-1c454911c7e0",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, CALLES Y CARRETERAS"
    },
    {
        "id": "8ec958b6-e850-101b-b454-cdf0a6147818",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, COMERCIO Y ÁREA DE SERVICIOS"
    },
    {
        "id": "c9ffea09-daa7-74a7-25aa-dadb892d8a86",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, ESCUELAS, OTRAS INSTITUCIONES Y ÁREAS ADMINISTRATIVAS PÚBLICAS"
    },
    {
        "id": "46a5bee6-f2a3-5353-9972-8f28ca1c1341",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, GRANJA"
    },
    {
        "id": "7ef5a829-c581-4a60-b066-8a39e7652b7d",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, INSTITUCIÓN RESIDENCIAL"
    },
    {
        "id": "2a6cda46-9336-08f2-b27e-99bac96377da",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, LUGAR NO ESPECIFICADO"
    },
    {
        "id": "04aeb383-1966-cbaf-f7cc-1b94606ec3cc",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, OTRO LUGAR ESPECIFICADO"
    },
    {
        "id": "2bc4eaa5-13f5-51c8-7410-e3d71e2d30ff",
        "NOMBRE": "VÍCTIMA DE TORMENTA CATACLÍSMICA, VIVIENDA"
    },
    {
        "id": "69496dac-f1b0-9d7a-df68-ac906559bbc9",
        "NOMBRE": "VÍCTIMA DE TSUNAMI"
    },
    {
        "id": "1917a4fe-7fa9-1e79-779d-043dfaf00148",
        "NOMBRE": "VIOLENCIA FÍSICA"
    },
    {
        "id": "e5cbe4e1-ecf4-4363-8104-852b1ec21107",
        "NOMBRE": "VIRUELA"
    },
    {
        "id": "0ed39c2f-9a89-8c2b-ed59-5d3839be247a",
        "NOMBRE": "VIRUELA DE LOS MONOS"
    },
    {
        "id": "62846402-f8aa-7d0e-1672-50f62b7e3638",
        "NOMBRE": "VIRUS SINCICIAL RESPIRATORIO COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPÍTULOS"
    },
    {
        "id": "5e8c99b4-822b-03f0-207f-f9d8846bca06",
        "NOMBRE": "VISCOSIDAD PLASMÁTICA ANORMAL"
    },
    {
        "id": "b3a2993c-845a-20cb-853a-5f96b7179585",
        "NOMBRE": "VITÍLIGO"
    },
    {
        "id": "95172cf2-3f35-45ae-08c0-b6700737a340",
        "NOMBRE": "VÓLVULO"
    },
    {
        "id": "e0988bc1-8776-1804-f045-e0b7d9452008",
        "NOMBRE": "VÓMITO POSTCIRUGÍA GASTROINTESTINAL"
    },
    {
        "id": "7cce00aa-afcc-c88f-dd98-5d31306f6ea5",
        "NOMBRE": "VÓMITOS ASOCIADOS CON OTRAS ALTERACIONES PSICOLÓGICAS"
    },
    {
        "id": "1cb3ff3c-fe37-2973-bbe0-6ef70b30161e",
        "NOMBRE": "VÓMITOS DEL EMBARAZO, NO ESPECIFICADOS"
    },
    {
        "id": "f173d007-4e8c-c4c0-a15d-396ac581c3c5",
        "NOMBRE": "VÓMITOS DEL RECIÉN NACIDO"
    },
    {
        "id": "51c4b8c6-8d9c-dac5-ad42-885fbd898988",
        "NOMBRE": "VÓMITOS EXCESIVOS DEL EMBARAZO"
    },
    {
        "id": "68280c51-3e20-0e9c-406e-013fdb8d2deb",
        "NOMBRE": "VOYEURISMO"
    },
    {
        "id": "b92e1f8c-121a-bda1-46ed-ae18b0491b35",
        "NOMBRE": "VULVITIS AGUDA"
    },
    {
        "id": "b4c9af16-6e7a-63f2-89d0-a030bc7fed01",
        "NOMBRE": "VULVITIS SUBAGUDA Y CRÓNICA"
    },
    {
        "id": "6f1abf9b-3a31-3f74-3fb4-80b17fdd1f76",
        "NOMBRE": "XANTELASMA DEL PÁRPADO"
    },
    {
        "id": "4bacd8d3-9c05-31e6-9426-998ff1b3c6c1",
        "NOMBRE": "XERODERMA PIGMENTOSO"
    },
    {
        "id": "3d715c28-58e2-cdeb-de5a-b3925c3509e3",
        "NOMBRE": "XEROSIS DEL CUTIS"
    },
    {
        "id": "d97cc479-5b2d-a4a8-e09a-463ae4de5c0b",
        "NOMBRE": "YERSINIOSIS EXTRAINTESTINAL"
    }
]

export default cie