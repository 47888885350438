/**
 * Librerias
 */
import React from "react";

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'

/**
 * Componentes
 */
//Componente asignado para contener el elemento DatePicker
import DateSelector from '../components/dateSelector';

/**
 * Componente asignado para mostrar el selector de fechas
 * @param {Object} param0 Recibe los elementos necesarios para mostrar el selector de fechas
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const DateModal = ({initialDay,setInitialDay,finalDay,setFinalDay}) => {

    /**
     * Función que cambia el estado del modal a oculto
     */
    const closeModal = () => {
        document.getElementById('dateModal').style.display='none'
    } 

    /**
     * Función que atrapa el evento click de cancelar y dispara el cierre del modal
     * @returns Devuelve el estado del objeto
     */
    const handleCancelarClick = () => (event) => {
        event.preventDefault()
        closeModal()
    }

    return (
        <div id='dateModal' className='modalConfiguracion'>
            <div className='modal-content'>
                <span className='closeBtn' onClick={handleCancelarClick()}>X</span>
                <br/>
                <form id="msform">
                    <h2 className="modalInfo">{"Seleccione el rango de fechas deseados"}</h2>
                    <DateSelector initialDay={initialDay} setInitialDay={setInitialDay} finalDay={finalDay} setFinalDay={setFinalDay}/>
                    <input type="submit" name="submit" className="submit cancel-button" value="Aceptar" onClick={handleCancelarClick()}/>
                </form>
            </div>
        </div>
    )
}

export default DateModal;